import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import swal from 'sweetalert'

import 'react-toastify/dist/ReactToastify.css'
import { useSwal } from '../hooks/swal/useSwal'
import { CustomerProps } from './CustomerContext'

interface UserProviderProps {
  children: ReactNode
  pathname?: string
}

export interface UserProps {
  id: string
  user_type: string
  situation: string
  email: string
  firstname: string
  lastname: string
  loglife_employee: boolean
  password: string
  permissions: string[]
  customer_id: string | null
  collector_id: string | null
  driver_id: string | null
  customerIDUser: {
    trading_firstname: string
  }
  collectorIDUser: {
    company_name: string
    trading_name: string
  }
  driverIDUser: {
    firstname: string
  }
  customers: CustomerProps[]
  collectors: {
    collectorId: string
    collector: {
      id: string
      trading_name: string
    }
  }[]
  substitute_id: string | null
  collectors_ids?: string[]
}

interface UserPasswordPropsInput {
  email: string
  firstname: string
  lastname: string
  oldPassword: string
  newPassword: string
  newPasswordRepeat: string
}

export type UserInput = Omit<
  UserProps,
  'id' | 'password' | 'customerIDUser' | 'collectorIDUser' | 'driverIDUser' | 'customers' | 'collectors'
> & {
  customers?: string[]
}

type UpdateUserPermissionsInputProps = {
  permissions: string[]
  user_type: string
}

type RecoverUserPasswordProps = {
  new_password: string
  token: string
}

type SendForgotUserPasswordProps = {
  email: string
}

interface UserContextProps {
  createUser: (userInput: UserInput) => Promise<boolean>
  deleteUser: (userId: string) => Promise<boolean | undefined>
  editUser: (
    userId: string,
    userInput: UserInput,
  ) => Promise<boolean | undefined>
  editUserPassword: (
    userId: string,
    userInput: UserPasswordPropsInput,
  ) => Promise<void>
  resetUserPassword: (userId: string) => Promise<void>
  updateUsersPermissions: (
    updateUserPermissionsInput: UpdateUserPermissionsInputProps,
  ) => Promise<boolean | undefined>
  recoverUserPassword: (
    input: RecoverUserPasswordProps
  ) => Promise<boolean | undefined>
  sendForgotUserPassword: (
    input: SendForgotUserPasswordProps
  ) => Promise<boolean | undefined>
}

export const UserContext = createContext<UserContextProps>(
  {} as UserContextProps,
)

export function UserProvider({ children, pathname }: UserProviderProps) {
  const { successMessage } = useSwal()

  async function createUser(userInput: UserInput) {
    try {
      await api.post('/user', userInput)

      setTimeout(() => {
        swal('Poof! O usuário foi criado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteUser(userId: string) {
    try {
      await api.delete(`/user/${userId}`)
      swal('Poof! O usuário foi excluído com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editUser(userId: string, userInput: UserInput) {
    try {
      await api.put(`/user/${userId}`, userInput)
      setTimeout(() => {
        swal('Poof! O usuário foi atualizado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editUserPassword(
    userId: string,
    userInput: UserPasswordPropsInput,
  ) {
    try {
      await api.put(`/user/password/${userId}`, userInput)
      setTimeout(() => {
        swal('Poof! A senha foi atualizada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function resetUserPassword(userId: string) {
    try {
      await api.put(`/user/password/${userId}`, {
        hasResetPassword: true,
      })
      setTimeout(() => {
        swal('Poof! A senha foi resetada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
    }
  }

  async function recoverUserPassword(
    input: RecoverUserPasswordProps
  ) {
    try {
      await api.put('/password/recover', input)

      await successMessage('Senha atualizada com sucesso!')

      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function sendForgotUserPassword(
    input: SendForgotUserPasswordProps
  ) {
    try {
      await api.put('/password/forgot', input)

      await successMessage('Email enviado com sucesso!')

      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function updateUsersPermissions(
    updateUserPermissionsInput: UpdateUserPermissionsInputProps,
  ) {
    try {
      await api.put('/user/update-permissions', updateUserPermissionsInput)

      successMessage('Permissões atualizadas com sucesso!')
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <UserContext.Provider
      value={{
        createUser,
        deleteUser,
        editUser,
        editUserPassword,
        resetUserPassword,
        updateUsersPermissions,
        recoverUserPassword,
        sendForgotUserPassword
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { useContentLoading } from '../../../hooks/loading/useContentLoading'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useRnc } from '../../../hooks/rnc/useRnc'
import { RncTable } from '../../../components/Tables/Rnc/RncTable'
import { RncProps } from '../../../utils/RequestFunctions/Rnc/requestRncFunctions'

export function ToAnswerRncList() {
  const [rncToValidate, setRncToValidate] = useState<RncProps[]>([])

  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()
  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const {
    rncs: { data: rnc, isLoading: isRncLoading },
  } = useRnc(null, true)

  useEffect(() => {
    function run() {
      if (!isRncLoading) {
        handleSetIsContentLoadingFalse()
      }
    }
    run()
  }, [isRncLoading, handleSetIsContentLoadingFalse])

  useEffect(() => {
    if (rnc) {
      const rncToValidateFilter = rnc.filter(
        (rnc) => rnc.status === 'AWAITING RESPONSE',
      )
      setRncToValidate(rncToValidateFilter)
    }
  }, [rnc])

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-rnc-list')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  const permissions = userLogged?.permissions

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Registros de Não Conformidade Pendentes Resposta
            </Heading>
            {permissions !== undefined && (
              <Link to="/rnc/adicionar">
                {userLogged?.permissions.includes('add-rnc') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Adicionar RNC
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!rnc && <RncTable rnc={rncToValidate} />}
        </Box>
      </Flex>

  )
}

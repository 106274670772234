import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getPatrimonyTypes } from "api/patrimonies/getPatrimonyTypes";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateEditPatrimonyType } from "./components/CreateEditPatrimonyType";
import { PatrimonyTypesTableFilters } from "./components/PatrimonyTypesTableFilters";
import { PatrimonyTypeTableRow } from "./components/PatrimonyTypeTableRow";

export function PatrimonyTypes() {

  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()

  const userHasViewPatrimonyTypesPermission = userLogged?.permissions.includes('view-patrimony-types')
  const userHasCreatePatrimonyTypesPermission = userLogged?.permissions.includes('create-patrimony-type')

  useEffect(() => {
    if (!userHasViewPatrimonyTypesPermission) {
      redirect('/')
    }
  }, [userHasViewPatrimonyTypesPermission, redirect])

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const type = searchParams.get('type')

  const { data: patrimonyTypesData } = useQuery({
    queryKey: ['patrimonyTypes',
      page,
      type
    ],
    queryFn: () => getPatrimonyTypes({
      currentPage: page,
      pageSize: '10',
      type
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: patrimonyTypesData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: patrimonyTypesData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreatePatrimonyTypeModalOpen,
    onOpen: onOpenCreatePatrimonyTypeModal,
    onClose: onCloseCreatePatrimonyTypeModal
  } = useDisclosure()

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Tipos de Patrimônio</Heading>
        {userHasCreatePatrimonyTypesPermission && (
          <IconButton
            aria-label="Criar tipo de patrimônio"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreatePatrimonyTypeModal}
          />
        )}
        <Modal
          isOpen={isCreatePatrimonyTypeModalOpen}
          onClose={onCloseCreatePatrimonyTypeModal}
          isCentered
        >
          <ModalOverlay />
          <CreateEditPatrimonyType
            onCloseModal={onCloseCreatePatrimonyTypeModal}
            slug='create'
          />
        </Modal>
      </Flex>
      <PatrimonyTypesTableFilters/>
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th color="white">Tipo</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {patrimonyTypesData?.patrimonyTypes.map(patrimonyType => {
              return (
                <PatrimonyTypeTableRow
                  key={patrimonyType.id}
                  patrimonyType={patrimonyType}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Select, Stack, Text, Textarea } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { StandardizationType } from "api/experiencePeriods/types/ExperiencePeriod";
import { standardizationMap } from "utils/experiencePeriodMappers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { evaluateExperiencePeriod } from "api/experiencePeriods/evaluateExperiencePeriod";
import { useEffect } from "react";
import { getExperiencePeriod } from "api/experiencePeriods/getExperiencePeriod";

interface EvaluateExperiencePeriodProps {
  experiencePeriodId: string
}

interface EvaluateExperiencePeriodSchema {
  delaysWithoutSickNote: string
  foulsWithSickNote: string
  hasUnjustifiedAbsense: string
  standardization: StandardizationType
  hasRhPendingDocuments: string
  hasBehavioralProblems: string
  considerations: string
}


const evaluateExperiencePeriodSchema = yup.object({
  delaysWithoutSickNote: yup.string().required(),
  foulsWithSickNote: yup.string().required(),
  hasUnjustifiedAbsense: yup.string().required(),
  standardization: yup.string().required(),
  hasRhPendingDocuments: yup.string().required(),
  hasBehavioralProblems: yup.string().required(),
  considerations: yup.string().required(),
})

export const optionsSelect = [
  "0",
  "1",
  "2",
  "3 ou mais",
];

export function EvaluateExperiencePeriod({
  experiencePeriodId
}: EvaluateExperiencePeriodProps) {
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    data: experiencePeriodResult
  } = useQuery({
    queryKey: ['experiencePeriod', experiencePeriodId],
    queryFn: () => getExperiencePeriod({ routeParams: { experiencePeriodId: experiencePeriodId } })
  })

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EvaluateExperiencePeriodSchema>({
    resolver: yupResolver(evaluateExperiencePeriodSchema)
  })

  useEffect(() => {
    if (experiencePeriodResult) {
      setValue('delaysWithoutSickNote', experiencePeriodResult?.experiencePeriod?.delays_without_sick_note)
      setValue('foulsWithSickNote', experiencePeriodResult?.experiencePeriod?.fouls_with_sick_note)
      setValue('hasUnjustifiedAbsense', experiencePeriodResult?.experiencePeriod?.has_unjustified_absense ? 'yes' : 'no')
      setValue('standardization', experiencePeriodResult?.experiencePeriod?.standardization)
      setValue('hasRhPendingDocuments', experiencePeriodResult?.experiencePeriod?.has_rh_pending_document ? 'yes' : 'no')
      setValue('hasBehavioralProblems', experiencePeriodResult?.experiencePeriod?.has_behavioral_problems ? 'yes' : 'no')
      setValue('considerations', experiencePeriodResult?.experiencePeriod?.considerations)
    }
  }, [experiencePeriodResult, setValue])


  const { mutateAsync: evaluateExperiencePeriodFn } = useMutation({
    mutationFn: evaluateExperiencePeriod,
    onSuccess: () => {
      queryClient.invalidateQueries(['experience-periods'])
      queryClient.invalidateQueries(['active-experience-periods'])
      queryClient.invalidateQueries(['validating-45-days-experience-periods'])
      queryClient.invalidateQueries(['validating-90-days-experience-periods'])
      queryClient.invalidateQueries(['dismissing-experience-periods'])
      queryClient.invalidateQueries({ queryKey: ['experience-period', experiencePeriodId] })
    }
  })

  async function handleEvaluateExperiencePeriod(values: EvaluateExperiencePeriodSchema, dismiss: boolean) {

    await promiseMessage(evaluateExperiencePeriodFn({
      body: {
        ...values,
        hasUnjustifiedAbsense: values.hasUnjustifiedAbsense === 'yes',
        hasRhPendingDocuments: values.hasRhPendingDocuments === 'yes',
        hasBehavioralProblems: values.hasBehavioralProblems === 'yes',
        dismiss
      },
      routeParams: {
        experiencePeriodId
      }
    }), 'Avaliação realizada!')
  }
  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'

    >
      <Heading letterSpacing="tight" size='sm'>Avaliar Periodo de Experiência</Heading>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.delaysWithoutSickNote} mt="3">
            <FormLabel fontSize="sm">
              Atrasos sem atestado
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('delaysWithoutSickNote')}
              name="delaysWithoutSickNote"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {Object.entries(optionsSelect).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>

          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.foulsWithSickNote} mt="3">
            <FormLabel fontSize="sm">
              Faltas com atestado
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register('foulsWithSickNote')}
              name="foulsWithSickNote"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {Object.entries(optionsSelect).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>

          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasUnjustifiedAbsense}>
            <FormLabel>
              Falta injustificada
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasUnjustifiedAbsense"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>

                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.standardization}>
            <FormLabel fontSize="sm">
              Padronização
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('standardization')}
              name="standardization"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {Object.entries(standardizationMap).map(([key, value]) => {
                return (
                  <option key={key} value={key}>{value}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>

      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasRhPendingDocuments}>
            <FormLabel>
              Possui pendência de documentação da qualidade ou RH?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasRhPendingDocuments"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>

                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasBehavioralProblems}>
            <FormLabel>
              Teve reclamação do cliente ou problema de postura/atendimento sobre o colaborador ?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasBehavioralProblems"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="column">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.considerations}>
            <FormLabel fontSize="sm">
              Descreva aqui o porque a pessoa deve ou não continuar pós período de experiência
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Textarea
              {...register('considerations')}
              name="considerations"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
      </Stack>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
        gap={2}
      >
        <Button
          type="button"
          size="sm"
          variant='ghost'
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={handleSubmit((values) => handleEvaluateExperiencePeriod(values, true))}
        >
          Demissão
        </Button>

        <Button
          type="button"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={handleSubmit((values) => handleEvaluateExperiencePeriod(values, false))}
        >
          Avaliar
        </Button>
      </Flex>
    </Box>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Pmc } from "./pmc";

interface CreatePmcBody {
  pmcs: {
    purchaseQuantity: number
    productId: string
  }[]
}

interface CreatePmcParams {
  warehouseId: string
  body: CreatePmcBody
}

interface CreatePmcResponse {
  notificationProfiles: string[]
  pmc: Pmc
}

export async function createPmc({
  warehouseId,
  body
}: CreatePmcParams) {
  try {
    const response = await api.post<CreatePmcResponse>(`/warehouses/${warehouseId}/pmcs`, {
      pmcs: body.pmcs
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

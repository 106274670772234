import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

interface IEmptyAddressesFormServiceProps {
  title: string
  description: string
}

export function EmptyAddressesFormServiceAlert({
  title,
  description,
}: IEmptyAddressesFormServiceProps) {
  return (
    <Alert
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="180px"
    >
      <AlertIcon boxSize="40px" />
      <AlertTitle mt="4" fontSize="lg">
        {title}
      </AlertTitle>
      <AlertDescription maxWidth="sm">{description}</AlertDescription>
    </Alert>
  )
}

export const handleChangeReportFormTilte = (slug: string) => {
  switch (slug) {
    case 'sla':
      return 'Relatório de SLA'
    case 'agendamentos':
      return 'Relatório de Agendamentos'
    case 'estoque':
      return 'Relatório de Estoque'
    case 'cargas':
      return 'Relatório de Cargas'
    case 'custo':
      return 'Relatório de Custo'
    case 'cte':
      return 'Relatório de CTE'
    case 'faturamento':
      return 'Relatório de Faturamento'
    case 'controle-estoque':
      return 'Relatório de Controle de Estoque'
    case 'ocorrencias':
      return 'Relatório de Ocorrências'
    case 'despacho-estoque':
      return 'Relatório de Despacho de Estoque'
    case 'rnc':
      return 'Relatório de RNC'
    case 'coletador':
      return 'Relatório de Coletadores'
    case 'gerencial-cliente':
      return 'Relatório Gerencial de Cliente'
    case 'pontos':
      return 'Relatório de pontos de coleta'
    default:
      return ''
  }
}

import { ChangeEvent, createContext, ReactNode, useCallback, useState } from "react";

export interface Filters {
  protocol: string
  customer: string
  sourceCity: string
  destinationCity: string
  collectDate: string
  deliveryDatetime: string
  tracker: string
  cte: string
  vehicle: string
  volume: string
  shipping: string
  sourceBranch: string
  destinationBranch: string
  destinationCollector: string
  step: string
}

interface LoadRatioServicesListContextProps {
  filters: Filters
  handleSetInputFilters: (event: ChangeEvent<HTMLInputElement>) => void
  handleSetSelectFilters: (event: ChangeEvent<HTMLSelectElement>) => void
  handleRemoveFilters: (name: keyof Filters) => void
}

interface LoadRatioServicesListContextProviderProps {
  children: ReactNode
}

export const LoadRatioServicesListContext = createContext(
  {} as LoadRatioServicesListContextProps
)

export function LoadRatioServicesListContextProvider({
  children
}: LoadRatioServicesListContextProviderProps) {
  const [filters, setFilters] = useState({} as Filters)

  function handleSetInputFilters(event: ChangeEvent<HTMLInputElement>) {
    setFilters(prevFilters => {
      return {
        ...prevFilters,
        [event.target.name]: event.target.value
      }
    })
  }

  function handleSetSelectFilters(event: ChangeEvent<HTMLSelectElement>) {
    setFilters(prevFilters => {
      return {
        ...prevFilters,
        [event.target.name]: event.target.value
      }
    })
  }

  const handleRemoveFilters = useCallback((name: keyof Filters) => {
    setFilters((prevFilters) => {
      delete prevFilters[name]
      return {
        ...prevFilters
      }
    })
  }, [])


  return (
    <LoadRatioServicesListContext.Provider value={{
      filters,
      handleSetInputFilters,
      handleSetSelectFilters,
      handleRemoveFilters
    }}>
      {children}
    </LoadRatioServicesListContext.Provider>
  )
}

import { Button, Flex, IconButton, Input, Select, Text, useMediaQuery } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface SearchBusinessCostsSchema {
  customer: string
  situation: 'active' | 'inactive' | 'all'
}

export function BusinessCostsTableFilter() {
  const [isLargerThen768, isSmallerThen1200] = useMediaQuery([
    '(min-width: 768px)',
    '(max-width: 1200px)'
  ])

  const searchParams = useSearchParams()
  const history = useHistory()

  const customer = searchParams.get('customer')
  const situation = searchParams.get('situation') ?? 'all'


  const {
    register,
    handleSubmit,
    reset,
  } = useForm<SearchBusinessCostsSchema>({
    defaultValues: {
      customer,
      situation: situation as 'active' | 'inactive' | 'all'
    }
  })

  function handleSearchBusinessCosts(values: SearchBusinessCostsSchema) {
    if (values.customer) {
      searchParams.set('customer', values.customer)
    } else {
      searchParams.delete('customer')
    }

    if (values.situation) {
      searchParams.set('situation', values.situation)
    } else {
      searchParams.delete('situation')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('customer')
    searchParams.delete('situation')

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })

    reset({
      customer: '',
      situation: 'all'
    })
  }

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      gap="2"
      mt="6"
      align="center"
      as="form"
      onSubmit={handleSubmit(handleSearchBusinessCosts)}
    >
      <Text display={{ base: 'none', md: 'block' }}>Filtros: </Text>

      <Input
        {...register('customer')}
        size="sm"
        rounded="md"
        placeholder="Cliente"
        w={{ base: 'full', md: '250px' }}
      />

      <Select
        {...register('situation')}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: '250px' }}
      >
        <option value="active">Ativo</option>
        <option value="inactive">Inativo</option>
        <option value="all">Todas situações</option>
      </Select>

      {isLargerThen768 && isSmallerThen1200 ? (
        <IconButton
          aria-label="Fitrar resultados"
          size="sm"
          icon={<FaSearch />}
          type="submit"
          w={{ base: 'full', md: 'fit-content' }}
        />
      ) : (
        <Button
          size="sm"
          leftIcon={<FaSearch />}
          type="submit"
          w={{ base: 'full', md: 'fit-content' }}
        >
          Filtrar resultados
        </Button>
      )}

      {isLargerThen768 && isSmallerThen1200 ? (
        <IconButton
          aria-label="Remover filtros"
          size="sm"
          icon={<FaTimes />}
          type="button"
          variant="ghost"
          w={{ base: 'full', md: 'fit-content' }}
          onClick={handleClearFilters}
        />
      ) : (
        <Button
          size="sm"
          leftIcon={<FaTimes />}
          variant="ghost"
          type="button"
          w={{ base: 'full', md: 'fit-content' }}
          onClick={handleClearFilters}
        >
          Remover filtros
        </Button>
      )}

    </Flex>
  )
}

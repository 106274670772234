import swal from 'sweetalert'

type SwalConfirmMessageProps = {
  title: string
  text?: string
  buttons?: string[]
}

const confirmMessagePatterns = {
  text: 'Essa ação não poderá ser desfeita!',
  icon: 'warning',
  buttons: ['Cancelar', 'Confirmar'],
  dangerMode: false,
}

const addAddressConfirmMessagePatterns = {
  text: "Clique em 'Não' caso queira solicitar um novo serviço mesmo assim!",
  icon: 'warning',
  buttons: ['Não', 'Sim'],
  dangerMode: false,
}

export const useSwal = () => {
  const standardMessage = (message: string) => swal(message)

  const confirmMessage = ({ title, text, buttons }: SwalConfirmMessageProps) =>
    swal({
      title,
      ...confirmMessagePatterns,
      text: text ?? confirmMessagePatterns.text,
      buttons: buttons ?? confirmMessagePatterns.buttons
    })

  const addAddressConfirmMessage = ({ title }: SwalConfirmMessageProps) =>
    swal({ title, ...addAddressConfirmMessagePatterns })

  const errorMessage = (message: string) => swal('Erro!', message, 'error')

  const successMessage = (message: string) =>
    swal('Sucesso!', message, 'success')

  const alertMessage = (message: string) => swal('Oops!', message, 'info')

  return {
    confirmMessage,
    standardMessage,
    errorMessage,
    successMessage,
    addAddressConfirmMessage,
    alertMessage
  }
}

import { Button, IconButton, Modal, ModalOverlay, Portal, Td, Tr, useDisclosure } from "@chakra-ui/react"
import { PatrimonyRequest } from "api/patrimonies/patrimonyRequest"
import { addHours, format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { FaArrowRight, FaSearch } from "react-icons/fa"
import { ActivateRequestedPatrimony } from "./ActivateRequestedPatrimony"
import { DisablePatrimonyRequest } from "./DisablePatrimonyRequest"
import { PatrimonyRequestDetails } from "./PatrimonyRequestDetails"
import { PatrimonyRequestStatus } from "./PatrimonyRequestStatus"
import { ProceedRequestedPatrimonyToDevolution } from "./ProceedRequestedPatrimonyToDevolution"
import { ReturnRequestedPatrimony } from "./ReturnRequestedPatrimony"

interface PatrimonyRequestTableRowProps {
  patrimonyRequest: PatrimonyRequest
}

export function PatrimonyRequestTableRow({ patrimonyRequest }: PatrimonyRequestTableRowProps) {

  const userTimezone = new Date().getTimezoneOffset() / 60

  const { userLogged } = useAuth()

  const userCanDisablePatrimonyRequest = userLogged?.permissions.includes('disable-patrimony-request')

  const userCanReturnPatrimonyRequest = userLogged?.permissions.includes('return-patrimony-request')

  const userCanProceedRequestedPatrimonyToDevolution = userLogged?.permissions.includes('proceed-requested-patrimony-devolution')

  const userCanActivatePatrimonyRequest = userLogged?.permissions.includes('activate-patrimony-request')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()


  const {
    isOpen: isActivatePatrimonyRequestModalOpen,
    onOpen: onOpenActivatePatrimonyRequestModal,
    onClose: onCloseActivatePatrimonyRequestModal
  } = useDisclosure()

  const {
    isOpen: isDisablePatrimonyRequestModalOpen,
    onOpen: onOpenDisablePatrimonyRequestModal,
    onClose: onCloseDisablePatrimonyRequestModal
  } = useDisclosure()

  const {
    isOpen: isReturnPatrimonyRequestModalOpen,
    onOpen: onOpenReturnPatrimonyRequestModal,
    onClose: onCloseReturnPatrimonyRequestModal
  } = useDisclosure()

  const {
    isOpen: isProceedRequestedPatrimonyToDevolutionModalOpen,
    onOpen: onOpenProceedRequestedPatrimonyToDevolutionModal,
    onClose: onCloseProceedRequestedPatrimonyToDevolutionModal
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Solicitação de Patrimônio"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />
          <PatrimonyRequestDetails
            patrimonyRequestId={patrimonyRequest?.patrimony_request_id}
            patrimonyId={patrimonyRequest?.patrimony_id}
          />

        </Modal>
      </Td>
      <Td>{patrimonyRequest?.patrimonyRequest.protocol}</Td>
      <Td>{patrimonyRequest?.patrimonyRequest.regional}</Td>
      <Td>{patrimonyRequest?.patrimonyRequest.responsible_name}</Td>
      <Td>{patrimonyRequest?.patrimonyType.type}</Td>
      <Td>{format(addHours(new Date(patrimonyRequest?.patrimonyRequest.limit_date), userTimezone), 'dd/MM/yyy')}</Td>
      <Td>{patrimonyRequest?.patrimonyRequest.sector}</Td>
      <Td>
        <PatrimonyRequestStatus status={patrimonyRequest?.status} />
      </Td>
      <Td>
        {userCanProceedRequestedPatrimonyToDevolution && patrimonyRequest.status === 'active' && (
          <>
            <Modal
              isOpen={isProceedRequestedPatrimonyToDevolutionModalOpen}
              onClose={onCloseProceedRequestedPatrimonyToDevolutionModal}
              isCentered
            >
              <ModalOverlay />
              <ProceedRequestedPatrimonyToDevolution
                onCloseModal={onCloseProceedRequestedPatrimonyToDevolutionModal}
                patrimonyRequestId={patrimonyRequest.patrimony_request_id}
                patrimonyId={patrimonyRequest?.patrimony_id}
              />
            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenProceedRequestedPatrimonyToDevolutionModal}
              mr={2}
            >
              Processo de Devolução
            </Button>
          </>
        )}

        {userCanReturnPatrimonyRequest && patrimonyRequest?.status === 'returning' && (
          <>
            <Modal
              isOpen={isReturnPatrimonyRequestModalOpen}
              onClose={onCloseReturnPatrimonyRequestModal}
              isCentered
            >
              <ModalOverlay />
              <ReturnRequestedPatrimony
                onCloseModal={onCloseReturnPatrimonyRequestModal}
                patrimonyRequestId={patrimonyRequest.patrimony_request_id}
                patrimonyId={patrimonyRequest?.patrimony_id} />

            </Modal><Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenReturnPatrimonyRequestModal}
              mr={2}
            >
              Concluído
            </Button>
          </>
        )}
        {userCanDisablePatrimonyRequest && patrimonyRequest?.status === 'active' && patrimonyRequest?.patrimony.status === 'in-possession' && (
          <>
            <Modal
              isOpen={isDisablePatrimonyRequestModalOpen}
              onClose={onCloseDisablePatrimonyRequestModal}
              isCentered
            >
              <ModalOverlay />
              <DisablePatrimonyRequest
                onCloseModal={onCloseDisablePatrimonyRequestModal}
                patrimonyRequestId={patrimonyRequest.patrimony_request_id}
                patrimonyId={patrimonyRequest?.patrimony_id}
              />
            </Modal>
            <Button
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              size="xs"
              onClick={onOpenDisablePatrimonyRequestModal}
              mr={2}
            >
              Inutilizado
            </Button>
          </>
        )}

        {userCanActivatePatrimonyRequest && patrimonyRequest?.status === 'requested' && (
          <>
            <Modal
              isOpen={isActivatePatrimonyRequestModalOpen}
              onClose={onCloseActivatePatrimonyRequestModal}
            >
              <ModalOverlay />

              <Portal>
                <ActivateRequestedPatrimony
                  patrimonyType={patrimonyRequest?.patrimonyType?.type}
                  patrimonyRequestId={patrimonyRequest?.id}
                  onClose={onCloseActivatePatrimonyRequestModal}
                />
              </Portal>

            </Modal>

            <Button
              leftIcon={<FaArrowRight />}
              lineHeight="1"
              size="xs"
              onClick={onOpenActivatePatrimonyRequestModal}
            >
              Ativar
            </Button>
          </>
        )}
      </Td>
    </Tr>
  )
}

import { useToastify } from "hooks/toastify/useToastify"
import { useMutation, useQuery, useQueryClient } from "react-query"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text } from "@chakra-ui/react";
import { analyzeSupport } from "api/supports/analyzeSupport";

import { format } from "date-fns";
import { getSupport } from "api/supports/getSupport";
import { useEffect } from "react";

interface AnalyzeSupportProps {
  supportId: string
}

interface AnalyzeSupportSchema {
  deadline: string
}

const analyzeSupportSchema = yup.object({
  deadline: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    value = new Date(originalValue).toISOString()

    return value
  }),
})


export function AnalyzeSupport({
  supportId
}: AnalyzeSupportProps) {

  const {
    data: supportResult,
    isLoading: isLoadingSupportResult
  } = useQuery({
    queryKey: ['support', supportId],
    queryFn: () => getSupport({ supportId }),
    refetchOnWindowFocus: false,
  })


  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()


  const {
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AnalyzeSupportSchema>({
    resolver: yupResolver(analyzeSupportSchema)
  })

  useEffect(() => {
    if (supportResult) {
      setValue('deadline', format(new Date(supportResult.support.deadline), "yyyy-MM-dd'T'HH:mm"))
    }
  }, [supportResult, setValue])

  const { mutateAsync: analyzeSupportFn } = useMutation({
    mutationFn: analyzeSupport,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'analyzing-supports' })
      queryClient.invalidateQueries({ queryKey: 'supporting-supports' })
      queryClient.invalidateQueries({ queryKey: ['support', supportId] })
    }
  })

  async function handleAnalyzeSupport(values: AnalyzeSupportSchema) {
    await promiseMessage(analyzeSupportFn({
      body: {
        ...values
      },
      supportId
    }), 'Análise realizada!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleAnalyzeSupport)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'> Analisar atendimento</Heading>
      <Stack
        direction="column"
        w="full"
        mt="3"
      >
        <FormControl isInvalid={!!errors?.deadline}>
          <FormLabel fontSize="sm">
            Prazo para resposta
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('deadline')}
            name="deadline"
            type="datetime-local"
            size="sm"
            rounded="md"
          />
        </FormControl>
      </Stack>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>

  )
}

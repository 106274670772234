import axios from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { CustomerAccountCsvResponse } from "./dtos/CustomerAccountCsv";

interface CustomerAccountCsvQueryParams {
  is_business: string
  customer_id: string
  start_filter: string
  end_filter: string
}

type QueryOptions = UseQueryOptions<CustomerAccountCsvResponse, unknown, CustomerAccountCsvResponse, QueryKey>

interface UseCustomerAccountCsvOptions {
  queryOptions?: QueryOptions
  queryParams?: CustomerAccountCsvQueryParams
}

async function generateCustomerAccountCsvReqFunction({
  is_business,
  customer_id,
  start_filter,
  end_filter,
}: CustomerAccountCsvQueryParams) {
  try {
    const { data } = await api.get<CustomerAccountCsvResponse>(`/accounts/customer/csv`, {
      params: {
        is_business,
        customer_id,
        start_filter,
        end_filter,
      },
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useCustomerAccountCsv(options?: UseCustomerAccountCsvOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery([
    "customerAccountCsv",
    { ...options?.queryParams }
  ], () => generateCustomerAccountCsvReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}

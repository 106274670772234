import { HStack, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { SupportSubject } from "api/supports/_types/SupportSubject";
import { useAuth } from "hooks/auth/useAuth";
import { FaSearch } from "react-icons/fa";
import { FaPen } from "react-icons/fa6";
import { EditSupportSubjectModal } from "./EditSupportSubjectModal";
import { SupportSubjectDetail } from "./SupportSubjectDetail";

interface SupportSubjectsTableRowProps {
  supportSubject: SupportSubject
}

export function SupportSubjectsTableRow({
  supportSubject
}: SupportSubjectsTableRowProps) {

  const { userLogged } = useAuth()

  const doesUserCanEditSupportSubject = userLogged?.permissions.includes(
    'edit-support-subject'
  )

  const {
    isOpen: isSupportSubjectModalOpen,
    onOpen: onOpenSupportSubjectModal,
    onClose: onCloseSupportSubjectModal
  } = useDisclosure()

  const {
    isOpen: isEditSupportSubjectModalOpen,
    onOpen: onOpenEditSupportSubjectModal,
    onClose: onCloseEditSupportSubjectModal
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <HStack spacing={2}>
          <IconButton
            aria-label=""
            icon={<FaSearch />}
            size="sm"
            variant="ghost"
            onClick={onOpenSupportSubjectModal}
          />

          <Modal
            isOpen={isSupportSubjectModalOpen}
            onClose={onCloseSupportSubjectModal}
            size="2xl"
          >
            <ModalOverlay />

            <SupportSubjectDetail supportSubjectId={supportSubject.id} />

          </Modal>

          {doesUserCanEditSupportSubject && (
            <>
              <IconButton
                aria-label=""
                icon={<FaPen />}
                size="sm"
                variant="ghost"
                onClick={onOpenEditSupportSubjectModal}
              />

              <Modal
                isOpen={isEditSupportSubjectModalOpen}
                onClose={onCloseEditSupportSubjectModal}
                size="2xl"
              >
                <ModalOverlay />

                <EditSupportSubjectModal
                  onCloseModal={onCloseEditSupportSubjectModal}
                  supportSubjectId={supportSubject.id}
                />

              </Modal>
            </>
          )}

        </HStack>
      </Td>
      <Td>{supportSubject?.supportType}</Td>
      <Td>{supportSubject?.category ?? 'Todas'}</Td>
      <Td>{supportSubject?.name}</Td>
    </Tr>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface DismissalDocsInternCltBody {
  isTicketCardBlocked: string
  isRemovedOnTransfacilAndOtimo: string
  isConferredPointRegisters: string
  inactivations: string[]
  isWithCoParticipationDiscount: boolean
  coParticipationDiscountValueInCents: number
}

interface DismissalDocsInternCltProps {
  body: DismissalDocsInternCltBody
  internCltId: string
}

export async function dismissalComunicationInternClt({
  body,
  internCltId
}: DismissalDocsInternCltProps) {
  try {
    const response = await api.put(`/intern-clts/${internCltId}/dismissal-comunication`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { useQuery } from 'react-query'
import {
  getAllProfilesReqFunction,
  getOneProfileReqFunction,
} from '../../utils/RequestFunctions/Profile/requestProfileFunctions'
import { useAuth } from '../auth/useAuth'
import { useSwal } from '../swal/useSwal'

export function useProfile(id: string | null, refetchOnFocus?: boolean) {
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useSwal()

  const profiles = useQuery('profiles', getAllProfilesReqFunction, {
    enabled: id === null && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      errorMessage(error.message || 'Erro Inesperado!')
      if (error.status === 401) {
        handleLogout()
      }
    },
  })

  const profile = useQuery(
    ['profile', id],
    () => getOneProfileReqFunction(id !== null ? id : ''),
    {
      enabled: !!id && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.status === 401) {
          handleLogout()
        }
      },
    },
  )

  return {
    profiles,
    profile,
  }
}

import {
  getAllUsers,
  getOneUser,
} from '../../services/getFunctions/user/getUser'
import { useQuery } from 'react-query'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'

export function useUser(
  userId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const users = useQuery('users', getAllUsers, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const user = useQuery(
    ['user', userId],
    () => getOneUser(userId !== null ? userId : ''),
    {
      enabled: !!userId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    users,
    user,
  }
}

import { useEffect } from "react"
import * as yup from "yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"

import { useToastify } from "hooks/toastify/useToastify"
import { FormProvider, useForm } from "react-hook-form"
import { Link, useHistory, useParams } from "react-router-dom"

import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Flex, Spinner } from "@chakra-ui/react"

import { useAuth } from "hooks/auth/useAuth"
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions"
import { QuotePurchaseOrderForm, QuotePurchaseOrderFormInputs } from "./components/QuotePurchaseOrderForm"
import { validateHasFile } from "utils/imageValidation"
import { useQueryClient } from "react-query"
import { usePurchaseOrder } from "hooks/purchaseOrder/usePurchaseOrder"
import { usePurchaseOrdersSocket } from "hooks/purchaseOrder/usePurchaseOrdersSocket"
import { useLLMUsers } from "hooks/user/useLLMUsers"

interface ParamsProps {
  purchaseOrderId: string
}

const quotePurchaseOrderSchema = yup.object().shape({
  quoteType: yup.string().required('Campo obrigatório'),
  quoteProviders: yup.array().min(1, 'Campo obrigatório.').of(yup.object({
    providerName: yup.string().required('Campo obrigatório'),
    budgetAttachment: yup
      .mixed()
      .test('hasFile', 'Campo obrigatório', (value: FileList) => validateHasFile(value))
  })),
  responsibleForApproval: yup.string().required('Campo obrigatório')
})

const userTypes = [
  'COMERCIAL - GERÊNCIA',
  'ADMINISTRATIVO FINANCEIRO - GERÊNCIA',
  'ADMIN',
  'REGULAÇÃO E QUALIDADE - DIRETORIA',
  'COMERCIAL - DIRETORIA',
  'SUCESSO DO CLIENTE - DIRETORIA',
  'ADMINISTRATIVO FINANCEIRO - DIRETORIA'
];

export function QuotePurchaseOrder() {
  const { purchaseOrderId } = useParams<ParamsProps>()

  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const { push: redirect } = useHistory()

  const userHasPermissionToQuotePurchaseOrder = userLogged.permissions.includes('quote-purchase-order')

  useEffect(() => {
    if (!userHasPermissionToQuotePurchaseOrder) {
      redirect('/')
    }
  }, [userHasPermissionToQuotePurchaseOrder, redirect])


  const formMethods = useForm<QuotePurchaseOrderFormInputs>({
    resolver: yupResolver(quotePurchaseOrderSchema),
    defaultValues: {
      quoteProviders: []
    }
  })

  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const { data: purchaseOrderData, isFetching: isFetchingPurchaseOrderData } = usePurchaseOrder({ purchaseOrderId: purchaseOrderId, queryOptions: { enabled: !!purchaseOrderId } })

  const {
    data: llmUsersData,
    isFetching: isFetchingLlmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const usersOptions = llmUsersData?.users
    .filter(user => userTypes.includes(user?.user_type))
    .map((user) => {
      return {
        key: user.id,
        value: user.id,
        showOption: user.firstname + ' ' + user.lastname
      }
    })

  const {
    quotePurchaseOrder: {
      mutateAsync: quotePurchaseOrder
    }
  } = usePurchaseOrderFunctions()

  const queryClient = useQueryClient()

  const { purchaseOrderSocketConnection } = usePurchaseOrdersSocket()

  async function handleCreatePurchaseOrder(values: QuotePurchaseOrderFormInputs) {
    const formData = new FormData()

    formData.append('quoteType', values.quoteType)
    formData.append('responsibleForApproval', values.responsibleForApproval)
    values.quoteProviders.forEach((quoteProvider, index) => {
      formData.append(`quoteProviders.${index}.providerName`, quoteProvider.providerName)
      formData.append(`quoteProviders.${index}.budgetAttachment`, quoteProvider.budgetAttachment[0])
    })

    const successMessage = "Cotação realizada com sucesso!"

    await promiseMessage(quotePurchaseOrder({
      purchaseOrderId,
      input: formData
    }, {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries(['purchaseOrdersAwaitingQuote'])
        redirect("/compras/pedidos/aguardando-cotacao")

        purchaseOrderSocketConnection.emit('purchaseOrderQuoted', {
          purchaseOrderProtocol: data.purchaseOrder.protocol,
          responsibleForApproval: data.purchaseOrder.responsible_for_approval,
        })
      }
    }), successMessage)
  }
  return (
    <StandardBackgroundForm
      title='Cotar Pedido de Compra'
      onSubmit={handleSubmit(handleCreatePurchaseOrder)}
    >
      {isFetchingPurchaseOrderData || isFetchingLlmUsersData ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <QuotePurchaseOrderForm
            purchaseOrder={purchaseOrderData.purchaseOrder}
            users={usersOptions} />
        </FormProvider>
      )}

      <Flex justify='flex-end' mt={4} gap={4}>
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/compras/pedidos/aguardando-cotacao'
        >
          Pedidos Aguardando Cotação
        </Button>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}

import { Box, FormControl, FormLabel, Text, Textarea, VStack } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { AttachmentTableRowField } from "pages/Support/AttachmentTableRowField";
import { FormEvent, useEffect, useReducer } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { CreateSupportSchema } from "../CreateSupport";
import { AttachmentState, SetAttachmentAction } from "../InternalSupportForm";
import { AccountChangeForm } from "./AccountChangeForm";
import { AuthorizedOvertimeRequestForm } from "./AuthorizedOvertimeRequestForm";
import { DocumentRequestForm } from "./DocumentRequestForm";
import { DocumentSubmissionForm } from "./DocumentSubmissionForm";
import { LLMEmployeePromotionForm } from "./LLMEmployeePromotionForm";
import { PaymentInquiryForm } from "./PaymentInquiryForm";
import { PayslipRequestForm } from "./PayslipRequestForm";
import { TimeTrackingAdjustmentForm } from "./TimeTrackingAdjustmentForm";


interface CreateHumanResourcesSupportProps {
  reducer: (state: AttachmentState[], action: SetAttachmentAction) => AttachmentState[];
}

export function CreateHumanResourcesSupport({ reducer }: CreateHumanResourcesSupportProps) {

  const { register, control, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()
  const [attachments, setAttachments] = useReducer(reducer, [] as AttachmentState[])

  useEffect(() => {
    if (attachments.length !== 0) {
      setValue(`formData.attachments.label`, 'Anexos');
      setValue(`formData.attachments.value`, JSON.stringify(attachments));
      setValue(`formData.attachments.type`, 'attachments');
    }
  }, [attachments, setValue])

  const {
    fields: attachmentsFields,
    append: appendAttachmentsField,
    remove: removeAttachmentsField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const [supportSubjectId, attachmentsSelected] = useWatch({
    control,
    name: ['subject', 'attachments']
  })


  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      description: 'Descreva o que precisa que seja verificado/criado'
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  const hasDescriptioInSubject = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de inclusão de benefício'.toLowerCase()
  const subjectIsPayslipRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de holerite/Informe de rendimentos'.toLowerCase()
  const subjectIsPaymentInquiry = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Dúvidas sobre o pagamento (salário ou benefícios)'.toLowerCase()
  const subjectIsDocumentSubmission = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Envio de atestados/documentos'.toLowerCase()
  const subjectIsTimeTrackingAdjustment = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Ajustes ou problemas no ponto'.toLowerCase()
  const subjectIsAccountChange = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Alteração de conta'.toLowerCase()
  const subjectIsLLMEmployeePromotion = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Promoção de colaborador LLM'.toLowerCase()
  const subjectIsAuthorizedOvertimeRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de HE autorizada'.toLowerCase()
  const subjectIsDocumentRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de documento'.toLowerCase()

  return (
    <Box w='full'>

      {hasDescriptioInSubject && (
        <FormControl isInvalid={!!errors.formData?.description} mt={3}>
          <FormLabel fontSize="sm">
            Descrição
          </FormLabel>
          <Textarea
            {...register('formData.description.value')}
            name='formData.description.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('description')}
          />
        </FormControl>
      )}
      {subjectIsPayslipRequest && (
        <PayslipRequestForm />
      )}

      {subjectIsPaymentInquiry && (
        <PaymentInquiryForm />
      )}

      {subjectIsDocumentSubmission && (
        <DocumentSubmissionForm />
      )}

      {subjectIsTimeTrackingAdjustment && (
        <TimeTrackingAdjustmentForm />
      )}

      {subjectIsAccountChange && (
        <AccountChangeForm />
      )}

      {subjectIsLLMEmployeePromotion && (
        <LLMEmployeePromotionForm />
      )}

      {subjectIsDocumentRequest && (
        <DocumentRequestForm />
      )}

      {subjectIsAuthorizedOvertimeRequest && (
        <AuthorizedOvertimeRequestForm />
      )}

      {subjectIsDocumentSubmission && (
        <>
          <FormLabel fontSize='sm' mt={3}>
            Anexo(s)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <VStack spacing={3}>
            {attachmentsFields.map((field, index) => {

              const isFilledField = Boolean(attachmentsSelected[index]?.file?.length);
              return (
                <AttachmentTableRowField
                  key={field.id}
                  index={index}
                  onSetAttachmentId={setAttachments}
                  isFilledField={isFilledField}
                  onAppendField={() => appendAttachmentsField({})}
                  onRemoveField={() => removeAttachmentsField(index)} />
              );
            })}
          </VStack>
        </>
      )}
    </Box>
  )
}

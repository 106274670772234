import axios from "axios"
import { StockProps } from "contexts/StockContext"
import { User } from "hooks/user/dtos/User"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"


interface GetStockLogsParams {
  stockId: string
  pageSize: string
  currentPage: string
}

type LogDetail = {
  [Property in keyof StockProps]: {
    prev_value: StockProps[Property]
    new_value: StockProps[Property]
  }
}

export interface StockLogs {
  id: string
  log_detail: LogDetail
  created_at: string
  updated_at: string
  createdAt: string
  updatedAt: string
  stock_id: string
  updated_by: string
  updatedBy: Pick<User, 'id' | 'firstname' | 'lastname'>
}

export interface GetStockLogsResponse {
  stockLogs: StockLogs[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getStockLogs({
  stockId,
  currentPage,
  pageSize
}: GetStockLogsParams) {
  try {
    const { data } = await api.get<GetStockLogsResponse>(
      `/stocks/${stockId}/logs`, {
      params: {
        pageSize,
        currentPage
      }
    }
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Accordion, Box, Flex, Stack, useMediaQuery } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ListButton } from '../../../components/Buttons/ListButton'
import { Input } from '../../../components/Inputs/Input'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'

import { useBusinessBudget } from '../../../hooks/businessBudget/useBusinessBudget'
import { useCity } from '../../../hooks/city/useCity'
import { useCollector } from '../../../hooks/collector/useCollector'
import { useService } from '../../../hooks/services/service'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { IAddressesScheduleProps } from '../../../utils/RequestFunctions/Service/RequestedBusiness/requestRequestedBusinessServiceFunctions'
import { CollectInfo } from './components/Accordions/CollectInfo'
import { ScheduleInfo } from './components/Accordions/ScheduleInfo'
import { useProviders } from 'hooks/provider/useProviders'

interface IParamsProps {
  id: string
}

export interface ICollectionsInfoProps {
  id: string;
  trading_name: string;
  arrival_location?: string;
  departure_location?: string;
  unsuccess_location?: string;
  branch?: string;
  street: string;
  number: string;
  neighborhood: string;
  cep: string;
  city: string;
  state: string;
  complement: string;
  reference_point?: string;
  responsible_name?: string;
  planned_hour: string;
  real_hour: string
  observation: string
  is_provider: boolean
}

export function DetailBusinessService() {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')
  const { id }: IParamsProps = useParams()

  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(id, false, false)
  const {
    cities: { data: cities, isLoading: isCityLoading },
  } = useCity(null, true, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const { data: providers, isLoading: isProvidersLoading } = useProviders();
  const { data: collector, isLoading: isCollectorLoading } = useCollector(
    service?.serviceIDRequestedBusiness.source_collector_id
  )
  const {
    businessBudget: {
      data: businessBudget,
      isLoading: isBusinessBudgetLoading,
    },
  } = useBusinessBudget(service?.serviceIDRequestedBusiness.business_budget_id)

  const businessBudgetSourceCities = useMemo<string[] | []>(() => {
    if (cities && businessBudget) {
      return Array.from(
        new Set(
          cities
            .filter((city) => businessBudget.source_cities.includes(city.id))
            .map((city) => city.name),
        ),
      )
    }
    return []
  }, [cities, businessBudget])

  const businessBudgetDestinationCities = useMemo<string[] | []>(() => {
    if (cities && businessBudget) {
      return Array.from(
        new Set(
          cities
            .filter((city) =>
              businessBudget.destination_cities.includes(city.id),
            )
            .map((city) => city.name),
        ),
      )
    }
    return []
  }, [cities, businessBudget])

  const successCollections = service?.serviceIDCollect
    .filter(collect => collect.step === "DONE")
    .map(collect => {
      if (collect.provider_id) {
        return { ...collect, isProvider: true }
      } else {
        return { ...collect, isProvider: false }
      }
    })

  const unsuccessCollections = service?.serviceIDCollect
    .filter(collect => collect.step === "UNSUCCESS")
    .map(collect => {
      if (collect.provider_id) {
        return { ...collect, isProvider: true }
      } else {
        return { ...collect, isProvider: false }
      }
    })

  const sourceAddressesSchedule: IAddressesScheduleProps[] | null = service?.serviceIDRequestedBusiness.source_addresses_schedule
    ? service?.serviceIDRequestedBusiness.source_addresses_schedule.map(schedule => JSON.parse(schedule))
    : null

  const successCollectedAddresses = successCollections?.reduce((acc, curr) => {
    if (curr.isProvider) {
      providers?.filter(provider => provider.id === curr.provider_id).map(provider => {
        return acc.push({
          id: provider.id,
          trading_name: provider.trading_name,
          arrival_location: `${curr.arrival_latitude}, ${curr.arrival_longitude}`,
          departure_location: `${curr.departure_latitude}, ${curr.departure_longitude}`,
          street: provider.street,
          number: provider.number,
          neighborhood: provider.neighborhood,
          cep: provider.cep,
          city: provider.city,
          state: provider.state,
          planned_hour: '-',
          real_hour: formatDate.handle(curr.departure_timestamp, "DateOnlyWithHourMinute"),
          complement: provider.complement,
          observation: curr.observation ?? '-',
          is_provider: true,
        })
      })
    } else {
      addresses?.filter(address => address.id === curr.address_id).map(address => {
        const plannedHour = sourceAddressesSchedule
          ? sourceAddressesSchedule.find(schedule => schedule.address_id === address.id)?.planned_hour
          : null

        return acc.push({
          id: address.id,
          trading_name: address.trading_name,
          arrival_location: `${curr.arrival_latitude}, ${curr.arrival_longitude}`,
          departure_location: `${curr.departure_latitude}, ${curr.departure_longitude}`,
          branch: address.branch,
          street: address.street,
          number: address.number,
          neighborhood: address.neighborhood,
          cep: address.cep,
          city: address.cityIDAddress.name,
          state: address.state,
          planned_hour: formatDate.handle(plannedHour as string, "DateOnlyWithHourMinute"),
          real_hour: formatDate.handle(curr.departure_timestamp, "DateOnlyWithHourMinute"),
          complement: address.complement,
          reference_point: address.reference_point,
          responsible_name: address.responsible_name,
          observation: curr.observation ?? '-',
          is_provider: false
        })
      })
    }

    return acc
  }, [] as Array<ICollectionsInfoProps>)

  const unsuccessCollectedAddresses = unsuccessCollections?.reduce((acc, curr) => {
    if (curr.isProvider) {
      providers?.filter(provider => provider.id === curr.provider_id).map(provider => {
        return acc.push({
          id: provider.id,
          trading_name: provider.trading_name,
          arrival_location: `${curr.arrival_latitude}, ${curr.arrival_longitude}`,
          unsuccess_location: `${curr.unsuccess_latitude}, ${curr.unsuccess_longitude}`,
          street: provider.street,
          number: provider.number,
          neighborhood: provider.neighborhood,
          cep: provider.cep,
          city: provider.city,
          state: provider.state,
          planned_hour: '-',
          real_hour: formatDate.handle(curr.unsuccess_timestamp, "DateOnlyWithHourMinute"),
          complement: provider.complement,
          observation: curr.observation ?? '-',
          is_provider: true
        })
      })
    } else {
      addresses?.filter(address => address.id === curr.address_id).map(address => {
        const plannedHour = sourceAddressesSchedule
          ? sourceAddressesSchedule.find(schedule => schedule.address_id === address.id)?.planned_hour
          : null

        return acc.push({
          id: address.id,
          trading_name: address.trading_name,
          arrival_location: `${curr.arrival_latitude}, ${curr.arrival_longitude}`,
          unsuccess_location: `${curr.unsuccess_latitude}, ${curr.unsuccess_longitude}`,
          branch: address.branch,
          street: address.street,
          number: address.number,
          neighborhood: address.neighborhood,
          cep: address.cep,
          city: address.cityIDAddress.name,
          state: address.state,
          planned_hour: formatDate.handle(plannedHour as string, "DateOnlyWithHourMinute"),
          real_hour: formatDate.handle(curr.unsuccess_timestamp, "DateOnlyWithHourMinute"),
          complement: address.complement,
          reference_point: address.reference_point,
          observation: curr.observation ?? '-',
          responsible_name: address.responsible_name,
          is_provider: false
        })
      })
    }

    return acc
  }, [] as Array<ICollectionsInfoProps>)

  const isCollectionsAccordionLoading = isAddressesLoading || isProvidersLoading

  if (
    isServiceLoading ||
    isBusinessBudgetLoading ||
    isCityLoading ||
    isCollectorLoading
  ) {
    return <GeneralContentLoading />
  }

  return (

      <Box flex="1" borderRadius="8px" bg="white" p={['6', '8']}>
        {service && (
          <>
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name="protocol"
                  defaultValue={service.protocol}
                  label="Protocolo"
                  isDisabled={true}
                />
                <Input
                  name="customer"
                  defaultValue={service.customerIDService.trading_firstname}
                  label="Cliente"
                  isDisabled={true}
                />
              </Stack>

              <Input
                name="vehicle"
                defaultValue={service.serviceIDRequestedBusiness.vehicle}
                label="Veículo"
                isDisabled={true}
              />
            </Stack>
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Input
                name="routeStartDate"
                defaultValue={formatDate.handle(
                  service.serviceIDRequestedBusiness.route_start_date,
                  'DateWithoutHourToInput',
                )}
                label="Data Inicial da Rota"
                type="date"
                isDisabled={true}
              />
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name="routeStartHour"
                  defaultValue={formatDate.handle(
                    service.serviceIDRequestedBusiness.route_start_date,
                    'DateWithoutHourToInput',
                  )}
                  label="Horário Inicial da Rota"
                  type="date"
                  isDisabled={true}
                />

                <Input
                  name="routeEndHour"
                  defaultValue={formatDate.handle(
                    service.serviceIDRequestedBusiness.route_end_hour,
                    'DateWithoutHourToInput',
                  )}
                  label="Horário Final da Rota"
                  type="date"
                  isDisabled={true}
                />
              </Stack>
            </Stack>
            <Accordion allowMultiple mt="4">
              <ScheduleInfo
                service={service}
                collectorTradingName={collector!.trading_name}
                businessBudgetSourceCities={businessBudgetSourceCities}
                businessBudgetDestinationCities={
                  businessBudgetDestinationCities
                }
              />
              <CollectInfo
                successAddressesCollected={successCollectedAddresses!}
                unsuccessAddressesCollected={unsuccessCollectedAddresses!}
                isLoading={isCollectionsAccordionLoading}
              />
            </Accordion>
          </>
        )}

        <Flex
          mt="4"
          columnGap={2}
          rowGap={10}
          flex="1"
          justifyContent="flex-end"
        >
          <ListButton
            customStyle={isWideVersion ? undefined : { width: '100%' }}
            href="/servicos-solicitados-business"
            name="Serviços business"
          />
        </Flex>
      </Box>

  )
}

import { usePagination } from '@ajna/pagination'
import {
  Box, Button, Flex, Icon, Stack, Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { BudgetProps } from 'contexts/BudgetContext'
import { Address } from 'hooks/address/dtos/Address'
import { ServiceInternComunicationModal } from 'pages/Services/Detail/components/Modal/ServiceInternComunicationModal'
import { Fragment, useContext, useEffect, useReducer, useState } from 'react'
import { FaCommentMedical, FaUndoAlt } from 'react-icons/fa'
import { FiCheckCircle, FiEdit3, FiEye } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../../../contexts/AuthContext'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { useFilterOptions } from '../../../../hooks/filterOptions/useFilterOptions'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { serviceFormatDateToFrontTable } from '../../../../utils/ServiceFunctions/serviceFormatDateToFrontTable'
import { serviceFormatHourToFront } from '../../../../utils/ServiceFunctions/serviceFormatHourToFront'
import { serviceSortByDate } from '../../../../utils/ServiceFunctions/serviceSortByDate'
import { serviceSortByHour } from '../../../../utils/ServiceFunctions/serviceSortByHourt'
import { EmptyContentTableAlert } from '../../../Alerts/EmptyContentTableAlert'
import { TableFilterButton } from '../../../Filters/TableFilterButton'
import { Pagination } from '../../../Pagination/Pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'

interface RequestedServiceTableProps {
  budgets?: BudgetProps[]
  services?: ServiceProps[]
  addresses?: Address[]
  refetch: () => void
}

const requestedServiceOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'collectDate', value: 'Data da Coleta', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'sourceCity', value: 'Cidade de Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade de Destino', checked: false },
  { key: 'modal', value: 'Modal', checked: false },
  { key: 'serviceType', value: 'Tipo de Serviço', checked: false },
  { key: 'geloSeco', value: 'Gelo Seco', checked: false },
  {
    key: 'collectHourStart',
    value: 'Horário Inicial da Coleta',
    checked: false,
  },
  { key: 'collectHourEnd', value: 'Horário Final da Coleta', checked: false },
  { key: 'deliveryDate', value: 'Data da Entrega', checked: false },
  { key: 'deliveryHour', value: 'Horário da Entrega', checked: false },
]

const requestedServiceOrderOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'collectDate', value: 'Data da Coleta', checked: false },
  { key: 'collectHourEnd', value: 'Horário Final Coleta', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

interface ActionPayload {
  serviceId: string
  budgetHubs: string[]
}

interface Action {
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  return {
    ...action.payload
  }
}

export function RequestedServiceTable({
  services,
  addresses,
  refetch,
  budgets
}: RequestedServiceTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [serviceInternComunicationState, dispatch] = useReducer(reducer, {
    serviceId: '',
    budgetHubs: []
  })

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [sourceCityFiltered, setCitySourceFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [modalFiltered, setModalFiltered] = useState('')
  const [serviceTypeFiltered, setServiceTypeFiltered] = useState('')
  const [collectDateFiltered, setCollectDateFiltered] = useState('')
  const [collectHourStartFiltered, setCollectHourStartFiltered] = useState('')
  const [collectHourEndFiltered, setCollectHourEndFiltered] = useState('')
  const [deliveryDateFiltered, setDeliveryDateFiltered] = useState('')
  const [deliveryHourFiltered, setDeliveryHourFiltered] = useState('')
  const [geloSecoFiltered, setGeloSecoFiltered] = useState('')

  const { userLogged } = useContext(AuthContext)
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()

  const isUserLoggedCustomer = userLogged?.user_type === 'CLIENTE'

  const isUserLoggedRecipient = userLogged?.user_type === 'REMETENTE'

  const isUserLoggedCollector = userLogged?.user_type === 'COLETADOR'

  console.log(services?.filter(service => service.serviceIDRequested === null))

  const requestedServicesByUserType = isUserLoggedCustomer
    ? services
      ?.filter(service =>
        service.step === 'toValidateRequestedService' &&
        service.customerIDService.id === userLogged.customer_id
      )
    : isUserLoggedRecipient
      ? services?.filter(service => service.step === 'toValidateRequestedService' && service.serviceIDRequested.owner === userLogged?.id)
      : isUserLoggedCollector
        ? services
          ?.filter(service =>
            (service.serviceIDRequested.source_collector_id === userLogged.collector_id ||
              service.serviceIDRequested.destination_collector_id === userLogged.collector_id) &&
            service.step === 'toValidateRequestedService'
          )
        : services?.filter(service => service.step === 'toValidateRequestedService')

  const searchedRequestedServices = requestedServicesByUserType?.filter(service => {
    const protocolFilter = searchBoxFilter(
      String(service.protocol),
      protocolFiltered
    )

    const customerFilter = searchBoxFilter(
      service.customerIDService.trading_firstname,
      customerFiltered,
    )
    const deliveryDateFilter = searchBoxFilter(
      serviceFormatDateToFrontTable(
        service.serviceIDRequested.delivery_date,
      ),
      deliveryDateFiltered,
    )
    const collectDateFilter = searchBoxFilter(
      serviceFormatDateToFrontTable(
        service.serviceIDRequested.collect_date,
      ),
      collectDateFiltered,
    )
    const collectHourStartFilter = searchBoxFilter(
      serviceFormatHourToFront(
        service.serviceIDRequested.collect_hour_start,
      ),
      collectHourStartFiltered,
    )
    const collectHourEndFilter = searchBoxFilter(
      serviceFormatHourToFront(service.serviceIDRequested.collect_hour_end),
      collectHourEndFiltered,
    )
    const deliveryHourFilter = searchBoxFilter(
      serviceFormatHourToFront(service.serviceIDRequested.delivery_date),
      deliveryHourFiltered,
    )
    const geloSecoFilter = searchBoxFilter(
      service.serviceIDRequested.gelo_seco.toString(),
      geloSecoFiltered,
    )
    const sourceCityFilter = searchBoxFilter(
      addresses!.filter(address =>
        service.serviceIDRequested.source_address_id.includes(address.id),
      )
        .map((address) => address.cityIDAddress.name)
        .join(', '),
      sourceCityFiltered,
    )
    const destinationCityFilter = searchBoxFilter(
      addresses!.filter((address) =>
        service.serviceIDRequested.destination_address_id.includes(
          address.id,
        ),
      )
        .map((address) => address.cityIDAddress.name)
        .join(', '),
      destinationCityFiltered,
    )
    const modalFilter = searchBoxFilter(
      service.serviceIDRequested.modal,
      modalFiltered,
    )
    const serviceTypeFilter = searchBoxFilter(
      service.serviceIDRequested.service_type,
      serviceTypeFiltered,
    )

    const hasSomeFilterActive = [
      customerFiltered,
      protocolFiltered,
      sourceCityFiltered,
      destinationCityFiltered,
      collectDateFiltered,
      deliveryDateFiltered,
      collectHourStartFiltered,
      collectHourEndFiltered,
      deliveryHourFiltered,
      geloSecoFiltered,
      modalFiltered,
      serviceTypeFiltered,
    ].some(value => value !== '')

    if (!hasSomeFilterActive) return service

    return (
      protocolFilter &&
      customerFilter &&
      deliveryDateFilter &&
      sourceCityFilter &&
      destinationCityFilter &&
      collectDateFilter &&
      collectHourStartFilter &&
      collectHourEndFilter &&
      deliveryHourFilter &&
      geloSecoFilter &&
      modalFilter &&
      serviceTypeFilter
    )
  })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedRequestedServices?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(requestedServiceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(requestedServiceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  const hasSomeOrderOptionSelected = orderOptionSelected.length;

  const searchedRequestedServicesOrdered = hasSomeOrderOptionSelected
    ? searchedRequestedServices?.sort(
      (a, b) =>
        (orderOptionSelected.includes('protocol') &&
          a.protocol - b.protocol) ||
        (orderOptionSelected.includes('collectDate') &&
          serviceSortByDate(
            a.serviceIDRequested.collect_date,
            b.serviceIDRequested.collect_date,
          )) ||
        (orderOptionSelected.includes('collectHourEnd') &&
          serviceSortByHour(
            a.serviceIDRequested.collect_hour_end,
            b.serviceIDRequested.collect_hour_end,
          )) ||
        0,
    )
    : searchedRequestedServices?.sort(
      (dateA, dateB) =>
        serviceSortByDate(
          dateA.serviceIDRequested.collect_date,
          dateB.serviceIDRequested.collect_date,
        ) ||
        serviceSortByHour(
          dateA.serviceIDRequested.collect_hour_end,
          dateB.serviceIDRequested.collect_hour_end,
        ),
    )

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleRefetchData = () => {
    return refetch()
  }

  function handleChangeSetter(key: string, value: string) {
    switch (key) {
      case 'protocol':
        return setProtocolFiltered(value)
      case 'customer':
        return setCustomerFiltered(value)
      case 'deliveryDate':
        return setDeliveryDateFiltered(value)
      case 'deliveryHour':
        return setDeliveryHourFiltered(value)
      case 'collectDate':
        return setCollectDateFiltered(value)
      case 'collectHourStart':
        return setCollectHourStartFiltered(value)
      case 'collectHourEnd':
        return setCollectHourEndFiltered(value)
      case 'geloSeco':
        return setGeloSecoFiltered(value)
      case 'sourceCity':
        return setCitySourceFiltered(value)
      case 'destinationCity':
        return setDestinationCityFiltered(value)
      case 'modal':
        return setModalFiltered(value)
      case 'serviceType':
        return setServiceTypeFiltered(value)
    }
  }

  const {
    isOpen: isServiceInternComunicationModalOpen,
    onOpen: onOpenServiceInternComunicationModal,
    onClose: onCloseServiceInternComunicationModal,
  } = useDisclosure()

  function handleOpenServiceInternComunicationModal(
    serviceId: string,
    budgetHubs: string[]
  ) {
    dispatch({
      payload: {
        serviceId,
        budgetHubs,
      }
    })
    onOpenServiceInternComunicationModal()
  }

  return (
    <>
      {!requestedServicesByUserType?.length ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <ServiceInternComunicationModal
            isOpen={isServiceInternComunicationModalOpen}
            onClose={onCloseServiceInternComunicationModal}
            serviceId={serviceInternComunicationState.serviceId}
            budgetHubs={serviceInternComunicationState.budgetHubs}
          />
          <Stack direction="row">
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchData}>
              <Icon as={FaUndoAlt} />{' '}
            </Button>
          </Stack>
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  {requestedServiceOptions.map(option => {
                    return (
                      <Th key={option.key} fontSize="12" color="blue.900">
                        {option.value.toUpperCase()}
                      </Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {searchedRequestedServicesOrdered
                  ?.slice(offset, offset + Number(itemLimit))
                  .map((service) => {
                    const sourceCities = Array.from(
                      new Set(addresses
                        ?.filter(address =>
                          service.serviceIDRequested.source_address_id.includes(
                            address.id,
                          ))
                        .map(address => address.cityIDAddress.name),
                      ),
                    ) ?? []
                      .join(', ')
                      .toUpperCase()

                    const destinationCities = Array.from(
                      new Set(addresses
                        ?.filter(address =>
                          service.serviceIDRequested.destination_address_id.includes(
                            address.id,
                          ))
                        .map(address => address.cityIDAddress.name),
                      ),
                    ) ?? []
                      .join(', ')
                      .toUpperCase()

                    const budget = budgets?.find(budget => {
                      return budget.id === service.serviceIDRequested.budget_id
                    })

                    const budgetHubs: string[] = []

                    if (budget) {
                      if (budget?.crossdocking_hub_id) {
                        budgetHubs.push(
                          budget?.source_hub_id,
                          budget?.crossdocking_hub_id,
                          budget?.destination_hub_id
                        )
                      } else {
                        budgetHubs.push(
                          budget?.source_hub_id,
                          budget?.destination_hub_id
                        )
                      }
                    }

                    const isServiceEnabledToValidation = service.billing
                    ? service.billing.received_at !== null
                    : true

                    return (
                      <Tr key={service.id}>
                        <Td isNumeric>
                          {userLogged !== null && (
                            <Flex
                              align='center'
                              justify='flex-end'
                              w='full'
                              gap={1}
                            >
                              {userLogged?.permissions.includes('create-intern-comunication') && (
                                <Button
                                  variant='ghost'
                                  onClick={() => {
                                    handleOpenServiceInternComunicationModal(
                                      service.id,
                                      budgetHubs
                                    )
                                  }}
                                >
                                  <Icon
                                    as={FaCommentMedical}
                                  />
                                </Button>
                              )}
                              {userLogged.permissions.includes(
                                'view-requested-service',
                              ) && (
                                  <Button
                                    variant='ghost'
                                    as={Link}
                                    to={`/servicos/solicitado/visualizar/${service.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEye}
                                      fontSize="20"
                                    />
                                  </Button>
                                )}
                              {userLogged.permissions.includes(
                                'edit-requested-service',
                              ) && (
                                  <Button
                                    variant='ghost'
                                    as={Link}
                                    to={`/servicos/solicitado/editar/${service.id}`}
                                  >
                                    <Icon
                                      as={FiEdit3}
                                      fontSize="20"
                                    />
                                  </Button>
                                )}
                              {userLogged.permissions.includes(
                                'valid-requested-service',
                              ) && isServiceEnabledToValidation && (
                                  <Button
                                    variant='ghost'
                                    as={Link}
                                    to={`/servico/validar/${service.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiCheckCircle}
                                      fontSize="20"
                                    />
                                  </Button>
                                )}
                            </Flex>
                          )}
                        </Td>
                        <Td fontSize="12">{service.protocol}</Td>
                        <Td fontSize="12">
                          {serviceFormatDateToFrontTable(
                            service.serviceIDRequested.collect_date,
                          )}
                        </Td>
                        <Td fontSize="12">
                          {service.customerIDService.trading_firstname.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {sourceCities}
                        </Td>
                        <Td fontSize="12">
                          {destinationCities}
                        </Td>
                        <Td fontSize="12">
                          {service.serviceIDRequested.modal.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {service.serviceIDRequested.service_type.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {service.serviceIDRequested.gelo_seco}
                        </Td>
                        <Td fontSize="12">
                          {serviceFormatHourToFront(
                            service.serviceIDRequested.collect_hour_start,
                          )}
                        </Td>
                        <Td fontSize="12">
                          {serviceFormatHourToFront(
                            service.serviceIDRequested.collect_hour_end,
                          )}
                        </Td>
                        <Td fontSize="12">
                          {serviceFormatDateToFrontTable(
                            service.serviceIDRequested.delivery_date,
                          )}
                        </Td>
                        <Td fontSize="12">
                          {serviceFormatHourToFront(
                            service.serviceIDRequested.delivery_hour,
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

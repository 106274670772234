
import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';


interface CreateTrainingLLmParams {
  body: FormData
}

export async function createTrainingLlmCsv({
  body
}: CreateTrainingLLmParams) {
  try {
    await api.post('/llm/trainings/csv', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

interface ExtraHoursCltIntercurrenceFormSchema {
  minutesQuantity: string
}

export function ExtraHoursCltIntercurrenceForm() {

  const {
    register,
    formState: {
      errors,
    }
  } = useFormContext<ExtraHoursCltIntercurrenceFormSchema>()

  return (
    <>
      <FormControl isInvalid={!!errors.minutesQuantity}>
        <FormLabel>
          Quantidade total de minutos
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('minutesQuantity')}
          type="number"
        />
      </FormControl>
    </>
  )
}

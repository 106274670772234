import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";


interface IErrorFallBackPageProps {
  error: Error | unknown;
  resetErrorBoundary: () => void;
}

export function ErrorFallbackPage({ error, resetErrorBoundary }: IErrorFallBackPageProps) {
  console.log(error)

  return (

      <Box
        flex="1"
        bg="white"
        p={['6', '8']}
      >
        <Flex align="center" justify="center" direction="column" gap="4">
          <Icon as={FaTimesCircle} fontSize="8xl" />
          <Text fontWeight="bold">Oops, ocorreu um erro durante o carregamento!</Text>
          <Text>Tente recarregar a página ou realizar o login novamente.</Text>
          <Text>Caso o problema persista, informe nossa equipe imediatamente!</Text>
          <Button colorScheme="blue" onClick={resetErrorBoundary} as={Link} to="/">Voltar à página inicial</Button>
          <Button onClick={resetErrorBoundary}>Tentar novamente</Button>
        </Flex>
      </Box>

  )
}

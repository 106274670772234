import { Button, FormControl, FormLabel, IconButton, Input, Link, Progress, Stack, Td, Tr } from "@chakra-ui/react";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { ChangeEvent } from "react";
import { DeepMap, FieldError, UseFormRegister } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaFileImport } from "react-icons/fa6";
import { AuditFieldSchema } from "./AuditField";

interface AuditFieldAttachmentFieldProps {
  fieldname: string
  label: string
  register: UseFormRegister<AuditFieldSchema>
  errors: DeepMap<AuditFieldSchema, FieldError>
  onSetAuditFieldAttachmentId: (key: string, attachmentId: string) => void
}

export function AuditFieldAttachmentField({
  fieldname,
  label,
  errors,
  register,
  onSetAuditFieldAttachmentId
}: AuditFieldAttachmentFieldProps) {
  const {
    mutation,
    uploadProggress
  } = useAttachmentMutation()

  async function handleUploadAuditFieldAttachment(
    event: ChangeEvent<HTMLInputElement>
  ) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    const data = await mutation.mutateAsync(formData)

    onSetAuditFieldAttachmentId(label, data.attachment.id)
  }



  return (
    <Tr>
      <Td>{label}</Td>
      <Td>
        <Stack
          direction="column"
          w="full"
        >
          <Button
            as={FormLabel}
            htmlFor={fieldname}
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={errors[fieldname] && '2px solid'}
            borderColor={errors[fieldname] && 'red.500'}
          >
            Anexar
          </Button>
          <FormControl isInvalid={errors[fieldname]}>
            <Input
              {...register(fieldname as keyof AuditFieldSchema)}
              name={fieldname}
              id={fieldname}
              type="file"
              hidden
              onChangeCapture={handleUploadAuditFieldAttachment}
            />
          </FormControl>
        </Stack>
      </Td>
      <Td fontSize="xs" w="200px">
        {uploadProggress > 0 && (
          <Progress size="sm" rounded="md" value={uploadProggress} />
        )}
      </Td>
      <Td fontSize="xs" isNumeric>
        {mutation.data && (
          <IconButton
            aria-label="Visualizar anexo"
            as={Link}
            size="sm"
            icon={<FaExternalLinkAlt />}
            href={mutation.data.attachment.link}
            isExternal
          />
        )}
      </Td>
    </Tr>
  )
}

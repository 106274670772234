import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

export function useGeolocation() {
  const [position, setPosition] = useState<GeolocationPosition>()
  const [error, setError] = useState<GeolocationPositionError | null | undefined>(null)

  function successCallback(position: GeolocationPosition) {
    setPosition(position)
  }

  function errorCallback(error: GeolocationPositionError | null | undefined) {
    if (error) {
      setError(error)
    }
  }

  const getPosition = useCallback(async () => {
    if (navigator.geolocation) {
      await new Promise((resolve, reject) => {
        resolve(navigator.geolocation.getCurrentPosition(successCallback, errorCallback))
      })
    } else {
      toast.error("Desculpe! O navegador utilizado não possui suporte à geolocalização.")
    }
  }, [])

  useEffect(() => {
    async function setPositionState() {
      await getPosition()
    }

    setPositionState()
  }, [getPosition])

  return { position, error }

}

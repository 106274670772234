import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  getAllBranches,
  getOneBranch,
} from '../../services/getFunctions/branch/getBranch'
import { useAuth } from '../auth/useAuth'

export function useBranch(
  branchId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useAuth()

  const branches = useQuery('branches', getAllBranches, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const branch = useQuery(
    ['branch', branchId],
    () => getOneBranch(branchId !== null ? branchId : ''),
    {
      enabled: !!branchId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    branches,
    branch,
  }
}

import { Box, Flex, Grid, GridItem, Heading, Skeleton } from "@chakra-ui/react";
import { getCollectorsUsersDashboardMetrics, GetCollectorsUsersDashboardMetricsResponse } from "api/metrics/getCollectorsUsersDashboardMetrics";

import { useAuth } from "hooks/auth/useAuth";
import { FaBoxOpen, FaClock, FaUsers } from "react-icons/fa";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { DashboardCard } from "./components/DashboardCard";

function switchMetricTitle(key: keyof GetCollectorsUsersDashboardMetricsResponse) {
  switch (key) {
    case 'delayedBoardServicesCount':
      return { title: 'Serviços Atrasados - Embarque', order: 4, isAlertCard: true, icon: FaClock, path: '/dashboard/embarques-atrasados' }
    case 'delayedLandingServicesCount':
      return { title: 'Serviços Atrasados - Desembarque', order: 4, isAlertCard: true, icon: FaClock, path: '/dashboard/coletador/desembarques-atrasados' }
    case 'minStocksCount':
      return { title: 'Estoque Mínimo de Insumos', order: 13, isAlertCard: true, icon: FaBoxOpen, path: '/dashboard/estoques-abaixo-do-minimo' }
    case 'driversCount':
      return { title: 'Motoristas', order: 14, icon: FaUsers }
    default:
      return { title: '-', order: Infinity, icon: FaClock, path: '/' }

  }
}

export function NewCollectorDashboard() {
  const { userLogged } = useAuth()

  const userLoggedName = captalize(`${userLogged.firstname} ${userLogged.lastname}`)

  const {
    data: llmUsersDashboardMetricsResult,
    isLoading: isLoadingLlmUsersDashboardMetricsResult
  } = useQuery({
    queryKey: ['dashboard-metrics-collectors'],
    queryFn: getCollectorsUsersDashboardMetrics
  })

  const metrics = llmUsersDashboardMetricsResult
    ? Object.entries(llmUsersDashboardMetricsResult)
      .map(([key, value]: [keyof GetCollectorsUsersDashboardMetricsResponse, number]) => {
        const metricInfo = switchMetricTitle(key)

        return {
          title: metricInfo.title,
          order: metricInfo.order,
          isAlertCard: metricInfo?.isAlertCard,
          icon: metricInfo.icon,
          path: metricInfo.path,
          count: value,
        }
      })
      .sort((a, b) => a.order - b.order)
    : []

  return (

      <Box
        p="6"
        rounded="md"
      >
        <Flex
          direction="column"
          gap="6"
        >
          <Heading letterSpacing="tight">Olá, {userLoggedName}</Heading>

          <Grid
            gap="6"
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          >

            {isLoadingLlmUsersDashboardMetricsResult ? (
              Array.from({ length: 15 }).map((_, index) => {
                return (
                  <GridItem key={index}>
                    <Skeleton rounded="xl" startColor="gray.300" endColor="gray.500" height="220px" w="full" />
                  </GridItem>
                )
              })
            ) : (
              metrics.map((metric, index) => {
                return (
                  <GridItem key={metric.title}>
                    <DashboardCard
                      title={metric.title}
                      count={metric.count}
                      isAlertCard={metric.isAlertCard}
                      icon={metric.icon}
                      path={metric.path}
                    />
                  </GridItem>
                )
              })
            )}

          </Grid>

        </Flex>
      </Box>

  )
}

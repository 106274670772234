import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Text } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { sendInternCltBankDetails } from "api/internClts/sendInternCltBankDetails";

interface SendInternCltBankDetailsProps {
  internCltId: string
}

export interface SendInternCltBankDetailsSchema {
  account: string
  agency: string
  bank: string
}

const sendInternCltBankDetailsSchema = yup.object({
  account: yup.string().required(),
  agency: yup.string().required(),
  bank: yup.string().required(),
})


export function SendInternCltBankDetails({ internCltId }: SendInternCltBankDetailsProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendInternCltBankDetailsSchema>({
    resolver: yupResolver(sendInternCltBankDetailsSchema),
    defaultValues: {
      bank: 'Itaú'
    }
  })

  const { mutateAsync: sendInternCltBankDetailsFn } = useMutation({
    mutationFn: sendInternCltBankDetails,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })

    }
  })



  async function handleSendInternCltBankDetails(values: SendInternCltBankDetailsSchema) {
    await promiseMessage(sendInternCltBankDetailsFn({
      body: values,
      internCltId
    }), 'Dados bancários enviados!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendInternCltBankDetails)}
    >
      <Heading letterSpacing="tight" size='sm'>Envio de dados bancários</Heading>
      <FormControl isInvalid={!!errors.bank} mt={3}>
        <FormLabel fontSize="sm">
          Banco
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('bank')}
          name="bank"
          size="sm"
          rounded="md"
          isDisabled
        />
      </FormControl>
      <FormControl isInvalid={!!errors.agency} mt={3}>
        <FormLabel fontSize="sm">
          Agência
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('agency')}
          name="agency"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.account} mt={3}>
        <FormLabel fontSize="sm">
          Conta
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('account')}
          name="account"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

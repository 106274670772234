import { Box, Flex, Heading, Image } from "@chakra-ui/react";

import { ReactNode } from "react";
import emptyContentImage from "../../assets/images/empty-content.svg"

interface RootProps {
  message?: string
  children: ReactNode
}

export function Root({ message, children }: RootProps) {
  return (

    <Box
      p="6"
      rounded="md"
      bg="white"
    >
      <Flex
        justify="center"
        align="center"
        direction="column"
        gap="6"
        textAlign="center"
      >
        <Heading
          fontSize="xl"
          letterSpacing="tight"
        >
          {message ?? 'Parece que não há nada por aqui!'}
        </Heading>
        <Image src={emptyContentImage} />
        {children}
      </Flex>
    </Box>

  )
}

import { useHistory, useParams } from 'react-router'
import { useContext, useState, useEffect } from 'react'
import swal from 'sweetalert'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { BudgetForm } from './BudgetForm'
import { budgetFormatDataToBack } from '../../utils/BudgetFunctions/budgetFormatDataToBack'
import { BudgetContext } from '../../contexts/BudgetContext'
import { AuthContext } from '../../contexts/AuthContext'

interface QueryParams {
  slug: string
  id: string
}

interface FormInputs {
  customer_id: string
  situation: string
  source_hub_id: string
  crossdocking_hub_id: string | null
  destination_hub_id: string
  source_cities: string[]
  destination_cities: string[]
  source_address_qty: number
  destination_address_qty: number
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  price: number
  price_kg_extra: number | null
  transfer_budget: number | null
  price_add_collect: number
  price_add_delivery: number
  price_unsuccessful_collect: number
  deadline_delivery: string
  days_service: string[]
  priority_budget: 'yes' | 'no'
  observation: string
  has_crossdocking: 'SIM' | 'NÃO'
  last_mile_budget: string
  shippingIds: string[]
  shipping_type: 'Próximo voo' | 'Econômico'
}

export function BudgetAddDetailEdit() {
  const [isLoading, setIsLoading] = useState(false)
  const { slug, id }: QueryParams = useParams()

  const { createBudget, editBudget } = useContext(BudgetContext)
  const { userLogged } = useContext(AuthContext)

  const history = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-budget')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-budget')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-budget')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateBudget(values: FormInputs) {
    await swal({
      title: 'Deseja adicionar um orçamento?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willCreate) => {
      if (willCreate) {
        const formatedData = budgetFormatDataToBack(values)
        const response = await createBudget(formatedData)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/orcamentos')
          }, 1500)
        }
      } else {
        return swal('Criação cancelada com êxito!')
      }
    })
  }

  async function handleEditBudget(values: FormInputs) {
    await swal({
      title: 'Deseja editar um orçamento?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        const formatedData = budgetFormatDataToBack(values)

        const response = await editBudget(id, formatedData)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/orcamentos')
          }, 1500)
        }
      } else {
        return swal('Edição cancelada com êxito!')
      }
    })
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <BudgetForm
        slug={slug}
        id={id}
        href=""
        submit={handleCreateBudget}
        title="Adicionar Orçamento"
        action="Salvar"
      />
    )
  } else if (slug === 'editar') {
    return (
      <BudgetForm
        slug={slug}
        id={id}
        title="Editar Orçamento"
        action="Salvar"
        submit={handleEditBudget}
        href=""
      />
    )
  } else {
    return (
      <BudgetForm
        slug={slug}
        id={id}
        href={`/orcamento/editar/${id}`}
        submit={async () => {}}
        title="Visualizar Orçamento"
        isDisabled={true}
        action="Editar"
      />
    )
  }
}

import { Box, Flex } from "@chakra-ui/react";
import { DispatchStockStepTypes } from "api/dispachStocks/dispatchStock";
import { dispatchStockStepMap } from "utils/dispatchStockMappers";

interface DispatchStockStepProps {
  step: DispatchStockStepTypes
}

export function DispatchStockStep({ step }: DispatchStockStepProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {step !== 'dispatched' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {step === 'dispatched' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {dispatchStockStepMap[step]}
    </Flex>
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetDeliveryServicesRelationResponse {
  services: {
    id: string
    protocol: number,
    deliveryDate: string
    deliveryHour: string
    sourceCollector: {
      id: string
      tradingName: string
    }
    destinationCollector: {
      id: string
      tradingName: string
    }
    destinationAddresses: {
      id: string
      street: string
      neighborhood: string
      number: number
      cep: string
      branch: string
      tradingName: string
      responsibleName: string
      city: {
        id: string
        name: string
      }
      hub: {
        id: string
        name: string
        timezone: string
      }
      isDelivered: boolean
    }[]
    sourceAddresses: {
      id: string
      street: string
      neighborhood: string
      number: number
      cep: string
      branch: string
      tradingName: string
      responsibleName: string
      city: {
        id: string
        name: string
      }
      hub: {
        id: string
        name: string
        timezone: string
      }
    }[]
    customer: {
      id: string
      tradingFirstname: string
    }
    sourceBranch: {
      id: string
      nickname: string
      shipping: {
        id: string
        companyName: string
      }
    } | null
    destinationBranch: {
      id: string
      nickname: string
      shipping: {
        id: string
        companyName: string
      }
    } | null
    boardService: {
      id: string
      cte: string
      boardVolume: number
      operationalNumber: string
      trackerLink: string | null
    } | null
    vehicle: string
    cte: number
    tracker: number
    step: string
    hasOccurrence: boolean
    serviceType: string
  }[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}

interface GetInDeliveryServicesQueryParams {
  pageSize: string
  currentPage: string
  protocol?: string | null
  customer?: string | null
  sourceAddress?: string | null
  destinationAddress?: string | null
  deliveryDate?: string | null
  deliveryHour?: string | null
  withdrawInfo?: string | null
  timezoneOffset: string
  steps: string
  sourceCollector?: string | null
  destinationCollector?: string | null
  collectorId?: string
}

export async function getDeliveryServicesRelation({
  currentPage,
  pageSize,
  protocol,
  customer,
  sourceAddress,
  destinationAddress,
  deliveryDate,
  deliveryHour,
  withdrawInfo,
  timezoneOffset,
  steps,
  sourceCollector,
  destinationCollector,
  collectorId
}: GetInDeliveryServicesQueryParams) {
  try {
    const { data } = await api.get<GetDeliveryServicesRelationResponse>('/services/relations/deliveries', {
      params: {
        currentPage,
        pageSize,
        protocol,
        customer,
        sourceAddress,
        destinationAddress,
        timezoneOffset,
        deliveryDate,
        deliveryHour,
        withdrawInfo,
        steps,
        sourceCollector,
        destinationCollector,
        collectorId
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

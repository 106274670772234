import { timezoneList } from "pages/Hub/HubForm";

export function getTimezoneNameByValue(value: string): string | null {
  for (const key in timezoneList) {
    if (timezoneList.hasOwnProperty(key)) {
      if (timezoneList[key].value === value) {
        return timezoneList[key].name;
      }
    }
  }
  return null;
}

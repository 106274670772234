import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text, VStack } from "@chakra-ui/react"
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverDismissDocumentation } from "api/cltDrivers/sendCltDriverDismissDocumentation";
import { TerminationDocumentTableRowField } from "./TerminationDocumentTableRowField";
import { useReducer } from "react";

interface SendCltDriverDismissDocumentationProps {
  cltDriverId: string
}

export interface SendCltDriverDismissDocumentationSchema {
  doesReceivedDismissionDocumentsFromAccounting: string
  hasCheckedPeriodFuelAndRentalBalance: string
  hasCheckedGrrf: string
  doesOperationLeaderKnownAboutDismiss: string
  terminationDocuments: {
    file: FileList
  }[]
  paymentDate: string
}

const sendCltDriverDismissDocumentationSchema = yup.object({
  doesReceivedDismissionDocumentsFromAccounting: yup.string().required(),
  hasCheckedPeriodFuelAndRentalBalance: yup.string().required(),
  hasCheckedGrrf: yup.string().required(),
  doesOperationLeaderKnownAboutDismiss: yup.string().required(),
  terminationDocuments: yup.array().min(1),
  paymentDate: yup.string().required()
})

interface SetTerminationDocumentAttachmentIdActionPayload {
  attachmentId: string
}

export interface SetTerminationDocumentAttachmentIdAction {
  type: 'ADD' | 'DELETE'
  payload: SetTerminationDocumentAttachmentIdActionPayload
}

function reducer(state: string[], action: SetTerminationDocumentAttachmentIdAction) {
  if (action.type === 'ADD') {
    state.push(action.payload.attachmentId)
  }

  if (action.type === 'DELETE') {
    const attachmentIndex = state.findIndex(attachmentId => attachmentId === action.payload.attachmentId)

    state.splice(attachmentIndex, 1)
  }


  return state
}

export function SendCltDriverDismissDocumentation({ cltDriverId }: SendCltDriverDismissDocumentationProps) {
  const [terminationDocumentsAttachmentsIds, setTerminationDocumentAttachmentId] = useReducer(reducer, [])

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const formMethods = useForm<SendCltDriverDismissDocumentationSchema>({
    resolver: yupResolver(sendCltDriverDismissDocumentationSchema),
    defaultValues: {
      terminationDocuments: [{ file: undefined }]
    }
  })

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = formMethods

  const {
    fields: terminationDocumentsFields,
    append: appendTerminationDocumentField,
    remove: removeTerminationDocumentField,
  } = useFieldArray({
    control,
    name: 'terminationDocuments'
  })

  const terminationDocuments = useWatch({
    control,
    name: 'terminationDocuments'
  })


  const { mutateAsync: sendCltDriverDismissDocumentationFn } = useMutation({
    mutationFn: sendCltDriverDismissDocumentation,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverDismissDocumentation(values: SendCltDriverDismissDocumentationSchema) {
    await promiseMessage(sendCltDriverDismissDocumentationFn({
      body: {
        doesOperationLeaderKnownAboutDismiss: values.doesOperationLeaderKnownAboutDismiss === 'yes',
        doesReceivedDismissionDocumentsFromAccounting: values.doesReceivedDismissionDocumentsFromAccounting === 'yes',
        hasCheckedGrrf: values.hasCheckedGrrf === 'yes',
        hasCheckedPeriodFuelAndRentalBalance: values.hasCheckedPeriodFuelAndRentalBalance === 'yes',
        paymentDate: new Date(`${values.paymentDate} 12:00`),
        terminationDocumentsAttachmentsIds
      }, cltDriverId
    }), 'Informações de Aviso prévio em andamento enviadas!')
  }

  console.log(errors)

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverDismissDocumentation)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão - Documentação</Heading>
      <FormControl isInvalid={!!errors.doesReceivedDismissionDocumentsFromAccounting} mt={3}>
        <FormLabel>
          Documentos de demissão recebidos da contabilidade?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesReceivedDismissionDocumentsFromAccounting"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.hasCheckedPeriodFuelAndRentalBalance} mt={3}>
        <FormLabel>
          Fez apuração de saldo de combustível e aluguel do período?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasCheckedPeriodFuelAndRentalBalance"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.hasCheckedGrrf} mt={3}>
        <FormLabel>
          Realizou conferência GRFF?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasCheckedGrrf"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.doesOperationLeaderKnownAboutDismiss} mt={3}>
        <FormLabel>
          Líder da operação ciente para realizar a demissão?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesOperationLeaderKnownAboutDismiss"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>


      <FormLabel>
        Anexo de documentos rescisórios
        <Text as="sup" color="red.500">*</Text>
      </FormLabel>
      <VStack spacing={3}>
        {terminationDocumentsFields.map((field, index) => {

          const isFilledField = Boolean(terminationDocuments[index]?.file?.length)

          return (

            <FormProvider {...formMethods}>
              <TerminationDocumentTableRowField
                key={field.id}
                index={index}
                onSetTerminationDocumentAttachmentId={setTerminationDocumentAttachmentId}
                isFilledField={isFilledField}
                onAppendField={() => appendTerminationDocumentField({})}
                onRemoveField={() => removeTerminationDocumentField(index)}
              />
            </FormProvider>
          )
        })}
      </VStack>

      <FormControl isInvalid={!!errors.paymentDate} mt={3}>
        <FormLabel>
          Data de pagamento
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('paymentDate')}
          name="paymentDate"
          type="date"
          size="sm"
          rounded="md"
        />
        {/* <Controller
          name="paymentDate"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        /> */}
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

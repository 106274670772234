import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createAudit } from "api/audits/createAudit";
import { AuditCollaboratorTypes, AuditTypes } from "api/audits/_types/Audit";
import { getInternClts } from "api/internClts/getInternClts";
import { set } from "date-fns";
import { useAggregates } from "hooks/aggregate/useAggregates";
import { useCustomers } from "hooks/customer/useCustomers";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as yup from "yup"
import AsyncSelect from 'react-select/async';
import { getCltDrivers } from "api/cltDrivers/getCltDrivers";
import { getRegionals } from "api/regionals/getRegionals";
import { getInternClt } from "api/internClts/getInternClt";
import { getCltDriver } from "api/cltDrivers/getCltDriver";
import { captalize } from "utils/captalize";

interface CreateAuditProps {
  onClose: () => void
}

interface CreateAuditSchema {
  scheduledDate: string
  type: AuditTypes
  customerId: string
  collaboratorType: AuditCollaboratorTypes
  collaborator: {
    label: string
    value: string
  }
  regionalId: {
    label: string
    value: string
  }
}

export const auditOptions: AuditTypes[] = [
  'OPERACIONAL',
  'COMERCIAL',
  'RH',
  'CAMPO',
  'RH LLM',
  'FINANCEIRA',
  'QUALIDADE VISA',
  'QUALIDADE ISO',
  'MANUTENÇÃO',
  'MULTAS'
]
export const collaboratorTypesOptions: AuditCollaboratorTypes[] = [
  'MOTOCICLISTAS E MOTORISTAS',
  'AGREGADOS',
  'LLM',
  'N.A',
]

const createAuditSchema = yup.object({
  scheduledDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  type: yup.string().required(),
  customerId: yup.string().required(),
  collaboratorType: yup.string().required(),
  collaborator: yup.mixed().when('collaboratorType', {
    is: (value: AuditCollaboratorTypes) => value && value !== 'N.A',
    then: yup.mixed().required(),
    otherwise: yup.mixed().notRequired()
  }),
  regionalId: yup.mixed().when('collaboratorType', {
    is: (value: AuditCollaboratorTypes) => value && value !== 'N.A',
    then: yup.mixed().required(),
    otherwise: yup.mixed().notRequired()
  })
})

export function CreateAudit({
  onClose
}: CreateAuditProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutateAsync: createAuditFn } = useMutation({
    mutationFn: createAudit,
    onSuccess: () => {
      queryClient.invalidateQueries(['audits'])
      onClose()
    }
  })

  const { data: customers } = useCustomers({})

  const {
    data: aggregates,
  } = useAggregates()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateAuditSchema>({
    resolver: yupResolver(createAuditSchema)
  })

  console.log(errors)

  const [collaboratorType, collaborator] = useWatch({
    control,
    name: ['collaboratorType', 'collaborator']
  })

  const {
    data: internCltResult
  } = useQuery({
    queryKey: ['intern-clt', collaborator?.value],
    queryFn: () => getInternClt({ routeParams: { internCltId: collaborator?.value } }),
    enabled: collaboratorType === 'LLM' && !!collaborator?.value
  })

  const {
    data: cltDriverResult
  } = useQuery({
    queryKey: ['clt-driver', collaborator?.value],
    queryFn: () => getCltDriver({ routeParams: { cltDriverId: collaborator?.value } }),
    enabled: collaboratorType === 'MOTOCICLISTAS E MOTORISTAS' && !!collaborator?.value
  })

  useEffect(() => {
    async function run() {
      const response = await getRegionals({ currentPage: 1, pageSize: 10 })

      if (collaboratorType === 'LLM') {
        const regional = response?.regionals.find(regional => regional.code.toUpperCase() === internCltResult?.internClt?.regional.toUpperCase())
        setValue('regionalId', { label: regional.name, value: regional.id })

      }
      if (collaboratorType === 'MOTOCICLISTAS E MOTORISTAS') {
        const regional = response?.regionals.find(regional => regional.code.toUpperCase() === cltDriverResult?.regional.toUpperCase())
        setValue('regionalId', { label: regional.name, value: regional.id })

      }
    }
    run()

  }, [cltDriverResult, collaboratorType, internCltResult, setValue])

  async function promiseOptionsDrivers(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getCltDrivers({ currentPage: 1, pageSize: 5, search: inputValue ?? '' })

    return response.cltDrivers.map(driver => {
      return { value: driver.id, label: captalize(`${driver?.driver.firstname} ${driver?.driver.lastname}`) }
    })

  }
  async function promiseOptionsLLM(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getInternClts({ currentPage: 1, pageSize: 5, name: inputValue ?? '' })

    return response.internClts.map(internClt => {
      return { value: internClt.id, label: internClt.name }
    })
  }

  async function promiseOptionsAggregates(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    return aggregates.map(aggregate => {
      return { value: aggregate.id, label: captalize(`${aggregate?.driver.firstname} ${aggregate?.driver.lastname}`) }
    })
  }


  async function promiseOptionsRegionals(inputValue: string): Promise<Array<{ value: string, label: string }>> {

    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response.regionals.map(regional => {
      return { value: regional.id, label: regional.code.toUpperCase() }
    })
  }

  async function handleCreateAudit(values: CreateAuditSchema) {
    await promiseMessage(createAuditFn({
      body: {
        ...values,
        regionalId: values?.regionalId?.value,
        collaborator: values?.collaborator?.label
      }
    }), 'Auditoria criada!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar Auditoria
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateAudit)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.scheduledDate} mt={3}>
              <FormLabel fontSize="sm">
                Data Programada
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('scheduledDate')}
                name="scheduledDate"
                type="date"
                rounded="md"

              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.type} mt="3">
              <FormLabel fontSize="sm">
                Tipo de Auditoria
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('type')}
                name="type"
                placeholder="Selecione..."
                rounded="md"
              >
                {Object.entries(auditOptions).map(([key, value]) => (
                  <option key={key} value={value}>{value}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.customerId}>
              <FormLabel fontSize="sm">
                Cliente
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Select
                {...register('customerId')}
                name='customerId'
                placeholder="Selecione uma opção..."
                rounded="md"
              >
                {customers?.map(customer => {
                  return (
                    <option key={customer.id} value={customer.id}>{customer.trading_firstname}</option>
                  )
                })}
              </Select>
            </FormControl>

          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.collaboratorType}>
              <FormLabel fontSize="sm">
                Tipo de Colaborador
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('collaboratorType')}
                name="collaboratorType"
                placeholder="Selecione..."
                rounded="md"
                onChangeCapture={() => setValue('collaborator', undefined)}
              >
                {Object.entries(collaboratorTypesOptions).map(([key, value]) => (
                  <option key={key} value={value}>{value}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        {collaboratorType && collaboratorType !== 'N.A' && (
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            {collaboratorType === 'MOTOCICLISTAS E MOTORISTAS' && (
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors?.collaborator}>
                  <FormLabel w="full" fontSize='sm'>
                    Colaborador
                    <Text as="sup" color="red.500">*</Text>

                  </FormLabel>
                  <Controller
                    control={control}
                    name="collaborator"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('collaborator')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            borderColor: 'gray.200',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          }),
                          placeholder: (base, props) => ({
                            ...base,
                            color: 'blackAlpha.900'
                          }),
                          dropdownIndicator(base, props) {
                            return {
                              ...base,
                              color: 'blackAlpha.900',
                              width: '34px',
                            }
                          },
                        }}
                        noOptionsMessage={() => 'Não há internos cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborador..."
                        loadOptions={promiseOptionsDrivers}
                        isClearable={true}

                      />
                    )}
                  />
                </FormControl>
              </Stack>
            )}
            {collaboratorType === 'LLM' && (
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors?.collaborator}>
                  <FormLabel w="full" fontSize='sm'>
                    Colaborador
                    <Text as="sup" color="red.500">*</Text>

                  </FormLabel>
                  <Controller
                    control={control}
                    name="collaborator"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('collaborator')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            borderColor: 'gray.200',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          }),
                          placeholder: (base, props) => ({
                            ...base,
                            color: 'blackAlpha.900'
                          }),
                          dropdownIndicator(base, props) {
                            return {
                              ...base,
                              color: 'blackAlpha.900',
                              width: '34px',
                            }
                          },
                        }}
                        noOptionsMessage={() => 'Não há internos cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborador..."
                        loadOptions={promiseOptionsLLM}
                        isClearable={true}

                      />
                    )}
                  />
                </FormControl>
              </Stack>
            )}
            {collaboratorType === 'AGREGADOS' && (
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors?.collaborator}>
                  <FormLabel w="full" fontSize='sm'>
                    Colaborador
                    <Text as="sup" color="red.500">*</Text>

                  </FormLabel>
                  <Controller
                    control={control}
                    name="collaborator"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('collaborator')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            borderColor: 'gray.200',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          }),
                          placeholder: (base, props) => ({
                            ...base,
                            color: 'blackAlpha.900'
                          }),
                          dropdownIndicator(base, props) {
                            return {
                              ...base,
                              color: 'blackAlpha.900',
                              width: '34px',
                            }
                          },
                        }}
                        noOptionsMessage={() => 'Não há agregados cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborador..."
                        loadOptions={promiseOptionsAggregates}
                        isClearable={true}

                      />
                    )}
                  />
                </FormControl>
              </Stack>
            )}
          </Stack>
        )}
        {collaboratorType && collaboratorType !== 'N.A' && (
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.regionalId}>
                <FormLabel w="full" fontSize='sm'>
                  Regional
                  <Text as="sup" color="red.500">*</Text>

                </FormLabel>
                <Controller
                  control={control}
                  name="regionalId"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('regionalId')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          borderColor: 'gray.200',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        }),
                        placeholder: (base, props) => ({
                          ...base,
                          color: 'blackAlpha.900'
                        }),
                        dropdownIndicator(base, props) {
                          return {
                            ...base,
                            color: 'blackAlpha.900',
                            width: '34px',
                          }
                        },
                      }}
                      noOptionsMessage={() => 'Não há regionais cadastradas ou encontradas!'}
                      placeholder="Selecione uma regional..."
                      loadOptions={promiseOptionsRegionals}
                      isClearable={true}

                    />
                  )}
                />
              </FormControl>
            </Stack>
          </Stack>
        )}

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

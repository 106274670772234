import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Flex,
  Button,
  Link as ChakraLink,
  Checkbox,
  useDisclosure,
  Modal,
  ModalOverlay
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { serviceFormatDateToFrontTable } from '../../../../utils/ServiceFunctions/serviceFormatDateToFrontTable'
import { AuthContext } from '../../../../contexts/AuthContext'
import { serviceFormatHourToFront } from '../../../../utils/ServiceFunctions/serviceFormatHourToFront'
import { BranchProps } from '../../../../contexts/BranchContext'
import { serviceSortByDate } from '../../../../utils/ServiceFunctions/serviceSortByDate'
import { serviceSortByHour } from '../../../../utils/ServiceFunctions/serviceSortByHourt'
import { TableFilterButton } from '../../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../../hooks/filterOptions/useFilterOptions'
import { FaClock, FaUndoAlt } from 'react-icons/fa'
import { EmptyContentTableAlert } from '../../../Alerts/EmptyContentTableAlert'
import { formatDate } from '../../../../utils/DateFunctions/formatDate'
import { OccurrenceProps } from '../../../../contexts/OccurrenceContext'
import { Address } from 'hooks/address/dtos/Address'
import { differenceInMinutes, format, isBefore, isSameDay, set } from 'date-fns'
import { MultiBoardValidations } from './components/MultiBoardValidations'
import { FaCubesStacked } from 'react-icons/fa6'

interface ValidateServiceTableProps {
  services?: ServiceProps[]
  addresses?: Address[]
  branches: BranchProps[]
  refetch: () => void
}

const boardValidateServiceOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'sourceCity', value: 'Cidade de Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade de Destino', checked: false },
  { key: 'sourceBranch', value: 'Base de Origem', checked: false },
  { key: 'destinationBranch', value: 'Base de Destino', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'boardDate', value: 'Data do Embarque', checked: false },
  { key: 'collectDate', value: 'Data da Coleta', checked: false },
  { key: 'deliveryDate', value: 'Data da Entrega', checked: false },
  { key: 'operationalNumber', value: 'Rastreador', checked: false },
  { key: 'boardVolume', value: 'Volume Embarcado', checked: false },
]

const boardValidateServiceOrderOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

export interface MultiValidationServicesProps {
  shipping: string
  sourceBranch: string
  destinationBranch: string
  services: {
    id: string
  }[]
  modal: string
  operationalNumber: string
}

export function ValidateServiceTable({
  services,
  addresses,
  branches,
  refetch,
}: ValidateServiceTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [multiValidationServices, setMultiValidationServices] = useState<MultiValidationServicesProps>({
    destinationBranch: '',
    services: [],
    shipping: '',
    sourceBranch: '',
    modal: '',
    operationalNumber: ''
  })

  function handleResetMultiValidationServices() {
    setMultiValidationServices({
      destinationBranch: '',
      services: [],
      shipping: '',
      sourceBranch: '',
      modal: '',
      operationalNumber: ''
    })
  }

  function handleToggleServiceToMultiValidationServices({
    serviceId,
    shipping,
    sourceBranch,
    destinationBranch,
    modal,
    operationalNumber
  }: {
    serviceId: string
    shipping: string
    sourceBranch: string
    destinationBranch: string,
    modal: string
    operationalNumber: string
  }) {
    setMultiValidationServices(prev => {
      const services = prev.services.find(service => service.id === serviceId)
        ? prev.services.filter(service => service.id !== serviceId)
        : [...prev.services, { id: serviceId }]

      if (!prev.sourceBranch && services.length) {
        prev.sourceBranch = sourceBranch
        prev.destinationBranch = destinationBranch
        prev.shipping = shipping
        prev.modal = modal
        prev.operationalNumber = operationalNumber

      }

      if (!services.length) {
        prev.sourceBranch = ''
        prev.destinationBranch = ''
        prev.shipping = ''
        prev.modal = ''
        prev.operationalNumber = ''
      }

      return {
        ...prev,
        services
      }
    })
  }

  const [searchedServices, setSearchedServices] = useState<ServiceProps[]>([])
  const [slicedServices, setSlicedServices] = useState<ServiceProps[]>([])

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [sourceCityFiltered, setSourceCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [sourceBranchFiltered, setSourceBranchFiltered] = useState('')
  const [destinationBranchFiltered, setDestinationBranchFiltered] = useState('')
  const [shippingFiltered, setShippingFiltered] = useState('')
  const [boardDateFiltered, setBoardDateFiltered] = useState('')
  const [collectDateFiltered, setCollectDateFiltered] = useState('')
  const [deliveryDateFiltered, setDeliveryDateFiltered] = useState('')
  const [operationalNumberFiltered, setOperationalNumberFiltered] = useState('')
  const [boardVolumeFiltered, setBoardVolumeFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useContext(AuthContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedServices?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(boardValidateServiceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(boardValidateServiceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      if (services) {
        const servicesPerPage = services?.filter(
          (service) => service.step === 'toBoardValidate',
        )
        setSlicedServices(servicesPerPage)
      }
    }

    run()
  }, [itemLimit, currentPage, offset, services])

  useEffect(() => {
    function run() {
      if (!!branches && !!addresses) {
        const newSlicedServices = slicedServices?.filter((service) => {
          const sourceCrossdockingBranchID =
            service.serviceIDRequested.source_crossdocking_branch_id

          const sourceBranch = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested
                          .source_crossdocking_branch_id,
                    )
                    .map((branch) => branch.nickname),
                )
                : String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested.source_branch_id,
                    )
                    .map((branch) => branch.nickname),
                )
              : String(
                branches
                  .filter(
                    (branch) =>
                      branch.id ===
                      service.serviceIDRequested.source_branch_id,
                  )
                  .map((branch) => branch.nickname),
              )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id === service.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.nickname),
            )

          const destinationBranch = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested.destination_branch_id,
                    )
                    .map((branch) => branch.nickname),
                )
                : String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested
                          .destination_crossdocking_branch_id,
                    )
                    .map((branch) => branch.nickname),
                )
              : String(
                branches
                  .filter(
                    (branch) =>
                      branch.id ===
                      service.serviceIDRequested
                        .destination_crossdocking_branch_id,
                  )
                  .map((branch) => branch.nickname),
              )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id ===
                    service.serviceIDRequested.destination_branch_id,
                )
                .map((branch) => branch.nickname),
            )

          const shipping = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested
                          .source_crossdocking_branch_id,
                    )
                    .map((branch) => branch.shippingIDBranch.company_name),
                )
                : String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested.source_branch_id,
                    )
                    .map((branch) => branch.shippingIDBranch.company_name),
                )
              : String(
                branches
                  .filter(
                    (branch) =>
                      branch.id ===
                      service.serviceIDRequested.source_branch_id,
                  )
                  .map((branch) => branch.shippingIDBranch.company_name),
              )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id === service.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.shippingIDBranch.company_name),
            )

          const operationalNumber = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  service.serviceIDBoard
                    .filter(
                      (boardService) =>
                        boardService.branch_id ===
                        service.serviceIDRequested
                          .source_crossdocking_branch_id,
                    )
                    .map((boardService) => boardService.operational_number),
                )
                : String(
                  service.serviceIDBoard
                    .filter(
                      (boardService) =>
                        boardService.branch_id ===
                        service.serviceIDRequested.source_branch_id,
                    )
                    .map((boardService) => boardService.operational_number),
                )
              : String(
                service.serviceIDBoard
                  .filter(
                    (boardService) =>
                      boardService.branch_id ===
                      service.serviceIDRequested.source_branch_id,
                  )
                  .map((boardService) => boardService.operational_number),
              )
            : String(
              service.serviceIDBoard
                .filter(
                  (boardService) =>
                    boardService.branch_id ===
                    service.serviceIDRequested.source_branch_id,
                )
                .map((boardService) => boardService.operational_number),
            )

          const boardVolume = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  service.serviceIDBoard
                    .filter(
                      (boardService) =>
                        boardService.branch_id ===
                        service.serviceIDRequested
                          .source_crossdocking_branch_id,
                    )
                    .map((boardService) => boardService.board_volume),
                )
                : String(
                  service.serviceIDBoard
                    .filter(
                      (boardService) =>
                        boardService.branch_id ===
                        service.serviceIDRequested.source_branch_id,
                    )
                    .map((boardService) => boardService.board_volume),
                )
              : String(
                service.serviceIDBoard
                  .filter(
                    (boardService) =>
                      boardService.branch_id ===
                      service.serviceIDRequested.source_branch_id,
                  )
                  .map((boardService) => boardService.board_volume),
              )
            : String(
              service.serviceIDBoard
                .filter(
                  (boardService) =>
                    boardService.branch_id ===
                    service.serviceIDRequested.source_branch_id,
                )
                .map((boardService) => boardService.board_volume),
            )

          const protocolFilter = searchBoxFilter(
            String(service.protocol),
            protocolFiltered,
          )
          const customerFilter = searchBoxFilter(
            service.customerIDService.trading_firstname,
            customerFiltered,
          )
          const sourceCityFilter = searchBoxFilter(
            addresses
              .filter((address) =>
                service.serviceIDRequested.source_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => address.cityIDAddress.name)
              .join(', '),
            sourceCityFiltered,
          )
          const destinationCityFilter = searchBoxFilter(
            addresses
              .filter((address) =>
                service.serviceIDRequested.destination_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => address.cityIDAddress.name)
              .join(', '),
            destinationCityFiltered,
          )
          const collectDateFilter = searchBoxFilter(
            serviceFormatDateToFrontTable(
              service.serviceIDRequested.collect_date,
            ),
            collectDateFiltered,
          )
          const sourceBranchFilter = searchBoxFilter(
            sourceBranch,
            sourceBranchFiltered,
          )
          const destinationBranchFilter = searchBoxFilter(
            destinationBranch,
            destinationBranchFiltered,
          )
          const shippingFilter = searchBoxFilter(shipping, shippingFiltered)
          const boardDateFilter = searchBoxFilter(
            serviceFormatHourToFront(
              service.serviceIDRequested.board_hour !== null
                ? service.serviceIDRequested.board_hour
                : '',
            ),
            boardDateFiltered,
          )
          const deliveryDateFilter = searchBoxFilter(
            serviceFormatDateToFrontTable(
              service.serviceIDRequested.delivery_date,
            ),
            deliveryDateFiltered,
          )
          const operationalNumberFilter = searchBoxFilter(
            operationalNumber,
            operationalNumberFiltered,
          )
          const boardVolumeFilter = searchBoxFilter(
            boardVolume,
            boardVolumeFiltered,
          )

          if (
            customerFiltered === '' &&
            protocolFiltered === '' &&
            sourceCityFiltered === '' &&
            destinationCityFiltered === '' &&
            collectDateFiltered === '' &&
            deliveryDateFiltered === '' &&
            boardDateFiltered === '' &&
            sourceBranchFiltered === '' &&
            destinationBranchFiltered === '' &&
            shippingFiltered === '' &&
            operationalNumberFiltered === '' &&
            boardVolumeFiltered === ''
          ) {
            return service
          }
          return (
            protocolFilter &&
            customerFilter &&
            sourceCityFilter &&
            destinationCityFilter &&
            collectDateFilter &&
            sourceBranchFilter &&
            destinationBranchFilter &&
            shippingFilter &&
            boardDateFilter &&
            deliveryDateFilter &&
            operationalNumberFilter &&
            boardVolumeFilter
          )
        })

        if (orderOptionSelected.length > 0) {
          const slicedServicesByOrder = newSlicedServices.sort(
            (a, b) =>
              (orderOptionSelected.includes('protocol') &&
                a.protocol - b.protocol) ||
              (orderOptionSelected.includes('customer') &&
                a.customerIDService.trading_firstname.localeCompare(
                  b.customerIDService.trading_firstname,
                )) ||
              0,
          )

          return setSearchedServices(slicedServicesByOrder)
        }

        setSearchedServices(
          newSlicedServices.sort(
            (dateA, dateB) =>
              serviceSortByDate(
                dateA.serviceIDRequested.collect_date,
                dateB.serviceIDRequested.collect_date,
              ) ||
              serviceSortByHour(
                dateA.serviceIDRequested.board_hour !== null
                  ? dateA.serviceIDRequested.board_hour
                  : '',
                dateB.serviceIDRequested.board_hour !== null
                  ? dateB.serviceIDRequested.board_hour
                  : '',
              ) ||
              branches
                .filter(
                  (branch) =>
                    branch.id === dateA.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.shippingIDBranch.company_name)
                .toString()
                .localeCompare(
                  branches
                    .filter(
                      (branch) =>
                        branch.id === dateB.serviceIDRequested.source_branch_id,
                    )
                    .map((branch) => branch.shippingIDBranch.company_name)
                    .toString(),
                ) ||
              branches
                .filter(
                  (branch) =>
                    branch.id === dateA.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.nickname)
                .toString()
                .localeCompare(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        dateB.serviceIDRequested.destination_branch_id,
                    )
                    .map((branch) => branch.nickname)
                    .toString(),
                ),
          ),
        )
      }
    }
    run()
  }, [
    protocolFiltered,
    customerFiltered,
    sourceCityFiltered,
    destinationCityFiltered,
    operationalNumberFiltered,
    collectDateFiltered,
    sourceBranchFiltered,
    destinationBranchFiltered,
    boardVolumeFiltered,
    shippingFiltered,
    boardDateFiltered,
    deliveryDateFiltered,
    orderOptionSelected,
    slicedServices,
    addresses,
    branches,
  ])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleRefetchData = async () => {
    return refetch()
  }

  const {
    isOpen: isMultiValidateBoardModalOpen,
    onOpen: onOpenMultiValidateBoardModal,
    onClose: onCloseMultiValidateBoardModal
  } = useDisclosure()

  console.log(multiValidationServices)

  return (
    <>
      {slicedServices.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <Stack direction="row">
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchData}>
              <Icon as={FaUndoAlt} />{' '}
            </Button>
            {multiValidationServices.shipping && (
              <>
                <Modal
                  isOpen={isMultiValidateBoardModalOpen}
                  onClose={onCloseMultiValidateBoardModal}
                  size="6xl"
                >
                  <ModalOverlay />

                  <MultiBoardValidations
                    multiValidationInfo={multiValidationServices}
                    onClose={onCloseMultiValidateBoardModal}
                    onResetMultiValidationServices={handleResetMultiValidationServices}
                  />
                </Modal>
                <Button
                  onClick={onOpenMultiValidateBoardModal}
                >
                  Validar selecionados
                </Button>
              </>
            )}
          </Stack>
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'protocol' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Protocolo..."
                        handleSearch={(e) =>
                          setProtocolFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'customer' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cliente..."
                        handleSearch={(e) =>
                          setCustomerFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Origem..."
                        handleSearch={(e) =>
                          setSourceCityFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Destino..."
                        handleSearch={(e) =>
                          setDestinationCityFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceBranch' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Base de Origem..."
                        handleSearch={(e) =>
                          setSourceBranchFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationBranch' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Base de Destino..."
                        handleSearch={(e) =>
                          setDestinationBranchFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'shipping' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Transportadora..."
                        handleSearch={(e) =>
                          setShippingFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'boardDate' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Data do Embarque..."
                        handleSearch={(e) =>
                          setBoardDateFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'collectDate' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Data da Coleta..."
                        handleSearch={(e) =>
                          setCollectDateFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'deliveryDate' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Data da Entrega..."
                        handleSearch={(e) =>
                          setDeliveryDateFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'operationalNumber' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Rastreador..."
                        handleSearch={(e) =>
                          setOperationalNumberFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'boardVolume' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Volume do Embarque..."
                        handleSearch={(e) =>
                          setBoardVolumeFiltered(e.target.value)
                        }
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                  <Th></Th>
                  <Th fontSize="12" color="blue.900">
                    PROTOCOLO
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    DATA DA COLETA
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    TRANSPORTADORA
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    RASTREADOR EMBARCADO
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    BASE ORIGEM
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    BASE DESTINO
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    CLIENTE
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    VOLUMES EMBARCADOS
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    CIDADE DE ORIGEM
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    CIDADE DESTINO
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    HORÁRIO LIMITE DE EMBARQUE
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    DATA DA ENTREGA
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedServices
                  .slice(offset, offset + Number(itemLimit))
                  .map((service, index) => {
                    const sourceCrossdockingBranchID =
                      service.serviceIDRequested.source_crossdocking_branch_id

                    const modal = sourceCrossdockingBranchID
                      ? service.serviceIDLanding.find(landing => ['VALIDATING', 'DONE'].includes(landing.step))
                        ? service.serviceIDRequested.modal
                        : service.serviceIDRequested.crossdocking_modal
                      : service.serviceIDRequested.modal

                    const sourceBranch = sourceCrossdockingBranchID
                      ? service.serviceIDBoard.find(
                        (boardService) =>
                          boardService.collector_id ===
                          service.serviceIDRequested
                            .crossdocking_collector_id,
                      ) !== undefined
                        ? service.serviceIDLanding.find(
                          (landingService) =>
                            landingService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested
                                    .source_crossdocking_branch_id,
                              )
                              .map((branch) => branch.nickname),
                          )
                          : String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested.source_branch_id,
                              )
                              .map((branch) => branch.nickname),
                          )
                        : String(
                          branches
                            .filter(
                              (branch) =>
                                branch.id ===
                                service.serviceIDRequested.source_branch_id,
                            )
                            .map((branch) => branch.nickname),
                        )
                      : String(
                        branches
                          .filter(
                            (branch) =>
                              branch.id ===
                              service.serviceIDRequested.source_branch_id,
                          )
                          .map((branch) => branch.nickname),
                      )

                    const destinationBranch = sourceCrossdockingBranchID
                      ? service.serviceIDBoard.find(
                        (boardService) =>
                          boardService.collector_id ===
                          service.serviceIDRequested
                            .crossdocking_collector_id,
                      ) !== undefined
                        ? service.serviceIDLanding.find(
                          (landingService) =>
                            landingService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested
                                    .destination_branch_id,
                              )
                              .map((branch) => branch.nickname),
                          )
                          : String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested
                                    .destination_crossdocking_branch_id,
                              )
                              .map((branch) => branch.nickname),
                          )
                        : String(
                          branches
                            .filter(
                              (branch) =>
                                branch.id ===
                                service.serviceIDRequested
                                  .destination_crossdocking_branch_id,
                            )
                            .map((branch) => branch.nickname),
                        )
                      : String(
                        branches
                          .filter(
                            (branch) =>
                              branch.id ===
                              service.serviceIDRequested
                                .destination_branch_id,
                          )
                          .map((branch) => branch.nickname),
                      )

                    const shipping = sourceCrossdockingBranchID
                      ? service.serviceIDBoard.find(
                        (boardService) =>
                          boardService.collector_id ===
                          service.serviceIDRequested
                            .crossdocking_collector_id,
                      ) !== undefined
                        ? service.serviceIDLanding.find(
                          (landingService) =>
                            landingService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested
                                    .source_crossdocking_branch_id,
                              )
                              .map(
                                (branch) =>
                                  branch.shippingIDBranch.company_name,
                              ),
                          )
                          : String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested.source_branch_id,
                              )
                              .map(
                                (branch) =>
                                  branch.shippingIDBranch.company_name,
                              ),
                          )
                        : String(
                          branches
                            .filter(
                              (branch) =>
                                branch.id ===
                                service.serviceIDRequested.source_branch_id,
                            )
                            .map(
                              (branch) =>
                                branch.shippingIDBranch.company_name,
                            ),
                        )
                      : String(
                        branches
                          .filter(
                            (branch) =>
                              branch.id ===
                              service.serviceIDRequested.source_branch_id,
                          )
                          .map(
                            (branch) => branch.shippingIDBranch.company_name,
                          ),
                      )

                    const operationalNumber = sourceCrossdockingBranchID
                      ? service.serviceIDBoard.find(
                        (boardService) =>
                          boardService.collector_id ===
                          service.serviceIDRequested
                            .crossdocking_collector_id,
                      ) !== undefined
                        ? service.serviceIDLanding.find(
                          (landingService) =>
                            landingService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? String(
                            service.serviceIDBoard
                              .filter(
                                (boardService) =>
                                  boardService.branch_id ===
                                  service.serviceIDRequested
                                    .source_crossdocking_branch_id,
                              )
                              .map(
                                (boardService) =>
                                  boardService.operational_number,
                              ),
                          )
                          : String(
                            service.serviceIDBoard
                              .filter(
                                (boardService) =>
                                  boardService.branch_id ===
                                  service.serviceIDRequested.source_branch_id,
                              )
                              .map(
                                (boardService) =>
                                  boardService.operational_number,
                              ),
                          )
                        : String(
                          service.serviceIDBoard
                            .filter(
                              (boardService) =>
                                boardService.branch_id ===
                                service.serviceIDRequested.source_branch_id,
                            )
                            .map(
                              (boardService) =>
                                boardService.operational_number,
                            ),
                        )
                      : String(
                        service.serviceIDBoard
                          .filter(
                            (boardService) =>
                              boardService.branch_id ===
                              service.serviceIDRequested.source_branch_id,
                          )
                          .map(
                            (boardService) => boardService.operational_number,
                          ),
                      )

                    const trackerLink = sourceCrossdockingBranchID
                      ? service.serviceIDBoard.find((boardService) =>
                        boardService.collector_id ===
                        service.serviceIDRequested.crossdocking_collector_id
                      )
                        ? service.serviceIDLanding.find((landingService) =>
                          landingService.collector_id ===
                          service.serviceIDRequested.crossdocking_collector_id
                        )
                          ? String(
                            service.serviceIDBoard
                              .filter(
                                (boardService) =>
                                  boardService.branch_id ===
                                  service.serviceIDRequested.source_crossdocking_branch_id
                              )
                              .map((boardService) => boardService.tracker_link)
                          ) || null
                          : String(
                            service.serviceIDBoard
                              .filter(
                                (boardService) =>
                                  boardService.branch_id ===
                                  service.serviceIDRequested.source_branch_id
                              )
                              .map((boardService) => boardService.tracker_link)
                          ) || null
                        : null
                      : String(
                        service.serviceIDBoard
                          .filter(
                            (boardService) =>
                              boardService.branch_id ===
                              service.serviceIDRequested.source_branch_id
                          )
                          .map((boardService) => boardService.tracker_link)
                      ) || null;

                    const boardVolume = sourceCrossdockingBranchID
                      ? service.serviceIDBoard.find(
                        (boardService) =>
                          boardService.collector_id ===
                          service.serviceIDRequested
                            .crossdocking_collector_id,
                      ) !== undefined
                        ? service.serviceIDLanding.find(
                          (landingService) =>
                            landingService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? String(
                            service.serviceIDBoard
                              .filter(
                                (boardService) =>
                                  boardService.branch_id ===
                                  service.serviceIDRequested
                                    .source_crossdocking_branch_id,
                              )
                              .map(
                                (boardService) => boardService.board_volume,
                              ),
                          )
                          : String(
                            service.serviceIDBoard
                              .filter(
                                (boardService) =>
                                  boardService.branch_id ===
                                  service.serviceIDRequested.source_branch_id,
                              )
                              .map(
                                (boardService) => boardService.board_volume,
                              ),
                          )
                        : String(
                          service.serviceIDBoard
                            .filter(
                              (boardService) =>
                                boardService.branch_id ===
                                service.serviceIDRequested.source_branch_id,
                            )
                            .map((boardService) => boardService.board_volume),
                        )
                      : String(
                        service.serviceIDBoard
                          .filter(
                            (boardService) =>
                              boardService.branch_id ===
                              service.serviceIDRequested.source_branch_id,
                          )
                          .map((boardService) => boardService.board_volume),
                      )


                    const collectDelayIntercurrences = [
                      "ATRASO NA COLETA",
                      "NO SHOW"
                    ]

                    const serviceCollectDelay = service.occurrenceIDService
                      ?.filter(occurrence =>
                        collectDelayIntercurrences.includes(occurrence.intercurrence) && occurrence.proceed === "SIM")
                      ?.reduce((acc, curr) => new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr, {} as OccurrenceProps)

                    const hasCollectDelay = serviceCollectDelay?.id ? true : false

                    const collectDate = serviceCollectDelay?.occurrence_date
                      ? formatDate.handle(serviceCollectDelay.occurrence_date, "DateWithoutHourToShow")
                      : formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")

                    const deliveryDelayIntercurrences = [
                      "ATRASO NA ENTREGA",
                      "CANCELAMENTO DE VOO",
                      "CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)"
                    ]

                    const serviceDeliveryDelay = service.occurrenceIDService
                      ?.filter(occurrence =>
                        deliveryDelayIntercurrences.includes(occurrence.intercurrence) && occurrence.proceed === "SIM")
                      ?.reduce((acc, curr) => new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr, {} as OccurrenceProps)

                    const hasDeliveryDelay = serviceDeliveryDelay?.id ? true : false

                    const deliveryDate = serviceDeliveryDelay?.occurrence_date
                      ? formatDate.handle(serviceDeliveryDelay.occurrence_date, "DateWithoutHourToShow")
                      : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

                    const sourceCities = Array.from(new Set(addresses
                      ?.filter((address) =>
                        service.serviceIDRequested.source_address_id.includes(address.id))
                      .map((address) => address.cityIDAddress.name)
                    ))
                      .join(', ')
                      .toUpperCase()

                    const destinationCities = Array.from(new Set(addresses
                      ?.filter((address) =>
                        service.serviceIDRequested.destination_address_id.includes(address.id))
                      .map((address) => address.cityIDAddress.name)
                    ))
                      .join(', ')
                      .toUpperCase()

                    const isCrossdockingService = service.serviceIDRequested.crossdocking_collector_id

                    let isServiceDelayed = false

                    if (isCrossdockingService) {

                      const isBoardDateBeforeToToday = isBefore(
                        new Date(service.serviceIDRequested.board_date),
                        new Date()
                      )

                      const isBoardDateSameToToday = isSameDay(
                        new Date(service.serviceIDRequested.board_date),
                        new Date()
                      )

                      if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
                        isServiceDelayed = true
                      } else if (isBoardDateSameToToday) {
                        const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

                        isServiceDelayed = differenceInMinutes(
                          set(new Date(), {
                            hours: boardHours,
                            minutes: boardMinutes
                          }), new Date()
                        ) < 240
                      }
                    }

                    const totalCollectVolumeService = service?.serviceIDCollect.reduce((accumulator, current) => {
                      return accumulator + (current.collect_volume || 0);
                    }, 0);

                    const totalCollectsExceedsSumCollectsService = totalCollectVolumeService < Number(service.collects_total_volumes)

                    return (
                      <Tr
                        key={index}
                        color={hasCollectDelay || hasDeliveryDelay ? "yellow.400" : ""}
                      >
                        <Td>
                          {(!multiValidationServices.shipping ||
                            (
                              multiValidationServices.shipping === shipping &&
                              multiValidationServices.sourceBranch === sourceBranch &&
                              multiValidationServices.destinationBranch === destinationBranch &&
                              multiValidationServices.modal === modal
                            )) && (
                              <Checkbox
                                onChange={() => handleToggleServiceToMultiValidationServices({
                                  serviceId: service.id,
                                  sourceBranch: sourceBranch,
                                  destinationBranch: destinationBranch,
                                  shipping: shipping,
                                  modal,
                                  operationalNumber
                                })}

                                isChecked={!!multiValidationServices.services.find(multiService => multiService.id === service.id)}
                              />
                            )}

                        </Td>
                        <Td>
                          {isServiceDelayed && (
                            <Icon as={FaClock} color={'red.500'} />
                          )}
                          {totalCollectsExceedsSumCollectsService && (
                             <Icon as={FaCubesStacked} color={'red.500'} />
                          )}
                        </Td>
                        <Td>
                          {!!userLogged && (
                            <>
                              {userLogged.permissions.includes(
                                'valid-boarding-service',
                              ) && (
                                  <Link
                                    to={`/servicos/embarques/validar/${service.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEdit3}
                                      fontSize="20"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              <Link to={`/servico/detalhes/${service.id}`}>
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  fontSize="20"
                                />
                              </Link>
                            </>
                          )}
                        </Td>
                        <Td fontSize="12">{service.protocol}</Td>
                        <Td fontSize="12">{collectDate}</Td>
                        <Td fontSize="12">{shipping.toUpperCase()}</Td>
                        <Td fontSize="12">{
                          trackerLink ?
                            <ChakraLink href={trackerLink} isExternal>
                              {operationalNumber}
                            </ChakraLink>
                            : operationalNumber}
                        </Td>
                        <Td fontSize="12">{sourceBranch.toUpperCase()}</Td>
                        <Td fontSize="12">{destinationBranch.toUpperCase()}</Td>
                        <Td fontSize="12">
                          {service.customerIDService.trading_firstname.toUpperCase()}
                        </Td>
                        <Td fontSize="12">{boardVolume}</Td>
                        <Td fontSize="12">{sourceCities}</Td>
                        <Td fontSize="12">{destinationCities}</Td>
                        <Td fontSize="12">
                          {serviceFormatHourToFront(
                            service.serviceIDRequested.board_hour !== null
                              ? service.serviceIDRequested.board_hour
                              : '',
                          )}
                        </Td>
                        <Td fontSize="12">{deliveryDate}</Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

export function serviceHandleStep(value: string) {
  switch (value) {
    case 'toValidateRequestedService':
      return 'AGUARDANDO AGENDAMENTO'
    case 'toCollectService':
      return 'AGENDADO'
    case 'collectingService':
      return 'COLETANDO SERVIÇO'
    case 'toCollectBusinessService':
      return 'AGENDADO BUSINESS'
    case 'collectingBusinessService':
      return 'COLETANDO SERVIÇO BUSINESS'
    case 'toValidateCollectAddresses':
      return 'PENDENTE VALIDAÇÃO DE ENDEREÇOS PARA COLETA'
    case 'toBoardService':
      return 'EMBARCAR SERVIÇO'
    case 'boardingService':
      return 'EM ROTA DE EMBARQUE'
    case 'toBoardValidate':
      return 'EMBARCADO (VALIDAÇÃO)'
    case 'toAllocateService':
      return 'EMBARCADO (ALOCAÇÃO)'
    case 'toAvailableService':
      return 'ALOCADO'
    case 'availableService':
      return 'AGUARDANDO DISPONIBILIZAÇÃO'
    case 'toLandingService':
      return 'DISPONÍVEL'
    case 'landingService':
      return 'DESEMBARCANDO SERVIÇO'
    case 'pendingGeloSecoMaintenance':
      return 'PENDENTE MANUTENÇÃO DE GELO SECO'
    case 'toDeliveryService':
      return 'EM ROTA DE ENTREGA'
    case 'deliveringService':
      return 'ENTREGANDO SERVIÇO'
    case 'toDeliveryBusinessService':
      return 'EM ROTA DE ENTREGA BUSINESS'
    case 'deliveringBusinessService':
      return 'ENTREGANDO SERVIÇO BUSINESS'
    case 'finishedService':
      return 'ENTREGUE'
    case 'unsuccessService':
      return 'SERVIÇO SEM SUCESSO'
    case 'cancelledService':
      return 'SERVIÇO CANCELADO'
    case 'toValidateCancelRequest':
      return 'AGUARDANDO VALIDAÇÃO DE SOLICITAÇÃO DE CANCELAMENTO DE SERVIÇO'
    case 'toValidateAdditionalCollectAddresses':
      return 'AGUARDANDO VALIDAÇÃO DE SOLICITAÇÃO DE CANCELAMENTO DE ENDEREÇO DE COLETA'
    case 'toValidateAdditionalDeliveryAddresses':
      return 'PENDENTE VALIDAÇÃO DE ENDEREÇOS PARA ENTREGA'
    case 'toMaintenanceService':
      return 'EM MANUTENÇÃO'
    case 'ongoingMaintenanceService':
      return 'MANUTENÇÃO EM ANDAMENTO'
    case 'toValidateFiscalRetention':
      return 'EM RETENÇÃO FISCAL'
    default:
      return '-'
  }
}

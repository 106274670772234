import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  CheckboxGroup,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckBoxProps,
  Text,
  Stack,
  Box,
  Flex,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface PermissionProps {
  id: string
  key: string
  value: string
}

interface HubProps {
  id: string
  name: string
}

interface InputProps extends ChakraCheckBoxProps {
  slug?: string
  id?: string
  defaultOption?: string[]
  permissions?: PermissionProps[]
  hubs?: HubProps[]
  name: string
  label?: string
  required?: boolean
  error?: FieldError
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    slug,
    id,
    permissions,
    hubs,
    defaultOption,
    required = false,
    error = null,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            <Flex h="full" w="full" gap="1" align="center">
              <Text fontSize="sm" color="gray.700" w="full">
                {label}
              </Text>
              {required && (
                <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                  {' '}
                  *
                </Text>
              )}
            </Flex>
          </FormLabel>
        </Flex>
      )}

      {!!permissions && (
        <Box
          overflowY="auto"
          height="300px"
          borderWidth="1px"
          borderRadius="lg"
          p="2"
        >
          <Stack spacing="10px" justify="flex-start">
            {slug !== 'adicionar' && (
              <CheckboxGroup colorScheme="blue" defaultValue={defaultOption}>
                <Stack direction="column">
                  {permissions?.map((permission) => (
                    <ChakraCheckbox
                      key={permission.id}
                      name={name}
                      label={label}
                      value={permission.value}
                      ref={ref}
                      {...rest}
                    >
                      {permission.key}
                    </ChakraCheckbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            )}
          </Stack>
        </Box>
      )}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const CheckboxUser = forwardRef(CheckboxBase)

import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure, } from "@chakra-ui/react";
import { getPassivesFeedbacks } from "api/feedbacks/getPassivesFeedbacks";
import { PassiveFeedback, PassiveFeedbackStatus } from "api/feedbacks/_types/PassiveFeedback";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreatePassiveFeedback } from "./components/CreatePassiveFeedback";
import { PassiveFeedbackTableRow } from "./components/PassiveFeedbackTableRow";
import { PassivesFeedbacksTableFilter } from "./components/PassivesFeedbacksTableFilter";
import { passiveFeedbackStatusMap } from "utils/feedbackMappers";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";

const headers = [
  { label: 'COLABORADOR', key: 'collaborator_name' },
  { label: 'CPF', key: 'cpf' },
  { label: 'RG', key: 'rg' },
  { label: 'E-MAIL', key: 'email' },
  { label: 'STATUS', key: 'status' },
  { label: 'DATA FEEDBACK', key: 'deadline' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'TIPO', key: 'type' },
]

function formatValuesToReport(values: PassiveFeedback[]) {
  return values?.map((passiveFeedback) => ({
    ...passiveFeedback,
    collaborator_name: passiveFeedback?.cltDriver ? `${passiveFeedback?.cltDriver?.driver?.firstname} ${passiveFeedback?.cltDriver?.driver?.lastname}` : passiveFeedback?.internClt ? passiveFeedback?.internClt.name : '-',
    cpf: passiveFeedback?.cltDriver ? `${passiveFeedback?.cltDriver?.driver?.cpf} ` : passiveFeedback?.internClt ? passiveFeedback?.internClt.cpf : '-',
    email: passiveFeedback?.cltDriver ? `${passiveFeedback?.cltDriver?.driver?.email} ` : passiveFeedback?.internClt ? passiveFeedback?.internClt.email : '-',
    rg: passiveFeedback?.cltDriver ? `${passiveFeedback?.cltDriver?.rg} ` : passiveFeedback?.internClt ? passiveFeedback?.internClt.rg : '-',
    regional: passiveFeedback?.internClt.regional === 'other' ? 'Outra' : passiveFeedback?.internClt.regional.toUpperCase(),
    deadline: passiveFeedback.deadline
      ? format(new Date(passiveFeedback.deadline), 'dd/MM/yyyy')
      : '-',
    type: passiveFeedback?.type === 'initial' ? 'Inicial' : 'Recorrente',
    status: passiveFeedbackStatusMap[passiveFeedback?.status]
  }))
}

export function PassiveFeedbacksTable() {

  const { replace, push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userCanViewPassivesFeedbacks = userLogged?.permissions?.includes(
    'view-passives-feedbacks'
  )

  const userCanCreatePassiveFeedback = userLogged?.permissions?.includes(
    'create-passive-feedback'
  )

  useEffect(() => {
    if (!userCanViewPassivesFeedbacks) redirect('/')
  }, [redirect, userCanViewPassivesFeedbacks])

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'

  const { data: passiveFeedbacksResult } = useQuery({
    queryKey: [
      'passives-feedbacks',
      page,
      status,

    ],
    queryFn: () => getPassivesFeedbacks({
      currentPage: Number(page),
      pageSize: 10,
      status: status as PassiveFeedbackStatus | 'all'
    }),
    keepPreviousData: true
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: passiveFeedbacksDataReportResult,
    isLoading: isLoadingPassiveFeedbacksDataReportResult
  } = useQuery({
    queryKey: [
      'passives-feedbacks-report',
      page,
      status,
      passiveFeedbacksResult?.meta?.count
    ],
    queryFn: () => getPassivesFeedbacks({
      currentPage: 1,
      pageSize: passiveFeedbacksResult?.meta?.count,
      status: ["scheduled", "done"].includes(status) ? status as PassiveFeedbackStatus : 'all'
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })


  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: passiveFeedbacksResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: passiveFeedbacksResult?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreatePassiveFeedbackOpen,
    onOpen: onOpenCreatePassiveFeedback,
    onClose: onCloseCreatePassiveFeedback
  } = useDisclosure()

  const csvReportProps = {
    data: passiveFeedbacksDataReportResult ? formatValuesToReport(passiveFeedbacksDataReportResult?.passivesFeedbacks) : [],
    headers,
    filename: `feedbaks-passivos.csv`,
  }


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Feedbacks Passivos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/feedbacks-passivos/kanban')}
          />
          {userCanCreatePassiveFeedback && (
            <IconButton
              aria-label="Criar feedback"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreatePassiveFeedback}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreatePassiveFeedbackOpen}
          onClose={onCloseCreatePassiveFeedback}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreatePassiveFeedback onCloseModal={onCloseCreatePassiveFeedback} />
        </Modal>
      </Flex>
      <PassivesFeedbacksTableFilter />

      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Status</Th>
              <Th color="white">Data Feedback</Th>
            </Tr>
          </Thead>
          <Tbody>
            {passiveFeedbacksResult?.passivesFeedbacks?.map((passiveFeedback) => {
              return (
                <PassiveFeedbackTableRow
                  key={passiveFeedback.id}
                  passiveFeedback={passiveFeedback}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!passiveFeedbacksDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingPassiveFeedbacksDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

import { Box, Button, Flex, Grid, Heading, Icon, IconButton } from "@chakra-ui/react";
import { getUserNotifications, GetUserNotificationsResponse } from "api/users/getUserNotifications";
import { Empty } from "components/Empty";
import { Notification } from "components/Notification";
import { FaCheck } from "react-icons/fa6";
import { RiExternalLinkFill } from "react-icons/ri";
import { GoInfo } from "react-icons/go";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { readUserNotification } from "api/users/readUserNotification";
import { formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAuth } from "hooks/auth/useAuth";

interface ReadUserNotificationProps {
  userId: string
  notificationId: string
}

export function Notifications() {
  const { userLogged } = useAuth()

  const {
    data: result,
    isError: isResultError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam = 1 }) => getUserNotifications({
      currentPage: pageParam.toString(),
      pageSize: '10'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const queryClient = useQueryClient()

  const {
    mutateAsync: readUserNotificationFn,
    isLoading: isReadUserNotificationLoading
  } = useMutation({
    mutationFn: readUserNotification,
    onSuccess: (_data, { body }) => {
      queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

      const cached = queryClient.getQueriesData<{ pages: GetUserNotificationsResponse[] }>({
        queryKey: ['notifications'],
      })

      cached.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) return

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          pages: cachedData.pages.map((page) => {
            return {
              ...page,
              notifications: page.notifications.map((notification) => {
                if (notification.notification.id === body.notificationId) {

                  return { ...notification, is_read: true }
                }

                return notification
              })
            }
          })
        })
      })
    }
  })

  async function handleReadUserNofication({
    notificationId,
    userId
  }: ReadUserNotificationProps) {
    await readUserNotificationFn({
      body: { notificationId },
      routeParams: { userId }
    })
  }

  const handleReloadPage = () => window.location.reload()

  if (isResultError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (
    <Box
      rounded="md"
      p="6"
      bg="white"
    >
      <Heading letterSpacing="tight">Notificações</Heading>

      <Grid
        mt="6"
        templateColumns="repeat(1 1fr)"
        w="full"
      >
        {result?.pages?.map((page) => {
          return page?.notifications.map((notification) => {
            return (
              <Notification.Root
                _notFirst={{ mt: '1' }}
                _first={{ borderTopRadius: 'lg' }}
                _last={{ borderBottomRadius: 'lg' }}
                bg="blackAlpha.50"
                key={notification.notification.id}
                cursor={notification.notification.link ? 'pointer' : 'normal'}
                position="relative"
              >
                {notification.notification.link ? (
                  <Flex w="full" align="center" gap="2" p="4">
                    <Notification.Icon
                      icon={GoInfo}
                      fontSize="3xl"
                      isRead={notification.is_read}
                    />
                    <Box w="full" maxW="800px">
                      <Link to={notification.notification.link}>
                        <Notification.Title fontSize="md" title={notification.notification.title} />
                        <Notification.Message fontSize="sm" message={
                          `${notification.notification.message} - ${formatDistance(new Date(notification.created_at), new Date(), { addSuffix: true, locale: ptBR })}`
                        } />
                      </Link>
                    </Box>

                    <Notification.Actions
                      flex="1"
                      justifyContent="center"
                    >
                      {!notification.is_read && (
                        <IconButton
                          aria-label=""
                          icon={<FaCheck />}
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleReadUserNofication({
                            notificationId: notification.notification.id,
                            userId: userLogged?.id
                          })}
                          isDisabled={isReadUserNotificationLoading}
                        />
                      )}
                    </Notification.Actions>

                    <Icon
                      position="absolute"
                      as={RiExternalLinkFill}
                      right="1"
                      top="1"
                      fontSize="lg"
                    />
                  </Flex>

                ) : (
                  <Flex w="full" align="center" gap="2" p="4">
                    <Notification.Icon
                      icon={GoInfo}
                      fontSize="3xl"
                      isRead={notification.is_read}
                    />
                    <Box w="full" maxW="800px">
                      <Notification.Title fontSize="md" title={notification.notification.title} />
                      <Notification.Message fontSize="sm" message={
                        `${notification.notification.message} - ${formatDistance(new Date(notification.created_at), new Date(), { addSuffix: true, locale: ptBR })}`
                      } />
                    </Box>

                    <Notification.Actions
                      flex="1"
                      justifyContent="center"
                    >
                      {!notification.is_read && (
                        <IconButton
                          aria-label=""
                          icon={<FaCheck />}
                          size="sm"
                          colorScheme="blue"
                          onClick={() => handleReadUserNofication({
                            notificationId: notification.notification.id,
                            userId: userLogged?.id
                          })}
                          isDisabled={isReadUserNotificationLoading}
                        />
                      )}
                    </Notification.Actions>
                  </Flex>
                )}
              </Notification.Root>
            )
          })
        })}

        {hasNextPage && (
          <Button mt="3" variant="ghost" onClick={() => fetchNextPage()}>Carregar Mais</Button>
        )}
      </Grid>

    </Box>
  )
}

import { Button, Flex, Icon, Select, Text } from "@chakra-ui/react"
import { AuditStatus } from "api/audits/_types/Audit"
import { useSearchParams } from "hooks/useSearchParams"
import { useForm } from "react-hook-form"
import { FaSearch, FaTimes } from "react-icons/fa"
import { useHistory } from "react-router-dom"
import { auditStatusMap } from "utils/auditMappers"
import { auditOptions } from "./CreateAudit"

interface AuditTableFilterSchema {
  status: AuditStatus | 'all'
  type?: string
}

export function AuditTableFilters() {
  const { replace } = useHistory()
  const searchParams = useSearchParams()

  const status = searchParams.get('status') ?? 'all'
  const type = searchParams.get('type')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<AuditTableFilterSchema>({
    defaultValues: {
      status: status as AuditStatus | 'all',
      type: type ?? ''
    }
  })

  function handleSearchPmcs({ status, type }: AuditTableFilterSchema) {
    if (status) {
      searchParams.set('status', status)
    } else {
      searchParams.delete('status')
    }

    if (type) {
      searchParams.set('type', type)
    } else {
      searchParams.delete('type')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('status')
    searchParams.delete('type')

    reset({ status: 'all' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      as="form"
      align="center"
      direction={["column", "column", "row"]}
      gap="2"
      mt="4"
      onSubmit={handleSubmit(handleSearchPmcs)}
    >
      <Text alignSelf={["flex-start", "flex-start", "center"]} fontSize="sm">
        Filtros:
      </Text>

      <Select
        {...register('status')}
        defaultValue="all"
        size="sm"
        borderRadius="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        {Object.entries(auditStatusMap).map(([key, value]) => {
          return (
            <option key={key} value={key}>{value}</option>
          )
        })}
      </Select>

      <Select
        {...register('type')}
        name="type"
        placeholder="Tipo de auditoria..."
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        {Object.entries(auditOptions).map(([key, value]) => (
          <option key={key} value={value}>{value}</option>
        ))}
      </Select>

      <Button
        type="submit"
        size="sm"
        leftIcon={<Icon as={FaSearch} />}
        w={['full', 'full', 'min']}
      >
        Filtrar resultados
      </Button>

      <Button
        type="submit"
        size="sm"
        variant="outline"
        leftIcon={<Icon as={FaTimes} />}
        w={['full', 'full', 'min']}
        onClick={handleRemoveFilters}
      >
        Remover filtros
      </Button>
    </Flex>
  )
}

import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Socket } from "socket.io-client";
import { socketApi } from "../services/socket/socket";

interface PmcSocketContextProps {
  pmcSocketConnection: Socket | null
}

interface PmcSocketProviderProps {
  children: ReactNode
}

interface CreatedPmcNotificationData {
  message: string
  profiles: string[]
}

export const PmcSocketContext = createContext({} as PmcSocketContextProps)

export function PmcSocketProvider({ children }: PmcSocketProviderProps) {
  const [pmcSocketConnection, setPmcSocketConnection] = useState<Socket | null>(null)

  const { infoMessage } = useToastify()
  const { userLogged } = useAuth()

  const queryClient = useQueryClient()

  useEffect(() => {
    setPmcSocketConnection(socketApi.connect("pmc"))
  }, [])

  useEffect(() => {
    pmcSocketConnection?.on("pmcCreatedNotification", async ({
      message,
      profiles
    }: CreatedPmcNotificationData) => {
      const isUserLoggedNotification = profiles.includes(userLogged?.user_type)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    pmcSocketConnection?.on("pmcApprovedNotification", async ({
      message,
      profiles
    }: CreatedPmcNotificationData) => {
      const isUserLoggedNotification = profiles.includes(userLogged?.user_type)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    return () => {
      pmcSocketConnection?.off("pmcCreatedNotification")
      pmcSocketConnection?.off("pmcApprovedNotification")
    }
  }, [pmcSocketConnection, queryClient, infoMessage, userLogged])

  return (
    <PmcSocketContext.Provider value={{
      pmcSocketConnection,
    }}>
      {children}
    </PmcSocketContext.Provider>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Vacation, VacationStatus } from "./_types/Vacation";
import { VacationPeriod } from "./_types/VacationPeriod";

interface GetVacationsParams {
  pageSize: number
  currentPage: number
  status: VacationStatus | 'all'
  collaboratorName?: string | null
}

export interface GetVacationsResponse {
  vacations: Array<Vacation & {
    periods: VacationPeriod[]
  }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
    count: number
  }
}

export async function getVacations({
  pageSize,
  currentPage,
  status,
  collaboratorName
}: GetVacationsParams) {
  try {
    const { data } = await api.get<GetVacationsResponse>('/vacations', {
      params: {
        pageSize,
        currentPage,
        status,
        collaboratorName,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


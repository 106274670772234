import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getPmcs } from "api/pmcs/getPmcs";
import { Pagination } from "components/Pagination/Pagination";

import { useSearchParams } from "hooks/useSearchParams";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { PmcsTableFilter } from "./components/PmcsTableFilter";
import { PmcsTableRow } from "./components/PmcsTableRow";
import { PmcStatusTypes } from "./components/PmcStatus";

export function Pmcs() {
  const { replace } = useHistory()

  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const protocol = searchParams.get('protocol')
  const status = searchParams.get('status') as PmcStatusTypes

  const { data: pmcsData, isLoading: isPmcsLoading } = useQuery(
    ['pmcs', page, protocol, status],
    () => getPmcs({
      currentPage: page,
      pageSize: '10',
      status: status,
      protocol: protocol
    }),
  )

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: pmcsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: pmcsData?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (

      <Box
        bg="white"
        borderRadius="md"
        p="4"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Heading letterSpacing="tight">Pmcs</Heading>

          <PmcsTableFilter />
          {isPmcsLoading ? (
            <Spinner />
          ) : (
            <TableContainer
              rounded="md"
              border="1px solid"
              borderColor="gray.100"
            >
              <Table
                size="sm"
              >
                <Thead
                  h="40px"
                  bg="#38c3fa"
                >
                  <Tr>
                    <Th w="150px"></Th>
                    <Th w="200px" textColor="white">Protocolo</Th>
                    <Th w="200px" textColor="white">Status</Th>
                    <Th w="400px" textColor="white">Almoxarifado</Th>
                    <Th w="200px" textColor="white">Data da solicitação</Th>
                    <Th></Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pmcsData?.pmcs?.map((pmc) => {
                    return (
                      <PmcsTableRow key={pmc.id} pmc={pmc} />
                    )
                  })}
                </Tbody>
              </Table>

            </TableContainer>
          )}


          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />

        </Flex>

      </Box>


  )
}

import { Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";

interface IKanbanCardItemProps {
  icon: IconType
  content: string
  isLink?: boolean
  url?: string
}

export function KanbanCardItem({ icon, content, isLink, url = '' }: IKanbanCardItemProps) {
  return (
    <Flex gap="2" align="flex-start">
      {isLink ? (
        <Link style={{ width: '100%' }} to={url}>
          <Stack direction="row" transition="0.2s" _hover={{ opacity: 0.8 }}>
            <Icon as={icon} mt={1.5} />
            <Text
              lineHeight={1.8}
            >
              {content}
            </Text>
          </Stack>
        </Link>
      ) : (
        <>
          <Icon as={icon} mt={1.5} />
          <Text
            lineHeight={1.8}
          >
            {content}
          </Text>
        </>
      )}
    </Flex>
  )
}

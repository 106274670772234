import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

interface IKanbanCardProps {
  children: ReactNode
}

export function KanbanCard({ children }: IKanbanCardProps) {

  return (
    <Box
      bgColor="white"
      maxW={400}
      borderRadius="8px"
    >
      {children}
    </Box>
  )
}

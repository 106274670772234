import { add, set, sub } from "date-fns"
import { UsePlannedNetworkFormattedData } from "hooks/usePlannedRoutes"
import { formatDate } from "utils/DateFunctions/formatDate"

export function findPlannedRouteInfoAndFormatAccordinglyWithModal(
  routeNetworks: UsePlannedNetworkFormattedData[],
  truckFlightSelected: string,
  modal: 'AÉREO' | 'RODOVIÁRIO'
) {

  const routeNetwork = routeNetworks?.find(routeNetwork => {
    return routeNetwork.value_filter === truckFlightSelected
  })

  if (routeNetwork) {
    const routeDifferenceHours = modal === 'AÉREO' ? 2 : 1

    const [routeArrivalHours, routeArrivalMinutes] = formatDate
      .handle(new Date(routeNetwork.arrival_hour), 'DateOnlyWithHourMinute')
      .split(':')
      .map(time => Number(time))

    const arrivalHour =
      formatDate.handle(add(new Date(routeNetwork.arrival_hour), {
        hours: routeDifferenceHours
      }), 'DateOnlyWithHourMinute')

    const arrivalDate = formatDate.handle(add(set(new Date(routeNetwork.arrival_date), {
      hours: routeArrivalHours,
      minutes: routeArrivalMinutes
    }), {
      hours: routeDifferenceHours
    }), 'DateWithoutHourToInput')

    const [routeDepartureHours, routeDepartureMinutes] = formatDate
      .handle(new Date(routeNetwork.departure_hour), 'DateOnlyWithHourMinute')
      .split(':')
      .map(time => Number(time))

    const departureHour = formatDate
      .handle(sub(new Date(routeNetwork.departure_hour), {
        hours: routeDifferenceHours
      }), 'DateOnlyWithHourMinute')

    const departureDate = formatDate.handle(sub(set(new Date(routeNetwork.departure_date), {
      hours: routeDepartureHours,
      minutes: routeDepartureMinutes
    }), {
      hours: routeDifferenceHours
    }), 'DateWithoutHourToInput')

    return {
      arrivalDate,
      arrivalHour,
      departureHour,
      departureDate
    }
  }
  return undefined
}

import axios from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { ProductivityReportResponse } from "./dtos/ProductivityReport";

interface AggregatesReportQueryParams {
  collector_id: string
  start_filter: string
  end_filter: string
}

type QueryOptions = UseQueryOptions<ProductivityReportResponse, unknown, ProductivityReportResponse, QueryKey>

interface UseProdutivityReportOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: AggregatesReportQueryParams
}

async function generateProductivityReportReqFunction({
  collector_id,
  start_filter,
  end_filter
}: AggregatesReportQueryParams) {
  try {

    const { data } = await api.get(`/report/productivity`, {
      params: {
        collector_id,
        start_filter,
        end_filter
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useProdutivityReport(options?: UseProdutivityReportOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery([
    options?.queryKey ?? "productivityReport",
    { ...options?.queryParams }
  ], () => generateProductivityReportReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, Flex, FormLabel, Grid, Heading, Icon, Link, Menu, MenuButton, Spinner, Text, Textarea, useDisclosure } from "@chakra-ui/react";

import { useAuth } from "hooks/auth/useAuth";
import { Bug } from "hooks/bugs/dtos/Bug";
import { useBugs } from "hooks/bugs/useBugs";
import { useBugsFunctions } from "hooks/bugs/useBugsFunctionts";
import { useBugSocket } from "hooks/bugs/useBugSocket";
import { useToastify } from "hooks/toastify/useToastify";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { FocusEvent, useRef, useState } from "react";
import { FaExternalLinkAlt, FaUserPlus } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { RequestError } from "utils/errors/RequestErrors";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { BugActionsMenu } from "./components/BugActionsMenu";
import { BugCard } from "./components/BugCard";
import { BugCardContentSubject } from "./components/BugCard/BugCardContentSubject";
import { SearchAssignedUsers } from "./components/SearchAssignedUsers";
import { ValidateBugActionButton } from "./components/ValidateBugActionButton";
import { ViewBugModal } from "./components/ViewBugModal";
import { ViewBugModalContentHeader } from "./components/ViewBugModal/ViewBugModalHeader";

export function BugsList() {
  const [selectedBugId, setSelectedBugId] = useState('')

  const { userLogged } = useAuth()

  const {
    data: openBugsData,
    hasNextPage: hasNextOpenBugsPage,
    fetchNextPage: fetchNextOpenBugsPage,
    isFetching: isFetchingOpenBugs,
  } = useBugs({
    queryOptions: {
      getNextPageParam: (prePages, pages) => {
        if (pages.length < pages[0].totalPages) {
          return pages.length + 1
        } else {
          return undefined
        }
      },
      refetchOnWindowFocus: false,
    },
    queryParams: {
      status: 'open',
      pageSize: 5
    },
    queryKey: 'openBugs'
  })

  const {
    data: validatedBugsData,
    hasNextPage: hasNextValidatedBugsPage,
    fetchNextPage: fetchNextValidatedBugsPage,
    isFetching: isFetchingValidatedBugs,
  } = useBugs({
    queryOptions: {
      getNextPageParam: (prePages, pages) => {
        if (pages.length < pages[0].totalPages) {
          return pages.length + 1
        } else {
          return undefined
        }
      },
      refetchOnWindowFocus: false,
    },
    queryParams: {
      status: 'validated',
      pageSize: 5
    },
    queryKey: 'validatedBugs'
  })

  const {
    data: usersData,
    isFetching: isFetchingUsersData
  } = useLLMUsers()

  const openBugsDivRef = useRef<HTMLDivElement | null>(null)

  async function handleFetchNextOpenBugs() {
    const div = openBugsDivRef.current

    if (div) {
      const isScrollAtBottom = Math.round(div.scrollHeight - div.scrollTop) === div.clientHeight

      if (isScrollAtBottom && hasNextOpenBugsPage) {
        console.log('asoid')
        await fetchNextOpenBugsPage()
      }
    }
  }

  const validatedBugsDivRef = useRef<HTMLDivElement | null>(null)

  async function handleFetchNextValidatedBugs() {
    const div = openBugsDivRef.current

    if (div) {
      const isScrollAtBottom = div.scrollHeight - div.scrollTop === div.clientHeight

      if (isScrollAtBottom && hasNextValidatedBugsPage) {
        await fetchNextValidatedBugsPage()
      }
    }
  }

  const {
    validateBug,
    removeBugValidation,
    editBugSubject,
    editBugDescription,
    createBugComment,
    deleteBug,
    assignUserToBug,
    removeAssignedUserFromBug,
  } = useBugsFunctions()

  const queryClient = useQueryClient()
  const { errorMessage } = useToastify()

  const { bugSocketConnection } = useBugSocket()

  async function handleValidateBug(bugId: string) {
    await validateBug.mutateAsync(bugId, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('openBugs')
        queryClient.invalidateQueries('validatedBugs')

        bugSocketConnection.emit('bugValidated', {
          assignedUsers: data.bug.assignedUsers.map((d) => d.id),
          subject: data.bug.subject
        })
      },
      onError: (error: RequestError) => {
        errorMessage(error.message)
      }
    })
  }

  async function handleRemoveBugValidation(bugId: string) {
    await removeBugValidation.mutateAsync(bugId, {
      onSuccess: (data) => {
        queryClient.invalidateQueries('openBugs')
        queryClient.invalidateQueries('validatedBugs')

        bugSocketConnection.emit('bugValidationRemoved', {
          assignedUsers: data.bug.assignedUsers.map((d) => d.id),
          subject: data.bug.subject
        })
      },
      onError: (error: RequestError) => {
        errorMessage(error.message)
      }
    })
  }

  async function handleDeleteBug(bugId: string, status: 'open' | 'validated') {
    await deleteBug.mutateAsync(bugId, {
      onSuccess: () => {
        if (status === 'open') {
          queryClient.invalidateQueries('openBugs')
        }

        if (status === 'validated') {
          queryClient.invalidateQueries('validatedBugs')
        }
      },
      onError: (error: RequestError) => {
        errorMessage(error.message)
      }
    })
  }

  const {
    isOpen: isViewBugModalOpen,
    onOpen: onOpenViewBugModal,
    onClose: onCloseViewBugModal,
  } = useDisclosure()

  const bugs = openBugsData
    ? openBugsData?.pages.flatMap((page) => page.bugs).concat(validatedBugsData?.pages.flatMap((page) => page.bugs))
    : []

  const selectedBug = bugs?.find((bug) => bug?.id === selectedBugId ?? '')

  const selectedBugStatus = selectedBug?.validated_at ? 'validated' : 'open'

  function handleOpenViewBugModal(bug: Bug) {
    setSelectedBugId(bug.id)

    onOpenViewBugModal()
  }

  async function handleEditBugSubject(
    event: FocusEvent<HTMLInputElement>,
    status: 'open' | 'validated'
  ) {
    if (event.target.value !== selectedBug?.subject) {
      await editBugSubject.mutateAsync({
        bugId: selectedBugId,
        requestBody: {
          subject: event.target.value
        }
      }, {
        onSuccess: (data) => {
          if (status === 'open') {
            queryClient.invalidateQueries('openBugs')
          }

          if (status === 'validated') {
            queryClient.invalidateQueries('validatedBugs')
          }

          bugSocketConnection.emit('bugSubjectUpdated', {
            assignedUsers: data.bug.assignedUsers.map((d) => d.id),
            subject: data.bug.subject
          })
        },
        onError: (error: RequestError) => {
          errorMessage(error.message)
        }
      })
    }
  }

  async function handleEditBugDescription(
    event: FocusEvent<HTMLTextAreaElement>,
    status: 'open' | 'validated'
  ) {

    console.log('blur event')

    if (event.target.value !== selectedBug?.description) {
      await editBugDescription.mutateAsync({
        bugId: selectedBugId,
        requestBody: {
          description: event.target.value
        }
      }, {
        onSuccess: (data) => {
          if (status === 'open') {
            queryClient.invalidateQueries('openBugs')
          }

          if (status === 'validated') {
            queryClient.invalidateQueries('validatedBugs')
          }

          bugSocketConnection?.emit('bugDescriptionUpdated', {
            assignedUsers: data.bug.assignedUsers.map((d) => d.id),
            subject: data.bug.subject
          })
        },
        onError: (error: RequestError) => {
          errorMessage(error.message)
        }
      })
    }
  }

  async function handleCreateBugComment(content: string, status: 'open' | 'validated') {
    await createBugComment.mutateAsync({
      bugId: selectedBugId,
      requestBody: {
        content,
      }
    }, {
      onSuccess: (data) => {
        if (status === 'open') {
          queryClient.invalidateQueries('openBugs')
        }

        if (status === 'validated') {
          queryClient.invalidateQueries('validatedBugs')
        }

        bugSocketConnection.emit('bugCommentCreated', {
          assignedUsers: data.bug.assignedUsers.map((d) => d.id),
          subject: data.bug.subject
        })
      },
      onError: (error: RequestError) => {
        errorMessage(error.message)
      }
    })
  }

  async function handleAssignUserToBug(
    {
      bugId,
      userId,
    }: {
      bugId: string,
      userId: string
    },
    status: 'open' | 'validated'
  ) {
    await assignUserToBug.mutateAsync({
      bugId,
      userId,
    }, {
      onSuccess: () => {
        if (status === 'open') {
          queryClient.invalidateQueries('openBugs')
        }

        if (status === 'validated') {
          queryClient.invalidateQueries('validatedBugs')
        }
      },
      onError: (error: RequestError) => {
        errorMessage(error.message)
      }
    })
  }

  async function handleRemoveAssignedUserFromBug(
    {
      bugId,
      userId,
    }: {
      bugId: string,
      userId: string
    },
    status: 'open' | 'validated'
  ) {
    await removeAssignedUserFromBug.mutateAsync({
      bugId,
      userId,
    }, {
      onSuccess: () => {
        if (status === 'open') {
          queryClient.invalidateQueries('openBugs')
        }

        if (status === 'validated') {
          queryClient.invalidateQueries('validatedBugs')
        }
      },
      onError: (error: RequestError) => {
        errorMessage(error.message)
      }
    })
  }

  return (

      <Box
        p="6"
        bgColor="white"
        rounded="md"
        boxShadow="md"
      >
        <ViewBugModal.Root
          isOpen={isViewBugModalOpen}
          onClose={onCloseViewBugModal}
          bug={selectedBug}
          size={["sm", "sm", "xl"]}
        >
          <ViewBugModal.Content>
            <ViewBugModalContentHeader
              subject={selectedBug?.subject}
              onUpdateBugSubject={handleEditBugSubject}
              status={selectedBugStatus}
              bugLastUpdatedById={selectedBug?.updatedBy?.id}
              bugLastUpdatedByName={`${selectedBug?.updatedBy?.firstname} ${selectedBug?.updatedBy?.lastname}`}
              bugLastUpdatedDate={selectedBug?.updated_at}
              bugValidationDate={selectedBug?.validated_at}
              bugCreatedByName={`${selectedBug?.createdBy?.firstname} ${selectedBug?.createdBy?.lastname}`}
              onValidateBug={() => handleValidateBug(selectedBugId)}
              onRemoveBugValidation={() => handleRemoveBugValidation(selectedBugId)}
            />

            <Menu>
              <MenuButton
                role="group"
              >
                <Flex
                  gap="1"
                  align="center"
                  cursor="pointer"
                >
                  <Box
                    rounded="full"
                    bg="transparent"
                    _groupHover={{
                      bg: "gray.100"
                    }}
                  >
                    <FaUserPlus />
                  </Box>
                  {selectedBug?.assignedUsers.length ? (
                    selectedBug?.assignedUsers.map((assignedUser) => {
                      return (
                        <Avatar
                          key={assignedUser.id}
                          size="xs"
                          name={`${assignedUser.firstname} ${assignedUser.lastname}`}
                        />
                      )
                    })
                  ) : (
                    <Text fontSize="xs">Atribuir</Text>
                  )}
                </Flex>
              </MenuButton>

              <SearchAssignedUsers
                assignedUsers={selectedBug?.assignedUsers}
                bugId={selectedBug?.id}
                isFetchingUsersData={isFetchingUsersData}
                onAssignUserToBug={handleAssignUserToBug}
                onRemoveAssignedUserFromBug={handleRemoveAssignedUserFromBug}
                status={selectedBugStatus}
                users={usersData?.users}
              />
            </Menu>

            <Flex
              mt="4"
              direction="column"
            >
              <FormLabel fontSize="xs" fontWeight="bold">Descrição</FormLabel>
              <Textarea
                h="100px"
                size="xs"
                defaultValue={selectedBug?.description}
                onBlur={(event) => handleEditBugDescription(event, selectedBugStatus)}
                isReadOnly={!userLogged?.permissions.includes('update-bug')}
              />

              {selectedBug?.attachments?.map((attachment) => {
                return (
                  <Button
                    mt="4"
                    as={Link}
                    href={handleChangeUrl(attachment.key)}
                    variant="outline"
                  >
                    <Text  mr="4" fontSize='sm'>{attachment.filename}</Text>
                    <Icon as={FaExternalLinkAlt} />
                  </Button>
                )
              })}

            </Flex>

            <Grid
              mt="4"
              templateColumns="repeat(1, 1fr)"
            >
              <Text fontSize="xs" fontWeight="bold">Comentários</Text>

              <ViewBugModal.CommentForm
                onSubmitCommentForm={handleCreateBugComment}
                status={selectedBug?.validated_at ? 'validated' : 'open'}
              />

              {selectedBug?.comments.map((comment) => {
                return (
                  <ViewBugModal.Comment
                    key={comment.id}
                    name={`${comment.createdBy.firstname} ${comment.createdBy.lastname}`}
                    createdAt={comment.created_at}
                    content={comment.content}
                  />
                )
              })}
            </Grid>

          </ViewBugModal.Content>
        </ViewBugModal.Root>

        <Heading size="base">Problemas do Sistema (Bugs)</Heading>

        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}
          gap="2"
          mt="4"
          maxH="150px"
          ref={openBugsDivRef}
          onScroll={handleFetchNextOpenBugs}
          overflow="auto"
          p="4"
        >

          {isFetchingOpenBugs ? (
            <Spinner />
          ) : (
            openBugsData?.pages.map((page) => {
              return page.bugs.map((bug) => {
                return (
                  <BugCard.Root
                    key={bug.id}
                    position="relative"
                  >
                    <BugActionsMenu.Root
                      placement="left-start"
                    >
                      <BugActionsMenu.Trigger
                        aria-label="Abrir menu de ações"
                        position="absolute"
                        right="2"
                        top="1"
                        variant="ghost"
                      />
                      <BugActionsMenu.List
                        onDeleteBug={() => handleDeleteBug(bug.id, 'open')}
                      />
                    </BugActionsMenu.Root>
                    <BugCard.Content
                      cursor="pointer"
                      onClick={() => handleOpenViewBugModal(bug)}
                    >
                      <BugCardContentSubject
                        subject={bug.subject}
                      >
                        <ValidateBugActionButton.Root
                          aria-label="Validar Bug"
                          onClick={() => handleValidateBug(bug.id)}
                        />
                      </BugCardContentSubject>
                    </BugCard.Content>
                  </BugCard.Root>
                )
              })
            })
          )}



        </Grid>

        <Accordion allowMultiple mt="4">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Flex
                  w="full"
                  align="center"
                  gap="4"
                >
                  <Box
                    as="span"
                    textAlign="left"
                  >
                    Finalizadas
                  </Box>
                  <Text
                    w="5"
                    h="5"
                    border="1px solid"
                    rounded="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="sm"
                    borderColor="gray.200"
                    bgColor="blue.50"
                  >
                    {validatedBugsData?.pages[0].count}
                  </Text>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>

              <Grid
                templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}
                gap="2"
                mt="4"
                maxH="150px"
                ref={validatedBugsDivRef}
                onScroll={handleFetchNextValidatedBugs}
                overflow="auto"
                p="4"
              >

                {isFetchingValidatedBugs ? (
                  <Spinner />
                ) : (
                  validatedBugsData?.pages.map((page) => {
                    return page.bugs.map((bug) => {
                      return (
                        <BugCard.Root
                          key={bug.id}
                          position="relative"
                        >
                          <BugActionsMenu.Root
                            placement="left-start"
                          >
                            <BugActionsMenu.Trigger
                              aria-label="Abrir menu de ações"
                              position="absolute"
                              right="2"
                              top="1"
                              variant="ghost"
                            />
                            <BugActionsMenu.List
                              onDeleteBug={() => handleDeleteBug(bug.id, 'validated')}
                            />
                          </BugActionsMenu.Root>
                          <BugCard.Content
                            cursor="pointer"
                            onClick={() => handleOpenViewBugModal(bug)}
                          >
                            <BugCardContentSubject
                              subject={bug.subject}
                            >
                              <ValidateBugActionButton.Root
                                aria-label="Validar Bug"
                                aria-checked={true}
                                onClick={() => handleRemoveBugValidation(bug.id)}
                              />
                            </BugCardContentSubject>
                          </BugCard.Content>
                        </BugCard.Root>
                      )
                    })
                  })
                )}



              </Grid>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

      </Box>

  )
}

import { Box, FormControl, FormLabel, Input, Select, Stack, Text } from "@chakra-ui/react";
import { User } from "hooks/user/dtos/User";
import { useFormContext } from "react-hook-form";

export interface RegionalFormSchema {
  name: string
  code: string
  responsibleId: string
}

interface RegionalFormProps {
  llmUsers: User[]
}

export function RegionalForm({ llmUsers }: RegionalFormProps) {

  const { register, formState: { errors } } = useFormContext<RegionalFormSchema>()

  return (
    <Box w="full">
      <Stack
        w="full"
        spacing={3}
      >
        <FormControl isInvalid={!!errors.name}>
          <FormLabel>
            Nome
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('name')}
            name="name"
          />
        </FormControl>

        <FormControl isInvalid={!!errors.code}>
          <FormLabel>
            Código
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('code')}
            name="code"
          />
        </FormControl>
      </Stack>

      <FormControl isInvalid={!!errors.code} mt={3}>
        <FormLabel>
          Responsável
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('responsibleId')}
          name="responsibleId"
          placeholder="Selecione um usuário"
        >
          {llmUsers?.map((user) => {
            return <option key={user.id} value={user.id}>{`${user.firstname} ${user.lastname}`}</option>
          })}
        </Select>
      </FormControl>

    </Box>
  )
}

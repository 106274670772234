import { HStack, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { CltDriver } from "hooks/cltDriver/dtos/CltDriver";
import { useCltDriverFunctions } from "hooks/cltDriver/useCltDriverFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { FaSearch } from "react-icons/fa";
import { FaPen, FaTrash } from "react-icons/fa6";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { captalize } from "utils/captalize";
import { CltDriverDetail } from "./CltDriverDetail";
import { CltDriverStatus } from "./CltDriverStatus";

interface CltDriverTableRowProps {
  cltDriver: CltDriver
}

export function CltDriverTableRow({ cltDriver }: CltDriverTableRowProps) {
  const { userLogged } = useAuth()

  const {
    deleteCltDriver: {
      mutateAsync: deleteCltDriver
    }
  } = useCltDriverFunctions()

  const driverFullName = captalize(`
    ${cltDriver?.driver.firstname} ${cltDriver?.driver.lastname}
  `)

  const doesUserCanEditCltDriver = userLogged?.permissions.includes('edit-clt-driver')
  const doesUserCanDeleteCltDriver = userLogged?.permissions.includes('delete-clt-driver')

  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  async function handleDeleteCltDriver(id: string) {
    await promiseMessage(deleteCltDriver(id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['cltDrivers'])
      }
    }), 'Motorista CLT excluído com sucesso!')
  }

  const {
    isOpen: isCltDriverDetailModalOpen,
    onOpen: handleOpenCltDriverDetailModal,
    onClose: handleCloseCltDriverDetailModal,
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <HStack spacing={3}>
          <IconButton
            size="sm"
            variant="ghost"
            aria-label=""
            icon={<FaSearch />}
            onClick={handleOpenCltDriverDetailModal}
          />

          <Modal
            isOpen={isCltDriverDetailModalOpen}
            onClose={handleCloseCltDriverDetailModal}
            size='6xl'
          >
            <ModalOverlay />
            <CltDriverDetail
              cltDriverId={cltDriver.id}
              onClose={handleCloseCltDriverDetailModal}
            />
          </Modal>

          {doesUserCanEditCltDriver && (
            <IconButton
              as={Link}
              size="sm"
              variant="ghost"
              aria-label=""
              icon={<FaPen />}
              to={`/motorista-clt/editar/${cltDriver.id}`}
            />
          )}
        </HStack>
      </Td>
      <Td>{driverFullName}</Td>
      <Td>{cltDriver?.regional}</Td>
      <Td>
        <CltDriverStatus status={cltDriver?.status} />
      </Td>
      <Td>
        {doesUserCanDeleteCltDriver && (
          <IconButton
            size="sm"
            variant="outline"
            aria-label=""
            icon={<FaTrash />}
            onClick={() => handleDeleteCltDriver(cltDriver.id)}
          />
        )}
      </Td>
    </Tr>
  )
}

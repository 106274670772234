import { VaccineCollaboratorType, VaccineType, DoseVaccineType, VaccinePendingType, VaccineStatus } from './../api/vaccines/vaccine';

export const vaccineCollaboratorTypeMap: Record<VaccineCollaboratorType, string>  = {
  llm: 'Funcionário LLM',
  collector: 'Coletador',
  carrier: 'Portador',
  "clt-driver": 'Motorista CLT',
  "aggregate-driver": 'Agregado',
}

export const vaccineTypesMap: Record<VaccineType, string> = {
  hepatitis_b: 'Hepatite B',
  mmr: 'Tríplice Viral',
  tetanus: 'Difteria e Tétano'
}

export const doseVaccineTypesMap: Record<DoseVaccineType, string> = {
  first: '1ª dose',
  second: '2ª dose',
  third: '3ª dose',
  reinforce: 'Reforço',
}

export const vaccinePendingTypesMap: Record<VaccinePendingType, string> = {
  yes: 'Sim',
  no: 'Não',
}

export const vaccineStatusMap: Record<VaccineStatus, string> = {
  "on-time": 'Em dia',
  inactive: 'Inativo',
  pending: 'Pendente'
}





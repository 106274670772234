import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { Collector } from "./dtos/Collector";

type UseQueryOptionsProps = UseQueryOptions<Collector, unknown, Collector, QueryKey>

export function useCollector(collector_id?: string, queryOptions?: UseQueryOptionsProps) {
  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const isEnabledQuery = !!collector_id

  async function getCollector(collector_id?: string) {
    try {
      const { data } = await api.get(`/collector/${collector_id}`)

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  return useQuery(["collector", collector_id], () => getCollector(collector_id), {
    ...queryOptions,
    enabled: isEnabledQuery,
    refetchOnWindowFocus: false,
    onError: async (error) => {
      if (error instanceof RequestError) {
        errorMessage(error.message)
        if (error.httpCode === 401) await handleLogout()
      } else {
        console.log(error, "Request unique address error!")
      }
    }
  })
}

import { Box, Button, Flex, FormControl, FormLabel, IconButton, Input, Link, Progress, Select, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { validateHasFile } from "utils/fileValidation";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useForm, useWatch } from "react-hook-form";
import { ChangeEvent } from "react";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { completRefund } from "api/refunds/completRefund";

interface CompletRefundProps {
  refundId: string
}

export interface CompletRefundSchema {
  status: 'approved' | 'reproved'
  evidenceAttachmentId: FileList
  invoiceNumber: number
  disapprovalReason: string
}


const completRefundSchema = yup.object({
  status: yup.string().required(),
  evidenceAttachmentId: yup.mixed().when('status', {
    is: 'approved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  invoiceNumber: yup.mixed().when('status', {
    is: 'approved',
    then: yup.number().required(),
  }),
  disapprovalReason: yup.mixed().when('status', {
    is: 'reproved',
    then: yup.string().required(),
  }),
})


export function CompletRefund({ refundId }: CompletRefundProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()


  const { mutation: uploadEvidenceAttachmentFn, uploadProggress: uploadProggressEvidenceAttachment } = useAttachmentMutation()

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CompletRefundSchema>({
    resolver: yupResolver(completRefundSchema)
  })

  const [evidenceAttachmentId, status] = useWatch({
    control,
    name: ['evidenceAttachmentId', 'status'],
  })


  const isStatusApprovedRefund = status === 'approved'

  async function handleUploadEvidenceAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadEvidenceAttachmentFn.mutateAsync(formData)

  }

  const { mutateAsync: completRefundFn } = useMutation({
    mutationFn: completRefund,
    onSuccess(_data, { body, refundId }) {

      queryClient.invalidateQueries({ queryKey: 'pending-refunds' })
      if (body.status === 'approved') {
        queryClient.invalidateQueries({ queryKey: 'approved-refunds' })
      }
      if (body.status === 'reproved') {
        queryClient.invalidateQueries({ queryKey: 'reproved-refunds' })
      }

      queryClient.invalidateQueries({ queryKey: ['refund', refundId] })

    }
  })

  async function handleCompletRefund(values: CompletRefundSchema) {
    await promiseMessage(completRefundFn({
      body: {
        ...values,
        evidenceAttachmentId: uploadEvidenceAttachmentFn?.data?.attachment?.id
      },
      refundId
    }), 'Reembolso realizado')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleCompletRefund)}
    >
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <FormControl isInvalid={!!errors.status}>
          <FormLabel fontSize="sm">
            Próximo Status
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Select
            {...register('status')}
            name="status"
            placeholder="Selecione..."
            rounded="md"
            size='sm'
          >
            <option value='approved'>Aprovado</option>
            <option value='reproved'>Reprovado</option>

          </Select>
        </FormControl>
      </Stack>
      {status && isStatusApprovedRefund && (
        <>
          <Stack
            direction="column"
            w="full"
            mt="3"
          >
            <Button
              as={FormLabel}
              htmlFor="evidenceAttachmentId"
              lineHeight="1"
              leftIcon={<FaFileImport />}
              size="sm"
              w="min"
              cursor="pointer"
              border={!!errors?.evidenceAttachmentId && '2px solid'}
              borderColor={(!!errors?.evidenceAttachmentId) && 'red.500'}
            >
              Anexo da fatura de evidência
            </Button>
            <FormControl isInvalid={!!errors?.evidenceAttachmentId}>
              <Input
                {...register('evidenceAttachmentId')}
                name="evidenceAttachmentId"
                id="evidenceAttachmentId"
                type="file"
                hidden
                onChangeCapture={handleUploadEvidenceAttachment}
              />
            </FormControl>
          </Stack>

          {evidenceAttachmentId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(evidenceAttachmentId).map(([key, file]) => {
                  return (
                    <Tr key={key}>
                      <Td fontSize="xs" maxW="100px">{file.name}</Td>
                      <Td fontSize="xs" w="200px">
                        <Progress size="sm" rounded="md" value={uploadProggressEvidenceAttachment} />
                      </Td>
                      <Td fontSize="xs" isNumeric>
                        {uploadEvidenceAttachmentFn.data && (
                          <IconButton
                            aria-label="Visualizar anexo"
                            as={Link}
                            size="sm"
                            icon={<FaExternalLinkAlt />}
                            href={uploadEvidenceAttachmentFn.data.attachment.link}
                            isExternal
                          />
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
          <FormControl isInvalid={!!errors.invoiceNumber} mt={3}>
            <FormLabel fontSize="sm">
              Nº da fatura a ser descontada
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('invoiceNumber')}
              name="invoiceNumber"
              rounded="md"
              size="sm"
            />
          </FormControl>
        </>
      )}
      {status && !isStatusApprovedRefund && (
        <FormControl isInvalid={!!errors?.disapprovalReason} mt={3}>
          <FormLabel fontSize="sm">
            Motivo da não aprovação
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Textarea
            {...register('disapprovalReason')}
            name="disapprovalReason"
            size="sm"
            rounded="md"
          />
        </FormControl>
      )}
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { NotificationType } from "./dtos/NotificationType";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";

interface NotificationTypesQueryParams { }

type QueryOptions = UseQueryOptions<NotificationType[], unknown, NotificationType[], "notificationTypes">

interface UseNotificationTypesOptions {
  queryOptions?: QueryOptions
  queryParams?: NotificationTypesQueryParams
}

export function useNotificationTypes(options?: UseNotificationTypesOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getNotificationTypes(
    queryParams?: NotificationTypesQueryParams
  ) {
    try {
      const { data } = await api.get(`/notification-type`, {
        params: { ...queryParams }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery(
    "notificationTypes", () => getNotificationTypes(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}

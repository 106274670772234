import { Button, Divider, Flex, Stack, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { getDriverAvailableSchedules } from 'api/drivers/getDriverAvailableSchedules'
import { differenceInMinutes, parseISO, set } from 'date-fns'
import { Address } from 'hooks/address/dtos/Address'
import { Collector } from 'hooks/collector/dtos/Collector'
import { useEffect, useState } from 'react'
import { Controller, NestedValue, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { CustomerProps } from '../../../../contexts/CustomerContext'
import { useAuth } from '../../../../hooks/auth/useAuth'
import { IDriverProps } from '../../../../services/getFunctions/driver/getDrivers'
import {
  days_of_week,
  deadline_delivery,
  negative_positive,
  service_types,
  situation,
  vehicle_types,
} from '../../../../utils/customLists'
import { BusinessBudgetProps } from '../../../../utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions'
import { schema } from '../../../../validations/requestedBusinessServiceSchema'
import { EmptyAddressesFormServiceAlert } from '../../../Alerts/EmptyAddressesFormServiceAlert'
import { ListButton } from '../../../Buttons/ListButton'
import { SubmitButton } from '../../../Buttons/SubmitButton'
import { CheckboxDaysWeek } from '../../../Inputs/CheckboxDaysWeek'
import { CheckboxService } from '../../../Inputs/CheckboxService'
import { Input } from '../../../Inputs/Input'
import { Select } from '../../../Inputs/SelectInput'
import { StandardBackgroundForm } from '../../StandardBackgroundForm'

interface IAddressesScheduleProps {
  address_id: string;
  planned_hour: string;
}

interface IFormInputProps {
  customer_id: string
  business_budget_id: string
  situation: string
  source_collector_id: string
  provider_id: string
  driver_id: string
  source_address_id: NestedValue<string[]>
  destination_address_id: NestedValue<string[]>
  vehicle: string
  service_type: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  route_start_date: string
  route_start_hour: string
  route_end_hour: string
  deadline: number
  is_recurrent: string;
  recurrence_type: string
  recurrence: 'EVERY OTHER DAY' | 'ALTERNATE WEEKLY'
  days_of_week: NestedValue<string[]>
  observation: string
  is_source_addresses_schedule_updated: string;
  is_destination_addresses_schedule_updated: string;
  source_addresses_schedule: Array<IAddressesScheduleProps>
  destination_addresses_schedule: Array<IAddressesScheduleProps>
  automation_start_date: string
}

interface CreateRequestedBusinessServiceFormProps {
  drivers: IDriverProps[]
  addresses: Address[]
  customers: CustomerProps[]
  collectors: Collector[]
  businessBudgets: BusinessBudgetProps[]
  submit: (values: IFormInputProps) => Promise<void>
}

const requestedBusinessServiceRecurrenceTypeOptions = [
  { key: '0', value: 'DIAS DA SEMANA', showOption: 'DIAS DA SEMANA' },
  { key: '1', value: 'PERÍODO DE RECORRÊNCIA', showOption: 'PERÍODO DE RECORRÊNCIA' },
]

const requestedBusinessServiceRecurrenceOptions = [
  { key: '0', value: 'EVERY OTHER DAY', showOption: 'DIA SIM E DIA NÃO' },
  { key: '1', value: 'ALTERNATE WEEKLY', showOption: 'SEMANAL ALTERNADA' }
]

export function CreateRequestedBusinessServiceForm({
  drivers,
  addresses,
  customers,
  collectors,
  businessBudgets,
  submit,
}: CreateRequestedBusinessServiceFormProps) {
  const [scheduleDate, setScheduleDate] = useState(null)

  const { userLogged } = useAuth()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      is_recurrent: "NÃO",
      situation: 'ATIVO'
    }
  })

  console.log(errors, 'Erros')

  const {
    update: updateSourceAddressesScheduleFields,
    remove: removeSourceAddressesScheduleFields,
    fields: sourceAddressesScheduleFields,
  } = useFieldArray({
    control,
    name: "source_addresses_schedule"
  })

  const {
    update: updateDestinationAddressesScheduleFields,
    remove: removeDestinationAddressesScheduleFields,
    fields: destinationAddressesScheduleFields,
  } = useFieldArray({
    control,
    name: "destination_addresses_schedule"
  })

  const customerId = watch('customer_id')
  const isRecurrent = watch('is_recurrent')
  const recurrenceType = watch('recurrence_type')
  const isDaysOfWeekRecurrenceType = recurrenceType === 'DIAS DA SEMANA'
  const isPeriodRecurrenceType = recurrenceType === 'PERÍODO DE RECORRÊNCIA'
  const businessBudgetId = watch('business_budget_id')
  const sourceCollectorId = watch('source_collector_id')
  const [sourceAddressesValue, destinationAddressesValue] = watch([
    'source_address_id',
    'destination_address_id',
  ])

  const recurrence = watch('recurrence')

  const isAlternateWeeklyRecurrence = recurrence === 'ALTERNATE WEEKLY'

  const sourceScheduleAddresses = useWatch<IFormInputProps, "source_addresses_schedule">({
    control,
    name: "source_addresses_schedule"
  })

  const destinationScheduleAddresses = useWatch<IFormInputProps, "source_addresses_schedule">({
    control,
    name: "source_addresses_schedule"
  })

  const isSourceAddressesScheduleUpdated = watch('is_source_addresses_schedule_updated') === "SIM"
  const isDestinationAddressesScheduleUpdated = watch('is_destination_addresses_schedule_updated') === "SIM"

  const handleUpdateSourceAddressesScheduleFields = () => {
    setValue('is_source_addresses_schedule_updated', 'SIM')
    if (sourceAddressesValue) {
      if (!sourceScheduleAddresses) {
        sourceAddressesValue.forEach((address, index) => {
          updateSourceAddressesScheduleFields(index, { address_id: address, planned_hour: '' })
        })

      } else {
        removeSourceAddressesScheduleFields()

        sourceAddressesValue
          .reduce((acc, curr) => {
            const alreadySettedAddress = sourceScheduleAddresses.find(schedule => {
              return schedule.address_id === curr
            })

            if (alreadySettedAddress) {
              acc.push({ ...alreadySettedAddress })
            } else {
              acc.push({ address_id: curr, planned_hour: '' })
            }

            return acc
          }, [] as IAddressesScheduleProps[])
          .sort((a, b) => {
            const [firstHours, firstMinutes] = a.planned_hour.split(":")
            const [secondHours, secondMinutes] = b.planned_hour.split(":")

            const difference = differenceInMinutes(
              set(new Date(), {
                hours: Number(firstHours),
                minutes: Number(firstMinutes),
              }),
              set(new Date(), {
                hours: Number(secondHours),
                minutes: Number(secondMinutes),
              })
            )

            if (difference > 0) {
              return 1
            }

            if (difference < 0) {
              return -1
            }

            return 0
          })
          .forEach((value, index) => {
            updateSourceAddressesScheduleFields(index, { address_id: value.address_id, planned_hour: value.planned_hour })
          })
      }
    }
  }

  const handleUpdateDestinationAddressesScheduleFields = () => {
    if (destinationAddressesValue) {
      if (!destinationScheduleAddresses) {
        destinationAddressesValue.forEach((address, index) => {
          updateDestinationAddressesScheduleFields(index, { address_id: address, planned_hour: '' })
        })

      } else {
        removeDestinationAddressesScheduleFields()

        destinationAddressesValue
          .reduce((acc, curr) => {
            const alreadySettedAddress = destinationScheduleAddresses.find(schedule => {
              return schedule.address_id === curr
            })

            if (alreadySettedAddress) {
              acc.push({ ...alreadySettedAddress })
            } else {
              acc.push({ address_id: curr, planned_hour: '' })
            }

            return acc
          }, [] as IAddressesScheduleProps[])
          .sort((a, b) => {
            const [firstHours, firstMinutes] = a.planned_hour.split(":")
            const [secondHours, secondMinutes] = b.planned_hour.split(":")

            const difference = differenceInMinutes(
              set(new Date(), {
                hours: Number(firstHours),
                minutes: Number(firstMinutes),
              }),
              set(new Date(), {
                hours: Number(secondHours),
                minutes: Number(secondMinutes),
              })
            )

            if (difference > 0) {
              return 1
            }

            if (difference < 0) {
              return -1
            }

            return 0
          })
          .forEach((value, index) => {
            updateDestinationAddressesScheduleFields(index, { address_id: value.address_id, planned_hour: value.planned_hour })
          })
      }
    }
    setValue('is_destination_addresses_schedule_updated', "SIM")
  }

  const budgetSelectedById = businessBudgets.find(budget => budget.id === businessBudgetId)

  const sourceAddresses = budgetSelectedById
    ? addresses
      .filter(address =>
        address.cityIDAddress.hub_id === budgetSelectedById?.source_hub_id)
      .filter(address =>
        address.customer_id === customerId)
      .filter(address =>
        address.situation === "ATIVO") ?? []
    : []

  const destinationAddresses = budgetSelectedById
    ? addresses
      .filter(address =>
        address.cityIDAddress.hub_id === budgetSelectedById?.destination_hub_id)
      .filter(address =>
        address.customer_id === customerId)
      .filter(address =>
        address.situation === "ATIVO") ?? []
    : []

  useEffect(() => {
    if (businessBudgetId) {
      const businessBudgetFilteredById = businessBudgets.find(
        (budget) => budget.id === businessBudgetId,
      )

      if (businessBudgetFilteredById) {
        setValue(
          'source_collector_id',
          businessBudgetFilteredById.source_collector_id,
        )
        setValue('vehicle', businessBudgetFilteredById.vehicle)
        setValue('service_type', 'DEDICADO')
        setValue('caixa_termica', businessBudgetFilteredById.caixa_termica)
        setValue(
          'embalagem_secundaria',
          businessBudgetFilteredById.embalagem_secundaria,
        )
        setValue('gelo_seco', businessBudgetFilteredById.gelo_seco)
        setValue('gelox', businessBudgetFilteredById.gelox)
        setValue('isopor3l', businessBudgetFilteredById.isopor3l)
        setValue('isopor7l', businessBudgetFilteredById.isopor7l)
        setValue('terciaria3l', businessBudgetFilteredById.terciaria3l)
        setValue('terciaria8l', businessBudgetFilteredById.terciaria8l)
      }
    }
  }, [setValue, businessBudgetId, businessBudgets])

  const [driverId, routeStartHour] = useWatch({
    control,
    name: ['driver_id', 'route_start_hour']
  })

  useEffect(() => {
    if (routeStartHour) {
      const [hours,] = routeStartHour.split(':').map(Number)

      const endHour = hours === 23 ? '00:00' : `${(hours + 1).toString().padStart(2, '0')}:00`

      setValue('route_end_hour', endHour)

    }
  }, [routeStartHour, setValue])

  const {
    data: driverAvailableSchedulesResult,
  } = useQuery({
    queryKey: [
      'driver-available-schedules',
      driverId,
      scheduleDate
    ],
    queryFn: () => getDriverAvailableSchedules({
      queryParams: {
        date: parseISO(scheduleDate),
        timezoneOffset: new Date().getTimezoneOffset() / 60
      },
      routeParams: {
        driverId: driverId
      },
    }),
    enabled: !!driverId && !!scheduleDate,
    refetchOnWindowFocus: false,
  })

  const routeStartHourOptions = driverAvailableSchedulesResult?.possibleTimes?.map(
    (time) => {
      const formatted = `${time.toString().padStart(2, '0')}:00`

      return {
        key: time,
        value: formatted,
        showOption: formatted,
        disabled: !driverAvailableSchedulesResult?.availableTimes.includes(time)
      }
    }
  )

  return (
    <StandardBackgroundForm
      title="Solicitar Serviço Business"
      onSubmit={handleSubmit(submit)}
    >
      <Stack mt="4">
        <Select
          {...register('customer_id')}
          name="customer_id"
          label="Cliente"
          error={errors.customer_id}
          placeholder="Selecione uma opção..."
          customers={
            userLogged?.user_type === 'CLIENTE'
              ? customers.filter(
                (customer) => customer.id === userLogged?.customer_id,
              )
              : customers
          }
          required
        />
      </Stack>
      {isRecurrent === 'SIM' && (
        <Stack mt="4">
          <Select
            {...register('situation')}
            name="situation"
            label="Situação"
            situations={situation}
            placeholder="Selecione uma opção..."
            error={errors.situation}
            required
          />
        </Stack>
      )}

      {customerId && (
        <>
          <Stack mt="4">
            <Select
              {...register('business_budget_id')}
              name="business_budget_id"
              label="Orçamento Business"
              businessBudgets={businessBudgets.filter(
                (budget) => budget.customer_id === customerId,
              )}
              error={errors.business_budget_id}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>

          {businessBudgetId && (
            <>
              <Stack
                spacing="24px"
                mt="4"
                direction={
                  sourceAddresses.length && destinationAddresses.length
                    ? ['column', 'column', 'row']
                    : 'column'
                }
              >
                {sourceAddresses.length ? (
                  <Controller
                    name="source_address_id"
                    control={control}
                    render={({ field: { onChange } }) => {
                      return (
                        <CheckboxService
                          onCheckboxChange={onChange}
                          onChangeCapture={() => setValue('is_source_addresses_schedule_updated', "NÃO")}
                          name="source_address_id"
                          error={errors.source_address_id}
                          addresses={sourceAddresses}
                          label="Endereço(s) de origem"
                          required
                        />
                      )
                    }}
                  />
                ) : (
                  <>
                    <Flex w="full" justify="center">
                      <EmptyAddressesFormServiceAlert
                        title="Oops!"
                        description="Não há endereços de origem!"
                      />
                    </Flex>
                  </>
                )}
                {destinationAddresses.length ? (
                  <Controller
                    control={control}
                    name="destination_address_id"
                    render={({ field: { onChange } }) => {
                      return (
                        <CheckboxService
                          onCheckboxChange={onChange}
                          name="destination_address_id"
                          onChangeCapture={() => setValue('is_destination_addresses_schedule_updated', "NÃO")}
                          error={errors.destination_address_id}
                          addresses={destinationAddresses}
                          label="Endereço(s) de destino"
                          required
                        />
                      )
                    }}
                  />
                ) : (
                  <>
                    <Flex w="full" justify="center">
                      <EmptyAddressesFormServiceAlert
                        title="Oops!"
                        description="Não há endereços de destino!"
                      />
                    </Flex>
                  </>
                )}
              </Stack>

              {sourceAddressesValue && destinationAddressesValue && (
                <>
                  {userLogged?.user_type !== "CLIENTE" && (
                    <Stack mt="4">
                      <Select
                        {...register('source_collector_id')}
                        name="source_collector_id"
                        label="Coletador"
                        error={errors.source_collector_id}
                        placeholder="Selecione uma opção..."
                        collectors={collectors}
                        required
                      />
                    </Stack>
                  )}

                  <Stack
                    mt="4"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                  >
                    <Select
                      {...register('vehicle')}
                      name="vehicle"
                      label="Veículo"
                      vehicle_types={vehicle_types}
                      error={errors.vehicle}
                      placeholder="Selecione uma opção..."
                      required
                    />

                    <Select
                      {...register('service_type')}
                      name="service_type"
                      label="Tipo de Serviço"
                      service_types={service_types}
                      error={errors.service_type}
                      placeholder="Selecione uma opção..."
                      required
                    />
                  </Stack>

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register('caixa_termica')}
                      name="caixa_termica"
                      label="Caixa Térmica"
                      type="number"
                      error={errors.caixa_termica}
                      required
                    />
                    <Stack
                      w="full"
                      spacing="24px"
                      direction={['column', 'column', 'row']}
                    >
                      <Input
                        {...register('gelo_seco')}
                        name="gelo_seco"
                        label="Gelo Seco"
                        type="number"
                        error={errors.gelo_seco}
                        required
                      />

                      <Input
                        {...register('gelox')}
                        name="gelox"
                        label="Gelox"
                        type="number"
                        error={errors.gelox}
                        required
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register('isopor3l')}
                      name="isopor3l"
                      label="Isopor 3L"
                      type="number"
                      error={errors.isopor3l}
                      required
                    />
                    <Input
                      {...register('isopor7l')}
                      name="isopor7l"
                      label="Isopor 7L"
                      type="number"
                      error={errors.isopor7l}
                      required
                    />
                    <Input
                      {...register('terciaria3l')}
                      name="terciaria3l"
                      label="Terciária 3L"
                      type="number"
                      error={errors.terciaria3l}
                      required
                    />

                    <Input
                      {...register('terciaria8l')}
                      name="terciaria8l"
                      label="Terciária 8L"
                      type="number"
                      error={errors.gelox}
                      required
                    />
                  </Stack>

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register('embalagem_secundaria')}
                      name="embalagem_secundaria"
                      label="Embalagem Secundária Ziplock"
                      type="number"
                      error={errors.embalagem_secundaria}
                      required
                    />
                  </Stack>

                  <Stack
                    mt="4"
                    spacing="24px"
                    direction={['column', 'column', 'row']}
                  >
                    <Stack
                      w="full"
                      spacing="24px"
                      direction={['column', 'column', 'row']}
                    >
                      <Input
                        {...register('route_start_date')}
                        name="route_start_date"
                        label="Data de Início da Rota"
                        error={errors.route_start_date}
                        onBlur={event => setScheduleDate(event.target.value)}
                        type="date"
                        required
                      />
                      <Select
                        {...register('deadline')}
                        name="deadline"
                        label="Prazo de Término"
                        error={errors.deadline}
                        placeholder="Selecione uma opção..."
                        deadline_delivery={deadline_delivery}
                        required
                      />
                    </Stack>
                    <Stack
                      w="full"
                      spacing="24px"
                      direction={['column', 'column', 'row']}
                    >
                      <Select
                        {...register('route_start_hour')}
                        name="route_start_hour"
                        label="Horário de Início da Rota"
                        error={errors.route_start_hour}
                        placeholder="Selecione uma opção..."
                        options={routeStartHourOptions}
                        required
                      />

                      <Input
                        {...register('route_end_hour')}
                        name="route_end_hour"
                        label="Horário de Término da Rota"
                        error={errors.route_end_hour}
                        type="time"
                        required
                        isDisabled
                      />
                    </Stack>
                  </Stack>

                  <Input
                    {...register('is_source_addresses_schedule_updated')}
                    name="is_source_addresses_schedule_updated"
                    error={errors.is_source_addresses_schedule_updated}
                    required
                    hidden
                  >
                    <Button
                      mt="4"
                      flex="1"
                      padding="2"
                      w="full"
                      onClick={handleUpdateSourceAddressesScheduleFields}
                      colorScheme='blue'
                      variant={isSourceAddressesScheduleUpdated ? 'outline' : 'solid'}
                      isDisabled={isSourceAddressesScheduleUpdated}
                    >
                      {isSourceAddressesScheduleUpdated ? 'Planejamento de coletas está atualizado' : 'Atualizar planejamento de coletas'}
                    </Button>
                  </Input>


                  {sourceAddressesValue && (
                    <Stack mt="4">
                      <Text as="em" fontSize="xl">Planejamento coletas</Text>
                      {sourceAddressesScheduleFields.map((address, index) => {
                        return (
                          <Stack key={address.id} direction={['column', 'column', 'row']} spacing="24px">
                            <Select
                              {...register(`source_addresses_schedule.${index}.address_id`)}
                              name={`source_addresses_schedule.${index}.address_id`}
                              addresses={sourceAddresses}
                              label="Endereço de origem"
                              error={errors.source_addresses_schedule ? errors?.source_addresses_schedule[index]?.address_id : undefined}
                              isDisabled
                              required
                            />
                            <Input
                              {...register(`source_addresses_schedule.${index}.planned_hour`)}
                              name={`source_addresses_schedule.${index}.planned_hour`}
                              label="Horário planejado"
                              error={errors.source_addresses_schedule ? errors.source_addresses_schedule[index]?.planned_hour : undefined}
                              type="time"
                              required
                            />
                          </Stack>
                        )
                      })}
                    </Stack>
                  )}

                  <Divider
                    mt="4"
                    orientation="horizontal"
                  />

                  <Input
                    {...register('is_destination_addresses_schedule_updated')}
                    name="is_destination_addresses_schedule_updated"
                    error={errors.is_destination_addresses_schedule_updated}
                    required
                    hidden
                  >
                    <Button
                      mt="4"
                      flex="1"
                      padding="2"
                      w="full"
                      onClick={handleUpdateDestinationAddressesScheduleFields}
                      colorScheme='blue'
                      variant={isDestinationAddressesScheduleUpdated ? 'outline' : 'solid'}
                      isDisabled={isDestinationAddressesScheduleUpdated}
                    >
                      {isDestinationAddressesScheduleUpdated ? 'Planejamento de entregas está atualizado' : 'Atualizar planejamento de entregas'}
                    </Button>
                  </Input>

                  {destinationAddressesValue && (
                    <Stack mt="4">
                      <Text as="em" fontSize="xl">Planejamento entregas</Text>
                      {destinationAddressesScheduleFields.map((address, index) => {
                        return (
                          <Stack key={address.id} direction={['column', 'column', 'row']} spacing="24px">
                            <Select
                              {...register(`destination_addresses_schedule.${index}.address_id`)}
                              name={`destination_addresses_schedule.${index}.address_id`}
                              addresses={destinationAddresses}
                              label="Endereço de destino"
                              error={errors.destination_addresses_schedule ? errors?.destination_addresses_schedule[index]?.address_id : undefined}
                              isDisabled
                              required
                            />
                            <Input
                              {...register(`destination_addresses_schedule.${index}.planned_hour`)}
                              name={`destination_addresses_schedule.${index}.planned_hour`}
                              label="Horário planejado"
                              error={errors.destination_addresses_schedule ? errors.destination_addresses_schedule[index]?.planned_hour : undefined}
                              type="time"
                              required
                            />
                          </Stack>
                        )
                      })}
                    </Stack>
                  )}

                  <Divider
                    mt="4"
                    orientation="horizontal"
                  />

                  <Stack mt="4">
                    <Select
                      {...register('driver_id')}
                      name="driver_id"
                      placeholder="Selecione uma opção..."
                      label="Motorista"
                      error={errors.driver_id}
                      drivers={drivers.filter(
                        (driver) => driver.collector_id === sourceCollectorId,
                      )}
                      required
                    />
                  </Stack>
                  <Stack mt="4">
                    <Select
                      {...register("is_recurrent")}
                      name="is_recurrent"
                      label="Serviço Recorrente?"
                      placeholder="Selecione uma opção..."
                      negative_positive={negative_positive}
                      error={errors.is_recurrent}
                      required
                    />
                  </Stack>
                  {isRecurrent === "SIM" && (
                    <>
                      <Stack
                        mt={4}
                        w="full"
                        spacing="24px"
                        direction={['column', 'column', 'row']}>
                        <Select
                          {...register('recurrence_type')}
                          name='recurrence_type'
                          label='Tipo de recorrência'
                          options={requestedBusinessServiceRecurrenceTypeOptions}
                          placeholder='Selecione uma opção...'
                          error={errors.recurrence_type}
                          required
                        />
                        {recurrenceType === 'ALTERNATE WEEKLY' && (
                          <Input
                            {...register('automation_start_date')}
                            name="automation_start_date"
                            label="Data de Início da Recorrência"
                            error={errors.automation_start_date}
                            type="date"
                            required
                          />
                        )}
                      </Stack>
                      {isDaysOfWeekRecurrenceType && (
                        <Stack mt="4">
                          <Controller
                            control={control}
                            name='days_of_week'
                            render={({ field: { onChange } }) => (
                              <CheckboxDaysWeek
                                name="days_of_week"
                                days_of_week={days_of_week}
                                error={errors.days_of_week}
                                onCheckboxChange={onChange}
                                required
                              />
                            )}
                          />
                        </Stack>
                      )}
                      {isPeriodRecurrenceType && (
                        <Stack mt={4}>
                          <Select
                            {...register('recurrence')}
                            name='recurrence'
                            options={requestedBusinessServiceRecurrenceOptions}
                            label='Período de recorrência'
                            placeholder='Selecione uma opção...'
                            required
                          />

                          {isAlternateWeeklyRecurrence && (
                            <Stack mt="4">
                              <Input
                                {...register('automation_start_date')}
                                name="automation_start_date"
                                label="Data de Início da Recorrência"
                                error={errors.automation_start_date}
                                type="date"
                                required
                              />
                              <Controller
                                control={control}
                                name='days_of_week'
                                render={({ field: { onChange } }) => (
                                  <CheckboxDaysWeek
                                    name="days_of_week"
                                    days_of_week={days_of_week}
                                    error={errors.days_of_week}
                                    onCheckboxChange={onChange}
                                    required
                                  />
                                )}
                              />
                            </Stack>
                          )}

                        </Stack>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      <Flex mt="4" flex="1" justifyContent="flex-end">
        <Stack direction="row">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          <ListButton
            name="Serviços Solicitados Business"
            href="/servicos-solicitados-business"
          />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}

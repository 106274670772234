import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import swal from 'sweetalert'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import {
  ProviderContext,
  ProviderProps,
} from '../../../contexts/ProviderContext'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { GenerateExcelReportButton } from 'components/Buttons/GenerateExcelReportButton'
import { format } from 'date-fns'

interface ProviderTableProps {
  providers: ProviderProps[]
  refetch: () => void
}

const providerOptions = [
  { key: 'companyName', value: 'Nome Fantasia', checked: false },
  { key: 'material', value: 'Material', checked: false },
  { key: 'hub', value: 'Hub', checked: false },
  { key: 'unitCost', value: 'Custo Por Unidade', checked: false },
  { key: 'materialPrice', value: 'Preço', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
]

const csvHeaders = [
  { label: "CLIENTE", key: "situation" },
  { label: "NOME FANTASIA", key: "company_name" },
  { label: "RAZÃO SOCIAL", key: "trading_name" },
  { label: "PERTENCE AO HUB", key: "hubIDProvider.name" },
  { label: "CNPJ", key: "cnpj" },
  { label: "EMAIL", key: "email" },
  { label: "PREÇO", key: "material_price" },
  { label: "CUSTO POR UNIDADE", key: "unit_cost" },
  { label: "CONDIÇÃO DE PAGAMENTO", key: "payment_conditional" },
  { label: "DIA DE VENCIMENTO", key: "day_expiration_1" },
  { label: "2º DIA DE VENCIMENTO", key: "day_expiration_2" },
  { label: "TIPO DE PAGAMENTO", key: "payment_type" },
  { label: "CELULAR", key: "cellphone" },
  { label: "TELEFONE", key: "telephone" },
  { label: "CEP", key: "cep" },
  { label: "RUA", key: "street" },
  { label: "NÚMERO", key: "number" },
  { label: "COMPLEMENTO", key: "complement" },
  { label: "BAIRRO", key: "neighborhood" },
  { label: "ESTADO", key: "state" },
  { label: "CIDADE", key: "city" },
  { label: "PRAZO DE SOLICITAÇÃO", key: "deadline" },
  { label: "TIPO DE FORNECEDOR", key: "is_primary_provider" },
  { label: "HORÁRIO DE ABERTURA (ÚTEIS)", key: "business_open" },
  { label: "HORÁRIO DE FECHAMENTO (ÚTEIS)", key: "business_close" },
  { label: "HORÁRIO DE ABERTURA (SÁBADOS)", key: "saturday_open" },
  { label: "HORÁRIO DE FECHAMENTO (SÁBADOS)", key: "saturday_close" },
  { label: "HORÁRIO DE ABERTURA (DOMINGOS)", key: "sunday_open" },
  { label: "HORÁRIO DE FECHAMENTO (DOMINGOS)", key: "sunday_close" },
  { label: "OBSERVAÇÕES", key: "observation" },
]

const providerOrderOptions = [{ key: 'companyName', value: 'Nome Fantasia' }]

export function ProviderTable({ providers, refetch }: ProviderTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const [searchedProviders, setSearchedProviders] = useState<ProviderProps[]>(
    [],
  )
  const [slicedProviders, setSlicedProviders] = useState<ProviderProps[]>([])

  const [companyFiltered, setCompanyFiltered] = useState('')
  const [materialFiltered, setMaterialFiltered] = useState('')
  const [hubFiltered, setHubFiltered] = useState('')
  const [unitCostFiltered, setUnitCostFiltered] = useState('')
  const [materialPriceFiltered, setMaterialPriceFiltered] = useState('')
  const [situationFiltered, setSituationFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { deleteProvider } = useContext(ProviderContext)
  const { userLogged } = useAuth()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedProviders?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(providerOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(providerOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedProviders(providers)
    }

    run()
  }, [itemLimit, currentPage, offset, providers])

  useEffect(() => {
    function run() {
      const newSlicedProviders = slicedProviders?.filter((providers) => {
        const companyFilter = searchBoxFilter(
          providers.company_name,
          companyFiltered,
        )
        const materialFilter = searchBoxFilter(
          providers.material,
          materialFiltered,
        )
        const hubFilter = searchBoxFilter(
          providers.hubIDProvider.name,
          hubFiltered,
        )
        const unitCostFilter = searchBoxFilter(
          String(providers?.unit_cost?.toFixed(2)).replace('.', ','),
          unitCostFiltered,
        )
        const materialPriceFilter = searchBoxFilter(
          String(providers?.material_price?.toFixed(2)).replace('.', ','),
          materialPriceFiltered,
        )
        const situationFilter =
          providers?.situation.toLowerCase().startsWith(situationFiltered.toLowerCase())

        if (
          companyFiltered === '' &&
          materialFiltered === '' &&
          hubFiltered === '' &&
          unitCostFiltered === '' &&
          materialPriceFiltered === '' &&
          situationFiltered === ''
        ) {
          return providers
        }

        return companyFilter && materialFilter && hubFilter && unitCostFilter && materialPriceFilter && situationFilter
      })

      if (orderOptionSelected.length > 0) {
        const slicedProvidersByOrder = newSlicedProviders.sort(
          (a, b) =>
            (orderOptionSelected.includes('companyName') &&
              a.company_name.localeCompare(b.company_name)) ||
            0,
        )
        setSearchedProviders(slicedProvidersByOrder)
      } else {
        setSearchedProviders(newSlicedProviders)
      }
    }

    run()
  }, [
    companyFiltered,
    materialFiltered,
    hubFiltered,
    slicedProviders,
    unitCostFiltered,
    orderOptionSelected,
    materialPriceFiltered,
    situationFiltered
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  const csvReportProps = {
    data: providers?.map((provider) => {
      return {
        ...provider,
        is_primary_provider: provider.is_primary_provider ? 'Fornecedor principal' : 'Fornecedor backup',
        deadline: `D-${provider.deadline}`,
        business_open: format(new Date(provider.business_open), 'HH:mm'),
        business_close: format(new Date(provider.business_close), 'HH:mm'),
        saturday_open: provider.saturday_open ? format(new Date(provider.saturday_open), 'HH:mm') : '',
        saturday_close: provider.saturday_close ? format(new Date(provider.saturday_close), 'HH:mm') : '',
        sunday_open: provider.sunday_open ? format(new Date(provider.sunday_open), 'HH:mm') : '',
        sunday_close: provider.sunday_close ? format(new Date(provider.sunday_close), 'HH:mm') : '',
      }
    }),
    headers: csvHeaders,
    filename: `relatório-fornecedores-gelo-seco.csv`,
  };

  async function handleDeleteProvider(providerId: string) {
    await swal({
      title: 'Deseja excluir um fornecedor?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        deleteProvider(providerId).then((res) => {
          if (res) {
            refetch()
          }
        })
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  return (
    <>
      {slicedProviders.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui."
        />
      ) : (
        <>
          <Flex
            mb="2"
            wrap="wrap"
            justify={isWideVersion ? 'space-between' : 'flex-start'}
          >
            <Box>
              <TableFilterButton />
            </Box>
            <Box w={isWideVersion ? '' : 'full'}>
              <Stack mt="4" direction="column">
                {filterOptions
                  .filter((option) => option.checked === true)
                  .map((option) => (
                    <Fragment key={option.key}>
                      <Flex>
                        {option.key === 'companyName' && (
                          <SearchBox
                            placeholder="Buscar Nome Fantasia..."
                            handleSearch={(e) =>
                              setCompanyFiltered(e.target.value)
                            }
                          />
                        )}
                        {option.key === 'material' && (
                          <SearchBox
                            placeholder="Buscar Material..."
                            handleSearch={(e) =>
                              setMaterialFiltered(e.target.value)
                            }
                          />
                        )}
                        {option.key === 'hub' && (
                          <SearchBox
                            placeholder="Buscar Hub..."
                            handleSearch={(e) => setHubFiltered(e.target.value)}
                          />
                        )}
                        {option.key === 'unitCost' && (
                          <SearchBox
                            placeholder="Buscar Custo por Unidade..."
                            handleSearch={(e) =>
                              setUnitCostFiltered(e.target.value)
                            }
                          />
                        )}
                        {option.key === 'materialPrice' && (
                          <SearchBox
                            placeholder="Buscar Preço..."
                            handleSearch={(e) =>
                              setMaterialPriceFiltered(e.target.value)
                            }
                          />
                        )}
                        {option.key === 'situation' && (
                          <SearchBox
                            placeholder="Buscar Situação..."
                            handleSearch={(e) =>
                              setSituationFiltered(e.target.value)
                            }
                          />
                        )}
                      </Flex>
                    </Fragment>
                  ))}
              </Stack>
            </Box>
          </Flex>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {providerOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th fontSize="12" color="blue.900">PRAZO DE SOLICITAÇÃO</Th>
                  <Th fontSize="12" color="blue.900">TIPO DE FORNECEDOR</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedProviders
                  .slice(offset, offset + Number(itemLimit))
                  .map((providers, index) => (
                    <Tr key={index}>
                      <Td fontSize="12">
                        {providers.company_name.toUpperCase()}
                      </Td>
                      <Td fontSize="12">{providers.material.toUpperCase()}</Td>
                      <Td fontSize="12">
                        {providers.hubIDProvider.name.toUpperCase()}
                      </Td>
                      <Td fontSize="12">
                        {`R$ ${providers.unit_cost?.toFixed(2)}`.replace(
                          '.',
                          ',',
                        )}
                      </Td>
                      <Td fontSize="12">
                        {`R$ ${providers.material_price?.toFixed(2)}`.replace(
                          '.',
                          ',',
                        )}
                      </Td>
                      <Td fontSize="12">
                        {providers?.situation}
                      </Td>
                      <Td fontSize="12">
                        D-{providers?.deadline}
                      </Td>
                      <Td fontSize="12">
                        {providers?.is_primary_provider ? 'FORNCEDOR PRINCIPAL' : 'FORNECEDOR BACKUP'}
                      </Td>
                      <Td isNumeric>
                        {userLogged !== null && (
                          <>
                            {userLogged.permissions.includes(
                              'view-provider',
                            ) && (
                                <Link
                                  to={`/fornecedor/visualizar/${providers.id}`}
                                >
                                  <Icon
                                    cursor="pointer"
                                    as={FiEye}
                                    fontSize="20"
                                  />
                                </Link>
                              )}
                            {userLogged.permissions.includes(
                              'edit-provider',
                            ) && (
                                <Link to={`/fornecedor/editar/${providers.id}`}>
                                  <Icon
                                    cursor="pointer"
                                    as={FiEdit3}
                                    fontSize="20"
                                    ml="4"
                                    mt={['2', '2', '0']}
                                  />
                                </Link>
                              )}
                            {userLogged.permissions.includes(
                              'remove-provider',
                            ) && (
                                <Icon
                                  cursor="pointer"
                                  as={FiTrash2}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                  onClick={() =>
                                    handleDeleteProvider(providers.id)
                                  }
                                />
                              )}
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>

          <GenerateExcelReportButton csvReportProps={csvReportProps} />

          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

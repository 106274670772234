import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Box,
  Flex,
  Spinner,
  Button,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import { Select } from '../../../Inputs/SelectInput'
import * as yup from 'yup'
import { SubmitButton } from '../../../Buttons/SubmitButton'
import { useSwal } from '../../../../hooks/swal/useSwal'
import { useGenerateContentDeclarationFunctions } from '../../../../hooks/services/contentDeclaration/useGenerateContentDeclarationFunctions'
import { useState } from 'react'
import { Address } from 'hooks/address/dtos/Address'
import { TextArea } from 'components/Inputs/TextInput'

interface IGenerateContentDeclarationModalProps {
  isModalOpen: boolean
  sourceAddresses: Address[]
  destinationAddresses: Address[]
  addressesWithOwnBoardOrLanding: Address[]
  onClose: () => void
}

type DeclarationType = 'DECLARAÇÃO DE CONTEÚDO - UN3373' |
  'DECLARAÇÃO DE CONTEÚDO DE MATERIAL ISENTO' |
  'DECLARAÇÃO DE CONTEÚDO DE CARGA GERAL'

interface IFormInputProps {
  source_address_id: string
  destination_address_id: string
  declaration_type: DeclarationType
  declaration_description: string
}

const declarationTypesOptions = [
  {
    key: 'biologic_material',
    showOption: 'DECLARAÇÃO DE CONTEÚDO - UN3373',
    value: 'DECLARAÇÃO DE CONTEÚDO - UN3373'
  },
  {
    key: 'exempt_material',
    showOption: 'DECLARAÇÃO DE CONTEÚDO DE MATERIAL ISENTO',
    value: 'DECLARAÇÃO DE CONTEÚDO DE MATERIAL ISENTO'
  },
  {
    key: 'general_cargo',
    showOption: 'DECLARAÇÃO DE CONTEÚDO DE CARGA GERAL',
    value: 'DECLARAÇÃO DE CONTEÚDO DE CARGA GERAL'
  },
  {
    key: 'material_transport',
    showOption: 'DECLARAÇÃO DE CONTEÚDO - RISCO MÍNIMO',
    value: 'DECLARAÇÃO DE CONTEÚDO - RISCO MÍNIMO'
  },
  {
    key: 'correlates_material',
    showOption: 'DECLARAÇÃO DE CONTEÚDO - CORRELATOS',
    value: 'DECLARAÇÃO DE CONTEÚDO - CORRELATOS'
  },
]

const schema = yup.object().shape({
  source_address_id: yup.string().required('Campo Obrigatório'),
  destination_address_id: yup.string().required('Campo Obrigatório'),
  declaration_type: yup.string().required('Campo Obrigatório'),
  declaration_description: yup.string().when('declaration_type', {
    is: 'DECLARAÇÃO DE CONTEÚDO DE CARGA GERAL',
    then: yup.string().required('Campo Obrigatório'),
  })
})

export function GenerateContentDeclarationModal({
  isModalOpen,
  sourceAddresses,
  destinationAddresses,
  addressesWithOwnBoardOrLanding,
  onClose,
}: IGenerateContentDeclarationModalProps) {
  const [contentDeclarationPdfUrl, setContentDeclarationPdfUrl] = useState('')

  const { confirmMessage, standardMessage } = useSwal()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })
  const declarationType = useWatch<IFormInputProps, 'declaration_type'>({
    control,
    name: 'declaration_type',
  })
  const showDescriptionField = declarationType === 'DECLARAÇÃO DE CONTEÚDO DE CARGA GERAL'

  const declarationSourceAddressesWithOwnBoardOrLanding = sourceAddresses.concat(addressesWithOwnBoardOrLanding)
  const declarationDestinationAddressesWithOwnBoardOrLanding = destinationAddresses.concat(addressesWithOwnBoardOrLanding)

  const {
    generateContentDelcaration: {
      mutateAsync: generateContentDelcaration,
      isLoading,
    },
  } = useGenerateContentDeclarationFunctions()

  async function handleGeneratePdfContentDeclaration(values: IFormInputProps) {
    const hasGeneratePdfContentDeclaration = await confirmMessage({
      title: 'Deseja gerar um pdf com os endereços selecionados?',
    })

    if (hasGeneratePdfContentDeclaration) {
      const generateContentDeclarationResponse =
        await generateContentDelcaration(values)

      const bytes = new Uint8Array(generateContentDeclarationResponse)

      const url = URL.createObjectURL(
        new Blob([bytes], { type: 'application/pdf' }),
      )

      setContentDeclarationPdfUrl(url)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <Modal
      size="4xl"
      blockScrollOnMount={false}
      isOpen={isModalOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Gerar pdf de declaração de conteúdo</ModalHeader>
        <ModalCloseButton />

        <ModalBody overflow="hidden">
          {isLoading ? (
            <Spinner />
          ) : (
            <Box
              w="full"
              onSubmit={handleSubmit(handleGeneratePdfContentDeclaration)}
              as="form"
              p="2"
              noValidate
            >
              <Stack direction="column" spacing={6}>
                <Select
                  {...register("declaration_type")}
                  name="declaration_type"
                  placeholder="Selecione uma opção..."
                  size="sm"
                  options={declarationTypesOptions}
                  label="Tipo de declaração"
                  required
                />

                <Select
                  {...register('source_address_id')}
                  name="source_address_id"
                  label="Endereço de Origem"


                  addresses={
                    declarationSourceAddressesWithOwnBoardOrLanding
                  }
                  error={errors.source_address_id}
                  placeholder="Selecione uma opção..."
                  size="sm"
                  required
                />

                <Select
                  {...register('destination_address_id')}
                  name="destination_address_id"
                  label="Endereço de Destino"
                  addresses={declarationDestinationAddressesWithOwnBoardOrLanding}
                  error={errors.destination_address_id}
                  placeholder="Selecione uma opção..."
                  size="sm"
                  required
                />

                {showDescriptionField && (
                  <TextArea
                    {...register('declaration_description')}
                    name="declaration_description"
                    label="Descrição"
                    required
                  />
                )}
              </Stack>

              <Flex w="full" mt="4" justify="flex-end" align="center">
                <Stack direction="row">
                  <SubmitButton
                    action="Gerar Pdf"
                    isSubmitting={isSubmitting}
                  />
                  {contentDeclarationPdfUrl !== '' && (
                    <a href={contentDeclarationPdfUrl} download={`declaracao`}>
                      <Button colorScheme="facebook">Download</Button>
                    </a>
                  )}
                </Stack>
              </Flex>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import { Box, Flex, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { getCarrier } from "api/carriers/getCarrier";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { CarrierStatus } from "./CarrierStatus";

interface CarrierDetailProps {
  carrierId: string
}

export function CarrierDetail({
  carrierId
}: CarrierDetailProps) {

  const {
    data: carrierResult,
    isLoading: isLoadingCarrierResult
  } = useQuery({
    queryKey: ['carrier', carrierId],
    queryFn: () => getCarrier({ carrierId })
  })

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Detalhes do portador
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Box w="full" maxH="800px" overflowY="scroll" p={2}>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Nome</Td>
                <Td isNumeric>{carrierResult?.carrier.name}</Td>
              </Tr>
              <Tr>
                <Td>CPF</Td>
                <Td isNumeric>{carrierResult?.carrier.cpf}</Td>
              </Tr>
              <Tr>
                <Td>Email</Td>
                <Td isNumeric>{carrierResult?.carrier.email}</Td>
              </Tr>
              <Tr>
                <Td>Status</Td>
                <Td isNumeric>
                  <Flex justify="end">
                    <CarrierStatus status={carrierResult?.carrier.status} />
                  </Flex>
                </Td>
              </Tr>
              {carrierResult?.carrier.collector && (
                <Tr>
                  <Td>Coletador</Td>
                  <Td isNumeric>{captalize(carrierResult?.carrier?.collector?.trading_name)}</Td>
                </Tr>
              )}
              <Tr>
                <Td>Cartão de vacina - dose DT (difteria e tétano)</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.anttMotorFreightAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Cartão de vacina - dose hepatite B</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.hepatiteBVaccineDoseAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Cartão de vacina - dose tríplice viral</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.tripliceViralVaccineDoseAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>CNH atual</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.cnhAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>CRLV atual</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.crlvAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Curso de motofrete/ANTT</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.crlvAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Foto da lateral da moto/carro</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.vehicleSidePhotoAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Foto da traseira da moto/carro</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.vehicleRearPhotoAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>Foto interna do baú/carroceria</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label=""
                    as={Link}
                    href={carrierResult?.carrier.vehicleInternPhotoAttachment.link}
                    size="sm"
                    icon={<FaExternalLinkAlt />}
                  />
                </Td>
              </Tr>
              {carrierResult?.carrier?.pendencies && (
                <Tr>
                  <Td>Pendências</Td>
                  <Td isNumeric>
                    {carrierResult?.carrier?.pendencies.map(pendency => {
                      return (
                        <Text fontSize="sm">{pendency}</Text>
                      )
                    })}
                  </Td>
                </Tr>
              )}
              {carrierResult?.carrier.has_loglife_university_register !== null && (
                <Tr>
                  <Td>Cadastro universidade Loglife</Td>
                  <Td isNumeric>{carrierResult?.carrier.has_loglife_university_register ? 'Sim' : 'Não'}</Td>
                </Tr>
              )}

              {carrierResult?.carrier.has_aso_register !== null && (
                <Tr>
                  <Td>Cadastro de ASO</Td>
                  <Td isNumeric>{carrierResult?.carrier.has_aso_register ? 'Sim' : 'Não'}</Td>
                </Tr>
              )}
              {carrierResult?.carrier.observations && (
                <Tr>
                  <Td>Observações</Td>
                  <Td isNumeric>{carrierResult?.carrier.observations}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </ModalBody>
    </ModalContent>
  )
}

import { Box, Button, ButtonGroup, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, VStack } from "@chakra-ui/react";
import { editRegional } from "api/regionals/editRegional";
import { useToastify } from "hooks/toastify/useToastify";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { RegionalForm, RegionalFormSchema } from "./RegionalForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getRegional } from "api/regionals/getRegional";
import { useEffect } from "react";

interface EditRegionalProps {
  regionalId: string
  onCloseModal: () => void
}

const editRegionalSchema = yup.object({
  name: yup.string().required(),
  code: yup.string().required(),
  responsibleId: yup.string().required(),
})

export function EditRegional({ regionalId, onCloseModal }: EditRegionalProps) {

  const formMethods = useForm<RegionalFormSchema>({
    resolver: yupResolver(editRegionalSchema)
  })

  const { setValue } = formMethods

  const {
    data: regionalResult,
    isLoading: isLoadingRegionalResult
  } = useQuery({
    queryFn: () => getRegional({
      regionalId
    })
  })

  const {
    data: llmUsers,
    isLoading: isLoadingUsers
  } = useLLMUsers({ queryParams: { situation: 'ATIVO' } })

  useEffect(() => {
    if (regionalResult) {
      setValue('name', regionalResult.regional.name)
      setValue('code', regionalResult.regional.code)

      if (!isLoadingUsers) {
        setValue('responsibleId', regionalResult.regional.responsible.id)
      }
    }
  }, [setValue, regionalResult, isLoadingUsers])

  const queryClient = useQueryClient()

  const { mutateAsync: editRegionalFn } = useMutation({
    mutationFn: editRegional,
    onSuccess(_data, _variables, _context) {
      queryClient.invalidateQueries({ queryKey: 'regionals' })

      onCloseModal()
    }
  })

  const { promiseMessage } = useToastify()

  async function handleEditRegional(values: RegionalFormSchema) {
    await promiseMessage(editRegionalFn({
      body: values,
      regionalId,
    }), 'Regional editada com sucesso!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Editar regional
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>

        {(isLoadingRegionalResult || isLoadingUsers) ? (
          Array.from({ length: 3 }).map((_, index) => {
            return (
              <VStack>
                <Skeleton w="80px" h={6} />
                <Skeleton w="400px" h={10} />
              </VStack>
            )
          })
        ) : (
          <FormProvider {...formMethods}>
            <Box
              as="form"
              onSubmit={formMethods.handleSubmit(handleEditRegional)}
            >
              <RegionalForm llmUsers={llmUsers?.users} />

              <ButtonGroup
                w="full"
                justifyContent="end"
                mt={3}
              >
                <Button
                  type="submit"
                  colorScheme="blue"
                >
                  Salvar
                </Button>
              </ButtonGroup>
            </Box>
          </FormProvider>
        )}



      </ModalBody>
    </ModalContent>
  )
}

import { Button, Flex, Input, Select } from "@chakra-ui/react"
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaRedo, FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";


interface ProductsTableFilterProps {
  handleUpdateTable(): void
}



interface ProductsTableFiltersSchema {
  situation: 'ativo' | 'inativo' | 'all'
  q: string
}

export function ProductsTableFilter({ handleUpdateTable }: ProductsTableFilterProps) {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const q = searchParams.get('q')
  const situation = searchParams.get('situation') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<ProductsTableFiltersSchema>({
    defaultValues: {
      q: q ?? '',
      situation: situation as 'ativo' | 'inativo' | 'all'
    }
  })

  async function handleFilterProducts(data: ProductsTableFiltersSchema) {
    if (data.q) {
      searchParams.set('q', data.q)
    } else {
      searchParams.delete('q')
    }
    if (
      data.situation) {
      searchParams.set('situation', data.situation)
    } else {
      searchParams.delete('situation')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('situation')
    searchParams.delete('q')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterProducts)}
    >
      <Input
        {...register("q")}
        placeholder="Busca por palavra"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register("situation")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="ativo">Ativo</option>
        <option value="inativo">Inativo</option>
      </Select>


      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

      <Button
        type="button"
        size="sm"
        letterSpacing="1"
        leftIcon={<FaRedo />}
        variant="ghost"
        onClick={handleUpdateTable}
      >
        Atualizar página
      </Button>

    </Flex>
  )
}

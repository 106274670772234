import { Box, Button, Flex, Heading, IconButton, Spinner } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormProvider, useForm } from "react-hook-form";
import { BusinessCostForm, BusinessCostFormSchema } from "./components/BusinessCostForm";
import * as yup from "yup";
import { useMutation, useQuery } from "react-query";
import { editBusinessCost } from "api/businessCosts/editBusinessCost";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { set } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getBusinessCost } from "api/businessCosts/getBusinessCost";
import { FaArrowLeft } from "react-icons/fa";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";

const editBusinessCostSchema = yup.object({
  collectors: yup.array().min(1).of(yup.object({
    collectorId: yup.string().required()
  })),
  cost: yup.number().required().transform((value, originalValue, ctx) => {
    if (ctx.isType(value)) return Math.ceil(transformStringToNumber(value.toString()) * 100)

    value = Math.ceil(transformStringToNumber(originalValue) * 100)

    return value
  }),
  customerId: yup.string().required(),
  startDate: yup.date().required().transform((value, originalValue, ctx) => {
    if (!ctx.isType(value)) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 })

    return value
  }),
  situation: yup.string().required(),
  endDate: yup.mixed().nullable().when('situation', {
    is: 'inactive',
    then: yup.date().required().transform((value, originalValue, ctx) => {
      if (!ctx.isType(value)) return null

      const [year, month, day] = originalValue?.split('-').map(Number)
      value = set(new Date(), { date: day, month: month - 1, year })

      return value
    }),
    otherwise: yup.string().nullable().transform((value) => {
      value = null

      return value
    })
  }),
  businessBudgetId: yup.string().required(),
  observations: yup.string().nullable(),
})

interface TransformedBusinessCostSchema {
  startDate: Date
  endDate?: Date | null
  cost: number
  observations?: string | null
  businessBudgetId: string
  collectors: {
    collectorId: string
  }[]
}

interface Params {
  businessCostId: string
}

export function EditBusinessCost() {
  const { userLogged } = useAuth()
  const history = useHistory()
  const { businessCostId } = useParams<Params>()

  const userCanEditBusinessCost = userLogged?.permissions.includes('edit-business-cost')

  useEffect(() => {
    if (!userCanEditBusinessCost) history.push('/')
  },[history, userCanEditBusinessCost])

  const formMethods = useForm<BusinessCostFormSchema>({
    resolver: yupResolver(editBusinessCostSchema),
    defaultValues: {
      collectors: [{ collectorId: '' }],
      situation: 'active'
    }
  })

  const {
    data: businessCostResult,
    isLoading: isLoadingBusinessCostResult
  } = useQuery({
    queryKey: ['business-cost', businessCostId],
    queryFn: () => getBusinessCost(businessCostId)
  })


  const { mutateAsync: editBusinessCostFn } = useMutation({
    mutationFn: editBusinessCost,
    onSuccess: () => {
      toast.success('Custo business editado com sucesso!')
      history.push('/custos/business')
    }
  })

  async function handleEditBusinessCost(values: TransformedBusinessCostSchema) {
    await editBusinessCostFn({
      body: {
        ...values,
        collectorsIds: values.collectors.map((collector) => collector.collectorId)
      },
      params: {
        businessBudgetId: values.businessBudgetId,
        businessCostId,
      }
    })
  }

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
      >

        <Flex align="center" gap="2">
          <IconButton
            icon={<FaArrowLeft />}
            aria-label="Voltar"
            size="sm"
            variant="ghost"
            onClick={() => history.goBack()}
          />
          <Heading letterSpacing="tight" fontSize="2xl">Editar custo business</Heading>
        </Flex>

        {isLoadingBusinessCostResult ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>
            <Box
              as="form"
              onSubmit={formMethods.handleSubmit(handleEditBusinessCost)}
            >
              <BusinessCostForm businessCost={businessCostResult?.businessCost} />

              <Flex
                w="full"
                justify="end"
                mt="6"
              >
                <Button
                  colorScheme="blue"
                  type="submit"
                >
                  Salvar
                </Button>

              </Flex>
            </Box>
          </FormProvider>
        )}

      </Box>

  )
}

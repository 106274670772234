import { Box, Button, ButtonGroup, FormControl, FormLabel, IconButton, Input, InputGroup, InputLeftAddon, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Select, Skeleton, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import { useLLMUsers } from "hooks/user/useLLMUsers"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useToastify } from "hooks/toastify/useToastify"
import { createRefundShipping } from "api/refunds/createRefundShipping"
import { ShippingRefundForm } from "./ShippingRefundForm"
import { RefundTypes } from "api/refunds/_types/Refund"
import { captalize } from "utils/captalize";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation"
import { validateHasFile } from "utils/fileValidation"
import { ChangeEvent } from "react"
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa"
import { set } from "date-fns"
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber"
import { useCurrency } from "react-hook-currency"
import { getServiceBoards } from "api/service/getServiceBoards"
import { useGeneralProviders } from "hooks/generalProvider/useGeneralProviders"
import { ProviderRefundForm } from "./ProviderRefundForm"
import { createRefundProvider } from "api/refunds/createRefundProvider"
import { RequestError } from "utils/errors/RequestErrors"
import { toast } from "react-toastify"

interface CreateRefundProps {
  onClose: () => void
}

export const refundOptions: RefundTypes[] = [
  'Fornecedor',
  'Transportadora'
]

export interface CreateRefundSchema {
  type: RefundTypes
  serviceProtocol: string
  responsibleId: string
  requestAttachmentId: FileList
  protocol: string
  date: string
  valueInCents: number
  emailRequestAttachmentId: FileList
  description: string
  boardServiceId: string
  occurrenceDate: string
  providerId: string
  providerType: string
  invoiceNumber: number
}

const createRefundSchema = yup.object({
  type: yup.string().required(),
  serviceProtocol: yup.mixed().when('type', {
    is: 'Transportadora',
    then: yup.string().required(),
  }),
  responsibleId: yup.string().required(),
  requestAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  protocol: yup.string().required(),
  date: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null
    value = new Date(value).toISOString()
    return value
  }),
  valueInCents: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
  emailRequestAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  description: yup.string().required(),
  boardServiceId: yup.mixed().when('type', {
    is: 'Transportadora',
    then: yup.string().required(),
  }),
  occurrenceDate: yup.mixed().when('type', {
    is: 'Fornecedor',
    then: yup.string().required().transform((value, originalValue, ctx) => {
      if (!value) return null

      const [year, month, day] = originalValue?.split('-').map(Number)
      value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

      return value
    })
  }),
  providerId: yup.mixed().when('type', {
    is: 'Fornecedor',
    then: yup.string().required(),
  }),
  providerType: yup.mixed().when('type', {
    is: 'Fornecedor',
    then: yup.string().required(),
  }),
  invoiceNumber:
    yup.mixed().when(['type', 'providerType'], {
      is: (type, providerType) => type === 'Fornecedor' && providerType === 'Insumos/Serviços',
      then: yup.string().required()
    })
})


export function CreateRefund({ onClose }: CreateRefundProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const formMethods = useForm<CreateRefundSchema>({
    resolver: yupResolver(createRefundSchema)
  })

  const { handleSubmit, register, control, formState: { isSubmitting, errors } } = formMethods

  const {
    data: llmUsers,
  } = useLLMUsers({ queryParams: { situation: 'ATIVO' } })

  const { data: generalProviders } =
    useGeneralProviders({
      queryParams: {
        situation: 'ATIVO'
      }
    })

  const { mutation: uploadRequestAttachmentFn, uploadProggress: uploadProggressRequestAttachment } = useAttachmentMutation()

  const { mutation: uploadEmailRequestAttachmentFn, uploadProggress: uploadProggressEmailRequestAttachment } = useAttachmentMutation()

  const [requestAttachmentId, emailRequestAttachmentId, type, serviceProtocol] = useWatch({
    control,
    name: ['requestAttachmentId', 'emailRequestAttachmentId', 'type', 'serviceProtocol'],
  })

  const isShippingRefundType = type === 'Transportadora'

  async function handleUploadRequestAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadRequestAttachmentFn.mutateAsync(formData)

  }
  async function handleUploadEmailRequestAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadEmailRequestAttachmentFn.mutateAsync(formData)

  }

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })


  const {
    data: serviceBoardsResult,
    isLoading: isLoadingServiceBoardsResult
  } = useQuery({
    queryKey: ['service-board', serviceProtocol],
    queryFn: () => getServiceBoards({ protocol: serviceProtocol }),
    enabled: !!serviceProtocol && serviceProtocol.trim().length > 0,
    onError(error: RequestError) {
      toast.error(error.message)
    }
  })


  const disableCreateRefundButton = serviceBoardsResult?.boards?.length === 0

  const { mutateAsync: createRefundShippingFn } = useMutation({
    mutationFn: createRefundShipping,
    onSuccess(_data, _variables, _context) {
      queryClient.invalidateQueries({ queryKey: 'pending-refunds' })
      onClose()
    }
  })

  const { mutateAsync: createRefundProviderFn } = useMutation({
    mutationFn: createRefundProvider,
    onSuccess(_data, _variables, _context) {
      queryClient.invalidateQueries({ queryKey: 'pending-refunds' })
      onClose()
    }
  })


  async function handleCreateRefundShipping(values: CreateRefundSchema) {
    await promiseMessage(createRefundShippingFn({
      body: {
        boardServiceId: values.boardServiceId,
        date: values.date,
        description: values.description,
        emailRequestAttachmentId: uploadEmailRequestAttachmentFn?.data?.attachment?.id,
        protocol: values.protocol,
        requestAttachmentId: uploadRequestAttachmentFn?.data?.attachment?.id,
        type: values.type,
        valueInCents: values.valueInCents,
        responsibleId: values.responsibleId
      }
    }), 'Reembolso criado!')
  }

  async function handleCreateRefundProvider(values: CreateRefundSchema) {
    await promiseMessage(createRefundProviderFn({
      body: {
        date: values.date,
        description: values.description,
        emailRequestAttachmentId: uploadEmailRequestAttachmentFn?.data?.attachment?.id,
        occurrenceDate: values.occurrenceDate,
        protocol: values.protocol,
        providerId: values.providerId,
        providerType: values.providerType,
        requestAttachmentId: uploadRequestAttachmentFn?.data?.attachment?.id,
        type: values.type,
        valueInCents: values.valueInCents,
        invoiceNumber: values.invoiceNumber
      }

    }), 'Reembolso criado!')
  }

  return (
    <ModalContent maxW='900px'>
      <ModalHeader letterSpacing="tight">
        Criar Solicitação de Reembolso
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <FormProvider {...formMethods}>
          <Box
            as="form"
            onSubmit={isShippingRefundType ? handleSubmit(handleCreateRefundShipping) : handleSubmit(handleCreateRefundProvider)}
            maxH='600px'
            overflowY='scroll'
          >
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
              mt="3"
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors.type}>
                  <FormLabel fontSize="sm">
                    Tipo do reembolso
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>

                  <Select
                    {...register('type')}
                    name="type"
                    placeholder="Selecione..."
                    rounded="md"
                    size='sm'
                  >
                    {Object.entries(refundOptions).map(([key, value]) => (
                      <option key={key} value={value}>{value}</option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors.responsibleId}>
                  <FormLabel fontSize="sm">
                    Responsável
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>
                  <Select
                    {...register('responsibleId')}
                    name="responsibleId"
                    placeholder="Selecione um usuário"
                    size="sm"
                    rounded='md'
                  >
                    {llmUsers?.users?.map((user) => {
                      return <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>

            {isShippingRefundType && (
              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <ShippingRefundForm
                  serviceBoardsResult={serviceBoardsResult}
                />
              </Stack>
            )}
            {isShippingRefundType && isLoadingServiceBoardsResult && (
              <Skeleton
                height='40px'
                rounded='md'
              />
            )}
            {type === 'Fornecedor' && (
              <Stack
                spacing="6"
                direction={["column", "column", "row"]}
                mt="3"
              >
                <ProviderRefundForm
                  generalProviders={generalProviders}
                />
              </Stack>
            )}
            {type && (
              <>
                <Stack
                  direction="column"
                  w="full"
                  mt="3"
                >
                  <Button
                    as={FormLabel}
                    htmlFor="requestAttachmentId"
                    lineHeight="1"
                    leftIcon={<FaFileImport />}
                    size="sm"
                    w="min"
                    cursor="pointer"
                    border={!!errors?.requestAttachmentId && '2px solid'}
                    borderColor={(!!errors?.requestAttachmentId) && 'red.500'}
                  >
                    {isShippingRefundType ? 'Anexo de CTE ou Fatura' : 'Anexar Fatura ou NF'}
                  </Button>
                  <FormControl isInvalid={!!errors?.requestAttachmentId}>
                    <Input
                      {...register('requestAttachmentId')}
                      name="requestAttachmentId"
                      id="requestAttachmentId"
                      type="file"
                      hidden
                      onChangeCapture={handleUploadRequestAttachment}
                    />
                  </FormControl>
                </Stack>

                {requestAttachmentId && (
                  <Table size="sm">
                    <Tbody>
                      {Object.entries(requestAttachmentId).map(([key, file]) => {
                        return (
                          <Tr key={key}>
                            <Td fontSize="xs" maxW="100px">{file.name}</Td>
                            <Td fontSize="xs" w="200px">
                              <Progress size="sm" rounded="md" value={uploadProggressRequestAttachment} />
                            </Td>
                            <Td fontSize="xs" isNumeric>
                              {uploadRequestAttachmentFn.data && (
                                <IconButton
                                  aria-label="Visualizar anexo"
                                  as={Link}
                                  size="sm"
                                  icon={<FaExternalLinkAlt />}
                                  href={uploadRequestAttachmentFn.data.attachment.link}
                                  isExternal
                                />
                              )}
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                )}
              </>
            )}
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
              mt="3"
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors.protocol}>
                  <FormLabel fontSize="sm">
                    Protocolo do pedido de reembolso
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>
                  <Input
                    {...register('protocol')}
                    name="protocol"
                    rounded="md"
                    size="sm"
                  />
                </FormControl>
              </Stack>
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors?.date}>
                  <FormLabel fontSize="sm">
                    Data do reembolso
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>
                  <Input
                    {...register('date')}
                    name="date"
                    type="datetime-local"
                    rounded="md"
                    size='sm'
                  />
                </FormControl>
              </Stack>
            </Stack>
            <FormControl isInvalid={!!errors?.valueInCents} mt={3}>
              <FormLabel fontSize="sm">
                Valor do reembolso
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Controller
                name="valueInCents"
                control={control}
                render={({ field }) => {
                  return (
                    <InputGroup
                      size="sm"
                    >
                      <InputLeftAddon
                        borderTopLeftRadius="md"
                        borderBottomLeftRadius="md"
                      >
                        R$
                      </InputLeftAddon>
                      <Input
                        {...register("valueInCents")}
                        size="sm"
                        rounded="md"
                        w="full"
                        name={field.name}
                        defaultValue={currencyFormat('000')}
                        onChange={e => field.onChange(onCurrencyChange(e))}
                      />
                    </InputGroup>
                  )
                }}
              />
            </FormControl>
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="emailRequestAttachmentId"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.emailRequestAttachmentId && '2px solid'}
                borderColor={(!!errors?.emailRequestAttachmentId) && 'red.500'}
              >
                Anexo E-mail da solicitação de reembolso
              </Button>
              <FormControl isInvalid={!!errors?.emailRequestAttachmentId}>
                <Input
                  {...register('emailRequestAttachmentId')}
                  name="emailRequestAttachmentId"
                  id="emailRequestAttachmentId"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadEmailRequestAttachment}
                />
              </FormControl>
            </Stack>

            {emailRequestAttachmentId && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(emailRequestAttachmentId).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressEmailRequestAttachment} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadEmailRequestAttachmentFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadEmailRequestAttachmentFn.data.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            )}
            <FormControl isInvalid={!!errors?.description} mt={3}>
              <FormLabel fontSize="sm">
                Descrição
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Textarea
                {...register('description')}
                name="description"
                size="sm"
                rounded="md"
              />
            </FormControl>
            <ButtonGroup
              w="full"
              justifyContent="end"
              mt={3}
            >
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                size="sm"
                disabled={disableCreateRefundButton}
              >
                Criar
              </Button>
            </ButtonGroup>
          </Box>
        </FormProvider>
      </ModalBody>
    </ModalContent>
  )
}

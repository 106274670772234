import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";

import { ServiceCancelReasons, useServiceFunctions } from "hooks/services/useServiceFunctions";
import { useSwal } from "hooks/swal/useSwal";
import { formatDate } from "utils/DateFunctions/formatDate";

interface ValidateServiceRequestedToCancelModalProps {
  isOpen: boolean;
  onClose: () => void;
  protocol: number;
  serviceId: string;
  collectDate: string;
  cancelReason: ServiceCancelReasons | null
  cancelObservation: string | null
}

export function ValidateServicesRequestedToCancelModal({
  isOpen,
  onClose,
  serviceId,
  protocol,
  collectDate,
  cancelReason,
  cancelObservation
}: ValidateServiceRequestedToCancelModalProps) {
  const {
    validateCancelServiceRequest: { mutateAsync: validateCancelServiceRequest },
  } = useServiceFunctions();

  const { confirmMessage, standardMessage, successMessage } = useSwal();

  async function handleValidateCancelServiceRequest(
    action: "validar" | "invalidar"
  ) {
    const hasValidatedCancelService = await confirmMessage({
      title: `Deseja ${action} o cancelamento do serviço?`,
    });

    if (hasValidatedCancelService) {
      await validateCancelServiceRequest({
        serviceId: serviceId,
        hasValidate: action === "validar" ? true : false,
      });
      successMessage("Serviço cancelado com sucesso!");
      onClose();
    } else {
      standardMessage("Ação cancelada com êxito!");
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Validar Cancelamento do Serviço</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack mt="4" w="full" direction="column" spacing="24px">
              <Input
                name="protocol"
                label="Protocolo"
                defaultValue={protocol}
                isDisabled={true}
              />

              <Input
                name="collect_date"
                label="Data de coleta"
                defaultValue={formatDate.handle(
                  collectDate,
                  "DateWithoutHourToShow"
                )}
                isDisabled={true}
              />

              <Input
                name='cancel_reason'
                label='Motivo'
                defaultValue={cancelReason}
                isDisabled
              />

              <TextArea
                name='cancel_observation'
                label='Obervações'
                defaultValue={cancelObservation}
                isDisabled
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => handleValidateCancelServiceRequest("validar")}
            >
              Validar
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleValidateCancelServiceRequest("invalidar")}
            >
              Invalidar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

import { addHours, subHours } from 'date-fns';
interface FormatTimeByTimezoneUserProps {
  serviceDateTime: Date
  timezoneOffset: string
}

export const formatTimeByTimezoneUser = ({ serviceDateTime, timezoneOffset }: FormatTimeByTimezoneUserProps) => {
  const timezone = parseInt(timezoneOffset.replace('UTC-', ''))
  const userTimezone = new Date().getTimezoneOffset() / 60

  if (userTimezone < timezone) {
    let newTimezone = (userTimezone - timezone) * -1
    return addHours(serviceDateTime, newTimezone)
  }

  if (userTimezone > timezone) {
    let newTimezone = userTimezone - timezone
    return subHours(serviceDateTime, newTimezone)
  }

  return serviceDateTime
}

import { Button, Flex, FormControl, Input, Select } from "@chakra-ui/react";
import { TrainingLlmStatus } from "api/trainingLlm/trainingLlm";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";

interface TrainingsLlmReportFiltersSchema {
  collaboratorId: string
  status: TrainingLlmStatus | 'all'
  limitDate: string
}

interface TrainingsLlmReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: {
      due_date: string
      training: string
      status: string
      collaborator: {
        name: string
      }
      approved_at: string
      reproved_at: string
    }[],
    filename: string
  }
}

export function TrainingsLlmReportFilters({
  csvProps
}: TrainingsLlmReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const {
    register,
    handleSubmit,
  } = useForm<TrainingsLlmReportFiltersSchema>({
    defaultValues: {
      status: 'all'
    }
  })

  const {
    data: llmUsersData,
    isLoading: isLoadingLlmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  function handleSearchTrainingsLlmReport(values: TrainingsLlmReportFiltersSchema) {

    if (values.collaboratorId) {
      searchParams.set('collaboratorId', values.collaboratorId)
    } else {
      searchParams.delete('collaboratorId')
    }

    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    if (values.limitDate) {
      searchParams.set('limitDate', values.limitDate)
    } else {
      searchParams.delete('limitDate')
    }

    replace({ search: searchParams.toString() })

  }

  return (
    <Flex
      justify={{
        base:
          'center',
        md:
          'space-between'
      }}
      direction={{
        base: 'column',
        md: 'row'
      }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchTrainingsLlmReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Select
          {...register("collaboratorId")}
          name="collaboratorId"
          placeholder="Selecione um colaborador..."
          size="sm"
          rounded="md"
          w={{ base: 'full', md: '250px' }}
          disabled={isLoadingLlmUsersData}
        >
          {llmUsersData?.users?.map((user) => {
            return (
              <option key={user.id} value={user.id}>{captalize(`${user.firstname} ${user.lastname}`)}</option>
            )
          })}
        </Select>

        <Select
          {...register("status")}
          name="status"
          defaultValue="all"
          size="sm"
          rounded="md"
          w={["full", "full", "200px"]}
        >
          <option value="all">Todos status</option>
          <option value="pending">Pendentes</option>
          <option value="approved">Aprovados</option>
          <option value="reproved">Reprovados</option>
        </Select>
        <Flex w='200px'>
        <FormControl>
          <Input
            {...register('limitDate')}
            name="limitDate"
            type="date"
            size="sm"
            rounded="md"
          />
        </FormControl>
        </Flex>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
      </Flex>
    </Flex>
  )
}

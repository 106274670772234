import { Button, Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteProduct } from "api/products/deleteProduct";
import { GetProductsResponse } from "api/products/getProducts";
import { Product } from "api/products/_types/Product";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaPen, FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { EditProduct } from "./EditProduct";
import { ProductDetails } from "./ProductDetails";
import { ProductStatus } from "./ProductStatus";

interface ProductTableRowProps {
  product: Product
}

export function ProductTableRow({ product }: ProductTableRowProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const { userLogged } = useAuth()

  const userCanDeleteProduct = userLogged?.permissions?.includes(
    'delete-product'
  )

  const userCanEditProduct = userLogged?.permissions?.includes(
    'edit-product'
  )

  const {
    isOpen: isProductDetailModalOpen,
    onToggle: onToggleProductDetailModal,
  } = useDisclosure()

  const {
    isOpen: isEditProductOpen,
    onToggle: onToggleEditProduct,
  } = useDisclosure()

  const { mutateAsync: deleteProductFn } = useMutation({
    mutationFn: deleteProduct,
    onSuccess(_data, { productId }) {
      const cachedProducts = queryClient.getQueriesData<GetProductsResponse>({
        queryKey: ['products']
      })

      cachedProducts.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          products: cachedData.products.filter((cachedProduct) => {
            return cachedProduct.id !== productId
          })
        })

      })
    },
  })

  async function handleDeleteProduct() {
    await promiseMessage(deleteProductFn({
      productId: product?.id
    }), 'Produto excluido!')
  }


  return (
    <Tr>
      <Td>
        <Flex align="center" gap={3}>
          <IconButton
            aria-label="Visualizar Produto"
            icon={<FaSearch />}
            size="sm"
            variant="ghost"
            onClick={onToggleProductDetailModal}
          />
          {userCanEditProduct && (
            <IconButton
              aria-label="Editar produto"
              icon={<FaPen />}
              variant="ghost"
              size="sm"
              onClick={onToggleEditProduct} />
          )}
        </Flex>
        <Modal
          isOpen={isProductDetailModalOpen}
          onClose={onToggleProductDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <ProductDetails
            productId={product?.id}

          />
        </Modal>
        <Modal
          isOpen={isEditProductOpen}
          onClose={onToggleEditProduct}
          size="2xl"
        >
          <ModalOverlay />

          <EditProduct
            productId={product.id}
            onClose={onToggleEditProduct} />

        </Modal>
      </Td>
      <Td>{captalize(product?.material.material_name)}</Td>
      <Td>{captalize(product.warehouse.name)}</Td>
      <Td>{captalize(product?.generalProvider.company_name)}</Td>
      <Td>{captalize(product.measure)}</Td>
      <Td>{new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(Number(product.unit_cost) / 100)}</Td>
      <Td>{product.min_stock}</Td>
      <Td>{product.standard_stock}</Td>
      <Td>{product.current_stock}</Td>
      <Td>
        <ProductStatus
          status={product?.situation}
        />

      </Td>
      <Td>
        {userCanDeleteProduct && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaTimes} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleDeleteProduct}
          >
            Excluir
          </Button>
        )}
      </Td>
    </Tr>
  )
}

export class SwitchCategoryTypes {
  handle() {
    const categories = [
      { key: 'BIOLOGICAL', showValue: 'BIOLÓGICO', value: 'BIOLÓGICO'},
      { key: 'EMERGENCY GENERAL CARGO', showValue: 'CARGA GERAL EMERGENCIAL', value: 'CARGA GERAL EMERGENCIAL'},
      { key: 'CONVENTIONAL GENERAL CARGO', showValue: 'CARGA GERAL CONVENCIONAL', value: 'CARGA GERAL CONVENCIONAL'}
    ]

    return categories
  }
}

const switchCategoryTypes = new SwitchCategoryTypes()

export { switchCategoryTypes }



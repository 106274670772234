import { Box, Flex } from "@chakra-ui/react";
import { TrainingStatus as TrainingStatusTypes } from "api/trainings/training";
import { trainingStatusMap } from "utils/trainingMappers";

interface TrainingStatusProps {
  status: TrainingStatusTypes;
}

export function TrainingStatus({ status }: TrainingStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'pending' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'active' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'inactive' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {trainingStatusMap[status]}
    </Flex>
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

export interface GetDelayedDeliveriesResponse {
  services: {
    id: string
    protocol: number
    step: string
    customer: string
    source_hub: string
    source_tz: string
    destination_hub: string
    destination_tz: string
    delivery_date: string
    delivery_hour: string
    last_interaction_status: string | null
  }[]
}

export async function getDelayedDeliveries() {
  try {
    const { data } = await api.get<GetDelayedDeliveriesResponse>('/services/dashboard/deliveries/delayed')

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetCustomerBoardsResponse {
  services: {
    id: string
    protocol: number
    step: string
    customer: string
    shipping: string
    source_hub: string
    source_tz: string
    destination_hub: string
    destination_tz: string
  }[]
}

interface GetCustomerBoardsParams {
  customerId: string
}

export async function getCustomerBoards({ customerId }: GetCustomerBoardsParams) {
  try {
    const { data } = await api.get<GetCustomerBoardsResponse>(`/services/dashboard/customers/${customerId}/boards`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import { Tabs, TabList, Tab, TabPanels } from "@chakra-ui/react";
import { ReactNode } from "react";

interface TabListOptionsProps {
  key: 'open' | 'closed'
  value: string
}

const tabListOptions: TabListOptionsProps[] = [
  { key: 'open', value: 'Abertos' },
  { key: 'closed', value: 'Encerrados' },
]

interface ExternalComunicationsSidebarContentProps {
  children: ReactNode
  onChangeExternalComunicationStatus: (status: 'closed' | 'open') => void
}

export function ExternalComunicationsSidebarContent({
  children,
  onChangeExternalComunicationStatus
}: ExternalComunicationsSidebarContentProps) {

  function handleChangeExternalComunicationStatus(index: number) {
    const status = tabListOptions[index].key

    onChangeExternalComunicationStatus(status)
  }

  return (
    <Tabs
      size="xs"
      fontSize="xx-small"
      isFitted
      w="full"
      mt="2"
      onChange={(eventIndex) => handleChangeExternalComunicationStatus(eventIndex)}
    >
      <TabList>
        {tabListOptions.map((option) => {
          return (
            <Tab
              key={option.key}
              _focus={{
                outline: "none"
              }}
            >
              {option.value}
            </Tab>
          )
        })}
      </TabList>
      <TabPanels w="full">
        {children}
      </TabPanels>
    </Tabs>
  )
}

import { Box, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function TerminationForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      fullName: 'Nome Completo',
      terminationDateTime: 'Data e hora de desligamento'
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };
  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.fullName} mt={3}>
        <FormLabel fontSize='sm'>Nome Completo</FormLabel>
        <Input
          {...register('formData.fullName.value')}
          name='formData.fullName.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('fullName')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.terminationDateTime} mt={3}>
        <FormLabel fontSize='sm'>Data e hora de desligamento</FormLabel>
        <Input
          {...register('formData.terminationDateTime.value')}
          name='formData.terminationDateTime.value'
          type="datetime-local"
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('terminationDateTime')}
        />
      </FormControl>
    </Box>
  )
}

import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Aso } from "api/asos/aso";
import { Regional } from "api/regionals/_types/Regional";
import { format } from "date-fns";
import { FaSearch } from "react-icons/fa";
import { asoTypeMap } from "utils/asoMappers";
import { AsoDetail } from "./AsoDetail";
import { AsoStatus } from "./AsoStatus";

interface AsoTableRowProps {
  aso: Aso & { regional: Regional }
}

export function AsosTableRow({ aso }: AsoTableRowProps) {
  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar aso"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
          size='6xl'
        >
          <ModalOverlay />

          <AsoDetail
            asoId={aso.id}
            onClose={onCloseDetailModal}
          />

        </Modal>
      </Td>
      <Td>{aso.regional ? aso.regional.code.toUpperCase() : '-'}</Td>
      <Td>
        <AsoStatus status={aso.status} />
      </Td>
      <Td>{format(new Date(aso.created_at), 'dd/MM/yyyy')}</Td>
      <Td>{asoTypeMap[aso.type]}</Td>
      <Td>{aso.collaborator_name}</Td>
      <Td>{aso.status === 'active' ? (
        format(new Date(aso.due_date), 'dd/MM/yyyy')
      ) : (
        '-'
      )}</Td>

    </Tr>
  )
}

import { Stack, Flex, Button, useDisclosure, Box } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useCurrency } from 'react-hook-currency'
import { Controller, useForm } from 'react-hook-form'
import { CitiesProps } from '../../../services/getFunctions/city/getCity'
import { deadline_delivery } from '../../../utils/customLists'
import { statesWithUfList } from '../../../utils/CustomLists/statesWithUfList'
import { switchRouteTypes } from '../../../utils/CustomLists/switchRouteTypes'
import { switchCategoryTypes } from 'utils/CustomLists/switchCategotyTypes'
import { IHubsProps } from '../../../utils/RequestFunctions/Hubs/requestHubFunctions'
import { PriceProps } from '../../../utils/RequestFunctions/Price/requestPriceFunctions'
import { switchPriceSchema } from '../../../validations/priceSchema'
import { FormActionButton } from '../../Buttons/FormActionButton'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { Input } from '../../Inputs/Input'
import { Select } from '../../Inputs/SelectInput'
import { TextArea } from '../../Inputs/TextInput'
import { StandardBackgroundForm } from '../StandardBackgroundForm'
import { PriceLogsModal } from './components/PriceLogsModal'
import { vehicle_types } from './CreatePriceForm'
import { ShippingProps } from 'contexts/ShippingContext'
import { GeneralCheckbox } from 'components/Inputs/GeneralCheckbox'
import { searchBoxFilter } from 'utils/searchBoxFilter'
import { SearchBox } from 'components/SearchBox/SearchBox'
import { priceSituationMapper } from 'utils/priceMappers'

export type PriceCategory =
  | "BIOLÓGICO"
  | "CARGA GERAL EMERGENCIAL"
  | "CARGA GERAL CONVENCIONAL"

interface IFormInputProps {
  source_city_uf: string
  source_city_id: string
  source_hub_id: string
  destination_city_uf: string
  destination_city_id: string
  destination_hub_id: string
  route_type: string
  air_minimum_price: string
  air_extra_kg: string
  air_deadline: number | null
  highway_minimum_price: string
  highway_extra_kg: string
  highway_deadline: number | null
  additional_collect_price: string
  additional_delivery_price: string
  observation: string | null
  category: PriceCategory
  vehicle: string
  shippingIds: string[]
  situation: 'active' | 'inactive'
  service_type: 'FRACIONADO' | 'DEDICADO'
}

interface IEditDetailPriceFormProps {
  price: PriceProps
  slug: string
  cities: CitiesProps[]
  hubs: IHubsProps[]
  shippings: ShippingProps[]
  submit: (values: IFormInputProps) => Promise<void>
}

export function EditDetailPriceForm({
  price,
  slug,
  cities,
  hubs,
  shippings,
  submit,
}: IEditDetailPriceFormProps) {
  const { onChange, format: currencyFormat } = useCurrency({ style: 'decimal' })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(switchPriceSchema.handle()),
  })

  const routeType = watch('route_type')
  const serviceType = watch('service_type')
  const sourceCityUf = watch('source_city_uf')
  const sourceCityId = watch('source_city_id')
  const destinationCityUf = watch('destination_city_uf')
  const destinationCityId = watch('destination_city_id')

  useEffect(() => {
    setValue(
      'source_city_uf',
      Object.entries(statesWithUfList)
        .filter(
          ([key, value]) => key.toUpperCase() === price.sourceCityIDPrice.state,
        )
        .map(([key, value]) => key)[0],
    )
    setValue('source_city_id', price.source_city_id)
    setValue(
      'destination_city_uf',
      Object.entries(statesWithUfList)
        .filter(
          ([key, value]) =>
            key.toUpperCase() === price.destinationCityIDPrice.state,
        )
        .map(([key, value]) => key)[0],
    )
    setValue('destination_city_id', price.destination_city_id)
    setValue('category', price.category)
    setValue('route_type', price.route_type)
    setValue(
      'air_minimum_price',
      price.air_minimum_price !== null ? String(price.air_minimum_price) : '',
    )
    setValue(
      'air_extra_kg',
      price.air_extra_kg !== null ? String(price.air_extra_kg) : '',
    )
    setValue(
      'air_deadline',
      price.air_deadline !== null ? price.air_deadline : null,
    )
    setValue(
      'highway_minimum_price',
      price.highway_minimum_price !== null
        ? String(price.highway_minimum_price)
        : '',
    )
    setValue(
      'highway_extra_kg',
      price.highway_extra_kg !== null ? String(price.highway_extra_kg) : '',
    )
    setValue(
      'highway_deadline',
      price.highway_deadline !== null ? price.highway_deadline : null,
    )
    setValue('additional_collect_price', String(price.additional_collect_price))
    setValue(
      'additional_delivery_price',
      String(price.additional_delivery_price),
    )
    setValue('observation', price.observation !== null ? price.observation : '')
    setValue("vehicle", price.vehicle !== null ? price.vehicle : '')
    setValue('service_type', price.service_type)
    setValue('situation', price.situation)


    const shippings = price?.shippings.map(shipping => shipping.ShippingId)

    setValue('shippingIds', shippings ?? [])

  }, [price, setValue])

  useEffect(() => {
    if (sourceCityId) {
      const sourceHubBySourceCity = cities.find(
        (city) => city.id === sourceCityId,
      )?.hub_id
      if (sourceHubBySourceCity) {
        setValue('source_hub_id', sourceHubBySourceCity)
      }
    }
  }, [sourceCityId, setValue, cities])

  useEffect(() => {
    if (destinationCityId) {
      const destinationHubBySourceCity = cities.find(
        (city) => city.id === destinationCityId,
      )?.hub_id
      if (destinationHubBySourceCity) {
        setValue('destination_hub_id', destinationHubBySourceCity)
      }
    }
  }, [destinationCityId, setValue, cities])

  useEffect(() => {
    if (routeType === 'AÉREO') {
      setValue('highway_deadline', null)
      setValue('highway_minimum_price', '')
      setValue('highway_extra_kg', '')
    }
    if (routeType === 'RODOVIÁRIO') {
      setValue('air_deadline', null)
      setValue('air_minimum_price', '')
      setValue('air_extra_kg', '')
    }
  }, [routeType, setValue])

  const {
    isOpen: isOpenPriceLogsModal,
    onOpen: onOpenPriceLogsModal,
    onClose: onClosePriceLogsModal
  } = useDisclosure()

  const shippingsCheckboxOptions = shippings?.filter((shipping) => {
    if (routeType === 'RODOVIÁRIO') {
      return shipping.modal === routeType
    }
    if (routeType === 'AÉREO') {
      return shipping.modal === routeType
    }
    return shipping

  })?.map((shipping) => {
    return {
      key: shipping.id,
      value: shipping.id,
      showOption: shipping.trading_name
    }
  })
  const [shippingFiltered, setShippingFiltered] = useState('')

  const shippingsOptions = shippingsCheckboxOptions?.filter((shipping) => {
    const shippingFilter = searchBoxFilter(
      shipping.showOption,
      shippingFiltered,
    )
    if (shippingFiltered === "") {
      return shipping
    }
    return shippingFilter
  })

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(submit)}
      title={slug === 'Visualizar' ? 'Visualizar Preço' : 'Editar Preço'}
    >
      <Flex w='full' justifyContent='flex-end'>
        <Button colorScheme='blue' onClick={onOpenPriceLogsModal}>
          Logs
        </Button>
      </Flex>
      {price && (
        <PriceLogsModal
          isOpen={isOpenPriceLogsModal}
          onClose={onClosePriceLogsModal}
          priceLogs={price?.logs}
          cities={cities}
          hubs={hubs}
        />
      )}
      <Stack>
        <Select
          {...register('situation')}
          name="situation"
          label="Situação"
          placeholder="Selecione uma opção..."
          error={errors.situation}
          isDisabled={slug === 'visualizar'}
          options={Object.entries(priceSituationMapper).map(([key, value]) => ({
            key,
            value: key,
            showOption: value,
          }))}
          required
        />
      </Stack>
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Select
            {...register('source_city_uf')}
            name="source_city_uf"
            label="UF Origem"
            placeholder="Selecione uma opção..."
            error={errors.source_city_uf}
            onChangeCapture={() => setValue('source_city_id', '')}
            isDisabled={slug === 'visualizar'}
            statesWithUf={statesWithUfList}
            required
          />
          {sourceCityUf && (
            <Select
              {...register('source_city_id')}
              name="source_city_id"
              label="Cidade Origem"
              placeholder="Selecione uma opção..."
              error={errors.source_city_id}
              isDisabled={slug === 'visualizar'}
              citiesBack={cities.filter(
                (city) => city.state === sourceCityUf.toUpperCase(),
              )}
              required
            />
          )}
        </Stack>
        {sourceCityId && (
          <Select
            {...register('source_hub_id')}
            name="source_hub_id"
            label="Hub Origem"
            placeholder="Selecione uma opção..."
            hubs={hubs}
            isDisabled={true}
            error={errors.source_hub_id}
            required
          />
        )}
      </Stack>
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Select
            {...register('destination_city_uf')}
            name="destination_city_uf"
            placeholder="Selecione uma opção..."
            label="UF Destino"
            onChangeCapture={() => setValue('destination_city_id', '')}
            error={errors.destination_city_uf}
            isDisabled={slug === 'visualizar'}
            statesWithUf={statesWithUfList}
            required
          />
          {destinationCityUf && (
            <Select
              {...register('destination_city_id')}
              name="destination_city_id"
              placeholder="Selecione uma opção..."
              label="Cidade Destino"
              error={errors.destination_city_id}
              isDisabled={slug === 'visualizar'}
              citiesBack={cities.filter(
                (city) => city.state === destinationCityUf.toUpperCase(),
              )}
              required
            />
          )}
        </Stack>
        {destinationCityId && (
          <Select
            {...register('destination_hub_id')}
            name="destination_hub_id"
            label="Hub Destino"
            placeholder="Selecione uma opção..."
            isDisabled={true}
            hubs={hubs}
            error={errors.destination_hub_id}
            required
          />
        )}
      </Stack>
      <Stack mt="4">
        <Select
          {...register('category')}
          name="category"
          label="Categoria"
          placeholder="Selecione uma opção..."
          categoryTypes={switchCategoryTypes.handle()}
          isDisabled={slug === 'visualizar'}
          required
        />
      </Stack>

      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Stack
          spacing={6}
          w="full"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Select
            {...register('service_type')}
            name="service_type"
            label="Tipo de serviço"
            placeholder="Selecione uma opção..."
            options={[
              {
                key: '0',
                value: 'FRACIONADO',
                showOption: 'FRACIONADO',
              },
              {
                key: '1',
                value: 'DEDICADO',
                showOption: 'DEDICADO',
              },
            ]}
            required
          />
          <Select
            {...register('route_type')}
            name="route_type"
            label="Tipo de Rota"
            placeholder="Selecione uma opção..."
            routeTypes={switchRouteTypes.handle()}
            required
          />
        </Stack>
        <Select
          {...register("vehicle")}
          name="vehicle"
          label="Veículo"
          placeholder="Selecione uma opção..."
          options={vehicle_types}
          error={errors.vehicle}
          isDisabled={slug === 'visualizar'}
          required
        />
      </Stack>

      {serviceType === 'FRACIONADO' && (
        <Stack w="full" mt="4" spacing="10px" justify="flex-start">
          <>
            <SearchBox
              isCheckbox={true}
              size="sm"
              placeholder="Buscar Transportadora..."
              handleSearch={(e) =>
                setShippingFiltered(e.target.value)
              }
            />
            <Box
              overflowY="auto"
              height="300px"
              borderWidth="1px"
              borderRadius="lg"
              p="2"
            >
              <Controller
                control={control}
                name="shippingIds"
                render={({ field }) => (
                  <GeneralCheckbox
                    name="shippingIds"
                    defaultCheckedOptions={price?.shippings.map(shipping => shipping.ShippingId)}
                    onCheckboxChange={field.onChange}
                    checkboxOptions={shippingsOptions}
                    label="Transportadora (s)"
                    required
                  />
                )}
              />
            </Box>
          </>
        </Stack>
      )}

      {(routeType === 'AÉREO' || routeType === 'AMBOS') && (
        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('air_minimum_price')}
            name="air_minimum_price"
            label="Preço mínimo aéreo"
            isDisabled={slug === 'visualizar'}
            onChange={onChange}
            defaultValue={currencyFormat('000')}
            error={errors.air_minimum_price}
            required
          />

          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('air_extra_kg')}
              name="air_extra_kg"
              label="KG extra aéreo"
              isDisabled={slug === 'visualizar'}
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.air_extra_kg}
              required
            />
            <Select
              {...register('air_deadline')}
              name="air_deadline"
              label="Prazo aéreo"
              isDisabled={slug === 'visualizar'}
              error={errors.air_deadline}
              deadline_delivery={deadline_delivery}
              required
            />
          </Stack>
        </Stack>
      )}
      {(routeType === 'RODOVIÁRIO' || routeType === 'AMBOS') && (
        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('highway_minimum_price')}
            name="highway_minimum_price"
            label="Preço mínimo rodoviário"
            isDisabled={slug === 'visualizar'}
            onChange={onChange}
            defaultValue={currencyFormat('000')}
            error={errors.highway_minimum_price}
            required
          />

          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('highway_extra_kg')}
              name="highway_extra_kg"
              label="KG extra rodoviário"
              isDisabled={slug === 'visualizar'}
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.highway_extra_kg}
              required
            />
            <Select
              {...register('highway_deadline')}
              name="highway_deadline"
              label="Prazo rodoviário"
              isDisabled={slug === 'visualizar'}
              error={errors.highway_deadline}
              deadline_delivery={deadline_delivery}
              required
            />
          </Stack>
        </Stack>
      )}

      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register('additional_collect_price')}
          name="additional_collect_price"
          label="Preço adicional coleta"
          isDisabled={slug === 'visualizar'}
          onChange={onChange}
          defaultValue={currencyFormat('000')}
          error={errors.additional_collect_price}
          required
        />
        <Input
          {...register('additional_delivery_price')}
          name="additional_delivery_price"
          label="Preço adicional entrega"
          isDisabled={slug === 'visualizar'}
          onChange={onChange}
          defaultValue={currencyFormat('000')}
          error={errors.additional_delivery_price}
          required
        />
      </Stack>
      <Stack mt="4">
        <TextArea
          {...register("observation")}
          name="observation"
          label="Observações"
        />
      </Stack>
      <Flex mt="4" justifyContent="flex-end">
        <Stack spacing="24px" direction="row">
          {slug === 'visualizar' ? (
            <FormActionButton
              action="Editar"
              href={`/preco/editar/${price.id}`}
            />
          ) : (
            <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          )}
          <ListButton href="/precos" name="Preços" />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}

import axios from "axios"
import { set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery, UseQueryOptions } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { InDeliveryServiceData } from "./dtos/InDeliveryService"

interface InDeliveryServicesQueryParams {
  page_size?: number
  current_page?: number
  protocol?: string
  delivery_date?: string
  delivery_hour?: string
  customer?: string
  gelo_seco?: string
  source_city?: string
  destination_city?: string
  volume?: string
  recipient?: string
  collector?: string
  collector_id?: string
  driver_id?: string
}

type QueryOptions = UseQueryOptions<InDeliveryServiceData, unknown, InDeliveryServiceData, ["inDeliveryServices"]>

interface UseInDeliveryServicesOptions {
  queryParams?: InDeliveryServicesQueryParams,
  queryOptions?: QueryOptions
}

async function getInDeliveryServicesReqFunction(queryParams?: InDeliveryServicesQueryParams) {
  try {
    const { data } = await api.get('/service/list/in-delivery', {
      params: {
        ...queryParams,
        delivery_hour: queryParams?.delivery_hour
          ? set(new Date(), {
            hours: Number(queryParams?.delivery_hour.split(':')[0]),
            minutes: Number(queryParams?.delivery_hour.split(':')[1])
          })
          : undefined,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useInDeliveryServices(options?: UseInDeliveryServicesOptions) {

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  return useQuery([
    'inDeliveryServices',
    { ...options?.queryParams }
  ],
    () => getInDeliveryServicesReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )

}

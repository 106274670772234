import { Stack } from "@chakra-ui/react"
import { Input } from "components/Inputs/Input"
import { Options, Select } from "components/Inputs/SelectInput"
import { UsePlannedNetworkFormattedData } from "hooks/usePlannedRoutes"
import { useFormContext } from "react-hook-form"
import { formatDate } from "utils/DateFunctions/formatDate"

interface PlannedRoutesInputsGroupInputs {
  planned_flight: string
  planned_connection_flight: string
  crossdocking_planned_flight: string
  crossdocking_planned_connection_flight: string
}

interface PlannedRoutesInputsGroupProps {
  isDisabled?: boolean
  hasCrossdockingConnection: boolean
  isFetchingRouteNetworks: boolean
  hasConnection: boolean
  isCrossdockingService: boolean
  plannedRoutesWithoutCrossdockingAndConnection: UsePlannedNetworkFormattedData[]
  plannedRoutesFromSourceBranchToConnectionCrossdockingBranch: UsePlannedNetworkFormattedData[]
  plannedRoutesFromSourceBranchToConnectionBranch: UsePlannedNetworkFormattedData[]
  plannedRoutesFromConnectionBranchToDestinationBranch: UsePlannedNetworkFormattedData[]
  plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch: UsePlannedNetworkFormattedData[]
  plannedRoutesFromSourceBranchToDestinationCrossdockingBranch: UsePlannedNetworkFormattedData[]
  plannedRoutesFromSourceCrossdockingBranchToConnectionBranch: UsePlannedNetworkFormattedData[]
  plannedRoutesFromSourceCrossdockingBranchToDestinationBranch: UsePlannedNetworkFormattedData[]
}

function formatPlannedRoutesToSelectOptions(
  plannedRoute: UsePlannedNetworkFormattedData,
): Options {
  const plannedRouteInfo = `${plannedRoute.truck_flight} - ${formatDate.handle(
    plannedRoute.departure_date, "DateWithoutHourToShow"
  )} - ${formatDate.handle(
    plannedRoute.departure_hour, "DateOnlyWithHourMinute"
  )} / ${formatDate.handle(
    plannedRoute.arrival_date, "DateWithoutHourToShow"
  )} - ${formatDate.handle(
    plannedRoute.arrival_hour, "DateOnlyWithHourMinute"
  )}`

  return {
    key: plannedRoute.id,
    showOption: plannedRouteInfo,
    value: plannedRouteInfo
  }
}

export function PlannedRoutesInputsGroup({
  isDisabled = false,
  hasCrossdockingConnection,
  isFetchingRouteNetworks,
  hasConnection,
  isCrossdockingService,
  plannedRoutesWithoutCrossdockingAndConnection,
  plannedRoutesFromSourceBranchToConnectionCrossdockingBranch,
  plannedRoutesFromSourceBranchToConnectionBranch,
  plannedRoutesFromConnectionBranchToDestinationBranch,
  plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch,
  plannedRoutesFromSourceBranchToDestinationCrossdockingBranch,
  plannedRoutesFromSourceCrossdockingBranchToConnectionBranch,
  plannedRoutesFromSourceCrossdockingBranchToDestinationBranch,
}: PlannedRoutesInputsGroupProps) {

  const {
    register,
    formState: {
      errors
    }
  } = useFormContext<PlannedRoutesInputsGroupInputs>()

  const plannedFlightFieldOptions =
    plannedRoutesWithoutCrossdockingAndConnection.length
      ? plannedRoutesWithoutCrossdockingAndConnection.map(formatPlannedRoutesToSelectOptions)
      : plannedRoutesFromConnectionBranchToDestinationBranch.length
        ? plannedRoutesFromConnectionBranchToDestinationBranch.map(formatPlannedRoutesToSelectOptions)
        : plannedRoutesFromSourceCrossdockingBranchToDestinationBranch.length
          ? plannedRoutesFromSourceCrossdockingBranchToDestinationBranch.map(formatPlannedRoutesToSelectOptions)
          : []

  const crossdockingConnectionPlannedFlightFieldOptions =
    plannedRoutesFromSourceBranchToConnectionCrossdockingBranch.length
      ? plannedRoutesFromSourceBranchToConnectionCrossdockingBranch.map(formatPlannedRoutesToSelectOptions)
      : []

  const conectionPlannedFlightFieldOptions =
    plannedRoutesFromSourceBranchToConnectionBranch.length
      ? plannedRoutesFromSourceBranchToConnectionBranch.map(formatPlannedRoutesToSelectOptions)
      : plannedRoutesFromSourceCrossdockingBranchToConnectionBranch.length
        ? plannedRoutesFromSourceCrossdockingBranchToConnectionBranch.map(formatPlannedRoutesToSelectOptions)
        : []

  const crossdockingPlannedFlightFieldOptions =
    plannedRoutesFromSourceBranchToDestinationCrossdockingBranch.length
      ? plannedRoutesFromSourceBranchToDestinationCrossdockingBranch.map(formatPlannedRoutesToSelectOptions)
      : plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch.length
        ? plannedRoutesFromConnectionCrossdockingBranchToDestinationCrossdockingBranch.map(formatPlannedRoutesToSelectOptions)
        : []

  const hasPlannedFlightOptions = plannedFlightFieldOptions.length
  const hasCrossdockingConnectionPlannedFlightOptions = crossdockingConnectionPlannedFlightFieldOptions.length
  const hasConectionPlannedFlightOptions = conectionPlannedFlightFieldOptions.length
  const hasCrossdockingPlannedFlightOptions = crossdockingPlannedFlightFieldOptions.length

  return (
    <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
      {(hasCrossdockingConnection || isCrossdockingService) && (
        <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
          {hasCrossdockingConnection && (
            hasCrossdockingConnectionPlannedFlightOptions ? (
              <Select
                {...register('crossdocking_planned_connection_flight')}
                name='crossdocking_planned_connection_flight'
                label="Viagem conexão planejada crossdocking"
                placeholder="Selecione uma opção..."
                error={errors.crossdocking_planned_connection_flight}
                options={crossdockingConnectionPlannedFlightFieldOptions}
                isDisabled={isFetchingRouteNetworks || isDisabled}
                required
              />
            ) : (
              <Input
                {...register('crossdocking_planned_connection_flight')}
                name='crossdocking_planned_connection_flight'
                error={errors.crossdocking_planned_connection_flight}
                label="Viagem conexão planejada crossdocking"
                isDisabled={isFetchingRouteNetworks || isDisabled}
                required
              />
            )
          )}

          {isCrossdockingService && (
            hasCrossdockingPlannedFlightOptions ? (
              <Select
                {...register('crossdocking_planned_flight')}
                name='crossdocking_planned_flight'
                label="Viagem planejada crossdocking"
                error={errors.crossdocking_planned_flight}
                placeholder="Selecione uma opção..."
                options={crossdockingPlannedFlightFieldOptions}
                isDisabled={isFetchingRouteNetworks || isDisabled}
                required
              />
            ) : (
              <Input
                {...register('crossdocking_planned_flight')}
                name='crossdocking_planned_flight'
                error={errors.crossdocking_planned_flight}
                isDisabled={isFetchingRouteNetworks || isDisabled}
                label="Viagem planejada crossdocking"
                required
              />
            )
          )}
        </Stack>
      )}

      <Stack w="full" direction={["column", "column", "row"]} spacing={6}>

        {hasConnection && (
          hasConectionPlannedFlightOptions ? (
            <Select
              {...register('planned_connection_flight')}
              name='planned_connection_flight'
              label="Viagem conexão planejada"
              error={errors.planned_connection_flight}
              placeholder="Selecione uma opção..."
              options={conectionPlannedFlightFieldOptions}
              isDisabled={isFetchingRouteNetworks || isDisabled}
              required
            />
          ) : (
            <Input
              {...register('planned_connection_flight')}
              name='planned_connection_flight'
              error={errors.planned_connection_flight}
              label="Viagem conexão planejada"
              isDisabled={isFetchingRouteNetworks || isDisabled}
              required
            />
          )
        )}

        {hasPlannedFlightOptions ? (
          <Select
            {...register('planned_flight')}
            name='planned_flight'
            label="Viagem planejada"
            error={errors.planned_flight}
            placeholder="Selecione uma opção..."
            options={plannedFlightFieldOptions}
            isDisabled={isFetchingRouteNetworks || isDisabled}
            required
          />
        ) : (
          <Input
            {...register('planned_flight')}
            error={errors.planned_flight}
            name='planned_flight'
            label="Viagem planejada"
            isDisabled={isFetchingRouteNetworks || isDisabled}
            required
          />
        )}
      </Stack>
    </Stack>
  )
}

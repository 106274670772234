import axios, { AxiosInstance } from 'axios'

export class Api {
  private baseUrl: string | undefined
  protected server: AxiosInstance

  constructor(baseUrl: string | undefined) {
    this.baseUrl = baseUrl
    this.server = axios.create({
      baseURL: this.baseUrl,
      headers: { 'Content-Type': 'application/json' },
      timeout: 10000,
    })
  }
}

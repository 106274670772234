import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { getSupportsSubjects } from "api/supports/getSupportSubjects";
import { SupportSubjectsTableRow } from "./components/SupportSubjectsTableRow";
import { CreateSupportSubjectModal } from "./components/CreateSupportSubjectModal";
import { SupportsSubjectsTableFilters } from "./components/SupportsSubjectsTableFilters";
import { SupportType } from "utils/supportTypes";

export function SupportsSubjects() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const doesUserCanViewSupportsSubjects = userLogged?.permissions?.includes(
    'view-supports-subjects'
  )

  const doesUserCanCreateSupportSubject = userLogged?.permissions?.includes(
    'create-support-subject'
  )

  useEffect(() => {
    if (!doesUserCanViewSupportsSubjects) history.push('/')
  }, [history, doesUserCanViewSupportsSubjects])

  const page = searchParams.get('page') ?? '1'
  const supportType = searchParams.get('supportType') ?? 'all'

  const {
    data: supportsSubjectsResult
  } = useQuery({
    queryKey: ['supports-subjects', page, supportType],
    queryFn: () => getSupportsSubjects({
      currentPage: Number(page),
      pageSize: 10,
      supportType: supportType as SupportType | 'all'
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: supportsSubjectsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: supportsSubjectsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateSupportSubjectModalOpen,
    onOpen: onOpenCreateSupportSubjectModal,
    onClose: onCloseCreateSupportSubjectModal
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Assuntos de Atendimentos</Heading>
        {doesUserCanCreateSupportSubject && (
          <IconButton
            aria-label=""
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateSupportSubjectModal}
          />
        )}

        <Modal
          isOpen={isCreateSupportSubjectModalOpen}
          onClose={onCloseCreateSupportSubjectModal}
          isCentered
        >
          <ModalOverlay />
          <CreateSupportSubjectModal
            onCloseModal={onOpenCreateSupportSubjectModal}
          />
        </Modal>
      </Flex>
      <SupportsSubjectsTableFilters />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Tipo de suporte</Th>
              <Th color="white">Categoria</Th>
              <Th color="white">Assunto</Th>
            </Tr>
          </Thead>
          <Tbody>
            {supportsSubjectsResult?.supportsSubjects?.map((supportSubject) => {
              return <SupportSubjectsTableRow
                key={supportSubject.id}
                supportSubject={supportSubject}
              />
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

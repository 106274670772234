import axios from "axios"
import { addDays, format, set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useMutation } from "react-query"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { api } from "../../services/api"
import { useSwal } from "../swal/useSwal"
import { MaintenanceReport } from "./dtos/MaintenanceReport"

interface IReportInputProps {
  is_driver: boolean
  shipping_id?: string
  customer_id?: string
  collector_id?: string
  startFilter: Date
  endFilter: Date
  is_customer: boolean
  is_business: boolean
}

interface GenerateMaintenanceReportProps {
  start_filter?: string
  end_filter?: string
  maintenance_status: 'EM MANUTENÇÃO' | 'FINALIZADAS'
}

interface GenerateOccurrenceReportInput {
  start_filter: string
  end_filter: string
  customer_id?: string
  collector_id?: string
  shipping_id?: string
  is_customer: boolean
  is_driver: boolean
  is_collector: boolean
}

interface IGenerateReportProps {
  path: string
  input: IReportInputProps
}

async function generateReportReqFunction({ path, input }: IGenerateReportProps) {
  try {
    const startFilterFormatted = format(set(new Date(input.startFilter), {
      hours: 0,
      minutes: 0
    }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

    const endFilterFormatted = format(set(new Date(input.endFilter), {
      hours: 23,
      minutes: 59
    }), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

    const { data } = await api.post(`/report/${path}`, {
      ...input,
      startFilter: startFilterFormatted,
      endFilter: endFilterFormatted
    })

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function generateMaintenanceReportReqFunction(
  input: GenerateMaintenanceReportProps
): Promise<MaintenanceReport[]> {
  try {
    let startFilter = null

    if (input.start_filter) {
      startFilter = addDays(set(new Date(input.start_filter), {
        hours: 0,
        minutes: 0
      }), 1)
    }

    let endFilter = null

    if (input.end_filter) {
      endFilter = addDays(set(new Date(input.end_filter), {
        hours: 23,
        minutes: 59
      }), 1)
    }

    const { data } = await api.post(`/report/maintenance`, {
      ...input,
      start_filter: startFilter,
      end_filter: endFilter
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

async function generateOccurrenceReportReqFunction(
  input: GenerateOccurrenceReportInput
) {
  try {
    let startFilter = null

    if (input.start_filter) {
      startFilter = addDays(set(new Date(input.start_filter), {
        hours: 0,
        minutes: 0
      }), 1)
    }

    let endFilter = null

    if (input.end_filter) {
      endFilter = addDays(set(new Date(input.end_filter), {
        hours: 23,
        minutes: 59
      }), 1)
    }

    const { data } = await api.post(`/report/occurrence`, {
      ...input,
      start_filter: startFilter,
      end_filter: endFilter
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useReport() {

  const { successMessage, errorMessage } = useSwal()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const generateReport = useMutation('report', generateReportReqFunction, {
    onSuccess: () => {
      successMessage("Relatório gerado com sucesso!")
    },

    onError: (error: any) => {
      errorMessage(error.message)
    }
  })

  const generateMaintenanceServiceReport =
    useMutation<MaintenanceReport[], RequestError, GenerateMaintenanceReportProps>('maintenanceReport', generateMaintenanceReportReqFunction, {
      onSuccess: () => {
        successMessage("Relatório gerado com sucesso!")
      },

      onError: ({ message }: RequestError) => {
        errorMessage(message)
      }
    })

  const generateOccurrenceReport =
    useMutation('occurrenceReport', generateOccurrenceReportReqFunction, {
      onError: async (error: RequestError) => onRequestError(error)
    })

  return {
    generateReport,
    generateOccurrenceReport,
    generateMaintenanceServiceReport,
  }
}

import { Box, Flex, Heading, IconButton } from "@chakra-ui/react"
import { getCltIntercurrences } from "api/cltIntercurrences/getCltIntercurrences"
import { useAuth } from "hooks/auth/useAuth"
import { useEffect } from "react"
import { FaExchangeAlt, FaPlus } from "react-icons/fa"
import { useInfiniteQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { CltIntercurrencesKanbanColumn } from "./components/CltIntercurrencesKanbanColumn"

export function CltIntercurrencesKanban() {

  const { push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userCanCreateCltIntercurrence = userLogged?.permissions?.includes('create-clt-intercurrence')
  const userCanViewCltIntercurrences = userLogged?.permissions?.includes('view-clt-intercurrences')

  useEffect(() => {
    if (!userCanViewCltIntercurrences) redirect('/')
  }, [redirect, userCanViewCltIntercurrences])

  const {
    data: requestedCltIntercurrencesResult,
    isLoading: isRequestedCltIntercurrencesResultLoading,
    hasNextPage: hasNextPageRequestedCltIntercurrences,
    fetchNextPage: fetchNextPageRequestedCltIntercurrences,
    isFetchingNextPage: isFetchingNextPageRequestedCltIntercurrences,
  } = useInfiniteQuery({
    queryKey: ['requested-cltintercurrences'],
    queryFn: ({ pageParam = 1 }) => getCltIntercurrences({
      currentPage: pageParam,
      pageSize: '10',
      status: 'requested',
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: approvedCltIntercurrencesResult,
    isLoading: isApprovedCltIntercurrencesResultLoading,
    hasNextPage: hasNextPageApprovedCltIntercurrences,
    fetchNextPage: fetchNextPageApprovedCltIntercurrences,
    isFetchingNextPage: isFetchingNextPageApprovedCltIntercurrences,
  } = useInfiniteQuery({
    queryKey: ['approved-clt-intercurrences'],
    queryFn: ({ pageParam = 1 }) => getCltIntercurrences({
      currentPage: pageParam,
      pageSize: '10',
      status: 'approved',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: rejectedCltIntercurrencesResult,
    isLoading: isRejectedCltIntercurrencesResultLoading,
    hasNextPage: hasNextPageRejectedCltIntercurrences,
    fetchNextPage: fetchNextPageRejectedCltIntercurrences,
    isFetchingNextPage: isFetchingNextPageRejectedCltIntercurrences,
  } = useInfiniteQuery({
    queryKey: ['rejected-clt-intercurrences'],
    queryFn: ({ pageParam = 1 }) => getCltIntercurrences({
      currentPage: pageParam,
      pageSize: '10',
      status: 'rejected',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsCltIntercurrences = [
    {
      title: 'Pendentes',
      cltIntercurrences: requestedCltIntercurrencesResult?.pages.flatMap((page) => page.cltIntercurrences) || [],
      cltIntercurrencesCount: requestedCltIntercurrencesResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageRequestedCltIntercurrences,
      fetchNextPage: fetchNextPageRequestedCltIntercurrences,
      isFetchingNextPage: isFetchingNextPageRequestedCltIntercurrences,
      isLoading: isRequestedCltIntercurrencesResultLoading,
    },
    {
      title: 'Aprovadas',
      cltIntercurrences: approvedCltIntercurrencesResult?.pages.flatMap((page) => page.cltIntercurrences) || [],
      cltIntercurrencesCount: approvedCltIntercurrencesResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageApprovedCltIntercurrences,
      fetchNextPage: fetchNextPageApprovedCltIntercurrences,
      isFetchingNextPage: isFetchingNextPageApprovedCltIntercurrences,
      isLoading: isApprovedCltIntercurrencesResultLoading,
    },
    {
      title: 'Reprovadas',
      cltIntercurrences: rejectedCltIntercurrencesResult?.pages.flatMap((page) => page.cltIntercurrences) || [],
      cltIntercurrencesCount: rejectedCltIntercurrencesResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageRejectedCltIntercurrences,
      fetchNextPage: fetchNextPageRejectedCltIntercurrences,
      isFetchingNextPage: isFetchingNextPageRejectedCltIntercurrences,
      isLoading: isRejectedCltIntercurrencesResultLoading,
    },
  ]

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Intercorrências CLT</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            as={Link}
            to="/intercorrencias-clt"
          />
          {userCanCreateCltIntercurrence && (
            <IconButton
              aria-label="Criar intercorrência clt"
              as={Link}
              to="/intercorrencia-clt/criar"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
            />
          )}
        </Flex>

      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsCltIntercurrences?.map((cltIntercurrence, index) => {
          return (
            <CltIntercurrencesKanbanColumn
              key={index}
              cltIntercurrence={cltIntercurrence}
            />
          )
        })}
      </Flex>
    </Box>
  )
}

import { Button, Divider, Flex, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { Occurrence } from "hooks/occurrences/dtos/Occurrence";
import { formatDate } from "utils/DateFunctions/formatDate";
import { handleChangeUrl } from "utils/handleChangeUrl";

interface FinishedCustomerOccurrenceInfoModalProps {
  isOpen: boolean
  onClose: () => void
  occurrence: Occurrence
}

export function FinishedCustomerOccurrenceInfoModal({
  isOpen,
  onClose,
  occurrence
}: FinishedCustomerOccurrenceInfoModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        overflow='auto'
        maxH='600px'
      >
        <ModalHeader>Informações da ocorrência</ModalHeader>
        <ModalCloseButton />

        <ModalBody as={Flex} flexDirection='column' gap={4} p={4}>
          <Text as='b' >Informações de abertura</Text>

          <Input
            name='intercurrence'
            defaultValue={occurrence.intercurrence}
            size='sm'
            label='Intercorrência'
            isDisabled
          />
          {occurrence.occurrence_detail && (
            <TextArea
              name='occurrence_detail'
              defaultValue={occurrence.occurrence_detail}
              size='sm'
              label='Descrição'
              isDisabled
            />
          )}
          {occurrence.observation && (
            <TextArea
              name='observation'
              defaultValue={occurrence.observation}
              size='sm'
              label='Observações'
              isDisabled
            />
          )}
          {occurrence?.attachment_photo && (
            <Button
              as={Link}
              href={handleChangeUrl(occurrence?.attachment_photo)}
              isExternal
            >
              Visualizar anexo
            </Button>
          )}

          <Divider />

          <Text as='b'>Informações de validação</Text>

          <Input
            name='proceed'
            defaultValue={occurrence.proceed}
            size='sm'
            label='Procede?'
            isDisabled
          />

          {occurrence.reason && (
            <Input
              name='reason'
              defaultValue={occurrence.proceed}
              size='sm'
              label='Motivo'
              isDisabled
            />
          )}

          {occurrence.cause && (
            <Input
              name='cause'
              defaultValue={occurrence.cause}
              size='sm'
              label='Causa'
              isDisabled
            />
          )}
          {occurrence.corrective && (
            <Input
              name='corrective'
              defaultValue={occurrence.corrective}
              size='sm'
              label='Corretiva'
              isDisabled
            />
          )}
          {occurrence.preventive && (
            <Input
              name='preventive'
              defaultValue={occurrence.preventive}
              size='sm'
              label='Preventiva'
              isDisabled
            />
          )}

          <Divider />

          <Text as='b'>Informações de alinhamento</Text>

          {occurrence?.alignment_indicated_user && (
            <Input
              name='alignment_indicated_user'
              defaultValue={`${occurrence.occurrenceIDAlignmentIndicatedUser.firstname} ${occurrence.occurrenceIDAlignmentIndicatedUser.lastname}`}
              size='sm'
              label='Usuário indicado no alinhamento'
              isDisabled
            />
          )}

          {occurrence?.alignments?.map(alignment => {
            const alignmentRegistedAttachments = alignment?.attachments
              ? typeof alignment?.attachments !== 'string'
                ? alignment?.attachments
                : JSON.parse(alignment?.attachments)
              : []
            return (
              <>
                <Input
                  name='preventive_responsible'
                  defaultValue={`${alignment.preventiveResponsible.firstname} ${alignment.preventiveResponsible.lastname}`}
                  size='sm'
                  label='Responsável pela preventiva'
                  isDisabled
                />
                <Input
                  name='preventive_limit_date'
                  defaultValue={formatDate.handle(alignment.preventive_limit_date, "DateWithoutHourToInput")}
                  size='sm'
                  type='date'
                  label='Data limite da ação preventiva'
                  isDisabled
                />

                <TextArea
                  name='alignment_actions'
                  defaultValue={alignment.alignment_actions}
                  size='sm'
                  label='Ações de alinhamento'
                  mb={2}
                  isDisabled
                />

                {alignmentRegistedAttachments?.map((attachment) => {
                  return (
                    <Button
                      as={Link}
                      href={handleChangeUrl(attachment.key)}
                      isExternal
                    >
                      Visualizar anexo
                    </Button>

                  )
                })}

              </>
            )
          })}

        </ModalBody>

      </ModalContent>


    </Modal>
  )
}

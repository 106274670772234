import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface RequestStepChangeBody {
  step: string
}

interface  RequestStepChangeProps {
  routeParams: {
    serviceId: string
  }
  body: RequestStepChangeBody
}

export async function requestStepChange({
  routeParams,
  body
}: RequestStepChangeProps) {
  try {
    const { data } = await api.patch(`/services/${routeParams.serviceId}/request-step-change`, body)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import { Box, Button, Textarea } from "@chakra-ui/react";
import { FormEvent, useState } from "react";

interface ViewBugModalCommentFormProps {
  status: 'open' | 'validated'
  onSubmitCommentForm: (content: string, status: 'open' | 'validated') => Promise<void>
}

export function ViewBugModalCommentForm({
  status,
  onSubmitCommentForm
}: ViewBugModalCommentFormProps) {
  const [content, setContent] = useState('')

  async function handleCreateCommentForm(event: FormEvent) {
    event.preventDefault()

    await onSubmitCommentForm(content, status)
  }

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      onSubmit={handleCreateCommentForm}
    >
      <Textarea
        name="content"
        value={content ?? ''}
        onChange={(event) => setContent(event.target.value)}
        mt="2"
        mb="4"
        size="xs"
        placeholder="Digite sua mensagem aqui"
      />

      <Button
        type="submit"
        isDisabled={!content}
        size="sm"
        alignSelf="flex-end"
      >
        Enviar
      </Button>

    </Box>
  )
}

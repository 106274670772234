import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface DismissInternCltProps {
  internCltId: string
}

export async function dismissInternClt({
  internCltId
}: DismissInternCltProps) {
  try {
    const response = await api.put(`/intern-clts/${internCltId}/dismiss`)

  return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

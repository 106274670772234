import { PatrimonyType } from './patrimonyType';
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface GetPatrimonyTypesParams {
  pageSize?: string | null
  currentPage?: string | null
  type?: string
}

export interface GetPatrimonyTypesResponse {
  patrimonyTypes: PatrimonyType[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getPatrimonyTypes({
  pageSize,
  currentPage,
  type
}: GetPatrimonyTypesParams) {
  try {
    const { data } = await api.get<GetPatrimonyTypesResponse>('/patrimony-types', {
      params: {
        pageSize,
        currentPage,
        type
      }
    })
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


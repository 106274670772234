import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetPrimaryProviderByHubParams {
  hubId: string
}

interface GetPrimaryProviderByHub {
  provider: {
    id: string
    trading_name: string
    company_name: string
  } | null

}

export async function getPrimaryProviderByHub({
  hubId
}: GetPrimaryProviderByHubParams) {
  try {
    const response = await api.get<GetPrimaryProviderByHub>(`/hubs/${hubId}/primary-provider`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Box, Flex } from "@chakra-ui/react"

export type PmcStatusTypes = 'all' | 'approving' | 'receiving' | 'received'

interface PmcStatusProps {
  status: PmcStatusTypes
}

const pmcStatusMap: Record<PmcStatusTypes, string> = {
  all: 'Todos status',
  approving: 'Em aprovação',
  receiving: 'Em recebimento',
  received: 'Recebido',
}

export function PmcStatus({ status }: PmcStatusProps) {
  return (
    <Flex
      align="center"
      gap="2"
    >
      {['approving', 'receiving'].includes(status) && (
        <Box w="2" h="2" rounded="full" bgColor="orange.300" />
      )}
      {status === 'received' && (
        <Box w="2" h="2" rounded="full" bgColor="green.300" />
      )}
      {pmcStatusMap[status]}
    </Flex>
  )
}

import { useShipping } from 'hooks/shipping/useShipping'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CreatePriceForm } from '../../components/Form/Price/CreatePriceForm'
import { EditDetailPriceForm } from '../../components/Form/Price/EditDetailPriceForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useCity } from '../../hooks/city/useCity'
import { useHub } from '../../hooks/hub/useHub'
import { usePrice } from '../../hooks/price/usePrice'
import { usePriceFunctions } from '../../hooks/price/usePriceFunctions'
import { useSwal } from '../../hooks/swal/useSwal'
import { transformStringToNumber } from '../../utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

export type PriceCategory =
  | "BIOLÓGICO"
  | "CARGA GERAL EMERGENCIAL"
  | "CARGA GERAL CONVENCIONAL";

interface IFormInputProps {
  source_city_id: string
  source_hub_id: string
  destination_city_id: string
  destination_hub_id: string
  route_type: string
  air_minimum_price: string | null
  air_extra_kg: string | null
  air_deadline: number | null
  highway_minimum_price: string | null
  highway_extra_kg: string | null
  highway_deadline: number | null
  additional_collect_price: string
  additional_delivery_price: string
  observation: string | null
  category: PriceCategory;
  vehicle: string
  shippingIds: string[]
  service_type: 'FRACIONADO' | 'DEDICADO'
}

interface IQueryParamsProps {
  id?: string
  slug: string
}

async function formatValuesToBack(values: IFormInputProps) {
  const valuesFormated = {
    ...values,
    shippingIds: values.service_type === 'DEDICADO' ? [] : values.shippingIds,
    observation: values.observation ? values.observation : null,
    air_minimum_price: values.air_minimum_price
      ? transformStringToNumber(values.air_minimum_price)
      : null,
    air_extra_kg: values.air_extra_kg
      ? transformStringToNumber(values.air_extra_kg)
      : null,
    air_deadline: values.air_deadline !== null ? values.air_deadline : null,
    highway_minimum_price: values.highway_minimum_price
      ? transformStringToNumber(values.highway_minimum_price)
      : null,
    highway_extra_kg: values.highway_extra_kg
      ? transformStringToNumber(values.highway_extra_kg)
      : null,
    highway_deadline:
      values.highway_deadline !== null ? values.highway_deadline : null,
    additional_collect_price: transformStringToNumber(
      values.additional_collect_price,
    ),
    additional_delivery_price: transformStringToNumber(
      values.additional_delivery_price,
    ),
  }

  return valuesFormated
}

export function CreateDetailEditPrice() {
  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()
  const { id, slug }: IQueryParamsProps = useParams()
  const { confirmMessage, standardMessage } = useSwal()

  const {
    hubs: { data: hubs, isLoading: isHubsLoading },
  } = useHub(null, true)
  const {
    price: { data: price, isLoading: isPriceLoading },
  } = usePrice(id)
  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)

  const {
    shipping: { data: shippings, isLoading: isShippingLoading },
  } = useShipping(null, true, false)

  const {
    createPrice: {
      mutateAsync: createPrice,
      isLoading: isCreatePriceLoading,
      isSuccess: isCreatePriceSuccess,
    },
    editPrice: {
      mutateAsync: editPrice,
      isLoading: isEditPriceLoading,
      isSuccess: isEditPriceSuccess,
    },
  } = usePriceFunctions()

  useEffect(() => {
    if (isCreatePriceSuccess || isEditPriceSuccess) {
      redirectTo('/precos')
    }
  }, [isCreatePriceSuccess, isEditPriceSuccess, redirectTo])

  useEffect(() => {
    if (slug === 'adicionar') {
      if (!userLogged?.permissions.includes('add-price')) {
        redirectTo('/')
      }
    }
    if (slug === 'editar') {
      if (!userLogged?.permissions.includes('edit-price')) {
        redirectTo('/')
      }
    }
    if (slug === 'visualizar') {
      if (!userLogged?.permissions.includes('view-price')) {
        redirectTo('/')
      }
    }
  }, [slug, userLogged, redirectTo])

  async function handleCreatePrice(values: IFormInputProps) {
    const hasCreatePrice = await confirmMessage({
      title: 'Deseja criar um preço?',
    })

    if (hasCreatePrice) {
      const formatedValuesToBack = await formatValuesToBack(values)
      await createPrice(formatedValuesToBack)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleEditPrice(values: IFormInputProps) {
    const hasCreatePrice = await confirmMessage({
      title: 'Deseja editar um preço?',
    })

    if (hasCreatePrice && id) {
      const formatedValuesToBack = await formatValuesToBack(values)
      const createPriceReqData = { id, input: formatedValuesToBack }
      await editPrice(createPriceReqData)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (
    isCitiesLoading ||
    isCreatePriceLoading ||
    isHubsLoading ||
    isEditPriceLoading ||
    isPriceLoading ||
    isShippingLoading
  ) {
    return <GeneralContentLoading />
  }

  return (
    <>
      {slug === 'adicionar' && cities && hubs && (
        <CreatePriceForm
          submit={handleCreatePrice}
          cities={cities}
          hubs={hubs}
          shippings={shippings}
        />
      )}

      {(slug === 'visualizar' || slug === 'editar') &&
        cities &&
        hubs &&
        price &&
        slug && (
          <EditDetailPriceForm
            submit={handleEditPrice}
            cities={cities}
            hubs={hubs}
            price={price}
            shippings={shippings}
            slug={slug}
          />
        )}

    </>
  )
}

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useEffect, useContext } from 'react'


import { Input } from '../../components/Inputs/Input'
import { UserContext } from '../../contexts/UserContext'
import swal from 'sweetalert'
import { useAuth } from '../../hooks/auth/useAuth'

interface FormInputProps {
  email: string
  firstname: string
  lastname: string
  oldPassword: string
  newPassword: string
  newPasswordRepeat: string
}

interface ISettingProps {
  title: string
  isDisabled?: boolean
}

const hubSchema = yup.object().shape({
  email: yup.string().required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  oldPassword: yup.string().required(),
  newPassword: yup.string().required(),
  newPasswordRepeat: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Senhas precisam ser as mesmas'),
})

export function Settings({ title, isDisabled = false }: ISettingProps) {
  const { editUserPassword } = useContext(UserContext)
  const { userLogged } = useAuth()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(hubSchema),
  })

  useEffect(() => {
    function run() {
      if (userLogged !== null) {
        setValue('email', userLogged.email)
        setValue('firstname', userLogged.firstname)
        setValue('lastname', userLogged.lastname)
      }
    }
    run()
  }, [setValue, userLogged])

  const handleChangePassword = async (values: FormInputProps) => {
    const userId = userLogged?.id
    if (userId !== undefined) {
      await swal({
        title: 'Deseja alterar a senha?',
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar'],
        dangerMode: false,
      }).then((willChangePassword) => {
        if (willChangePassword) {
          editUserPassword(userId, values)
        } else {
          swal('Ação cancelada com êxito!')
        }
      })
    }
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(handleChangePassword)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <HStack spacing="24px">
            <Input
              {...register('email')}
              isDisabled={isDisabled}
              name="name"
              label="Nome"
              error={errors.email}
              required
            />
          </HStack>

          <HStack spacing="24px" mt="4">
            <Input
              {...register('firstname')}
              isDisabled={isDisabled}
              name="name"
              label="Nome"
              error={errors.firstname}
              required
            />

            <Input
              {...register('lastname')}
              isDisabled={isDisabled}
              name="name"
              label="Nome"
              error={errors.firstname}
              required
            />
          </HStack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('oldPassword')}
              isDisabled={isDisabled}
              name="oldPassword"
              label="Senha atual"
              type="password"
              error={errors.oldPassword}
              required
            />

            <Stack
              spacing="24px"
              w="100%"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('newPassword')}
                isDisabled={isDisabled}
                name="newPassword"
                label="Nova senha"
                type="password"
                error={errors.newPassword}
                required
              />
              <Input
                {...register('newPasswordRepeat')}
                isDisabled={isDisabled}
                name="newPasswordRepeat"
                type="password"
                label="Confirmar nova senha"
                error={errors.newPasswordRepeat}
                required
              />
            </Stack>
          </Stack>

          <Flex mt="8" justify="flex-end">
            <HStack>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                loadingText="Carregando"
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}

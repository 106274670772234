import { Button, Flex, Grid, GridItem, Skeleton } from "@chakra-ui/react"
import { getInternComunicationsByOwnerId } from "api/internComunications/getInternComunicationsByOwnerId"
import { useAuth } from "hooks/auth/useAuth"
import { useSearchParams } from "hooks/useSearchParams"
import { useInfiniteQuery } from "react-query"
import { InternComunicationCard } from "./InternComunicationCard"

export function OwnerInternComunications() {
  const { userLogged } = useAuth()
  const searchParams = useSearchParams()

  const customer = searchParams.get('customer');
  const responsible = searchParams.get('responsible');
  const serviceProtocol = searchParams.get('serviceProtocol');
  const sourceHub = searchParams.get('sourceHub');
  const destinationHub = searchParams.get('destinationHub');
  const date = searchParams.get('date');
  const hour = searchParams.get('hour');
  const comunicationType = searchParams.get('comunicationType')
  const comunicationProtocol = searchParams.get('comunicationProtocol')

  const {
    data: result,
    isLoading: isInternComunicationsByOwnerLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'owner-intern-comunications',
      userLogged?.id,
      customer,
      responsible,
      serviceProtocol,
      comunicationProtocol,
      sourceHub,
      destinationHub,
      date,
      hour,
      comunicationType
    ],
    queryFn: ({ pageParam = 1 }) => getInternComunicationsByOwnerId({
      routeParams: {
        ownerId: userLogged?.id
      },
      queryParams: {
        currentPage: pageParam,
        pageSize: '9',
        customer,
        responsible,
        serviceProtocol,
        comunicationProtocol,
        sourceHub,
        destinationHub,
        date,
        hour,
        timezoneOffset: new Date().getTimezoneOffset() / 60,
        comunicationType
      }
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  return (
    <Flex
      direction="column"
    >
      <Grid
        gap="4"
        templateColumns={{ base: 'repeat(1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
      >
        {isInternComunicationsByOwnerLoading && (
          Array.from({ length: 6 }).map((_, index) => {
            return (
              <GridItem key={index.toString()}>
                <Skeleton h={335} w="full" rounded="lg" />
              </GridItem>
            )
          })
        )}
        {result?.pages?.map((page) => {
          return page?.internComunications?.map((internComunication) => {
            return (
              <GridItem key={internComunication.id}>
                <InternComunicationCard internComunication={internComunication} />
              </GridItem>
            )
          })
        })}
      </Grid>
      {hasNextPage && (
        <Button
          colorScheme="blue"
          alignSelf="center"
          mt="6"
          w={{ base: 'full', md: "400px" }}
          onClick={() => fetchNextPage()}
          isLoading={isFetchingNextPage}
          disabled={isFetchingNextPage}
        >
          Carregar mais
        </Button>
      )}
    </Flex>
  )
}

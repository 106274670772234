import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FiCheckCircle } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { TableFilterButton } from "../../../../components/Filters/TableFilterButton";
import { GeneralContentLoading } from "../../../../components/Loading/GeneralContentLoading";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { SearchBox } from "../../../../components/SearchBox/SearchBox";

import { useAuth } from "../../../../hooks/auth/useAuth";
import { useCollectServices } from "../../../../hooks/collectService/useCollectService";
import { useFilterOptions } from "../../../../hooks/filterOptions/useFilterOptions";
import { formatDate } from "../../../../utils/DateFunctions/formatDate";
import { searchBoxFilter } from "../../../../utils/searchBoxFilter";
import { serviceSortByDate } from "../../../../utils/ServiceFunctions/serviceSortByDate";

interface FilterOptionInputs {
  protocol: string;
  address: string;
  collect_date: string;
  customer: string;
  collector: string;
}

const unsuccessCollectsPendingfilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'address', value: 'Endereço', checked: false },
  { key: 'collect_date', value: 'Data da coleta', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'collector', value: 'Coletador', checked: false },
]

const unsuccessCollectsPendingOrderOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'collect_date', value: 'Data da coleta', checked: false },
]

export function UnsuccessCollectsPendingValidationTable() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const {
    collectServices: {
      data: collectServices, isLoading: isCollectServicesLoading
    }
  } = useCollectServices("VALIDATEUNSUCCESS")

  useEffect(() => {
    onLoadSetFilterOptions(unsuccessCollectsPendingfilterOptions)
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    onLoadSetOrderOptions(unsuccessCollectsPendingOrderOptions)
  }, [onLoadSetOrderOptions])

  const userHasPermissionToValidateUnsuccesCollectAddress = userLogged?.permissions.includes("validate-unsuccess-collect-service")

  useEffect(() => {
    if (!userHasPermissionToValidateUnsuccesCollectAddress) {
      redirectTo("/")
    }
  }, [userHasPermissionToValidateUnsuccesCollectAddress, redirectTo])

  const {
    register,
    watch,
  } = useForm<FilterOptionInputs>()

  const filterValues = watch(["protocol", "address", "collect_date", "customer", "collector"])

  const collectServicesFiltered = collectServices?.filter(collect => {

    const [protocolFiltered, addressFiltered, collectDateFiltered, customerFiltered, collectorFiltered] = filterValues

    const { street, number, cep, neighborhood } = collect.addressIDCollect ?? collect.providerIDCollect

    const protocolFilter = protocolFiltered
      ? searchBoxFilter(String(collect.serviceIDCollect.protocol), protocolFiltered)
      : null
    const addressFilter = addressFiltered
      ? searchBoxFilter(`${street}, ${number}, ${neighborhood} - ${cep}`, addressFiltered)
      : null
    const collectDateFilter = collectDateFiltered
      ? searchBoxFilter(formatDate.handle(collect.requestedService.collect_date, "DateWithoutHourToShow"), collectDateFiltered)
      : null
    const customerFilter = customerFiltered
      ? searchBoxFilter(collect.serviceIDCollect.customerIDService.trading_firstname, customerFiltered)
      : null
    const collectorFilter = collectorFiltered
      ? searchBoxFilter(collect.requestedService.sourceCollectorIDService.trading_name, collectorFiltered)
      : null

    if (filterValues.some(value => !!value)) {
      return protocolFilter || collectDateFilter || customerFilter || collectorFilter || addressFilter
    }

    return collect
  })
    .sort((a, b) => {
      const isProtocolOrder = orderOptionSelected.includes("protocol")
        ? a.serviceIDCollect.protocol - b.serviceIDCollect.protocol
        : null

      const isCollectDateOrder = orderOptionSelected.includes("collect_date")
        ? serviceSortByDate(a.requestedService.collect_date, b.requestedService.collect_date)
        : null

      if (!isProtocolOrder && !isCollectDateOrder) {
        return 0
      }

      return isProtocolOrder! || isCollectDateOrder!
    })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: collectServices?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  if (isCollectServicesLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Box
        bg="white"
        padding={[2, 4]}
        borderRadius="8px"
        flex="1"
      >
        <Flex
          gap="2"
          direction="column"
        >
          <Heading
            size="lg"
            fontFamily="poppins"
            textAlign={["center", "center", "justify"]}
          >
            Coletas sem sucesso pendente validação
          </Heading>
          <Flex justify="space-between" flex="1" w="full">
            <TableFilterButton />

            <Flex direction="column" gap="4">
              {filterOptions
                .filter(option => option.checked === true)
                .map((option) => {
                  return (
                    <SearchBox
                      {...register(option.key as keyof FilterOptionInputs)}
                      key={option.key}
                      size="sm"
                      placeholder={'Buscar ' + option.value}
                      handleSearch={() => { }}
                    />
                  )
                })}
            </Flex>
          </Flex>
          <TableContainer w="full">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Td></Td>
                  {unsuccessCollectsPendingfilterOptions.map(option => {
                    return (
                      <Th key={option.key}>{option.value.toUpperCase()}</Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {collectServicesFiltered
                  ?.slice(offset, offset + Number(itemLimit))
                  ?.map(collect => {
                    const { street, number, cep, neighborhood } = collect.addressIDCollect ?? collect.providerIDCollect

                    return (
                      <Tr key={collect.id}>
                        <Td>
                          <Link to={`/servicos/coletas-sem-sucesso/${collect.service_id}`}>
                            <Icon
                              cursor="pointer"
                              as={FiCheckCircle}
                              fontSize="20"
                              mr="2"
                              mt={['2', '2', '0']}
                            />
                          </Link>
                        </Td>
                        <Td>{collect.serviceIDCollect.protocol} </Td>
                        <Td>{`${street}, ${number}, ${neighborhood} - ${cep}`} </Td>
                        <Td>{formatDate.handle(collect.requestedService.collect_date, "DateWithoutHourToShow")} </Td>
                        <Td>{collect.serviceIDCollect.customerIDService.trading_firstname} </Td>
                        <Td>{collect.requestedService.sourceCollectorIDService.trading_name} </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </TableContainer>

          <Pagination
            currentPage={currentPage}
            pagesQuantity={pagesCount}
            pages={pages}
            handlePageChange={setCurrentPage}
          />
        </Flex>
      </Box>

  )
}


import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { Collector } from "hooks/collector/dtos/Collector";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Carrier } from "./_types/Carrier";

interface GetCarrierResponse {
  carrier: Carrier & {
    collector: Collector
    dtVaccineDoseAttachment: Attachment
    hepatiteBVaccineDoseAttachment: Attachment
    tripliceViralVaccineDoseAttachment: Attachment
    cnhAttachment: Attachment
    crlvAttachment: Attachment
    anttMotorFreightAttachment: Attachment
    vehicleSidePhotoAttachment: Attachment
    vehicleRearPhotoAttachment: Attachment
    vehicleInternPhotoAttachment: Attachment
  }
}

interface GetCarrierParams {
  carrierId: string
}

export async function getCarrier({
  carrierId
}: GetCarrierParams) {
  try {
    const response = await api.get<GetCarrierResponse>(`/carriers/${carrierId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

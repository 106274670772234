import { useMutation, useQueryClient } from 'react-query'
import {
  createCityReqFunction,
  deleteCityReqFunction,
  editCityReqFunction,
} from '../../utils/RequestFunctions/City/requestCityFunctions'
import { useSwal } from '../swal/useSwal'

export const useCityFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createCity = useMutation('createCity', createCityReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['cities'])
      successMessage('Cidade criada com sucesso!')
    },

    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
    },
  },
  )

  const editCity = useMutation('editCity', editCityReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['city'])
      await queryClient.invalidateQueries(['cities'])
      successMessage('Cidade editada com sucesso!')
    },

    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
    },
  },
  )
  const deleteCity = useMutation('deleteCity', deleteCityReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['cities'])
      successMessage('Cidada excluída com sucesso!')
    },

    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
    },
  },
  )

  return {
    createCity,
    editCity,
    deleteCity,
  }
}

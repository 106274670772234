import { AuditStatus } from './../api/audits/_types/Audit'

export const auditStatusMap: Record<AuditStatus, string> = {
  pending: 'Não Realizada',
  approved: 'Aprovada',
  reproved: 'Reprovada'
}





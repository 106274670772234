import { Box, Button, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { dismissInternClt } from "api/internClts/dismissInternClt";
import { getInternClt } from "api/internClts/getInternClt";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DismissalComunicationInternClt } from "./DismissalComunicationInternClt";
import { DismissalDocsInternClt } from "./DismissalDocsInternClt";
import { DismissalFinancialInternClt } from "./DismissalFinancialInternClt";
import { DismissalPaymentInternClt } from "./DismissalPaymentInternClt";
import { HireInternClt } from "./HireInternClt";
import { InternCltDetailTable } from "./InternCltDetailTable";
import { RegisterInternCltOnPontoMais } from "./RegisterInternCltOnPontoMais";
import { SendInternCltBankDetails } from "./SendInternCltBankDetails";

interface InternCltDetailProps {
  internCltId: string
}

export function InternCltDetail({
  internCltId
}: InternCltDetailProps) {
  const queryClient = useQueryClient()

  const { promiseMessage } = useToastify()

  const { userLogged } = useAuth()
  const userCanDismissInternClt = userLogged?.permissions?.includes(
    'dismiss-intern-clt'
  )


  const {
    data: internCltData,
    isLoading: isLoadingInternCltData
  } = useQuery({
    queryKey: ['intern-clt', internCltId],
    queryFn: () => getInternClt({ routeParams: { internCltId } })
  })

  const { mutateAsync: dismissInternCltFn } = useMutation({
    mutationFn: dismissInternClt,
    onSuccess(_data, variables) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })
    },
  })


  async function handleDismissInternClt() {
    await promiseMessage(dismissInternCltFn({
      internCltId
    }), 'Processo de demissão iniciado')
  }

  return (
    <ModalContent>
      <ModalHeader>
        Detalhes do CLT Interno
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        {isLoadingInternCltData ? (
          <Table size="sm">
            <Tbody>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        ) : (
          <Grid
            templateColumns={['hiring', 'pontomais-registering', 'sending-bank-details', 'dismissal-docs', 'dismissal-comunication', 'dismissal-payment', 'dismissal-financial']
              .includes(internCltData?.internClt.status) && userCanDismissInternClt
              ? "1fr auto 1fr"
              : "1fr"
            }
            gap={6}
          >
            <GridItem>
              <InternCltDetailTable
                internCltData={internCltData}
              />
            </GridItem>
            {userCanDismissInternClt && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <GridItem>
                  {internCltData?.internClt?.status === 'hiring' && (
                    <HireInternClt
                      internCltId={internCltData?.internClt?.id}
                    />
                  )}
                  {internCltData?.internClt?.status === 'pontomais-registering' && (
                    <RegisterInternCltOnPontoMais
                      internCltId={internCltData?.internClt?.id}
                    />
                  )}
                  {internCltData?.internClt?.status === 'sending-bank-details' && (
                    <SendInternCltBankDetails
                      internCltId={internCltData?.internClt?.id}
                    />
                  )}
                  {internCltData?.internClt?.status === 'dismissal-docs' && (
                    <DismissalDocsInternClt
                      internCltId={internCltData?.internClt?.id}
                    />
                  )}
                  {internCltData?.internClt?.status === 'dismissal-comunication' && (
                    <DismissalComunicationInternClt
                      internCltId={internCltData?.internClt?.id}
                    />
                  )}
                  {internCltData?.internClt?.status === 'dismissal-payment' && (
                    <DismissalPaymentInternClt
                      internCltId={internCltData?.internClt?.id}
                    />
                  )}
                  {internCltData?.internClt?.status === 'dismissal-financial' && (
                    <DismissalFinancialInternClt
                      internCltId={internCltData?.internClt?.id}
                    />
                  )}
                </GridItem>
              </>
            )}
          </Grid>
        )}


      </ModalBody>
      {internCltData?.internClt?.status === 'active' && userCanDismissInternClt && (
        <ModalFooter>
          <Button
            size="sm"
            colorScheme='blue'
            onClick={handleDismissInternClt}
          >
            Iniciar processo de demissão
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  )
}

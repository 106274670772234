import { useQuery, UseQueryOptions, QueryKey } from "react-query";
import request from "axios"
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { Aggregate } from "./dtos/Aggregate";

type QueryOptions = UseQueryOptions<Aggregate[], unknown, Aggregate[], QueryKey>

interface AggregatesQueryParams {
  status: 'in-qualification' | 'active' | 'in-resigning' | 'inactive'
}

interface UseAggregatesOptions {
  queryKey?: QueryKey
  queryParams?: AggregatesQueryParams
  queryOptions?: QueryOptions
}

export async function getAggregates(queryParams?: AggregatesQueryParams) {
  try {
    const { data } = await api.get<Aggregate[]>(`/aggregates`, {
      params:{
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new Error("Erro inesperado!")
    }
  }
}

export function useAggregates(options?: UseAggregatesOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey ?? "aggregates",
    { ...options?.queryParams }
  ], () => getAggregates(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

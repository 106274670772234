import { differenceInMinutes, set } from "date-fns"

export function comparePlannedRouteArrivalHourIsBiggerThanDestinationBranchOpenHour(
  arrivalHour: string,
  branchOpenHour: string,
) {
  const [arrivalHours, arrivalMinutes] = arrivalHour.split(":").map(time => Number(time))
  const [branchOpenHours, branchCloseMinutes] = branchOpenHour.split(":").map(time => Number(time))

  const difference = differenceInMinutes(
    set(new Date(), { hours: arrivalHours, minutes: arrivalMinutes }),
    set(new Date(), { hours: branchOpenHours, minutes: branchCloseMinutes }),
  )

  if (difference > 0) return true

  return false
}

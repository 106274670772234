import { useQuery } from 'react-query'
import {
  getAllPricesReqFunction,
  getOnePriceReqFunction,
} from '../../utils/RequestFunctions/Price/requestPriceFunctions'
import { useAuth } from '../auth/useAuth'
import { useSwal } from '../swal/useSwal'

export function usePrice(id?: string, refetchOnFocus?: boolean) {
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useSwal()

  const prices = useQuery('prices', getAllPricesReqFunction, {
    enabled: !id && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      errorMessage(error.message || 'Erro Inesperado!')
      if (error.status === 401) {
        handleLogout()
      }
    },
  })

  const price = useQuery(
    ['price', id],
    () => getOnePriceReqFunction(id || ''),
    {
      enabled: !!id && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.status === 401) {
          handleLogout()
        }
      },
    },
  )

  return {
    prices,
    price,
  }
}

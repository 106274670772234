import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getAudits } from "api/audits/getAudits";
import { Audit, AuditStatus } from "api/audits/_types/Audit";
import { Customer } from "api/customers/_types/Customer";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { auditStatusMap } from "utils/auditMappers";
import { captalize } from "utils/captalize";
import { AuditsTableRow } from "./components/AuditsTableRow";
import { AuditTableFilters } from "./components/AuditTableFilters";
import { CreateAudit } from "./components/CreateAudit";


const headers = [
  { label: 'DATA PROGRAMADA', key: 'scheduled_date' },
  { label: 'TIPO DE AUDITORIA', key: 'type' },
  { label: 'CLIENTE', key: 'customer' },
  { label: 'COLABORADOR', key: 'collaborator' },
  { label: 'STATUS', key: 'status' },
]

function formatValuesToReport(values: Array<Audit & { customer: Customer }>) {
  return values?.map((audit) => ({
    ...audit,
    scheduled_date: audit.scheduled_date
      ? format(new Date(audit.scheduled_date), 'dd/MM/yyyy')
      : '-',
    customer: audit.customer ? captalize(audit?.customer.trading_firstname) : '-',
    type: captalize(audit.type),
    collaborator: audit.collaborator ? captalize(audit.collaborator) : 'N/A',
    status: auditStatusMap[audit?.status],
  }))
}


export function Audits() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewAudits = userLogged?.permissions?.includes(
    'view-audits'
  )

  const userCanCreateAudit = userLogged?.permissions?.includes(
    'create-audit'
  )

  useEffect(() => {
    if (!userCanViewAudits) history.push('/')
  }, [history, userCanViewAudits])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const type = searchParams.get('type')

  const {
    data: auditsResult
  } = useQuery({
    queryKey: ['audits', page, status, type],
    queryFn: () => getAudits({
      currentPage: Number(page),
      pageSize: 10,
      status: status as AuditStatus | 'all',
      type
    })
  })

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: auditsDataReportResult,
    isLoading: isLoadingAuditsDataReportResult
  } = useQuery({
    queryKey: ['audits', page, status, type, auditsResult?.meta?.count],
    queryFn: () => getAudits({
      currentPage: 1,
      pageSize: auditsResult?.meta?.count,
      status: ["pending", "approved", "reproved"].includes(status) ? status as AuditStatus : 'all',
      type
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })


  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: auditsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: auditsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateAuditOpen,
    onOpen: onOpenCreateAudit,
    onClose: onCloseCreateAudit
  } = useDisclosure()

  const csvReportProps = {
    data: auditsDataReportResult ? formatValuesToReport(auditsDataReportResult?.audits) : [],
    headers,
    filename: `auditorias.csv`,
  }


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Auditorias</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/auditorias/kanban')}
          />
          {userCanCreateAudit && (
            <IconButton
              aria-label="Criar auditoria"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateAudit}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreateAuditOpen}
          onClose={onCloseCreateAudit}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <CreateAudit onClose={onCloseCreateAudit} />
        </Modal>
      </Flex>
      <AuditTableFilters />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Data programada</Th>
              <Th color="white">Tipo de auditoria</Th>
              <Th color="white">Cliente</Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {auditsResult?.audits?.map((audit) => {
              return (
                <AuditsTableRow
                  key={audit.id}
                  audit={audit}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!auditsDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingAuditsDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )

}

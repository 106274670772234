import { Collector } from 'hooks/collector/dtos/Collector'
import { api } from '../../api'

export interface IDriverProps {
  id: string
  situation: string
  collector_id: string
  collectorIDDriver: Collector
  firstname: string
  lastname: string
  cpf: string
  email: string
  observation: string
  intervals: {
    time_start_in_minutes: number
    time_end_in_minutes: number
    week_day: number
  }[] | null
}

export const getAllDrivers = () =>
  api.get<IDriverProps[]>('/driver').then((res) => res.data)
export const getOneDriver = (driverId: string) =>
  api.get<IDriverProps>(`/driver/${driverId}`).then((res) => res.data)

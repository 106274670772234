import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  CheckboxGroup,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckBoxProps,
  Text,
  Stack,
  Box,
  Flex,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'

interface HubProps {
  id: string
  name: string
}

interface InputProps extends ChakraCheckBoxProps {
  slug?: string
  id?: string
  defaultOption?: string[]
  hubs?: HubProps[]
  name: string
  label?: string
  required?: boolean
  error?: FieldError
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    slug,
    id,
    hubs,
    defaultOption,
    required = false,
    error = null,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            <Flex h="full" w="full" gap="1" align="center">
              <Text fontSize="sm" color="gray.700" w="full">
                {label}
              </Text>
              {required && (
                <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                  {' '}
                  *
                </Text>
              )}
            </Flex>
          </FormLabel>
        </Flex>
      )}

      <Box
        overflowY="auto"
        height="100px"
        borderWidth="1px"
        borderRadius="lg"
        p="2"
      >
        {!!hubs && (
          <Stack spacing="10px" justify="flex-start">
            {slug !== 'adicionar' && (
              <CheckboxGroup colorScheme="blue" defaultValue={defaultOption}>
                <Stack direction="column">
                  {hubs?.map((hub) => (
                    <ChakraCheckbox
                      key={hub.id}
                      name={name}
                      label={label}
                      value={hub.id}
                      error={error}
                      ref={ref}
                      {...rest}
                    >
                      {hub.name.toUpperCase()}
                    </ChakraCheckbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            )}
          </Stack>
        )}
      </Box>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const CheckboxCollector = forwardRef(CheckboxBase)

import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client"

class SocketApi {
  connect(path: string, options?: Partial<ManagerOptions & SocketOptions>): Socket {
    const socket = io(`${process.env.REACT_APP_API_BACKEND_SOCKET}/${path}`, options)

    return socket
  }
}

const socketApi = new SocketApi()

function connectSocket(path: string, options?: Partial<ManagerOptions & SocketOptions>): Socket {
  return io(`${process.env.REACT_APP_API_BACKEND_SOCKET}/${path}`, options)
}


export { socketApi, connectSocket }

import { Box, Text, Icon, Flex } from "@chakra-ui/react"
import { Address } from "hooks/address/dtos/Address"
import { ReactNode } from "react"
import { FaMapPin } from "react-icons/fa"

interface ValidateNewServiceAddressesFormProps {
  address: Address
  children: ReactNode
  observations?: string | null
}

export function ValidateNewServiceAddressesCard({
  address,
  children,
  observations
}: ValidateNewServiceAddressesFormProps) {

  return (
    <Box
      display="flex"
      alignItems='center'
      justifyContent="space-between"
      flexDirection="row"
      gap="4"
      boxShadow="base"
      rounded="md"
      bgColor="white"
      p="4"
    >
      <Flex align="center" gap="4">
        <Icon as={FaMapPin} fontSize="2xl" />
        <Flex
          direction="column"
          gap="1"
        >
          <Text>
            {`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name}`}
          </Text>
          {observations && (
            <Text>
              {observations}
            </Text>
          )}
        </Flex>
      </Flex>
      {children}
    </Box>
  )
}

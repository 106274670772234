import { useState } from 'react'

const useContentLoading = () => {
  const [isContentLoading, setIsContentLoading] = useState(true)

  const handleSetIsContentLoadingFalse = () => setIsContentLoading(false)

  return { isContentLoading, handleSetIsContentLoadingFalse }
}

export { useContentLoading }

import { Menu, MenuButton, Button, MenuList, MenuOptionGroup, MenuItemOption } from "@chakra-ui/react";
import { useMaterialRequestsStatusTableButton } from "hooks/materialRequests/useMaterialRequestsStatusTableButton";
import { FaChevronDown } from "react-icons/fa";
import { MaterialRequestsStatusEnum } from "../MaterialRequestsList";

const materialRequestsStatusOptions = [
  { key: '0', value: 'requested', showOption: 'Solicitado' },
  { key: '1', value: 'awaiting-delivery', showOption: 'Aguardando entrega' },
  { key: '2', value: 'delivered', showOption: 'Entregue' },
  { key: '3', value: 'reproved', showOption: 'Reprovado' },
]

export function MaterialRequestsStatusTableButton() {
  const { status, handleChangeStatus } = useMaterialRequestsStatusTableButton()

  return (
    <Menu>
      <MenuButton fontSize="12px" variant="ghost" size="sm" as={Button} rightIcon={<FaChevronDown />}>
        STATUS
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue="active"
          value={status}
          type="radio"
          onChange={
            (status: keyof typeof MaterialRequestsStatusEnum) => handleChangeStatus(status)
          }
        >
          {materialRequestsStatusOptions.map((option) => {
            return (
              <MenuItemOption value={option.value} key={option.key}>{option.showOption}</MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

import { FormProvider, useForm } from "react-hook-form";
import { StandardBackgroundForm } from "../../components/Form/StandardBackgroundForm";
import { Input } from "../../components/Inputs/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { Button, Flex, Stack, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { usePdfFunctions } from "../../hooks/pdf/usePdfFunctions";
import { SubmitButton } from "../../components/Buttons/SubmitButton";
import { useAuth } from "../../hooks/auth/useAuth";
import { useHistory } from "react-router-dom";
import { SelectPdfTypeInput } from "./components/SelectPdfTypeInput";

interface IFormProps {
  type: string
  pdf_files: FileList;
}

const schema = yup.object().shape({
  type: yup.string().required("Campo Obrigatório"),
  pdf_files: yup.mixed().required("Campo Obrigatório")
})

export function UploadPdfPage() {
  const [selectedPdfFiles, setSelectedPdfFiles] = useState<string[] | null>(null)

  const formMethods = useForm<IFormProps>({
    resolver: yupResolver(schema)
  })

  const { register, setValue, reset, handleSubmit, formState: { errors, isSubmitting } } = formMethods

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes("upload-pdf")) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  const {
    sendPdfFiles: {
      mutateAsync: sendPdfFiles,
      isSuccess: isSendPdfFilesSuccess
    }
  } = usePdfFunctions()

  useEffect(() => {
    if (isSendPdfFilesSuccess) {
      reset()
      setSelectedPdfFiles(null)
    }
  }, [isSendPdfFilesSuccess, reset])

  const inputRefPdfFiles = useRef<HTMLInputElement | null>(null)
  const handleInputRefPdfFiles = () => inputRefPdfFiles.current?.click()

  function handleSelectPdfFiles(e: { target: HTMLInputElement }) {
    const files = e.target.files || e.target.files

    if (!files || files.length === 0) {
      setSelectedPdfFiles(null)
      return
    }

    const filesNames = []

    for (let i = 0; i < files.length; i++) {
      filesNames.push(files[i].name)
    }

    setValue("pdf_files", files)
    setSelectedPdfFiles(filesNames)
  }

  async function handleSendPdfFiles(data: IFormProps) {
    const formData = new FormData()

    for (let i = 0; i < data.pdf_files.length; i++) {
      formData.append("pdf_files", data.pdf_files[i] as any)
    }
    formData.append("type", data.type)


    await sendPdfFiles(formData)
  }

  return (
    <StandardBackgroundForm title="Upload PDF" onSubmit={handleSubmit(handleSendPdfFiles)}>
      <Stack spacing="24px">
        <FormProvider {...formMethods}>
          <SelectPdfTypeInput />
        </FormProvider>
        <Input
          {...register("pdf_files")}
          name="pdf_files"
          accept="application/pdf"
          type="file"
          ref={inputRefPdfFiles}
          error={errors.pdf_files}
          hidden
          onChange={handleSelectPdfFiles}
          multiple
        >
          <Button onClick={handleInputRefPdfFiles}>Escolher arquivos</Button>
        </Input>
      </Stack>

      <Table mt="4">
        <Thead>
          <Tr>
            <Th>Arquivos</Th>
          </Tr>
        </Thead>
        <Tbody>
          {selectedPdfFiles?.map(file => {
            return (
              <Tr key={file}>
                <Th>{file}</Th>
              </Tr>
            )
          })}
        </Tbody>
      </Table>

      <Flex mt="4" justify="flex-end">
        <SubmitButton
          action="Enviar"
          isSubmitting={isSubmitting}
        />
      </Flex>
    </StandardBackgroundForm >
  );
}

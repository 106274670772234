import { Flex, Input, Text } from "@chakra-ui/react";
import { formatDistance } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAuth } from "hooks/auth/useAuth";
import { FocusEvent } from "react";
import { ValidateBugActionButton } from "../ValidateBugActionButton";


interface ViewBugModalContentHeaderProps {
  subject: string
  onUpdateBugSubject: (event: FocusEvent<HTMLInputElement>, status: 'open' | 'validated') => Promise<void>
  status: 'open' | 'validated'
  bugLastUpdatedDate: string
  bugLastUpdatedById: string
  bugLastUpdatedByName: string
  bugValidationDate: string
  bugCreatedByName: string
  onValidateBug: () => Promise<void>
  onRemoveBugValidation: () => Promise<void>
}

export function ViewBugModalContentHeader({
  subject,
  onUpdateBugSubject,
  status,
  bugLastUpdatedDate,
  bugLastUpdatedById,
  bugLastUpdatedByName,
  bugValidationDate,
  onValidateBug,
  onRemoveBugValidation,
  bugCreatedByName
}: ViewBugModalContentHeaderProps) {
  const { userLogged } = useAuth()

  return (
    <Flex
      gap="1"
      direction="column"
    >
      <Flex>
        <Text fontSize="x-small" fontWeight='bold' mr='1'>Criado por: </Text>
        <Text
          fontSize="x-small"
        >{bugCreatedByName}
        </Text>
      </Flex>
      <Flex
        gap="2"
        align="center"
      >
        {status === 'open' ? (
          <ValidateBugActionButton.Root
            aria-label="Validar Bug"
            aria-checked={false}
            onClick={onValidateBug}
          />
        ) : (
          <ValidateBugActionButton.Root
            aria-label="Remove Validação de Bug"
            aria-checked={true}
            onClick={onRemoveBugValidation}
          />
        )}
        <Input
          rounded="sm"
          variant="outline"
          bgColor="white"
          border="none"
          size="sm"
          fontSize="xs"
          defaultValue={subject}
          onBlur={(event) => onUpdateBugSubject(event, status)}
          isReadOnly={!userLogged?.permissions.includes('update-bug')}
        />
      </Flex>

      {bugLastUpdatedById && (
        status === 'validated' ? (
          <Text
            fontSize="x-small"
          >
            Concluído {formatDistance(new Date(bugValidationDate), new Date(), { addSuffix: true, locale: ptBR })} por {userLogged?.id === bugLastUpdatedById ? 'Você' : bugLastUpdatedByName}
          </Text>
        ) : (
          <Text
            fontSize="x-small"
          >
            Última atualização {formatDistance(new Date(bugLastUpdatedDate), new Date(), { addSuffix: true, locale: ptBR })} por {userLogged?.id === bugLastUpdatedById ? 'Você' : bugLastUpdatedByName}
          </Text>
        )
      )}
    </Flex>
  )
}

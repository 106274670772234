import axios from "axios";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";

interface UpdateFinancialClassificationRequestProps {
  type_id: string
  type: string
}

async function createFinancialClassificationReqFunction(type: string) {
  try {
    await api.post('/financial-classifications', { type })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function updateFinancialClassificationReqFunction({
  type_id,
  type
}: UpdateFinancialClassificationRequestProps) {
  try {
    await api.put(`/financial-classifications/${type_id}`, { type })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function deleteFinancialClassificationReqFunction(type_id: string) {
  try {
    await api.delete(`/financial-classifications/${type_id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

export function useFinancialClassificationFunctions() {

  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("financialClassifications")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createFinancialClassification = useMutation('createFinancialClassification', createFinancialClassificationReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const updateFinancialClassification = useMutation('updateFinancialClassification', updateFinancialClassificationReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const deleteFinancialClassification = useMutation('deleteFinancialClassification', deleteFinancialClassificationReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return { createFinancialClassification, updateFinancialClassification, deleteFinancialClassification }
}

import { StandardBackgroundForm } from "../../../../components/Form/StandardBackgroundForm"
import { useServiceByQueryParams } from "../../../../hooks/services/useServiceByQueryParams"
import * as yup from "yup"
import { Flex, Spinner } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { useCustomer } from "../../../../hooks/customer/useCustomer";
import { ConsultServicesForm } from "../components/ConsultServicesForm";
import { useEffect } from "react";
import { ConsultedExpressServicesTable } from "../components/ConsultedServicesTable/Express";
import { useAuth } from "../../../../hooks/auth/useAuth";
import { useParams } from "react-router-dom";
import { EmptyContentTableAlert } from "../../../../components/Alerts/EmptyContentTableAlert";
import { useAddresses } from "../../../../hooks/address/useAddresses";
import { useCity } from "hooks/city/useCity";

interface IFormProps {
  consult_type: string;
  customer_id?: string;
  protocol?: number;
  start_filter?: Date;
  end_filter?: Date;
  sourceCity?: string;
  destinationCity?: string;
}

const consultOptions = [
  { key: "customer", user_types: ["customer"], value: "CLIENTE" },
  { key: "protocol", user_types: ["customer", "collector"], value: "PROTOCOLO" },
  { key: "customer_with_date", user_types: ["customer"], value: "CLIENTE (DATA)" },
  { key: "cities_with_date", user_types: ["customer", "collector"], value: "CIDADE (DATA)" },
  { key: "date", user_types: ["customer", "collector"], value: "DATA" },
]

const scheme = yup.object().shape({
  consult_type: yup.string().required("Campo obrigatório!"),
  customer_id: yup.string().when("consult_type", {
    is: (value: string) => value === "CLIENTE" || value === "CLIENTE (DATA)",
    then: yup.string().required("Campo obrigatório!")
  }),
  protocol: yup.number().when("consult_type", {
    is: "PROTOCOLO",
    then: yup.number().typeError("Campo obrigatório!")
  }),
  start_filter: yup.date().when("consult_type", {
    is: (value: string) => value === "DATA" || value === "CLIENTE (DATA)" || value === "CIDADE (DATA)",
    then: yup.date().typeError("Campo obrigatório!")
  }),
  end_filter: yup.date().when("consult_type", {
    is: (value: string) => value === "DATA" || value === "CLIENTE (DATA)" || value === "CIDADE (DATA)",
    then: yup.date().typeError("Campo obrigatório!")
  }),
  sourceCity: yup.string().when("consult_type", {
    is: "CIDADE (DATA)",
    then: yup.string()
  }),
  destinationCity: yup.string().when("consult_type", {
    is: "CIDADE (DATA)",
    then: yup.string()
  })
}).test("source-or-destination", "Pelo menos uma das cidades (origem ou destino) deve ser preenchida.", function (value) {
  const { consult_type, sourceCity, destinationCity } = value;
  if (consult_type === "CIDADE (DATA)" && !sourceCity && !destinationCity) {
    return this.createError({ path: "sourceCity", message: "Pelo menos uma das cidades (origem ou destino) deve ser preenchida." });
  }
  return true;
})

interface IParams {
  slug: string
}

export function ConsultExpressService() {
  const {
    consultServices: { mutateAsync: consultServices, isLoading: isConsultServicesLoading, data: services, isSuccess: isConsultServicesSuccess }
  } = useServiceByQueryParams()
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  const { userLogged } = useAuth()
  const { slug } = useParams<IParams>()

  const formProps = useForm<IFormProps>({
    resolver: yupResolver(scheme),
    defaultValues: {
      customer_id: userLogged?.customer_id ?? undefined
    }
  })

  const { watch, handleSubmit, setValue } = formProps

  const consultType = watch("consult_type")

  const isConsultByProtocol = consultType === "PROTOCOLO"
  const isConsultByCustomer = consultType === "CLIENTE"
  const isConsultByCustomerWithDate = consultType === "CLIENTE (DATA)"
  const isConsultByDate = consultType === "DATA"
  const isConsultByCitiesWithDate = consultType === "CIDADE (DATA)"

  const {
    customers: { data: customers, isLoading: isCustomersLoading }
  } = useCustomer(null, isConsultByCustomer || isConsultByCustomerWithDate)

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, isConsultByCitiesWithDate)

  async function handleConsultServices(values: IFormProps) {
    const queryParams = {
      customer_id: values.customer_id || userLogged?.customer_id,
      protocol: values.protocol,
      startFilter: values.start_filter,
      endFilter: values.end_filter,
      collector_id: userLogged?.collector_id,
      ...(values.sourceCity && { sourceCity: values.sourceCity }),
      ...(values.destinationCity && { destinationCity: values.destinationCity })
    }

    await consultServices(queryParams)
  }

  const isUserTypeCollector = userLogged?.user_type === "COLETADOR"
  const isUserTypeCustomer = userLogged?.user_type === "CLIENTE"

  const consultOptionsByUserType = isUserTypeCollector
    ? consultOptions.filter(option => option.user_types.includes("collector"))
    : isUserTypeCustomer
      ? consultOptions.filter(option => option.user_types.includes("customer"))
      : consultOptions

  useEffect(() => {
    if (isUserTypeCustomer) {
      setValue("customer_id", userLogged?.customer_id)
    }
  }, [userLogged, isUserTypeCustomer, setValue])

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(handleConsultServices)} title={slug === "editar" ? "Editar serviços express" : "Consultar serviços express"}>
      <Flex flex="1" direction="column" gap="4">
        <FormProvider {...formProps}>
          <ConsultServicesForm
            consultOptions={consultOptionsByUserType}
            isCustomersLoading={isCustomersLoading}
            customers={customers}
            isCitiesLoading={isCitiesLoading}
            cities={cities}
            isConsultByProtocol={isConsultByProtocol}
            isConsultByCustomer={isConsultByCustomer}
            isConsultByCustomerWithDate={isConsultByCustomerWithDate}
            isUserTypeCustomer={isUserTypeCustomer}
            isConsultByDate={isConsultByDate}
            isConsultByCitiesWithDate={isConsultByCitiesWithDate}
          />
        </FormProvider>
        {isConsultServicesLoading || isAddressesLoading ? (
          <Spinner />
        ) : services?.length ? (
          <ConsultedExpressServicesTable
            services={services}
            addresses={addresses!}
            slug={slug}
          />
        ) : isConsultServicesSuccess ? (
          <EmptyContentTableAlert
            title="Oops"
            description="Não foram econtrados serviços com as definições de busca. Tente reajustá-las e realize uma nova consulta!"
          />
        ) : (
          <></>
        )}
      </Flex>
    </StandardBackgroundForm>
  )
}

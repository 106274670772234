import { Flex, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { NestedValue, useForm } from 'react-hook-form'
import { PermissionProps } from '../../../contexts/PermissionContext'
import { switchUserTypes } from '../../../utils/CustomLists/switchUserTypes'
import { ProfileProps } from '../../../utils/RequestFunctions/Profile/requestProfileFunctions'
import { schema } from '../../../validations/profileSchema'
import { FormActionButton } from '../../Buttons/FormActionButton'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { CheckboxUser } from '../../Inputs/CheckboxUser'
import { Select } from '../../Inputs/SelectInput'
import { StandardBackgroundForm } from '../StandardBackgroundForm'

interface IFormInputProps {
  user_type: string
  permissions: NestedValue<string[]>
}

interface IEditDetailProfileFormProps {
  submit: (values: IFormInputProps) => Promise<void>
  permissions: PermissionProps[]
  profile: ProfileProps
  slug: string
  id: string
}

export function EditDetailProfileForm({
  submit,
  permissions,
  profile,
  slug,
  id,
}: IEditDetailProfileFormProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  const defaultOptions = useMemo(() => {
    if (profile) {
      return profile.permissions
    }
  }, [profile])

  useEffect(() => {
    if (profile) {
      setValue('user_type', profile.user_type)
    }
  }, [setValue, profile])

  return (
    <StandardBackgroundForm
      title="Criar Perfil"
      onSubmit={handleSubmit(submit)}
    >
      <Select
        {...register('user_type')}
        name="user_type"
        error={errors.user_type}
        label="Tipo"
        user_types={switchUserTypes.handle()}
        placeholder="Selecione uma opção..."
        isDisabled={slug === 'visualizar'}
        required
      />

      <Stack mt="4">
        <CheckboxUser
          {...register('permissions')}
          name="permissions"
          error={errors.permissions}
          label="Permissões"
          permissions={permissions}
          defaultOption={defaultOptions}
          isDisabled={slug === 'visualizar'}
          required
        />
      </Stack>

      <Flex mt="4" justifyContent="flex-end" alignItems="center">
        <Stack direction="row">
          {slug === 'visualizar' ? (
            <FormActionButton href={`/perfil/editar/${id}`} action="Editar" />
          ) : (
            <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          )}
          <ListButton name="Perfis" href="/perfis" />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface HireInternCltBody {
  documentationChecklist: string[]
}

interface HireInternCltProps {
  body: HireInternCltBody
  internCltId: string
}

export async function hireInternClt({
  body,
  internCltId
}: HireInternCltProps) {
  try {
    const response = await api.put(`/intern-clts/${internCltId}/hire`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

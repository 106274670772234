import { Button, Flex, FormLabel, Icon, Image, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "components/Inputs/Input";
import { useAuth } from "hooks/auth/useAuth";
import { useMaintenanceServiceFunctions } from "hooks/services/maintenance/useMaintenanceServiceFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FieldError, useForm, useWatch } from "react-hook-form";
import { FiFile } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { supportedFileFormats, validateFileSize, validateFileType, validateHasFile } from "utils/imageValidation";
import * as yup from 'yup'

interface FinishMaintenanceFormInputs {
  maintenance_allocated_refrigeration_quantity: number
  maintenance_departure_temperature: number
  maintenance_departure_photo: File[]
}

interface FinishMaintenanceFormProps {
  maintenanceId: string
}

const finishMaintenanceFormSchema = yup.object().shape({
  maintenance_allocated_refrigeration_quantity: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  maintenance_departure_temperature:  yup.string().matches(/^-?\d+(\.\d+)?$/, 'Apenas números (positivos ou negativos) com ponto (.) são permitidos').required('Campo obrigatório'),
  maintenance_departure_photo: yup.mixed()
    .test("length", "Campo obrigatório", value => validateHasFile(value))
    .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
    .test("fileSize", "É suportado o upload de imagem somente até 10Mb", value => validateFileSize(value)),

})

export function FinishMaintenanceForm({
  maintenanceId
}: FinishMaintenanceFormProps) {

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<FinishMaintenanceFormInputs>({
    resolver: yupResolver(finishMaintenanceFormSchema)
  })

  const { promiseMessage } = useToastify()

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('finish-maintenance-service')) {
      redirectTo('/')
    }
  }, [redirectTo, userLogged])

  const {
    finishMaintenance: { mutateAsync: finishMaintenance }
  } = useMaintenanceServiceFunctions()

  const maintenanceDeparturePhoto = useWatch({
    control,
    name: 'maintenance_departure_photo'
  })

  const maintenanceDeparturePhotoPreview = maintenanceDeparturePhoto
    ? Object.entries(maintenanceDeparturePhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  async function handleFinishMaintenance({
    maintenance_allocated_refrigeration_quantity,
    maintenance_departure_temperature,
    maintenance_departure_photo,
  }: FinishMaintenanceFormInputs) {
    const formData = new FormData()
    formData.append(
      'maintenance_departure_photo', maintenance_departure_photo[0]
    )
    formData.append(
      'maintenance_departure_temperature',
      String(maintenance_departure_temperature)
    )
    formData.append(
      'maintenance_allocated_refrigeration_quantity',
      String(maintenance_allocated_refrigeration_quantity)
    )

    await promiseMessage(finishMaintenance({
      input: formData,
      maintenanceId
    }), 'Manutenção finalizada com sucesso')

    redirectTo('/servicos/manutencoes')
  }

  return (
    <Flex
      w="full"
      as="form"
      gap={4}
      mt={4}
      direction="column"
      onSubmit={handleSubmit(handleFinishMaintenance)}
      noValidate
    >
      <Stack
        direction={['column', 'column', 'row']}
        spacing={4}
        w="full"
      >
        <Input
          {...register('maintenance_allocated_refrigeration_quantity')}
          name='maintenance_allocated_refrigeration_quantity'
          label='Quantidade de refrigeração alocada'
          type='number'
          step="1"
          error={errors.maintenance_allocated_refrigeration_quantity}
          required
        />
        <Stack
          w='full'
          direction={['column', 'column', 'row']}
          spacing={4}
        >
          <Input
            {...register('maintenance_departure_temperature')}
            name='maintenance_departure_temperature'
            label='Temperatura de saída'
            error={errors.maintenance_departure_temperature}
            required
          />
        </Stack>
      </Stack>

      <Stack
        direction={['column', 'column', 'row']}
        spacing={4}
      >
        <Input
          {...register("maintenance_departure_photo")}
          name="maintenance_departure_photo"
          type="file"
          accept={supportedFileFormats.join(", ")}
          hidden
          label="Foto após manutenção"
          error={errors.maintenance_departure_photo as FieldError}
          required
          multiple={false}
        >
          <Button
            leftIcon={<Icon as={FiFile} />}
            as={FormLabel}
            htmlFor="maintenance_departure_photo"
            _hover={{
              cursor: "pointer"
            }}
          >Escolha uma imagem</Button>
        </Input>
        {maintenanceDeparturePhotoPreview && (
          <Image
            objectFit="cover"
            maxHeight="300px"
            maxWidth="300px"
            w="full"
            h="full"
            src={maintenanceDeparturePhotoPreview}
          />
        )}
      </Stack>

      <Flex alignSelf='flex-end'>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Finalizar manutenção
        </Button>
      </Flex>

    </Flex>
  )
}

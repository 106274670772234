import { Flex, FlexProps, forwardRef } from "@chakra-ui/react"
import { ReactNode } from "react"

interface RootProps extends FlexProps {
  children: ReactNode
}

const Root = forwardRef<RootProps, 'div'>(({ children, ...rest }, ref) => {
  return (
    <Flex align="center" {...rest}>
      {children}
    </Flex>
  )
})

export { Root }


import { MaterialRequest } from './dtos/MaterialRequest';
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";

interface MaterialRequestsResponse {
  materialRequests: MaterialRequest[]
  totalPages: number
}

interface MaterialRequestsQueryParams {
  status: string
  currentPage?: number
  pageSize?: number
}

type QueryOptions = UseQueryOptions<MaterialRequestsResponse, unknown, MaterialRequestsResponse, QueryKey>

interface UseMaterialRequestsOptions {
  queryKey?: string
  queryParams?: MaterialRequestsQueryParams
  queryOptions?: QueryOptions
}

export function useMaterialRequests(options?: UseMaterialRequestsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getReceivedPmcs() {

    try {
      const { data } = await api.get('/material-requests', {
        params: {
          ...options?.queryParams
        }
      })

      return data

    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey || 'materialRequests',
    { ...options?.queryParams }
  ], getReceivedPmcs, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

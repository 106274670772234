import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Box, Button, Divider, Flex, Heading, HStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'


import { Select } from '../../components/Inputs/SelectInput'
import { Input } from '../../components/Inputs/Input'
import { permissions_list } from '../../utils/customLists'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { usePermission } from '../../hooks/permission/usePermission'

interface FormInputProps {
  group: string
  order: number
  key: string
  value: string
}

interface HubFormProps {
  title: string
  action: string
  slug?: string
  id?: string
  isDisabled?: boolean
  href: string
  submit: (values: FormInputProps) => Promise<void>
}

const hubSchema = yup.object().shape({
  group: yup.string().required('Grupo inválido'),
  order: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .required('Ordem inválida')
    .nullable(),
  key: yup.string().required('Permissão inválida'),
  value: yup.string().required('Código inválido'),
})

export function PermissionForm({
  title,
  slug,
  isDisabled = false,
  id,
  action,
  href,
  submit,
}: HubFormProps) {
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const {
    permissions: { data: permissions, isLoading: isPermissionLoading },
  } = usePermission(null, true, false)
  const {
    permission: { data: permission, isLoading: isPermissionByIdLoading },
  } = usePermission(slug !== 'adicionar' ? id || null : null, false, false)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(hubSchema),
  })

  const groupSelected = watch('group')

  useEffect(() => {
    function run() {
      if (!isPermissionLoading && !isPermissionByIdLoading) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isPermissionLoading, isPermissionByIdLoading])

  useEffect(() => {
    async function run() {
      if (slug === 'adicionar' && !!permissions) {
        const defaultOrderValue = permissions.filter(
          (permission) => permission.group === groupSelected,
        )
        setValue('order', defaultOrderValue.length + 1)
      }

      if (slug === 'editar' || slug === 'visualizar') {
        if (permission) {
          setValue('group', permission.group)
          setValue('order', permission.order)
          setValue('key', permission.key)
          setValue('value', permission.value)
        }
      }
    }

    run()
  }, [setValue, slug, permission, groupSelected, permissions])

  if (isLoadingPage) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <HStack spacing="24px">
            {slug !== 'adicionar' ? (
              <Select
                {...register('group')}
                isDisabled={true}
                name="group"
                label="Grupo"
                placeholder="Selecione uma opção..."
                permissions_list={permissions_list}
                error={errors.group}
                required
              />
            ) : (
              <Select
                {...register('group')}
                isDisabled={isDisabled}
                name="group"
                label="Grupo"
                placeholder="Selecione uma opção..."
                permissions_list={permissions_list}
                error={errors.group}
                required
              />
            )}

            <Input
              {...register('order')}
              name="order"
              label="Ordem"
              type="number"
              error={errors.order}
              isDisabled={true}
              required
            />
          </HStack>

          <HStack spacing="24px" pt="4">
            <Input
              {...register('key')}
              isDisabled={isDisabled}
              name="key"
              label="Permissão"
              error={errors.key}
              required
            />

            <Input
              {...register('value')}
              name="value"
              label="Código"
              type="value"
              error={errors.value}
              isDisabled={isDisabled}
              required
            />
          </HStack>
          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/permissoes">
                <Button type="button" colorScheme="gray">
                  Lista de Permissões
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}

import * as yup from "yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToastify } from "hooks/toastify/useToastify";
import { ExtrasDiscountsForm, ExtrasDiscountsFormInputs } from "components/Form/ExtrasDiscountsForm";
import { useHistory, useParams } from "react-router-dom";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { Button, Flex, Spinner } from "@chakra-ui/react";
import { Select } from "components/Inputs/SelectInput";
import { useExtraDiscount } from "hooks/extrasDiscounts/useExtraDiscount";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useAggregates } from "hooks/aggregate/useAggregates";
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { useAuth } from "hooks/auth/useAuth";

interface UpdateAggregateExtraDiscountInputs extends Omit<ExtrasDiscountsFormInputs, 'extraDiscountAttachments'> {
  aggregateId: string
  attachmentsIds: string[]
}

const schema = yup.object().shape({
  aggregateId: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  serviceDate: yup.string().required('Campo obrigatório'),
  value: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  serviceType: yup.string().required('Campo obrigatório'),
  customerId: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  serviceRoute: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  servicesProtocols: yup.mixed().when('serviceType', {
    is: 'EXPRESS',
    then: yup.array()
      .of(
        yup.object().shape({
          protocol: yup.number()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório')
            .positive('O protocolo deve ser um número positivo')
            .integer('O protocolo deve ser um número inteiro'),
        })
      )
      .min(1, 'Você deve adicionar pelo menos um serviço'),
  })
})

interface Params {
  extraDiscountId: string
}

export function UpdateAggregateExtraDiscount() {

  const { extraDiscountId } = useParams<Params>()
  const { push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const [attachmentsIds, setAttachmentsIds] = useState<string[]>([])

  const userHasPermissionToUpdateAggregateExtraDiscount =
    userLogged?.permissions.includes('update-aggregate-extra-discount')

  useEffect(() => {
    if (!userHasPermissionToUpdateAggregateExtraDiscount) {
      redirect('/')
    }
  }, [redirect, userHasPermissionToUpdateAggregateExtraDiscount])


  const formMethods = useForm({
    resolver: yupResolver(schema),
  })

  const { setValue, register, handleSubmit, formState: { errors } } = formMethods

  const {
    data: aggregates,
    isFetching: isFetchingAggregates
  } = useAggregates()

  const aggregatesSelectOptions = aggregates?.map((aggregate) => {
    return {
      key: aggregate.id,
      value: aggregate.id,
      showOption: `${aggregate.driver.firstname} ${aggregate.driver.lastname}`
    }
  })

  const {
    data: extraDiscountData,
    isFetching: isFetchingExtraDiscountData,
  } = useExtraDiscount({
    extraDiscountId,
    queryOptions: { enabled: !!extraDiscountId }
  })

  useEffect(() => {
    if (extraDiscountData) {
      const { extraDiscount } = extraDiscountData

      setValue('aggregateId', extraDiscount.aggregate.id)
      setValue('type', extraDiscount.type)
      setValue('serviceDate', extraDiscount.service_date)
      setValue('value', extraDiscount.value / 100)
      setValue('description', extraDiscount.description)
      setValue('serviceType', extraDiscount.services ? 'EXPRESS' : 'BUSINESS')
      setValue('serviceRoute', extraDiscount.service_route)

      if (extraDiscount?.services.length) {
        const services = extraDiscount?.services?.map((service) => {
          return { protocol: service.service.protocol }
        })
        setValue('servicesProtocols', services)
      }
    }
  }, [setValue, extraDiscountData])

  const extraDiscountAttachments = extraDiscountData?.extraDiscount.attachments

  const { updateCollectorExtraDiscount } = useAggregateFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  async function handleUpdateAggregateExtraDiscount(
    input: UpdateAggregateExtraDiscountInputs
  ) {
    await promiseMessage(updateCollectorExtraDiscount.mutateAsync({
      aggregateId: input.aggregateId,
      extraDiscountId,
      input: {
        ...input,
        value: transformStringToNumber(input.value),
        attachmentsIds: attachmentsIds,
        servicesProtocols: input.serviceType === 'EXPRESS' ? input.servicesProtocols.map(serviceProtocol => serviceProtocol.protocol) : null
      }
    }, {
      onSuccess: () => {
        redirect('/extras-descontos/agregados')
        queryClient.invalidateQueries('extraDiscount')
      }
    }), 'Extra/Desconto de agregado atualizado com sucesso!')
  }

  const handleSetAttachmentsIds = (attachmentsIds: string[]) => setAttachmentsIds(attachmentsIds)

  return (
    <StandardBackgroundForm
      title='Editar Extra/Desconto de Agregado'
      onSubmit={handleSubmit(handleUpdateAggregateExtraDiscount)}
    >
      {(isFetchingAggregates || isFetchingExtraDiscountData) ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ExtrasDiscountsForm
            onSetAttachmentsIds={handleSetAttachmentsIds}
            extraDiscountAttachments={extraDiscountAttachments}
          >
            <Select
              {...register('aggregateId')}
              name='aggregateId'
              options={aggregatesSelectOptions}
              label='Agregado'
              placeholder='Selecione uma opção...'
              error={errors.aggregateId}
              required
            />
          </ExtrasDiscountsForm>
        </FormProvider>
      )}

      <Flex
        mt={4}
        gap={4}
        w='full'
        align='center'
        justifyContent='flex-end'
      >
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

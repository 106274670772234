import { format, toDate } from 'date-fns'

interface ICustomerContacts {
  name: string
  email: string
  phone: string | null
  description: string | null
  type: string[]
  canReceiveAutomaticallyMessages: string
}

interface DataProps {
  type: string
  situation: string
  trading_firstname: string
  company_lastname: string
  attendance_responsible: string
  cnpj_cpf: string
  cost_center: string
  deadline_delivery: string
  operational_email: string
  financial_email: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  state: string
  city: string
  municipal_register: string
  state_register: string
  icms: string
  iss: string
  payment_conditional: string
  day_expiration_1: string
  day_expiration_2: string
  payment_type: string
  emission_type: string
  billing_type: string
  custom_billing_day: number
  lifebox_payment_term: string
  has_waiting_time: string
  observation: string
  contacts: ICustomerContacts[]
  billing_observation: string
  can_receive_service_extras_email: 'yes' | 'no'
  open_address_inclusion_communication: 'yes' | 'no'
  anual_adjustment_date: string
  has_maintenance: 'yes' | 'no'
  has_temp_check: 'yes' | 'no'
}

export function customerFormatDateToBack(data: DataProps) {
  const hour = data.deadline_delivery.split(':')
  const dateNow = toDate(Date.now()).setHours(
    Number(hour[0]),
    Number(hour[1]),
    0,
  )
  const formatedData = format(toDate(dateNow), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

  const customerData = {
    type: data.type.toUpperCase(),
    situation: data.situation.toUpperCase(),
    trading_firstname: data.trading_firstname.toUpperCase(),
    attendance_responsible: data.attendance_responsible,
    company_lastname: data.company_lastname.toUpperCase(),
    cnpj_cpf: data.cnpj_cpf.toUpperCase(),
    cost_center: data.cost_center.toUpperCase(),
    deadline_delivery: formatedData.toUpperCase(),
    operational_email: data.operational_email.toUpperCase(),
    financial_email: data.financial_email.toUpperCase(),
    cellphone: data.cellphone.toUpperCase(),
    telephone: data.telephone.toUpperCase(),
    cep: data.cep.toUpperCase(),
    street: data.street.toUpperCase(),
    number: data.number.toUpperCase(),
    complement: data.complement.toUpperCase(),
    neighborhood: data.neighborhood.toUpperCase(),
    state: data.state.toUpperCase(),
    city: data.city.toUpperCase(),
    municipal_register: data.municipal_register
      ? data.municipal_register.toUpperCase()
      : null,
    state_register: data.state_register
      ? data.state_register.toUpperCase()
      : null,
    icms: data.icms.toUpperCase(),
    iss: data.iss.toUpperCase(),
    payment_conditional: data.payment_conditional.toUpperCase(),
    day_expiration_1: data.day_expiration_1
      ? data.day_expiration_1.toUpperCase()
      : null,
    day_expiration_2: data.day_expiration_2
      ? data.day_expiration_2.toUpperCase()
      : null,
    payment_type: data.payment_type.toUpperCase(),
    emission_type: data.emission_type.toUpperCase(),
    billing_type: data.billing_type.toUpperCase(),
    custom_billing_day: data.custom_billing_day,
    lifebox_payment_term: data.lifebox_payment_term.toUpperCase(),
    has_waiting_time: data.has_waiting_time === 'SIM',
    observation: data.observation.toUpperCase(),
    contacts: data.contacts.map((contact) => {
      return {
        ...contact,
        canReceiveAutomaticallyMessages: contact.canReceiveAutomaticallyMessages === 'yes'
      }
    }),
    billing_observation: data.billing_observation,
    can_receive_service_extras_email: data.can_receive_service_extras_email,
    open_address_inclusion_communication: data.open_address_inclusion_communication,
    anual_adjustment_date: data.anual_adjustment_date,
    has_maintenance: data.has_maintenance,
    has_temp_check: data.has_temp_check === 'yes'
  }

  return customerData
}

import axios from "axios";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";
import { ICostCenters, IFinancialClassifications, IMaterials } from "./dtos/GeneralProvider";


interface CreateGeneralProviderInput {
  situation: string
  company_name: string
  trading_name: string
  state_registration: number
  cnpj: string
  email: string
  payment_conditional: string
  payment_type: string
  cellphone: string
  telephone: string
  cep: string
  state: string
  city: string
  street: string
  number: number
  neighborhood: string
  complement: string
  type: string
  materials?: IMaterials[]
  financial_classifications: IFinancialClassifications[]
  cost_centers: ICostCenters[]
  observation: string
  cpf: string
}

interface UpdateGeneralProviderRequestProps {
  id: string
  input: CreateGeneralProviderInput
}

async function createGeneralProviderReqFunction(input: CreateGeneralProviderInput) {
  try {
    await api.post('/general-providers', input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function updateGeneralProviderReqFunction({
  id, input
}: UpdateGeneralProviderRequestProps) {
  try {
    await api.put(`/general-providers/${id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function deleteGeneralProviderReqFunction(id: string) {
  try {
    await api.delete(`/general-providers/${id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

export function useGeneralProviderFunctions() {

  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("generalProviders")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createGeneralProvider = useMutation('createGeneralProvider', createGeneralProviderReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const updateGeneralProvider = useMutation('updateGeneralProvider', updateGeneralProviderReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const deleteGeneralProvider = useMutation('deleteGeneralProvider', deleteGeneralProviderReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return { createGeneralProvider, updateGeneralProvider, deleteGeneralProvider }
}

import { Flex, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useCity } from '../../../hooks/city/useCity'
import { useContentLoading } from '../../../hooks/loading/useContentLoading'
import { ICreateHolidayDTO } from '../../../pages/Holiday/IHolidayFormDTO'
import { negative_positive } from '../../../utils/customLists'
import { months } from '../../../utils/CustomLists/months'
import { numbersList } from '../../../utils/CustomLists/numbersList'
import { holidaySchema } from '../../../validations/holidaySchema'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { Input } from '../../Inputs/Input'
import { Select } from '../../Inputs/SelectInput'
import { GeneralContentLoading } from '../../Loading/GeneralContentLoading'
import { StandardBackgroundForm } from '../StandardBackgroundForm'

interface ICreateHolidayFormProps {
  submit: (values: ICreateHolidayDTO) => Promise<void>
}

export function CreateHolidayForm({ submit }: ICreateHolidayFormProps) {
  const { handleSetIsContentLoadingFalse, isContentLoading } =
    useContentLoading()
  const {
    cities: { data: cities, isLoading: isCityLoading },
  } = useCity(null, true)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ICreateHolidayDTO>({
    resolver: yupResolver(holidaySchema),
  })

  const nationalFieldValue = watch('national')

  useEffect(() => {
    if (!isCityLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [handleSetIsContentLoadingFalse, isCityLoading])

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(submit)}
      title="Criar Feriado"
    >
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Input
          {...register('name')}
          name="name"
          label="Nome"
          error={errors.name}
          required
        />
        <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
          <Select
            {...register('day')}
            name="day"
            label="Dia"
            placeholder="Selecione uma opção..."
            numbersList={numbersList}
            error={errors.day}
            required
          />
          <Select
            {...register('month')}
            name="month"
            label="Mês"
            placeholder="Selecione uma opção..."
            monthsOfYear={months}
            error={errors.month}
            required
          />
        </Stack>
      </Stack>
      <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
        <Select
          {...register('national')}
          name="national"
          negative_positive={negative_positive}
          label="É nacional?"
          placeholder="Selecione uma opção..."
          error={errors.national}
          required
        />
        {nationalFieldValue === 'NÃO' && (
          <Select
            {...register('city_id')}
            name="city_id"
            placeholder="Selecione uma opção..."
            citiesBack={cities}
            error={errors.city_id}
            label="Cidade"
            required
          />
        )}
      </Stack>
      <Flex mt="8" justify="flex-end">
        <Stack direction="row">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          <ListButton href="/feriados" name="Feriados" />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}

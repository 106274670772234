import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface AcceptPurchaseOrderReqFunctionProps {
  purchaseOrderId: string
}

export async function acceptPurchaseOrder({
  purchaseOrderId
}: AcceptPurchaseOrderReqFunctionProps) {
  try {

    await api.patch(`/purchase-orders/${purchaseOrderId}/accept`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


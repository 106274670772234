import { differenceInMinutes, format, set } from 'date-fns'

export function serviceSortByHour(dateA: string, dateB: string) {
  const dateAFormatedWithGmt = format(
    new Date(dateA),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )
  const dateBFormatedWithGmt = format(
    new Date(dateB),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const [, dateAFormated] = dateAFormatedWithGmt.split('T')
  const [hourA, minuteA] = dateAFormated.toString().split(':')

  const [, dateBFormated] = dateBFormatedWithGmt.split('T')
  const [hourB, minuteB] = dateBFormated.toString().split(':')

  const difference = differenceInMinutes(
    set(new Date(), {
      hours: Number(hourA),
      minutes: Number(minuteA),
      seconds: 0,
    }),
    set(new Date(), {
      hours: Number(hourB),
      minutes: Number(minuteB),
      seconds: 0,
    }),
  )

  if (difference < 0) {
    return -1
  }

  if (difference > 0) {
    return 1
  }

  return 0
}

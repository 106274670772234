import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getAsos } from "api/asos/getAsos";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { AsosKanbanColumn } from "./components/AsosKanbanColumn";
import { CreateAso } from "./components/CreateAso";

export function AsosKanban() {
  const { push: redirect } = useHistory()

  const { userLogged } = useAuth()

  const userHasViewAsosPermission = userLogged?.permissions.includes('view-asos')

  const userHasCreateAsoPermission = userLogged?.permissions.includes('create-aso')

  useEffect(() => {
    if (!userHasViewAsosPermission) {
      redirect('/')
    }
  }, [userHasViewAsosPermission, redirect])

  const {
    isOpen: isCreateAsoModalOpen,
    onOpen: onOpenCreateAsoModal,
    onClose: onCloseCreateAsoModal
  } = useDisclosure()

  const {
    data: pendingAsosResult,
    isLoading: isPendingAsosResultLoading,
    hasNextPage: hasNextPagePendingAsos,
    fetchNextPage: fetchNextPagePendingAsos,
    isFetchingNextPage: isFetchingNextPagePendingAsos,
  } = useInfiniteQuery({
    queryKey: ['pending-asos'],
    queryFn: ({ pageParam = 1 }) => getAsos({
      currentPage: pageParam,
      pageSize: '10',
      status: 'pending'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: activeAsosResult,
    isLoading: isActiveAsosResultLoading,
    hasNextPage: hasNextPageActiveAsos,
    fetchNextPage: fetchNextPageActiveAsos,
    isFetchingNextPage: isFetchingNextPageActiveAsos,
  } = useInfiniteQuery({
    queryKey: ['active-asos'],
    queryFn: ({ pageParam = 1 }) => getAsos({
      currentPage: pageParam,
      pageSize: '10',
      status: 'active'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: inactiveAsosResult,
    isLoading: isInactiveAsosResultLoading,
    hasNextPage: hasNextPageInactiveAsos,
    fetchNextPage: fetchNextPageInactiveAsos,
    isFetchingNextPage: isFetchingNextPageInactiveAsos,
  } = useInfiniteQuery({
    queryKey: ['inactive-asos'],
    queryFn: ({ pageParam = 1 }) => getAsos({
      currentPage: pageParam,
      pageSize: '10',
      status: 'inactive'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsAsos = [
    {
      title: 'Pendentes',
      asos: pendingAsosResult?.pages.flatMap((page) => page.asos) || [],
      asosCount: pendingAsosResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePendingAsos,
      fetchNextPage: fetchNextPagePendingAsos,
      isFetchingNextPage: isFetchingNextPagePendingAsos,
      isLoading: isPendingAsosResultLoading,
    },
    {
      title: 'Ativos',
      asos: activeAsosResult?.pages.flatMap((page) => page.asos) || [],
      asosCount: activeAsosResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageActiveAsos,
      fetchNextPage: fetchNextPageActiveAsos,
      isFetchingNextPage: isFetchingNextPageActiveAsos,
      isLoading: isActiveAsosResultLoading,
    },
    {
      title: 'Inativos',
      asos: inactiveAsosResult?.pages.flatMap((page) => page.asos) || [],
      asosCount: inactiveAsosResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageInactiveAsos,
      fetchNextPage: fetchNextPageInactiveAsos,
      isFetchingNextPage: isFetchingNextPageInactiveAsos,
      isLoading: isInactiveAsosResultLoading,
    },
  ]

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">

        <Heading letterSpacing="tight">Asos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/asos')}
          />
          {userHasCreateAsoPermission && (
            <IconButton
              aria-label="Criar Aso"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateAsoModal}
            />
          )}
        </Flex>

        <Modal
          isOpen={isCreateAsoModalOpen}
          onClose={onCloseCreateAsoModal}
          size="2xl"
          isCentered
        >
          <ModalOverlay />
          <CreateAso onCloseModal={onCloseCreateAsoModal} />
        </Modal>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsAsos?.map((aso, index) => {
          return (
            <AsosKanbanColumn
              key={index}
              aso={aso}
            />
          )
        })}
      </Flex>
    </Box>
  )
}

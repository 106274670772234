import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useHistory, useParams } from "react-router-dom";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { ShippingCostForm, ShippingCostFormInputs } from "./components/ShippingCostForm";
import { useShipping } from "hooks/shipping/useShipping";
import { transformStringToNumber } from '../../utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'
import { Flex, Button, Spinner } from "@chakra-ui/react";
import { useShippingCost } from "hooks/shippingCost/useShippingCost";
import { useEffect } from "react";
import { useShippingCostFunctions } from "hooks/shippingCost/useShippingCostFunctions";

interface Params {
  slug: string
  shippingCostId: string
}

const editShippingCostFormSchema = yup.object().shape({
  shippingId: yup.string().required('Campo obrigatório'),
  sourceBranchId: yup.string().required('Campo obrigatório'),
  destinationBranchId: yup.string().required('Campo obrigatório'),
  weights: yup.array().of(yup.object({
    kg: yup.number().required('Campo obrigatório'),
    priceInCents: yup.number()
      .typeError('Campo obrigatório')
      .required('Campo obrigatório')
      .transform((price, originalPrice) => Math.ceil(transformStringToNumber(originalPrice) * 100)),
  })).min(1, 'Campo obrigatório'),
  extraKgPriceInCents: yup
    .number()
    .typeError('Campo obrigatório')
    .required('Campo obrigatório')
    .transform((price, originalPrice) => Math.ceil(transformStringToNumber(originalPrice) * 100)),
})

export function EditViewShippingCosts() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const { promiseMessage } = useToastify();
  const { slug, shippingCostId } = useParams<Params>()

  const isViewPage = slug === "visualizar"
  const formTitle = isViewPage
    ? "Visualizar Custo de Transportadora"
    : "Editar Custo de Transportadora"

  const userHasPermissionToEditShippingCost =
    userLogged?.permissions.includes("edit-shipping-cost");

  useEffect(() => {
    if (!userHasPermissionToEditShippingCost && slug !== 'visualizar') {
      redirect('/')
    }
  }, [redirect, slug, userHasPermissionToEditShippingCost])

  const formMethods = useForm<ShippingCostFormInputs>({
    resolver: yupResolver(editShippingCostFormSchema)
  })

  const {
    handleSubmit,
    setValue,
    formState: {
      isSubmitting
    }
  } = formMethods

  const {
    shipping: { data: shipping, isFetching: isFetchingShipping },
  } = useShipping(null, true, false)

  const { data: shippingCostData, isFetching: isFetchingShippingCostData } = useShippingCost({
    shippingCostId,
    queryOptions: { enabled: !!shippingCostId }
  })

  const { updateShippingCost: { mutateAsync: updateShippingCost } } = useShippingCostFunctions()

  useEffect(() => {
    if (shippingCostData?.shippingCost !== undefined) {

      const weightsFormated = shippingCostData?.shippingCost.weights.map((weight) => {
        return Object.assign({}, weight, { priceInCents: String(weight.price_in_cents / 100) });
      }).sort((a, b) => a.kg - b.kg)

      setValue('shippingId', shippingCostData?.shippingCost.shipping_id)
      setValue('sourceBranchId', shippingCostData?.shippingCost.source_branch_id)
      setValue('destinationBranchId', shippingCostData?.shippingCost.destination_branch_id)
      setValue("extraKgPriceInCents", String(shippingCostData?.shippingCost.extra_kg_price_in_cents / 100))
      setValue("weights", weightsFormated)

    }
  }, [setValue, shippingCostData])

  async function handleEditShippingCost(values: ShippingCostFormInputs) {
    await promiseMessage(updateShippingCost({ ...values, shippingCostId }, {
      onSuccess: () => {
        redirect("/transportadoras/custos")
      }
    }), "Custo de transportadora atualizado com sucesso!")
  }
  return (
    <StandardBackgroundForm title={formTitle} onSubmit={handleSubmit(handleEditShippingCost)}>
      {(isFetchingShippingCostData || isFetchingShipping) ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ShippingCostForm shippings={shipping} slug={slug} />
        </FormProvider>
      )}

      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to={`/transportadoras/custos`}
        >
          Custos de Transportadoras
        </Button>

        {isViewPage ? (
          <Button
            as={Link}
            to={`/transportadoras/custos/${shippingCostId}/editar`}
            type="button"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingShippingCostData}
          >
            Editar
          </Button>
        ) : (
          <Button
            type="submit"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingShippingCostData || isSubmitting}
          >
            Salvar
          </Button>
        )}
      </Flex>
    </StandardBackgroundForm>
  )
}

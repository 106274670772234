import { Button, Flex, Select } from "@chakra-ui/react";
import { CarrierStatus } from "api/carriers/_types/Carrier";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { carrierStatusMap } from "utils/carrierMappers";

interface CarriersReportFiltersSchema {
  status: CarrierStatus | 'all'
}

interface CarriersReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: any[],
    filename: string
  }
}

export function CarriersReportTableFilters({
  csvProps
}: CarriersReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const {
    register,
    handleSubmit,
  } = useForm<CarriersReportFiltersSchema>({
    defaultValues: {
      status: 'all'
    }
  })

  function handleSearchCarriersReport(values: CarriersReportFiltersSchema) {
    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    replace({ search: searchParams.toString() })

  }

  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchCarriersReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Select
          {...register("status")}
          defaultValue="all"
          size="sm"
          rounded="md"
          w={{ base: 'full', lg: '300px' }}
        >
          {Object.entries(carrierStatusMap).map(([statusKey, statusValue]) => {
            return <option key={statusKey} value={statusKey}>{statusValue}</option>
          })}
          <option value="all">Todos status</option>
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
      </Flex>
    </Flex>
  )
}

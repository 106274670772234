import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Document, DocumentStatus } from "./types/Document";

export interface GetDocumentsParams {
  pageSize: string
  currentPage: string
  status: DocumentStatus | 'all'
  name?: string
}

export interface GetDocumentsResponse {
  documents: Document[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}


export async function getDocuments({
  currentPage,
  pageSize,
  status,
  name
}: GetDocumentsParams) {
  try {
    const response = await api.get<GetDocumentsResponse>('/documents', {
      params: {
        pageSize,
        currentPage,
        status,
        name
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

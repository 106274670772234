import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Advertence } from "./types/Advertence";

interface GetAdvertencesReportProps {
  queryParams: {
    startDate: string
    endDate: string
    status: string
  }
}

export interface GetAdvertencesReportResponse {
  advertences: Array<Advertence & {
    advertenceAttachment: Attachment | null
    proofOfSendingAdvertenceAttachment: Attachment | null
  }
  >
}

export async function getAdvertencesReport({
  queryParams
}: GetAdvertencesReportProps) {
  try {
    const { data } = await api.get<GetAdvertencesReportResponse>('/advertences/report', {
      params: { ...queryParams }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import { Box, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { handleSwitchMask } from "utils/handleChangeMask";
import { CreateSupportSchema } from "../CreateSupport";


export function NewClientCreditAnalysisForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      cnpj: 'CNPJ do cliente',
      clientCorporateName: 'Razão social do cliente'
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors?.formData?.cnpj} mt={3}>
        <FormLabel fontSize="sm">
          CNPJ do cliente
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.cnpj.value')}
          name="formData.cnpj.value"
          as={ReactInputMask as any}
          mask={handleSwitchMask('cnpj')}
          size="sm"
          rounded="md"
          onChangeCapture={handleInputChange('cnpj')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.clientCorporateName} mt={3}>
        <FormLabel fontSize='sm'>
          Razão social do cliente
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.clientCorporateName.value')}
          name='formData.clientCorporateName.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('clientCorporateName')}
        />
      </FormControl>
    </Box>
  )

}

import { useEffect } from "react"
import { Flex, Button } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { InactivateAggregateForm, InactivateAggregateFormInputs} from './components/InactivateAggregateForm'
import { validateHasFile } from "utils/imageValidation";
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
  id: string
}

const inactivateAggregateForm = yup.object().shape({
  legalControlRisk: yup.mixed().required('Campo obrigatório'),
  patrimoniesRefund: yup.mixed().required('Campo obrigatório'),
  bhInactivation: yup.mixed().required('Campo obrigatório'),
  hasOpenProvisionAdjustComunication: yup.mixed().required('Campo obrigatório'),
  contractDismissalAttachment: yup.mixed()
    .test('validateHasFile', 'Campo obrigatório', (value: FileList) => validateHasFile(value))
})

export function InactivateAggregatePage(){
  const { id } = useParams<ParamsProps>()
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const { push: redirect } = useHistory()

  const userHasPermissionToActivateAggregate = userLogged.permissions.includes('inactivate-aggregate')

  useEffect(() => {
    if (!userHasPermissionToActivateAggregate) {
      redirect('/')
    }
  }, [userHasPermissionToActivateAggregate, redirect])

  const formMethods = useForm<InactivateAggregateFormInputs>({
    resolver: yupResolver(inactivateAggregateForm)
  })
  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const {inactivateAggregate: {mutateAsync: inactivateAggregate}} = useAggregateFunctions()


  async function handleInactivateAggregate({ ...values }: InactivateAggregateFormInputs) {
    const formData = new FormData()

    formData.append('legalControlRisk', values.legalControlRisk)
    formData.append('patrimoniesRefund', values.patrimoniesRefund)
    formData.append('bhInactivation', values.bhInactivation)
    formData.append('hasOpenProvisionAdjustComunication', values.hasOpenProvisionAdjustComunication)
    formData.append('contractDismissalAttachment', values.contractDismissalAttachment[0])

    await promiseMessage(inactivateAggregate({aggregateId: id, input: formData}, {
      onSuccess: async () => {
        redirect("/agregados")
      }
    }), 'Agregado inativado com sucesso!')
  }
  return (
    <StandardBackgroundForm title="Inativar Agregado" onSubmit={handleSubmit(handleInactivateAggregate)}>
      <FormProvider {...formMethods}>
        <InactivateAggregateForm />
      </FormProvider>
      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

import axios from "axios"
import { Product } from "hooks/product/dtos/Product"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetProductsWithOrUnderMinimumResponse {
  products: Product[]
}

export async function getProductsWithOrUnderMinStock() {
  try {
    const { data } = await api.get<GetProductsWithOrUnderMinimumResponse>('/products/stocks/minimun')

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import { Box, Circle, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Sporadic } from "api/sporadics/_types/Sporadic";
import { format, formatDistanceToNowStrict, isFuture } from "date-fns";
import { FaCalendarAlt, FaCheck, FaExclamationCircle } from "react-icons/fa";
import { ptBR } from "date-fns/locale";
import { SporadicDetail } from "./SporadicDetail";
import { Regional } from "api/regionals/_types/Regional";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { verifySporadic } from "api/sporadics/verifySporadic";
import { unverifySporadic } from "api/sporadics/unverifySporadic";
import { useAuth } from "hooks/auth/useAuth";

interface KanbanSporadicCardProps {
  sporadic: Sporadic & {
    regional: Regional
  }
}

export function KanbanSporadicCard({ sporadic }: KanbanSporadicCardProps) {
  const { userLogged } = useAuth()
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const userCanVerifySporadic = userLogged?.permissions.includes('verify-sporadic')

  const { mutateAsync: verifySporadicFn } = useMutation({
    mutationFn: verifySporadic,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'in-billing' })
      queryClient.invalidateQueries({ queryKey: 'billed' })
      queryClient.invalidateQueries({ queryKey: 'canceled' })
      queryClient.invalidateQueries({ queryKey: 'sporadics' })

    },
  })

  const { mutateAsync: unverifySporadicFn } = useMutation({
    mutationFn: unverifySporadic,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'in-billing' })
      queryClient.invalidateQueries({ queryKey: 'canceled' })
      queryClient.invalidateQueries({ queryKey: 'billed' })
      queryClient.invalidateQueries({ queryKey: 'sporadics' })
    },
  })


  const {
    isOpen: isSporadicDetailModalOpen,
    onToggle: onToggleSporadicDetailModal,
  } = useDisclosure()

  const sporadicFinalDate = new Date(sporadic?.finalDate);
  const formattedSporadicDate = format(sporadicFinalDate, "dd MMM", { locale: ptBR });

  const relativeDate = formatDistanceToNowStrict(sporadicFinalDate, { locale: ptBR });
  const isDateInFuture = isFuture(sporadicFinalDate);
  const relativeDateText = isDateInFuture ? `em ${relativeDate}` : `${relativeDate} atrás`;

  async function handleVerifyToggle(event: React.MouseEvent) {
    event.stopPropagation();

    if (sporadic?.isVerified) {
      await promiseMessage(unverifySporadicFn({ sporadicId: sporadic?.id }),
        'Verificação removida!')

    } else {
      await promiseMessage(verifySporadicFn({ sporadicId: sporadic?.id }), 'Esporádico verificado!')

    }

  }


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleSporadicDetailModal}
      cursor='pointer'
    >

      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" fontSize="lg">
          {sporadic.protocol}
        </Text>
        <Circle
          size="7"
          bg={sporadic?.isVerified ? "green.400" : "gray.300"}
          color="white"
          onClick={userCanVerifySporadic ? handleVerifyToggle : (e) => e.stopPropagation()}
        >
          {sporadic?.isVerified && < Icon as={FaCheck} boxSize={4} />}
        </Circle>
      </HStack>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA INICIO REALIZAÇÃO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(sporadic?.startDate), "dd/MM/yyyy '-' HH:mm")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA FIM REALIZAÇÃO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(sporadic?.finalDate), "dd/MM/yyyy '-' HH:mm")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">REGIONAL</Text>
          </HStack>
          <Text ml={5}>{sporadic?.regional?.name}</Text>
        </Box>

        {sporadic?.status === 'in-billing' && (
          <HStack spacing={2}>
            {isDateInFuture ? (
              <>
                <Icon as={FaCalendarAlt} />
                <Text fontWeight="bold">{`Venc ${formattedSporadicDate}`}</Text>
              </>
            ) : (
              <>
                <Icon as={FaExclamationCircle} color="red.500" />
                <Text color="red.500" fontWeight="bold">{`Venc ${formattedSporadicDate}`}</Text>
              </>
            )}
            <Text>{relativeDateText}</Text>
          </HStack>

        )}

      </VStack>

      <Modal
        isOpen={isSporadicDetailModalOpen}
        onClose={onToggleSporadicDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <SporadicDetail
          sporadicId={sporadic.id}
          onClose={onToggleSporadicDetailModal}
        />
      </Modal>
    </Box>
  )
}

import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Text,
  Icon,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { ServiceProps } from '../../../contexts/ServiceContext'
import { DeliveryServiceProps } from '../../../contexts/DeliveryServiceContext'


import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { Input } from '../../../components/Inputs/Input'

import { useService } from '../../../hooks/services/service'
import { useAuth } from '../../../hooks/auth/useAuth'
import { FaFilePdf } from 'react-icons/fa'
import { GenerateLabelModal } from '../../../components/Modal/Service/Label/GenerateLabelModal'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { Address } from 'hooks/address/dtos/Address'

interface IQueryParams {
  service_id: string
  address_id: string
}

interface IDeliveryServiceAddressesProps {
  slug: string
  isDisabled?: boolean
}

export function DeliveryBusinessServiceAddresses({
  slug,
  isDisabled = false,
}: IDeliveryServiceAddressesProps) {
  const [isLoading, setIsLoading] = useState(true)
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const [sourceModalAddresses, setSourceModalAddresses] = useState<
    Address[]
  >([])
  const [destinationModalAddresses, setDestinationModalAddresses] = useState<
    Address[]
  >([])

  const [destinationAddresses, setDestinationAddresses] = useState<
    Address[]
  >([])
  const [stepsOfDeliveryService, setStepsOfDeliveryService] = useState<
    string[]
  >([])

  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])
  const [deliveryServiceFilteredById, setDeliveryServiceFilteredById] =
    useState<DeliveryServiceProps[]>([])

  const { userLogged } = useAuth()

  const { service_id }: IQueryParams = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  useEffect(() => {
    if (serviceById && addresses) {
      const sourceAddressesToModal = addresses.filter((address) =>
        serviceById.serviceIDRequestedBusiness.source_address_id.includes(
          address.id,
        ),
      )
      const destinationAddressesToModal = addresses.filter((address) =>
        serviceById.serviceIDRequestedBusiness.destination_address_id.includes(
          address.id,
        ),
      )

      setSourceModalAddresses(sourceAddressesToModal)
      setDestinationModalAddresses(destinationAddressesToModal)
    }
  }, [serviceById, addresses])

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)

        if (serviceById.serviceIDDelivery) {
          setDeliveryServiceFilteredById(serviceById.serviceIDDelivery)
        }
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (!isAddressesLoading && !isServiceLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isAddressesLoading, isServiceLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-delivery-business-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  useEffect(() => {
    async function run() {
      if (!deliveryServiceFilteredById.length) {
        setStepsOfDeliveryService([])
        return
      }
      const stepsOfDeliveryService = deliveryServiceFilteredById.map(
        (delService) => delService.step,
      )
      setStepsOfDeliveryService(stepsOfDeliveryService)
    }

    run()
  }, [deliveryServiceFilteredById])

  useEffect(() => {
    function run() {
      if (addresses) {
        const destinationAddressOfService = serviceFilteredById.flatMap(
          (service) =>
            service.serviceIDRequestedBusiness.destination_address_id,
        )
        if (stepsOfDeliveryService.length === 0) {
          const destinationAddress = addresses.filter((address) =>
            destinationAddressOfService.includes(address.id),
          )
          setDestinationAddresses(destinationAddress)
        } else {
          const deliveryServiceAddresses = deliveryServiceFilteredById.map(
            (delService) => delService.address_id,
          )
          const servicesWithoutAddress = addresses
            .filter((address) =>
              destinationAddressOfService.includes(address.id),
            )
            .filter((address) => !deliveryServiceAddresses.includes(address.id))
          const addressesFilteredByStep = deliveryServiceFilteredById
            .filter(
              (delService) =>
                delService.step !== 'DONE' && delService.step !== 'UNSUCCESS',
            )
            .map((delService) => delService.address_id)

          const destinationAddress = addresses.filter((address) =>
            addressesFilteredByStep.includes(address.id),
          )
          setDestinationAddresses([
            ...servicesWithoutAddress,
            ...destinationAddress,
          ])
        }
      }
    }

    run()
  }, [
    serviceFilteredById,
    addresses,
    deliveryServiceFilteredById,
    stepsOfDeliveryService,
  ])

  useEffect(() => {
    async function run() {
      const serviceStep = String(
        serviceFilteredById.map((service) => service.step),
      )
      const lengthDestinationAddress = serviceFilteredById.flatMap(
        (service) => service.serviceIDRequestedBusiness.destination_address_id,
      ).length
      const stepsOfDeliveryService = deliveryServiceFilteredById.map(
        delivery => delivery.step
      )

      if (serviceStep !== 'toDeliveryBusinessService') {
        if (lengthDestinationAddress !== 0) {
          if (deliveryServiceFilteredById.length === lengthDestinationAddress) {
            if (!stepsOfDeliveryService.includes('GOING')) {
              if (stepsOfDeliveryService.includes('DONE')) {
                setIsLoading(true)
                history.push('/servicos-business/entregas')
              }
            }
          }
        }
      }
    }
    run()
  }, [serviceFilteredById, deliveryServiceFilteredById, history])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <>
      <GenerateLabelModal
        isModalOpen={isOpen}
        onClose={onClose}
        sourceAddresses={sourceModalAddresses}
        destinationAddresses={destinationModalAddresses}
        serviceProtocol={serviceById?.protocol!}
      />

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          noValidate
        >
          <Flex
            align="center"
            justify="space-between"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Endereços para Entrega
            </Heading>
          </Flex>

          <Divider my="6" borderColor="gray.700" />

          {destinationAddresses.length === 0 ? (
            <Text fontSize="2xl">NÃO HÁ ENDEREÇOS PARA ENTREGA</Text>
          ) : (
            <>
              {destinationAddresses.map((address) => (
                <Stack
                  key={address.id}
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'column']}
                >
                  <Input
                    defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name} | ${address.complement}`.toUpperCase()}
                    isDisabled={isDisabled}
                    name="firstname"
                    label="Endereço Destinatário"
                  />

                  <Link
                    to={`/servico-business/entrega/${service_id}/endereco/${address.id}`}
                  >
                    <Button type="button" colorScheme="blue" w="100%">
                      Entregar
                    </Button>
                  </Link>
                  <Divider my="6" borderColor="gray.700" />
                </Stack>
              ))}
            </>
          )}

          <Flex mt="8" justify="flex-end">
            <Flex w="full" justifySelf="flex-start">
              <Button onClick={onOpen}>
                {isWideVersion ? 'Download Etiqueta' : <Icon as={FaFilePdf} />}
              </Button>
            </Flex>
            <HStack>
              <Link to="/servicos-business/entregas">
                <Button type="button" colorScheme="gray">
                  Serviços Business à Entregar
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

import { IHubsProps } from '../../../utils/RequestFunctions/Hubs/requestHubFunctions'
import { api } from '../../api'

const getAllHubs = async () => {
  try {
    const { data } = await api.get<IHubsProps[]>('/hub')

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}


const getOnehub = async (hubId: string | null) => {
  try {
    const { data } = await api.get<IHubsProps>(`/hub/${hubId}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }

}

export { getAllHubs, getOnehub }

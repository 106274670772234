import { Box, Button, Flex, FormControl, FormLabel, IconButton, Modal, ModalOverlay, Radio, RadioGroup, Text, useDisclosure, } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup";
import { approveExecutiveLeaderActiveFeedback } from "api/feedbacks/approveExecutiveLeaderActiveFeedback";
import { useToastify } from "hooks/toastify/useToastify";
import { useSearchParams } from "hooks/useSearchParams";
import { CreateSupport } from "pages/Support/components/CreateSupport";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import * as yup from "yup"


interface ExecutiveLeaderApprovalFormProps {
  activeFeedbackId: string
}

interface ExecutiveLeaderApprovalFormSchema {
  doesExecutiveLeaderKeepCollaborator: string
  doesExecutiveLeaderApproveCollaboratorPromotion: string

}

const executiveLeaderApprovalFormSchema = yup.object({
  doesExecutiveLeaderKeepCollaborator: yup.string().required(),
  doesExecutiveLeaderApproveCollaboratorPromotion: yup.string().required(),

})

export function ExecutiveLeaderApprovalForm({ activeFeedbackId }: ExecutiveLeaderApprovalFormProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const {
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ExecutiveLeaderApprovalFormSchema>({
    resolver: yupResolver(executiveLeaderApprovalFormSchema)
  })

  const doesExecutiveLeaderApproveCollaboratorPromotion = useWatch({
    control,
    name: 'doesExecutiveLeaderApproveCollaboratorPromotion'
  })

  const isOpenSupport = doesExecutiveLeaderApproveCollaboratorPromotion === 'yes'

  const supportRequested = searchParams.get('supportRequested')
  const hasSupportRequested = supportRequested === 'true'

  const {
    isOpen: isCreateSupportOpen,
    onOpen: onOpenCreateSupport,
    onClose: onCloseCreateSupport
  } = useDisclosure()

  const { mutateAsync: approveExecutiveLeaderActiveFeedbackFn } = useMutation({
    mutationFn: approveExecutiveLeaderActiveFeedback,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'scheduled-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'direct-leader-approving-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'executive-leader-approving-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'awaiting-leader-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'done-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: ['active-feedback', activeFeedbackId] })

      searchParams.delete('supportRequested')
      replace({ search: searchParams.toString() })
    }
  })

  async function handleExecutiveLeaderApproval(values: ExecutiveLeaderApprovalFormSchema) {
    await promiseMessage(approveExecutiveLeaderActiveFeedbackFn({
      body: {
        ...values,
        doesExecutiveLeaderApproveCollaboratorPromotion: values.doesExecutiveLeaderApproveCollaboratorPromotion === 'yes',
        doesExecutiveLeaderKeepCollaborator: values.doesExecutiveLeaderKeepCollaborator === 'yes'
      },
      activeFeedbackId
    }), 'Feedback aprovado!')
  }
  return (
    <>
      <Box
        w="full"
        as="form"
        onSubmit={handleSubmit(handleExecutiveLeaderApproval)}
        maxH='500px'
        overflowY='scroll'
      >
        <FormControl isInvalid={!!errors.doesExecutiveLeaderKeepCollaborator} mt={3}>
          <FormLabel fontSize='sm'>
            Manter colaborador (a)?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="doesExecutiveLeaderKeepCollaborator"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Radio size='sm' mr={2} rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </RadioGroup>
            )} />
        </FormControl>
        <FormControl isInvalid={!!errors.doesExecutiveLeaderApproveCollaboratorPromotion} mt={3}>
          <FormLabel fontSize='sm'>
            Subir de nível?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="doesExecutiveLeaderApproveCollaboratorPromotion"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Radio size='sm' mr={2} rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </RadioGroup>
            )} />
        </FormControl>
        {isOpenSupport && (
          <Flex mt={3} alignItems="baseline" justifyContent="space-between">
            <FormLabel fontSize='sm'>
              Abrir atendimento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <IconButton
              aria-label="Abrir atendimento"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateSupport} />

          </Flex>
        )}

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || (isOpenSupport && !hasSupportRequested)}
          >
            Salvar
          </Button>
        </Flex>
      </Box>
      <Modal
        isOpen={isCreateSupportOpen}
        onClose={onCloseCreateSupport}
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <CreateSupport
          onClose={onCloseCreateSupport}
          required={true} />
      </Modal>
    </>
  )
}

import { Button, ButtonProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ActionButtonProps extends ButtonProps {
  children: ReactNode
}

export function ActionButton({ children, ...rest }: ActionButtonProps) {
  return <Button fontSize="sm" {...rest}>{children}</Button>
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { realizePassiveFeedback } from "api/feedbacks/realizePassiveFeedback";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface RealizeInternCltPassiveFeedbackProps {
  passiveFeedbackId: string
}

interface RealizeInternCltPassiveFeedbackSchema {
  whatDidYouLikedMore: string
  whatDidYouLikedLess: string
  whatYouThinkAboutYourDevelopment: number
  howCouldYouBetter: string
  haveYouBeenTrainedCorrectly: string
  howManyWeeksAreYourLearnCurve: number
  doYouHaveClarityOnKpiAndMatrix: string
  howDoYouFeel: string
  whatYouThinkAboutTheTeam: string
  whoDidYouFeelMostSupportedBy: string
  leaderRate: number
  whatWouldYouImproveAboutLeadership: string
  didYouKnowWhereConsultProcesses: string
  didYouHasQuestionsAboutRh: string
  doesYouKnowTheFullPointFlow: string
  didYouRequestedRhAnswerYourQuestions: string
  doesYourQuestionsAnswered: string
  haveQuestionsAboutYourTimeBank: string
  didYouKnowWhereOpenSupport: string
  haveYouQuestionsAboutBuildingMaintenance: string
  doesYouFollowsYammerPublications: string
  doesYouKnowAboutGeneralMeet: string
  observations: string
  doesYouKnowHowOpenSystemBug: string
  doesYouThereInSystemImprovementsPlanner: string
}

const realizeInternCltPassiveFeedbackSchema = yup.object({
  whatDidYouLikedMore: yup.string().required(),
  whatDidYouLikedLess: yup.string().required(),
  whatYouThinkAboutYourDevelopment: yup.number().required(),
  howCouldYouBetter: yup.string().required(),
  haveYouBeenTrainedCorrectly: yup.string().required(),
  howManyWeeksAreYourLearnCurve: yup.number().required(),
  doYouHaveClarityOnKpiAndMatrix: yup.string().required(),
  howDoYouFeel: yup.string().required(),
  whatYouThinkAboutTheTeam: yup.string().required(),
  whoDidYouFeelMostSupportedBy: yup.string().required(),
  leaderRate: yup.number().required(),
  whatWouldYouImproveAboutLeadership: yup.string().required(),
  didYouKnowWhereConsultProcesses: yup.string().required(),
  didYouHasQuestionsAboutRh: yup.string().required(),
  doesYouKnowTheFullPointFlow: yup.string().required(),
  didYouRequestedRhAnswerYourQuestions: yup.string().required(),
  doesYourQuestionsAnswered: yup.string().required(),
  haveQuestionsAboutYourTimeBank: yup.string().required(),
  didYouKnowWhereOpenSupport: yup.string().required(),
  haveYouQuestionsAboutBuildingMaintenance: yup.string().required(),
  doesYouFollowsYammerPublications: yup.string().required(),
  doesYouKnowAboutGeneralMeet: yup.string().required(),
  observations: yup.string().required(),
  doesYouKnowHowOpenSystemBug: yup.string().required(),
  doesYouThereInSystemImprovementsPlanner: yup.string().required(),
})

const whatYouThinkAboutYourDevelopmentOptions = Array.from({ length: 10 }, (_, i) => (i + 1).toString());

const howManyWeeksAreYourLearnCurveOptions = Array.from({ length: 8 }, (_, i) => (i + 1).toString());

const leaderRateOptions = Array.from({ length: 10 }, (_, i) => (i + 1).toString());

const howDoYouFeelOptions = [
  'Sobrecarregado',
  'Subtrabalhado',
  'Carga normal de trabalho'
]

export function RealizeInternCltPassiveFeedback({ passiveFeedbackId }: RealizeInternCltPassiveFeedbackProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<RealizeInternCltPassiveFeedbackSchema>({
    resolver: yupResolver(realizeInternCltPassiveFeedbackSchema)
  })

  const { mutateAsync: realizePassiveFeedbackFn } = useMutation({
    mutationFn: realizePassiveFeedback,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'scheduled-passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'done-passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: ['passive-feedback', passiveFeedbackId] })
    }
  })

  async function handleRealizeInternCltPassiveFeedback(values: RealizeInternCltPassiveFeedbackSchema) {
    await promiseMessage(realizePassiveFeedbackFn({
      body: {
        ...values,
        haveYouBeenTrainedCorrectly: values.haveYouBeenTrainedCorrectly === 'yes',
        doYouHaveClarityOnKpiAndMatrix: values.doYouHaveClarityOnKpiAndMatrix === 'yes',
        didYouKnowWhereConsultProcesses: values.didYouKnowWhereConsultProcesses === 'yes',
        didYouHasQuestionsAboutRh: values.didYouHasQuestionsAboutRh === 'yes',
        didYouRequestedRhAnswerYourQuestions: values.didYouRequestedRhAnswerYourQuestions === 'yes',
        haveQuestionsAboutYourTimeBank: values.haveQuestionsAboutYourTimeBank === 'yes',
        didYouKnowWhereOpenSupport: values.didYouKnowWhereOpenSupport === 'yes',
        haveYouQuestionsAboutBuildingMaintenance: values.haveYouQuestionsAboutBuildingMaintenance === 'yes',
        doesYouFollowsYammerPublications: values.doesYouFollowsYammerPublications === 'yes',
        doesYouKnowAboutGeneralMeet: values.doesYouKnowAboutGeneralMeet === 'yes',
        doesYouKnowHowOpenSystemBug: values.doesYouKnowHowOpenSystemBug === 'yes',
        doesYouThereInSystemImprovementsPlanner: values.doesYouThereInSystemImprovementsPlanner === 'yes'
      },
      passiveFeedbackId
    }), 'Feedback realizado')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleRealizeInternCltPassiveFeedback)}
      maxH='500px'
      overflowY='scroll'
      px={2}
    >
      <Heading letterSpacing="tight" size='sm'>Formulário Inicial</Heading>
      <FormControl isInvalid={!!errors.whatDidYouLikedMore} mt={3}>
        <FormLabel fontSize="sm">
          O que mais gostou até agora?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("whatDidYouLikedMore")}
          name="whatDidYouLikedMore"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.whatDidYouLikedLess} mt={3}>
        <FormLabel fontSize="sm">
          O que menos gostou até agora?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("whatDidYouLikedLess")}
          name="whatDidYouLikedLess"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.whatYouThinkAboutYourDevelopment} mt={3}>
        <FormLabel fontSize="sm">
          Como você acha que está indo o seu desempenho
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('whatYouThinkAboutYourDevelopment')}
          name="whatYouThinkAboutYourDevelopment"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(whatYouThinkAboutYourDevelopmentOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.howCouldYouBetter} mt={3}>
        <FormLabel fontSize="sm">
          Como poderia melhorar?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("howCouldYouBetter")}
          name="howCouldYouBetter"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.haveYouBeenTrainedCorrectly} mt={3}>
        <FormLabel fontSize='sm'>
          Foi devidamente treinado ?

          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="haveYouBeenTrainedCorrectly"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.howManyWeeksAreYourLearnCurve} mt={3}>
        <FormLabel fontSize="sm">
          Quantas semanas foi sua curva de aprendizado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('howManyWeeksAreYourLearnCurve')}
          name="howManyWeeksAreYourLearnCurve"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(howManyWeeksAreYourLearnCurveOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.doYouHaveClarityOnKpiAndMatrix} mt={3}>
        <FormLabel fontSize='sm'>
          Clareza sobre KPI e Matriz?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doYouHaveClarityOnKpiAndMatrix"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.howDoYouFeel} mt={3}>
        <FormLabel fontSize='sm'>
          Você se sente?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="howDoYouFeel"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                {Object.entries(howDoYouFeelOptions).map(([key, value]) => (
                  <Radio
                    key={key}
                    size='sm'
                    rounded='md'
                    value={value}

                  >{value}</Radio>
                ))}

              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.whatYouThinkAboutTheTeam} mt={3}>
        <FormLabel fontSize="sm">
          Sobre o time, o que achou?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("whatYouThinkAboutTheTeam")}
          name="whatYouThinkAboutTheTeam"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.whoDidYouFeelMostSupportedBy} mt={3}>
        <FormLabel fontSize="sm">
          Por quem se sente mais apoiado? 1, 2, 3 lugar
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("whoDidYouFeelMostSupportedBy")}
          name="whoDidYouFeelMostSupportedBy"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.leaderRate} mt={3}>
        <FormLabel fontSize="sm">
          Liderança direta de 0 a 10
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('leaderRate')}
          name="leaderRate"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
        >
          {Object.entries(leaderRateOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}

        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.whatWouldYouImproveAboutLeadership} mt={3}>
        <FormLabel fontSize="sm">
          Sobre a liderança? O que precisa melhorar?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("whatWouldYouImproveAboutLeadership")}
          name="whatWouldYouImproveAboutLeadership"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.didYouKnowWhereConsultProcesses} mt={3}>
        <FormLabel fontSize='sm'>
          Sabe onde consultar todos os processos?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="didYouKnowWhereConsultProcesses"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.didYouHasQuestionsAboutRh} mt={3}>
        <FormLabel fontSize='sm'>
          Você tem dúvidas sobre o RH?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="didYouHasQuestionsAboutRh"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesYouKnowTheFullPointFlow} mt={3}>
        <FormLabel fontSize="sm">
          Sabe como bater o ponto? Lançamento de horas extras? Sabe o fluxo a ser seguido?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("doesYouKnowTheFullPointFlow")}
          name="doesYouKnowTheFullPointFlow"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.didYouRequestedRhAnswerYourQuestions} mt={3}>
        <FormLabel fontSize='sm'>
          Já requisitou o RH para esclarecer suas duvidas acima?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="didYouRequestedRhAnswerYourQuestions"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesYourQuestionsAnswered} mt={3}>
        <FormLabel fontSize="sm">
          Se sim, suas duvidas foram sanadas?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("doesYourQuestionsAnswered")}
          name="doesYourQuestionsAnswered"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.haveQuestionsAboutYourTimeBank} mt={3}>
        <FormLabel fontSize='sm'>
          Tem duvidas sobre o seu banco de horas?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="haveQuestionsAboutYourTimeBank"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.didYouKnowWhereOpenSupport} mt={3}>
        <FormLabel fontSize='sm'>
          Se tiver duvida sobre algum processo, sabe onde abrir atendimento?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="didYouKnowWhereOpenSupport"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.haveYouQuestionsAboutBuildingMaintenance} mt={3}>
        <FormLabel fontSize='sm'>
          Tem dúvidas sobre manutenção predial?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="haveYouQuestionsAboutBuildingMaintenance"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesYouFollowsYammerPublications} mt={3}>
        <FormLabel fontSize='sm'>
          Você acompanha as publicações do Yammer?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesYouFollowsYammerPublications"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesYouKnowAboutGeneralMeet} mt={3}>
        <FormLabel fontSize='sm'>
          Tem ciência da reunião geral?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesYouKnowAboutGeneralMeet"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.observations} mt={3}>
        <FormLabel fontSize="sm">
          Observações
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("observations")}
          name="observations"
          size="sm"
          rounded="md"
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesYouKnowHowOpenSystemBug} mt={3}>
        <FormLabel fontSize='sm'>
          Você sabe como abrir Bug no sistema?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesYouKnowHowOpenSystemBug"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.doesYouThereInSystemImprovementsPlanner} mt={3}>
        <FormLabel fontSize='sm'>
          Você está no Planner de Melhorias do sistema?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesYouThereInSystemImprovementsPlanner"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>


    </Box>

  )
}

import { Stack } from "@chakra-ui/react";
import { SubmitButton } from "../../../../components/Buttons/SubmitButton";
import { Select } from "../../../../components/Inputs/SelectInput";
import { ProviderProps } from "../../../../contexts/ProviderContext";
import { IDriverProps } from "../../../../services/getFunctions/driver/getDrivers";
import { useFormContext } from "react-hook-form"
import { Address } from "hooks/address/dtos/Address";

interface IStartCollectServiceFormProps {
  isProviderForm?: boolean
  addresses?: Address[]
  providers?: ProviderProps[]
  drivers: IDriverProps[]
}

export function StartCollectServiceForm({
  isProviderForm,
  addresses,
  providers,
  drivers,
}: IStartCollectServiceFormProps) {
  const { register, formState: { errors, isSubmitting } } = useFormContext()

  return (
    <Stack direction="column" spacing="24px">
      {isProviderForm ? (
        <Select
          {...register("provider_id")}
          name="provider_id"
          providerAddress={providers}
          label="Endereço Fornecedor"
          required
          isDisabled
        />
      ) : (
        <Select
          {...register("address_id")}
          name="address_id"
          addresses={addresses}
          label="Endereço"
          required
          isDisabled
        />
      )}
      <Select
        {...register("driver_id")}
        name="driver_id"
        drivers={drivers}
        label="Motorista"
        placeholder="Selecione uma opção..."
        error={errors.driver_id}
        required
      />
      <SubmitButton
        action="Iniciar coleta"
        isSubmitting={isSubmitting}
      />
    </Stack>
  )
}

interface DataProps {
  id: string
  situation: string
  source_cities: string[]
  destination_cities: string[]
  sourceHubIDBudget: {
    name: string
  }
  destinationHubIDBudget: {
    name: string
  }
  service_type: string
  vehicle: string
  modal: string
  gelox: number
  gelo_seco: number
  franchising: number
  observation: string
}

interface CityProps {
  id: string
  name: string
}

export function budgetFormatArrayToSelect(
  data: DataProps[] | undefined,
  cities: CityProps[],
) {
  const newBudgetFormatedData = data
    ?.map((data) => {
      return {
        id: data?.id,
        source_cities: cities
          .filter((city) => data.source_cities?.includes(city.id))
          .map((city) => city.name),
        destination_cities: cities
          .filter((city) => data.destination_cities?.includes(city.id))
          .map((city) => city.name),
        sourceHubIDBudget: {
          name: data?.sourceHubIDBudget.name,
        },
        destinationHubIDBudget: {
          name: data?.destinationHubIDBudget.name,
        },
        service_type: data?.service_type,
        vehicle: data?.vehicle,
        franchising: data?.franchising,
        gelox: data?.gelox,
        gelo_seco: data?.gelo_seco,
        modal: data?.modal,
        observation: data?.observation
      }
    })
    .sort(
      (a, b) =>
        a.source_cities.toString().localeCompare(b.source_cities.toString()) ||
        a.destination_cities
          .toString()
          .localeCompare(b.destination_cities.toString()) || a.franchising - b.franchising,
    )

  return newBudgetFormatedData
}

import { Box, Table, Tbody, Td, Tr, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Accordion, Text, } from "@chakra-ui/react"
import { GetPassiveFeedbackResponse } from "api/feedbacks/getPassiveFeedback"
import { format } from "date-fns"
import { PassiveFeedbackStatus } from "./PassiveFeedbackStatus"

interface PassiveFeedbackDetailTableProps {
  passiveFeedback: GetPassiveFeedbackResponse
}

export function PassiveFeedbackDetailTable({
  passiveFeedback
}: PassiveFeedbackDetailTableProps) {
  return (
    <Box
      w='full'
      maxH='500px'
      overflowY='scroll'
    >
      <Table size="sm" >
        <Tbody>
          {passiveFeedback?.passiveFeedback?.cltDriver && (
            <>
              <Tr>
                <Td>Nome Completo</Td>
                <Td isNumeric>{`${passiveFeedback?.passiveFeedback?.cltDriver?.driver?.firstname} ${passiveFeedback?.passiveFeedback?.cltDriver?.driver?.lastname} `}</Td>
              </Tr>
              <Tr>
                <Td>CPF</Td>
                <Td isNumeric>{passiveFeedback?.passiveFeedback?.cltDriver?.driver?.cpf}</Td>
              </Tr>
              <Tr>
                <Td>E-mail do colaborador</Td>
                <Td isNumeric>{passiveFeedback?.passiveFeedback?.cltDriver?.email}</Td>
              </Tr>
              <Tr>
                <Td>RG</Td>
                <Td isNumeric>{passiveFeedback?.passiveFeedback?.cltDriver?.rg}</Td>
              </Tr>
              <Tr>
                <Td>Celular</Td>
                <Td isNumeric>{passiveFeedback?.passiveFeedback?.cltDriver?.cellphone}</Td>
              </Tr>
              {passiveFeedback?.passiveFeedback?.cltDriver?.admission_date && (
                <Tr>
                  <Td>Data de admissão</Td>
                  <Td isNumeric>{format(new Date(passiveFeedback?.passiveFeedback?.cltDriver?.admission_date), "dd/MM/yyyy")}</Td>
                </Tr>
              )}
            </>
          )}
          {passiveFeedback?.passiveFeedback?.internClt && (
            <>
              <Tr>
                <Td>Nome</Td>
                <Td isNumeric>{passiveFeedback?.passiveFeedback?.internClt.name}</Td>
              </Tr>
              <Tr>
                <Td>CPF</Td>
                <Td isNumeric>{passiveFeedback?.passiveFeedback?.internClt.cpf}</Td>
              </Tr>
              <Tr>
                <Td>RG</Td>
                <Td isNumeric>{passiveFeedback?.passiveFeedback?.internClt.rg}</Td>
              </Tr>

              <Tr>
                <Td>Regional</Td>
                <Td isNumeric>{passiveFeedback?.passiveFeedback?.internClt.regional === 'other' ? 'Outra' : passiveFeedback?.passiveFeedback?.internClt.regional.toUpperCase()}</Td>
              </Tr>
              {passiveFeedback?.passiveFeedback?.internClt.admission_date && (
                <Tr>
                  <Td>Data da admissão</Td>
                  <Td isNumeric>{format(new Date(passiveFeedback?.passiveFeedback?.internClt.admission_date), 'dd/MM/yyyy')}</Td>
                </Tr>
              )}
            </>
          )}
          <Tr>
            <Td>Status</Td>
            <Td display="flex" justifyContent="flex-end">
              <PassiveFeedbackStatus status={passiveFeedback?.passiveFeedback?.status} />
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Accordion allowMultiple>
        {passiveFeedback?.passiveFeedback.status === 'done' && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontSize='sm'>
                    Feedback Inicial
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {passiveFeedback?.passiveFeedback?.internClt && (
                <Table size="sm" w="full" >
                  <Tbody>
                    <Tr>
                      <Td>O que mais gostou até agora?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.whatDidYouLikedMore}</Td>
                    </Tr>
                    <Tr>
                      <Td>O que menos gostou até agora?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.whatDidYouLikedLess}</Td>
                    </Tr>
                    <Tr>
                      <Td>Como você acha que está indo o seu desempenho</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.whatYouThinkAboutYourDevelopment}</Td>
                    </Tr>
                    <Tr>
                      <Td>Como poderia melhorar?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.howCouldYouBetter}</Td>
                    </Tr>
                    <Tr>
                      <Td>Foi devidamente treinado?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.haveYouBeenTrainedCorrectly ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Quantas semanas foi sua curva de aprendizado?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.howManyWeeksAreYourLearnCurve} semanas</Td>
                    </Tr>
                    <Tr>
                      <Td>Clareza sobre KPI e Matriz?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doYouHaveClarityOnKpiAndMatrix ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Você se sente?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.howDoYouFeel}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sobre o time, o que achou?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.whatYouThinkAboutTheTeam}</Td>
                    </Tr>
                    <Tr>
                      <Td>Por quem se sente mais apoiado? 1, 2, 3 lugar</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.whoDidYouFeelMostSupportedBy}</Td>
                    </Tr>
                    <Tr>
                      <Td>Liderança direta de 0 a 10</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.leaderRate}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sobre a liderança? O que precisa melhorar?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.whatWouldYouImproveAboutLeadership}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sabe onde consultar todos os processos?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.didYouKnowWhereConsultProcesses ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Você tem dúvidas sobre o RH?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.didYouHasQuestionsAboutRh ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sabe como bater o ponto? Lançamento de horas extras? Sabe o fluxo a ser seguido?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doesYouKnowTheFullPointFlow}</Td>
                    </Tr>
                    <Tr>
                      <Td>Já requisitou o RH para esclarecer suas duvidas acima?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.didYouRequestedRhAnswerYourQuestions ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Se sim, suas duvidas foram sanadas?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doesYourQuestionsAnswered}</Td>
                    </Tr>
                    <Tr>
                      <Td>Tem duvidas sobre o seu banco de horas?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.haveQuestionsAboutYourTimeBank ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Se tiver duvida sobre algum processo, sabe onde abrir atendimento?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.didYouKnowWhereOpenSupport ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Tem dúvidas sobre manutenção predial?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.haveYouQuestionsAboutBuildingMaintenance ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Você acompanha as publicações do Yammer?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doesYouFollowsYammerPublications ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Tem ciência da reunião geral?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doesYouKnowAboutGeneralMeet ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Observações</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.observations}</Td>
                    </Tr>
                    <Tr>
                      <Td>Você sabe como abrir Bug no sistema?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doesYouKnowHowOpenSystemBug ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Você está no Planner de Melhorias do sistema?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doesYouThereInSystemImprovementsPlanner ? 'Sim' : 'Não'}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              )}

              {passiveFeedback?.passiveFeedback?.cltDriver && (
                <Table size="sm" w="full" >
                  <Tbody>
                    <Tr>
                      <Td>Foi devidamente treinado?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.haveYouBeenTrainedCorrectly ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Você tem dúvidas sobre DP? Quais?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.hasDpDoubts}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sabe como bater ponto?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doesYouKnowHowToClockIn ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Tem dúvidas ainda sobre pagamentos e datas?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.hasPaymentsAndDatesDoubts}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sabe onde abrir atendimento para esclarecer suas dúvidas, anexar atestados dentre outros procedimento?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.hasProceduresDoubts}</Td>
                    </Tr>
                    <Tr>
                      <Td>Ficou claro como funciona o controle de padronização e pagamento de aluguel de moto?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.hasStandardizationsAndMotorcycleLocationDoubts}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sabe como acessar o seu holerite?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.doesYouKnowHowAccessPayStub ? 'Sim' : 'Não'}</Td>
                    </Tr>
                    <Tr>
                      <Td>Observações</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.observations}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              )}

            </AccordionPanel>
          </AccordionItem>
        )}
        {passiveFeedback?.passiveFeedback.status === 'done' && passiveFeedback?.passiveFeedback?.type === 'recurrent' && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontSize='sm'>
                    Feedback Recorrente
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table size="sm" w="full" >
                <Tbody>
                  {passiveFeedback?.passiveFeedback?.selfPerformanceLast6Months && (
                    <Tr>
                      <Td>Como você avalia o seu desempenho nos últimos 6 meses?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.selfPerformanceLast6Months}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.goalAchievementFeedback && (
                    <Tr>
                      <Td>Você sente que atingiu suas metas e objetivos?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.goalAchievementFeedback}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.improvementFeedback && (
                    <Tr>
                      <Td>O que precisa melhorar? Há algum projeto ou tarefa que gostaria de ter se saído melhor?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.improvementFeedback}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.challengesFeedback && (
                    <Tr>
                      <Td>Quais foram os maiores desafios que você enfrentou nos últimos 6 meses? O que aprendeu com eles?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.challengesFeedback}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.goalsFeedback && (
                    <Tr>
                      <Td>Quais são as suas metas para o próximo semestre? Como você acha que a empresa pode contribuir?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.goalsFeedback}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.teamEnvironmentRate && (
                    <Tr>
                      <Td>Como você avalia o ambiente de trabalho da sua equipe? (1 a 10)</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.teamEnvironmentRate}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.improvementSugestion && (
                    <Tr>
                      <Td>Qual sugestão para melhoria?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.improvementSugestion}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.marketingFeedback && (
                    <Tr>
                      <Td>O que está achando das ações de endomarketing? Sugere alguma melhoria?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.marketingFeedback}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.leadershipRate && (
                    <Tr>
                      <Td>Nota para a liderança? (1 a 10)</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.leadershipRate}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.supportFeedback && (
                    <Tr>
                      <Td>Você se sente apoiado? O que poderia ser melhorado?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.supportFeedback}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.companyImprovementFeedback && (
                    <Tr>
                      <Td>Existe algo que você gostaria de mudar ou melhorar na empresa?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.companyImprovementFeedback}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.loglifeReferralScore && (
                    <Tr>
                      <Td>Qual a possibilidade de você indicar a Loglife para uma pessoa trabalhar? (0 a 10)</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.loglifeReferralScore}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.workSatisfactionRate && (
                    <Tr>
                      <Td>Avalie sua satisfação em trabalhar na Loglife até hoje. (1 a 10)</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.workSatisfactionRate}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.routeChallengesEncountered && (
                    <Tr>
                      <Td>Você encontrou algum desafio específico nas rotas que faz? Como lidou com isso?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.routeChallengesEncountered}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.roleResponsibilityFeedback && (
                    <Tr>
                      <Td>Como você se sente em relação ao seu papel e às suas responsabilidades na equipe?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.roleResponsibilityFeedback}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.impactOfPartnersAbsencesOnRoutine && (
                    <Tr>
                      <Td>Como você acha que as faltas dos seus parceiros de trabalho impactam na sua rotina?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.impactOfPartnersAbsencesOnRoutine}</Td>
                    </Tr>
                  )}
                  {passiveFeedback?.passiveFeedback?.awareOfStandardizationReason && (
                    <Tr>
                      <Td>Você está ciente do porquê da exigência da padronização?</Td>
                      <Td isNumeric>{passiveFeedback?.passiveFeedback?.awareOfStandardizationReason}</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>

    </Box>
  )
}

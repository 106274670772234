import { Flex, Box, Heading } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'

import { RecurrentServiceTable } from '../../../components/Tables/Service/Recurrent/RecurrentTable'
import { useRecurrentService } from '../../../hooks/recurrentService/useRecurrentService'
import { useAddresses } from '../../../hooks/address/useAddresses'

export function RecurrentServiceList() {
  const [isLoading, setIsLoading] = useState(true)

  const {
    recurrentServices: {
      data: recurrentServices,
      isLoading: isRecurrentServiceLoading,
      refetch,
    },
  } = useRecurrentService(null, true, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  useEffect(() => {
    function run() {
      if (!isAddressesLoading && !isRecurrentServiceLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isAddressesLoading, isRecurrentServiceLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Lista de Serviços Recorrentes
            </Heading>
          </Flex>
          {!!addresses && !!recurrentServices && (
            <RecurrentServiceTable
              addresses={addresses}
              recurrentServices={recurrentServices}
              refetch={refetch}
            />
          )}
        </Box>
      </Flex>

  )
}

import { Box, FormControl, FormLabel, Input, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

export function HubsSuppliesQuantificationForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      description: 'Descrição ',
      expectedShippingDate: 'Data Prevista de Envio'
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };
  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.description} mt={3}>
        <FormLabel fontSize="sm">
          Descrição
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.description.value')}
          name='formData.description.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('description')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.expectedShippingDate} mt={3}>
        <FormLabel fontSize='sm'>
          Data Prevista de Envio
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.expectedShippingDate.value')}
          name='formData.expectedShippingDate.value'
          type="date"
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('expectedShippingDate')}
        />
      </FormControl>
    </Box>
  )
}

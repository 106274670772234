import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import swal from 'sweetalert'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { BranchContext, BranchProps } from '../../../contexts/BranchContext'
import { AuthContext } from '../../../contexts/AuthContext'
import { useBranch } from '../../../hooks/branch/useBranch'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { GenerateExcelReportButton } from '../../Buttons/GenerateExcelReportButton'

interface BranchTableProps {
  branches: BranchProps[]
}

const branchOptions = [
  { key: 'nickname', value: 'Apelido', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'hub', value: 'Hub', checked: false },
  { key: 'modal', value: 'Modal', checked: false },
]

const branchOrderOptions = [
  { key: 'nickname', value: 'Apelido' },
  { key: 'shipping', value: 'Transportadora' },
]

const headers = [
  { label: "APELIDO", key: "nickname" },
  { label: "TRANSPORTADORA", key: "shipping" },
  { label: "EMAIL", key: "email" },
  { label: "HUB", key: "hub" },
  { label: "CELULAR", key: "cellphone" },
  { label: "TELEFONE DO RESPONSÁVEL", key: "telephone" },
  { label: "CEP", key: "cep" },
  { label: "RUA", key: "street" },
  { label: "NÚMERO", key: "number" },
  { label: "COMPLEMENTO", key: "complement" },
  { label: "BAIRRO", key: "neighborhood" },
  { label: "ESTADO", key: "state" },
  { label: "CIDADE", key: "city" },
  { label: "HORÁRIOS SEGUNDA A SEXTA", key: "business_range" },
  { label: "HORÁRIOS SÁBADOS", key: "saturday_range" },
  { label: "HORÁRIOS DOMINGOS", key: "sunday_range" },
]

function formatValuesToReport(branches: BranchProps[]) {
  return branches.map(branch => {
    const businessOpenHour =
      formatDate.handle(branch.business_open, "DateOnlyWithHourMinute")
    const businessCloseHour =
      formatDate.handle(branch.business_close, "DateOnlyWithHourMinute")
    const saturdayOpenHour = branch.saturday_open
      ? formatDate.handle(branch.saturday_open, "DateOnlyWithHourMinute")
      : '-'
    const saturdayCloseHour = branch.saturday_open
      ? formatDate.handle(branch.saturday_close, "DateOnlyWithHourMinute")
      : '-'
    const sundayOpenHour = branch.sunday_open
      ? formatDate.handle(branch.sunday_open, "DateOnlyWithHourMinute")
      : '-'
    const sundayCloseHour = branch.sunday_close
      ? formatDate.handle(branch.sunday_close, "DateOnlyWithHourMinute")
      : '-'

    return {
      ...branch,
      shipping: branch.shippingIDBranch.trading_name,
      hub: branch.hubIDBranch.name,
      business_range: `${businessOpenHour} / ${businessCloseHour}`,
      saturday_range: `${saturdayOpenHour} / ${saturdayCloseHour}`,
      sunday_range: `${sundayOpenHour} / ${sundayCloseHour}`
    }
  })
}

export function BranchTable({ branches }: BranchTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [searchedBranches, setSearchedBranches] = useState<BranchProps[]>([])
  const [slicedBranches, setSlicedBranches] = useState<BranchProps[]>([])

  // SEARCHBOX STATES
  const [nicknameFiltered, setNicknameFiltered] = useState('')
  const [shippingFiltered, setShippingFiltered] = useState('')
  const [hubFiltered, setHubFiltered] = useState('')
  const [modalFiltered, setModalFiltered] = useState('')
  // ---------------------------------------------------------------------------- //

  // CONTEXTS
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const {
    branches: { refetch },
  } = useBranch(null, false, false)
  const { deleteBranch } = useContext(BranchContext)
  const { userLogged } = useContext(AuthContext)
  // ---------------------------------------------------------------------------- //

  // PAGINATION PROPS
  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedBranches?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })
  // ---------------------------------------------------------------------------- //

  const csvReportProps = {
    data: formatValuesToReport(branches),
    headers,
    filename: `relatório-bases`,
  }

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(branchOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(branchOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedBranches(branches)
    }
    run()
  }, [itemLimit, currentPage, offset, branches])

  useEffect(() => {
    function run() {
      const newSlicedBranches = slicedBranches?.filter((branches) => {
        const nickNameFilter = searchBoxFilter(
          branches.nickname,
          nicknameFiltered,
        )
        const shippingFilter = searchBoxFilter(
          branches.shippingIDBranch.company_name,
          shippingFiltered,
        )
        const hubFilter = searchBoxFilter(
          branches.hubIDBranch.name,
          hubFiltered,
        )
        const modalFilter = searchBoxFilter(
          branches.shippingIDBranch.modal,
          modalFiltered,
        )

        if (
          nicknameFiltered === '' &&
          shippingFiltered === '' &&
          hubFiltered === '' &&
          modalFiltered === ''
        ) {
          return branches
        }
        return nickNameFilter && shippingFilter && hubFilter && modalFilter
      })

      if (orderOptionSelected.length > 0) {
        const slicedBranchesByOrder = newSlicedBranches.sort(
          (a, b) =>
            (orderOptionSelected.includes('nickname') &&
              a.nickname.localeCompare(b.nickname)) ||
            (orderOptionSelected.includes('shipping') &&
              a.shippingIDBranch.company_name.localeCompare(
                b.shippingIDBranch.company_name,
              )) ||
            0,
        )

        return setSearchedBranches(slicedBranchesByOrder)
      }

      setSearchedBranches(newSlicedBranches)
    }
    run()
  }, [
    nicknameFiltered,
    shippingFiltered,
    hubFiltered,
    modalFiltered,
    slicedBranches,
    orderOptionSelected,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteCollectorCost(collectorCostId: string) {
    await swal({
      title: 'Deseja deletar uma base de transportadora?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        deleteBranch(collectorCostId).then((res) => {
          if (res) {
            refetch()
          }
        })
      } else {
        swal('Remoção cancelada com êxito!')
      }
    })
  }

  return (
    <>
      {slicedBranches.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'nickname' && (
                      <SearchBox
                        placeholder="Buscar Apelido..."
                        handleSearch={(e) =>
                          setNicknameFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'shipping' && (
                      <SearchBox
                        placeholder="Buscar Transportadora..."
                        handleSearch={(e) =>
                          setShippingFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'hub' && (
                      <SearchBox
                        placeholder="Buscar Hub..."
                        handleSearch={(e) => setHubFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'modal' && (
                      <SearchBox
                        placeholder="Buscar Modal..."
                        handleSearch={(e) => setModalFiltered(e.target.value)}
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {branchOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedBranches
                  .slice(offset, offset + Number(itemLimit))
                  .map((branch, index) => (
                    <Tr key={index}>
                      <Td fontSize="12">{branch.nickname.toUpperCase()}</Td>
                      <Td fontSize="12">
                        {branch.shippingIDBranch.company_name.toUpperCase()}
                      </Td>
                      <Td fontSize="12">
                        {branch.hubIDBranch.name.toUpperCase()}
                      </Td>
                      <Td fontSize="12">
                        {branch.shippingIDBranch.modal.toUpperCase()}
                      </Td>
                      <Td isNumeric>
                        {userLogged !== null && (
                          <>
                            {userLogged.permissions.includes('view-branch') && (
                              <Link
                                to={`/transportadoras/base/visualizar/${branch.id}`}
                              >
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  fontSize="20"
                                />
                              </Link>
                            )}
                            {userLogged.permissions.includes('edit-branch') && (
                              <Link
                                to={`/transportadoras/base/editar/${branch.id}`}
                              >
                                <Icon
                                  cursor="pointer"
                                  as={FiEdit3}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                />
                              </Link>
                            )}
                            {userLogged.permissions.includes(
                              'remove-branch',
                            ) && (
                                <Icon
                                  cursor="pointer"
                                  as={FiTrash2}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                  onClick={() =>
                                    handleDeleteCollectorCost(branch.id)
                                  }
                                />
                              )}
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <GenerateExcelReportButton csvReportProps={csvReportProps} />
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

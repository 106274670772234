import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"


interface GetDriverAvailableSchedulesProps {
  routeParams: {
    driverId: string
  }
  queryParams: {
    date: Date
    timezoneOffset: number
  }
}

interface GetDriverAvailableSchedulesResponse {
  possibleTimes: string[]
  availableTimes: string[]
}

export async function getDriverAvailableSchedules({
  queryParams,
  routeParams
}: GetDriverAvailableSchedulesProps) {
  try {
    const response = await api.get<GetDriverAvailableSchedulesResponse>(`/drivers/${routeParams.driverId}/available-schedulings`, {
      params: { ...queryParams }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Button, Checkbox, CheckboxGroup, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createInternCltOffice } from "api/internClts/createInternCltOffice";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup"

interface CreateInternCltOfficeSchema {
  name: string
  trainings: string[]
}

interface CreateInternCltOfficeModalProps {
  onClose: () => void
}

const createInternCltOfficeSchema = yup.object({
  name: yup.string().required(),
  trainings: yup.array().min(1).required()
})

const trainings = [
  { name: 'LLM - COMPRAS' },
  { name: 'LLM - RH' },
  { name: 'LLM - OPERAÇÃO MATRIZ' },
  { name: 'LLM - COMERCIAL' },
  { name: 'LLM - QUALIDADE' },
  { name: 'LLM - TI' },
  { name: 'LLM - FINANCEIRO' },
  { name: 'LLM - CULTURA ORGANIZACIONAL' },
  { name: 'LLM - OPERAÇÃO REGIONAIS' },
]


export function CreateInternCltOfficeModal({
  onClose
}: CreateInternCltOfficeModalProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateInternCltOfficeSchema>({
    resolver: yupResolver(createInternCltOfficeSchema)
  })

  const { mutateAsync: createInternCltOfficeFn } = useMutation({
    mutationFn: createInternCltOffice,
    onSuccess: onClose
  })

  async function handleCreateInternCltOffice(values: CreateInternCltOfficeSchema) {
    await createInternCltOfficeFn({
      body: {
        name: values.name,
        trainings: values.trainings
      }
    })
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar cargo de CLT interno
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody
        as="form"
        display="flex"
        flexDirection="column"
        onSubmit={handleSubmit(handleCreateInternCltOffice)}
      >

        <FormControl isInvalid={!!errors?.name}>
          <FormLabel>
            Nome
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Input
            {...register('name')}
            name="name"
          />
        </FormControl>
        <FormControl isInvalid={!!errors?.trainings}>
          <FormLabel>
            Treinamentos
          </FormLabel>
          <Controller
            control={control}
            name="trainings"
            render={({ field }) => {
              return (
                <CheckboxGroup
                  value={field.value}
                  onChange={field.onChange}
                >
                  <VStack
                    spacing={3}
                    p={2}
                    border="1px solid"
                    borderColor="gray.200"
                    rounded="md"
                    align="left"
                  >
                    {trainings.map(training => {
                      return (
                        <Checkbox key={training.name} value={training.name}>
                          <Text fontSize="sm">
                            {training.name}
                          </Text>
                        </Checkbox>
                      )
                    })}
                  </VStack>

                </CheckboxGroup>
              )
            }}
          />
        </FormControl>

        <Button
          type="submit"
          colorScheme="blue"
          mt={3}
          isLoading={isSubmitting}
          alignSelf="end"
        >
          Criar
        </Button>

      </ModalBody>
    </ModalContent>
  )

}

import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getRegionals } from "api/regionals/getRegionals";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateRegional } from "./components/CreateRegional";
import { RegionalsTableRow } from "./components/RegionalsTableRow";


export function Regionals() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewRegionals = userLogged?.permissions?.includes('view-regionals')
  const userCanCreateRegional = userLogged?.permissions?.includes('create-regional')

  useEffect(() => {
    if (!userCanViewRegionals) history.push('/')
  }, [history, userCanViewRegionals])

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')

  const {
    data: regionalsResult
  } = useQuery({
    queryKey: ['regionals', page, status],
    queryFn: () => getRegionals({
      currentPage: Number(page),
      pageSize: 10,
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: regionalsResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: regionalsResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateRegionalModalOpen,
    onOpen: onOpenCreateRegionalModal,
    onClose: onCloseCreateRegionalModal
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Regionais</Heading>
        {userCanCreateRegional && (
          <>
            <IconButton
              aria-label="Criar regional"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateRegionalModal}
            />

            <Modal
              isOpen={isCreateRegionalModalOpen}
              onClose={onCloseCreateRegionalModal}
              isCentered
            >
              <ModalOverlay />

              <CreateRegional onCloseModal={onCloseCreateRegionalModal} />
            </Modal>
          </>
        )}
      </Flex>

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Sigla</Th>
              <Th color="white">Responsável</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {regionalsResult?.regionals?.map((regional) => {
              return (
                <RegionalsTableRow
                  key={regional.id}
                  regional={regional}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

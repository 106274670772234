import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Link, Progress, Select, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { applyAdvertence } from "api/advertences/applyAdvertence"
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation"
import { useToastify } from "hooks/toastify/useToastify"
import { ChangeEvent } from "react"
import { useForm, useWatch } from "react-hook-form"
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"
import { validateHasFile } from "utils/fileValidation"
import * as yup from "yup"

interface ApplyAdvertenceProps {
  advertenceId: string
}

interface ApplyAdvertenceSchema {
  signedAdvertence: 'yes' | 'no'
  advertenceAttachmentId: FileList
  proofOfSendingAdvertenceAttachmentId: FileList
}

const applyAdvertenceSchema = yup.object({
  signedAdvertence: yup.mixed().required().transform((value) => {
    return value.toLowerCase() === 'yes'
  }),
  advertenceAttachmentId: yup.mixed().test(value => validateHasFile(value)).required(),
  proofOfSendingAdvertenceAttachmentId: yup.mixed().when('signedAdvertence', {
    is: 'no',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  })
})

export function ApplyAdvertence({ advertenceId }: ApplyAdvertenceProps) {

  const { mutation: uploadAdvertenceAttachmentFn, uploadProggress: uploadProggressAdvertenceAttachment } = useAttachmentMutation()

  const { mutation: uploadProofOfSendingAdvertenceAttachmentFn, uploadProggress: uploadProggressProofOfSendingAdvertenceAttachment } = useAttachmentMutation()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutateAsync: applyAdvertenceFn } = useMutation({
    mutationFn: applyAdvertence,
    onSuccess() {
      queryClient.invalidateQueries(['advertences'])

      queryClient.invalidateQueries({ queryKey: 'awaiting-application-advertences' })
      queryClient.invalidateQueries({ queryKey: 'parent-verification-advertences' })
      queryClient.invalidateQueries({ queryKey: 'cancelled-advertences' })
      queryClient.invalidateQueries({ queryKey: 'done-advertences' })
      queryClient.invalidateQueries({ queryKey: ['advertence', advertenceId] })
    }
  })

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ApplyAdvertenceSchema>({
    resolver: yupResolver(applyAdvertenceSchema)
  })

  const [advertenceAttachmentId, proofOfSendingAdvertenceAttachmentId, signedAdvertence] = useWatch({
    control,
    name: ['advertenceAttachmentId', 'proofOfSendingAdvertenceAttachmentId', 'signedAdvertence'],
  })

  const hasSignedAdvertence = signedAdvertence === 'yes'

  async function handleUploadAdvertenceAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadAdvertenceAttachmentFn.mutateAsync(formData)

  }
  async function handleUploadProofOfSendingAdvertenceAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadProofOfSendingAdvertenceAttachmentFn.mutateAsync(formData)

  }

  async function handleApplyAdvertence({
    signedAdvertence,
  }: ApplyAdvertenceSchema) {
    await promiseMessage(applyAdvertenceFn({
      body: {
        advertenceId,
        advertenceAttachmentId: uploadAdvertenceAttachmentFn?.data?.attachment?.id,
        signedAdvertence,
        proofOfSendingAdvertenceAttachmentId: uploadProofOfSendingAdvertenceAttachmentFn?.data?.attachment?.id
      },

      routeParams: {
        advertenceId
      }
    }), 'Advertência aplicada!')
  }

  return (
    <Box
    w="full"
    as="form"
    maxH='500px'
    overflowY='scroll'
    onSubmit={handleSubmit(handleApplyAdvertence)}
  >
    <Heading letterSpacing="tight" size='sm'>Aplicar Advertência</Heading>

        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <Button
            as={FormLabel}
            htmlFor="advertenceAttachmentId"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.advertenceAttachmentId && '2px solid'}
            borderColor={(!!errors?.advertenceAttachmentId) && 'red.500'}
          >
            Anexar Advertência
          </Button>
          <FormControl isInvalid={!!errors?.advertenceAttachmentId}>
            <Input
              {...register('advertenceAttachmentId')}
              name="advertenceAttachmentId"
              id="advertenceAttachmentId"
              type="file"
              hidden
              onChangeCapture={handleUploadAdvertenceAttachment}
            />
          </FormControl>
        </Stack>


        {advertenceAttachmentId && (
          <Table size="sm">
            <Tbody>
              {Object.entries(advertenceAttachmentId).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggressAdvertenceAttachment} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadAdvertenceAttachmentFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadAdvertenceAttachmentFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >

            <FormControl isInvalid={!!errors.signedAdvertence}>
              <FormLabel fontSize="sm">
                Advertência assinada?
                <Text color="red" as="sup">*</Text>
              </FormLabel>
              <Select
                {...register('signedAdvertence')}
                name='signedAdvertence'
                size="sm"
                rounded="md"
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        {!hasSignedAdvertence && (
          <>
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="proofOfSendingAdvertenceAttachmentId"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.proofOfSendingAdvertenceAttachmentId && '2px solid'}
                borderColor={(!!errors?.proofOfSendingAdvertenceAttachmentId) && 'red.500'}
              >
                Anexar Comprovante de Envio da Advertência
              </Button>
              <FormControl isInvalid={!!errors?.proofOfSendingAdvertenceAttachmentId}>
                <Input
                  {...register('proofOfSendingAdvertenceAttachmentId')}
                  name="proofOfSendingAdvertenceAttachmentId"
                  id="proofOfSendingAdvertenceAttachmentId"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadProofOfSendingAdvertenceAttachment}
                />
              </FormControl>
            </Stack>

            {proofOfSendingAdvertenceAttachmentId && (
              <Table size="sm">
                <Tbody>
                  {Object.entries(proofOfSendingAdvertenceAttachmentId).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Td fontSize="xs" maxW="100px">{file.name}</Td>
                        <Td fontSize="xs" w="200px">
                          <Progress size="sm" rounded="md" value={uploadProggressProofOfSendingAdvertenceAttachment} />
                        </Td>
                        <Td fontSize="xs" isNumeric>
                          {uploadProofOfSendingAdvertenceAttachmentFn.data && (
                            <IconButton
                              aria-label="Visualizar anexo"
                              as={Link}
                              size="sm"
                              icon={<FaExternalLinkAlt />}
                              href={uploadProofOfSendingAdvertenceAttachmentFn.data.attachment.link}
                              isExternal
                            />
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            )}
          </>
        )}

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Aplicar
          </Button>
        </Flex>

    </Box>
  )

}

import { Box, Button, Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverTRCTSignatureDetails } from "api/cltDrivers/sendCltDriverTRCTSignatureDetails";

interface SendCltDriverDismissTRCTSignatureDetailsProps {
  cltDriverId: string
}

export interface SendCltDriverDismissTRCTSignatureDetailsSchema {
  doesSignedTRCT: string

}

const sendCltDriverDismissTRCTSignatureDetailsSchema = yup.object({
  doesSignedTRCT: yup.string().required(),
})

export function SendCltDriverDismissTRCTSignatureDetails({ cltDriverId }: SendCltDriverDismissTRCTSignatureDetailsProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverDismissTRCTSignatureDetailsSchema>({
    resolver: yupResolver(sendCltDriverDismissTRCTSignatureDetailsSchema)
  })

  const { mutateAsync: sendCltDriverScalabriniDetailsFn } = useMutation({
    mutationFn: sendCltDriverTRCTSignatureDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverDismissTRCTSignatureDetails(values: SendCltDriverDismissTRCTSignatureDetailsSchema) {
    await promiseMessage(sendCltDriverScalabriniDetailsFn({
      body: {
        doesSignedTRCT: values.doesSignedTRCT === 'yes'
      }, cltDriverId
    }), 'Informação de assinatura TRCT enviada com sucesso!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverDismissTRCTSignatureDetails)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão - Assinatura TRCT</Heading>
      <FormControl isInvalid={!!errors.doesSignedTRCT} mt={3}>
        <FormLabel>
          TRCT Assinado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesSignedTRCT"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

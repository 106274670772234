import { Flex, Icon, IconButton, Input } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { GetPmcResponse } from "api/pmcs/getPmc"
import { GetPmcsResponse } from "api/pmcs/getPmcs"
import { receivePmcProduct } from "api/pmcs/receivePmcProduct"
import { useToastify } from "hooks/toastify/useToastify"
import { useForm } from "react-hook-form"
import { FaArrowRight } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"

interface ReceivePmcProductFormProps {
  pmcId: string
  productId: string
  onCloseModal: () => void
}

interface ReceivePmcProductFormSchema {
  deliveredQuantity: number
}

const receivePmcProductSchema = yup.object().shape({
  deliveredQuantity: yup.number().required('É necessário informar a quantidade!')
})

export function ReceivePmcProductForm({
  pmcId,
  productId,
  onCloseModal,
}: ReceivePmcProductFormProps) {

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<ReceivePmcProductFormSchema>({
    resolver: yupResolver(receivePmcProductSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: receivePmcProductFn } = useMutation({
    mutationFn: receivePmcProduct,
    onSuccess(data, { pmcId, productId, body }) {
      queryClient.setQueryData<GetPmcResponse>(['pmc', pmcId], (pmcData) => {
        return {
          pmc: {
            ...pmcData.pmc,
            pmcProducts: pmcData.pmc.pmcProducts.map((pmcProduct) => {
              if (pmcProduct.product_id === productId) {
                return {...pmcProduct, delivered_at: new Date().toString(), delivered_quantity: body.deliveredQuantity }
              }

              return pmcProduct
            })
          }
        }
      })

      if (data.meta.received) {
        const cachedPmcs = queryClient.getQueriesData<GetPmcsResponse>({
          queryKey: ['pmcs']
        })

        cachedPmcs.forEach(([cachedKey, cachedData]) => {
          if (!cachedData) {
            return
          }

          queryClient.setQueryData(cachedKey, {
            ...cachedData,
            pmcs: cachedData.pmcs.map((pmc) => {
              if (pmc.id === pmcId) {
                return { ...pmc, received_at: new Date().toISOString() }
              }

              return pmc
            })
          })
        })

        onCloseModal()
      }
    },
  })

  const { promiseMessage } = useToastify()

  async function handleReceivePmcProduct(data: ReceivePmcProductFormSchema) {
    await promiseMessage(receivePmcProductFn({
      body: data,
      pmcId,
      productId,
    }), 'Produto de pmc recebido! 🚀')
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(handleReceivePmcProduct)}
      align="center"
      gap="2"
    >
      <Input
        {...register('deliveredQuantity')}
        name="deliveredQuantity"
        type="number"
        size="sm"
        placeholder="Ex: 10"
        borderRadius="md"
        focusBorderColor={errors.deliveredQuantity && 'red.500'}
      />

      <IconButton
        aria-label="Receber produto da pmc"
        icon={<Icon as={FaArrowRight} />}
        size="sm"
        type="submit"
        colorScheme="blue"
        isDisabled={isSubmitting}
      />
    </Flex>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Customer } from "./_types/Customer";

export interface GetCustomersParams {
  pageSize: number
  currentPage: number
  search?: string
}

export interface GetCustomersResponse {
  customers: Customer[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}


export async function getCustomers({
  currentPage,
  pageSize,
  search
}: GetCustomersParams) {
  try {
    const response = await api.get<GetCustomersResponse>('/customers', {
      params: {
        pageSize,
        currentPage,
        search
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Audit } from "api/audits/_types/Audit";
import { AuditCommercial } from "./AuditCommercial";
import { AuditField } from "./AuditField";
import { AuditFinancial } from "./AuditFinancial";
import { AuditFine } from "./AuditFine";
import { AuditMaintenance } from "./AuditMaintenance";
import { AuditOperational } from "./AuditOperational";
import { AuditQualityIso } from "./AuditQualityIso";
import { AuditQualityVisa } from "./AuditQualityVisa";
import { AuditRh } from "./AuditRh";
import { AuditRhLlm } from "./AuditRhLlm";

interface RealizeAuditProps {
  audit: Audit
}

export function RealizeAudit({ audit }: RealizeAuditProps) {
  return (
    <>
      {audit.type === 'COMERCIAL' && (
        <AuditCommercial
          auditId={audit.id}
        />
      )}
      {audit.type === 'RH' && (
        <AuditRh
          auditId={audit.id}
        />
      )}
      {audit.type === 'FINANCEIRA' && (
        <AuditFinancial
          auditId={audit.id}
        />
      )}
      {audit.type === 'RH LLM' && (
        <AuditRhLlm
          auditId={audit.id}
        />
      )}
      {audit.type === 'CAMPO' && (
        <AuditField
          auditId={audit.id}
        />
      )}
      {audit.type === 'MULTAS' && (
        <AuditFine
          auditId={audit.id}
        />
      )}
      {audit.type === 'MANUTENÇÃO' && (
        <AuditMaintenance
          auditId={audit.id}
        />
      )}
      {audit.type === 'QUALIDADE VISA' && (
        <AuditQualityVisa
          auditId={audit.id}
        />
      )}
      {audit.type === 'QUALIDADE ISO' && (
        <AuditQualityIso
          auditId={audit.id}
        />
      )}
      {audit.type === 'OPERACIONAL' && (
        <AuditOperational
          auditId={audit.id}
        />
      )}
    </>
  )
}

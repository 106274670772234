import { Button, HStack, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteVacation } from "api/vacations/deleteVacation";
import { dismissVacationCollaborator } from "api/vacations/dismissVacationCollaborator";
import { GetVacationsResponse } from "api/vacations/getVacations";
import { Vacation } from "api/vacations/_types/Vacation";
import { VacationPeriod } from "api/vacations/_types/VacationPeriod";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaSearch, FaTimes } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { VacationDetail } from "./VacationDetail";
import { VacationStatus } from "./VacationStatus";

interface VacationsTableRowProps {
  vacation: Vacation & {
    periods: VacationPeriod[]
  }
}

export function VacationsTableRow({ vacation }: VacationsTableRowProps) {
  const { userLogged } = useAuth()

  const userCanDismissVacationCollaborator = userLogged?.permissions.includes(
    'dismiss-vacation-collaborator'
  )

  const userCanDeleteVacation = userLogged?.permissions.includes(
    'delete-vacation'
  )

  const {
    isOpen: isVacationDetailOpen,
    onToggle: onToggleVacationDetail
  } = useDisclosure()


  const queryClient = useQueryClient()

  const { promiseMessage } = useToastify()

  const { mutateAsync: dismissVacationCollaboratorFn } = useMutation({
    mutationFn: dismissVacationCollaborator,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })

      queryClient.invalidateQueries({ queryKey: 'active-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planning-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planned-vacations' })
      queryClient.invalidateQueries({ queryKey: 'paying-vacations' })
      queryClient.invalidateQueries({ queryKey: 'waiting-vacations' })
      queryClient.invalidateQueries({ queryKey: 'on-vacations' })
      queryClient.invalidateQueries({ queryKey: 'done-vacations' })
      queryClient.invalidateQueries({ queryKey: 'dismissed-vacations' })
      queryClient.invalidateQueries({ queryKey: ['vacation', vacation?.id]})

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        const collaboratorName = cachedValue.vacations.find(
          (vacation) => vacation.id === variables.vacationId
        )?.collaborator_name

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.map((vacation) => {
            if (
              vacation.id === variables.vacationId ||
              vacation.collaborator_name === collaboratorName
            ) {
              return { ...vacation, status: 'dismissed' }
            }

            return vacation
          })
        })
      })
    },
  })

  async function handleDismissVacationCollaborator(vacationId: string) {
    await promiseMessage(dismissVacationCollaboratorFn({
      vacationId,
    }), 'Demissão realizada com sucesso!')
  }

  const { mutateAsync: deleteVacationFn } = useMutation({
    mutationFn: deleteVacation,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })
      queryClient.invalidateQueries({ queryKey: 'active-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planning-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planned-vacations' })
      queryClient.invalidateQueries({ queryKey: 'paying-vacations' })
      queryClient.invalidateQueries({ queryKey: 'waiting-vacations' })
      queryClient.invalidateQueries({ queryKey: 'on-vacations' })
      queryClient.invalidateQueries({ queryKey: 'done-vacations' })
      queryClient.invalidateQueries({ queryKey: 'dismissed-vacations' })
      queryClient.invalidateQueries({ queryKey: ['vacation', vacation?.id]})

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.filter(vacation => vacation.id === variables.vacationId)
        })
      })
    },
  })

  async function handleDeleteVacation(vacationId: string) {
    await promiseMessage(deleteVacationFn({
      vacationId,
    }), 'Férias excluída com sucesso!')
  }


  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Abrir detalhes das férias"
          variant="ghost"
          icon={<FaSearch />}
          size="sm"
          onClick={onToggleVacationDetail}
        />

        <Modal isOpen={isVacationDetailOpen} onClose={onToggleVacationDetail} size="2xl">
          <ModalOverlay />

          <VacationDetail vacationId={vacation.id} />
        </Modal>
      </Td>
      <Td>{vacation.collaborator_name}</Td>
      <Td>{format(new Date(vacation.deadline), 'dd/MM/yyyy')}</Td>
      <Td>
        <VacationStatus status={vacation.status} />
      </Td>
      <Td>
        <HStack spacing={3} align="center">


          {userCanDismissVacationCollaborator && (
            <Button
              size="xs"
              rightIcon={<FaTimes />}
              onClick={() => handleDismissVacationCollaborator(vacation.id)}
              lineHeight={1}
            >
              Demitir
            </Button>
          )}

          {userCanDeleteVacation && (
            <Button
              size="xs"
              rightIcon={<FaTrash />}
              onClick={() => handleDeleteVacation(vacation.id)}
              lineHeight={1}
              variant="ghost"
            >
              Excluir
            </Button>
          )}
        </HStack>
      </Td>
    </Tr>
  )
}

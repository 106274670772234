import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { ActiveFeedback } from "api/feedbacks/_types/ActiveFeedback";
import { format } from "date-fns";
import { FaSearch } from "react-icons/fa";
import { ActiveFeedbackDetails } from "./ActiveFeedbackDetails";

import { ActiveFeedbackStatus } from "./ActiveFeedbackStatus";

interface ActiveFeedbackTableRowProps {
  activeFeedback: ActiveFeedback
}

export function ActiveFeedbackTableRow({
  activeFeedback }: ActiveFeedbackTableRowProps) {

  const {
    isOpen: isActiveFeedbackDetailModalOpen,
    onToggle: onToggleActiveFeedbackDetailModal,
  } = useDisclosure()

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Feedback"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onToggleActiveFeedbackDetailModal}
        />
        <Modal
          isOpen={isActiveFeedbackDetailModalOpen}
          onClose={onToggleActiveFeedbackDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <ActiveFeedbackDetails
            activeFeedbackId={activeFeedback.id}
          />
        </Modal>
      </Td>
      <Td> {
        activeFeedback?.cltDriverId ? activeFeedback?.cltDriver?.driver?.firstname : activeFeedback?.internClt ? activeFeedback?.internClt?.name : '-'}</Td>
      <Td>
        <ActiveFeedbackStatus
          status={activeFeedback?.status}
        />
      </Td>
      <Td>{format(new Date(activeFeedback?.deadline), 'dd/MM/yyyy')}</Td>
    </Tr>
  )
}

import { createContext, ReactNode } from 'react'
import swal from 'sweetalert'
import { api } from '../services/api'
import { UserProps } from './UserContext'

interface CustomerProviderProps {
  children: ReactNode
  pathname?: string
}

interface ICustomerContacts {
  name: string
  email: string
  phone: string | null
  description: string | null
  type: string[]
  canReceiveAutomaticallyMessages: boolean
}

export interface CustomerProps {
  id: string
  type: string
  situation: string
  trading_firstname: string
  attendance_responsible: string
  company_lastname: string
  cnpj_cpf: string
  cost_center: string
  deadline_delivery: string
  operational_email: string
  financial_email: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  state: string
  city: string
  municipal_register: string | null
  state_register: string | null
  icms: string
  iss: string
  payment_conditional: string
  day_expiration_1: string | null
  day_expiration_2: string | null
  payment_type: string
  emission_type: string
  billing_type: string
  custom_billing_day: number
  lifebox_payment_term: string
  has_waiting_time: boolean
  observation: string
  attendanceResponsibleIDCustomer: UserProps
  attachments: string[]
  contacts: ICustomerContacts[]
  billing_observation: string
  can_receive_service_extras_email: boolean
  open_address_inclusion_communication: boolean
  anual_adjustment_date: string
  has_maintenance: string
  has_temp_check: boolean
}

interface CustomerInput extends Omit<
  CustomerProps,
  'id' | 'attendanceResponsibleIDCustomer' | 'attachments' | 'can_receive_service_extras_email' | 'open_address_inclusion_communication'
> {
  can_receive_service_extras_email: 'yes' | 'no'
  open_address_inclusion_communication: 'yes' | 'no'
}

interface CustomerContextProps {
  createCustomer: (customerInput: CustomerInput) => Promise<boolean | CustomerProps>
  deleteCustomer: (customerId: string) => Promise<boolean | undefined>
  editCustomer: (
    customerId: string,
    customerInput: CustomerInput,
  ) => Promise<boolean | undefined>
}

export const CustomerContext = createContext<CustomerContextProps>(
  {} as CustomerContextProps,
)

export function CustomerProvider({
  children,
  pathname,
}: CustomerProviderProps) {
  async function createCustomer(customerInput: CustomerInput) {
    try {
      const { data } = await api.post('/customer', customerInput)

      setTimeout(() => {
        swal('Poof! O cliente foi criado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return data
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteCustomer(customerId: string) {
    try {
      await api.delete(`/customer/${customerId}`)
      swal('Poof! O cliente foi excluído com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editCustomer(
    customerId: string,
    customerInput: CustomerInput,
  ) {
    try {
      await api.put(`/customer/${customerId}`, customerInput)
      setTimeout(() => {
        swal('Poof! O cliente foi atualizado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <CustomerContext.Provider
      value={{ createCustomer, editCustomer, deleteCustomer }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Text,
  Flex,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import {
  OccurrenceReportProps,
  ReportContext,
} from '../../../../contexts/ReportContext'
import { ReportLoading } from '../../../Loading/ReportLoading'
import { occurrenceStatusDict } from '../../../../utils/OccurrenceFunctions/occurrenceDict'
import { useContentLoading } from '../../../../hooks/loading/useContentLoading'
import { EmptyContentTableAlert } from '../../../Alerts/EmptyContentTableAlert'

export function OccurrenceReportTable() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()

  const [searchedOccurrenceReport, setSearchedOccurrenceReport] = useState<
    OccurrenceReportProps[]
  >([])
  const [slicedOccurrenceReports, setSlicedOccurrenceReports] = useState<
    OccurrenceReportProps[]
  >([])

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [typeFiltered, setTypeFiltered] = useState('')
  const [stepFiltered, setStepFiltered] = useState('')

  const { occurrenceReport, isOccurrenceReportLoaded } =
    useContext(ReportContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedOccurrenceReport?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    const setIsLoadingState = () => {
      if (isOccurrenceReportLoaded) handleSetIsContentLoadingFalse()
    }
    setIsLoadingState()
  }, [isOccurrenceReportLoaded, handleSetIsContentLoadingFalse])

  useEffect(() => {
    const setSlicedOccurrence = () => {
      setSlicedOccurrenceReports(occurrenceReport)
    }
    setSlicedOccurrence()
  }, [itemLimit, currentPage, offset, occurrenceReport])

  useEffect(() => {
    const setFilterAtributes = () => {
      const newSlicedBillingReport = slicedOccurrenceReports?.filter(
        (occurrence) => {
          const typeFilter = searchBoxFilter(occurrence.type, typeFiltered)
          const customerFilter = searchBoxFilter(
            occurrence.customer,
            customerFiltered,
          )
          const stepFilter = searchBoxFilter(
            occurrenceStatusDict(occurrence.step),
            stepFiltered,
          )
          const protocolFilter = searchBoxFilter(
            occurrence.occurrenceProtocol.toString(),
            protocolFiltered,
          )

          if (
            protocolFiltered === '' &&
            customerFiltered === '' &&
            typeFiltered === '' &&
            stepFiltered === ''
          )
            return occurrence

          return protocolFilter && customerFilter && typeFilter && stepFilter
        },
      )
      setSearchedOccurrenceReport(newSlicedBillingReport)
    }

    setFilterAtributes()
  }, [
    protocolFiltered,
    customerFiltered,
    typeFiltered,
    stepFiltered,
    slicedOccurrenceReports,
  ])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  if (isContentLoading) {
    return <ReportLoading />
  }

  return (
    <>
      {occurrenceReport.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui! Tente ajustar os filtros e tente novamente 😀!"
        />
      ) : (
        <>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th fontSize="15" color="blue.900">
                    <Flex>
                      <Stack>
                        <SearchBox
                          placeholder="Buscar Protocolo..."
                          handleSearch={(e) =>
                            setProtocolFiltered(e.target.value)
                          }
                        />
                        <Text>PROTOCOLO</Text>
                      </Stack>
                    </Flex>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Flex>
                      <Stack>
                        <SearchBox
                          placeholder="Buscar Cliente..."
                          handleSearch={(e) =>
                            setCustomerFiltered(e.target.value)
                          }
                        />
                        <Text>CLIENTE</Text>
                      </Stack>
                    </Flex>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Flex>
                      <Stack>
                        <SearchBox
                          placeholder="Buscar Ocorrência..."
                          handleSearch={(e) => setTypeFiltered(e.target.value)}
                        />
                        <Text>OCORRÊNCIA</Text>
                      </Stack>
                    </Flex>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Flex>
                      <Stack>
                        <SearchBox
                          placeholder="Buscar Status..."
                          handleSearch={(e) => setStepFiltered(e.target.value)}
                        />
                        <Text>STATUS</Text>
                      </Stack>
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedOccurrenceReport
                  ?.slice(offset, offset + Number(itemLimit))
                  .map((occurrenceReport, index) => {
                    return (
                      <Tr key={index}>
                        <Td>
                          {occurrenceReport.occurrenceProtocol.toString()}
                        </Td>
                        <Td>{occurrenceReport.customer.toUpperCase()}</Td>
                        <Td>{occurrenceReport.type.toUpperCase()}</Td>
                        <Td>
                          {occurrenceStatusDict(
                            occurrenceReport.step,
                          ).toUpperCase()}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

import { usePagination } from "@ajna/pagination"
import { Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { getAddressLogs } from "api/addresses/getAddressLogs"
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert"
import { Pagination } from "components/Pagination/Pagination"
import { CustomerProps } from "contexts/CustomerContext"
import { useSearchParams } from "hooks/useSearchParams"
import { Fragment } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"
import { AddressLogTableRow } from "./AddressLogTableRow"


interface AddressLogsModalProps {
  addressId: string
  customers: CustomerProps[]
}
export function AddressLogsModal({ customers, addressId }: AddressLogsModalProps) {

  const searchParams = useSearchParams()
  const page = searchParams.get('page') ?? '1'

  const { replace } = useHistory()

  const { data: addressLogData, isLoading: isAddressLogsDataLoading } = useQuery({
    queryKey: ['addressLogs', page],
    queryFn: () => getAddressLogs({
      currentPage: page,
      pageSize: '10',
      addressId: addressId
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: addressLogData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: addressLogData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }
  return (
    <ModalContent maxW={'950px'}>
      <ModalHeader>Logs</ModalHeader>
      <ModalCloseButton />
      {isAddressLogsDataLoading ? (
        <ModalBody
          maxH='500px'
          h='full'
          overflow='auto'
        >
          <Spinner />
        </ModalBody>
      ) : (
        <ModalBody
          maxH='500px'
          overflow='auto'
        >
          <Flex direction='column' align='center'>
            {addressLogData?.addressLogs?.length === 0 ? (
              <EmptyContentTableAlert title="Ops..." description="Não há logs para este endereço!" />
            ) : (
              <TableContainer
                rounded="md"
              >
                <Table m='2' maxW='500px' variant='simple' size='sm'>
                  <Thead>
                    <Tr
                      h="40px"
                      bg="#38c3fa"
                      rounded='md'
                    >
                      <Th color="white">CAMPO</Th>
                      <Th color="white">VALOR ANTERIOR</Th>
                      <Th color="white">VALOR ATUALIZADO</Th>
                      <Th color="white">ATUALIZADO POR</Th>
                      <Th color="white">DATA DA ATUALIZAÇÃO</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {addressLogData?.addressLogs?.map((log) => {
                      return (
                        <Fragment key={log.id}>
                          {log.log_detail?.situation && (
                            <AddressLogTableRow
                              name="Situação"
                              prevValue={String(log?.log_detail.situation?.prev_value)}
                              newValue={String(log?.log_detail?.situation?.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.customer_id && (
                            <AddressLogTableRow
                              name="Cliente"
                              prevValue={`${customers?.find(customer => customer.id === log?.log_detail.customer_id?.prev_value)?.trading_firstname}`}
                              newValue={`${customers?.find(customer => customer.id === log?.log_detail.customer_id?.new_value)?.trading_firstname}`}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.type && (
                            <AddressLogTableRow
                              name="Tipo de cliente"
                              prevValue={log?.log_detail.type?.prev_value}
                              newValue={log?.log_detail?.type?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.cnpj_cpf && (
                            <AddressLogTableRow
                              name="CPF/CNPJ"
                              prevValue={log?.log_detail?.cnpj_cpf?.prev_value}
                              newValue={log?.log_detail?.cnpj_cpf?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.trading_name && (
                            <AddressLogTableRow
                              name="Nome fantasia"
                              prevValue={log?.log_detail?.trading_name?.prev_value}
                              newValue={log?.log_detail?.trading_name?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}

                          {log.log_detail?.branch && (
                            <AddressLogTableRow
                              name="Setor/Unidade"
                              prevValue={log?.log_detail.branch?.prev_value}
                              newValue={log?.log_detail?.branch?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.responsible_name && (
                            <AddressLogTableRow
                              name="Nome do responsável"
                              prevValue={log?.log_detail?.responsible_name?.prev_value}
                              newValue={log?.log_detail?.responsible_name?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.responsible_email && (
                            <AddressLogTableRow
                              name="E-mail do responsável"
                              prevValue={log?.log_detail?.responsible_email?.prev_value}
                              newValue={log?.log_detail?.responsible_email?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.responsible_telephone && (
                            <AddressLogTableRow
                              name="Telefone do responsável"
                              prevValue={log?.log_detail?.responsible_telephone?.prev_value}
                              newValue={log?.log_detail?.responsible_telephone?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.cep && (
                            <AddressLogTableRow
                              name="CEP"
                              prevValue={log?.log_detail?.cep?.prev_value}
                              newValue={log?.log_detail?.cep?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.street && (
                            <AddressLogTableRow
                              name="Rua"
                              prevValue={log?.log_detail?.street?.prev_value}
                              newValue={log?.log_detail?.street?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.number && (
                            <AddressLogTableRow
                              name="Número"
                              prevValue={String(log?.log_detail.number?.prev_value)}
                              newValue={String(log?.log_detail?.number?.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.complement && (
                            <AddressLogTableRow
                              name="Complemento"
                              prevValue={String(log?.log_detail?.complement?.prev_value)}
                              newValue={String(log?.log_detail?.complement?.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.neighborhood && (
                            <AddressLogTableRow
                              name="Bairro"
                              prevValue={String(log?.log_detail?.neighborhood?.prev_value)}
                              newValue={String(log?.log_detail?.neighborhood?.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.state && (
                            <AddressLogTableRow
                              name="Estado"
                              prevValue={String(log?.log_detail?.state?.prev_value)}
                              newValue={String(log?.log_detail?.state?.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.city_id && (
                            <AddressLogTableRow
                              name="Cidade"
                              prevValue={String(log?.log_detail?.city_id?.prev_value)}
                              newValue={String(log?.log_detail?.city_id?.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.reference_point && (
                            <AddressLogTableRow
                              name="Ponto de referência"
                              prevValue={String(log?.log_detail?.reference_point?.prev_value)}
                              newValue={String(log?.log_detail?.reference_point?.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.icms && (
                            <AddressLogTableRow
                              name="ICMS"
                              prevValue={log?.log_detail?.icms?.prev_value}
                              newValue={log?.log_detail?.icms?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.deadline && (
                            <AddressLogTableRow
                              name="Prazo máximo"
                              prevValue={`D + ${log?.log_detail?.deadline?.prev_value}`}
                              newValue={`D + ${log?.log_detail?.deadline?.new_value}`}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.business_open && (
                            <AddressLogTableRow
                              name="Horário de abertura de segunda a sexta"
                              prevValue={String(formatDate.handle(log?.log_detail?.business_open?.prev_value, "DateOnlyWithHourMinute"))}
                              newValue={String(formatDate.handle(log?.log_detail?.business_close?.new_value, "DateOnlyWithHourMinute"))}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.business_close && (
                            <AddressLogTableRow
                              name="Horário de fechamento de segunda a sexta"
                              prevValue={String(formatDate.handle(log?.log_detail?.business_close?.prev_value, "DateOnlyWithHourMinute"))}
                              newValue={String(formatDate.handle(log?.log_detail?.business_close?.new_value, "DateOnlyWithHourMinute"))}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.saturday_open && (
                            <AddressLogTableRow
                              name="Horário de abertura aos sábados"
                              prevValue={String(formatDate.handle(log?.log_detail?.saturday_open?.prev_value, "DateOnlyWithHourMinute"))}
                              newValue={String(formatDate.handle(log?.log_detail?.saturday_open?.new_value, "DateOnlyWithHourMinute"))}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.saturday_close && (
                            <AddressLogTableRow
                              name="Horário de fechamento aos sábados"
                              prevValue={String(formatDate.handle(log?.log_detail?.saturday_close?.prev_value, "DateOnlyWithHourMinute"))}
                              newValue={String(formatDate.handle(log?.log_detail?.saturday_close?.new_value, "DateOnlyWithHourMinute"))}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.sunday_open && (
                            <AddressLogTableRow
                              name="Horário de abertura aos domingos"
                              prevValue={String(formatDate.handle(log?.log_detail?.sunday_open?.prev_value, "DateOnlyWithHourMinute"))}
                              newValue={String(formatDate.handle(log?.log_detail?.sunday_open?.new_value, "DateOnlyWithHourMinute"))}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.sunday_close && (
                            <AddressLogTableRow
                              name="Horário de fechamento aos domingos"
                              prevValue={String(formatDate.handle(log?.log_detail?.sunday_close?.prev_value, "DateOnlyWithHourMinute"))}
                              newValue={String(formatDate.handle(log?.log_detail?.sunday_close?.new_value, "DateOnlyWithHourMinute"))}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}

                          {log.log_detail?.has_own_board_landing && (
                            <AddressLogTableRow
                              name="Possui embarque/desembarque próprio?"
                              prevValue={log?.log_detail?.has_own_board_landing?.prev_value ? 'SIM' : 'NÃO'}
                              newValue={log?.log_detail?.has_own_board_landing?.new_value ? 'SIM' : 'NÃO'}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail?.observation && (
                            <AddressLogTableRow
                              name="Observações"
                              prevValue={log?.log_detail?.observation?.prev_value}
                              newValue={log?.log_detail?.observation?.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                        </Fragment>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <Pagination
              currentPage={currentPage}
              pages={pages}
              pagesQuantity={pagesCount}
              handlePageChange={handleChangePage}
            />
          </Flex>
        </ModalBody>
      )}
    </ModalContent>
  )
}

import { useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../../hooks/auth/useAuth'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useOccurrence } from '../../../hooks/occurrences/useOccurrence'
import { useSwal } from '../../../hooks/swal/useSwal'
import { StandardBackgroundForm } from '../../../components/Form/StandardBackgroundForm'
import { OccurrenceAccordion } from '../../../components/Accordions/Occurrence/OccurrenceAccordion'
import { useInput } from '../../../hooks/input/useInput'
import { ValidateOccurrenceForm } from '../components/Forms/Validate'
import { useService } from 'hooks/services/service'
import { useBudget } from 'hooks/budget/useBudget'
import { useProviders } from 'hooks/provider/useProviders'
import { useOccurrenceFunctions } from 'hooks/occurrences/useOccurrenceFunctions'
import { useRouteNetworksBySourceAndDestinationBranch } from 'hooks/useRouteNetwork/useRouteNetworksBySourceAndDestinationBranch'
import { formatDate } from 'utils/DateFunctions/formatDate'

interface OccurrenceMaterials {
  materialName: string
  quantity: number
}

interface IFormProps {
  protocol: number
  is_customer: boolean
  proceed: string
  service_new_planned_travel: string
  reason?: string
  cause?: string
  corrective?: string
  preventive?: string
  provider_id?: string
}

interface QueryParamsProps {
  occurrence_id: string
}

const schema = yup.object().shape({
  proceed: yup.string().required('Campo Obrigatório'),
  is_customer: yup.boolean(),

  // DOES NOT PROCEED
  reason: yup.string().when('proceed', {
    is: 'NÃO',
    then: yup.string().required('Campo Obrigatório'),
  }),

  service_new_planned_travel: yup.mixed().when('$isAlterBoardIntercurrence', {
    is: true,
    then: yup.mixed().when('proceed', {
      is: 'SIM',
      then: yup.string().required('Campo obrigatório')
    })
  }),

  // PROCEED
  cause: yup.string().when('is_customer', {
    is: true,
    then: yup.string().when('proceed', {
      is: 'SIM',
      then: yup.string().when('$isIntercurrenceWithDetails', {
        is: true,
        then: yup.string().required('Campo Obrigatório'),
      })
    }),
  }),
  corrective: yup.string().when('is_customer', {
    is: true,
    then: yup.string().when('proceed', {
      is: 'SIM',
      then: yup.string().when('$isIntercurrenceWithDetails', {
        is: true,
        then: yup.string().required('Campo Obrigatório'),
      })
    }),
  }),
  preventive: yup.string().when('is_customer', {
    is: true,
    then: yup.string().when('proceed', {
      is: 'SIM',
      then: yup.string().when('$isIntercurrenceWithDetails', {
        is: true,
        then: yup.string().required('Campo Obrigatório'),
      })
    }),
  }),
})

export function OccurrenceProceedAddDetailEdit() {

  const { occurrence_id }: QueryParamsProps = useParams()

  const {
    occurrence: {
      data: occurrence, isLoading: isOccurrenceLoading
    },
    handleSetOccurrenceId
  } = useOccurrence()
  const {
    inputs: { data: inputs, isLoading: isInputsLoading }
  } = useInput(null, true)

  // const { validateOccurrence } = useContext(OccurrenceContext)
  const isAlterBoardIntercurrence = occurrence?.intercurrence === 'ALTERAÇÃO DE EMBARQUE'
  const isIntercurrenceWithDetails =
  occurrence?.intercurrence !== 'ALTERAÇÃO DE INSUMOS UTILIZADOS' &&
  occurrence?.intercurrence !== 'NECESSIDADE DE MANUTENÇÃO DE REFRIGERAÇÃO'

  const {
    validateOccurrence: { mutateAsync: validateOccurrence }
  } = useOccurrenceFunctions()
  const { userLogged } = useAuth()
  const formProps = useForm<IFormProps>({
    context: {
      isIntercurrenceWithDetails: isIntercurrenceWithDetails,
      isAlterBoardIntercurrence: isAlterBoardIntercurrence
    },
    resolver: yupResolver(schema),
  })
  const history = useHistory()
  const { confirmMessage, standardMessage } = useSwal()

  const { setValue, control, handleSubmit } = formProps

  useEffect(() => {
    if (occurrence_id) handleSetOccurrenceId(occurrence_id)
  }, [occurrence_id, handleSetOccurrenceId])

  const isCustomerOccurrence = occurrence?.type === "CLIENTE"
  const isAlterInputsItercurrence = occurrence?.intercurrence === 'ALTERAÇÃO DE INSUMOS UTILIZADOS'

  useEffect(() => {
    if (isCustomerOccurrence) {
      setValue("is_customer", true)
    } else {
      setValue("is_customer", false)
    }
  })

  const occurrenceProceed = useWatch<IFormProps, 'proceed'>({
    control,
    name: 'proceed'
  }) === "SIM"

  useEffect(() => {
    if (occurrenceProceed) {
      setValue("reason", undefined)
    } else {
      setValue("cause", undefined)
      setValue("corrective", undefined)
      setValue("preventive", undefined)
    }
  }, [occurrenceProceed, setValue])

  const isNotMatrizUserType = ['COLETADOR', 'CLIENTE', 'MOTORISTA', 'REMETENTE'].some(value => value === userLogged?.user_type)

  useEffect(() => {
    if (isNotMatrizUserType) {
      history.push("/")
    }
  }, [isNotMatrizUserType, history])

  async function handleValidateOccurrence(values: IFormProps) {
    const hasValidateOccurrence = await confirmMessage({ title: "Deseja validar a ocorrência?" })

    const formattedValues = {
      proceed: values.proceed as 'SIM' | 'NÃO',
      service_new_planned_travel: values.service_new_planned_travel ? values.service_new_planned_travel : null,
      cause: !values.cause ? null : values.cause,
      corrective: !values.corrective ? null : values.corrective,
      preventive: !values.preventive ? null : values.preventive,
      reason: !values.reason ? null : values.reason,
      provider_id: !values.provider_id ? null : values.provider_id
    }

    if (hasValidateOccurrence) {

      await validateOccurrence({
        id: occurrence_id,
        input: formattedValues
      })
      history.push("/ocorrencias")

    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const isAlterInputsOccurrence = occurrence?.intercurrence === "ALTERAÇÃO DE INSUMOS UTILIZADOS"

  const {
    service: { data: service, isFetching: isServiceFetching }
  } = useService(occurrence?.service_id)

  const {
    budget: { data: budget, isFetching: isFetchingBudget }
  } = useBudget(service?.serviceIDRequested.budget_id ?? null, false)
  const { data: providers, isFetching: isFetchingProviders } = useProviders({
    queryOptions: { enabled: !!budget }
  })

  const providersFilteredByBudgetSourceHubOptions = providers
    ?.filter(
      provider => provider.material === "GELO SECO" && provider.hub_id === budget?.source_hub_id
    )
    .map(provider => {
      return {
        key: provider.id,
        value: provider.id,
        showOption: provider.company_name,
      }
    })

  const occurrenceMaterials = isAlterInputsOccurrence
    ? occurrence?.materials?.reduce((acc, material) => {
      const inputFiltered = inputs?.find(input => input.id === material.input_id)

      if (material.input_id === "GELO SECO") {
        acc.push({
          materialName: material?.input_id,
          quantity: material?.quantity
        })
      } else {
        acc.push({
          materialName: inputFiltered?.name,
          quantity: material?.quantity
        })
      }


      return acc
    }, [] as OccurrenceMaterials[])
    : []

  const requestedServiceHasProvider = service?.serviceIDRequested.provider_id !== null

  const isGeloSecoQuantityChanged = occurrenceMaterials?.length
    ? occurrenceMaterials?.find(material => material.materialName === "GELO SECO")?.quantity > 0 &&
    !requestedServiceHasProvider
    : false

  const {
    data: routeNetworksData,
    isFetching: isFetchingRouteNetworksData
  } = useRouteNetworksBySourceAndDestinationBranch({
    routeParams: {
      sourceBranch: occurrence?.service_new_source_branch_id,
      destinationBranch: occurrence?.service_new_destination_branch_id
    },
    queryOptions: {
      enabled: occurrence?.intercurrence === 'ALTERAÇÃO DE EMBARQUE'
    }
  })

  const routeNetworksSelectOptions = routeNetworksData?.routeNetworks.map((routeNetwork) => {
    return {
      key: routeNetwork.id,
      value: `${routeNetwork.truck_flight} - ${formatDate.handle(
        routeNetwork.departure_date, "DateWithoutHourToShow"
      )} - ${formatDate.handle(
        routeNetwork.departure_hour, "DateOnlyWithHourMinute"
      )} / ${formatDate.handle(
        routeNetwork.arrival_date, "DateWithoutHourToShow"
      )} - ${formatDate.handle(
        routeNetwork.arrival_hour, "DateOnlyWithHourMinute"
      )}`,
      showOption: `${routeNetwork.truck_flight} - ${formatDate.handle(
        routeNetwork.departure_date, "DateWithoutHourToShow"
      )} - ${formatDate.handle(
        routeNetwork.departure_hour, "DateOnlyWithHourMinute"
      )} / ${formatDate.handle(
        routeNetwork.arrival_date, "DateWithoutHourToShow"
      )} - ${formatDate.handle(
        routeNetwork.arrival_hour, "DateOnlyWithHourMinute"
      )}`,
    }
  })

  console.log(routeNetworksData)

  if (isOccurrenceLoading || isServiceFetching || isFetchingBudget || isFetchingProviders || isFetchingRouteNetworksData) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(handleValidateOccurrence)} title="Validar ocorrência">
      {occurrence && inputs && (
        <OccurrenceAccordion
          occurrenceById={occurrence}
          isInputsLoading={isInputsLoading}
          isOccurrenceLoading={isOccurrenceLoading}
          inputs={inputs!}
        />
      )}
      <FormProvider {...formProps}>
        <ValidateOccurrenceForm
          isCustomerOccurrence={isCustomerOccurrence}
          isAlterInputsItercurrence={isAlterInputsItercurrence}
          isIntercurrenceWithDetails={isIntercurrenceWithDetails}
          isAlterBoardIntercurrence={isAlterBoardIntercurrence}
          occurrenceProceed={occurrenceProceed}
          routeNetworksSelectOptions={routeNetworksSelectOptions}
          isGeloSecoQuantityChanged={isGeloSecoQuantityChanged}
          providersFilteredByBudgetSourceHubOptions={providersFilteredByBudgetSourceHubOptions}
        />
      </FormProvider>
    </StandardBackgroundForm>
  )
}

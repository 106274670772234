import { Button, Flex, Input, Select } from "@chakra-ui/react"
import { PatrimonyStatus } from "api/patrimonies/patrimony"
import { useSearchParams } from "hooks/useSearchParams"
import { useForm } from "react-hook-form"
import { FaSearch, FaTimes } from "react-icons/fa"
import { useHistory } from "react-router-dom"

interface PatrimoniesTableFiltersSchema {
  status: PatrimonyStatus | 'all'
  type: string
  brand: string
}


export function PatrimoniesTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const type = searchParams.get('type')
  const brand = searchParams.get('brand')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<PatrimoniesTableFiltersSchema>({
    defaultValues: {
      type: type ?? '',
      brand: brand ?? '',
      status: status as PatrimonyStatus | 'all'
    }
  })

  async function handleFilterOnboardings(data: PatrimoniesTableFiltersSchema) {
    if (data.type) {
      searchParams.set('type', data.type)
    } else {
      searchParams.delete('type')
    }

    if (data.brand) {
      searchParams.set('brand', data.brand)
    } else {
      searchParams.delete('brand')
    }

    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('type')
    searchParams.delete('brand')
    searchParams.delete('status')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterOnboardings)}
    >
      <Input
        {...register("type")}
        placeholder="Tipo"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("brand")}
        placeholder="Marca"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="available">Disponível</option>
        <option value="in-possession">Em posse</option>
        <option value="disabled">Inutilizado</option>

      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>
    </Flex>
  )
}

import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useFormContext } from "react-hook-form";

interface RegionFormProps {
  slug: string
}

export interface RegionFormInputs {
  name: string,
  acronym: string
}

export function RegionForm({ slug }: RegionFormProps) {
  const isViewPage = slug === 'vizualizar'
  const {
    register,
    formState: { errors }
  } = useFormContext<RegionFormInputs>()

  return (
    <Flex direction='column' gap={4}>
      <Stack spacing={4} direction={['column', 'column', 'row']}>
        <Input
          {...register('name')}
          name='name'
          label='Nome'
          error={errors.name}
          required
          isDisabled={isViewPage}
        />
        <Input
          {...register('acronym')}
          name='acronym'
          label='Acrônimo'
          error={errors.acronym}
          isDisabled={isViewPage}
          required
        />
      </Stack>
    </Flex>
  )
}

import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Flex, Icon } from "@chakra-ui/react";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { SearchBox } from "components/SearchBox/SearchBox";
import { Aggregate } from "hooks/aggregate/dtos/Aggregate";
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { useSwal } from "hooks/swal/useSwal";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FiEye, FiEdit3, FiTrash2, FiPower } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";

import { formatDate } from "utils/DateFunctions/formatDate";
import { searchBoxFilter } from "utils/searchBoxFilter";
import { AggregatesStatusTableButton } from "./AggregatesStatusTableButton";

interface ActivateAggregatesTableProps {
  aggregatesActivated: Aggregate[]
}

interface FilterOptionInputs {
  company_name: string
  trading_name: string
  name: string
  state: string
  city: string
}

const aggregateFilterOptions = [
  { key: "company_name", value: "RAZÃO SOCIAL", checked: false },
  { key: "trading_name", value: "NOME FANTASIA", checked: false },
  { key: "name", value: "NOME", checked: false },
  { key: "state", value: "ESTADO", checked: false },
  { key: "city", value: "CIDADE", checked: false },
  { key: "payment_type", value: 'TIPO DE PAGAMENTO', checked: false },
  { key: 'contract_start_date', value: 'INICIO DO CONTRATO', checked: false },
];

const aggregateOrderOptions = [
  { key: "company_name", value: "Razão Social", checked: false },
  { key: "trading_name", value: "Nome Fantasia", checked: false },
];

export function ActivateAggregatesTable({ aggregatesActivated }: ActivateAggregatesTableProps) {
  const { confirmMessage, standardMessage } = useSwal()

  const { deleteAggregate: { mutateAsync: deleteAggregate } } = useAggregateFunctions()

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions();



  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(aggregateOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(aggregateFilterOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])



  const { register, watch } = useForm<FilterOptionInputs>();

  const { userLogged } = useAuth()


  const { push: redirectTo } = useHistory()


  const userHasPermissionViewAggregates =
    userLogged?.permissions.includes("view-aggregate");

  useEffect(() => {
    if (!userHasPermissionViewAggregates) {
      redirectTo("/");
    }
  }, [userHasPermissionViewAggregates, redirectTo]);

  const filterValues = watch([
    "company_name",
    "trading_name",
    "name",
    "state",
    "city",
  ]);


  const aggregatesFiltered = aggregatesActivated?.filter(aggregate => {
    const [
      companyNameFiltered,
      tradingNameFiltered,
      nameFiltered,
      stateFiltered,
      cityFiltered,
    ] = filterValues;


    const companyNameFilter = companyNameFiltered
      ? searchBoxFilter(String(aggregate.company_name), companyNameFiltered)
      : null;


    const tradingNameFilter = tradingNameFiltered
      ? searchBoxFilter(String(aggregate.trading_name), tradingNameFiltered)
      : null;

    const nameFilter = nameFiltered
      ? searchBoxFilter(String(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`), nameFiltered)
      : null;

    const stateFilter = stateFiltered
      ? searchBoxFilter(String(aggregate.state), stateFiltered)
      : null;

    const cityFilter = cityFiltered
      ? searchBoxFilter(String(aggregate.city), cityFiltered)
      : null;

    if (filterValues.some((value) => !!value)) {
      return (
        companyNameFilter ||
        tradingNameFilter ||
        stateFilter ||
        cityFilter ||
        nameFilter
      );
    }

    return aggregate
  }).sort((a, b) => (orderOptionSelected.includes('trading_name') &&
    a.trading_name.localeCompare(b.trading_name)) ||
    (orderOptionSelected.includes('company_name') &&
      a.company_name.localeCompare(b.company_name)) ||
    0)

  async function handleDeleteAggregate(aggregate_id: string) {
    const hasDeleteAggregate = await confirmMessage({ title: "Deseja excluir um agregado?" })

    if (hasDeleteAggregate) {
      deleteAggregate(aggregate_id)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <Flex
      w="full"
      direction="column"
      gap={6}
    >
      <Flex justify="space-between" flex="1" w="full">
        <TableFilterButton />
        <Flex direction="column" gap="4">
          {filterOptions
            .filter((option) => option.checked === true)
            .map((option) => {
              return (
                <SearchBox
                  {...register(option.key as keyof FilterOptionInputs)}
                  key={option.key}
                  size="sm"
                  placeholder={"Buscar " + option.value}
                  handleSearch={() => { }}
                />
              );
            })}
        </Flex>
      </Flex>
      <TableContainer w="full">
        <Table colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {aggregateFilterOptions.map((option) => {
                return (
                  <Th key={option.key}>{option.value.toUpperCase()}</Th>
                );
              })}
              <Th>
                <AggregatesStatusTableButton />
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {aggregatesFiltered?.map((aggregate) => {
                return (
                  <Tr key={aggregate.id}>
                    <Td>{aggregate.company_name}</Td>
                    <Td>{aggregate.trading_name}</Td>
                    <Td>{`${aggregate.driver.firstname} ${aggregate.driver.lastname}`}</Td>
                    <Td>{aggregate.state}</Td>
                    <Td>{aggregate.city}</Td>
                    <Td>{aggregate.payment_type}</Td>
                    <Td>{formatDate.handle(new Date(aggregate.contract_start_date), "DateWithoutHourToShow")}</Td>
                    <Td>Ativo</Td>
                    <Td isNumeric>
                      {userLogged.permissions.includes('resign-aggregate') && (
                        <Link
                          to={`/agregado-desligamento/${aggregate.id}`}
                        >
                          <Icon
                            cursor="pointer"
                            as={FiPower}
                            fontSize="20"
                            ml="4"
                            mt={['2', '2', '0']}
                          />
                        </Link>
                      )}
                      {userLogged !== null && (
                        <>
                          {
                            userLogged.permissions.includes('view-aggregate') && (
                              <Link
                                to={`/agregado/visualizar/${aggregate.id}`}
                              >
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                />
                              </Link>
                            )
                          }
                          {userLogged.permissions.includes('edit-aggregate') && (
                            <Link
                              to={`/agregado/editar/${aggregate.id}`}
                            >
                              <Icon
                                cursor="pointer"
                                as={FiEdit3}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                              />
                            </Link>
                          )}

                          {userLogged.permissions.includes('delete-aggregate') && (
                            <Icon
                              cursor="pointer"
                              as={FiTrash2}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                              onClick={() =>
                                handleDeleteAggregate(aggregate.id)
                              }
                            />
                          )}

                        </>
                      )}
                    </Td>

                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}

import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { useAuth } from "hooks/auth/useAuth";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from "hooks/toastify/useToastify";
import { PurchaseOrderListResponse } from "./dtos/PurchaseOrder";

interface PurchaseOrdersQueryParams {
  pageSize?: number
  currentPage?: number
}

type QueryOptions = UseQueryOptions<PurchaseOrderListResponse, unknown, PurchaseOrderListResponse, QueryKey>

interface UsePurchaseOrdersOptions {
  queryKey?: QueryKey
  queryParams?: PurchaseOrdersQueryParams
  queryOptions?: QueryOptions
}

export function useAwaitingQuotePurchaseOrders(options?: UsePurchaseOrdersOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getAwaitingQuotePurchaseOrders(queryParams?: PurchaseOrdersQueryParams) {
    try {
      const { data } = await api.get(`/purchase-orders/list/awaiting-quote`, {
        params: {
          ...queryParams
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey ?? 'purchaseOrdersAwaitingQuote',
    {...options?.queryParams}
  ], () => getAwaitingQuotePurchaseOrders(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

import { Input, Td } from "@chakra-ui/react"
import { useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"

interface CreatePmcFormProps {
  fieldIndex: number
  product: {
    id: string
    standard_stock: number
    current_stock: number
    unit_cost: number
  }
}

export function CreatePmcForm({
  fieldIndex,
  product
}: CreatePmcFormProps) {

  const { register, setValue, formState: { errors } } = useFormContext()

  const purchaseQuantity = useWatch({
    name: `pmcs.${fieldIndex}.purchaseQuantity`
  })

  useEffect(() => {
    setValue(`pmcs.${fieldIndex}.productId`, product.id)
    const calcPurchaseQuantity = (product.standard_stock * 2) - product.current_stock

    setValue(`pmcs.${fieldIndex}.purchaseQuantity`, calcPurchaseQuantity < 0 ? 0 : calcPurchaseQuantity)

  }, [setValue, fieldIndex, product])

  useEffect(() => {
    setValue(`pmcs.${fieldIndex}.estimatedCost`, (purchaseQuantity * (product.unit_cost / 100)).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }))
  }, [setValue, fieldIndex, purchaseQuantity, product])

  return (
    <>
      <Td>
        <Input
          {...register(`pmcs.${fieldIndex}.purchaseQuantity`)}
          name={`pmcs.${fieldIndex}.purchaseQuantity`}
          size="sm"
          placeholder="Ex: 4"
          rounded="md"
          w="150px"
          focusBorderColor={
            (errors.pmcs && errors?.pmcs[fieldIndex]?.purchaseQuantity) && 'red.500'
          }
        />
      </Td>
      <Td>
        <Input
          {...register(`pmcs.${fieldIndex}.estimatedCost`)}
          name={`pmcs.${fieldIndex}.estimatedCost`}
          size="sm"
          rounded="md"
          w="150px"
          focusBorderColor={
            (errors.pmcs && errors?.pmcs[fieldIndex]?.estimatedCost) && 'red.500'
          }
        />
      </Td>
    </>
  )
}

import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { StandardizationStatus } from "api/standardizations/standardization";
import { useSearchParams } from "hooks/useSearchParams";
import { HTMLInputTypeAttribute, useState } from "react";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface StandardizationsReportFiltersSchema {
  status: StandardizationStatus | 'all'
  sendedAtStartDate: string
  sendedAtEndDate: string
}

interface StandardizationsReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: any[]
    filename: string
  }
}

export function StandardizationsReportFilters({
  csvProps
}: StandardizationsReportFiltersProps) {
  const [sendedAtStartDateInputType, setSendedAtStartDateInputType] = useState<HTMLInputTypeAttribute>('text')
  const [sendedAtEndDateInputType, setSendedAtEndDateInputType] = useState<HTMLInputTypeAttribute>('text')

  const searchParams = useSearchParams()

  const history = useHistory()

  const status = searchParams.get('status')
  const sendedAtStartDate = searchParams.get('sendedAtStartDate')
  const sendedAtEndDate = searchParams.get('sendedAtEndDate')

  const {register, handleSubmit } = useForm<StandardizationsReportFiltersSchema>({
    defaultValues: {
      status: status as StandardizationStatus | 'all' ?? undefined,
      sendedAtStartDate: sendedAtStartDate ?? '',
      sendedAtEndDate: sendedAtEndDate ?? ''
    }
  })

  function handleFilterStandardizationReport(values: StandardizationsReportFiltersSchema) {
    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    if (values.sendedAtStartDate) {
      searchParams.set('sendedAtStartDate', values.sendedAtStartDate)
    } else {
      searchParams.delete('sendedAtStartDate')
    }

    if (values.sendedAtEndDate) {
      searchParams.set('sendedAtEndDate', values.sendedAtEndDate)
    } else {
      searchParams.delete('sendedAtEndDate')
    }

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleFilterStandardizationReport)}
    >
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        gap="2"
      >
        <Select
          {...register("status")}
          name="status"
          placeholder="Selecione um status..."
          size="sm"
          rounded="md"
          w={{ base: "full", md: "200px" }}
        >
          <option value="all">Todos status</option>
          <option value="pending">Pendente</option>
          <option value="approved">Aprovado</option>
          <option value="reproved">Reprovado</option>
        </Select>

        <Input
          {...register("sendedAtStartDate")}
          placeholder="Data de envio (período inicial)"
          type={sendedAtStartDateInputType}
          onMouseEnter={() => setSendedAtStartDateInputType('date')}
          onMouseLeave={(event) => {
            if (!event.currentTarget.value) {
              setSendedAtStartDateInputType('text')
            }
          }}
          size="sm"
          rounded="md"
          w={{ base: "full", md: "200px" }}
        />

        <Input
          {...register("sendedAtEndDate")}
          placeholder="Data de envio (período final)"
          type={sendedAtEndDateInputType}
          onMouseEnter={() => setSendedAtEndDateInputType('date')}
          onMouseLeave={(event) => {
            if (!event.currentTarget.value) {
              setSendedAtEndDateInputType('text')
            }
          }}
          size="sm"
          rounded="md"
          w={{ base: "full", md: "200px" }}
        />
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
      </Flex>

    </Flex>
  )
}

import axios from "axios"
import { Address } from "hooks/address/dtos/Address"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface ValidateAddressRequestedChangeProps {
  params: {
    addressId: string
    addressRequestChangeId: string
  }
  body: {
    key: keyof Address
    status: 'approved' | 'reproved'
  }
}

export async function validateAddressRequestedChange({
  params,
  body
}: ValidateAddressRequestedChangeProps) {
  try {
    await api.put(
      `/address/${params.addressId}/changes/${params.addressRequestChangeId}`, body
    )
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

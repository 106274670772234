import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface BuyPurchaseOrderReqFunctionProps {
  providerId: string
  purchaseOrderId: string
  input: FormData
}

export async function buyPurchaseOrder({
  providerId,
  purchaseOrderId,
  input
}: BuyPurchaseOrderReqFunctionProps) {
  try {

    await api.patch(`/providers/${providerId}/purchase-orders/${purchaseOrderId}/buy`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


export function handleStepToFilterCollector(step: string) {
  switch (step) {
    case 'toValidateRequestedService':
      return false
    case 'toCollectService':
      return false
    case 'collectingService':
      return false
    case 'toBoardService':
      return false
    case 'boardingService':
      return false
    case 'toBoardValidate':
      return true
    case 'toAllocateService':
      return true
    case 'toAvailableService':
      return true
    case 'availableService':
      return true
    case 'toLandingService':
      return true
    case 'landingService':
      return true
    case 'toDeliveryService':
      return true
    case 'deliveringService':
      return true
    case 'finishedService':
      return false
    case 'unsuccessService':
      return false
    case 'cancelledService':
      return false
    default:
      return false
  }
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface ReceiveBillingParams {
  billingId: string
}


export async function receiveBilling({
  billingId,
}: ReceiveBillingParams) {
  try {
    await api.patch(`/billings/${billingId}/receive`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateSporadicBody {
  protocol: string
  applicant: string
  regionalId: string
  startDate: string
  finalDate: string
  vehicle: string
  serviceType: string
  reason: string
  costCenter: string
  requestEmailAttachmentId: string
  responsible: string
}

interface CreateSporadicProps {
  body: CreateSporadicBody
}

export async function createSporadic({
  body
}: CreateSporadicProps) {
  try {
    const response = await api.post(`/sporadics`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Aso, AsoStatus } from "./aso";

interface GetAsosReportParams {
  status?: AsoStatus | 'all'
  collaborator?: string
}

export interface GetAsosResponse {
  asos: Array<Pick<Aso, 'id' | 'due_date' | 'status' | 'collaborator_name'> & {
    regional: Regional & {
      responsible: User
    } | null
    attachment: Attachment | null
  }>
}

export async function getAsosReport({
  status,
  collaborator,
}: GetAsosReportParams) {
  try {
    const response = await api.get<GetAsosResponse>('/asos/report', {
      params: {
        status,
        collaborator,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

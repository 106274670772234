import { Box, FormControl, FormLabel, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { useCollectors } from "hooks/collector/useCollectors";
import { FormEvent } from "react";
import { useFormContext, useWatch, } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

const adjustmentsOptions = [
  'Carro',
  'Moto',
  'Carro e Moto',
]



export function AdjustmentRequestForm() {

  const { register, setValue, control, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const {
    data: collectorsResult,
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO',
    }
  })

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      isCollectorAdjustment: 'Se refere a reajuste de coletador?',
      collector: 'Selecione o coletador a que se refere',
      adjustmentRelatedTo: 'Reajuste referente a',
      adjustmentDescription: 'Favor descrever o reajuste (incluindo qual cidade e também se teve ajuste do valor da coleta adicional)',
      requestedAdjustmentDescription: 'Descrição do reajuste solicitado',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  const collectorAdjustment = useWatch({
    control,
    name: 'formData.isCollectorAdjustment.value',
  });

  const isCollectorAdjustment = collectorAdjustment === 'Sim'


  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.isCollectorAdjustment}>
            <FormLabel fontSize="sm">
              Se refere a reajuste de coletador?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.isCollectorAdjustment.value")}
              name="formData.isCollectorAdjustment.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('isCollectorAdjustment')}
            >

              <option value='Sim'>Sim</option>
              <option value='Não'>Não</option>

            </Select>
          </FormControl>
        </Stack>
        {isCollectorAdjustment && (
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.formData?.collector}>
              <FormLabel fontSize="sm">
                Coletador
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register("formData.collector.value")}
                name="formData.collector.value"
                placeholder="Selecione uma opção..."
                size='sm'
                rounded="md"
                onChangeCapture={handleInputChange('collector')}
              >
                {collectorsResult?.map((collector) => {
                  return <option key={collector.id} value={collector.trading_name}>{collector.trading_name}</option>
                })}
              </Select>
            </FormControl>
          </Stack>
        )}
      </Stack>
      {isCollectorAdjustment && (
        <>
          <FormControl isInvalid={!!errors.formData?.adjustmentRelatedTo} mt={3}>
            <FormLabel fontSize="sm">
              Reajuste referente a
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.adjustmentRelatedTo.value")}
              name="formData.adjustmentRelatedTo.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('adjustmentRelatedTo')}
            >
              {Object.entries(adjustmentsOptions).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl isInvalid={!!errors.formData?.adjustmentDescription} mt={3}>
            <FormLabel fontSize="sm">
              Favor descrever o reajuste (incluindo qual cidade e também se teve ajuste do valor da coleta adicional)
            </FormLabel>
            <Textarea
              {...register('formData.adjustmentDescription.value')}
              name='formData.adjustmentDescription.value'
              size='sm'
              rounded='md'
              onChangeCapture={handleInputChange('adjustmentDescription')} />
          </FormControl>
        </>
      )}

      {!isCollectorAdjustment && (
        <FormControl isInvalid={!!errors.formData?.requestedAdjustmentDescription} mt={3}>
          <FormLabel fontSize="sm">
            Descrição do reajuste solicitado
          </FormLabel>
          <Textarea
            {...register('formData.requestedAdjustmentDescription.value')}
            name='formData.requestedAdjustmentDescription.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('requestedAdjustmentDescription')}
          />
        </FormControl>
      )}

    </Box>
  )
}

import axios from "axios"
import { BudgetProps } from "contexts/BudgetContext"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { Billing } from "./billing"

interface GetBillingsParams {
  billingId: string
}


interface GetBillingResponse {
  billing: Billing & {
    customer: {
      trading_firstname: string
      billing_observation: string
    },
    service: {
      protocol: number
      serviceIDRequested: {
        budgetIDService: BudgetProps
      }
    }
  }
}

export async function getBilling({
  billingId
}: GetBillingsParams) {
  try {
    const response = await api.get<GetBillingResponse>(`/billings/${billingId}`)

    return response.data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Spinner, Stack, Table, Tbody } from "@chakra-ui/react";
import { getStandardization } from "api/standardizations/getStandardization";
import { Empty } from "components/Empty";

import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { FaFileImport } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AttachmentRow } from "./components/AttachmentRow";
import { FileUpload } from "./components/FileUpload";
import { StandardizationDetailTable } from "./components/StandardizationDetailTable";
import * as yup from "yup";
import { validateHasFile } from "utils/imageValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { correctStandardization } from "api/standardizations/correctStandardization";
import { useToastify } from "hooks/toastify/useToastify";
import { useAuth } from "hooks/auth/useAuth";

interface AttachmentUpload {
  [key: string]: {
    id: string
    link: string
    title: string
    progresss: number
  };
}

interface Attachments {
  attachment: FileList
}

interface CorrectStandardizationSchema {
  cnhPhotoId?: FileList
  crlvPhotoId?: FileList
  anttPhotoId?: FileList
  cleaningRegistersPhotosIds?: Attachments[]
  frontPhotoWithUniformAndBadgePhotoId?: FileList
  vehicleFrontPhotoId?: FileList
  vehicleLeftSidePhotoId?: FileList
  vehicleRightSidePhotoId?: FileList
  vehicleRearPhotoId?: FileList
  vehicleInternPhotoId?: FileList
  trunkLidOrCarWithEmergencyFormPhotoId?: FileList
  tiresPhotosIds?: Attachments[]
}

const correctStandardizationSchema = yup.object({
  cnhPhotoId: yup.mixed().when('$cnhStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  crlvPhotoId: yup.mixed().when('$crlvStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  anttPhotoId: yup.mixed().when('$motorFreightCertificateStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  cleaningRegistersPhotosIds: yup.mixed().when('$cleaningRegisterStatus', {
    is: 'reproved',
    then: yup.array().of(yup.object({
      attachment: yup.mixed().test('hasFile', 'Campo obrigatório.', value => validateHasFile(value))
    })).required()
  }),
  frontPhotoWithUniformAndBadgePhotoId: yup.mixed().when('$uniformAndBadgeStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  vehicleFrontPhotoId: yup.mixed().when('$stickersStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  vehicleLeftSidePhotoId: yup.mixed().when('$reflectiveStripStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  vehicleRightSidePhotoId: yup.mixed().when('$reflectiveStripStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  vehicleRearPhotoId: yup.mixed().when('$redPlateStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  vehicleInternPhotoId: yup.mixed().when('$vehicleVisualCleaningStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  trunkLidOrCarWithEmergencyFormPhotoId: yup.mixed().when('$vehicleEpiKitStatus', {
    is: 'reproved',
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  tiresPhotosIds: yup.mixed().when('$tiresConditionStatus', {
    is: 'reproved',
    then: yup.array().of(yup.object({
      attachment: yup.mixed().test('hasFile', 'Campo obrigatório.', value => validateHasFile(value))
    })).required()
  }),
})

interface Params {
  standardizationId: string
}

export function CorrectStandardization() {
  const { userLogged } = useAuth()
  const params = useParams<Params>()
  const history = useHistory()

  const {
    mutation: uploadAttachmentMutation,
    uploadProggress: uploadProggressAttachentPhoto
  } = useAttachmentMutation()


  const [attachments, setAttachments] = useState<AttachmentUpload[]>([]);

  const handleUploadAttachment = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name.replace(/\.\d+\.attachment$/, '');

    const formData = new FormData();
    formData.append('attachment', event.target.files[0]);

    try {
      const result = await uploadAttachmentMutation.mutateAsync(formData);

      const newAttachment = {
        id: result.attachment?.id || '',
        link: result.attachment?.link || '',
        title: result?.attachment?.title || '',
        progresss: uploadProggressAttachentPhoto,
      };


      setAttachments(prevAttachments => {
        if (fieldName.includes('cleaningRegistersPhotosIds') || fieldName === 'tiresPhotosIds') {
          return {
            ...prevAttachments,
            [fieldName]: [
              ...(prevAttachments[fieldName] || []),
              newAttachment
            ]
          };
        } else {

          return {
            ...prevAttachments,
            [fieldName]: newAttachment
          };
        }
      });

    } catch (error) {
      console.log(error)
    }
  };

  const {
    data: standardizationResult,
    isError,
    isLoading
  } = useQuery({
    queryKey: ['standardization', params.standardizationId],
    queryFn: () => getStandardization({ standardizationId: params.standardizationId }),
    refetchOnWindowFocus: false,
  })

  const userCanCorrectStandardization = userLogged?.permissions.includes('correct-standardization')

  useEffect(() => {
    if (standardizationResult) {
      if (standardizationResult?.standardization.status !== 'reproved' || !userCanCorrectStandardization) {
        history.push('/')
      }
    }
  }, [history, standardizationResult, userCanCorrectStandardization])

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(correctStandardizationSchema),
    context: {
      cnhStatus: standardizationResult?.standardization?.cnh_status,
      crlvStatus: standardizationResult?.standardization?.crlv_status,
      motorFreightCertificateStatus: standardizationResult?.standardization?.motor_freight_certificate_status,
      cleaningRegisterStatus: standardizationResult?.standardization?.cleaning_register_status,
      uniformAndBadgeStatus: standardizationResult?.standardization?.uniform_and_badge_status,
      stickersStatus: standardizationResult?.standardization?.stickers_status,
      reflectiveStripStatus: standardizationResult?.standardization?.reflective_strip_status,
      redPlateStatus: standardizationResult?.standardization?.red_plate_status,
      vehicleVisualCleaningStatus: standardizationResult?.standardization?.vehicle_visual_cleaning_status,
      vehicleEpiKitStatus: standardizationResult?.standardization?.vehicle_epi_kit_status,
      tiresConditionStatus: standardizationResult?.standardization?.tires_condition_status,
    }
  })

  const [
    cnhPhotoId,
    crlvPhotoId,
    anttPhotoId,
    frontPhotoWithUniformAndBadgePhotoId,
    vehicleFrontPhotoId,
    vehicleLeftSidePhotoId,
    vehicleRightSidePhotoId,
    vehicleRearPhotoId,
    vehicleInternPhotoId,
    trunkLidOrCarWithEmergencyFormPhotoId,
  ] = useWatch({
    control,
    name: [
      'cnhPhotoId',
      'crlvPhotoId',
      'anttPhotoId',
      'frontPhotoWithUniformAndBadgePhotoId',
      'vehicleFrontPhotoId',
      'vehicleLeftSidePhotoId',
      'vehicleRightSidePhotoId',
      'vehicleRearPhotoId',
      'vehicleInternPhotoId',
      'trunkLidOrCarWithEmergencyFormPhotoId',
    ]
  })

  const {
    fields: cleaningRegistersPhotosIdsFields,
    update: updateCleaningRegisterPhotosIdsFields

  } = useFieldArray({
    control,
    name: 'cleaningRegistersPhotosIds'
  })

  useEffect(() => {
    if (standardizationResult?.standardization?.cleaning_register_status === 'reproved') {
      if (['SÃO PAULO', 'MINAS GERAIS'].includes(standardizationResult?.standardization?.hub.name)) {
        Array.from({ length: 4 }).forEach((_, index) => updateCleaningRegisterPhotosIdsFields(index, { attachment: undefined }))
      } else {
        updateCleaningRegisterPhotosIdsFields(0, { attachment: undefined })
      }
    }
  }, [updateCleaningRegisterPhotosIdsFields, standardizationResult])

  const {
    fields: tiresPhotosIdsFields,
    update: updateTiresPhotosIdsFields
  } = useFieldArray({
    control,
    name: 'tiresPhotosIds'
  })

  useEffect(() => {
    if (standardizationResult?.standardization?.tires_condition_status === 'reproved') {
      if (standardizationResult?.standardization?.vehicle === 'Carro') {
        Array.from({ length: 4 }).forEach((_, index) => updateTiresPhotosIdsFields(index, { attachment: undefined }))
      } else {
        Array.from({ length: 2 }).forEach((_, index) => updateTiresPhotosIdsFields(index, { attachment: undefined }))
      }
    }
  }, [updateTiresPhotosIdsFields, standardizationResult])

  const handleReloadPage = () => window.location.reload()

  const { mutateAsync: createStandardizationFn } = useMutation({
    mutationFn: correctStandardization,
    onSuccess: () => history.push('/')
  })

  const { promiseMessage } = useToastify()

  async function handleCorrectStandardization(values: CorrectStandardizationSchema) {
    const cleaningRegistersPhotosAttachmentIds = attachments['cleaningRegistersPhotosIds']?.length
      ? attachments['cleaningRegistersPhotosIds']?.map(attachment => attachment.id)
      : undefined

    const tiresPhotosAttachmentIds = attachments['tiresPhotosIds']?.length
      ? attachments['tiresPhotosIds']?.map(attachment => attachment.id)
      : undefined

    await promiseMessage(createStandardizationFn({
      body: {
        cnhPhotoId: attachments['cnhPhotoId']?.id ?? undefined,
        crlvPhotoId: attachments['crlvPhotoId']?.id ?? undefined,
        anttPhotoId: attachments['anttPhotoId']?.id ?? undefined,
        cleaningRegistersPhotosIds: cleaningRegistersPhotosAttachmentIds,
        frontPhotoWithUniformAndBadgePhotoId: attachments['frontPhotoWithUniformAndBadgePhotoId']?.id ?? undefined,
        vehicleFrontPhotoId: attachments['vehicleFrontPhotoId']?.id ?? undefined,
        vehicleLeftSidePhotoId: attachments['vehicleLeftSidePhotoId']?.id ?? undefined,
        vehicleRightSidePhotoId: attachments['vehicleRightSidePhotoId']?.id ?? undefined,
        vehicleRearPhotoId: attachments['vehicleRearPhotoId']?.id ?? undefined,
        vehicleInternPhotoId: attachments['vehicleInternPhotoId']?.id ?? undefined,
        trunkLidOrCarWithEmergencyFormPhotoId: attachments['trunkLidOrCarWithEmergencyFormPhotoId']?.id ?? undefined,
        tiresPhotosIds: tiresPhotosAttachmentIds,
      },
      params: {
        standardizationId: params.standardizationId
      }
    }), 'Correção enviada!')
  }

  if (isError) {
    return (
      <Empty.Root>
        <Empty.ActionButton
          colorScheme="blue"
          onClick={handleReloadPage}
        >
          Recarregar a página
        </Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (

      <Box
        p="6"
        rounded="md"
        bg="white"
      >
        <Heading letterSpacing="tight" fontSize="2xl" mb="6">Corrigir padronização</Heading>
        {isLoading && <Spinner />}
        {standardizationResult && (
          <StandardizationDetailTable standardization={standardizationResult.standardization} />
        )}

        <Flex
          direction="column"
          mt="6"
          gap="6"
          as="form"
          onSubmit={handleSubmit(handleCorrectStandardization)}
        >
          {standardizationResult?.standardization?.cnh_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="CNH atual"
                fieldName="cnhPhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {cnhPhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(cnhPhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['cnhPhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.crlv_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="CRLV"
                fieldName="crlvPhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {crlvPhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(crlvPhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['crlvPhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.motor_freight_certificate_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="Curso de motofrete/ANTT"
                fieldName="anttPhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {anttPhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(anttPhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['anttPhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.cleaning_register_status === 'reproved' && (

            <Flex
              border="1px solid"
              borderColor="gray.200"
              p="4"
              rounded="lg"
              direction="column"
            >
              <Heading
                fontSize="md"
                letterSpacing="tight"
              >
                Anexe aqui seu registro de limpeza
              </Heading>

              <Box>
                {cleaningRegistersPhotosIdsFields.map((field, index) => {
                  const attachment = attachments['cleaningRegistersPhotosIds']?.[index];
                  return (
                    <FormControl mt="3" key={field.id} isInvalid={errors?.cleaningRegistersPhotosIds ? !!errors?.cleaningRegistersPhotosIds[index]?.attachment : undefined}>
                      <Flex gap="3">
                        <Stack
                          direction="column"
                          w="full"
                          mt="3"
                        >
                          <Button
                            as={FormLabel}
                            htmlFor={`cleaningRegistersPhotosIds.${index}.attachment`}
                            lineHeight="1"
                            leftIcon={<FaFileImport />}
                            size="sm"
                            w="min"
                            cursor="pointer"
                            border={!!errors?.cleaningRegistersPhotosIds && '2px solid'}
                            borderColor={(!!errors?.cleaningRegistersPhotosIds) && 'red.500'}
                          >
                            Anexe aqui seu registro de limpeza
                          </Button>
                          <FormControl isInvalid={!!errors?.cleaningRegistersPhotosIds}>
                            <Input
                              {...register(`cleaningRegistersPhotosIds.${index}.attachment`)}
                              name={`cleaningRegistersPhotosIds.${index}.attachment`}
                              id={`cleaningRegistersPhotosIds.${index}.attachment`}
                              type="file"
                              hidden
                              onChangeCapture={handleUploadAttachment}
                            />
                          </FormControl>

                        </Stack>
                      </Flex>
                      {attachment !== undefined && (
                        <Table size="sm">
                          <Tbody>
                            <AttachmentRow attachment={attachment} />
                          </Tbody>
                        </Table>
                      )}
                    </FormControl>
                  )
                })
                }
              </Box>
            </Flex>
          )}

          {standardizationResult?.standardization?.motor_freight_certificate_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="Foto frontal com uniforme e crachá"
                fieldName="frontPhotoWithUniformAndBadgePhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {frontPhotoWithUniformAndBadgePhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(frontPhotoWithUniformAndBadgePhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['frontPhotoWithUniformAndBadgePhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.stickers_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="Foto do veículo (frente)"
                fieldName="vehicleFrontPhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {vehicleFrontPhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(vehicleFrontPhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['vehicleFrontPhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.reflective_strip_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="Foto do veículo (lateral 1)"
                fieldName="vehicleLeftSidePhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {vehicleLeftSidePhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(vehicleLeftSidePhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['vehicleLeftSidePhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}

              <FileUpload<CorrectStandardizationSchema>
                label="Foto do veículo (lateral 2)"
                fieldName="vehicleRightSidePhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {vehicleRightSidePhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(vehicleRightSidePhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['vehicleRightSidePhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.red_plate_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="Foto do veículo (traseira com placa)"
                fieldName="vehicleRearPhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {vehicleRearPhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(vehicleRearPhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['vehicleRearPhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.vehicle_visual_cleaning_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="Foto do veículo (interna se carro, baú se moto - com KIT EPI)"
                fieldName="vehicleInternPhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {vehicleInternPhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(vehicleInternPhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['vehicleInternPhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.vehicle_epi_kit_status === 'reproved' && (
            <>
              <FileUpload<CorrectStandardizationSchema>
                label="Foto da tampa do baú ou carro com ficha de emergência"
                fieldName="trunkLidOrCarWithEmergencyFormPhotoId"
                register={register}
                errors={errors}
                handleUpload={handleUploadAttachment}
              />
              {trunkLidOrCarWithEmergencyFormPhotoId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(trunkLidOrCarWithEmergencyFormPhotoId).map(([key, file]) => {
                      return (
                        <AttachmentRow key={key} attachment={attachments['trunkLidOrCarWithEmergencyFormPhotoId']} />
                      );
                    })}
                  </Tbody>
                </Table>
              )}
            </>
          )}

          {standardizationResult?.standardization?.tires_condition_status === 'reproved' && (
            <Flex
              border="1px solid"
              borderColor="gray.200"
              p="4"
              rounded="lg"
              direction="column"
            >
              <Heading
                fontSize="md"
                letterSpacing="tight"
              >
                Foto dos pneus
              </Heading>
              <Box>
                {tiresPhotosIdsFields.map((field, index) => {
                  const attachment = attachments['tiresPhotosIds']?.[index];
                  return (
                    <FormControl mt="3" key={field.id} isInvalid={errors?.tiresPhotosIds ? !!errors?.tiresPhotosIds[index]?.attachment : undefined}>
                      <Flex gap="3">
                        <Stack
                          direction="column"
                          w="full"
                          mt="3"
                        >
                          <Button
                            as={FormLabel}
                            htmlFor={`tiresPhotosIds.${index}.attachment`}
                            lineHeight="1"
                            leftIcon={<FaFileImport />}
                            size="sm"
                            w="min"
                            cursor="pointer"
                            border={!!errors?.tiresPhotosIds && '2px solid'}
                            borderColor={(!!errors?.tiresPhotosIds) && 'red.500'}
                          >
                            Foto dos pneus
                          </Button>
                          <FormControl isInvalid={!!errors?.tiresPhotosIds}>
                            <Input
                              {...register(`tiresPhotosIds.${index}.attachment`)}
                              name={`tiresPhotosIds.${index}.attachment`}
                              id={`tiresPhotosIds.${index}.attachment`}
                              type="file"
                              hidden
                              onChangeCapture={handleUploadAttachment}
                            />
                          </FormControl>
                        </Stack>

                      </Flex>
                      {attachment !== undefined && (
                        <Table size="sm">
                          <Tbody>
                            <AttachmentRow attachment={attachment} />
                          </Tbody>
                        </Table>
                      )}
                    </FormControl>
                  )
                })
                }
              </Box>
            </Flex>
          )}

          <Button
            type="submit"
            alignSelf="end"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Enviar
          </Button>
        </Flex>


      </Box>

  )
}

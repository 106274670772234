import { BudgetProps } from '../../../contexts/BudgetContext'
import { api } from '../../api'

interface GetAllBudgetsQueryParams {
  customer_id?: string;
}

export const getAllBudgets = ({ customer_id }: GetAllBudgetsQueryParams) =>
  api.get<BudgetProps[]>('/budget', {
    params: {
      customer_id
    }
  }).then((res) => res.data)
export const getOneBudget = (budgetId: string) =>
  api.get<BudgetProps>(`/budget/${budgetId}`).then((res) => res.data)

import { Box, Button, Flex, FormControl, FormLabel, IconButton, Input, Link, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { createTrainingLlmCsv } from "api/trainingLlm/createTrainingLlmCsv"
import { useToastify } from "hooks/toastify/useToastify"
import { useFormContext, useWatch } from "react-hook-form"
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"
import { CreateTrainingLlmSchema } from "./CreateTrainingLlm"

export function CreateTrainingLlmCsvForm() {
  const { promiseMessage } = useToastify()
  const { register, control, handleSubmit, formState: { errors, isSubmitting } } = useFormContext<CreateTrainingLlmSchema>()

  const csvAttachment = useWatch({
    control,
    name: 'csv'
  })

  const attachmentCsvPreview = csvAttachment
    ? Object.entries(csvAttachment).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const queryClient = useQueryClient()

  const { mutateAsync: createTrainingLlmCsvFn } = useMutation({
    mutationFn: createTrainingLlmCsv,
    onSuccess() {
      queryClient.invalidateQueries(['trainingsLlm'])
      queryClient.invalidateQueries(['pending-trainings-llm'])
    }
  })

  async function handleCreateTrainingLlm({ csv }: CreateTrainingLlmSchema) {
    const formData = new FormData()
    formData.append("csv", csv[0])

    await promiseMessage(createTrainingLlmCsvFn({
      body: formData
    }), 'Treinamento LLM solicitado! 🎉')
  }

  return (
    <Box
      as='form'
      onSubmit={handleSubmit(handleCreateTrainingLlm)}
    >
      <Stack
        direction="column"
        w="full"
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors?.csv}>
            <FormLabel fontSize="sm">
              Arquivo csv
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('csv')}
              name="csv"
              id="csv"
              type="file"
              hidden
              accept=".csv"

            />
          </FormControl>
          <Button
            as={FormLabel}
            htmlFor="csv"
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={!!errors?.csv && '2px solid'}
            borderColor={!!errors?.csv && 'red.500'}
          >
            Anexar csv
          </Button>
          {attachmentCsvPreview && (
            <Table size="sm">
              <Tbody>

                <Tr>
                  <Td fontSize="xs" maxW="100px">{csvAttachment[0].name}</Td>

                  <Td fontSize="xs" isNumeric>

                    <IconButton
                      aria-label="Visualizar anexo"
                      as={Link}
                      size="sm"
                      icon={<FaExternalLinkAlt />}
                      href={URL.createObjectURL(csvAttachment[0])}
                      isExternal
                    />

                  </Td>
                </Tr>


              </Tbody>
            </Table>
          )}
        </Stack>
      </Stack>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Criar
        </Button>
      </Flex>
    </Box>
  )
}

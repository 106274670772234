import { useEffect, useState } from "react";
import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, Icon, Modal, ModalOverlay, Spinner, Stack, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBox } from "components/SearchBox/SearchBox";
import { Address } from "hooks/address/dtos/Address";
import { useAddresses } from "hooks/address/useAddresses";
import { useAddressesFunctions } from "hooks/address/useAddressesFunctions";
import { useAddressesPaginated } from "hooks/address/useAddressesPagination";
import { useAuth } from "hooks/auth/useAuth";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { useSwal } from "hooks/swal/useSwal";
import { useForm, useWatch } from "react-hook-form";
import { FaExchangeAlt, FaEye, FaPen, FaPlus, FaTrash } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { CreateAddressCsv } from "./components/CreateAddressCsv";


interface AddressFilterOptionsInputs {
  branch: string
  trading_name: string
  customer: string
  city: string
}

const addressFilterOptionsInputs = [
  { key: 'branch', value: 'Setor/Unidade', checked: false },
  { key: 'trading_name', value: 'Nome fantasia', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'city', value: 'Cidade', checked: false },
]

const addressOrderOptions = [
  { key: 'trading_name', value: 'Nome fantasia', checked: false },
  { key: 'city', value: 'Cidade', checked: false }
]


function formatValuesToReport(addresses: Address[]) {
  return addresses?.map(address => {
    return {
      'CLIENTE': address.customerIDAddress.trading_firstname,
      'TIPO DE PESSOA': address.type,
      'CPF/CNPJ': address.cnpj_cpf,
      'NOME FANTASIA': address.trading_name,
      'SETOR': address.branch,
      'RESPONSÁVEL': address.responsible_name,
      'EMAIL DO RESPONSÁVEL': address.responsible_email ?? '-',
      'TELEFONE DO RESPONSÁVEL': address.responsible_telephone ?? '-',
      'CEP': address.cep,
      'ESTADO': address.state,
      'CIDADE': address.cityIDAddress.name,
      'RUA': address.street,
      'NÚMERO': address.number,
      'BAIRRO': address.neighborhood,
      'COMPLEMENTO': address.complement ?? '-',
      'PONTO DE REFERÊNCIA': address.reference_point ?? '-',
      'ICMS': address.icms,
      'HORÁRIO DE ABERTURA (SEGUNDA A SEXTA)': formatDate.handle(address.business_open, "DateOnlyWithHourMinute"),
      'HORÁRIO DE FECHAMENTO (SEGUNDA A SEXTA)': formatDate.handle(address.business_close, "DateOnlyWithHourMinute"),
      'HORÁRIO DE ABERTURA (SÁBADOS)': address.saturday_open ? formatDate.handle(address.saturday_open, "DateOnlyWithHourMinute") : '-',
      'HORÁRIO DE FECHAMENTO (SÁBADOS)': address.saturday_close ? formatDate.handle(address.saturday_close, "DateOnlyWithHourMinute") : '-',
      'HORÁRIO DE ABERTURA (DOMINGOS)': address.sunday_open ? formatDate.handle(address.sunday_open, "DateOnlyWithHourMinute") : '-',
      'HORÁRIO DE FECHAMENTO (DOMINGOS)': address.sunday_close ? formatDate.handle(address.sunday_close, "DateOnlyWithHourMinute") : '-',
    }
  })
}

export function AddressesTable() {
  const [paginationTotalPages, setPaginationTotalPages] = useState(0)
  const [cityFiltered, setCityFiltered] = useState(null)
  const [branchFiltered, setBranchFiltered] = useState(null)
  const [customerFiltered, setCustomerFiltered] = useState(null)
  const [tradingNameFiltered, setTradingNameFiltered] = useState(null)
  const [isRequestedAddressesCsv, setIsRequestedAddressesCsv] = useState(false)

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const {
    register,
    control,
  } = useForm<AddressFilterOptionsInputs>()

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()

  const transformAddressData = (addresses: Address[]) => {
    return addresses.map(address => {
      return {
        ...address,
        trading_name: address.trading_name || '-'
      }
    })
  }

  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: paginationTotalPages,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  const branch = useWatch({
    control,
    name: 'branch'
  })
  const customer = useWatch({
    control,
    name: 'customer'
  })
  const tradingName = useWatch({
    control,
    name: 'trading_name'
  })
  const city = useWatch({
    control,
    name: 'city'
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentPage(1)
      setBranchFiltered(() => branch)
      setCustomerFiltered(() => customer)
      setCityFiltered(() => city)
      setTradingNameFiltered(() => tradingName)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [branch, customer, city, tradingName, setCurrentPage])

  const { data: addressesPaginated, isFetching: isAddressesPaginatedFetching } = useAddressesPaginated({
    queryParams: {
      customer_id: userLogged?.customer_id,
      currentPage: currentPage,
      pageSize: Number(itemLimit),
      branch: branchFiltered,
      city: cityFiltered,
      customer: customerFiltered,
      trading_name: tradingNameFiltered,
      order_by: orderOptionSelected?.length ? orderOptionSelected.join(',') : null
    },
    queryOptions: {
      cacheTime: 0,
      keepPreviousData: true,
      select: (response) => {
        if (response) {
          response.data = transformAddressData(response?.data as Address[])
          return response
        }
      },
      onSuccess: response => setPaginationTotalPages(response.totalPages)
    }
  })

  const { deleteAddress: { mutateAsync: deleteAddress } } = useAddressesFunctions()

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(addressOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(addressFilterOptionsInputs)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleRemoveAddress(address_id: string) {
    const hasRemoveAddress = await confirmMessage({ title: "Deseja excluir um endereço?" })

    if (hasRemoveAddress) {
      await deleteAddress(address_id)
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const handleRequestGenerateAddressesCsv = () => {
    return setIsRequestedAddressesCsv(isRequestedAddressesCsv => !isRequestedAddressesCsv)
  }


  const { data: addresses, isFetching: isAddressesFetching } = useAddresses({
    queryOptions: { enabled: isRequestedAddressesCsv }
  })


  const addressCsvReportHeaders = addresses && addresses?.length
    ? Object.keys(formatValuesToReport(addresses)[0])?.map(key => {
      return { label: key, key }
    })
    : [{ label: '', key: '' }]



  const csvReportProps = {
    data: addresses ? formatValuesToReport(addresses) : [],
    headers: addressCsvReportHeaders,
    filename: `relatório-endereços`,
  }

  // const [
  //   branchFiltered,
  //   tradingNameFiltered,
  //   customerFiltered,
  //   cityFiltered
  // ] = watch(["branch", "trading_name", "customer", "city"])



  // const addressesFilteredByUserInput = addresses?.filter(address => {
  //   const branchFilter = branchFiltered
  //     ? searchBoxFilter(address.branch, branchFiltered)
  //     : address.branch

  //   const tradingNameFilter = tradingNameFiltered
  //     ? searchBoxFilter(address.trading_name, tradingNameFiltered)
  //     : address.trading_name

  //   const customerFilter = customerFiltered
  //     ? searchBoxFilter(address.customerIDAddress.trading_firstname, customerFiltered)
  //     : address.customerIDAddress.trading_firstname

  //   const cityFilter = cityFiltered
  //     ? searchBoxFilter(address.cityIDAddress.name, cityFiltered)
  //     : address.cityIDAddress.name

  //   return branchFilter && tradingNameFilter && customerFilter && cityFilter
  // })

  // const addressesFilteredOrdered = addressesFilteredByUserInput?.sort((a, b) => {
  //   const tradingNameSort = orderOptionSelected?.includes("trading_name")
  //     ? a.trading_name.localeCompare(b.trading_name)
  //     : 0

  //   const cityNameSort = orderOptionSelected?.includes("city")
  //     ? a.cityIDAddress.name.localeCompare(b.cityIDAddress.name)
  //     : 0

  //   return tradingNameSort || cityNameSort
  // })

  const userHasCreateAddressPermission = userLogged?.permissions.includes("add-address")
  const userHasViewAddressPermission = userLogged?.permissions.includes("view-address")
  const userHasEditAddressPermission = userLogged?.permissions.includes("edit-address")
  const userHasRemoveAddressPermission = userLogged?.permissions.includes("remove-address")
  const userLoggedHasPermissionToRequestChangesAddress =
    userLogged?.permissions.includes('request-address-changes') && userLogged?.user_type === 'CLIENTE'

  const addressesActionButtons = [
    { icon: FaEye, action: 'view', show: userHasViewAddressPermission, href: "/endereco/visualizar/" },
    { icon: FaPen, action: 'edit', show: userHasEditAddressPermission, href: "/endereco/editar/" },
    { icon: FaExchangeAlt, action: 'request-changes', show: userLoggedHasPermissionToRequestChangesAddress, href: "/endereco/solicitar/alteracoes/" },
    { icon: FaTrash, action: 'remove', show: userHasRemoveAddressPermission },
  ]

  const {
    isOpen: isCreateAddressCsvModalOpen,
    onOpen: onOpenCreateAddressCsvModal,
    onClose: onCloseCreateAddressCsvModal
  } = useDisclosure()

  return (

    <Flex
      align="center"
      direction="column"
      bg="white"
      padding={[4, 8]}
      gap={6}
      borderRadius="8px"
      h="full"
    >
      <Flex
        align="center"
        gap={6}
        justify={["center", "center", "space-between"]}
        direction={["column", "column", "row"]}
        flex="1"
        w="full"
      >
        <Heading alignSelf="flex-start" size="lg">Endereços</Heading>
        {userHasCreateAddressPermission && (
          <Flex gap='2'>
            {!isAddressesPaginatedFetching && (
              <Button
                w={["full", "full", "min"]}
                leftIcon={<FaPlus />}
                size="sm"
                colorScheme='gray'
                onClick={onOpenCreateAddressCsvModal}
              >
                Adicionar endereços csv
              </Button>
            )}
            <Button
              as={Link}
              size="sm"
              w={["full", "full", "min"]}
              colorScheme="gray"
              to="/endereco/adicionar"
              leftIcon={<Icon as={FiPlus} />}
            >
              Adicionar Endereço
            </Button>

          </Flex>
        )}
      </Flex>

      <Modal
        isOpen={isCreateAddressCsvModalOpen}
        onClose={onCloseCreateAddressCsvModal}
        isCentered
      >
        <ModalOverlay />
        <CreateAddressCsv
          onCloseModal={onCloseCreateAddressCsvModal}
          addresses={addressesPaginated?.data}
        />

      </Modal>

      <Flex
        w="full"
        justify="space-between"
        align="center"
        gap={6}
        direction={["column", "column", "row"]}
      >
        <Box alignSelf="flex-start">
          <TableFilterButton />
        </Box>
        <Stack w={["full", "full", "min"]} spacing={4}>
          {filterOptions
            .filter(option => option.checked === true)
            .map(option => (
              <SearchBox
                {...register(option.key as keyof AddressFilterOptionsInputs)}
                key={option.key}
                size="sm"
                placeholder={'Buscar ' + option.value}
                handleSearch={() => { }}
              />
            ))}
        </Stack>
      </Flex>
      {isAddressesPaginatedFetching && (
        <Flex alignSelf="flex-start">
          <Spinner />
        </Flex>
      )}

      {!addressesPaginated?.data?.length ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <Flex overflow="auto" w="full">
          <Table size="sm" variant="striped">
            <Thead>
              <Tr>
                {addressFilterOptionsInputs.map(option => {
                  return (
                    <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                  )
                })}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {addressesPaginated?.data?.map(address => {
                return (
                  <Tr key={address.id}>
                    <Td>{address.branch}</Td>
                    <Td>{address.trading_name}</Td>
                    <Td>{address.customerIDAddress.trading_firstname}</Td>
                    <Td>{address.cityIDAddress.name}</Td>
                    <Td isNumeric>
                      <Flex
                        justify="flex-end"
                        direction={["column", "column", "row"]}
                        gap={2}
                      >
                        {addressesActionButtons.map(iconButton => {
                          if (iconButton.href) {
                            return (
                              <Box
                                key={iconButton.action}
                                as={Link}
                                hidden={!iconButton.show}
                                to={iconButton.href + address.id}
                              >
                                <Icon
                                  cursor="pointer"
                                  fontSize="20"
                                  as={iconButton.icon}
                                />
                              </Box>
                            )
                          }

                          return (
                            <Box
                              key={iconButton.action}
                              hidden={!iconButton.show}
                            >
                              <Icon
                                cursor="pointer"
                                fontSize="20"
                                as={iconButton.icon}
                                onClick={async () => await handleRemoveAddress(address.id)}
                              />
                            </Box>
                          )
                        })}
                      </Flex>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Flex>
      )}

      <Flex alignSelf="flex-end" justifySelf="flex-end" w="full">
        {!isRequestedAddressesCsv ? (
          <Button
            colorScheme="whatsapp"
            onClick={handleRequestGenerateAddressesCsv}
          >
            Gerar Csv
          </Button>
        ) : (
          <GenerateExcelReportButton
            csvReportProps={csvReportProps}
            isLoading={isAddressesFetching}
            onClick={handleRequestGenerateAddressesCsv}
          />
        )}
      </Flex>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handlePageChange}
      />
    </Flex>

  )
}

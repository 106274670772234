import { Box, Flex, Skeleton, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { getDriverSchedulingsByDate } from "api/drivers/getDriverSchedulingsByDate";
import { addHours, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAuth } from "hooks/auth/useAuth";
import { Fragment } from "react";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";

interface DateSchedulingsProps {
  date: Date
}

interface Schedule {
  [key: string]: {
    date: string,
    scheduleId: string
    service: {
      protocol: number
      id: string
    }
    driver: {
      id: string
      firstname: string
      lastname: string
    }
    tradingName: string
  }[]
}

export function DateSchedulings({ date }: DateSchedulingsProps) {
  const { userLogged } = useAuth()

  const {
    data: schedulingsResult,
    isLoading: isLoadingSchedulingsResult
  } = useQuery({
    queryKey: ['drivers-schedulings-by-date', date],
    queryFn: () => getDriverSchedulingsByDate({
      queryParams: {
        date,
        timezoneOffset: new Date().getTimezoneOffset() / 60,
        customerId: userLogged?.customer_id
      }
    }),
    refetchOnWindowFocus: false
  })

  const schedulings = schedulingsResult?.driverSchedulings?.sort((a, b) => new Date(a.date).getMilliseconds() - new Date(b.date).getMilliseconds())?.reduce<Schedule>((schedules, curr) => {
    if (!schedules[curr.customer.trading_firstname]) {
      schedules[curr.customer.trading_firstname] = []
    }

    schedules[curr.customer.trading_firstname].push({
      scheduleId: curr.id,
      date: curr.date,
      tradingName: curr.customer.trading_firstname,
      service: curr.service,
      driver: curr.driver
    })

    return schedules
  }, {})

  const weekDay = format(new Date(date), 'eeee', { locale: ptBR })
  const monthDate = format(new Date(date), 'dd', { locale: ptBR })
  const month = format(new Date(date), 'MMMM', { locale: ptBR })

  if (isLoadingSchedulingsResult) {
    return (
      <Box
        rounded="md"
        w="full"
        p="2"
        h="full"
        maxH={{ base: "500px", lg: "852px"}}
        overflowY="scroll"
      >
        <Skeleton h="24px" w="200px" />

        <Flex
          gap={4}
          direction="column"
          mt={4}
        >
          {Array.from({ length: 5 }).map((_, index) => {
            return (
              <Fragment key={index.toString()}>
                <Skeleton
                  w="200px"
                  h="16px"
                  py={1}
                />
                <Flex
                  gap="3"
                  direction="column"
                >
                  <Skeleton
                    w="full"
                    h="24px"
                    py={2}
                  />
                  <Skeleton
                    w="full"
                    h="24px"
                    py={2}
                  />
                  <Skeleton
                    w="full"
                    h="24px"
                    py={2}
                  />
                  <Skeleton
                    w="full"
                    h="24px"
                    py={2}
                  />
                </Flex>
              </Fragment>
            )
          })}
        </Flex>

      </Box>
    )
  }


  return (
    <Box
      rounded="md"
      w="full"
      p="2"
      h="full"
      overflowY="scroll"
    >
      <Text>
        <Text as="b">{captalize(weekDay)}</Text> {' '}
        {`${monthDate} de ${captalize(month)}`}
      </Text>

      {schedulings && (
        Object?.entries(schedulings)?.map(([key, schedules]) => {
          return (
            <Flex
              direction="column"
              mt={4}
              key={key}
            >
              <Table size="sm">
                <TableCaption>Cliente: {captalize(key)}</TableCaption>
                <Thead>
                  <Tr>
                    <Th fontSize="xs">Motorista</Th>
                    <Th fontSize="xs">Protocolo</Th>
                    <Th fontSize="xs" isNumeric>Horário</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {schedules.map((schedule) => {
                    return (
                      <Tr key={schedule.scheduleId}>
                        <Td>{captalize(`${schedule.driver.firstname} ${schedule.driver.lastname}`)}</Td>
                        <Td>{schedule.service.protocol}</Td>
                        <Td isNumeric>{format(new Date(schedule.date), 'HH:mm')} - {format(addHours(new Date(schedule.date), 1), 'HH:mm')}</Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </Flex>
          )
        })
      )}
    </Box>
  )
}

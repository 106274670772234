import { Accordion } from '@chakra-ui/react'

import { InformationAccordion } from '../InformationAccordion/InformationAccordion'
import BranchAccordion from '../BranchAccordion/BranchAccordion'
import { ServiceProps } from '../../../../contexts/ServiceContext'

interface LandingAccordionProps {
  serviceByID: ServiceProps
  branchId: string
}

export function LandingAccordion({
  serviceByID,
  branchId,
}: LandingAccordionProps) {
  return (
    <Accordion allowMultiple>
      <InformationAccordion serviceByID={serviceByID} />
      <BranchAccordion serviceByID={serviceByID} branchId={branchId} />
    </Accordion>
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetGollogTrackingProps {
  queryParams: {
    values: string
  }
}

interface GetGollogTrackingResponse {
  result: {
    events: {
      date: string
      code: string
      station: string
      codeDescription: string
      message: string
    }[]
    lastStatus: {
      description: string
    }
  }[]
}

export async function getGollogTracking({
  queryParams
}: GetGollogTrackingProps) {
  try {
    const { data } = await api.get<GetGollogTrackingResponse>('/trackers/gollog', {
      params: { ...queryParams }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, TableContainer, Tbody, Td, Tr, Text, Spinner } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { useToastify } from "hooks/toastify/useToastify"
import { Radio } from "components/Inputs/RadioInput"
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { validatePurchaseOrder } from "api/purchaseOrders/validatePurchaseOrder"
import { GetPurchaseOrdersResponse } from "api/purchaseOrders/getPurchaseOrders"
import { getPurchaseOrder } from "api/purchaseOrders/getPurchaseOrder"

interface ValidatePurchaseProps {
  purchaseOrderId: string
  onCloseModal: () => void
  isProductForm: boolean
}

export interface ValidatePurchaseOrderFormInputs {
  isFinishedCorrectly: 'yes' | 'no'
  hasRequestedFinancialPayment: 'yes' | 'no'
  hasConferredEquity: 'yes' | 'no'
}

const validatePurchaseOrderSchema = yup.object().shape({
  isFinishedCorrectly: yup.mixed().required('Campo obrigatório'),
  hasRequestedFinancialPayment: yup.mixed().required('Campo obrigatório'),
  hasConferredEquity: yup.mixed().required('Campo obrigatório'),
})

export function ValidatePurchaseOrder({
  purchaseOrderId,
  isProductForm,
  onCloseModal
}: ValidatePurchaseProps) {
  const { promiseMessage } = useToastify()

  const { data: purchaseOrderData, isLoading: isPurchaseOrderDataLoading } = useQuery({
    queryKey: ['purchaseOrder', purchaseOrderId],
    queryFn: () => getPurchaseOrder({ purchaseOrderId })
  })

  const purchaseOrder = purchaseOrderData?.purchaseOrder
  const isPurchaseOrderTypePmc = purchaseOrder?.purchase_description === 'PMC'

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ValidatePurchaseOrderFormInputs>({
    resolver: yupResolver(validatePurchaseOrderSchema)
  })

  const labels = isProductForm
    ? {
      isFinishedCorrectlyLabel: 'Produto entregue corretamente?',
      purchaseTypeAttachment: 'NF do produto',
    }
    : {
      isFinishedCorrectlyLabel: 'Serviço finalizado corretamente?',
      purchaseTypeAttachment: 'RPA do serviço',
    }

  const queryClient = useQueryClient()

  const { mutateAsync: validatePurchaseOrderFn } = useMutation({
    mutationFn: validatePurchaseOrder,
    onSuccess(_data, { input, purchaseOrderId }) {
      const cachedPurchaseOrders = queryClient.getQueriesData<GetPurchaseOrdersResponse>({
        queryKey: ['purchaseOrders']
      })
      queryClient.invalidateQueries(['purchaseOrders'])
      cachedPurchaseOrders.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          purchaseOrders: cachedData.purchaseOrders.map((purchaseOrder) => {
            if (purchaseOrder.id === purchaseOrderId) {
              return {
                ...purchaseOrder,
              }
            }

            return purchaseOrder
          })
        })
      })

      onCloseModal()
    }
  })


  async function handleValidatePurchaseOrder(values: ValidatePurchaseOrderFormInputs) {
    await promiseMessage(validatePurchaseOrderFn({
      purchaseOrderId,
      input: values
    }), 'Compra validada com sucesso! 🎉')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Validar Compra</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as='form'
        onSubmit={handleSubmit(handleValidatePurchaseOrder)}
        maxH='600px'
        overflowY='scroll'
      >
        {isPurchaseOrderDataLoading ? (
          <Spinner />
        ) : (
          <TableContainer>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Protocolo</Td>
                  <Td isNumeric>{purchaseOrder.protocol}</Td>
                </Tr>
                <Tr>
                  <Td>Tipo</Td>
                  <Td isNumeric>{purchaseOrder.type}</Td>
                </Tr>
                <Tr>
                  <Td>Descrição da compra</Td>
                  <Td isNumeric>{purchaseOrder?.purchase_description}</Td>
                </Tr>

                {!isPurchaseOrderTypePmc && (
                  <Tr>
                    <Td>Motivo da compra</Td>
                    <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                      <Text w='220px' whiteSpace="pre-line">{purchaseOrder?.purchase_reason}</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Flex mt='4' direction="column" gap={6} align="center">
          <Radio
            {...register('isFinishedCorrectly')}
            name='isFinishedCorrectly'
            error={errors.isFinishedCorrectly}
            radioOptions={positiveNegativeOptions}
            label={labels.isFinishedCorrectlyLabel}
            placeholder='Selecione uma opção...'
            required
          />
          <Radio
            {...register('hasRequestedFinancialPayment')}
            name='hasRequestedFinancialPayment'
            error={errors.hasRequestedFinancialPayment}
            radioOptions={positiveNegativeOptions}
            label='Solicitado pagamento ao financeiro?'
            placeholder='Selecione uma opção...'
            required
          />
          <Radio
            {...register('hasConferredEquity')}
            name='hasConferredEquity'
            error={errors.hasConferredEquity}
            radioOptions={positiveNegativeOptions}
            label='Conferiu se patrimonio deu entrada no controle de patrimonio?'
            placeholder='Selecione uma opção...'
            required
          />

        </Flex>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Validar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

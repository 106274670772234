import { Button, Flex, FormLabel, Icon, Stack, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Radio } from "components/Inputs/RadioInput";
import { Select } from "components/Inputs/SelectInput";
import { CustomerProps } from "contexts/CustomerContext";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { useCurrency } from "react-hook-currency";
import { useFormContext, useWatch } from "react-hook-form";
import { FiFile } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";

export interface BillingsFormInputs {
  startDate: string
  endDate: string
  dueDate: string
  value: string
  autoSendEmail: string
  paymentSlips: FileList
  invoices: FileList
  attachments: FileList
  billingObservations: string
  customerId: string
}

interface BillingsFormProps {
  pageType?: 'bill' | 'view' | 'edit'
  defaultAutoSendEmailCheckedOption?: string
  isUniqueBilling?: boolean
  customers: CustomerProps[]
}

export function BillingsForm({
  pageType = 'bill',
  defaultAutoSendEmailCheckedOption,
  isUniqueBilling = false,
  customers
}: BillingsFormProps) {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const userLoggedHasPermissionToBillBilling =
    userLogged?.permissions.includes('bill-billing')

  useEffect(() => {
    if (!userLoggedHasPermissionToBillBilling) {
      redirect('/')
    }
  }, [userLoggedHasPermissionToBillBilling, redirect])

  const {
    control,
    register,
    formState: { errors }
  } = useFormContext<BillingsFormInputs>()

  const [
    paymentSlip,
    invoices,
    attachments
  ] = useWatch({
    control,
    name: ['paymentSlips', 'invoices', 'attachments'],
  })

  const { onChange, format: currencyFormat } = useCurrency({
    style: 'decimal'
  })

  const isBillOrEditPageType = pageType === 'bill' || pageType === 'edit'

  const customersSelectOptions = customers?.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  return (
    <Flex direction='column' gap={4}>
      <Stack spacing={4} direction={['column', 'column', 'row']}>
        <Select
          {...register('customerId')}
          name='customerId'
          options={customersSelectOptions}
          label='Cliente'
          placeholder='Selecione uma opção...'
          error={errors.customerId}
          isDisabled={!isBillOrEditPageType}
          required
        />
      </Stack>
      <Stack spacing={4} direction={['column', 'column', 'row']}>
        <Input
          {...register('startDate')}
          name='startDate'
          label='Período inicial do faturamento'
          type='date'
          error={errors.startDate}
          isDisabled={!isBillOrEditPageType}
          required
        />
        <Input
          {...register('endDate')}
          name='endDate'
          label='Período final do faturamento'
          type='date'
          error={errors.endDate}
          isDisabled={!isBillOrEditPageType}
          required
        />
      </Stack>

      <Input
        {...register('value')}
        name='value'
        label='Valor da fatura'
        onChange={onChange}
        defaultValue={currencyFormat('000')}
        error={errors.value}
        isDisabled={!isBillOrEditPageType}
        required
      />

      <Input
        {...register('dueDate')}
        name='dueDate'
        label='Data de vencimento'
        type='date'
        error={errors.dueDate}
        isDisabled={!isBillOrEditPageType}
        required
      />

      <Radio
        {...register('autoSendEmail')}
        name='autoSendEmail'
        radioOptions={positiveNegativeOptions}
        label='Enviar e-mail automaticamente?'
        error={errors.autoSendEmail}
        defaultCheckedOption={defaultAutoSendEmailCheckedOption}
        isDisabled={!isBillOrEditPageType}
        required
      />
      {isBillOrEditPageType && (
        <>
          <Input
            {...register('paymentSlips')}
            name='paymentSlips'
            type='file'
            multiple
            error={errors.paymentSlips}
            hidden
            label='Anexo boleto (Não obrigatório)'
          >
            <Button
              leftIcon={<Icon as={FiFile} />}
              as={FormLabel}
              htmlFor='paymentSlips'
              _hover={{
                cursor: 'pointer'
              }}
              size='sm'
            >
              Escolha um arquivo
            </Button>
          </Input>

          {paymentSlip && (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Anexo Boleto</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(paymentSlip).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Th >{file.name} </Th>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}

          <Input
            {...register('invoices')}
            name='invoices'
            type='file'
            multiple
            hidden
            label='Anexo fatura'
            error={errors.invoices}
            required={!isUniqueBilling}
          >
            <Button
              leftIcon={<Icon as={FiFile} />}
              as={FormLabel}
              htmlFor='invoices'
              _hover={{
                cursor: 'pointer'
              }}
              size='sm'
            >
              Escolha um arquivo
            </Button>
          </Input>

          {invoices && (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Anexo Fatura</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(invoices).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Th >{file.name} </Th>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}

          <Input
            {...register('attachments')}
            name='attachments'
            type='file'
            multiple
            hidden
            label='Anexar'
            error={errors.attachments}
            required={!isUniqueBilling}
          >
            <Button
              leftIcon={<Icon as={FiFile} />}
              as={FormLabel}
              htmlFor='attachments'
              _hover={{
                cursor: 'pointer'
              }}
              size='sm'
            >
              Escolha um arquivo
            </Button>
          </Input>

          {attachments && (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Anexo Fatura</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(attachments).map(([key, file]) => {
                    return (
                      <Tr key={key}>
                        <Th >{file.name} </Th>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Flex>
  )
}


import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { CitiesNames } from "./dtos/CitiesNames";

interface CitiesNamesResponse {
  cities: CitiesNames[]
  totalPages: number
}
type IninityQueryOptions = UseInfiniteQueryOptions<CitiesNamesResponse, unknown, CitiesNamesResponse, QueryKey>


interface CitiesNamesQueryParams {
  pageSize?: number
  currentPage?: number
}

interface UseCitiesNamesOptions {
  queryKey?: string
  queryOptions?: IninityQueryOptions
  queryParams?: CitiesNamesQueryParams
}

export function useCitiesNames(options?: UseCitiesNamesOptions){
  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  async function getCitiesNames({
    pageParam = 1
  }) {
    try {
      const { data } = await api.get('/cities/names', {
        params: {
          ...options?.queryParams,
          currentPage: pageParam
        }
      })
      return data

    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  return useInfiniteQuery(
    [options?.queryKey || 'citiesNames', { ...options?.queryParams }],
    getCitiesNames,
    {
      ...options?.queryOptions,
      refetchOnWindowFocus: false,
      onError: async (error) => onRequestError(error)
    })
}

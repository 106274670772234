import { differenceInMinutes, set } from "date-fns"

export function comparePlannedRouteDepartureHourIsLessThanSourceBranchOpenHour(
  departureHour: string,
  branchOpenHour: string,
) {
  const [departureHours, departureMinutes] = departureHour.split(":").map(time => Number(time))
  const [branchOpenHours, branchOpenMinutes] = branchOpenHour.split(":").map(time => Number(time))

  const difference = differenceInMinutes(
    set(new Date(), { hours: departureHours, minutes: departureMinutes }),
    set(new Date(), { hours: branchOpenHours, minutes: branchOpenMinutes }),
  )

  if (difference < 0) return true

  return false
}

import axios from "axios"
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { UsageReport } from "./dtos/UsageReport";

interface UsageReportQueryParams {
  user_id: string
  start_filter: string
  end_filter: string
}

type QueryOptions = UseQueryOptions<UsageReport[], unknown, UsageReport[], ["usageReport"]>

interface UseUsageReportOptions {
  queryOptions?: QueryOptions
  queryParams?: UsageReportQueryParams
}

async function generateUsageReportReqFunction({
  user_id,
  start_filter,
  end_filter
}: UsageReportQueryParams) {
  try {
    const { data } = await api.get<UsageReport[]>(`/report/usage`, {
      params: {
        user_id,
        start_filter,
        end_filter
      },
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useUsageReport(options?: UseUsageReportOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()
  }

  return useQuery([
    "usageReport",
    options.queryParams.user_id,
    options.queryParams.start_filter,
    options.queryParams.end_filter
  ], () => generateUsageReportReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}

import { useEffect } from "react"
import { Flex, Button } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { ActivateAggregateForm, ActivateAggregateFormInputs } from './components/ActivateAggregateForm'
import { validateHasFile } from "utils/imageValidation";
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
  aggregateId: string
}

const activateAggregateForm = yup.object().shape({
  hasOpenProvisionComunication: yup.mixed().required('Campo obrigatório'),
  hasOnboarding: yup.mixed().required('Campo obrigatório'),
  signedContract: yup.mixed()
    .test('validateHasFile', 'Campo obrigatório', (value: FileList) => validateHasFile(value)),
  sheetAttachment: yup.mixed()
    .test('validateHasFile', 'Campo obrigatório', (value: FileList) => validateHasFile(value))
})

export function ActivateAggregatesPage() {
  const { aggregateId } = useParams<ParamsProps>()
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const { push: redirect } = useHistory()

  const userHasPermissionToActivateAggregate = userLogged.permissions.includes('activate-aggregate')

  useEffect(() => {
    if (!userHasPermissionToActivateAggregate) {
      redirect('/')
    }
  }, [userHasPermissionToActivateAggregate, redirect])

  const formMethods = useForm<ActivateAggregateFormInputs>({
    resolver: yupResolver(activateAggregateForm)
  })
  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const { activateAggregate: { mutateAsync: activateAggregate } } = useAggregateFunctions()

  async function handleActiveAggregate({ ...values }: ActivateAggregateFormInputs) {
    const formData = new FormData()

    formData.append('hasOpenProvisionComunication', values.hasOpenProvisionComunication)
    formData.append('hasOnboarding', values.hasOnboarding)
    formData.append('signedContract', values.signedContract[0])
    formData.append('sheetAttachment', values.sheetAttachment[0])

    await promiseMessage(activateAggregate({aggregateId: aggregateId, input: formData}, {
      onSuccess: async () => {
        redirect("/agregados")
      }
    }), 'Agregado ativado com sucesso!')
  }

  return (
    <StandardBackgroundForm title="Ativar Agregado" onSubmit={handleSubmit(handleActiveAggregate)}>
      <FormProvider {...formMethods}>
        <ActivateAggregateForm />
      </FormProvider>
      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

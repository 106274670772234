import { api } from '../../../services/api'

export type CteInput = {
  csv_file: {} | null
}

const sendLoglifeCteListFileReqFunction = (cteInput: CteInput) =>
  api
    .post('/uploads/cte-loglife', cteInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

async function sendComplementaryCteListFileReqFunction(input: CteInput) {
  try {
    await api.post('/uploads/cte-complementary', input)
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export { sendLoglifeCteListFileReqFunction, sendComplementaryCteListFileReqFunction }

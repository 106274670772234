import { Flex, IconButton, Td, Tr } from "@chakra-ui/react";
import { deleteCustomer } from "api/customers/deleteCustomer";
import { GetCustomersResponse } from "api/customers/getCustomers";
import { Customer } from "api/customers/_types/Customer";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaSearch } from "react-icons/fa";
import { FaPen, FaTrash } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { captalize } from "utils/captalize";
import { CustomerSituation } from "./CustomerSituation";

interface CustomerTableRowProps {
  customer: Customer
}

export function CustomerTableRow({
  customer
}: CustomerTableRowProps) {
  const { userLogged } = useAuth()

  const queryClient = useQueryClient()

  const { mutateAsync: deleteCustomerFn } = useMutation({
    mutationFn: deleteCustomer,
    onSuccess: (_, customerId) => {
      const cached = queryClient.getQueriesData<GetCustomersResponse>({
        queryKey: ['customers']
      })

      cached.forEach(([cachedKey, cachedValue]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          customers: cachedValue.customers.filter((customer) => customer.id !== customerId)
        })
      })

    }
  })

  const { promiseMessage } = useToastify()


  async function handleDeleteCustomer(customerId: string) {
    await promiseMessage(deleteCustomerFn(customerId), 'Cliente excluído com sucesso!')
  }

  const userCanEditCustomer = userLogged?.permissions.includes('edit-customer')
  const userCanDeleteCustomer = userLogged?.permissions.includes('remove-customer')

  return (
    <Tr>
      <Td>
        <Flex align="center" gap={3}>
          <IconButton
            as={Link}
            aria-label="Visualizar cliente"
            icon={<FaSearch />}
            variant="ghost"
            to={`/cliente/visualizar/${customer.id}`}
          />
          {userCanEditCustomer && (
            <IconButton
              as={Link}
              aria-label="Editar cliente"
              icon={<FaPen />}
              variant="ghost"
              to={`/cliente/editar/${customer.id}`}
            />
          )}
        </Flex>
      </Td>
      <Td>{captalize(customer.trading_firstname)}</Td>
      <Td>{captalize(customer.cost_center)}</Td>
      <Td>{captalize(`${customer?.attendanceResponsibleIDCustomer?.firstname} ${customer?.attendanceResponsibleIDCustomer?.lastname}`)}</Td>
      <Td>{captalize(customer.type)}</Td>
      <Td>
        <CustomerSituation situation={customer.situation} />
      </Td>
      <Td>
        {userCanDeleteCustomer && (
          <IconButton
            aria-label="Excluir cliente"
            icon={<FaTrash />}
            variant="ghost"
            onClick={() => handleDeleteCustomer(customer.id)}
          />
        )}
      </Td>
    </Tr>
  )
}

import { useMutation } from 'react-query'
import { generateMinutaReqFunction } from '../../utils/RequestFunctions/Minuta/requestMinutaFunctions'
import { useSwal } from '../swal/useSwal'

export const useMinutaFunctions = () => {
  const { successMessage, errorMessage } = useSwal()

  const generateMinuta = useMutation(
    'generateMinuta',
    (service_id: string) => generateMinutaReqFunction(service_id),
    {
      onSuccess: () => {
        successMessage('Minuta gerada com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    generateMinuta,
  }
}

import { Box, Button, Flex, Heading, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

import { useAuth } from "hooks/auth/useAuth";
import { useMaterialFunctions } from "hooks/material/useMaterialFunctions";
import { useMaterials } from "hooks/material/useMaterials";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

export function MaterialList() {

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged.permissions.includes('view-material')) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  const { confirmMessage, standardMessage } = useSwal()

  const { data: materials, isFetching: isFetchingMaterials } = useMaterials()
  const { deleteMaterial: { mutateAsync: deleteMaterial } } = useMaterialFunctions()
  const { promiseMessage } = useToastify()

  async function handleDeleteMaterial(material_id: string) {
    const hasDeleteMaterial = await confirmMessage({ title: "Deseja excluir um material?" })

    if (hasDeleteMaterial) {
      await promiseMessage(deleteMaterial(material_id), "Material excluído com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  if (isFetchingMaterials) {
    return <GeneralContentLoading />
  }

  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Heading fontSize="xl">Lista de materiais</Heading>

          <TableContainer>
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>NOME</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {materials.map(material => {
                  return (
                    <Tr key={material.id}>
                      <Td>{material.material_name}</Td>
                      <Td isNumeric>
                        {userLogged.permissions.includes('delete-material') && (
                          <Button as={Link} to={`/material/editar/${material.id}`} size="xs" variant="unstyled">
                            <Icon
                              cursor="pointer"
                              as={FaPen}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                        )}
                        {userLogged.permissions.includes('update-material') && (
                          <Button size="xs" variant="unstyled" ml={2}>
                            <Icon
                              onClick={async () => await handleDeleteMaterial(material.id)}
                              cursor="pointer"
                              as={FaTrash}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      </Box>

  )
}

import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { FaCheck } from "react-icons/fa";

interface ValidateBugActionButtonRootProps extends IconButtonProps { }

export function ValidateBugActionButtonRoot({
  ...rest
}: ValidateBugActionButtonRootProps) {
  const { userLogged } = useAuth()

  return (
    <IconButton
      aria-label="Validar/Remover validação de bug"
      icon={<FaCheck />}
      rounded="full"
      border="1px solid"
      borderColor="gray.200"
      color="white"
      bgColor="white"
      size="xs"
      isDisabled={!userLogged?.permissions.includes('validate-bug')}
      _hover={{
        borderColor: "green.500",
        color: "green.500"
      }}
      _checked={{
        bgColor: "green.500",
        color: "white",
        borderColor: "green.500",
        _hover: {
          opacity: 0.9
        }
      }}
      {...rest}
    />
  )
}

import { useQuery, UseQueryOptions } from "react-query";
import { toast } from "react-toastify";
import { apiCep } from "services/cepApiService/cepApi";
import { ICepApiProps } from "services/cepApiService/ICepApi";

interface UseCepArguments {
  cep?: string | null
  useQueryOptions?: UseQueryOptions<ICepApiProps, unknown, ICepApiProps, ["cep"]>
}

export function useCep({ cep, useQueryOptions }: UseCepArguments) {
  return useQuery(['cep', cep], () => apiCep.get(cep! + "/json"), {
    ...useQueryOptions,
    retry: false,
    refetchOnWindowFocus: false,
    onError: () => {
      toast.error("Ocorreu um erro ao realizar a consulta por cep, tente novamente ou insira os dados manualmente!")
    }
  })
}

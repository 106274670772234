import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { FormProvider, useForm } from "react-hook-form"

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { useToastify } from "hooks/toastify/useToastify"
import { Button, Flex } from "@chakra-ui/react"
import { useAuth } from "hooks/auth/useAuth"
import { Link, useHistory } from "react-router-dom"
import { useEffect } from "react"
import { WarehouseForm, WarehouseFormInputs } from "./components/WarehouseForm"
import { useWarehouseFunctions } from "hooks/warehouse/useWarehouseFunctions"

const createWarehouseSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório")
})

export function CreateWarehousePage() {
  const formMethods = useForm<WarehouseFormInputs>({
    resolver: yupResolver(createWarehouseSchema)
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('create-warehouse')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const { promiseMessage } = useToastify()

  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const {createWarehouse: {mutateAsync: createWarehouse}} = useWarehouseFunctions()

  async function handleCreateWarehouse({
    ...values
  }: WarehouseFormInputs) {
    const successMessage = 'Almoxarifado criado com sucesso!'

    await promiseMessage(createWarehouse(values, {
      onSuccess: () => redirectTo("/almoxarifados")
    }), successMessage)
  }

  return (
    <StandardBackgroundForm title="Criar Almoxarifado" onSubmit={handleSubmit(handleCreateWarehouse)}>
      <FormProvider {...formMethods}>
        <WarehouseForm slug="criar"/>
      </FormProvider>

      <Flex
        mt={6}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/almoxarifados'
        >
          Lista de almoxarifados
        </Button>
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

import { api } from '../../../../services/api'

export type CreateBoardServiceInputProps = {
  service_id: string
  collector_id: string
  branch_id: string
  driver_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
}

export type FinishBoardServiceInputProps = {
  service_id: string
  collector_id: string
  operational_number: string
  board_volume: number
  cte_photo?: FileList
  box_photo?: FileList
  departure_latitude: string
  departure_longitude: string
  departure_timestamp: string
  board_observation: string
}

export type ValidateBoardServiceInputProps = {
  service_id: string
  collector_id: string
  cte: string
  operational_number: string
  board_volume: string
  board_weight: string
  real_weight: string
  taxed_weight: string
  cte_transfer_cost: string
  validate_observation: string
  open_ticket_email: 'yes' | 'no'
  tracker_link: string
}

export type UpdateBoardServiceInputProps = ValidateBoardServiceInputProps

export type FinishBoardServiceReqProps = {
  serviceID: string
  finishBoardServiceInput: FinishBoardServiceInputProps
}

export type ValidateBoardServiceReqProps = {
  serviceID: string
  serviceModal: string
  validateBoardServiceInput: ValidateBoardServiceInputProps
}

export type UpdateBoardServiceReqProps = {
  serviceID: string
  updateBoardServiceInput: UpdateBoardServiceInputProps
}

const createBoardServiceReqFunction = (
  createBoardServiceInputProps: CreateBoardServiceInputProps,
) =>
  api
    .post('/board-service', createBoardServiceInputProps)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const finishBoardServiceReqFunction = ({
  serviceID,
  finishBoardServiceInput,
}: FinishBoardServiceReqProps) =>
  api
    .put(`/board-service/${serviceID}`, finishBoardServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const validateBoardServiceReqFunction = ({
  serviceID,
  serviceModal,
  validateBoardServiceInput,
}: ValidateBoardServiceReqProps) =>
  api
    .put(`/board-service/${serviceID}`, {
      ...validateBoardServiceInput,
      hasValidate: true,
      service_modal: serviceModal,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const updateBoardServiceReqFunction = ({
  serviceID,
  updateBoardServiceInput,
}: UpdateBoardServiceReqProps) =>
  api
    .put(`in-progress/board-service/${serviceID}`, updateBoardServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

export {
  createBoardServiceReqFunction,
  finishBoardServiceReqFunction,
  validateBoardServiceReqFunction,
  updateBoardServiceReqFunction,
}

import { IconButton, Link, Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { StandardizationAttachmentTypes } from "api/standardizations/getStandardization"
import { Standardization } from "api/standardizations/standardization"
import { format } from "date-fns"
import { Attachment } from "hooks/attachment/dtos/Attachment"
import { FaExternalLinkAlt } from "react-icons/fa"
import { standardizationAttachmentsTypesMapper } from "utils/standardizations/standardizationMappers"
import { StandardizationStatus } from "./StandardizationStatus"

interface StandardizationDetailTableProps {
  standardization: Standardization & {
    attachments: {
      id: string
      type: StandardizationAttachmentTypes
      created_at: string
      updated_at: string
      attachment_id: string
      standardization_id: string
      attachment: Attachment
    }[]
  }
}

export function StandardizationDetailTable({
  standardization
}: StandardizationDetailTableProps) {
  return (
    <TableContainer maxH='550px' overflowY='scroll'>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Td>Nome</Td>
            <Td isNumeric>{standardization?.name}</Td>
          </Tr>

          <Tr>
            <Td>Hub</Td>
            <Td isNumeric>{standardization?.hub?.name}</Td>
          </Tr>

          <Tr>
            <Td>Cliente loglife</Td>
            <Td isNumeric>{standardization?.loglife_customer}</Td>
          </Tr>

          <Tr>
            <Td>Veículo</Td>
            <Td isNumeric>{standardization?.vehicle}</Td>
          </Tr>

          <Tr>
            <Td>Data de envio</Td>
            <Td isNumeric>{
              format(new Date(standardization?.sended_at), 'dd/MM/yyyy')
            }</Td>
          </Tr>
          <Tr>
            <Td>Status</Td>
            <Td display='flex' justifyContent='flex-end'>
              <StandardizationStatus status={standardization.status} />
            </Td>
          </Tr>
          {standardization?.attachments.map((attachment) => {
            return (
              <Tr key={attachment.id}>
                <Td maxW="210px" overflow="hidden" textOverflow="ellipsis">
                  <Text w="250px" whiteSpace="pre-line" >
                    {standardizationAttachmentsTypesMapper(standardization?.vehicle as 'Moto' | 'Carro')[attachment?.type]}
                  </Text>
                </Td>
                <Td isNumeric>
                  <IconButton
                    aria-label="Abrir anexo"
                    as={Link}
                    href={attachment?.attachment?.link}
                    icon={<FaExternalLinkAlt />}
                    size="sm"
                    isExternal
                  />
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

import { Button, Flex, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { add, format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useReport } from "hooks/report/useReport";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { handleChangeUrl } from "utils/handleChangeUrl";
import * as yup from "yup"

interface MaintenanceReportInputs {
  start_filter: string
  end_filter: string
  maintenance_status: 'EM MANUTENÇÃO' | 'FINALIZADAS'
}

const maintenanceReportHeaders = [
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'TIPO DE MANUTENÇÃO', key: 'maintenance_type'},
  { label: 'CLIENTE', key: 'customer' },
  { label: 'CIDADE(S) REMETENTE(S)', key: 'source_cities' },
  { label: 'CIDADE(S) DESTINATÁRIA(S)', key: 'destination_cities' },
  { label: 'VOLUMES', key: 'volume' },
  { label: 'TEMPERATURA DE CHEGADA', key: 'maintenance_arrival_temperature' },
  { label: 'TEMPERATURA DE SAÍDA', key: 'maintenance_departure_temperature' },
  { label: 'DATA DA MANUTENÇÃO', key: 'maintenance_date' },
  { label: 'POSSUI CROSSDOCKING', key: 'has_crossdocking' },
  { label: 'COLETADOR', key: 'maintenance_collector' },
  { label: 'FOTO DE CHEGADA', key: 'maintenance_arrival_photo' },
  { label: 'FOTO DE SAÍDA', key: 'maintenance_departure_photo' },
  { label: 'RESPONSÁVEL PELA MANUTENÇÃO', key: 'responsible' },
  { label: 'DATA DO LANÇAMENTO', key: 'created_at' }
]

const maintenanceStatusOptions = [
  { key: 'inProgress', value: 'EM MANUTENÇÃO', showOption: 'EM MANUTENÇÃO' },
  { key: 'finalized', value: 'FINALIZADAS', showOption: 'FINALIZADAS' },
]

const maintenanceReportSchema = yup.object().shape({
  maintenance_status: yup.string().required('Campo obrigatório'),
  start_filter: yup.string().when('maintenance_status', {
    is: 'FINALIZADAS',
    then: yup.string().required('Campo obrigatório')
  }),
  end_filter: yup.string().when('maintenance_status', {
    is: 'FINALIZADAS',
    then: yup.string().required('Campo obrigatório')
  })
})

export function MaintenanceReport() {

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-maintenance-report')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<MaintenanceReportInputs>({
    resolver: yupResolver(maintenanceReportSchema)
  })

  const [
    startFilter,
    endFilter,
    maintenanceStatus
  ] = useWatch({
    control,
    name: ['start_filter', 'end_filter', 'maintenance_status']
  })

  const isValidStartDate = new Date(startFilter) instanceof Date &&
    !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''


  const isReportWithDateFilter = maintenanceStatus === 'FINALIZADAS'

  const reportFilename = isReportWithDateFilter
    ? `relatório-manutenções-${startFilter}-${endFilter}`
    : 'relatório-geral-manutenções'

  const {
    generateMaintenanceServiceReport: {
      mutateAsync: generateMaintenanceServiceReport,
      isLoading: isGenerateMaintenanceServiceReportDataLoading,
      data: maintenanceServiceReportData,
    }
  } = useReport()

  const maintenanceServiceReportDataFormatted = maintenanceServiceReportData ?
    maintenanceServiceReportData
      .map(maintenance => {
        return {
          ...maintenance,
          maintenance_arrival_photo: maintenance.maintenance_arrival_photo !== "" ? handleChangeUrl(maintenance.maintenance_arrival_photo) : '-',
          maintenance_departure_photo: maintenance.maintenance_departure_photo !== "" ? handleChangeUrl(maintenance.maintenance_departure_photo) : '-',
          maintenance_collector: maintenance.maintenance_collector !== "" ? maintenance.maintenance_collector : '-',
          created_at: format(new Date(maintenance.created_at), 'dd/MM/yyyy')
        }
      })
    : []

  const csvReportProps = {
    data: maintenanceServiceReportDataFormatted,
    headers: maintenanceReportHeaders,
    filename: reportFilename,
  }

  async function handleGenerateMaintenanceServiceReport({
    end_filter,
    start_filter,
    maintenance_status,
  }: MaintenanceReportInputs) {
    await generateMaintenanceServiceReport({
      start_filter,
      end_filter,
      maintenance_status
    })
  }

  return (
    <StandardBackgroundForm
      title='Relatório de manutenções'
      onSubmit={handleSubmit(handleGenerateMaintenanceServiceReport)}
    >
      <Flex
        direction='column'
        gap={4}
      >
        <Select
          {...register('maintenance_status')}
          name='maintenance_status'
          label='Pesquisar por (status da manutenção)'
          error={errors.maintenance_status}
          placeholder='Selecione uma opção...'
          options={maintenanceStatusOptions}
          required
        />

        {isReportWithDateFilter && (
          <Stack
            spacing={4}
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('start_filter')}
              name='start_filter'
              type='date'
              error={errors.start_filter}
              label='Data inicial'
              required
            />
            <Input
              {...register('end_filter')}
              name='end_filter'
              type='date'
              error={errors.end_filter}
              label='Data final'
              max={maxEndFilter}
              required
            />
          </Stack>
        )}

        <Flex
          gap={4}
          align="center"
          justify="flex-end"
        >
          {maintenanceServiceReportData && (
            <GenerateExcelReportButton
              mt={-4}
              csvReportProps={csvReportProps}
              isLoading={isGenerateMaintenanceServiceReportDataLoading}
            />
          )}
          <Button
            type='submit'
            colorScheme='blue'
            isLoading={isGenerateMaintenanceServiceReportDataLoading}
          >
            Gerar relatório
          </Button>
        </Flex>
      </Flex>
    </StandardBackgroundForm>
  )
}

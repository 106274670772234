import { Box, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function CTECancellationForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      cteNumberToBeCancelled: 'Número do CTE a ser cancelado',
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.cteNumberToBeCancelled} mt={3}>
        <FormLabel fontSize='sm'>
          Número do CTE a ser cancelado
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.cteNumberToBeCancelled.value')}
          name='formData.cteNumberToBeCancelled.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('cteNumberToBeCancelled')}
        />
      </FormControl>
    </Box>
  )

}

import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Select, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createAso } from "api/asos/createAso";
import { getInternClts } from "api/internClts/getInternClts";
import { set } from "date-fns";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { getCltDrivers } from "hooks/cltDriver/useCltDrivers";
import { useToastify } from "hooks/toastify/useToastify";
import { ChangeEvent, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { asoCollaboratorTypeMap } from "utils/asoMappers";
import { captalize } from "utils/captalize";
import { validateHasFile } from "utils/fileValidation";
import * as yup from "yup"
import AsyncSelect from "react-select/async"
import { getRegionals } from "api/regionals/getRegionals";
import { getAggregates } from "hooks/aggregate/useAggregates";

interface CreateAsoProps {
  onCloseModal: () => void
}

interface CreateAsoSchema {
  regional: { value: string, label: string }
  collaboratorType: string
  collaborator: { value: string, label: string, email }
  collaboratorEmail: string
  status: 'active' | 'pending'
  attachment: FileList
  dueDate: string
}

const createAsoSchema = yup.object({
  regional: yup.object().required(),
  collaboratorType: yup.string().required(),
  collaborator: yup.object().required(),
  collaboratorEmail: yup.string().required(),
  status: yup.string().required(),
  attachment: yup.mixed().when('status', {
    is: 'active',
    then: yup.mixed().test(value => validateHasFile(value))
  }),
  dueDate: yup.mixed().when('status', {
    is: 'active',
    then: yup.string().required().transform((value: string) => {
      const [year, month, day] = value.split('-').map(Number)

      return set(new Date(), {
        year,
        month: month - 1,
        date: day,
        hours: 12,
        minutes: 0,
      }).toISOString()

    })
  }),
})

export function CreateAso({ onCloseModal }: CreateAsoProps) {
  const { mutation: uploadAttachmentFn, uploadProggress } = useAttachmentMutation()

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateAsoSchema>({
    resolver: yupResolver(createAsoSchema),
    defaultValues: {
      status: 'active',
    }
  })

  const [attachment, status, collaboratorType, collaborator] = useWatch({
    control,
    name: ['attachment', 'status', 'collaboratorType', 'collaborator'],
  })

  useEffect(() => {
    if (collaborator) {
      setValue('collaboratorEmail', collaborator.email)
    }
  }, [setValue, collaborator])

  async function handleUploadAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadAttachmentFn.mutateAsync(formData)

  }

  const queryClient = useQueryClient()

  const { mutateAsync: createAsoFn } = useMutation({
    mutationFn: createAso,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: 'asos'
      })
      queryClient.invalidateQueries({ queryKey: 'pending-asos' })

      onCloseModal()
    },
  })

  const { promiseMessage } = useToastify()

  async function handleCreateAso(values: CreateAsoSchema) {
    await promiseMessage(createAsoFn({
      body: {
        attachmentId: uploadAttachmentFn?.data?.attachment?.id,
        regionalId: values.regional.value,
        collaboratorType,
        collaboratorEmail: values.collaboratorEmail,
        collaboratorName: values.collaborator.value,
        status: values.status,
        dueDate: values.dueDate
      }
    }), 'Aso criada! 🎉')
  }

  async function regionalPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response.regionals.filter(regional => regional.code.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(regional => {
      return { value: regional.id, label: regional.code.toUpperCase() }
    })
  }

  async function llmCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string, email: string }>> {
    const response = await getInternClts({ currentPage: 1, pageSize: 5, name: inputValue })

    return response.internClts.map(internClt => {
      return {
        value: internClt.name,
        label: internClt.name,
        email: internClt.email
      }
    })
  }

  async function cltDriversCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string, email: string }>> {
    const response = await getCltDrivers({ currentPage: 1, pageSize: 5, search: inputValue })

    return response.cltDrivers.map(cltDriver => {
      return {
        value: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        label: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        email: cltDriver.driver.email.toLocaleLowerCase()
      }
    })
  }

  async function aggregatesCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string, email: string }>> {
    const response = await getAggregates({ status: 'active' })

    return response.filter(aggregate => `${aggregate.driver.firstname} ${aggregate.driver.lastname}`.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(aggregate => {
      return {
        value: captalize(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`),
        label: captalize(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`),
        email: aggregate.driver.email.toLocaleLowerCase()
      }
    })
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Criar Aso</ModalHeader>
      <ModalCloseButton />

      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateAso)}
      >

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Regional
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.regional}>
              <Controller
                control={control}
                name="regional"
                render={({ field }) => (
                  <AsyncSelect
                    {...register('regional')}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    isMulti={false}
                    cacheOptions
                    defaultOptions
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '1px',
                        borderRadius: '6px',
                        border: state.isFocused ? 'none' : '',
                        boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                      })
                    }}
                    noOptionsMessage={() => 'Não há regionais cadastradas ou encontradas!'}
                    placeholder="Selecione uma regional..."
                    loadOptions={regionalPromiseOptions}
                    isClearable={true}
                  />
                )}
              />
            </FormControl>
          </Stack>
        </Stack>

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.collaboratorType}>
              <Select
                {...register('collaboratorType')}
                name="collaboratorType"
                placeholder="Selecione..."
                rounded="md"
              >
                {Object.entries(asoCollaboratorTypeMap).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>

          {collaboratorType && collaboratorType !== 'motorcyclist' && (
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormLabel fontSize="sm">
                Colaborador
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <FormControl isInvalid={!!errors.collaborator}>
                {collaboratorType === 'llm' && (
                  <Controller
                    control={control}
                    name="collaborator"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('collaborator')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          })
                        }}
                        noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborator..."
                        loadOptions={llmCollaboratorsPromiseOptions}
                        isClearable={true}
                      />
                    )}
                  />
                )}

                {collaboratorType === 'aggregate-driver' && (
                  <Controller
                    control={control}
                    name="collaborator"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('collaborator')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          })
                        }}
                        noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborator..."
                        loadOptions={aggregatesCollaboratorsPromiseOptions}
                        isClearable={true}
                      />
                    )}
                  />
                )}

                {collaboratorType === 'clt-driver' && (
                  <Controller
                    control={control}
                    name="collaborator"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('collaborator')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          })
                        }}
                        noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborator..."
                        loadOptions={cltDriversCollaboratorsPromiseOptions}
                        isClearable={true}
                      />
                    )}
                  />
                )}
              </FormControl>
            </Stack>
          )}

        </Stack>

        <FormControl mt={3}>
          <FormLabel fontSize="sm">
            Email
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register("collaboratorEmail")}
            name="collaboratorEmail"
            disabled
          />
        </FormControl>

        <Stack
          direction="column"
          w="full"
          mt="3"
          spacing="0.25"
        >
          <FormLabel fontSize="sm">
            Status
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.status}>
            <Select
              {...register('status')}
              name="status"
              defaultValue="active"
              rounded="md"
            >
              <option value="active">Ativo</option>
              <option value="pending">Pendente</option>
            </Select>
          </FormControl>
        </Stack>

        {status === 'active' && (
          <>
            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <FormControl isInvalid={!!errors?.attachment}>
                <FormLabel fontSize="sm">
                  Data de realização
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('dueDate')}
                  name="dueDate"
                  type="date"
                  rounded="md"
                />
              </FormControl>
            </Stack>

            <Stack
              direction="column"
              w="full"
              mt="3"
            >
              <Button
                as={FormLabel}
                htmlFor="attachment"
                lineHeight="1"
                leftIcon={<FaFileImport />}
                size="sm"
                w="min"
                cursor="pointer"
                border={!!errors?.attachment && '2px solid'}
                borderColor={!!errors?.attachment && 'red.500'}
              >
                Anexar
              </Button>
              <FormControl isInvalid={!!errors?.attachment}>
                <Input
                  {...register('attachment')}
                  name="attachment"
                  id="attachment"
                  type="file"
                  hidden
                  onChangeCapture={handleUploadAttachment}
                />
              </FormControl>
            </Stack>
          </>
        )}

        {attachment && (
          <Table size="sm">
            <Tbody>
              {Object.entries(attachment).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Td fontSize="xs" maxW="100px">{file.name}</Td>
                    <Td fontSize="xs" w="200px">
                      <Progress size="sm" rounded="md" value={uploadProggress} />
                    </Td>
                    <Td fontSize="xs" isNumeric>
                      {uploadAttachmentFn.data && (
                        <IconButton
                          aria-label="Visualizar anexo"
                          as={Link}
                          size="sm"
                          icon={<FaExternalLinkAlt />}
                          href={uploadAttachmentFn.data.attachment.link}
                          isExternal
                        />
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )}

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

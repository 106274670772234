import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CorrectStandardizationBody {
  anttPhotoId?: string
  cleaningRegistersPhotosIds?: string[]
  cnhPhotoId?: string
  crlvPhotoId?: string
  frontPhotoWithUniformAndBadgePhotoId?: string
  tiresPhotosIds?: string[],
  trunkLidOrCarWithEmergencyFormPhotoId?: string
  vehicleFrontPhotoId?: string
  vehicleInternPhotoId?: string
  vehicleLeftSidePhotoId?: string
  vehicleRearPhotoId?: string
  vehicleRightSidePhotoId?: string
}

interface CorrectStantardizationParams {
  params: {
    standardizationId: string
  }
  body: CorrectStandardizationBody
}

export async function correctStandardization({
  params,
  body
}: CorrectStantardizationParams) {
  try {
    await api.put(`/standardizations/${params.standardizationId}/correct`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


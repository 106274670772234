import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetInBoardServicesResponse {
  services: {
    id: string
    step: string
    protocol: number
    customer: string
    vehicle: string
    source_addresses: string[]
    destination_addresses: string[]
    collect_hour_start: string
    collect_hour_end: string
    service_type: string
    board_date: string
    board_hour: string
    shipping: string
    source_branch: string
    destination_branch: string
    delivery_date: string
    delivery_hour: string
    observations: string
    source_collector: string
    destination_collector: string
    source_tz: string
    destination_tz: string
    has_occurrence: boolean
  }[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}

interface GetInBoardServicesQueryParams {
  pageSize: string
  currentPage: string
  protocol?: string | null
  customer?: string | null
  sourceAddress?: string | null
  destinationAddress?: string | null
  boardDate?: string | null
  boardHour?: string | null
  boardInfo?: string | null
  timezoneOffset: string
  sourceCollector: string
  destinationCollector: string
  collectorId?: string
}

export async function getInBoardServices({
  currentPage,
  pageSize,
  protocol,
  customer,
  sourceAddress,
  destinationAddress,
  boardDate,
  boardHour,
  boardInfo,
  timezoneOffset,
  sourceCollector,
  destinationCollector,
  collectorId,
}: GetInBoardServicesQueryParams) {
  try {
    const { data } = await api.get<GetInBoardServicesResponse>('/services/boards', {
      params: {
        currentPage,
        pageSize,
        protocol,
        customer,
        sourceAddress,
        destinationAddress,
        timezoneOffset,
        boardDate,
        boardHour,
        boardInfo,
        sourceCollector,
        destinationCollector,
        collectorId
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetServicesRecurrentsByBudgetParams {
  budgetId: string,
  pageSize: string
  currentPage: string
}

interface GetServicesRecurrentsByBudgetResponse {
  recurrentServices: {
    id: string,
    collect_hour_start: string,
    collect_hour_end: string,
    delivery_hour: string,
    days_of_week: number[]
  }[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}

export async function getServicesRecurrentsByBudget({
  currentPage,
  pageSize,
  budgetId
}: GetServicesRecurrentsByBudgetParams) {
  try {

    const { data } = await api.get<GetServicesRecurrentsByBudgetResponse>(`/budgets/${budgetId}/recurrent-services`,{
      params:{
        pageSize,
        currentPage
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

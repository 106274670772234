import { Box, Divider, Grid, Heading, Spinner } from "@chakra-ui/react";
import { RncAlignAccordion } from "components/Accordions/Rnc/RncAlignAccordion";

import { useAuth } from "hooks/auth/useAuth";
import { useRnc } from "hooks/rnc/useRnc";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SendRncAlignmentAttachmentsForm } from "./components/SendRncAlignmentAttachmentsForm";

interface Params {
  rncId: string
}

export function SendRncAlignmentAttachments() {
  const { userLogged } = useAuth()

  const { push: redirect } = useHistory()
  const { rncId } = useParams<Params>()

  const { rnc } = useRnc(rncId)

  useEffect(() => {
    if (rnc.data) {
      const isPendingAttachSomeAlignment = rnc?.data?.alignments.find(
        (alignment) => alignment.attachments === null
      )

      if (!isPendingAttachSomeAlignment) {
        redirect('/rncs/pendente-anexo')
      }
    }
  }, [redirect, rnc])

  const userLoggedHasPermissionToSendAlignmentAttachments =
    userLogged?.permissions.includes('send-intern-rnc-alignment-attachments')

  useEffect(() => {
    if (!userLoggedHasPermissionToSendAlignmentAttachments) {
      redirect('/')
    }
  }, [userLoggedHasPermissionToSendAlignmentAttachments, redirect])

  return (

      <Box
        p={4}
        rounded="md"
        bg="white"
      >
        <Heading size="md">Enviar anexos da RNC</Heading>

        <Divider />

        <RncAlignAccordion rnc={rnc.data} isRncLoading={rnc.isFetching} />

        {rnc.isFetching ? (
          <Spinner />
        ) : (
          <Grid mt={4} templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={6}>
            {rnc?.data?.alignments.map((alignment, index) => {
              return (
                <SendRncAlignmentAttachmentsForm
                  key={alignment.id}
                  index={index}
                  alignment={alignment}
                />
              )
            })}
          </Grid>
        )}
      </Box>

  )
}

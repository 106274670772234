import { Button, Flex, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { add } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useCollectors } from "hooks/collector/useCollectors";
import { useProdutivityReport } from "hooks/report/useProdutivityReport";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import * as yup from "yup"

interface ProductivityReportInputs {
  collector_id: string
  start_filter: string
  end_filter: string
}

const productivityReportHeaders = [
  { key: 'protocol', label: 'PROTOCOLO' },
  { key: 'source_hub', label: 'HUB DE ORIGEM' },
  { key: 'destination_hub', label: 'HUB DE DESTINO' },
  { key: 'vehicle', label: 'VEÍCULO' },
  { key: 'collector', label: 'COLETADOR' },
  { key: 'total_collects_and_deliveries_count', label: 'NÚMERO DE COLETAS/ENTREGAS' },
  { key: 'driver', label: 'MOTORISTA' },
  { key: 'type', label: 'TIPO (CLT / AGREGADO / COLETADOR)' },
]

const schema = yup.object().shape({
  collector_id: yup.string().required('Campo obrigatório!'),
  start_filter: yup.string().required('Campo obrigatório!'),
  end_filter: yup.string().required('Campo obrigatório!')
})

export function ProductivityReport() {
  const [
    enableProductivityReportRequest,
    setEnableProductivityReportRequest
  ] = useState(false)

  const {
    data: collectors,
    isFetching: isFetchingCollectors
  } = useCollectors()

  const collectorsSelectOptions = collectors?.map(collector => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-productivity-report')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<ProductivityReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const [
    collectorFilter,
    startFilter,
    endFilter
  ] = useWatch({
    control,
    name: ['collector_id', 'start_filter', 'end_filter']
  })

  const isValidStartDate = new Date(startFilter) instanceof Date &&
  !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const collectorFilteredInfo = collectors?.find(collector => {
    return collector.id === collectorFilter
  })

  const {
    data: productivityReportData,
    isFetching: isProductivityReportDataFetching,

  } = useProdutivityReport({
    queryOptions: {
      enabled: enableProductivityReportRequest,
      select: (productivityReportData) => {
        productivityReportData.reportData = productivityReportData.reportData.map((data) => {
          return {
            ...data,
            source_hub: data.source_hub ?? '-',
            destination_hub: data.destination_hub ?? '-',
          }
        })

        return productivityReportData
      }
    },
    queryParams: {
      collector_id: collectorFilter,
      start_filter: startFilter,
      end_filter: endFilter
    }
  })

  const handleGenerateReport = () => {
    setEnableProductivityReportRequest(true)
    setTimeout(() => {
      setEnableProductivityReportRequest(false)
    }, 500)
  }

  const csvReportProps = {
    data: productivityReportData?.reportData ?? [],
    headers: productivityReportHeaders,
    filename: `relatorio-produtividade-${collectorFilteredInfo?.trading_name}-${startFilter}-${endFilter}.csv`
  }

  return (
    <StandardBackgroundForm
      title='Relatório de Produtividade'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Select
          {...register('collector_id')}
          name='collector_id'
          label='Coletador'
          error={errors.collector_id}
          placeholder='Selecione uma opção...'
          options={collectorsSelectOptions}
          required
        />
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('start_filter')}
            name='start_filter'
            label='Data inicial do filtro'
            type='date'
            error={errors.start_filter}
            required
          />

          <Input
            {...register('end_filter')}
            name='end_filter'
            label='Data final do filtro'
            type='date'
            error={errors.end_filter}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isProductivityReportDataFetching || isFetchingCollectors}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {productivityReportData && (
            <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} />
          )}
        </Flex>
      </Flex>


    </StandardBackgroundForm>
  )
}

import { useMutation } from 'react-query'
import {
  CteInput,
  sendLoglifeCteListFileReqFunction,
  sendComplementaryCteListFileReqFunction,
} from '../../utils/RequestFunctions/Cte/requestCteFunctions'
import { useSwal } from '../swal/useSwal'

export const useCteFunctions = () => {
  const { successMessage, errorMessage } = useSwal()

  const sendLoglifeCteFile = useMutation(
    'sendLoglifeCteFile',
    (cteInput: CteInput) => sendLoglifeCteListFileReqFunction(cteInput),
    {
      onSuccess: () => {
        successMessage('Arquivo enviado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )
  const sendComplementaryCteFile = useMutation(
    'sendComplementaryCteFile',
    (input: CteInput) => sendComplementaryCteListFileReqFunction(input),
    {
      onSuccess: () => {
        successMessage('Arquivo enviado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    sendLoglifeCteFile,
    sendComplementaryCteFile
  }
}

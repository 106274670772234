import { api } from "services/api";

interface GetServiceTotalCostParams {
  serviceId: string
}

export interface GetServiceTotalCostResponse {
  unsuccessfulCollectPrice: number
  extraRequestedMaterialsTotalCost: number
  sourceAddressDiffValue: number
  destinationAddressDiffValue: number
  franchisingDiffValue: number
  otherCollectionsValue: number
  extrasDiscountsTotal: number
  budgetPrice: number

  total: number
}

export async function getServiceTotalCost({
  serviceId
}: GetServiceTotalCostParams) {
  const response = await api.get<GetServiceTotalCostResponse>(`/services/${serviceId}/total-cost`)

  return response.data
}

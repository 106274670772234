import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Heading, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

import { useAuth } from "hooks/auth/useAuth";

import { Pagination } from "components/Pagination/Pagination"
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { Fragment, useEffect, useReducer } from "react";
import { FaEye, FaTrash } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useCrms } from "hooks/crms/useCrms";
import { useForm, useWatch } from "react-hook-form";
import { useCrmFunctions } from "hooks/crms/useCrmFunctions";
import { useQueryClient } from "react-query";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { Input } from "components/Inputs/Input";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FiPlus } from "react-icons/fi";

interface CrmsFilterInputs {
  customer: string
  createdAt: string
  type: string
  owner: string
}

interface ActionPayload {
  queryParams?: CrmsFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-crms-current-page' | 'set-crms-query-params'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-crms-current-page') {
    return {
      queryParams: { ...state.queryParams },
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

const crmsListFilterOptions = [
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'createdAt', value: 'Data Abertura', checked: false },
  { key: 'type', value: 'Tipo de CRM', checked: false },
  { key: 'owner', value: 'Usuário', checked: false },
]

export function CrmsList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE


  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(crmsListFilterOptions)
  }, [onLoadSetFilterOptions])


  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const [listCrmsDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const { confirmMessage, standardMessage } = useSwal()

  const {
    control,
    register
  } = useForm<CrmsFilterInputs>()

  const { data: crmsResponseData, isFetching: isFetchingCrms } = useCrms({
    queryParams: {
      ...listCrmsDataState.queryParams,
      currentPage: listCrmsDataState.currentPage,
      pageSize: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!listCrmsDataState.currentPage
    }
  })

  useEffect(() => {
    if (!userLogged.permissions.includes('view-crm')) {
      redirect("/")
    }
  }, [userLogged, redirect])

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: crmsResponseData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-crms-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  const [
    customerFiltered,
    createdAtFiltered,
    typeFiltered,
    ownerFiltered,
  ] = useWatch({
    control,
    name: [
      'customer',
      'createdAt',
      'type',
      'owner'
    ]
  })

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch({
        type: 'set-crms-query-params',
        payload: {
          queryParams: {
            customer: customerFiltered,
            createdAt: createdAtFiltered,
            type: typeFiltered,
            owner: ownerFiltered
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounce)
  }, [
    customerFiltered,
    createdAtFiltered,
    typeFiltered,
    ownerFiltered,
    setCurrentPage
  ])


  const { deleteCrm: { mutateAsync: deleteCrm } } = useCrmFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleDeleteCrm(crmId: string) {
    const hasDeleteCrm = await confirmMessage({ title: 'Deseja excluir uma Crm?' })

    if (hasDeleteCrm) {
      await promiseMessage(deleteCrm(crmId), 'Crm excluída com sucesso!')

      await queryClient.invalidateQueries(['crms'])
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  if (isFetchingCrms) {
    return <GeneralContentLoading />
  }

  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading fontSize="xl">Lista de Crms</Heading>
            {userLogged.permissions.includes('create-crm') && (
              <Link to="/crm/criar">
                <Button
                  colorScheme="gray"
                  rightIcon={<Icon as={FiPlus} />}
                  size="sm"
                  mt={['4', '4', '0']}
                >
                  Adicionar CRM
                </Button>
              </Link>
            )}
          </Flex>
          <Flex w={['full', 'full', 'min']} gap={2}>
            <TableFilterButton />
          </Flex>

          {
            filterOptions
              .filter((option) => option.checked)
              .map((option) => {
                return (
                  <Fragment key={option.key}>
                    {option.key === 'customer' ? (
                      <Input
                        {...register('customer')}
                        name='customer'
                        label='Cliente'
                        placeholder='Buscar cliente...'
                        size='sm'
                      />
                    ) : (
                      option.key === 'createdAt' ? (
                        <Input
                          {...register('createdAt')}
                          name='createdAt'
                          label='Data Abertura'
                          type='date'
                          size='sm'
                        />
                      ) : (
                        option.key === 'type' ? (
                          <Input
                            {...register('type')}
                            name='type'
                            label='Tipo de CRM'
                            placeholder='Buscar tipo de crm...'
                            size='sm'
                          />
                        ) : (
                          option.key === 'owner' ? (
                            <Input
                              {...register('owner')}
                              name='owner'
                              label='Usuário'
                              placeholder='Buscar usuário...'
                              size='sm'
                            />
                          ) :
                            <></>
                        )
                      )
                    )}
                  </Fragment>
                )
              })
          }
          <TableContainer>
            <Table variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th>CLIENTE</Th>
                  <Th>DATA DA ABERTURA</Th>
                  <Th>TIPO</Th>
                  <Th>USUÁRIO</Th>
                  <Th></Th>
                </Tr>
              </Thead>

              <Tbody>
                {crmsResponseData?.crms.map(crm => {
                  return (
                    <Tr key={crm.id}>
                      <Td>{crm.customer.trading_firstname}</Td>
                      <Td>{formatDate.handle(crm.created_at, 'DateWithoutHourToShow')}</Td>
                      <Td>{crm.type}</Td>
                      {crm.owner ? (
                        <Td>{crm?.owner.firstname} {crm?.owner.lastname}</Td>
                      ) : (
                        <Td>-</Td>
                      )}
                      <Td isNumeric>
                        <Button
                          as={Link}
                          to={`/crm/visualizar/${crm.id}`}
                          variant="ghost"
                        >
                          <Icon
                            cursor="pointer"
                            as={FaEye}
                            transition="all 0.2s"
                            _hover={{ opacity: 0.8 }}
                          />
                        </Button>
                        {userLogged.permissions.includes('delete-crm') && (
                          <Button variant="ghost" ml={2}>
                            <Icon
                              onClick={async () => await handleDeleteCrm(crm.id)}
                              cursor="pointer"
                              as={FaTrash}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>


            </Table>
          </TableContainer>
          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />
        </Flex>
      </Box>

  )
}

import { TrainingLlm } from './trainingLlm';
import axios from "axios";

import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface GetTrainingLlmParams {
  trainingLlmId: string
}

interface GetTrainingLlmResponse {
  llmTraining: TrainingLlm
}


export async function getTrainingLlm({ trainingLlmId }: GetTrainingLlmParams) {
  try {
    const response = await api.get<GetTrainingLlmResponse>(`/llm/trainings/${trainingLlmId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

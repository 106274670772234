import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import swal from 'sweetalert'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import {
  ShippingContext,
  ShippingProps,
} from '../../../contexts/ShippingContext'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { GenerateExcelReportButton } from '../../Buttons/GenerateExcelReportButton'

interface ShippingTableProps {
  shipping: ShippingProps[]
  refetch: () => void
}

const shippingOptions = [
  { key: 'companyName', value: 'Nome Fantasia', checked: false },
  { key: 'tradingName', value: 'Razão Social', checked: false },
]

const shippingOrderOptions = [{ key: 'companyName', value: 'Nome Fantasia' }]

const headers = [
  { label: 'NOME FANTASIA', key: 'company_name' },
  { label: 'RAZÃO SOCIAL', key: 'trading_name' },
  { label: 'CNPJ', key: 'cnpj' },
  { label: 'EMAIL', key: 'email' },
  { label: 'MODAL', key: 'modal' },
  { label: 'CELULAR', key: 'cellphone' },
  { label: 'TELEFONE', key: 'telephone' },
  { label: 'REGISTRO ESTADUAL', key: 'state_register' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

export function ShippingTable({ shipping, refetch }: ShippingTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedShipping, setSearchedShipping] = useState<ShippingProps[]>([])
  const [slicedShipping, setSlicedShipping] = useState<ShippingProps[]>([])

  // SEARCHBOX FILTERS
  const [companyFiltered, setCompanyFiltered] = useState('')
  const [socialFiltered, setSocialFiltered] = useState('')
  // ---------------------------------------------------------------------------- //

  // HOOKS
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useAuth()
  // ---------------------------------------------------------------------------- //

  // CONTEXTS
  const { deleteShipping } = useContext(ShippingContext)
  // ---------------------------------------------------------------------------- //

  // PAGINATION PROPS
  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: slicedShipping?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })
  // ---------------------------------------------------------------------------- //

  const csvReportProps = {
    data: shipping,
    headers,
    filename: `relatório-transportadoras`
  }

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(shippingOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(shippingOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      setSlicedShipping(shipping)
    }

    run()
  }, [itemLimit, currentPage, offset, shipping])

  useEffect(() => {
    function run() {
      const newSlicedShipping = slicedShipping?.filter((shipping) => {
        const companyFilter = searchBoxFilter(
          shipping.company_name,
          companyFiltered,
        )
        const socialFilter = searchBoxFilter(
          shipping.trading_name,
          socialFiltered,
        )

        if (companyFiltered === '' && socialFiltered === '') return shipping

        return companyFilter && socialFilter
      })

      if (orderOptionSelected.length > 0) {
        const slicedShippingByOrder = newSlicedShipping.sort(
          (a, b) =>
            (orderOptionSelected.includes('companyName') &&
              a.company_name.localeCompare(b.company_name)) ||
            0,
        )
        setSearchedShipping(slicedShippingByOrder)
      } else {
        setSearchedShipping(newSlicedShipping)
      }
    }
    run()
  }, [companyFiltered, socialFiltered, slicedShipping, orderOptionSelected])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteShipping(shippingId: string) {
    await swal({
      title: 'Deseja deletar uma transportadora?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        deleteShipping(shippingId).then((res) => {
          if (res) {
            refetch()
          }
        })
      } else {
        swal('Ação cancelada com êxito!')
      }
    })
  }

  return (
    <>
      <TableFilterButton />
      <Stack mt="4" direction="column">
        {filterOptions
          .filter((option) => option.checked === true)
          .map((option) => (
            <Fragment key={option.key}>
              <Flex>
                {option.key === 'companyName' && (
                  <SearchBox
                    placeholder="Buscar Nome Fantasia..."
                    handleSearch={(e) => setCompanyFiltered(e.target.value)}
                  />
                )}
                {option.key === 'tradingName' && (
                  <SearchBox
                    placeholder="Buscar Razão Social..."
                    handleSearch={(e) => setSocialFiltered(e.target.value)}
                  />
                )}
              </Flex>
            </Fragment>
          ))}
      </Stack>
      <Box overflowX="auto" w="100%">
        <Table colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {shippingOptions.map((option) => (
                <Th key={option.key} fontSize="12" color="blue.900">
                  {option.value.toUpperCase()}
                </Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchedShipping.map((ship, index) => (
              <Tr key={index}>
                <Td fontSize="12">{ship.company_name.toUpperCase()}</Td>
                <Td fontSize="12">{ship.trading_name.toUpperCase()}</Td>
                <Td isNumeric>
                  {!!userLogged && (
                    <>
                      {userLogged.permissions.includes('view-shipping') && (
                        <Link to={`/transportadora/visualizar/${ship.id}`}>
                          <Icon cursor="pointer" as={FiEye} fontSize="20" />
                        </Link>
                      )}
                      {userLogged.permissions.includes('edit-shipping') && (
                        <Link to={`/transportadora/editar/${ship.id}`}>
                          <Icon
                            cursor="pointer"
                            as={FiEdit3}
                            fontSize="20"
                            ml="4"
                            mt={['2', '2', '0']}
                          />
                        </Link>
                      )}
                      {userLogged.permissions.includes('remove-shipping') && (
                        <Icon
                          cursor="pointer"
                          as={FiTrash2}
                          fontSize="20"
                          ml="4"
                          mt={['2', '2', '0']}
                          onClick={() => handleDeleteShipping(ship.id)}
                        />
                      )}
                    </>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <GenerateExcelReportButton csvReportProps={csvReportProps} />
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}

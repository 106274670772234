import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

export interface EditVaccineBody {
  cardBackPhotoId?: string
  cardFrontPhotoId?: string
  doses: string[],
  nextDoseDate: string
  pending: boolean
}

interface EditVaccineParams {
  vaccineId: string
  body: EditVaccineBody
}

export async function editVaccine({
  vaccineId,
  body
}: EditVaccineParams) {
  try {
    await api.put(`/vaccines/${vaccineId}`, {
      cardBackPhotoId: body.cardBackPhotoId,
      cardFrontPhotoId: body.cardFrontPhotoId,
      doses: body.doses,
      nextDoseDate: body.nextDoseDate,
      pending: body.pending
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ReceivePmcProductBody {
  deliveredQuantity: number
}

interface ReceivePmcProductParams {
  pmcId: string
  productId: string
  body: ReceivePmcProductBody
}

interface ReceivePmcProductResponse {
  meta: {
    received: boolean
  }
}

export async function receivePmcProduct({
  pmcId,
  productId,
  body: {
    deliveredQuantity,
   }
}: ReceivePmcProductParams) {
  try {
    const response = await api.patch<ReceivePmcProductResponse>(`/pmcs/${pmcId}/products/${productId}/receive`, {
      deliveredQuantity,
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

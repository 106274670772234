import axios from "axios"
import { User } from "hooks/user/dtos/User"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { Interaction } from "./interaction"

interface GetInteractionsByServiceResponse {
  interactions: Array<Interaction & {
    owner: User
  }>
  totalPages: number
}

interface GetInteractionsByServiceProps {
  routeParams: { serviceId: string }
}

export async function getInteractionsByService({
  routeParams
}: GetInteractionsByServiceProps) {

  try {
    const { data } = await api.get<GetInteractionsByServiceResponse>(`/services/${routeParams.serviceId}/interactions`)

    return data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new Error("Erro inesperado!")
    }
  }
}

import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getContingencies } from "api/contingencies/getContingencies";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ContingencyTableRow } from "./components/ContingencyTableRow";
import { CreateContingency } from "./components/CreateContingency";


export function Contingencies() {

  const { userLogged } = useAuth()
  const { replace, push: redirect } = useHistory()

  const userCanViewContingencies = userLogged?.permissions?.includes(
    'view-contingencies'
  )

  const userCanCreateContingencie = userLogged?.permissions?.includes(
    'create-contingency'
  )

  useEffect(() => {
    if (!userCanViewContingencies) redirect('/')
  }, [redirect, userCanViewContingencies])

  const {
    isOpen: isCreateContingencyOpen,
    onOpen: onOpenCreateContingency,
    onClose: onCloseCreateContingency
  } = useDisclosure()



  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'

  const { data: contingenciesResult } = useQuery({
    queryKey: [
      'contingencies',
      page,
      status,
    ],
    queryFn: () => getContingencies({
      currentPage: Number(page),
      pageSize: 10,
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: contingenciesResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: contingenciesResult?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Contingências</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          {userCanCreateContingencie && (
            <IconButton
              aria-label="Criar contingência"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateContingency}
            />
          )}
        </Flex>

        <Modal
          isOpen={isCreateContingencyOpen}
          onClose={onCloseCreateContingency}
          isCentered
          size="md"
        >
          <ModalOverlay />
          <CreateContingency onClose={onCloseCreateContingency} />
        </Modal>
      </Flex>

      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Criado em</Th>
              <Th color="white">Criado por</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {contingenciesResult?.contingencies?.map((contingency) => {
              return (
                <ContingencyTableRow
                  key={contingency.id}
                  contingency={contingency}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { EmptyContentTableAlert } from "../../../../components/Alerts/EmptyContentTableAlert";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { useAuth } from "../../../../hooks/auth/useAuth";

export interface ICustomerManagmentReportData {
  protocol: number
  customer: string
  step: string
  collectDate: string
  sourceCities: string
  sourceAddressQuantity: number
  recipientName: string
  realCollectHours: string
  deliveryDate: string
  destinationCities: string
  destinationAddressQuantity: number
  receiverName: string
  realDeliveryHours: string
  unsuccessCollectsCount: number
  unsuccessCollectsAddresses: string
}

interface ICustomerManagmentReportTableProps {
  reportData: ICustomerManagmentReportData[]
}

export function CustomerManagmentReportTable({ reportData }: ICustomerManagmentReportTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: reportData?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  function handleChangePage(page: number) {
    setCurrentPage(page)
  }

  const userDoesNotHaveViewCustomerManagmentReportPermission =
    !userLogged?.permissions.includes('view-customer-managment-report')

  useEffect(() => {
    if (userDoesNotHaveViewCustomerManagmentReportPermission) {
      redirectTo("/")
    }
  }, [userDoesNotHaveViewCustomerManagmentReportPermission, redirectTo])

  return (
    <Box mt="4">
      <Box overflow="auto">
        {reportData?.length ? (
          <Table size="sm" variant="striped">
            <Thead>
              <Tr>
                <Th>PROTOCOLO</Th>
                <Th>DATA DA COLETA</Th>
                <Th>REMETENTES</Th>
                <Th>DESTINATÁRIOS</Th>
              </Tr>
            </Thead>
            <Tbody>
              {reportData.slice(offset, offset + Number(itemLimit)).map(data => {
                return (
                  <Tr key={data.protocol}>
                    <Td>{data.protocol}</Td>
                    <Td>{data.collectDate}</Td>
                    <Td>{data.receiverName}</Td>
                    <Td>{data.recipientName}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        ) : (
          <EmptyContentTableAlert
            title="Oops!"
            description={`Não foram encontrados dados com os filtros informados. Tente ajustá-los e realize a busca novamente.`}
          />
        )}
      </Box>
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}

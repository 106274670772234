import { Flex, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  business_meeting_options,
  direction_options,
  negative_positive,
  risk_rating,
  rnc_types,
} from '../../../utils/customLists'
import { RncValidateAccordion } from '../../Accordions/Rnc/RncValidateAccordion'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { StandardBackgroundForm } from '../../Form/StandardBackgroundForm'
import { Select } from '../../Inputs/SelectInput'
import { TextArea } from '../../Inputs/TextInput'

interface IFormInputProps {
  risk_rating: string
  rnc_type: string | null
  has_alignment: string
  alignment_observation: string | null
  business_meeting: string | null
}

interface IRncFormProps {
  title: string
  rncID: string
  submit: (values: IFormInputProps) => Promise<void>
}

const schema = yup.object().shape({
  risk_rating: yup.string().required('Campo Obrigatório'),
  rnc_type: yup.string(),
  has_alignment: yup.string().required('Campo Obrigatório'),

  alignment_observation: yup.string().when('has_alignment', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),
  business_meeting: yup.string().when('has_alignment', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),
})

export function ValidateRncForm({ title, submit, rncID }: IRncFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  const hasAlignmentValue = watch('has_alignment')

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(submit)} title={title}>
      <RncValidateAccordion rncID={rncID} />

      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Select
          {...register('risk_rating')}
          risk_rating={risk_rating}
          placeholder="Selecione uma opção..."
          name="risk_rating"
          label="Classificação de Risco"
          error={errors.risk_rating}
          required
        />
        <Select
          {...register('rnc_type')}
          direction={direction_options}
          rnc_types={rnc_types}
          placeholder="Selecione uma opção..."
          name="rnc_type"
          label="Causa raiz"
        />
      </Stack>
      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Select
          {...register('has_alignment')}
          negative_positive={negative_positive}
          placeholder="Selecione uma opção..."
          name="has_alignment"
          label="Gera Alinhamento?"
          error={errors.has_alignment}
          required
        />
      </Stack>

      {hasAlignmentValue === 'SIM' && (
        <>
          <Stack
            w="full"
            mt="4"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <TextArea
              {...register('alignment_observation')}
              name="alignment_observation"
              label="Observações/Alinhamento"
              error={errors.alignment_observation}
              required
            />
          </Stack>
          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <Select
              {...register('business_meeting')}
              business_meeting_options={business_meeting_options}
              placeholder="Selecione uma opção..."
              name="business_meeting"
              label="Reunião de Tratativa"
              error={errors.business_meeting}
              required
            />
          </Stack>
        </>
      )}

      <Flex align="center" justify="flex-end" mt="4">
        <Stack direction="row">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          <ListButton
            href="/rnc/pendentes-validacao"
            name="Lista de Rnc Pendentes"
          />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}

import { usePagination } from "@ajna/pagination"
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { Pagination } from "components/Pagination/Pagination"
import { SearchBox } from "components/SearchBox/SearchBox"

import { useAuth } from "hooks/auth/useAuth"
import { useBranches } from "hooks/branch/useBranches"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { useServices } from "hooks/services/useServices"
import { useEffect, useReducer } from "react"
import { useForm, useWatch } from "react-hook-form"
import { FaCheck } from "react-icons/fa"
import { useHistory } from "react-router-dom"
import { searchBoxFilter } from "utils/searchBoxFilter"
import { ValidateUnsuccessBoardModal } from "./components/ValidateUnsuccessBoardModal"

interface UnsuccessBoardServicesFilterOptionsInputs {
  protocol: string
  customer: string
  shipping: string
  sourceBranch: string
  destinationBranch: string
}

const unsuccessBoardServicesFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'sourceBranch', value: 'Base origem', checked: false },
  { key: 'destinationBranch', value: 'Base destino', checked: false },
]

interface ActionPayload {
  id: string
  unsuccessReason: string
  unsuccessPhoto: string
  unsuccessObservation: string | null
}

interface Action {
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  return { ...action.payload }
}

export function UnsuccessBoardServicesToValidate() {
  const [unsuccessBoardModalState, dispatch] = useReducer(reducer, {
    id: '',
    unsuccessReason: '',
    unsuccessPhoto: '',
    unsuccessObservation: null
  })

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()

  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('validate-unsuccess-board-service')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const { data: services, isFetching: isFetchingServices } = useServices({
    queryParams: { steps: ['toValidateUnsuccessBoard'] }
  })
  const { data: branches, isFetching: isFetchingBranches } = useBranches()

  const {
    isOpen: isValidateUnsuccessBoardModalOpen,
    onOpen: onOpenValidateUnsuccessBoardModal,
    onClose: onCloseValidateUnsuccessBoardModal
  } = useDisclosure()

  const { filterOptions, onLoadSetFilterOptions } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(unsuccessBoardServicesFilterOptions)
  }, [onLoadSetFilterOptions])

  const { control, register } = useForm<UnsuccessBoardServicesFilterOptionsInputs>()

  const [
    protocolFiltered,
    customerFiltered,
    shippingFiltered,
    sourceBranchFiltered,
    destinationBranchFiltered
  ] = useWatch<UnsuccessBoardServicesFilterOptionsInputs, Array<keyof UnsuccessBoardServicesFilterOptionsInputs>>({
    control,
    name: [
      'protocol',
      'customer',
      'shipping',
      'sourceBranch',
      'destinationBranch'
    ]
  })

  const servicesFiltered = services
    ?.map(service => {
      const isCrossdockingService =
        service.serviceIDRequested.source_crossdocking_branch_id !== null


      const isCrossdockingBoardUnsuccess = isCrossdockingService
        ? service.serviceIDBoard.find(board => {
          return board.branch_id === service.serviceIDRequested.source_branch_id
        })?.step === 'UNSUCCESS'
        : false

      const sourceBranch = isCrossdockingService
        ? isCrossdockingBoardUnsuccess
          ? branches?.find(branch => {
            return branch.id === service.serviceIDRequested.source_branch_id
          })
          : branches?.find(branch => {
            return branch.id === service.serviceIDRequested.source_crossdocking_branch_id
          })
        : branches?.find(branch => {
          return branch.id === service.serviceIDRequested.source_branch_id
        })

      const destinationBranch = isCrossdockingService
        ? isCrossdockingBoardUnsuccess
          ? branches?.find(branch => {
            return branch.id === service.serviceIDRequested.destination_crossdocking_branch_id
          })
          : branches?.find(branch => {
            return branch.id === service.serviceIDRequested.destination_branch_id
          })
        : branches?.find(branch => {
          return branch.id === service.serviceIDRequested.destination_branch_id
        })

      return {
        ...service,
        sourceBranch,
        destinationBranch
      }
    })
    ?.filter(service => {
      const protocolFilter = protocolFiltered
        ? searchBoxFilter(String(service.protocol), protocolFiltered)
        : true

      const customerFilter = customerFiltered
        ? searchBoxFilter(service.customerIDService.trading_firstname, customerFiltered)
        : true

      const shippingFilter = shippingFiltered
        ? searchBoxFilter(service.sourceBranch.shippingIDBranch.trading_name, shippingFiltered)
        : true

      const sourceBranchFilter = sourceBranchFiltered
        ? searchBoxFilter(service.sourceBranch.nickname, sourceBranchFiltered)
        : true

      const destinationBranchFilter = destinationBranchFiltered
        ? searchBoxFilter(service.destinationBranch.nickname, destinationBranchFiltered)
        : true

      return protocolFilter && customerFilter && shippingFilter && sourceBranchFilter && destinationBranchFilter
    })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: servicesFiltered?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  const handlePageChange = (page: number) => setCurrentPage(page)

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setCurrentPage(1)
    }, 500)

    return () => clearTimeout(debounceTimeout)
  }, [
    setCurrentPage,
    protocolFiltered,
    customerFiltered,
    shippingFiltered,
    sourceBranchFiltered,
    destinationBranchFiltered
  ])

  function handleSetUnsuccessBoardModalState(
    id,
    unsuccessReason: string,
    unsuccessPhoto: string,
    unsuccessObservation: string | null
  ) {
    dispatch({
      payload: {
        id,
        unsuccessPhoto,
        unsuccessReason,
        unsuccessObservation
      }
    })

    onOpenValidateUnsuccessBoardModal()
  }

  return (

      <Box
        borderRadius='8px'
        p={['2', '4']}
        bg='white'
      >
        <Flex
          gap={4}
          direction='column'
        >
          <ValidateUnsuccessBoardModal
            isOpen={isValidateUnsuccessBoardModalOpen}
            onClose={onCloseValidateUnsuccessBoardModal}
            unsuccessBoardInfo={unsuccessBoardModalState}
          />

          <Heading size='lg'>Embarques sem sucesso para validação</Heading>

          <Divider />

          <Flex
            w='full'
            direction={['column', 'column', 'row']}
            gap={2}
            justify='space-between'
          >
            <Flex>
              <TableFilterButton />
            </Flex>
            <Stack direction='column'>
              {
                filterOptions
                  .filter(option => option.checked)
                  .map(option => {
                    return (
                      <SearchBox
                        {...register(option.key as keyof UnsuccessBoardServicesFilterOptionsInputs)}
                        key={option.key}
                        size="sm"
                        placeholder={'Buscar ' + option.value}
                        handleSearch={() => { }}
                      />
                    )
                  })
              }
            </Stack>
          </Flex>

          {(isFetchingServices || isFetchingBranches) ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Tr>
                    {unsuccessBoardServicesFilterOptions.map(option => {
                      return (
                        <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                      )
                    })}
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {servicesFiltered?.slice(offset, offset + Number(itemLimit))?.map(service => {
                    const unsuccessBoard = service.serviceIDBoard.find(board => {
                      return board.step === 'UNSUCCESS'
                    })

                    return (
                      <Tr key={service.id}>
                        <Td>{service.protocol}</Td>
                        <Td>{service.customerIDService.trading_firstname}</Td>
                        <Td>{service.sourceBranch?.shippingIDBranch.trading_name}</Td>
                        <Td>{service.sourceBranch?.nickname}</Td>
                        <Td>{service.destinationBranch?.nickname}</Td>
                        <Td>
                          <Button
                            variant='ghost'
                            onClick={() => {
                              handleSetUnsuccessBoardModalState(
                                unsuccessBoard.id,
                                unsuccessBoard.unsuccess_reason,
                                unsuccessBoard.unsuccess_photo,
                                unsuccessBoard.board_observation
                              )
                            }}
                          >
                            <Icon as={FaCheck} />
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </Flex>

      </Box>

  )
}

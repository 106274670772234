import { Box, Flex } from "@chakra-ui/react";
import { CltIntercurrenceStatus as CltIntercurrenceStatusTypes } from "api/cltIntercurrences/types/CltIntercurrence";
import { cltIntercurrenceStatusMap } from "utils/cltIntercurrenceMappers";


interface CltIntercurrenceStatusProps {
  status: CltIntercurrenceStatusTypes;
}

export function CltIntercurrenceStatus({ status }: CltIntercurrenceStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'requested' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'approved' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'rejected' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}


      {cltIntercurrenceStatusMap[status]}
    </Flex>
  )
}

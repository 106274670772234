import { Box, Button, Circle, Flex, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt, FaTimes, FaTrash } from "react-icons/fa";
import { VacationDetail } from "./VacationDetail";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { GetVacationsResponse } from "api/vacations/getVacations";
import { format } from "date-fns";
import { Vacation } from "api/vacations/_types/Vacation";
import { deleteVacation } from "api/vacations/deleteVacation";
import { dismissVacationCollaborator } from "api/vacations/dismissVacationCollaborator";


interface VacationsKanbanCardProps {
  vacation: Vacation
}

export function VacationsKanbanCard({ vacation }: VacationsKanbanCardProps) {
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()
  const { userLogged } = useAuth()

  const userCanDismissVacationCollaborator = userLogged?.permissions.includes(
    'dismiss-vacation-collaborator'
  )

  const userCanDeleteVacation = userLogged?.permissions.includes(
    'delete-vacation'
  )

  const {
    isOpen: isVacationDetailModalOpen,
    onToggle: onToggleVacationDetailModal,
  } = useDisclosure()

  const { mutateAsync: deleteVacationFn } = useMutation({
    mutationFn: deleteVacation,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })

      queryClient.invalidateQueries({ queryKey: 'active-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planning-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planned-vacations' })
      queryClient.invalidateQueries({ queryKey: 'paying-vacations' })
      queryClient.invalidateQueries({ queryKey: 'waiting-vacations' })
      queryClient.invalidateQueries({ queryKey: 'on-vacations' })
      queryClient.invalidateQueries({ queryKey: 'done-vacations' })
      queryClient.invalidateQueries({ queryKey: 'dismissed-vacations' })
      queryClient.invalidateQueries({ queryKey: ['vacation', vacation?.id]})

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.filter(vacation => vacation.id === variables.vacationId)
        })
      })
    },
  })

  async function handleDeleteVacation() {
    await promiseMessage(deleteVacationFn({
      vacationId: vacation?.id,
    }), 'Férias excluída com sucesso!')
  }
  const { mutateAsync: dismissVacationCollaboratorFn } = useMutation({
    mutationFn: dismissVacationCollaborator,
    onSuccess(_data, variables) {
      const cached = queryClient.getQueriesData<GetVacationsResponse>({
        queryKey: ['vacations']
      })

      queryClient.invalidateQueries({ queryKey: 'active-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planning-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planned-vacations' })
      queryClient.invalidateQueries({ queryKey: 'paying-vacations' })
      queryClient.invalidateQueries({ queryKey: 'waiting-vacations' })
      queryClient.invalidateQueries({ queryKey: 'on-vacations' })
      queryClient.invalidateQueries({ queryKey: 'done-vacations' })
      queryClient.invalidateQueries({ queryKey: 'dismissed-vacations' })
      queryClient.invalidateQueries({ queryKey: ['vacation', vacation?.id]})

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        const collaboratorName = cachedValue.vacations.find(
          (vacation) => vacation.id === variables.vacationId
        )?.collaborator_name

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          vacations: cachedValue.vacations.map((vacation) => {
            if (
              vacation.id === variables.vacationId ||
              vacation.collaborator_name === collaboratorName
            ) {
              return { ...vacation, status: 'dismissed' }
            }

            return vacation
          })
        })
      })
    },
  })

  async function handleDismissVacationCollaborator() {
    await promiseMessage(dismissVacationCollaboratorFn({
      vacationId: vacation?.id,
    }), 'Demissão realizada com sucesso!')
  }



  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleVacationDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {vacation.collaborator_name}
        </Text>
        {userCanDeleteVacation && (
          <Circle
            size="5"
            color="white"
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteVacation()
            }}
          >
            <Icon as={FaTrash} boxSize={"3.5"} color='blue.900' />
          </Circle>
        )}
      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA LIMITE PARA GOZO</Text>
          </HStack>
          <Text ml={5}>{vacation.deadline ? format(new Date(vacation.deadline), 'dd/MM/yyyy') : '-'}</Text>
        </Box>

      </VStack>
      <Flex align="center" gap="2" alignItems="flex-end" justifyContent="flex-end">
        {userCanDismissVacationCollaborator && (
          <Button
            size="xs"
            lineHeight="1"
            leftIcon={<FaTimes />}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              handleDismissVacationCollaborator()
            }}
          >
            Demitir
          </Button>
        )}

      </Flex>
      <Modal
        isOpen={isVacationDetailModalOpen}
        onClose={onToggleVacationDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <VacationDetail
          vacationId={vacation.id}
        />
      </Modal>
    </Box>
  )
}

import { api } from '../../../../services/api'

export type GenerateWithdrawAuthorizationInputProps = {
  driver_id: string
}

export type GenerateWithdrawAuthorizationProps = {
  input: GenerateWithdrawAuthorizationInputProps
  service_id: string
}

const generateServiceWithdrawAuthorizationReqFunction = ({ input, service_id }: GenerateWithdrawAuthorizationProps) =>
  api
    .post(`/service/withdraw/authorization/${service_id}`, input, { responseType: 'arraybuffer' })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

export { generateServiceWithdrawAuthorizationReqFunction }

import { Box, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export function CustomerEmailList() {

  const { push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userLoggedHasViewCustomerEmailsPermission = userLogged?.permissions.includes("view-customer-emails")

  useEffect(() => {
    if (!userLoggedHasViewCustomerEmailsPermission) {
      redirect('/')
    }
  }, [userLoggedHasViewCustomerEmailsPermission, redirect])

  const customerEmails = [
    "Extra(s) de serviço",
    "Tentativa de entrega sem sucesso",
    "RNC de cliente",
    "Negociação de inadimplência",
    "Orçamento atualizado",
    "Novo orçamento",
    "Faturamento LogLife",
    "Ocorrência de retenção fiscal",
    "Validação de ocorrência",
    "Nova ocorrência de cliente",
    "Alteração de materiais solicitados",
    "Tempo de espera maior que 10 minutos",
    "Resolução de ocorrência de serviço",
    "Serviço agendado",
    "Serviço entregue",
    "Atraso no serviço",
    "Atraso na coleta",
    "Atraso na entrega",
    "Cancelamento de voo",
    "Corte do voo",
    "Novos endereços solicitados para coleta",
    "Coleta finalizada",
    "Embarque finalizado",
    "Serviço alocado",
    "Serviço disponibilizado",
    "Informações/Status da Coleta - (Sem sucesso/Realizada com sucesso)"
  ]
  return (
    <Box
      p="6"
      rounded="md"
      bg="white"
      display="flex"
      flexDirection="column"
      gap="6"
    >
      <Flex
        align="center"
        gap="2"
      >
        <Heading letterSpacing="tight">E-mails automáticos para o cliente</Heading>
      </Flex>
      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        {customerEmails.map((service) => (
          <GridItem key={service}>
            <Box
              display='flex'
              borderWidth="1px"
              borderRadius="lg"
              p="4"
              boxShadow="md"
              alignItems='center'
              h='20'
            >
              {service}
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"


export interface GetCollectorsUsersDashboardMetricsResponse {
  delayedBoardServicesCount: number
  delayedLandingServicesCount: number
  minStocksCount: number
  driversCount: number
}

export async function getCollectorsUsersDashboardMetrics() {
  try {
    const response = await api.get<GetCollectorsUsersDashboardMetricsResponse>(`/metrics/dashboard/collectors`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import {
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  Stack,
} from '@chakra-ui/react'
import { ServiceProps } from '../../../../../../contexts/ServiceContext'
import { formatDate } from '../../../../../../utils/DateFunctions/formatDate'
import { Input } from '../../../../../../components/Inputs/Input'
import { TextArea } from '../../../../../../components/Inputs/TextInput'

interface IScheduleInfoProps {
  service: ServiceProps
  collectorTradingName: string
  businessBudgetSourceCities: string[]
  businessBudgetDestinationCities: string[]
}

export function ScheduleInfo({
  service,
  businessBudgetSourceCities,
  businessBudgetDestinationCities,
  collectorTradingName,
}: IScheduleInfoProps) {
  return (
    <AccordionItem>
      <AccordionButton p="4">
        <Box flex="1" textAlign="left">
          Informações de agendamento do serviço
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb="4">
        <Stack spacing="24px" mt="4">
          <Stack w="full" spacing="24px" direction="column">
            <Input
              name="businessBudget"
              label="Orçamento Business"
              defaultValue={`${businessBudgetSourceCities.join(
                ', ',
              )} > ${businessBudgetDestinationCities.join(', ')} | ${
                service.serviceIDRequestedBusiness.service_type
              } | ${service.serviceIDRequestedBusiness.vehicle}`}
              isDisabled={true}
            />
            <Input
              name="collector"
              label="Coletador"
              defaultValue={collectorTradingName}
              isDisabled={true}
            />
          </Stack>
          <Stack w="full" spacing="24px" direction="column">
            <Input
              name="vehicle"
              label="Veículo"
              defaultValue={service.serviceIDRequestedBusiness.vehicle}
              isDisabled={true}
            />
          </Stack>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              name="caixaTermica"
              label="Caixa térmica"
              defaultValue={service.serviceIDRequestedBusiness.caixa_termica}
              isDisabled={true}
            />
            <Input
              name="embalagemSecundaria"
              label="Embalagem secundária"
              defaultValue={
                service.serviceIDRequestedBusiness.embalagem_secundaria
              }
              isDisabled={true}
            />
            <Input
              name="geloSeco"
              label="Gelo seco"
              defaultValue={service.serviceIDRequestedBusiness.gelo_seco}
              isDisabled={true}
            />
            <Input
              name="gelox"
              label="Gelox"
              defaultValue={service.serviceIDRequestedBusiness.gelox}
              isDisabled={true}
            />
          </Stack>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              name="isopor3l"
              label="Isopor 3l"
              defaultValue={service.serviceIDRequestedBusiness.isopor3l}
              isDisabled={true}
            />
            <Input
              name="isopor7l"
              label="Isopor 7l"
              defaultValue={service.serviceIDRequestedBusiness.isopor7l}
              isDisabled={true}
            />
            <Input
              name="terciaria3l"
              label="Terciária 3l"
              defaultValue={service.serviceIDRequestedBusiness.terciaria3l}
              isDisabled={true}
            />
            <Input
              name="terciaria8l"
              label="Terciária 8l"
              defaultValue={service.serviceIDRequestedBusiness.terciaria8l}
              isDisabled={true}
            />
          </Stack>
          <Stack spacing="24px" direction={['column', 'column', 'row']}>
            <Input
              name="routeStartHour"
              label="Horário inicial da rota"
              defaultValue={formatDate.handle(
                service.serviceIDRequestedBusiness.route_start_date,
                'DateOnlyWithHourMinute',
              )}
              isDisabled={true}
              type="time"
            />
            <Input
              name="routeEndHour"
              label="Horário final da rota"
              defaultValue={formatDate.handle(
                service.serviceIDRequestedBusiness.route_end_hour,
                'DateOnlyWithHourMinute',
              )}
              isDisabled={true}
              type="time"
            />
          </Stack>
          <TextArea
            name="observation"
            label="Observações"
            defaultValue={service.serviceIDRequestedBusiness.observation}
            isDisabled={true}
          />
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  )
}

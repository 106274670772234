import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { StandardizationType } from "./types/ExperiencePeriod";

interface EvaluateExperiencePeriodBody {
  delaysWithoutSickNote: string
  foulsWithSickNote: string
  hasUnjustifiedAbsense: boolean
  standardization: StandardizationType
  hasRhPendingDocuments: boolean
  hasBehavioralProblems: boolean
  considerations: string
  dismiss: boolean
}


interface EvaluateExperiencePeriodProps {
  body: EvaluateExperiencePeriodBody
  routeParams: {
    experiencePeriodId: string
  }
}

export async function evaluateExperiencePeriod({
  body,
  routeParams
}: EvaluateExperiencePeriodProps) {
  try {
    const response = await api.put(`/experience-periods/${routeParams.experiencePeriodId}/evaluate`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Stack,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useInput } from '../../hooks/input/useInput'
import { useStock } from '../../hooks/stock/useStock'
import { AuthContext } from '../../contexts/AuthContext'
import { useCollectors } from 'hooks/collector/useCollectors'
import { TextArea } from 'components/Inputs/TextInput'
import { StockLogsModal } from './components/StockLogsModal'

interface FormInputProps {
  collector_id: string
  input_id: string
  minimum_stock: number
  standard_stock: number
  current_stock: number
  observation: string
}

interface CityFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

const inputSchema = yup.object().shape({
  collector_id: yup.string().required('Campo Obrigatório'),
  input_id: yup.string().required('Campo Obrigatório'),
  minimum_stock: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório!'),
  standard_stock: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório!'),
  observation: yup.string(),
})

export function StockForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: CityFormProps) {
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const { userLogged } = useContext(AuthContext)

  const {
    stock: { data: stock, isLoading: isStockLoading },
  } = useStock(id || null, false, false)
  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    inputs: { data: inputs, isLoading: isInputLoading },
  } = useInput(null, true, false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(inputSchema),
  })

  useEffect(() => {
    function run() {
      if (!isInputLoading && !isCollectorsLoading && !isStockLoading) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isInputLoading, isCollectorsLoading, isStockLoading])

  useEffect(() => {
    function run() {
      if (slug === 'editar' || slug === 'visualizar') {
        if (stock) {
          setValue('collector_id', stock.collector_id)
          setValue('input_id', stock.input_id)
          setValue('minimum_stock', stock.minimum_stock)
          setValue('standard_stock', stock.standard_stock)
          setValue('current_stock', stock.current_stock)
          setValue('observation', stock.observation)
        }
      }
    }

    run()
  }, [setValue, slug, id, stock])

  const {
    isOpen: isOpenStockLogsModal,
    onOpen: onOpenStockLogsModal,
    onClose: onCloseStockLogsModal
  } = useDisclosure()

  if (isLoadingPage && slug !== 'adicionar') {
    return <GeneralContentLoading />
  }

  return (

    <Flex>
      <Box
        as="form"
        flex="1"
        borderRadius="8px"
        bg="white"
        p={['6', '8']}
        onSubmit={handleSubmit(submit)}
        noValidate
      >
        <Flex w="full" alignItems="center" justifyContent="space-between">
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>
          <Flex justifyContent="flex-end">
            {(slug !== 'adicionar') && (
              <Button colorScheme="blue" onClick={onOpenStockLogsModal}>
                Logs
              </Button>
            )}
          </Flex>
        </Flex>
        {!isCollectorsLoading && (
        <Modal
          isOpen={isOpenStockLogsModal}
          onClose={onCloseStockLogsModal}
          isCentered
        >
          <ModalOverlay />
          <StockLogsModal
            stockId={id}
          />

        </Modal>
        )}

        <Divider my="6" borderColor="gray.700" />


        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Select
            {...register('collector_id')}
            name="collector_id"
            label="Pertence ao Coletador"
            error={errors.collector_id}
            isDisabled={isDisabled}
            collectors={collectors}
            placeholder="Selecione uma opção..."
            required
          />

          <Select
            {...register('input_id')}
            name="input_id"
            label="Insumo"
            error={errors.input_id}
            isDisabled={isDisabled}
            inputs={inputs}
            placeholder="Selecione uma opção..."
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('minimum_stock')}
              isDisabled={isDisabled}
              name="minimum_stock"
              type="number"
              label="Estoque Mínimo"
              error={errors.minimum_stock}
              required
            />
            <Input
              {...register('standard_stock')}
              isDisabled={isDisabled}
              name="standard_stock"
              type="number"
              label="Estoque Padrão"
              error={errors.standard_stock}
              required
            />
          </Stack>
          {slug !== 'adicionar' && (
            <Input
              {...register('current_stock')}
              isDisabled={
                !userLogged?.permissions.includes('edit-current-stock')
              }
              name="current_stock"
              type="number"
              label="Estoque Atual"
              error={errors.standard_stock}
              required
            />
          )}
        </Stack>
        <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
          <TextArea
            {...register('observation')}
            name="observation"
            label="Observações"
            isDisabled={isDisabled}
            error={errors.observation}
          />
        </Stack>
        <Flex mt="8" justify="flex-end">
          <HStack>
            {slug === 'visualizar' ? (
              <Link to={href}>
                <Button
                  type="button"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              </Link>
            ) : (
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                loadingText="Carregando"
              >
                {action}
              </Button>
            )}

            <Link to="/estoques">
              <Button type="button" colorScheme="gray">
                Estoque de Insumos de Transporte
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Box>
    </Flex>

  )
}

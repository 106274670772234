import { Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { InternCltOffice } from "api/internClts/_types/internCltOffice";
import { useAuth } from "hooks/auth/useAuth";
import { FaPen, FaSearch } from "react-icons/fa";
import { EditInternCltOffice } from "./EditInternCltOffice";
import { InternCltOfficeDetail } from "./InternCltOfficeDetail";

interface InternCltOfficeTableRowProps {
  internCltOffice: InternCltOffice
}

export function InternCltOfficeTableRow({ internCltOffice }: InternCltOfficeTableRowProps) {

  const { userLogged } = useAuth()

  const userCanEditInternCltOffice = userLogged?.permissions.includes('edit-intern-clt-office')

  const {
    isOpen: isInternCltOfficeDetailOpen,
    onOpen: onOpenInternCltOfficeDetail,
    onClose: onCloseInternCltOfficeDetail
  } = useDisclosure()

  const {
    isOpen: isEditInternCltOfficeOpen,
    onOpen: onOpenEditInternCltOffice,
    onClose: onCloseEditInternCltOffice
  } = useDisclosure()


  return (
    <Tr>
      <Td>
        <Flex
          align="center"
          w="full"
          gap={3}
        >
          <Modal
            isOpen={isInternCltOfficeDetailOpen}
            onClose={onCloseInternCltOfficeDetail}
            size="2xl"
          >
            <ModalOverlay />

            <InternCltOfficeDetail officeId={internCltOffice.id} />

          </Modal>
          <IconButton
            aria-label="Abrir detalhes do cargo interno CLT"
            icon={<FaSearch />}
            variant="ghost"
            size="sm"
            onClick={onOpenInternCltOfficeDetail}
          />
          {userCanEditInternCltOffice && (
            <>
              <IconButton
                aria-label="Editar cargo interno CLT"
                icon={<FaPen />}
                variant="ghost"
                size="sm"
                onClick={onOpenEditInternCltOffice} /><Modal
                  isOpen={isEditInternCltOfficeOpen}
                  onClose={onCloseEditInternCltOffice}
                  size="2xl"
                >
                <ModalOverlay />

                <EditInternCltOffice
                  officeId={internCltOffice.id}
                  onClose={onCloseInternCltOfficeDetail} />

              </Modal>
            </>
          )}
        </Flex>
      </Td>

      <Td>{internCltOffice.name}</Td>

    </Tr>
  )
}

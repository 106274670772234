import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

export interface SendTrainingAttachmentBody {
  trainingAttachmentId: string
  certificateAttachmentId: string
  notes: string
  dueDate: string
  realizationDate: string
}

interface SendTrainingAttachmentParams {
  trainingId: string
  body: SendTrainingAttachmentBody
}

export async function sendTrainingAttachments({
  trainingId,
  body
}: SendTrainingAttachmentParams) {
  try {
    await api.put(`trainings/${trainingId}/attachments`, {
      trainingAttachmentId: body.trainingAttachmentId,
      certificateAttachmentId: body.certificateAttachmentId,
      notes: body.notes,
      dueDate: body.dueDate,
      realizationDate: body.realizationDate
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


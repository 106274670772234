import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DeliveriesWithoutQRCodePendingValidation } from "./Delivery/DeliveriesWithoutQRCodePendingValidation";
import { ValidatePendingLandingServices } from "./ValidatePendingLandingServices/ValidatePendingLandingServices";

export function ServiceValidateWithoutQrList() {
  const searchParams = useSearchParams()
  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanValidateFinishedLandingWithoutQRCode = userLogged?.permissions.includes('validate-finished-landing-without-qr-code')

  const userCanValidateFinishedDeliveryWithoutQRCode = userLogged?.permissions.includes('validate-finished-delivery-without-qr-code')

  useEffect(() => {
    if (!userCanValidateFinishedLandingWithoutQRCode && !userCanValidateFinishedDeliveryWithoutQRCode) {
      history.push('/')
    }
  }, [history, userCanValidateFinishedDeliveryWithoutQRCode, userCanValidateFinishedLandingWithoutQRCode])

  function handleChangeTab() {
    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }
  return (
    <Box
      p="6"
      rounded="md"
      bg="white"
      display="flex"
      flexDirection="column"
      gap="6"
    >
      <Tabs isLazy onChange={handleChangeTab}>
        <TabList>
          <Tab>Desembarques</Tab>
          <Tab>Entregas</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ValidatePendingLandingServices/>
          </TabPanel>
          <TabPanel>
            <DeliveriesWithoutQRCodePendingValidation/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )

}

import { Button, Flex, Icon, Spinner } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import * as yup from "yup"
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { FaList } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import { ExtrasAndDiscountsForm, ExtrasAndDiscountsFormInputs } from "./components/ExtrasAndDiscountsForm";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { useBusinessBudgetRoutes } from "hooks/businessBudget/useBusinessBudgetsRoutes";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";
import { useExtraAndDiscount } from "hooks/extrasAndDiscounts/useExtraAndDiscount";

interface QueryParams {
  slug: string
  id: string
}

const createExtrasAndDiscountsFormSchema = yup.object().shape({
  customer_id: yup.string().required('Campo obrigatório.'),
  type: yup.string().required('Campo obrigatório.'),
  service_type: yup.string().required('Campo obrigatório.'),
  protocol: yup.string().when('service_type', {
    is: 'EXPRESS',
    then: yup.string().required('Campo obrigatório.')
  }),
  route: yup.string().when('service_type', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório.')
  }),
  description: yup.string().required('Campo obrigatório.'),
  value: yup.string().required('Campo obrigatório.'),
})

export function ViewExtrasAndDiscounts() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const { id } = useParams<QueryParams>()

  const { data: extraAndDiscountData, isFetching: isFetchingExtraAndDiscountData } = useExtraAndDiscount({ extraAndDiscountId: id, queryOptions: { enabled: !!id } })

  const formMethods = useForm<ExtrasAndDiscountsFormInputs>({
    resolver: yupResolver(createExtrasAndDiscountsFormSchema)
  })

  const { control, handleSubmit, setValue } = formMethods

  const userLoggedHasPermissionToCreateExtrasAndDiscounts =
    userLogged?.permissions.includes('view-extras-and-discounts')

  useEffect(() => {
    if (!userLoggedHasPermissionToCreateExtrasAndDiscounts) {
      redirect('/')
    }
  }, [userLoggedHasPermissionToCreateExtrasAndDiscounts, redirect])

  useEffect(() => {
    if (extraAndDiscountData) {
      setValue('customer_id', extraAndDiscountData?.extraAndDiscount?.customer.id)
      setValue('type', extraAndDiscountData?.extraAndDiscount.type)
      setValue('service_type', extraAndDiscountData?.extraAndDiscount.service_type)
      setValue('description', extraAndDiscountData?.extraAndDiscount.description)
      setValue('value', String(extraAndDiscountData?.extraAndDiscount.value / 100))
      setValue('protocol', extraAndDiscountData?.extraAndDiscount.protocol)
      setValue('occurrence_date', extraAndDiscountData?.extraAndDiscount?.occurrence_date)
    }
  }, [extraAndDiscountData, setValue])

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers()

  const [customerSelected, serviceType] = useWatch({
    control,
    name: ['customer_id', 'service_type']
  })

  const isBusinessServiceType = serviceType === 'BUSINESS'

  const {
    data: businessBudgetRoutesData,
    isFetching: isFetchingBusinessBudgetRoutesData
  } = useBusinessBudgetRoutes({
    customer_id: customerSelected,
    queryOptions: {
      enabled: !!customerSelected && isBusinessServiceType
    }
  })

  const businessBudgetRoutesOptions = businessBudgetRoutesData?.routes.map(
    (route) => {
      return {
        key: route.id,
        value: route.route_nickname,
        showOption: route.route_nickname
      }
    }
  )

  const customersOptions = customersData?.customers.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(() => { })}
      title='Visualizar extras e descontos'
    >
      {isCustomersDataFetching || isFetchingExtraAndDiscountData ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ExtrasAndDiscountsForm
            slug={'view'}
            customerOptions={customersOptions}
            businessBudgetRoutesOptions={businessBudgetRoutesOptions}
            isFetchingBusinessBudgetRoutesData={isFetchingBusinessBudgetRoutesData}
            attachmentPreview={extraAndDiscountData?.extraAndDiscount.attachment}
          />
        </FormProvider>
      )}

      <Flex w='full' mt={4} gap={2} justify='flex-end'>
        <Button
          as={Link}
          to='/extras-e-descontos'
          leftIcon={<Icon as={FaList} />}
        >
          Extras e descontos
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

import * as yup from "yup"
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { Select } from "components/Inputs/SelectInput"
import { ThirdPartyRnc } from "hooks/rnc/dtos/ThirdPartyRnc"
import { useThirdPartyRncFunctions } from "hooks/rnc/useThirdPartyRncFunctions"
import { useToastify } from "hooks/toastify/useToastify"
import { useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import { rncRiskRatingOptions } from "utils/CustomLists/rncRiskRatingOptions"
import { rncValidationCauseOptions } from "utils/CustomLists/rncValidationCauseOptions"
import { ViewThirdPartyRncInfoModal } from "./ViewThirdPartyRncInfoModal"
import { yupResolver } from "@hookform/resolvers/yup"

interface ValidateThirdPartyRncInputs {
  risk_rating: string
  validation_cause: string
}

interface ValidateThirdPartyRncModalProps {
  isOpen: boolean
  onClose: () => void
  thirdPartyRnc: ThirdPartyRnc
}

const validateThirdPartyRncFormSchema = yup.object().shape({
  risk_rating: yup.string().required('Campo obrigatório.'),
  validation_cause: yup.string().required('Campo obrigatório.')
})

export function ValidateThirdPartyRncModal({
  isOpen,
  onClose,
  thirdPartyRnc
}: ValidateThirdPartyRncModalProps) {

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ValidateThirdPartyRncInputs>({
    resolver: yupResolver(validateThirdPartyRncFormSchema)
  })

  const {
    isOpen: isViewThirdPartyRncInfoModalOpen,
    onOpen: onOpenThirdPartyRncInfoModalOpen,
    onClose: onCloseThirdPartyRncInfoModalOpen,
  } = useDisclosure()

  const handleOpenViewThirdPartyRnc = () => onOpenThirdPartyRncInfoModalOpen()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    validateThirdPartyRnc: { mutateAsync: validateThirdPartyRnc }
  } = useThirdPartyRncFunctions()

  async function handleValidateThirdPartyRnc(values: ValidateThirdPartyRncInputs) {
    await promiseMessage(validateThirdPartyRnc({
      id: thirdPartyRnc.id,
      input: values
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('thirdPartyRncs')
        onClose()
      }
    }), 'Rnc validado com sucesso!')
  }


  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        as='form'
        onSubmit={handleSubmit(handleValidateThirdPartyRnc)}
        noValidate
      >
        <ModalHeader>Validar rnc</ModalHeader>
        <ModalCloseButton />

        <ViewThirdPartyRncInfoModal
          isOpen={isViewThirdPartyRncInfoModalOpen}
          onClose={onCloseThirdPartyRncInfoModalOpen}
          thirdPartyRnc={thirdPartyRnc}
        />

        <ModalBody>
          <Flex direction='column' gap={2}>

            <Button
              size='sm'
              variant='outline'
              onClick={handleOpenViewThirdPartyRnc}
            >
              Informações da rnc
            </Button>

            <Select
              {...register('risk_rating')}
              name='risk_rating'
              options={rncRiskRatingOptions}
              error={errors.risk_rating}
              label='Classificação de risco'
              placeholder='Selecione uma opção...'
              required
            />

            <Select
              {...register('validation_cause')}
              name='validation_cause'
              options={rncValidationCauseOptions}
              error={errors.validation_cause}
              label='Causa raiz'
              placeholder='Selecione uma opção...'
              required
            />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex gap={4} w='full' direction={['column', 'column', 'row']}>
            <Button
              w='full'
              variant='ghost'
              onClick={onClose}
              isLoading={isSubmitting}
            >
              Fechar
            </Button>
            <Button
              w='full'
              colorScheme='blue'
              type='submit'
              isLoading={isSubmitting}
            >
              Validar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>

    </Modal>
  )
}

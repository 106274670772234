import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"


interface GetDriverSchedulingsByDateProps {
  queryParams: {
    date: Date
    timezoneOffset: number
    customerId?: string
  }
}

interface GetDriverSchedulingsByDateResponse {
  driverSchedulings: {
    id: string
    date: string
    service: {
      id: string
      protocol: number
    }
    driver: {
      id: string
      firstname: string
      lastname: string
    }
    customer: {
      id: string
      trading_firstname: string
    }
  }[]
}

export async function getDriverSchedulingsByDate({
  queryParams,
}: GetDriverSchedulingsByDateProps) {
  try {
    const response = await api.get<GetDriverSchedulingsByDateResponse>(`/drivers/schedulings-by-date`, {
      params: queryParams
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

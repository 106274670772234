import { Box, Button, Divider, FormControl, Heading, IconButton, Input, Select, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useCollectors } from "hooks/collector/useCollectors";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { useInput } from "hooks/input/useInput";
import { FaMinus } from "react-icons/fa";
import { createCollectorDispatchStockProposalManually } from "api/collectors/createCollectorDispatchStockProposalManually";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";

interface ProposalInput {
  collectorId: string
  inputId: string;
  materialName: string;
  sendQuantity: number;
  standardStock: number;
  currentStock: number;
  periodConsume: number;
  minStock: number;
}

interface CreateCollectorDispatchStockProposalManuallySchema {
  collectorId: string;
  proposalInputs: ProposalInput[];
}

const createColllectorDispatchStockProposalManuallySchema = yup.object({
  collectorId: yup.string().required(),
  proposalInputs: yup.array().min(1).of(
    yup.object({
      materialName: yup.string().required(),
      sendQuantity: yup.number().required(),
      standardStock: yup.number().required(),
      currentStock: yup.number().required(),
      periodConsume: yup.number().required(),
      minStock: yup.number().required(),
    })
  )
})

export function CreateCollectorDispatchStockProposalManually() {
  const { push: redirect } = useHistory();

  const { userLogged } = useAuth()

  const userHasCreateDispatchStockProposalPermission = userLogged?.permissions.includes('add-dispatch-stock-proposal')

  useEffect(() => {
    if (!userHasCreateDispatchStockProposalPermission) {
      redirect('/')
    }
  }, [userHasCreateDispatchStockProposalPermission, redirect])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateCollectorDispatchStockProposalManuallySchema>({
    resolver: yupResolver(createColllectorDispatchStockProposalManuallySchema)
  })

  console.log(errors)

  const {
    fields: proposalInputsFields,
    append: appendProposalInputField,
    remove: removeProposalInputField
  } = useFieldArray({
    control,
    name: "proposalInputs",
  });



  const { data: collectors, isFetching: isFetchingCollectors } = useCollectors({
    queryParams: {
      situation: "ATIVO",
    },
  });

  const {
    inputs: { data: inputsData, isLoading: isInputLoading },
  } = useInput(null, true, false)

  const handleAddRow = () => {
    appendProposalInputField({
      inputId: "",
      materialName: "",
      sendQuantity: 0,
      standardStock: 0,
      currentStock: 0,
      periodConsume: 0,
      minStock: 0
    });
  };

  const queryClient = useQueryClient();
  const { promiseMessage } = useToastify();


  const { mutateAsync: createCollectorDispatchStockProposalManuallyFn } = useMutation({
    mutationFn: createCollectorDispatchStockProposalManually,
    onSuccess() {
      queryClient.invalidateQueries(['dispatchStockProposals'])
    }
  })

  async function handleCreateCollectorDispatchStockProposalManually({ proposalInputs, collectorId }: CreateCollectorDispatchStockProposalManuallySchema) {

    const proposalInputsFormatted = proposalInputs.reduce((acc, curr) => {
      const materialInfo = inputsData.find(
        (input) => input.name === curr.materialName
      )

      if (materialInfo) {
        acc.push({
          ...curr,
          inputId: materialInfo.id
        })
      }

      return acc
    }, [])


    await promiseMessage(createCollectorDispatchStockProposalManuallyFn({
      body: {
        proposalInputs: proposalInputsFormatted
      },
      collectorId: collectorId
    }), 'Proposta de envio de materiais criada com sucesso!')

    redirect('/estoques/despachos/propostas');
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
      rounded="md"
      bg="white"
      boxShadow="md"
      p="6"
    >
      <Heading letterSpacing="tight">Criar Proposta de Despacho de Estoque Manual</Heading>
      <Divider />
      {isFetchingCollectors || isInputLoading ? (
        <Spinner />
      ) : (
        <Box
          as="form"
          display="flex"
          flexDirection="column"
          gap="4"
          onSubmit={handleSubmit(handleCreateCollectorDispatchStockProposalManually)}>
          <FormControl
            isInvalid={!!errors.collectorId}
          >
            <Select
              {...register("collectorId")}
              placeholder="Selecione um coletador..."
              size="md"
            >
              {collectors?.map((collector) => (
                <option key={collector.id} value={collector.id}>{collector.trading_name}</option>
              ))}
            </Select>
          </FormControl>

          <TableContainer rounded="md" borderColor="gray.100">
            <Table size="sm">
              <Thead h="40px" bg="#38C3FA">
                <Tr>
                  <Th color="white">Material</Th>
                  <Th color="white">Estoque atual</Th>
                  <Th color="white">Estoque padrão</Th>
                  <Th color="white">Estoque mínimo</Th>
                  <Th color="white">Consumo nos últimos 30 dias</Th>
                  <Th color="white">Quantidade a enviar</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {proposalInputsFields.map((field, index) => (
                  <Tr key={field.id}>
                    <Td>
                      <FormControl
                        isInvalid={
                          errors.proposalInputs &&
                            errors?.proposalInputs[index]?.materialName ? true : false}
                      >
                        <Select
                          {...register(`proposalInputs.${index}.materialName`)}
                          placeholder="Selecione um material..."
                          size="sm"
                          defaultValue={field.materialName}
                          rounded="md"
                        >
                          {inputsData?.map((input) => (
                            <option
                              key={input.id}
                              value={input.name}
                              disabled={
                                proposalInputsFields.some((f) => f.materialName === input.name && f.materialName !== field.materialName)}
                            >
                              {input.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                    </Td>
                    <Td>
                      <FormControl
                        isInvalid={
                          errors.proposalInputs &&
                            errors?.proposalInputs[index]?.currentStock ? true : false}
                      >
                        <Input
                          {...register(`proposalInputs.${index}.currentStock`)}
                          defaultValue={field.currentStock}
                          size="sm"
                          rounded="md"
                        />
                      </FormControl>
                    </Td>
                    <Td>
                      <FormControl
                        isInvalid={
                          errors.proposalInputs &&
                            errors?.proposalInputs[index]?.standardStock ? true : false}
                      >
                        <Input
                          {...register(`proposalInputs.${index}.standardStock`)}
                          defaultValue={field.standardStock}
                          size="sm"
                          rounded="md"
                        />
                      </FormControl>
                    </Td>
                    <Td>
                      <FormControl
                        isInvalid={
                          errors.proposalInputs &&
                            errors?.proposalInputs[index]?.minStock ? true : false}
                      >
                        <Input
                          {...register(`proposalInputs.${index}.minStock`)}
                          defaultValue={field.minStock}
                          size="sm"
                          rounded="md"
                        />
                      </FormControl>
                    </Td>
                    <Td>
                      <FormControl
                        isInvalid={
                          errors.proposalInputs &&
                            errors?.proposalInputs[index]?.periodConsume ? true : false}
                      >

                        <Input
                          {...register(`proposalInputs.${index}.periodConsume`)}
                          defaultValue={field.periodConsume}
                          size="sm"
                          rounded="md"
                        />
                      </FormControl>
                    </Td>
                    <Td>
                      <FormControl
                        isInvalid={
                          errors.proposalInputs &&
                            errors?.proposalInputs[index]?.sendQuantity ? true : false}
                      >
                        <Input
                          {...register(`proposalInputs.${index}.sendQuantity`)}
                          defaultValue={field.sendQuantity ?? 0}
                          size="sm"
                          rounded="md"
                        />
                      </FormControl>
                    </Td>
                    <Td>
                      <IconButton
                        aria-label="Remover material"
                        icon={<FaMinus />}
                        size="sm"
                        color='red'
                        colorScheme='whiteAlpha'
                        onClick={() => removeProposalInputField(index)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Button colorScheme="blue" boxShadow="md" type="button" onClick={handleAddRow} w="min" alignSelf="flex-end">
            Adicionar Material
          </Button>
          <Button
            colorScheme="blue"
            boxShadow="md"
            type="submit"
            w="min"
            alignSelf="flex-end"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Solicitar
          </Button>
        </Box>
      )}
    </Box>
  );
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverInProgressNoticeDetails } from "api/cltDrivers/sendCltDriverInProgressNoticeDetails";

interface SendCltDriverInProgressNoticeDetailsProps {
  cltDriverId: string
}

export interface SendCltDriverInProgressNoticeDetailsSchema {
  doesInNoticeReturnedPatrimonies: string
  hasDiscountedMeetAndFoodAndFuelVouchers: string
}

const sendCltDriverInProgressNoticeDetailsSchema = yup.object({
  doesInNoticeReturnedPatrimonies: yup.string().required(),
  hasDiscountedMeetAndFoodAndFuelVouchers: yup.string().required(),
})

export function SendCltDriverInProgressNoticeDetails({ cltDriverId }: SendCltDriverInProgressNoticeDetailsProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverInProgressNoticeDetailsSchema>({
    resolver: yupResolver(sendCltDriverInProgressNoticeDetailsSchema)
  })


  const { mutateAsync: sendCltDriverInProgressNoticeDetailsFn } = useMutation({
    mutationFn: sendCltDriverInProgressNoticeDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverInProgressNoticeDetails(values: SendCltDriverInProgressNoticeDetailsSchema) {
    await promiseMessage(sendCltDriverInProgressNoticeDetailsFn({
      body: {
        doesInNoticeReturnedPatrimonies: values.doesInNoticeReturnedPatrimonies === 'yes',
        hasDiscountedMeetAndFoodAndFuelVouchers: values.hasDiscountedMeetAndFoodAndFuelVouchers === 'yes'
      }, cltDriverId
    }), 'Informações de Aviso prévio em andamento enviadas!')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverInProgressNoticeDetails)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação - Cumprindo aviso prévio</Heading>
      <FormControl isInvalid={!!errors.doesInNoticeReturnedPatrimonies} mt={3}>
        <FormLabel>
          Patrimônios devolvidos?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesInNoticeReturnedPatrimonies"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.hasDiscountedMeetAndFoodAndFuelVouchers} mt={3}>
        <FormLabel>
          Descontou VA/VR/Combustível?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasDiscountedMeetAndFoodAndFuelVouchers"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

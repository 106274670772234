import axios from "axios"
import { Address } from "hooks/address/dtos/Address"
import { api } from "services/api"
import { IDriverProps } from "services/getFunctions/driver/getDrivers"
import { RequestError } from "utils/errors/RequestErrors"
import { Service } from "./_types/Service"

interface GetDeliveriesAssignedDriversByServiceIdResponse {
  deliveriesAssignedDrivers: {
    id: string
    volume: number | null
    address: Address
    driver: IDriverProps
    service: Pick<Service, 'id' | 'protocol'> & {
      type: 'DEDICADO' | 'FRACIONADO'
    }
  }[]
}

interface GetDeliveriesAssignedDriversByServiceIdParams {
  serviceId: string
}

export async function getDeliveriesAssignedDriversByServiceId({
  serviceId
}: GetDeliveriesAssignedDriversByServiceIdParams) {
  try {
    const { data } = await api.get<GetDeliveriesAssignedDriversByServiceIdResponse>(`/services/${serviceId}/deliveries/assigned-drivers`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

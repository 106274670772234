import { Flex, IconButton, Link, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Skeleton, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { deleteAttachmentInContingency } from "api/contingencies/deleteAttachmentInContingency";
import { getContingency, GetContingencyResponse } from "api/contingencies/getContingency";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaExternalLinkAlt, FaTrashAlt } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query"
import { captalize } from "utils/captalize";
import { AddAttachmentContingency } from "./AddAttachmentContingency";

interface ContingencyDetailsProps {
  contingencyId: string
}

export function ContingencyDetails({
  contingencyId,
}: ContingencyDetailsProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { userLogged } = useAuth()


  const userCanDeleteAttachmentContingency = userLogged?.permissions?.includes(
    'delete-attachment-contingency'
  )

  const userCanAddAttachmentContingency = userLogged?.permissions?.includes(
    'add-attachment-contingency'
  )

  const {
    data: contingencyResult,
    isLoading: isLoadingContingencyResult
  } = useQuery({
    queryKey: ['contingency', contingencyId],
    queryFn: () => getContingency({ contingencyId })
  })



  const { mutateAsync: deleteAttachmentInContingencyFn } = useMutation({
    mutationFn: deleteAttachmentInContingency,
    onSuccess(_data, { contingencyId, attachmentId }) {

      queryClient.invalidateQueries(['contingency', contingencyId]);

      const cachedContingency = queryClient.getQueryData<GetContingencyResponse>(['contingency', contingencyId]);

      if (cachedContingency) {
        queryClient.setQueryData(['contingency', contingencyId], {
          ...cachedContingency,
          attachments: cachedContingency?.contingency?.attachments.filter(attachment => attachment.id !== attachmentId)
        });
      }
    },
  });


  async function handleDeleteAttachmentContingency(attachmentId: string) {
    await promiseMessage(deleteAttachmentInContingencyFn({
      contingencyId,
      attachmentId
    }), 'Anexo excluido!')
  }


  return (
    <ModalContent maxW='750px'>
      <ModalHeader>
        Detalhes da Contingência - {contingencyResult?.contingency.name}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody
        maxH='600px'
        overflowY='scroll'
      >
        <Table
          size="sm"
        >
          {!isLoadingContingencyResult && (
            <Thead>
              <Tr
                h="30px"
              >
                <Th color="black">Nome</Th>
                <Th color="black">Criado em</Th>
                <Th color="black">Criado por</Th>
                <Th color="black"></Th>
              </Tr>
            </Thead>
          )}
          <Tbody>
            {isLoadingContingencyResult ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })
            ) : (
              <>
                {contingencyResult?.contingency?.attachments && contingencyResult?.contingency?.attachments?.map((attachment) => {
                  return (
                    <Tr key={attachment.id}>
                      <Td>{attachment?.attachment?.title}</Td>
                      <Td>
                        {format(new Date(attachment?.attachment.createdAt), "dd/MM/yyyy")}
                      </Td>
                      <Td>
                        {captalize(`${attachment?.createdByUser?.firstname} ${attachment?.createdByUser?.lastname}`)}
                      </Td>
                      <Td isNumeric>
                        <Flex>
                          <IconButton
                            aria-label="Abrir anexo"
                            as={Link}
                            href={attachment?.attachment?.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                          {userCanDeleteAttachmentContingency && (
                            <IconButton
                              aria-label="Remover anexo"
                              icon={<FaTrashAlt />}
                              size="sm"
                              ml={3}
                              onClick={() => handleDeleteAttachmentContingency(attachment?.attachment?.id)}
                            />
                          )}
                        </Flex>
                      </Td>

                    </Tr>
                  )
                })}
              </>
            )}
          </Tbody>
        </Table>
      </ModalBody>
      {userCanAddAttachmentContingency && (
        <ModalFooter >
          <AddAttachmentContingency
            contingencyId={contingencyResult?.contingency?.id}
          />

        </ModalFooter>
      )}
    </ModalContent>
  )
}

import { Box, Button, ButtonGroup, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from "@chakra-ui/react";
import { createRegional } from "api/regionals/createRegional";
import { useToastify } from "hooks/toastify/useToastify";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { RegionalForm, RegionalFormSchema } from "./RegionalForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface CreateRegionalProps {
  onCloseModal: () => void
}

const createRegionalSchema = yup.object({
  name: yup.string().required(),
  code: yup.string().required(),
  responsibleId: yup.string().required(),
})

export function CreateRegional({ onCloseModal }: CreateRegionalProps) {

  const formMethods = useForm<RegionalFormSchema>({
    resolver: yupResolver(createRegionalSchema)
  })

  const {
    data: llmUsers,
  } = useLLMUsers({ queryParams: { situation: 'ATIVO' } })

  const queryClient = useQueryClient()

  const { mutateAsync: createRegionalFn } = useMutation({
    mutationFn: createRegional,
    onSuccess(_data, _variables, _context) {
      queryClient.invalidateQueries({ queryKey: 'regionals' })

      onCloseModal()
    }
  })

  const { promiseMessage } = useToastify()

  async function handleCreateRegional(values: RegionalFormSchema) {
    await promiseMessage(createRegionalFn({
      body: values
    }), 'Regional criada com sucesso!')
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar regional
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>

        <FormProvider {...formMethods}>
          <Box
            as="form"
            onSubmit={formMethods.handleSubmit(handleCreateRegional)}
          >
            <RegionalForm llmUsers={llmUsers?.users} />

            <ButtonGroup
              w="full"
              justifyContent="end"
              mt={3}
            >
              <Button
                type="submit"
                colorScheme="blue"
              >
                Criar
              </Button>
            </ButtonGroup>
          </Box>
        </FormProvider>


      </ModalBody>
    </ModalContent>
  )
}

import { useQuery } from 'react-query'
import {
  getAllRequestedUserReqFunction,
  getOneRequestedUserReqFunction,
} from '../../utils/RequestFunctions/RequestedUser/requestRequestedUserFunctions'
import { useAuth } from '../auth/useAuth'
import { useSwal } from '../swal/useSwal'

export function useRequestedUser(id: string | null, refetchOnFocus?: boolean) {
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useSwal()

  const requestedUsers = useQuery(
    'requestedUsers',
    getAllRequestedUserReqFunction,
    {
      enabled: id === null && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.status === 401) {
          handleLogout()
        }
      },
    },
  )

  const requestedUser = useQuery(
    ['requestedUser', id],
    () => getOneRequestedUserReqFunction(id !== null ? id : ''),
    {
      enabled: !!id && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.status === 401) {
          handleLogout()
        }
      },
    },
  )

  return {
    requestedUsers,
    requestedUser,
  }
}

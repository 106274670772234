import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Stack,
  Button,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import {
  input_material_list,
  measure_options,
  situation,
} from '../../utils/customLists'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useInput } from '../../hooks/input/useInput'
import { useCurrency } from 'react-hook-currency'

import { useGeneralProviders } from 'hooks/generalProvider/useGeneralProviders'

interface FormInputProps {
  name: string
  minimum_stock: number
  standard_stock: number
  measure: string
  general_provider_id: string
  unit_cost: number
  unit_price: number
  situation: string
}

interface InputFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

const inputSchema = yup.object().shape({
  name: yup.string().required('Campo Obrigatório'),
  minimum_stock: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório!'),
  standard_stock: yup
    .number()
    .positive('Insira um valor positivo!')
    .min(0, 'Valor mínimo precisa ser maior ou igual a zero')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório.')
    .typeError('Insira um valor válido!')
    .required('Campo Obrigatório!'),
  measure: yup.string().required('Campo Obrigatório'),
  general_provider_id: yup.string().required('Campo Obrigatório'),
  unit_cost: yup.string().required('Campo Obrigatório'),
  unit_price: yup.string().required('Campo Obrigatório'),
  situation: yup.string().required('Campo Obrigatório'),
})

export function InputForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: InputFormProps) {
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const {
    input: { data: inputById, isLoading: isInputLoading },
  } = useInput(id || null, false, false)

  const { data: generalProviders, isFetching: isFetchingGeneralProviders } = useGeneralProviders()

  const generalProvidersOptions = generalProviders?.generalProviders?.map(generalProvider => {
    return { key: generalProvider.id, value: generalProvider.id, showOption: generalProvider.trading_name }
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(inputSchema),
  })

  const { onChange, format } = useCurrency({
    style: 'decimal',
  })

  useEffect(() => {
    function run() {
      if (!isInputLoading && !isFetchingGeneralProviders) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isInputLoading, isFetchingGeneralProviders])

  useEffect(() => {
    function run() {
      if (slug === 'adicionar') {
        setValue('situation', 'ATIVO')
      }

      if (slug === 'editar' || slug === 'visualizar') {
        if (inputById && generalProviders) {
          setValue('name', inputById.name)
          setValue('minimum_stock', inputById.minimum_stock)
          setValue('standard_stock', inputById.standard_stock)
          setValue('measure', inputById.measure)
          setValue('general_provider_id', inputById.general_provider_id)
          setValue('unit_cost', inputById.unit_cost)
          setValue('unit_price', inputById.unit_price)
          setValue('situation', inputById.situation)
        }
      }
    }

    run()
  }, [setValue, slug, inputById, generalProviders])

  if (isLoadingPage && slug !== 'adicionar') {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack>
            <Select
              {...register('situation')}
              name="situation"
              label="Situação"
              error={errors.situation}
              isDisabled={slug === 'adicionar' ? true : isDisabled}
              situations={situation}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Select
              {...register('name')}
              isDisabled={isDisabled}
              material_list={input_material_list}
              name="name"
              label="Nome"
              placeholder="Selecione uma opção..."
              error={errors.name}
              required
            />
            <HStack w="full" spacing="24px">
              <Input
                {...register('minimum_stock')}
                isDisabled={isDisabled}
                name="minimum_stock"
                type="number"
                label="Estoque Mínimo"
                error={errors.minimum_stock}
                required
              />
              <Input
                {...register('standard_stock')}
                isDisabled={isDisabled}
                name="standard_stock"
                type="number"
                label="Estoque Padrão"
                error={errors.standard_stock}
                required
              />
            </HStack>
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Select
              {...register('measure')}
              name="measure"
              label="Medida"
              error={errors.measure}
              isDisabled={isDisabled}
              measure_options={measure_options}
              placeholder="Selecione uma opção..."
              required
            />

            <Select
              {...register('general_provider_id')}
              name="general_provider_id"
              label="Fornecedor Geral"
              error={errors.general_provider_id}
              isDisabled={isDisabled}
              options={generalProvidersOptions}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('unit_cost')}
              isDisabled={isDisabled}
              name="unit_cost"
              label="Custo Unitário"
              addChildren="R$"
              onChange={onChange}
              defaultValue={format('000')}
              error={errors.unit_cost}
              required
            />
            <Input
              {...register('unit_price')}
              isDisabled={isDisabled}
              name="unit_price"
              addChildren="R$"
              onChange={onChange}
              defaultValue={format('000')}
              label="Preço Unitário"
              error={errors.standard_stock}
              required
            />
          </Stack>

          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/insumos">
                <Button type="button" colorScheme="gray">
                  Lista de Insumos de Transporte
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}

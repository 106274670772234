import { Box, Card, CardBody, CardHeader, Flex, Grid, GridItem, Icon, IconButton, Popover, PopoverTrigger, Portal, Table, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react"
import { getDelayedDeliveries } from "api/service/getDelayedDeliveries"
import { formatDistanceToNowStrict } from "date-fns"
import { ptBR } from "date-fns/locale"
import { InfoCardsLoading } from "pages/Dashboard/components/InfoCardsLoading"
import { Interactions } from "pages/Dashboard/components/Interactions"
import { useEffect } from "react"
import { FaInfoCircle, FaPeopleArrows } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { captalize } from "utils/captalize"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"
import { interactionStatusColors } from "./DelayedCollects"

interface DelayedDeliveriesProps {
  onSetIsError: () => void
}

export function DelayedDeliveries({ onSetIsError }: DelayedDeliveriesProps) {

  const {
    data: result,
    isError,
    isLoading: isLoadingDelayedDeliveriesResult
  } = useQuery({
    queryKey: ['delayed-deliveries'],
    queryFn: getDelayedDeliveries,
    refetchInterval: 1000 * 60 * 2, // 2 minutes,
    refetchIntervalInBackground: true,
  })

  useEffect(() => {
    if (isError) onSetIsError()
  }, [isError, onSetIsError])

  return (
    <Grid
      gap="6"
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
    >
      {isLoadingDelayedDeliveriesResult && (
        <InfoCardsLoading />
      )}
      {result?.services?.map((service) => {
        const deliveryDate = service.delivery_date.substring(0, 10);
        const deliveryHourEnd = service.delivery_hour.substring(11);

        const deliveryDateTime = new Date(`${deliveryDate}T${deliveryHourEnd}`);

        const now = new Date();
        let distanceDeliveryDateTime = null
        let timeUntilDelivery = null

        if (now > deliveryDateTime) {
          distanceDeliveryDateTime = formatDistanceToNowStrict(deliveryDateTime, { locale: ptBR });
        } else {
          timeUntilDelivery = formatDistanceToNowStrict(deliveryDateTime, { locale: ptBR });
        }

        console.log(Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60, service.destination_tz, new Date().getTimezoneOffset() / 60)
        return (
          <GridItem
            key={service.id}
          >
            <Card
              rounded="xl"
              transition="all 0.2s"
              _hover={{
                transform: 'translateY(-0.5rem)'
              }}
              h="full"
            >
              <CardHeader
                as={Link}
                display="flex"
                alignItems="center"
                to={`/servico/detalhes/${service.id}`}
                h="40px"
                bg="#38c3fa"
                borderTopRadius="xl"
              >
                <Text fontWeight="bold" color="white">{service.protocol}</Text>
              </CardHeader>

              <CardBody
                as={Flex}
                gap="6"
                direction="column"
              >
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td fontSize='lg'>Status</Td>
                      <Td isNumeric fontSize='lg'>{captalize(serviceHandleStep(service.step))}</Td>
                    </Tr>
                    <Tr>
                      <Td fontSize='lg'>Cliente</Td>
                      <Td isNumeric fontSize='lg'>{captalize(service.customer)}</Td>
                    </Tr>
                    <Tr>
                      <Td fontSize='lg'>
                        <Flex>
                          Hub de Origem
                          {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                            <Tooltip label={service.source_tz}>
                              <Box as="span" ml="2">
                                <Icon as={FaInfoCircle} />
                              </Box>
                            </Tooltip>
                          )}
                        </Flex>
                      </Td>
                      <Td isNumeric fontSize='lg'>{captalize(service.source_hub)}</Td>
                    </Tr>
                    <Tr>
                      <Td fontSize='lg'>
                        <Flex>
                          Hub de Destino
                          {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                            <Tooltip label={service.destination_tz}>
                              <Box as="span" ml="2">
                                <Icon as={FaInfoCircle} />
                              </Box>
                            </Tooltip>
                          )}
                        </Flex>
                      </Td>
                      <Td isNumeric fontSize='lg'>{captalize(service.destination_hub)}</Td>
                    </Tr>
                    {timeUntilDelivery && (
                      <Tr>
                        <Td fontSize='lg'>Entrega em: </Td>
                        <Td fontSize='lg' isNumeric>{captalize(timeUntilDelivery)}</Td>
                      </Tr>
                    )}
                    {distanceDeliveryDateTime && (
                      <Tr>
                        <Td fontSize='lg'>Tempo de Atraso</Td>
                        <Td fontSize='lg' isNumeric>{captalize(distanceDeliveryDateTime)}</Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>

                <Flex h="full" justify="flex-end" align="flex-end">
                  <Popover isLazy>
                    {({ isOpen }) => {
                      return (
                        <>
                          <PopoverTrigger>
                            <IconButton
                              aria-label="Abrir Interações"
                              icon={<FaPeopleArrows />}
                              colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
                              size="sm"
                            />
                          </PopoverTrigger>
                          <Portal>
                            <Interactions isOpen={isOpen} serviceId={service.id} />
                          </Portal>
                        </>
                      )
                    }}

                  </Popover>
                </Flex>
              </CardBody>
            </Card>
          </GridItem>
        )
      })}
    </Grid>
  )
}

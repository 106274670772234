import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Pmc } from "./pmc";

export interface GetPmcsWaitingReceiveResponse {
  pmcs: Array<Pick<Pmc, 'id' | 'protocol'>>
}


export async function getPmcsWaitingReceive() {
  try {
    const response = await api.get<GetPmcsWaitingReceiveResponse>('/pmcs/waiting-receive')

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

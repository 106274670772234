import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { useToastify } from "hooks/toastify/useToastify";
import { createPatrimonyRequest } from "api/patrimonies/createPatrimonyRequest";
import { getPatrimonyTypes } from "api/patrimonies/getPatrimonyTypes";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { useAggregates } from "hooks/aggregate/useAggregates";
import AsyncSelect from 'react-select/async';
import { getInternClts } from "api/internClts/getInternClts";
import { getCltDrivers } from "api/cltDrivers/getCltDrivers";


interface CreateRequestPatrimonyProps {
  onCloseModal: () => void
  required?: boolean
}

interface CreateRequestPatrimonySchema {
  limitDate: string
  patrimonyTypesIds: string[]
  receiptAttachmentId: FileList
  regional: string
  responsibleName: {
    label: string
    value: string
  }
  sector: string
  collaboratorType: string
}

const createRequestPatrimonySchema = yup.object({
  limitDate: yup.string().required(),
  regional: yup.string().required(),
  collaboratorType: yup.string().required(),
  responsibleName: yup.object().required(),
  sector: yup.string().required(),
  patrimoniesId: yup.array()
    .min(1)
    .of(yup.string()),

})

const sectors = [
  'Administrativo',
  'Financeiro',
  'Almoxarifado',
  'Comercial',
  'Copa',
  'Lifebox',
  'Operacional',
  'Qualidade',
  'RH',
  'Sala de Reunião',
  'Sala Executivo',
  'Externo'
]

const regionals = [
  'MG',
  'RJ',
  'DF',
  'SP',
  'OUTRA'
]

export const collaboratorTypesOptions = [
  'AGREGADO',
  'INTERNO CLT',
  'MOTORISTA CLT',
]
export function CreateRequestPatrimony({
  onCloseModal,
  required = false
}: CreateRequestPatrimonyProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<CreateRequestPatrimonySchema>({
    resolver: yupResolver(createRequestPatrimonySchema),
    defaultValues: {
      patrimonyTypesIds: [],
    },
  })

  const [collaboratorType] = useWatch({
    control,
    name: ['collaboratorType']
  })

  const {
    data: aggregates,
  } = useAggregates()

  async function promiseOptionsCltDrivers(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getCltDrivers({ currentPage: 1, pageSize: 5, search: inputValue ?? '' })

    return response.cltDrivers.map(driver => {
      return { value: captalize(`${driver?.driver.firstname} ${driver?.driver.lastname}`), label: captalize(`${driver?.driver.firstname} ${driver?.driver.lastname}`) }
    })

  }
  async function promiseOptionsInternClts(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getInternClts({ currentPage: 1, pageSize: 5, name: inputValue ?? '' })

    return response.internClts.map(internClt => {
      return { value: internClt.name, label: internClt.name }
    })
  }

  async function promiseOptionsAggregates(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    return aggregates
      ?.filter((aggregate) => {
        const fullName = `${aggregate?.driver.firstname} ${aggregate?.driver.lastname}`.toLowerCase();
        return !inputValue || fullName.includes(inputValue.toLowerCase());
      })
      ?.map((aggregate) => {
        const fullName = `${aggregate?.driver.firstname} ${aggregate?.driver.lastname}`;
        return {
          value: captalize(fullName),
          label: captalize(fullName)
        };
      });
  }



  const { data: patrimonyTypesResult } = useQuery({
    queryKey: ['patrimonyTypes'],
    queryFn: () => getPatrimonyTypes({}),
    keepPreviousData: true
  })


  const { mutateAsync: createPatrimonyRequestFn } = useMutation({
    mutationFn: createPatrimonyRequest,
    onSuccess() {
      queryClient.invalidateQueries(['requestsPatrimony'])

      if (required) {
        searchParams.set('patrimonyRequested', 'true')
        replace({ search: searchParams.toString() })
      }
      onCloseModal()
    }
  })

  async function handleRequestPatrimony({
    limitDate,
    patrimonyTypesIds,
    regional,
    responsibleName,
    sector
  }: CreateRequestPatrimonySchema) {
    await promiseMessage(createPatrimonyRequestFn({
      body: {
        limitDate,
        patrimonyTypesIds,
        regional,
        responsibleName: responsibleName?.value,
        sector
      }
    }), 'Solicitação de patrimônio criada! 🎉')
  }


  return (
    <ModalContent>
      <ModalHeader>Solicitar Patrimônio</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleRequestPatrimony)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Regional
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.regional}>
              <Select
                {...register("regional")}
                name="regional"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {regionals?.map((regional, index) => {
                  return (
                    <option key={index} value={regional}>{regional}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>

        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >

          <FormControl isInvalid={!!errors?.collaboratorType}>
            <FormLabel fontSize="sm">
              Tipo de colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register("collaboratorType")}
              name="collaboratorType"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              {collaboratorTypesOptions?.map((colaboradorType, index) => {
                return (
                  <option key={index} value={colaboradorType}>{colaboradorType}</option>
                )
              })}
            </Select>
          </FormControl>

        </Stack>
        {collaboratorType && (
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            {collaboratorType === 'MOTORISTA CLT' && (
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors?.responsibleName}>
                  <FormLabel w="full" fontSize='sm'>
                    Nome do Responsável
                    <Text as="sup" color="red.500">*</Text>

                  </FormLabel>
                  <Controller
                    control={control}
                    name="responsibleName"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('responsibleName')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            borderColor: 'gray.200',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          }),
                          placeholder: (base, props) => ({
                            ...base,
                            color: 'blackAlpha.900'
                          }),
                          dropdownIndicator(base, props) {
                            return {
                              ...base,
                              color: 'blackAlpha.900',
                              width: '34px',
                            }
                          },
                        }}
                        noOptionsMessage={() => 'Não há motoristas cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborador..."
                        loadOptions={promiseOptionsCltDrivers}
                        isClearable={true}

                      />
                    )}
                  />
                </FormControl>
              </Stack>
            )}
            {collaboratorType === 'INTERNO CLT' && (
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors?.responsibleName}>
                  <FormLabel w="full" fontSize='sm'>
                    Nome do Responsável
                    <Text as="sup" color="red.500">*</Text>

                  </FormLabel>
                  <Controller
                    control={control}
                    name="responsibleName"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('responsibleName')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            borderColor: 'gray.200',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          }),
                          placeholder: (base, props) => ({
                            ...base,
                            color: 'blackAlpha.900'
                          }),
                          dropdownIndicator(base, props) {
                            return {
                              ...base,
                              color: 'blackAlpha.900',
                              width: '34px',
                            }
                          },
                        }}
                        noOptionsMessage={() => 'Não há internos cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborador..."
                        loadOptions={promiseOptionsInternClts}
                        isClearable={true}

                      />
                    )}
                  />
                </FormControl>
              </Stack>
            )}
            {collaboratorType === 'AGREGADO' && (
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors?.responsibleName}>
                  <FormLabel w="full" fontSize='sm'>
                    Nome do Responsável
                    <Text as="sup" color="red.500">*</Text>

                  </FormLabel>
                  <Controller
                    control={control}
                    name="responsibleName"
                    render={({ field }) => (
                      <AsyncSelect
                        {...register('responsibleName')}
                        name={field.name}
                        onChange={field.onChange}
                        value={field.value}
                        cacheOptions
                        defaultOptions
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            padding: '1px',
                            borderRadius: '6px',
                            borderColor: 'gray.200',
                            border: state.isFocused ? 'none' : '',
                            boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                          }),
                          placeholder: (base, props) => ({
                            ...base,
                            color: 'blackAlpha.900'
                          }),
                          dropdownIndicator(base, props) {
                            return {
                              ...base,
                              color: 'blackAlpha.900',
                              width: '34px',
                            }
                          },
                        }}
                        noOptionsMessage={() => 'Não há agregados cadastrados ou encontrados!'}
                        placeholder="Selecione um colaborador..."
                        loadOptions={promiseOptionsAggregates}
                        isClearable={true}

                      />
                    )}
                  />
                </FormControl>
              </Stack>
            )}
          </Stack>
        )}

        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de patrimônio
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Box
              overflowY="auto"
              height="100px"
              w='400px'
              borderWidth="1px"
              borderRadius="lg"
              p="2">

              <FormControl isInvalid={!!errors.patrimonyTypesIds}>
                <Controller
                  name="patrimonyTypesIds"
                  control={control}
                  render={({ field }) => {
                    return (
                      <CheckboxGroup
                        {...field}
                        onChange={field.onChange}
                      >
                        <Stack
                          direction="column"
                        >
                          {patrimonyTypesResult?.patrimonyTypes.map((patrimonyType) => {
                            return (
                              <Checkbox
                                size='sm'
                                rounded='md'
                                mr='6'
                                key={patrimonyType.id}
                                value={patrimonyType.id}
                              >
                                {patrimonyType?.type}
                              </Checkbox>
                            )
                          })}
                        </Stack>
                      </CheckboxGroup>
                    )
                  }}
                />

              </FormControl>
            </Box>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <FormControl isInvalid={!!errors?.limitDate}>
            <FormLabel fontSize="sm">
              Data limite
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('limitDate')}
              name="limitDate"
              type="date"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            spacing="0.25"
            w="full"
          >
            <FormLabel fontSize="sm">
              Setor
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.sector}>
              <Select
                {...register('sector')}
                name="sector"
                placeholder="Selecione..."
                size="sm"
                rounded="md"
              >
                {sectors?.map((sector, index) => {
                  return (
                    <option key={index} value={sector}>{captalize(sector)}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent >
  )
}

import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";
import { api } from "../../services/api";
import { useAuth } from "../auth/useAuth";
import { useSwal } from "../swal/useSwal";

export interface ICreateCollectServiceInput {
  service_id: string;
  address_id?: string
  provider_id?: string
  driver_id: string;
  isBusiness: boolean;
  arrival_latitude: string;
  arrival_longitude: string;
  arrival_timestamp: Date;
}

interface FinishCollectServiceInputProps {
  id: string
  input: FormData
}

interface CancelCollectAddressInputProps {
  service_id: string,
  address_id: string
}

interface StandardFinishCollectServiceInputProps {
  service_id: string
  input: FormData
}

async function createCollectServiceReqFunction(
  input: ICreateCollectServiceInput,
) {
  try {
    const { data } = await api.post("/collect-service", input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function finishCollectServiceReqFunction({ id, input }: FinishCollectServiceInputProps) {
  try {
    const { data } = await api.put(`/collect-service/${id}`, input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperada!')
    }
  }
}

async function finishUnsuccessCollectServiceReqFunction({ id, input }: FinishCollectServiceInputProps) {
  try {
    const { data } = await api.put(`/collect-service/unsuccess/${id}`, input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperada!')
    }
  }
}
async function cancelCollectServiceAddressReqFunction({
  service_id,
  address_id
}: CancelCollectAddressInputProps) {
  try {
    const { data } = await api.put(`/collect-service/cancel/${service_id}`, {
      address_id
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperada!')
    }
  }
}

async function finishCollectBusinessServiceReqFunction({ input, service_id }: StandardFinishCollectServiceInputProps) {
  try {
    const { data } = await api.put(`/collect-service/business/${service_id}`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function editCollectServiceReqFunction({ input, service_id }: StandardFinishCollectServiceInputProps) {
  try {
    const { data } = await api.put(`/in-progress/collect-service/${service_id}`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export function useCollectServiceFunctions() {
  const queryClient = useQueryClient()
  const { handleLogout } = useAuth()
  const { errorMessage, successMessage } = useSwal()

  const createCollectService = useMutation("createCollectService",
    createCollectServiceReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("service")
      await successMessage("Coleta iniciada com sucesso!")
    },
    onError: async (error: any) => {
      await errorMessage(error.message)

      if (error.status === 401) {
        await handleLogout()
      }
    }
  })

  const finishCollectService = useMutation("finishCollectService",
    finishCollectServiceReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("service")
      await queryClient.invalidateQueries("services")
      await successMessage("Coleta finalizada com sucesso!")
    },
    onError: async (error: any) => {
      await errorMessage(error.message)

      if (error.status === 401) {
        await handleLogout()
      }
    }
  })

  const finishUnsuccessCollectService = useMutation(
    'finishUnsuccessCollectService', finishUnsuccessCollectServiceReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("service")
      await queryClient.invalidateQueries("services")
      await successMessage('Coleta finalizada como sem sucesso!')
    },
    onError: async ({ message, httpCode }: RequestError) => {
      await errorMessage(message)

      if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
        await handleLogout()
      }
    }
  }
  )

  const cancelCollectServiceAddress = useMutation(
    'cancelCollectServiceAddress', cancelCollectServiceAddressReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("service")
      await queryClient.invalidateQueries("services")
      await successMessage('Endereço de coleta cancelado com sem sucesso!')
    },
    onError: async ({ message, httpCode }: RequestError) => {
      await errorMessage(message)

      if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
        await handleLogout()
      }
    }
  }
  )

  const finishCollectBusinessService = useMutation("finishCollectBusinessService",
    finishCollectBusinessServiceReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("service")
      await successMessage("Coleta business finalizada com sucesso!")
    },
    onError: async (error: any) => {
      await errorMessage(error.message)

      if (error.status === 401) {
        await handleLogout()
      }
    }
  })

  const editCollectService = useMutation("editCollectService",
    editCollectServiceReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("service")
      await successMessage("Coleta editada com sucesso!")
    },
    onError: async (error: any) => {
      await errorMessage(error.message)

      if (error.status === 401) {
        await handleLogout()
      }
    }
  })

  return {
    createCollectService,
    finishCollectService,
    editCollectService,
    cancelCollectServiceAddress,
    finishCollectBusinessService,
    finishUnsuccessCollectService,
  }
}

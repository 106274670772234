import { Box, Flex } from "@chakra-ui/react"
import { AddressesWithRequestedChangesStatus as AddressesWithRequestedChangesStatusTypes } from "api/addresses/addressRequestedChange"
import { addressesWithRequestedChangesStatusMap } from "utils/addressesWithRequestedChangesMappers"

interface AddressesWithRequestedChangesStatusProps {
  status: AddressesWithRequestedChangesStatusTypes
}

export function AddressesWithRequestedChangesStatus({
  status
}: AddressesWithRequestedChangesStatusProps) {
  return (
  <Flex
    align="center"
    gap="2"
  >
    {status === 'pending' && (
      <Box w="2" h="2" rounded="full" bgColor="orange.300" />
    )}
    {status === 'approving' && (
      <Box w="2" h="2" rounded="full" bgColor="blue.300" />
    )}
    {status === 'finished' && (
      <Box w="2" h="2" rounded="full" bgColor="green.300" />
    )}
    {addressesWithRequestedChangesStatusMap[status]}
  </Flex>

  )
}

import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { LogProps } from "contexts/LogContext";

export interface UseExcludedServicesLogsReponse {
  logs: LogProps[]
  totalPages: number
}


type QueryOptions = UseQueryOptions<UseExcludedServicesLogsReponse, unknown, UseExcludedServicesLogsReponse, QueryKey>

interface QueryParams {
  currentPage: number
  pageSize: number
}

interface UseExcludedServicesLogsOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: QueryParams
}


export function useExcludedServicesLogs(options?: UseExcludedServicesLogsOptions) {
  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getExcludedServicesLogs() {

    try {
      const { data } = await api.get(`/services/excluded`, {
        params: options?.queryParams
      })

      return data

    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([options?.queryKey || 'excludedServicesLogs', options?.queryParams], getExcludedServicesLogs, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

import axios from "axios";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { SupportType } from "utils/supportTypes";
import { SupportSubject } from "./_types/SupportSubject";

export interface GetSupportsSubjectsParams {
  pageSize: number
  currentPage: number
  name?: string
  supportType?: SupportType | 'all'
  supportCategory?: string
}

export interface GetSupportsSubjectsResponse {
  supportsSubjects: Array<SupportSubject & {
    responsibles: {
      user: User
    }[]
  }>
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}

export async function getSupportsSubjects({
  currentPage,
  pageSize,
  name,
  supportCategory,
  supportType
}: GetSupportsSubjectsParams) {
  try {
    const response = await api.get<GetSupportsSubjectsResponse>('/supports/subjects', {
      params: {
        pageSize,
        currentPage,
        name,
        supportCategory,
        supportType
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { CustomerProps } from '../../../contexts/CustomerContext'
import { api } from '../../../services/api'
import { IHubsProps } from '../Hubs/requestHubFunctions'
import { User } from 'hooks/user/dtos/User';
import { Collector } from 'hooks/collector/dtos/Collector';

type LogDetail = {
  [Property in keyof BusinessBudgetProps]: {
    prev_value: BusinessBudgetProps[Property]
    new_value: BusinessBudgetProps[Property]
  }
}

export interface BusinessBudgetLogs {
  id: string
  log_detail: LogDetail
  created_at: string
  updated_at: string
  createdAt: string
  updatedAt: string
  business_budget_id: string
  updated_by: string
  updatedBy: User
}

export type BusinessBudgetProps = {
  id: string
  customer_id: string
  source_hub_id: string
  destination_hub_id: string
  source_collector_id: string
  situation: string
  source_cities: string[]
  destination_cities: string[]
  route_nickname: string
  vehicle: string
  start_date: string
  end_date: string
  sourceCollectorIDBusinessBudget: Collector
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  monthly_price: number
  budget: number
  monthly_km_franchising: number
  week_time_franchising: number
  km_extra_price: number
  extra_hour_price: number
  weekday_daily_budget_backup: number
  weekend_daily_budget_backup: number
  drivers: {
    [driver: string]: {
      attendance_days: string[]
      start_hour: string
      end_hour: string
      value_in_cents: number
    }
  }
  observation: string
  customerIDBusinessBudget: CustomerProps
  sourceHubIDBusinessBudget: IHubsProps
  destinationHubIDBusinessBudget: IHubsProps
  logs: BusinessBudgetLogs[]
  createdBy: User
  last_readjustment: string
}

export type BusinessBudgetInputProps = {
  customer_id: string
  source_hub_id: string
  destination_hub_id: string
  source_collector_id: string
  situation: string
  source_cities: string[]
  destination_cities: string[]
  route_nickname: string
  vehicle: string
  start_date: Date
  end_date: Date
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  monthly_price: number
  budget: number
  monthly_km_franchising: number
  week_time_franchising: number
  km_extra_price: number
  extra_hour_price: number
  weekday_daily_budget_backup: number
  weekend_daily_budget_backup: number
  drivers: {
    [driver: string]: {
      attendance_days?: string[]
      start_hour?: string
      end_hour?: string,
      value_in_cents?: number
    }
  }
  last_readjustment: string
  observation: string
}

export type EditBusinessBudgetReqProps = {
  id: string
  input: BusinessBudgetInputProps
}

async function getAllBusinessBudgetsReqFunction() {
  try {
    const { data } = await api.get<BusinessBudgetProps[]>('/business-budget')

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function getOneBusinessBudgetReqFunction(id: string) {
  try {
    const { data } = await api.get<BusinessBudgetProps>(
      `/business-budget/${id}`,
    )

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function createBusinessBudgetReqFunction(
  input: BusinessBudgetInputProps,
) {
  try {
    const { data } = await api.post('/business-budget', input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function editBusinessBudgetReqFunction({
  id,
  input,
}: EditBusinessBudgetReqProps) {
  try {
    const { data } = await api.put(`/business-budget/${id}`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function removeBusinessBudgetReqFunction(id: string) {
  try {
    const { data } = await api.delete(`/business-budget/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export {
  getAllBusinessBudgetsReqFunction,
  getOneBusinessBudgetReqFunction,
  createBusinessBudgetReqFunction,
  editBusinessBudgetReqFunction,
  removeBusinessBudgetReqFunction,
}

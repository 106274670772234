
import { Box, FormControl, FormLabel, Input, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext, } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function LegalAgreementForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      suggestedPaymentDate: 'Data de pagamento sugerida',
      amountToBePaidByBankTransfer: 'Valor a ser pago em conta',
      amountToBePaidByInvoice: 'Valor a ser pago através de guia',
      others: 'Outros',
      describePaymentRequestAndDeadlines: 'Descreva a solicitação do pagamento e devidos vencimentos',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };


  return (
    <Box w='full'>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.suggestedPaymentDate} >
            <FormLabel fontSize='sm'>
              Data de pagamento sugerida
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.suggestedPaymentDate.value')}
              name='formData.suggestedPaymentDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('suggestedPaymentDate')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.amountToBePaidByBankTransfer}>
            <FormLabel fontSize='sm'>
              Valor a ser pago em conta
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.amountToBePaidByBankTransfer.value')}
              name='formData.amountToBePaidByBankTransfer.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('amountToBePaidByBankTransfer')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.amountToBePaidByInvoice}>
            <FormLabel fontSize='sm'>
              Valor a ser pago através de guia
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.amountToBePaidByInvoice.value')}
              name='formData.amountToBePaidByInvoice.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('amountToBePaidByInvoice')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.others}>
            <FormLabel fontSize='sm'>
              Outros
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.others.value')}
              name='formData.others.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('others')}
            />
          </FormControl>
        </Stack>
      </Stack>

      <FormControl isInvalid={!!errors.formData?.describePaymentRequestAndDeadlines} mt={3}>
        <FormLabel fontSize="sm">
          Descreva a solicitação do pagamento e devidos vencimentos
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.describePaymentRequestAndDeadlines.value')}
          name='formData.describePaymentRequestAndDeadlines.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('describePaymentRequestAndDeadlines')}
        />
      </FormControl>
    </Box>
  )
}

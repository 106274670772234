import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

export interface GetDeliveriesWithoutQRCodePendingValidationResponse {
  deliveries: {
    id: string
    protocol: number
    serviceIDDelivery: {
      id: string
      protocol: number
      customerIDService: {
        trading_firstname: string
      }
      serviceIDRequested: {
        destinationCollectorIDService: {
          trading_name: string
        }
      }
    }
  }[]
  meta: {
    totalPages: number
    pageSize: number
    currentPage: number
  }
}

interface GetLandingsPendingValidationsQueryParams {
  pageSize: string
  currentPage: string
  destinationCollector?: string
}

export async function getDeliveriesWithoutQRCodePendingValidation({
  currentPage,
  pageSize,
  destinationCollector
}: GetLandingsPendingValidationsQueryParams) {
  try {
    const { data } = await api.get<GetDeliveriesWithoutQRCodePendingValidationResponse>('/services/deliveries/pending-validations', {
      params: {
        currentPage,
        pageSize,
        destinationCollector
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

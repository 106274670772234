import { FormProvider, useForm } from "react-hook-form";
import { NotificationTypeForm, NotificationTypeFormInputs } from "./components/NotificationTypeForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Button, Flex } from "@chakra-ui/react";
import { useNotificationTypeFunctions } from "hooks/notification/useNotificationTypeFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  profiles: yup.array().min(1, 'É necessário escolher ao menos uma opção').required('Campo obrigatório')
})

export function CreateNotificationType() {

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('create-notification-type')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      profiles: []
    }
  })

  const { handleSubmit } = formMethods

  const {
    createNotificationType: { mutateAsync: createNotificationType }
  } = useNotificationTypeFunctions()

  const { promiseMessage } = useToastify()

  async function handleCreateNotificationType({
    name,
    profiles
  }: NotificationTypeFormInputs) {
    await promiseMessage(createNotificationType({
      name,
      profiles
    }, {
      onSuccess: () => redirectTo('/notificacao/tipos')
    }), 'Tipo de notificação criada com sucesso!')
  }

  return (
    <StandardBackgroundForm
      title='Criar tipo de notificação'
      onSubmit={handleSubmit(handleCreateNotificationType)}
    >
      <FormProvider {...formMethods}>
        <NotificationTypeForm mode="create" />
      </FormProvider>


      <Flex
        direction={['column', 'column', 'row']}
        gap={2}
        justify={['center', 'center', 'flex-end']}
        mt={4}
      >
        <Button type='submit' colorScheme='blue'>Criar</Button>
        <Button
          as={Link}
          to='/notificacao/tipos'
          variant='ghost'
        >
          Tipos Cadastrados
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

import { Flex, Box, Heading, Divider } from '@chakra-ui/react'
import { BaseSyntheticEvent, ReactNode } from 'react'


interface IStandardBackgroundForm {
  onSubmit: (
    e?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>
  title: string
  children: ReactNode
}

export function StandardBackgroundForm({
  onSubmit,
  title,
  children,
}: IStandardBackgroundForm) {
  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          overflowX="auto"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={onSubmit}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />
          {children}
        </Box>
      </Flex>

  )
}

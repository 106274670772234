import { Td, Tr } from "@chakra-ui/react";
import { GetAuditResponse } from "api/audits/getAudit";

interface AuditCommercialTableRowProps {
  audit: GetAuditResponse
}

export function AuditCommercialTableRow({
  audit
}: AuditCommercialTableRowProps) {
  return (
    <>
      <Tr>
        <Td>Contrato válido assinado?</Td>
        <Td isNumeric>{audit?.audit?.commercialAudit?.isSignedValidContract ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>POP atualizado e assinado?</Td>
        <Td isNumeric>{audit?.audit?.commercialAudit?.isUpdatedAndSignedPop ? 'SIM' : 'NÃO'}</Td>
      </Tr>
    </>
  )
}

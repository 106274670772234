import { Menu, MenuButton, Button, MenuList, MenuOptionGroup, MenuItemOption } from "@chakra-ui/react";
import { useAggregateStatusTableButton } from "hooks/aggregate/useAggregateStatusTableButton";
import { FaChevronDown } from "react-icons/fa";
import { AggregatesTablesTitlesByStatusEnum } from "../AggregateList";

const aggregatesStatusOptions = [
  { key: 'in-qualification', value: 'in-qualification', showOption: 'Em Qualificação' },
  { key: 'active', value: 'active', showOption: 'Ativos' },
  { key: 'in-resigning', value: 'in-resigning', showOption: 'Em Desligamento' },
  { key: 'inactive', value: 'inactive', showOption: 'Inativos' }
]

export function AggregatesStatusTableButton() {
  const { status, handleChangeStatus } = useAggregateStatusTableButton()

  return (
    <Menu>
      <MenuButton fontSize="12px" variant="ghost" size="sm" as={Button} rightIcon={<FaChevronDown />}>
        STATUS
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue="active"
          value={status}
          type="radio"
          onChange={
            (status: keyof typeof AggregatesTablesTitlesByStatusEnum) => handleChangeStatus(status)
          }
        >
          {aggregatesStatusOptions.map((option) => {
            return (
              <MenuItemOption value={option.value} key={option.key}>{option.showOption}</MenuItemOption>
            )
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

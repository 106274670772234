import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useDriverFunctions } from '../../../hooks/driver/useDriverFunctions'
import { useSwal } from '../../../hooks/swal/useSwal'
import { IDriverProps } from '../../../services/getFunctions/driver/getDrivers'
import { CSVLink } from 'react-csv'

interface DriverTableProps {
  drivers: IDriverProps[]
  refetch: () => void
}

const driverOptions = [
  { key: 'driver', value: 'Nome', checked: false },
  { key: 'collector', value: 'Razão Social', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
]

const driverOrderOptions = [{ key: 'driver', value: 'Nome' }]


const headers = [
  { label: 'MOTORISTA', key: 'firstname' },
  { label: 'COLETADOR', key: 'collector_id' },
  { label: 'SITUAÇÃO', key: 'situation' },
  { label: 'E-MAIL', key: 'email' },
  { label: 'CPF', key: 'cpf' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

function formatValuesToReport(
  driversData: IDriverProps[],

) {
  const driversDataFormated = driversData.map((driver) => {
    return {
      ...driver,
      firstname: `${driver.firstname} ${driver.lastname}`,
      collector_id: driver.collectorIDDriver?.trading_name,
      observation: driver.observation !== '' ? driver.observation : '-',
    }
  })

  return driversDataFormated
}

export function DriverTable({ drivers, refetch }: DriverTableProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedDrivers, setSearchedDrivers] = useState<IDriverProps[]>([])

  const [slicedDrivers, setSlicedDrivers] = useState<IDriverProps[]>([])
  const [driverFiltered, setDriverFiltered] = useState('')
  const [collectorFiltered, setCollectorFiltered] = useState('')
  const [situationFiltered, setSituationFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { confirmMessage, standardMessage } = useSwal()
  const {
    deleteDriver: { mutateAsync: deleteDriver },
  } = useDriverFunctions()
  const { userLogged } = useAuth()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedDrivers?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function run() {
      if (userLogged) {
        if (userLogged.user_type === 'COLETADOR') {
          const driversFiltered = drivers.filter(
            (driver) => driver.collector_id === userLogged.collector_id,
          )
          setSlicedDrivers(driversFiltered)
        } else {
          setSlicedDrivers(drivers)
        }
      }
    }
    run()
  }, [itemLimit, currentPage, offset, drivers, userLogged])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(driverOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(driverOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      const newSlicedDrivers = slicedDrivers?.filter((drivers) => {
        const driverFilter = searchBoxFilter(
          drivers.firstname + drivers.lastname,
          driverFiltered,
        )
        const collectorFilter = searchBoxFilter(
          drivers.collectorIDDriver.trading_name,
          collectorFiltered,
        )
        const situationFilter = searchBoxFilter(
          drivers.situation,
          situationFiltered,
        )

        if (
          driverFiltered === '' &&
          collectorFiltered === '' &&
          situationFiltered === ''
        )
          return drivers

        return driverFilter && collectorFilter && situationFilter
      })

      if (orderOptionSelected.length > 0) {
        const slicedDriversByOrder = newSlicedDrivers.sort(
          (a, b) =>
            (orderOptionSelected.includes('driver') &&
              (a.firstname + a.lastname).localeCompare(
                b.firstname + b.lastname,
              )) ||
            0,
        )
        setSearchedDrivers(slicedDriversByOrder)
      } else {
        setSearchedDrivers(newSlicedDrivers)
      }
    }
    run()
  }, [
    driverFiltered,
    collectorFiltered,
    situationFiltered,
    slicedDrivers,
    orderOptionSelected,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteDriver(driverId: string) {
    const hasDeleteHub = await confirmMessage({
      title: 'Deseja excluir um motorista?',
    })

    if (hasDeleteHub) {
      await deleteDriver(driverId)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const csvReportProps = {
    data: formatValuesToReport(slicedDrivers),
    headers,
    filename: `relatório-cadastro-motoristas`,
  }

  return (
    <>
      <TableFilterButton />
      <Stack mt="4" direction="column">
        {filterOptions
          .filter((option) => option.checked === true)
          .map((option) => (
            <Fragment key={option.key}>
              <Flex>
                {option.key === 'driver' && (
                  <SearchBox
                    placeholder="Buscar Nome..."
                    handleSearch={(e) => setDriverFiltered(e.target.value)}
                  />
                )}
                {option.key === 'collector' && (
                  <SearchBox
                    placeholder="Buscar Coletador..."
                    handleSearch={(e) => setCollectorFiltered(e.target.value)}
                  />
                )}
                {option.key === 'situation' && (
                  <SearchBox
                    placeholder="Buscar Situação..."
                    handleSearch={(e) => setSituationFiltered(e.target.value)}
                  />
                )}
              </Flex>
            </Fragment>
          ))}
      </Stack>
      <Box overflowX="auto" w="100%">
        <Table colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {driverOptions.map((option) => (
                <Th key={option.key} fontSize="12" color="blue.900">
                  {option.value.toUpperCase()}
                </Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchedDrivers
              .slice(offset, offset + Number(itemLimit))
              .map((driver, index) => (
                <Tr key={index}>
                  <Td fontSize="12">
                    {`${driver.firstname} ${driver.lastname}`.toUpperCase()}
                  </Td>
                  <Td fontSize="12">
                    {driver.collectorIDDriver.trading_name.toUpperCase()}
                  </Td>
                  <Td fontSize="12">{driver.situation.toUpperCase()}</Td>
                  <Td isNumeric>
                    {userLogged !== null && (
                      <>
                        {userLogged.permissions.includes('view-driver') && (
                          <Link to={`/motorista/visualizar/${driver.id}`}>
                            <Icon cursor="pointer" as={FiEye} fontSize="20" />
                          </Link>
                        )}
                        {userLogged.permissions.includes('edit-driver') && (
                          <Link to={`/motorista/editar/${driver.id}`}>
                            <Icon
                              cursor="pointer"
                              as={FiEdit3}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                            />
                          </Link>
                        )}
                        {userLogged.permissions.includes('remove-driver') && (
                          <Icon
                            cursor="pointer"
                            as={FiTrash2}
                            fontSize="20"
                            ml="4"
                            mt={['2', '2', '0']}
                            onClick={() => handleDeleteDriver(driver.id)}
                          />
                        )}
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justifyContent="flex-end" mt="4">
        <CSVLink
          {...csvReportProps}
          separator={';'}
          style={{
            flex: isWideVersion ? 0 : 1,
            width: isWideVersion ? '' : '100%',
          }}
        >
          <Button
            type="button"
            colorScheme="green"
            w={['full', 'full', '']}
          >
            Exportar CSV
          </Button>
        </CSVLink>
      </Flex>
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}

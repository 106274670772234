import { StandardizationAttachmentTypes } from "api/standardizations/getStandardization"
import { StandardizationStatus } from "api/standardizations/standardization"

export const standardizationStatusMapper: Record<StandardizationStatus , string> = {
  approved: 'Aprovado',
  pending: 'Pendente',
  reproved: 'Reprovado'
}


export const standardizationAttachmentsTypesMapper = (vehicle: 'Moto' | 'Carro'): Record<StandardizationAttachmentTypes, string> => {
  return {
    cnh: 'CNH',
    crlv: 'CRLV',
    antt: 'Curso de motofrete/ANTT',
    "cleaning-register": 'Registro de Limpeza',
    "uniform-and-badge": 'Foto frontal com uniforme e crachá',
    "vehicle-front": 'Foto do veículo (frente)',
    "vehicle-left": 'Foto do veículo (lateral esquerda)',
    "vehicle-right": 'Foto do veículo (lateral direita)',
    "vehicle-rear": 'Foto do veículo (traseira com placa)',
    "vehicle-intern": vehicle === 'Moto' ? 'Moto - Foto do baú' : 'Carro - Foto interna',
    "vehicle-trunk-lid-or-car-with-emergency-form": vehicle === 'Moto' ? 'Moto - Foto da tampa do baú' : 'Carro - Foto da ficha de emergência',
    "vehicle-tire": 'Foto dos pneus'
  }
}

import { ModalBody, ModalBodyProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ViewBugModalContentProps extends ModalBodyProps {
  children: ReactNode
}

export function ViewBugModalContent({
  children,
  ...rest
}: ViewBugModalContentProps) {
  return (
    <ModalBody
      {...rest}
    >
      {children}
    </ModalBody>
  )
}

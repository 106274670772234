import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { getRegional } from "api/regionals/getRegional"
import { useQuery } from "react-query"
import { captalize } from "utils/captalize"

interface RegionalDetailProps {
  regionalId: string
}

export function RegionalDetail({ regionalId }: RegionalDetailProps) {
  const {
    data: regionalResult,
    isLoading: isLoadingRegionalResult
  } = useQuery({
    queryKey: ['regional', regionalId],
    queryFn: () => getRegional({ regionalId })
  })

  if (isLoadingRegionalResult) {
    return (
      <ModalContent>
        <ModalHeader>
          <Skeleton w="150px" />
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
          <Skeleton h="15px" />
        </ModalBody>
      </ModalContent>
    )
  }

  return (
    <ModalContent maxW='550px'>
      <ModalHeader>
        Detalhes da regional
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>

        <Table
          size="sm"
        >
          <Tbody>
            <Tr>
              <Td>Nome</Td>
              <Td isNumeric>{regionalResult?.regional.name}</Td>
            </Tr>
            <Tr>
              <Td>Sigla</Td>
              <Td isNumeric>{regionalResult?.regional.code.toUpperCase()}</Td>
            </Tr>
            {regionalResult?.regional?.responsible && (
              <Tr>
                <Td>Responsável</Td>
                <Td isNumeric>{regionalResult?.regional.responsible ? captalize(`${regionalResult?.regional.responsible.firstname} ${regionalResult?.regional.responsible.lastname}`) : '-'}</Td>
              </Tr>
            )}
          </Tbody>

        </Table>
      </ModalBody>
    </ModalContent>
  )

}

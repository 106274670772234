import { useEffect, useState } from 'react'
import { usePagination } from "@ajna/pagination";
import { Box, Divider, Flex, Heading } from "@chakra-ui/react";

import { Pagination } from "components/Pagination/Pagination";

import { FaFilter } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { LoadRatioServicesCheckboxFilterButton } from "./components/LoadRatioServicesCheckboxFilterButton";
import { LoadRatioServicesFilterOptions } from './components/LoadRatioServicesFilterOptions';
import { LoadRatioServicesListContextProvider } from 'contexts/LoadRatioServicesListContext';
import { LoadRatioServicesTable } from './components/LoadRatioServicesTable';
import { useAuth } from 'hooks/auth/useAuth';
import { GenerateExcelReportButton } from 'components/Buttons/GenerateExcelReportButton';

export interface FilterOptions {
  showValue: string
  value: string
  selected: boolean
}

const generalFilterOptions = [
  { showValue: 'Protocolo', value: 'protocol', selected: false },
  { showValue: 'Cliente', value: 'customer', selected: false },
  { showValue: 'Data de Coleta', value: 'collectDate', selected: false },
  { showValue: 'Data de Entrega', value: 'deliveryDatetime', selected: false },
  { showValue: 'Cidade de Origem', value: 'sourceCity', selected: false },
  { showValue: 'Cidade de Destino', value: 'destinationCity', selected: false },
  { showValue: 'Rastreador', value: 'tracker', selected: false },
  { showValue: 'CTE', value: 'cte', selected: false },
  { showValue: 'Veiculo', value: 'vehicle', selected: false },
  { showValue: 'Volume', value: 'volume', selected: false },
  { showValue: 'Transportadora', value: 'shipping', selected: false },
  { showValue: 'Base de Origem', value: 'sourceBranch', selected: false },
  { showValue: 'Base de Destino', value: 'destinationBranch', selected: false },
  { showValue: 'Coletador de Destino', value: 'destinationCollector', selected: false },
  { showValue: 'Etapa', value: 'step', selected: false },
]

export function LoadRatioServices() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE
  const [totalPages, setTotalPages] = useState(1)
  const { userLogged } = useAuth()

  const { location, push: redirect } = useHistory()

  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const [generalFilters, setGeneralFilters] = useState<FilterOptions[]>(generalFilterOptions)

  const userLoggedHasViewLoadRatioServicesPermission = userLogged?.permissions.includes("view-load-ratio-service")

  useEffect(() => {
    if (!userLoggedHasViewLoadRatioServicesPermission) {
      redirect('/')
    }
  }, [redirect, userLoggedHasViewLoadRatioServicesPermission])


  function handleSetGeneralFilter(filterType: string) {
    const newGeneralFilters = [...generalFilters]

    const generalFilterSelected = newGeneralFilters.find(
      (generalFilter) => generalFilter.value === filterType
    )
    generalFilterSelected.selected = !generalFilterSelected.selected


    setGeneralFilters(() => newGeneralFilters)
  }

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      },
    });

  useEffect(() => {
    redirect({
      pathname: 'cargas',
      search: `?page=${currentPage}`
    })
  }, [currentPage, redirect])

  const handleChangePage = (page: number) => setCurrentPage(page)
  const handleSetTotalPages = (total: number) => setTotalPages(total)

  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >
      <Heading size='md'>Relação de Cargas em Trânsito</Heading>
      <LoadRatioServicesListContextProvider>
        <Flex
          w="full"
          align="center"
          justify="flex-end"
        >
          <Flex
            gap="4"
            w={["full", "full", "min"]}
            direction={["column", "column", "row"]}
          >
            <LoadRatioServicesCheckboxFilterButton.Root title="Filtros" icon={FaFilter}>
              <LoadRatioServicesCheckboxFilterButton.Content filterOptions={generalFilterOptions} onSetFilterOption={handleSetGeneralFilter} />
            </LoadRatioServicesCheckboxFilterButton.Root>
          </Flex>
        </Flex>
        <Divider mt="4" />
        <Flex
          mt="4"
          gap="4"
          p="2"
          overflow="auto"
          direction='row'
        >
          <LoadRatioServicesFilterOptions filterOptions={generalFilterOptions} />
        </Flex>

        <LoadRatioServicesTable
          rowsPerPage={rowsPerPage}
          currentPageFilter={currentPageFilter}
          onSetTotalPages={handleSetTotalPages}
        />

      </LoadRatioServicesListContextProvider>

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>

  )
}

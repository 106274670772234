import { Flex, Icon, Text, Link as ChakraLink, } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";

interface InternComunicationCardItemProps {
  icon: IconType;
  itemName: string
  isLinkItem?: boolean
  isExternal?: boolean
  href?: string
}

export function InternComunicationCardItem({
  icon,
  itemName,
  isLinkItem,
  isExternal,
  href
}: InternComunicationCardItemProps) {
  return (
    <>
      {isLinkItem ? (
        <Flex
          as={Link}
          to={href}
          alignSelf="flex-start"
          direction="row"
          gap={2}
          transition="all 0.2s"
          _hover={{
            opacity: 0.8
          }}
        >
          <Icon as={icon} fontSize="xl" />
          <Text>{itemName}</Text>
        </Flex>
      ) : isExternal ? (
        <Flex
          as={ChakraLink}
          href={href}
          isExternal
          alignSelf="flex-start"
          direction="row"
          gap={2}
          transition="all 0.2s"
          _hover={{
            opacity: 0.8,
            textDecoration: 'none'
          }}
        >
          <Icon as={icon} fontSize="xl" />
          <Text>{itemName}</Text>
        </Flex>
      ) : (
        <Flex alignSelf="flex-start" direction="row" gap={2}>
          <Icon as={icon} fontSize="xl" />
          <Text>{itemName}</Text>
        </Flex>
      )}
    </>

  )
}

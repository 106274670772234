import { Box, Flex } from "@chakra-ui/react";
import { ActiveFeedbackStatus as ActiveFeedbackStatusTypes } from "api/feedbacks/_types/ActiveFeedback";
import { activeFeedbackStatusMap } from "utils/feedbackMappers";

interface ActiveFeedbackStatusProps {
  status: ActiveFeedbackStatusTypes;
}

export function ActiveFeedbackStatus({ status }: ActiveFeedbackStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['scheduled', 'direct-leader-approving', 'executive-leader-approving', 'awaiting-leader-feedback'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'done' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}


      {activeFeedbackStatusMap[status]}
    </Flex>
  )
}

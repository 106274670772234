import { usePagination } from "@ajna/pagination";
import { Box, Heading, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getDispatchStockProposals } from "api/dispachStocks/getDispatchStockProposals";
import { Empty } from "components/Empty";
import { Pagination } from "components/Pagination/Pagination";
import { useSearchParams } from "hooks/useSearchParams";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { DispatchStockProposalsTableFilters } from "./components/DispatchStockProposalsTableFilters";
import { DispatchStockProposalsTableRow } from "./components/DispatchStockProposalsTableRow";

export function DispatchStockProposals() {
  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const protocol = searchParams.get('protocol')
  const collector = searchParams.get('collector')
  const status = searchParams.get('status') ?? 'all'

  const {
    data: result,
    isError: isDispatchStockProposalsResultError
  } = useQuery({
    queryKey: [
      'dispatch-stock-proposals',
      page,
      protocol,
      collector,
      status
    ],
    queryFn: () => getDispatchStockProposals({
      queryParams: {
        currentPage: page,
        pageSize: '10',
        protocol,
        collector,
        status,
      }
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: result?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: result?.meta?.pageSize
    },
  })

  const history = useHistory()

  const handleChangePage = (page: number) => {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const handleReloadPage = () => window.location.reload()

  if (isDispatchStockProposalsResultError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (
    <Box
      rounded="md"
      p="6"
      bg="white"
    >
      <Heading letterSpacing="tight">Propostas de Despacho de Estoque</Heading>

      <DispatchStockProposalsTableFilters />

      <TableContainer
        mt="6"
        rounded="md"
      >
        <Table size="sm">
          <Thead
            h="40px"
            bg="#38C3FA"
          >
            <Tr>
              <Th></Th>
              <Th textColor="white">Protocolo</Th>
              <Th textColor="white">Coletador</Th>
              <Th textColor="white">Data da Solicitação</Th>
              <Th textColor="white">Data da Aprovação</Th>
              <Th textColor="white">Status</Th>
              <Th textColor="white"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {result?.proposals?.map((proposal) => {
              return (
                <DispatchStockProposalsTableRow key={proposal.id} proposal={proposal} />
              )
            })}
          </Tbody>
        </Table>

      </TableContainer>

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />

    </Box>
  )
}

import { api } from '../../../../services/api'

export type CreateAvailableServiceInputProps = {
  service_id: string
  collector_id: string
  landing_availability_date: string
  landing_availability_hour: string
  observation: string
}

export type UpdateAvailableServiceInputProps = CreateAvailableServiceInputProps

export type UpdateAvailableServiceReqProps = {
  serviceId: string
  updateAvailableServiceInput: UpdateAvailableServiceInputProps
}

const createAvailableServiceReqFunction = (
  createAvailableServiceInput: CreateAvailableServiceInputProps,
) =>
  api
    .post('/available-service', createAvailableServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const updateAvailableServiceReqFunction = ({
  serviceId,
  updateAvailableServiceInput,
}: UpdateAvailableServiceReqProps) =>
  api
    .put(
      `in-progress/available-service/${serviceId}`,
      updateAvailableServiceInput,
    )
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const updateAvailableServicesToLandingServiceReqFunction = () =>
  api
    .put('/available-service')
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

export {
  createAvailableServiceReqFunction,
  updateAvailableServiceReqFunction,
  updateAvailableServicesToLandingServiceReqFunction,
}

import { Menu, MenuProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface BugActionsMenuRootProps extends MenuProps {
  children: ReactNode
}

export function BugActionsMenuRoot({
  children,
  ...rest
}: BugActionsMenuRootProps) {
  return (
    <Menu
      {...rest}
    >
      {children}
    </Menu>
  )
}

import { StandardBackgroundForm } from "../../../../components/Form/StandardBackgroundForm";
import * as yup from "yup"
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { ICreateCollectServiceInput, useCollectServiceFunctions } from "../../../../hooks/collectService/useCollectServiceFunctions";
import { useForm, FormProvider } from "react-hook-form";
import { useService } from "../../../../hooks/services/service";
import { GeneralContentLoading } from "../../../../components/Loading/GeneralContentLoading";
import { useDriver } from "../../../../hooks/driver/useDriver";
import { yupResolver } from "@hookform/resolvers/yup";
import { StartCollectServiceForm } from "../components/StartCollectServiceForm";
import { Accordion, Flex, useDisclosure } from "@chakra-ui/react";
import { RequestedServiceInfoAccordion } from "../../../../components/Accordions/Service/RequestedServiceInfoAccordion";
import { toast } from "react-toastify";
import { useGeolocation } from "../../../../hooks/useGeolocation";
import { useAddresses } from "../../../../hooks/address/useAddresses";
import { useProviders } from "hooks/provider/useProviders";
import { ExternalComunications } from "components/ExternalComunications";
import { useAuth } from "hooks/auth/useAuth";

interface IFormProps {
  provider_id: string;
  driver_id: string;
}

interface IParams {
  service_id: string;
  provider_id: string;
}

const schema = yup.object().shape({
  provider_id: yup.string(),
  driver_id: yup.string().required("Campo Obrigatório")
})

async function alertGeolocationError(error: GeolocationPositionError) {
  if (error.code === 1) {
    toast.error("Para iniciar a coleta, é necessário que a localização esteja habilitada!")
  }
  if (error.code === 2) {
    toast.error("Localização não está disponível! Tente recarregar a página ou verifique as configurações do dispositivo.")
  }
}

export function StartProviderCollectService() {
  const { provider_id, service_id }: IParams = useParams()
  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()

  const {
    service: { data: service, isLoading: isServiceLoading }
  } = useService(service_id)
  const { data: providers, isLoading: isProvidersLoading } = useProviders();
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    drivers: { data: drivers, isLoading: isDriversLoading }
  } = useDriver(null, true)

  const {
    createCollectService: {
      mutateAsync: createCollectService, isLoading: isCreateCollectServiceLoading, isSuccess: isCreateCollectServiceSuccess
    }
  } = useCollectServiceFunctions()

  const { position, error: geolocationError } = useGeolocation()

  const driversFilteredBySourceCollector = drivers?.filter(driver => driver.collector_id === service?.serviceIDRequested.source_collector_id)

  const formMethods = useForm<IFormProps>({
    resolver: yupResolver(schema)
  })

  const { setValue, handleSubmit } = formMethods

  useEffect(() => {
    setValue('provider_id', provider_id)
  }, [setValue, provider_id])

  const autoCreateCollectServiceCallback = useCallback(async (submitData: ICreateCollectServiceInput) => {
    await createCollectService(submitData)
  }, [createCollectService])

  const serviceDriverProviderAssignmentExists = service?.serviceIDRequested.driver_provider_assign

  useEffect(() => {
    async function autoStartCollect() {
      if (serviceDriverProviderAssignmentExists) {
        const driverProviderAssignment = serviceDriverProviderAssignmentExists
          .map(assignment => JSON.parse(assignment as string))
          .find(assignment => assignment.provider_id === provider_id)

        if (driverProviderAssignment) {
          setValue("provider_id", driverProviderAssignment.provider_id)
          setValue("driver_id", driverProviderAssignment.driver_id)

          if (geolocationError) return alertGeolocationError(geolocationError)

          if (position) {
            const submitData = {
              provider_id,
              service_id,
              driver_id: driverProviderAssignment.driver_id,
              arrival_latitude: String(position?.coords.latitude),
              arrival_longitude: String(position?.coords.longitude),
              arrival_timestamp: new Date(),
              isBusiness: false
            }

            await autoCreateCollectServiceCallback(submitData)
          }
        }
      }
    }

    autoStartCollect()
  }, [
    provider_id,
    autoCreateCollectServiceCallback,
    serviceDriverProviderAssignmentExists,
    service_id,
    setValue,
    position,
    geolocationError
  ])

  async function handleStartCollectService(values: IFormProps) {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }

    const submitData = {
      ...values,
      provider_id,
      service_id,
      arrival_latitude: String(position?.coords.latitude),
      arrival_longitude: String(position?.coords.longitude),
      arrival_timestamp: new Date(),
      isBusiness: false
    }

    await createCollectService(submitData)
  }

  useEffect(() => {
    if (isCreateCollectServiceSuccess) {
      redirectTo(`/servico/${service_id}/finalizar/coleta/fornecedor/${provider_id}`)
    }
  })

  const {
    isOpen: isExternalComunicationModalOpen,
    onOpen: onOpenExternalComunicationModal,
    onClose: onCloseExternalComunicationModal,
  } = useDisclosure()

  if (isServiceLoading || isProvidersLoading || isDriversLoading || isCreateCollectServiceLoading || isAddressesLoading) {
    return (
      <GeneralContentLoading />
    )
  }

  return (
    <>
      {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
        <ExternalComunications.TriggerButton
          aria-label="help-me"
          onClick={onOpenExternalComunicationModal}
        />
      )}
      <ExternalComunications.Root
        isOpen={isExternalComunicationModalOpen}
        onClose={onCloseExternalComunicationModal}
      >
        <ExternalComunications.Content
          serviceId={service.id}
          onClose={onCloseExternalComunicationModal}
        />
      </ExternalComunications.Root>
      <StandardBackgroundForm onSubmit={handleSubmit(handleStartCollectService)} title="Iniciar coleta">
        <Flex direction="column" gap="4">
          <Accordion allowMultiple>
            <RequestedServiceInfoAccordion
              requestedServiceId={service?.serviceIDRequested.id!}
              addresses={addresses!}
            />
          </Accordion>
          <FormProvider {...formMethods}>
            <StartCollectServiceForm
              providers={providers}
              drivers={driversFilteredBySourceCollector ?? []}
              isProviderForm
            />
          </FormProvider>
        </Flex>
      </StandardBackgroundForm>
    </>
  )
}

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { useState, useEffect, Fragment, useContext } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import swal from 'sweetalert'

import { serviceFormatDateToFront } from '../../../utils/ServiceFunctions/serviceFormatDateToFront'
import { serviceHandleStep } from '../../../utils/ServiceFunctions/serviceDict'
import { service_steps } from '../../../utils/customLists'

import {
  RequestedServiceContext,
  ServiceProps,
} from '../../../contexts/ServiceContext'
import { AuthContext } from '../../../contexts/AuthContext'


import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { Select } from '../../../components/Inputs/SelectInput'
import { Input } from '../../../components/Inputs/Input'

import { useService } from '../../../hooks/services/service'

interface FormInputProps {
  protocol: string
  customer: string
  service_type: string
  vehicle: string
  collect_date: string
  delivery_date: string
  correctingStep: string
  step: string
}

interface QueryParams {
  service_id: string
}

const hubSchema = yup.object().shape({
  protocol: yup.string().required(),
})

export function EditServiceInProgress() {
  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])
  const [isLoading, setIsLoading] = useState(true)

  const { handleChangeStepOfService } = useContext(RequestedServiceContext)
  const { userLogged } = useContext(AuthContext)

  const { handleSubmit, register, getValues } = useForm<FormInputProps>({
    resolver: yupResolver(hubSchema),
  })

  const { service_id }: QueryParams = useParams()

  const userCanEditServiceRequested = userLogged?.permissions.includes(
    'edit-service-requested'
  )
  const userCanEditServiceCollected = userLogged?.permissions.includes(
    'edit-service-collected'
  )
  const userCanEditServiceInProgress = userLogged?.permissions.includes(
    'edit-progress-service'
  )


  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)

  useEffect(() => {
    function run() {
      if (!isServiceLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isServiceLoading])

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)
      }
    }
    run()
  }, [serviceById])

  async function handleChangeStep() {
    await swal({
      title: `Deseja alterar a etapa do serviço para ${getValues(
        'correctingStep',
      )}?`,
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        handleChangeStepOfService(service_id, getValues('correctingStep'))
      } else {
        swal('Remoção cancelada com êxito!')
      }
    })
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

    <Flex>
      <Box
        as="form"
        flex="1"
        borderRadius="8px"
        bg="white"
        p={['6', '8']}
        onSubmit={handleSubmit(() => { })}
        noValidate
      >
        <Heading size="lg" fontFamily="poppins">
          Editar Serviço
        </Heading>

        <Divider my="6" borderColor="gray.700" />

        {serviceFilteredById.length &&
          serviceFilteredById.map((service) => (
            <Fragment key={service.id}>
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Stack
                  w="100%"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    isDisabled={true}
                    value={service.protocol}
                    name="protocol"
                    label="Protocolo"
                    required
                  />

                  <Input
                    isDisabled={true}
                    value={service.customerIDService.trading_firstname}
                    name="customer"
                    label="Pertence ao Cliente"
                    required
                  />
                </Stack>

                <Stack
                  w="100%"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    isDisabled={true}
                    value={service.serviceIDRequested.service_type}
                    name="service_type"
                    label="Tipo de Serviço"
                    required
                  />

                  <Input
                    isDisabled={true}
                    value={service.serviceIDRequested.vehicle}
                    name="vehicle"
                    label="Veículo"
                    required
                  />
                </Stack>
              </Stack>

              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Stack
                  w="100%"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    isDisabled={true}
                    value={serviceFormatDateToFront(
                      service.serviceIDRequested.collect_date,
                    )}
                    name="collect_date"
                    type="date"
                    label="Data da Coleta"
                    required
                  />

                  <Input
                    isDisabled={true}
                    value={serviceFormatDateToFront(
                      service.serviceIDRequested.delivery_date,
                    )}
                    name="delivery_date"
                    type="date"
                    label="Data da Entrega"
                    required
                  />
                </Stack>

                <Input
                  isDisabled={true}
                  value={serviceHandleStep(service.step)}
                  name="step"
                  label="Etapa"
                  required
                />
              </Stack>

              {(service.step === 'toCollectService' ||
                service.step === 'collectingService') && (
                  <Flex justify="center" mt="4">
                    <Stack w="full">
                      {(userCanEditServiceRequested || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/${service_id}/editar-solicitacao`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Solicitado
                          </Button>
                        </Link>
                      )}
                    </Stack>
                  </Flex>
                )}

              {(service.step === 'toBoardService' ||
                service.step === 'boardingService' ||
                service.step === 'toBoardValidate') && (
                  <Flex justify="center" mt="4">
                    <VStack spacing="24px" w="100%">
                      {(userCanEditServiceRequested || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/${service_id}/editar-solicitacao`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Solicitado
                          </Button>
                        </Link>
                      )}
                      {(userCanEditServiceCollected || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/coletas/andamento/${service_id}`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Coletado
                          </Button>
                        </Link>
                      )}
                    </VStack>
                  </Flex>
                )}

              {service.step === 'toAllocateService' &&
                service.serviceIDRequested.service_type !== 'DEDICADO' && (
                  <Flex justify="center" mt="4">
                    <VStack spacing="24px" w="100%">
                      {(userCanEditServiceRequested || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/${service_id}/editar-solicitacao`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Solicitado
                          </Button>
                        </Link>
                      )}
                      {(userCanEditServiceCollected || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/coletas/andamento/${service_id}`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Coletado
                          </Button>
                        </Link>
                      )}
                      {userCanEditServiceInProgress && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/embarques/${service_id}`}
                        >
                          <Button type="button" colorScheme="blue" w="full">
                            Editar Embarcado
                          </Button>
                        </Link>
                      )}
                    </VStack>
                  </Flex>
                )}

              {service.step === 'toAvailableService' &&
                service.serviceIDRequested.service_type !== 'DEDICADO' && (
                  <Flex justify="center" mt="4">
                    <VStack spacing="24px" w="100%">
                      {(userCanEditServiceRequested || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/${service_id}/editar-solicitacao`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Solicitado
                          </Button>
                        </Link>
                      )}
                      {(userCanEditServiceCollected || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/coletas/andamento/${service_id}`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Coletado
                          </Button>
                        </Link>
                      )}
                      {userCanEditServiceInProgress && (
                        <>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servico/embarques/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="full">
                              Editar Embarcado
                            </Button>
                          </Link>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servicos/alocados/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="full">
                              Editar Alocado
                            </Button>
                          </Link>
                        </>
                      )}
                    </VStack>
                  </Flex>
                )}

              {(service.step === 'toLandingService' ||
                service.step === 'landingService' ||
                service.step === 'availableService') &&
                service.serviceIDRequested.service_type !== 'DEDICADO' && (
                  <Flex justify="center" mt="4">
                    <VStack spacing="24px" w="100%">
                      {(userCanEditServiceRequested || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/${service_id}/editar-solicitacao`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Solicitado
                          </Button>
                        </Link>
                      )}
                      {(userCanEditServiceCollected || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/coletas/andamento/${service_id}`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Coletado
                          </Button>
                        </Link>
                      )}
                      {userCanEditServiceInProgress && (
                        <>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servico/embarques/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="full">
                              Editar Embarcado
                            </Button>
                          </Link>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servicos/alocados/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="full">
                              Editar Alocado
                            </Button>
                          </Link>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servicos/disponibilizados/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="100%">
                              Editar Disponibilizado
                            </Button>
                          </Link>
                        </>
                      )}
                    </VStack>
                  </Flex>
                )}

              {(service.step === 'toDeliveryService' ||
                service.step === 'deliveringService') && (
                  <Flex justify="center" mt="4">
                    <VStack spacing="24px" w="full">
                      {(userCanEditServiceRequested || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/${service_id}/editar-solicitacao`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Solicitado
                          </Button>
                        </Link>
                      )}
                      {(userCanEditServiceCollected || userCanEditServiceInProgress) && (
                        <Link
                          style={{ width: '100%' }}
                          to={`/servico/coletas/andamento/${service_id}`}
                        >
                          <Button type="button" colorScheme="blue" w="100%">
                            Editar Coletado
                          </Button>
                        </Link>
                      )}
                      {service.serviceIDRequested.service_type !==
                        'DEDICADO' && userCanEditServiceInProgress && (
                          <>
                            <Link
                              style={{ width: '100%' }}
                              to={`/servico/embarques/${service_id}`}
                            >
                              <Button type="button" colorScheme="blue" w="full">
                                Editar Embarcado
                              </Button>
                            </Link>
                            <Link
                              style={{ width: '100%' }}
                              to={`/servicos/alocados/${service_id}`}
                            >
                              <Button type="button" colorScheme="blue" w="full">
                                Editar Alocado
                              </Button>
                            </Link>
                            <Link
                              style={{ width: '100%' }}
                              to={`/servicos/disponibilizados/${service_id}`}
                            >
                              <Button type="button" colorScheme="blue" w="100%">
                                Editar Disponibilizado
                              </Button>
                            </Link>
                            <Link
                              style={{ width: '100%' }}
                              to={`/servicos/desembarcados/${service_id}`}
                            >
                              <Button type="button" colorScheme="blue" w="100%">
                                Editar Desembarcado
                              </Button>
                            </Link>
                          </>
                        )}
                    </VStack>
                  </Flex>
                )}

              {service.step === 'finishedService' && (
                <Flex justify="center" mt="4">
                  <VStack spacing="24px" w="100%">
                    {(userCanEditServiceRequested || userCanEditServiceInProgress) && (
                      <Link
                        style={{ width: '100%' }}
                        to={`/servico/${service_id}/editar-solicitacao`}
                      >
                        <Button type="button" colorScheme="blue" w="100%">
                          Editar Solicitado
                        </Button>
                      </Link>
                    )}
                    {(userCanEditServiceCollected || userCanEditServiceInProgress) && (
                      <Link
                        style={{ width: '100%' }}
                        to={`/servico/coletas/andamento/${service_id}`}
                      >
                        <Button type="button" colorScheme="blue" w="100%">
                          Editar Coletado
                        </Button>
                      </Link>
                    )}
                    {service.serviceIDRequested.service_type !==
                      'DEDICADO' && userCanEditServiceInProgress && (
                        <>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servico/embarques/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="full">
                              Editar Embarcado
                            </Button>
                          </Link>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servicos/alocados/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="full">
                              Editar Alocado
                            </Button>
                          </Link>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servicos/disponibilizados/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="100%">
                              Editar Disponibilizado
                            </Button>
                          </Link>
                          <Link
                            style={{ width: '100%' }}
                            to={`/servicos/desembarcados/${service_id}`}
                          >
                            <Button type="button" colorScheme="blue" w="100%">
                              Editar Desembarcado
                            </Button>
                          </Link>
                        </>
                      )}
                    {userCanEditServiceInProgress && (
                      <Link
                        style={{ width: '100%' }}
                        to={`/servico/entregas/andamento/${service_id}`}
                      >
                        <Button type="button" colorScheme="blue" w="100%">
                          Editar Entregue
                        </Button>
                      </Link>
                    )}
                  </VStack>
                </Flex>
              )}
              {userLogged?.user_type === 'ADMIN' && (
                <Stack mt="4" spacing="24px">
                  <Select
                    {...register('correctingStep')}
                    name="correctingStep"
                    label="Etapa do Serviço"
                    placeholder="Selecione uma opção..."
                    service_steps={service_steps}
                  />

                  <Button colorScheme="blue" onClick={handleChangeStep}>
                    Alterar Etapa do Serviço
                  </Button>
                </Stack>
              )}

              {userLogged?.permissions.includes("change-service-step") && (
                <Stack mt="4" spacing="24px">
                  <Select
                    {...register('correctingStep')}
                    name="correctingStep"
                    label="Etapa do Serviço"
                    placeholder="Selecione uma opção..."
                    service_steps={service_steps.filter(step => step.key === "unsuccessService")}
                  />

                  <Button colorScheme="blue" onClick={handleChangeStep}>
                    Alterar Etapa do Serviço
                  </Button>
                </Stack>
              )}
            </Fragment>
          ))}

        <Flex mt="8" justify="flex-end"></Flex>
      </Box>
    </Flex>

  )
}

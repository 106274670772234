import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import {
  ReportContext,
  StockControlReportProps,
} from '../../../../contexts/ReportContext'
import { ReportLoading } from '../../../Loading/ReportLoading'

export function StockControlTable() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [isLoading, setIsLoading] = useState(true)

  const [searchedStockControlReport, setSearchedStockControlReport] = useState<
    StockControlReportProps[]
  >([])
  const [slicedStockControlReports, setSlicedStockControlReports] = useState<
    StockControlReportProps[]
  >([])

  const [collectorFiltered, setCollectorFiltered] = useState('')
  const [inputFiltered, setInputFiltered] = useState('')
  const [currentStockFiltered, setCurrentStockFiltered] = useState('')

  const { stockControllerReport, isStockControllerReportLoaded } =
    useContext(ReportContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedStockControlReport?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function run() {
      if (isStockControllerReportLoaded) {
        setIsLoading(false)
      }
    }
    run()
  }, [isStockControllerReportLoaded])

  useEffect(() => {
    async function run() {
      setSlicedStockControlReports(stockControllerReport)
    }
    run()
  }, [itemLimit, currentPage, offset, stockControllerReport])

  useEffect(() => {
    function run() {
      const newSlicedBillingReport = slicedStockControlReports?.filter(
        (stockControl) => {
          const collectorFilter = searchBoxFilter(
            stockControl.collector,
            collectorFiltered,
          )
          const inputFilter = searchBoxFilter(stockControl.input, inputFiltered)
          const currentStockFilter = searchBoxFilter(
            stockControl.currentStock.toString(),
            currentStockFiltered,
          )

          if (
            inputFiltered === '' &&
            collectorFiltered === '' &&
            currentStockFiltered === ''
          ) {
            return stockControl
          }
          return collectorFilter && inputFilter && currentStockFilter
        },
      )
      setSearchedStockControlReport(newSlicedBillingReport)
    }
    run()
  }, [
    collectorFiltered,
    inputFiltered,
    currentStockFiltered,
    slicedStockControlReports,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  if (isLoading) {
    return <ReportLoading />
  }

  return (
    <>
      {stockControllerReport.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui! Tente ajustar os filtros e tente
            novamente 😀!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th fontSize="15" color="blue.900">
                    <Flex>
                      <Stack>
                        <SearchBox
                          placeholder="Buscar Coletador..."
                          handleSearch={(e) =>
                            setCollectorFiltered(e.target.value)
                          }
                        />
                        <Text>COLETADOR</Text>
                      </Stack>
                    </Flex>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Flex>
                      <Stack>
                        <SearchBox
                          placeholder="Buscar Insumo..."
                          handleSearch={(e) => setInputFiltered(e.target.value)}
                        />
                        <Text>INSUMO</Text>
                      </Stack>
                    </Flex>
                  </Th>
                  <Th fontSize="15" color="blue.900">
                    <Flex>
                      <Stack>
                        <SearchBox
                          placeholder="Buscar Estoque Atual..."
                          handleSearch={(e) =>
                            setCurrentStockFiltered(e.target.value)
                          }
                        />
                        <Text>ESTOQUE ATUAL</Text>
                      </Stack>
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedStockControlReport
                  ?.slice(offset, offset + Number(itemLimit))
                  .map((cargoReport, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{cargoReport.collector.toString()}</Td>
                        <Td>{cargoReport.input.toUpperCase()}</Td>
                        <Td>{cargoReport.currentStock}</Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

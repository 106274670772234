import { Button, Flex, FormLabel, Icon, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "components/Inputs/Input";
import { useAuth } from "hooks/auth/useAuth";
import { useInternComunicationsFunctions } from "hooks/internComunication/useInternComunicationsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm, useWatch } from "react-hook-form";
import { FiFile } from "react-icons/fi";
import { useQueryClient } from "react-query";
import * as yup from "yup";

interface AttachInternComunicationAttachmentModalProps {
  internComunicationId: string
  isAttachInternComunicationAttachmentModalOpen: boolean
  onCloseAttachInternComunicationAttachmentModal: () => void
}

interface AttachInternComunicationAttachmentModalInputs {
  attachment: FileList
}

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/zip",
  "application/gzip",
  "application/x-zip-compressed",
  "application/x-rar-compressed",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
]

function validateFileType(file: FileList) {
  if (file?.length) {
    return supportedFileFormats.includes(file[0].type)
  }

  return true
}

function validateFileSize(file: FileList) {
  if (file?.length) {
    return file[0].size / 1024 / 1024 <= 15
  }

  return true
}

function validateHasFile(file: FileList) {
  if (!file?.length) return false

  return true
}

const occurrenceAttachPhotoModalSchema = yup.object().shape({
  attachment: yup.mixed()
    .test("length", "Campo obrigatório", value => validateHasFile(value))
    .test("type", "O formato de arquivo enviado não é suportado!", value => validateFileType(value))
    .test("fileSize", "É suportado o upload de arquivo somente até 10Mb", value => validateFileSize(value)),
})

export function AttachInternComunicationAttachmentModal({
  internComunicationId,
  isAttachInternComunicationAttachmentModalOpen,
  onCloseAttachInternComunicationAttachmentModal
}: AttachInternComunicationAttachmentModalProps) {

  const { userLogged } = useAuth()

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {
      isSubmitting,
      errors
    }
  } = useForm<AttachInternComunicationAttachmentModalInputs>({
    resolver: yupResolver(occurrenceAttachPhotoModalSchema)
  })

  const {
    updateInternComunicationAttachment: { mutateAsync: updateInternComunicationAttachment }
  } = useInternComunicationsFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleUpdateInternComunicationAttachment(
    formValues: AttachInternComunicationAttachmentModalInputs
  ) {

    const formData = new FormData()
    formData.append('attachment', formValues.attachment[0])

    await promiseMessage(
      updateInternComunicationAttachment({
        comunication_id: internComunicationId,
        formData
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['owner-intern-comunications', userLogged?.id]
          })
          reset()
          onCloseAttachInternComunicationAttachmentModal()
        }
      }),
      "Arquivo atualizado com sucesso!"
    )
  }

  const comunicationAttachment = useWatch({
    control,
    name: 'attachment'
  })

  const attachmentPhotoPreview = comunicationAttachment
    ? Object.entries(comunicationAttachment).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  return (
    <Modal
      isOpen={isAttachInternComunicationAttachmentModalOpen}
      onClose={onCloseAttachInternComunicationAttachmentModal}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit(handleUpdateInternComunicationAttachment)}
      >
        <ModalHeader>
          <ModalHeader>Adicionar anexo à comunicação interna</ModalHeader>
        </ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={6} align="center" justify="center">
            <Input
              {...register("attachment")}
              name="attachment"
              type="file"
              accept={supportedFileFormats.join(", ")}
              hidden
              label="Arquivo (Opcional)"
              error={errors.attachment}
            >
              <Button
                leftIcon={<Icon as={FiFile} />}
                as={FormLabel}
                htmlFor="attachment"
                _hover={{
                  cursor: "pointer"
                }}
              >Escolha um arquivo</Button>
            </Input>
            {attachmentPhotoPreview && (
              <Link
                href={URL.createObjectURL(comunicationAttachment[0])}
                isExternal
              >
                <Text as='b'>{comunicationAttachment[0].name} (Visualizar)</Text>
              </Link>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Stack spacing={4} direction="row">
            <Button
              type="submit"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
            <Button
              onClick={onCloseAttachInternComunicationAttachmentModal}
            >
              Fechar
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

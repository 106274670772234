import { Flex, Icon, Input, InputProps, useMediaQuery } from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { RiSearchLine } from 'react-icons/ri'

interface SearchBoxProps extends InputProps {
  size?: string
  placeholder: string
  isCheckbox?: boolean
  handleSearch: (e: any) => void
}

const SearchBoxComponent: ForwardRefRenderFunction<HTMLInputElement, SearchBoxProps> = ({
  size,
  placeholder,
  handleSearch,
  isCheckbox = false,
  ...rest
}, ref) => {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  return (
    <Flex
      as="label"
      flex="1"
      py="2"
      px="2"
      maxWidth={isCheckbox === true ? 'full' : isWideVersion ? '250px' : 'full'}
      alignSelf="center"
      color="blue.800"
      position="relative"
      bg="gray.100"
      w={isCheckbox === true ? 'full' : isWideVersion ? '250px' : 'full'}
      borderRadius="4"
    >
      <Input
        color="blue.900"
        variant="unstyled"
        px="0"
        mr="2"
        size={size}
        placeholder={placeholder}
        onChange={handleSearch}
        _placeholder={{ color: 'gray.400' }}
        ref={ref}
        {...rest}
      />
      <Icon as={RiSearchLine} fontSize="20" />
    </Flex>
  )
}


export const SearchBox = forwardRef(SearchBoxComponent)

import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert";
import { Fragment } from "react";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { formatDate } from "utils/DateFunctions/formatDate";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";
import { PriceLogs } from "utils/RequestFunctions/Price/requestPriceFunctions";
import { PriceLogLine } from "./PriceLogLine";


interface PriceLogsModalProps {
  isOpen: boolean
  onClose: () => void
  priceLogs: PriceLogs[]
  cities: CitiesProps[]
  hubs: IHubsProps[]
}

export function PriceLogsModal({
  isOpen,
  onClose,
  priceLogs,
  hubs,
  cities
}: PriceLogsModalProps) {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="850px">
        <ModalHeader>Logs</ModalHeader>
        <ModalCloseButton />

        <ModalBody maxH='500px' h='full' overflow='auto' >
          <Flex direction='column' align='center'>
            <TableContainer borderRadius='md' maxW='700px'>
              <Table m='2' maxW='500px' variant='simple' size='sm'>
                <Thead>
                  <Tr>
                    <Th>CAMPO</Th>
                    <Th>VALOR ANTERIOR</Th>
                    <Th>VALOR ATUALIZADO</Th>
                    <Th>ATUALIZADO POR</Th>
                    <Th>DATA DA ATUALIZAÇÃO</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {priceLogs ? priceLogs.map((log) => {
                    return (
                      <Fragment key={log.id}>
                        {log.log_detail?.source_city_id && (
                          <PriceLogLine
                            name="Cidade Origem"
                            prevValue={cities?.find((city) => city.id === log.log_detail?.source_city_id.prev_value)?.name}
                            newValue={cities?.find((city) => city.id === log.log_detail?.source_city_id.new_value)?.name}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.source_hub_id && (
                          <PriceLogLine
                            name="Hub Origem"
                            prevValue={hubs?.find((hub) => hub.id === log.log_detail?.source_hub_id.prev_value)?.name}
                            newValue={hubs?.find((hub) => hub.id === log.log_detail?.source_hub_id.new_value)?.name}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.destination_city_id && (
                          <PriceLogLine
                            name="Cidade Destino"
                            prevValue={cities?.find((city) => city.id === log.log_detail?.destination_city_id.prev_value)?.name}
                            newValue={cities?.find((city) => city.id === log.log_detail?.destination_city_id.new_value)?.name}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.destination_hub_id && (
                          <PriceLogLine
                            name="Hub Destino"
                            prevValue={hubs?.find((hub) => hub.id === log.log_detail?.destination_hub_id.prev_value)?.name}
                            newValue={hubs?.find((hub) => hub.id === log.log_detail?.destination_hub_id.new_value)?.name}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`}
                            updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.category && (
                          <PriceLogLine
                            name="Categoria"
                            prevValue={String(log.log_detail?.category.prev_value)}
                            newValue={String(log.log_detail?.category.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.route_type && (
                          <PriceLogLine
                            name="Tipo de Rota"
                            prevValue={String(log.log_detail?.route_type.prev_value)}
                            newValue={String(log.log_detail?.route_type.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.air_minimum_price && (
                          <PriceLogLine
                            name="Preço mínimo aéreo"
                            prevValue={String(log.log_detail?.air_minimum_price.prev_value)}
                            newValue={String(log.log_detail?.air_minimum_price.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.air_extra_kg && (
                          <PriceLogLine
                            name="KG extra aéreo"
                            prevValue={String(log.log_detail?.air_extra_kg.prev_value)}
                            newValue={String(log.log_detail?.air_extra_kg.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.air_deadline && (
                          <PriceLogLine
                            name="Prazo aéreo"
                            prevValue={String(`D-${log.log_detail?.air_deadline.prev_value}`)}
                            newValue={String(`D-${log.log_detail?.air_deadline.new_value}`)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.highway_minimum_price && (
                          <PriceLogLine
                            name="Preço mínimo rodoviário"
                            prevValue={String(log.log_detail?.highway_minimum_price.prev_value)}
                            newValue={String(log.log_detail?.highway_minimum_price.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.highway_extra_kg && (
                          <PriceLogLine
                            name="KG extra rodoviário"
                            prevValue={String(log.log_detail?.highway_extra_kg.prev_value)}
                            newValue={String(log.log_detail?.highway_extra_kg.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.highway_deadline && (
                          <PriceLogLine
                            name="Prazo rodoviário"
                            prevValue={String(`D-${log.log_detail?.highway_deadline.prev_value}`)}
                            newValue={String(`D-${log.log_detail?.highway_deadline.new_value}`)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.additional_collect_price && (
                          <PriceLogLine
                            name="Preço adicional coleta"
                            prevValue={String(log.log_detail?.additional_collect_price.prev_value)}
                            newValue={String(log.log_detail?.additional_collect_price.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.additional_delivery_price && (
                          <PriceLogLine
                            name="Preço adicional entrega"
                            prevValue={String(log.log_detail?.additional_delivery_price.prev_value)}
                            newValue={String(log.log_detail?.additional_delivery_price.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log?.log_detail?.observation && (
                          <PriceLogLine
                            name="Observações"
                            prevValue={String(log.log_detail?.observation.prev_value)}
                            newValue={String(log.log_detail?.observation.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                      </Fragment>

                    )
                  }) : (
                    <EmptyContentTableAlert title="Ops..." description="Não há logs para este preço!" />
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button
              onClick={onClose}
              colorScheme='gray'
              variant='ghost'
            >
              Fechar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { ExternalComunication } from "./dtos/ExternalComunication";

interface ExternalComunicationsResponse {
  externalComunications: ExternalComunication[]
  totalPages: number
}

type IninityQueryOptions = UseInfiniteQueryOptions<ExternalComunicationsResponse, unknown, ExternalComunicationsResponse, QueryKey>

interface ExternalComunicationsQueryParams {
  status?: 'open' | 'closed'
  pageSize?: number
  currentPage?: number
}

interface UseExternalComunicationsOptions {
  typeId?: string
  queryKey?: string
  queryOptions?: IninityQueryOptions
  queryParams?: ExternalComunicationsQueryParams
}

export function useExternalComunications(options?: UseExternalComunicationsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  async function getExternalComunications({
    pageParam = 1
  }) {
    try {
      const { data } = await api.get('/comunications/external', {
        params: {
          ...options?.queryParams,
          currentPage: pageParam
        }
      })
      return data

    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  return useInfiniteQuery(
    [options?.queryKey || 'externalComunications', { ...options?.queryParams }],
    getExternalComunications,
    {
      ...options?.queryOptions,
      refetchOnWindowFocus: false,
      onError: async (error) => onRequestError(error)
    })
}

import { Box, Card, CardBody, CardHeader, Flex, Grid, GridItem, Heading, IconButton, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { getProductsWithOrUnderMinStock } from "api/products/getProductsWithOrUnderMinStock"
import { Empty } from "components/Empty"

import { FaArrowLeft } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { captalize } from "utils/captalize"
import { InfoCardsLoading } from "../components/InfoCardsLoading"

export function ProductsWithOrUnderMinimumStock() {
  const {
    data: result,
    isError,
    isLoading: isLoadingProductsWithOrUnderMinimumStockResult
  } = useQuery({
    queryKey: ['products-with-or-under-minimum-stock'],
    queryFn: getProductsWithOrUnderMinStock
  })

  const handleReloadPage = () => window.location.reload()

  const history = useHistory()

  const handleReturnToDashboard = () => history.goBack()

  if (isError) {
    return (
      <Empty.Root>
        <Empty.ActionButton onClick={handleReloadPage}>Recarregar a página</Empty.ActionButton>
      </Empty.Root>
    )
  }

  return (

    <Box
      p="6"
      rounded="md"
      bg="white"
      display="flex"
      flexDirection="column"
      gap="6"
    >
      <Flex
        align="center"
        gap="2"
      >
        <IconButton
          aria-label="Voltar ao dashboard"
          icon={<FaArrowLeft />}
          variant="ghost"
          size="sm"
          onClick={handleReturnToDashboard}
        />
        <Heading letterSpacing="tight">Produtos em Estoque Mínimo ou Abaixo</Heading>
      </Flex>

      <Grid
        gap="6"
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
      >
        {isLoadingProductsWithOrUnderMinimumStockResult && (
          <InfoCardsLoading />
        )}
        {result?.products?.map((product) => {
          return (
            <GridItem
              key={product.id}
            >
              <Card
                rounded="xl"
                transition="all 0.2s"
                _hover={{
                  transform: 'translateY(-0.5rem)'
                }}
                h="full"
              >
                <CardHeader
                  as={Link}
                  display="flex"
                  alignItems="center"
                  to={`/produto/visualizar/${product.id}`}
                  h="40px"
                  bg="#38c3fa"
                  borderTopRadius="xl"
                >
                  <Text fontWeight="bold" color="white">{captalize(product.material.material_name)}</Text>
                </CardHeader>

                <CardBody>
                  <Table size="sm">
                    <Tbody>
                      <Tr>
                        <Td fontSize='md'>Estoque padrão</Td>
                        <Td isNumeric fontSize='md'>{product.standard_stock}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Estoque Mínimo</Td>
                        <Td isNumeric fontSize='md'>{product.min_stock}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Estoque Atual</Td>
                        <Td isNumeric fontSize='md'>{product.current_stock}</Td>
                      </Tr>
                      <Tr>
                        <Td fontSize='md'>Almoxarifado</Td>
                        <Td isNumeric fontSize='md'>{captalize(product.warehouse.name)}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          )
        })}
      </Grid>
    </Box>

  )
}

import * as yup from "yup"
import { Box, Button, ButtonGroup, Divider, Heading, Stack } from "@chakra-ui/react";
import { MaterialRequest } from "hooks/materialRequests/dtos/MaterialRequest";
import { useFieldArray, useForm } from "react-hook-form";
import { MaterialRequestInfo } from "./MaterialRequestInfo";
import { MaterialRequestModal } from "./MaterialRequestModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToastify } from "hooks/toastify/useToastify";
import { useQueryClient } from "react-query";
import { useMaterialRequestsFunction } from "hooks/materialRequests/useMaterialRequestsFunctions";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { Fragment, useEffect } from "react";
import { Select } from "components/Inputs/SelectInput";

interface ApproveMaterialRequestFormInputs {
  approvedProducts: {
    productId: string
    quantity: number
    prevQuantity: number
  }[]
  approvedObservations: string
}

interface ApproveMaterialRequestProps {
  isOpen: boolean
  onClose: () => void
  materialRequest: MaterialRequest
}

const approveMaterialRequestSchema = yup.object().shape({
  approvedProducts: yup.array().min(1).of(yup.object({
    productId: yup.string().required('Campo obrigatório'),
    quantity: yup.number().required('Campo obrigatório'),
  })),
  approvedObservations: yup.string().nullable()
})

export function ApproveMaterialRequest({
  isOpen,
  onClose,
  materialRequest
}: ApproveMaterialRequestProps) {

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ApproveMaterialRequestFormInputs>({
    resolver: yupResolver(approveMaterialRequestSchema)
  })

  const {
    fields: approvedProductsFields,
    update,
  } = useFieldArray({
    control,
    name: 'approvedProducts'
  })

  useEffect(() => {
    if (materialRequest) {
      materialRequest?.products?.forEach((product, index) => {
        update(index, {
          prevQuantity: product.quantity,
          productId: product.product.id,
          quantity: 0
        })
      })
    }
  }, [update, materialRequest])

  const {
    approveMaterialRequest
  } = useMaterialRequestsFunction()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleApproveMaterialRequest(
    values: ApproveMaterialRequestFormInputs
  ) {
    await promiseMessage(approveMaterialRequest.mutateAsync({
      materialRequestId: materialRequest.id,
      input: values
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('materialRequests')
        onClose()
      }
    }), 'RSM aprovada com sucesso!')
  }

  const productsSelectOptions = materialRequest?.products?.map((product) => {
    return {
      key: product.product.id,
      value: product.product.id,
      showOption: product.product.material.material_name
    }
  })

  return (
    <MaterialRequestModal.Root
      title="Aprovar RSM"
      isOpen={isOpen}
      onClose={onClose}
    >
      <MaterialRequestModal.Content>
        <MaterialRequestInfo materialRequest={materialRequest} />

        <Divider />

        <Box
          as="form"
          onSubmit={handleSubmit(handleApproveMaterialRequest)}
          noValidate
          display="flex"
          flexDirection="column"
          gap="4"
        >
          <Stack
            spacing="4"
            direction={{
              base:
                'column'
            }}
            border="1px solid"
            borderColor={errors?.approvedProducts ? "red.500" : "gray.200"}
            rounded="xl"
            py="3"
            px="4"
          >
            <Heading fontSize="lg" letterSpacing="tight">Produtos</Heading>

            {approvedProductsFields.map((field, index) => {

              return (
                <Fragment key={field.id}>

                  <Divider borderColor="gray.200" />
                  <Stack spacing="4" direction={{ base: 'column', lg: 'row' }}>
                    <Select
                      {...register(`approvedProducts.${index}.productId`)}
                      name={`approvedProducts.${index}.productId`}
                      label="Produto"
                      options={productsSelectOptions}
                      error={
                        errors.approvedProducts
                          ? errors.approvedProducts[index]?.productId
                          : undefined
                      }
                      isDisabled
                      required
                    />

                    <Stack w="full" spacing="4" direction={{ base: 'column', lg: 'row' }}>
                    <Input
                        {...register(`approvedProducts.${index}.prevQuantity`)}
                        name={`approvedProducts.${index}.prevQuantity`}
                        label="Qtde solicitada"
                        required
                        isDisabled
                      />

                      <Input
                        {...register(`approvedProducts.${index}.quantity`)}
                        name={`approvedProducts.${index}.quantity`}
                        type="number"
                        label="Qtde aprovada"
                        error={errors?.approvedProducts
                          ? errors?.approvedProducts[index].quantity
                          : undefined
                        }
                        max={field.prevQuantity}
                        required
                      />


                    </Stack>
                  </Stack>
                </Fragment>
              )
            })}
          </Stack>

          <TextArea
            {...register("approvedObservations")}
            name="approvedObservations"
            label="Observações"
            error={errors.approvedObservations}
          />

          <ButtonGroup alignSelf="flex-end" >
            <Button
              type="submit"
              colorScheme="blue"
            >
              Aprovar
            </Button>
          </ButtonGroup>
        </Box>
      </MaterialRequestModal.Content>

    </MaterialRequestModal.Root>
  )
}

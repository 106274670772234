import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface InactivateTrainingParams {
  trainingId: string
}

export async function inactivateTraining({
  trainingId,
}: InactivateTrainingParams) {
  try {
    await api.patch(`/trainings/${trainingId}/inactivate`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { usePagination } from "@ajna/pagination";
import { Box, Heading, IconButton, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { AsoStatus as AsoStatusTypes } from "api/asos/aso";
import { getAsosReport } from "api/asos/getAsosReport";
import { Pagination } from "components/Pagination/Pagination";

import { format } from "date-fns";
import { useSearchParams } from "hooks/useSearchParams";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { asoStatusMap } from "utils/asoMappers";
import { captalize } from "utils/captalize";
import { AsosReportFilters } from "./components/AsosReportFilters";
import { AsoStatus } from "./components/AsoStatus";

const headers = [
  { label: 'Regional', key: 'regional.code' },
  { label: 'Colaborador', key: 'collaborator_name' },
  { label: 'Data de vencimento', key: 'due_date' },
  { label: 'Status', key: 'status' },
  { label: 'Anexo', key: 'attachment.link' },
]

export function AsosReport() {
  const searchParams = useSearchParams()

  const status = searchParams.get('status') ?? 'all'
  const collaborator = searchParams.get('collaborator')

  const {
    data: asosReportData
  } = useQuery({
    queryKey: ['asos-report', status, collaborator],
    queryFn: () => getAsosReport({
      collaborator,
      status: status as AsoStatusTypes | 'all',
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const csvProps = {
    data: asosReportData ? asosReportData?.asos.map(aso => {
      return {
        ...aso,
        due_date: aso.due_date ? format(new Date(aso.due_date), 'dd/MM/yyyy') : '-',
        status: asoStatusMap[aso.status],
      }
    })
      : [],
    headers,
    filename: `relatório-asos-${new Date().toISOString()}.csv`,
  }

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
      >
        <Heading letterSpacing="tight" >Relatório de Asos</Heading>

        <AsosReportFilters csvProps={csvProps} />

        {asosReportData && (
          <TableContainer mt="6">
            <Table
              size="sm"
            >
              <Thead>
                <Tr>
                  <Th>Regional</Th>
                  <Th>Colaborador</Th>
                  <Th>Data de vencimento</Th>
                  <Th>Status</Th>
                  <Th>Anexo</Th>
                </Tr>
              </Thead>

              <Tbody>
                {asosReportData?.asos.slice(offset, offset + 10).map((aso) => {
                  return (
                    <Tr key={aso.id}>
                      <Td>{aso.regional ? aso.regional.code.toUpperCase() : '-'}</Td>
                      <Td>{captalize(aso.collaborator_name)}</Td>
                      <Td>{aso.due_date ? format(new Date(aso.due_date), 'dd/MM/yyyy') : '-'}</Td>
                      <Td>
                        <AsoStatus status={aso.status} />
                      </Td>
                      <Td>
                        {aso.attachment ? (
                          <IconButton
                            aria-label="Abrir anexo de aso"
                            as={Link}
                            href={aso.attachment.link}
                            isExternal
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                          />
                        ) : (
                          '-'
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          handlePageChange={handleChangePage}
          pagesQuantity={pagesCount}
          pages={pages}
          currentPage={currentPage}
        />


      </Box>


  )
}

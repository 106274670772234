import { Text, TextProps } from '@chakra-ui/react'

interface PmcCardPurchaseOrderItemProps extends TextProps {
  title: string
  value: string
}

export function PmcCardPurchaseOrderItem({
  title,
  value,
  ...props
}: PmcCardPurchaseOrderItemProps) {
  return (
    <Text
      as="em"
      fontFamily="poppins"
      align="left"
      color="blue.900"
      fontSize="md"
      fontWeight="light"
      {...props}
    >
      {title}:  {value}
    </Text>
  )
}

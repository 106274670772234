import { Box, Flex, Heading, Icon, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from "@chakra-ui/react";
import { StandardInternComunicationModal } from "components/InternComunications/components/StandardInternComunicationModal";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { InternComunicationsFilters } from "./components/InternComunicationsFilters";
import { OwnerInternComunications } from "./components/OwnerInternComunications";
import { ResponsibleInternComunications } from "./components/ResponsibleInternComunications";
import { TeamInternComunications } from "./components/TeamInternComunications";

function switchDefaultTabIndex(type?: string) {
  switch (type) {
    case 'responsible':
      return 0
    case 'owner':
      return 1
    case 'team':
      return 2
    default:
      return 0
  }
}

export function InternComunications() {
  const { userLogged } = useAuth()
  const history = useHistory()
  const searchParams = useSearchParams()

  const userCanViewInternComunications = userLogged?.permissions.includes("view-intern-comunication")

  useEffect(() => {
    if (!userCanViewInternComunications) history.push("/")
  }, [userCanViewInternComunications, history])

  const {
    isOpen: isStandardInternComunicationModalOpen,
    onOpen: onOpenStandardInternComunicationModal,
    onClose: onCloseStandardInternComunicationModal
  } = useDisclosure()

  const tabType = searchParams.get('type')

  const tabIndex = switchDefaultTabIndex(tabType)

  return (

    <Box
      p="6"
      rounded="md"
      bg="white"
      scrollBehavior="smooth"
    >
      <Flex alignItems="center" justify="space-between">
        <Heading letterSpacing="tight">Comunicações internas</Heading>

        <IconButton
          aria-label="Nova comunicação"
          icon={<Icon as={FaPlus} />}
          colorScheme="blue"
          onClick={onOpenStandardInternComunicationModal}
        />

        <StandardInternComunicationModal
          isOpen={isStandardInternComunicationModalOpen}
          onClose={onCloseStandardInternComunicationModal}
        />
      </Flex>

      <InternComunicationsFilters />

      <Tabs mt="6" isLazy defaultIndex={tabIndex}>
        <TabList>
          <Tab>Abertas para você</Tab>
          <Tab>Abertas por você</Tab>
          <Tab>Seu time</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ResponsibleInternComunications />
          </TabPanel>
          <TabPanel>
            <OwnerInternComunications />
          </TabPanel>
          <TabPanel>
            <TeamInternComunications />
          </TabPanel>
        </TabPanels>
      </Tabs>

    </Box>

  )
}

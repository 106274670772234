import { Box, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

export function ExistingCollectorSuppliesForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      expectedStartDateForNewService: 'Data prevista para início do novo serviço',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };
  return (
    <Box w='full'>

      <FormControl isInvalid={!!errors.formData?.expectedStartDateForNewService} mt={3}>
        <FormLabel fontSize='sm'>
          Data prevista para início do novo serviço
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.expectedStartDateForNewService.value')}
          name='formData.expectedStartDateForNewService.value'
          type="date"
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('expectedStartDateForNewService')}
        />
      </FormControl>
    </Box>
  )
}

import { api } from '../../../../services/api'

export type GenerateLabelInputProps = {
  source_address_id: string
  destination_address_id: string
  service_protocol: number
}

const generateServiceLabelReqFunction = (input: GenerateLabelInputProps) =>
  api
    .post(`/label-pdf`, input, { responseType: 'arraybuffer' })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

export { generateServiceLabelReqFunction }

import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ExternalComunicationsChatContentProps extends BoxProps {
  children: ReactNode
}

export function ExternalComunicationsChatContent({
  children,
  ...rest
}: ExternalComunicationsChatContentProps) {
  return (
    <Box
      bgColor="gray.50"
      p="4"
      minW="220px"
      w="full"
      minH="300px"
      maxH="400px"
      h="full"
      display="flex"
      flexDirection="column"
      overflowY="auto"
      fontSize="xs"
      {...rest}
    >
      {children}
    </Box>
  )
}

import { VaccineType } from './vaccine';
import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreateVaccineBody {
  cardBackPhotoId: string
  cardFrontPhotoId: string
  collaboratorName: string
  collaboratorEmail: string
  regionalId: string
  collaboratorType: string
  doses: string[],
  type: VaccineType
  nextDoseDate: string
  pending: boolean

}

interface CreateVaccineParams {
  body: CreateVaccineBody
}

export async function createVaccine({
  body
}: CreateVaccineParams) {
  try {
    await api.post('/vaccines', {
      cardBackPhotoId: body.cardBackPhotoId,
      cardFrontPhotoId: body.cardFrontPhotoId,
      collaboratorName: body.collaboratorName,
      collaboratorEmail: body.collaboratorEmail,
      regionalId: body.regionalId,
      collaboratorType: body.collaboratorType,
      doses: body.doses,
      type: body.type,
      nextDoseDate: body.nextDoseDate,
      pending: body.pending
    })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { AddressRequestedChange } from "api/addresses/addressRequestedChange";

import { FaSearch } from "react-icons/fa";
import { captalize } from "utils/captalize";
import { AddressesWithRequestedChangesStatus } from "./AddressesWithRequestedChangesStatus";
import { AddressRequestedChangesDetail } from "./AddressRequestedChangesDetail";

interface AddressesWithRequestedChangesTableRowProps {
  requestedChange: Pick<AddressRequestedChange, 'id' | 'status' | 'address'>
}

export function AddressesWithRequestedChangesTableRow({
  requestedChange
}: AddressesWithRequestedChangesTableRowProps) {

  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure()

  return (
    <Tr key={requestedChange.id}>
      <Td>
        <IconButton
          icon={<FaSearch />}
          aria-label="Validar solicitação de alteração"
          size="sm"
          variant="ghost"
          lineHeight="1"
          onClick={onOpen}
        />
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
          <ModalOverlay />

          <AddressRequestedChangesDetail addressRequestChangeId={requestedChange.id} />

        </Modal>
      </Td>
      <Td>{captalize(requestedChange.address.customerIDAddress.trading_firstname)}</Td>
      <Td>{
        captalize(`${requestedChange.address.street}, ${requestedChange.address.number} ${' '}
    ${requestedChange.address.neighborhood} - ${requestedChange.address.cityIDAddress.name}${' '}`)
      }</Td>
      <Td>
        <AddressesWithRequestedChangesStatus status={requestedChange.status} />
      </Td>
    </Tr>
  )
}

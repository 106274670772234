import { ExperiencePeriodStatus, StandardizationType,  } from "api/experiencePeriods/types/ExperiencePeriod";

export const experiencePeriodStatusMap: Record<ExperiencePeriodStatus, string> = {
  active: 'Ativo',
  "validating-45-days": 'À validar 45 dias',
  "validating-90-days": 'À validar 90 dias',
  dismissing: 'Demissão solicitada'
}

export const standardizationMap : Record<StandardizationType, string> = {
  complete: 'Completa',
  incomplete: 'Incompleta',
  "n/a": 'N/A'
}

import { Button, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Link as ChakraLink, Icon } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useAuth } from "hooks/auth/useAuth";
import { useInternComunicationsFunctions } from "hooks/internComunication/useInternComunicationsFunctions";
import { useInternComunicationSocket } from "hooks/socket/useInternComunicationSocket";
import { useSwal } from "hooks/swal/useSwal";
import { useForm } from "react-hook-form";
import { FiExternalLink } from "react-icons/fi";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { handleChangeUrl } from "utils/handleChangeUrl";
import * as yup from "yup"

interface InternComunicationValidateAnswerModalInputs {
  comunication_question: string
  is_satisfactory_answer: string
}

interface InternComunicationLastResolutionMessages {
  comunication_answer: string
  comunication_question: string
  attachments: string[] | null
}

interface InternComunicationValidateAnswerModalInfo {
  id: string
  comunication_responsible: string
  internComunicationLastResolutionMessages: InternComunicationLastResolutionMessages
}

interface InternComunicationValidateAnswerModalProps {
  isInternComunicationValidateAnswerModalOpen: boolean
  onCloseInternComunicationValidateAnswerModal: () => void
  internComunicationValidateAnswerModalInfo: InternComunicationValidateAnswerModalInfo
}

const satisfactoryAnswerOptions = [
  { key: "no", value: "NÃO", showOption: "NÃO (SOLICITAR NOVA RESPOSTA)" },
  { key: "yes", value: "SIM", showOption: "SIM (ENCERRAR COMUNICAÇÃO INTERNA)" }
]

const internComunicationValidateAnswerModalSchema = yup.object().shape({
  is_satisfactory_answer: yup.string().required("Campo obrigatório"),
  comunication_question: yup.string().when("is_satisfactory_answer", {
    is: "NÃO",
    then: yup.string().required("Campo obrigatório")
  }),
})

function cleanText(text: string) {
  return text?.replace(/<\/?[^>]+(>|$)/g, '').replace(/strong/g, '').trim();
}

export function InternComunicationValidateAnswerModal({
  internComunicationValidateAnswerModalInfo,
  isInternComunicationValidateAnswerModalOpen,
  onCloseInternComunicationValidateAnswerModal
}: InternComunicationValidateAnswerModalProps) {
  const history = useHistory()

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<InternComunicationValidateAnswerModalInputs>({
    defaultValues: {
      is_satisfactory_answer: "SIM"
    },
    resolver: yupResolver(internComunicationValidateAnswerModalSchema)
  })

  const isSatisfactoryAnswer = watch("is_satisfactory_answer") === "SIM"
  const hasAnswerAtachments = internComunicationValidateAnswerModalInfo.internComunicationLastResolutionMessages.attachments

  const {
    validateInternComunicationAnswer: { mutateAsync: validateInternComunicationAnswer }
  } = useInternComunicationsFunctions()

  const { standardMessage, confirmMessage } = useSwal()
  const { internComunicationSocketConnection } = useInternComunicationSocket()

  const { userLogged } = useAuth()
  const queryClient = useQueryClient()

  async function handleValidateInternComunicationAnswer(
    values: InternComunicationValidateAnswerModalInputs
  ) {
    let title = ''

    if (isSatisfactoryAnswer) {
      title = "Deseja validar e finalizar a comunicação interna?"
    } else {
      title = "Deseja solicitar uma nova resposta para a comunicação interna?"
    }

    const hasValidateInternComunication = await confirmMessage({ title })

    const validateInternComunicationAnswerInput = {
      ...values,
      is_satisfactory_answer: values.is_satisfactory_answer === "SIM"
    }

    if (hasValidateInternComunication) {

      internComunicationSocketConnection.emit("internComunicationValidated", {
        comunication_question: values.comunication_question,
        is_satisfactory_answer: values.is_satisfactory_answer,
        comunication_responsible: internComunicationValidateAnswerModalInfo.comunication_responsible
      })

      await validateInternComunicationAnswer({
        id: internComunicationValidateAnswerModalInfo.id,
        input: validateInternComunicationAnswerInput
      }, {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: ['owner-intern-comunications', userLogged?.id]
          })
          reset()
          history.replace({ state: undefined })
          onCloseInternComunicationValidateAnswerModal()
        }
      })
      await queryClient.invalidateQueries('userTasks')
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  function handleCloseModal() {
    history.replace({ state: undefined })
    onCloseInternComunicationValidateAnswerModal()
  }

  return (
    <Modal
      isOpen={isInternComunicationValidateAnswerModalOpen}
      onClose={handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(handleValidateInternComunicationAnswer)}>
        <ModalHeader>Validar resposta comunicação interna</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={6} p={4}>
            <Flex
              w="full"
              gap={6}
              flexDirection="column"
              alignItems="center"
            >
              <TextArea
                name="comunication_question"
                label="Solicitação"
                isReadOnly={true}
                opacity="0.5"
                value={cleanText(internComunicationValidateAnswerModalInfo.internComunicationLastResolutionMessages.comunication_question)}
                style={{
                  minHeight: '100px',
                  resize: 'vertical',
                  overflow: 'hidden',
                }}
              />
              <TextArea
                name="comunication_question"
                label="Resposta"
                isReadOnly={true}
                opacity="0.5"
                value={internComunicationValidateAnswerModalInfo.internComunicationLastResolutionMessages.comunication_answer}
              />
              {hasAnswerAtachments && internComunicationValidateAnswerModalInfo.internComunicationLastResolutionMessages.attachments.map(attachment =>
                <Stack w='full'>
                  <ChakraLink
                    fontSize="lg"
                    href={handleChangeUrl(attachment)}
                    isExternal
                  >
                    Anexo<Icon as={FiExternalLink} mx="2px" />
                  </ChakraLink>

                </Stack>
              )}
            </Flex>
            <Divider />
            <Select
              {...register("is_satisfactory_answer")}
              name="is_satisfactory_answer"
              options={satisfactoryAnswerOptions}
              label="A resposta foi satisfatória?"
              error={errors.is_satisfactory_answer}
              placeholder="Selecione uma opção..."
              required
            />

            {!isSatisfactoryAnswer && (
              <TextArea
                {...register("comunication_question")}
                name="comunication_question"
                label="Resposta"
                error={errors.comunication_question}
                required
              />
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button variant='ghost' onClick={handleCloseModal}>
              Fechar
            </Button>
            <Button isLoading={isSubmitting} type="submit" colorScheme='blue'>
              Validar
            </Button>
          </Flex>

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

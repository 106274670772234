import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Icon,
  Flex,
  Button,
} from '@chakra-ui/react'
import { useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { IAssignDriverToAddressProps, ServiceProps } from '../../../contexts/ServiceContext'
import { serviceFormatDateToFrontTable } from '../../../utils/ServiceFunctions/serviceFormatDateToFrontTable'
import { serviceHandleStep } from '../../../utils/ServiceFunctions/serviceDict'
import { Link } from 'react-router-dom'
import { FiEye } from 'react-icons/fi'
import { serviceSortByDate } from '../../../utils/ServiceFunctions/serviceSortByDate'
import { serviceFormatHourToFront } from '../../../utils/ServiceFunctions/serviceFormatHourToFront'
import { BranchProps } from '../../../contexts/BranchContext'
import { serviceSortByHour } from '../../../utils/ServiceFunctions/serviceSortByHourt'
import { sortByStep } from '../../../utils/ServiceFunctions/serviceSortByStep'
import { handleStepToFilterCollector } from '../../../utils/ServiceFunctions/handleStepToFilterCollector'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { useAuth } from '../../../hooks/auth/useAuth'
import { FaUndoAlt } from 'react-icons/fa'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { useQueryClient } from 'react-query'
import { BudgetProps } from '../../../contexts/BudgetContext'
import { IDriverProps } from '../../../services/getFunctions/driver/getDrivers'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { OccurrenceProps } from '../../../contexts/OccurrenceContext'
import { Address } from 'hooks/address/dtos/Address'

interface ServiceTableProps {
  services?: ServiceProps[]
  addresses?: Address[]
  branches: BranchProps[]
  budgets: BudgetProps[]
  drivers: IDriverProps[]
}

const serviceOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'collectDate', value: 'Data da Coleta', checked: false },
  { key: 'collectHourEnd', value: 'Horário Final da Coleta', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'sourceCity', value: 'Cidade de Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade de Destino', checked: false },
  { key: 'deliveryDate', value: 'Data de Entrega', checked: false },
  { key: 'deliveryHour', value: 'Horário da Entrega', checked: false },
  { key: 'boardHour', value: 'Horário Limite de Embarque', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'sourceCollector', value: 'Coletador Origem', checked: false },
  { key: 'destinationCollector', value: 'Coletador Destino', checked: false },
  { key: 'sourceHubRegional', value: 'Regional Hub Origem', checked: false },
  {
    key: 'destinationHubRegional',
    value: 'Regional Hub Destino',
    checked: false,
  },
  { key: 'stepFiltered', value: 'Etapa', checked: false },
  { key: 'assignedDriver', value: 'Motoristas Atribuídos', checked: false },
]

const serviceOrderOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'collectDate', value: 'Data da Coleta', checked: false },
  { key: 'collectHourEnd', value: 'Horário Final Coleta', checked: false },
  { key: 'deliveryDate', value: 'Data da Entrega', checked: false },
  { key: 'deliveryHour', value: 'Horário Limite Entrega', checked: false },
  { key: 'sourceCity', value: 'Cidade Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade Destino', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

export function ServiceTable({
  services,
  addresses,
  branches,
  budgets,
  drivers
}: ServiceTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchServices, setSearchServices] = useState<ServiceProps[]>([])
  const [slicedServices, setSlicedServices] = useState<ServiceProps[]>([])

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [collectDateFiltered, setCollectDateFiltered] = useState('')
  const [collectHourEndFiltered, setCollectHourEndFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [sourceCityFiltered, setCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [deliveryDateFiltered, setDeliveryDateFiltered] = useState('')
  const [deliveryHourFiltered, setDeliveryHourFiltered] = useState('')
  const [boardHourFiltered, setBoardHourFiltered] = useState('')
  const [shippingFiltered, setShippingFiltered] = useState('')
  const [sourceCollectorFiltered, setSourceCollectorFiltered] = useState('')
  const [destinationCollectorFiltered, setDestinationCollectorFiltered] =
    useState('')
  const [sourceHubRegionalFiltered, setSourceHubRegionalFiltered] = useState('')
  const [destinationHubRegionalFiltered, setDestinationHubRegionalFiltered] =
    useState('')
  const [stepFiltered, setStepFiltered] = useState('')
  const [driversAssignedFiltered, setDriversAssignedFiltered] = useState('')

  const queryClient = useQueryClient()
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useAuth()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchServices.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(serviceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(serviceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    if (userLogged) {
      if (services) {
        const servicesWithStepDifferentOfToValidateRequestedServiceAndBusinessService =
          services.filter(
            (service) =>
              service.step !== 'toValidateRequestedService' && service.step !== 'toValidateCancelRequest' &&
              service.is_business === false,
          )

        if (
          userLogged.user_type === 'CLIENTE'
        ) {
          const servicesByCustomerId =
            servicesWithStepDifferentOfToValidateRequestedServiceAndBusinessService.filter(
              (service) =>
                service.customerIDService.id === userLogged.customer_id,
            )

          return setSlicedServices(servicesByCustomerId)
        }

        if (userLogged.user_type === 'REMETENTE') {
          const senderServices = servicesWithStepDifferentOfToValidateRequestedServiceAndBusinessService
            .filter(service => service.serviceIDRequested.owner === userLogged?.id)

          return setSlicedServices(senderServices)
        }

        if (userLogged.user_type === 'COLETADOR') {
          const servicesByCollectorId =
            servicesWithStepDifferentOfToValidateRequestedServiceAndBusinessService.filter(
              (service) => {
                const isCrossdockingService =
                  service.serviceIDRequested.crossdocking_collector_id !== null

                if (isCrossdockingService) {
                  const isCrossdockingLadingCompleted =
                    service.serviceIDLanding.find(
                      landing =>
                        landing.collector_id ===
                        service.serviceIDRequested.crossdocking_collector_id
                    )
                  if (isCrossdockingLadingCompleted) {
                    return handleStepToFilterCollector(service.step)
                      ? service.serviceIDRequested.destination_collector_id ===
                      userLogged.collector_id
                      : service.serviceIDRequested.crossdocking_collector_id ===
                      userLogged.collector_id
                  }

                  return handleStepToFilterCollector(service.step)
                    ? service.serviceIDRequested.crossdocking_collector_id ===
                    userLogged.collector_id
                    : service.serviceIDRequested.source_collector_id ===
                    userLogged.collector_id
                }
                return handleStepToFilterCollector(service.step)
                  ? service.serviceIDRequested.destination_collector_id ===
                  userLogged.collector_id
                  : service.serviceIDRequested.source_collector_id ===
                  userLogged.collector_id
              })

          return setSlicedServices(servicesByCollectorId)
        }

        setSlicedServices(
          servicesWithStepDifferentOfToValidateRequestedServiceAndBusinessService,
        )
      }
    }
  }, [itemLimit, currentPage, offset, userLogged, services])

  useEffect(() => {
    function run() {
      if (addresses !== undefined) {
        const newSlicedServices = slicedServices?.filter((service) => {
          const crossdockingCollectorId =
            service.serviceIDRequested.crossdocking_collector_id

          const sourceCollector =
            service.serviceIDRequested.sourceCollectorIDService.trading_name
          const destinationCollector =
            service.serviceIDRequested.destinationCollectorIDService
              .trading_name
          const shippingFilteredByBranch = branches
            .filter(
              (branch) =>
                branch.id === service.serviceIDRequested.source_branch_id,
            )
            .map((branch) => branch.shippingIDBranch.company_name)
            .toString()
          const sourceHubRegionalFilteredByBudgetId = budgets
            .filter(
              (budget) => budget.id === service.serviceIDRequested.budget_id,
            )
            .map((budget) => budget.sourceHubIDBudget.regional)
          const destinationHubRegionalFilteredByBudgetId = budgets
            .filter(
              (budget) => budget.id === service.serviceIDRequested.budget_id,
            )
            .map((budget) => budget.destinationHubIDBudget.regional)

          const boardHour = crossdockingCollectorId
            ? service.serviceIDBoard.filter(
              (boardService) =>
                boardService.collector_id === crossdockingCollectorId,
            ).length > 0
              ? serviceFormatHourToFront(service.serviceIDRequested.board_hour)
              : serviceFormatHourToFront(
                service.serviceIDRequested.crossdocking_board_hour,
              )
            : serviceFormatHourToFront(service.serviceIDRequested.board_hour)

          const protocolFilter = searchBoxFilter(
            service.protocol.toString(),
            protocolFiltered,
          )
          const collectDateFilter = searchBoxFilter(
            serviceFormatDateToFrontTable(
              service.serviceIDRequested.collect_date,
            ),
            collectDateFiltered,
          )
          const collectHourEndFilter = searchBoxFilter(
            serviceFormatHourToFront(
              service.serviceIDRequested.collect_hour_end,
            ),
            collectHourEndFiltered,
          )
          const customerFilter = searchBoxFilter(
            service.customerIDService.trading_firstname,
            customerFiltered,
          )
          const sourceCityFilter = searchBoxFilter(
            addresses
              .filter((address) =>
                service.serviceIDRequested.source_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => address.cityIDAddress.name)
              .join(', '),
            sourceCityFiltered,
          )
          const destinationCityFilter = searchBoxFilter(
            addresses
              .filter((address) =>
                service.serviceIDRequested.destination_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => address.cityIDAddress.name)
              .join(', '),
            destinationCityFiltered,
          )
          const deliveryDateFilter = searchBoxFilter(
            serviceFormatDateToFrontTable(
              service.serviceIDRequested.delivery_date,
            ),
            deliveryDateFiltered,
          )
          const deliveryHourFilter = searchBoxFilter(
            serviceFormatHourToFront(service.serviceIDRequested.delivery_hour),
            deliveryHourFiltered,
          )
          const boardHourFilter = searchBoxFilter(boardHour, boardHourFiltered)
          const shippingFilter = searchBoxFilter(
            shippingFilteredByBranch,
            shippingFiltered,
          )
          const sourceCollectorFilter = searchBoxFilter(
            sourceCollector,
            sourceCollectorFiltered,
          )
          const destinationCollectorFilter = searchBoxFilter(
            destinationCollector,
            destinationCollectorFiltered,
          )
          const sourceHubRegionalFilter = searchBoxFilter(
            String(sourceHubRegionalFilteredByBudgetId),
            sourceHubRegionalFiltered,
          )
          const destinationHubRegionalFilter = searchBoxFilter(
            String(destinationHubRegionalFilteredByBudgetId),
            destinationHubRegionalFiltered,
          )
          const stepFilter = searchBoxFilter(
            serviceHandleStep(service.step),
            stepFiltered,
          )

          const driversAssigned = drivers.reduce((acc, curr) => {
            service.serviceIDRequested.driver_address_assign?.map(assigned => {
              const assignedParsed: IAssignDriverToAddressProps = JSON.parse(assigned as string)

              if (assignedParsed.driver_id === curr.id) {
                return acc.push(`${curr.firstname} ${curr.lastname}`)
              }
              return []
            })

            service.serviceIDRequested.driver_provider_assign?.map(assigned => {
              const assignedParsed: IAssignDriverToAddressProps = JSON.parse(assigned as string)

              if (assignedParsed.driver_id === curr.id) {
                return acc.push(`${curr.firstname} ${curr.lastname}`)
              }
              return []
            })

            return acc
          }, [] as string[])

          const driversAssignedFilter = searchBoxFilter(
            driversAssigned.length ? Array.from(new Set(driversAssigned)).join(", ") : '-',
            driversAssignedFiltered
          )

          if (
            customerFiltered === '' &&
            protocolFiltered === '' &&
            sourceCityFiltered === '' &&
            destinationCityFiltered === '' &&
            collectDateFiltered === '' &&
            deliveryDateFiltered === '' &&
            stepFiltered === '' &&
            collectHourEndFiltered === '' &&
            deliveryHourFiltered === '' &&
            sourceCollectorFiltered === '' &&
            destinationCollectorFiltered === '' &&
            shippingFiltered === '' &&
            sourceHubRegionalFiltered === '' &&
            destinationHubRegionalFiltered === '' &&
            boardHourFiltered === '' &&
            driversAssignedFiltered === ''
          ) {
            return service
          }
          return (
            protocolFilter &&
            customerFilter &&
            sourceCityFilter &&
            destinationCityFilter &&
            collectDateFilter &&
            deliveryDateFilter &&
            stepFilter &&
            collectHourEndFilter &&
            deliveryHourFilter &&
            sourceCollectorFilter &&
            destinationCollectorFilter &&
            shippingFilter &&
            sourceHubRegionalFilter &&
            destinationHubRegionalFilter &&
            boardHourFilter &&
            driversAssignedFilter
          )
        })

        if (orderOptionSelected.length > 0) {
          newSlicedServices.sort(
            (a, b) =>
              (orderOptionSelected.includes('protocol') &&
                a.protocol - b.protocol) ||
              (orderOptionSelected.includes('collectDate') &&
                serviceSortByDate(
                  a.serviceIDRequested.collect_date,
                  b.serviceIDRequested.collect_date,
                )) ||
              (orderOptionSelected.includes('collectHourEnd') &&
                serviceSortByHour(
                  a.serviceIDRequested.collect_hour_end,
                  b.serviceIDRequested.collect_hour_end,
                )) ||
              (orderOptionSelected.includes('deliveryDate') &&
                serviceSortByDate(
                  a.serviceIDRequested.delivery_date,
                  b.serviceIDRequested.delivery_date,
                )) ||
              (orderOptionSelected.includes('deliveryHour') &&
                serviceSortByHour(
                  a.serviceIDRequested.delivery_hour,
                  b.serviceIDRequested.delivery_hour,
                )) ||
              (orderOptionSelected.includes('sourceCity') &&
                Array.from(
                  new Set(
                    addresses
                      .filter((address) =>
                        a.serviceIDRequested.source_address_id.includes(
                          address.id,
                        ),
                      )
                      .map((address) => address.cityIDAddress.name),
                  ),
                )
                  .toString()
                  .localeCompare(
                    Array.from(
                      new Set(
                        addresses
                          .filter((address) =>
                            b.serviceIDRequested.source_address_id.includes(
                              address.id,
                            ),
                          )
                          .map((address) => address.cityIDAddress.name),
                      ),
                    ).toString(),
                  )) ||
              (orderOptionSelected.includes('destinationCity') &&
                Array.from(
                  new Set(
                    addresses
                      .filter((address) =>
                        a.serviceIDRequested.destination_address_id.includes(
                          address.id,
                        ),
                      )
                      .map((address) => address.cityIDAddress.name),
                  ),
                )
                  .toString()
                  .localeCompare(
                    Array.from(
                      new Set(
                        addresses
                          .filter((address) =>
                            b.serviceIDRequested.destination_address_id.includes(
                              address.id,
                            ),
                          )
                          .map((address) => address.cityIDAddress.name),
                      ),
                    ).toString(),
                  )) ||
              0,
          )

          return setSearchServices(newSlicedServices)
        }

        setSearchServices(
          newSlicedServices.sort(
            (dateA, dateB) =>
              sortByStep(dateA.step, dateB.step) ||
              serviceSortByDate(
                dateA.serviceIDRequested.collect_date,
                dateB.serviceIDRequested.collect_date,
              ) ||
              serviceSortByHour(
                dateA.serviceIDRequested.collect_hour_end,
                dateB.serviceIDRequested.collect_hour_end,
              ),
          ),
        )
      }
    }
    run()
  }, [
    protocolFiltered,
    customerFiltered,
    sourceCityFiltered,
    destinationCityFiltered,
    collectDateFiltered,
    deliveryDateFiltered,
    stepFiltered,
    slicedServices,
    collectHourEndFiltered,
    deliveryHourFiltered,
    boardHourFiltered,
    sourceCollectorFiltered,
    destinationCollectorFiltered,
    shippingFiltered,
    orderOptionSelected,
    sourceHubRegionalFiltered,
    destinationHubRegionalFiltered,
    driversAssignedFiltered,
    drivers,
    branches,
    budgets,
    addresses,
  ])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleRefetchData = () => {
    queryClient.invalidateQueries(['services'])
  }

  return (
    <>
      {slicedServices.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <Flex direction="row">
            <TableFilterButton />
            <Button ml="2" colorScheme="blue" onClick={handleRefetchData}>
              <Icon as={FaUndoAlt} />
            </Button>
          </Flex>
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'protocol' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Protocolo..."
                        handleSearch={(e) =>
                          setProtocolFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'collectDate' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Data Coleta..."
                        handleSearch={(e) =>
                          setCollectDateFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'collectHourEnd' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Bloco Fim..."
                        handleSearch={(e) =>
                          setCollectHourEndFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'customer' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cliente..."
                        handleSearch={(e) =>
                          setCustomerFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Origem..."
                        handleSearch={(e) => setCityFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'destinationCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Destino..."
                        handleSearch={(e) =>
                          setDestinationCityFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'deliveryDate' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Data Entrega..."
                        handleSearch={(e) =>
                          setDeliveryDateFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'deliveryHour' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Horário Limite Entrega..."
                        handleSearch={(e) =>
                          setDeliveryHourFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'boardHour' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Horário Limite Embarque..."
                        handleSearch={(e) =>
                          setBoardHourFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'shipping' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Transportadora..."
                        handleSearch={(e) =>
                          setShippingFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceCollector' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Coletador..."
                        handleSearch={(e) =>
                          setSourceCollectorFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationCollector' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Coletador..."
                        handleSearch={(e) =>
                          setDestinationCollectorFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceHubRegional' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Regional Origem..."
                        handleSearch={(e) =>
                          setSourceHubRegionalFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationHubRegional' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Regional Destino..."
                        handleSearch={(e) =>
                          setDestinationHubRegionalFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'stepFiltered' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Etapa..."
                        handleSearch={(e) => setStepFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'assignedDriver' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Motorista(s) Atribuído(s)..."
                        handleSearch={(e) => setDriversAssignedFiltered(e.target.value)}
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="full">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th fontSize="12" color="blue.900">
                    PROTOCOLO
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    DATA DA COLETA
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    BLOCO FIM DE COLETA
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    CLIENTE
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    CIDADE DE ORIGEM
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    CIDADE DESTINO
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    DATA DA ENTREGA
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    HORÁRIO LIMITE DE ENTREGA
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    HORÁRIO LIMITE DE EMBARQUE
                  </Th>
                  {userLogged?.user_type !== 'CLIENTE' && (
                    <>
                      <Th fontSize="12" color="blue.900">
                        TRANSPORTADORA
                      </Th>
                      <Th fontSize="12" color="blue.900">
                        COLETADOR ORIGEM
                      </Th>
                      <Th fontSize="12" color="blue.900">
                        COLETADOR DESTINO
                      </Th>
                      <Th fontSize="12" color="blue.900">
                        REGIONAL HUB ORIGEM
                      </Th>
                      <Th fontSize="12" color="blue.900">
                        REGIONAL HUB DESTINO
                      </Th>
                    </>
                  )}
                  <Th fontSize="12" color="blue.900">
                    ETAPA
                  </Th>
                  <Th fontSize="12" color="blue.900">
                    MOTORISTAS ATRIBUÍDOS
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchServices
                  .slice(offset, offset + Number(itemLimit))
                  .map((service, index) => {
                    const crossdockingCollectorId =
                      service.serviceIDRequested.crossdocking_collector_id

                    const sourceCollector =
                      service.serviceIDRequested.sourceCollectorIDService
                        .trading_name
                    const destinationCollector =
                      service.serviceIDRequested.destinationCollectorIDService
                        .trading_name
                    const shippingFiltered = branches
                      .filter(
                        (branch) =>
                          branch.id ===
                          service.serviceIDRequested.source_branch_id,
                      )
                      .map((branch) => branch.shippingIDBranch.company_name)
                      .toString()
                    const sourceHubRegionalFilteredByBudgetId = budgets
                      .filter(
                        (budget) =>
                          budget.id === service.serviceIDRequested.budget_id,
                      )
                      .map((budget) => budget.sourceHubIDBudget.regional)
                    const destinationHubRegionalFilteredByBudgetId = budgets
                      .filter(
                        (budget) =>
                          budget.id === service.serviceIDRequested.budget_id,
                      )
                      .map((budget) => budget.destinationHubIDBudget.regional)

                    const boardHour = crossdockingCollectorId
                      ? service.serviceIDBoard.filter(
                        (boardService) =>
                          boardService.collector_id ===
                          crossdockingCollectorId,
                      ).length > 0
                        ? serviceFormatHourToFront(
                          service.serviceIDRequested.board_hour,
                        )
                        : serviceFormatHourToFront(
                          service.serviceIDRequested.crossdocking_board_hour,
                        )
                      : serviceFormatHourToFront(
                        service.serviceIDRequested.board_hour,
                      )

                    const driversAssigned = drivers.reduce((acc, curr) => {
                      service.serviceIDRequested.driver_address_assign?.map(assigned => {
                        const assignedParsed: IAssignDriverToAddressProps = JSON.parse(assigned as string)

                        if (assignedParsed.driver_id === curr.id) {
                          return acc.push(`${curr.firstname} ${curr.lastname}`)
                        }
                        return []
                      })

                      service.serviceIDRequested.driver_provider_assign?.map(assigned => {
                        const assignedParsed: IAssignDriverToAddressProps = JSON.parse(assigned as string)

                        if (assignedParsed.driver_id === curr.id) {
                          return acc.push(`${curr.firstname} ${curr.lastname}`)
                        }
                        return []
                      })

                      return acc
                    }, [] as string[])

                    const collectDelayIntercurrences = [
                      "ATRASO NA COLETA",
                      "NO SHOW"
                    ]

                    const serviceCollectDelay = service.occurrenceIDService
                      ?.filter(occurrence =>
                        collectDelayIntercurrences.includes(occurrence.intercurrence) && occurrence.proceed === "SIM")
                      ?.reduce((acc, curr) => new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr, {} as OccurrenceProps)

                    const serviceStepsToShowCollectDelayOccurrence = [
                      'toCollectService',
                      'collectingService',
                      'toValidateCollectAddresses',
                      'toBoardService',
                      'boardingService'
                    ]

                    const hasCollectDelay = !!serviceCollectDelay?.id && serviceStepsToShowCollectDelayOccurrence.includes(service.step)

                    const collectDate = serviceCollectDelay?.occurrence_date
                      ? formatDate.handle(serviceCollectDelay.occurrence_date, "DateWithoutHourToShow")
                      : formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")

                    const collectHourEnd = serviceCollectDelay?.occurrence_hour
                      ? formatDate.handle(serviceCollectDelay.occurrence_hour, "DateOnlyWithHourMinute")
                      : formatDate.handle(service.serviceIDRequested.collect_hour_end, "DateOnlyWithHourMinute")

                    const deliveryDelayIntercurrences = [
                      "ATRASO NA ENTREGA",
                      "CANCELAMENTO DE VOO",
                      "CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)",
                      "ATRASO NA LIBERAÇÃO"
                    ]

                    const serviceDeliveryDelay = service.occurrenceIDService
                      ?.filter(occurrence =>
                        deliveryDelayIntercurrences.includes(occurrence.intercurrence) && occurrence.proceed === "SIM")
                      ?.reduce((acc, curr) => new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr, {} as OccurrenceProps)

                    const hasDeliveryDelay = serviceDeliveryDelay?.id ? true : false

                    const deliveryDate = serviceDeliveryDelay?.occurrence_date
                      ? formatDate.handle(serviceDeliveryDelay.occurrence_date, "DateWithoutHourToShow")
                      : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

                    const deliveryHour = serviceDeliveryDelay?.occurrence_hour
                      ? formatDate.handle(serviceDeliveryDelay.occurrence_hour, "DateOnlyWithHourMinute")
                      : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

                    const sourceCities = Array.from(new Set(addresses
                      ?.filter((address) =>
                        service.serviceIDRequested.source_address_id.includes(address.id))
                      .map((address) => address.cityIDAddress.name)
                    ))
                      .join(', ')
                      .toUpperCase()

                    const destinationCities = Array.from(new Set(addresses
                      ?.filter((address) =>
                        service.serviceIDRequested.destination_address_id.includes(address.id))
                      .map((address) => address.cityIDAddress.name)
                    ))
                      .join(', ')
                      .toUpperCase()

                    return (
                      <Tr
                        key={index}
                        color={(hasCollectDelay || hasDeliveryDelay) ? "yellow.400" : ""}
                        fontWeight={(hasCollectDelay || hasDeliveryDelay) ? "bold" : ""}
                      >
                        <Td>
                          <Link to={`/servico/detalhes/${service.id}`}>
                            <Icon cursor="pointer" as={FiEye} fontSize="20" />
                          </Link>
                        </Td>
                        <Td fontSize="12">{service.protocol}</Td>
                        <Td fontSize="12">{collectDate}</Td>
                        <Td fontSize="12">{collectHourEnd}</Td>
                        <Td fontSize="12">
                          {service.customerIDService.trading_firstname.toUpperCase()}
                        </Td>
                        <Td fontSize="12">{sourceCities}</Td>
                        <Td fontSize="12">{destinationCities}</Td>
                        <Td fontSize="12">{deliveryDate}</Td>
                        <Td fontSize="12">{deliveryHour}</Td>
                        <Td fontSize="12">{boardHour}</Td>
                        {userLogged?.user_type !== 'CLIENTE' && (
                          <>
                            <Td fontSize="12">
                              {shippingFiltered !== ''
                                ? shippingFiltered.toUpperCase()
                                : '-'}
                            </Td>
                            <Td fontSize="12">
                              {sourceCollector !== ''
                                ? sourceCollector.toUpperCase()
                                : '-'}
                            </Td>
                            <Td fontSize="12">
                              {destinationCollector !== ''
                                ? destinationCollector.toUpperCase()
                                : '-'}
                            </Td>
                            <Td fontSize="12">
                              {sourceHubRegionalFilteredByBudgetId}
                            </Td>
                            <Td fontSize="12">
                              {destinationHubRegionalFilteredByBudgetId}
                            </Td>
                          </>
                        )}
                        <Td fontSize="12">{serviceHandleStep(service.step)}</Td>
                        <Td fontSize="12">{driversAssigned.length ? Array.from(new Set(driversAssigned)).join(", ") : '-'}</Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

import { Flex, FormControl, FormLabel, Icon, IconButton, Input, InputProps, Select, Stack, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { FaRegTrashAlt, FaSave } from "react-icons/fa"
import * as yup from "yup"
import { Material } from "./DispatchStockMaterial"

export interface SaveDispatchStockMaterialFormSchema {
  inputId: string
  quantity: number
}

const saveDispatchStockMaterialFormSchema = yup.object({
  inputId: yup.string().required(),
  quantity: yup.string().required(),
})

interface SaveDispatchStockMaterialFormProps {
  alreadyChoosedMaterials: string[]
  defaultMaterialValue?: Material
  inputs?: InputProps[]
  onSaveDispatchStockMaterial: (values: SaveDispatchStockMaterialFormSchema) => Promise<void>
  onRemoveDispatchStockMaterial: () => void
}

export function SaveDispatchStockMaterialForm({
  alreadyChoosedMaterials,
  onSaveDispatchStockMaterial,
  onRemoveDispatchStockMaterial,
  inputs,
  defaultMaterialValue
}: SaveDispatchStockMaterialFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }

  } = useForm<SaveDispatchStockMaterialFormSchema>({
    resolver: yupResolver(saveDispatchStockMaterialFormSchema),
    defaultValues: {
      inputId: defaultMaterialValue.inputId,
      quantity: defaultMaterialValue.quantity
    }
  })

  return (
    <Flex
      justifyContent='space-between'
      align="flex-end"
      gap="2"
      direction={{ base: 'column', md: 'row' }}
      as="form"
      onSubmit={handleSubmit(onSaveDispatchStockMaterial)}
    >
      <FormControl isInvalid={!!errors.inputId}>
        <FormLabel fontSize="sm">
          Insumo
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('inputId')}
          placeholder='Selecione..'
          size='sm'
          rounded='md'
          w="250px"
        >
          {inputs?.map((input) => {

            return (
              <option
                key={input.id}
                value={input.id}
                disabled={alreadyChoosedMaterials.includes(input.id)}
              >
                {input.name}
              </option>
            )
          })}
        </Select>
      </FormControl>

      <Stack
        direction="column"
        w="full"
      >
        <FormControl isInvalid={!!errors.quantity}>
          <FormLabel fontSize="sm">
            Quantidade
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('quantity')}
            size="sm"
            rounded="md"
          />
        </FormControl>
      </Stack>
      <Stack
        direction="row"
        w="full"
        spacing="2"
        justifyContent="flex-end"
      >

        <IconButton
          aria-label="Salvar material"
          colorScheme='blue'
          icon={<Icon as={FaSave} />}
          type="submit"
          size="sm"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        />
        <IconButton
          aria-label="Remover material"
          icon={<Icon as={FaRegTrashAlt} />}
          size="sm"
          variant="ghost"
          onClick={onRemoveDispatchStockMaterial}
        />
      </Stack>
    </Flex>
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { Billing, BillingStatus } from "./billing"

interface GetBillingsQueryParams {
  pageSize: string
  currentPage: string
  customer: string
  status: BillingStatus | 'all'
  customerId?: string | null
}

interface BillingsResponse extends Billing {
  customer: {
    trading_firstname: string
    day_expiration_1: string
    day_expiration_2: string
    payment_conditional: string
    billing_type: string
    payment_type: string
    cost_center: string
  }
}

export interface GetBillingsResponse {
  billings: BillingsResponse[]
  meta: {
    totalPages: number
    pageSize: number
    currentPage: number
  }
}

export async function getBillings({
  currentPage,
  customer,
  pageSize,
  status,
  customerId
}: GetBillingsQueryParams) {
  try {
    const response = await api.get<GetBillingsResponse>('/billings', {
      params: {
        currentPage,
        customer,
        pageSize,
        status,
        customerId
      }
    })

    return response.data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { format, getDate, getMonth, getYear, set } from 'date-fns'
import { DateFormats, dateFormatsMoreUsed } from './dateFormats'

export class SetDate {
  handleWithDefaultHourWithoutFormat(date: string | Date) {
    return set(new Date(date), { hours: 12, minutes: 0, seconds: 0 })
  }

  async handleWithDefaultDateWithoutFormat(date: string | Date) {
    const dayNow = getDate(new Date())
    const monthNow = getMonth(new Date())
    const yearNow = getYear(new Date())

    return set(new Date(date), { date: dayNow, month: monthNow, year: yearNow })
  }

  handleSetDateAndHourWithoutFormat(
    day: number,
    month: number,
    year: number,
    hour: number,
    minute: number,
  ) {
    return set(new Date(), {
      date: day,
      month: month - 1,
      year,
      hours: hour,
      minutes: minute,
      seconds: 0
    })
  }

  handleSetDateAndHourWithFormat(
    day: number,
    month: number,
    year: number,
    hour: number,
    minute: number,
    dateFormat: DateFormats,
  ) {
    return format(
      set(new Date(), {
        date: day,
        month: month - 1,
        year,
        hours: hour,
        minutes: minute,
      }),
      dateFormatsMoreUsed[dateFormat].format,
    )
  }

  handleSetHourWithFormat(
    hour: number,
    minute: number,
    dateFormat: DateFormats,
  ) {
    return format(
      set(new Date(), { hours: hour, minutes: minute }),
      dateFormatsMoreUsed[dateFormat].format,
    )
  }

  handleSetHourWithoutFormat(
    hour: number,
    minute: number,
  ) {
    return set(new Date(), { hours: hour, minutes: minute, seconds: 0 })
  }
}

const setDate = new SetDate()

export { setDate }

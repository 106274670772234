import { Flex, Box, Heading, Button, Icon, useDisclosure, Modal, ModalOverlay } from '@chakra-ui/react'
import { useEffect } from 'react'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'

import { useContentLoading } from '../../hooks/loading/useContentLoading'
import { PriceTable } from '../../components/Tables/Price/PriceTable'
import { usePrice } from '../../hooks/price/usePrice'
import { Link } from 'react-router-dom'
import { FiPlus } from 'react-icons/fi'
import { useAuth } from '../../hooks/auth/useAuth'
import { FaPlus } from 'react-icons/fa'
import { UpdatePricesByCsvModal } from './components/UpdatePricesByCsvModal'

export function PriceList() {
  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()
  const { userLogged } = useAuth()

  const {
    prices: { data: prices, isLoading: isPricesLoading },
  } = usePrice()

  useEffect(() => {
    function run() {
      if (!isPricesLoading) {
        handleSetIsContentLoadingFalse()
      }
    }
    run()
  }, [isPricesLoading, handleSetIsContentLoadingFalse])

  const {
    isOpen: isUpdatePricesByCsvModalOpen,
    onOpen: onOpenUpdatePricesByCsvModal,
    onClose: onCloseUpdatePricesByCsvModal,
  } = useDisclosure()

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (

    <Flex w="100%" direction={['column', 'column', 'row']}>
      <Box
        overflowX="auto"
        flex="1"
        borderRadius="8"
        bg="white"
        p={['6', '8']}
      >
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontFamily="poppins">
            Lista de Preços
          </Heading>
          <Flex>
            <Link to="/preco/adicionar">
              {userLogged?.permissions.includes('add-price') && (
                <Button
                  colorScheme="Blue"
                  rightIcon={<Icon as={FiPlus} />}
                >
                  Criar
                </Button>
              )}
            </Link>
            {userLogged?.permissions.includes('edit-price') && (
              <>
                <Modal
                  isOpen={isUpdatePricesByCsvModalOpen}
                  onClose={onCloseUpdatePricesByCsvModal}
                >
                  <ModalOverlay />

                  <UpdatePricesByCsvModal />

                </Modal>
                <Button
                  onClick={onOpenUpdatePricesByCsvModal}
                  lineHeight={1}
                  leftIcon={<FaPlus />}
                  colorScheme="green"
                  size="sm"
                  w={{ base: 'full', lg: 'fit-content' }}
                >
                  Atualizar por Csv
                </Button>
              </>
            )}
          </Flex>
        </Flex>
        {prices && <PriceTable prices={prices} />}
      </Box>
    </Flex>

  )
}

import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Flex,
  Button,
  Spinner,
  Link as ChakraLink
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye } from 'react-icons/fi'
import { useContext, useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import {
  RequestedServiceProps,
  ServiceProps,
} from '../../../../contexts/ServiceContext'
import { serviceFormatDateToFrontTable } from '../../../../utils/ServiceFunctions/serviceFormatDateToFrontTable'
import { AuthContext } from '../../../../contexts/AuthContext'
import { serviceFormatHourToFront } from '../../../../utils/ServiceFunctions/serviceFormatHourToFront'
import { BranchProps } from '../../../../contexts/BranchContext'
import { serviceSortByDate } from '../../../../utils/ServiceFunctions/serviceSortByDate'
import { serviceSortByHour } from '../../../../utils/ServiceFunctions/serviceSortByHourt'
import { TableFilterButton } from '../../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../../hooks/filterOptions/useFilterOptions'
import { FaClock, FaUndoAlt } from 'react-icons/fa'
import { EmptyContentTableAlert } from '../../../Alerts/EmptyContentTableAlert'
import { useSwal } from '../../../../hooks/swal/useSwal'
import { AllocatedServiceProps } from '../../../../contexts/AllocateServiceContext'
import { differenceInMinutes, format, getDate, getMonth, getYear, isBefore, isSameDay, set } from 'date-fns'
import { useAvailableServiceFunctions } from '../../../../hooks/services/available/useAvailableServiceFunctions'
import { LandingServiceProps } from '../../../../services/getFunctions/getLandingServiceFunctions'
import { formatDate } from '../../../../utils/DateFunctions/formatDate'
import { OccurrenceProps } from '../../../../contexts/OccurrenceContext'
import { Address } from 'hooks/address/dtos/Address'

interface AvailableServiceTableProps {
  services?: ServiceProps[]
  addresses?: Address[]
  branches?: BranchProps[]
  refetch: () => void
}

const availableServiceOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'deliveryDate', value: 'Data da Entrega', checked: false },
  { key: 'deliveryHour', value: 'Horário da Entrega', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'operationalNumber', value: 'Rastreador Validado', checked: false },
  { key: 'sourceBranch', value: 'Base de Origem', checked: false },
  { key: 'destinationBranch', value: 'Base de Destino', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'boardVolume', value: 'Volume Embarcado Validado', checked: false },
  { key: 'sourceCity', value: 'Cidade de Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade de Destino', checked: false },
]

const availableServiceOrderOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

const handleChangeTextTableColor = (
  serviceIDLanding: LandingServiceProps[],
  serviceIDRequested: RequestedServiceProps,
  serviceIDAllocated: AllocatedServiceProps[],
) => {
  const crossdockingCollectorID = serviceIDRequested.crossdocking_collector_id
  const sourceCollectorID = serviceIDRequested.source_collector_id
  const destinationCollectorID = serviceIDRequested.destination_collector_id

  if (crossdockingCollectorID) {
    const hasCrossdockingLanding = serviceIDLanding.find(
      (landing) => landing.collector_id === crossdockingCollectorID,
    )

    if (!hasCrossdockingLanding) {
      const allocatedServiceByCrossdockingCollectorID =
        serviceIDAllocated.filter(
          (allocated) => allocated.collector_id === crossdockingCollectorID,
        )

      const allocatedAvailabilityDate =
        allocatedServiceByCrossdockingCollectorID[0].allocate_availability_date
      const allocatedAvailabilityHour =
        allocatedServiceByCrossdockingCollectorID[0].allocate_availability_hour

      const compareDateNowWithAllocated = compareAllocatedDateHourWithDateNow(
        allocatedAvailabilityDate,
        allocatedAvailabilityHour,
      )
      if (compareDateNowWithAllocated) {
        return true
      }
      return
    }
    if (hasCrossdockingLanding) {
      const hasDestinationCollectorLanding = serviceIDLanding.find(
        (landing) => landing.collector_id === destinationCollectorID,
      )

      if (!hasDestinationCollectorLanding) {
        const allocatedServiceByCrossdockingCollectorID =
          serviceIDAllocated.filter(
            (allocated) => allocated.collector_id === sourceCollectorID,
          )
        const allocatedAvailabilityDate =
          allocatedServiceByCrossdockingCollectorID[0]
            .allocate_availability_date
        const allocatedAvailabilityHour =
          allocatedServiceByCrossdockingCollectorID[0]
            .allocate_availability_hour

        const compareDateNowWithAllocated = compareAllocatedDateHourWithDateNow(
          allocatedAvailabilityDate,
          allocatedAvailabilityHour,
        )
        if (compareDateNowWithAllocated) {
          return true
        }
        return
      }
      return
    }
    return
  }

  const allocatedAvailabilityDate =
    serviceIDAllocated[0].allocate_availability_date
  const allocatedAvailabilityHour =
    serviceIDAllocated[0].allocate_availability_hour

  const compareDateNowWithAllocated = compareAllocatedDateHourWithDateNow(
    allocatedAvailabilityDate,
    allocatedAvailabilityHour,
  )

  if (compareDateNowWithAllocated) {
    return true
  }
}

const compareAllocatedDateHourWithDateNow = (
  allocatedAvailabilityDate: string,
  allocatedAvailabilityHour: string,
) => {
  const actualYear = getYear(new Date())
  const actualMonth = getMonth(new Date())
  const actualDate = getDate(new Date())

  const allocatedAvailabilityDateWithDefaultHour = set(
    new Date(allocatedAvailabilityDate),
    { hours: 12, minutes: 0, seconds: 0 },
  )

  const differenceBetweenAllocatedDayAndCurrentDay = differenceInMinutes(
    set(new Date(), { hours: 12, minutes: 0, seconds: 0 }),
    allocatedAvailabilityDateWithDefaultHour,
  )

  if (differenceBetweenAllocatedDayAndCurrentDay > 0) {
    return true
  }

  if (differenceBetweenAllocatedDayAndCurrentDay === 0) {
    const allocatedAvailabilityHourWithDefaultDate = set(
      new Date(allocatedAvailabilityHour),
      { year: actualYear, month: actualMonth, date: actualDate },
    )

    const differenceBetweenAllocatedHourAndCurrentHour = differenceInMinutes(
      set(new Date(), {
        year: actualYear,
        month: actualMonth,
        date: actualDate,
      }),
      allocatedAvailabilityHourWithDefaultDate,
    )

    if (differenceBetweenAllocatedHourAndCurrentHour >= 0) {
      return true
    }
  }

  return false
}

export function AvailableServiceTable({
  services,
  addresses,
  branches,
  refetch,
}: AvailableServiceTableProps) {
  const [isLoading, setIsLoading] = useState(false)

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedServices, setSearchedServices] = useState<ServiceProps[]>([])
  const [slicedServices, setSlicedServices] = useState<ServiceProps[]>([])

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [sourceCityFiltered, setSourceCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [sourceBranchFiltered, setSourceBranchFiltered] = useState('')
  const [destinationBranchFiltered, setDestinationBranchFiltered] = useState('')
  const [shippingFiltered, setShippingFiltered] = useState('')
  const [deliveryDateFiltered, setDeliveryDateFiltered] = useState('')
  const [deliveryHourFiltered, setDeliveryHourFiltered] = useState('')
  const [operationalNumberFiltered, setOperationalNumberFiltered] = useState('')
  const [boardVolumeFiltered, setBoardVolumeFiltered] = useState('')

  const {
    updateAllocateServicesToLandingService: {
      mutateAsync: updateAllocateServicesToLandingService,
    },
  } = useAvailableServiceFunctions()
  const { confirmMessage, standardMessage } = useSwal()
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useContext(AuthContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedServices?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(availableServiceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(availableServiceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      if (services) {
        const availableServicePerPage = services?.filter(
          (service) =>
            service.step === 'toAvailableService' ||
            service.step === 'availableService',
        )
        setSlicedServices(availableServicePerPage)
      }
    }

    run()
  }, [itemLimit, currentPage, offset, services])

  useEffect(() => {
    function run() {
      if (!!addresses && !!branches) {
        const newSlicedServices = slicedServices.filter((service) => {
          const sourceCrossdockingBranchID =
            service.serviceIDRequested.source_crossdocking_branch_id

          const sourceBranch = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested
                          .source_crossdocking_branch_id,
                    )
                    .map((branch) => branch.nickname),
                )
                : String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested.source_branch_id,
                    )
                    .map((branch) => branch.nickname),
                )
              : String(
                branches
                  .filter(
                    (branch) =>
                      branch.id ===
                      service.serviceIDRequested.source_branch_id,
                  )
                  .map((branch) => branch.nickname),
              )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id === service.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.nickname),
            )

          const destinationBranch = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested.destination_branch_id,
                    )
                    .map((branch) => branch.nickname),
                )
                : String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested
                          .destination_crossdocking_branch_id,
                    )
                    .map((branch) => branch.nickname),
                )
              : String(
                branches
                  .filter(
                    (branch) =>
                      branch.id ===
                      service.serviceIDRequested
                        .destination_crossdocking_branch_id,
                  )
                  .map((branch) => branch.nickname),
              )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id ===
                    service.serviceIDRequested.destination_branch_id,
                )
                .map((branch) => branch.nickname),
            )

          const shipping = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested
                          .source_crossdocking_branch_id,
                    )
                    .map((branch) => branch.shippingIDBranch.company_name),
                )
                : String(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        service.serviceIDRequested.source_branch_id,
                    )
                    .map((branch) => branch.shippingIDBranch.company_name),
                )
              : String(
                branches
                  .filter(
                    (branch) =>
                      branch.id ===
                      service.serviceIDRequested.source_branch_id,
                  )
                  .map((branch) => branch.shippingIDBranch.company_name),
              )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id === service.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.shippingIDBranch.company_name),
            )

          const operationalNumber = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  service.serviceIDBoard
                    .filter(
                      (boardService) =>
                        boardService.branch_id ===
                        service.serviceIDRequested
                          .source_crossdocking_branch_id,
                    )
                    .map((boardService) => boardService.operational_number),
                )
                : String(
                  service.serviceIDBoard
                    .filter(
                      (boardService) =>
                        boardService.branch_id ===
                        service.serviceIDRequested.source_branch_id,
                    )
                    .map((boardService) => boardService.operational_number),
                )
              : String(
                service.serviceIDBoard
                  .filter(
                    (boardService) =>
                      boardService.branch_id ===
                      service.serviceIDRequested.source_branch_id,
                  )
                  .map((boardService) => boardService.operational_number),
              )
            : String(
              service.serviceIDBoard
                .filter(
                  (boardService) =>
                    boardService.branch_id ===
                    service.serviceIDRequested.source_branch_id,
                )
                .map((boardService) => boardService.operational_number),
            )

          const boardVolume = sourceCrossdockingBranchID
            ? service.serviceIDBoard.find(
              (boardService) =>
                boardService.collector_id ===
                service.serviceIDRequested.crossdocking_collector_id,
            ) !== undefined
              ? service.serviceIDLanding.find(
                (landingService) =>
                  landingService.collector_id ===
                  service.serviceIDRequested.crossdocking_collector_id,
              ) !== undefined
                ? String(
                  service.serviceIDBoard
                    .filter(
                      (boardService) =>
                        boardService.branch_id ===
                        service.serviceIDRequested
                          .source_crossdocking_branch_id,
                    )
                    .map((boardService) => boardService.board_volume),
                )
                : String(
                  service.serviceIDBoard
                    .filter(
                      (boardService) =>
                        boardService.branch_id ===
                        service.serviceIDRequested.source_branch_id,
                    )
                    .map((boardService) => boardService.board_volume),
                )
              : String(
                service.serviceIDBoard
                  .filter(
                    (boardService) =>
                      boardService.branch_id ===
                      service.serviceIDRequested.source_branch_id,
                  )
                  .map((boardService) => boardService.board_volume),
              )
            : String(
              service.serviceIDBoard
                .filter(
                  (boardService) =>
                    boardService.branch_id ===
                    service.serviceIDRequested.source_branch_id,
                )
                .map((boardService) => boardService.board_volume),
            )

          const protocolFilter = searchBoxFilter(
            String(service.protocol),
            protocolFiltered,
          )
          const customerFilter = searchBoxFilter(
            service.customerIDService.trading_firstname,
            customerFiltered,
          )
          const sourceCityFilter = searchBoxFilter(
            addresses
              .filter((address) =>
                service.serviceIDRequested.source_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => address.cityIDAddress.name)
              .join(', '),
            sourceCityFiltered,
          )
          const destinationCityFilter = searchBoxFilter(
            addresses
              .filter((address) =>
                service.serviceIDRequested.destination_address_id.includes(
                  address.id,
                ),
              )
              .map((address) => address.cityIDAddress.name)
              .join(', '),
            destinationCityFiltered,
          )
          const deliveryDateFilter = searchBoxFilter(
            serviceFormatDateToFrontTable(
              service.serviceIDRequested.delivery_date,
            ),
            deliveryDateFiltered,
          )
          const sourceBranchFilter = searchBoxFilter(
            sourceBranch,
            sourceBranchFiltered,
          )
          const destinationBranchFilter = searchBoxFilter(
            destinationBranch,
            destinationBranchFiltered,
          )
          const shippingFilter = searchBoxFilter(shipping, shippingFiltered)
          const deliveryHourFilter = searchBoxFilter(
            serviceFormatHourToFront(service.serviceIDRequested.delivery_hour),
            deliveryHourFiltered,
          )
          const operationalNumberFilter = searchBoxFilter(
            operationalNumber,
            operationalNumberFiltered,
          )
          const boardVolumeFilter = searchBoxFilter(
            boardVolume,
            boardVolumeFiltered,
          )

          if (
            customerFiltered === '' &&
            protocolFiltered === '' &&
            sourceCityFiltered === '' &&
            destinationCityFiltered === '' &&
            deliveryDateFiltered === '' &&
            deliveryHourFiltered === '' &&
            sourceBranchFiltered === '' &&
            destinationBranchFiltered === '' &&
            shippingFiltered === '' &&
            operationalNumberFiltered === '' &&
            boardVolumeFiltered === ''
          ) {
            return service
          }
          return (
            protocolFilter &&
            customerFilter &&
            sourceCityFilter &&
            destinationCityFilter &&
            deliveryDateFilter &&
            sourceBranchFilter &&
            destinationBranchFilter &&
            shippingFilter &&
            deliveryHourFilter &&
            boardVolumeFilter &&
            operationalNumberFilter
          )
        })

        if (orderOptionSelected.length > 0) {
          const slicedServicesByOrder = newSlicedServices.sort(
            (a, b) =>
              (orderOptionSelected.includes('protocol') &&
                a.protocol - b.protocol) ||
              (orderOptionSelected.includes('customer') &&
                a.customerIDService.trading_firstname.localeCompare(
                  b.customerIDService.trading_firstname,
                )) ||
              0,
          )

          return setSearchedServices(slicedServicesByOrder)
        }

        setSearchedServices(
          newSlicedServices.sort(
            (dateA, dateB) =>
              serviceSortByDate(
                dateA.serviceIDRequested.delivery_date,
                dateB.serviceIDRequested.delivery_date,
              ) ||
              serviceSortByHour(
                dateA.serviceIDRequested.delivery_hour,
                dateB.serviceIDRequested.delivery_hour,
              ) ||
              branches
                .filter(
                  (branch) =>
                    branch.id === dateA.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.shippingIDBranch.company_name)
                .toString()
                .localeCompare(
                  branches
                    .filter(
                      (branch) =>
                        branch.id === dateB.serviceIDRequested.source_branch_id,
                    )
                    .map((branch) => branch.shippingIDBranch.company_name)
                    .toString(),
                ) ||
              branches
                .filter(
                  (branch) =>
                    branch.id === dateA.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.nickname)
                .toString()
                .localeCompare(
                  branches
                    .filter(
                      (branch) =>
                        branch.id ===
                        dateB.serviceIDRequested.destination_branch_id,
                    )
                    .map((branch) => branch.nickname)
                    .toString(),
                ),
          ),
        )
      }
    }
    run()
  }, [
    protocolFiltered,
    customerFiltered,
    sourceCityFiltered,
    destinationCityFiltered,
    deliveryDateFiltered,
    sourceBranchFiltered,
    boardVolumeFiltered,
    operationalNumberFiltered,
    destinationBranchFiltered,
    shippingFiltered,
    deliveryHourFiltered,
    orderOptionSelected,
    slicedServices,
    addresses,
    branches,
  ])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleRefetchData = () => {
    return refetch()
  }

  const handleUpdateAllocateServicesToLandingService = async () => {
    const hasUpdateAllocateServiceToLandingService = await confirmMessage({
      title: 'Deseja disponiblizar serviços já alocados para desembarque?',
    })

    if (hasUpdateAllocateServiceToLandingService) {
      setIsLoading(true)
      try {
        const updateAllocateServicesToLandingServiceResponse =
          await updateAllocateServicesToLandingService()
        if (updateAllocateServicesToLandingServiceResponse) {
          setIsLoading(false)
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {slicedServices.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <Stack direction="row">
            <TableFilterButton />
            {userLogged?.user_type !== 'MOTORISTA' &&
              userLogged?.user_type !== 'COLETADOR' &&
              userLogged?.user_type !== 'CLIENTE' && (
                <Button
                  colorScheme="blue"
                  variant="outline"
                  onClick={handleUpdateAllocateServicesToLandingService}
                >
                  Disponibilizar Serviços
                </Button>
              )}
            <Button colorScheme="blue" onClick={handleRefetchData}>
              <Icon as={FaUndoAlt} />
            </Button>
          </Stack>
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'protocol' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Protocolo..."
                        handleSearch={(e) =>
                          setProtocolFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'deliveryDate' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Data da Entrega..."
                        handleSearch={(e) =>
                          setDeliveryDateFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'deliveryHour' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Horário da Entrega..."
                        handleSearch={(e) =>
                          setDeliveryHourFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'shipping' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Transportadora..."
                        handleSearch={(e) =>
                          setShippingFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'operationalNumber' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Rastreador..."
                        handleSearch={(e) =>
                          setOperationalNumberFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceBranch' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Base de Origem..."
                        handleSearch={(e) =>
                          setSourceBranchFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationBranch' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Base de Destino..."
                        handleSearch={(e) =>
                          setDestinationBranchFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'customer' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cliente..."
                        handleSearch={(e) =>
                          setCustomerFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'boardVolume' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Volume do Embarque..."
                        handleSearch={(e) =>
                          setBoardVolumeFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Origem..."
                        handleSearch={(e) =>
                          setSourceCityFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Destino..."
                        handleSearch={(e) =>
                          setDestinationCityFiltered(e.target.value)
                        }
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                  {availableServiceOptions.map((option) => (
                    <Th fontSize="12" color="blue.900" key={option.key}>
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              {addresses && branches && (
                <Tbody>
                  {searchedServices
                    .slice(offset, offset + Number(itemLimit))
                    .map((service, index) => {
                      const sourceCrossdockingBranchID =
                        service.serviceIDRequested.source_crossdocking_branch_id

                      const sourceBranch = sourceCrossdockingBranchID
                        ? service.serviceIDBoard.find(
                          (boardService) =>
                            boardService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? service.serviceIDLanding.find(
                            (landingService) =>
                              landingService.collector_id ===
                              service.serviceIDRequested
                                .crossdocking_collector_id,
                          ) !== undefined
                            ? String(
                              branches
                                .filter(
                                  (branch) =>
                                    branch.id ===
                                    service.serviceIDRequested
                                      .source_crossdocking_branch_id,
                                )
                                .map((branch) => branch.nickname),
                            )
                            : String(
                              branches
                                .filter(
                                  (branch) =>
                                    branch.id ===
                                    service.serviceIDRequested
                                      .source_branch_id,
                                )
                                .map((branch) => branch.nickname),
                            )
                          : String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested.source_branch_id,
                              )
                              .map((branch) => branch.nickname),
                          )
                        : String(
                          branches
                            .filter(
                              (branch) =>
                                branch.id ===
                                service.serviceIDRequested.source_branch_id,
                            )
                            .map((branch) => branch.nickname),
                        )

                      const destinationBranch = sourceCrossdockingBranchID
                        ? service.serviceIDBoard.find(
                          (boardService) =>
                            boardService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? service.serviceIDLanding.find(
                            (landingService) =>
                              landingService.collector_id ===
                              service.serviceIDRequested
                                .crossdocking_collector_id,
                          ) !== undefined
                            ? String(
                              branches
                                .filter(
                                  (branch) =>
                                    branch.id ===
                                    service.serviceIDRequested
                                      .destination_branch_id,
                                )
                                .map((branch) => branch.nickname),
                            )
                            : String(
                              branches
                                .filter(
                                  (branch) =>
                                    branch.id ===
                                    service.serviceIDRequested
                                      .destination_crossdocking_branch_id,
                                )
                                .map((branch) => branch.nickname),
                            )
                          : String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested
                                    .destination_crossdocking_branch_id,
                              )
                              .map((branch) => branch.nickname),
                          )
                        : String(
                          branches
                            .filter(
                              (branch) =>
                                branch.id ===
                                service.serviceIDRequested
                                  .destination_branch_id,
                            )
                            .map((branch) => branch.nickname),
                        )

                      const shipping = sourceCrossdockingBranchID
                        ? service.serviceIDBoard.find(
                          (boardService) =>
                            boardService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? service.serviceIDLanding.find(
                            (landingService) =>
                              landingService.collector_id ===
                              service.serviceIDRequested
                                .crossdocking_collector_id,
                          ) !== undefined
                            ? String(
                              branches
                                .filter(
                                  (branch) =>
                                    branch.id ===
                                    service.serviceIDRequested
                                      .source_crossdocking_branch_id,
                                )
                                .map(
                                  (branch) =>
                                    branch.shippingIDBranch.company_name,
                                ),
                            )
                            : String(
                              branches
                                .filter(
                                  (branch) =>
                                    branch.id ===
                                    service.serviceIDRequested
                                      .source_branch_id,
                                )
                                .map(
                                  (branch) =>
                                    branch.shippingIDBranch.company_name,
                                ),
                            )
                          : String(
                            branches
                              .filter(
                                (branch) =>
                                  branch.id ===
                                  service.serviceIDRequested.source_branch_id,
                              )
                              .map(
                                (branch) =>
                                  branch.shippingIDBranch.company_name,
                              ),
                          )
                        : String(
                          branches
                            .filter(
                              (branch) =>
                                branch.id ===
                                service.serviceIDRequested.source_branch_id,
                            )
                            .map(
                              (branch) =>
                                branch.shippingIDBranch.company_name,
                            ),
                        )

                      const operationalNumber = sourceCrossdockingBranchID
                        ? service.serviceIDBoard.find(
                          (boardService) =>
                            boardService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? service.serviceIDLanding.find(
                            (landingService) =>
                              landingService.collector_id ===
                              service.serviceIDRequested
                                .crossdocking_collector_id,
                          ) !== undefined
                            ? String(
                              service.serviceIDBoard
                                .filter(
                                  (boardService) =>
                                    boardService.branch_id ===
                                    service.serviceIDRequested
                                      .source_crossdocking_branch_id,
                                )
                                .map(
                                  (boardService) =>
                                    boardService.operational_number,
                                ),
                            )
                            : String(
                              service.serviceIDBoard
                                .filter(
                                  (boardService) =>
                                    boardService.branch_id ===
                                    service.serviceIDRequested
                                      .source_branch_id,
                                )
                                .map(
                                  (boardService) =>
                                    boardService.operational_number,
                                ),
                            )
                          : String(
                            service.serviceIDBoard
                              .filter(
                                (boardService) =>
                                  boardService.branch_id ===
                                  service.serviceIDRequested.source_branch_id,
                              )
                              .map(
                                (boardService) =>
                                  boardService.operational_number,
                              ),
                          )
                        : String(
                          service.serviceIDBoard
                            .filter(
                              (boardService) =>
                                boardService.branch_id ===
                                service.serviceIDRequested.source_branch_id,
                            )
                            .map(
                              (boardService) =>
                                boardService.operational_number,
                            ),
                        )

                      const trackerLink = sourceCrossdockingBranchID
                        ? service.serviceIDBoard.find((boardService) =>
                          boardService.collector_id ===
                          service.serviceIDRequested.crossdocking_collector_id
                        )
                          ? service.serviceIDLanding.find((landingService) =>
                            landingService.collector_id ===
                            service.serviceIDRequested.crossdocking_collector_id
                          )
                            ? String(
                              service.serviceIDBoard
                                .filter(
                                  (boardService) =>
                                    boardService.branch_id ===
                                    service.serviceIDRequested.source_crossdocking_branch_id
                                )
                                .map((boardService) => boardService.tracker_link)
                            ) || null
                            : String(
                              service.serviceIDBoard
                                .filter(
                                  (boardService) =>
                                    boardService.branch_id ===
                                    service.serviceIDRequested.source_branch_id
                                )
                                .map((boardService) => boardService.tracker_link)
                            ) || null
                          : null
                        : String(
                          service.serviceIDBoard
                            .filter(
                              (boardService) =>
                                boardService.branch_id ===
                                service.serviceIDRequested.source_branch_id
                            )
                            .map((boardService) => boardService.tracker_link)
                        ) || null;


                      const boardVolume = sourceCrossdockingBranchID
                        ? service.serviceIDBoard.find(
                          (boardService) =>
                            boardService.collector_id ===
                            service.serviceIDRequested
                              .crossdocking_collector_id,
                        ) !== undefined
                          ? service.serviceIDLanding.find(
                            (landingService) =>
                              landingService.collector_id ===
                              service.serviceIDRequested
                                .crossdocking_collector_id,
                          ) !== undefined
                            ? String(
                              service.serviceIDBoard
                                .filter(
                                  (boardService) =>
                                    boardService.branch_id ===
                                    service.serviceIDRequested
                                      .source_crossdocking_branch_id,
                                )
                                .map(
                                  (boardService) => boardService.board_volume,
                                ),
                            )
                            : String(
                              service.serviceIDBoard
                                .filter(
                                  (boardService) =>
                                    boardService.branch_id ===
                                    service.serviceIDRequested
                                      .source_branch_id,
                                )
                                .map(
                                  (boardService) => boardService.board_volume,
                                ),
                            )
                          : String(
                            service.serviceIDBoard
                              .filter(
                                (boardService) =>
                                  boardService.branch_id ===
                                  service.serviceIDRequested.source_branch_id,
                              )
                              .map(
                                (boardService) => boardService.board_volume,
                              ),
                          )
                        : String(
                          service.serviceIDBoard
                            .filter(
                              (boardService) =>
                                boardService.branch_id ===
                                service.serviceIDRequested.source_branch_id,
                            )
                            .map((boardService) => boardService.board_volume),
                        )
                      const deliveryDelayIntercurrences = [
                        "ATRASO NA ENTREGA",
                        "CANCELAMENTO DE VOO",
                        "CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)",
                        "ATRASO NA TRANSFERÊNCIA",
                        "ATRASO NA LIBERAÇÃO"
                      ]

                      const serviceDeliveryDelay = service.occurrenceIDService
                        ?.filter(occurrence =>
                          deliveryDelayIntercurrences.includes(occurrence.intercurrence) && occurrence.proceed === "SIM")
                        ?.reduce((acc, curr) => new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr, {} as OccurrenceProps)

                      const hasDeliveryDelay = serviceDeliveryDelay?.id ? true : false

                      const deliveryDate = serviceDeliveryDelay?.occurrence_date
                        ? formatDate.handle(serviceDeliveryDelay.occurrence_date, "DateWithoutHourToShow")
                        : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

                      const deliveryHour = serviceDeliveryDelay?.occurrence_hour
                        ? formatDate.handle(serviceDeliveryDelay.occurrence_hour, "DateOnlyWithHourMinute")
                        : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

                      const isCrossdockingService = service.serviceIDRequested.crossdocking_collector_id

                      let isServiceDelayed = false

                      if (isCrossdockingService) {

                        const isBoardDateBeforeToToday = isBefore(
                          new Date(service.serviceIDRequested.board_date),
                          new Date()
                        )

                        const isBoardDateSameToToday = isSameDay(
                          new Date(service.serviceIDRequested.board_date),
                          new Date()
                        )

                        if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
                          isServiceDelayed = true
                        } else if (isBoardDateSameToToday) {
                          const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

                          isServiceDelayed = differenceInMinutes(
                            set(new Date(), {
                              hours: boardHours,
                              minutes: boardMinutes
                            }), new Date()
                          ) < 240
                        }
                      }
                      return (
                        <Tr
                          key={index}
                          color={
                            hasDeliveryDelay
                              ? "yellow.400"
                              : service.step === 'availableService'
                                ? handleChangeTextTableColor(
                                  service.serviceIDLanding,
                                  service.serviceIDRequested,
                                  service.serviceIDAllocate,
                                )
                                  ? 'green.400'
                                  : ''
                                : ''
                          }
                          fontWeight={
                            service.step === 'availableService'
                              ? handleChangeTextTableColor(
                                service.serviceIDLanding,
                                service.serviceIDRequested,
                                service.serviceIDAllocate,
                              )
                                ? 'bold'
                                : ''
                              : ''
                          }
                        >
                          <Td>
                            {isServiceDelayed && (
                              <Icon as={FaClock} color={'red.500'} />
                            )}
                          </Td>
                          <Td isNumeric>
                            {!!userLogged && (
                              <>
                                {userLogged.permissions.includes(
                                  'add-available-service',
                                ) &&
                                  service.step !== 'availableService' && (
                                    <Link
                                      to={`/servicos/disponibilizar/desembarque/${service.id}`}
                                    >
                                      <Icon
                                        cursor="pointer"
                                        as={FiEdit3}
                                        fontSize="20"
                                        mt={['2', '2', '0']}
                                      />
                                    </Link>
                                  )}
                                <Link to={`/servico/detalhes/${service.id}`}>
                                  <Icon
                                    cursor="pointer"
                                    as={FiEye}
                                    fontSize="20"
                                  />
                                </Link>
                              </>
                            )}
                          </Td>
                          <Td fontSize="12">{service.protocol}</Td>
                          <Td fontSize="12">{deliveryDate}</Td>
                          <Td fontSize="12">{deliveryHour}</Td>
                          <Td fontSize="12">{shipping.toUpperCase()}</Td>
                          <Td fontSize="12">{
                            trackerLink ?
                              <ChakraLink href={trackerLink} isExternal>
                                {operationalNumber}
                              </ChakraLink>
                              : operationalNumber}</Td>
                          <Td fontSize="12">{sourceBranch.toUpperCase()}</Td>
                          <Td fontSize="12">
                            {destinationBranch.toUpperCase()}
                          </Td>
                          <Td fontSize="12">
                            {service.customerIDService.trading_firstname.toUpperCase()}
                          </Td>
                          <Td fontSize="12">{boardVolume}</Td>
                          <Td fontSize="12">
                            {Array.from(
                              new Set(
                                addresses
                                  .filter((address) =>
                                    service.serviceIDRequested.source_address_id.includes(
                                      address.id,
                                    ),
                                  )
                                  .map((address) => address.cityIDAddress.name),
                              ),
                            )
                              .join(', ')
                              .toUpperCase()}
                          </Td>
                          <Td fontSize="12">
                            {Array.from(
                              new Set(
                                addresses
                                  .filter((address) =>
                                    service.serviceIDRequested.destination_address_id.includes(
                                      address.id,
                                    ),
                                  )
                                  .map((address) => address.cityIDAddress.name),
                              ),
                            )
                              .join(', ')
                              .toUpperCase()}
                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              )}
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

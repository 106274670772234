import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetCityResponse {
  name: string
}

export async function getCity(cityId: string) {
  try {
    const response = await api.get<GetCityResponse>(`/city/${cityId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AvatarGroup, Box, Flex, HStack, IconButton, Link, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { GetSupportResponse } from "api/supports/getSupport";
import { format } from "date-fns";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { FaExternalLinkAlt } from "react-icons/fa";

import { SupportStatus } from "./SupportStatus";
import { TooltipAvatar } from "./TooltipAvatar";

interface SupportDetailTableProps {
  supportResult: GetSupportResponse
}

export function SupportDetailTable({ supportResult }: SupportDetailTableProps) {
  return (
    <Box w='full'>
      <Table size="sm" >
        <Tbody>
          <Tr>
            <Td>Tipo atendimento</Td>
            <Td isNumeric>{supportResult?.support?.type}</Td>
          </Tr>
          <Tr>
            <Td>Status</Td>
            <Td display="flex" justifyContent="flex-end">
              <SupportStatus status={supportResult?.support?.status} />
            </Td>
          </Tr>
          <Tr>
            <Td>Criado por</Td>
            <Td isNumeric>{supportResult?.support?.createdByUser?.firstname} {supportResult?.support?.createdByUser?.lastname}</Td>
          </Tr>
          <Tr>
            <Td>Assunto</Td>
            <Td isNumeric>{supportResult?.support.subject?.name}</Td>
          </Tr>
          <Tr>
            <Td>Responsáveis</Td>
            <Td isNumeric>
              <AvatarGroup justifyContent="end" size="sm" max={3}>
                {supportResult?.support.subject?.responsibles.map((responsible) => {
                  return <TooltipAvatar key={responsible.user.id} name={`${responsible.user.firstname} ${responsible.user.lastname}`} />
                })}
              </AvatarGroup>
            </Td>
          </Tr>
          {supportResult?.support.type === 'Externo' && (
            <>
              <Tr>
                <Td>Data da Solicitação</Td>
                <Td isNumeric>{format(new Date(supportResult?.support?.createdAt), "dd/MM/yyyy '-' HH:mm")}</Td>
              </Tr>
              {supportResult?.support?.deadline && (
                <Tr>
                  <Td>Prazo para resposta</Td>
                  <Td isNumeric>{format(new Date(supportResult?.support?.deadline), "dd/MM/yyyy '-' HH:mm")}</Td>
                </Tr>
              )}
              {supportResult?.support.subject?.name === 'ALTERAÇÃO DE CONTA DE PAGAMENTO' && (
                <>
                  <Tr>
                    <Td>Titular da Conta</Td>
                    <Td isNumeric>{supportResult?.support.externalSupport?.accountOwner}</Td>
                  </Tr>
                  <Tr>
                    <Td>Banco</Td>
                    <Td isNumeric>{supportResult?.support.externalSupport?.bank}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo da conta</Td>
                    <Td isNumeric>{supportResult?.support.externalSupport?.accountType}</Td>
                  </Tr>
                  <Tr>
                    <Td>Agência</Td>
                    <Td isNumeric>{`${String(supportResult?.support.externalSupport?.agency).slice(0, -1)}-${String(supportResult?.support.externalSupport?.agency).slice(-1)}`}</Td>
                  </Tr>

                  <Tr>
                    <Td>Conta</Td>
                    <Td isNumeric>{`${String(supportResult?.support.externalSupport?.accountNumber).slice(0, -1)}-${String(supportResult?.support.externalSupport?.accountNumber).slice(-1)}`}</Td>
                  </Tr>
                  <Tr>
                    <Td>CPF</Td>
                    <Td isNumeric>{supportResult?.support.externalSupport?.cpf}</Td>
                  </Tr>
                </>
              )}
            </>
          )}

          {supportResult?.support.description && (
            <Tr>
              <Td>Descrição da solicitação</Td>
              <Td isNumeric>{supportResult?.support?.description}</Td>
            </Tr>
          )}

          {supportResult?.support.type === 'Parceiro' && (
            <>
              <Tr>
                <Td>Motivo do atendimento</Td>
                <Td isNumeric>{supportResult?.support.partnerSupport.subject}</Td>
              </Tr>
              <Tr>
                <Td>Coletador</Td>
                <Td isNumeric>{supportResult?.support?.partnerSupport.collector?.trading_name}</Td>
              </Tr>

              {supportResult?.support.partnerSupport.subject === 'SOLICITAÇÃO DE ALTERAÇÃO DE DADOS CADASTRAIS/CONTA DE PAGAMENTO' && (
                <>
                  <Tr>
                    <Td>Banco</Td>
                    <Td isNumeric>{supportResult?.support.partnerSupport?.bank}</Td>
                  </Tr>
                  <Tr>
                    <Td>Agência</Td>
                    <Td isNumeric>{supportResult?.support.partnerSupport.agency}</Td>
                  </Tr>
                  <Tr>
                    <Td>Conta</Td>
                    <Td isNumeric>{`${String(supportResult?.support.partnerSupport.accountNumber).slice(0, -1)}-${String(supportResult?.support.partnerSupport.accountNumber).slice(-1)}`}</Td>
                  </Tr>
                </>
              )}
            </>
          )}

          {supportResult?.support?.requestAttachments.length !== 0 && supportResult?.support?.requestAttachments.map((attachment) => {
            return (
              <Tr key={attachment.id}>
                <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Anexo da solicitação</Td>
                <Td isNumeric>
                  <IconButton
                    aria-label="Abrir anexo"
                    as={Link}
                    href={attachment?.attachment?.link}
                    icon={<FaExternalLinkAlt />}
                    size="sm"
                    isExternal
                  />
                </Td>
              </Tr>
            )
          })}

          {supportResult?.support?.type === 'Interno' && (
            <>
              <Tr>
                <Td>Categoria</Td>
                <Td isNumeric>{supportResult?.support?.internSupport?.category}</Td>
              </Tr>
              {Object.entries(supportResult?.support?.internSupport?.formData).map(([key, { label, value, type }]) => (
                <Tr key={key}>
                  <Td>{label}</Td>
                  <Td isNumeric>
                    {type === 'date' && typeof value === 'string' ? (
                      format(new Date(value), 'dd/MM/yyyy')
                    ) : type === 'attachments' && Array.isArray(JSON.parse(value as string)) ? (
                      <>
                        {JSON.parse(value as string).map((attachment: Attachment) => (
                          <IconButton
                            aria-label="Abrir anexo"
                            as={Link}
                            href={attachment.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                            ml={2}
                          />
                        ))}
                      </>
                    ) : (
                      value
                    )}
                  </Td>
                </Tr>
              ))}

            </>
          )}
        </Tbody>
      </Table>
      {supportResult?.support?.resolutions.length !== 0 && (
        <Accordion allowToggle w="full">
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontSize="md">
                Resoluções
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4} maxH="400px" overflowY="scroll">
              {supportResult?.support?.resolutions?.map((resolution) => (
                <Box key={resolution.id}>
                  <HStack justifyContent="flex-start" mb={4} w="full">
                    <Box
                      bg="gray.50"
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="lg"
                      p={4}
                      w="70%"
                      shadow="sm"
                    >
                      <Flex direction="column" justifyContent="space-between" h="full">
                        <Text fontSize="sm" fontWeight="medium" mb={2}>
                          {resolution.content}
                        </Text>

                        {resolution?.attachments?.length !== 0 &&
                          resolution?.attachments?.map((attachment) => (
                            <Flex key={attachment.id} justify="flex-start" align="center" mb={2}>
                              <IconButton
                                aria-label="Abrir anexo"
                                as={Link}
                                href={attachment?.attachment.link}
                                icon={<FaExternalLinkAlt />}
                                size="sm"
                                isExternal
                                colorScheme="teal"
                                variant="ghost"
                                mr={2}
                              />
                              <Text fontSize="xs" color="teal.600">
                                Abrir anexo
                              </Text>
                            </Flex>
                          ))}

                        <Text fontSize="xs" color="gray.500" alignSelf="flex-end">
                          {format(new Date(resolution.createdAt), "dd/MM/yyyy")}
                        </Text>
                      </Flex>
                    </Box>
                  </HStack>

                  {resolution.reply && (
                    <HStack justifyContent="flex-end" mb={4} w="full">
                      <Box
                        bg="blue.50"
                        border="1px solid"
                        borderColor="blue.200"
                        borderRadius="lg"
                        p={4}
                        w="70%"
                        shadow="sm"
                      >
                        <Flex direction="column" justifyContent="space-between" h="full">
                          <Text fontSize="sm" fontWeight="medium" mb={2}>
                            {resolution.reply.content}
                          </Text>

                          {resolution.reply.attachments?.length !== 0 &&
                            resolution.reply.attachments?.map((attachment) => (
                              <Flex key={attachment.id} justify="flex-start" align="center" mb={2}>
                                <IconButton
                                  aria-label="Abrir anexo"
                                  as={Link}
                                  href={attachment?.attachment.link}
                                  icon={<FaExternalLinkAlt />}
                                  size="sm"
                                  isExternal
                                  colorScheme="teal"
                                  variant="ghost"
                                  mr={2}
                                />
                                <Text fontSize="xs" color="teal.600">
                                  Abrir anexo
                                </Text>
                              </Flex>
                            ))}

                          <Text fontSize="xs" color="gray.500" alignSelf="flex-end">
                            {format(new Date(resolution.reply.createdAt), "dd/MM/yyyy")}
                          </Text>
                        </Flex>
                      </Box>
                    </HStack>
                  )}
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Box>
  )
}

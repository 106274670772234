import { Box, Button, Flex, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt, FaTimes } from "react-icons/fa";
import { OnboardingDetail } from "./OnboardingDetail";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { GetOnboardingsResponse } from "api/onboardings/getOnboardings";
import { Onboarding } from "api/onboardings/onboarding";
import { inactivateOnboarding } from "api/onboardings/inactivateOnboarding";
import { inviteToOnboarding } from "api/onboardings/inviteToOnboarding";

interface OnboardingsKanbanCardProps {
  onboarding: Onboarding
}

export function OnboardingsKanbanCard({ onboarding }: OnboardingsKanbanCardProps) {
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()
  const { userLogged } = useAuth()

  const {
    isOpen: isOnboardingDetailModalOpen,
    onToggle: onToggleOnboardingDetailModal,
  } = useDisclosure()

  const userHasInactivateOnboardingPermission = userLogged?.permissions.includes('inactivate-onboarding')
  const userCanInviteToOnboarding = userLogged?.permissions.includes('invite-to-onboarding')

  const { mutateAsync: inactivateOnboardingFn } = useMutation({
    mutationFn: inactivateOnboarding,
    onSuccess(_data, { onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })
      queryClient.invalidateQueries(['onboardings'])
      queryClient.invalidateQueries({ queryKey: 'inviting-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'training-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'testing-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'finished-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'inactive-onboardings' })
      queryClient.invalidateQueries({ queryKey: ['onboarding', onboardingId]})
      cachedOnboardings.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
                status: 'inactivate',
                inactivated_at: new Date().toISOString(),
              }
            }

            return onboarding
          })
        })
      })
    },
  })

  const { mutateAsync: inviteToOnboardingFn } = useMutation({
    mutationFn: inviteToOnboarding,
    onSuccess(_data, { onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })

      queryClient.invalidateQueries(['onboardings'])
      queryClient.invalidateQueries({ queryKey: 'inviting-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'training-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'testing-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'finished-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'inactive-onboardings' })
      queryClient.invalidateQueries({ queryKey: ['onboarding', onboardingId]})
      cachedOnboardings.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
                status: 'inviting',
              }
            }

            return onboarding
          })
        })
      })
    },
  })


  async function handleInviteOnboarding(onboardingId: string) {
    await promiseMessage(inviteToOnboardingFn({
      onboardingId: onboardingId
    }), 'Convite onboarding enviado!')
  }



  async function handleInactivateOnboarding() {
    await promiseMessage(inactivateOnboardingFn({
      onboardingId: onboarding.id
    }), 'Onboarding inativado!')
  }

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleOnboardingDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {onboarding?.name}
        </Text>

      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">TREINAMENTO</Text>
          </HStack>
          <Text ml={5}>{onboarding?.training_type}</Text>
        </Box>

      </VStack>
      <Flex align="center" gap="2" alignItems="flex-end" justifyContent="flex-end">
        {(onboarding.status !== 'inactive' && userHasInactivateOnboardingPermission) && (
          <Button
            size="xs"
            lineHeight="1"
            leftIcon={<FaTimes />}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              handleInactivateOnboarding()
            }}
          >
            Inativar
          </Button>
        )}
        {(!onboarding.training_type.includes('LLM') && onboarding.status === 'inviting' && userCanInviteToOnboarding) && (
          <Button
            size="xs"
            lineHeight="1"
            leftIcon={<FaTimes />}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              handleInviteOnboarding(onboarding?.id)
            }}
          >
            Convidar
          </Button>
        )}
      </Flex>
      <Modal
        isOpen={isOnboardingDetailModalOpen}
        onClose={onToggleOnboardingDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <OnboardingDetail
          onboardingId={onboarding.id}
        />
      </Modal>
    </Box>
  )
}

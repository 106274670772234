import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { ExtraDiscount } from "./dtos/ExtrasDiscounts";

interface ExtraDiscountResponse {
  extraDiscount: ExtraDiscount
}

type QueryOptions = UseQueryOptions<ExtraDiscountResponse, unknown, ExtraDiscountResponse, QueryKey>

interface UseExtraDiscountOptions {
  extraDiscountId: string
  queryKey?: string
  queryOptions?: QueryOptions
}

export function useExtraDiscount(options?: UseExtraDiscountOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getExtraDiscount() {
    try {
      const { data } = await api.get(`/extras-discounts/${options.extraDiscountId}`)

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado.")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery(options?.queryKey || 'extraDiscount', getExtraDiscount, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

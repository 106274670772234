import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import 'react-toastify/dist/ReactToastify.css'
import { IDriverProps } from 'services/getFunctions/driver/getDrivers'

interface DeliveryServiceProviderProps {
  children: ReactNode
  pathname?: string
}

interface ContentDeclarationAttachments {
  key: string
  filename: string
}

export interface DeliveryServiceProps {
  id: string
  address_id: string
  service_id: string
  driver_id: string
  step: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  responsible_name: string | null
  delivery_volume: number | null
  box_photo: any | null
  content_declaration_attachments: string[] | ContentDeclarationAttachments[]  | null
  receipt_photo: string | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  observation: string | null
  driverIDDelivery: IDriverProps
  real_arrival_timestamp?: string
  real_arrival_timestamp_validated?: boolean
}

interface DeliveryServiceContextProps {
  deleteDeliveryService: (
    deliveryServiceId: string,
  ) => Promise<boolean | undefined>
}

export const DeliveryServiceContext =
  createContext<DeliveryServiceContextProps>({} as DeliveryServiceContextProps)

export function DeliveryServiceProvider({
  children,
  pathname,
}: DeliveryServiceProviderProps) {
  async function deleteDeliveryService(deliveryServiceId: string) {
    try {
      await api.delete(`/delivery-service/${deliveryServiceId}`)
      return true
    } catch {
      return false
    }
  }

  return (
    <DeliveryServiceContext.Provider
      value={{
        deleteDeliveryService,
      }}
    >
      {children}
    </DeliveryServiceContext.Provider>
  )
}

import { Flex, HStack, Stack } from '@chakra-ui/react'
import { Select } from 'components/Inputs/SelectInput';
import { useEffect } from 'react';

import { useFormContext } from "react-hook-form";

interface PurchaseOrderPMCProps {
  pmcs: {
    id: string
    protocol: number
  }[]
}

export interface PurchaseOrderPmcFormInputs {
  type: string
  purchaseDescription: string
  pmcId: string
}

export const purchaseOrderTypeOptions = [
  { key: 'product', value: 'PRODUTO', showOption: 'PRODUTO' },
  { key: 'service', value: 'SERVIÇO', showOption: 'SERVIÇO' },
]

export const purchaseOrderTypeProductsOptions = [
  { key: 'product', value: 'PMC', showOption: 'PMC' },
  { key: 'outhers', value: 'OUTROS', showOption: 'OUTROS' },
]

export function PurchaseOrderPmcForm({ pmcs }: PurchaseOrderPMCProps) {

  const {
    register,
    setValue,
    formState: {
      errors
    }
  } = useFormContext<PurchaseOrderPmcFormInputs>()

  useEffect(() => {
    setValue('type', 'PRODUTO')
    setValue("purchaseDescription", 'PMC')
  }, [setValue])

  const waitingReceivePmcsOptions = pmcs.map(pmc => {
    return { key: pmc.id, value: pmc.id, showOption: pmc.protocol }
  })

  return (
    <Flex direction="column" gap={6} align="center">
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <HStack w="100%">
          <Select
            {...register('type')}
            name="type"
            options={purchaseOrderTypeOptions}
            label="Tipo"
            placeholder="Selecione uma opção..."
            error={errors.type}
            isDisabled
            required
          />

          <Select
            {...register('purchaseDescription')}
            name="purchaseDescription"
            options={purchaseOrderTypeProductsOptions}
            label="Tipo do Produto"
            placeholder="Selecione uma opção..."
            error={errors.purchaseDescription}
            isDisabled
            required
          />


        </HStack>
      </Stack>

      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <HStack w="100%">
          <Select
            {...register('pmcId')}
            name="pmcId"
            options={waitingReceivePmcsOptions}
            label="Protocolo da PMC"
            placeholder="Selecione uma opção..."
            error={errors.pmcId}
            required
          />
        </HStack>
      </Stack>

    </Flex>
  )
}

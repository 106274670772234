import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Td, Tr } from '@chakra-ui/react';
import { getTrainingLlm } from "api/trainingLlm/getTrainingLlm"
import { format } from 'date-fns';
import { useQuery } from "react-query"
import { TrainingLlmStatus } from './TrainingLlmStatus';

interface TrainingLlmDetailProps {
  trainingLlmId: string
}

export function TrainingLlmDetail({ trainingLlmId }: TrainingLlmDetailProps) {

  const { data: trainingLlmData, isLoading: isTrainingLlmDataLoading } = useQuery({
    queryKey: ['trainingLlm', trainingLlmId],
    queryFn: () => getTrainingLlm({ trainingLlmId })
  })

  return (
    <ModalContent>
      {isTrainingLlmDataLoading ? (
        <ModalBody
          p="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Detalhes do Treinamento LLM</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <TableContainer>
              <Table size="sm">
                <Tr>
                  <Td>Colaborador</Td>
                  <Td isNumeric>{trainingLlmData?.llmTraining?.collaborator.firstname} {trainingLlmData?.llmTraining?.collaborator.lastname}</Td>
                </Tr>
                <Tr>
                  <Td>Treinamento</Td>
                  <Td isNumeric>{trainingLlmData?.llmTraining.training}</Td>
                </Tr>
                <Tr>
                  <Td>Status</Td>
                  <Td display='flex' justifyContent='flex-end'>
                    <TrainingLlmStatus status={trainingLlmData?.llmTraining.status} />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Data limite para realização</Td>
                  <Td isNumeric>{format(new Date(trainingLlmData.llmTraining.due_date), 'dd/MM/yyyy')}</Td>
                </Tr>
                {trainingLlmData?.llmTraining.approved_at && (
                  <Tr>
                    <Td>Data de aprovação</Td>
                    <Td isNumeric>{format(new Date(trainingLlmData.llmTraining.approved_at), 'dd/MM/yyyy')}</Td>
                  </Tr>
                )}
                {trainingLlmData?.llmTraining.reproved_at && (
                  <Tr>
                    <Td>Data de reprovação</Td>
                    <Td isNumeric>{format(new Date(trainingLlmData.llmTraining.reproved_at), 'dd/MM/yyyy')}</Td>
                  </Tr>
                )}
              </Table>
            </TableContainer>
          </ModalBody>
        </>
      )}

    </ModalContent>
  )
}

import { Button, Flex, Icon, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { approvePmc } from "api/pmcs/approvePmc";
import { getPmc } from "api/pmcs/getPmc";
import { GetPmcsResponse } from "api/pmcs/getPmcs";
import { usePmcSocket } from "hooks/socket/usePmcSocket";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaArrowRight } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as yup from "yup"

interface ApprovePmcProps {
  pmcId: string
  onCloseModal: () => void
}

interface ApprovePmcSchema {
  pmcProducts: {
    provider: string
    measure: string
    currentStock: number
    standardStock: number
    minStock: number
    unitCost: number
    purchaseQuantity: number
    productId: string
    approvedQuantity: number
    productName: string
  }[]
}

const approvePmcSchema = yup.object().shape({
  pmcProducts: yup.array().min(1).of(yup.object({
    approvedQuantity: yup.number().required()
  }))
})

export function ApprovePmc({ pmcId, onCloseModal }: ApprovePmcProps) {

  const { data: pmcData } = useQuery({
    queryKey: ['pmc', pmcId],
    queryFn: () => getPmc({ pmcId })
  })

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<ApprovePmcSchema>({
    resolver: yupResolver(approvePmcSchema)
  })

  const { fields, update } = useFieldArray({
    name: 'pmcProducts',
    control,
  })

  useEffect(() => {
    if (pmcData) {
      pmcData.pmc.pmcProducts.forEach((pmcProduct, index) => {
        update(index, {
          purchaseQuantity: pmcProduct.purchase_quantity,
          currentStock: pmcProduct.product.current_stock,
          measure: pmcProduct.product.measure,
          provider: pmcProduct.product.generalProvider.trading_name,
          minStock: pmcProduct.product.min_stock,
          standardStock: pmcProduct.product.standard_stock,
          unitCost: pmcProduct.product.unit_cost,
          approvedQuantity: undefined,
          productId: pmcProduct.product_id,
          productName: pmcProduct.product.material.material_name
        })
      })
    }
  }, [update, pmcData])

  const queryClient = useQueryClient()

  const { pmcSocketConnection } = usePmcSocket()

  const { mutateAsync: approvePmcFn } = useMutation({
    mutationFn: approvePmc,
    onSuccess(data, { pmcId }) {
      const cachedPmcs = queryClient.getQueriesData<GetPmcsResponse>({
        queryKey: ['pmcs']
      })

      cachedPmcs.forEach(([cacheKey, cacheData]) => {

        queryClient.setQueryData<GetPmcsResponse>(cacheKey, {
          ...cacheData,
          pmcs: cacheData.pmcs.map((pmc) => {
            if (pmc.id === pmcId) {
              return { ...pmc, approved_at: new Date().toISOString() }
            }

            return pmc
          })
        })
      })

      pmcSocketConnection?.emit("pmcApproved", {
        profiles: data.notificationProfiles,
        pmcProtocol: data.pmc.protocol
      })

      onCloseModal()
    },
  })

  const { promiseMessage } = useToastify()

  async function handleApprovePmc(data: ApprovePmcSchema) {
    await promiseMessage(approvePmcFn({
      body: data,
      pmcId,
    }), 'Pmc aprovada! 🚀')
  }

  return (
    <ModalContent maxW='1350px'>
      <ModalHeader letterSpacing="tight">Pmc: {pmcData?.pmc.protocol}</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        p="4"
        as="form"
        maxHeight="600px"
        overflowY="scroll"
        onSubmit={handleSubmit(handleApprovePmc)}
      >
        <TableContainer>
          <Table size="sm" w="full">
            <Thead
              h="40px"
              bg="#38c3fa"
            >
              <Tr>
                <Th textColor="white">Produto</Th>
                <Th textColor="white">Fornecedor</Th>
                <Th textColor="white">Medida</Th>
                <Th textColor="white">Atual</Th>
                <Th textColor="white">Padrão</Th>
                <Th textColor="white">Mínimo</Th>
                <Th textColor="white">Custo unitário</Th>
                <Th textColor="white">Qtde a comprar</Th>
                <Th textColor="white">Custo estimado</Th>
                <Th textColor="white">Qtde aprovada</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fields.map((field, index) => {
                return (
                  <Tr key={field.id}>
                    <Td>{field.productName}</Td>
                    <Td>{field.provider}</Td>
                    <Td>{field.measure}</Td>
                    <Td>{field.currentStock}</Td>
                    <Td>{field.standardStock}</Td>
                    <Td>{field.minStock}</Td>
                    <Td>{(field.unitCost / 100).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}</Td>
                    <Td>{field.purchaseQuantity}</Td>
                    <Td>{((field.unitCost / 100) * field.purchaseQuantity).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}</Td>
                    <Td>
                      <Input
                        {...register(`pmcProducts.${index}.approvedQuantity`)}
                        name={`pmcProducts.${index}.approvedQuantity`}
                        defaultValue={field.purchaseQuantity}
                        placeholder="Ex: 10"
                        size="sm"
                        borderRadius="md"
                      />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>

        {errors?.pmcProducts && (
          <Flex
            w="full"
            justifyContent="flex-end"
            mt="2"
          >
            <Text color="red.500" fontSize="xs">É necessário preencher todos os campos de quantidade aprovada!</Text>
          </Flex>
        )}

        <Flex
          mt="2"
          gap="2"
          w="full"
          justifyContent="flex-end"
        >
          <Button
            type="button"
            size="sm"
            variant="outline"
            onClick={onCloseModal}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            leftIcon={<Icon as={FaArrowRight} />}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Aprovar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import request from "axios"
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { BusinessBugdetRoutesResponse } from "./dtos/BusinessBudgetRoutes";


type QueryOptions = UseQueryOptions<BusinessBugdetRoutesResponse, unknown, BusinessBugdetRoutesResponse, QueryKey>

interface UseBusinessBudgetsRoutesOptions {
  customer_id: string
  queryOptions?: QueryOptions
}

export function useBusinessBudgetRoutes(
  options?: UseBusinessBudgetsRoutesOptions
) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getBusinessBudgetsRoutes() {
    try {
      const { data } = await api.get<BusinessBugdetRoutesResponse>(
        `/business-budget/${options.customer_id}/routes`
      )

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    "businessBudgetsRoutes",
    options?.customer_id
  ], getBusinessBudgetsRoutes, {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

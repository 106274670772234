import * as yup from "yup"
import { Button, Flex, Spinner } from "@chakra-ui/react";
import { ExtrasDiscountsForm, ExtrasDiscountsFormInputs } from "components/Form/ExtrasDiscountsForm";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Select } from "components/Inputs/SelectInput";
import { useCollectors } from "hooks/collector/useCollectors";
import { useCollectorsFunctions } from "hooks/collector/useCollectorsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect, useState } from "react";

interface CreateCollectorExtraDiscountInputs extends Omit<ExtrasDiscountsFormInputs, 'extraDiscountAttachments'> {
  collectorId: string
  attachmentsIds: string[]
}

const createCollectorExtraDiscountSchema = yup.object().shape({
  collectorId: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  serviceDate: yup.string().required('Campo obrigatório'),
  value: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  serviceType: yup.string().required('Campo obrigatório'),
  customerId: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  serviceRoute: yup.mixed().when('serviceType', {
    is: 'BUSINESS',
    then: yup.string().required('Campo obrigatório'),
  }),
  servicesProtocols: yup.mixed().when('serviceType', {
    is: 'EXPRESS',
    then: yup.array()
      .of(
        yup.object().shape({
          protocol: yup.number()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório')
            .positive('O protocolo deve ser um número positivo')
            .integer('O protocolo deve ser um número inteiro'),
        })
      )
      .min(1, 'Você deve adicionar pelo menos um serviço'),
  })
})

export function CreateCollectorExtraDiscount() {

  const { userLogged } = useAuth()

  const { push: redirect } = useHistory()
  const [attachmentsIds, setAttachmentsIds] = useState<string[]>([])

  const userHasPermissionToCreateCollectorExtraDiscount =
    userLogged?.permissions.includes('create-collector-extra-discount')

  useEffect(() => {
    if (!userHasPermissionToCreateCollectorExtraDiscount) {
      redirect('/')
    }
  }, [redirect, userHasPermissionToCreateCollectorExtraDiscount])

  const {
    data: collectors,
    isFetching: isFetchingCollectors
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const collectorsSelectOptions = collectors?.map((collector) => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }
  })

  const formMethods = useForm<CreateCollectorExtraDiscountInputs>({
    resolver: yupResolver(createCollectorExtraDiscountSchema),
    defaultValues: {
      servicesProtocols: [{protocol: 0}]
    }
  })

  const { register, handleSubmit, formState: { errors, isSubmitting } } = formMethods

  const {
    createCollectorExtraDiscount: {
      mutateAsync: createCollectorExtraDiscount
    }
  } = useCollectorsFunctions()

  const { promiseMessage } = useToastify()

  async function handleCreateCollectorExtraDiscount(
    input: CreateCollectorExtraDiscountInputs
  ) {
    await promiseMessage(createCollectorExtraDiscount({
      collectorId: input.collectorId,
      input: {
        ...input,
        value: transformStringToNumber(input.value),
        attachmentsIds: attachmentsIds,
        servicesProtocols: input.serviceType === 'EXPRESS' ? input.servicesProtocols.map(serviceProtocol => serviceProtocol.protocol) : null
      }
    }, {
      onSuccess: () => {
        redirect('/extras-descontos/coletadores')
      }
    }), 'Extra/Desconto de coletador criado com sucesso!')
  }

  const handleSetAttachmentsIds = (attachmentsIds : string[]) => setAttachmentsIds(attachmentsIds)

  return (
    <StandardBackgroundForm
      title='Criar Extra/Desconto de Coletador'
      onSubmit={handleSubmit(handleCreateCollectorExtraDiscount)}
    >
      {isFetchingCollectors ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ExtrasDiscountsForm onSetAttachmentsIds={handleSetAttachmentsIds}>
            <Select
              {...register('collectorId')}
              name='collectorId'
              options={collectorsSelectOptions}
              label='Coletador'
              placeholder='Selecione uma opção...'
              error={errors.collectorId}
              required
            />
          </ExtrasDiscountsForm>
        </FormProvider>
      )}

      <Flex
        mt={4}
        gap={4}
        w='full'
        align='center'
        justifyContent='flex-end'
      >
        <Button
          type='submit'
          colorScheme='blue'
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

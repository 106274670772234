import { Box, FormControl, FormLabel, Input, Select, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";

import { useCustomers } from "hooks/customer/useCustomers";
import { FormEvent } from "react";
import { useFormContext, } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function AccountsReceivableProvisionForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const {
    data: customersResult,
  } = useCustomers({
    queryParams: {
      situation: 'ATIVO',
    }
  })

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      customer: 'Cliente',
      description: 'Descrição',
      adjustmentStartDate: 'Data de início do ajuste',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };


  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.customer} mt={3}>
        <FormLabel fontSize="sm">
          Cliente
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.customer.value")}
          name="formData.customer.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('customer')}
        >
          {customersResult?.map((customer) => {
            return <option key={customer.id} value={customer.trading_firstname}>{customer.trading_firstname}</option>
          })}
        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.description} mt={3}>
        <FormLabel fontSize="sm">
          Descrição
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.description.value')}
          name='formData.description.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('description')} />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.adjustmentStartDate} mt={3}>
        <FormLabel fontSize='sm'>
          Data de início do ajuste
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.adjustmentStartDate.value')}
          name='formData.adjustmentStartDate.value'
          type="date"
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('adjustmentStartDate')}
        />
      </FormControl>

    </Box>
  )
}

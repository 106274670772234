import { Icon, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { useConsultOccurrencesContext } from "hooks/occurrences/useConsultOccurrencesContext";
import { FaSearch } from "react-icons/fa";

export function QueryOccurrences() {
  const { query, handleChangeQuery } = useConsultOccurrencesContext()

  return (
    <InputGroup
      w={["full", "full", "min"]}
    >
      <InputLeftElement
        pointerEvents="none"
      >
        <Icon
          as={FaSearch}
        />
      </InputLeftElement>
      <Input
        name="query"
        placeholder="Busca por palavras..."
        size="md"
        value={query}
        onChange={(event) => handleChangeQuery(event.target.value)}
        w={["full", "full", "min"]}
        rounded="xl"
        boxShadow="base"
        pl={8}
      />
    </InputGroup>
  )
}

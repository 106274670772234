import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { format, formatDistanceToNowStrict, isFuture } from "date-fns";
import { FaCalendarAlt, FaExclamationCircle } from "react-icons/fa";
import { ptBR } from "date-fns/locale";
import { ActiveFeedback } from "api/feedbacks/_types/ActiveFeedback";
import { ActiveFeedbackDetails } from "./ActiveFeedbackDetails";

interface KanbanActiveFeedbackCardProps {
  activeFeedback: ActiveFeedback
}

export function KanbanActiveFeedbackCard({ activeFeedback }: KanbanActiveFeedbackCardProps) {

  const {
    isOpen: isActiveFeedbackDetailModalOpen,
    onToggle: onToggleActiveFeedbackDetailModal,
  } = useDisclosure()

  const activeFeedbackFinalDate = new Date(activeFeedback?.deadline);
  const formattedActiveFeedbackDate = format(activeFeedbackFinalDate, "dd MMM", { locale: ptBR });

  const relativeDate = formatDistanceToNowStrict(activeFeedbackFinalDate, { locale: ptBR });
  const isDateInFuture = isFuture(activeFeedbackFinalDate);
  const relativeDateText = isDateInFuture ? `em ${relativeDate}` : `${relativeDate} atrás`;

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      cursor='pointer'
      onClick={onToggleActiveFeedbackDetailModal}
    >

      <Text fontWeight="bold" mb={2}>
        {activeFeedback?.cltDriverId ? activeFeedback?.cltDriver?.driver?.firstname : activeFeedback?.internClt ? activeFeedback?.internClt?.name : '-'}
      </Text>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        {['scheduled', 'direct-leader-approving', 'executive-leader-approving', 'awaiting-leader-feedback'].includes(activeFeedback?.status) && (
          <HStack spacing={2}>
            {isDateInFuture ? (
              <>
                <Icon as={FaCalendarAlt} />
                <Text fontWeight="bold">{`Venc ${formattedActiveFeedbackDate}`}</Text>
              </>
            ) : (
              <>
                <Icon as={FaExclamationCircle} color="red.500" />
                <Text color="red.500" fontWeight="bold">{`Venc ${formattedActiveFeedbackDate}`}</Text>
              </>
            )}
            <Text>{relativeDateText}</Text>
          </HStack>
        )}

      </VStack>
      <Modal
        isOpen={isActiveFeedbackDetailModalOpen}
        onClose={onToggleActiveFeedbackDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <ActiveFeedbackDetails
          activeFeedbackId={activeFeedback.id}
        />
      </Modal>
    </Box>
  )
}

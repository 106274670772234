import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { BillingStatus } from "api/billings/billing";
import { getBillings } from "api/billings/getBillings";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { BillingsTableFilters } from "./components/BillingsTableFilters";
import { BillingsTableRow } from "./components/BillingsTableRow";

export function Billings() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const { userLogged } = useAuth()

  const page = searchParams.get('page') ?? '1'
  const customer = searchParams.get('customer')
  const status = searchParams.get('status') as BillingStatus | 'all' ?? 'all'

  const { data: billingsData, isLoading: isBillingsDataLoading } = useQuery({
    queryKey: ['billings', page, customer, status, userLogged?.customer_id],
    queryFn: () => getBillings({
      currentPage: page,
      customer: customer,
      pageSize: '20',
      status: status,
      customerId: userLogged?.customer_id
    }),
    keepPreviousData: true
  })

  useEffect(() => {
    if (userLogged?.customer_id && billingsData) {
      searchParams.set('customer', billingsData.billings[0].customer.trading_firstname)
      searchParams.set('status', 'done')

      replace({ search: searchParams.toString() })
    }
  }, [searchParams, userLogged, billingsData, replace])

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: billingsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: billingsData?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (

      <Box
        bg="white"
        borderRadius="md"
        p="4"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Heading letterSpacing="tight">Faturamentos</Heading>

          <BillingsTableFilters />

          {isBillingsDataLoading ? (
            <Spinner />
          ) : (
            <TableContainer
              border="1px solid"
              borderColor="gray.200"
              borderRadius="md"
            >
              <Table size="sm">
                <Thead
                  h="40px"
                  bg="#38c3fa"
                >
                  <Tr>
                    <Th></Th>
                    <Th></Th>
                    <Th textColor="white">Cliente</Th>
                    <Th textColor="white">status</Th>
                    <Th textColor="white">Vencimento padrão</Th>
                    <Th textColor="white">Vencimento real</Th>
                    <Th textColor="white">Dia do faturamento</Th>
                    <Th textColor="white">Tipo de pagamento</Th>
                    <Th textColor="white">Centro de custo</Th>
                    <Th></Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {billingsData.billings.map((billing) => {
                    return (
                      <BillingsTableRow key={billing.id} billing={billing} />
                    )
                  })}

                </Tbody>
              </Table>
            </TableContainer>
          )}


          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />

        </Flex>

      </Box>

  )
}

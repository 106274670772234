import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface DeletePatrimonyTypeParams {
  patrimonyTypeId: string
}
export async function deletePatrimonyType({
  patrimonyTypeId,
}: DeletePatrimonyTypeParams) {
  try {
    await api.delete(`patrimony-types/${patrimonyTypeId}`)

  } catch (error) {
    console.log("error", error)
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Socket } from "socket.io-client";
import { socketApi } from "../services/socket/socket";

interface EmitCreatedExternalComnunicationNotificationProps {
  profiles: string[]
  serviceProtocol: number
}

interface EmitAnsweredExternalComnunicationNotificationProps {
  questionOwner: string
  serviceProtocol: number
}

interface EmitAskedExternalComnunicationNotificationProps extends EmitCreatedExternalComnunicationNotificationProps {}
interface EmitClosedExternalComnunicationNotificationProps extends EmitCreatedExternalComnunicationNotificationProps {}

interface ExternalComunicationsSocketContextProps {
  externalComunicationsocketConnection: Socket | null
  emitCreatedExternalComunicationNotification: (props: EmitCreatedExternalComnunicationNotificationProps) => void
  emitAnsweredExternalComunicationNotification: (props: EmitAnsweredExternalComnunicationNotificationProps) => void
  emitAskedExternalComunicationNotification: (props: EmitAskedExternalComnunicationNotificationProps) => void
  emitClosedExternalComunicationNotification: (props: EmitClosedExternalComnunicationNotificationProps) => void
}

interface ExternalComunicationsSocketProviderProps {
  children: ReactNode
}

interface CreatedExternalComunicationNotificationData {
  message: string
  profiles: string[]
}

interface AnsweredExternalComunicationData {
  message: string
  questionOwner: string
}

interface AskedExternalComunicationData extends CreatedExternalComunicationNotificationData { }
interface ClosedExternalComunicationData extends CreatedExternalComunicationNotificationData { }


export const ExternalComunicationsSocketContext = createContext({} as ExternalComunicationsSocketContextProps)

export function ExternalComunicationsSocketProvider({ children }: ExternalComunicationsSocketProviderProps) {
  const [externalComunicationsocketConnection, setExternalComunicationsSocketConnection] = useState<Socket | null>(null)

  const { infoMessage } = useToastify()
  const { userLogged } = useAuth()

  const queryClient = useQueryClient()

  useEffect(() => {
    setExternalComunicationsSocketConnection(socketApi.connect("comunications/external"))
  }, [])

  useEffect(() => {
    externalComunicationsocketConnection?.on("externalComunicationCreatedNotification", async ({
      message,
      profiles
    }: CreatedExternalComunicationNotificationData) => {
      const isUserLoggedNotification = profiles.includes(userLogged?.user_type)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries('externalComunications')
        queryClient.invalidateQueries('externalComunicationsMetrics')

        infoMessage(message)
      }
    })

    externalComunicationsocketConnection?.on("externalComunicationAnsweredNotification", async ({
      message,
      questionOwner
    }: AnsweredExternalComunicationData) => {
      const isUserLoggedNotification = userLogged?.id === questionOwner

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries('externalComunications')

        infoMessage(message)
      }
    })

    externalComunicationsocketConnection?.on("externalComunicationAskedNotification", async ({
      message,
      profiles
    }: AskedExternalComunicationData) => {
      const isUserLoggedNotification = profiles.includes(userLogged?.user_type)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries('externalComunications')

        infoMessage(message)
      }
    })

    externalComunicationsocketConnection?.on("externalComunicationClosedNotification", async ({
      message,
      profiles
    }: ClosedExternalComunicationData) => {
      const isUserLoggedNotification = profiles.includes(userLogged?.user_type)

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries('externalComunications')
        queryClient.invalidateQueries('externalComunicationsMetrics')

        infoMessage(message)
      }
    })

    return () => {
      externalComunicationsocketConnection?.off("externalComunicationCreatedNotification")
      externalComunicationsocketConnection?.off("externalComunicationAnsweredNotification")
      externalComunicationsocketConnection?.off("externalComunicationAskedNotification")
      externalComunicationsocketConnection?.off("externalComunicationClosedNotification")
    }
  }, [externalComunicationsocketConnection, queryClient, infoMessage, userLogged])

  function emitCreatedExternalComunicationNotification({
    profiles,
    serviceProtocol,
  }: EmitCreatedExternalComnunicationNotificationProps) {
    externalComunicationsocketConnection.emit('externalComunicationCreated', {
      profiles,
      serviceProtocol
    })
  }

  function emitAnsweredExternalComunicationNotification({
    questionOwner,
    serviceProtocol,
  }: EmitAnsweredExternalComnunicationNotificationProps) {
    externalComunicationsocketConnection.emit('externalComunicationAnswered', {
      questionOwner,
      serviceProtocol
    })
  }

  function emitAskedExternalComunicationNotification({
    profiles,
    serviceProtocol,
  }: EmitAskedExternalComnunicationNotificationProps) {
    externalComunicationsocketConnection.emit('externalComunicationAsked', {
      profiles,
      serviceProtocol
    })
  }

  function emitClosedExternalComunicationNotification({
    profiles,
    serviceProtocol,
  }: EmitClosedExternalComnunicationNotificationProps) {
    externalComunicationsocketConnection.emit('externalComunicationClosed', {
      profiles,
      serviceProtocol
    })
  }

  return (
    <ExternalComunicationsSocketContext.Provider value={{
      externalComunicationsocketConnection,
      emitCreatedExternalComunicationNotification,
      emitAnsweredExternalComunicationNotification,
      emitAskedExternalComunicationNotification,
      emitClosedExternalComunicationNotification
    }}>
      {children}
    </ExternalComunicationsSocketContext.Provider>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Customer } from "./_types/Customer";

export interface GetCustomersReportResponse {
  customers: Customer[]
}

export async function getCustomersReport() {
  try {
    const response = await api.get<GetCustomersReportResponse>('/customers/report')

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Box, Button, Flex, Icon, Input, Select, Tooltip } from "@chakra-ui/react"
import { useSearchParams } from "hooks/useSearchParams"
import { useForm } from "react-hook-form"
import { FaInfoCircle, FaSearch, FaTimes } from "react-icons/fa"
import { useHistory } from "react-router-dom"

export type Modal = 'AÉREO' | 'RODOVIÁRIO'

interface RouteNetworksTableFiltersSchema {
  search: string
  source_branch: string
  destination_branch: string
  shipping: string
  departure_date: string
  arrival_date: string
  modal: Modal | ''
}

export function RouteNetworksTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const search = searchParams.get('search')
  const source_branch = searchParams.get('source_branch')
  const destination_branch = searchParams.get('destination_branch')
  const departure_date = searchParams.get('departure_date')
  const arrival_date = searchParams.get('arrival_date')
  const shipping = searchParams.get('shipping')
  const modal = searchParams.get('modal') ?? ''

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<RouteNetworksTableFiltersSchema>({
    defaultValues: {
      search: search ?? '',
      source_branch: source_branch ?? '',
      destination_branch: destination_branch ?? '',
      departure_date: departure_date ?? '',
      arrival_date: arrival_date ?? '',
      shipping: shipping ?? '',
      modal: modal as Modal | '',
    }
  })

  async function handleFilterVaccines(data: RouteNetworksTableFiltersSchema) {
    if (data.search) {
      searchParams.set('search', data.search)
    } else {
      searchParams.delete('search')
    }

    if (data.source_branch) {
      searchParams.set('source_branch', data.source_branch)
    } else {
      searchParams.delete('source_branch')
    }
    if (data.destination_branch) {
      searchParams.set('destination_branch', data.destination_branch)
    } else {
      searchParams.delete('destination_branch')
    }
    if (data.departure_date) {
      searchParams.set('departure_date', data.departure_date)
    } else {
      searchParams.delete('departure_date')
    }
    if (data.arrival_date) {
      searchParams.set('arrival_date', data.arrival_date)
    } else {
      searchParams.delete('arrival_date')
    }
    if (data.modal) {
      searchParams.set('modal', data.modal)
    } else {
      searchParams.delete('modal')
    }
    if (data.shipping) {
      searchParams.set('shipping', data.shipping)
    } else {
      searchParams.delete('shipping')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('search')
    searchParams.delete('source_branch')
    searchParams.delete('destination_branch')
    searchParams.delete('departure_date')
    searchParams.delete('arrival_date')
    searchParams.delete('modal')
    searchParams.delete('shipping')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterVaccines)}
    >
      <Flex
        flexWrap="wrap"
        gap="2"
      >
        <Input
          {...register("search")}
          placeholder="Buscar por palavra"
          size="sm"
          rounded="md"
          w={["full", "full", "min"]}
        />
        <Input
          {...register("shipping")}
          placeholder="Transportadora"
          size="sm"
          rounded="md"
          w={["full", "full", "min"]}
        />

        <Input
          {...register("source_branch")}
          placeholder="Base de origem"
          size="sm"
          rounded="md"
          w={["full", "full", "min"]}
        />
        <Input
          {...register("destination_branch")}
          placeholder="Base de destino"
          size="sm"
          rounded="md"
          w={["full", "full", "min"]}
        />

        <Select
          {...register("modal")}
          defaultValue=""
          size="sm"
          rounded="md"
          w={["full", "full", "200px"]}
        >
          <option value="">Todos modais</option>
          <option value="AÉREO">AÉREO</option>
          <option value="RODOVIÁRIO">RODOVIÁRIO</option>

        </Select>

        <Flex direction='row' >
          <Input
            {...register("departure_date")}
            type="date"
            size="sm"
            rounded="md"
            w={["full", "full", "min"]}
          />
          <Tooltip label='Data de saída'>
            <Box as="span" ml="2">
              <Icon as={FaInfoCircle} />
            </Box>
          </Tooltip>
        </Flex>
        <Flex direction='row' >
          <Input
            {...register("arrival_date")}
            type="date"
            size="sm"
            rounded="md"
            w={["full", "full", "min"]}
          />
          <Tooltip label='Data de chegada'>
            <Box as="span" ml="2">
              <Icon as={FaInfoCircle} />
            </Box>
          </Tooltip>
        </Flex>
        <Button
          size="sm"
          leftIcon={<FaSearch />}
          lineHeight="1"
          type="submit"
          w={["full", "full", "min"]}
        >
          Filtrar resultados
        </Button>

        <Button
          size="sm"
          leftIcon={<FaTimes />}
          lineHeight="1"
          variant="ghost"
          type="button"
          onClick={handleRemoveFilters}
          w={["full", "full", "min"]}
        >
          Remover filtros
        </Button>
      </Flex>
    </Flex>
  )

}

import { Box, Button, Divider, Flex, Heading, Spinner, Text } from "@chakra-ui/react"

import { useMaintenanceServices } from "hooks/services/maintenance/useMaintenanceServices"
import { Link, useLocation, useParams } from "react-router-dom"
import { StartMaintenanceForm } from "./components/StartMaintenanceForm"

interface Params {
  service_id: string
}

export function StartMaintenanceServicePage() {

  const { service_id } = useParams<Params>()

  const {
    data: maintenanceServices, isFetching: isMaintenanceServicesFetching
  } = useMaintenanceServices({
    queryParams: { maintenance_service: service_id }
  })

  const location = useLocation()
  const resState = location.state as { path?: string }
  const hasPathRedirect = resState?.path !== undefined && resState?.path !== null

  return (

      <Box
        p={["2", "4"]}
        bg="white"
        borderRadius="8px"
      >
        <StartMaintenanceForm serviceId={service_id} />

        <Divider mt={4} />

        <Flex
          gap={4}
          direction="column"
        >
          <Heading mt={4} size="md">Manutenções em andamento/finalizadas</Heading>

          {isMaintenanceServicesFetching ? (
            <Spinner />
          ) : (
            <>
              {maintenanceServices?.map(maintenance => {
                return (
                  <Box
                    p="4"
                    w="full"
                    boxShadow="base"
                    borderRadius="8px"
                    key={maintenance.id}
                  >
                    <Flex
                      direction="column"
                      gap={2}
                    >
                      <Text>Temperatura de chegada: {maintenance.maintenance_arrival_temperature}º</Text>
                      <Text>Tipo de manuntenção: {maintenance.maintenance_type}</Text>
                      <Flex
                        w="full"
                        justifySelf="flex-end"
                      >
                        {maintenance.status === 'GOING' && (
                          <Button
                            as={Link}
                            to={hasPathRedirect ? resState?.path :`/servico/manutencao/finalizar/${maintenance.id}`}
                            colorScheme="blue"
                            w="full"
                          >
                            Finalizar
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  </Box>
                )
              })}
            </>
          )}
        </Flex>
      </Box>

  )
}

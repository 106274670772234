import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface AddAttachmentInContingencyProps {
  contingencyId: string
  attachmentId: string
}

export async function addAttachmentInContingency({
  contingencyId,
  attachmentId
}: AddAttachmentInContingencyProps) {
  try {
    const response = await api.patch(`/contingencies/${contingencyId}/attachments/${attachmentId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

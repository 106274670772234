import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateAuditBody {
  scheduledDate: string
  type: string
  customerId: string
  collaboratorType: string
  collaborator: string
  regionalId: string
}

interface CreateAuditProps {
  body: CreateAuditBody
}

export async function createAudit({
  body
}: CreateAuditProps) {
  try {
    const response = await api.post(`/audits`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface DismissalDocsInternCltBody {
  isDocsVerified: boolean
  isLegalRiskVerified: boolean
  signedDocs: string[]
  isOperationLeaderAware: boolean
  isWithNotice: boolean
  lastWorkDate: string
  isDismissalSignedByCollaborator: string
}

interface DismissalDocsInternCltProps {
  body: DismissalDocsInternCltBody
  internCltId: string
}

export async function dismissalDocsInternClt({
  body,
  internCltId
}: DismissalDocsInternCltProps) {
  try {
    const response = await api.put(`/intern-clts/${internCltId}/dismissal-docs`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

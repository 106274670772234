import { Button, Flex, FormLabel, Icon, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr, Text, Link } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Options, Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useCurrency } from "react-hook-currency";
import { useFormContext, useWatch } from "react-hook-form";
import { FiFile } from "react-icons/fi";
import { extrasAndDiscountsTypesOptions } from "utils/CustomLists/extrasAndDiscountsTypesOptions";
import { serviceTypeOptions } from "utils/CustomLists/serviceTypesOptions";
import { handleChangeUrl } from "utils/handleChangeUrl";

export interface ExtrasAndDiscountsFormInputs {
  customer_id: string
  type: 'EXTRA' | 'DESCONTO'
  service_type: 'EXPRESS' | 'BUSINESS'
  protocol: number
  route: string
  description: string
  value: string
  attachment: FileList
  occurrence_date: string
}

interface ExtrasAndDiscountsFormProps {
  customerOptions: Options[]
  businessBudgetRoutesOptions: Options[]
  isFetchingBusinessBudgetRoutesData: boolean
  slug: string
  attachmentPreview?: string
}

export function ExtrasAndDiscountsForm({
  customerOptions,
  businessBudgetRoutesOptions,
  isFetchingBusinessBudgetRoutesData,
  slug,
  attachmentPreview
}: ExtrasAndDiscountsFormProps) {

  const isViewPage = slug === "view"
  const isUpdatePage = slug === 'update'

  const { onChange, format: currencyFormat } = useCurrency({
    style: 'decimal'
  })

  const {
    control,
    register,
    formState: {
      errors
    }
  } = useFormContext<ExtrasAndDiscountsFormInputs>()


  const [serviceType, attachment] = useWatch({
    control,
    name: ['service_type', 'attachment']
  })

  const isExpressServiceType = serviceType === 'EXPRESS'
  const isBusinessServiceType = serviceType === 'BUSINESS'

  return (
    <Flex
      direction='column'
      gap={4}
    >
      <Select
        {...register('customer_id')}
        name='customer_id'
        options={customerOptions}
        placeholder='Selecione uma opção...'
        label='Cliente'
        error={errors.customer_id}
        isDisabled={isViewPage}
        required
      />

      <Select
        {...register('type')}
        name='type'
        options={extrasAndDiscountsTypesOptions}
        placeholder='Selecione uma opção...'
        label='Tipo'
        error={errors.type}
        isDisabled={isViewPage}
        required
      />

      <Select
        {...register('service_type')}
        name='service_type'
        options={serviceTypeOptions}
        placeholder='Selecione uma opção...'
        label='Tipo de serviço'
        error={errors.service_type}
        isDisabled={isViewPage}
        required
      />

      {isBusinessServiceType && (
        <Input
        {...register('occurrence_date')}
        name='occurrence_date'
        label='Data de ocorrência'
        type='date'
        error={errors.occurrence_date}
        required
        isDisabled={isViewPage}
      />
      )}
      {isExpressServiceType && (
        <Input
          {...register('protocol')}
          name='protocol'
          type='number'
          label='Protocolo'
          error={errors.protocol}
          isDisabled={isViewPage}
          required
        />
      )}

      {isBusinessServiceType && (
        isFetchingBusinessBudgetRoutesData ? (
          <Spinner />
        ) : (
          <Select
            {...register('route')}
            name='route'
            options={businessBudgetRoutesOptions}
            label='Rota'
            error={errors.route}
            placeholder='Selecione uma opção...'
            isDisabled={isViewPage}
            required
          />
        )
      )}

      <TextArea
        {...register('description')}
        name='description'
        label='Descrição'
        error={errors.description}
        isDisabled={isViewPage}
        required
      />

      <Input
        {...register('value')}
        name='value'
        label='Valor'
        onChange={onChange}
        defaultValue={currencyFormat('000')}
        error={errors.value}
        isDisabled={isViewPage}
        required
      />

      {!isViewPage && (
        <Input
          {...register('attachment')}
          name='attachment'
          type='file'
          hidden
          label='Anexar'
          isDisabled={isViewPage}
        >
          <Button
            leftIcon={<Icon as={FiFile} />}
            as={FormLabel}
            htmlFor='attachment'
            _hover={{
              cursor: 'pointer'
            }}
            size='sm'
            isDisabled={isViewPage}
          >
            Escolha um arquivo
          </Button>
        </Input>
      )}

      {attachment && (
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Anexo</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(attachment).map(([key, file]) => {
                return (
                  <Tr key={key}>
                    <Th >{file.name} </Th>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {(isViewPage || isUpdatePage) && attachmentPreview && (
        <Link
          href={handleChangeUrl(attachmentPreview)}
          isExternal
        >
          <Text as='b'>Visualizar anexo</Text>
        </Link>
      )}

    </Flex>
  )
}

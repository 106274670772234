import { Divider, Td, Tr } from "@chakra-ui/react";
import { GetAuditResponse } from "api/audits/getAudit";

interface AuditOperationalTableRowProps {
  audit: GetAuditResponse
}

export function AuditOperationalTableRow({
  audit
}: AuditOperationalTableRowProps) {
  return (
    <>
      {audit?.audit?.operationalAudits?.map((auditOperational) => {
        return (
          <>
            <Tr>
              <Td>Protocolo do Serviço</Td>
              <Td isNumeric>{auditOperational.serviceProtocol}</Td>
            </Tr>
            <Tr>
              <Td>Insumos sem divergência?</Td>
              <Td isNumeric>{auditOperational.isWithNonDivergentInputs ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Horário de coleta dentro do bloco de horário?*</Td>
              <Td isNumeric>{auditOperational.collectHourInsideHourBlock ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Foto da caixa na coleta?</Td>
              <Td isNumeric>{auditOperational.hasBoxPhotoInCollect ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Foto da declaração de conteúdo na coleta?</Td>
              <Td isNumeric>{auditOperational.hasContentDeclarationInCollect ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Declaração de conteúdo assinada na coleta?</Td>
              <Td isNumeric>{auditOperational.isContentDeclarationSignedInCollect ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Declaração de conteúdo preenchida na coleta?</Td>
              <Td isNumeric>{auditOperational.isContentDeclarationFilledInCollect ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Declaração de conteúdo com carimbo na coleta?</Td>
              <Td isNumeric>{auditOperational.isContentDeclarationStampedInCollect ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Lançamento feito pelo coletador de origem?</Td>
              <Td isNumeric>{auditOperational.isReleaseMadeBySourceCollector ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Horário de entrega dentro do bloco de horário?</Td>
              <Td isNumeric>{auditOperational.deliveryHourInsideHourBlock ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Foto da caixa na entrega?</Td>
              <Td isNumeric>{auditOperational.hasBoxPhotoInDelivery ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Foto da declaração de conteúdo na entrega?</Td>
              <Td isNumeric>{auditOperational.hasContentDeclarationInDelivery ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Declaração de conteúdo assinada na entrega?</Td>
              <Td isNumeric>{auditOperational.hasBoxPhotoInDelivery ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Tr>
              <Td>Lançamento feito pelo coletador de destino?</Td>
              <Td isNumeric>{auditOperational.isReleaseMadeByDestinationCollector ? 'SIM' : 'NÃO'}</Td>
            </Tr>
            <Divider my="6" borderColor="gray.700" />
          </>
        )
      })}
    </>
  )
}

import { Button, Flex, Spinner, Stack } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { Input } from "../../../../../components/Inputs/Input"
import { Select } from "../../../../../components/Inputs/SelectInput"
import { CustomerProps } from "../../../../../contexts/CustomerContext";

interface IConsultOptionsProps {
  key: string;
  value: string;
}

interface IFormProps {
  consult_type: string;
  customer_id?: string;
  protocol?: number;
  start_filter?: Date | null;
  end_filter?: Date | null;
  sourceCity?: string;
  destinationCity?: string;
}

interface IConsultServicesFormProps {
  consultOptions: IConsultOptionsProps[]
  customers?: CustomerProps[]
  isCustomersLoading: boolean
  isConsultByProtocol: boolean
  isConsultByCustomer: boolean
  isConsultByCustomerWithDate: boolean
  isConsultByDate: boolean
  isUserTypeCustomer?: boolean
  isCitiesLoading?: boolean
  cities?: CitiesProps[]
  isConsultByCitiesWithDate?: boolean
}

export function ConsultServicesForm({
  consultOptions,
  customers,
  isCustomersLoading,
  isConsultByProtocol,
  isConsultByCustomer,
  isConsultByCustomerWithDate,
  isConsultByDate,
  isUserTypeCustomer = false,
  cities,
  isCitiesLoading = false,
  isConsultByCitiesWithDate = false
}: IConsultServicesFormProps) {

  const { register, unregister, formState: { errors, isSubmitting } } = useFormContext<IFormProps>()

  function unregisterFields() {
    unregister("protocol")
    unregister("customer_id", {
      keepDefaultValue: isUserTypeCustomer,
    })
    unregister("start_filter")
    unregister("end_filter")
  }

  return (
    <Flex direction="column" gap="4">
      <Select
        {...register("consult_type")}
        name="consult_type"
        label="Tipo de consulta"
        placeholder="Selecione uma opção..."
        onClick={unregisterFields}
        error={errors.consult_type}
        required
      >
        {consultOptions.map(option => {
          return (
            <option key={option.key} value={option.value}>{option.value}</option>
          )
        })}
      </Select>
      {isConsultByProtocol && (
        <Input
          {...register("protocol")}
          name="protocol"
          label="Protocolo"
          type="number"
          error={errors.protocol}
          required
        />
      )}
      {(isConsultByCustomerWithDate || isConsultByCustomer) && (
        isCustomersLoading ? (
          <Spinner />
        ) : (
          <Select
            {...register("customer_id")}
            name="customer_id"
            label="Cliente"
            error={errors.customer_id}
            isDisabled={isUserTypeCustomer}
            customers={customers}
            placeholder="Selecione uma opção..."
            required
          />
        )
      )}
      {(isConsultByCitiesWithDate) && (
        isCitiesLoading ? (
          <Spinner />
        ) : (
          <Stack direction={['column', 'column', 'row']} spacing="24px">
            <Select
              {...register("sourceCity")}
              name="sourceCity"
              label="Cidade de Origem"
              error={errors.sourceCity}
              citiesBack={cities}
              placeholder="Selecione uma opção..."
              required
            />
            <Select
              {...register("destinationCity")}
              name="destinationCity"
              label="Cidade de Destino"
              error={errors.destinationCity}
              citiesBack={cities}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>
        )
      )}
      {(isConsultByDate || isConsultByCustomerWithDate || isConsultByCitiesWithDate) && (
        <Stack direction={['column', 'column', 'row']} spacing="24px">
          <Input
            {...register("start_filter")}
            name="start_filter"
            label="Data inicial do filtro"
            error={errors.start_filter}
            tooltipLabel="Informação baseada na data inicial da coleta/rota"
            type="date"
            required
          />
          <Input
            {...register("end_filter")}
            name="end_filter"
            label="Data final do filtro"
            error={errors.end_filter}
            tooltipLabel="Informação baseada na data inicial da coleta/rota"
            type="date"
            required
          />
        </Stack>
      )}
      <Flex flex="1" justify={["center", "center", "flex-end"]}>
        <Button
          type="submit"
          colorScheme="blue"
          w={["full", "full", "min"]}
          isLoading={isSubmitting}
        >
          Consultar
        </Button>
      </Flex>
    </Flex>
  )
}

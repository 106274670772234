import { Box, Flex, Icon, IconProps } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface NotificationIconProps extends IconProps {
  icon: IconType;
  isRead: boolean
}

export function NotificationIcon({ icon, isRead, ...rest }: NotificationIconProps) {
  return (
    <Flex position="relative">
      <Icon color="#38C3FA" as={icon} {...rest} />
      {!isRead && (
        <Box position="absolute" right="0" top="-1" rounded="full" w="6px" h="6px" bg="red" />
      )}
    </Flex>
  )
}

import { Button, ButtonProps, Icon, useMediaQuery } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { Link, LinkProps } from 'react-router-dom'

interface IFormActionButtonProps extends ButtonProps {
  href: LinkProps['to']
  action: string
  icon?: IconType
  width?: string
}

export function FormActionButton({
  href,
  action,
  icon,
  width,
  ...rest
}: IFormActionButtonProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  return (
    <Link to={href} style={ width ? { width: `${width}` } : {}}>
      <Button type="button" colorScheme="blue" {...rest}>
        {isWideVersion ? action : icon ? <Icon as={icon} /> : action}
      </Button>
    </Link>
  )
}

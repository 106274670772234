import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { getAdvertences } from "api/advertences/getAdvertences"
import { useAuth } from "hooks/auth/useAuth"
import { useEffect } from "react"
import { FaExchangeAlt, FaPlus } from "react-icons/fa"
import { useInfiniteQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { AdvertencesKanbanColumn } from "./components/AdvertencesKanbanColumn"
import { CreateAdvertence } from "./components/CreateAdvertence"

export function AdvertencesKanban(){

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const userCanCreateAdvertence = userLogged?.permissions?.includes('create-advertence')
  const userCanViewAdvertences = userLogged?.permissions?.includes('view-advertences')

  useEffect(() => {
    if (!userCanViewAdvertences) redirect('/')
  }, [redirect, userCanViewAdvertences])

  const {
    isOpen: isCreateAdvertenceModalOpen,
    onOpen: onOpenCreateAdvertenceModal,
    onClose: onCloseCreateAdvertenceModal
  } = useDisclosure()


  const {
    data: awaitingApplicationAdvertencesResult,
    isLoading: isAwaitingApplicationAdvertencesResultLoading,
    hasNextPage: hasNextPageAwaitingApplicationAdvertences,
    fetchNextPage: fetchNextPageAwaitingApplicationAdvertences,
    isFetchingNextPage: isFetchingNextPageAwaitingApplicationAdvertences,
  } = useInfiniteQuery({
    queryKey: ['awaiting-application-advertences'],
    queryFn: ({ pageParam = 1 }) => getAdvertences({
      currentPage: pageParam,
      pageSize: '10',
      status: 'awaiting-application',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: parentverificationAdvertencesResult,
    isLoading: isParentVerificationAdvertencesResultLoading,
    hasNextPage: hasNextPageParentVerificationAdvertences,
    fetchNextPage: fetchNextPageParentVerificationAdvertences,
    isFetchingNextPage: isFetchingNextPageParentVerificationAdvertences,
  } = useInfiniteQuery({
    queryKey: ['parent-verification-advertences'],
    queryFn: ({ pageParam = 1 }) => getAdvertences({
      currentPage: pageParam,
      pageSize: '10',
      status: 'parent-verification',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: cancelledAdvertencesResult,
    isLoading: isCancelledAdvertencesResultLoading,
    hasNextPage: hasNextPageCancelledAdvertences,
    fetchNextPage: fetchNextPageCancelledAdvertences,
    isFetchingNextPage: isFetchingNextPageCancelledAdvertences,
  } = useInfiniteQuery({
    queryKey: ['cancelled-advertences'],
    queryFn: ({ pageParam = 1 }) => getAdvertences({
      currentPage: pageParam,
      pageSize: '10',
      status: 'cancelled',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })
  const {
    data: doneAdvertencesResult,
    isLoading: isDoneAdvertencesResultLoading,
    hasNextPage: hasNextPageDoneAdvertences,
    fetchNextPage: fetchNextPageDoneAdvertences,
    isFetchingNextPage: isFetchingNextPageDoneAdvertences,
  } = useInfiniteQuery({
    queryKey: ['done-advertences'],
    queryFn: ({ pageParam = 1 }) => getAdvertences({
      currentPage: pageParam,
      pageSize: '10',
      status: 'done',

    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsAdvertences = [
    {
      title: 'Aguardando aplicação',
      advertences: awaitingApplicationAdvertencesResult?.pages.flatMap((page) => page.advertences) || [],
      advertencesCount: awaitingApplicationAdvertencesResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageAwaitingApplicationAdvertences,
      fetchNextPage: fetchNextPageAwaitingApplicationAdvertences,
      isFetchingNextPage: isFetchingNextPageAwaitingApplicationAdvertences,
      isLoading: isAwaitingApplicationAdvertencesResultLoading,
    },
    {
      title: 'Aguardando verificação da matriz',
      advertences: parentverificationAdvertencesResult?.pages.flatMap((page) => page.advertences) || [],
      advertencesCount: parentverificationAdvertencesResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageParentVerificationAdvertences,
      fetchNextPage: fetchNextPageParentVerificationAdvertences,
      isFetchingNextPage: isFetchingNextPageParentVerificationAdvertences,
      isLoading: isParentVerificationAdvertencesResultLoading,
    },
    {
      title: 'Canceladas',
      advertences: cancelledAdvertencesResult?.pages.flatMap((page) => page.advertences) || [],
      advertencesCount: cancelledAdvertencesResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageCancelledAdvertences,
      fetchNextPage: fetchNextPageCancelledAdvertences,
      isFetchingNextPage: isFetchingNextPageCancelledAdvertences,
      isLoading: isCancelledAdvertencesResultLoading,
    },
    {
      title: 'Concluídas',
      advertences: doneAdvertencesResult?.pages.flatMap((page) => page.advertences) || [],
      advertencesCount: doneAdvertencesResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageDoneAdvertences,
      fetchNextPage: fetchNextPageDoneAdvertences,
      isFetchingNextPage: isFetchingNextPageDoneAdvertences,
      isLoading: isDoneAdvertencesResultLoading,
    },
  ]
  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Advertências</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/advertencias')}
          />
           {userCanCreateAdvertence && (
          <IconButton
            aria-label="Criar Advertência"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onOpenCreateAdvertenceModal}
          />
        )}
        </Flex>
        <Modal
          isOpen={isCreateAdvertenceModalOpen}
          onClose={onCloseCreateAdvertenceModal}
          isCentered
        >
          <ModalOverlay />
          <CreateAdvertence
            onCloseModal={onCloseCreateAdvertenceModal}
          />
        </Modal>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsAdvertences?.map((advertence, index) => {
          return (
            <AdvertencesKanbanColumn
              key={index}
              advertence={advertence}
            />
          )
        })}
      </Flex>
    </Box>
  )

}

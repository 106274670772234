import { Button, Flex, FormLabel, Icon, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { Input } from "components/Inputs/Input"
import { useOccurrenceFunctions } from "hooks/occurrences/useOccurrenceFunctions"
import { useSwal } from "hooks/swal/useSwal"
import { useToastify } from "hooks/toastify/useToastify"
import { useForm } from "react-hook-form"
import { FiFile } from "react-icons/fi"
import { useHistory } from "react-router-dom"
import * as yup from "yup"

interface OccurrenceAttachPhotoModalInputs {
  attachment_photo: FileList
}

interface OccurrenceAttachPhotoModalProps {
  isOccurrenceAttachPhotoModalOpen: boolean
  onCloseOccurrenceAttachPhotoModal: () => void
  occurrenceId: string | null
}

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png"
]

function validateFileType(file: FileList) {
  if (file?.length) {
    return supportedFileFormats.includes(file[0].type)
  }

  return true
}

function validateFileSize(file: FileList) {
  if (file?.length) {
    return file[0].size / 1024 / 1024 <= 15
  }

  return true
}

function validateHasFile(file: FileList) {
  if (!file?.length) return false

  return true
}

const occurrenceAttachPhotoModalSchema = yup.object().shape({
  attachment_photo: yup.mixed()
    .test("length", "Campo obrigatório", value => validateHasFile(value))
    .test("type", "São suportados apenas os formatos de imagem: .jpeg, .pjpeg, jpg, png", value => validateFileType(value))
    .test("fileSize", "É suportado o upload de imagem somente até 10Mb", value => validateFileSize(value)),
})

export function OccurrenceAttachPhotoModal({
  isOccurrenceAttachPhotoModalOpen,
  onCloseOccurrenceAttachPhotoModal,
  occurrenceId
}: OccurrenceAttachPhotoModalProps) {

  const {
    register,
    watch,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<OccurrenceAttachPhotoModalInputs>({
    resolver: yupResolver(occurrenceAttachPhotoModalSchema)
  })

  const attachmentPhoto = watch("attachment_photo")

  const attachmentPhotoPreview = attachmentPhoto
    ? Object.entries(attachmentPhoto).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const {
    updateOccurrenceAttachmentPhoto: {
      mutateAsync: updateOccurrenceAttachmentPhoto
    }
  } = useOccurrenceFunctions()

  const { goBack } = useHistory()
  const { confirmMessage, standardMessage } = useSwal()
  const { errorMessage } = useToastify()

  async function handleAttachOccurrencePhoto(
    { attachment_photo }: OccurrenceAttachPhotoModalInputs
  ) {

    if (!occurrenceId) {
      return errorMessage("Não foi possível encontrar a ocorrência.")
    }

    const hasAttachOccurrencePhoto = await confirmMessage({
      title: "Deseja adicionar a foto selecionada à ocorrência?",
    })


    if (hasAttachOccurrencePhoto) {
      const formData = new FormData()
      formData.append('attachment_photo', attachment_photo[0])

      await updateOccurrenceAttachmentPhoto({ occurrenceId, input: formData }, {
        onSuccess: () => goBack()
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }

  }

  function handleCloseModalAndGoBackToServiceDetails() {
    onCloseOccurrenceAttachPhotoModal()
    goBack()
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOccurrenceAttachPhotoModalOpen}
      onClose={onCloseOccurrenceAttachPhotoModal}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleAttachOccurrencePhoto)}>
        <ModalHeader>Incluir foto na ocorrência</ModalHeader>
        <ModalBody>
          <Flex direction="column" gap={6}>
            <Input
              {...register("attachment_photo")}
              name="attachment_photo"
              type="file"
              accept={supportedFileFormats.join(", ")}
              hidden
              label="Foto (Opcional)"
              error={errors.attachment_photo}
            >
              <Button
                leftIcon={<Icon as={FiFile} />}
                as={FormLabel}
                htmlFor="attachment_photo"
                _hover={{
                  cursor: "pointer"
                }}
              >Escolha uma imagem</Button>
            </Input>
            {attachmentPhotoPreview && (
              <Image
                objectFit="contain"
                maxWidth="300px"
                maxHeight="500px"
                src={attachmentPhotoPreview}
              />
            )}
          </Flex>

        </ModalBody>

        <ModalFooter>
          <Stack direction="row" spacing={6}>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
            <Button
              variant="ghost"
              onClick={handleCloseModalAndGoBackToServiceDetails}
            >
              Cancelar
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>


    </Modal>
  )
}

import { Td, Tr } from "@chakra-ui/react";
import { ReactNode } from "react";

interface BusinessBudgetLogTrProps {
  name: string
  prevValue: string | ReactNode
  newValue: string | ReactNode
  updatedBy: string
  updatedAt: string
}

export function PriceLogLine({ name, prevValue, newValue, updatedAt, updatedBy }: BusinessBudgetLogTrProps) {
  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{prevValue}</Td>
      <Td>{newValue}</Td>
      <Td>{updatedBy}</Td>
      <Td>{updatedAt}</Td>
    </Tr>
  )
}

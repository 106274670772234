import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { createAddressCsv } from "api/addresses/createAddressCsv"
import { useToastify } from "hooks/toastify/useToastify"
import { useForm, useWatch } from "react-hook-form"
import { FaExternalLinkAlt, FaFileArchive, FaFileImport } from "react-icons/fa"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { validateHasFile } from "utils/fileValidation"
import { Address } from "hooks/address/dtos/Address"
import { formatDate } from "utils/DateFunctions/formatDate"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"

interface CreateAddressCsvProps {
  onCloseModal: () => void
  addresses: Address[]
}

function formatValuesCsvExample(addresses: Address[]) {
  return addresses?.map(address => {
    return {
      'Situação': address.situation,
      'Cliente': address.customerIDAddress.trading_firstname,
      'Tipo de cliente': address.type,
      'Nome fantasia': address.trading_name,
      'CNPJ/CPF': address.cnpj_cpf,
      'Setor/Unidade': address.branch,
      'Nome do responsável': address.responsible_name,
      'Email do responsável': address.responsible_email ?? '-',
      'Telefone do responsável': address.responsible_telephone ?? '-',
      'Cep': address.cep,
      'Rua': address.street,
      'Número': address.number,
      'Complemento': address.complement ?? '-',
      'Bairro': address.neighborhood,
      'Estado': address.state,
      'Cidade': address.cityIDAddress.name,
      'Ponto de referência': address.reference_point ?? '-',
      'ICMS': address.icms,
      'Prazo máximo': address.deadline,
      'Horário abertura (segunda a sexta)': formatDate.handle(address.business_open, "DateOnlyWithHourMinute"),
      'Horário fechamento (segunda a sexta)': formatDate.handle(address.business_close, "DateOnlyWithHourMinute"),
      'Horário de abertura (sábados)': address.saturday_open ? formatDate.handle(address.saturday_open, "DateOnlyWithHourMinute") : '-',
      'Horário fechamento (sábados)': address.saturday_close ? formatDate.handle(address.saturday_close, "DateOnlyWithHourMinute") : '-',
      'Horário abertura (domingos)': address.sunday_open ? formatDate.handle(address.sunday_open, "DateOnlyWithHourMinute") : '-',
      'Horário fechamento (domingos)': address.sunday_close ? formatDate.handle(address.sunday_close, "DateOnlyWithHourMinute") : '-',
      'Possui embarque/desembarque próprio': address.has_own_board_landing ? 'SIM' : 'NÃO',
      'Observações': address.observation
    }
  })
}

interface CreateAddressSchema {
  csv: FileList
}

const createAddressSchema = yup.object({
  csv: yup.mixed().test(value => validateHasFile(value)),
})

export function CreateAddressCsv({ onCloseModal, addresses }: CreateAddressCsvProps) {
  const { promiseMessage } = useToastify()

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateAddressSchema>({
    resolver: yupResolver(createAddressSchema),
  })



  const csvAttachment = useWatch({
    control,
    name: 'csv'
  })

  const attachmentCsvPreview = csvAttachment
    ? Object.entries(csvAttachment).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const queryClient = useQueryClient()

  const { mutateAsync: createAddressCsvFn } = useMutation({
    mutationFn: createAddressCsv,
    onSuccess() {
      queryClient.invalidateQueries(['addresses'])
      onCloseModal()
    }
  })

  async function handleCreateAddress({ csv }: CreateAddressSchema) {
    const formData = new FormData()
    formData.append("csv", csv[0])

    await promiseMessage(createAddressCsvFn({
      body: formData
    }), 'Endereços criados! 🎉')
  }

  const addressCsvExampleHeaders = addresses && addresses?.length
    ? Object.keys(formatValuesCsvExample(addresses)[0])?.map(key => {
      return { label: key, key }
    })
    : [{ label: '', key: '' }]

  const csvExampleProps = {
    data: addresses ? formatValuesCsvExample([addresses[0]]) : [],
    headers: addressCsvExampleHeaders,
    filename: `exemplo-cadastro-endereço`,
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Criar Endereços</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as='form'
        onSubmit={handleSubmit(handleCreateAddress)}
      >

        <Stack
          direction="column"
          w="full"
          mt="3"
        >

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >

            <FormControl isInvalid={!!errors?.csv}>
              <FormLabel fontSize="sm">
                Arquivo csv
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('csv')}
                name="csv"
                id="csv"
                type="file"
                hidden
                accept=".csv"

              />
            </FormControl>
            <Button
              as={FormLabel}
              htmlFor="csv"
              lineHeight="1"
              leftIcon={<FaFileImport />}
              size="sm"
              w="min"
              cursor="pointer"
              border={!!errors?.csv && '2px solid'}
              borderColor={!!errors?.csv && 'red.500'}
            >
              Anexar csv
            </Button>
            {attachmentCsvPreview && (
              <Table size="sm">
                <Tbody>

                  <Tr>
                    <Td fontSize="xs" maxW="100px">{csvAttachment[0].name}</Td>

                    <Td fontSize="xs" isNumeric>

                      <IconButton
                        aria-label="Visualizar anexo"
                        as={Link}
                        size="sm"
                        icon={<FaExternalLinkAlt />}
                        href={URL.createObjectURL(csvAttachment[0])}
                        isExternal
                      />

                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            )}
          </Stack>
        </Stack>
        <Flex
          w="full"
          justify="flex-end"
        >
          <GenerateExcelReportButton
            buttonTitle="Csv de exemplo"
            size='sm'
            w="min"
            leftIcon={<FaFileArchive />}
            variant='outline'
            csvReportProps={csvExampleProps}
          />
        </Flex>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

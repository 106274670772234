import axios from "axios"
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { CostReport, CostReportTransformedData } from "./dtos/CostReport";

interface CostReportQueryParams {
  customer_id: string
  start_filter: string
  end_filter: string
}

type QueryOptions = UseQueryOptions<CostReport[], unknown, CostReportTransformedData[], ["costReport"]>

interface UseCostReportOptions {
  queryOptions?: QueryOptions
  queryParams?: CostReportQueryParams
}

async function generateCostReportReqFunction({
  customer_id,
  start_filter,
  end_filter
}: CostReportQueryParams) {
  try {
    const { data } = await api.get<CostReport[]>(`/report/cost`, {
      params: {
        customer_id,
        start_filter,
        end_filter
      },
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useCostReport(options?: UseCostReportOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery([
    "costReport",
    options.queryParams.customer_id,
    options.queryParams.start_filter,
    options.queryParams.end_filter
  ], () => generateCostReportReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}

import { ServiceStep } from "api/service/_types/Service";

export const serviceStepMap: Record<ServiceStep, string> = {
  toValidateRequestedService: 'Aguardando agendamento',
  toCollectService: 'Agendado',
  collectingService: 'Coletando serviço',
  toValidateCollectAddresses: 'Validando novos endereços de coleta',
  toBoardService: 'Embarcar serviço',
  boardingService: 'Em rota de embarque',
  toBoardValidate: 'Embarcado (validação)',
  toAllocateService: 'Embarcado (alocação)',
  toAvailableService: 'Alocado',
  availableService: 'Aguardando disponibilização',
  toLandingService: 'Disponível',
  landingService: 'Desembarcando serviço',
  toDeliveryService: 'Em rota de entrega',
  toMaintenanceService: 'Em manutenção',
  ongoingMaintenanceService: 'Manutenção em andamento',
  toValidateFiscalRetention: 'Em retenção fiscal',
  cancelledService: 'Cancelado',
  unsuccessService: 'Serviço sem sucesso',
  finishedService: 'Entregue',
  deliveringService: 'Em rota de entrega'
}

import { format } from 'date-fns'

export function reportFormatHourToFront(data: string) {
  if (data !== '-') {
    const hourFormated = format(new Date(data), 'HH:mm')

    return hourFormated
  } else {
    return '-'
  }
}

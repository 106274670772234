import { Box, FormControl, FormLabel, Input, Select, Stack, Text } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { useBudget } from "hooks/budget/useBudget";
import { useCity } from "hooks/city/useCity";
import { useCustomers } from "hooks/customer/useCustomers";
import { FormEvent, useEffect } from "react";
import { useFormContext, useWatch, } from "react-hook-form";
import { budgetFormatArrayToSelect } from "utils/BudgetFunctions/budgetFormatArrayToSelect";
import { CreateSupportSchema } from "../CreateSupport";


export function SingleInvoicingRequestForm() {

  const { register, setValue, control, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const [recipient] = useWatch({
    control,
    name: ['formData.recipient.value']
  })

  const {
    data: customersResult,
  } = useCustomers({
    queryParams: {
      situation: 'ATIVO',
    }
  })

  const {
    cities: { data: cities },
  } = useCity(null, true)

  useEffect(() => {
    if (!!recipient && customersResult) {
      const recipientSelected = customersResult?.find(customer => customer.id === recipient)?.trading_firstname

      setValue(`formData.customer.label`, 'Tomador');
      setValue(`formData.customer.value`, recipientSelected);
      setValue(`formData.customer.type`, 'select-one');
    }
  }, [recipient, setValue, customersResult])

  const {
    budgets: { data: budgets, refetch: refetchBudgets }
  } = useBudget(null, !!recipient, false, recipient)


  useEffect(() => {
    if (!!recipient) refetchBudgets()
  }, [recipient, refetchBudgets])

  const recipients = customersResult?.filter((customer) => customer.cost_center === 'OUT')


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      billingDate: 'Data para faturamento',
      dueDate: 'Data de vencimento',
      invoiceType: 'Tipo de fatura',
      budget: 'Orçamento',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };


  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.recipient} mt={3}>
        <FormLabel fontSize="sm">
          Tomador
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.recipient.value")}
          name="formData.recipient.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('recipient')}
        >
          {recipients?.map((recipient) => {
            return <option key={recipient.id} value={recipient.id}>
              {recipient?.trading_firstname}
            </option>
          })}
        </Select>
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.billingDate}>
            <FormLabel fontSize='sm'>
              Data para faturamento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.billingDate.value')}
              name='formData.billingDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('billingDate')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.dueDate}>
            <FormLabel fontSize='sm'>
              Data de vencimento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.dueDate.value')}
              name='formData.dueDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('dueDate')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.invoiceType} mt={3}>
        <FormLabel fontSize="sm">
          Tipo de fatura
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register("formData.invoiceType.value")}
          name="formData.invoiceType.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('invoiceType')}
        >

          <option value='CTE'>CTE</option>
          <option value='NFs'>NFs</option>

        </Select>
      </FormControl>
      {!!recipient && budgets && (
        <FormControl isInvalid={!!errors.formData?.budget} mt={3}>
          <FormLabel fontSize="sm">
            Orçamento
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Select
            {...register("formData.budget.value")}
            name="formData.budget.value"
            placeholder="Selecione uma opção..."
            size='sm'
            rounded="md"
            onChangeCapture={handleInputChange('budget')}
          >
            {!!budgets &&
              budgetFormatArrayToSelect(budgets, cities || [])?.map((budget) => {
                const budgetFormated = `${budget.source_cities.join(
                  ', ',
                )} > ${budget.destination_cities.join(', ')} | ${budget.service_type
                  } |
            ${budget.vehicle} | ${budget.modal} | ${budget.franchising
                  }KG | GELO SECO: ${budget.gelo_seco} | GELOX: ${budget.gelox
                  } | ${budget.observation}
            `.toUpperCase()
                return (
                  <option key={budget.id}
                    value={budgetFormated}
                  >
                    budgetFormated
                  </option>)
              })
            }
          </Select>
        </FormControl>
      )}

    </Box>
  )
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetOnboardingsResponse } from "api/onboardings/getOnboardings";
import { inviteToOnboarding } from "api/onboardings/inviteToOnboarding";
import { useToastify } from "hooks/toastify/useToastify";
import { getLLMUsers } from "hooks/user/useLLMUsers";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import AsyncSelect from "react-select/async"
import { captalize } from "utils/captalize";
import * as yup from "yup"

interface InviteOnboardingSchema {
  invitedUser: { value: string, label: string, email: string }
}

const inviteOnboardingSchema = yup.object({
  invitedUser: yup.object().required()
})

interface InviteOnboardingPopoverProps {
  onboardingId: string
}

export function InviteOnboardingPopover({
  onboardingId
}: InviteOnboardingPopoverProps) {

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<InviteOnboardingSchema>({
    resolver: yupResolver(inviteOnboardingSchema)
  })

  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string, email: string }>> {
    const response = await getLLMUsers({ situation: 'ATIVO' })

    return response.users
      .filter((user) => (`${user.firstname} ${user.lastname}`).toLowerCase().includes(inputValue.toLocaleLowerCase()))
      .map((user) => {
        return { value: user.id, label: captalize(`${user.firstname} ${user.lastname}`), email: user.email.toLowerCase() }
      })
  }

  const queryClient = useQueryClient()

  const { mutateAsync: inviteToOnboardingFn } = useMutation({
    mutationFn: inviteToOnboarding,
    onSuccess(_data, { onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })
      queryClient.invalidateQueries(['onboardings'])
      queryClient.invalidateQueries({ queryKey: 'inviting-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'training-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'testing-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'finished-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'inactive-onboardings' })
      queryClient.invalidateQueries({ queryKey: ['onboarding', onboardingId]})

      cachedOnboardings.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
                status: 'training',
              }
            }

            return onboarding
          })
        })
      })


    },
  })

  const { promiseMessage } = useToastify()

  async function handleInviteOnboarding(values: InviteOnboardingSchema) {
    await promiseMessage(inviteToOnboardingFn({
      onboardingId,
      body: {
        inviteEmail: values.invitedUser.email
      }
    }), 'Convite enviado! 🎉')
  }


  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleInviteOnboarding)}
    >
      <Heading letterSpacing="tight" size='sm'>Convidar para onboarding</Heading>
      <FormControl isInvalid={!!errors.invitedUser}>
        <FormLabel fontSize="sm">
          Usuário
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Controller
          control={control}
          name="invitedUser"
          render={({ field }) => (
            <AsyncSelect
              {...register('invitedUser')}
              name={field.name}
              onChange={field.onChange}
              value={field.value}
              cacheOptions
              defaultOptions
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  padding: '1px',
                  borderRadius: '6px',
                  border: state.isFocused ? 'none' : '',
                  boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                })
              }}
              noOptionsMessage={() => 'Não há usuários cadastrados ou encontrados com o filtro!'}
              placeholder="Selecione um usuário..."
              loadOptions={promiseOptions}
              isClearable={true}
            />
          )}
        />
      </FormControl>
      <Flex
        mt="6"
        alignItems="center"
        justifyContent="end"
        border="none"
      >
        <Button
          size="sm"
          type="submit"
          colorScheme="blue"
          isLoading={isSubmitting}
        >
          Convidar
        </Button>
      </Flex>
    </Box>
  )
}

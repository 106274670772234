import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { negative_positive } from '../../utils/customLists'


import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { TextArea } from '../../components/Inputs/TextInput'
import { apiCep } from '../../services/cepApiService/cepApi'
import { getBranchById } from '../../utils/BranchFunctions/getBranchById'
import { branchFormatDateToFront } from '../../utils/BranchFunctions/branchFormatDateToFront'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { InputMaskCustom } from '../../components/Inputs/InputMask'
import { handleSwitchMask } from '../../utils/handleChangeMask'
import { toast } from 'react-toastify'
import { useShipping } from '../../hooks/shipping/useShipping'
import { useHub } from '../../hooks/hub/useHub'
import { apiIbge } from '../../services/ibgeApiservice/ibgeApi'
import {
  IIbgeApiCityProps,
  IIbgeApiStatesProps,
} from '../../services/ibgeApiservice/IIbgeApi'

interface FormInputProps {
  nickname: string
  shipping_id: string
  email: string
  hub_id: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  open_on_holidays: string
  holiday_open: string | null
  holiday_close: string | null
  observation: string
}

interface CustomerFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

const customerSchema = yup.object().shape({
  nickname: yup.string().required('Apelido inválido'),
  shipping_id: yup.string().required('Transportadora inválida'),
  email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('E-mail inválido'),
  hub_id: yup.string().required('HUB inválido'),
  cellphone: yup.string().required('Celular inválido'),
  telephone: yup.string().required('Telefone inválido'),
  cep: yup.string().required('CEP inválido'),
  street: yup.string().required('Rua inválida'),
  number: yup.string().required('Número inválido'),
  complement: yup.string().notRequired(),
  neighborhood: yup.string().required('Bairro inválido'),
  state: yup.string().required('Selecione um Estado'),
  city: yup.string().required('Selecione uma Cidade'),
  business_open: yup.string().required('Horário inválido'),
  business_close: yup.string().required('Horário inválido'),
  saturday_open: yup.string().nullable(),
  saturday_close: yup.string().nullable(),
  sunday_open: yup.string().nullable(),
  sunday_close: yup.string().nullable(),
  open_on_holidays: yup.string().required('Campo Obrigatório'),
  holiday_open: yup.string().when('open_on_holiday', { is: 'yes', then: yup.string().required('Horário inválido') }),
  holiday_close: yup.string().when('open_on_holiday', { is: 'yes', then: yup.string().required('Horário inválido') }),
  observation: yup.string(),
})

export function BranchForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: CustomerFormProps) {
  // SELECT LISTS
  const [statesList, setStatesList] = useState<IIbgeApiStatesProps[]>([])
  const [citiesList, setCitiesList] = useState<IIbgeApiCityProps[]>([])
  // ---------------------------------------------------------------------------- //

  // HOUR OPERATION STATES
  const [hasOperationSaturday, setHasOperationSaturday] = useState('')
  const [hasOperationSunday, setHasOperationSunday] = useState('')
  // ---------------------------------------------------------------------------- //

  const [isLoadingPage, setIsLoadingPage] = useState(true)

  // HOOKS
  const {
    shipping: { data: shipping, isLoading: isShippingLoading },
  } = useShipping(null, true, false)
  const {
    hubs: { data: hubs, isLoading: isHubLoading },
  } = useHub(null, true, false)
  // ---------------------------------------------------------------------------- //

  const openOnHolidays = [
    { key: 'yes', value: 'yes', showOption: 'SIM' },
    { key: 'no', value: 'no', showOption: 'NÃO' },
  ]

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(customerSchema),
  })

  // REAL TIME FIELD VALUES
  const selectedState = watch('state')
  const cepValue = watch('cep')
  const selectedCity = watch('city')
  const isOpenOnHolidays = watch('open_on_holidays')
  // ---------------------------------------------------------------------------- //

  const history = useHistory()

  useEffect(() => {
    if (!isShippingLoading && !isHubLoading) {
      setIsLoadingPage(false)
    }
  }, [isShippingLoading, isHubLoading])

  useEffect(() => {
    function run() {
      if (hasOperationSaturday === 'NÃO') {
        setValue('saturday_open', null)
        setValue('saturday_close', null)
      }

      if (hasOperationSunday === 'NÃO') {
        setValue('sunday_open', null)
        setValue('sunday_close', null)
      }
    }
    run()
  }, [setValue, hasOperationSaturday, hasOperationSunday])

  useEffect(() => {
    async function run() {
      const cepSplited = cepValue?.split('-').join('')
      if (
        !cepSplited?.includes('_') &&
        cepSplited !== undefined &&
        cepSplited !== '' &&
        slug === 'adicionar'
      ) {
        try {
          const data = await apiCep.get(`/${cepSplited}/json/`)

          const cityFilteredByCep = data.localidade

          const statesListByCep = await apiIbge.getStates('estados')

          const filteredState = statesListByCep
            .filter((state) => state.sigla === data.uf)
            .map((state) => state.nome)
            .toString()

          setValue('street', data.logradouro)
          setValue('neighborhood', data.bairro)
          setValue('city', cityFilteredByCep.toUpperCase())
          setValue('state', filteredState.toUpperCase())
          setValue('complement', data.complemento)
        } catch {
          toast.error('Erro ao cosultar cep!')
          const data = await getBranchById(id)
          setValue('street', data.street)
          setValue('number', data.number)
          setValue('complement', data.complement)
          setValue('neighborhood', data.neighborhood)
          setValue('city', data.city)
          setValue('state', data.state)
        }
      }
    }

    run()
  }, [setValue, cepValue, slug, id])

  useEffect(() => {
    async function run() {
      const statesList = await apiIbge.getStates('estados')
      setStatesList(statesList)

      const uf = statesList
        .filter((state) => state.nome.toUpperCase() === selectedState)
        .map((state) => state.sigla)
        .toString()

      if (selectedState) {
        const citiesList = await apiIbge.getCities(`/estados/${uf}/municipios`)
        setCitiesList(citiesList)
        setValue('city', selectedCity)
      } else {
        const citiesList = await apiIbge.getCities(`/estados/${uf}/municipios`)
        setCitiesList(citiesList)
      }
    }

    run()
  }, [setValue, selectedState, selectedCity])

  // SET FIELD VALUES
  useEffect(() => {
    async function run() {
      if (slug !== 'adicionar') {
        const data = await getBranchById(id)

        if (data.saturday_open === null) {
          setHasOperationSaturday('NÃO')
        } else {
          setHasOperationSaturday('SIM')
        }

        if (data.sunday_open === null) {
          setHasOperationSunday('NÃO')
        } else {
          setHasOperationSunday('SIM')
        }

        const timeOpenWeek = branchFormatDateToFront(data.business_open)
        const timeCloseWeek = branchFormatDateToFront(data.business_close)
        const timeOpenSaturday = branchFormatDateToFront(data.saturday_open)
        const timeCloseSaturday = branchFormatDateToFront(data.saturday_close)
        const timeOpenSunday = branchFormatDateToFront(data.sunday_open)
        const timeCloseSunday = branchFormatDateToFront(data.sunday_close)

        setValue('nickname', data.nickname)
        setValue('shipping_id', data.shipping_id)
        setValue('email', data.email)
        setValue('hub_id', data.hub_id)
        setValue('cellphone', data.cellphone)
        setValue('telephone', data.telephone)
        setValue('cep', data.cep)
        setValue('street', data.street)
        setValue('number', data.number)
        setValue('complement', data.complement)
        setValue('neighborhood', data.neighborhood)
        setValue('city', data.city)
        setValue('state', data.state)
        setValue('business_open', timeOpenWeek as string)
        setValue('business_close', timeCloseWeek as string)
        setValue(
          'saturday_open',
          timeOpenSaturday === ' ' ? null : timeOpenSaturday,
        )
        setValue(
          'saturday_close',
          timeCloseSaturday === ' ' ? null : timeCloseSaturday,
        )
        setValue('sunday_open', timeOpenSunday === ' ' ? null : timeOpenSunday)
        setValue(
          'sunday_close',
          timeCloseSunday === ' ' ? null : timeCloseSunday,
        )
        setValue('open_on_holidays', data.open_on_holidays ? 'yes' : 'no')

        if(data.open_on_holidays === true){
          setValue('holiday_open', data.holiday_open)
          setValue('holiday_close', data.holiday_close)
        }

        setValue('observation', data.observation)
      }
    }

    run()
  }, [setValue, slug, id])
  // ---------------------------------------------------------------------------- //

  // UPDATE FIELD VALUES ON URL CHANGES
  useEffect(() => {
    function run() {
      history.listen((location) => {
        if (slug !== 'visualizar' && location.pathname) {
          reset()
          reset({
            telephone: '',
            cellphone: '',
            cep: '',
          })
        }
      })
    }
    run()
  }, [history, slug, reset])
  // ---------------------------------------------------------------------------- //

  if (isLoadingPage && slug !== 'adicionar') {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('nickname')}
              isDisabled={isDisabled}
              name="nickname"
              label="Apelido"
              error={errors.nickname}
              required
            />

            <Select
              {...register('shipping_id')}
              isDisabled={isDisabled}
              shipping={shipping}
              name="shipping_id"
              label="Pertence a Transportadora"
              placeholder="Selecione uma opção..."
              error={errors.shipping_id}
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('email')}
              name="email"
              label="E-mail"
              placeholder="email@exemplo.com"
              error={errors.email}
              isDisabled={isDisabled}
              required
            />
            <Select
              {...register('hub_id')}
              name="hub_id"
              label="Pertence ao HUB"
              error={errors.hub_id}
              hubs={hubs}
              placeholder="Selecione uma opção..."
              isDisabled={isDisabled}
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Controller
              control={control}
              name="cellphone"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('cellphone')}
                  mask={handleSwitchMask('cellphone')}
                  onChange={onChange}
                  defaultValue={value}
                  label="Celular"
                  error={errors.cellphone}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />
            <Controller
              control={control}
              name="telephone"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('telephone')}
                  mask={handleSwitchMask('phone')}
                  defaultValue={value}
                  onChange={onChange}
                  label="Telefone do Responsável"
                  error={errors.telephone}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Controller
              control={control}
              name="cep"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  {...register('cep')}
                  mask={handleSwitchMask('cep')}
                  defaultValue={value}
                  onChange={onChange}
                  label="CEP"
                  error={errors.cep}
                  isDisabled={isDisabled}
                  required
                />
              )}
            />
            <HStack w="100%" spacing="24px">
              <Input
                {...register('street')}
                name="street"
                label="Rua"
                error={errors.street}
                isDisabled={isDisabled}
                required
              />

              <Input
                {...register('number')}
                name="number"
                label="Número"
                error={errors.number}
                isDisabled={isDisabled}
                required
              />
            </HStack>
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('complement')}
              name="complement"
              label="Complemento"
              error={errors.complement}
              isDisabled={isDisabled}
            />
            <Input
              {...register('neighborhood')}
              name="neighborhood"
              label="Bairro"
              error={errors.neighborhood}
              isDisabled={isDisabled}
              required
            />

            <Select
              {...register('state')}
              name="state"
              label="Estado"
              error={errors.state}
              states={statesList}
              placeholder="Selecione um estado"
              isDisabled={isDisabled}
              required
            />
            <Select
              {...register('city')}
              name="city"
              label="Cidade"
              error={errors.city}
              citiesIBGE={citiesList}
              placeholder="Selecione uma Cidade"
              isDisabled={isDisabled}
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Select
              {...register('open_on_holidays')}
              name="open_on_holidays"
              options={openOnHolidays}
              label="Funcionamento em feriados?"
              placeholder="Selecione uma opção..."
              error={errors.open_on_holidays}
              isDisabled={isDisabled}
              required
            />
            {isOpenOnHolidays === 'yes' && (
              <HStack w="100%" spacing="24px">
                <Input
                  {...register('holiday_open')}
                  name="holiday_open"
                  label="Horário de abertura"
                  type="time"
                  error={errors.holiday_open}
                  isDisabled={isDisabled}
                  required
                />
                <Input
                  {...register('holiday_close')}
                  name="holiday_close"
                  label="Horário de fechamento"
                  type="time"
                  error={errors.holiday_close}
                  isDisabled={isDisabled}
                  required
                />
              </HStack>
            )}
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              value="SIM"
              name="week_select"
              label="Funcionamento de segunda a sexta?"
              error={undefined}
              isDisabled={true}
              required
            />
            <HStack w="100%" spacing="24px">
              <Input
                {...register('business_open')}
                name="business_open"
                label="Horário de abertura"
                type="time"
                error={errors.business_open}
                isDisabled={isDisabled}
                required
              />
              <Input
                {...register('business_close')}
                name="business_close"
                label="Horário de fechamento"
                type="time"
                error={errors.business_close}
                isDisabled={isDisabled}
                required
              />
            </HStack>
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Select
              onChange={(e) => setHasOperationSaturday(e.target.value)}
              value={hasOperationSaturday}
              negative_positive={negative_positive}
              placeholder="Selecione uma opção..."
              name="weekend_select"
              label="Funcionamento aos sábados?"
              error={undefined}
              isDisabled={isDisabled}
              required
            />
            <HStack w="100%" spacing="24px">
              <Input
                {...register('saturday_open')}
                name="saturday_open"
                label="Horário de abertura"
                type="time"
                error={
                  hasOperationSaturday === 'SIM'
                    ? errors.business_open
                    : undefined
                }
                isDisabled={
                  !(slug !== 'visualizar' && hasOperationSaturday === 'SIM')
                }
                required
              />
              <Input
                {...register('saturday_close')}
                name="saturday_close"
                label="Horário de fechamento"
                type="time"
                error={
                  hasOperationSaturday === 'SIM'
                    ? errors.saturday_close
                    : undefined
                }
                isDisabled={
                  !(slug !== 'visualizar' && hasOperationSaturday === 'SIM')
                }
                required
              />
            </HStack>
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Select
              onChange={(e) => setHasOperationSunday(e.target.value)}
              value={hasOperationSunday}
              negative_positive={negative_positive}
              placeholder="Selecione uma opção..."
              name="weekend_select"
              label="Funcionamento aos domingos?"
              error={undefined}
              isDisabled={isDisabled}
              required
            />
            <HStack w="100%" spacing="24px">
              <Input
                {...register('sunday_open')}
                name="sunday_open"
                label="Horário de abertura"
                type="time"
                error={
                  hasOperationSunday === 'SIM' ? errors.sunday_open : undefined
                }
                isDisabled={
                  !(slug !== 'visualizar' && hasOperationSunday === 'SIM')
                }
                required
              />
              <Input
                {...register('sunday_close')}
                name="sunday_close"
                label="Horário de fechamento"
                type="time"
                error={
                  hasOperationSunday === 'SIM' ? errors.sunday_close : undefined
                }
                isDisabled={
                  !(slug !== 'visualizar' && hasOperationSunday === 'SIM')
                }
                required
              />
            </HStack>
          </Stack>
          <TextArea
            {...register('observation')}
            name="observation"
            label="Observações"
            isDisabled={isDisabled}
          />
          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/transportadoras/bases">
                <Button type="button" colorScheme="gray">
                  Lista de Bases de Transportadoras
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}

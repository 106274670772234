import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface AddActivityRefundBody {
  observations?: string
  attachmentsIds?: string[]
}

interface AddActivityRefundParams {
  body: AddActivityRefundBody
  refundId: string
}

export async function addActivityRefund({
  body,
  refundId
}: AddActivityRefundParams) {
  try {
    await api.put(`/refunds/${refundId}/activities`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { createContext, ReactNode, useCallback, useState } from "react";

export enum CltDriversExtrasDiscountsStatusEnum {
  'requested' = 'Solicitado',
  'approved' = 'Aprovado',
  'effectivated' = 'Efetivado',
  'rejected' = 'Rejeitado'
}

export type CltDriversExtrasDiscountsStatus = keyof typeof CltDriversExtrasDiscountsStatusEnum

interface CltDriversExtrasDiscountsStatusContextProps {
  status: CltDriversExtrasDiscountsStatus
  handleChangeStatus: (status: CltDriversExtrasDiscountsStatus) => void
}

interface CltDriversExtrasDiscountsStatusContextProviderProps {
  children: ReactNode
}

export const CltDriversExtrasDiscountsStatusContext = createContext(
  {} as CltDriversExtrasDiscountsStatusContextProps
)

export function CltDriversExtrasDiscountsStatusProvider({
  children
}: CltDriversExtrasDiscountsStatusContextProviderProps) {
  const [status, setStatus] = useState<CltDriversExtrasDiscountsStatus>('requested')

  const handleChangeStatus = useCallback((status: CltDriversExtrasDiscountsStatus) => {
    setStatus(status)
  }, [])

  return (
    <CltDriversExtrasDiscountsStatusContext.Provider value={{
      status,
      handleChangeStatus
    }}>
      {children}
    </CltDriversExtrasDiscountsStatusContext.Provider>
  )
}

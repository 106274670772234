import { Box, BoxProps } from "@chakra-ui/react";

function formatMessage(message: string) {
  return message.replace(/\n/g, `</br>`)
}

interface ExternalComunicationsMessageContentBodyProps extends BoxProps {
  message: string
}

export function ExternalComunicationsMessageContentBody({
  message,
  ...rest
}: ExternalComunicationsMessageContentBodyProps) {
  return (
    <Box
      alignSelf="flex-end"
      bg="blue.50"
      p="2"
      h="min"
      rounded="xl"
      borderTopRightRadius="0"
      dangerouslySetInnerHTML={{ __html: formatMessage(message) }}
      {...rest}
    />
  )
}

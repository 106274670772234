import { usePagination } from "@ajna/pagination"
import { Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { getStockLogs } from "api/stocks/getStockLogs"
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert"
import { Pagination } from "components/Pagination/Pagination"
import { useSearchParams } from "hooks/useSearchParams"
import { Fragment } from "react"
import { useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import { StockLogTableRow } from "./StockLogTableRow"


interface StockLogsModalProps {
  stockId: string
}
export function StockLogsModal({ stockId }: StockLogsModalProps) {

  const searchParams = useSearchParams()
  const page = searchParams.get('page') ?? '1'

  const { replace } = useHistory()

  const { data: stockLogsData, isLoading: isStockLogsDataLoading } = useQuery({
    queryKey: ['stockLogs', page],
    queryFn: () => getStockLogs({
      currentPage: page,
      pageSize: '10',
      stockId: stockId
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: stockLogsData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: stockLogsData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }
  return (
    <ModalContent maxW={'950px'}>
      <ModalHeader>Logs</ModalHeader>
      <ModalCloseButton />
      {isStockLogsDataLoading ? (
        <ModalBody
          maxH='500px'
          h='full'
          overflow='auto'
        >
          <Spinner />
        </ModalBody>
      ) : (
        <ModalBody
          maxH='500px'
          overflow='auto'
        >
          <Flex direction='column' align='center'>
            {stockLogsData?.stockLogs?.length === 0 ? (
              <EmptyContentTableAlert title="Ops..." description="Não há logs para este estoque!" />
            ) : (
              <TableContainer
                rounded="md"
              >
                <Table m='2' maxW='500px' variant='simple' size='sm'>
                  <Thead>
                    <Tr
                      h="40px"
                      bg="#38c3fa"
                      rounded='md'
                    >
                      <Th color="white">CAMPO</Th>
                      <Th color="white">VALOR ANTERIOR</Th>
                      <Th color="white">VALOR ATUALIZADO</Th>
                      <Th color="white">ATUALIZADO POR</Th>
                      <Th color="white">DATA DA ATUALIZAÇÃO</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {stockLogsData?.stockLogs?.map((log) => {
                      return (
                        <Fragment key={log?.id}>
                          {log.log_detail.collector_id && (
                            <StockLogTableRow
                              name="Coletador"
                              prevValue={log?.log_detail.collector_id.prev_value}
                              newValue={log?.log_detail?.collector_id.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail.input_id && (
                            <StockLogTableRow
                              name="Insumo"
                              prevValue={log?.log_detail?.input_id.prev_value}
                              newValue={log?.log_detail?.input_id.new_value}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail.minimum_stock && (
                            <StockLogTableRow
                              name="Estoque Mínimo"
                              prevValue={String(log?.log_detail?.minimum_stock.prev_value)}
                              newValue={String(log?.log_detail?.minimum_stock.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail.standard_stock && (
                            <StockLogTableRow
                              name="Estoque Padrão"
                              prevValue={String(log?.log_detail?.standard_stock.prev_value)}
                              newValue={String(log?.log_detail?.standard_stock.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail.current_stock && (
                            <StockLogTableRow
                              name="Estoque Atual"
                              prevValue={String(log?.log_detail?.current_stock.prev_value)}
                              newValue={String(log?.log_detail?.current_stock.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                          {log.log_detail.observation && (
                            <StockLogTableRow
                              name="Observações"
                              prevValue={String(log?.log_detail?.observation.prev_value)}
                              newValue={String(log?.log_detail?.observation.new_value)}
                              updatedBy={log?.updatedBy}
                              updatedAt={log?.updatedAt}
                            />
                          )}
                        </Fragment>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <Pagination
              currentPage={currentPage}
              pages={pages}
              pagesQuantity={pagesCount}
              handlePageChange={handleChangePage}
            />
          </Flex>
        </ModalBody>
      )}
    </ModalContent>
  )
}

import { api } from '../../api'
import { CitiesProps } from '../city/getCity'

export type HolidayProps = {
  id: string
  name: string
  date: string
  city_id: string
  cityIDHoliday: CitiesProps
  national: boolean
}

const getAllHolidays = () =>
  api
    .get<HolidayProps[]>('/holiday')
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const getOneHoliday = (holidayId: string) =>
  api
    .get<HolidayProps>(`/holiday/${holidayId}`)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

export { getAllHolidays, getOneHoliday }

import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { Address } from "./dtos/Address";

interface AddressesPaginatedQueryParams {
  customer_id?: string
  situation?: 'ATIVO' | 'INATIVO'
  currentPage?: number
  pageSize?: number
  branch?: string
  customer?: string
  trading_name?: string
  city?: string
  order_by?: string
}

interface AddressesPaginated {
  data: Address[]
  totalPages: number
}

type QueryOptions = UseQueryOptions<AddressesPaginated, unknown, AddressesPaginated, QueryKey>

interface UseAddressesPaginatedOptions {
  queryParams?: AddressesPaginatedQueryParams,
  queryOptions?: QueryOptions
}

export function useAddressesPaginated(options?: UseAddressesPaginatedOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getAddress(queryParams?: AddressesPaginatedQueryParams): Promise<AddressesPaginated> {

    try {
      const response = await api.get<Address[]>(`/address/`, {
        params: queryParams && {
          ...queryParams,
        }
      })

      return { data: response.data, totalPages: Number(response.headers['x-total-pages']) }
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery<AddressesPaginated, unknown, AddressesPaginated, QueryKey>(["addressesPaginated",
    options?.queryParams?.currentPage,
    options?.queryParams?.pageSize,
    options?.queryParams?.branch,
    options?.queryParams?.city,
    options?.queryParams?.customer,
    options?.queryParams?.trading_name,
    options?.queryParams?.situation,
    options?.queryParams?.order_by,
  ], () => getAddress(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => await onRequestError(error)
  })
}

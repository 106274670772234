import { Box, Button, Flex, Stack, Link as ChakraLink, Heading, Divider, } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useCurrency } from 'react-hook-currency'
import { Controller, useForm } from 'react-hook-form'
import { CitiesProps } from '../../../services/getFunctions/city/getCity'
import { deadline_delivery } from '../../../utils/customLists'
import { statesWithUfList } from '../../../utils/CustomLists/statesWithUfList'
import { switchRouteTypes } from '../../../utils/CustomLists/switchRouteTypes'
import { switchCategoryTypes } from 'utils/CustomLists/switchCategotyTypes'
import { IHubsProps } from '../../../utils/RequestFunctions/Hubs/requestHubFunctions'
import { switchPriceSchema } from '../../../validations/priceSchema'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { Input } from '../../Inputs/Input'
import { Select } from '../../Inputs/SelectInput'
import { TextArea } from '../../Inputs/TextInput'
import { ShippingProps } from 'contexts/ShippingContext'
import { SearchBox } from 'components/SearchBox/SearchBox'
import { GeneralCheckbox } from 'components/Inputs/GeneralCheckbox'
import { searchBoxFilter } from 'utils/searchBoxFilter'


export type PriceCategory =
  | "BIOLÓGICO"
  | "CARGA GERAL EMERGENCIAL"
  | "CARGA GERAL CONVENCIONAL"


interface IFormInputProps {
  source_city_uf: string
  source_city_id: string
  source_hub_id: string
  destination_city_uf: string
  destination_city_id: string
  destination_hub_id: string
  route_type: string
  air_franchising: string
  highway_franchising: string
  air_minimum_price: string
  air_extra_kg: string
  air_deadline: number | null
  highway_minimum_price: string
  highway_extra_kg: string
  highway_deadline: number | null
  additional_collect_price: string
  additional_delivery_price: string
  observation: string
  category: PriceCategory
  vehicle: string
  shippingIds: string[]
  service_type: 'FRACIONADO' | 'DEDICADO'
}

export const vehicle_types = [
  { key: "0", value: 'CARRO', showOption: 'CARRO' },
  { key: "1", value: 'MOTO', showOption: 'MOTO' },
  { key: "2", value: 'CAMINHÃO', showOption: 'CAMINHÃO' },
]

interface ICreatePriceFormProps {
  cities: CitiesProps[]
  hubs: IHubsProps[]
  shippings: ShippingProps[]
  submit: (values: IFormInputProps) => Promise<void>
}

export function CreatePriceForm({
  submit,
  cities,
  hubs,
  shippings
}: ICreatePriceFormProps) {
  const { onChange, format: currencyFormat } = useCurrency({ style: 'decimal' })
  const [shippingFiltered, setShippingFiltered] = useState('')

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(switchPriceSchema.handle()),
  })

  const routeType = watch('route_type')
  const serviceType = watch('service_type')
  const sourceCityUf = watch('source_city_uf')
  const sourceCityId = watch('source_city_id')
  const destinationCityUf = watch('destination_city_uf')
  const destinationCityId = watch('destination_city_id')
  const categoryType = watch('category')

  useEffect(() => {
    if (sourceCityId) {
      const sourceHubBySourceCity = cities.find(
        (city) => city.id === sourceCityId,
      )?.hub_id
      if (sourceHubBySourceCity) {
        setValue('source_hub_id', sourceHubBySourceCity)
      }
    }
  }, [sourceCityId, setValue, cities])

  useEffect(() => {
    if (destinationCityId) {
      const destinationHubBySourceCity = cities.find(
        (city) => city.id === destinationCityId,
      )?.hub_id
      if (destinationHubBySourceCity) {
        setValue('destination_hub_id', destinationHubBySourceCity)
      }
    }
  }, [destinationCityId, setValue, cities])

  useEffect(() => {
    if (categoryType === 'BIOLÓGICO') {

      if (routeType === 'AÉREO' || routeType === 'AMBOS') {
        setValue('air_franchising', '2 KG')
      }

      if (routeType === 'RODOVIÁRIO' || routeType === 'AMBOS') {
        setValue('highway_franchising', '10 KG')
      }
    }

    if (categoryType === 'CARGA GERAL EMERGENCIAL' || categoryType === 'CARGA GERAL CONVENCIONAL') {
      if (routeType === 'AÉREO' || routeType === 'AMBOS') {
        setValue('air_franchising', '10 KG')
      }

      if (routeType === 'RODOVIÁRIO' || routeType === 'AMBOS') {
        setValue('highway_franchising', '10 KG')
      }
    }

    if (routeType === 'AÉREO') {
      setValue('highway_minimum_price', '')
      setValue('highway_extra_kg', '')
    }
    if (routeType === 'RODOVIÁRIO') {
      setValue('air_minimum_price', '')
      setValue('air_extra_kg', '')
    }



  }, [routeType, setValue, categoryType])

  const shippingsCheckboxOptions = shippings?.filter((shipping) => {
    if (routeType === 'RODOVIÁRIO') {
      return shipping.modal === routeType
    }
    if (routeType === 'AÉREO') {
      return shipping.modal === routeType
    }
    return shipping

  })?.map((shipping) => {
    return {
      key: shipping.id,
      value: shipping.id,
      showOption: shipping.trading_name
    }
  })

  const shippingsOptions = shippingsCheckboxOptions?.filter((shipping) => {
    const shippingFilter = searchBoxFilter(
      shipping.showOption,
      shippingFiltered,
    )
    if (shippingFiltered === "") {
      return shipping
    }
    return shippingFilter
  })


  return (
    <Flex>
      <Box
        as="form"
        flex="1"
        borderRadius="8px"
        bg="white"
        p={['6', '8']}
        onSubmit={handleSubmit(submit)}
        noValidate
      >
        <Flex
          mb="8"
          justify="space-between"
          align="center"
          direction={['column', 'column', 'row']}
        >
          <Heading size="lg" fontFamily="poppins">
            Adicionar Preço
          </Heading>
          <Flex
            w={['full', 'full', 'min']}
            justifyContent='flex-end'
            direction={["column", "column", "row"]}
          >
            <Button
              colorScheme='blue'
              as={ChakraLink}
              href="https://transporte-biologico-images.s3.sa-east-1.amazonaws.com/Cria%C3%A7%C3%A3o+de+pre%C3%A7o.pdf"
              isExternal
            >
              POP - Criação de Preço
            </Button>
          </Flex>
        </Flex>
        <Divider my="6" borderColor="gray.700" />

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
            <Select
              {...register('source_city_uf')}
              name="source_city_uf"
              label="UF Origem"
              placeholder="Selecione uma opção..."
              error={errors.source_city_uf}
              onChangeCapture={() => setValue('source_city_id', '')}
              statesWithUf={statesWithUfList}
              required
            />
            {sourceCityUf && (
              <Select
                {...register('source_city_id')}
                name="source_city_id"
                label="Cidade Origem"
                placeholder="Selecione uma opção..."
                error={errors.source_city_id}
                citiesBack={cities.filter(
                  (city) => city.state === sourceCityUf.toUpperCase(),
                )}
                required
              />
            )}
          </Stack>
          {sourceCityId && (
            <Select
              {...register('source_hub_id')}
              name="source_hub_id"
              label="Hub Origem"
              placeholder="Selecione uma opção..."
              hubs={hubs}
              isDisabled={true}
              error={errors.source_hub_id}
              required
            />
          )}
        </Stack>
        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Stack w="full" spacing="24px" direction={['column', 'column', 'row']}>
            <Select
              {...register('destination_city_uf')}
              name="destination_city_uf"
              placeholder="Selecione uma opção..."
              label="UF Destino"
              onChangeCapture={() => setValue('destination_city_id', '')}
              error={errors.destination_city_uf}
              statesWithUf={statesWithUfList}
              required
            />
            {destinationCityUf && (
              <Select
                {...register('destination_city_id')}
                name="destination_city_id"
                placeholder="Selecione uma opção..."
                label="Cidade Destino"
                error={errors.destination_city_id}
                citiesBack={cities.filter(
                  (city) => city.state === destinationCityUf.toUpperCase(),
                )}
                required
              />
            )}
          </Stack>
          {destinationCityId && (
            <Select
              {...register('destination_hub_id')}
              name="destination_hub_id"
              label="Hub Destino"
              placeholder="Selecione uma opção..."
              isDisabled={true}
              hubs={hubs}
              error={errors.destination_hub_id}
              required
            />
          )}
        </Stack>
        <Stack mt="4">
          <Select
            {...register('category')}
            name="category"
            label="Categoria"
            placeholder="Selecione uma opção..."
            categoryTypes={switchCategoryTypes.handle()}
            required
          />
        </Stack>

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Stack
            spacing={6}
            w="full"
            direction={{ base: 'column', lg: 'row' }}
          >
            <Select
              {...register('service_type')}
              name="service_type"
              label="Tipo de serviço"
              placeholder="Selecione uma opção..."
              options={[
                {
                  key: '0',
                  value: 'FRACIONADO',
                  showOption: 'FRACIONADO',
                },
                {
                  key: '1',
                  value: 'DEDICADO',
                  showOption: 'DEDICADO',
                },
              ]}
              required
            />
            <Select
              {...register('route_type')}
              name="route_type"
              label="Tipo de Rota"
              placeholder="Selecione uma opção..."
              routeTypes={switchRouteTypes.handle()}
              required
            />
          </Stack>
          <Select
            {...register("vehicle")}
            name="vehicle"
            label="Veículo"
            placeholder="Selecione uma opção..."
            options={vehicle_types}
            error={errors.vehicle}
            required
          />
        </Stack>
        {routeType && serviceType === 'FRACIONADO' && (
          <Stack w="full" mt="4" spacing="10px" justify="flex-start">
            <>
              <SearchBox
                isCheckbox={true}
                size="sm"
                placeholder="Buscar Transportadora..."
                handleSearch={(e) =>
                  setShippingFiltered(e.target.value)
                }
              />
              <Box
                overflowY="auto"
                height="300px"
                borderWidth="1px"
                borderRadius="lg"
                p="2"
              >
                <Controller
                  control={control}
                  name="shippingIds"
                  render={({ field }) => (
                    <GeneralCheckbox
                      name="shippingIds"
                      onCheckboxChange={field.onChange}
                      checkboxOptions={shippingsOptions}
                      label="Transportadora (s)"
                    />
                  )}
                />
              </Box>
            </>
          </Stack>
        )}
        {(routeType === 'AÉREO' || routeType === 'AMBOS') && (
          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('air_minimum_price')}
              name="air_minimum_price"
              label="Preço mínimo aéreo"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.air_minimum_price}
              required
            />

            <Input
              {...register('air_franchising')}
              name="air_franchising"
              label="Franquia aérea"
              isDisabled={true}
            />

            <Input
              {...register('air_extra_kg')}
              name="air_extra_kg"
              label="KG extra aéreo"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.air_extra_kg}
              required
            />
            <Select
              {...register('air_deadline')}
              name="air_deadline"
              label="Prazo aéreo"
              error={errors.air_deadline}
              placeholder="Selecione uma opção..."
              deadline_delivery={deadline_delivery}
              required
            />
          </Stack>
        )}
        {(routeType === 'RODOVIÁRIO' || routeType === 'AMBOS') && (
          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('highway_minimum_price')}
              name="highway_minimum_price"
              label="Preço mínimo rodoviário"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.highway_minimum_price}
              required
            />

            <Input
              {...register('highway_franchising')}
              name="highway_franchising"
              label="Franquia rodoviária"
              isDisabled={true}
            />

            <Input
              {...register('highway_extra_kg')}
              name="highway_extra_kg"
              label="KG extra rodoviário"
              onChange={onChange}
              defaultValue={currencyFormat('000')}
              error={errors.highway_extra_kg}
              required
            />
            <Select
              {...register('highway_deadline')}
              name="highway_deadline"
              label="Prazo rodoviário"
              error={errors.highway_deadline}
              placeholder="Selecione uma opção..."
              deadline_delivery={deadline_delivery}
              required
            />
          </Stack>
        )}

        <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('additional_collect_price')}
            name="additional_collect_price"
            label="Preço adicional coleta"
            onChange={onChange}
            defaultValue={currencyFormat('000')}
            error={errors.additional_collect_price}
            required
          />
          <Input
            {...register('additional_delivery_price')}
            name="additional_delivery_price"
            label="Preço adicional entrega"
            onChange={onChange}
            defaultValue={currencyFormat('000')}
            error={errors.additional_delivery_price}
            required
          />
        </Stack>

        <Stack mt="4">
          <TextArea
            {...register("observation")}
            name="observation"
            label="Observações"
          />
        </Stack>
        <Flex mt="4" justifyContent="flex-end">
          <Stack spacing="24px" direction="row">
            <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
            <ListButton href="/precos" name="Preços" />
          </Stack>
        </Flex>
      </Box>
    </Flex>
  )
}

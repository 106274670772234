import { useMutation, useQueryClient } from 'react-query'
import {
  editRecurrentServiceReqFunction,
  EditRecurrentServiceProps,
  CreateRecurrentServiceInputProps,
  createRecurrentServiceReqFunction,
} from '../../utils/RequestFunctions/Service/Recurrent/requestRecurrentServiceFunctions'
import { useSwal } from '../swal/useSwal'

export const useRecurrentServiceFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createRecurrentService = useMutation(
    'createRecurrentService',
    (createRecurrentServiceInput: CreateRecurrentServiceInputProps) =>
      createRecurrentServiceReqFunction(createRecurrentServiceInput),
    {
      onSuccess: () => {
        successMessage('Serviço recorrente criado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const editRecurrentService = useMutation(
    'editRecurrentService',
    ({
      recurrentServiceID,
      recurrentServiceInput,
    }: EditRecurrentServiceProps) =>
      editRecurrentServiceReqFunction({
        recurrentServiceID,
        recurrentServiceInput,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['recurrentServices'])
        await queryClient.invalidateQueries(['recurrentService'])
        successMessage('Serviço recorrente editado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )
  return {
    editRecurrentService,
    createRecurrentService,
  }
}

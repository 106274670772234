import { Button, Flex, Select } from "@chakra-ui/react";
import { ActiveFeedbackStatus } from "api/feedbacks/_types/ActiveFeedback";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { activeFeedbackStatusMap } from "utils/feedbackMappers";

interface ActivesFeedbacksTableFiltersSchema {
  status: ActiveFeedbackStatus | 'all'
}

export function ActivesFeedbacksTableFilter() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<ActivesFeedbacksTableFiltersSchema>({
    defaultValues: {

      status: status as ActiveFeedbackStatus | 'all'
    }
  })

  async function handleFilterActivesFeedbacks(data: ActivesFeedbacksTableFiltersSchema) {

    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('status')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterActivesFeedbacks)}
    >

      <Select
        {...register('status')}
        defaultValue="all"
        size="sm"
        borderRadius="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        {Object.entries(activeFeedbackStatusMap).map(([key, value]) => {
          return (
            <option key={key} value={key}>{value}</option>
          )
        })}
      </Select>
      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}

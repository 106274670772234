import { Button, Flex, Spinner, Stack, Icon, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useProfile } from "hooks/profile/useProfile";
import { useUsers } from "hooks/user/useUsers";
import { useFormContext, useWatch, useFieldArray } from "react-hook-form";
import { Link } from "react-router-dom";
import { AlignOccurrenceInputs } from "../AlignOccurrence";
import { FaPlus, FaTimes } from "react-icons/fa"
import { useCollectors } from "hooks/collector/useCollectors";
import { useCustomers } from "hooks/customer/useCustomers";
import { useEffect } from "react";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";
import { useCurrency } from "react-hook-currency";

export function AlignOccurrenceForm() {
  const {
    control,
    register,
    setValue,
    unregister,
    formState: { errors, isSubmitting }
  } = useFormContext<AlignOccurrenceInputs>()

  const { onChange: onChangeCurrency, format: currencyFormat } = useCurrency({
    style: 'decimal',
  })

  const {
    profiles: {
      data: profiles, isFetching: isFetchingProfiles
    }
  } = useProfile(null)

  const [
    alignmentIndicatedUserType,
    collectorSelected,
    customerSelected,
  ] = useWatch({
    control,
    name: [
      'alignment_indicated_user_type',
      'collector_id',
      'customer_id'
    ],
  })

  const {
    fields: occurrenceResponsibleFields,
    append: appendOcurrenceResponsibleField,
    remove: removeOccurrenceResponsibleField
  } = useFieldArray({
    control,
    name: 'alignments'
  })

  function handleRemoveOccurrenceResponsibleField(index: number) {
    removeOccurrenceResponsibleField(index)
  }
  function handleAppendOccurrenceResponsibleField() {
    appendOcurrenceResponsibleField({ alignment_actions: '', preventive_limit_date: '', preventive_responsible: '' })
  }

  useEffect(() => {
    if (alignmentIndicatedUserType !== 'COLETADOR') {
      unregister('has_collector_fine')
      unregister('collector_fine_value')
    }
    if (alignmentIndicatedUserType !== 'COLETADOR' && alignmentIndicatedUserType !== 'MOTORISTA') {
      unregister('collector_id')
    }
    if (alignmentIndicatedUserType !== 'CLIENTE') {
      unregister('customer_id')
    }
  }, [alignmentIndicatedUserType, unregister])

  useEffect(() => {
    if (alignmentIndicatedUserType === 'COLETADOR') {
      setValue('has_collector_fine', 'yes')
      setValue('collector_fine_value', '5,00')
    }
  }, [alignmentIndicatedUserType, setValue])

  const {
    data: collectors,
    isFetching: isFetchingCollectors
  } = useCollectors({
    queryOptions: {
      enabled: alignmentIndicatedUserType === 'COLETADOR' || alignmentIndicatedUserType === 'MOTORISTA'
    }
  })

  const collectorsSelectOptions = collectors?.map((collector) => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }
  })

  const {
    data: customers,
    isFetching: isFetchingCustomers
  } = useCustomers({
    queryOptions: {
      enabled: alignmentIndicatedUserType === 'CLIENTE'
    }
  })

  const customersSelectOptions = customers?.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })


  const { data: alignmentIndicatedUsers, isFetching: isFetchingAlignmentIndicatedUsers } = useUsers({
    queryParms: {
      situation: 'ATIVO',
      collector_id: collectorSelected,
      customer_id: customerSelected
    },
    queryKey: 'alignmentIndicatedUsers'
  })

  const { data: preventiveResponsibleUsers, isFetching: isFetchingPreventiveResponsibleUsers } = useUsers({
    queryParms: {
      situation: 'ATIVO',
    },
    queryKey: 'preventiveResponsibleUsers'
  })

  const alignmentIndicatedUserOptions = alignmentIndicatedUsers
    ?.filter(user => {
      return user.user_type === alignmentIndicatedUserType
    })
    ?.map(user => {
      return {
        key: user.id,
        value: user.id,
        showOption: `${user.firstname} ${user.lastname}`
      }
    })

  const preventiveResponsibleOptions = preventiveResponsibleUsers
    ?.filter(user => {
      return user.loglife_employee
    })
    ?.map(user => {
      return {
        key: user.id,
        value: user.id,
        showOption: `${user.firstname} ${user.lastname}`
      }
    })

  const profilesOptions = profiles?.map(profile => {
    return {
      key: profile.id,
      value: profile.user_type,
      showOption: profile.user_type
    }
  })

  if (isFetchingPreventiveResponsibleUsers || isFetchingAlignmentIndicatedUsers || isFetchingProfiles) {
    return <Spinner />
  }

  return (
    <Flex gap={4} direction='column' mt={4}>
      <Stack w='full' spacing={4} direction={['column', 'column', 'row']}>
        <Select
          {...register('alignment_indicated_user_type')}
          name='alignment_indicated_user_type'
          label='Perfil do usuário indicado'
          placeholder='Selecione uma opção...'
          error={errors.alignment_indicated_user_type}
          options={profilesOptions}
          required
        />
        {(alignmentIndicatedUserType === 'COLETADOR' || alignmentIndicatedUserType === 'MOTORISTA') && (
          <>
            {isFetchingCollectors ? (
              <Spinner />
            ) : (
              <Select
                {...register('collector_id')}
                name='collector_id'
                label='Coletador'
                placeholder='Selecione uma opção...'
                error={errors.collector_id}
                options={collectorsSelectOptions}
                required
              />
            )}
          </>
        )}
        {alignmentIndicatedUserType === 'CLIENTE' && (
          <>
            {isFetchingCustomers ? (
              <Spinner />
            ) : (
              <Select
                {...register('customer_id')}
                name='customer_id'
                label='Cliente'
                placeholder='Selecione uma opção...'
                error={errors.customer_id}
                options={customersSelectOptions}
                required
              />
            )}
          </>
        )}
      </Stack>
      {(
        (alignmentIndicatedUserType === 'CLIENTE' && !!customerSelected) ||
        ((alignmentIndicatedUserType === 'COLETADOR' || alignmentIndicatedUserType === 'MOTORISTA') && !!collectorSelected) ||
        !['CLIENTE', 'COLETADOR', 'MOTORISTA'].includes(alignmentIndicatedUserType)
      )
        && (
          <Select
            {...register('alignment_indicated_user')}
            name='alignment_indicated_user'
            label='Usuário indicado'
            placeholder='Selecione uma opção...'
            error={errors.alignment_indicated_user}
            options={alignmentIndicatedUserOptions}
            required
          />
        )}

      {alignmentIndicatedUserType === 'COLETADOR' && (
        <Stack w='full' spacing={4} direction={['column', 'column', 'row']}>
          <Select
            {...register('has_collector_fine')}
            name='has_collector_fine'
            label='Gera multa?'
            placeholder='Selecione uma opção...'
            error={errors.has_collector_fine}
            options={positiveNegativeOptions}
            required
          />


          <Input
            {...register('collector_fine_value')}
            name='collector_fine_value'
            label='Valor da multa'
            addChildren="R$"
            onChange={onChangeCurrency}
            defaultValue={currencyFormat('000')}
            error={errors.collector_fine_value}
            required
          />
        </Stack>
      )}
      <Stack w='full' spacing={4} direction={['column', 'column', 'row']}>
        <Text>Alinhamentos</Text>
      </Stack>

      <Flex direction="column">
        {occurrenceResponsibleFields.map((field, index) => {
          return (
            <Flex direction='column'>
              <Stack
                w="full"
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Select
                  {...register(`alignments.${index}.preventive_responsible`)}
                  name={`alignments.${index}.preventive_responsible`}
                  label='Responsável pela ação preventiva'
                  placeholder='Selecione uma opção...'
                  error={
                    errors.alignments
                      ? errors?.alignments[index]?.preventive_responsible
                      : undefined
                  }
                  options={preventiveResponsibleOptions}
                  required


                />
              </Stack>
              <Stack
                w="full"
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register(`alignments.${index}.preventive_limit_date`)}
                  name={`alignments.${index}.preventive_limit_date`}
                  type='date'
                  label='Data limite da ação preventiva'
                  error={
                    errors.alignments
                      ? errors?.alignments[index]?.preventive_limit_date
                      : undefined
                  }
                  required
                />
              </Stack>
              <Stack
                w="full"
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <TextArea
                  {...register(`alignments.${index}.alignment_actions`)}
                  name={`alignments.${index}.alignment_actions`}
                  label='Ações'
                  error={
                    errors.alignments
                      ? errors?.alignments[index]?.alignment_actions
                      : undefined
                  }
                  required
                />

              </Stack>
              <Stack pt={8}>
                <Button
                  leftIcon={<Icon as={FaTimes} />}
                  variant="ghost"
                  onClick={() => handleRemoveOccurrenceResponsibleField(index)}
                  h="48px"
                >
                  Remover
                </Button>
              </Stack>
            </Flex>
          )
        })}
        <Button
          leftIcon={<Icon as={FaPlus} />}
          alignSelf="flex-start"
          onClick={handleAppendOccurrenceResponsibleField}
          mt={2}
        >
          Adicionar
        </Button>
      </Flex>
      <Flex
        justify={['center', 'center', 'flex-end']}
        direction={['column', 'column', 'row']}
        gap={4}
      >
        <Button
          w={['full', 'full', 'min']}
          as={Link}
          to='/ocorrencias/pendentes/alinhamento'
          colorScheme='gray'
          isLoading={isSubmitting}
        >
          Ocorrências em alinhamento
        </Button>

        <Button
          w={['full', 'full', 'min']}
          colorScheme='blue'
          isLoading={isSubmitting}
          type='submit'
        >
          Salvar
        </Button>
      </Flex>

    </Flex>
  )
}

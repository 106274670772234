import { Box, FormControl, FormLabel, Input, Select, Stack, Text } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { useCollectors } from "hooks/collector/useCollectors";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

const collectorTypeOptions = [
  'Padrão',
  'Backup',
]


export function NewCollectorSuppliesForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const {
    data: collectorsResult,
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO',
    }
  })


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      collector: 'Data da Retirada',
      isPrimaryCollector: 'Será coletador padrão ou backup?',
      expectedStartDate: 'Data prevista de início',
      isopor3l: 'Caixa de isopor 3L',
      isopor8l: 'Caixa de isopor 8L',
      papelao3l: 'Caixa de papelão 3L',
      papelao8l: 'Caixa de papelão 8L',
      gelox: 'Gelox',
      lacre: 'Lacre numerado',
      embalagemSecundaria: 'Embalagem secundária',
      almofadaAbsorvente: 'Almofada absorvente',
      geloSeco: 'Adesivo gelo seco'
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };
  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.collector}>
            <FormLabel fontSize="sm">
              Coletador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.collector.value")}
              name="formData.collector.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('collector')}
            >
              {collectorsResult?.map((collector) => {
                return <option key={collector.id} value={collector.trading_name}>{collector.trading_name}</option>
              })}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.isPrimaryCollector}>
            <FormLabel fontSize="sm">
              Será coletador padrão ou backup?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.isPrimaryCollector.value")}
              name="formData.isPrimaryCollector.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('isPrimaryCollector')}
            >
              {Object.entries(collectorTypeOptions).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.expectedStartDate} mt={3}>
        <FormLabel fontSize='sm'>
          Data prevista de início
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.expectedStartDate.value')}
          name='formData.expectedStartDate.value'
          type="date"
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('expectedStartDate')}
        />
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.isopor3l} >
            <FormLabel fontSize='sm'>Caixa de isopor 3L</FormLabel>
            <Input
              {...register('formData.isopor3l.value')}
              name='formData.isopor3l.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('isopor3l')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.isopor8l} >
            <FormLabel fontSize='sm'>Caixa de isopor 8L</FormLabel>
            <Input
              {...register('formData.isopor8l.value')}
              name='formData.isopor8l.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('isopor8l')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.papelao3l}>
            <FormLabel fontSize='sm'>Caixa de papelão 3L</FormLabel>
            <Input
              {...register('formData.papelao3l.value')}
              name='formData.papelao3l.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('papelao3l')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.papelao8l} >
            <FormLabel fontSize='sm'>Caixa de papelão 8L</FormLabel>
            <Input
              {...register('formData.papelao8l.value')}
              name='formData.papelao8l.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('papelao8l')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.gelox}>
            <FormLabel fontSize='sm'>Gelox</FormLabel>
            <Input
              {...register('formData.gelox.value')}
              name='formData.gelox.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('gelox')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.lacre}>
            <FormLabel fontSize='sm'>Lacre numerado</FormLabel>
            <Input
              {...register('formData.lacre.value')}
              name='formData.lacre.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('lacre')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.embalagemSecundaria}>
            <FormLabel fontSize='sm'>Embalagem secundária</FormLabel>
            <Input
              {...register('formData.embalagemSecundaria.value')}
              name='formData.embalagemSecundaria.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('embalagemSecundaria')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.almofadaAbsorvente}>
            <FormLabel fontSize='sm'>Almofada absorvente</FormLabel>
            <Input
              {...register('formData.almofadaAbsorvente.value')}
              name='formData.almofadaAbsorvente.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('almofadaAbsorvente')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.geloSeco} mt={3}>
        <FormLabel fontSize='sm'>Gelo Seco</FormLabel>
        <Input
          {...register('formData.geloSeco.value')}
          name='formData.geloSeco.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('geloSeco')}
        />
      </FormControl>
    </Box>
  )
}

import { Box, Grid, GridItem, Heading } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Calendar } from "./components/Calendar";
import { DateSchedulings } from "./components/DateSchedulings";

export function BusinessServiceSchedule() {
  const [selectedDatetime, setSelectedDatetime] = useState<Date | null>(null)

  const { userLogged } = useAuth()

  const history = useHistory()

  const userCanViewBusinessServiceSchedule = userLogged?.permissions?.includes('view-business-services-schedule')

  useEffect(() => {
    if (!userCanViewBusinessServiceSchedule) history.push('/')
  }, [history, userCanViewBusinessServiceSchedule])

  return (
    <Box
      p={4}
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight">Agenda Business</Heading>

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
        border="1px solid"
        borderColor="gray.200"
        mt={4}
        p={3}
        rounded="lg"
      >
        <GridItem overflowY="scroll" colSpan={{ base: 1, lg: selectedDatetime ? 2 : 3 }}>
          <Calendar onSetSelectedDatetime={setSelectedDatetime} />
        </GridItem>
        {selectedDatetime && (
          <GridItem colSpan={1} borderLeft={{ base: 'none', lg: "1px solid"}} borderColor={{ base: 'none', lg: "gray.200"}}>
            <DateSchedulings date={selectedDatetime} />
          </GridItem>
        )}
      </Grid>
    </Box>
  )
}

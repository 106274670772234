import { Button, Flex, FormControl, FormLabel, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useToastify } from "hooks/toastify/useToastify"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"
import { inProgressServiceSelectOptions } from "utils/CustomLists/inProgressServiceSelectOptions"
import { requestStepChange } from "api/service/requestStepChange/requestStepChange"

interface RequestStepChangeModalProps {
  serviceId: string
  onCloseModal: () => void
}

interface RequestStepChangeModalSchema {
  step: string
}

const confirmRequestStepChangeSchema = yup.object({
  step: yup.string().required(),
})


export function RequestStepChangeModal({ serviceId, onCloseModal }: RequestStepChangeModalProps) {
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutateAsync: requestStepChangeFn } = useMutation({
    mutationFn: requestStepChange,
    onSuccess: () => {
      queryClient.invalidateQueries(['services'])
      queryClient.invalidateQueries(['service'])
      onCloseModal()
    }

  })
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<RequestStepChangeModalSchema>({
    resolver: yupResolver(confirmRequestStepChangeSchema),
    defaultValues: {
      step: 'toCollectService'
    }
  })

  async function handleRequestStepModal(values: RequestStepChangeModalSchema) {

    await promiseMessage(requestStepChangeFn({
      routeParams: {
        serviceId,
      },
      body: {
        step: values.step
      }
    }), 'Solicitação de alteração de fase do serviço, relaizada com sucesso!')
  }

  return (
    <ModalContent>
      <ModalHeader>Solicitar alteração de fase do endereço</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        gap="3"
        as="form"
        onSubmit={handleSubmit(handleRequestStepModal)}
      >
        <Stack
          spacing="0.25"
          direction={["column", "column", "column"]}
          mt="3"
        >

          <FormLabel fontSize="sm">
            Etapa
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.step}>
            <Select
              {...register('step')}
              name="step"
              placeholder="Selecione..."
              size="md"
              rounded="md"
              defaultValue={inProgressServiceSelectOptions[0].value}
              disabled
            >

              {inProgressServiceSelectOptions.map((step) => {
                return (
                  <option key={step.key} value={step.value}>{step.showOption}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Solicitar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetUnsuccessCollectsPendingValidationResponse {
  collects: {
    id: string
    service_id: string
    protocol: number
    customer: string
    source_hub: string
    source_tz: string
    destination_hub: string
    destination_tz: string
    shipping: string
    street: string
    number: number
    cep: string
    neighborhood: string
    collect_date: string
  }[]
}

export async function getUnsuccessCollectsPendingValidation() {
  try {
    const { data } = await api.get<GetUnsuccessCollectsPendingValidationResponse>('/services/dashboard/collects/unsuccess-pending-validation')

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface ProceedRequestedPatrimonyToDevolutionBody {
  devolutionLimitDate: string
}

interface ProceedRequestedPatrimonyToDevolutionParams {
  body: ProceedRequestedPatrimonyToDevolutionBody,
  patrimonyRequestId: string
  patrimonyId: string
}

export async function proceedRequestedPatrimonyToDevolution({
  body,
  patrimonyRequestId,
  patrimonyId
}: ProceedRequestedPatrimonyToDevolutionParams) {
  try {

    await api.patch(`patrimony-requests/${patrimonyRequestId}/patrimonies/${patrimonyId}/proceed`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import { Box, FormControl, FormLabel, Select, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

const typeSupportOptions = [
  'Correção',
  'Prevenção/Mellhoria',
  'N.A'
]
export function CreatePipefyAccessForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      typesSupport: 'Tipo',
      description: 'Descreva o problema ou a sugestão',
      describePipesAccess: 'Descreva quais pipes a pessoa terá acesso',

    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.typesSupport} mt={3}>
        <FormLabel fontSize="sm">
          Tipo
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.typesSupport.value")}
          name="formData.typesSupport.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('typesSupport')}
        >
          {Object.entries(typeSupportOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.description} mt={3}>
        <FormLabel fontSize="sm">
          Descreva o problema ou a sugestão
        </FormLabel>
        <Textarea
          {...register('formData.description.value')}
          name='formData.description.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('description')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.describePipesAccess} mt={3}>
        <FormLabel fontSize="sm">
          Descreva quais pipes a pessoa terá acesso
        </FormLabel>
        <Textarea
          {...register('formData.describePipesAccess.value')}
          name='formData.describePipesAccess.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('describePipesAccess')}
        />
      </FormControl>

    </Box>
  )

}

import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, IconButton, Modal, ModalOverlay, Radio, RadioGroup, Stack, Text, useDisclosure, VStack } from "@chakra-ui/react"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { CreateRequestPatrimony } from "pages/Patrimony/components/CreateRequestPatrimony";
import { hireCltDriver } from "api/cltDrivers/hireCltDriver";

interface HireCltDriverProps {
  cltDriverId: string
}

export interface HireCltDriverSchema {
  hiringDocumentationChecklist: string[]
  hasRequestedMealFoodVoucher: string
  hasRequestedTicketLog: string
}


const hireCltDriverSchema = yup.object({
  hiringDocumentationChecklist: yup.array().min(1).of(yup.string()).nullable().required(),
  hasRequestedMealFoodVoucher: yup.string().required(),
  hasRequestedTicketLog: yup.string().required(),
})

const documentationChecklist = [
  { name: 'Comprovante de endereço' },
  { name: 'Carteira de vacinação do colaboradora' },
  { name: 'Carteira de Trabalho (CTPS)' },
  { name: 'Comprovante de escolaridade dos filhos' },
  { name: 'Comprovante de vacinação dos filhos' },
  { name: 'Certidão de Casamento' },
  { name: 'Certidão de nascimento dos filhos' },
]


export function HireCltDriver({cltDriverId}: HireCltDriverProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<HireCltDriverSchema>({
    resolver: yupResolver(hireCltDriverSchema)
  })

  const patrimonyRequested = searchParams.get('patrimonyRequested')
  const hasPatrimonyRequested = patrimonyRequested === 'true'

  const {
    isOpen: isCreateRequestPatrimonyModalOpen,
    onOpen: onOpenCreateRequestPatrimonyModal,
    onClose: onCloseCreateRequestPatrimonyModal
  } = useDisclosure()

  const { mutateAsync: hireCltDriverFn } = useMutation({
    mutationFn: hireCltDriver,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clts-drivers', cltDriverId] })

      searchParams.delete('patrimonyRequested')
      replace({ search: searchParams.toString() })

    }
  })

  async function handleHireCltDriver(values: HireCltDriverSchema) {
    await promiseMessage(hireCltDriverFn({
      body: {
        ...values,
        hasRequestedMealFoodVoucher: values.hasRequestedMealFoodVoucher === 'yes',
        hasRequestedTicketLog: values.hasRequestedTicketLog === 'yes'
      },
      cltDriverId
    }), 'Contratatação Realizada')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleHireCltDriver)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação</Heading>
      <FormControl mt={3} isInvalid={!!errors.hiringDocumentationChecklist}>
        <FormLabel fontSize='sm'>
          Documentação
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          control={control}
          name="hiringDocumentationChecklist"
          render={({ field }) => {
            return (
              <CheckboxGroup
                value={field.value}
                onChange={field.onChange}
              >
                <VStack
                  spacing={3}
                  p={2}
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="md"
                  align="left"
                >
                  {documentationChecklist.map(check => {
                    return (
                      <Checkbox key={check.name} value={check.name}>
                        <Text fontSize="sm">
                          {check.name}
                        </Text>
                      </Checkbox>
                    )
                  })}
                </VStack>

              </CheckboxGroup>
            )
          }}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRequestedMealFoodVoucher} mt={3}>
        <FormLabel>
          VA/VR Solicitado ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRequestedMealFoodVoucher"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRequestedTicketLog} mt={3}>
        <FormLabel>
          Ticket Log Solicitado ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRequestedTicketLog"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <Flex mt={3} alignItems="baseline" justifyContent="space-between">
        <FormLabel fontSize='sm'>
          Solicitação de patrimônio
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <IconButton
          aria-label="Criar solicitação de patrimônio"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenCreateRequestPatrimonyModal}
        />

      </Flex>

      <Modal
        isOpen={isCreateRequestPatrimonyModalOpen}
        onClose={onCloseCreateRequestPatrimonyModal}
        isCentered
      >
        <ModalOverlay />
        <CreateRequestPatrimony
          onCloseModal={onCloseCreateRequestPatrimonyModal}
          required={true}
        />
      </Modal>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || !hasPatrimonyRequested}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

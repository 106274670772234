import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Flex,
  Button,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiCheckCircle, FiEdit3, FiEye } from 'react-icons/fi'
import { useContext, useState, useEffect } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { AuthContext } from '../../../../contexts/AuthContext'
import { serviceSortByDate } from '../../../../utils/ServiceFunctions/serviceSortByDate'
import { serviceSortByHour } from '../../../../utils/ServiceFunctions/serviceSortByHourt'
import { TableFilterButton } from '../../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../../hooks/filterOptions/useFilterOptions'
import { FaUndoAlt } from 'react-icons/fa'
import { EmptyContentTableAlert } from '../../../Alerts/EmptyContentTableAlert'
import { differenceInMinutes, format, set } from 'date-fns'
import { formatDate } from '../../../../utils/DateFunctions/formatDate'
import { RequestedBusinessServiceProps } from '../../../../utils/RequestFunctions/Service/RequestedBusiness/requestRequestedBusinessServiceFunctions'
import { Address } from 'hooks/address/dtos/Address'
import { Collector } from 'hooks/collector/dtos/Collector'

interface ICollectBusinessServiceTableProps {
  requestedBusinessServices: RequestedBusinessServiceProps[]
  addresses: Address[]
  collectors: Collector[]
  refetch: () => void
}
const collectBusinessServiceOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'routeStartDate', value: 'Data Inicial da Rota', checked: false },
  { key: 'sourceCity', value: 'Cidade de Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade de Destino', checked: false },
  { key: 'geloSeco', value: 'Gelo Seco', checked: false },
  { key: 'routeStartHour', value: 'Horário Inicial da Rota', checked: false },
  { key: 'routeEndHour', value: 'Horário Final da Rota', checked: false },
  { key: 'collector', value: 'Coletador', checked: false },
  { key: 'driver', value: 'Motorista', checked: false },
]

const collectBusinessServiceOrderOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'routeStartDate', value: 'Data Inicial da Rota', checked: false },
  { key: 'routeStartHour', value: 'Horário Inicial da Rota', checked: false },
  { key: 'routeEndHour', value: 'Horário Final da Rota', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

const compareDateNowWithCollectDateAndFinalCollectHour = (
  collectDate: string,
  finalCollectHour: string,
) => {
  const [, finalCollectHourSplited] = format(
    new Date(finalCollectHour),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  ).split('T')
  const [hour, minutes] = finalCollectHourSplited.split(':')

  const differenceInMinutesDateNowWithCollectDateAndFinalCollectHour =
    differenceInMinutes(
      new Date(),
      set(new Date(collectDate), {
        hours: Number(hour),
        minutes: Number(minutes),
        seconds: 0,
      }),
    )

  return differenceInMinutesDateNowWithCollectDateAndFinalCollectHour > 0
}

export function CollectBusinessServiceTable({
  requestedBusinessServices,
  collectors,
  addresses,
  refetch,
}: ICollectBusinessServiceTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [routeStartDateFiltered, setRouteStartDateFiltered] = useState('')
  const [routeStartHourFiltered, setRouteStartHourFiltered] = useState('')
  const [routeEndHourFiltered, setRouteEndHourFiltered] = useState('')
  const [sourceCityFiltered, setSourceCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [geloSecoFiltered, setGeloSecoFiltered] = useState('')
  const [collectorFiltered, setCollectorFiltered] = useState('')
  const [driverFiltered, setDriverFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useContext(AuthContext)

  const noRecurrentRequestedBusinessServices = requestedBusinessServices.filter(requestedBusiness => requestedBusiness.is_recurrent === false)

  const toCollectAndCollectingBusinessServices = noRecurrentRequestedBusinessServices
    ?.filter(
      (requestedBusiness) =>
        requestedBusiness.serviceIDRequestedBusiness.is_business === true &&
        (requestedBusiness.serviceIDRequestedBusiness.step === 'toCollectBusinessService' ||
          requestedBusiness.serviceIDRequestedBusiness.step === 'collectingBusinessService')
    )



  const isUserLoggedDriver = userLogged?.user_type === 'MOTORISTA'

  const servicesFilteredByUserType = isUserLoggedDriver
    ? toCollectAndCollectingBusinessServices
      .filter((requestedBusiness) => requestedBusiness.driver_id === userLogged?.driver_id)
    : toCollectAndCollectingBusinessServices

  const servicesFilteredByUserInput = servicesFilteredByUserType.filter(requestedBusiness => {
    const sourceCollectorFiltered = collectors.find(collector =>
      collector.id === requestedBusiness.source_collector_id,
    )?.trading_name

    const destinationCityFilter = searchBoxFilter(
      addresses
        .filter(address =>
          requestedBusiness.destination_address_id.includes(
            address.id,
          ),
        )
        .map(address => address.cityIDAddress.name)
        .join(', '),
      destinationCityFiltered,
    )
    const sourceCityFilter = searchBoxFilter(
      addresses
        .filter((address) =>
          requestedBusiness.source_address_id.includes(
            address.id,
          ),
        )
        .map((address) => address.cityIDAddress.name)
        .join(', '),
      sourceCityFiltered,
    )
    const routeStartHourFilter = searchBoxFilter(
      formatDate.handle(
        requestedBusiness.route_start_hour,
        'DateOnlyWithHourMinute',
      ),
      routeStartHourFiltered,
    )
    const routeEndHourFilter = searchBoxFilter(
      formatDate.handle(
        requestedBusiness.route_end_hour,
        'DateOnlyWithHourMinute',
      ),
      routeEndHourFiltered,
    )
    const routeStartDateFilter = searchBoxFilter(
      formatDate.handle(
        requestedBusiness.route_start_date,
        'DateWithoutHourToInput',
      ),
      routeStartDateFiltered,
    )
    const geloSecoFilter = searchBoxFilter(
      requestedBusiness.gelo_seco.toString(),
      geloSecoFiltered,
    )
    const customerFilter = searchBoxFilter(
      requestedBusiness.customerIDBusinessService.trading_firstname,
      customerFiltered,
    )
    const protocolFilter = searchBoxFilter(
      String(requestedBusiness.serviceIDRequestedBusiness.protocol),
      protocolFiltered,
    )
    const collectorFilter = searchBoxFilter(
      sourceCollectorFiltered!,
      collectorFiltered,
    )

    const driver = `${requestedBusiness.driverIDBusinessService.firstname}
  ${requestedBusiness.driverIDBusinessService.lastname}`

    const driverFilter = searchBoxFilter(
      driver,
      driverFiltered
    )

    if (
      [
        customerFiltered,
        protocolFiltered,
        sourceCityFiltered,
        destinationCityFiltered,
        routeStartDateFiltered,
        routeStartHourFiltered,
        routeEndHourFiltered,
        geloSecoFiltered,
        collectorFiltered,
        driverFiltered
      ].every((value) => value === '')
    ) {
      return requestedBusiness
    }
    return (
      protocolFilter &&
      customerFilter &&
      sourceCityFilter &&
      geloSecoFilter &&
      destinationCityFilter &&
      routeStartDateFilter &&
      routeStartHourFilter &&
      routeEndHourFilter &&
      collectorFilter &&
      driverFilter
    )
  })

  console.log(servicesFilteredByUserInput, "asoidjas")

  const isOrderOptionSelected = orderOptionSelected.length > 0

  const searchedServicesSorted = isOrderOptionSelected
    ? servicesFilteredByUserInput.sort(
      (a, b) =>
        (orderOptionSelected.includes('protocol') &&
          a.serviceIDRequestedBusiness.protocol - b.serviceIDRequestedBusiness.protocol) ||
        (orderOptionSelected.includes('routeStartDate') &&
          serviceSortByDate(
            a.route_start_date,
            b.route_start_date,
          )) ||
        (orderOptionSelected.includes('routeStartHour') &&
          serviceSortByHour(
            a.route_start_hour,
            b.route_start_hour,
          )) ||
        (orderOptionSelected.includes('routeEndHour') &&
          serviceSortByHour(
            a.route_end_hour,
            b.route_end_hour,
          )) ||
        0,
    )
    : servicesFilteredByUserInput.sort(
      (dateA, dateB) =>
        serviceSortByDate(
          dateA.route_start_date,
          dateB.route_start_date,
        ) ||
        serviceSortByHour(
          dateA.route_end_hour,
          dateB.route_end_hour,
        ),
    )

  console.log(searchedServicesSorted, "SORTED")

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedServicesSorted.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(collectBusinessServiceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(collectBusinessServiceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])


  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleRefetchData = () => {
    return refetch()
  }

  function handleChangeSetter(key: string, value: string) {
    switch (key) {
      case 'protocol':
        return setProtocolFiltered(value)
      case 'customer':
        return setCustomerFiltered(value)
      case 'routeStartDate':
        return setRouteStartDateFiltered(value)
      case 'routeStartHour':
        return setRouteStartHourFiltered(value)
      case 'routeEndHour':
        return setRouteEndHourFiltered(value)
      case 'sourceCity':
        return setSourceCityFiltered(value)
      case 'destinationCity':
        return setDestinationCityFiltered(value)
      case 'geloSeco':
        return setGeloSecoFiltered(value)
      case 'collector':
        return setCollectorFiltered(value)
      case 'driver':
        return setDriverFiltered(value)
      default:
    }
  }

  console.log(searchedServicesSorted.length, "Length")

  return (
    <>
      {searchedServicesSorted.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <Stack direction="row">
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchData}>
              <Icon as={FaUndoAlt} />
            </Button>
          </Stack>
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  {collectBusinessServiceOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {searchedServicesSorted
                  .slice(offset, offset + Number(itemLimit))
                  .map((requestedBusiness, index) => {
                    const driver = `${requestedBusiness.driverIDBusinessService.firstname}
                    ${requestedBusiness.driverIDBusinessService.lastname}`

                    const sourceCities = Array.from(
                      new Set(
                        addresses
                          .filter(address =>
                            requestedBusiness.source_address_id.includes(
                              address.id,
                            ),
                          )
                          .map(address => address.cityIDAddress.name)
                      ),
                    )
                      .join(', ')
                      .toUpperCase()

                    const destinationCities = Array.from(
                      new Set(
                        addresses
                          .filter(address =>
                            requestedBusiness.destination_address_id.includes(
                              address.id,
                            ),
                          )
                          .map(address => address.cityIDAddress.name)
                      ),
                    )
                      .join(', ')
                      .toUpperCase()

                    const sourceCollectorFiltered = collectors.find(
                      (collector) =>
                        collector.id === requestedBusiness.source_collector_id,
                    )?.trading_name

                    return (
                      <Tr
                        key={requestedBusiness.id}
                        color={
                          compareDateNowWithCollectDateAndFinalCollectHour(
                            requestedBusiness.route_start_date,
                            requestedBusiness.route_end_hour,
                          )
                            ? 'red.400'
                            : requestedBusiness.serviceIDRequestedBusiness.serviceIDCollect.filter(
                              (service) =>
                                service.step === 'VALIDATEUNSUCCESS',
                            ).length > 0
                              ? 'blue.400'
                              : ''
                        }
                        fontWeight={
                          compareDateNowWithCollectDateAndFinalCollectHour(
                            requestedBusiness.route_start_date,
                            requestedBusiness.route_start_hour,
                          ) ||
                          requestedBusiness.serviceIDRequestedBusiness.serviceIDCollect.filter(
                              service => service.step === 'VALIDATEUNSUCCESS',
                            ).length > 0
                            ? 'bold'
                            : ''
                        }
                      >
                        <Td isNumeric>
                          {!!userLogged && (
                            <>
                              {userLogged.permissions.includes(
                                'validate-unsuccess-collect-service',
                              ) &&
                              requestedBusiness.serviceIDRequestedBusiness.serviceIDCollect.filter(
                                  (service) =>
                                    service.step === 'VALIDATEUNSUCCESS',
                                ).length > 0 && (
                                  <Link
                                    to={`/servicos/coletas-sem-sucesso/${requestedBusiness.service_id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiCheckCircle}
                                      fontSize="20"
                                      mr="2"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              {userLogged.permissions.includes(
                                'add-collect-business-service',
                              ) && (
                                  <Link
                                    to={`/servico-business/coleta/enderecos/${requestedBusiness.service_id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEdit3}
                                      fontSize="20"
                                      mr="2"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              <Link
                                to={`/servico-business/detalhes/${requestedBusiness.service_id}`}
                              >
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  mr="2"
                                  fontSize="20"
                                />
                              </Link>
                            </>
                          )}
                        </Td>
                        <Td fontSize="12">{requestedBusiness.serviceIDRequestedBusiness.protocol}</Td>
                        <Td fontSize="12">
                          {requestedBusiness.customerIDBusinessService.trading_firstname.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedBusiness.route_start_date,
                            'DateWithoutHourToShow',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {sourceCities}
                        </Td>
                        <Td fontSize="12">
                          {destinationCities}
                        </Td>
                        <Td fontSize="12">
                          {requestedBusiness.gelo_seco}
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedBusiness.route_start_hour,
                            'DateOnlyWithHourMinute',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedBusiness.route_end_hour,
                            'DateOnlyWithHourMinute',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {sourceCollectorFiltered!.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {driver.toUpperCase()}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

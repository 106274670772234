import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { Box, Flex, Button } from "@chakra-ui/react"
import { TextArea } from "components/Inputs/TextInput"
import { Input } from "components/Inputs/Input"

export interface CreateExternalComunicationFormInputs {
  subject: string
  content: string
}

const createExternalComunicationSchema = yup.object().shape({
  subject: yup.string().required('Assunto obrigatório'),
  content: yup.string().required('Descrição obrigatória'),
})

interface CreateExternalComunicationFormProps {
  onCreateExternalComunication: (values: CreateExternalComunicationFormInputs) => Promise<void>
}

export function CreateExternalComunicationForm({
  onCreateExternalComunication
}: CreateExternalComunicationFormProps) {

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<CreateExternalComunicationFormInputs>({
    resolver: yupResolver(createExternalComunicationSchema)
  })

  return (
    <Box
      as="form"
      p="3"
      gap="2"
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit(onCreateExternalComunication)}
      noValidate
    >

      <Input
        {...register("subject")}
        name="subject"
        placeholder="Digite o assunto..."
        size="md"
        rounded="md"
        variant="outline"
        label="Assunto"
        bgColor="white"
        error={errors.subject}
        required
      />

      <TextArea
        {...register("content")}
        name="content"
        placeholder="Descreva a dúvida/problema"
        label="Descrição"
        bgColor="white"
        variant="outline"
        error={errors.content}
        required
      />

      <Flex
        mt="2"
        w="full"
        justify="flex-end"
      >
        <Button
          size="sm"
          rounded="md"
          colorScheme="blue"
          type="submit"
        >
          Iniciar
        </Button>
      </Flex>

    </Box>
  )
}

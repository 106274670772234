import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { SearchBox } from 'components/SearchBox/SearchBox'
import { Address } from 'hooks/address/dtos/Address'
import { Collector } from 'hooks/collector/dtos/Collector'
import { ForwardRefRenderFunction, forwardRef, ReactNode, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { searchBoxFilter } from 'utils/searchBoxFilter'
import { ProviderProps } from '../../contexts/ProviderContext'
import { UserProps } from '../../contexts/UserContext'
import { useCity } from '../../hooks/city/useCity'
import { CitiesProps } from '../../services/getFunctions/city/getCity'
import {
  IIbgeApiCityProps,
  IIbgeApiStatesProps,
} from '../../services/ibgeApiservice/IIbgeApi'
import { budgetFormatArrayToSelect } from '../../utils/BudgetFunctions/budgetFormatArrayToSelect'
import { StatesWithUfListProps } from '../../utils/CustomLists/statesWithUfList'
import { BusinessBudgetProps } from '../../utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions'

export interface Options {
  key: string,
  value: string | number | readonly string[],
  showOption: ReactNode,
  bold?: boolean
  disabled?: boolean
}


interface HubProps {
  id: string
  name: string
  situation: string
}

interface TypeProps {
  id: number
  name: string
}

type SituationProps = TypeProps

type PaymentProps = TypeProps

type PaymentTypeProps = TypeProps

type EmissionTypes = TypeProps

type ContributionTypes = TypeProps

type PositiveNegativeProps = TypeProps

type CitiesBackProps = HubProps

type MaterialListProps = TypeProps

type ModalTypeProps = TypeProps

type UserTypeProps = {
  id: string;
  name: string;
}

type DeadlineDeliveryProps = TypeProps

type ServiceTypeProps = TypeProps

type VehicleTypeProps = TypeProps

type FindByProps = TypeProps

type IssProps = TypeProps

type MeasureProps = TypeProps

type DispatchSenderProps = TypeProps

type LogFilterProps = TypeProps

type DirectionProps = TypeProps

type BusinessMeetingOptionsProps = TypeProps

type RiskRatingProps = TypeProps

type RncTypesProps = TypeProps

interface CustomerProps {
  id: string
  situation: string
  trading_firstname: string
  company_lastname: string
}

type CustomerFirstNameProps = Omit<CustomerProps, 'company_lastname'>

interface ShippingProps {
  id: string
  company_name: string
}

interface PermissionProps {
  key: string
  value: string
}

type ServiceStepsProps = PermissionProps

type NumbersListProps = {
  key: number
  value: number
}

type MonthsOfYearProps = {
  key: number
  value: string
}

interface WeekDaysProps {
  value: number
  name: string
}

interface DriverProps {
  id: string
  situation: string
  firstname: string
  lastname: string
}

interface BudgetProps {
  id: string
  situation: string
  source_cities: string[]
  destination_cities: string[]
  sourceHubIDBudget: {
    name: string
  }
  destinationHubIDBudget: {
    name: string
  }
  service_type: string
  vehicle: string
  modal: string
  gelox: number
  gelo_seco: number
  franchising: number
  observation: string
  price: number
}

interface SourceCollectorProps {
  id: string
  company_name: string
  trading_name: string
  situation: string
}

type DestinationCollectorProps = SourceCollectorProps

interface SourceBranchProps {
  id: string
  nickname: string
  shippingIDBranch: {
    company_name: string
  }
}

type DestinationBranchProps = SourceBranchProps

interface InputProps {
  id: string
  name: string
  situation: string
}

interface FilterByProps {
  id: number
  value: string
}

interface OcurrenceDriversProps {
  id: number
  value: string
}

interface IntercurrenceTypeProps {
  id: number
  value: string
}

interface IRegionalProps {
  value: number
  name: string
}

export interface ICategoryTypesPros {
  key: string,
  value: string,
  showValue: string,
}

interface IRouteTypesProps {
  id: number
  value: string
}

interface IRequestedServiceMaterialTypes {
  id: number
  value: string
}

interface SelectProps extends ChakraSelectProps {
  name: string
  states?: IIbgeApiStatesProps[]
  hubs?: HubProps[]
  types?: TypeProps[]
  situations?: SituationProps[]
  payment_conditions?: PaymentProps[]
  payment_types?: PaymentTypeProps[]
  emission_types?: EmissionTypes[]
  contribution_types?: ContributionTypes[]
  negative_positive?: PositiveNegativeProps[]
  citiesIBGE?: IIbgeApiCityProps[]
  collectors?: Collector[]
  customers?: CustomerProps[]
  citiesBack?: CitiesBackProps[]
  material_list?: MaterialListProps[]
  modal_types?: ModalTypeProps[]
  shipping?: ShippingProps[]
  permissions_list?: PermissionProps[]
  user_types?: UserTypeProps[]
  drivers?: DriverProps[]
  customers_first_name?: CustomerFirstNameProps[]
  deadline_delivery?: DeadlineDeliveryProps[]
  service_types?: ServiceTypeProps[]
  vehicle_types?: VehicleTypeProps[]
  budgets?: BudgetProps[]
  businessBudgets?: BusinessBudgetProps[]
  sourceCollector?: SourceCollectorProps[]
  destinationCollector?: DestinationCollectorProps[]
  sourceBranch?: SourceBranchProps[]
  destinationBranch?: DestinationBranchProps[]
  providerList?: ProviderProps[]
  providerAddress?: ProviderProps[]
  find_by_options?: FindByProps[]
  days_of_week?: WeekDaysProps[]
  iss_options?: IssProps[]
  measure_options?: MeasureProps[]
  inputs?: InputProps[]
  service_steps?: ServiceStepsProps[]
  filter_by?: FilterByProps[]
  occurrence_types?: OcurrenceDriversProps[]
  intercurrence_types?: IntercurrenceTypeProps[]
  users?: UserProps[]
  truck_flight?: TruckFlightProps[]
  dispatch_sender?: DispatchSenderProps[]
  business_meeting_options?: BusinessMeetingOptionsProps[]
  risk_rating?: RiskRatingProps[]
  rnc_types?: RncTypesProps[]
  label?: string
  error?: FieldError
  inputsSelected?: string[]
  logFilterOptions?: LogFilterProps[]
  direction?: DirectionProps[]
  regional_list?: IRegionalProps[]
  numbersList?: NumbersListProps[]
  monthsOfYear?: MonthsOfYearProps[]
  addresses?: Address[]
  categoryTypes?: ICategoryTypesPros[]
  routeTypes?: IRouteTypesProps[]
  requestedServiceMaterialTypes?: IRequestedServiceMaterialTypes[]
  statesWithUf?: StatesWithUfListProps
  required?: boolean
  selected?: boolean
  stateSelected?: string
  isLoading?: boolean
  children?: ReactNode
  options?: Options[]
  hasFilter?: boolean
}

interface TruckFlightProps {
  id: number
  truck_flight_to_input: string
}

function businessBudgetFormatDataToSelect(
  businessBudgets: BusinessBudgetProps[],
  cities: CitiesProps[],
): BusinessBudgetProps[] {
  const businessBudgetsToSelectInput = businessBudgets
    .map((budget) => {
      return {
        ...budget,
        source_cities: cities
          .filter((city) => budget.source_cities.includes(city.id))
          .map((city) => city.name),
        destination_cities: cities
          .filter((city) => budget.destination_cities.includes(city.id))
          .map((city) => city.name),
      }
    })
    .sort(
      (a, b) =>
        a.source_cities.toString().localeCompare(b.source_cities.toString()) ||
        a.destination_cities
          .toString()
          .localeCompare(b.destination_cities.toString()) || a.monthly_km_franchising - b.monthly_km_franchising,
    )

  return businessBudgetsToSelectInput
}

const SelectInputBase: ForwardRefRenderFunction<
  HTMLSelectElement,
  SelectProps
> = (
  {
    name,
    label,
    states,
    hubs,
    types,
    situations,
    citiesIBGE,
    payment_conditions,
    payment_types,
    emission_types,
    contribution_types,
    negative_positive,
    collectors,
    customers,
    citiesBack,
    material_list,
    modal_types,
    shipping,
    permissions_list,
    user_types,
    drivers,
    customers_first_name,
    deadline_delivery,
    service_types,
    vehicle_types,
    budgets,
    businessBudgets,
    sourceCollector,
    destinationCollector,
    sourceBranch,
    destinationBranch,
    providerList,
    providerAddress,
    find_by_options,
    days_of_week,
    iss_options,
    measure_options,
    inputs,
    service_steps,
    filter_by,
    occurrence_types,
    intercurrence_types,
    users,
    truck_flight,
    dispatch_sender,
    required,
    inputsSelected,
    logFilterOptions,
    direction,
    business_meeting_options,
    risk_rating,
    rnc_types,
    regional_list,
    numbersList,
    monthsOfYear,
    addresses,
    categoryTypes,
    routeTypes,
    requestedServiceMaterialTypes,
    statesWithUf,
    selected = false,
    stateSelected,
    error = null,
    children,
    isLoading,
    options,
    hasFilter = false,
    ...rest
  },
  ref,
  ) => {
    const {
      cities: { data: cities },
    } = useCity(null, !!budgets || !!businessBudgets, false)
    const [budgetFiltered, setBudgetFiltered] = useState('')
    return (
      <FormControl isInvalid={!!error}>
        {label && (
          <Flex flex="1" justify="space-between" >
            <FormLabel htmlFor={name} noOfLines={1}>
              <Flex h="full" w="full" gap="1" align="center">
                <Text fontSize="sm" color="gray.700" w="full">
                  {label}
                </Text>
                {required && (
                  <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                    {' '}
                    *
                  </Text>
                )}
              </Flex>
            </FormLabel>
          </Flex>
        )}
        {hasFilter && (
          <SearchBox
            isCheckbox={true}
            size="sm"
            placeholder="Buscar orçamento..."
            handleSearch={(e) =>
              setBudgetFiltered(e.target.value)
            }
          />
        )}
        <ChakraSelect
          name={name}
          id={name}
          focusBorderColor="blue.500"
          bgColor="gray.100"
          variant="filled"
          _hover={{
            bgcolor: 'gray.900',
          }}
          required={required}
          size="lg"
          ref={ref}
          {...rest}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {children}
              {options && options.map(option => {
                if (option.bold) {
                  return (
                    <option style={{ fontWeight: 'bold' }} key={option.key} value={option.value} disabled={option.disabled}>
                      {option.showOption}
                    </option>
                  )
                }
                return (
                  <option key={option.key} value={option.value} disabled={option.disabled}>
                    {option.showOption}
                  </option>
                )
              })}
              {!!states &&
                states?.map((state) => (
                  <option value={state.nome.toUpperCase()} key={state.id}>
                    {state.nome.toUpperCase()}
                  </option>
                ))}
              {!!hubs &&
                hubs
                  .filter((hub) => hub.situation === 'ATIVO')
                  .map((hub) => (
                    <option value={hub.id} key={hub.id}>
                      {hub.name}
                    </option>
                  ))}
              {!!types &&
                types.map((type) => (
                  <option value={type.name} key={type.id}>
                    {type.name}
                  </option>
                ))}
              {!!situations &&
                situations.map((situation) => (
                  <option value={situation.name} key={situation.id}>
                    {situation.name}
                  </option>
                ))}
              {!!citiesIBGE &&
                citiesIBGE.map((city) => (
                  <option value={city.nome.toUpperCase()} key={city.id}>
                    {city.nome.toUpperCase()}
                  </option>
                ))}
              {!!payment_conditions &&
                payment_conditions.map((payment) => (
                  <option value={payment.name} key={payment.id}>
                    {payment.name}
                  </option>
                ))}
              {!!payment_types &&
                payment_types.map((payment) => (
                  <option value={payment.name} key={payment.id}>
                    {payment.name}
                  </option>
                ))}
              {!!emission_types &&
                emission_types.map((emission) => (
                  <option value={emission.name} key={emission.id}>
                    {emission.name}
                  </option>
                ))}
              {!!contribution_types &&
                contribution_types.map((contribution) => (
                  <option value={contribution.name} key={contribution.id}>
                    {contribution.name}
                  </option>
                ))}
              {!!negative_positive &&
                negative_positive.map((options) => (
                  <option value={options.name} key={options.id}>
                    {options.name}
                  </option>
                ))}
              {!!collectors &&
                collectors
                  .filter((collector) => collector.situation === 'ATIVO')
                  .sort((a, b) => a.trading_name.localeCompare(b.trading_name))
                  .map((collector) => (
                    <option value={collector.id} key={collector.id}>
                      {collector.trading_name.toUpperCase()}
                    </option>
                  ))}
              {!!customers &&
                customers
                  .filter((customer) => customer.situation === 'ATIVO')
                  .sort((a, b) =>
                    a.trading_firstname.localeCompare(b.trading_firstname),
                  )
                  .map((customer) => (
                    <option value={customer.id} key={customer.id}>
                      {`${customer.trading_firstname} - ${customer.company_lastname}`}
                    </option>
                  ))}
              {!!citiesBack &&
                citiesBack
                  .filter((city) => city.situation === 'ATIVO')
                  .map((city) => (
                    <option value={city.id} key={city.id}>
                      {city.name.toUpperCase()}
                    </option>
                  ))}
              {!!material_list &&
                material_list.map((material) => (
                  <option value={material.name} key={material.id}>
                    {material.name}
                  </option>
                ))}
              {!!modal_types &&
                modal_types.map((modal) => (
                  <option value={modal.name} key={modal.id}>
                    {modal.name}
                  </option>
                ))}
              {!!shipping &&
                shipping
                  .sort((a, b) => a.company_name.localeCompare(b.company_name))
                  .map((ship) => (
                    <option value={ship.id} key={ship.id}>
                      {ship.company_name}
                    </option>
                  ))}
              {!!permissions_list &&
                permissions_list.map((permission) => (
                  <option value={permission.value} key={permission.value}>
                    {permission.key}
                  </option>
                ))}
              {!!user_types &&
                user_types.map((user) => (
                  <option value={user.name.toUpperCase()} key={user.id}>
                    {user.name}
                  </option>
                ))}
              {!!drivers &&
                drivers
                  .filter((driver) => driver.situation === 'ATIVO')
                  .map((driver) => (
                    <option
                      value={driver.id}
                      key={driver.id}
                    >{`${driver.firstname} ${driver.lastname}`}</option>
                  ))}
              {!!customers_first_name &&
                customers_first_name
                  .filter((customer) => customer.situation === 'ATIVO')
                  .map((customer) => (
                    <option value={customer.id} key={customer.id}>
                      {customer.trading_firstname}
                    </option>
                  ))}
              {!!deadline_delivery &&
                deadline_delivery.map((deadline) => (
                  <option value={deadline.id} key={deadline.id}>
                    {deadline.name}
                  </option>
                ))}
              {!!service_types &&
                service_types.map((service) => (
                  <option value={service.name} key={service.id}>
                    {service.name}
                  </option>
                ))}
              {!!vehicle_types &&
                vehicle_types.map((vehicle) => (
                  <option value={vehicle.name} key={vehicle.id}>
                    {vehicle.name}
                  </option>
                ))}
              {!!budgets &&
                budgetFormatArrayToSelect(budgets, cities || [])?.filter((budget) => {
                  const budgetFilter = searchBoxFilter(
                    `${budget.source_cities.join(
                      ', ',
                    )} > ${budget.destination_cities.join(', ')} | ${budget.service_type
                      } |
            ${budget.vehicle} | ${budget.modal} | ${budget.franchising
                      }KG | GELO SECO: ${budget.gelo_seco} | GELOX: ${budget.gelox
                      } | ${budget.observation}
            `.toUpperCase(),
                    budgetFiltered,
                  )

                  if (budgetFiltered === '') return budget

                  return budgetFilter && budget
                })?.map((budget) => {
                  return (
                    <option value={budget.id} key={budget.id}>
                      {`${budget.source_cities.join(
                        ', ',
                      )} > ${budget.destination_cities.join(', ')} | ${budget.service_type
                        } |
              ${budget.vehicle} | ${budget.modal} | ${budget.franchising
                        }KG | GELO SECO: ${budget.gelo_seco} | GELOX: ${budget.gelox
                        } | ${budget.observation}
              `.toUpperCase()}
                    </option>
                  )
                })}

              {!!businessBudgets &&
                businessBudgetFormatDataToSelect(businessBudgets, cities || [])?.map(
                  (budget) => {
                    return (
                      <option value={budget.id} key={budget.id}>
                        {`${budget.route_nickname} | ${budget.source_cities.join(
                          ', ',
                        )} > ${budget.destination_cities.join(', ')} | ${budget.vehicle
                          } | GELO SECO: ${budget.gelo_seco} | GELOX: ${budget.gelox
                          } | ${budget.observation}
              `.toUpperCase()}
                      </option>
                    )
                  },
                )}
              {!!sourceCollector &&
                sourceCollector
                  .filter((collector) => collector.situation === 'ATIVO')
                  .map((sCollector) => (
                    <option
                      value={sCollector.id}
                      key={sCollector.id}
                    >{`${sCollector.company_name} - ${sCollector.trading_name}`}</option>
                  ))}
              {!!destinationCollector &&
                destinationCollector
                  .filter((collector) => collector.situation === 'ATIVO')
                  .map((dCollector) => (
                    <option
                      value={dCollector.id}
                      key={dCollector.id}
                    >{`${dCollector.company_name} - ${dCollector.trading_name}`}</option>
                  ))}
              {!!sourceBranch &&
                sourceBranch.map((sBranch) => (
                  <option
                    value={sBranch.id}
                    key={sBranch.id}
                  >{`${sBranch.nickname} - ${sBranch.shippingIDBranch.company_name}`}</option>
                ))}
              {!!destinationBranch &&
                destinationBranch.map((dBranch) => (
                  <option
                    value={dBranch.id}
                    key={dBranch.id}
                  >{`${dBranch.nickname} - ${dBranch.shippingIDBranch.company_name}`}</option>
                ))}
              {!!providerList &&
                providerList
                  .filter((provider) => provider.situation === 'ATIVO')
                  .map((provider) => (
                    <option
                      value={provider.id}
                      key={provider.id}
                    >{`${provider.company_name} - ${provider.trading_name}`}</option>
                  ))}
              {!!providerAddress &&
                providerAddress
                  .filter((provider) => provider.situation === 'ATIVO')
                  .map((provider) => (
                    <option
                      value={provider.id}
                      key={provider.id}
                    >{`| ${provider.trading_name} | ${provider.street} | ${provider.number
                      } | ${provider.neighborhood} | ${provider.city} | ${provider.cep
                      }`.toUpperCase()}</option>
                  ))}
              {!!find_by_options &&
                find_by_options.map((option) => (
                  <option value={option.name} key={option.id}>
                    {option.name}
                  </option>
                ))}
              {!!iss_options &&
                iss_options.map((option) => (
                  <option value={option.name} key={option.id}>
                    {option.name}
                  </option>
                ))}
              {!!measure_options &&
                measure_options.map((measure) => (
                  <option value={measure.name} key={measure.id}>
                    {measure.name}
                  </option>
                ))}
              {!!inputs &&
                inputs
                  .filter((input) => input.situation === 'ATIVO')
                  .map((input) => {
                    if (input.name === 'GELO SECO') {
                      return (
                        <option
                          value={input.name}
                          key={input.id}
                          disabled={inputsSelected?.includes(input.id)}
                        >
                          {input.name}
                        </option>
                      )
                    }

                    return (
                      <option
                        value={input.id}
                        key={input.id}
                        disabled={inputsSelected?.includes(input.id)}
                      >
                        {input.name}
                      </option>
                    )
                  })}
              {!!days_of_week &&
                days_of_week.map((day) => (
                  <option value={day.value} key={day.value}>
                    {day.name}
                  </option>
                ))}
              {!!service_steps &&
                service_steps.map((step) => (
                  <option value={step.key} key={step.key}>
                    {step.value}
                  </option>
                ))}
              {!!filter_by &&
                filter_by.map((filter) => (
                  <option value={filter.value} key={filter.id}>
                    {filter.value}
                  </option>
                ))}
              {!!occurrence_types &&
                occurrence_types.map((occurrence) => (
                  <option value={occurrence.value} key={occurrence.id}>
                    {occurrence.value}
                  </option>
                ))}
              {!!intercurrence_types &&
                intercurrence_types.map((intercurrence) => (
                  <option value={intercurrence.value} key={intercurrence.id}>
                    {intercurrence.value}
                  </option>
                ))}
              {!!truck_flight &&
                truck_flight.map((data) => (
                  <option value={data.truck_flight_to_input} key={data.id}>
                    {data.truck_flight_to_input}
                  </option>
                ))}
              {!!dispatch_sender &&
                dispatch_sender.map((sender) => (
                  <option value={sender.name} key={sender.id}>
                    {sender.name}
                  </option>
                ))}
              {!!logFilterOptions &&
                logFilterOptions.map((option) => (
                  <option value={option.name} key={option.id}>
                    {option.name}
                  </option>
                ))}
              {!!direction &&
                direction.map((option) => (
                  <option value={option.name} key={option.id}>
                    {option.name}
                  </option>
                ))}
              {!!users &&
                users
                  .filter(
                    (responsible) =>
                      responsible.loglife_employee === true &&
                      responsible.situation === 'ATIVO',
                  )
                  .map((responsible) => (
                    <option value={responsible.id} key={responsible.id}>
                      {`${responsible.firstname} ${responsible.lastname}`.toUpperCase()}
                    </option>
                  ))}
              {!!business_meeting_options &&
                business_meeting_options.map((meeting) => (
                  <option value={meeting.name} key={meeting.id}>
                    {meeting.name.toUpperCase()}
                  </option>
                ))}
              {!!risk_rating &&
                risk_rating.map((risk) => (
                  <option value={risk.name} key={risk.id}>
                    {risk.name.toUpperCase()}
                  </option>
                ))}
              {!!rnc_types &&
                rnc_types.map((type) => (
                  <option value={type.name} key={type.id}>
                    {type.name.toUpperCase()}
                  </option>
                ))}
              {!!regional_list &&
                regional_list.map((regional) => (
                  <option value={regional.value} key={regional.value}>
                    {regional.name.toUpperCase()}
                  </option>
                ))}
              {!!numbersList &&
                numbersList.map((numbers) => (
                  <option value={numbers.value} key={numbers.key}>
                    {numbers.value}
                  </option>
                ))}
              {!!monthsOfYear &&
                monthsOfYear.map((months) => (
                  <option value={months.key} key={months.key}>
                    {months.value.toUpperCase()}
                  </option>
                ))}
              {!!addresses &&
                addresses.map((address) => (
                  <option value={address.id} key={address.id}>
                    {`${address.trading_name} | ${address.branch} | ${address.street
                      } | ${address.number} | ${address.neighborhood} | ${address.complement ?? '-'} | ${address.reference_point ?? '-'} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name}
`.toUpperCase()}
                  </option>
                ))}
              {!!categoryTypes &&
                categoryTypes.map((route) => (
                  <option value={route.value} key={route.key}>
                    {route.value.toUpperCase()}
                  </option>
                ))}
              {!!routeTypes &&
                routeTypes.map((route) => (
                  <option value={route.value} key={route.id}>
                    {route.value.toUpperCase()}
                  </option>
                ))}
              {!!requestedServiceMaterialTypes &&
                requestedServiceMaterialTypes.map((materialType) => (
                  <option value={materialType.value} key={materialType.id}>
                    {materialType.value.toUpperCase()}
                  </option>
                ))}
              {!!statesWithUf &&
                Object.entries(statesWithUf).map(([key, value]) => (
                  <option value={key} key={key}>
                    {value.uf.toUpperCase()}
                  </option>
                ))}
            </>
          )}
        </ChakraSelect>

        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    )
  }

export const Select = forwardRef(SelectInputBase)

import { useEffect } from "react"
import { Flex, Button } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { DismissalAggregateFormInputs, DismissalAggregateForm } from './components/DismissalAggregateForm'

import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
  id: string
}
const dismissalAggregateForm = yup.object().shape({
  hasLogisticsDirectorApproved: yup.mixed().required('Campo obrigatório'),
  endContractDate: yup.string().required('Campo obrigatório'),
})

export function DismissalAggregatePage() {
  const { id } = useParams<ParamsProps>()
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const { push: redirect } = useHistory()

  const userHasPermissionToResignAggregate = userLogged.permissions.includes('resign-aggregate')

  useEffect(() => {
    if (!userHasPermissionToResignAggregate) {
      redirect('/')
    }
  }, [userHasPermissionToResignAggregate, redirect])

  const formMethods = useForm<DismissalAggregateFormInputs>({
    resolver: yupResolver(dismissalAggregateForm)
  })
  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const { dismissalAggregate: { mutateAsync: dismissalAggregate } } = useAggregateFunctions()

  async function handleDismissalAggregate({ ...values }: DismissalAggregateFormInputs) {
    await promiseMessage(dismissalAggregate({ aggregateId: id, input: values }, {
      onSuccess: async () => {
        redirect("/agregados")
      }
    }), 'Agregado adicionado em desligamento com sucesso!')
  }

  return (
    <StandardBackgroundForm title="Desligamento de agregado" onSubmit={handleSubmit(handleDismissalAggregate)}>
      <FormProvider {...formMethods}>
        <DismissalAggregateForm />
      </FormProvider>
      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

import { Regional } from "api/regionals/_types/Regional";
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Vaccine } from "./vaccine";

interface GetVaccineParams {
  vaccineId: string
}

interface GetVaccineResponse {
  vaccine: Vaccine & {
    regional: Regional & {
      responsible: User
    }
    cardFrontPhotoAttachment: Attachment
    cardBackPhotoAttachment: Attachment
  }
}

export async function getVaccine({ vaccineId }: GetVaccineParams) {
  try {
    const response = await api.get<GetVaccineResponse>(`/vaccines/${vaccineId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import {
  Button,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { PurchaseOrderPmcForm, PurchaseOrderPmcFormInputs } from "./components/PurchaseOrderPmcForm";
import { usePurchaseOrdersSocket } from "hooks/purchaseOrder/usePurchaseOrdersSocket";
import { getPmcsWaitingReceive } from "api/pmcs/getPmcsWaitingReceive";
import { createPurchaseOrderPmc } from "api/pmcs/createPmcPurchaseOrder";


const createPurchaseOrderPmcSchema = yup.object().shape({
  pmcId: yup.string().required('Campo Obrigatório'),
  type: yup.string().required('Campo Obrigatório'),
  purchaseDescription: yup.string().required('Campo Obrigatório'),
})

export function CreatePurchaseOrderPmcPage() {
  const formMethods = useForm<PurchaseOrderPmcFormInputs>({
    resolver: yupResolver(createPurchaseOrderPmcSchema)
  })

  const { push: redirect } = useHistory()
  const { purchaseOrderSocketConnection } = usePurchaseOrdersSocket()

  const { userLogged } = useAuth()

  const userHasPermissionToCreatePmc =
    userLogged?.permissions.includes('create-purchase-order-pmc')

  useEffect(() => {
    if (!userHasPermissionToCreatePmc) {
      redirect('/')
    }
  }, [userHasPermissionToCreatePmc, redirect])

  const { handleSubmit } = formMethods

  const { data: pmcsWaitingReceiveData, isFetching:isFetchingPmcsWaitingReceiveData } = useQuery({
    queryKey: ['pmcs-waiting-receive'],
    queryFn: getPmcsWaitingReceive
  })


  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutateAsync: createPurchaseOrderPmcFn } = useMutation({
    mutationFn: createPurchaseOrderPmc
  })

  async function handleCreatePurchaseOrderPMC(values: PurchaseOrderPmcFormInputs) {

    await promiseMessage(createPurchaseOrderPmcFn({
      pmcId: values.pmcId,
      body: {
        purchaseDescription: values.purchaseDescription,
        type: values.type
      }
    }, {
      onSuccess: async (data) => {
        data.purchaseOrders.forEach((purchaseOrder) => {
          purchaseOrderSocketConnection.emit('purchaseOrderCreated', {
            profiles: data.notificationProfiles,
            purchaseOrderProtocol: purchaseOrder.protocol,
          })
        })
        redirect('/compras/pedidos/aguardando-cotacao')
        await queryClient.invalidateQueries(['purchaseOrdersAwaitingQuote'])
      }
    }), 'Pedido de compra pmc criado com sucesso!')

  }

  return (
    <StandardBackgroundForm title="Criar Pedido de Compra PMC" onSubmit={handleSubmit(handleCreatePurchaseOrderPMC)}>
      {isFetchingPmcsWaitingReceiveData ? <Spinner /> :
        <FormProvider {...formMethods}>
          <PurchaseOrderPmcForm pmcs={pmcsWaitingReceiveData.pmcs} />
        </FormProvider>
      }
      <Flex justify='flex-end' mt={4}>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Solicitar Pedido de Compra PMC
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}



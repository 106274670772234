import { Flex, Box, Heading } from '@chakra-ui/react'

import { AuthContext } from '../../contexts/AuthContext'
import { useContext, useEffect, useState } from 'react'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { LogTable } from '../../components/Tables/Log/LogTable'
import { useLog } from '../../hooks/log/useLog'
import { useHistory } from 'react-router-dom'

export function LogList() {
  const [isLoading, setIsLoading] = useState(true)

  const { userLogged } = useContext(AuthContext)
  const history = useHistory()

  const {
    logs: { data: logs, isLoading: isLogLoading },
  } = useLog(true, false)

  useEffect(() => {
    function run() {
      if (!isLogLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isLogLoading])

  useEffect(() => {
    if (userLogged) {
      history.push('/')
    }
  }, [history, userLogged])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Logs
            </Heading>
          </Flex>
          {!!logs && <LogTable logs={logs} />}
        </Box>
      </Flex>

  )
}

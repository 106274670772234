import { createContext, ReactNode, useCallback, useState } from "react";

export type AggregatesTableButtonStatus = 'active' | 'in-qualification' | 'in-resigning' | 'inactive'

interface AggregatesStatusTableButtonContextProps {
  status: AggregatesTableButtonStatus
  handleChangeStatus: (status: AggregatesTableButtonStatus) => void
}

interface AggregatesStatusTableButtonContextProviderProps {
  children: ReactNode
}

export const AggregatesStatusTableButtonContext = createContext(
  {} as AggregatesStatusTableButtonContextProps
)

export function AggregatesStatusTableButtonProvider({
  children
}: AggregatesStatusTableButtonContextProviderProps) {
  const [status, setStatus] = useState<AggregatesTableButtonStatus>('active')

  const handleChangeStatus = useCallback((status: AggregatesTableButtonStatus) => {
    setStatus(status)
  }, [])

  return (
    <AggregatesStatusTableButtonContext.Provider value={{
      status,
      handleChangeStatus
    }}>
      {children}
    </AggregatesStatusTableButtonContext.Provider>
  )
}

import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface BugCardContentSubjectProps extends FlexProps {
  children: ReactNode
  subject: string
}

export function BugCardContentSubject({
  children,
  subject,
  ...rest
}: BugCardContentSubjectProps) {
  return (
    <Flex
      w="calc(100% - 24px)"
      align="center"
      gap="2"
      {...rest}
    >
      {children}

      <Text
        fontSize="xs"
      >
        {subject}
      </Text>
    </Flex>
  )
}

import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Alert,
  AlertIcon,
  Icon,
} from '@chakra-ui/react'
import { useEffect, useContext, useState, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Link } from 'react-router-dom'
import { useHistory, useLocation, useParams } from 'react-router'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Input } from '../../../components/Inputs/Input'

import AvailableAccordion from '../../../components/Accordions/Service/AvailableAccordion/AvailableAccordion'


import { AuthContext } from '../../../contexts/AuthContext'

import { availableServiceFormatDataToBack } from '../../../utils/ServiceFunctions/Available/availableServiceFormatDataToBack'
import { serviceFormatDateToFront } from '../../../utils/ServiceFunctions/serviceFormatDateToFront'
import { serviceFormatHourToFront } from '../../../utils/ServiceFunctions/serviceFormatHourToFront'

import { useService } from '../../../hooks/services/service'
import { FiPlus } from 'react-icons/fi'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAvailableServiceFunctions } from '../../../hooks/services/available/useAvailableServiceFunctions'
import { AvailableServiceProps } from '../../../services/getFunctions/getAvailableServiceFunctions'

interface FormInputProps {
  service_id: string
  collector_id: string
  landing_availability_date: string
  landing_availability_hour: string
  observation: string
}

interface QueryParams {
  service_id: string
  slug: string
}

interface ILocationProps {
  state: {
    collector_id: string
  }
}

interface IAvailableServiceFormProps {
  isDisabled?: boolean
}

const userSchema = yup.object().shape({
  service_id: yup.string().required('Serviço inválido'),
  landing_availability_date: yup
    .string()
    .nullable()
    .required('Campo Obrigatório'),
  landing_availability_hour: yup
    .string()
    .nullable()
    .required('Campo Obrigatório'),
  observation: yup.string(),
})

export function AvailableService({
  isDisabled = false,
}: IAvailableServiceFormProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const [availableServiceById, setAvailableServiceById] = useState<
    AvailableServiceProps[]
  >([])

  const {
    createAvailableService: { mutateAsync: createAvailableService },
    updateAvailableService: { mutateAsync: updateAvailableService },
  } = useAvailableServiceFunctions()
  const { userLogged } = useContext(AuthContext)

  const { confirmMessage, standardMessage } = useSwal()

  const { service_id, slug }: QueryParams = useParams()
  const { state }: ILocationProps = useLocation()
  const history = useHistory()

  const location = useLocation()
  const resState = location.state as { path?: string }
  const hasPathRedirect = resState?.path !== undefined && resState?.path !== null

  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(userSchema),
  })

  const collectorId = watch('collector_id')

  const availableServiceByCollectorId = useMemo(() => {
    const availableServiceCollectorValue = availableServiceById.map(
      (availableService) => availableService.collector_id,
    )

    if (!availableServiceCollectorValue[0]) {
      return availableServiceById.filter(
        (availableService) => availableService.collector_id === null,
      )
    } else {
      return availableServiceById.filter(
        (availableService) => availableService.collector_id === collectorId,
      )
    }
  }, [collectorId, availableServiceById])

  useEffect(() => {
    function run() {
      if (!isServiceLoading) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isServiceLoading])

  useEffect(() => {
    if (serviceById) {
      const crossdockingCollectorId =
        serviceById.serviceIDRequested.crossdocking_collector_id
      const sourceCollectorId = String(
        serviceById.serviceIDRequested.source_collector_id,
      )

      if (state?.collector_id) {
        return setValue('collector_id', state?.collector_id)
      }

      if (!crossdockingCollectorId) {
        setValue('collector_id', sourceCollectorId)
      } else {
        if (
          availableServiceById.filter(
            (availableService) =>
              availableService.collector_id === crossdockingCollectorId,
          ).length === 0
        ) {
          setValue('collector_id', crossdockingCollectorId)
        } else {
          setValue('collector_id', sourceCollectorId)
        }
      }
    }
  }, [serviceById, availableServiceById, setValue, state?.collector_id])

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        if (serviceById.serviceIDAvailable !== null) {
          setAvailableServiceById(serviceById.serviceIDAvailable)
        }
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-available-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [history, userLogged])

  useEffect(() => {
    function run() {
      setValue('service_id', service_id)

      if (slug) {
        availableServiceByCollectorId.map((service) => {
          return (
            setValue(
              'landing_availability_date',
              serviceFormatDateToFront(service.landing_availability_date),
            ),
            setValue(
              'landing_availability_hour',
              serviceFormatHourToFront(service.landing_availability_hour),
            ),
            setValue('observation', service.observation)
          )
        })
      }
    }
    run()
  }, [setValue, service_id, availableServiceByCollectorId, slug])

  async function handleCreateAvailableService(values: FormInputProps) {

    const hasCreateAvailableService = await confirmMessage({
      title: 'Deseja disponibilizar o serviço para desembarque?',
    })

    if (hasCreateAvailableService) {
      setIsLoading(true)
      try {
        const formatedValues = availableServiceFormatDataToBack(values)
        const createAvailableServiceResponse = await createAvailableService(
          formatedValues,
        )

        if (createAvailableServiceResponse && userLogged) {
          if(hasPathRedirect){
            history.push(resState?.path)
          }else{
            history.push('/servicos/disponibilizar/desembarque')
          }
        }
      } catch (err) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleUpdateAvailableService(values: FormInputProps) {

    const hasUpdateAvailableService = await confirmMessage({
      title: 'Deseja atualizar um serviço disponibilizado?',
    })

    if (hasUpdateAvailableService) {
      setIsLoading(true)
      try {
        const formatedValues = availableServiceFormatDataToBack(values)
        const updateAvailableServiceReqObj = {
          serviceId: service_id,
          updateAvailableServiceInput: formatedValues,
        }
        const updateAvailableServiceResponse = await updateAvailableService({
          ...updateAvailableServiceReqObj,
        })

        if (updateAvailableServiceResponse && userLogged) {
          history.push(`/servico/editar/${service_id}`)
        }
      } catch (err) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading || isLoadingPage) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(
            slug ? handleUpdateAvailableService : handleCreateAvailableService,
          )}
          noValidate
        >
          <Flex
            align="center"
            justify="space-between"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Disponibilizar Serviço
            </Heading>
            {/* {userLogged?.permissions.includes("add-occurrence") && ( */}
            <Link
              to={{
                pathname: '/ocorrencia/criar',
                state: {
                  service_id,
                },
              }}
            >
              <Button
                colorScheme="gray"
                rightIcon={<Icon as={FiPlus} />}
                size="sm"
                mt={['2', '2', '0']}
              >
                Adicionar Ocorrência
              </Button>
            </Link>
            {/* )} */}
          </Flex>

          <Divider my="6" borderColor="gray.700" />

          {serviceById && <AvailableAccordion serviceByID={serviceById} />}

          <Alert status="info">
            <AlertIcon />
            Informações do serviço!
          </Alert>

          <Divider my="6" borderColor="gray.700" />

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('landing_availability_date')}
              isDisabled={isDisabled}
              name="landing_availability_date"
              type="date"
              error={errors.landing_availability_date}
              label="Data Disponível para Desembarque"
              required
            />

            <Input
              {...register('landing_availability_hour')}
              name="landing_availability_hour"
              label="Horário Disponível para Desembarque"
              error={errors.landing_availability_hour}
              type="time"
              isDisabled={isDisabled}
              required
            />
          </Stack>

          <TextArea
            {...register('observation')}
            name="observation"
            label="Observações"
            isDisabled={isDisabled}
          />

          <Flex mt="8" justify="flex-end">
            <HStack>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                loadingText="Carregando"
              >
                {slug
                  ? 'Atualizar Serviço Disponibilizado'
                  : 'Disponibilizar Serviço'}
              </Button>

              <Link to="/servicos/disponibilizar/desembarque">
                <Button type="button" colorScheme="gray">
                  Lista de Serviços à Disponibilizar
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { useCollectors } from "hooks/collector/useCollectors";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { useScheduleReport } from "hooks/report/useScheduleReport";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { formatDate } from "utils/DateFunctions/formatDate";
import { ScheduleReportTable } from "./components/ScheduleReportTable";
import { add } from "date-fns";
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers";

interface ScheduleReportInputs {
  search_type: 'general' | 'customer' | 'collector'
  customer_id: string
  collector_id: string
  start_filter: string
  end_filter: string
}

const scheduleReportHeaders = [
  { key: 'protocol', label: 'PROTOCOLO' },
  { key: 'step', label: 'ETAPA' },
  { key: 'customer', label: 'CLIENTE' },
  { key: 'source_hub', label: 'HUB ORIGEM' },
  { key: 'source_collector', label: 'COLETADOR ORIGEM' },
  { key: 'source_addresses', label: 'REMETENTES' },
  { key: 'collect_date', label: 'DATA DA COLETA' },
  { key: 'collect_hour_start', label: 'HORÁRIO INICIAL DA COLETA' },
  { key: 'collect_hour_end', label: 'HORÁRIO FINAL DA COLETA' },
  { key: 'board_hour', label: 'HORÁRIO LIMITE PARA EMBARQUE' },
  { key: 'inputs', label: 'INSUMOS' },
  { key: 'source_branch', label: 'BASE TRANSPORTADORA ORIGEM' },
  { key: 'destination_branch', label: 'BASE TRANSPORTADORA DESTINO' },
  { key: 'destination_hub', label: 'HUB DESTINO' },
  { key: 'destination_addresses', label: 'DESTINATÁRIOS' },
  { key: 'delivery_date', label: 'DATA DA ENTREGA' },
  { key: 'delivery_hour', label: 'HORÁRIO DE ENTREGA' },
  { key: 'vehicle', label: 'VEÍCULO' },
  { key: 'tracker', label: 'RASTREADOR' },
  { key: 'which_board', label: 'IDENTIFICADOR DO EMBARQUE ATUAL' },
  { key: 'has_crossdocking', label: 'CROSSDOCKING' },
  { key: 'crossdocking_collector_id', label: 'COLETADOR INTERMEDIÁRIO' },
  { key: 'destination_collector', label: 'COLETADOR DESTINO' },
  { key: 'crossdocking_board_hour', label: 'HORÁRIO LIMITE DE EMBARQUE CROSSDOCKING' },
  { key: 'source_crossdocking_branch', label: 'BASE TRANSPORTADORA ORIGEM INTERMEDIÁRIA' },
  { key: 'destination_crossdocking_branch', label: 'BASE TRANSPORTADORA DESTINO INTERMEDIÁRIA' },
  { key: 'observation', label: 'OBSERVAÇÕES' },
]

const searchTypeSelectOptions = [
  { key: '0', value: 'general', showOption: 'GERAL' },
  { key: '1', value: 'customer', showOption: 'CLIENTE' },
  { key: '2', value: 'collector', showOption: 'COLETADOR' },
]

const schema = yup.object().shape({
  search_type: yup.string().required('Campo obrigatório!'),
  customer_id: yup.string().when('search_type', {
    is: 'customer',
    then: yup.string().required('Campo obrigatório!')
  }),
  collector_id: yup.string().when('search_type', {
    is: 'collector',
    then: yup.string().required('Campo obrigatório!')
  }),
  start_filter: yup.string().required('Campo obrigatório!'),
  end_filter: yup.string().required('Campo obrigatório!')
})

export function ScheduleReport() {
  const [
    enableScheduleReportRequest,
    setEnableScheduleReportRequest
  ] = useState(false)

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<ScheduleReportInputs>({
    resolver: yupResolver(schema),
    shouldUnregister: true
  })

  const searchType = useWatch<ScheduleReportInputs, 'search_type'>({
    control,
    name: 'search_type',
  })

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers({
    queryOptions: {
      enabled: searchType === 'customer'
    }
  })

  const {
    data: collectors,
    isFetching: isFetchingCollectors
  } = useCollectors({
    queryOptions: {
      enabled: searchType === 'collector'
    }
  })

  const customerSelectOptions = customersData?.customers.map(customer => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  const collectorSelectOptions = collectors?.map(collector => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }
  })

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-schedule-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  useEffect(() => {
    if (userLogged?.user_type === 'CLIENTE') {
      setValue('customer_id', userLogged?.customer_id)
    }

    if (userLogged?.user_type === 'COLETADOR') {
      setValue('collector_id', userLogged?.collector_id)
    }
  }, [userLogged, setValue])

  const [
    customerFiltered,
    collectorFiltered,
    startFilter,
    endFilter
  ] = useWatch({
    control,
    name: ['customer_id', 'collector_id', 'start_filter', 'end_filter']
  })

  const isValidStartDate = new Date(startFilter) instanceof Date &&
    !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const selectedCustomer = useWatch({
    control,
    name: 'customer_id'
  })

  const selectedCustomerInfo = customersData?.customers.find(customer => {
    return customer.id === selectedCustomer
  })

  const selectedCollector = useWatch({
    control,
    name: 'customer_id'
  })

  const selectedCollectorInfo = collectors?.find(collector => {
    return collector.id === selectedCollector
  })

  const {
    data: scheduleReportData,
    isFetching: isScheduleReportDataFetching,

  } = useScheduleReport({
    queryOptions: {
      enabled: enableScheduleReportRequest,
      select: (scheduleReportData) => {
        return scheduleReportData.map(data => {
          return {
            ...data,
            board_hour: data.board_hour !== '-' ? formatDate.handle(data.board_hour, 'DateOnlyWithHourMinute') : data.board_hour,
            collect_date: formatDate.handle(data.collect_date, 'DateWithoutHourToShow'),
            collect_hour_end: formatDate.handle(data.collect_hour_end, 'DateOnlyWithHourMinute'),
            collect_hour_start: formatDate.handle(data.collect_hour_start, 'DateOnlyWithHourMinute'),
            delivery_date: formatDate.handle(data.delivery_date, 'DateWithoutHourToShow'),
            delivery_hour: formatDate.handle(data.delivery_hour, 'DateOnlyWithHourMinute'),
            crossdocking_board_hour: data.crossdocking_board_hour !== '-' ? formatDate.handle(data.crossdocking_board_hour, 'DateOnlyWithHourMinute') : data.crossdocking_board_hour,
          }
        })
      }
    },
    queryParams: {
      customer_id: customerFiltered,
      collector_id: collectorFiltered,
      start_filter: startFilter,
      end_filter: endFilter
    }
  })

  const handleGenerateReport = () => {
    setEnableScheduleReportRequest(true)
    setTimeout(() => {
      setEnableScheduleReportRequest(false)
    }, 500)
  }

  const csvReportFilename = searchType === 'collector' && selectedCollector
    ? `relatorio-agendamentos-coletador-${selectedCollectorInfo.trading_name
    }-${startFilter}-${endFilter}.csv`
    : searchType === 'customer' && selectedCustomer
      ? `relatorio-agendamentos-cliente-${selectedCustomerInfo.trading_firstname
      }-${startFilter}-${endFilter}.csv`
      : `relatorio-agendamentos-geral-${startFilter}-${endFilter}.csv`

  const csvReportProps = {
    data: scheduleReportData ?? [],
    headers: scheduleReportHeaders,
    filename: csvReportFilename,
  }

  return (
    <StandardBackgroundForm
      title='Relatório de agendamentos'
      onSubmit={handleSubmit(handleGenerateReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Select
          {...register('search_type')}
          name='search_type'
          label='Tipo de busca'
          error={errors.search_type}
          placeholder='Selecione uma opção...'
          options={searchTypeSelectOptions}
          required
        />

        {searchType === 'collector' && (
          <Select
            {...register('collector_id')}
            name='collector_id'
            label='Coletador'
            error={errors.collector_id}
            placeholder='Selecione uma opção...'
            options={collectorSelectOptions}
            isDisabled={isFetchingCollectors || userLogged?.user_type === 'COLETADOR'}
            required
          />
        )}

        {searchType === 'customer' && (
          <Select
            {...register('customer_id')}
            name='customer_id'
            label='Cliente'
            error={errors.customer_id}
            placeholder='Selecione uma opção...'
            options={customerSelectOptions}
            isDisabled={isCustomersDataFetching || userLogged?.user_type === 'CLIENTE'}
            required
          />
        )}

        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('start_filter')}
            name='start_filter'
            label='Data inicial do filtro'
            type='date'
            error={errors.start_filter}
            required
          />

          <Input
            {...register('end_filter')}
            name='end_filter'
            label='Data final do filtro'
            type='date'
            error={errors.end_filter}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isScheduleReportDataFetching}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {scheduleReportData && (
            <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} />
          )}
        </Flex>

        {scheduleReportData && (
          <ScheduleReportTable
            scheduleReportData={scheduleReportData}
          />
        )}
      </Flex>


    </StandardBackgroundForm>
  )
}

import { useMutation, useQueryClient } from 'react-query'
import {
  CreateLandingServiceInputProps,
  createLandingServiceReqFunction,
  finishLandingServiceReqFunction,
  FinishLandingServiceReqProps,
  updateLandingServiceReqFunction,
  UpdateLandingServiceReqProps,
} from '../../../utils/RequestFunctions/Service/Landing/requestLandingServiceFunctions'
import { useSwal } from '../../swal/useSwal'

export const useLandingServiceFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createLandingService = useMutation(
    'createLandingService',
    (createLandingServiceInput: CreateLandingServiceInputProps) =>
      createLandingServiceReqFunction(createLandingServiceInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('O desembarque foi iniciado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const finishLandingService = useMutation(
    'finishLandingService',
    ({ serviceID, finishLandingServiceInput }: FinishLandingServiceReqProps) =>
      finishLandingServiceReqFunction({ serviceID, finishLandingServiceInput }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('O desembarque foi finalizado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const updateLandingService = useMutation(
    'updateLandingService',
    ({ serviceID, updateLandingServiceInput }: UpdateLandingServiceReqProps) =>
      updateLandingServiceReqFunction({ serviceID, updateLandingServiceInput }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['services'])
        queryClient.invalidateQueries(['service'])
        successMessage('O desembarque foi atualizado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    createLandingService,
    finishLandingService,
    updateLandingService,
  }
}

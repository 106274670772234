import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteIsoQualityAuditBody {
  realizedDate: string
  hasTrainingSchedule: boolean
  hasAuditsSchedule: boolean
  hasCommercialProcessMapping: boolean
  hasRhDpProcessMapping: boolean
  hasOperationProcessMapping: boolean
  hasPurchaseProcessMapping: boolean
  hasFinancialProcessMapping: boolean
  hasQualityProcessMapping: boolean
  hasOrganizationalCultureProcessMapping: boolean
  hasUpdatedOrganizationalChart: boolean
  hasCargoAndFunctionsDetail: boolean
  hasAuditProcessDocument: boolean
  hasTrainingProcessDocument: boolean
  hasMeetingModelForCriticalAnalysis: boolean
  hasAuditorsSavedCertificates: boolean
  hasChangesPlanning: boolean
  hasQualityPolicy: boolean
  hasQualityObjectives: boolean
  hasDocumentToStandardizesDocuments: boolean
  hasProcessInteraction: boolean
  hasQualityManagmentSystemManual: boolean
  hasCarriersIqrQualification: boolean
  hasProvidersIqrQualification: boolean
  hasCollectorsIqrQualification: boolean
  hasNonComplianceDocument: boolean
  hasEthicsCode: boolean
  hasInternExternalComunication: boolean
  hasStrategicPlanning: boolean
  isTimeCardRecordedCorrectly: boolean
  nonCompliance: string
}

interface CompleteIsoQualityAuditParams {
  body: CompleteIsoQualityAuditBody
  auditId: string
}

export async function completeIsoQualityAudit({
  body,
  auditId
}: CompleteIsoQualityAuditParams) {
  try {
    await api.put(`/audits/${auditId}/iso-quality`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { GridItem, Flex, Avatar, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

interface ViewBugModalCommentProps {
  name: string
  createdAt: string
  content: string
}

export function ViewBugModalComment({
  name,
  createdAt,
  content,
}: ViewBugModalCommentProps) {
  return (
    <GridItem
      as={Flex}
      gap="2"
      flexDirection="column"
    >
      <Flex
        mt="2"
        align="flex-end"
        justify="space-between"
      >
        <Flex
          gap="2"
          alignItems="inherit"
        >
          <Avatar
            size="xs"
            name={name}
          />
          <Text
            fontSize="xs"
          >
            {name}
          </Text>
        </Flex>

        <Text fontSize="xs">{format(new Date(createdAt), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", {
          locale: ptBR
        })}</Text>

      </Flex>
      <Text fontSize="xs" ml="8">
        {content}
      </Text>
    </GridItem>
  )
}

import { Button, Flex, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,  Stack, Text } from "@chakra-ui/react"
import { CheckboxService } from "components/Inputs/CheckboxService"
import * as yup from "yup"
import { Address } from "hooks/address/dtos/Address"
import { useToastify } from "hooks/toastify/useToastify"
import { Controller, useForm, useWatch, useFieldArray, NestedValue } from "react-hook-form"
import { useRequestServiceFunctions } from "hooks/services/request/useRequestServiceFunctions"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextArea } from "components/Inputs/TextInput"
import { FaTimes, FaPlus } from "react-icons/fa"
import { Select } from "components/Inputs/SelectInput"

interface RequestDeliveryAddressesModalInputs {
  addresses: NestedValue<string[]>
  addressesObservations: {
    address_id: string
    observation: string
  }[]
}

interface RequestDeliveryAddressesModalProps {
  isOpen: boolean
  onClose: () => void
  requestedServiceId: string
  deliveryAddresses: Address[]
}

const requestDeliveryAddressesModalSchema = yup.object().shape({
  addresses: yup.array().of(yup.string()).min(1, 'É necessário selecionar no mínimo um endereço!').required('Campo obrigatório!'),
  addressesObservations: yup.array().of(yup.object().shape({
    address_id: yup.string(),
    observation: yup.string()
  }))
})

export function RequestDeliveryAddressesModal({
  isOpen,
  onClose,
  requestedServiceId,
  deliveryAddresses,
}: RequestDeliveryAddressesModalProps) {

  const { promiseMessage } = useToastify()

  const {
    requestNewDeliveryAddresses: { mutateAsync: requestNewDeliveryAddresses }
  } = useRequestServiceFunctions()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<RequestDeliveryAddressesModalInputs>({
    resolver: yupResolver(requestDeliveryAddressesModalSchema),
    defaultValues: { addresses: [] }
  })

  const addressesDeliverySelected = useWatch({
    control,
    name: 'addresses'
  })

  const addressesObservationsOptions = deliveryAddresses?.filter((address) => addressesDeliverySelected?.includes(address?.id))?.map((address) => {
    return {
      key: address.id,
      value: address.id,
      showOption: `${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
      ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()
    }
  })

  const {
    fields: addressObservationFields,
    append: appendAddressObservationField,
    remove: removeAddressObservationField
  } = useFieldArray({
    control,
    name: 'addressesObservations'
  })

  function handleRemoveAddressObservationField(index: number) {
    removeAddressObservationField(index)
  }
  function handleAppendAddressObservationField() {
    appendAddressObservationField({ address_id: '', observation: '' })
  }

  async function handleRequestNewDeliveryAddresses({
    addresses,
    addressesObservations
  }: RequestDeliveryAddressesModalInputs) {

    await promiseMessage(requestNewDeliveryAddresses({
      input:{
        addresses: addresses as string[],
        addressesObservations
      },
      requestedServiceId,
    }, {
      onSuccess: () => onClose()
    }), 'Endereços para entrega solicitados com sucesso!')

  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        as='form'
        onSubmit={handleSubmit(handleRequestNewDeliveryAddresses)}
      >
        <ModalHeader>Solicitar endereços para entrega</ModalHeader>
        <ModalCloseButton />

        <ModalBody as={Flex} gap={2} flexDirection='column' maxH="500px" overflowY="auto">
          <Controller
            name="addresses"
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <CheckboxService
                  onCheckboxChange={onChange}
                  name="addresses"
                  label="Endereços"
                  error={errors.addresses}
                  addresses={deliveryAddresses}
                />
              )
            }}
          />
          <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Text>Adicionar observações em endereços</Text>
          </Stack>
          <Flex direction="column">
            {addressObservationFields.map((field, index) => {
              return (
                <Flex direction='column'>
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Select
                      {...register(`addressesObservations.${index}.address_id`)}
                      name={`addressesObservations.${index}.address_id`}
                      label='Endereço'
                      placeholder='Selecione uma opção...'
                      error={
                        errors.addressesObservations
                          ? errors?.addressesObservations[index]?.address_id
                          : undefined
                      }
                      options={addressesObservationsOptions}
                      required
                    />
                  </Stack>

                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <TextArea
                      {...register(`addressesObservations.${index}.observation`)}
                      name={`addressesObservations.${index}.observation`}
                      label='Observações'
                      error={
                        errors.addressesObservations
                          ? errors?.addressesObservations[index]?.observation
                          : undefined
                      }
                      required
                    />

                  </Stack>
                  <Stack pt={8}>
                    <Button
                      leftIcon={<Icon as={FaTimes} />}
                      variant="ghost"
                      onClick={() => handleRemoveAddressObservationField(index)}
                      h="48px"
                    >
                      Remover
                    </Button>
                  </Stack>
                </Flex>
              )
            })}
            <Button
              leftIcon={<Icon as={FaPlus} />}
              alignSelf="flex-start"
              onClick={handleAppendAddressObservationField}
              mt={2}
            >
              Adicionar
            </Button>
          </Flex>
        </ModalBody>
        <ModalFooter as={Flex} gap={4}>
          <Button
            onClick={onClose}
          >
            Fechar
          </Button>

          <Button
            type='submit'
            isLoading={isSubmitting}
            colorScheme='blue'
          >
            Solicitar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

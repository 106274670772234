import { VacationStatus } from 'api/vacations/_types/Vacation';

export const vacationStatusMap: Record<VacationStatus, string> = {
  active: 'Ativa',
  "on-vacation": 'Em férias',
  dismissed: 'Funcionário Demitido',
  done: 'Finalizada',
  planned: 'Planejada',
  paying: 'Em pagamento',
  planning: 'Aguardando planejamento',
  waiting: 'Aguardando férias'
}





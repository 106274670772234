import { Button, Flex, FormControl, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Textarea, Tr } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { useToastify } from "hooks/toastify/useToastify";
import { getDelivery } from "api/service/deliveries/getDelivery";
import { validateDeliveryWithoutQRCode } from "api/service/deliveries/validateDeliveryWithoutQRCode";
import { GetDeliveriesWithoutQRCodePendingValidationResponse } from "api/service/deliveries/getDeliveriesWithoutQRCodePendingValidation";

interface LandingDetailProps {
  deliveryId: string
  onCloseModal: () => void
}

interface ValidateDeliverySchema {
  responsibleName: string
  deliveryVolume: number
  observation: string
  isInvalid: boolean
}

const validateDeliverySchema = yup.object({
  deliveryVolume: yup.number().required(),
  responsibleName: yup.string().required(),
  observations: yup.string(),
})


export function DeliveryDetail({ deliveryId, onCloseModal }: LandingDetailProps) {
  const queryClient = useQueryClient()
  const { data: delivery, isLoading: isDeliveryDataLoading } =
    useQuery({
      queryKey: ['delivery', deliveryId],
      queryFn: () => getDelivery({ deliveryId })
    })


  const { mutateAsync: validateDeliveryWithoutQRCodeFn } = useMutation({
    mutationFn: validateDeliveryWithoutQRCode,
    onSuccess(_data, { routeParams }) {
      const cached = queryClient.getQueriesData<GetDeliveriesWithoutQRCodePendingValidationResponse>({
        queryKey: 'deliveries-without-qr-code-pending-validation'
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          deliveries: cachedValue.deliveries.filter(
            (delivery) => delivery.id !== routeParams.deliveryId
          )
        })
      })


      onCloseModal()
    }
  })

  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ValidateDeliverySchema>({
    resolver: yupResolver(validateDeliverySchema),
  })

  function handleSetValueValidateDelivery(action: "validate" | "validateWithFine") {
    if (action === 'validate') {
      setValue('isInvalid', false)
      return
    } else {
      setValue('isInvalid', true)
    }
  }

  async function handleValidateDeliveryWithoutQRCode({
    deliveryVolume,
    responsibleName,
    observation,
    isInvalid
  }: ValidateDeliverySchema) {

    await promiseMessage(validateDeliveryWithoutQRCodeFn({
      body: {
        deliveryVolume,
        responsibleName,
        observation,
        isInvalid
      },
      routeParams: {
        deliveryId
      }
    }), 'Entrega validada com sucesso! 🎉')
  }

  const alreadyAttachedContentDeclarations: Array<{
    key: string
    filename: string
  }> = typeof delivery?.content_declaration_attachments === 'string'
      ? JSON.parse(delivery?.content_declaration_attachments)
      : delivery?.content_declaration_attachments


  return (
    <ModalContent maxW='600px'>
      <ModalHeader letterSpacing="tight">Detalhes da Entrega</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleValidateDeliveryWithoutQRCode)}
        maxH='600px'
        overflowY='scroll'
      >
        {isDeliveryDataLoading ? (
          <Spinner />
        ) : (
          <TableContainer>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Protocolo</Td>
                  <Td isNumeric>{delivery?.serviceIDDelivery?.protocol}</Td>
                </Tr>
                <Tr>
                  <Td>Motorista</Td>
                  <Td isNumeric>{delivery?.driverIDDelivery?.firstname} {delivery?.driverIDDelivery?.lastname}</Td>
                </Tr>

                <Tr>
                  <Td>Responsável</Td>
                  <Td isNumeric>
                    <FormControl isInvalid={!!errors?.responsibleName}>
                      <Input
                        {...register('responsibleName')}
                        name="responsibleName"
                        size="sm"
                        rounded="md"
                        defaultValue={delivery?.responsible_name}
                        textAlign="right"
                      />
                    </FormControl>
                  </Td>
                </Tr>

                <Tr>
                  <Td>Volume Entregue</Td>
                  <Td isNumeric>
                    <FormControl isInvalid={!!errors?.deliveryVolume}>
                      <Input
                        {...register('deliveryVolume')}
                        name="deliveryVolume"
                        size="sm"
                        rounded="md"
                        type="number"
                        defaultValue={delivery?.delivery_volume}
                        textAlign="right"
                      />
                    </FormControl>
                  </Td>
                </Tr>

                <Tr>
                  <Td>Foto da caixa</Td>
                  <Td isNumeric>
                    <IconButton
                      aria-label="Foto da caixa"
                      as={Link}
                      href={handleChangeUrl(delivery?.box_photo)}
                      icon={<FaExternalLinkAlt />}
                      size="sm"
                      isExternal
                    />
                  </Td>
                </Tr>

                {alreadyAttachedContentDeclarations?.map((contentDeclaration) => {
                  return (
                    <Tr key={contentDeclaration.key}>
                      <Td>Foto da declaração de conteúdo</Td>
                      <Td isNumeric>
                        <IconButton
                          aria-label="Foto da declaração de conteúdo"
                          as={Link}
                          href={handleChangeUrl(contentDeclaration.key)}
                          icon={<FaExternalLinkAlt />}
                          size="sm"
                          isExternal
                        />
                      </Td>
                    </Tr>
                  )
                })}

                <Tr>
                  <Td>Observações</Td>
                  <Td isNumeric>
                    <FormControl w='full' isInvalid={!!errors?.observation}>
                      <Textarea
                        {...register('observation')}
                        name="observation"
                        size="md"
                        rounded='lg'
                        defaultValue={delivery?.observation}
                      />

                    </FormControl>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
          gap={2}
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={() => handleSetValueValidateDelivery("validate")}
          >
            Validar
          </Button>
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={() => handleSetValueValidateDelivery("validateWithFine")}
          >
            Validar com Multa
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

import * as yup from "yup"
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Inputs/SelectInput";
import { CustomerProps } from "contexts/CustomerContext";
import { useForm } from "react-hook-form";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";

interface LoginCustomerSelectorModalProps {
  isOpen: boolean
  onClose: () => void
  userCustomers: CustomerProps[]
}

interface SelectLoggedUserCustomerInputs {
  customerId: string
}

const selectLoggedUserCustomerSchema = yup.object().shape({
  customerId: yup.string().required('Campo obrigatório')
})

export function LoginCustomerSelectorModal({
  isOpen,
  onClose,
  userCustomers,
}: LoginCustomerSelectorModalProps) {
  const { handleSetUserLoggedCustomerId } = useAuth()

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    }
  } = useForm<SelectLoggedUserCustomerInputs>({
    resolver: yupResolver(selectLoggedUserCustomerSchema)
  })

  const userCustomersOptions = userCustomers?.map((userCustomer) => {
    return {
      key: userCustomer.id,
      value: userCustomer.id,
      showOption: userCustomer.trading_firstname
    }
  })

  const { successMessage } = useToastify()

  function handleSubmitForm(values: SelectLoggedUserCustomerInputs) {
    handleSetUserLoggedCustomerId(values.customerId)

    successMessage('Cliente selecionado com sucesso!')
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Selecione um cliente
        </ModalHeader>
        <ModalBody
          p="6"
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          noValidate
        >
          <Text mb="4" textAlign="left">Olá!
          <br />
          <br />
          Antes de iniciar sua sessão, é necessário que selecione de qual cliente deseja que as informações sejam visualizadas!</Text>
          <Select
            {...register('customerId')}
            name="customerId"
            label="Cliente"
            placeholder="Selecione um cliente..."
            options={userCustomersOptions}
            error={errors.customerId}
            required
          />

          <Flex
            mt="4"
            align="center"
            justify="flex-end"
          >
            <Button type="submit" colorScheme="blue">Salvar</Button>
          </Flex>
        </ModalBody>
      </ModalContent>


    </Modal>
  )
}

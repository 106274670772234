import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { Fragment, memo, useEffect, useState } from 'react'
import { CollectedServiceProps } from '../../../../contexts/CollectedServiceContext'
import {
  RequestedServiceProps,
  ServiceProps,
} from '../../../../contexts/ServiceContext'
import { useBranch } from '../../../../hooks/branch/useBranch'
import { Input } from '../../../Inputs/Input'
import { TextArea } from '../../../Inputs/TextInput'

interface IBranchAccordionProps {
  serviceByID: ServiceProps
  branchId: string
}

interface BranchProps {
  id: string
  nickname: string
  shipping_id: string
  email: string
  hub_id: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  observation: string
  shippingIDBranch: {
    id: string
    company_name: string
    trading_name: string
    modal: string
  }
  hubIDBranch: {
    name: string
  }
}

function BranchAccordion({ serviceByID, branchId }: IBranchAccordionProps) {
  const [requestedServiceByServiceId, setRequestedServiceByServiceId] =
    useState<RequestedServiceProps[]>([])
  const [collectedServiceByServiceId, setCollectedServiceByServiceId] =
    useState<CollectedServiceProps[]>([])
  const [sourceBranchFiltered, setSourceBranchFiltered] = useState<
    BranchProps[]
  >([])

  const {
    branch: { data: branchById },
  } = useBranch(branchId, false, false)

  useEffect(() => {
    function run() {
      if (serviceByID !== undefined) {
        if (serviceByID.serviceIDRequested) {
          const requestedServiceFiltered = []
          requestedServiceFiltered.push(serviceByID.serviceIDRequested)
          setRequestedServiceByServiceId(requestedServiceFiltered)
        }
        if (serviceByID.serviceIDCollect) {
          setCollectedServiceByServiceId(serviceByID.serviceIDCollect)
        }
      }
    }
    run()
  }, [serviceByID])

  useEffect(() => {
    function run() {
      if (branchById !== undefined) {
        const sourceBranchFiltered = []
        sourceBranchFiltered.push(branchById)
        setSourceBranchFiltered(sourceBranchFiltered)
      }
    }
    run()
  }, [branchById])

  return (
    <>
      {requestedServiceByServiceId.map((service) => (
        <Fragment key={service.id}>
          {service.service_type === 'FRACIONADO' && (
            <AccordionItem>
              <h2>
                <AccordionButton p="4">
                  <Box flex="1" textAlign="left">
                    Informações da transportadora
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              {sourceBranchFiltered.map((branch) => (
                <AccordionPanel key={branch.id} pb={4}>
                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="branch_name"
                      value={branch.nickname}
                      label="Base"
                      isDisabled={true}
                    />
                    <Input
                      name="cep"
                      value={branch.cep}
                      label="CEP"
                      isDisabled={true}
                    />
                  </Stack>

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="branch_street"
                      value={branch.street}
                      label="Rua"
                      isDisabled={true}
                    />

                    <HStack w="100%">
                      <Input
                        name="branch_number"
                        value={branch.number}
                        label="Número"
                        isDisabled={true}
                      />
                      <Input
                        name="branch_complement"
                        value={branch.complement}
                        label="Complemento"
                        isDisabled={true}
                      />
                    </HStack>
                  </Stack>

                  <Stack
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      name="branch_neighborhood"
                      value={branch.neighborhood}
                      label="Bairro"
                      isDisabled={true}
                    />

                    <HStack w="100%">
                      <Input
                        name="branch_city"
                        value={branch.city}
                        label="Cidade"
                        isDisabled={true}
                      />
                      <Input
                        name="branch_state"
                        value={branch.state}
                        label="Estado"
                        isDisabled={true}
                      />
                    </HStack>
                  </Stack>

                  <Stack mt="4" spacing="24px">
                    <TextArea
                      name="branch_observation"
                      value={branch.observation}
                      label="Observações da base"
                      isDisabled={true}
                    />
                    {collectedServiceByServiceId.map((colService) => (
                      <TextArea
                        key={colService.id}
                        name="branch_observation"
                        value={
                          colService.observation !== null
                            ? colService.observation
                            : ''
                        }
                        label="Observações da Coleta"
                        isDisabled={true}
                      />
                    ))}
                  </Stack>
                </AccordionPanel>
              ))}
            </AccordionItem>
          )}
          <Divider my="6" borderColor="gray.700" />
        </Fragment>
      ))}
    </>
  )
}

export default memo(BranchAccordion)

import { IconButton, Link, Td, Tr } from "@chakra-ui/react";
import { GetAuditResponse } from "api/audits/getAudit";
import { FaExternalLinkAlt } from "react-icons/fa";

interface AuditMaintenanceTableRowProps {
  audit: GetAuditResponse
}

export function AuditMaintenanceTableRow({
  audit
}: AuditMaintenanceTableRowProps) {
  return (
    <>
      <Tr >
        <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Relatório</Td>
        <Td isNumeric>
          <IconButton
            aria-label="Abrir anexo"
            as={Link}
            href={audit?.audit?.maintenanceAudit.reportAttachment?.link}
            icon={<FaExternalLinkAlt />}
            size="sm"
            isExternal
          />
        </Td>
      </Tr>
    </>
  )
}

import { Flex, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect, useState } from 'react'
import { NestedValue, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { ListButton } from '../../components/Buttons/ListButton'
import { SubmitButton } from '../../components/Buttons/SubmitButton'
import { StandardBackgroundForm } from '../../components/Form/StandardBackgroundForm'
import { CheckboxUser } from '../../components/Inputs/CheckboxUser'
import { Select } from '../../components/Inputs/SelectInput'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { UserContext } from '../../contexts/UserContext'
import { useAuth } from '../../hooks/auth/useAuth'
import { usePermission } from '../../hooks/permission/usePermission'
import { useProfile } from '../../hooks/profile/useProfile'
import { useSwal } from '../../hooks/swal/useSwal'

interface IFormInputProps {
  permissions: NestedValue<string[]>
  user_type: string
}

interface IUserTypesByProfileProps {
  id: string
  name: string
}

const schema = yup.object().shape({
  permissions: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  user_type: yup.string().required('Campo Obrigatório'),
})

export function UserUpdatePermissions() {
  const [isLoading, setIsLoading] = useState(false)
  const [userTypesByProfiles, setUserTypesByProfiles] = useState<
    IUserTypesByProfileProps[]
  >([])

  const { updateUsersPermissions } = useContext(UserContext)
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const {
    permissions: { data: permissions, isLoading: isPermissionsLoading },
  } = usePermission(null, true)
  const {
    profiles: { data: profiles, isLoading: isProfilesLoading },
  } = useProfile(null, false)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (!userLogged?.permissions?.includes('update-users-permissions')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  useEffect(() => {
    if (profiles) {
      const userTypesByProfile = profiles.map((profile, index) => {
        return { id: profile.id, name: profile.user_type }
      })
      setUserTypesByProfiles(userTypesByProfile)
    }
  }, [profiles])

  async function handleUpdateUsersPermissions(values: IFormInputProps) {
    const hasUpdatePermissions = await confirmMessage({
      title:
        'Deseja atualizar as permissões de todos os usuários do tipo selecionado?',
    })

    if (hasUpdatePermissions) {
      setIsLoading(true)
      const updateUsersPermissionsReqObj = {
        user_type: values.user_type,
        permissions:
          values.permissions.toString().split(',').length > 1
            ? values.permissions
            : values.permissions.toString().split('  '),
      }
      const updateUsersPermissionsResponse = await updateUsersPermissions(
        updateUsersPermissionsReqObj,
      )
      if (!updateUsersPermissionsResponse) {
        setIsLoading(false)
        return
      }
      redirectTo('/usuarios')
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading || isPermissionsLoading || isProfilesLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      title="Atualizar permissões de usuários"
      onSubmit={handleSubmit(handleUpdateUsersPermissions)}
    >
      <Stack>
        <Select
          {...register('user_type')}
          name="user_type"
          label="Tipo de usuário"
          error={errors.user_type}
          user_types={userTypesByProfiles}
          placeholder="Selecione uma opção..."
          required
        />

        <CheckboxUser
          {...register('permissions')}
          name="permissions"
          error={errors.permissions}
          label="Permissões"
          permissions={permissions}
        />
      </Stack>

      <Flex justify="flex-end" align="center">
        <Stack spacing="12px" mt="4" direction="row">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          <ListButton href="/usuarios" name="Usuários" />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}

import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { RequestedServiceContext } from "contexts/ServiceContext";

import { useSwal } from "hooks/swal/useSwal";

import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup"

import { useHistory } from 'react-router'

import { useAuth } from "hooks/auth/useAuth";
import { useLogFunctions } from "hooks/log/useLogFunctions";

interface CancelServiceFormInputs {
  cancel_reason: 'CLIENTE SOLICITOU CANCELAMENTO' | 'PACIENTE NÃO COMPARECEU' |
  'PACIENTE NÃO COMPARECEU' | 'SERVIÇO ABERTO INCORRETAMENTE'
  cancel_observation: string
}

interface CancelServiceModalProps {
  isOpen: boolean
  onClose: () => void
  serviceId: string
  serviceProtocol: number
}

const cancelReasonOptions = [
  { key: '0', value: 'CLIENTE SOLICITOU CANCELAMENTO', showOption: 'CLIENTE SOLICITOU O CANCELAMENTO' },
  { key: '1', value: 'PACIENTE NÃO COMPARECEU', showOption: 'PACIENTE NÃO COMPARECEU' },
  { key: '2', value: 'PACIENTE NÃO COMPARECEU', showOption: 'SERVIÇO DUPLICADO' },
  { key: '3', value: 'SERVIÇO ABERTO INCORRETAMENTE', showOption: 'SERVIÇO ABERTO INCORRETAMENTE' },
]

const schema = yup.object().shape({
  cancel_reason: yup.string().required('Campo obrigatório'),
  cancel_observation: yup.string(),
})

export function CancelServiceModal({
  isOpen,
  onClose,
  serviceId,
  serviceProtocol,
}: CancelServiceModalProps) {

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<CancelServiceFormInputs>({
    resolver: yupResolver(schema)
  })

  const history = useHistory()
  const { userLogged } = useAuth()

  const { cancelRequestedService } = useContext(
    RequestedServiceContext,
  )
  const {createLog: {mutateAsync: createLog}} = useLogFunctions()


  const { confirmMessage, standardMessage } = useSwal()



  async function handleCancelService({cancel_reason,
    cancel_observation}: CancelServiceFormInputs) {
    const hasCancelService = await confirmMessage({
      title: 'Deseja cancelar um serviço?',
    })

    const input = {
      cancel_reason,
      cancel_observation
    }

    if (hasCancelService) {
      try {

        const cancelServiceResponse = await cancelRequestedService(serviceId, input)
        if (cancelServiceResponse && userLogged) {

          const createLogResponse = await createLog({
            user_id: userLogged.id,
            action: `CANCELAR SERVIÇO - PROTOCOLO: ${serviceProtocol}`,
          })
          if (createLogResponse) {
            history.push('/servicos')
          }
        }
      } catch {
        history.push('/servicos')
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        as='form'
        onSubmit={handleSubmit(handleCancelService)}
        noValidate
      >
        <ModalHeader>Cancelar serviço</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex gap={4} direction='column'>
            <Select
              {...register('cancel_reason')}
              name='cancel_reason'
              label='Motivo do cancelamento'
              placeholder='Selecione uma opção...'
              options={cancelReasonOptions}
              error={errors.cancel_reason}
              required
            />

            <TextArea
              {...register('cancel_observation')}
              name='cancel_observation'
              label='Observações'
            />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex gap={2}>
            <Button
              onClick={onClose}
              colorScheme='gray'
            >
              Fechar
            </Button>
            <Button
              type='submit'
              colorScheme='blue'
            >
              Salvar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>

    </Modal >
  )
}


import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { ExperiencePeriod } from "./types/ExperiencePeriod";

interface GetExperiencePeriodResponse {
  experiencePeriod: ExperiencePeriod
}

interface GetExperiencePeriodProps {
  routeParams: {
    experiencePeriodId: string
  }
}

export async function getExperiencePeriod({
  routeParams
}: GetExperiencePeriodProps) {
  try {
    const response = await api.get<GetExperiencePeriodResponse>(`/experience-periods/${routeParams.experiencePeriodId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

type BillBillingBody = FormData

interface BillBillingParams {
  billingId: string
  body: BillBillingBody
}


export async function billBilling({
  billingId,
  body,
}: BillBillingParams) {
  try {
    await api.put(`/billings/${billingId}/bill`, body)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

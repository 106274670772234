import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { useMutation, useQueryClient } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"

interface CreateReportBugInputReqFunctionProps {
  input: FormData
}

async function createReportBugReqFunction({
  input
}: CreateReportBugInputReqFunctionProps) {
  try {
    const { data } = await api.post("/bugs", input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

export function useReportBugsFunctions() {
  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("bugs")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createReportBug = useMutation('createReportBug', createReportBugReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return {
    createReportBug
  }
}

import { ServiceStatus } from "pages/Services/ServiceStatus";
import { Route, BrowserRouter as Switch } from "react-router-dom";

export function PublicRoutes() {
  return (
    <>
      <Switch>
        <Route
          path="/servico/:serviceId/status"
          component={ServiceStatus}
        />
      </Switch>
    </>
  )
}

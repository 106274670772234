import { Flex, Button, Icon, Text, Input } from "@chakra-ui/react";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface CustomersTableFilterSchema {
  search: string
}

export function CustomersTableFilters() {
  const { replace } = useHistory()
  const searchParams = useSearchParams()

  const search = searchParams.get('search')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<CustomersTableFilterSchema>({
    defaultValues: {
      search
    }
  })

  function handleSearchPmcs({ search }: CustomersTableFilterSchema) {
    if (search) {
      searchParams.set('search', search)
    } else {
      searchParams.delete('search')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('search')

    reset({ search: '' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      as="form"
      align="center"
      direction={["column", "column", "row"]}
      gap="2"
      mt="4"
      onSubmit={handleSubmit(handleSearchPmcs)}
    >
      <Text alignSelf={["flex-start", "flex-start", "center"]} fontSize="sm">
        Filtros:
      </Text>

      <Input
        {...register('search')}
        size="sm"
        borderRadius="md"
        w={{ base: 'full', lg: '300px'}}
      />

      <Button
        type="submit"
        size="sm"
        leftIcon={<Icon as={FaSearch} />}
        w={['full', 'full', 'min']}
      >
        Filtrar resultados
      </Button>

      <Button
        type="submit"
        size="sm"
        variant="outline"
        leftIcon={<Icon as={FaTimes} />}
        w={['full', 'full', 'min']}
        onClick={handleRemoveFilters}
      >
        Remover filtros
      </Button>


    </Flex>
  )
}

import { AspectRatio, Box, Button, ButtonGroup, Flex, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { addDays, addMonths, format, getDate, getDay, getDaysInMonth, set, subDays, subMonths } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { getWeekDays } from "utils/getWeekDays";
import { captalize } from "utils/captalize"

interface CalendarWeek {
  week: number
  days: { date: Date, disabled: boolean }[]
}

interface CalendarProps {
  onSetSelectedDatetime: (date: Date) => void
}

export function Calendar({ onSetSelectedDatetime }: CalendarProps) {
  const [currentDate, setCurrentDate] = useState(set(new Date(), { date: 1, hours: 12 }))

  const shortDaysOfWeek = getWeekDays({ short: true })

  const monthFirstDayOfWeek = getDay(currentDate)

  const prevMonthDates = Array.from({
    length: monthFirstDayOfWeek
  }).map((_, index) => {
    return subDays(currentDate, index + 1)
  }).reverse()

  const lastDateInCurrentMonth = set(currentDate, {
    date: getDaysInMonth(currentDate)
  })

  const monthLastDayOfWeek = getDay(lastDateInCurrentMonth)

  const lastMonthDates = Array.from({
    length: 7 - (monthLastDayOfWeek + 1)
  }).map((_, index) => {
    return addDays(lastDateInCurrentMonth, index + 1)
  })

  const calendarDays = [
    ...prevMonthDates.map(date => {
      return { date, disabled: true }
    }),
    ...Array.from({
      length: getDaysInMonth(currentDate)
    }).map((_, i) => {
      return { date: set(currentDate, { date: i + 1 }), disabled: false }
    }),
    ...lastMonthDates.map(date => {
      return { date, disabled: true }
    }),
  ]

  const calendarWeeks = calendarDays?.reduce<CalendarWeek[]>((weeks, _, index, original) => {
    const isNewWeek = index % 7 === 0

    if (isNewWeek) {
      weeks.push({
        week: index / 7 + 1,
        days: original.slice(index, index + 7)
      })
    }

    return weeks
  }, [])

  const handleSubMonthFromCurrentDate = () => setCurrentDate(subMonths(currentDate, 1))
  const handleAddMonthFromCurrentDate = () => setCurrentDate(addMonths(currentDate, 1))

  const currentMonth = format(new Date(currentDate), "MMMM", { locale: ptBR })
  const currentYear = format(new Date(currentDate), "yyyy", { locale: ptBR })

  const handleSelectDatetime = (date: Date) => onSetSelectedDatetime(date)

  return (
    <Box
      px="2"
      py="1"
    >
      <Flex align="center" justify="space-between">
        {`${captalize(currentMonth)} ${currentYear}`}

        <ButtonGroup spacing="2">
          <IconButton
            aria-label="Mês anterior"
            icon={<FaAngleLeft />}
            onClick={handleSubMonthFromCurrentDate}
            variant="ghost"
            size="sm"
          />

          <IconButton
            aria-label="Mês anterior"
            icon={<FaAngleRight />}
            onClick={handleAddMonthFromCurrentDate}
            variant="ghost"
            size="sm"
          />
        </ButtonGroup>
      </Flex>

      <TableContainer mt={4}>
        <Table
          cellSpacing={1}
          w="full"
          layout="fixed"
          size="sm"
        >
          <Thead>
            <Tr>
              {shortDaysOfWeek?.map((weekDay) => {
                return (
                  <Th textAlign="center" key={weekDay}>{weekDay}.</Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody
            _before={{
              lineHeight: "0.75rem",
              display: 'block',
              color: 'gray.800'
            }}
          >
            {calendarWeeks?.map((calendarWeek) => {
              return (
                <Tr key={calendarWeek.week}>
                  {calendarWeek.days.map((day) => {
                    return (
                      <Td key={day.date.toISOString()} p="1" border="none" boxSizing="border-box">
                        <AspectRatio
                          ratio={1 / 1}
                          w="full"
                          _focus={{
                            border: 'none',
                            boxShadow: '0 0 0 2px #38c3fa',
                          }}
                        >
                          <Button
                            rounded="md"
                            textAlign="center"
                            isDisabled={day.disabled}
                            onClick={() => handleSelectDatetime(day.date)}
                          >
                            {getDate(day.date)}
                          </Button>
                        </AspectRatio>
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>

      </TableContainer>

    </Box>
  )
}

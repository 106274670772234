import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreatePatrimonyTypeBody {
  type: string
}

interface CreatePatrimonyTypeParams {
  body: CreatePatrimonyTypeBody
}

export async function createPatrimonyType({
  body
}: CreatePatrimonyTypeParams) {
  try {
    await api.post('/patrimony-types', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import { Button, Flex, FormControl,  IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Spinner,  Table, TableContainer, Tbody, Td, Textarea, Tr } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { getLandingService } from "api/service/landing/getLandingService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { validateLanding } from "api/service/landing/validateLanding";
import { useToastify } from "hooks/toastify/useToastify";

interface EditLandingProps {
  landingId: string
  onCloseModal: () => void
}

interface EditLandingSchema {
  boxBreak: string
  landingVolume: number
  observation: string
  isInvalid: boolean
}

const editLandingSchema = yup.object({
  landingVolume: yup.number().required(),
})


export function LandingDetail({ landingId, onCloseModal }: EditLandingProps) {
  const queryClient = useQueryClient()
  const { data: landingData, isLoading: isLandingLoading } =
    useQuery({
      queryKey: ['landing', landingId],
      queryFn: () => getLandingService({ landingId })
    })


  const { mutateAsync: validateLandingFn } = useMutation({
    mutationFn: validateLanding,
    onSuccess() {
      queryClient.invalidateQueries(['landings-validations'])
      onCloseModal()
    }
  })

  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<EditLandingSchema>({
    resolver: yupResolver(editLandingSchema),
  })


  function handleSetValueValidateLanding(action: "validate" | "validateWithFine") {
    if (action === 'validate') {
      setValue('isInvalid', false)
      return
    } else {
      setValue('isInvalid', true)
    }
  }

  async function handleValidateLanding({
    landingVolume,
    isInvalid
  }: EditLandingSchema) {

    await promiseMessage(validateLandingFn({
      body: {
        isInvalid,
        landingVolume
      },
      landingId: landingId
    }), 'Desembarque validado com sucesso! 🎉')
  }


  return (
    <ModalContent maxW='600px'>
      <ModalHeader letterSpacing="tight">Detalhes do Desmbarque</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleValidateLanding)}
        maxH='600px'
        overflowY='scroll'
      >
        {isLandingLoading ? (
          <Spinner />
        ) : (
          <TableContainer>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Protocolo</Td>
                  <Td isNumeric>{landingData?.serviceIDLanding?.protocol}</Td>
                </Tr>
                <Tr>
                  <Td>Motorista</Td>
                  <Td isNumeric>{landingData?.driverIDLanding?.firstname} {landingData?.driverIDLanding?.lastname}</Td>
                </Tr>

                <Tr>
                  <Td>Volume Desembarcado</Td>
                  <Td isNumeric>
                    <FormControl isInvalid={!!errors?.landingVolume}>
                      <Input
                        {...register('landingVolume')}
                        name="landingVolume"
                        size="sm"
                        rounded="md"
                        defaultValue={landingData?.landing_volume}
                        textAlign="right"
                      />
                    </FormControl>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Caixas com avarias</Td>
                  <Td isNumeric>
                    <FormControl isInvalid={!!errors.boxBreak}>
                      <Select
                        {...register('boxBreak')}
                        name="boxBreak"
                        placeholder="Selecione..."
                        size="sm"
                        rounded="md"
                        defaultValue={landingData?.box_break ? 'yes' : 'no'}
                        justifySelf='flex-end'
                        isDisabled
                      >
                        <option value='yes'>Sim</option>
                        <option value='no'>Não</option>
                      </Select>
                    </FormControl>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Foto da Carga</Td>
                  <Td isNumeric>
                    <IconButton
                      aria-label="Foto da carga"
                      as={Link}
                      href={handleChangeUrl(landingData?.cargo_photo)}
                      icon={<FaExternalLinkAlt />}
                      size="sm"
                      isExternal
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Observações</Td>
                  <Td isNumeric>
                    <FormControl w='full' isInvalid={!!errors?.observation}>
                      <Textarea
                        {...register('observation')}
                        name="observation"
                        size="md"
                        rounded='lg'
                        defaultValue={landingData?.observation}
                        isDisabled
                      />

                    </FormControl>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
          gap={2}
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={() => handleSetValueValidateLanding('validate')}
          >
            Validar
          </Button>
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={() => handleSetValueValidateLanding("validateWithFine")}
          >
            Validar com Multa
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

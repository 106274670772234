import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface ReproveDispatchStockProposalProps {
  routeParams: { proposalId: string }
}

export async function reproveDispatchStockProposal({
  routeParams
}: ReproveDispatchStockProposalProps) {
  try {
    await api.patch(`/dispatch-stock/proposals/${routeParams.proposalId}/disapprove`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverDismissComunincationBody {
  doesInformedDismissOnScalabrini: boolean
  lastWorkDate: string
  hasConferredPointRegisters: boolean
  employeeInactivationsChecklist: string[]
  doesVerifiedEmployeeHasTrunkAndCoolerBoxAndBags: boolean
  doesEmployeeReturnedAllPatrimonies: boolean
  hasSignedNotice: boolean
}

interface SendCltDriverDismissComunincationProps {
  body: SendCltDriverDismissComunincationBody
  cltDriverId: string
}

export async function sendCltDriverDismissComunincation({
  body,
  cltDriverId
}: SendCltDriverDismissComunincationProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/dismiss/comunication`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useHoliday } from '../../hooks/holiday/useHoliday'
import { useAuth } from '../../hooks/auth/useAuth'
import { HolidayTable } from '../../components/Tables/Holiday/HolidayTable'

export function HolidayList() {
  const [isLoading, setIsLoading] = useState(true)

  const {
    holidays: { data: holidays, isLoading: isHolidayLoading },
  } = useHoliday(null, true)

  const { userLogged } = useAuth()

  const permissions = userLogged?.permissions

  useEffect(() => {
    const setLoadingState = () => {
      if (!isHolidayLoading) setIsLoading(false)
    }
    setLoadingState()
  }, [isHolidayLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Lista de Feriados
            </Heading>
            {permissions !== undefined && (
              <Link to="/feriado/adicionar">
                {userLogged?.permissions.includes('add-holiday') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                  >
                    Adicionar Feriado
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!holidays && <HolidayTable holidays={holidays} />}
        </Box>
      </Flex>

  )
}

import { createContext, ReactNode, useEffect, useState } from 'react'
import { api } from '../services/api'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { User } from 'hooks/user/dtos/User'

interface ClientsProviderProps {
  children: ReactNode
}

interface AuthProps {
  id: string
  email: string
  password: string
}

export interface UserAuthProps {
  id: string
  email: string
  firstname: string
  user_type: string
  permissions: string[]
  loglife_employee: boolean
  lastname: string
  situation: string
  customer_id: string
  collector_id: string
  driver_id: string
}

type LoginInput = Omit<AuthProps, 'id'>

interface AuthContextProps {
  userLogged: UserAuthProps | null
  loading: boolean
  authenticated: boolean
  handleLogin: (loginInput: LoginInput) => Promise<{ user: User, token: string }>
  handleLogout: () => Promise<void>
  handleSetUserLoggedCustomerId: (customerId: string) => void
  handleSetUserLoggedCollectorId: (collectorId: string) => void
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
)

export function AuthProvider({ children }: ClientsProviderProps) {
  const [userLogged, setUserLogged] = useState<UserAuthProps | null>(null)
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo')

    if (userInfo) {
      setAuthenticated(true)
      const { user, token } = JSON.parse(userInfo)
      setUserLogged(user)
      api.defaults.headers.authorization = `${token}`
    }
    setLoading(false)
  }, [])

  async function handleLogin(loginInput: LoginInput) {
    try {
      const { data } = await api.post(`/sessions`, {
        email: loginInput.email,
        password: loginInput.password,
      })
      setUserLogged(data.user)
      localStorage.setItem('userInfo', JSON.stringify(data))
      api.defaults.headers.authorization = `${data.token}`
      toast.success('Acesso Autorizado')

      if (data.user.user_type !== 'COLETADOR' && data.user.user_type !== 'CLIENTE' && data.user.user_type !== 'REMETENTE') {
        setAuthenticated(true)
      }

      return data
    } catch (error: any) {
      toast.error(`${error.response.data.message}`)
    }
  }

  async function handleLogout() {
    setAuthenticated(false)
    setUserLogged(null)
    localStorage.removeItem('userInfo')
    api.defaults.headers.authorization = undefined
  }

  function handleSetUserLoggedCustomerId(customerId: string) {
    const newUserLogged = { ...userLogged }

    newUserLogged.customer_id = customerId

    const userInfo = localStorage.getItem('userInfo')

    const { token } = JSON.parse(userInfo)

    localStorage.setItem('userInfo', JSON.stringify({
      user: newUserLogged, token
    }))

    setUserLogged(newUserLogged)
    setAuthenticated(true)
  }

  function handleSetUserLoggedCollectorId(collectorId: string) {
    const newUserLogged = { ...userLogged }

    newUserLogged.collector_id = collectorId

    const userInfo = localStorage.getItem('userInfo')

    const { token } = JSON.parse(userInfo)

    localStorage.setItem('userInfo', JSON.stringify({
      user: newUserLogged, token
    }))

    setUserLogged(newUserLogged)
    setAuthenticated(true)
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        userLogged,
        loading,
        handleLogin,
        handleLogout,
        handleSetUserLoggedCustomerId,
        handleSetUserLoggedCollectorId
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

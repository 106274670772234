
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Text } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import { useToastify } from "hooks/toastify/useToastify"
import { proceedRequestedPatrimonyToDevolution } from "api/patrimonies/proceedRequestedPatrimonyToDevolution"

interface ProceedRequestedPatrimonyToDevolutionProps {
  onCloseModal: () => void
  patrimonyRequestId: string
  patrimonyId: string
}

interface ProceedRequestedPatrimonyToDevolutionSchema {
  devolutionLimitDate: string
}

const proceedRequestedPatrimonyToDevolutionSchema = yup.object({
  devolutionLimitDate: yup.string().required(),
})


export function ProceedRequestedPatrimonyToDevolution({
  onCloseModal,
  patrimonyId,
  patrimonyRequestId
}: ProceedRequestedPatrimonyToDevolutionProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ProceedRequestedPatrimonyToDevolutionSchema>({
    resolver: yupResolver(proceedRequestedPatrimonyToDevolutionSchema)
  })

  const { mutateAsync: proceedRequestedPatrimonyToDevolutionFn } = useMutation({
    mutationFn: proceedRequestedPatrimonyToDevolution,
    onSuccess() {
      queryClient.invalidateQueries(['requestsPatrimony'])
      onCloseModal()
    }
  })

  async function handleProceedRequestedPatrimonyToDevolution({
    devolutionLimitDate
  }: ProceedRequestedPatrimonyToDevolutionSchema) {
     await promiseMessage(proceedRequestedPatrimonyToDevolutionFn({
        body: {
          devolutionLimitDate
        },
        patrimonyId: patrimonyId,
        patrimonyRequestId: patrimonyRequestId
     }), 'Patrimônio em processo de devolução! 🎉')
  }

  return (
    <ModalContent>
      <ModalHeader>Processo de Devolução de Patrimônio</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleProceedRequestedPatrimonyToDevolution)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <FormControl isInvalid={!!errors?.devolutionLimitDate}>
            <FormLabel fontSize="sm">
              Data limite de devolução
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('devolutionLimitDate')}
              name="devolutionLimitDate"
              type="date"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Divider, Heading, Icon, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { Pagination } from "components/Pagination/Pagination";

import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { MaterialRequest } from "hooks/materialRequests/dtos/MaterialRequest";
import { useMaterialRequests } from "hooks/materialRequests/useMaterialRequests";
import { useMaterialRequestsFunction } from "hooks/materialRequests/useMaterialRequestsFunctions";
import { useMaterialRequestsStatusTableButton } from "hooks/materialRequests/useMaterialRequestsStatusTableButton";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect, useState } from "react";
import { FaEllipsisH, FaEye, FaRegCheckCircle, FaTimesCircle, FaTruck } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { ApproveMaterialRequest } from "./components/ApproveMaterialRequest";
import { DeliveryMaterialRequestModal } from "./components/DeliveryMaterialRequestModal";
import { MaterialRequestsStatusTableButton } from "./components/MaterialRequestsStatusTableButton";
import { ViewMaterialRequestModal } from "./components/ViewMaterialRequestModal";

export enum MaterialRequestsStatusEnum {
  'requested' = 'Solicitado',
  'awaiting-delivery' = 'Aguardando entrega',
  'delivered' = 'Entregue',
  'reproved' = 'Reprovado'
}


export function MaterialRequestsList() {
  const [materialRequestSelected, setMaterialRequestSelected] = useState<MaterialRequest>({} as MaterialRequest)

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { location, push: redirect } = useHistory()

  const statusFilter = location.search.match(`[?&]status=([^&]+)`)
  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const { status } = useMaterialRequestsStatusTableButton()

  const {
    data: materialRequestsData,
    isFetching: isFetchingMaterialRequestsData,
  } = useMaterialRequests({
    queryParams: {
      status: statusFilter ? statusFilter[1] : undefined,
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      pageSize: Number(itemLimit)
    }
  })


  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: materialRequestsData?.totalPages,
      pagesCount: materialRequestsData?.totalPages,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1
      },
    });

  useEffect(() => {
    redirect({
      pathname: 'rsms',
      search: `?status=${status}&page=${currentPage}`
    })
  }, [currentPage, status, redirect])

  const {
    onOpen: onOpenApproveMaterialRequestModal,
    isOpen: isApproveMaterialRequestModalOpen,
    onClose: onCloseApproveMaterialRequestModal,
  } = useDisclosure()

  function handleOpenApproveMaterialRequestModal(materialRequest: MaterialRequest) {
    setMaterialRequestSelected(materialRequest)
    onOpenApproveMaterialRequestModal()
  }

  const {
    onOpen: onOpenDeliveryMaterialRequestModal,
    isOpen: isDeliveryMaterialRequestModalOpen,
    onClose: onCloseDeliveryMaterialRequestModal,
  } = useDisclosure()

  function handleOpenDeliveryMaterialRequestModal(materialRequest: MaterialRequest) {
    setMaterialRequestSelected(materialRequest)
    onOpenDeliveryMaterialRequestModal()
  }

  const {
    onOpen: onOpenViewMaterialRequestModal,
    isOpen: isViewMaterialRequestModalOpen,
    onClose: onCloseViewMaterialRequestModal,
  } = useDisclosure()

  function handleOpenViewMaterialRequestModal(materialRequest: MaterialRequest) {
    setMaterialRequestSelected(materialRequest)
    onOpenViewMaterialRequestModal()
  }

  const {
    reproveMaterialRequest
  } = useMaterialRequestsFunction()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleReproveMaterialRequest(materialRequest: MaterialRequest) {
    await promiseMessage(reproveMaterialRequest.mutateAsync(materialRequest.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('materialRequests')
      }
    }), 'RSM Reprovada com sucesso!')
  }

  const materialRequestsActionsOptions = [
    {
      status: 'requested',
      actions: [
        {
          type: 'Aprovar',
          icon: FaRegCheckCircle,
          permission: 'approve-rsm',
          onClick: handleOpenApproveMaterialRequestModal
        },
        {
          type: 'Reprovar',
          icon: FaTimesCircle,
          permission: 'reprove-rsm',
          onClick: handleReproveMaterialRequest,
        },
        {
          type: 'Visualizar',
          icon: FaEye,
          permission: 'view-rsm',
          onClick: handleOpenViewMaterialRequestModal
        },
      ],
    },
    {
      status: 'awaiting-delivery',
      actions: [
        {
          type: 'Entregar',
          icon: FaTruck,
          permission: 'delivery-rsm',
          onClick: handleOpenDeliveryMaterialRequestModal
        },
        {
          type: 'Visualizar',
          icon: FaEye,
          permission: 'view-rsm',
          onClick: handleOpenViewMaterialRequestModal
        },
      ]
    },
    {
      status: 'delivered',
      actions: [
        {
          type: 'Visualizar',
          icon: FaEye,
          permission: 'view-rsm',
          onClick: handleOpenViewMaterialRequestModal
        },
      ]
    },
    {
      status: 'reproved',
      actions: [
        {
          type: 'Visualizar',
          icon: FaEye,
          permission: 'view-rsm',
          onClick: handleOpenViewMaterialRequestModal
        },
      ]
    }
  ]


  return (

      <Box
        p="6"
        rounded="md"
        bg="white"
        boxShadow="md"
        display="flex"
        flexDirection="column"
        gap="4"
      >
        <ApproveMaterialRequest
          isOpen={isApproveMaterialRequestModalOpen}
          onClose={onCloseApproveMaterialRequestModal}
          materialRequest={materialRequestSelected}
        />

        <DeliveryMaterialRequestModal
          isOpen={isDeliveryMaterialRequestModalOpen}
          onClose={onCloseDeliveryMaterialRequestModal}
          materialRequest={materialRequestSelected}
        />

        <ViewMaterialRequestModal
          isOpen={isViewMaterialRequestModalOpen}
          onClose={onCloseViewMaterialRequestModal}
          materialRequest={materialRequestSelected}
        />

        <Heading>Requisições de Materiais ({MaterialRequestsStatusEnum[status]})</Heading>

        <Divider />

        {isFetchingMaterialRequestsData ? (
          <Spinner />
        ) : (
          <TableContainer alignSelf="center" w="full" maxW="1440px" flex="1">
            <Table size="sm" variant="striped">
              <Thead>
                <Tr>
                  <Th>DATA SOLICITAÇÃO</Th>
                  <Th>SOLICITANTE</Th>
                  <Th>
                    <MaterialRequestsStatusTableButton />
                  </Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {materialRequestsData?.materialRequests.map((materialRequest) => {
                  return (
                    <Tr key={materialRequest.id}>
                      <Td>{format(new Date(materialRequest.created_at), 'dd/MM/yyyy')}</Td>
                      <Td>{`${materialRequest.requestedBy.firstname} ${materialRequest.requestedBy.lastname}`}</Td>
                      <Td>{MaterialRequestsStatusEnum[status]}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            variant='ghost'
                            aria-label='Options'
                            icon={<FaEllipsisH />}
                          />
                          <MenuList>
                            <MenuGroup title='Opções'>
                              {materialRequestsActionsOptions.find((option) => option.status === status)?.actions.map((action) => {
                                if (userLogged?.permissions.includes(action.permission)) {
                                  return (
                                    <MenuItem
                                      display="flex"
                                      alignItems="center"
                                      gap="2"
                                      onClick={() => action.onClick(materialRequest)}
                                    >
                                      <Icon as={action.icon} w="4" h="4" />
                                      {action.type}
                                    </MenuItem>
                                  )
                                }

                                return null
                              })}
                            </MenuGroup>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          currentPage={currentPage}
          pagesQuantity={pagesCount}
          pages={pages}
          handlePageChange={setCurrentPage}
        />
      </Box>

  )
}

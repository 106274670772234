import { CheckboxGroup, CheckboxProps, FormControl, Checkbox as ChakraCheckbox, FormErrorMessage, FormLabel, Text, Box, Stack } from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Profiles {
  key: string
  value: string
}

interface CheckboxInputProps extends CheckboxProps {
  name: string
  label?: string
  required?: boolean
  error?: FieldError
  profiles: Profiles[]
  onCheckboxChange: () => void
  defaultCheckedOptions?: string[]
}

const ProfilesCheckboxBase: ForwardRefRenderFunction<HTMLInputElement, CheckboxInputProps> = ({
  name,
  label,
  required,
  error,
  profiles,
  onCheckboxChange,
  defaultCheckedOptions,
  ...rest
}, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      {label ? (
        required ? (
          <FormLabel htmlFor={name}>
            {label}
            <Text color="red" fontWeight="bold" fontSize="14" as="sup">
              {' '}
              *
            </Text>
          </FormLabel>
        ) : (
          <FormLabel htmlFor={name}>{label}</FormLabel>
        )
      ) : (
        ''
      )}
      <CheckboxGroup
        colorScheme='blue'
        onChange={onCheckboxChange}
        defaultValue={defaultCheckedOptions}
      >
        <Box
          overflowY="auto"
          height="300px"
          borderWidth="1px"
          borderRadius="lg"
          p="2"
        >
          <Stack direction='column'>
            {profiles.map(profile => {
              return (
                <ChakraCheckbox
                  key={profile.key}
                  name={name}
                  label={label}
                  error={error}
                  value={profile.value}
                  ref={ref}
                  {...rest}
                >
                  {profile.value}
                </ChakraCheckbox>
              )
            })}
          </Stack>
        </Box>
      </CheckboxGroup>


      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const ProfilesCheckbox = forwardRef(ProfilesCheckboxBase)

import { useEffect, useState } from "react"
import { Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td, IconButton } from "@chakra-ui/react"
import { useLoadRatioServices } from "hooks/loadRatio/useLoadRatioServices"
import { formatDate } from "utils/DateFunctions/formatDate"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"
import { useAuth } from "hooks/auth/useAuth"
import { useLoadRatioServicesListContext } from "hooks/loadRatio/useLoadRatioServicesListContext"
import { format } from "date-fns"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"
import { FaSearch } from "react-icons/fa"
import { Link } from "react-router-dom"


interface LoadRatioServicesTableProps {
  rowsPerPage: string
  currentPageFilter: RegExpMatchArray
  onSetTotalPages: (total: number) => void
}

const headers = [
  { label: "Protocolo", key: "protocol" },
  { label: "Cliente", key: "customer" },
  { label: "Data da Coleta", key: "collect_date" },
  { label: "Data de Entrega", key: "delivery_datetime" },
  { label: "Cidade de Origem", key: "source_cities" },
  { label: "Cidade de Destino", key: "destination_cities" },
  { label: "Rastreador", key: "tracker" },
  { label: "CTE", key: "cte" },
  { label: "Veículo", key: "vehicle" },
  { label: "Volume", key: "volume" },
  { label: "Transportadora", key: "shipping" },
  { label: "Base de Origem", key: "source_branch" },
  { label: "Base de Destino", key: "destination_branch" },
  { label: "Coletador de Destino", key: "destination_collector" },
  { label: "Etapa", key: "step" },
]

export function LoadRatioServicesTable({
  rowsPerPage,
  currentPageFilter,
  onSetTotalPages
}: LoadRatioServicesTableProps) {
  const { userLogged } = useAuth()
  const { filters, handleRemoveFilters } = useLoadRatioServicesListContext()

  const timezoneOffset = new Date().getTimezoneOffset() / 60

  const [debouncedFilters, setDebouncedFilters] = useState({
    protocol: '',
    customer: '',
    sourceCity: '',
    destinationCity: '',
    collectDate: '',
    deliveryDatetime: '',
    tracker: '',
    cte: '',
    vehicle: '',
    volume: '',
    shipping: '',
    sourceBranch: '',
    destinationBranch: '',
    destinationCollector: '',
    step: '',
  })

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedFilters({
        protocol: filters.protocol,
        customer: filters.customer,
        sourceCity: filters.sourceCity,
        destinationCity: filters.destinationCity,
        collectDate: filters.collectDate,
        deliveryDatetime: filters.deliveryDatetime,
        tracker: filters.tracker,
        cte: filters.cte,
        vehicle: filters.vehicle,
        volume: filters.volume,
        shipping: filters.shipping,
        sourceBranch: filters.sourceBranch,
        destinationBranch: filters.destinationBranch,
        destinationCollector: filters.destinationCollector,
        step: filters.step,
      })

    }, 1000)
    return () => clearTimeout(debounce)
  }, [filters, handleRemoveFilters])


  const { data: loadRatioServicesData, isFetching: isFetchingLoadRatioServicesData } = useLoadRatioServices({
    queryParams: {
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      pageSize: Number(rowsPerPage),
      timezoneOffset: timezoneOffset,
      protocol: debouncedFilters.protocol ? debouncedFilters.protocol : undefined,
      customer: debouncedFilters.customer ? debouncedFilters.customer : undefined,
      sourceCity: debouncedFilters.sourceCity ? debouncedFilters.sourceCity : undefined,
      destinationCity: debouncedFilters.destinationCity ? debouncedFilters.destinationCity : undefined,
      collectDate: debouncedFilters.collectDate ? debouncedFilters.collectDate : undefined,
      deliveryDatetime: debouncedFilters.deliveryDatetime ? format(new Date(debouncedFilters.deliveryDatetime), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : undefined,
      tracker: debouncedFilters.tracker ? debouncedFilters.tracker : undefined,
      cte: debouncedFilters.cte ? debouncedFilters.cte : undefined,
      vehicle: debouncedFilters.vehicle ? debouncedFilters.vehicle : undefined,
      volume: debouncedFilters.volume ? debouncedFilters.volume : undefined,
      shipping: debouncedFilters.shipping ? debouncedFilters.shipping : undefined,
      sourceBranch: debouncedFilters.sourceBranch ? debouncedFilters.sourceBranch : undefined,
      destinationBranch: debouncedFilters.destinationBranch ? debouncedFilters.destinationBranch : undefined,
      destinationCollector: debouncedFilters.destinationCollector ? debouncedFilters.destinationCollector : undefined,
      step: debouncedFilters.step ? debouncedFilters.step : undefined,
      collectorId: userLogged?.collector_id !== null ? userLogged?.collector_id : undefined
    },

  })
  useEffect(() => {
    onSetTotalPages(loadRatioServicesData?.totalPages ?? 1)
  }, [loadRatioServicesData, onSetTotalPages])

  const { data: loadRatioServicesReportData, isFetching: isFetchingLoadRatioServicesReportData } = useLoadRatioServices({
    queryKey: 'load-ratio-report',
    queryParams: {
      currentPage: 1,
      timezoneOffset: timezoneOffset,
      collectorId: userLogged?.collector_id !== null ? userLogged?.collector_id : undefined
    }
  })

  const csvReportProps = {
    data: loadRatioServicesReportData
      ? loadRatioServicesReportData?.services?.map(service => {
        return {
          ...service,
          collect_date: formatDate.handle(service.collect_date, "DateWithoutHourToShow"),
          delivery_datetime: formatDate.handle(service.delivery_date, "DateWithDateAndHourMinute"),
          step: serviceHandleStep(service.step)
        }
      })
      : [],
    headers,
    filename: `relatório-de-cargas-em-transito.csv`,
  };


  return (
    <>
      {isFetchingLoadRatioServicesData && (
        <Spinner />
      )}
      <TableContainer
        fontSize="xs"
        rounded="md"
        border="1px solid"
        borderColor="gray.100"
        ml="2"
      >
        <Table
          size="md"
          variant="simple"
        >
          <Thead bg="blue.50">
            <Tr>
              <Th></Th>
              <Th>PROTOCOLO</Th>
              <Th>CLIENTE</Th>
              <Th>DATA DA COLETA</Th>
              <Th>DATA DA ENTREGA</Th>
              <Th>ORIGEM</Th>
              <Th>DESTINO</Th>
              <Th>RASTREADOR</Th>
              <Th>CTE</Th>
              <Th>VEICULO</Th>
              <Th>TRASPORTADORA</Th>
              <Th>BASE ORIGEM</Th>
              <Th>BASE DESTINO</Th>
              <Th>COLETADOR DESTINO</Th>
              <Th>ETAPA</Th>
              <Th>TIPO DE SERVIÇO</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loadRatioServicesData?.services.map((service) => {
              return (
                <Tr key={service.protocol}>
                  <Td>
                    <IconButton
                      aria-label="Detalhes do Serviço"
                      icon={<FaSearch />}
                      size="sm"
                      variant="ghost"
                      as={Link}
                      to={`/servico/detalhes/${service.id}`}
                    />
                  </Td>
                  <Td>{service.protocol}</Td>
                  <Td>{service?.customer}</Td>
                  <Td>{formatDate.handle(service.collect_date, "DateWithoutHourToShow")}</Td>
                  <Td>{formatDate.handle(service.delivery_date, "DateWithDateAndHourMinute")}</Td>
                  <Td>{service.source_cities.join(', ')}</Td>
                  <Td>{service.destination_cities.join(', ')}</Td>
                  <Td>{service.tracker}</Td>
                  <Td>{service.cte}</Td>
                  <Td>{service.vehicle}</Td>
                  <Td>{service.shipping}</Td>
                  <Td>{service.source_branch}</Td>
                  <Td>{service.destination_branch}</Td>
                  <Td>{service.destination_collector}</Td>
                  <Td>{serviceHandleStep(service.step)}</Td>
                  <Td>{service.service_type}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {!isFetchingLoadRatioServicesReportData && (
        <GenerateExcelReportButton csvReportProps={csvReportProps} />
      )}
    </>
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface ValidateDeliveryWithoutQRCodeProps {
  routeParams: { deliveryId: string }
  body: {
    responsibleName: string
    deliveryVolume: number
    observation: string
    isInvalid: boolean
  }
}

export async function validateDeliveryWithoutQRCode({
  body,
  routeParams
}: ValidateDeliveryWithoutQRCodeProps) {
  try {
    await api.put(
      `/services/deliveries/${routeParams.deliveryId}/validate`,
      body
    )


  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

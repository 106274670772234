import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface PlanVacationBody {
  periodsQuantity: number
  periods: {
    period: number
    startDate: Date
    endDate: Date
    withAllowance: boolean
  }[]
}

interface PlanVacationProps {
  vacationId: string
  body: PlanVacationBody
}

export async function planVacation({
  body,
  vacationId
}: PlanVacationProps) {
  try {
    const response = await api.put(`/vacations/${vacationId}/plan`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useHistory, useParams, Link } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form";
import { Button, Flex } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAuth } from "hooks/auth/useAuth"
import { isCnpjValid, isCpfValid } from "utils/cpfCnpjValidateFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { GeneralProviderForm, GeneralProviderFormInputs } from "./components/GeneralProviderForm";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { useIbgeCitiesByUf } from "hooks/ibge/useIbgeCitiesByUf";
import { useEffect } from "react";
import { useFinancialClassifications } from "hooks/financialClassification/useFinancialClassifications";
import { useIbgeState } from "hooks/ibge/useIbgeState";
import { useMaterials } from "hooks/material/useMaterials";
import { useGeneralProviderFunctions } from "hooks/generalProvider/useGeneralProviderFunctions";
import { useCep } from "hooks/useCep";
import { useGeneralProvider } from "hooks/generalProvider/useGeneralProvider";
import { useSwal } from "hooks/swal/useSwal";

interface QueryParams {
  slug: string
  id: string
}


const editGeneralProviderFormSchema = yup.object().shape({
  situation: yup.string().required("Campo Obrigatório"),
  company_name: yup.mixed().when('provider_person', {
    is: 'PESSOA JURÍDICA',
    then: yup.string().required("Campo obrigatório")
  }),
  trading_name: yup.string().required("Campo obrigatório"),
  state_registration: yup.string().nullable(),
  cnpj: yup.mixed().when('provider_person', {
    is: 'PESSOA JURÍDICA',
    then: yup.string().transform(isCnpjValid).required('CNPJ inválido')
  }),
  email: yup.string().required("Campo obrigatório"),
  payment_conditional: yup.string().required("Campo obrigatório"),
  payment_type: yup.string().required("Campo obrigatório"),
  cellphone: yup.string().required("Campo obrigatório"),
  telephone: yup.string().required("Campo obrigatório"),
  cep: yup.string().required("Campo obrigatório"),
  state: yup.string().required("Campo obrigatório"),
  city: yup.string().required("Campo obrigatório"),
  street: yup.string().required("Campo obrigatório"),
  number: yup.string().required("Campo obrigatório"),
  neighborhood: yup.string().required("Campo obrigatório"),
  complement: yup.string(),
  type: yup.string().required("Campo obrigatório"),
  materials: yup.array().when('type', {
    is: 'PRODUTO',
    then: yup.array().of(yup.object().shape({
      _id: yup.string().required("Campo obrigatório")
    }))
  }),
  financial_classifications: yup.array().of(yup.object().shape({
    _id: yup.string().required("Campo obrigatório")
  })),
  cost_centers: yup.array().of(yup.object().shape({
    name: yup.string().required("Campo obrigatório")
  })),
  observation: yup.string().nullable(),
  provider_person: yup.string().required("Campo obrigatório"),
  cpf: yup.mixed().when('provider_person', {
    is: 'PESSOA FÍSICA',
    then: yup.string().transform(isCpfValid).required('CPF inválido'),
  })
})

export function EditGeneralProviderPage() {
  const formMethods = useForm<GeneralProviderFormInputs>({
    resolver: yupResolver(editGeneralProviderFormSchema)
  })

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const { slug, id } = useParams<QueryParams>()

  const { warnMessage, promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()


  const { watch, setValue, handleSubmit, formState: { isSubmitting } } = formMethods

  const cepValue = watch("cep")
  const isValidCep = cepValue ? !cepValue.includes("_") : false

  const cepFormatted = isValidCep ? cepValue.split("-").join("") : null

  const {
    data: cepData, isFetching: isFetchingCepData, refetch: refetchCep
  } = useCep({ cep: cepFormatted, useQueryOptions: { enabled: isValidCep } })

  const { data: generalProvider, isFetching: isFetchingGeneralProvider } = useGeneralProvider({ id: id, queryOptions: { enabled: !!id } })


  useEffect(() => {
    if (!userLogged.permissions.includes('edit-general-provider')) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  useEffect(() => {
    if (isValidCep) refetchCep()
  }, [refetchCep, isValidCep, cepValue])

  const isErrorOnConsultCep = cepData?.erro

  useEffect(() => {
    if (isErrorOnConsultCep && !isFetchingCepData) {
      warnMessage("Ocorreu um erro ao consultar o cep informado!")
    }
  }, [isErrorOnConsultCep, warnMessage, isFetchingCepData])

  const {
    ibgeStates: { data: ibgeStates, isFetching: isFetchingIbgeStates }
  } = useIbgeState()

  const { data: ibgeCities, isFetching: isFetchingIbgeCitiesByUf } =
    useIbgeCitiesByUf({
      uf: cepData?.uf,
      queryOptions: { enabled: !isErrorOnConsultCep && !isFetchingCepData }
    })

  const { data: materials, isFetching: isFetchingMaterials } = useMaterials()
  const { data: financialClassifications, isFetching: isFetchingFinancialClassifications } = useFinancialClassifications()

  const { updateGeneralProvider: { mutateAsync: updateGeneralProvider } } = useGeneralProviderFunctions()


  useEffect(() => {

    if (generalProvider !== undefined) {
      setValue('situation', generalProvider.situation)
      setValue('company_name', generalProvider.company_name)
      setValue('trading_name', generalProvider.trading_name)
      setValue('state_registration', generalProvider.state_registration)
      setValue('cnpj', generalProvider.cnpj)
      setValue('email', generalProvider.email)
      setValue('payment_conditional', generalProvider.payment_conditional)
      setValue('payment_type', generalProvider.payment_type)
      setValue('cellphone', generalProvider.cellphone)
      setValue('telephone', generalProvider.telephone)
      setValue('cep', generalProvider.cep)
      setValue('street', generalProvider.street)
      setValue('number', generalProvider.number)
      setValue('neighborhood', generalProvider.neighborhood)
      setValue('complement', generalProvider.complement ? generalProvider.complement : '')
      setValue('type', generalProvider.type)
      setValue('observation', generalProvider.observation)
      setValue('cost_centers', JSON.parse(generalProvider.cost_centers))
      setValue('cpf', generalProvider.cpf)

      if (generalProvider.cnpj) {
        setValue("provider_person", 'PESSOA JURÍDICA')
      }
      if (generalProvider.cpf) {
        setValue("provider_person", 'PESSOA FÍSICA')
      }

    }

  }, [generalProvider, setValue])

  useEffect(() => {
    if (!isFetchingMaterials && !isFetchingFinancialClassifications) {
      const materials = generalProvider.materials.map((material) => {
        return { _id: material.id }
      })
      const financials = generalProvider.financialClassifications.map((financial) => {
        return { _id: financial.id }
      })

      setValue('financial_classifications', financials)
      setValue('materials', materials)

    }
  }, [isFetchingMaterials, isFetchingFinancialClassifications, generalProvider, setValue])

  const stateFromCepUf = Object.entries(statesWithUfList).find(([key, value]) => value.uf === cepData?.uf)?.[0]

  const cityFilteredByCep = ibgeCities?.find(city => {
    return city.nome === cepData?.localidade
  })

  useEffect(() => {

    if (stateFromCepUf) setValue('state', stateFromCepUf?.toLocaleUpperCase())
    if (cityFilteredByCep) setValue('city', cityFilteredByCep?.nome.toLocaleUpperCase())
  }, [

    stateFromCepUf,
    setValue,
    cityFilteredByCep,
    isErrorOnConsultCep,
    isFetchingCepData,
    isFetchingIbgeCitiesByUf,
    isFetchingIbgeStates
  ])

  async function handleEditGeneralProvider({ ...values }: GeneralProviderFormInputs) {
    const hasUpdateGeneralProvider = await confirmMessage({ title: "Deseja editar o fornecedor geral?" })

    if (hasUpdateGeneralProvider) {
      await promiseMessage(updateGeneralProvider({ id: id, input: values }, {
        onSuccess: () => redirectTo("/fornecedor-geral")
      }), "Fornecedor Geral editado com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const isViewPage = slug === "visualizar"
  const formTitle = isViewPage ? "Visualizar Fornecedor Geral" : "Editar Fornecedor Geral"

  return (
    <StandardBackgroundForm title={formTitle} onSubmit={handleSubmit(handleEditGeneralProvider)}>

      <FormProvider {...formMethods}>
        <GeneralProviderForm slug={slug} ibgeStates={ibgeStates}
          ibgeCities={ibgeCities} materials={materials} financialClassifications={financialClassifications} />
      </FormProvider>
      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/fornecedor-geral'
        >
          Lista de fornecedores gerais
        </Button>
        {isViewPage ? (
          <Button
            as={Link}
            to={`/fornecedor-geral/editar/${id}`}
            type="button"
            colorScheme="blue"
            w={["full", "full", "min"]}
            isLoading={isFetchingGeneralProvider}
          >
            Editar
          </Button>
        ) : (<Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>)}

      </Flex>
    </StandardBackgroundForm>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteFieldAuditBody {
  realizedDate: string
  collaboratorPhotoAttachmentId: string
  vehicleRearPhotoAttachmentId: string
  tertiaryPackingPhotoAttachmentId: string
  internChestPhotoAttachmentId: string
  cnhPhotoAttachmentId: string
  crlvPhotoAttachmentId: string
  lastOilChangeDate: string
  eletricSystem: string
  chestPatrimonyNumber: string
  thermicBoxesPatrimonyNumber: string
  status: string
  nonCompliance?: string
}

interface CompleteFieldAuditParams {
  body: CompleteFieldAuditBody
  auditId: string
}

export async function completeFieldAudit({
  body,
  auditId
}: CompleteFieldAuditParams) {
  try {
    await api.put(`/audits/${auditId}/field`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface FinishTrainingOnboardingBody{
  certificateId: string
  leaderEmail: string
}

interface FinishTrainingOnboardingParams {
  onboardingId: string
  body: FinishTrainingOnboardingBody
}

export async function finishTrainingOnboarding({
  onboardingId,
  body
}: FinishTrainingOnboardingParams) {
  try {
    await api.patch(`/onboardings/${onboardingId}/finish-training`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { DispatchStock, DispatchStockStepTypes } from "api/dispachStocks/dispatchStock";
import { addDays, format } from "date-fns";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm, useWatch } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { dispatchStockStepMap } from "utils/dispatchStockMappers";

interface DispatchStocksReportFiltersSchema {
  startDate: string
  endDate: string
  step: DispatchStockStepTypes | 'all'
}

interface DispatchStocksReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: Array<DispatchStock & {
      materialsInfo: string,
      newStep: string
    }>,
    filename: string
  }
}

export function DispatchStocksReportFilters({
  csvProps
}: DispatchStocksReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const step = searchParams.get('step')
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')

  const {
    control,
    register,
    handleSubmit,
  } = useForm<DispatchStocksReportFiltersSchema>({
    defaultValues: {
      startDate,
      endDate,
      step: step as DispatchStockStepTypes | 'all' ?? 'all'
    }
  })

  function handleSearchDispatchStocksReport(values: DispatchStocksReportFiltersSchema) {
    if (values.startDate) {
      searchParams.set('startDate', values.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (values.endDate) {
      searchParams.set('endDate', values.endDate)
    } else {
      searchParams.delete('endDate')
    }

    if (values.step) {
      searchParams.set('step', values.step)
    } else {
      searchParams.delete('step')
    }

    replace({ search: searchParams.toString() })

  }

  const startDateSelected = useWatch({ control, name: 'startDate' })

  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchDispatchStocksReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Input
          {...register("startDate")}
          type="date"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Input
          {...register("endDate")}
          type="date"
          size="sm"
          rounded="md"
          max={format(addDays(new Date(startDateSelected), 31), 'yyyy-MM-dd')}
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Select
          {...register("step")}
          defaultValue="all"
          size="sm"
          rounded="md"
          placeholder="Selecione uma opção..."
          w={{ base: "full", md: '250px' }}
        >
          {Object.entries(dispatchStockStepMap).map(([key, value]) => {
            return (
              <option key={key} value={key}>{value}</option>
            )
          })}
          <option value='all'>Todas etapas</option>
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
      </Flex>
    </Flex>
  )
}

import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { Material } from "./dtos/Material";

type QueryOptions = UseQueryOptions<Material, unknown, Material, "material">

interface UseMaterialOptions {
  queryOptions?: QueryOptions
}

export function useMaterial(materialId: string, options?: UseMaterialOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getMaterial(id: string) {
    try {
      const { data } = await api.get(`/material/${id}`)

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery("material", () => getMaterial(materialId), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

import { OnboardingStatus, OnboardingStatusThemeType } from "api/onboardings/onboarding";

export const onboardingStatusMap: Record<OnboardingStatus, string> = {
  inviting: 'Aguardando convite',
  training: 'Em treinamento',
  testing: 'Teste da cumbuca',
  finished: 'Finalizado',
  inactive: 'Inativo'
}

export const OnboardingStatusThemeTypeMap: Record<OnboardingStatusThemeType, string> = {
  approved: 'Aprovado',
  reproved: 'Reprovado'
}

type DataProps = {
  address_id: string
  service_id: string
  driver_id: string
  provider_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  responsible_name: string | null
  collect_volume: number | null
  collect_sample: number | null
  box_photo: {} | null
  open_box_photo: {} | null
  content_declaration: {} | null
  receipt_photo: {} | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  unsuccess_latitude: string | null
  unsuccess_longitude: string | null
  unsuccess_timestamp: string | null
  box_photo_change: string | null
  open_box_photo_change: string | null
  content_photo_change: string | null
  receipt_photo_change: string | null
  observation: string | null
}

export function collectFormatDataToBack(
  data: DataProps,
  hasUnsuccess: boolean,
) {
  const formData = new FormData()

  if (data.address_id) {
    formData.append('address_id', data.address_id)
  }
  formData.append('service_id', data.service_id)
  formData.append('driver_id', data.driver_id)

  if (data.provider_id) {
    formData.append('provider_id', data.provider_id)
  }

  formData.append('arrival_latitude', data.arrival_latitude)
  formData.append('arrival_longitude', data.arrival_longitude)
  formData.append('arrival_timestamp', data.arrival_timestamp)
  formData.append('responsible_name', data.responsible_name as any)
  formData.append(
    'collect_volume',
    data.collect_volume !== null
      ? (Number(data.collect_volume.toString().split(',').join('.')) as any)
      : data.collect_volume,
  )
  formData.append('collect_sample', data.collect_sample as any)

  formData.append('box_photo', data.box_photo as any)
  formData.append('open_box_photo', data.open_box_photo as any)
  formData.append('content_declaration', data.content_declaration as any)
  formData.append('receipt_photo', data.receipt_photo as any)

  if (hasUnsuccess === false) {
    formData.append('departure_latitude', data.departure_latitude as any)
    formData.append('departure_longitude', data.departure_longitude as any)
    formData.append('departure_timestamp', data.departure_timestamp as any)
  }
  if (hasUnsuccess === true) {
    formData.append('unsuccess_latitude', data.unsuccess_latitude as any)
    formData.append('unsuccess_longitude', data.unsuccess_longitude as any)
    formData.append('unsuccess_times', data.unsuccess_timestamp as any)
    formData.append('hasUnsuccess', 'true')
  }
  formData.append('observation', data.observation as any)

  if (data.box_photo_change === 'SIM') {
    formData.append('boxPhotoChoose', 'SIM')
  }
  if (data.open_box_photo_change === 'SIM') {
    formData.append('openBoxPhotoChoose', 'SIM')
  }
  if (data.content_photo_change === 'SIM') {
    formData.append('contentDeclarationChoose', 'SIM')
  }
  if (data.receipt_photo_change === 'SIM') {
    formData.append('receiptPhotoChose', 'SIM')
  }

  return formData
}

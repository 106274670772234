import { GridItem, GridItemProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface NotificationRootProps extends GridItemProps {
  children: ReactNode
}

export function NotificationRoot({ children, ...rest }: NotificationRootProps) {
  return (
    <GridItem
      transition="all 0.2s"
      _hover={{ bg: 'blackAlpha.100' }}
      {...rest}
    >
      {children}
    </GridItem>
  )
}

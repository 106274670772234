import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { CreateInternCltOfficeModal } from "./components/CreateInternCltOfficeModal";
import { InternCltForm, InternCltFormSchema } from "./components/InternCltForm";
import * as yup from "yup"
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQuery } from "react-query";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { editInternClt } from "api/internClts/editInternClt";
import { getInputTimeHoursAndMinutesSplited } from "utils/getInputTimeHoursAndMinutesSplited";
import { format, set } from "date-fns";
import { getInputDateYearsMonthAndDateSplited } from "utils/getInputDateYearsMonthAndDateSplited";
import { getInternClt } from "api/internClts/getInternClt";
import { useEffect, useState } from "react";
import { InternCltBenefitName } from "api/internClts/_types/internClt";
import { useAuth } from "hooks/auth/useAuth";

const editInternCltSchema = yup.object().shape({
  name: yup.string().required(),
  cpf: yup.string().required(),
  rg: yup.string().required(),
  bornDate: yup.string().required(),
  addressStreet: yup.string().required(),
  addressNumber: yup.string().required(),
  addressNeighborhood: yup.string().required(),
  addressComplement: yup.mixed(),
  addressCity: yup.string().required(),
  addressCep: yup.string().required(),
  addressState: yup.string().required(),
  email: yup.string().required(),
  regional: yup.string().required(),
  admissionDate: yup.string().required(),
  salary: yup.string().required(),
  office: yup.object().required(),
  hasLeadershipGratification: yup.string().required(),
  leadershipGratificationValue: yup.mixed().when('hasLeadershipGratification', {
    is: 'yes',
    then: yup.string().required()
  }),
  hasCommission: yup.string().required(),
  commissionDescription: yup.mixed().when('hasCommission', {
    is: 'yes',
    then: yup.string().required()
  }),
  journeyType: yup.string().required(),
  workStartHour: yup.string().required(),
  workEndHour: yup.string().required(),
  workIntervalStartHour: yup.string().required(),
  workIntervalEndHour: yup.string().required(),
  workWeekDays: yup.mixed().when('journeyType', {
    is: 'days',
    then: yup.array().min(1)
  }),
  benefits: yup.array().of(yup.string()),
  hasHealthInsuranceDependents: yup.mixed().when('benefits', {
    is: (value: InternCltBenefitName[]) => !!value?.find(v => v === 'health-insurance'),
    then: yup.string().required(),
  }),
  vrDailyValue: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('vr'),
    then: yup.string().required()
  }),
  vaDailyValue: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('va'),
    then: yup.string().required()
  }),
  vtDailyValue: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('vt'),
    then: yup.string().required()
  }),
  fuelMonthlyValue: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('fuel-aid'),
    then: yup.string().required()
  }),
  vtTypes: yup.mixed().when('benefits', {
    is: (value: string[]) => value?.includes('vt'),
    then: yup.array().min(1)
  })
})

interface Params {
  internCltId: string
}

export function EditInternClt() {
  const [isCepQueryEnabled, setIsCepQueryEnabled] = useState(false)

  const { userLogged } = useAuth()

  const userCanEditInternClt = userLogged?.permissions?.includes('edit-intern-clt')

  const history = useHistory()

  useEffect(() => {
    if (!userCanEditInternClt) history.push('/')
  }, [history, userCanEditInternClt])


  const { internCltId } = useParams<Params>()

  const formMethods = useForm<InternCltFormSchema>({
    resolver: yupResolver(editInternCltSchema),
  })

  const {
    isOpen: isCreateInternCltOfficeModalOpen,
    onOpen: onOpenCreateInternCltOfficeModal,
    onClose: onCloseCreateInternCltOfficeModal
  } = useDisclosure()

  const { handleSubmit, setValue, formState: { isSubmitting } } = formMethods
  const { promiseMessage } = useToastify()

  const {
    data: internCltData
  } = useQuery({
    queryKey: ['intern-clt', internCltId],
    queryFn: () => getInternClt({ routeParams: { internCltId } })
  })

  useEffect(() => {
    if (internCltData) {
      const { internClt } = internCltData

      setValue('name', internClt.name)
      setValue('status', internClt.status)
      setValue('cpf', internClt.cpf)
      setValue('rg', internClt.rg)
      setValue('office', { value: internClt.office.id, label: internClt.office.name })
      setValue('bornDate', format(new Date(internClt.born_date), 'yyyy-MM-dd'))
      setValue('addressCep', internClt.address_cep)
      setValue('addressStreet', internClt.address_street)
      setValue('addressNeighborhood', internClt.address_neighborhood)
      setValue('addressCity', internClt.address_city)
      setValue('addressState', internClt.address_state)
      setValue('addressComplement', internClt.address_complement)
      setValue('addressNumber', internClt.address_number)
      setValue('email', internClt.email)
      setValue('salary', (internClt.salary_in_cents / 100).toLocaleString('pt-BR', {
        style: 'decimal'
      }))
      setValue('admissionDate', format(new Date(internClt.admission_date), 'yyyy-MM-dd'))
      setValue('hasCommission', internClt.has_commission ? 'yes' : 'no')
      if (internClt.commission_description) setValue('commissionDescription', internClt.commission_description)
      setValue('hasLeadershipGratification', internClt.has_leadership_gratification ? 'yes' : 'no')
      if (internClt.leadership_gratification_value_in_cents) setValue('leadershipGratificationValue', (internClt.leadership_gratification_value_in_cents / 100).toLocaleString('pt-BR', {
        style: 'decimal'
      }))
      setValue('journeyType', internClt.journey_type)
      setValue('hasHealthInsuranceDependents', internClt.has_health_insurance_dependents ? 'yes' : 'no')
      setValue('workStartHour', format(new Date(internClt.work_start_hour), 'HH:mm'))
      setValue('workEndHour', format(new Date(internClt.work_end_hour), 'HH:mm'))
      setValue('workIntervalStartHour', format(new Date(internClt.work_interval_start_hour), 'HH:mm'))
      setValue('workIntervalEndHour', format(new Date(internClt.work_interval_end_hour), 'HH:mm'))

      if (internClt.saturday_work_start_hour) {
        setValue('saturdayWorkStartHour', format(new Date(internClt.saturday_work_start_hour), 'HH:mm'))
      }
      if (internClt.saturday_work_end_hour) {
        setValue('saturdayWorkEndHour', format(new Date(internClt.saturday_work_end_hour), 'HH:mm'))
      }
      if (internClt.saturday_work_interval_start_hour) {
        setValue('saturdayWorkIntervalStartHour', format(new Date(internClt.saturday_work_interval_start_hour), 'HH:mm'))
      }
      if (internClt.saturday_work_interval_end_hour) {
        setValue('saturdayWorkIntervalEndHour', format(new Date(internClt.saturday_work_interval_end_hour), 'HH:mm'))
      }
      if (internClt.sunday_work_start_hour) {
        setValue('sundayWorkStartHour', format(new Date(internClt.sunday_work_start_hour), 'HH:mm'))
      }
      if (internClt.sunday_work_end_hour) {
        setValue('sundayWorkEndHour', format(new Date(internClt.sunday_work_end_hour), 'HH:mm'))
      }
      if (internClt.sunday_work_interval_start_hour) {
        setValue('sundayWorkIntervalStartHour', format(new Date(internClt.sunday_work_interval_start_hour), 'HH:mm'))
      }
      if (internClt.sunday_work_interval_end_hour) {
        setValue('sundayWorkIntervalEndHour', format(new Date(internClt.sunday_work_interval_end_hour), 'HH:mm'))
      }

      if (internClt.work_week_days) setValue('workWeekDays', internClt.work_week_days)
      if (internClt.benefits) setValue('benefits', internClt.benefits)
      if (internClt.vr_daily_value_in_cents) setValue('vrDailyValue', (internClt.vr_daily_value_in_cents / 100).toLocaleString('pt-BR', {
        style: 'decimal'
      }))
      if (internClt.va_daily_value_in_cents) setValue('vaDailyValue', (internClt.va_daily_value_in_cents / 100).toLocaleString('pt-BR', {
        style: 'decimal'
      }))
      if (internClt.fuel_monthly_value_in_cents) setValue('fuelMonthlyValue', (internClt.fuel_monthly_value_in_cents / 100).toLocaleString('pt-BR', {
        style: 'decimal'
      }))
      if (internClt.phone_assistance_value_in_cents) setValue('phoneAssistanceValue', (internClt.phone_assistance_value_in_cents / 100).toLocaleString('pt-BR', {
        style: 'decimal'
      }))

      if (internClt.vt_daily_value_in_cents) setValue('vtDailyValue', (internClt.vt_daily_value_in_cents / 100).toLocaleString('pt-BR', {
        style: 'decimal'
      }))

      if (internClt.vt_types) setValue('vtTypes', internClt.vt_types)

    }
  }, [internCltData, setValue])

  const { mutateAsync: editInternCltFn } = useMutation({
    mutationFn: editInternClt,
    onSuccess: () => history.push('/internos-clt')
  })

  async function handleEditInternClt(values: InternCltFormSchema) {
    await promiseMessage(editInternCltFn({
      body: {
        ...values,
        officeId: values.office.value,
        salaryInCents: values.salary ? Math.ceil(transformStringToNumber(values.salary) * 100) : null,
        benefits: values.benefits,
        hasCommission: values.hasCommission === 'yes',
        hasHealthInsuranceDependents: values.hasHealthInsuranceDependents === 'yes',
        hasLeadershipGratification: values.hasLeadershipGratification === 'yes',
        leadershipGratificationValueInCents: values.hasLeadershipGratification === 'yes'
          ? Math.ceil(transformStringToNumber(values.leadershipGratificationValue) * 100)
          : null,
        workStartHour: set(new Date(), {
          ...getInputTimeHoursAndMinutesSplited(values.workStartHour)
        }),
        workEndHour: set(new Date(), {
          ...getInputTimeHoursAndMinutesSplited(values.workEndHour)
        }),
        workIntervalStartHour: set(new Date(), {
          ...getInputTimeHoursAndMinutesSplited(values.workIntervalStartHour)
        }),
        workIntervalEndHour: set(new Date(), {
          ...getInputTimeHoursAndMinutesSplited(values.workIntervalEndHour)
        }),
        saturdayWorkStartHour: values.saturdayWorkStartHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.saturdayWorkStartHour)
          })
          : null,
        saturdayWorkEndHour: values.saturdayWorkEndHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.saturdayWorkEndHour)
          })
          : null,
        saturdayWorkIntervalStartHour: values.saturdayWorkIntervalStartHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.saturdayWorkIntervalStartHour)
          })
          : null,
        saturdayWorkIntervalEndHour: values.saturdayWorkIntervalEndHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.saturdayWorkIntervalEndHour)
          })
          : null,
        sundayWorkStartHour: values.sundayWorkStartHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.sundayWorkStartHour)
          })
          : null,
        sundayWorkEndHour: values.sundayWorkEndHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.sundayWorkEndHour)
          })
          : null,
        sundayWorkIntervalStartHour: values.sundayWorkIntervalStartHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.sundayWorkIntervalStartHour)
          })
          : null,
        sundayWorkIntervalEndHour: values.sundayWorkIntervalEndHour
          ? set(new Date(), {
            ...getInputTimeHoursAndMinutesSplited(values.sundayWorkIntervalEndHour)
          })
          : null,
        admissionDate: set(new Date(), {
          ...getInputDateYearsMonthAndDateSplited(values.admissionDate)
        }),
        bornDate: set(new Date(), {
          ...getInputDateYearsMonthAndDateSplited(values.bornDate)
        }),
        vrDailyValueInCents: values.vrDailyValue ? Math.ceil(transformStringToNumber(values.vrDailyValue) * 100) : null,
        vaDailyValueInCents: values.vaDailyValue ? Math.ceil(transformStringToNumber(values.vaDailyValue) * 100) : null,
        fuelMonthlyValueInCents: values.fuelMonthlyValue ? Math.ceil(transformStringToNumber(values.fuelMonthlyValue) * 100) : null,
        phoneAssistanceValueInCents: values.phoneAssistanceValue ? Math.ceil(transformStringToNumber(values.phoneAssistanceValue) * 100) : null,
        vtDailyValueInCents: values.vtDailyValue
          ? Math.ceil(transformStringToNumber(values.vtDailyValue) * 100)
          : null,
      },
      routeParams: {
        internCltId
      }
    }), 'Clt interno editado com sucesso!')
  }

  return (
    <Box
      rounded="md"
      p={4}
      bg="white"
    >
      <Flex gap={3} align="center">
        <IconButton
          aria-label="Voltar à lista de internos clt"
          icon={<FaArrowLeft />}
          variant="ghost"
          size="sm"
          onClick={() => history.goBack()}
        />
        <Heading letterSpacing="tight">Editar Interno CLT</Heading>
      </Flex>

      <Modal
        isOpen={isCreateInternCltOfficeModalOpen}
        onClose={onCloseCreateInternCltOfficeModal}
        isCentered
      >
        <ModalOverlay />

        <CreateInternCltOfficeModal
          onClose={onCloseCreateInternCltOfficeModal}
        />

      </Modal>

      <FormProvider {...formMethods}>
        <Flex
          direction="column"
          w="full"
          gap={3}
          as="form"
          onSubmit={handleSubmit(handleEditInternClt)}
        >
          {internCltData && (
            <InternCltForm
              onOpenCreateInternCltOfficeModal={onOpenCreateInternCltOfficeModal}
              isCepQueryEnabled={isCepQueryEnabled}
              onSetCepQueryEnabled={() => setIsCepQueryEnabled(true)}
              slug='edit'
            />
          )}

          <Button
            alignSelf="end"
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>

      </FormProvider>

    </Box>
  )
}

import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CollectorConfirmDispatchStockProps {
  body: {
    arrivalDate: Date
  },
  routeParams: {
    dispatchStockId: string
  }
}

export async function collectorConfirmDispatchStock({
  routeParams,
  body
}: CollectorConfirmDispatchStockProps) {
  try {
    await api.post(`/dispatch-stocks/${routeParams.dispatchStockId}/collector-confirm`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Patrimony, PatrimonyStatus } from './patrimony';


interface GetPatrimonyParams {
  pageSize?: string
  currentPage?: string
  type?: string
  brand?: string
  status?: PatrimonyStatus | 'all' | null
}

export interface GetPatrimonyResponse {
  patrimonies: Patrimony[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getPatrimonies({
  pageSize,
  currentPage,
  type,
  brand,
  status
}: GetPatrimonyParams) {
  try {
    const { data } = await api.get<GetPatrimonyResponse>('/patrimonies', {
      params: {
        pageSize,
        currentPage,
        type,
        brand,
        status
      }
    })
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import request from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { useQuery, UseQueryOptions } from "react-query"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { ComunicationAnswer, ComunicationQuestion, ComunicationStatus, InternComunication } from "./dtos/InternComunication"

interface InternComunicationsQueryParams {
  comunication_type?: string
  status?: ComunicationStatus[]
  comunication_service?: string
}

type QueryOptions = UseQueryOptions<InternComunication[], unknown, InternComunication[], "internComunications">

interface UseInternComunicationsOptions {
  queryOptions?: QueryOptions
  queryParams?: InternComunicationsQueryParams
}

export function useInternComunications(options?: UseInternComunicationsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getInternComunications(queryParams?: InternComunicationsQueryParams) {
    try {
      const { data: internComunications } = await api.get("/intern-comunication", {
        params: {
          ...queryParams,
          status: queryParams?.status?.join(",")
        }
      })

      return internComunications
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!", 500)
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  const transformResponseData = (
    internComunicationsResponse: InternComunication[]
  ) => {
    return internComunicationsResponse.map(internComunication => {
      return {
        ...internComunication,
        comunication_answers: JSON.parse(internComunication.comunication_answers as string) as ComunicationAnswer[],
        comunication_questions: JSON.parse(internComunication.comunication_questions as string) as ComunicationQuestion[]
      }
    })
  }

  return useQuery("internComunications", () => getInternComunications(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    select: transformResponseData,
    onError: onRequestError
  })
}

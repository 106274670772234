import { Box, Flex, Heading, IconButton } from "@chakra-ui/react";
import { getStandardizations } from "api/standardizations/getStandardizations";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { StandardizationsKanbanColumn } from "./components/StandardizationsKanbanColumn";

export function StandardizationsKanban() {
  const { userLogged } = useAuth()
  const {  push: redirect } = useHistory()

  const userCanViewStandardizations = userLogged?.permissions.includes('view-standardizations')
  const userCanCreateStandardization = userLogged?.permissions.includes('create-standardization')

  useEffect(() => {
    if (!userCanViewStandardizations) redirect('/')
  }, [redirect, userCanViewStandardizations])

  const {
    data: pendingStandardizationsResult,
    isLoading: isPendingStandardizationsResultLoading,
    hasNextPage: hasNextPagePendingStandardizations,
    fetchNextPage: fetchNextPagePendingStandardizations,
    isFetchingNextPage: isFetchingNextPagePendingStandardizations,
  } = useInfiniteQuery({
    queryKey: ['pending-standardizations'],
    queryFn: ({ pageParam = 1 }) => getStandardizations({
      currentPage: pageParam,
      pageSize: '10',
      status: 'pending'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: approvedStandardizationsResult,
    isLoading: isApprovedStandardizationsResultLoading,
    hasNextPage: hasNextPageApprovedStandardizations,
    fetchNextPage: fetchNextPageApprovedStandardizations,
    isFetchingNextPage: isFetchingNextPageApprovedStandardizations,
  } = useInfiniteQuery({
    queryKey: ['approved-standardizations'],
    queryFn: ({ pageParam = 1 }) => getStandardizations({
      currentPage: pageParam,
      pageSize: '10',
      status: 'approved'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: reprovedStandardizationsResult,
    isLoading: isReprovedStandardizationsResultLoading,
    hasNextPage: hasNextPageReprovedStandardizations,
    fetchNextPage: fetchNextPageReprovedStandardizations,
    isFetchingNextPage: isFetchingNextPageReprovedStandardizations,
  } = useInfiniteQuery({
    queryKey: ['reproved-standardizations'],
    queryFn: ({ pageParam = 1 }) => getStandardizations({
      currentPage: pageParam,
      pageSize: '10',
      status: 'reproved'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsStandardizations = [
    {
      title: 'Pendentes',
      standardizations: pendingStandardizationsResult?.pages.flatMap((page) => page.standardizations) || [],
      standardizationsCount: pendingStandardizationsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPagePendingStandardizations,
      fetchNextPage: fetchNextPagePendingStandardizations,
      isFetchingNextPage: isFetchingNextPagePendingStandardizations,
      isLoading: isPendingStandardizationsResultLoading,
    },
    {
      title: 'Aprovadas',
      standardizations: approvedStandardizationsResult?.pages.flatMap((page) => page.standardizations) || [],
      standardizationsCount: approvedStandardizationsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageApprovedStandardizations,
      fetchNextPage: fetchNextPageApprovedStandardizations,
      isFetchingNextPage: isFetchingNextPageApprovedStandardizations,
      isLoading: isApprovedStandardizationsResultLoading,
    },
    {
      title: 'Reprovadas',
      standardizations: reprovedStandardizationsResult?.pages.flatMap((page) => page.standardizations) || [],
      standardizationsCount: reprovedStandardizationsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageReprovedStandardizations,
      fetchNextPage: fetchNextPageReprovedStandardizations,
      isFetchingNextPage: isFetchingNextPageReprovedStandardizations,
      isLoading: isReprovedStandardizationsResultLoading,
    },
  ]

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">

        <Heading letterSpacing="tight">Padronizações</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/padronizacoes')}
          />
          {userCanCreateStandardization && (
            <IconButton
              aria-label="Criar Padronização"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={() => redirect("/padronizacao/criar")}
            />
          )}
        </Flex>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsStandardizations?.map((standardization, index)=> {
          return(
            <StandardizationsKanbanColumn
              key={index}
              standardization={standardization}
            />
          )
        })}
      </Flex>
    </Box>
  )
}

import { Attachment } from 'hooks/attachment/dtos/Attachment';
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Support } from "./_types/Support";
import { ExternalSupport } from './_types/ExternalSupport';
import { PartnerSupport } from './_types/PartnerSupport';
import { Collector } from 'hooks/collector/dtos/Collector';
import { SupportSubject } from './_types/SupportSubject';
import { User } from 'hooks/user/dtos/User';
import { InternSupport } from './_types/InternSupport';

interface Resolution {
  id: string
  content: string
  createdAt: string
  updatedAt: string
  supportId: string
  attachments: {
    id: string
    attachment: Attachment
  }[]
  reply: {
    id: string
    content: string
    createdAt: string
    updatedAt: string
    supportResolutionId: string
    attachments: {
      id: string
      attachment: Attachment
    }[]
  }
}

export interface GetSupportResponse {
  support: Support & {
    requestAttachments: {
      id: string
      attachment: Attachment
    }[]
    externalSupport: ExternalSupport | null
    partnerSupport: PartnerSupport & {
      collector: Collector
    } | null
    subject: SupportSubject & {
      responsibles: {
        id: string
        user: User
      }[]
    }
    resolutions: Resolution[] | null
    internSupport: InternSupport | null
  }
}

interface GetSupportParams {
  supportId: string
}

export async function getSupport({
  supportId
}: GetSupportParams) {
  try {
    const response = await api.get<GetSupportResponse>(`/supports/${supportId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

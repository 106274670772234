import axios from "axios"
import { CollectorCost } from "hooks/cost/dtos/CollectorCost"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetPrimaryExpressCostByCityParams {
  cityId: string
}

interface GetPrimaryExpressCostByCity {
  collectorCost: CollectorCost
}

export async function getPrimaryExpressCostByCity({
  cityId
}: GetPrimaryExpressCostByCityParams) {
  try {
    const response = await api.get<GetPrimaryExpressCostByCity>(`/primary-cost/collector/cities/${cityId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { AnswerRncForm } from '../../components/Form/Rnc/AnswerRncForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useRncFunctions } from '../../hooks/rnc/useRncFunctions'
import { useSwal } from '../../hooks/swal/useSwal'

interface IFormInputProps {
  cause: string | null
  corrective: string | null
  preventive: string | null
  why_not_proceed: string | null
  reasons_why: {
    content: string
  }[]
}

interface IQueryParams {
  rncID: string
}

export function AnswerRncPage() {
  const [isLoading, setIsLoading] = useState(false)

  const {
    answerRnc: { mutateAsync: answerRnc },
  } = useRncFunctions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  const { rncID }: IQueryParams = useParams()

  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('add-rnc')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const queryClient = useQueryClient()

  const handleAnswerRnc = async (values: IFormInputProps) => {
    const newValues = {
      rncId: rncID,
      answerInput: {
        cause: values.cause ?? null,
        corrective: values.corrective ?? null,
        preventive: values.preventive ?? null,
        why_not_proceed: values.why_not_proceed ?? null,
        reasons_why: values.reasons_why,
      },
    }

    const hasAnswerRnc = await confirmMessage({
      title: 'Deseja responder um RNC?',
    })

    if (hasAnswerRnc) {
      setIsLoading(true)
      try {
        const answerRncResponse = await answerRnc(newValues)
        if (answerRncResponse) {
          await queryClient.invalidateQueries('userTasks')
          redirectTo('/rnc/pendentes-resposta')
        }
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <AnswerRncForm
      title="Responder Rnc"
      rncID={rncID}
      submit={handleAnswerRnc}
    />
  )
}

import { Box, Button, CheckboxGroup, Checkbox, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Select, Stack, Text, VStack, HStack, Modal, ModalOverlay, useDisclosure, Portal } from "@chakra-ui/react"
import { Controller, useForm, useWatch } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { set } from "date-fns";
import { sendCltDriverDismissComunincation } from "api/cltDrivers/sendCltDriverDismissComunication";
import { getCltDriver } from "api/cltDrivers/getCltDriver";
import { captalize } from "utils/captalize";
import { FaPlus } from "react-icons/fa6";
import { CreateCltDriverCltIntercurrenceModal } from "./CreateCltDriverCltIntercurrenceModal";
import { useEffect, useState } from "react";

interface SendCltDriverDismissComunicationProps {
  cltDriverId: string
}

export interface SendCltDriverDismissComunicationSchema {
  doesInformedDismissOnScalabrini: string
  lastWorkDate: string
  hasConferredPointRegisters: string
  employeeInactivationsChecklist: string[]
  doesVerifiedEmployeeHasTrunkAndCoolerBoxAndBags: string
  doesEmployeeReturnedAllPatrimonies: string
  hasSignedNotice: string
}

const sendCltDriverDismissComunicationSchema = yup.object({
  lastWorkDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  doesInformedDismissOnScalabrini: yup.string().required(),
  hasConferredPointRegisters: yup.string().required(),
  employeeInactivationsChecklist: yup.array().min(1),
  doesVerifiedEmployeeHasTrunkAndCoolerBoxAndBags: yup.string().required(),
  doesEmployeeReturnedAllPatrimonies: yup.string().required(),
  hasSignedNotice: yup.string().required(),
})


const employeeInactivationsChecklistOptions = [
  { name: 'Pontomais' },
  { name: 'Plataforma de treinamento' }
]


export function SendCltDriverDismissComunication({ cltDriverId }: SendCltDriverDismissComunicationProps) {
  const [isCltIntercurrenceCreatedOrNotNecessary, setIsCltIntercurrenceCreatedOrNotNecessary] = useState(true)

  const {
    data: cltDriver,
  } = useQuery({
    queryKey: ['clt-driver', cltDriverId],
    queryFn: () => getCltDriver({ routeParams: { cltDriverId } })
  })

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    register,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverDismissComunicationSchema>({
    resolver: yupResolver(sendCltDriverDismissComunicationSchema),
  })

  const { mutateAsync: sendCltDriverDismissComunicationFn } = useMutation({
    mutationFn: sendCltDriverDismissComunincation,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverDismissComunication(values: SendCltDriverDismissComunicationSchema) {
    if (isCltIntercurrenceCreatedOrNotNecessary) {
      await promiseMessage(sendCltDriverDismissComunicationFn({
        body: {
          ...values,
          doesEmployeeReturnedAllPatrimonies: values.doesEmployeeReturnedAllPatrimonies === 'yes',
          doesInformedDismissOnScalabrini: values.doesInformedDismissOnScalabrini === 'yes',
          doesVerifiedEmployeeHasTrunkAndCoolerBoxAndBags: values.doesVerifiedEmployeeHasTrunkAndCoolerBoxAndBags === 'yes',
          hasSignedNotice: values.hasSignedNotice === 'yes',
          hasConferredPointRegisters: values.hasConferredPointRegisters === 'yes',
        }, cltDriverId
      }), 'Comunicação de demissão enviada com sucesso!')
    }
  }

  const doesEmployeeReturnedAllPatrimonies = useWatch({
    control,
    name: 'doesEmployeeReturnedAllPatrimonies'
  })

  const doesEmployeeNotReturnedAllPatrimonies = doesEmployeeReturnedAllPatrimonies === 'no'

  useEffect(() => {
    if (doesEmployeeNotReturnedAllPatrimonies) {
      setIsCltIntercurrenceCreatedOrNotNecessary(false)
    } else {
      setIsCltIntercurrenceCreatedOrNotNecessary(true)
    }
  }, [doesEmployeeNotReturnedAllPatrimonies])

  const {
    isOpen: isCreateCltIntercurrenceModalOpen,
    onOpen: handleOpenCreateCltIntercurrenceModal,
    onClose: handleCloseCreateCltIntercurrenceModal
  } = useDisclosure()

  return (
    <>
      <Modal
        isOpen={isCreateCltIntercurrenceModalOpen}
        onClose={handleCloseCreateCltIntercurrenceModal}
        size="4xl"
      >
        <ModalOverlay />

        <CreateCltDriverCltIntercurrenceModal
          driverName={captalize(`${cltDriver?.driver.firstname} ${cltDriver?.driver.lastname}`)}
          driverRegional={cltDriver?.regional}
          onCloseModal={handleCloseCreateCltIntercurrenceModal}
          onSetIsCltIntercurrenceCreatedOrNotNecessary={setIsCltIntercurrenceCreatedOrNotNecessary}
        />

      </Modal>

      <Box
        w="full"
        as="form"
        onSubmit={handleSubmit(handleSendCltDriverDismissComunication)}
        ml={3}
      >
        <Heading letterSpacing="tight" size='sm'>Demissão - Comunicação</Heading>
        <FormControl isInvalid={!!errors.doesInformedDismissOnScalabrini} mt={3}>
          <FormLabel fontSize='sm'>
            Demissão informada na Scalabrini?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel >
          <Controller
            name="doesInformedDismissOnScalabrini"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row">
                  <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                  <Radio size='sm' rounded='md' value="no">Não</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.lastWorkDate} mt={3}>
          <FormLabel fontSize="sm">
            Último dia de trabalho
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('lastWorkDate')}
            name="lastWorkDate"
            type="date"
            size="sm"
          />

        </FormControl>

        <FormControl isInvalid={!!errors.hasConferredPointRegisters} mt={3}>
          <FormLabel fontSize="sm">
            Realizou conferência de registros de ponto?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="hasConferredPointRegisters"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row">
                  <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                  <Radio size='sm' rounded='md' value="no">Não</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.employeeInactivationsChecklist} mt={3}>
          <FormLabel fontSize='sm'>
            Funcionário inativado
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            control={control}
            name="employeeInactivationsChecklist"
            render={({ field }) => {
              return (
                <CheckboxGroup
                  value={field.value}
                  onChange={field.onChange}
                >
                  <VStack
                    spacing={3}
                    p={2}
                    border="1px solid"
                    borderColor="gray.200"
                    rounded="md"
                    align="left"
                  >
                    {employeeInactivationsChecklistOptions.map(employeeInactivationChecklistOption => {
                      return (
                        <Checkbox key={employeeInactivationChecklistOption.name} value={employeeInactivationChecklistOption.name}>
                          <Text fontSize="sm">
                            {employeeInactivationChecklistOption.name}
                          </Text>
                        </Checkbox>
                      )
                    })}
                  </VStack>

                </CheckboxGroup>
              )
            }}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.doesVerifiedEmployeeHasTrunkAndCoolerBoxAndBags} mt={3}>
          <FormLabel fontSize="sm">
            Verificou se o funcionário possui baú/caixa térmica e bags?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="doesVerifiedEmployeeHasTrunkAndCoolerBoxAndBags"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row">
                  <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                  <Radio size='sm' rounded='md' value="no">Não</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.doesEmployeeReturnedAllPatrimonies} mt={3}>
          <FormLabel
            fontSize="sm"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack>
              <Text>
                Foram devolvidos todos os patrimônios em posse do funcionário?
              </Text>
              <Text as="sup" color="red.500">*</Text>
            </HStack>

            {doesEmployeeNotReturnedAllPatrimonies && !isCltIntercurrenceCreatedOrNotNecessary && (

              <Button
                leftIcon={<FaPlus />}
                size="xs"
                variant="ghost"
                onClick={handleOpenCreateCltIntercurrenceModal}
              >
                Criar intercorrência
              </Button>
            )}
          </FormLabel>
          <Controller
            name="doesEmployeeReturnedAllPatrimonies"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row">
                  <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                  <Radio size='sm' rounded='md' value="no">Não</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.hasSignedNotice} mt={3}>
          <FormLabel fontSize="sm">
            Aviso prévio assinado?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            name="hasSignedNotice"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row">
                  <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                  <Radio size='sm' rounded='md' value="no">Não</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !isCltIntercurrenceCreatedOrNotNecessary}
          >
            Salvar
          </Button>
        </Flex>
      </Box>
    </>
  )
}

import { Button, Flex, FormLabel, Icon, Link, Stack, Table, TableContainer, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { useFormContext, useWatch } from "react-hook-form";
import { FaEye, FaFile } from "react-icons/fa";
import { handleChangeUrl } from "utils/handleChangeUrl";

interface CrmRncFormInputs {
  rncDate: number
  rncDescription: string
  rncAttachments: FileList
}

interface CrmRncProps {
  isViewPage: boolean
  rncAttachmentPreview?: string[]
}

export function CrmRncForm({ isViewPage, rncAttachmentPreview }: CrmRncProps) {

  const {
    control,
    register,
    formState: { errors }
  } = useFormContext<CrmRncFormInputs>()

  const rncAttachment = useWatch({
    control,
    name: 'rncAttachments'
  })

  return (
    <Flex
      gap={4}
      direction='column'
    >
      <Input
        {...register('rncDate')}
        name='rncDate'
        error={errors.rncDate}
        type='date'
        label='Data'
        isDisabled={isViewPage}
        required
      />

      <TextArea
        {...register('rncDescription')}
        name='rncDescription'
        label='Descrição'
        error={errors.rncDescription}
        isDisabled={isViewPage}
        required
      />
      {!isViewPage && (
        <><Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
          <Input
            {...register('rncAttachments')}
            name="rncAttachments"
            label="Anexo"
            type="file"
            hidden
            multiple
            error={errors.rncAttachments}
            required
          >
            <Flex w="full" align="center" justify="flex-start">
              <Flex align="center">
                <Button
                  as={FormLabel}
                  htmlFor='rncAttachments'
                  _hover={{
                    cursor: 'pointer'
                  }}
                  mr="2"
                  leftIcon={<Icon as={FaFile} />}
                >
                  Upload
                </Button>
              </Flex>
            </Flex>
          </Input>
        </Stack>
          <>
            {rncAttachment && (
              <TableContainer w='full'>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Anexos</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(rncAttachment).map(([key, file]) => {
                      return (
                        <Tr key={key}>
                          <Th>
                            <Link
                              href={URL.createObjectURL(file)}
                              isExternal
                            >
                              <Text as='b'>{file.name} (Visualizar)</Text>
                            </Link>
                          </Th>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </>
        </>
      )}

      {rncAttachmentPreview && rncAttachmentPreview.length > 0 && (
        <TableContainer w="full">
          <Table size="sm" mt={4}>
            <Thead>
              <Tr>
                <Th>Anexos Cadastrados</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {rncAttachmentPreview.map((attachment) => {
                return (
                  <Tr key={attachment}>
                    <Th>{attachment} </Th>
                    <Th isNumeric>
                      <Button
                        as={Link}
                        href={handleChangeUrl(attachment)}
                        variant="ghost"
                        isExternal
                      >
                        <Icon as={FaEye} />
                      </Button>
                    </Th>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  )
}

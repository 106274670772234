import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Flex,
  Button,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye } from 'react-icons/fi'
import { useState, useEffect, Fragment } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { serviceFormatDateToFrontTable } from '../../../../utils/ServiceFunctions/serviceFormatDateToFrontTable'
import { serviceFormatHourToFront } from '../../../../utils/ServiceFunctions/serviceFormatHourToFront'
import { BranchProps } from '../../../../contexts/BranchContext'
import { serviceSortByDate } from '../../../../utils/ServiceFunctions/serviceSortByDate'
import { serviceSortByHour } from '../../../../utils/ServiceFunctions/serviceSortByHourt'
import { useFilterOptions } from '../../../../hooks/filterOptions/useFilterOptions'
import { FaUndoAlt } from 'react-icons/fa'
import { EmptyContentTableAlert } from '../../../Alerts/EmptyContentTableAlert'
import { useAuth } from '../../../../hooks/auth/useAuth'
import { TableFilterButton } from '../../../Filters/TableFilterButton'
import { BudgetProps } from '../../../../contexts/BudgetContext'
import { Address } from 'hooks/address/dtos/Address'

interface LandingServiceTableProps {
  services: ServiceProps[]
  addresses: Address[]
  branches: BranchProps[]
  budgets: BudgetProps[]
  refetch: () => void
}

const landingServiceOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'deliveryDate', value: 'Data da Entrega', checked: false },
  { key: 'deliveryHour', value: 'Horário da Entrega', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'operationalNumber', value: 'Rastreador Validado', checked: false },
  { key: 'volume', value: 'Volumes Embarcados Validados', checked: false },
  { key: 'sourceBranch', value: 'Base de Origem', checked: false },
  { key: 'destinationBranch', value: 'Base de Destino', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'sourceCity', value: 'Cidade de Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade de Destino', checked: false },
  { key: 'destinationHubRegional', value: 'Regional Destino', checked: false },
  {
    key: 'destinationCollector',
    value: 'Coletador de Destino',
    checked: false,
  },
]

const landingServiceOrderOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'deliveryDate', value: 'Data da Entrega', checked: false },
  { key: 'deliveryHour', value: 'Horário da Entrega', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

export function GeloSecoMaintenanceTable({
  services,
  addresses,
  branches,
  budgets,
  refetch,
}: LandingServiceTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [protocolFiltered, setProtocolFiltered] = useState('')
  const [deliveryDateFiltered, setDeliveryDateFiltered] = useState('')
  const [customerFiltered, setCustomerFiltered] = useState('')
  const [sourceCityFiltered, setCityFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [sourceBranchFiltered, setSourceBranchFiltered] = useState('')
  const [destinationBranchFiltered, setDestinationBranchFiltered] = useState('')
  const [shippingFiltered, setShippingFiltered] = useState('')
  const [deliveryHourFiltered, setDeliveryHourFiltered] = useState('')
  const [volumeFiltered, setVolumeFiltered] = useState('')
  const [operationalNumberFiltered, setOperationalNumberFiltered] = useState('')
  const [destinationCollectorFiltered, setDestinationCollectorFiltered] =
    useState('')
  const [destinationHubRegionalFiltered, setDestinationHubRegionalFiltered] =
    useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useAuth()

  const toLandingAndLandingServices = services.filter(
    (service) =>
      service.step === 'pendingGeloSecoMaintenance'
  )

  const isUserTypeCollectorOrDriver =
    userLogged?.user_type === 'MOTORISTA' ||
    userLogged?.user_type === 'COLETADOR'

  const toLandingAndLandingServicesFilteredByUserType =
    isUserTypeCollectorOrDriver
      ? toLandingAndLandingServices.filter((service) => {
        const crossdockingCollectorID =
          service.serviceIDRequested.crossdocking_collector_id

        if (crossdockingCollectorID) {
          const toLandingAndLandingServicesWithCrossdockingCollectorID =
            service.serviceIDLanding.filter(
              (landingService) =>
                landingService.collector_id === crossdockingCollectorID,
            )

          if (
            toLandingAndLandingServicesWithCrossdockingCollectorID.length > 0
          ) {
            return (
              service.serviceIDRequested.destination_collector_id ===
              userLogged.collector_id
            )
          }

          return (
            service.serviceIDRequested.crossdocking_collector_id ===
            userLogged.collector_id
          )
        }

        return (
          service.serviceIDRequested.destination_collector_id ===
          userLogged.collector_id
        )
      })
      : toLandingAndLandingServices

  const isAllInputsEmpty = [
    customerFiltered,
    protocolFiltered,
    sourceCityFiltered,
    destinationCityFiltered,
    deliveryHourFiltered,
    sourceBranchFiltered,
    destinationBranchFiltered,
    shippingFiltered,
    volumeFiltered,
    deliveryDateFiltered,
    operationalNumberFiltered,
    destinationCollectorFiltered,
    destinationHubRegionalFiltered,
  ].every((value) => value === '')

  const servicesFilteredByUserInput =
    toLandingAndLandingServicesFilteredByUserType.filter((service) => {
      const sourceCrossdockingBranchID =
        service.serviceIDRequested.source_crossdocking_branch_id

      const destinationHubRegional = budgets.find(
        (budget) => budget.id === service.serviceIDRequested.budget_id,
      )?.destinationHubIDBudget.regional

      const destinationCollector = sourceCrossdockingBranchID
        ? service.serviceIDLanding.find(
          (landingService) =>
            landingService.collector_id ===
            service.serviceIDRequested.crossdocking_collector_id,
        ) !== undefined
          ? service.serviceIDLanding.find(
            (landingService) =>
              landingService.collector_id ===
              service.serviceIDRequested.crossdocking_collector_id,
          )?.step === 'DONE'
            ? service.serviceIDRequested.destinationCollectorIDService
              .trading_name
            : service.serviceIDRequested.crossdockingCollectorIDService
              .trading_name
          : service.serviceIDRequested.crossdockingCollectorIDService
            .trading_name
        : service.serviceIDRequested.destinationCollectorIDService.trading_name

      const sourceBranch = sourceCrossdockingBranchID
        ? service.serviceIDLanding.find(
          (landingService) =>
            landingService.collector_id ===
            service.serviceIDRequested.crossdocking_collector_id,
        ) !== undefined
          ? service.serviceIDLanding.find(
            (landingService) =>
              landingService.collector_id ===
              service.serviceIDRequested.crossdocking_collector_id,
          )?.step === 'DONE'
            ? String(
              branches
                .filter(
                  (branch) =>
                    branch.id ===
                    service.serviceIDRequested.source_crossdocking_branch_id,
                )
                .map((branch) => branch.nickname),
            )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id === service.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.nickname),
            )
          : String(
            branches
              .filter(
                (branch) =>
                  branch.id === service.serviceIDRequested.source_branch_id,
              )
              .map((branch) => branch.nickname),
          )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id === service.serviceIDRequested.source_branch_id,
            )
            .map((branch) => branch.nickname),
        )

      const destinationBranch = sourceCrossdockingBranchID
        ? service.serviceIDLanding.find(
          (landingService) =>
            landingService.collector_id ===
            service.serviceIDRequested.crossdocking_collector_id,
        ) !== undefined
          ? service.serviceIDLanding.find(
            (landingService) =>
              landingService.collector_id ===
              service.serviceIDRequested.crossdocking_collector_id,
          )?.step === 'DONE'
            ? String(
              branches
                .filter(
                  (branch) =>
                    branch.id ===
                    service.serviceIDRequested.destination_branch_id,
                )
                .map((branch) => branch.nickname),
            )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id ===
                    service.serviceIDRequested
                      .destination_crossdocking_branch_id,
                )
                .map((branch) => branch.nickname),
            )
          : String(
            branches
              .filter(
                (branch) =>
                  branch.id ===
                  service.serviceIDRequested
                    .destination_crossdocking_branch_id,
              )
              .map((branch) => branch.nickname),
          )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id ===
                service.serviceIDRequested.destination_branch_id,
            )
            .map((branch) => branch.nickname),
        )

      const shipping = sourceCrossdockingBranchID
        ? service.serviceIDLanding.find(
          (landingService) =>
            landingService.collector_id ===
            service.serviceIDRequested.crossdocking_collector_id,
        ) !== undefined
          ? service.serviceIDLanding.find(
            (landingService) =>
              landingService.collector_id ===
              service.serviceIDRequested.crossdocking_collector_id,
          )?.step === 'DONE'
            ? String(
              branches
                .filter(
                  (branch) =>
                    branch.id ===
                    service.serviceIDRequested.source_crossdocking_branch_id,
                )
                .map((branch) => branch.shippingIDBranch.company_name),
            )
            : String(
              branches
                .filter(
                  (branch) =>
                    branch.id === service.serviceIDRequested.source_branch_id,
                )
                .map((branch) => branch.shippingIDBranch.company_name),
            )
          : String(
            branches
              .filter(
                (branch) =>
                  branch.id === service.serviceIDRequested.source_branch_id,
              )
              .map((branch) => branch.shippingIDBranch.company_name),
          )
        : String(
          branches
            .filter(
              (branch) =>
                branch.id === service.serviceIDRequested.source_branch_id,
            )
            .map((branch) => branch.shippingIDBranch.company_name),
        )

      const operationalNumber = sourceCrossdockingBranchID
        ? service.serviceIDLanding.find(
          (landingService) =>
            landingService.collector_id ===
            service.serviceIDRequested.crossdocking_collector_id,
        ) !== undefined
          ? service.serviceIDLanding.find(
            (landingService) =>
              landingService.collector_id ===
              service.serviceIDRequested.crossdocking_collector_id,
          )?.step === 'DONE'
            ? String(
              service.serviceIDBoard
                .filter(
                  (boardService) =>
                    boardService.branch_id ===
                    service.serviceIDRequested.source_crossdocking_branch_id,
                )
                .map((boardService) => boardService.operational_number),
            )
            : String(
              service.serviceIDBoard
                .filter(
                  (boardService) =>
                    boardService.branch_id ===
                    service.serviceIDRequested.source_branch_id,
                )
                .map((boardService) => boardService.operational_number),
            )
          : String(
            service.serviceIDBoard
              .filter(
                (boardService) =>
                  boardService.branch_id ===
                  service.serviceIDRequested.source_branch_id,
              )
              .map((boardService) => boardService.operational_number),
          )
        : String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested.source_branch_id,
            )
            .map((boardService) => boardService.operational_number),
        )

      const boardVolume = sourceCrossdockingBranchID
        ? service.serviceIDLanding.find(
          (landingService) =>
            landingService.collector_id ===
            service.serviceIDRequested.crossdocking_collector_id,
        ) !== undefined
          ? service.serviceIDLanding.find(
            (landingService) =>
              landingService.collector_id ===
              service.serviceIDRequested.crossdocking_collector_id,
          )?.step === 'DONE'
            ? String(
              service.serviceIDBoard
                .filter(
                  (boardService) =>
                    boardService.branch_id ===
                    service.serviceIDRequested.source_crossdocking_branch_id,
                )
                .map((boardService) => boardService.board_volume),
            )
            : String(
              service.serviceIDBoard
                .filter(
                  (boardService) =>
                    boardService.branch_id ===
                    service.serviceIDRequested.source_branch_id,
                )
                .map((boardService) => boardService.board_volume),
            )
          : String(
            service.serviceIDBoard
              .filter(
                (boardService) =>
                  boardService.branch_id ===
                  service.serviceIDRequested.source_branch_id,
              )
              .map((boardService) => boardService.board_volume),
          )
        : String(
          service.serviceIDBoard
            .filter(
              (boardService) =>
                boardService.branch_id ===
                service.serviceIDRequested.source_branch_id,
            )
            .map((boardService) => boardService.board_volume),
        )

      const protocolFilter = searchBoxFilter(
        service.protocol.toString(),
        protocolFiltered,
      )
      const customerFilter = searchBoxFilter(
        service.customerIDService.trading_firstname,
        customerFiltered,
      )
      const deliveryDateFilter = searchBoxFilter(
        serviceFormatDateToFrontTable(service.serviceIDRequested.delivery_date),
        deliveryDateFiltered,
      )
      const deliveryHourFilter = searchBoxFilter(
        serviceFormatHourToFront(service.serviceIDRequested.delivery_hour),
        deliveryHourFiltered,
      )
      const sourceCityFilter = searchBoxFilter(
        addresses
          .filter((address) =>
            service.serviceIDRequested.source_address_id.includes(address.id),
          )
          .map((address) => address.cityIDAddress.name)
          .join(', '),
        sourceCityFiltered,
      )
      const destinationCityFilter = searchBoxFilter(
        addresses
          .filter((address) =>
            service.serviceIDRequested.destination_address_id.includes(
              address.id,
            ),
          )
          .map((address) => address.cityIDAddress.name)
          .join(', '),
        destinationCityFiltered,
      )
      const sourceBranchFilter = searchBoxFilter(
        sourceBranch.toString(),
        sourceBranchFiltered,
      )
      const destinationBranchFilter = searchBoxFilter(
        destinationBranch.toString(),
        destinationBranchFiltered,
      )
      const shippingFilter = searchBoxFilter(
        shipping.toString(),
        shippingFiltered,
      )
      const volumeFilter =
        service.serviceIDBoard !== null
          ? searchBoxFilter(boardVolume, volumeFiltered)
          : '-'
      const operationalNumberFilter = searchBoxFilter(
        operationalNumber,
        operationalNumberFiltered,
      )
      const collectorFilter = searchBoxFilter(
        destinationCollector,
        destinationCollectorFiltered,
      )

      const destinationRegionalFilter = searchBoxFilter(
        String(destinationHubRegional),
        destinationHubRegionalFiltered,
      )

      if (isAllInputsEmpty) {
        return service
      }
      return (
        protocolFilter &&
        customerFilter &&
        sourceCityFilter &&
        destinationCityFilter &&
        sourceBranchFilter &&
        destinationBranchFilter &&
        shippingFilter &&
        deliveryHourFilter &&
        volumeFilter &&
        deliveryDateFilter &&
        operationalNumberFilter &&
        collectorFilter &&
        destinationRegionalFilter
      )
    })

  const isOrderOptionsSelected = orderOptionSelected.length > 0

  function sortServicesByDefault(services: ServiceProps[]) {
    return services.sort(
      (dateA, dateB) =>
        serviceSortByDate(
          dateA.serviceIDRequested.delivery_date,
          dateB.serviceIDRequested.delivery_date,
        ) ||
        serviceSortByHour(
          dateA.serviceIDRequested.delivery_hour,
          dateB.serviceIDRequested.delivery_hour,
        ) ||
        branches
          .filter(
            (branch) => branch.id === dateA.serviceIDRequested.source_branch_id,
          )
          .map((branch) => branch.shippingIDBranch.company_name)
          .toString()
          .localeCompare(
            branches
              .filter(
                (branch) =>
                  branch.id === dateB.serviceIDRequested.source_branch_id,
              )
              .map((branch) => branch.shippingIDBranch.company_name)
              .toString(),
          ) ||
        branches
          .filter(
            (branch) => branch.id === dateA.serviceIDRequested.source_branch_id,
          )
          .map((branch) => branch.nickname)
          .toString()
          .localeCompare(
            branches
              .filter(
                (branch) =>
                  branch.id === dateB.serviceIDRequested.destination_branch_id,
              )
              .map((branch) => branch.nickname)
              .toString(),
          ),
    )
  }

  function sortServicesByOrderOptions(services: ServiceProps[]) {
    return services.sort(
      (a, b) =>
        (orderOptionSelected.includes('protocol') && a.protocol - b.protocol) ||
        (orderOptionSelected.includes('deliveryDate') &&
          serviceSortByDate(
            a.serviceIDRequested.delivery_date,
            b.serviceIDRequested.delivery_date,
          )) ||
        (orderOptionSelected.includes('deliveryHour') &&
          serviceSortByHour(
            a.serviceIDRequested.delivery_hour,
            b.serviceIDRequested.delivery_hour,
          )) ||
        0,
    )
  }

  const searchedServices = isOrderOptionsSelected
    ? sortServicesByOrderOptions(servicesFilteredByUserInput)
    : sortServicesByDefault(servicesFilteredByUserInput)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedServices.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(landingServiceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(landingServiceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleRefetchData = () => {
    return refetch()
  }

  return (
    <>
      {toLandingAndLandingServicesFilteredByUserType.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <Stack direction="row">
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchData}>
              <Icon as={FaUndoAlt} />
            </Button>
          </Stack>
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Fragment key={option.key}>
                  <Flex>
                    {option.key === 'protocol' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Protocolo..."
                        handleSearch={(e) =>
                          setProtocolFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'customer' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cliente..."
                        handleSearch={(e) =>
                          setCustomerFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'deliveryDate' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Data Entrega..."
                        handleSearch={(e) =>
                          setDeliveryDateFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'deliveryHour' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Horário Entrega..."
                        handleSearch={(e) =>
                          setDeliveryHourFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Origem..."
                        handleSearch={(e) => setCityFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'destinationCity' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Cidade Destino..."
                        handleSearch={(e) =>
                          setDestinationCityFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'sourceBranch' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Base Origem..."
                        handleSearch={(e) =>
                          setSourceBranchFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationBranch' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Base Destino..."
                        handleSearch={(e) =>
                          setDestinationBranchFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'shipping' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Transportadora..."
                        handleSearch={(e) =>
                          setShippingFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'volume' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Volume..."
                        handleSearch={(e) => setVolumeFiltered(e.target.value)}
                      />
                    )}
                    {option.key === 'operationalNumber' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Rastreador..."
                        handleSearch={(e) =>
                          setOperationalNumberFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationCollector' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Coletador..."
                        handleSearch={(e) =>
                          setDestinationCollectorFiltered(e.target.value)
                        }
                      />
                    )}
                    {option.key === 'destinationHubRegional' && (
                      <SearchBox
                        size="sm"
                        placeholder="Buscar Regional Destino..."
                        handleSearch={(e) =>
                          setDestinationHubRegionalFiltered(e.target.value)
                        }
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  {landingServiceOptions.map((option) => (
                    <Th fontSize="12" color="blue.900" key={option.key}>
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              {!!branches && !!addresses && (
                <Tbody>
                  {searchedServices
                    .slice(offset, offset + Number(itemLimit))
                    .map((service, index) => {
                      const sourceCrossdockingBranchID =
                        service.serviceIDRequested.source_crossdocking_branch_id

                      const sourceBranchId =
                        service.serviceIDRequested.source_branch_id

                      const sourceCrossdockingBranchId =
                        service.serviceIDRequested.source_crossdocking_branch_id

                      const destinationBranchId =
                        service.serviceIDRequested.destination_branch_id

                      const destinationCrossdockingBranchId =
                        service.serviceIDRequested.destination_crossdocking_branch_id

                      const destinationHubRegional = budgets.find(
                        (budget) =>
                          budget.id === service.serviceIDRequested.budget_id,
                      )?.destinationHubIDBudget.regional

                      const destinationCollector = sourceCrossdockingBranchID
                        ? service.pending_crossdocking_gelo_seco_maintenance === true
                          ? service.serviceIDRequested
                            .crossdockingCollectorIDService.trading_name
                          : service.serviceIDRequested
                            .destinationCollectorIDService.trading_name
                        : service.serviceIDRequested
                          .destinationCollectorIDService.trading_name

                      const sourceBranch = sourceCrossdockingBranchID
                        ? service.pending_crossdocking_gelo_seco_maintenance === true
                          ? branches
                            .find(branch => branch.id === sourceBranchId)?.nickname
                          : branches
                            .find(branch => branch.id === sourceCrossdockingBranchId)?.nickname
                        : branches
                          .find(branch => branch.id === sourceBranchId)?.nickname

                      const destinationBranch = sourceCrossdockingBranchID
                        ? service.pending_crossdocking_gelo_seco_maintenance === true
                          ? branches
                            .find(branch => branch.id === destinationCrossdockingBranchId)?.shippingIDBranch.trading_name
                          : branches
                            .find(branch => branch.id === destinationBranchId)?.shippingIDBranch.trading_name
                        : branches
                          .find(branch => branch.id === destinationBranchId)?.shippingIDBranch.trading_name


                      const shipping = sourceCrossdockingBranchID
                        ? service.pending_crossdocking_gelo_seco_maintenance === true
                          ? branches
                            .find(branch => branch.id === sourceBranchId)?.shippingIDBranch.trading_name
                          : branches
                            .find(branch => branch.id === sourceCrossdockingBranchId)?.shippingIDBranch.trading_name
                        : branches
                          .find(branch => branch.id === sourceBranchId)?.shippingIDBranch.trading_name

                      const operationalNumber = sourceCrossdockingBranchID
                        ? service.pending_crossdocking_gelo_seco_maintenance === true
                          ? service.serviceIDBoard
                            .find(board => board.branch_id === sourceBranchId)?.operational_number
                          : service.serviceIDBoard
                            .find(board => board.branch_id === sourceCrossdockingBranchId)?.operational_number
                        : service.serviceIDBoard
                          .find(board => board.branch_id === sourceBranchId)?.operational_number

                      const boardVolume = sourceCrossdockingBranchID
                        ? service.pending_crossdocking_gelo_seco_maintenance === true
                          ? service.serviceIDBoard
                            .find(board => board.branch_id === sourceBranchId)?.board_volume
                          : service.serviceIDBoard
                            .find(board => board.branch_id === sourceCrossdockingBranchId)?.board_volume
                        : service.serviceIDBoard
                          .find(board => board.branch_id === sourceBranchId)?.board_volume

                      return (
                        <Tr key={index}>
                          <Td>
                            {!!userLogged && (
                              <>
                                {userLogged.permissions.includes(
                                  'create-gelo-seco-maintenance',
                                ) && (
                                    <Link
                                      to={`/servico/gelo-seco/manutencao/${service.id}`}
                                    >
                                      <Icon
                                        cursor="pointer"
                                        as={FiEdit3}
                                        fontSize="20"
                                        mt={['2', '2', '0']}
                                      />
                                    </Link>
                                  )}
                                <Link to={`/servico/detalhes/${service.id}`}>
                                  <Icon
                                    cursor="pointer"
                                    as={FiEye}
                                    fontSize="20"
                                  />
                                </Link>
                              </>
                            )}
                          </Td>
                          <Td fontSize="12">{service.protocol}</Td>
                          <Td fontSize="12">
                            {serviceFormatDateToFrontTable(
                              service.serviceIDRequested.delivery_date,
                            )}
                          </Td>
                          <Td fontSize="12">
                            {serviceFormatHourToFront(
                              service.serviceIDRequested.delivery_hour,
                            )}
                          </Td>
                          <Td fontSize="12">
                            {shipping?.toString().toUpperCase() ?? "-"}
                          </Td>
                          <Td fontSize="12">{operationalNumber}</Td>
                          <Td fontSize="12">{boardVolume}</Td>
                          <Td fontSize="12">{sourceBranch?.toUpperCase() ?? "-"}</Td>
                          <Td fontSize="12">
                            {destinationBranch?.toUpperCase() ?? '-'}
                          </Td>
                          <Td fontSize="12">
                            {service.customerIDService.trading_firstname.toUpperCase()}
                          </Td>
                          <Td fontSize="12">
                            {Array.from(
                              new Set(
                                addresses
                                  .filter((address) =>
                                    service.serviceIDRequested.source_address_id.includes(
                                      address.id,
                                    ),
                                  )
                                  .map((address) => address.cityIDAddress.name),
                              ),
                            )
                              .join(', ')
                              .toUpperCase()}
                          </Td>
                          <Td fontSize="12">
                            {Array.from(
                              new Set(
                                addresses
                                  .filter((address) =>
                                    service.serviceIDRequested.destination_address_id.includes(
                                      address.id,
                                    ),
                                  )
                                  .map((address) => address.cityIDAddress.name),
                              ),
                            )
                              .join(', ')
                              .toUpperCase()}
                          </Td>
                          <Td fontSize="12">{destinationHubRegional}</Td>
                          <Td fontSize="12">
                            {destinationCollector.toUpperCase()}
                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              )}
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

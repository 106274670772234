import { Api } from '../api/apiClass'
import { IIbgeApiCityProps, IIbgeApiStatesProps } from './IIbgeApi'

class ApiIbge extends Api {
  constructor() {
    super(process.env.REACT_APP_API_STATES)
  }

  async getStates(url: string) {
    const { data } = await this.server.get<IIbgeApiStatesProps[]>(url)

    return data.sort((a, b) => a.nome.localeCompare(b.nome))
  }

  async getCities(url: string): Promise<IIbgeApiCityProps[]> {
    const { data } = await this.server.get<IIbgeApiCityProps[]>(url)

    return data.sort((a, b) =>
      a.nome.toUpperCase().localeCompare(b.nome.toUpperCase()),
    )
  }
}

const apiIbge = new ApiIbge()

export { apiIbge }

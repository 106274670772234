import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import swal from 'sweetalert'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { BudgetContext, BudgetProps } from '../../../contexts/BudgetContext'
import { AuthContext } from '../../../contexts/AuthContext'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { CitiesProps } from '../../../services/getFunctions/city/getCity'
import { GenerateExcelReportButton } from '../../Buttons/GenerateExcelReportButton'

interface IBudgetTableProps {
  budgets: BudgetProps[]
  cities: CitiesProps[]
  refetch: () => void
}

const budgetOptions = [
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'sourceCity', value: 'Cidade Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade Destino', checked: false },
  { key: 'deadline', value: 'Prazo', checked: false },
  { key: 'franchising', value: 'Franquia', checked: false },
  { key: 'price', value: 'Preço', checked: false },
  { key: 'modal', value: 'Modal', checked: false },
  { key: 'type', value: 'Tipo', checked: false },
  { key: 'vehicle', value: 'Veículo', checked: false },
  { key: 'sourceAddressQuantity', value: 'Pontos de Coleta', checked: false },
  {
    key: 'destinationAddressQuantity',
    value: 'Pontos de Entrega',
    checked: false,
  },
  { key: 'sourceHub', value: 'Hub Origem', checked: false },
  { key: 'destinationHub', value: 'Hub Destino', checked: false },
  { key: 'createdBy', value: 'Criado Por', checked: false },
  { key: 'situation', value: 'Situação', checked: false },
]

const budgetOrderOptions = [{ key: 'customer', value: 'Cliente' }]

const headers = [
  { label: 'CLIENTE', key: 'customer' },
  { label: 'HUB ORIGEM', key: 'source_hub' },
  { label: 'HUB DESTINO', key: 'destination_hub' },
  { label: 'CIDADES ORIGEM', key: 'source_cities' },
  { label: 'CIDADES DESTINO', key: 'destination_cities' },
  { label: 'TRANSPORTADORAS', key: 'shippings' },
  { label: 'QTDE. ENDEREÇOS COLETA', key: 'source_address_qty' },
  { label: 'QTDE. ENDEREÇOS ENTREGA', key: 'destination_address_qty' },
  { label: 'PRAZO', key: 'deadline' },
  { label: 'TIPO DE SERVIÇO', key: 'service_type' },
  { label: 'FRANQUIA', key: 'franchising' },
  { label: 'MODAL', key: 'modal' },
  { label: 'VEÍCULO', key: 'vehicle' },
  { label: 'CAIXA TÉRMICA', key: 'caixa_termica' },
  { label: 'EMBALAGEM SECUNDÁRIA', key: 'embalagem_secundaria' },
  { label: 'GELO SECO', key: 'gelo_seco' },
  { label: 'GELOX', key: 'gelox' },
  { label: 'ISOPOR 3L', key: 'isopor3l' },
  { label: 'ISOPOR 7L', key: 'isopor7l' },
  { label: 'TERCIÁRIA 3L', key: 'terciaria3l' },
  { label: 'TERCIÁRIA 8L', key: 'terciaria8l' },
  { label: 'PREÇO', key: 'price' },
  { label: 'PREÇO KG EXTRA', key: 'price_kg_extra' },
  { label: 'BUDGET DE TRANSFERÊNCIA', key: 'transfer_budget' },
  { label: 'PREÇO ADICIONAL DA COLETA', key: 'price_add_collect' },
  { label: 'PREÇO ADICIONAL DA ENTREGA', key: 'price_add_delivery' },
  { label: 'PREÇO DA COLETA SEM SUCESSO', key: 'price_unsuccessful_collect' },
  { label: 'SITUAÇÃO', key: 'situation' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

function formatValuesToReport(budgets: BudgetProps[], cities: CitiesProps[]) {
  return budgets.map((budget) => {
    return {
      ...budget,
      customer: budget.customerIDBudget.trading_firstname,
      source_hub: budget.sourceHubIDBudget.name,
      destination_hub: budget.destinationHubIDBudget.name,
      source_cities: cities
        .filter((city) => budget.source_cities.includes(city.id))
        .map((city) => city.name)
        .join(', '),
      destination_cities: cities
        .filter((city) => budget.destination_cities.includes(city.id))
        .map((city) => city.name)
        .join(', '),
      shippings: budget?.shippings.length === 0
        ? '-'
        : budget?.shippings?.map((shipping) => shipping?.shipping?.trading_name).join(', ')
    }
  })
}

export function BudgetTable({ budgets, cities, refetch }: IBudgetTableProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE
  const [searchedBudgets, setSearchedBudgets] = useState<BudgetProps[]>([])
  const [slicedBudgets, setSlicedBudgets] = useState<BudgetProps[]>([])

  const [tradingFiltered, setCustomerFiltered] = useState('')
  const [sourceHubsFiltered, setSourceHubsFiltered] = useState('')
  const [destinationHubsFiltered, setDestinationHubsFiltered] = useState('')
  const [sourceCitiesFiltered, setSourceCitiesFiltered] = useState('')
  const [destinationCitiesFiltered, setDestinationCitiesFiltered] = useState('')
  const [deadlineFiltered, setDeadlineFiltered] = useState('')
  const [franchisingFiltered, setFranchisingFiltered] = useState('')
  const [priceFiltered, setPriceFiltered] = useState('')
  const [modalFiltered, setModalFiltered] = useState('')
  const [typeFiltered, setTypeFiltered] = useState('')
  const [vehicleFiltered, setVehicleFiltered] = useState('')
  const [sourceAddressQuantityFiltered, setSourceAddressQuantityFiltered] =
    useState('')
  const [
    destinationAddressQuantityFiltered,
    setDestinationAddressQuantityFiltered,
  ] = useState('')
  const [situationFiltered, setSituationFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { deleteBudget } = useContext(BudgetContext)
  const { userLogged } = useContext(AuthContext)

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedBudgets?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  const headerCsvReportByUserLogged = userLogged?.user_type === 'CLIENTE'
    ? headers.filter((label) => label.key !== 'transfer_budget')
    : headers

  const csvReportProps = {
    data: formatValuesToReport(slicedBudgets, cities),
    headers: headerCsvReportByUserLogged,
    filename: 'relatório-orcamento.csv',
  }

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(budgetOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(budgetOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      if (userLogged !== null) {
        if (userLogged.user_type === 'CLIENTE') {
          const budgetsPerPage = budgets
            .filter((budget) => budget.customer_id === userLogged.customer_id)
            .filter((budget) => budget.situation === 'ATIVO')
          setSlicedBudgets(budgetsPerPage)
        } else {
          setSlicedBudgets(budgets)
        }
      }
    }

    run()
  }, [itemLimit, currentPage, offset, userLogged, budgets])

  useEffect(() => {
    const newSlicedBudgets = slicedBudgets?.filter((budgets) => {
      const sourceCities = cities
        .filter((city) => budgets.source_cities.includes(city.id))
        .map((city) => city.name)
        .join(', ')
      const destinationCities = cities
        .filter((city) => budgets.destination_cities.includes(city.id))
        .map((city) => city.name)
        .join(', ')

      const tradingFirstFilter = searchBoxFilter(
        budgets.customerIDBudget.trading_firstname,
        tradingFiltered,
      )
      const sourceHubFilter = searchBoxFilter(
        budgets.sourceHubIDBudget.name,
        sourceHubsFiltered,
      )
      const destinationHubFilter = searchBoxFilter(
        budgets.destinationHubIDBudget.name.toString(),
        destinationHubsFiltered,
      )
      const sourceCitiesFilter = searchBoxFilter(
        sourceCities,
        sourceCitiesFiltered,
      )
      const destinationCitiesFilter = searchBoxFilter(
        destinationCities,
        destinationCitiesFiltered,
      )
      const deadlineFilter = searchBoxFilter(
        budgets.deadline.toString(),
        deadlineFiltered,
      )
      const franchisingFilter = searchBoxFilter(
        String(budgets.franchising.toFixed(2)),
        franchisingFiltered,
      )
      const priceFilter = searchBoxFilter(
        String(budgets.price.toFixed(2)).replace('.', ','),
        priceFiltered,
      )
      const modalFilter = searchBoxFilter(budgets.modal, modalFiltered)
      const typeFilter = searchBoxFilter(budgets.service_type, typeFiltered)
      const vehicleFilter = searchBoxFilter(budgets.vehicle, vehicleFiltered)
      const sourceAddressQuantityFilter = searchBoxFilter(
        budgets.source_address_qty.toString(),
        sourceAddressQuantityFiltered,
      )
      const destinationAddressQuantityFilter = searchBoxFilter(
        budgets.source_address_qty.toString(),
        destinationAddressQuantityFiltered,
      )
      const situationFilter = budgets.situation.toLowerCase().startsWith(situationFiltered.toLowerCase())

      if (
        tradingFiltered === '' &&
        sourceHubsFiltered === '' &&
        destinationHubsFiltered === '' &&
        sourceCitiesFiltered === '' &&
        destinationCitiesFiltered === '' &&
        deadlineFiltered === '' &&
        franchisingFiltered === '' &&
        priceFiltered === '' &&
        modalFiltered === '' &&
        typeFiltered === '' &&
        vehicleFiltered === '' &&
        sourceAddressQuantityFiltered === '' &&
        destinationAddressQuantityFiltered === '' &&
        situationFiltered === ''
      )
        return budgets

      return (
        tradingFirstFilter &&
        sourceHubFilter &&
        destinationHubFilter &&
        sourceCitiesFilter &&
        destinationCitiesFilter &&
        deadlineFilter &&
        franchisingFilter &&
        priceFilter &&
        modalFilter &&
        typeFilter &&
        vehicleFilter &&
        sourceAddressQuantityFilter &&
        destinationAddressQuantityFilter &&
        situationFilter
      )
    })
    if (orderOptionSelected.length > 0) {
      const newSlicedBudgetsSorted = newSlicedBudgets.sort(
        (a, b) =>
          (orderOptionSelected.includes('customer') &&
            a.customerIDBudget.trading_firstname.localeCompare(
              b.customerIDBudget.trading_firstname,
            )) ||
          0,
      )
      setSearchedBudgets(newSlicedBudgetsSorted)
    } else {
      setSearchedBudgets(newSlicedBudgets)
    }
  }, [
    tradingFiltered,
    sourceCitiesFiltered,
    destinationCitiesFiltered,
    sourceHubsFiltered,
    destinationHubsFiltered,
    deadlineFiltered,
    priceFiltered,
    modalFiltered,
    typeFiltered,
    situationFiltered,
    vehicleFiltered,
    sourceAddressQuantityFiltered,
    destinationAddressQuantityFiltered,
    franchisingFiltered,
    slicedBudgets,
    orderOptionSelected,
    cities,
  ])

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'customer':
        return setCustomerFiltered(value)
      case 'sourceHub':
        return setSourceHubsFiltered(value)
      case 'destinationHub':
        return setDestinationHubsFiltered(value)
      case 'sourceCity':
        return setSourceCitiesFiltered(value)
      case 'destinationCity':
        return setDestinationCitiesFiltered(value)
      case 'deadline':
        return setDeadlineFiltered(value)
      case 'franchising':
        return setFranchisingFiltered(value)
      case 'price':
        return setPriceFiltered(value)
      case 'modal':
        return setModalFiltered(value)
      case 'type':
        return setTypeFiltered(value)
      case 'vehicle':
        return setVehicleFiltered(value)
      case 'sourceAddressQuantity':
        return setSourceAddressQuantityFiltered(value)
      case 'destinationAddressQuantity':
        return setDestinationAddressQuantityFiltered(value)
      case 'situation':
        return setSituationFiltered(value)
    }
  }

  async function handleDeleteCollector(budgetId: string) {
    await swal({
      title: 'Deseja deletar um orçamento?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        deleteBudget(budgetId).then((res) => {
          if (res) {
            refetch()
          }
        })
      } else {
        swal('Remoção cancelada com êxito!')
      }
    })
  }

  if (slicedBudgets.length <= 0) {
    return (
      <EmptyContentTableAlert
        title="Oops!"
        description="Não há dados para mostrar aqui!"
      />
    )
  }

  return (
    <>
      <Flex
        mb="2"
        wrap="wrap"
        justify={isWideVersion ? 'space-between' : 'flex-start'}
      >
        <Box>
          <TableFilterButton />
        </Box>
        <Box w={isWideVersion ? '' : 'full'}>
          <Stack mt={isWideVersion ? '0' : '2'} direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
        </Box>
      </Flex>
      <Box overflowX="auto" w="100%">
        <Table colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {budgetOptions.map((option) => (
                <Th key={option.key} fontSize="12" color="blue.900">
                  {option.value.toUpperCase()}
                </Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchedBudgets
              .slice(offset, offset + Number(itemLimit))
              .map((budget) => {
                const sourceCities = cities
                  .filter((city) => budget.source_cities.includes(city.id))
                  .map((city) => city.name)
                  .join(', ')
                const destinationCities = cities
                  .filter((city) => budget.destination_cities.includes(city.id))
                  .map((city) => city.name)
                  .join(', ')

                return (
                  <Tr key={budget.id}>
                    <Td fontSize="12">
                      {budget.customerIDBudget.trading_firstname.toUpperCase()}
                    </Td>
                    <Td fontSize="12">{sourceCities.toUpperCase()}</Td>
                    <Td fontSize="12">{destinationCities.toUpperCase()}</Td>
                    <Td fontSize="12">{budget.deadline}</Td>
                    <Td fontSize="12">{`${budget.franchising.toFixed(2)}`}</Td>
                    <Td fontSize="12">{`R$${String(
                      budget.price.toFixed(2),
                    ).replace('.', ',')}`}</Td>
                    <Td fontSize="12">{budget.modal.toUpperCase()}</Td>
                    <Td fontSize="12">{budget.service_type.toUpperCase()}</Td>
                    <Td fontSize="12">{budget.vehicle.toUpperCase()}</Td>
                    <Td fontSize="12">{budget.source_address_qty}</Td>
                    <Td fontSize="12">{budget.destination_address_qty}</Td>
                    <Td fontSize="12">
                      {budget.sourceHubIDBudget.name.toUpperCase()}
                    </Td>
                    <Td fontSize="12">
                      {budget.destinationHubIDBudget.name
                        .toString()
                        .replace(',', ', ')
                        .toUpperCase()}
                    </Td>
                    <Td fontSize="12">{budget.createdBy?.firstname.toUpperCase()} {budget.createdBy?.lastname.toUpperCase()}</Td>
                    <Td fontSize="12">{budget.situation.toUpperCase()}</Td>
                    <Td isNumeric>
                      {userLogged !== null && (
                        <>
                          {userLogged.permissions.includes('view-budget') && (
                            <Link to={`/orcamento/visualizar/${budget.id}`}>
                              <Icon cursor="pointer" as={FiEye} fontSize="20" />
                            </Link>
                          )}
                          {userLogged.permissions.includes('edit-budget') && (
                            <Link to={`/orcamento/editar/${budget.id}`}>
                              <Icon
                                cursor="pointer"
                                as={FiEdit3}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                              />
                            </Link>
                          )}
                          {userLogged.permissions.includes('remove-budget') && (
                            <Icon
                              cursor="pointer"
                              as={FiTrash2}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                              onClick={() => handleDeleteCollector(budget.id)}
                            />
                          )}
                        </>
                      )}
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </Box>
      <GenerateExcelReportButton csvReportProps={csvReportProps} />
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}

type DataProps = {
  group: string
  order: number
  key: string
  value: string
}

const permissionFormatDataToBack = (data: DataProps) => {
  const permissionFormatedData = { ...data }

  return permissionFormatedData
}

export { permissionFormatDataToBack }

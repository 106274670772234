import { Box, Flex } from "@chakra-ui/react";
import { ExtraDiscountStatus } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";

import { extrasDiscountsStatusMap } from "utils/extrasDiscountsMappers";

interface CollectorExtraDiscountStatusProps {
  status: ExtraDiscountStatus
}

export function CollectorExtraDiscountStatus({ status }: CollectorExtraDiscountStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      { ['effectivated', 'approved'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'requested' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}
      {status === 'rejected' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {extrasDiscountsStatusMap[status]}
    </Flex>
  )
}

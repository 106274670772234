import { useProfileSocket } from 'hooks/socket/useProfileSocket'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { CreateProfileForm } from '../../components/Form/Profile/CreateProfileForm'
import { EditDetailProfileForm } from '../../components/Form/Profile/EditDetailProfileForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { usePermission } from '../../hooks/permission/usePermission'
import { useProfile } from '../../hooks/profile/useProfile'
import { useProfileFunctions } from '../../hooks/profile/useProfileFunctions'
import { useSwal } from '../../hooks/swal/useSwal'

interface IFormInputValues {
  user_type: string
  permissions: string[]
}

interface IQueryParams {
  slug: string
  id?: string
}

export function CreateDetailEditProfilePage() {
  const { confirmMessage, standardMessage } = useSwal()
  const { slug, id }: IQueryParams = useParams()
  const { push: redirectTo } = useHistory()
  const { profileSocketConnection } = useProfileSocket()
  const { userLogged } = useAuth()

  const {
    permissions: { data: permissions, isLoading: isPermissionsLoading },
  } = usePermission(null, true)
  const {
    profile: { data: profile, isLoading: isProfileLoading },
  } = useProfile(id ?? null, false)
  const {
    createProfile: {
      mutateAsync: createProfile,
      isLoading: isCreateProfileLoading,
      isSuccess: isCreateProfileSuccess,
    },
    editProfile: {
      mutateAsync: editProfile,
      isLoading: isEditProfileLoading,
      isSuccess: isEditProfileSuccess,
    },
  } = useProfileFunctions()

  useEffect(() => {
    if (isCreateProfileSuccess || isEditProfileSuccess) {
      redirectTo('/perfis')
    }
  }, [isCreateProfileSuccess, isEditProfileSuccess, redirectTo])

  useEffect(() => {
    if (slug === 'adicionar') {
      if (!userLogged?.permissions?.includes('add-profile')) {
        return redirectTo('/')
      }
    }

    if (slug === 'editar') {
      if (!userLogged?.permissions?.includes('edit-profile')) {
        return redirectTo('/')
      }
    }

    if (slug === 'visualizar') {
      if (!userLogged?.permissions?.includes('view-profile')) {
        return redirectTo('/')
      }
    }
  }, [slug, userLogged, redirectTo])

  async function handleCreateProfile(values: IFormInputValues) {
    const hasCreateProfile = await confirmMessage({
      title: 'Deseja criar um perfil?',
    })

    if (hasCreateProfile) {
      await createProfile(values)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleEditProfile(values: IFormInputValues) {
    const hasCreateProfile = await confirmMessage({
      title: 'Deseja editar um perfil?',
    })

    if (hasCreateProfile && id) {
      const editProfileReqData = { input: values, id }
      await editProfile({ ...editProfileReqData }, {
        onSuccess: () => {
          profileSocketConnection.emit("updatePermission", {
            user_type: values.user_type
          })
        }
      })
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <>
      {(isCreateProfileLoading ||
        isPermissionsLoading ||
        isProfileLoading ||
        isEditProfileLoading) && <GeneralContentLoading />}
      {slug === 'adicionar' && permissions && (
        <CreateProfileForm
          submit={handleCreateProfile}
          permissions={permissions}
        />
      )}

      {(slug === 'editar' || slug === 'visualizar') &&
        permissions &&
        profile &&
        id && (
          <EditDetailProfileForm
            submit={handleEditProfile}
            permissions={permissions}
            profile={profile}
            slug={slug}
            id={id}
          />
        )}
    </>
  )
}

import { RncProps } from './../../utils/RequestFunctions/Rnc/requestRncFunctions';
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { api } from 'services/api'
import { RequestError } from 'utils/errors/RequestErrors'
import {
  createRncReqFunction,
  deleteRncReqFunction,
  validateRncReqFunction,
  ReqAnswerProps,
  ReqValidateProps,
  answerRncReqFunction,
  alignRncReqFunction,
  ReqAlignProps,
} from '../../utils/RequestFunctions/Rnc/requestRncFunctions'
import { useSwal } from '../swal/useSwal'

interface AlignInternRncReqFunctionProps {
  rncId: string
  input: {
    alignments: {
      alignedBy: string
      date: string
      description: string
    }[]
  }
}

async function alignInternRncReqFunction({
  rncId,
  input
}: AlignInternRncReqFunctionProps) {
  try {
    await api.patch(`/rncs/${rncId}/alignments`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado.')
    }
  }
}

interface SendInternRncAlignmentAttachmentsReqFunctionProps {
  internRncAlignmentId: string
  input: FormData
}

async function sendInternRncAlignmentAttachmentsReqFunction({
  internRncAlignmentId,
  input
}: SendInternRncAlignmentAttachmentsReqFunctionProps) {
  try {
    await api.patch(`/rncs/alignments/${internRncAlignmentId}/attachments`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado.')
    }
  }
}

interface GenerateRncReportProps {
  filterType: 'GERAL' | 'ETAPA'
  initialDate?: string
  finalDate?: string
  step?: string
}

export interface RncResponse{
  rncs:  RncProps[]
}


async function generateRncReportReqFunction(
  input: GenerateRncReportProps
): Promise<RncResponse> {
  try {
    const { data } = await api.post(`/report/rnc`, input)
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export const useRncFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()
  const { push: redirectTo } = useHistory()

  const postRnc = useMutation(
    'rncPost',
    (rncInput: FormData) => createRncReqFunction(rncInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rncs'])
        successMessage('Rnc criado com sucesso!')
        redirectTo('/rnc')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const answerRnc = useMutation(
    ({ rncId, answerInput }: ReqAnswerProps) =>
      answerRncReqFunction({ rncId, answerInput }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rncs'])
        successMessage('Rnc respondido com sucesso!')
        redirectTo('/rnc')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const validateRnc = useMutation(
    ({ rncId, validateRncInput, hasAlignment }: ReqValidateProps) =>
      validateRncReqFunction({ rncId, validateRncInput, hasAlignment }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rncs'])
        successMessage('Rnc validado com sucesso!')
        redirectTo('/rnc')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const alignRnc = useMutation(
    ({ rncId, alignRncInput, hasUpdateAlignment }: ReqAlignProps) =>
      alignRncReqFunction({ rncId, alignRncInput, hasUpdateAlignment }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rncs'])
        successMessage('Rnc finalizado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const deleteRnc = useMutation(
    (rncId: string) => deleteRncReqFunction(rncId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rncs'])
        successMessage('Rnc excluído com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const alignInternRnc = useMutation('alignRnc', alignInternRncReqFunction)
  const sendInternRncAlignmentAttachments = useMutation(
    'sendInternRncAlignmentAttachments', sendInternRncAlignmentAttachmentsReqFunction
  )

  const generateRncReport = useMutation('generateRncReport', generateRncReportReqFunction)


  return {
    postRnc,
    answerRnc,
    validateRnc,
    alignRnc,
    deleteRnc,
    alignInternRnc,
    sendInternRncAlignmentAttachments,
    generateRncReport
  }
}

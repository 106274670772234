import { Box, Flex, Grid, GridItem, Heading, Skeleton } from "@chakra-ui/react";
import { getCustomersUsersDashboardMetrics, GetCustomersUsersDashboardMetricsResponse } from "api/metrics/getCustomersUsersDashboardMetrics";

import { useAuth } from "hooks/auth/useAuth";
import { FaClock, FaTruck, FaTruckLoading } from "react-icons/fa";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { DashboardCard } from "./components/DashboardCard";

function switchMetricTitle(key: keyof GetCustomersUsersDashboardMetricsResponse) {
  switch (key) {
    case 'inCollectServicesCount':
      return { title: 'Serviços em Coleta', order: 1, icon: FaTruck, path: '/dashboard/cliente/servicos-em-coleta' }
    case 'inBoardServicesCount':
      return { title: 'Serviços em Embarque', order: 2, icon: FaTruckLoading, path: '/dashboard/cliente/servicos-em-embarque' }
    case 'inLandingServicesCount':
      return { title: 'Serviços em Desembarque', order: 3, icon: FaTruckLoading, path: '/dashboard/cliente/servicos-em-desembarque' }
    case 'inDeliveryServicesCount':
      return { title: 'Serviços em Entrega', order: 4, icon: FaTruck, path: '/dashboard/cliente/servicos-em-entregas' }
    default:
      return { title: '-', order: Infinity, icon: FaClock, path: '/' }

  }
}

export function NewCustomerDashboard() {
  const { userLogged } = useAuth()

  const userLoggedName = captalize(`${userLogged.firstname} ${userLogged.lastname}`)

  const {
    data: result,
    isLoading: isLoadingCustomersMetricsResult
  } = useQuery({
    queryKey: ['dashboard-metrics-customers', userLogged?.customer_id],
    queryFn: () => getCustomersUsersDashboardMetrics({ customerId: userLogged?.customer_id })
  })

  const metrics = result
    ? Object.entries(result)
      .map(([key, value]: [keyof GetCustomersUsersDashboardMetricsResponse, number]) => {
        const metricInfo = switchMetricTitle(key)

        return {
          title: metricInfo.title,
          order: metricInfo.order,
          icon: metricInfo.icon,
          path: metricInfo.path,
          count: value,
        }
      })
      .sort((a, b) => a.order - b.order)
    : []

  return (

    <Box
      p="6"
      rounded="md"
    >
      <Flex
        direction="column"
        gap="6"
      >
        <Heading letterSpacing="tight">Olá, {userLoggedName}</Heading>

        <Grid
          gap="6"
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        >

          {isLoadingCustomersMetricsResult ? (
            Array.from({ length: 15 }).map((_, index) => {
              return (
                <GridItem key={index}>
                  <Skeleton rounded="xl" startColor="gray.300" endColor="gray.500" height="220px" w="full" />
                </GridItem>
              )
            })
          ) : (
            metrics.map((metric, index) => {
              return (
                <GridItem key={metric.title}>
                  <DashboardCard
                    title={metric.title}
                    count={metric.count}
                    icon={metric.icon}
                    path={metric.path}
                  />
                </GridItem>
              )
            })
          )}

        </Grid>

      </Flex>
    </Box>

  )
}

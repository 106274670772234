import { useMutation, useQueryClient } from 'react-query'
import {
  CreateHolidayInputProps,
  createHolidayReqFunction,
  deleteHolidayReqFunction,
  editHolidayReqFunction,
  EditHolidayReqProps,
} from '../../utils/RequestFunctions/Holiday/requestHolidayFunctions'
import { useSwal } from '../swal/useSwal'

export const useHolidayFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createHoliday = useMutation(
    'createHoliday',
    (createHolidayInput: CreateHolidayInputProps) =>
      createHolidayReqFunction(createHolidayInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['holidays'])
        successMessage('Feriado criado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const editHoliday = useMutation(
    'editHoliday',
    ({ holidayId, editHolidayInput }: EditHolidayReqProps) =>
      editHolidayReqFunction({ holidayId, editHolidayInput }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['holidays'])
        successMessage('Feriado editado com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  const deleteHoliday = useMutation(
    'deleteHoliday',
    (holidayId: string) => deleteHolidayReqFunction(holidayId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['holidays'])
        successMessage('Feriado excluído com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    createHoliday,
    editHoliday,
    deleteHoliday,
  }
}

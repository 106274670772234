import { format, toDate } from 'date-fns'

interface DataProps {
  service_id: string
  collector_id: string
  landing_availability_date: string
  landing_availability_hour: string
  observation: string
}

export function availableServiceFormatDataToBack(data: DataProps) {
  const availabilityHourSplit = data.landing_availability_hour.split(':')
  const availabilityDateSplit = data.landing_availability_date.split('-')

  const availabilityHourSplitToDate = toDate(Date.now()).setHours(
    Number(availabilityHourSplit[0]),
    Number(availabilityHourSplit[1]),
    0,
  )

  const availabilityHourSplitToDateFormated = format(
    toDate(availabilityHourSplitToDate),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )
  const availabilityDateSplitFormated = format(
    new Date(
      Number(availabilityDateSplit[0]),
      Number(availabilityDateSplit[1]) - 1,
      Number(availabilityDateSplit[2]),
    ),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )

  const newAvailableServiceFormatedData = {
    ...data,
    landing_availability_date: availabilityDateSplitFormated,
    landing_availability_hour: availabilityHourSplitToDateFormated,
  }

  return newAvailableServiceFormatedData
}

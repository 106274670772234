import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { Controller, NestedValue, useForm, useFieldArray, useWatch } from 'react-hook-form'
import { CheckboxService } from '../../../Inputs/CheckboxService'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EmptyAddressesFormServiceAlert } from '../../../Alerts/EmptyAddressesFormServiceAlert'
import { useRequestServiceFunctions } from '../../../../hooks/services/request/useRequestServiceFunctions'
import { useSwal } from '../../../../hooks/swal/useSwal'
import { useEffect, useState } from 'react'
import { AddAddressesToCollectInputProps } from '../../../../utils/RequestFunctions/Service/Request/requestRequestServiceFunctions'
import { Address } from 'hooks/address/dtos/Address'
import { useServiceSocket } from 'hooks/socket/useServiceSocket'
import { useHistory } from 'react-router-dom'
import { TextArea } from 'components/Inputs/TextInput'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { Select } from 'components/Inputs/SelectInput'

interface IAddCollectAddressModalProps {
  isModalOpen: boolean
  collectAddressesToAdd: Address[]
  serviceId: string
  onClose: () => void
  serviceProtocol: number
}


interface IFormInputProps {
  service_id: string
  collect_add_addresses: NestedValue<string[]>
  addressesObservations: {
    address_id: string
    observation: string
  }[]
}

const schema = yup.object().shape({
  collect_add_addresses: yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
      case 'string':
        return yup.string().nullable().required('Campo Obrigatório')
      default:
        return yup
          .array()
          .typeError('Campo Obrigatório')
          .min(1, 'Campo Obrigatório')
          .of(yup.string())
    }
  }),
  addressesObservations: yup.array().of(yup.object().shape({
    address_id: yup.string(),
    observation: yup.string()
  }))
})

export function AddCollectAddressModal({
  isModalOpen,
  onClose,
  collectAddressesToAdd,
  serviceId,
  serviceProtocol
}: IAddCollectAddressModalProps) {
  const [isLoading, setIsLoading] = useState(false)
  const { push: redirectTo } = useHistory();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  const addressesCollectSelected = useWatch({
    control,
    name: 'collect_add_addresses'
  })

  const addressesObservationsOptions = collectAddressesToAdd.filter((address) => addressesCollectSelected?.includes(address.id))?.map((address) => {
    return {
      key: address.id,
      value: address.id,
      showOption: `${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
      ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()
    }
  })

  const {
    fields: addressObservationFields,
    append: appendAddressObservationField,
    remove: removeAddressObservationField
  } = useFieldArray({
    control,
    name: 'addressesObservations'
  })

  function handleRemoveAddressObservationField(index: number) {
    removeAddressObservationField(index)
  }
  function handleAppendAddressObservationField() {
    appendAddressObservationField({ address_id: '', observation: '' })
  }


  const { serviceSocketConnection } = useServiceSocket()

  const {
    addAddressesToCollectService: {
      mutateAsync: addAddressesToCollectService,
      isSuccess: isAddAddressSuccess,
      isError: isAddAddressError,
    },
  } = useRequestServiceFunctions()
  const { standardMessage, confirmMessage } = useSwal()

  useEffect(() => {
    if (isAddAddressError) {
      setIsLoading(false)
    }
  }, [isAddAddressError])

  useEffect(() => {
    if (isAddAddressSuccess) {
      onClose()
      setIsLoading(false)
    }
  }, [isAddAddressSuccess, onClose])

  async function handleAddAddressToCollect(values: IFormInputProps) {
    const hasAddAddressToCollect = await confirmMessage({
      title: 'Deseja solicitar mais endereços para coleta?',
    })

    if (hasAddAddressToCollect) {
      setIsLoading(true)

      const addAddressToCollectReqObj: AddAddressesToCollectInputProps = {
        service_id: serviceId,
        input: {
          collect_add_addresses:
            values.collect_add_addresses.toString().split(',').length > 1
              ? values.collect_add_addresses
              : values.collect_add_addresses.toString().split('  '),
          addressesObservations: values.addressesObservations
        }
      }

      await addAddressesToCollectService({ ...addAddressToCollectReqObj }, {
        onSuccess: async () => {
          serviceSocketConnection?.emit('includedCollectAddress', {
            service_protocol: serviceProtocol
          })
          const hasAddInput = await confirmMessage({
            title: "Gostaria de adicionar insumos?",
            buttons: ['Não', 'Sim'],
          })

          if (hasAddInput) {
            const service_id = serviceId
            redirectTo({
              pathname: '/ocorrencia/criar',
              state: {
                service_id,
              },
            });
          }

        }
      })

    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <Modal
      size="4xl"
      blockScrollOnMount={false}
      isOpen={isModalOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <>
          <ModalHeader>Adicionar Endereços</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <Box
                w="full"
                onSubmit={handleSubmit(handleAddAddressToCollect)}
                as="form"
                p="2"
                noValidate
                overflowY='auto'
                maxH="600px"
              >
                {collectAddressesToAdd.length > 0 ? (
                  <>
                    <Controller
                      name="collect_add_addresses"
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <CheckboxService
                            onCheckboxChange={onChange}
                            name="collect_add_addresses"
                            label="Endereços"
                            error={errors.collect_add_addresses}
                            addresses={collectAddressesToAdd}
                          />
                        )
                      }}
                    />
                    <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                      <Text>Adicionar observações em endereços</Text>
                    </Stack>
                    <Flex direction="column">
                      {addressObservationFields.map((field, index) => {
                        return (
                          <Flex direction='column'>
                            <Stack
                              w="full"
                              spacing="24px"
                              mt="4"
                              direction={['column', 'column', 'row']}
                            >
                              <Select
                                {...register(`addressesObservations.${index}.address_id`)}
                                name={`addressesObservations.${index}.address_id`}
                                label='Endereço'
                                placeholder='Selecione uma opção...'
                                error={
                                  errors.addressesObservations
                                    ? errors?.addressesObservations[index]?.address_id
                                    : undefined
                                }
                                options={addressesObservationsOptions}
                                required
                              />
                            </Stack>

                            <Stack
                              w="full"
                              spacing="24px"
                              mt="4"
                              direction={['column', 'column', 'row']}
                            >
                              <TextArea
                                {...register(`addressesObservations.${index}.observation`)}
                                name={`addressesObservations.${index}.observation`}
                                label='Observações'
                                error={
                                  errors.addressesObservations
                                    ? errors?.addressesObservations[index]?.observation
                                    : undefined
                                }
                                required
                              />

                            </Stack>
                            <Stack pt={8}>
                              <Button
                                leftIcon={<Icon as={FaTimes} />}
                                variant="ghost"
                                onClick={() => handleRemoveAddressObservationField(index)}
                                h="48px"
                              >
                                Remover
                              </Button>
                            </Stack>
                          </Flex>
                        )
                      })}
                      <Button
                        leftIcon={<Icon as={FaPlus} />}
                        alignSelf="flex-start"
                        onClick={handleAppendAddressObservationField}
                        mt={2}
                      >
                        Adicionar
                      </Button>
                    </Flex>
                    <Flex w="full" justify="flex-end" align="flex-end">
                      <Button
                        mt="4"
                        isLoading={isSubmitting}
                        mr="3"
                        type="submit"
                        colorScheme="blue"
                      >
                        Enviar
                      </Button>
                    </Flex>

                  </>
                ) : (
                  <EmptyAddressesFormServiceAlert
                    title="Oops!"
                    description="Não há mais endereços disponíveis para esse serviço! Para maiores informações, consulte a Matriz."
                  />
                )}
              </Box>
            )}
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { Input } from "components/Inputs/Input";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { Fragment, useEffect, useReducer } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FiEye, FiTrash2 } from "react-icons/fi";
import { useClosedExtrasAndDiscounts } from "hooks/extrasAndDiscounts/useClosedExtrasAndDiscounts";
import { useExtrasAndDiscountsFunctions } from "hooks/extrasAndDiscounts/useExtrasAndDiscountsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useSwal } from "hooks/swal/useSwal";
import { useQueryClient } from "react-query";

interface ClosedExtrasAndDiscountsListSearchOptions {
  customer: string
  date: string
  identifier: string
}

const closedExtrasAndDiscountsListSearchOptions = [
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'date', value: 'Data', checked: false },
  { key: 'identifier', value: 'Identificador', checked: false }
]

interface ActionPayload {
  closedExtrasAndDiscountsQueryData?: ClosedExtrasAndDiscountsListSearchOptions,
  currentPage?: number
}

interface Action {
  payload: ActionPayload
  type: 'set-current-page' | 'set-extras-and-discounts-closed-query-data'
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-current-page') {
    return {
      closedExtrasAndDiscountsQueryData: state.closedExtrasAndDiscountsQueryData,
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

export function ClosedExtrasAndDiscountsList() {
  const ROWS_PER_PAGE = process.env.REACT_APP_ITEMS_PER_PAGE

  const [closedExtrasAndDiscountsQueryDataState, dispatch] = useReducer(reducer, {} as ActionPayload)

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const userLoggedHasPermissionToViewClosedExtrasAndDiscounts =
    userLogged?.permissions.includes("view-closed-extras-and-discounts")

  useEffect(() => {
    if (!userLoggedHasPermissionToViewClosedExtrasAndDiscounts) {
      redirect('/')
    }
  }, [userLoggedHasPermissionToViewClosedExtrasAndDiscounts, redirect])

  const {
    control,
    register,
  } = useForm<ClosedExtrasAndDiscountsListSearchOptions>()

  const [
    customerSearched,
    dateSearched,
    identifierSearched
  ] = useWatch({
    control,
    name: ['customer', 'date', 'identifier']
  })

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(closedExtrasAndDiscountsListSearchOptions)
  }, [onLoadSetFilterOptions])

  const {
    data: closedExtrasAndDiscontsData,
    isFetching: isFetchingClosedExtrasAndDiscontsData
  } = useClosedExtrasAndDiscounts({
    queryParams: {
      ...closedExtrasAndDiscountsQueryDataState.closedExtrasAndDiscountsQueryData,
      current_page: closedExtrasAndDiscountsQueryDataState.currentPage,
      page_size: Number(ROWS_PER_PAGE)
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: closedExtrasAndDiscontsData?.total_pages,
      initialState: {
        pageSize: Number(ROWS_PER_PAGE),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleChangePage = (page: number) => setCurrentPage(page)


  useEffect(() => {
    dispatch({
      type: 'set-current-page',
      payload: {
        currentPage: currentPage
      }
    })
  }, [currentPage])


  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch({
        type: 'set-extras-and-discounts-closed-query-data',
        payload: {
          closedExtrasAndDiscountsQueryData: {
            customer: customerSearched,
            date: dateSearched,
            identifier: identifierSearched
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounceTimeout)
  }, [
    customerSearched,
    dateSearched,
    dispatch,
    setCurrentPage,
    identifierSearched
  ])

  const filterOptionsChecked = filterOptions.filter((option) => option.checked)

  const {
    deleteCustomerExtraAndDiscount: {
      mutateAsync: deleteCustomerExtraAndDiscount
    },
  } = useExtrasAndDiscountsFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()

  async function handleDeleteExtraAndDiscount(extraAndDiscountId: string) {
    const hasDeleteGeneralProvider = await confirmMessage({ title: "Deseja excluir o extra/desconto?" })

    if (hasDeleteGeneralProvider) {
      await promiseMessage(deleteCustomerExtraAndDiscount(extraAndDiscountId, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['closedExtrasAndDiscounts'])
        }
      }), "Extra/Desconto excluído com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Heading fontSize="xl">Extras e Descontos Finalizados</Heading>

          <Flex>
            <TableFilterButton />
          </Flex>

          {filterOptionsChecked.map((option) => {
            return (
              <Fragment key={option.key}>
                {option.key === 'date' ? (
                  <Input
                    {...register('date')}
                    name='date'
                    label='Data da criação'
                    type='date'
                  />
                ) : (
                  <Input
                    {...register(option.key as keyof ClosedExtrasAndDiscountsListSearchOptions)}
                    name={option.key}
                    label={`Buscar por ${option.value}`}
                  />
                )}

              </Fragment>
            )
          })}

          {isFetchingClosedExtrasAndDiscontsData ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Tr>
                    <Th>CLIENTE</Th>
                    <Th>DATA CRIAÇÃO</Th>
                    <Th>TIPO</Th>
                    <Th>TIPO DE SERVIÇO</Th>
                    <Th>IDENTIFICADOR</Th>
                    <Th>CRIADO POR</Th>
                    <Th>VALOR DO EXTRA OU DESCONTO</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {closedExtrasAndDiscontsData?.extrasAndDiscounts?.map((extraAndDiscount) => {
                    const extraAndDiscountInfo = extraAndDiscount.service_type === 'EXPRESS'
                      ? extraAndDiscount.protocol
                      : extraAndDiscount.route

                    return (
                      <Tr key={extraAndDiscount.id}>
                        <Td>{extraAndDiscount.customer.trading_firstname}</Td>
                        <Td>{formatDate.handle(extraAndDiscount.created_at, 'DateWithoutHourToShow')}</Td>
                        <Td>{extraAndDiscount.type}</Td>
                        <Td>{extraAndDiscount.service_type}</Td>
                        <Td>{extraAndDiscountInfo}</Td>
                        <Td>{extraAndDiscount.createdBy ? `${extraAndDiscount.createdBy.firstname} ${extraAndDiscount.createdBy.lastname}` : '-'}</Td>
                        <Td>
                          {
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(extraAndDiscount.value / 100)
                          }
                        </Td>
                        <Td>
                          {
                            userLogged.permissions.includes('delete-extra-and-discount') && (
                              <Icon
                                cursor="pointer"
                                as={FiTrash2}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                                onClick={() =>
                                  handleDeleteExtraAndDiscount(extraAndDiscount.id)
                                }
                              />
                            )
                          }
                          {
                            userLogged.permissions.includes('view-extras-and-discounts') && (
                              <Link
                                to={`/extras-e-descontos/visualizar/${extraAndDiscount.id}`}
                              >
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                />
                              </Link>
                            )
                          }
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Flex>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </Box>

  )
}

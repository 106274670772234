import * as yup from 'yup'
import { transformStringToNumber } from '../utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

function checkIfValueIsBiggerThenZero(value: string, originalValue: string) {
  return transformStringToNumber(value) > 0 ? originalValue : ''
}

export class SwitchPriceSchema {
  private schema: yup.ObjectSchema<any>

  constructor() {
    this.schema = yup.object()
  }

  handle(slug?: string) {
    if (slug === 'consultar') {
      const schema = this.schema.shape({
        source_cities: yup.array().min(1).of(yup.object({
          source_uf: yup.string().required('Campo Obrigatório'),
          source_city_id: yup.string().required('Campo Obrigatório')
        })),
        destination_cities: yup.array().min(1).of(yup.object({
          destination_uf: yup.string().required('Campo Obrigatório'),
          destination_city_id: yup.string().required('Campo Obrigatório')
        })),
        route_type: yup.string().required('Campo Obrigatório'),
        has_materials: yup.string().required('Campo Obrigatório'),
        taxed_weight: yup
          .number()
          .typeError('Campo Obrigatório')
          .required('Campo Obrigatório'),
        materials: yup.array().of(
          yup.object().shape({
            input_id: yup.string().required('Campo Obrigatório'),
            quantity: yup.number().typeError('Campo Obrigatório').required('Campo Obrigatório'),
          }),
        ),
        vehicle: yup.string().required('Campo Obrigatório')
      })

      return schema
    }

    const schema = this.schema.shape({
      source_city_uf: yup.string().required('Campo Obrigatório'),
      destination_city_uf: yup.string().required('Campo Obrigatório'),
      source_city_id: yup.string().required('Campo Obrigatório'),
      source_hub_id: yup.string().required('Campo Obrigatório'),
      destination_city_id: yup.string().required('Campo Obrigatório'),
      destination_hub_id: yup.string().required('Campo Obrigatório'),
      category: yup.string().required('Campo Obrigatório'),
      route_type: yup.string().required('Campo Obrigatório'),
      service_type: yup.string().required('Campo Obrigatório'),
      air_minimum_price: yup.string().when('route_type', {
        is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
        then: yup
          .string()
          .transform(checkIfValueIsBiggerThenZero)
          .required('Campo Obrigatório'),
      }),
      air_extra_kg: yup.string().when('route_type', {
        is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
        then: yup
          .string()
          .transform(checkIfValueIsBiggerThenZero)
          .required('Campo Obrigatório'),
      }),
      air_deadline: yup
        .number()
        .nullable()
        .when('route_type', {
          is: (value: string) => value === 'AMBOS' || value === 'AÉREO',
          then: yup
            .number()
            .nullable()
            .typeError('Campo Obrigatório')
            .required('Campo Obrigatório'),
        }),
      highway_minimum_price: yup.string().when('route_type', {
        is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
        then: yup
          .string()
          .transform(checkIfValueIsBiggerThenZero)
          .required('Campo Obrigatório'),
      }),
      highway_extra_kg: yup.string().when('route_type', {
        is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
        then: yup
          .string()
          .transform(checkIfValueIsBiggerThenZero)
          .required('Campo Obrigatório'),
      }),
      highway_deadline: yup
        .number()
        .nullable()
        .when('route_type', {
          is: (value: string) => value === 'AMBOS' || value === 'RODOVIÁRIO',
          then: yup
            .number()
            .typeError('Campo Obrigatório')
            .nullable()
            .required('Campo Obrigatório'),
        }),
      additional_collect_price: yup
        .string()
        .transform(checkIfValueIsBiggerThenZero)
        .required('Campo Obrigatório'),
      additional_delivery_price: yup
        .string()
        .transform(checkIfValueIsBiggerThenZero)
        .required('Campo Obrigatório'),
      shippingIds: yup.mixed().when('service_type', {
        is: 'FRACIONADO',
        then: yup.array().min(1, 'Campo obrigatório').required('Campo Obrigatório')
      }),
    })
    return schema
  }
}

const switchPriceSchema = new SwitchPriceSchema()

export { switchPriceSchema }

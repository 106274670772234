import {
  getAllShippings,
  getOneShipping,
} from '../../services/getFunctions/shipping/getShipping'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useAuth } from '../auth/useAuth'

export function useShipping(
  shippingId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useAuth()

  const shipping = useQuery('shipping', getAllShippings, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const shipp = useQuery(
    ['shipp', shippingId],
    () => getOneShipping(shippingId !== null ? shippingId : ''),
    {
      enabled: !!shippingId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    shipping,
    shipp,
  }
}

import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Standardization } from "./standardization";

export type StandardizationAttachmentTypes = 'cnh' | 'crlv' | 'antt' | 'cleaning-register' | 'uniform-and-badge' | 'vehicle-front' | 'vehicle-left' | 'vehicle-right' | 'vehicle-rear' | 'vehicle-intern' | 'vehicle-trunk-lid-or-car-with-emergency-form' | 'vehicle-tire'

interface GetStandardizationParams {
  standardizationId: string
}

interface GetStandardizationResponse {
  standardization: Standardization & {
    attachments: {
      id: string
      type: StandardizationAttachmentTypes
      created_at: string
      updated_at: string
      attachment_id: string
      standardization_id: string
      attachment: Attachment
    }[],
  }
}

export async function getStandardization({ standardizationId }: GetStandardizationParams) {
  try {
    const response = await api.get<GetStandardizationResponse>(`/standardizations/${standardizationId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getProducts } from "api/products/getProducts";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateProduct } from "./components/CreateProduct";
import { ProductsTableFilter } from "./components/ProductsTableFilter";
import { ProductTableRow } from "./components/ProductTableRow";

export function Products() {
  const searchParams = useSearchParams()
  const { replace, push: redirect } = useHistory()
  const { userLogged } = useAuth()
  const queryClient = useQueryClient()

  const page = searchParams.get('page') ?? '1'
  const q = searchParams.get('q')
  const situation = searchParams.get('situation')

  const userCanViewProducts = userLogged?.permissions?.includes(
    'view-product'
  )

  const userCanCreateProduct = userLogged?.permissions?.includes(
    'create-product'
  )

  useEffect(() => {
    if (!userCanViewProducts) redirect('/')
  }, [redirect, userCanViewProducts])

  const {
    data: productsResult
  } = useQuery({
    queryKey: [
      'products',
      page,
      q,
      situation
    ],
    queryFn: () => getProducts({
      current_page: Number(page),
      page_size: 10,
      q,
      situation: ['ativo', 'inativo'].includes(situation) ? situation.toUpperCase() : null
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: productsResult?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 10
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateProductOpen,
    onToggle: onToggleCreateProduc,
  } = useDisclosure()

  function handleUpdateTable() {
    queryClient.invalidateQueries({ queryKey: 'products' })
  }



  return (
    <Box
      rounded="md"
      p="6"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Produtos</Heading>
        {userCanCreateProduct && (
          <IconButton
            aria-label="Criar produto"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            onClick={onToggleCreateProduc}
          />
        )}
        <Modal
          isOpen={isCreateProductOpen}
          onClose={onToggleCreateProduc}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateProduct onClose={onToggleCreateProduc} />
        </Modal>
      </Flex>


      <ProductsTableFilter
        handleUpdateTable={handleUpdateTable}
      />


      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Almoxarifado</Th>
              <Th color="white">Fornecedor</Th>
              <Th color="white">Medida</Th>
              <Th color="white">Custo Unitário</Th>
              <Th color="white">Estoque Mínimo</Th>
              <Th color="white">Estoque Padrão</Th>
              <Th color="white">Estoque Atual</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {productsResult?.products?.map(product => {
              return (
                <ProductTableRow
                  key={product?.id}
                  product={product}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

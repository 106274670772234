import axios from "axios";
import { User } from "hooks/user/dtos/User";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Regional } from "./_types/Regional";

export interface GetRegionalsParams {
  pageSize: number
  currentPage: number
}

export interface GetRegionalsResponse {
  regionals: Array<Regional & {
    responsible: User | null
  }>
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}


export async function getRegionals({
  currentPage,
  pageSize,
}: GetRegionalsParams) {
  try {
    const response = await api.get<GetRegionalsResponse>('/regionals', {
      params: {
        pageSize,
        currentPage,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

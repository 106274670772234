import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Icon,
  Link,
  Spinner,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { handleChangeUrl } from 'utils/handleChangeUrl'
import { formatDateToFrontInput } from '../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontInput'
import { RncProps } from '../../../utils/RequestFunctions/Rnc/requestRncFunctions'
import { Input } from '../../Inputs/Input'
import { TextArea } from '../../Inputs/TextInput'

interface AlignmentAttachment {
  key: string
  filename: string
}

interface IRncAnswerAccordionProps {
  rnc: RncProps
  isModalOpen?: boolean
  isRncLoading?: boolean
}

export function RncAlignAccordion({
  rnc,
  isModalOpen,
  isRncLoading,
}: IRncAnswerAccordionProps) {
  if (isRncLoading) {
    return <Spinner />
  }

  return (
    <Accordion allowMultiple mt="4">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Informações de Abertura do RNC
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack>
            <Input
              label="Protocolo"
              name="protocol"
              defaultValue={rnc?.protocol}
              isDisabled
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <Input
              label="Diretoria"
              name="direction"
              defaultValue={rnc?.direction}
              isDisabled={true}
            />

            <Input
              label="Data"
              name="date"
              type="date"
              defaultValue={rnc ? formatDateToFrontInput(rnc?.date) : ''}
              isDisabled={true}
            />
          </Stack>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              label="Prejuízo"
              name="loss_value"
              defaultValue={rnc ? rnc.loss_value?.toFixed(2) : ''}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <Input
              label="Aberto Por"
              name="open_by"
              defaultValue={`${rnc?.UserOpenedIDRNC.firstname} ${rnc?.UserOpenedIDRNC.lastname}`}
              isDisabled={true}
            />
            <Input
              label="Responsável pela Intercorrência"
              name="intercurrence_responsible"
              defaultValue={`${rnc?.UserResponsibleIDRNC.firstname} ${rnc?.UserResponsibleIDRNC.lastname}`}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <TextArea
              label="Descrição da Intercorrência"
              name="intercurrence_description"
              defaultValue={rnc?.intercurrence_description}
              isDisabled={true}
            />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Informações de Resposta do RNC
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <TextArea
              label="Causa Raiz"
              name="cause"
              defaultValue={rnc?.cause ?? '-'}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <TextArea
              label="Preventiva"
              name="preventive"
              defaultValue={rnc?.preventive ?? '-'}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <TextArea
              label="Corretiva"
              name="corrective"
              defaultValue={rnc?.corrective ?? '-'}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <TextArea
              label="Por que não procede?"
              name="why_not_proceed"
              defaultValue={rnc?.why_not_proceed ?? '-'}
              isDisabled={true}
            />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Informações de Validação do RNC
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <Input
              label="Classificação de Risco"
              name="risk_rating"
              defaultValue={rnc?.risk_rating ?? '-'}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <Input
              label="Causa raiz"
              name="rnc_type"
              defaultValue={rnc?.rnc_type ?? '-'}
              isDisabled={true}
            />
          </Stack>

          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <TextArea
              label="Observações de Alinhamento Iniciais"
              name="alignment_observation"
              defaultValue={rnc?.alignment_observation ?? '-'}
              isDisabled={true}
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={isModalOpen ? 'column' : ['column', 'column', 'row']}
          >
            <Input
              label="Data de Alinhamento"
              name="alignment_date"
              type={rnc?.alignment_date ? 'date' : ''}
              defaultValue={
                rnc?.alignment_date
                  ? formatDateToFrontInput(rnc?.alignment_date)
                  : '-'
              }
              isDisabled={true}
            />
            <Input
              label="Reunião de Tratativa?"
              name="business_meeting"
              defaultValue={rnc?.business_meeting ?? '-'}
              isDisabled={true}
            />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
      {rnc?.alignments && rnc.alignments.length > 0 && (
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Informações de Alinhamento do RNC
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {rnc?.alignments?.map((alignment) => {
              const alignmentAttachments: AlignmentAttachment[] = alignment.attachments
                ? typeof alignment.attachments === 'string'
                  ? JSON.parse(alignment.attachments)
                  : alignment.attachments
                : null

              return (
                <Stack
                  direction={
                    isModalOpen
                      ? "column"
                      : ["column", "column", "row"]
                  }
                  spacing="4"
                  key={alignment.id}
                >
                  <Input
                    name="alignedBy"
                    label="Responsável pelo alinhamento"
                    defaultValue={`${alignment.alignedBy.firstname} ${alignment.alignedBy.lastname}`}
                    isDisabled
                  />

                  <Input
                    name="date"
                    label="Data do alinhamento"
                    type="date"
                    defaultValue={alignment.date}
                    isDisabled
                  />

                  <TextArea
                    name="description"
                    label="Descrição"
                    defaultValue={alignment.description}
                    isDisabled
                  />

                  {alignment.attachments && (
                    <TableContainer>
                      <Table size="sm" variant="simple">
                        <TableCaption>Arquivos anexados</TableCaption>
                        <Thead>
                          <Tr>
                            <Th>Nome</Th>
                            <Th></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {alignmentAttachments.map((attachment) => {
                            return (
                              <Tr key={attachment.key}>
                                <Td>{attachment.filename}</Td>
                                <Td>
                                  <Button
                                    as={Link}
                                    href={handleChangeUrl(attachment.key)}
                                    variant="outline"
                                  >
                                    <Icon as={FaExternalLinkAlt} />
                                  </Button>
                                </Td>
                              </Tr>
                            )
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  )}
                </Stack>
              )
            })}
          </AccordionPanel>
        </AccordionItem>
      )}
    </Accordion>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Heading, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link } from "@chakra-ui/react";
import { getDocumentsReport } from "api/documents/getDocumentsReport";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { documentStatusMap } from "utils/documentMappers";
import { DocumentsReportFilters } from "./components/DocumentsReportFilters";
import { DocumentStatus } from "./components/DocumentStatus";

const headers = [
  { label: 'Nome do documento', key: 'name' },
  { label: 'Tipo de documento', key: 'document_type' },
  { label: 'Classificação', key: 'classification' },
  { label: 'Versão', key: 'version' },
  { label: 'Status', key: 'status' },
  { label: 'Documento', key: 'document_id' },
]

export function DocumentsReport() {

  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const status = searchParams.get('status') ?? 'all'

  const userCanViewDocumentsReport = userLogged?.permissions.includes('view-documents-report')

  useEffect(() => {
    if (!userCanViewDocumentsReport) history.push('/')
  }, [history, userCanViewDocumentsReport])

  const {
    data: documentsReportData
  } = useQuery({
    queryKey: ['documents-report', status],
    queryFn: () => getDocumentsReport({
      queryParams: {
        status,
      }
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const csvPropsData = documentsReportData
    ? documentsReportData?.documents.map(document => {
      return {
        ...document,
        status: documentStatusMap[document.status],
        document_id: document.documentAttachments[0]?.attachment?.link,
        classification: document?.classification ?? '-'
      }
    })
    : []

  const csvProps = {
    data: csvPropsData,
    headers,
    filename: `relatório-documentos-${new Date().toISOString()}.csv`,
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (
    <Box
      p="6"
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight">Relatório de Documentos</Heading>
      <DocumentsReportFilters csvProps={csvProps} />
      {documentsReportData && (
        <TableContainer mt="6">
          <Table
            size="sm"
          >
            <Thead>
              <Tr>
                <Th>Nome do documento</Th>
                <Th>Tipo de documento</Th>
                <Th>Classificação</Th>
                <Th>Versão</Th>
                <Th>Status</Th>
                <Th>Documento</Th>
              </Tr>
            </Thead>
            <Tbody>
              {documentsReportData?.documents.slice(offset, offset + 10).map((document) => {
                return (
                  <Tr key={document.id}>
                    <Td>{document?.name}</Td>
                    <Td>{document?.document_type}</Td>
                    <Td>{document?.classification}</Td>
                    <Td>{document?.documentAttachments[0].version}</Td>
                    <Td>
                      <DocumentStatus status={document?.status}/>
                    </Td>

                    <Td>
                      {document?.documentAttachments.length > 0 ? (
                          <IconButton
                            aria-label="Abrir anexo"
                            as={Link}
                            href={document?.documentAttachments[0]?.attachment?.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        ) : '-'
                      }
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}

import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";

interface SupportSubjectDetailProps {
  supportSubjectId: string
}

export function SupportSubjectDetail({
  supportSubjectId
}: SupportSubjectDetailProps) {

  const {
    data: supportSubjectResult,
    isLoading: isLoadingSupportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId]
  })

  return (
    <ModalContent>
      <ModalHeader>
        Detalhes do assunto de atendimento
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>

        <Table
          size="sm"
        >
          <Tbody>
            <Tr>
              <Td>Tipo de suporte</Td>
              <Td isNumeric>{supportSubjectResult?.supportSubject?.supportType}</Td>
            </Tr>
            <Tr>
              <Td>Categoria</Td>
              <Td isNumeric>{supportSubjectResult?.supportSubject?.category || 'Todas'}</Td>
            </Tr>
            <Tr>
              <Td>Assunto</Td>
              <Td isNumeric>{supportSubjectResult?.supportSubject?.name}</Td>
            </Tr>
            <Tr>
              <Td>Responsáveis</Td>
              <Td isNumeric>{supportSubjectResult?.supportSubject?.responsibles.map(responsible => {
                return captalize(`${responsible.user.firstname} ${responsible.user.lastname}`)
              }).join(', ')}</Td>
            </Tr>
            <Tr>
              <Td>Prazo em horas</Td>
              <Td isNumeric>{supportSubjectResult?.supportSubject?.deadlineInMinutes / 60} Horas</Td>
            </Tr>
          </Tbody>

        </Table>
      </ModalBody>

    </ModalContent>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export interface CreateBusinessCostProps {
  body: {
    startDate: Date
    endDate?: Date | null
    observations?: string | null
    collectorsIds: string[]
    cost: number
  }
  params: {
    businessBudgetId: string
  }
}

export async function createBusinessCost({
  body,
  params
}: CreateBusinessCostProps) {
  try {
    await api.post(`/business-budgets/${params.businessBudgetId}/business-costs/`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import axios from "axios";
import { useMutation } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from 'hooks/toastify/useToastify';
import { useAuth } from 'hooks/auth/useAuth';
import { HttpErrorStatusCodeEnum } from 'utils/errors/HttpStatusCodeEnum';

interface Attachments {
  key: string
  filename: string
}

export interface ClientManagementResponse {
  data: {
    responsible_name: string
    branch: string
    street: string
    neighborhood: string
    number: number
    city: string
    state: string
    cep: string
    type: 'REMETENTE' | 'DESTINATÁRIO'
    content_declarations?: string | Attachments[] | null
  }[]
}

interface GenerateClientManagementReportProps {
  initialDate?: string
  finalDate?: string
  customerId: string
}

async function generateClientManagementReportReqFunction(
  input: GenerateClientManagementReportProps
): Promise<ClientManagementResponse> {
  try {
    const { data } = await api.post(`/report/customer-managment`, input)
    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useClientManagementReport() {

  const { errorMessage } = useToastify()

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()
  }
  const generateClientManagementReport = useMutation('clientManagementReport', generateClientManagementReportReqFunction, {
    onError: onRequestError
  })

  return {
    generateClientManagementReport
  }
}

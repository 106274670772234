import { Box, Heading } from "@chakra-ui/react";
import { CltIntercurrence } from "api/cltIntercurrences/types/CltIntercurrence";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ApproveHomeOfficeCltIntercurrence } from "./ApprovaHomeOfficeCltIntercurrence";
import { ApproveExtraHourCltIntercurrence } from "./ApproveExtraHourCltIntercurrence";
import { ApproveLateOrEarlyDepartureCltIntercurrence } from "./ApproveLateOrEarlyDepartureCltIntercurrence";
import { ApproveMissWorkCltIntercurrence } from "./ApproveMissWorkCltIntercurrence";
import { ApproveOtherCltIntercurrence } from "./ApproveOtherCltIntercurrence";
import { ApprovePatrimonyWithoutReturnCltIntercurrence } from "./ApprovePatrimonyWithoutReturnCltIntercurrence";
import { ApproveTicketLogAdditionalValueCltIntercurrence } from "./ApproveTicketLogAdditionalValueCltIntercurrence";
import { ApproveTurnChangeCltIntercurrence } from "./ApproveTurnChangeCltIntercurrence";
import { ApproveVacationCltIntercurrence } from "./ApproveVacationCltIntercurrence";
import { ApproveWorkAccidentCltIntercurrence } from "./ApproveWorkAccidentCltIntercurrence";

interface ApproveCltIntercurrenceProps {
  cltIntercurrence: CltIntercurrence
}

export function ApproveCltIntercurrence({
  cltIntercurrence
}: ApproveCltIntercurrenceProps) {

  const history = useHistory()

  const { userLogged } = useAuth()

  const userCanApproveCltIntercurrence = userLogged?.permissions?.includes('approve-clt-intercurrence')

  useEffect(() => {
    if (!userCanApproveCltIntercurrence) history.push('/')
  }, [history, userCanApproveCltIntercurrence])

  return (
    <Box
      w="full"
      maxH="500px"
      overflowY="auto"
    >
      <Heading letterSpacing="tight" size='sm'>Aprovar Intercorrência CLT</Heading>

      {cltIntercurrence.type === 'work-accident' && (
        <ApproveWorkAccidentCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'miss-work' && (
        <ApproveMissWorkCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'vacation' && (
        <ApproveVacationCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'home-office' && (
        <ApproveHomeOfficeCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'patrimony-without-return' && (
        <ApprovePatrimonyWithoutReturnCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'turn-change' && (
        <ApproveTurnChangeCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'ticket-log-additional-value' && (
        <ApproveTicketLogAdditionalValueCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'late-or-early-departure' && (
        <ApproveLateOrEarlyDepartureCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'extra-hours' && (
        <ApproveExtraHourCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}

      {cltIntercurrence.type === 'other' && (
        <ApproveOtherCltIntercurrence
          cltIntercurrenceId={cltIntercurrence.id}
        />
      )}
    </Box>
  )
}

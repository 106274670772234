import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverScalabriniDetailsBody {
  doesRegisteredInScalabriniSystem: boolean
}

interface SendCltDriverScalabriniDetailsProps {
  body: SendCltDriverScalabriniDetailsBody
  cltDriverId: string
}

export async function sendCltDriverScalabriniDetails({
  body,
  cltDriverId
}: SendCltDriverScalabriniDetailsProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/hire/scalabrini-register-info`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

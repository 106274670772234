import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { AsoStatus } from "api/asos/aso";
import { useHub } from "hooks/hub/useHub";
import { useUsers } from "hooks/user/useUsers";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";

interface AsosReportFiltersSchema {
  collaborator: string
  status: AsoStatus | 'all'
}

interface AsosReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: unknown[]
    filename: string
  }
}

export function AsosReportFilters({
  csvProps
}: AsosReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const {
    register,
    handleSubmit,
  } = useForm<AsosReportFiltersSchema>({
    defaultValues: {
      status: 'all'
    }
  })

  function handleSearchAsosReport(values: AsosReportFiltersSchema) {
    if (values.collaborator) {
      searchParams.set('collaborator', values.collaborator)
    } else {
      searchParams.delete('collaborator')
    }

    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    replace({ search: searchParams.toString() })

  }

  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchAsosReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Input
          {...register("collaborator")}
          name="collaborator"
          placeholder="Ex: Jhon Doe"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: '250px' }}
        />

        <Select
          {...register("status")}
          name="status"
          defaultValue="all"
          size="sm"
          rounded="md"
          w={["full", "full", "200px"]}
        >
          <option value="all">Todos status</option>
          <option value="pending">Pendente</option>
          <option value="active">Ativo</option>
          <option value="inactive">Inativo</option>
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
      </Flex>
    </Flex>
  )
}

import { Button, Flex, FormControl, FormLabel, IconButton, Input, InputGroup, InputLeftAddon, Link, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Popover, PopoverTrigger, Radio, RadioGroup, Spinner, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { billBilling } from "api/billings/billBilling";
import { getBilling } from "api/billings/getBilling";
import { GetBillingsResponse } from "api/billings/getBillings";
import { set } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { useCurrency } from "react-hook-currency";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaLink, FaFileImport, FaInfo } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { validateHasFile } from "utils/fileValidation";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import * as yup from "yup"
import { BillingServiceBudgetDetail } from "./BillingServiceBudgetDetail";
import { BillingStatus } from "./BillingStatus";

interface BillBillingProps {
  billingId: string
  onCloseModal: () => void
}

interface BillBillingSchema {
  startDate: string
  endDate: string
  dueDate: string
  value: string
  autoSendEmail: string
  paymentSlips: FileList
  invoices: FileList
  otherAttachments: FileList
}

function transformInputDateToTimestamp(inputDate: string) {
  if (inputDate) {
    const [year, month, day] = inputDate.split('-').map(Number)

    const timestamp = set(new Date(), {
      date: day,
      month: month - 1,
      year,
      hours: 12,
      minutes: 0,
      seconds: 0
    }).toISOString()

    return timestamp
  }

  return ''
}

const billBillingSchema = yup.object({
  startDate: yup.string().required().transform(transformInputDateToTimestamp),
  endDate: yup.string().required().transform(transformInputDateToTimestamp),
  dueDate: yup.string().required().transform(transformInputDateToTimestamp),
  value: yup.mixed().required().transform((value: string) => {
    return value ? Math.ceil(transformStringToNumber(value) * 100) : ''
  }),
  autoSendEmail: yup.boolean().required().transform(value => value === 'yes'),
  invoices: yup.mixed().when('$isServiceBilling', {
    is: false,
    then: yup.mixed().test(value => validateHasFile(value))
  }),
  otherAttachments: yup.mixed().when('$isServiceBilling', {
    is: false,
    then: yup.mixed().test(value => validateHasFile(value))
  })
})

export function BillBilling({ billingId, onCloseModal }: BillBillingProps) {

  const { data: billingData, isLoading: isBillingDataLoading } = useQuery({
    queryKey: ['billing', billingId],
    queryFn: () => getBilling({ billingId })
  })


  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<BillBillingSchema>({
    resolver: yupResolver(billBillingSchema),
    context: {
      isServiceBilling: !!billingData?.billing?.service,
    }
  })

  const [paymentSlips, invoices, attachments] = useWatch({
    control,
    name: ['paymentSlips', 'invoices', 'otherAttachments']
  })

  const {
    format: currencyFormat, onChange: onCurrencyChange,
  } = useCurrency({
    style: 'decimal'
  })

  const queryClient = useQueryClient()

  const { mutateAsync: billBillingFn } = useMutation({
    mutationFn: billBilling,
    onSuccess(_data, { body, billingId }) {
      const cachedBillings = queryClient.getQueriesData<GetBillingsResponse>({
        queryKey: ['billings']
      })

      cachedBillings.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          billings: cachedData.billings.map((billing) => {
            if (billing.id === billingId) {
              return {
                ...billing,
                auto_send_email: body.get('autoSendEmail') === 'true',
                due_date: body.get('dueDate'),
                start_date: body.get('startDate'),
                end_date: body.get('endDate'),
                status: 'billed'
              }
            }

            return billing
          })
        })
      })

      onCloseModal()
    }
  })

  const { promiseMessage } = useToastify()

  async function handleBillBilling(data: BillBillingSchema) {
    const formData = new FormData()

    formData.append('startDate', data.startDate)
    formData.append('endDate', data.endDate)
    formData.append('dueDate', data.dueDate)
    formData.append('value', data.value)
    formData.append('autoSendEmail', data.autoSendEmail)

    if (data.paymentSlips) {
      Object.entries(data.paymentSlips).forEach(([key, file]) => {
        formData.append('paymentSlips', file)
      })
    }

    if (data.invoices) {
      Object.entries(data.invoices).forEach(([key, file]) => {
        formData.append('invoices', file)
      })
    }

    if (data.otherAttachments) {
      Object.entries(data.otherAttachments).forEach(([key, file]) => {
        formData.append('attachments', file)
      })
    }

    await promiseMessage(billBillingFn({
      billingId,
      body: formData
    }), 'Faturamento realizado!')
  }

  return (
    <ModalContent>
      {isBillingDataLoading ? (
        <ModalBody p="14">
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">Faturar cliente</ModalHeader>
          <ModalCloseButton />

          <ModalBody
            as="form"
            onSubmit={handleSubmit(handleBillBilling)}
            maxH="600px"
            overflowY="scroll"
          >

            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Cliente</Td>
                  <Td isNumeric>{billingData.billing.customer.trading_firstname}</Td>
                </Tr>
                {billingData.billing.service && (
                  <>
                    <Tr>
                      <Td>Protocolo</Td>
                      <Td isNumeric>{billingData.billing.service.protocol}</Td>
                    </Tr>
                    <Tr>
                      <Td>Status</Td>
                      <Td display="flex" justifyContent="flex-end">
                        <BillingStatus status={billingData.billing.status} />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Orçamento</Td>
                      <Td isNumeric>
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              aria-label="Visualizar informações do orçamento"
                              icon={<FaInfo />}
                              size="xs"
                              colorScheme="linkedin"
                            />
                          </PopoverTrigger>
                          <BillingServiceBudgetDetail budget={billingData.billing.service.serviceIDRequested.budgetIDService} />
                        </Popover>
                      </Td>
                    </Tr>
                  </>
                )}

              </Tbody>
            </Table>

            <Stack
              direction={["column", "column", "row"]}
              spacing="6"
              w="full"
              mt="6"
            >
              <Flex
                direction="column"
                gap="1"
                w="full"
              >
                <Text fontSize="sm">
                  Período inicial
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </Text>
                <FormControl isInvalid={!!errors.startDate}>
                  <Input
                    {...register("startDate")}
                    name="startDate"
                    type="date"
                    size="sm"
                    rounded="md"
                    w="full"
                  />
                </FormControl>
              </Flex>
              <Flex
                direction="column"
                gap="1"
                w="full"
              >
                <Text fontSize="sm">
                  Período final
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </Text>
                <FormControl isInvalid={!!errors.endDate}>
                  <Input
                    {...register("endDate")}
                    name="endDate"
                    type="date"
                    size="sm"
                    rounded="md"
                    w="full"
                  />
                </FormControl>
              </Flex>
            </Stack>

            <Stack
              direction={["column", "column", "row"]}
              spacing="6"
              mt="6"
            >
              <Flex
                direction="column"
                gap="1"
                w="full"
              >
                <Text fontSize="sm">
                  Valor da fatura
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </Text>
                <FormControl isInvalid={!!errors.value}>
                  <Controller
                    name="value"
                    control={control}
                    render={({ field }) => {
                      return (
                        <InputGroup
                          size="sm"
                        >
                          <InputLeftAddon
                            borderTopLeftRadius="md"
                            borderBottomLeftRadius="md"
                          >
                            R$
                          </InputLeftAddon>
                          <Input
                            {...register("value")}
                            size="sm"
                            rounded="md"
                            w="full"
                            name={field.name}
                            value={field.value}
                            defaultValue={currencyFormat('000')}
                            onChange={e => field.onChange(onCurrencyChange(e))}
                          />
                        </InputGroup>
                      )
                    }}
                  />
                </FormControl>
              </Flex>

              <Flex
                direction="column"
                gap="1"
                w="full"
              >
                <Text fontSize="sm">
                  Data de vencimento
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </Text>
                <FormControl isInvalid={!!errors.dueDate}>
                  <Input
                    {...register("dueDate")}
                    name="dueDate"
                    type="date"
                    size="sm"
                    rounded="md"
                    w="full"
                  />
                </FormControl>
              </Flex>
            </Stack>


            <Flex
              direction="column"
              gap="1"
              w="full"
              mt="6"
            >
              <Text fontSize="sm">
                Enviar e-mail automaticamente
                <Text ml="1" textColor="red.500" as="sup">*</Text>
              </Text>
              <FormControl isInvalid={!!errors.autoSendEmail}>
                <Controller
                  name="autoSendEmail"
                  control={control}
                  render={({ field }) => {
                    return (
                      <RadioGroup
                        size="sm"
                        onChange={field.onChange}
                        value={field.value}
                        name={field.name}
                      >
                        <Stack direction="column">
                          <Radio autoFocus={!!errors.autoSendEmail} value="yes">Sim</Radio>
                          <Radio autoFocus={!!errors.autoSendEmail} value="no">Não</Radio>
                        </Stack>
                      </RadioGroup>
                    )
                  }}
                />
              </FormControl>
            </Flex>

            <Flex
              direction="column"
              gap="1"
              w="full"
              mt="6"
            >
              <Text fontSize="sm">
                Observações
              </Text>
              <Textarea
                size="sm"
                rounded="md"
                defaultValue={billingData.billing.customer.billing_observation}
                w="full"
                isReadOnly

              />
            </Flex>

            <Table size="sm" mt="6">
              <Tbody>
                <Tr>
                  <Td>
                    {(!paymentSlips || !paymentSlips.length) ? (
                      'Boleto (opcional)'
                    ) : (
                      Object.entries(paymentSlips).map(([key, file]) => {
                        return (
                          <Link
                            key={key}
                            href={URL.createObjectURL(file)}
                            display="flex"
                            gap="1"
                            isExternal
                          >
                            {file.name.slice(0, 20).padEnd(23, '...')}
                            <FaLink />
                          </Link>
                        )
                      })
                    )}
                  </Td>
                  <Td isNumeric>
                    <IconButton
                      size="sm"
                      as={FormLabel}
                      aria-label="Anexar boleto"
                      icon={<FaFileImport />}
                      htmlFor="paymentSlips"
                      cursor="pointer"
                    />
                    <Input
                      {...register("paymentSlips")}
                      id="paymentSlips"
                      name="paymentSlips"
                      type="file"
                      multiple
                      hidden
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {(!invoices || !invoices.length) ? (
                      'Fatura'
                    ) : (
                      Object.entries(invoices).map(([key, file]) => {
                        return (
                          <Link
                            key={key}
                            href={URL.createObjectURL(file)}
                            display="flex"
                            gap="1"
                            isExternal
                          >
                            {file.name.slice(0, 20).padEnd(23, '...')}
                            <FaLink />
                          </Link>
                        )
                      })

                    )}

                  </Td>
                  <Td isNumeric>
                    <IconButton
                      size="sm"
                      as={FormLabel}
                      aria-label="Anexar fatura"
                      icon={<FaFileImport />}
                      htmlFor="invoices"
                      cursor="pointer"
                      border={errors.invoices && '2px solid'}
                      borderColor={errors.invoices && 'red.500'}
                    />
                    <Input
                      {...register("invoices")}
                      id="invoices"
                      name="invoices"
                      type="file"
                      multiple
                      hidden
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    {(!attachments || !attachments.length) ? (
                      'Anexo'
                    ) : (
                      Object.entries(attachments).map(([key, file]) => {
                        return (
                          <Link
                            key={key}
                            href={URL.createObjectURL(file)}
                            display="flex"
                            gap="1"
                            isExternal
                          >
                            {file.name.slice(0, 20).padEnd(23, '...')}
                            <FaLink />
                          </Link>
                        )
                      })

                    )}

                  </Td>
                  <Td isNumeric>
                    <IconButton
                      size="sm"
                      as={FormLabel}
                      aria-label="Anexar"
                      icon={<FaFileImport />}
                      htmlFor="otherAttachments"
                      cursor="pointer"
                      border={errors.otherAttachments && '2px solid'}
                      borderColor={errors.otherAttachments && 'red.500'}
                    />
                    <Input
                      {...register("otherAttachments")}
                      id="otherAttachments"
                      name="otherAttachments"
                      type="file"
                      multiple
                      hidden
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <Flex
              w="full"
              justify="flex-end"
              mt="6"
            >

              <Button
                size="sm"
                colorScheme="blue"
                type="submit"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Faturar
              </Button>
            </Flex>

          </ModalBody>
        </>
      )
      }


      <ModalFooter></ModalFooter>
    </ModalContent >
  )
}

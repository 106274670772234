import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetInCollectServicesResponse {
  services: {
    id: string
    protocol: number,
    customer: string
    vehicle: string
    source_addresses: {
      id: string
      address: string
    }[],
    going_collect_addresses: {
      id: string
      address: string
    }[] | null,
    collected_addresses: string[] | null,
    destination_addresses: string[],
    collect_date: string
    collect_hour_start: string
    collect_hour_end: string
    board_hour: string
    source_collector_id: string
    source_collector: string
    destination_collector: string
    shipping: string
    source_branch: string
    destination_branch: string
    delivery_date: string
    delivery_hour: string
    caixa_termica: number
    embalagem_secundaria: number
    gelo_seco: number
    gelox: number
    isopor3l: number
    isopor7l: number
    terciaria3l: number
    terciaria8l: number
    service_type: string
    observations: string
    source_tz: string
    destination_tz: string
    has_occurrence: boolean
    has_unsuccess_collects_validation: boolean
    has_new_addresses_to_validate: boolean
  }[]
  totalPages: number
}

interface GetInCollectServicesQueryParams {
  pageSize: string
  currentPage: string
  protocol: string
  customer: string
  vehicle: string
  sourceAddress: string
  collectDate: string
  collectHour: string
  destinationAddress: string
  sourceCollector: string
  destinationCollector: string
  timezoneOffset: string
  collectorId?: string
}

export async function getInCollectServices({
  currentPage,
  customer,
  pageSize,
  protocol,
  vehicle,
  sourceAddress,
  collectDate,
  collectHour,
  destinationAddress,
  sourceCollector,
  destinationCollector,
  timezoneOffset,
  collectorId
}: GetInCollectServicesQueryParams) {
  try {
    const { data } = await api.get<GetInCollectServicesResponse>('/services/collects', {
      params: {
        currentPage,
        customer,
        pageSize,
        protocol,
        vehicle,
        sourceAddress,
        collectDate,
        collectHour,
        destinationAddress,
        sourceCollector,
        destinationCollector,
        timezoneOffset,
        collectorId
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Flex, Tooltip, Icon, Box } from "@chakra-ui/react";
import { GetDelayedDeliveriesResponse } from "api/service/getDelayedDeliveries";
import { formatDistanceToNowStrict, set } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { ptBR } from "date-fns/locale";
import { FaInfoCircle } from "react-icons/fa";
import { captalize } from "utils/captalize";
import { timezonesUsed } from "utils/DateFunctions/timezones";
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict";

interface DelayedDeliveriesTableProps {
  delayedDeliveriesResult: GetDelayedDeliveriesResponse
}

export function DelayedDeliveriesTable({ delayedDeliveriesResult }: DelayedDeliveriesTableProps) {
  return (
    <TableContainer
      rounded="md"
      mt="6"
    >
      <Table size="sm">
        <Thead>
          <Tr
            h="40px"
            bg="#38c3fa"
          >
            <Th color="white">Protocolo</Th>
            <Th color="white">Cliente</Th>
            <Th color="white">Origem</Th>
            <Th color="white">Destino</Th>
            <Th color="white">Status</Th>
            <Th color="white" isNumeric>Atraso</Th>
          </Tr>
        </Thead>
        <Tbody>
          {delayedDeliveriesResult?.services?.map(service => {
            const [
              day,
              month,
              year
            ] = formatInTimeZone(service.delivery_date, timezonesUsed[service.destination_tz].timezone, 'dd/MM/yyyy').split('/').map(Number)

            const [
              hours,
              minutes
            ] = formatInTimeZone(service.delivery_hour, timezonesUsed[service.destination_tz].timezone, 'HH:mm').split(':').map(Number)


            const deliveryDateTime = set(new Date(), {
              date: day,
              month: month - 1,
              year,
              hours,
              minutes
            })

            const now = new Date();
            let distanceDeliveryDateTime = null
            let timeUntilDelivery = null

            if (now > deliveryDateTime) {
              distanceDeliveryDateTime = formatDistanceToNowStrict(deliveryDateTime, { locale: ptBR });
            } else {
              timeUntilDelivery = formatDistanceToNowStrict(deliveryDateTime, { locale: ptBR });
            }



            return (
              <Tr key={service.id}>
                <Td>{service?.protocol}</Td>
                <Td>{captalize(service?.customer)}</Td>
                <Td>
                  <Flex>
                    {captalize(service.source_hub)}
                    {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                      <Tooltip label={service.source_tz}>
                        <Box as="span" ml="2">
                          <Icon as={FaInfoCircle} />
                        </Box>
                      </Tooltip>
                    )}
                  </Flex>
                </Td>
                <Td>
                  <Flex>
                    {captalize(service.destination_hub)}
                    {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                      <Tooltip label={service.destination_tz}>
                        <Box as="span" ml="2">
                          <Icon as={FaInfoCircle} />
                        </Box>
                      </Tooltip>
                    )}
                  </Flex>
                </Td>
                <Td>{captalize(serviceHandleStep(service.step))}</Td>
                {timeUntilDelivery && (
                  <Td >Entrega em:{captalize(timeUntilDelivery)}</Td>
                )}
                {distanceDeliveryDateTime && (
                  <Td>{captalize(distanceDeliveryDateTime)}</Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

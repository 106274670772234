import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { PassiveFeedback, PassiveFeedbackStatus } from "./_types/PassiveFeedback";

export interface GetPassivesFeedbacksParams {
  pageSize: number
  currentPage: number
  status?: PassiveFeedbackStatus | 'all'

}

export interface GetPassivesFeedbacksResponse {
  passivesFeedbacks: PassiveFeedback[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}


export async function getPassivesFeedbacks({
  currentPage,
  pageSize,
  status,

}: GetPassivesFeedbacksParams) {
  try {
    const response = await api.get<GetPassivesFeedbacksResponse>('/feedbacks/passives', {
      params: {
        pageSize,
        currentPage,
        status,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

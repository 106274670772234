import { differenceInMinutes } from 'date-fns'
import { HolidayProps } from '../../../services/getFunctions/holiday/getHoliday'
import { AddDate } from '../../DateFunctions/addDate'
import { SetDate } from '../../DateFunctions/setDate'

export class ValidateHolidayByDeliveryDate {
  constructor(private setDate: SetDate, private addDate: AddDate) {}

  async handle(
    holidays: HolidayProps[],
    deliveryDate: string | Date,
    budgetDestinationCities: string[],
  ) {
    const deliveryDateAdded = await this.addDate.handleFormatAndAddDays(
      deliveryDate,
      1,
      'FullDate',
    )
    const holidayWithSameDateOfDeliveryDate: HolidayProps[] = []

    await Promise.all(
      holidays.filter(async (holiday) => {
        const diff = differenceInMinutes(
          await this.setDate.handleWithDefaultHourWithoutFormat(holiday.date),
          await this.setDate.handleWithDefaultHourWithoutFormat(
            deliveryDateAdded,
          ),
        )
        if (diff === 0) {
          holidayWithSameDateOfDeliveryDate.push(holiday)
        }
      }),
    )

    let holidayDeliveryDateConfirmMessage = ''
    if (holidayWithSameDateOfDeliveryDate.length > 0) {
      if (holidayWithSameDateOfDeliveryDate[0].national === true) {
        holidayDeliveryDateConfirmMessage = String(
          `A data da entrega é feriado nacional de ${holidayWithSameDateOfDeliveryDate[0].name}. Deseja continuar?`,
        )
      } else {
        const holidayByCity = holidayWithSameDateOfDeliveryDate.filter(
          (holiday) => budgetDestinationCities.includes(holiday.city_id),
        )
        if (holidayByCity.length > 0) {
          holidayDeliveryDateConfirmMessage = String(
            `A data selecionada para entrega é feriado de ${holidayByCity[0].name} na cidade ${holidayByCity[0].cityIDHoliday.name}, deseja continuar?`,
          )
        }
      }
    }

    return holidayDeliveryDateConfirmMessage
  }
}

const addDate = new AddDate()
const setDate = new SetDate()
const validateHolidayByDeliveryDate = new ValidateHolidayByDeliveryDate(
  setDate,
  addDate,
)

export { validateHolidayByDeliveryDate }

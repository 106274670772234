import { Button, Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { rejectCltIntercurrence } from "api/cltIntercurrences/rejectCltIntercurrence";
import { CltIntercurrence } from "api/cltIntercurrences/types/CltIntercurrence";
import { format } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { cltIntercurrenceTypeMap } from "utils/cltIntercurrenceMappers";
import { CltIntercurrenceStatus } from "./CltIntercurrenceStatus";
import { useToastify } from "../../../hooks/toastify/useToastify"
import { GetCltIntercurrencesResponse } from "api/cltIntercurrences/getCltIntercurrences";
import { FaTimes } from "react-icons/fa";
import { RiSearchLine } from "react-icons/ri";
import { CltIntercurrenceDetail } from "./CltIntercurrenceDetail";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

interface CltIntercurrenceTableRowProps {
  cltIntercurrence: CltIntercurrence
}

export function CltIntercurrenceTableRow({
  cltIntercurrence
}: CltIntercurrenceTableRowProps) {

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewCltIntercurrences = userLogged?.permissions?.includes('view-clt-intercurrences')

  useEffect(() => {
    if (!userCanViewCltIntercurrences) history.push('/')
  }, [history, userCanViewCltIntercurrences])

  const userCanRejectCltIntercurrence = userLogged?.permissions?.includes('reject-clt-intercurrence')

  const {
    isOpen: isCltIntercurrenceDetailModalOpen,
    onToggle: onToggleCltIntercurrenceDetailModal,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: rejectCltIntercurrenceFn } = useMutation({
    mutationFn: rejectCltIntercurrence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetCltIntercurrencesResponse>({
        queryKey: ['clt-intercurrences']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          cltIntercurrences: cachedValue.cltIntercurrences.map((cltIntercurrence) => {
            if (cltIntercurrence.id === routeParams.cltIntercurrenceId) {
              return { ...cltIntercurrence, status: 'rejected' }
            }

            return cltIntercurrence
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleRejectCltIntercurrence() {
    await promiseMessage(
      rejectCltIntercurrenceFn({ routeParams: { cltIntercurrenceId: cltIntercurrence.id } }),
      'Intercorrência rejeitada com sucesso!'
    )
  }

  return (
    <Tr>
      <Td>
        <Modal
          isOpen={isCltIntercurrenceDetailModalOpen}
          onClose={onToggleCltIntercurrenceDetailModal}
          size='6xl'
        >
          <ModalOverlay />

          <CltIntercurrenceDetail cltIntercurrenceId={cltIntercurrence.id} />

        </Modal>
        <IconButton
          aria-label="Detalhes da intercorrência"
          icon={<RiSearchLine />}
          onClick={onToggleCltIntercurrenceDetailModal}
          size="sm"
        />
      </Td>
      <Td>{cltIntercurrenceTypeMap[cltIntercurrence.type]}</Td>
      <Td>{format(new Date(cltIntercurrence.date), 'dd/MM/yyyy')}</Td>
      <Td>{cltIntercurrence.regional.toUpperCase()}</Td>
      <Td>{cltIntercurrence.collaborator_name}</Td>
      <Td>
        <CltIntercurrenceStatus
          status={cltIntercurrence.status}
        />
      </Td>
      <Td>
        <Flex align="center" gap="2">
          {userCanRejectCltIntercurrence && (
            <Button
              size="xs"
              lineHeight="1"
              leftIcon={<FaTimes />}
              variant="ghost"
              onClick={handleRejectCltIntercurrence}
            >
              Rejeitar
            </Button>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}

import { Button, Flex, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { useCollectorsFunctions } from "hooks/collector/useCollectorsFunctions";
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaClone, FaPen, FaSearch, FaTimes } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { CollectorExtraDiscountDetails, getStatus } from "./CollectorExtraDiscountDetail";
import { CollectorExtraDiscountStatus } from "./CollectorExtraDiscountStatus";

interface CollectorsExtraDiscountTableRowProps {
  extraDiscount: ExtraDiscount
}

export function CollectorsExtraDiscountTableRow({ extraDiscount }: CollectorsExtraDiscountTableRowProps) {

  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const userCanDeleteCollectorExtraDiscount = userLogged?.permissions?.includes(
    'delete-collector-extra-discount'
  )
  const userCanApproveCollectorExtraDiscount = userLogged?.permissions?.includes(
    'approve-collector-extra-discount'
  )
  const userCanRejectCollectorExtraDiscount = userLogged?.permissions?.includes(
    'reject-collector-extra-discount'
  )
  const userCanEffectiveCollectorExtraDiscount = userLogged?.permissions?.includes(
    'effective-collector-extra-discount'
  )

  const userCanEditCollectorExtraDiscount = userLogged?.permissions?.includes(
    'update-collector-extra-discount'
  )


  const status = getStatus({
    approved_at: extraDiscount?.approved_at,
    effective_at: extraDiscount?.effective_at,
    rejected_at: extraDiscount?.rejected_at
  })

  const {
    isOpen: isCollectorExtraDiscountDetailModalOpen,
    onToggle: onToggleCollectorExtraDiscountDetailModal,
  } = useDisclosure()

  const [serviceYear, serviceMonth, serviceDay] = extraDiscount.service_date.split('-')

  const {
    approveCollectorExtraDiscount: {
      mutateAsync: approveCollectorExtraDiscount
    },
    rejectCollectorExtraDiscount: {
      mutateAsync: rejectCollectorExtraDiscount
    },
    deleteCollectorExtraDiscount: {
      mutateAsync: deleteCollectorExtraDiscount
    },
    effectiveCollectorExtraDiscount,
  } = useCollectorsFunctions()

  async function handleApproveCollectorExtraDiscount() {
    await promiseMessage(approveCollectorExtraDiscount(
      extraDiscount?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['collectors-extras-discounts'])
      }
    }
    ), 'Extra/Desconto de agregado aprovado com sucesso!')
  }

  async function handleEffectiveCollectorExtraDiscount() {
    await promiseMessage(effectiveCollectorExtraDiscount.mutateAsync(
      extraDiscount?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('collectors-extras-discounts')
      }
    }
    ), 'Extra/Desconto de agregado efetivado com sucesso!')
  }

  async function handleRejectCollectorExtraDiscount() {
    await promiseMessage(rejectCollectorExtraDiscount(
      extraDiscount?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['collectors-extras-discounts'])
      }
    }
    ), 'Extra/Desconto de agregado recusado com sucesso!')
  }

  async function handleDeleteCollectorExtraDiscount() {
    await promiseMessage(deleteCollectorExtraDiscount(
      extraDiscount?.id, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['collectors-extras-discounts'])
      }
    }
    ), 'Extra/Desconto de coletador excluido com sucesso!')
  }

  function handleUpdateCollectorExtraDiscount() {
    window.open(`/extra-desconto/${extraDiscount?.id}/coletador`, '_blank')
  }


  return (
    <Tr>
      <Td>
        <Flex align="center" gap={3}>
          <IconButton
            aria-label="Visualizar extra e desconto"
            icon={<FaSearch />}
            size="sm"
            variant="ghost"
            onClick={onToggleCollectorExtraDiscountDetailModal}
          />
          {userCanEditCollectorExtraDiscount && (
            <IconButton
              aria-label="Editar extra/desconto"
              icon={<FaPen />}
              variant="ghost"
              size="sm"
              onClick={handleUpdateCollectorExtraDiscount} />
          )}
          {extraDiscount.is_duplicated && (
            <Icon as={FaClone} mx="2px" title="Extra/Desconto duplicado"/>
          )}
        </Flex>
        <Modal
          isOpen={isCollectorExtraDiscountDetailModalOpen}
          onClose={onToggleCollectorExtraDiscountDetailModal}
          size='6xl'
        >
          <ModalOverlay />
          <CollectorExtraDiscountDetails
            extraDiscount={extraDiscount}
          />
        </Modal>
      </Td>
      <Td>{`${extraDiscount?.collector?.trading_name}`}</Td>
      <Td>{extraDiscount.type}</Td>
      <Td>{`${serviceDay}/${serviceMonth}/${serviceYear}`}</Td>
      <Td>{
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(extraDiscount.value / 100)
      }</Td>
      <Td>{extraDiscount.service_route ?? '-'}</Td>
      <Td>{extraDiscount.services ? extraDiscount?.services?.map((service) => service?.service?.protocol).join(', ') : '-'}</Td>
      <Td>{extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-'}</Td>

      <Td>
        <CollectorExtraDiscountStatus
          status={status}
        />
      </Td>
      <Td>
        {status === 'requested' && userCanApproveCollectorExtraDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaArrowRight} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleApproveCollectorExtraDiscount}
          >
            Aprovar
          </Button>
        )}
        {status === 'requested' && userCanRejectCollectorExtraDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaArrowRight} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleRejectCollectorExtraDiscount}
          >
            Recusar
          </Button>
        )}
        {status === 'approved' && userCanEffectiveCollectorExtraDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaArrowRight} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleEffectiveCollectorExtraDiscount}
          >
            Efetivar
          </Button>
        )}
        {userCanDeleteCollectorExtraDiscount && (
          <Button
            size="xs"
            leftIcon={<Icon as={FaTimes} />}
            variant="ghost"
            lineHeight="1"
            onClick={handleDeleteCollectorExtraDiscount}
          >
            Excluir
          </Button>
        )}

      </Td>

    </Tr>
  )
}

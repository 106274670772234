import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { TrainingLlm, TrainingLlmStatus } from "./trainingLlm";

interface GetTrainingsLlmReportParams {
  status?: TrainingLlmStatus | 'all' | null
  collaboratorId?: string | null
  limitDate?: string | null
}

export interface GetTrainingsLlmResponse {
  llmTrainings: Array<Pick<TrainingLlm, 'id' | 'training' | 'status' | 'due_date' | 'approved_at' | 'reproved_at'> & {
    collaborator: {
      name: string
    }
  }>
}

export async function getTrainingsLlmReport({
  collaboratorId,
  status,
  limitDate
}: GetTrainingsLlmReportParams) {
  try {
    const response = await api.get<GetTrainingsLlmResponse>('/llm/trainings/report', {
      params: {
        collaboratorId,
        status,
        limitDate
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

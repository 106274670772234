import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getTrainingsLlm } from "api/trainingLlm/getTrainingsLlm";
import { TrainingLlmStatus } from "api/trainingLlm/trainingLlm";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateTrainingLlm } from "./components/CreateTrainingLlm";
import { trainingLlmMStatusMap } from "./components/TrainingLlmStatus";
import { TrainingsLlmTableFilter } from "./components/TrainingsLlmTableFilter";
import { TrainingsLlmTableRow } from "./components/TrainingsLlmTableRow";

const headers = [
  { label: 'USUÁRIO', key: 'collaboratorName' },
  { label: 'TREINAMENTO', key: 'training' },
  { label: 'STATUS', key: 'status' },
  { label: 'DATA LIMITE PARA REALIZAÇÃO', key: 'due_date' },
  { label: 'DATA DA APROVAÇÃO', key: 'approved_at' },
  { label: 'DATA DA REPROVAÇÃO', key: 'reproved_at' },
]

export function TrainingsLlm() {
  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewTrainingsLlmPermission = userLogged?.permissions.includes('view-trainings-llm')

  const userHasCreateTrainingLlmPermission = userLogged?.permissions.includes('create-llm-training')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status') ?? 'all'
  const collaborator = searchParams.get('collaborator')

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const { data: trainingsLlmData } = useQuery({
    queryKey: [
      'trainingsLlm',
      page,
      status,
      collaborator,
    ],
    queryFn: () => getTrainingsLlm({
      currentPage: page,
      pageSize: '10',
      collaborator,
      status: status as TrainingLlmStatus | 'all'
    }),
    keepPreviousData: true
  })

  const {
    data: llmTrainingsReportResult,
    isLoading: isLlmTrainingsReportResultLoading
  } = useQuery({
    queryKey: [
      'trainingsLlm',
      status,
      collaborator,
    ],
    queryFn: () => getTrainingsLlm({
      currentPage: '1',
      pageSize: trainingsLlmData?.meta?.count.toString(),
      collaborator,
      status: status as TrainingLlmStatus | 'all'
    }),
    keepPreviousData: true,
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  useEffect(() => {
    if (!userHasViewTrainingsLlmPermission) {
      redirect('/')
    }
  }, [userHasViewTrainingsLlmPermission, redirect])


  const {
    isOpen: isCreateTrainingLlmModalOpen,
    onOpen: onOpenCreateTrainingLlmModal,
    onClose: onCloseCreateTrainingLlmModal
  } = useDisclosure()

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: trainingsLlmData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: trainingsLlmData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: llmTrainingsReportResult ? llmTrainingsReportResult?.llmTrainings.map(
      (llmTraining) => {
        return {
          ...llmTraining,
          collaborator_name: `${llmTraining.collaborator.firstname} ${llmTraining.collaborator.lastname}`,
          due_date: llmTraining.due_date ? format(new Date(llmTraining.due_date), 'dd/MM/yyyy') : '-',
          approved_at: llmTraining.approved_at ? format(new Date(llmTraining.approved_at), 'dd/MM/yyyy') : '-',
          reproved_at: llmTraining.reproved_at ? format(new Date(llmTraining.reproved_at), 'dd/MM/yyyy') : '-',
          status: trainingLlmMStatusMap[llmTraining.status]
        }
      }
    ) : [],
    headers,
    filename: `treinamentos-llm.csv`,
  }

  return (
    <>
      <Box
        bg="white"
        p="6"
        rounded="md"
      >
        <Flex
          align="center"
          justify="space-between"
        >
          <Heading letterSpacing="tight">Treinamentos LLM</Heading>
          <Flex width='full' justifyContent='flex-end' gap={3}>
            <IconButton
              aria-label="Visualizar kanban"
              icon={<FaExchangeAlt />}
              variant='ghost'
              borderWidth={'thin'}
              size="sm"
              onClick={() => redirect('/controle/treinamentos/llm/kanban')}
            />
            {userHasCreateTrainingLlmPermission && (
              <IconButton
                aria-label="Criar Treinamento"
                icon={<FaPlus />}
                colorScheme="blue"
                size="sm"
                onClick={onOpenCreateTrainingLlmModal}
              />
            )}
          </Flex>
          <Modal
            isOpen={isCreateTrainingLlmModalOpen}
            onClose={onCloseCreateTrainingLlmModal}
            isCentered
          >
            <ModalOverlay />
            <CreateTrainingLlm onCloseModal={onCloseCreateTrainingLlmModal} />
          </Modal>
        </Flex>
        <TrainingsLlmTableFilter />
        <TableContainer
          borderColor="gray.200"
          rounded="md"
          mt="6"
        >
          <Table size="sm">
            <Thead>
              <Tr
                h="40px"
                bg="#38c3fa"
              >
                <Th></Th>
                <Th color="white">Usuário</Th>
                <Th color="white">Treinamento</Th>
                <Th color="white">Status</Th>
                <Th color="white">Data limite para realização</Th>
                <Th color="white">Data da aprovação</Th>
                <Th color="white">Data da reprovação</Th>
                <Th></Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {trainingsLlmData?.llmTrainings?.map((trainingLlm) => {
                return (
                  <TrainingsLlmTableRow trainingLlm={trainingLlm} />
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {!llmTrainingsReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLlmTrainingsReportResultLoading}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </>
  )
}

import { Button, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputLeftElement, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetDispatchStocksResponse } from "api/dispachStocks/getDispatchStocks";
import { headConfirmDispatchStock } from "api/dispachStocks/headConfirmDispatchStock";
import { useToastify } from "hooks/toastify/useToastify";
import { useCurrency } from "react-hook-currency";
import { useForm } from "react-hook-form";
import { FaDollarSign } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import * as yup from "yup";

interface HeadConfirmDispatchStockSchema {
  dispatchCost: number
  dispatchCte: string
  dispatchTracker: string
  dispatchInvoice: number
}

const headConfirmDispatchStockSchema = yup.object({
  dispatchCost: yup.number().required().transform((value, originalValue, ctx) => {
    if (ctx.isType(value)) return null

    value = transformStringToNumber(originalValue)

    return value
  }),
  dispatchCte: yup.string().required(),
  dispatchTracker: yup.string().required(),
  dispatchInvoice: yup.number().required(),
})

interface HeadConfirmDispatchStockProps {
  dispatchStockId: string
}

export function HeadConfirmDispatchStock({
  dispatchStockId
}: HeadConfirmDispatchStockProps) {

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<HeadConfirmDispatchStockSchema>({
    resolver: yupResolver(headConfirmDispatchStockSchema)
  })

  console.log(errors)

  const { onChange, format } = useCurrency({ style: 'decimal' })

  const queryClient = useQueryClient()

  const { mutateAsync: headConfirmDispatchStockFn } = useMutation({
    mutationFn: headConfirmDispatchStock,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetDispatchStocksResponse>({
        queryKey: ['dispatch-stocks']
      })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) {
          return
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          dispatchStocks: cachedValue.dispatchStocks.map((dispatchStock) => {
            if (dispatchStock.id === routeParams.dispatchStockId) {
              return { ...dispatchStock, step: 'collector-confirming' }
            }

            return dispatchStock
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleHeadConfirmDispatchStock(
    values: HeadConfirmDispatchStockSchema
  ) {
    await promiseMessage(headConfirmDispatchStockFn({
      body: { ...values },
      routeParams: {
        dispatchStockId
      }
    }), 'Despacho confirmado pela matriz! ✔️')

  }

  return (
    <ModalContent>
      <ModalHeader>Confirmar despacho (Matriz)</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Flex
          direction="column"
          gap="6"
          as="form"
          onSubmit={handleSubmit(handleHeadConfirmDispatchStock)}
        >
          <Stack
            w="full"
            direction={{ base: 'column', md: 'row' }}
            spacing="3"
          >
            <VStack w="full">
              <FormControl isInvalid={!!errors.dispatchCost}>
                <FormLabel fontSize="sm">
                  Custo do envio
                  <Text color="red.500" as="sup">*</Text>
                </FormLabel>

                <InputGroup size="sm">
                  <InputLeftElement pointerEvents='none' color="gray.400" >
                    <Icon as={FaDollarSign} />
                  </InputLeftElement>
                  <Input
                    {...register('dispatchCost')}
                    defaultValue={format('000')}
                    onChange={onChange}
                    rounded="md"
                  />
                </InputGroup>
              </FormControl>
            </VStack>

            <VStack w="full">
              <FormControl isInvalid={!!errors.dispatchCte}>
                <FormLabel fontSize="sm">
                  Cte do envio
                  <Text color="red.500" as="sup">*</Text>
                </FormLabel>

                <Input
                  {...register('dispatchCte')}
                  name="dispatchCte"
                  rounded="md"
                  size="sm"
                />
              </FormControl>
            </VStack>
          </Stack>

          <Stack
            w="full"
            direction={{ base: 'column', md: 'row' }}
            spacing="3"
          >
            <VStack w="full">
              <FormControl isInvalid={!!errors.dispatchTracker}>
                <FormLabel fontSize="sm">
                  Rastreador
                  <Text color="red.500" as="sup">*</Text>
                </FormLabel>

                <Input
                  {...register('dispatchTracker')}
                  rounded="md"
                  size="sm"
                />
              </FormControl>
            </VStack>

            <VStack w="full">
              <FormControl isInvalid={!!errors.dispatchInvoice}>
                <FormLabel fontSize="sm">
                  Nota fiscal
                  <Text color="red.500" as="sup">*</Text>
                </FormLabel>

                <Input
                  {...register('dispatchInvoice')}
                  rounded="md"
                  type="number"
                  size="sm"
                />
              </FormControl>
            </VStack>
          </Stack>

          <Button
            colorScheme="blue"
            type="submit"
            size="sm"
            rounded="md"
            w="min"
            alignSelf="flex-end"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Confirmar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

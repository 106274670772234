import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GeneralContentLoading } from "../../../components/Loading/GeneralContentLoading";
import { useAuth } from "../../../hooks/auth/useAuth";
import { useService } from "../../../hooks/services/service";
import { useServiceFunctions } from "../../../hooks/services/useServiceFunctions";
import { useSwal } from "../../../hooks/swal/useSwal";
import { CreateGeloSecoMaintenanceForm } from "./components/CreateGeloSecoMaintenanceForm";

export interface ICreateGeloSecoMaintenanceFormData {
  replenished_gelo_seco: number;
  open_box_photo: File | null;
}

interface IQueryParams {
  service_id: string;
}

export function CreateGeloSecoMaintenancePage() {
  const { push: redirectTo} = useHistory()
  const { service_id }: IQueryParams = useParams()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()

  const {
    service: { data: service, isLoading: isServiceLoading }
  } = useService(service_id, false, false)

  const {
    createGeloSecoMaintenance: { mutateAsync: createGeloSecoMaintenance, isSuccess: isCreateGeloSecoMaintenanceSuccess }
  } = useServiceFunctions()

  useEffect(() => {
    if (!userLogged?.permissions.includes("create-gelo-seco-maintenance")) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  useEffect(() => {
    if (isCreateGeloSecoMaintenanceSuccess) {
      redirectTo("/servicos/gelo-seco/manutencoes")
    }
  }, [isCreateGeloSecoMaintenanceSuccess, redirectTo])

  const collectorId = service?.pending_crossdocking_gelo_seco_maintenance
    ? service.serviceIDRequested.crossdocking_collector_id
    : service?.serviceIDRequested.destination_collector_id

  async function handleCreateGeloSecoMaintenance(data: ICreateGeloSecoMaintenanceFormData) {
    const formData = new FormData()

    formData.append("replenished_gelo_seco", String(data.replenished_gelo_seco))
    formData.append("open_box_photo", data.open_box_photo as Blob)
    formData.append("collector_id", collectorId as string)

    const hasCreateGeloSecoMaintenance = await confirmMessage({ title: "Deseja realizar a manutenção do gelo seco?" })

    if (hasCreateGeloSecoMaintenance) {
      await createGeloSecoMaintenance({ service_id, input: formData })
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isServiceLoading) {
    return <GeneralContentLoading />
  }

  return (
    <CreateGeloSecoMaintenanceForm submit={handleCreateGeloSecoMaintenance} />
  );
}

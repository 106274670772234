import { useEffect } from "react"
import * as yup from "yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"

import { useToastify } from "hooks/toastify/useToastify"
import { useUsers } from 'hooks/user/useUsers'
import { FormProvider, useForm } from "react-hook-form"
import { Link, useHistory } from "react-router-dom"

import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Flex, Spinner } from "@chakra-ui/react"

import { useAuth } from "hooks/auth/useAuth"
import { PurchaseOrderForm, PurchaseOrderFormInputs } from "./components/PurchaseOrderForm"
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions"
import { usePurchaseOrdersSocket } from "hooks/purchaseOrder/usePurchaseOrdersSocket"

const createPurchaseOrderSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  purchaseDescription: yup.string().required('Campo Obrigatório'),
  quantity: yup.mixed().when('type', {
    is: 'PRODUTO',
    then: yup.number().required('Campo obrigatório'),
  }),
  purchaseReason: yup.string().required('Campo obrigatório'),
  sugestedProvider: yup.string().required('Campo obrigatório'),
  arrivalLimitDate: yup.string().required('Campo obrigatório'),
  productResponsibleId: yup.mixed().when('type', {
    is: 'PRODUTO',
    then: yup.string().required('Campo obrigatório'),
  }),
  attachment: yup.mixed()
})

export function CreatePurchaseOrder() {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const { purchaseOrderSocketConnection } = usePurchaseOrdersSocket()

  const { data: users, isFetching: isFetchingUser } = useUsers()
  const { push: redirect } = useHistory()

  const userHasPermissionToCreatePurchaseOrder =
    userLogged.permissions.includes('create-purchase-order')

  useEffect(() => {
    if (!userHasPermissionToCreatePurchaseOrder) {
      redirect('/')
    }
  }, [userHasPermissionToCreatePurchaseOrder, redirect])

  const formMethods = useForm<PurchaseOrderFormInputs>({
    resolver: yupResolver(createPurchaseOrderSchema)
  })
  const { handleSubmit, formState: { isSubmitting } } = formMethods

  const { createPurchaseOrder: { mutateAsync: createPurchaseOrder } } = usePurchaseOrderFunctions()

  async function handleCreatePurchaseOrder(values: PurchaseOrderFormInputs) {
    const successMessage = "Pedido de compra solicitado com sucesso!"
    const formData = new FormData()

    formData.append("type", values.type)
    formData.append("purchaseDescription", values.purchaseDescription)
    if (values.quantity) {
      formData.append("quantity", values.quantity.toString())
    }
    formData.append("purchaseReason", values.purchaseReason)
    formData.append("sugestedProvider", values.sugestedProvider)
    formData.append("arrivalLimitDate", values.arrivalLimitDate)
    if (values.productResponsibleId) {
      formData.append("productResponsibleId", values.productResponsibleId)
    }
    if (values.attachment) {
      formData.append("attachment", values.attachment[0])
    }

    await promiseMessage(createPurchaseOrder({ input: formData }, {
      onSuccess: (data) => {
        purchaseOrderSocketConnection.emit('purchaseOrderCreated', {
          profiles: data.notificationProfiles,
          purchaseOrderProtocol: data.purchaseOrder.protocol,
        })
        redirect("/compras")
      }
    }), successMessage)
  }
  return (
    <StandardBackgroundForm
      title='Criar Pedido de Compra'
      onSubmit={handleSubmit(handleCreatePurchaseOrder)}
    >
      {isFetchingUser ?
        <Spinner /> :
        <FormProvider {...formMethods}>
          <PurchaseOrderForm slug='create' users={users} />
        </FormProvider>
      }

      <Flex justify='flex-end' mt={4} gap={4}>
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/compras/pedidos/aguardando-cotacao'
        >
          Aguardando Cotação
        </Button>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}

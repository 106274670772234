import { Box, Button, Stack } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { Input } from '../../../components/Inputs/Input'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'

import { useService } from '../../../hooks/services/service'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { serviceHandleStep } from '../../../utils/ServiceFunctions/serviceDict'

interface IQueryParams {
  service_id: string
}

export function EditBusinessServiceInProgress() {
  const { service_id }: IQueryParams = useParams()

  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(service_id, false, false)

  const sourceAddressesWithProviderSum = service
    ? service?.serviceIDRequestedBusiness.source_address_id.length +
      (service?.serviceIDRequestedBusiness.provider_id ? 1 : 0)
    : 0

  if (isServiceLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Box flex="1" background="white" padding={[6, 8]} borderRadius="8px">
        {service && (
          <>
            <Stack direction="column" spacing="24px">
              <Stack direction={['column', 'column', 'row']} spacing="24px">
                <Input
                  name="protocol"
                  label="Protocolo"
                  defaultValue={service.protocol}
                  isDisabled={true}
                />
                <Input
                  name="customer"
                  label="Pertence ao cliente"
                  defaultValue={service.customerIDService.trading_firstname}
                  isDisabled={true}
                />
                <Input
                  name="serviceType"
                  label="Tipo de serviço"
                  defaultValue={service.serviceIDRequestedBusiness.service_type}
                  isDisabled={true}
                />
                <Input
                  name="vehicle"
                  label="Veículo"
                  defaultValue={service.serviceIDRequestedBusiness.vehicle}
                  isDisabled={true}
                />
              </Stack>

              <Stack direction={['column', 'column', 'row']} spacing="24px">
                <Stack
                  w="full"
                  direction={['column', 'column', 'row']}
                  spacing="24px"
                >
                  <Input
                    name="routeStartDate"
                    label="Data inicial da rota"
                    defaultValue={formatDate.handle(
                      service.serviceIDRequestedBusiness.route_start_date,
                      'DateWithoutHourToInput',
                    )}
                    type="date"
                    isDisabled={true}
                  />
                  <Input
                    name="routeStartHour"
                    label="Horário inicial da rota"
                    defaultValue={formatDate.handle(
                      service.serviceIDRequestedBusiness.route_start_hour,
                      'DateOnlyWithHourMinute',
                    )}
                    type="time"
                    isDisabled={true}
                  />
                </Stack>
                <Input
                  name="step"
                  label="Etapa"
                  defaultValue={serviceHandleStep(service.step)}
                  isDisabled={true}
                />
              </Stack>
            </Stack>

            <Stack mt={4} direction="column" spacing="24px">
              {service.serviceIDCollect !== null &&
                service.serviceIDCollect.length > 0 &&
                service?.serviceIDCollect.length ===
                  sourceAddressesWithProviderSum && (
                  <Link
                    to={`/servico-business/coletas-finalizadas/editar/${service.id}`}
                    style={{ width: '100%' }}
                  >
                    <Button w="full" colorScheme="blue">
                      Editar coletas business
                    </Button>
                  </Link>
                )}
            </Stack>

            <Stack mt={4} direction="column" spacing="24px">
              {service.serviceIDDelivery !== null &&
                service.serviceIDDelivery.length > 0 &&
                service?.serviceIDDelivery.length ===
                  service.serviceIDRequestedBusiness.destination_address_id
                    .length && (
                  <Link
                    to={`/servico-business/entregas-finalizadas/editar/${service.id}`}
                    style={{ width: '100%' }}
                  >
                    <Button w="full" colorScheme="blue">
                      Editar entregas business
                    </Button>
                  </Link>
                )}
            </Stack>
          </>
        )}
      </Box>

  )
}

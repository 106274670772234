import { usePagination } from "@ajna/pagination"
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { Pagination } from "components/Pagination/Pagination"
import { Link, useHistory } from "react-router-dom";

import { useEffect, useReducer, useState } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FaCheckCircle, FaEye, FaTrash } from "react-icons/fa";
import { useAwaitingApprovePurchaseOrders } from "hooks/purchaseOrder/useAwaitingApprovePurchaseOrders";
import { PurchaseOrder, PurchaseOrderQuoteProviders } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { usePurchaseOrderFunctions } from "hooks/purchaseOrder/usePurchaseOrderFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useQueryClient } from "react-query";
import { ViewPurchaseOrderModal } from "./components/ViewPurchaseOrderModal";

interface ActionPayload {
  currentPage?: number
}

interface Action {
  type: 'set-awaiting-approve-purchase-orders-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-awaiting-approve-purchase-orders-current-page') {
    return {
      currentPage: action.payload.currentPage
    }
  }
  return {
    ...state,
    ...action.payload
  }
}

export function PurchaseOrdersAwaitingApprove() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  const [purchaseOrder, setPurchaseOrder] = useState({} as PurchaseOrder)

  const [listAwaitingApproveDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const userHasPermissionToViewPurchaseOrdersAwaitingApprove =
    userLogged.permissions.includes('view-purchases-arrived')

  const userHasPermissionToApprovePurchaseOrderQuotes =
    userLogged.permissions.includes('approve-purchase-order-quotes')

  const userHasPermissionToDeletePurchaseOrdersAwaitingApprove =
    userLogged.permissions.includes('delete-purchase-order')

  useEffect(() => {
    if (!userHasPermissionToViewPurchaseOrdersAwaitingApprove) {
      redirect('/')
    }
  }, [userHasPermissionToViewPurchaseOrdersAwaitingApprove, redirect])


  const {
    data: purchaseOrdersAwaitingApproveData,
    isFetching: isFetchingAwaitingApprovePurchaseOrdersData
  } = useAwaitingApprovePurchaseOrders({
    queryParams: {
      currentPage: listAwaitingApproveDataState.currentPage,
      pageSize: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!listAwaitingApproveDataState.currentPage
    }
  })

  const { deletePurchaseOrder: { mutateAsync: deletePurchaseOrder } } = usePurchaseOrderFunctions()

  async function handleDeletePurchaseOrder(purchaseOrderId: string) {
    await promiseMessage(deletePurchaseOrder(purchaseOrderId, {
      onSuccess: () => {
        queryClient.invalidateQueries(['purchaseOrdersAwaitingApprove'])
      }
    }), 'Pedido de compra excluído com sucesso!')
  }


  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: purchaseOrdersAwaitingApproveData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-awaiting-approve-purchase-orders-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  const {
    isOpen: isViewPurchaseOrderModalOpen,
    onOpen: onOpenViewPurchaseOrderModal,
    onClose: onCloseViewPurchaseOrderModal,
  } = useDisclosure()

  const handleOpenViewPurchaseOrderModal = (purchaseOrder: PurchaseOrder) => {
    setPurchaseOrder(purchaseOrder)
    onOpenViewPurchaseOrderModal()
  }


  const handleChangePage = (page: number) => setCurrentPage(page)


  return (
    <>
      <ViewPurchaseOrderModal
        onClose={onCloseViewPurchaseOrderModal}
        isOpen={isViewPurchaseOrderModalOpen}
        purchaseOrder={purchaseOrder}
      />
      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        {isFetchingAwaitingApprovePurchaseOrdersData ? <Spinner /> :
          <Flex
            w="full"
            direction="column"
            gap={6}
          >
            <Heading fontSize="xl">Cotações Aguardando Aprovação</Heading>

            <Divider />
            <TableContainer>
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>PROTOCOLO</Th>
                    <Th>SOLICITANTE</Th>
                    <Th>DATA SOLICITAÇÃO</Th>
                    <Th>TIPO</Th>
                    <Th>TIPO DE COTAÇÃO</Th>
                    <Th>DATA LIMITE</Th>
                    <Th>FORNECEDOR 1</Th>
                    <Th>FORNECEDOR 2</Th>
                    <Th>FORNECEDOR 3</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {purchaseOrdersAwaitingApproveData?.purchaseOrders?.map((purchaseOrder) => {

                    const quoteProvidersTableData = Array(3).fill('-')

                    const quoteProviders: PurchaseOrderQuoteProviders = JSON.parse(purchaseOrder.quote_providers)

                    return (
                      <Tr key={purchaseOrder.id}>
                        <Td>{purchaseOrder.protocol}</Td>
                        <Td>{`${purchaseOrder.owner.firstname} ${purchaseOrder.owner.lastname}`}</Td>
                        <Td>{formatDate.handle(purchaseOrder.created_at, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.type}</Td>
                        <Td>{purchaseOrder.quote_type}</Td>
                        <Td>{formatDate.handle(purchaseOrder.arrival_limit_date, 'DateWithoutHourToShow')}</Td>
                        {quoteProvidersTableData.map((defaultValue, index) => {
                          return (
                            quoteProviders[index] ? (
                              <Td key={String(index)}>{quoteProviders[index]?.providerName.toUpperCase()}</Td>
                            ) : (
                              <Td key={String(index)}>{defaultValue}</Td>
                            )
                          )
                        })}
                        <Td isNumeric>
                          {userHasPermissionToApprovePurchaseOrderQuotes && (
                            <Button
                              as={Link}
                              to={`/pedido-compra/${purchaseOrder.id}/aprovar-cotacoes`}
                              variant="ghost"
                            >
                              <Icon
                                cursor="pointer"
                                as={FaCheckCircle}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                              />
                            </Button>
                          )}
                          <Button
                            onClick={() => handleOpenViewPurchaseOrderModal(purchaseOrder)}
                            variant="ghost"
                          >
                            <Icon
                              cursor="pointer"
                              as={FaEye}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                          {userHasPermissionToDeletePurchaseOrdersAwaitingApprove && (
                            <Button
                              onClick={() => handleDeletePurchaseOrder(purchaseOrder.id)}
                              variant="ghost"
                            >
                              <Icon
                                cursor="pointer"
                                as={FaTrash}
                                transition="all 0.2s"
                                _hover={{ opacity: 0.8 }}
                              />
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              pagesQuantity={pagesCount}
              handlePageChange={handleChangePage}
            />
          </Flex>
        }
      </Box>
    </>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { AddressesWithRequestedChangesStatus } from "api/addresses/addressRequestedChange";
import { getAddressesWithRequestedChanges } from "api/addresses/getAddressesWithRequestedChanges";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useCustomer } from "hooks/customer/useCustomer";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { AddressesWithRequestedChangesTableFilters } from "./components/AddressesWithRequestedChangesTableFilters";
import { AddressesWithRequestedChangesTableRow } from "./components/AddressesWithRequestedChangesTableRow";

export function AddressesWithRequestedChanges() {
  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const userCanViewAddressesWithRequestedChanges = userLogged?.permissions.includes('view-addresses-with-requested-changes')

  useEffect(() => {
    if (!userCanViewAddressesWithRequestedChanges) {
      history.push('/')
    }
  }, [history, userCanViewAddressesWithRequestedChanges])

  const {
    customer: {
      data: customerData,
    }
  } = useCustomer(userLogged?.customer_id)

  useEffect(() => {
    if (userLogged?.user_type === 'CLIENTE') {
      searchParams.set('customer', customerData?.trading_firstname)
    }
  }, [searchParams, userLogged, customerData])

  const page = searchParams.get('page')
  const customer = searchParams.get('customer')
  const status = searchParams.get('status')

  const {
    data: result,
    isLoading: isAddressesWithRequestedChangesLoading
  } = useQuery({
    queryKey: ['addresses-with-requested-changes', page, customer, status],
    queryFn: () => getAddressesWithRequestedChanges({
      pageSize: '10',
      currentPage: page ?? '1',
      customer,
      status: status as AddressesWithRequestedChangesStatus | 'all'
    })
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: result?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: result?.meta?.pageSize
    },
  })

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (

      <Box
        bg="white"
        p="6"
        rounded="md"
      >

        <Heading letterSpacing="tight" fontSize="3xl">Alterações Solicitadas de Endereços</Heading>


        <AddressesWithRequestedChangesTableFilters />

        {isAddressesWithRequestedChangesLoading ? (
          <Spinner />
        ) : (
          <>
          {result?.addressRequestedChanges?.length ? (
          <TableContainer
            border="1px solid"
            borderColor="gray.200"
            rounded="md"
            mt="6"
          >
            <Table size="sm">
              <Thead>
                <Tr
                  h="40px"
                  bg="#38c3fa"
                >
                  <Th></Th>
                  <Th textColor="white">Cliente</Th>
                  <Th textColor="white">Endereço</Th>
                  <Th textColor="white">Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {result.addressRequestedChanges.map((requestedChange) => {
                  return (
                    <AddressesWithRequestedChangesTableRow
                      key={requestedChange.id}
                      requestedChange={requestedChange}
                    />
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
          ) : (
            <Flex
              w="full"
              justify="center"
              align="center"
              h="250px"
              textAlign="center"
              textColor="gray.400"
              fontWeight="bold"
            >
              Nenhum resultado encontrado com os filtros informados ou não há solicitações pendentes.
            </Flex>
          )}
          </>
        )}

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </Box>


  )
}

import { Flex, Box, Heading } from '@chakra-ui/react'


import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'

import { useService } from '../../../hooks/services/service'
import { useBranch } from '../../../hooks/branch/useBranch'
import { useBudget } from '../../../hooks/budget/useBudget'
import { GeloSecoMaintenanceTable } from '../../../components/Tables/Service/GeloSecoMaintenance/GeloSecoMaintenanceTable'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAddresses } from '../../../hooks/address/useAddresses'

export function GeloSecoMaintenanceList() {

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const {
    servicesBySteps: { data: services, isLoading: isServiceLoading, refetch },
  } = useService(null, true, false, false, ["pendingGeloSecoMaintenance"])
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)
  const {
    budgets: { data: budgets, isLoading: isBudgetsLoading },
  } = useBudget(null, true, false)

  useEffect(() => {
    if (!userLogged?.permissions.includes("view-gelo-seco-maintenances")) {
      redirectTo("/")
    }
  }, [userLogged, redirectTo])

  if (isAddressesLoading || isServiceLoading || isBranchLoading || isBudgetsLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Manutenções de Gelo Seco
            </Heading>
          </Flex>
          {services && addresses && branches && budgets && (
            <GeloSecoMaintenanceTable
              services={services}
              addresses={addresses}
              branches={branches}
              budgets={budgets}
              refetch={refetch}
            />
          )}
        </Box>
      </Flex>

  )
}

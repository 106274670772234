import { Flex, Box, Heading } from '@chakra-ui/react'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { CollectBusinessServiceTable } from '../../../components/Tables/BusinessService/Collect/CollectBusinessServiceTable'
import { useRequestedBusinessService } from '../../../hooks/services/requestedBusiness/useRequestedBusinessService'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { useCollectors } from 'hooks/collector/useCollectors'

export function CollectBusinessServiceList() {
  const {
    requestedBusinessServices: { data: requestedBusinessServices, isLoading: isRequestedBusinessServiceLoading, refetch }
  } = useRequestedBusinessService(undefined, false, {
    queryParams: {
      step: 'collect'
    }
  })
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()

  if (isAddressesLoading || isCollectorsLoading || isRequestedBusinessServiceLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Serviços Business a Coletar
            </Heading>
          </Flex>
          {collectors  && addresses && requestedBusinessServices && (
            <CollectBusinessServiceTable
              requestedBusinessServices={requestedBusinessServices}
              collectors={collectors}
              addresses={addresses}
              refetch={refetch}
            />
          )}
        </Box>
      </Flex>

  )
}

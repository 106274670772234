import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, Text } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendCltDriverMotorcycleDetails } from "api/cltDrivers/sendCltDriverMotorcycleDetails";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";

interface SendCltDriverMotorcycleDetailsProps {
  cltDriverId: string
}

export interface SendCltDriverMotorcycleDetailsSchema {
  motorcycleBrand: string
  motorcycleModel: string
  motorcycleYear: string
  motorcycleColor: string
  motorcycleLicensePlate: string
  vehicleDocumentRegisterYear: string
}

const sendCltDriverMotorcycleDetailsSchema = yup.object({
  motorcycleBrand: yup.string().required(),
  motorcycleModel: yup.string().required(),
  motorcycleYear: yup.string().required(),
  motorcycleColor: yup.string().required(),
  motorcycleLicensePlate: yup.string().required(),
  vehicleDocumentRegisterYear: yup.string().required(),
})

export function SendCltDriverMotorcycleDetails({ cltDriverId }: SendCltDriverMotorcycleDetailsProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    register,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverMotorcycleDetailsSchema>({
    resolver: yupResolver(sendCltDriverMotorcycleDetailsSchema)
  })

  const { mutateAsync: sendCltDriverMotorcycleDetailsFn } = useMutation({
    mutationFn: sendCltDriverMotorcycleDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })

    }
  })

  async function handleSendCltDriverMotorcycleDetails(values: SendCltDriverMotorcycleDetailsSchema) {
    await promiseMessage(sendCltDriverMotorcycleDetailsFn({
      body: values,
      cltDriverId
    }), 'Dados da moto enviados')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverMotorcycleDetails)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação - Dados da moto</Heading>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.motorcycleBrand}>
            <FormLabel fontSize="sm">
              Marca da moto
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('motorcycleBrand')}
              name="motorcycleBrand"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.motorcycleModel}>
            <FormLabel fontSize="sm">
              Modelo da moto
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('motorcycleModel')}
              name="motorcycleModel"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.motorcycleYear}>
            <FormLabel fontSize="sm">
              Ano da moto
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('motorcycleYear')}
              name="motorcycleYear"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.motorcycleColor}>
            <FormLabel fontSize="sm">
              Cor
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('motorcycleColor')}
              name="motorcycleColor"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.motorcycleLicensePlate}>
            <FormLabel fontSize="sm">
              Placa
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('motorcycleLicensePlate')}
              name="motorcycleLicensePlate"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.vehicleDocumentRegisterYear}>
            <FormLabel fontSize="sm">
              Ano do documento do veículo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('vehicleDocumentRegisterYear')}
              name="vehicleDocumentRegisterYear"
              size="sm"
              rounded="md"
            />
          </FormControl>
        </Stack>
      </Stack>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

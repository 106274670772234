import { Button, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react"
import { approveTrainingLlm } from "api/trainingLlm/approveTrainingLlm"
import { deleteLlmTraining } from "api/trainingLlm/deleteLlmTraining"
import { GetTrainingsLlmResponse } from "api/trainingLlm/getTrainingsLlm"
import { reproveTrainingLlm } from "api/trainingLlm/reproveTrainingLlm"
import { TrainingLlm } from "api/trainingLlm/trainingLlm"
import { format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { FaArrowRight, FaSearch, FaTimes } from "react-icons/fa"
import { FaTrash } from "react-icons/fa6"
import { useMutation, useQueryClient } from "react-query"
import { TrainingLlmDetail } from "./TrainingLlmDetail"
import { TrainingLlmStatus } from "./TrainingLlmStatus"

interface TrainingTableRowProps {
  trainingLlm: TrainingLlm
}
export function TrainingsLlmTableRow({ trainingLlm }: TrainingTableRowProps) {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const userCanApproveLlmTraining = userLogged?.permissions.includes('approve-llm-training')

  const userCanReproveLlmTraining = userLogged?.permissions.includes('reprove-llm-training')

  const userCanDeleteLlmTraining = userLogged?.permissions.includes('delete-llm-training')

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: approveTrainingLlmFn } = useMutation({
    mutationFn: approveTrainingLlm,
    onSuccess(_data, { trainingLlmId }) {
      const cachedTraining = queryClient.getQueriesData<GetTrainingsLlmResponse>({
        queryKey: ['trainingsLlm']
      })
      queryClient.invalidateQueries(['pending-trainings-llm'])
      queryClient.invalidateQueries({ queryKey: 'approved-trainings-llm' })
      queryClient.invalidateQueries({ queryKey: 'reproved-trainings-llm' })

      queryClient.invalidateQueries({
        queryKey: ['trainingLlm', trainingLlmId]
      })


      cachedTraining.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          llmTrainings: cachedData.llmTrainings.map((training) => {
            if (training.id === trainingLlmId) {
              return {
                ...training,
                status: 'approved',
                approved_at: new Date().toISOString(),
              }
            }

            return training
          })
        })
      })
    },
  })

  const { mutateAsync: reproveTrainingLlmFn } = useMutation({
    mutationFn: reproveTrainingLlm,
    onSuccess(_data, { trainingLlmId }) {
      const cachedTraining = queryClient.getQueriesData<GetTrainingsLlmResponse>({
        queryKey: ['trainingsLlm']
      })
      queryClient.invalidateQueries(['pending-trainings-llm'])
      queryClient.invalidateQueries({ queryKey: 'approved-trainings-llm' })
      queryClient.invalidateQueries({ queryKey: 'reproved-trainings-llm' })

      queryClient.invalidateQueries({
        queryKey: ['trainingLlm', trainingLlmId]
      })


      cachedTraining.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          trainings: cachedData.llmTrainings.map((training) => {
            if (training.id === trainingLlmId) {
              return {
                ...training,
                status: 'reproved',
                reproved_at: new Date().toISOString(),
              }
            }

            return training
          })
        })
      })
    },
  })

  const { mutateAsync: deleteLlmTrainingFn } = useMutation({
    mutationFn: deleteLlmTraining,
    onSuccess(_data, { trainingId }) {
      const cachedTraining = queryClient.getQueriesData<GetTrainingsLlmResponse>({
        queryKey: ['trainingsLlm']
      })
      queryClient.invalidateQueries(['pending-trainings-llm'])
      queryClient.invalidateQueries({ queryKey: 'approved-trainings-llm' })
      queryClient.invalidateQueries({ queryKey: 'reproved-trainings-llm' })

      queryClient.invalidateQueries({
        queryKey: ['trainingLlm', trainingId]
      })


      cachedTraining.forEach(([cachedKey, cachedData]) => {

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          llmTrainings: cachedData.llmTrainings.filter((llmTraining) => llmTraining.id !== trainingId)
        })
      })
    },
  })

  async function handleApproveTrainingLlm() {
    await promiseMessage(approveTrainingLlmFn({
      trainingLlmId: trainingLlm.id
    }), 'Treinamento LLM Aprovado! 🎉')
  }

  async function handleReproveTrainingLlm() {
    await promiseMessage(reproveTrainingLlmFn({
      trainingLlmId: trainingLlm.id
    }), 'Treinamento LLM Reprovado! ')
  }

  async function handleDeleteLlmTrainig() {
    await promiseMessage(deleteLlmTrainingFn({
      trainingId: trainingLlm.id
    }), 'Treinamento LLM Excluído! ')
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Treinamento LLM"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />

          <TrainingLlmDetail trainingLlmId={trainingLlm.id} />

        </Modal>
      </Td>
      <Td>{trainingLlm.collaborator.firstname} {trainingLlm.collaborator.lastname}</Td>
      <Td>{trainingLlm.training}</Td>
      <Td>
        <TrainingLlmStatus status={trainingLlm.status} />
      </Td>
      <Td>{trainingLlm.due_date ? format(new Date(trainingLlm.due_date), 'dd/MM/yyyy') : '-'}</Td>
      <Td>{trainingLlm.approved_at ? format(new Date(trainingLlm.approved_at), 'dd/MM/yyyy') : '-'}</Td>
      <Td>{trainingLlm.reproved_at ? format(new Date(trainingLlm.reproved_at), 'dd/MM/yyyy') : '-'}</Td>
      <Td>
        {(trainingLlm.status === 'pending' && userCanApproveLlmTraining) && (
          <Button
            leftIcon={<FaArrowRight />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleApproveTrainingLlm}
          >
            Aprovar
          </Button>
        )}
      </Td>
      <Td>
        {(trainingLlm.status === 'pending' && userCanReproveLlmTraining) && (
          <Button
            leftIcon={<FaTimes />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleReproveTrainingLlm}
          >
            Reprovar
          </Button>
        )}
      </Td>
      <Td>
        {userCanDeleteLlmTraining && (
          <Button
            leftIcon={<FaTrash />}
            lineHeight="1"
            size="xs"
            variant="ghost"
            onClick={handleDeleteLlmTrainig}
          >
            Excluir
          </Button>
        )}
      </Td>
    </Tr>
  )

}

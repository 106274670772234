import { Box, FormControl, FormLabel, Input, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

export function PatrimonyRequestForm() {
  const { register, setValue, control, formState: { errors } } = useFormContext<CreateSupportSchema>()


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      isNewEmployee: 'Novo colaborador?',
      admissionDate: 'Data de admissão',
      patrimonyDescriptionAndRequestReason: 'Descrição do patrimônio e motivo de solicitação',
      isPatrimonyPurchaseNecessary: 'Será necessário compra de patrimônio?',

    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  const isNewEmployee = useWatch({
    control,
    name: 'formData.isNewEmployee.value',
  });


  return (
    <Box w='full'>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.isNewEmployee}>
            <FormLabel fontSize="sm">
              Novo colaborador?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>

            <Select
              {...register("formData.isNewEmployee.value")}
              name="formData.isNewEmployee.value"
              placeholder="Selecione uma opção..."
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('isNewEmployee')}
            >

              <option value='Sim'>Sim</option>
              <option value='Não'>Não</option>
            </Select>
          </FormControl>
        </Stack>
        {isNewEmployee === 'Sim' && (
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors.formData?.admissionDate}>
              <FormLabel fontSize='sm'>
                Data de admissão
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('formData.admissionDate.value')}
                name='formData.admissionDate.value'
                type="date"
                size='sm'
                rounded="md"
                onChangeCapture={handleInputChange('admissionDate')}
              />
            </FormControl>
          </Stack>
        )}
      </Stack>
      <FormControl isInvalid={!!errors.formData?.patrimonyDescriptionAndRequestReason} mt={3}>
        <FormLabel fontSize="sm">
          Descrição do patrimônio e motivo de solicitação
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.patrimonyDescriptionAndRequestReason.value')}
          name='formData.patrimonyDescriptionAndRequestReason.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('patrimonyDescriptionAndRequestReason')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.isPatrimonyPurchaseNecessary} mt={3}>
        <FormLabel fontSize="sm">
          Será necessário compra de patrimônio?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register("formData.isPatrimonyPurchaseNecessary.value")}
          name="formData.isPatrimonyPurchaseNecessary.value"
          placeholder="Selecione uma opção..."
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('isPatrimonyPurchaseNecessary')}
        >

          <option value='Sim'>Sim</option>
          <option value='Não'>Não</option>
        </Select>
      </FormControl>
    </Box>
  )
}

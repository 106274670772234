import { Button, Flex, Input, Select } from "@chakra-ui/react"
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";


interface AggregatesExtrasDiscountsTableFiltersSchema {
  status: 'requested' | 'approved' | 'effectivated' | 'rejected' | 'all'
  aggregate: string
  startDate: string
  endDate: string
  route: string
}

export function AggregatesExtrasDiscountsTableFilter() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const aggregate = searchParams.get('aggregate')
  const status = searchParams.get('status') ?? 'all'
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const route = searchParams.get('route')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<AggregatesExtrasDiscountsTableFiltersSchema>({
    defaultValues: {
      aggregate,
      startDate,
      endDate,
      route,
      status: status as 'requested' | 'approved' | 'effectivated' | 'rejected' | 'all'
    }
  })

  async function handleFilterAggregatesExtrasDiscounts(data: AggregatesExtrasDiscountsTableFiltersSchema) {
    if (data.aggregate) {
      searchParams.set('aggregate', data.aggregate)
    } else {
      searchParams.delete('aggregate')
    }
    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    if (data.startDate) {
      searchParams.set('startDate', data.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (data.endDate) {
      searchParams.set('endDate', data.endDate)
    } else {
      searchParams.delete('endDate')
    }

    if (data.route) {
      searchParams.set('route', data.route)
    } else {
      searchParams.delete('route')
    }


    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('status')
    searchParams.delete('aggregate')
    searchParams.delete('startDate')
    searchParams.delete('endDate')
    searchParams.delete('route')
    reset({
      aggregate: '',
      endDate: '',
      route: '',
      startDate: '',
      status: 'all'
    })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterAggregatesExtrasDiscounts)}
    >
      <Input
        {...register("aggregate")}
        placeholder="Motorista"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("route")}
        placeholder="Rota"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />
      <Input
        {...register("startDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />

      <Input
        {...register("endDate")}
        type="date"
        size="sm"
        rounded="md"
        w={{ base: 'full', md: 'fit-content' }}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="requested">Solicitado</option>
        <option value="approved">Aprovado</option>
        <option value="effectivated">Efetivado</option>
        <option value="rejected">Rejeitado</option>
      </Select>


      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>


    </Flex>
  )
}

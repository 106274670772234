import { Button, Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Link as ChakraLink, Icon, FormLabel, Text, Spinner, TableContainer, Table, Tbody, Tr, Td } from "@chakra-ui/react";
import { PurchaseOrderQuoteType } from "api/purchaseOrders/purchaseOrder";
import { useToastify } from "hooks/toastify/useToastify";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { validateHasFile } from "utils/imageValidation";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { Select } from "components/Inputs/SelectInput";
import { Input } from "components/Inputs/Input";
import { getPurchaseOrder } from "api/purchaseOrders/getPurchaseOrder";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import { useLLMUsers } from "hooks/user/useLLMUsers";
import { FiFile } from "react-icons/fi";
import { FaPlus, FaTrash } from "react-icons/fa";
import { usePurchaseOrdersSocket } from "hooks/purchaseOrder/usePurchaseOrdersSocket";
import { quotePurchaseOrder } from "api/purchaseOrders/quotePurchaseOrder";
import { GetPurchaseOrdersResponse } from "api/purchaseOrders/getPurchaseOrders";


interface QuotePurchaseOrderProps {
  purchaseOrderId: string
  onCloseModal: () => void
}

interface PurchaseOrderQuoteProvidersFormInputProps {
  providerName: string
  budgetAttachment: FileList
}

export interface QuotePurchaseOrderFormInputs {
  quoteType: PurchaseOrderQuoteType
  quoteProviders: PurchaseOrderQuoteProvidersFormInputProps[]
  responsibleForApproval: string
}

const quotePurchaseOrderSchema = yup.object().shape({
  quoteType: yup.string().required('Campo obrigatório'),
  quoteProviders: yup.array().min(1, 'Campo obrigatório.').of(yup.object({
    providerName: yup.string().required('Campo obrigatório'),
    budgetAttachment: yup
      .mixed()
      .test('hasFile', 'Campo obrigatório', (value: FileList) => validateHasFile(value))
  })),
  responsibleForApproval: yup.string().required('Campo obrigatório')
})

const purchaseOrderQuoteTypeOptions = [
  { key: '0', value: 'PMC', showOption: 'PMC' },
  { key: '1', value: 'ENDOMARKETING E IMÓVEL', showOption: 'ENDOMARKETING E IMÓVEL' },
  { key: '2', value: 'OUTRA', showOption: 'OUTRA' },
]

const userTypes = [
  'COMERCIAL - GERÊNCIA',
  'ADMINISTRATIVO FINANCEIRO - GERÊNCIA',
  'ADMIN',
  'REGULAÇÃO E QUALIDADE - DIRETORIA',
  'COMERCIAL - DIRETORIA',
  'SUCESSO DO CLIENTE - DIRETORIA',
  'ADMINISTRATIVO FINANCEIRO - DIRETORIA',
  'DIRETORIA EXECUTIVA'
];


export function QuotePurchaseOrder({
  purchaseOrderId,
  onCloseModal
}: QuotePurchaseOrderProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { purchaseOrderSocketConnection } = usePurchaseOrdersSocket()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<QuotePurchaseOrderFormInputs>({
    resolver: yupResolver(quotePurchaseOrderSchema)
  })

  const { data: purchaseOrderData, isLoading: isPurchaseOrderDataLoading } = useQuery({
    queryKey: ['purchaseOrder', purchaseOrderId],
    queryFn: () => getPurchaseOrder({ purchaseOrderId })
  })

  const {
    data: llmUsersData,
    isFetching: isFetchingLlmUsersData
  } = useLLMUsers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const usersOptions = llmUsersData?.users
    .filter(user => userTypes.includes(user?.user_type))
    .map((user) => {
      return {
        key: user.id,
        value: user.id,
        showOption: user.firstname + ' ' + user.lastname
      }
    })

  const purchaseOrder = purchaseOrderData?.purchaseOrder

  const isPurchaseOrderTypePmc = purchaseOrder?.purchase_description === 'PMC'

  const isProductPurchaseOrder = purchaseOrder?.type === 'PRODUTO'


  useEffect(() => {
    if (isPurchaseOrderTypePmc && purchaseOrder) {
      setValue('quoteType', 'PMC')
    }
  }, [isPurchaseOrderTypePmc, purchaseOrder, setValue])

  const {
    fields: quoteProvidersFields,
    append: appendQuoteProviderField,
    remove: removeQuoteProviderField
  } = useFieldArray({
    control,
    name: 'quoteProviders'
  })

  const [quoteProviders] = useWatch({
    control,
    name: ['quoteProviders']
  })


  function handleAppendQuoteProviderField() {
    appendQuoteProviderField({
      providerName: '',
      budgetAttachment: undefined
    })
  }

  const { mutateAsync: quotePurchaseOrderFn } = useMutation({
    mutationFn: quotePurchaseOrder,
    onSuccess(_data, { input, purchaseOrderId }) {
      const cachedPurchaseOrders = queryClient.getQueriesData<GetPurchaseOrdersResponse>({
        queryKey: ['purchaseOrders']
      })
      queryClient.invalidateQueries(['purchaseOrders'])
      cachedPurchaseOrders.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          purchaseOrders: cachedData.purchaseOrders.map((purchaseOrder) => {
            if (purchaseOrder.id === purchaseOrderId) {
              return {
                ...purchaseOrder,
              }
            }

            return purchaseOrder
          })
        })
      })

      onCloseModal()
    }
  })

  async function handleQuotePurchaseOrder(values: QuotePurchaseOrderFormInputs) {
    const formData = new FormData()

    formData.append('quoteType', values.quoteType)
    formData.append('responsibleForApproval', values.responsibleForApproval)
    values.quoteProviders.forEach((quoteProvider, index) => {
      formData.append(`quoteProviders.${index}.providerName`, quoteProvider.providerName)
      formData.append(`quoteProviders.${index}.budgetAttachment`, quoteProvider.budgetAttachment[0])
    })

    await promiseMessage(quotePurchaseOrderFn({
      purchaseOrderId,
      input: formData
    }, {
      onSuccess: async (data) => {
        purchaseOrderSocketConnection.emit('purchaseOrderQuoted', {
          purchaseOrderProtocol: data.purchaseOrder.protocol,
          responsibleForApproval: data.purchaseOrder.responsible_for_approval,
        })
      }
    }), "Cotação realizada com sucesso!🎉")
  }
  return (
    <ModalContent maxW='800px'>
      <ModalHeader letterSpacing="tight">Cotar Pedido de Compra</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as='form'
        onSubmit={handleSubmit(handleQuotePurchaseOrder)}
        maxH='600px'
        overflowY='scroll'
      >
        {isFetchingLlmUsersData || isPurchaseOrderDataLoading ? (
          <Spinner />
        ) : (
          <>
            <TableContainer>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Protocolo</Td>
                    <Td isNumeric>{purchaseOrder.protocol}</Td>
                  </Tr>
                  <Tr>
                    <Td>Tipo</Td>
                    <Td isNumeric>{purchaseOrder.type}</Td>
                  </Tr>
                  {isProductPurchaseOrder && !isPurchaseOrderTypePmc && (
                    <Tr>
                      <Td>Quantidade</Td>
                      <Td isNumeric>{purchaseOrder.quantity}</Td>
                    </Tr>
                  )}
                  {isProductPurchaseOrder && !isPurchaseOrderTypePmc && (
                    <Tr>
                      <Td>Responsável pelo Produto</Td>
                      <Td isNumeric>{purchaseOrder?.productResponsible?.firstname} {purchaseOrder?.productResponsible?.lastname}</Td>
                    </Tr>
                  )}

                  <Tr>
                    <Td>Descrição da compra</Td>
                    <Td isNumeric>{purchaseOrder?.purchase_description}</Td>
                  </Tr>

                  {!isPurchaseOrderTypePmc && (
                    <Tr>
                      <Td >Motivo da compra</Td>
                      <Td isNumeric alignSelf='flex-end' display="flex" justifyContent="flex-end">
                        <Text w='220px' whiteSpace="pre-line">{purchaseOrder?.purchase_reason}</Text>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>

            <Flex mt='4' direction="column" gap={6} align="center">

              <Select
                {...register('quoteType')}
                name="quoteType"
                options={purchaseOrderQuoteTypeOptions}
                label="Tipo"
                placeholder="Selecione uma opção..."
                error={errors.quoteType}
                isDisabled={isPurchaseOrderTypePmc}
                required />

              <Select
                {...register('responsibleForApproval')}
                name="responsibleForApproval"
                options={usersOptions}
                label="Responsável pela aprovação"
                placeholder="Selecione uma opção..."
                error={errors.responsibleForApproval}
                required />
              {quoteProvidersFields.map((field, index) => {
                return (
                  <Stack
                    w='full'
                    key={field.id}
                    spacing={4}
                    direction={['column', 'column', 'row']}
                  >
                    <Input
                      {...register(`quoteProviders.${index}.providerName`)}
                      name={`quoteProviders.${index}.providerName`}
                      label='Fornecedor'
                      error={errors.quoteProviders
                        ? errors?.quoteProviders[index]?.providerName
                        : undefined}
                      required />
                    <Stack
                      w='full'
                      spacing={4}
                      direction={['column', 'column', 'row']}
                    >
                      <Input
                        {...register(`quoteProviders.${index}.budgetAttachment`)}
                        name={`quoteProviders.${index}.budgetAttachment`}
                        type='file'
                        hidden
                        label='Anexo'
                        error={errors.quoteProviders
                          ? errors?.quoteProviders[index]?.budgetAttachment
                          : undefined}
                      >
                        <Button
                          leftIcon={<Icon as={FiFile} />}
                          as={FormLabel}
                          htmlFor={`quoteProviders.${index}.budgetAttachment`}
                          h='48px'
                          _hover={{
                            cursor: 'pointer'
                          }}
                          size='sm'
                        >
                          Escolha um arquivo
                        </Button>
                      </Input>
                      {quoteProviders && quoteProviders[index]?.budgetAttachment.length > 0 && (

                        <ChakraLink
                          p='8'
                          href={URL.createObjectURL(quoteProviders[index]?.budgetAttachment[0])}
                          isExternal
                        >
                          {quoteProviders[index]?.budgetAttachment[0]?.name} (Visualizar)

                        </ChakraLink>
                      )}
                    </Stack>
                    <Stack w='min' pt='8'>
                      <Button
                        colorScheme='red'
                        variant='ghost'
                        onClick={() => removeQuoteProviderField(index)}
                        w='full'
                      >
                        <Icon as={FaTrash} />
                      </Button>
                    </Stack>
                  </Stack>
                );
              })}

              {quoteProvidersFields.length < 3 && (
                <Flex direction="column" w="full" gap="2">
                  <Button
                    alignSelf='flex-start'
                    leftIcon={<Icon as={FaPlus} />}
                    onClick={handleAppendQuoteProviderField}
                  >
                    Adicionar
                  </Button>
                  {errors.quoteProviders && (
                    <Text fontSize="sm" color="red.500">{errors.quoteProviders.message}</Text>
                  )}
                </Flex>
              )}
            </Flex>
            <Flex
              mt="6"
              w="full"
              justify="flex-end"
            >
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Cotar
              </Button>
            </Flex>
          </>
        )}
      </ModalBody>
    </ModalContent>
  )
}

import axios from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { ESGReportResponse } from "./dtos/ESGReport";

interface ESGReportQueryParams {
  start_filter: string
  end_filter: string
}

type QueryOptions = UseQueryOptions<ESGReportResponse, unknown, ESGReportResponse, QueryKey>

interface UseESGReportOptions {
  queryOptions?: QueryOptions
  queryParams?: ESGReportQueryParams
}

async function generateESGReportReqFunction({
  start_filter,
  end_filter
}: ESGReportQueryParams) {
  try {

    const { data } = await api.get(`/report/esg`, {
      params: {
        start_filter,
        end_filter
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError(error.response.data.message)
    }
  }
}

export function useESGReport(options?: UseESGReportOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    errorMessage(message)

    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) await handleLogout()

  }

  return useQuery([
    "ESGReport",
    { ...options?.queryParams }
  ], () => generateESGReportReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface ValidateStepChangeRequestBody {
  hasValidate: boolean
}

interface ValidateStepChangeRequestProps {
  routeParams: {
    serviceId: string
  }
  body: ValidateStepChangeRequestBody
}

export async function validateStepChangeRequest({
  routeParams,
  body
}: ValidateStepChangeRequestProps) {
  try {
    const { data } = await api.patch(`/services/${routeParams.serviceId}/validate-step-change-request`, body)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateSupportSubjectBody {
  supportType: 'Externo' | 'Interno' | 'Parceiro'
  category?: string
  name: string
  deadlineInMinutes: number
  responsiblesIds: string[]
}

interface CreateSupportSubjectParams {
  body: CreateSupportSubjectBody

}

export async function createSupportSubject({
  body
}: CreateSupportSubjectParams) {
  try {
    const response = await api.post(`/supports/subjects`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

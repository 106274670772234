import { Editable, Flex, IconButton, Link, Table, Tbody, Td, Tr, } from "@chakra-ui/react";
import { editRefundField } from "api/refunds/editRefundField";
import { GetRefundResponse } from "api/refunds/getRefund";
import { Refund } from "api/refunds/_types/Refund";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect, useMemo, useState } from "react";
import { FaExternalLinkAlt, } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { RefundDateEditableInput } from "./RefundDateEditableInput";

interface RefundDetailTableProps {
  refundResult: GetRefundResponse
}

export function RefundDetailTable({ refundResult }: RefundDetailTableProps) {

  const [isEditing, setIsEditing] = useState(false)
  const [newRefundDate, setNewRefundDate] = useState("")
  const queryClient = useQueryClient()

  const { userLogged } = useAuth()

  const userCanEditRefund = userLogged?.permissions?.includes(
    'edit-refund'
  )

  function handleEditChange(isEditing: boolean) {
    setIsEditing(isEditing)
  }

  const defaultValueRefundDate = useMemo(() => {
    return refundResult?.refund?.date
      ? isEditing
        ? format(new Date(refundResult?.refund?.date), "yyyy-MM-dd'T'HH:mm")
        : format(new Date(refundResult?.refund?.date), "dd/MM/yyyy '-' HH:mm")
      : null
  }, [isEditing, refundResult])

  useEffect(() => {
    if (defaultValueRefundDate) {
      setNewRefundDate(defaultValueRefundDate)
    }
  }, [defaultValueRefundDate])

  const { mutateAsync: editRefundFieldFn } = useMutation({
    mutationFn: editRefundField,
    onSuccess() {
      const refundId = refundResult?.refund?.id
      queryClient.invalidateQueries({ queryKey: ['refund', refundId] })

      if (refundResult?.refund.status === 'approved') {
        queryClient.invalidateQueries({ queryKey: 'approved-refunds' })
      }

      if (refundResult?.refund.status === 'reproved') {
        queryClient.invalidateQueries({ queryKey: 'reproved-refunds' })
      }

      if (refundResult?.refund.status === 'refunding') {
        queryClient.invalidateQueries({ queryKey: 'pending-refunds' })
      }

    }
  })

  async function handleEditRefundField(value: unknown, fieldName: keyof Refund) {
    await editRefundFieldFn({
      body: { value },
      fieldName,
      refundId: refundResult?.refund?.id
    })
  }



  return (
    <Table size="sm">
      <Tbody>
        <Tr>
          <Td>Tipo reembolso</Td>
          <Td isNumeric>{refundResult?.refund.type}</Td>
        </Tr>
        {refundResult?.refund.protocol && (
          <Tr>
            <Td>Protocolo do pedido de reembolso</Td>
            <Td isNumeric>{refundResult?.refund.protocol}</Td>
          </Tr>
        )}
        <Tr>
          <Td>Responsável</Td>
          <Td isNumeric>{refundResult?.refund.responsible
            ? `${refundResult?.refund?.responsible?.firstname} ${refundResult?.refund?.responsible?.lastname}`
            : '-'}
          </Td>
        </Tr>
        {refundResult?.refund?.shippingRefund && (
          <>
            <Tr>
              <Td>Transportadora</Td>
              <Td isNumeric>{refundResult?.refund?.shippingRefund?.boardService?.branchIDBoard?.shippingIDBranch?.trading_name}</Td>
            </Tr>
            <Tr>
              <Td>Rastreador</Td>
              <Td isNumeric>{refundResult?.refund?.shippingRefund?.boardService?.operational_number}</Td>
            </Tr>
            {refundResult?.refund?.shippingRefund?.boardService?.real_board_date && (
              <Tr>
                <Td>Data do embarque</Td>
                <Td isNumeric>{format(new Date(refundResult?.refund?.shippingRefund?.boardService?.real_board_date), "dd/MM/yyyy")}</Td>
              </Tr>
            )}
          </>
        )}

        {refundResult?.refund?.providerRefund && (
          <>
            {refundResult?.refund?.providerRefund?.occurrenceDate && (
              <Tr>
                <Td>Data da ocorrência</Td>
                <Td isNumeric>{format(new Date(refundResult?.refund?.providerRefund?.occurrenceDate), "dd/MM/yyyy")}</Td>
              </Tr>
            )}
            <Tr>
              <Td>Fornecedor</Td>
              <Td isNumeric>{refundResult?.refund?.providerRefund?.provider?.trading_name}</Td>
            </Tr>
            <Tr>
              <Td>Tipo de fornecedor</Td>
              <Td isNumeric>{refundResult?.refund?.providerRefund?.type}</Td>
            </Tr>
          </>
        )}
        {refundResult?.refund?.invoiceNumber && (
          <Tr>
            <Td>Número da Fatura</Td>
            <Td isNumeric>{refundResult?.refund?.invoiceNumber}</Td>
          </Tr>
        )}
        {refundResult?.refund?.description && (
          <Tr>
            <Td>Descrição</Td>
            <Td isNumeric>{refundResult?.refund?.description}</Td>
          </Tr>
        )}
        {refundResult?.refund?.disapprovalReason && (
          <Tr>
            <Td>Motivo da não aprovação</Td>
            <Td isNumeric>{refundResult?.refund?.disapprovalReason}</Td>
          </Tr>
        )}


        {refundResult?.refund?.date && (
          <Tr>
            <Td>Data do reembolso</Td>
            <Td isNumeric>
              <Flex
                gap={2}
                align='center'
                justify='end'
              >
                <Editable

                  onChange={(e) => {
                    setNewRefundDate(e)
                  }}
                  value={newRefundDate}
                  onSubmit={(e) => handleEditRefundField(new Date(e).toISOString(), 'date')}
                >
                  <RefundDateEditableInput
                    type='datetime-local'
                    onEditChange={handleEditChange}
                    size='sm'
                    rounded='md'
                    isDisabled={!userCanEditRefund}
                  />

                </Editable>

              </Flex>
            </Td>
          </Tr>
        )}
        <Tr>
          <Td>Valor do reembolso</Td>
          <Td isNumeric>{String(new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(Number(refundResult?.refund?.valueInCents / 100)))}</Td>
        </Tr>
        <Tr>
          <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Anexo de CTE ou
            Fatura</Td>
          <Td isNumeric>
            <IconButton
              aria-label="Abrir anexo"
              as={Link}
              href={refundResult?.refund?.requestAttachment?.link}
              icon={<FaExternalLinkAlt />}
              size="sm"
              isExternal
            />
          </Td>
        </Tr>
        <Tr>
          <Td maxW="150px" overflow="hidden" textOverflow="ellipsis">Anexo E-mail da
            solicitação de reembolso</Td>
          <Td isNumeric>
            <IconButton
              aria-label="Abrir anexo"
              as={Link}
              href={refundResult?.refund?.emailRequestAttachment?.link}
              icon={<FaExternalLinkAlt />}
              size="sm"
              isExternal
            />
          </Td>
        </Tr>
      </Tbody>
    </Table>
  )
}

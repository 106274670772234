import { Box, Button, Flex, Icon, IconButton, Td, Text, Tooltip, Tr } from "@chakra-ui/react"
import { format } from "date-fns"
import { FaInfoCircle, FaSearch } from "react-icons/fa"
import { FaArrowRight } from "react-icons/fa6"
import { Link } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"
import { formatTimeByTimezoneUser } from "utils/formatTimeByTimezoneUser"
import { serviceDelay } from "utils/serviceDelay"

interface BoardsTableRowProps {
  service: {
    id: string
    step: string
    protocol: number,
    customer: string
    vehicle: string
    source_addresses: string[],
    destination_addresses: string[],
    collect_hour_start: string
    collect_hour_end: string
    service_type: string
    board_date: string
    board_hour: string
    shipping: string
    source_branch: string
    destination_branch: string
    delivery_date: string
    delivery_hour: string
    observations: string
    source_collector: string
    destination_collector: string
    source_tz: string
    destination_tz: string
    has_occurrence: boolean
  }
}

export function BoardsTableRow({ service }: BoardsTableRowProps) {
  const isSourceTzDifferentFromUser = service.source_tz
    ? Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60
    : false

  const serviceInDelay = serviceDelay({
    timezoneOffset: service.source_tz,
    serviceDate: service.board_date,
    serviceHour: service.board_hour
  })

  const pathRedirect = '/relacao/servicos?tab=boards&page=1'

  return (
    <Tr
      key={service.protocol}
      color={service.has_occurrence ? 'yellow.400' : serviceInDelay ? 'red.400' : ''}
    >
      <Td>
        <Flex align="center" gap="3">
          <IconButton
            as={Link}
            to={`/servico/detalhes/${service.id}`}
            aria-label="Detalhes do serviço"
            size="sm"
            icon={<FaSearch />}
            variant="ghost"
          />

          {['toBoardService', 'boardingService'].includes(service.step) && (
            <Button
              as={Link}
              size="xs"
              lineHeight="1"
              leftIcon={<FaArrowRight />}
              rounded="md"
              variant="ghost"
              to={{
                pathname:`/servicos/embarques/${service.id}`,
                state:{
                  path: pathRedirect
                }
              }}
            >
              Embarcar
            </Button>
          )}
        </Flex>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>
        <Text w="300px" overflow="hidden" whiteSpace="pre-line">
          {service.customer}
        </Text>
      </Td>
      <Td>
        {service?.source_addresses?.map((address, index) => (
          <Text w="300px" key={index} whiteSpace="pre-line">
            {service.source_addresses.length > 1 && index + 1 + '.'} {address}
          </Text>
        ))}

      </Td>
      <Td>
        {service.source_tz ? (
          <Flex align="center">
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.board_date), timezoneOffset: service.source_tz }), 'dd/MM/yyyy')}
            {isSourceTzDifferentFromUser && (
              <Tooltip label={service.source_tz}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service.board_date, "DateWithoutHourToShow")
        )}
      </Td>
      <Td>
        {service.source_tz ? (
          <Flex align="center">
            {format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.board_hour), timezoneOffset: service.source_tz }), 'HH:mm')}
            {isSourceTzDifferentFromUser && (
              <Tooltip label={service.source_tz}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          formatDate.handle(service.board_hour, "DateOnlyWithHourMinute")
        )}
      </Td>
      <Td>
        {service.source_tz ? (
          <Flex align="center">
            <Flex flexDirection='column'>
              <Text>Embarque {service.shipping}</Text>
              <Text>{`${service.source_branch} -> ${service.destination_branch}`}</Text>
              <Text>{`até ${format(formatTimeByTimezoneUser({ serviceDateTime: new Date(service.board_hour), timezoneOffset: service.source_tz }), 'HH:mm')} `}</Text>
            </Flex>
            {isSourceTzDifferentFromUser && (
              <Tooltip label={service.source_tz}>
                <Box as="span" ml="2">
                  <Icon as={FaInfoCircle} />
                </Box>
              </Tooltip>
            )}
          </Flex>
        ) : (
          <Flex flexDirection='column'>
            <Text>Embarque {service.shipping}</Text>
            <Text>{`${service.source_branch} -> ${service.destination_branch}`}</Text>
            <Text>{`até  ${formatDate.handle(service.board_hour, "DateOnlyWithHourMinute")}`}</Text>
          </Flex>
        )}

      </Td>
      <Td>
        {service?.destination_addresses?.map((address, index) => (
          <Text w="300px" key={index} whiteSpace="pre-line">
            {service.destination_addresses.length > 1 && index + 1 + '.'} {address}
          </Text>
        ))}
      </Td>
      <Td>
        <Text w="500px" overflow="hidden" whiteSpace="pre-line">
          {service.observations !== '' ? service.observations : '-'}
        </Text>
      </Td>
      <Td>{service.source_collector}</Td>
      <Td>{service.destination_collector}</Td>
    </Tr>
  )
}

import { Box, Button, Flex, Heading, Select, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPmc } from "api/pmcs/createPmc";

import { useProductsByWarehouse } from "hooks/product/useProductsByWarehouse";
import { usePmcSocket } from "hooks/socket/usePmcSocket";
import { useToastify } from "hooks/toastify/useToastify";
import { useWarehouses } from "hooks/warehouse/useWarehouses";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { CreatePmcForm } from "./components/CreatePmcForm";

export interface CreatePmcSchema {
  warehouseId: string
  pmcs: {
    productId: string
    purchaseQuantity: number
    estimatedCost: string
  }[]
}

const createPmcSchema = yup.object({
  warehouseId: yup.string().required(),
  pmcs: yup.array().min(1).of(yup.object({
    purchaseQuantity: yup.number().required(),
  }))
})

export function CreatePmc() {
  const { push: redirect } = useHistory()

  const formProps = useForm<CreatePmcSchema>({
    resolver: yupResolver(createPmcSchema)
  })

  const {
    register,
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = formProps

  const {
    data: warehousesData,
    isFetching: isFetchingWarehousesData
  } = useWarehouses()

  const [warehouseId] = useWatch({
    name: ['warehouseId'],
    control,
  })

  const {
    data: productsByWarehouseData,
    isFetching: isFetchingProductsByWarehouseData
  } = useProductsByWarehouse({
    warehouseId: warehouseId,
    queryOptions: {
      enabled: !!warehouseId
    }
  })

  const { pmcSocketConnection } = usePmcSocket()

  const { mutateAsync: createPmcFn } = useMutation({
    mutationFn: createPmc,
    onSuccess(data) {
      pmcSocketConnection?.emit('pmcCreated', {
        pmcProtocol: data.pmc.protocol,
        profiles: data.notificationProfiles
      })

      redirect('/pmcs')
    },
  })

  const { promiseMessage } = useToastify()

  async function handleCreatePmc(data: CreatePmcSchema) {
    await promiseMessage(createPmcFn({
      warehouseId: data.warehouseId,
      body: {
        pmcs: data.pmcs
      }
    }), 'Pmc criada! 🎉')
  }

  return (

      <Box
        bg="white"
        borderRadius="md"
        p="6"
      >
        <Heading letterSpacing="tight">Solicitar PMC</Heading>

        <Flex
          direction="column"
          as="form"
          onSubmit={handleSubmit(handleCreatePmc)}
        >
          <Select
            {...register('warehouseId')}
            size="sm"
            placeholder="Selecione um almoxarifado"
            borderRadius="md"
            isDisabled={isFetchingWarehousesData}
            mt="6"
            w={["full", "full", "300px"]}
          >
            {warehousesData?.warehouses?.map((warehouse) => {
              return (
                <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
              )
            })}
          </Select>
          {isFetchingProductsByWarehouseData ? (
            <Spinner mt="6" />
          ) : (
            <TableContainer
              fontSize="xs"
              border="1px solid"
              borderColor="gray.200"
              mt="6"
              borderRadius="md"
            >
              <Table size="sm" fontSize="xs">
                <Thead>
                  <Tr
                    h="40px"
                    bg="#38c3fa"
                  >
                    <Th textColor="white">Produto</Th>
                    <Th textColor="white">Fornecedor</Th>
                    <Th textColor="white">Medida</Th>
                    <Th textColor="white">Estoque atual</Th>
                    <Th textColor="white">Estoque padrão</Th>
                    <Th textColor="white">Estoque mínimo</Th>
                    <Th textColor="white">Custo unitário</Th>
                    <Th textColor="white">Qtde. a comprar</Th>
                    <Th textColor="white">Custo estimado</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {productsByWarehouseData?.products.map((product, index) => {
                    return (
                      <Tr key={product.id}>
                        <Td>{product.material.material_name}</Td>
                        <Td>{product.generalProvider.trading_name}</Td>
                        <Td>{product.measure}</Td>
                        <Td>{product.current_stock}</Td>
                        <Td>{product.standard_stock}</Td>
                        <Td>{product.min_stock}</Td>
                        <Td>{(product.unit_cost / 100).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })}</Td>
                        <FormProvider {...formProps}>
                          <CreatePmcForm
                            fieldIndex={index}
                            product={product}
                          />
                        </FormProvider>
                      </Tr>
                    )
                  })}

                </Tbody>

              </Table>
            </TableContainer>
          )}

          {errors?.pmcs && (
            <Text
              textColor="red.400"
              fontSize="xs"
              alignSelf="flex-end"
            >
              Todos os campos são necessários para a solicitação!
            </Text>
          )}
          <Button
            type="submit"
            colorScheme="blue"
            size="sm"
            alignSelf="flex-end"
            mt="2"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Solicitar
          </Button>
        </Flex>
      </Box>

  )
}

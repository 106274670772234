import { Button, FormControl, FormLabel, IconButton, Input, Link, Progress, Stack, Td, Text, Tr } from "@chakra-ui/react"
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation"
import { ChangeEvent } from "react"
import { DeepMap, FieldError, UseFormRegister } from "react-hook-form"
import { FaExternalLinkAlt, FaTimes } from "react-icons/fa"
import { FaFileImport } from "react-icons/fa6"
import { PaymentAttachment, PayVacationSchema } from "./PayVacation"

interface PaymentAttachmentFieldProps {
  fieldId: string
  fieldIndex: number
  register: UseFormRegister<PayVacationSchema>
  errors: DeepMap<PayVacationSchema, FieldError>
  onAddPaymentAttachment: (values: PaymentAttachment) => void
  onRemovePaymentAttachment: (index: number, fieldId: string) => void
}

export function PaymentAttachmentField({
  fieldId,
  fieldIndex,
  register,
  errors,
  onAddPaymentAttachment,
  onRemovePaymentAttachment
}: PaymentAttachmentFieldProps) {
  const {
    mutation,
    uploadProggress
  } = useAttachmentMutation()

  async function handleUploadAdvertenceAttachment(
    event: ChangeEvent<HTMLInputElement>
  ) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    const data = await mutation.mutateAsync(formData)

    onAddPaymentAttachment({
      attachmentId: data.attachment.id,
      id: fieldId,
    })
  }

  // useEffect(() => {
  //   if (mutation.data) {
  //     onAddPaymentAttachment({
  //       attachmentId: mutation.data.attachment.id,
  //       id: fieldId,
  //     })
  //   }
  // }, [fieldId, mutation.data, onAddPaymentAttachment])

  return (
    <Tr
      position="relative"
      outline="1px solid"
      outlineColor="gray.200"
      rounded="xl"
    >
      <Td>

        <Text
          position="absolute"
          rounded="full"
          h="20px"
          w="20px"
          bg="blue.500"
          outline="2px solid"
          outlineColor="white"
          textAlign="center"
          fontSize="sm"
          textColor="white"
          top={-2}
          left={-2}
        >
          {fieldIndex + 1}
        </Text>
      </Td>
      <Td border="none">
        <Stack
          direction="column"
          w="full"
        >
          <Button
            as={FormLabel}
            htmlFor={`paymentAttachments.${fieldIndex}.attachment`}
            lineHeight="1"
            leftIcon={<FaFileImport />}
            size="sm"
            w="min"
            cursor="pointer"
            border={errors?.paymentAttachments && !!errors?.paymentAttachments[fieldIndex].attachment && '2px solid'}
            borderColor={errors?.paymentAttachments && !!errors?.paymentAttachments[fieldIndex].attachment && 'red.500'}
          >
            Anexar
          </Button>
          <FormControl isInvalid={errors?.paymentAttachments && !!errors?.paymentAttachments[fieldIndex].attachment}>
            <Input
              {...register(`paymentAttachments.${fieldIndex}.attachment`)}
              name={`paymentAttachments.${fieldIndex}.attachment`}
              id={`paymentAttachments.${fieldIndex}.attachment`}
              type="file"
              hidden
              onChangeCapture={handleUploadAdvertenceAttachment}
            />
          </FormControl>
        </Stack>
      </Td>
      <Td fontSize="xs" w="200px" border="none">
        {uploadProggress > 0 && (
          <Progress size="sm" rounded="md" value={uploadProggress} />
        )}
      </Td>
      <Td fontSize="xs" border="none" isNumeric>
        {mutation.data && (
          <IconButton
            aria-label="Visualizar anexo"
            as={Link}
            size="sm"
            icon={<FaExternalLinkAlt />}
            href={mutation.data.attachment.link}
            isExternal
          />
        )}
      </Td>

      <Td>
        <IconButton
          aria-label="Remover anexo"
          size="xs"
          rounded="full"
          icon={<FaTimes />}
          onClick={() => onRemovePaymentAttachment(fieldIndex, fieldId)}
          position="absolute"
          top={-2}
          right={-2}
        />
      </Td>
    </Tr>
  )
}

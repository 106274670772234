import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  useMediaQuery,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { EmptyContentTableAlert } from '../../Alerts/EmptyContentTableAlert'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { PriceProps } from '../../../utils/RequestFunctions/Price/requestPriceFunctions'
import { usePriceFunctions } from '../../../hooks/price/usePriceFunctions'
import { statesWithUfList } from '../../../utils/CustomLists/statesWithUfList'
import { GenerateExcelReportButton } from '../../Buttons/GenerateExcelReportButton'

interface IPriceTableProps {
  prices: PriceProps[]
}

const priceOptions = [
  { key: 'sourceCityUf', value: 'UF Cidade Origem', checked: false },
  { key: 'sourceCity', value: 'Cidade Origem', checked: false },
  { key: 'destinationCityUf', value: 'UF Cidade Destino', checked: false },
  { key: 'destinationCity', value: 'Cidade Destino', checked: false },
  { key: 'airMinimumPrice', value: 'Preço Mínimo Aéreo', checked: false },
  { key: 'airDeadline', value: 'Prazo Aéreo', checked: false },
  { key: 'airExtraKg', value: 'Extra KG Aéreo', checked: false },
  {
    key: 'highwayMinimumPrice',
    value: 'Preço Mínimo Rodoviário',
    checked: false,
  },
  { key: 'highwayDeadline', value: 'Prazo Rodoviário', checked: false },
  { key: 'highwayExtraKg', value: 'Extra KG Rodoviário', checked: false },
  { key: 'category', value: 'Categoria', checked: false },
  { key: 'airFranchising', value: 'Franquia Aérea', checked: false },
  { key: 'highwayFranchising', value: 'Franquia Rodoviário', checked: false },
]

const priceOrderOptions = [{ key: 'sourceCity', value: 'Cidade Origem' }]

const priceReportHeaders = [
  { label: 'CIDADE ORIGEM', key: 'source_city_id' },
  { label: 'HUB ORIGEM', key: 'source_hub_id' },
  { label: 'CIDADE DESTINO', key: 'destination_city_id' },
  { label: 'HUB DESTINO', key: 'destination_hub_id' },
  { label: 'PREÇO MÍNIMO AÉREO', key: 'air_minimum_price' },
  { label: 'KG EXTRA AÉREO', key: 'air_extra_kg' },
  { label: 'PRAZO AÉREO', key: 'air_deadline' },
  { label: 'FRANQUIA AÉREA', key: 'air_franchising' },
  { label: 'PREÇO MÍNIMO RODOVIÁRIO', key: 'highway_minimum_price' },
  { label: 'KG EXTRA RODOVIÁRIO', key: 'highway_extra_kg' },
  { label: 'PRAZO RODOVIÁRIO', key: 'highway_deadline' },
  { label: 'FRANQUIA RODOVIÁRIA', key: 'highway_franchising' },
  { label: 'PREÇO ADICIONAL COLETA', key: 'additional_collect_price' },
  { label: 'PREÇO ADICIONAL ENTREGA', key: 'additional_delivery_price' },
  { label: 'TIPO DE ROTA', key: 'route_type' },
  { label: 'CATEGORIA', key: 'category' },
  { label: 'VEÍCULO', key: 'vehicle' },
]

export function PriceTable({ prices }: IPriceTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  // SEARCHBOX FILTERS
  const [sourceCityUfFiltered, setSourceCityUfFiltered] = useState('')
  const [sourceCityFiltered, setSourceCityFiltered] = useState('')
  const [destinationCityUfFiltered, setDestinationCityUfFiltered] = useState('')
  const [destinationCityFiltered, setDestinationCityFiltered] = useState('')
  const [airMinimumPriceFiltered, setAirMinimumPriceFiltered] = useState('')
  const [airDeadlineFiltered, setAirDeadlineFiltered] = useState('')
  const [airExtraKgFiltered, setAirExtraKgFiltered] = useState('')
  const [highwayMinimumPriceFiltered, setHighwayMinimumPriceFiltered] =
    useState('')
  const [highwayDeadlineFiltered, setHighwayDeadlineFiltered] = useState('')
  const [highwayExtraKgFiltered, setHighwayExtraKgFiltered] = useState('')
  const [categoryFiltered, setCategoryFiltered] = useState('')
  // ---------------------------------------------------------------------------- //

  // HOOKS
  const {
    deletePrice: { mutateAsync: deletePrice },
  } = usePriceFunctions()
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  // ---------------------------------------------------------------------------- //

  const isAllInputsEmpty = [
    sourceCityFiltered,
    destinationCityFiltered,
    airMinimumPriceFiltered,
    airExtraKgFiltered,
    highwayMinimumPriceFiltered,
    highwayExtraKgFiltered,
    sourceCityUfFiltered,
    destinationCityUfFiltered,
    airDeadlineFiltered,
    highwayDeadlineFiltered,
    categoryFiltered
  ].every((e) => e === '')

  function formatPriceDataToReport(prices: PriceProps[]) {
    return prices.map((price) => {
      let airFranchising = '-'
      let highwayFranchising = '-'

      if (price.category === 'BIOLÓGICO') {
        if (['AÉREO', 'AMBOS'].includes(price.route_type)) {
          airFranchising = '2 KG'
        }

        if (['RODOVIÁRIO', 'AMBOS'].includes(price.route_type)) {
          highwayFranchising = '10 KG'
        }
      }

      if (['CARGA GERAL EMERGENCIAL', 'CARGA GERAL CONVENCIONAL'].includes(price.category)) {
        if (['AÉREO', 'AMBOS'].includes(price.route_type)) {
          airFranchising = '10 KG'
        }

        if (['RODOVIÁRIO', 'AMBOS'].includes(price.route_type)) {
          highwayFranchising = '10 KG'
        }
      }

      return {
        ...price,
        source_city_id: price.sourceCityIDPrice.name,
        destination_city_id: price.destinationCityIDPrice.name,
        source_hub_id: price.sourceHubIDPrice.name,
        destination_hub_id: price.destinationHubIDPrice.name,
        highway_extra_kg: price.highway_extra_kg ?? '-',
        highway_deadline: price.highway_deadline ?? '-',
        highway_minimum_price: price.highway_minimum_price ?? '-',
        air_extra_kg: price.air_extra_kg ?? '-',
        air_deadline: price.air_deadline ?? '-',
        air_minimum_price: price.air_minimum_price ?? '-',
        air_franchising: airFranchising,
        highway_franchising: highwayFranchising
      }
    })
  }

  const csvReportProps = {
    headers: priceReportHeaders,
    data: formatPriceDataToReport(prices),
    filename: 'relatório-precos.csv'
  }
  const pricesFilteredByUserInput = prices.filter(price => {
    const filterPricesByUserType =  userLogged.user_type === 'CLIENTE' ? price.situation === 'active' : price

    return filterPricesByUserType
  }
  ).filter((price) => {
    const sourceCityUfFilter = searchBoxFilter(
      Object.entries(statesWithUfList)
        .filter(
          ([key, value]) => key.toUpperCase() === price.sourceCityIDPrice.state,
        )
        .map(([key, value]) => value.uf)[0],
      sourceCityUfFiltered,
    )
    const sourceCityFilter = searchBoxFilter(
      price.sourceCityIDPrice.name,
      sourceCityFiltered,
    )
    const destinationCityUfFilter = searchBoxFilter(
      Object.entries(statesWithUfList)
        .filter(
          ([key, value]) =>
            key.toUpperCase() === price.destinationCityIDPrice.state,
        )
        .map(([key, value]) => value.uf)[0],
      destinationCityUfFiltered,
    )
    const destinationCityFilter = searchBoxFilter(
      price.destinationCityIDPrice.name,
      destinationCityFiltered,
    )
    const airMinimumPriceFilter = searchBoxFilter(
      price.air_minimum_price !== null ? String(price.air_minimum_price) : '-',
      airMinimumPriceFiltered,
    )
    const airDeadlineFilter = searchBoxFilter(
      price.air_deadline !== null ? String(price.air_deadline) : '-',
      airDeadlineFiltered,
    )
    const airExtraKgFilter = searchBoxFilter(
      price.air_extra_kg !== null ? String(price.air_extra_kg) : '-',
      airExtraKgFiltered,
    )
    const highwayMinimumPriceFilter = searchBoxFilter(
      price.highway_minimum_price !== null
        ? String(price.highway_minimum_price)
        : '-',
      highwayMinimumPriceFiltered,
    )
    const highwayDeadlineFilter = searchBoxFilter(
      price.highway_deadline !== null ? String(price.highway_deadline) : '-',
      highwayDeadlineFiltered,
    )
    const highwayExtraKgFilter = searchBoxFilter(
      price.highway_extra_kg !== null ? String(price.highway_extra_kg) : '-',
      highwayExtraKgFiltered,
    )
    const categoryFilter = searchBoxFilter(
      price.category,
      categoryFiltered,
    )

    if (isAllInputsEmpty) {
      return price
    }

    return (
      sourceCityFilter &&
      destinationCityFilter &&
      airMinimumPriceFilter &&
      airDeadlineFilter &&
      airExtraKgFilter &&
      highwayMinimumPriceFilter &&
      highwayDeadlineFilter &&
      highwayMinimumPriceFilter &&
      highwayExtraKgFilter &&
      sourceCityUfFilter &&
      categoryFilter &&
      destinationCityUfFilter
    )
  }).sort((a, b) => {
    if (a.situation === 'active' && b.situation !== 'active') {
      return -1;
    }
    if (a.situation !== 'active' && b.situation === 'active') {
      return 1;
    }
    return 0;
  })

  function sortPricesByOrderOptions(prices: PriceProps[]) {
    return prices.sort(
      (a, b) =>
        (orderOptionSelected.includes('sourceCity') &&
          a.sourceCityIDPrice.name.localeCompare(b.sourceCityIDPrice.name)) ||
        0,
    )
  }

  const isOrderOptionsSelected = orderOptionSelected.length > 0

  const searchedAndOrderedPrices = isOrderOptionsSelected
    ? sortPricesByOrderOptions(pricesFilteredByUserInput)
    : pricesFilteredByUserInput

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedAndOrderedPrices?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(priceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(priceOptions.filter(option => {
        return option.key !== 'airFranchising' && option.key !== 'highwayFranchising'
      }))
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'sourceCityUf':
        return setSourceCityUfFiltered(value)
      case 'sourceCity':
        return setSourceCityFiltered(value)
      case 'destinationCityUf':
        return setDestinationCityUfFiltered(value)
      case 'destinationCity':
        return setDestinationCityFiltered(value)
      case 'airMinimumPrice':
        return setAirMinimumPriceFiltered(value)
      case 'airDeadline':
        return setAirDeadlineFiltered(value)
      case 'airExtraKg':
        return setAirExtraKgFiltered(value)
      case 'highwayMinimumPrice':
        return setHighwayMinimumPriceFiltered(value)
      case 'highwayDeadline':
        return setHighwayDeadlineFiltered(value)
      case 'highwayExtraKg':
        return setHighwayExtraKgFiltered(value)
      case 'category':
        return setCategoryFiltered(value)
    }
  }

  async function handleDeletePrice(id: string) {
    const hasConfirmed = await confirmMessage({
      title: 'Deseja excluir um preço?',
    })

    if (hasConfirmed) {
      await deletePrice(id)
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (prices.length <= 0) {
    return (
      <EmptyContentTableAlert
        title="Oops!"
        description="Não há dados para mostrar aqui!"
      />
    )
  }

  return (
    <>
      <Flex
        mb="2"
        wrap="wrap"
        justify={isWideVersion ? 'space-between' : 'flex-start'}
      >
        <Box>
          <TableFilterButton />
        </Box>
        <Box w={isWideVersion ? '' : 'full'}>
          <Stack mt={isWideVersion ? '0' : '2'} direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
        </Box>
      </Flex>
      <Box overflowX="auto" w="full">
        <Table w="full" colorScheme="gray" variant="striped" size="sm">
          <Thead>
            <Tr>
              {priceOptions.map((option) => (
                <Th key={option.key} fontSize="12" color="blue.900">
                  {option.value.toUpperCase()}
                </Th>
              ))}
              <Th fontSize="12" color="blue.900">VEÍCULO</Th>
              <Th fontSize="12" color="blue.900">SITUAÇÃO</Th>
              <Th fontSize="12" color="blue.900">CRIADO POR</Th>
              <Th fontSize="12" color="blue.900">TIPO DE SERVIÇO</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchedAndOrderedPrices
              .slice(offset, offset + Number(itemLimit))
              .map((price) => {
                return (
                  <Tr key={price.id}>
                    <Td fontSize="12">
                      {
                        Object.entries(statesWithUfList)
                          .filter(
                            ([key, value]) =>
                              key.toUpperCase() === price.sourceCityIDPrice.state,
                          )
                          .map(([key, value]) => value.uf)[0]
                      }
                    </Td>
                    <Td fontSize="12">
                      {price.sourceCityIDPrice.name.toUpperCase()}
                    </Td>
                    <Td fontSize="12">
                      {
                        Object.entries(statesWithUfList)
                          .filter(
                            ([key, value]) =>
                              key.toUpperCase() ===
                              price.destinationCityIDPrice.state,
                          )
                          .map(([key, value]) => value.uf)[0]
                      }
                    </Td>
                    <Td fontSize="12">
                      {price.destinationCityIDPrice.name.toUpperCase()}
                    </Td>
                    <Td fontSize="12">
                      {price.air_minimum_price !== null
                        ? price.air_minimum_price
                        : '-'}
                    </Td>
                    <Td fontSize="12">
                      {price.air_deadline !== null
                        ? `D + ${price.air_deadline}`
                        : '-'
                      }
                    </Td>
                    <Td fontSize="12">
                      {price.air_extra_kg !== null ? price.air_extra_kg : '-'}
                    </Td>
                    <Td fontSize="12">
                      {price.highway_minimum_price !== null
                        ? price.highway_minimum_price
                        : '-'}
                    </Td>
                    <Td fontSize="12">
                      {price.highway_deadline !== null
                        ? `D + ${price.highway_deadline}`
                        : '-'
                      }
                    </Td>
                    <Td fontSize="12">
                      {price.highway_extra_kg !== null
                        ? price.highway_extra_kg
                        : '-'}
                    </Td>
                    <Td fontSize="12">
                      {price.category}
                    </Td>
                    <Td fontSize="12">
                      {
                        price.category === 'BIOLÓGICO'
                          ? price.route_type === "AÉREO" || price.route_type === "AMBOS"
                            ? '2 KG'
                            : '-'
                          : price.category === 'CARGA GERAL CONVENCIONAL' || price.category === 'CARGA GERAL EMERGENCIAL'
                            ? price.route_type === "AÉREO" || price.route_type === "AMBOS"
                              ? '10 KG'
                              : '-'
                            : '-'
                      }
                    </Td>
                    <Td fontSize="12">
                      {
                        price.category === 'BIOLÓGICO'
                          ? price.route_type === "RODOVIÁRIO" || price.route_type === "AMBOS"
                            ? '10 KG'
                            : '-'
                          : price.category === 'CARGA GERAL CONVENCIONAL' || price.category === 'CARGA GERAL EMERGENCIAL'
                            ? price.route_type === "RODOVIÁRIO" || price.route_type === "AMBOS"
                              ? '10 KG'
                              : '-'
                            : '-'
                      }
                    </Td>
                    <Td fontSize="12">{price.vehicle}</Td>
                    <Td fontSize="12">{price.situation === 'active' ? 'ATIVO' : 'INATIVO'}</Td>
                    <Td fontSize="12">{price.createdBy !== null ? price?.createdBy?.firstname + " " + price?.createdBy?.lastname : "-"}</Td>
                    <Td fontSize="12">{price.service_type}</Td>
                    <Td isNumeric>
                      {userLogged !== null && (
                        <>
                          {userLogged?.permissions.includes('view-price') && (
                            <Link to={`/preco/visualizar/${price.id}`}>
                              <Icon cursor="pointer" as={FiEye} fontSize="20" />
                            </Link>
                          )}
                          {userLogged?.permissions.includes('edit-price') && (
                            <Link to={`/preco/editar/${price.id}`}>
                              <Icon
                                cursor="pointer"
                                as={FiEdit3}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                              />
                            </Link>
                          )}
                          {userLogged?.permissions.includes('remove-price') && (
                            <Icon
                              cursor="pointer"
                              as={FiTrash2}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                              onClick={async () => handleDeletePrice(price.id)}
                            />
                          )}
                        </>
                      )}
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </Box>
      <GenerateExcelReportButton csvReportProps={csvReportProps} />
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}

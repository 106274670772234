import { CollectorCost } from "hooks/cost/dtos/CollectorCost"

export class ValidateDestinationCollectorVehicleHasCost {
  async handle(
    collectorCost: CollectorCost[],
    destinationCollectorId: string,
    vehicle: string,
  ) {
    const collectorCostsFilteredByDestinationCollectorId = collectorCost.filter(
      (cost) => cost.collector_id === destinationCollectorId,
    )
    if (vehicle === 'CARRO') {
      const collectorDontHaveCarCost = collectorCostsFilteredByDestinationCollectorId
        .map(cost => cost.cost_car).reduce((a, b) => a + b, 0) === 0


      if (
        !collectorCostsFilteredByDestinationCollectorId.length ||
        collectorDontHaveCarCost
      ) {
        return 'O coletador de destino não possui custo cadastrado para o tipo de veículo selecionado. Deseja Continuar?'
      }
    }
    if (vehicle === 'MOTO') {
      const collectorDontHaveMotorcycleCost = collectorCostsFilteredByDestinationCollectorId
        .map(cost => cost.cost_motorcycle).reduce((a, b) => a + b, 0) === 0

      if (
        !collectorCostsFilteredByDestinationCollectorId.length ||
        collectorDontHaveMotorcycleCost
      ) {
        return 'O coletador de destino não possui custo cadastrado para o tipo de veículo selecionado. Deseja Continuar?'
      }
    }
    if (vehicle === 'CAMINHÃO') {
      const collectorDontHaveTruckCost = collectorCostsFilteredByDestinationCollectorId
        .map(cost => cost.cost_truck).reduce((a, b) => a + b, 0) === 0

      if (
        !collectorCostsFilteredByDestinationCollectorId.length ||
        collectorDontHaveTruckCost
      ) {
        return 'O coletador de destino não possui custo cadastrado para o tipo de veículo selecionado. Deseja Continuar?'
      }
    }
  }
}

const validateDestinationCollectorVehicleHasCost =
  new ValidateDestinationCollectorVehicleHasCost()

export { validateDestinationCollectorVehicleHasCost }

import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useLocation,
} from "react-router-dom";
import { useContext, FC, useEffect, useRef } from "react";
import { Home } from "./pages/Dashboard/Home";
import { HubList } from "./pages/Hub/HubList";
import { HubAdd } from "./pages/Hub/HubAdd";
import { HubDetailEdit } from "./pages/Hub/HubDetailEdit";
import { CityAddDetailEdit } from "./pages/City/CityAddDetailEdit";
import { CityList } from "./pages/City/CityList";
import { CustomerAddDetailEdit } from "./pages/Customer/CustomerAddDetailEdit";
import { CollectorAddDetailEdit } from "./pages/Collector/ColletctorAddDetailEdit";
import { CollectorList } from "./pages/Collector/CollectorList";
import { DriverAddDetailEdit } from "./pages/Driver/DriverAddDetailEdit";
import { DriverList } from "./pages/Driver/DriverList";
import { ProviderAddDetailEdit } from "./pages/Provider/ProviderAddDetailEdit";
import { ProviderList } from "./pages/Provider/ProviderList";
import { CollectorCostAddDetailEdit } from "./pages/CollectorCost/CollectorCostAddDetailEdit";
import { CollectorCostList } from "./pages/CollectorCost/CollectorCostList";
import { ShippingAddDetailEdit } from "./pages/Shipping/ShippingAddDetailEdit";
import { ShippingList } from "./pages/Shipping/ShippingList";
import { BranchAddDetailEdit } from "./pages/Branch/BranchAddDetailEdit";
import { BranchList } from "./pages/Branch/BranchList";
import { PermissionAddDetailEdit } from "./pages/Permissions/PermissionsAddDetailsEdit";
import { PermissionList } from "./pages/Permissions/PermissionList";
import { UserAddDetailEdit } from "./pages/Users/UserAddDetailEdit";
import { UserList } from "./pages/Users/UserList";
import { BudgetAddDetailEdit } from "./pages/Budget/BudgetAddDetailEdit";
import { BudgetList } from "./pages/Budget/BudgetList";
import { ServiceList } from "./pages/Services/ServiceList";
import { ServiceEditDetailValidate } from "./pages/Services/ServiceEditDetailValidate";
import { RequestedServiceList } from "./pages/Services/Requested/RequestedServiceList";
import { CollectServiceAddress } from "./pages/Services/Collected/CollectServiceAddress";
import { BoardedServiceSelected } from "./pages/Services/Boarded/BoardedServiceSelected";
import { ValidadteServiceList } from "./pages/Services/Validate/ValidateServiceList";
import { ValidateService } from "./pages/Services/Validate/ValidateService";
import { AllocatedServiceList } from "./pages/Services/Allocate/AllocateServiceList";
import { AllocatedService } from "./pages/Services/Allocate/AllocatedService";
import { AvailableServiceList } from "./pages/Services/Available/AvailableServiceList";
import { AvailableService } from "./pages/Services/Available/AvailableService";
import { LandingService } from "./pages/Services/Landing/LandingService";
import { DeliveryServiceAddresses } from "./pages/Services/Delivery/DeliveryServiceAddresses";
import { DeliveryServiceAddressSelected } from "./pages/Services/Delivery/DeliveryServiceAddressSelected";
import { AuthContext } from "./contexts/AuthContext";
import { GeneralContentLoading } from "./components/Loading/GeneralContentLoading";
import { Settings } from "./pages/Settings/Settings";
import { Report } from "./pages/Report/Report";
import { DetailService } from "./pages/Services/Detail/DetailService";
import { EditServiceInProgress } from "./pages/Services/InProgress/Edit";
import { CollectedAddresses } from "./pages/Services/InProgress/collected/CollectedAddresses";
import { DeliveryAddresses } from "./pages/Services/InProgress/delivery/DeliveryAddresses";
import { InputAddDetailEdit } from "./pages/Input/InputAddDetailEdit";
import { InputList } from "./pages/Input/InputList";
import { StockAddDetailEdit } from "./pages/Stock/StockAddDetailEdit";
import { RecurrentServiceList } from "./pages/Services/Recurrent/RecurrentServiceList";
import { BranchProvider } from "./contexts/BranchContext";
import { ShippingProvider } from "./contexts/ShippingContext";
import { CustomerProvider } from "./contexts/CustomerContext";
import { ProviderContextProvider } from "./contexts/ProviderContext";
import { InputProvider } from "./contexts/InputContext";
import { PermissionProvider } from "./contexts/PermissionContext";
import { StockProvider } from "./contexts/StockContext";
import { UserProvider } from "./contexts/UserContext";
import { BudgetProvider } from "./contexts/BudgetContext";
import { AllocatedServiceProvider } from "./contexts/AllocateServiceContext";
import { RequestedServiceProvider } from "./contexts/ServiceContext";
import { CollectedServiceProvider } from "./contexts/CollectedServiceContext";
import { DeliveryServiceProvider } from "./contexts/DeliveryServiceContext";
import { ReportProvider } from "./contexts/ReportContext";
import { LogList } from "./pages/Log/LogList";
import { LogProvider } from "./contexts/LogContext";
import { ExecuteRecurrentService } from "./pages/Services/Recurrent/ExecuteRecurrentService";
import { StockAddSent } from "./pages/Stock/StockAddSent";
import { OccurrenceProceedAddDetailEdit } from "./pages/Occurences/Proceed/OccurrenceProceedAddDetailEdit";
import { OccurrenceProvider } from "./contexts/OccurrenceContext";
import { OccurrenceList } from "./pages/Occurences/List/OccurrenceList";
import { KnitSendFilePage } from "./pages/RouteNetworks/KnitSendFilePage";
import { KnitProvider } from "./contexts/KnitContext";
import { RouteNetworkList } from "./pages/RouteNetworks/RouteNetworkList";
import { LogFilter } from "./pages/Log/LogFilter";
import { BoardedServicesToEdit } from "./pages/Services/InProgress/boarded/BoardedServicesToEdit";
import { AllocatedServicesToEdit } from "./pages/Services/InProgress/allocated/AllocatedServicesToEdit";
import { AvailableServicesToEdit } from "./pages/Services/InProgress/available/AvailableServicesToEdit";
import { LandingServicesToEdit } from "./pages/Services/InProgress/landing/LandingServicesToEdit";
import { CreateRncPage } from "./pages/Rnc/CreateRncPage";
import { ValidateRncPage } from "./pages/Rnc/ValidateRncPage";
import { AnswerRncPage } from "./pages/Rnc/AnswerRncPage";
import { RecurrentServiceEditDetailPage } from "./pages/Services/Recurrent/RecurrentServiceEditDetailPage";
import { ToAnswerRncList } from "./pages/Rnc/Lists/ToAnswerRncList";
import { ToValidateRncList } from "./pages/Rnc/Lists/ToValidateRncList";
import { FinishedRncList } from "./pages/Rnc/Lists/FinishedRncList";
import { ToAlignRncList } from "./pages/Rnc/Lists/ToAlignRncList";
import { AlignRncPage } from "./pages/Rnc/AlignRncPage";
import { ValidateUnsuccessCollectedService } from "./pages/Services/Collected/ValidateUnsuccessCollectedService";
import { Help } from "./pages/Help/Help";
import { CreateDetailEditHoliday } from "./pages/Holiday/CreateDetailEditHoliday";
import { HolidayList } from "./pages/Holiday/HolidayList";
import { RecurrentServiceAddPage } from "./pages/Services/Recurrent/RecurrentServiceAddPage";
import { ValidateAddressToCollect } from "./pages/Services/Collected/ValidateAddressToCollect";
import { UserUpdatePermissions } from "./pages/Users/UserUpdatePermissions";
import { StockSendCsvPage } from "./pages/Stock/StockSendCsvPage";
import { CreateDetailEditProfilePage } from "./pages/Profile/CreateDetailEditProfilePage";
import { ProfileList } from "./pages/Profile/ProfileList";
import { CreateDetailEditRequestedUser } from "./pages/RequestedUser/CreateDetailEditRequestedUser";
import { RequestedUserList } from "./pages/RequestedUser/RequestedUserList";
import { CreateDetailEditPrice } from "./pages/Price/CreateDetailEditPrice";
import { ConsultPrice } from "./pages/Price/ConsultPrice";
import { PriceList } from "./pages/Price/PriceList";
import { CreateDetailEditBusinessBudget } from "./pages/BusinessBudget/CreateDetailEditBusinessBudget";
import { BusinessBudgetList } from "./pages/BusinessBudget/BusinessBudgetList";
import { CreateDetailEditRequestedBusinessService } from "./pages/Services/RequestedBusiness/CreateDetailEditRequestedBusinessService";
import { CollectBusinessServiceList } from "./pages/Services/CollectBusiness/CollectBusinessServiceList";
import { CollectBusinessServiceAddresses } from "./pages/Services/CollectBusiness/CollectBusinessServiceAddresses";
import { CollectBusinessServiceAddressSelected } from "./pages/Services/CollectBusiness/CollectBusinessServiceAddressSelected";
import { DetailBusinessService } from "./pages/Services/Detail/DetailBusinessService";
import { DeliveryBusinessServiceList } from "./pages/Services/DeliveryBusiness/DeliveryBusinessServiceList";
import { DeliveryBusinessServiceAddresses } from "./pages/Services/DeliveryBusiness/DeliveryBusinessServiceAddresses";
import { DeliveryBusinessServiceAddressSelected } from "./pages/Services/DeliveryBusiness/DeliveryBusinessServiceAddressSelected";
import { CollectedBusinessAddresses } from "./pages/Services/InProgress/collected/CollectedBusinessAddresses";
import { EditBusinessServiceInProgress } from "./pages/Services/InProgress/EditBusines";
import { DeliveryBusinessAddresses } from "./pages/Services/InProgress/delivery/DeliveryBusinessAddresses";
import { GenerateCustomerAccountPage } from "./pages/Account/GenerateCustomerAccountPage";
import { RecoverPasswordPage } from "./pages/RecoverPassword/RecoverPasswordPage";
import { CTELoglifeSendFilePage } from "./pages/Cte/CTELoglifeSendFilePage";
import { CTEComplementarySendFilePage } from "./pages/Cte/CTEComplementarySendFilePage";
import { PriceSendFilePage } from "./pages/Price/PriceSendFilePage";
import { UploadPdfPage } from "./pages/Pdf/UploadPdfPage";
import { SendFileToAssignPdfAndServicePage } from "./pages/Pdf/SendFileToAssignPdfAndServicePage";
import { RecurrentRequestedBusinessServiceList } from "./pages/Services/RequestedBusiness/RecurrentRequestedBusinessServiceList";
import { KanbanViewServicesPage } from "./pages/Services/KanbanView/KanbanViewServicesPage";
import { GeloSecoMaintenanceList } from "./pages/Services/GeloSecoMaintenance/GeloSecoMaintenanceList";
import { CreateGeloSecoMaintenancePage } from "./pages/Services/GeloSecoMaintenance/CreateGeloSecoMaintenancePage";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallbackPage } from "./pages/Error/ErrorFalbackPage";
import { StartAddressCollectService } from "./pages/Services/Collected/AddressCollect/StartAddressCollectService";
import { StartProviderCollectService } from "./pages/Services/Collected/ProviderCollect/StartProviderCollectService";
import { FinishAddressCollectService } from "./pages/Services/Collected/AddressCollect/FinishAddressCollectService";
import { FinishProviderCollectService } from "./pages/Services/Collected/ProviderCollect/FinishProviderCollectService";
import { EditAddressCollectService } from "./pages/Services/Collected/AddressCollect/EditAddressCollectService";
import { EditProviderCollectService } from "./pages/Services/Collected/ProviderCollect/EditProviderCollectService";
import { ConsultBusinessService } from "./pages/Services/Consult/BusinessService";
import { ConsultExpressService } from "./pages/Services/Consult/ExpressService";
import { ReportPage } from "./pages/Report/ReportPage";
import { UnsuccessCollectsPendingValidationTable } from "./pages/Services/Collected/UnsuccessCollectsPendingValidationTable";
import { RequestService } from "./pages/Services/Request/RequestService";
import { AddressesTable } from "pages/Address/AddressesTable";
import { CreateAddress } from "pages/Address/CreateAddress";
import { EditViewAddress } from "pages/Address/EditViewAddress";
import { ServicesWithNewAddressesList } from "pages/Services/Collected/ServicesWithNewAddressesList";
import { ValidateRequestedService } from "pages/Services/ValidateRequested/ValidateRequestedService";
import { CreateOccurrence } from "pages/Occurences/CreateOccurrence";
import { CreateMaterialPage } from "pages/Material/CreateMaterialPage";
import { MaterialList } from "pages/Material/MaterialList";
import { EditMaterialPage } from "pages/Material/EditMaterialPage";
import { CollectAddressesAndServicesRequestedToCancel } from "pages/Services/Collected/PendingValidations/CollectAddressesAndServicesRequestedToCancel";
import { ServiceRequestedToCancel } from "pages/Services/PendingValidations/ServiceRequestedToCancel";
import { MaintenanceServiceList } from "pages/Services/Maintenance/MaintenanceServiceList";
import { StartMaintenanceServicePage } from "pages/Services/Maintenance/StartMaintenanceServicePage";
import { FinishMaintenanceService } from "pages/Services/Maintenance/FinishMaintenanceService";
import { MaintenanceReport } from "pages/Report/MaintenanceReport";
import { OccurrenceReport } from "pages/Report/OccurrenceReport";
import { CreateNotificationType } from "pages/NotificationType/CreateNotificationType";
import { NotificationTypesList } from "pages/NotificationType/NotificationTypesList";
import { UpdateNotificationType } from "pages/NotificationType/UpdateNotificationType";
import { InternComunicationReport } from "pages/Report/InternComunicationReport";
import { FiscalRetentionList } from "pages/Services/FiscalRetention/FiscalRetentionList";
import { ValidateFiscalRetention } from "pages/Services/FiscalRetention/ValidateFiscalRetention";

import { CreateAggregatePage } from "pages/Aggregate/CreateAggregatePage";
import { EditViewAggregatePage } from "pages/Aggregate/EditViewAggregatePage";
import { AggregateList } from "pages/Aggregate/AggregateList";
import { ActivateAggregatesPage } from "pages/Aggregate/ActivateAggregatesPage";
import { DismissalAggregatePage } from "pages/Aggregate/DismissalAggregatePage";
import { InactivateAggregatePage } from "pages/Aggregate/InactivateAggregatePage";

import { AwaitingAlignmentOccurrencesList } from "pages/Occurences/AwaitingAlignmentOccurrencesList";
import { AlignOccurrence } from "pages/Occurences/AlignOccurrence";
import { AttachOccurrenceEvidences } from "pages/Occurences/AttachOccurrenceEvidences";
import { AwaitingAttachmentEvidencesOccurrencesList } from "pages/Occurences/AwaitingAttachmentEvidencesOccurrencesList";
import { FinishedCustomerOccurrencesList } from "pages/Occurences/FinishedCustomerOccurrencesList";
import { UnsuccessBoardServicesToValidate } from "pages/Services/Boarded/UnsuccessBoardServicesToValidate";
import { AggregatesReport } from "pages/Report/AggregatesReport";
import { ScheduleReport } from "pages/Report/ScheduleReport";
import { CostReport } from "pages/Report/CostReport";
import { UsageReport } from "pages/Report/UsageReport";
import { CreateThirdPartyRnc } from "pages/Rnc/CreateThirdPartyRnc";
import { AwaitingAnswerThirdPartyRncsList } from "pages/Rnc/AwaitingAnswerThirdPartyRncsList";
import { AwaitingValidationThirdPartyRncsList } from "pages/Rnc/AwaitingValidationThirdPartyRncs";
import { FinalizedThirdPartyRncsList } from "pages/Rnc/FinalizedThirdPartyRncs";
import { RegisterCltDriver } from "pages/CltDrivers/RegisterCltDriver";
import { EditCltDriver } from "pages/CltDrivers/EditCltDriver";
import { InProgressServicesList } from "pages/Services/InProgressServicesList";
import { InCollectServicesList } from "pages/Services/Collected/InCollectServicesList";
import { InDeliveryServicesList } from "pages/Services/Delivery/InDeliveryServicesList";
import { InBoardServicesList } from "pages/Services/Boarded/InBoardServicesList";
import { InLandingServicesList } from "pages/Services/Landing/InLandingServicesList";
import { CreateExtrasAndDiscounts } from "pages/Financial/CreateExtrasAndDiscounts";
import { ViewExtrasAndDiscounts } from "pages/Financial/ViewExtrasAndDiscounts";
import { ExtrasAndDiscountsList } from "pages/Financial/ExtrasAndDiscountsList";
import { ClosedExtrasAndDiscountsList } from "pages/Financial/ClosedExtrasAndDiscountsList";
import { ESGReport } from "pages/Report/ESGReport";
import { ProductivityReport } from "pages/Report/ProductivityReport";
import { CreateFinancialClassificationPage } from "pages/FinancialClassification/CreateFinancialClassificationPage";
import { EditFinancialClassificationPage } from "pages/FinancialClassification/EditFinancialClassificationPage";
import { FinancialClassificationList } from "pages/FinancialClassification/FinancialClassificationList";
import { GeneralProviderList } from "pages/GeneralProvider/GeneralProviderList";
import { CreateGeneralProviderPage } from "pages/GeneralProvider/CreateGeneralProviderPage";
import { EditGeneralProviderPage } from "pages/GeneralProvider/EditGeneralProviderPage";
import { CreateWarehousePage } from "pages/Warehouse/CreateWarehousePage";
import { EditWarehousePage } from "pages/Warehouse/EditWarehousePage";
import { WarehouseList } from "pages/Warehouse/WarehouseList";
import { CreateCrm } from "pages/Crms/CreateCrm";
import { CrmsList } from "pages/Crms/CrmsList";
import { ViewCrm } from "pages/Crms/ViewCrm";
import { CreatePurchaseOrderPmcPage } from "pages/Pmc/CreatePurchaseOrderPmcPage";
import { CreatePurchaseOrder } from "pages/PurchaseOrder/CreatePurchaseOrder";
import { PurchaseOrdersAwaitingQuote } from "pages/PurchaseOrder/PurchaseOrdersAwaitingQuote";
import { EditValidatedRequestedService } from "pages/Services/ValidateRequested/EditValidatedRequestedService";
import { QuotePurchaseOrder } from "pages/PurchaseOrder/QuotePurchaseOrder";
import { PurchaseOrdersAwaitingApprove } from "pages/PurchaseOrder/PurchaseOrdersAwaitingApprove";
import { ApprovePurchaseOrder } from "pages/PurchaseOrder/ApprovePurchaseOrder";
import { PurchaseOrdersAwaitingBuy } from "pages/PurchaseOrder/PurchaseOrdersAwaitingBuy";
import { PurchaseOrdersAwaitingInvoice } from "pages/PurchaseOrder/PurchaseOrdersAwaitingInvoice";
import { PurchaseOrdersAwaitingArrival } from "pages/PurchaseOrder/PurchaseOrdersAwaitingArrival";
import { ValidatePurchaseOrder } from "pages/PurchaseOrder/ValidatePurchaseOrder";
import { PurchaseOrdersArrived } from "pages/PurchaseOrder/PurchaseOrdersArrived";
import { ViewPurchaseOrder } from "pages/PurchaseOrder/ViewPurchaseOrder";
import { CreateCollectorExtraDiscount } from "pages/Collector/CreateCollectorExtraDiscount";
import { CltDriversExtrasDiscountsList } from "pages/CltDrivers/CltDriversExtrasDiscountsList";
import { CreateCltDriverExtraDiscount } from "pages/CltDrivers/CreateCltDriverExtraDiscount";
import { CreateAggregateExtraDiscount } from "pages/Aggregate/CreateAggregateExtraDiscount";
import { AlignInternRnc } from "pages/Rnc/AlignInternRnc";
import { SendRncAlignmentAttachments } from "pages/Rnc/SendRncAlignmentAttachments";
import { AwaitingAttachmentRncsList } from "pages/Rnc/Lists/AwaitingAttachmentRncsList";
import { Login } from "pages/Login/Login";

import { ValidateNewServiceAddressesPage } from "pages/Services/ValidateNewServiceAddresses/ValidateNewServiceAddressesPage";
import { CrmReport } from "pages/Report/CrmReport";
import { CreateRSM } from "pages/MaterialRequests/CreateMaterialRequest";
import { MaterialRequestsStatusTableButtonProvider } from "contexts/MaterialRequestsStatusTableButtonContext";
import { MaterialRequestsList } from "pages/MaterialRequests/MaterialRequestsList";
import { CltDriversExtrasDiscountsStatusProvider } from "contexts/CltDriversExtrasDiscountsStatusContext";
import { CollectorReport } from "pages/Report/CollectorReport";
import { OccurrenceDetailsPage } from "pages/Occurences/OccurrenceDetailsPage";
import { ConsultOccurrences } from "pages/Occurences/ConsultOccurrences"; import { CreateCollectorDispatchStockProposal } from "pages/Stock/Sent/CreateRequestMaterialsSend";
import { useAuth } from "hooks/auth/useAuth";
import { useDisclosure } from "@chakra-ui/react";
import { UpdateCollectorExtraDiscount } from "pages/Collector/UpdateCollectorExtraDiscount";
import { UpdateCltDriverExtraDiscount } from "pages/CltDrivers/UpdateCltDriverExtraDiscount";
import { UpdateAggregateExtraDiscount } from "pages/Aggregate/UpdateAggregateExtraDiscount";
import { UpdateExtrasAndDiscounts } from "pages/Financial/UpdateExtraAndDiscount";
import { BusinessServices } from "pages/Services/RequestedBusiness/BusinessServices";
import { BugsList } from "pages/Bugs/BugsList";
import { BugReport } from "pages/Report/BugReport";
import { ExtrasAndDiscountsReport } from "pages/Report/ExtrasAndDiscountsReport";
import { ClientManagementReport } from "pages/Report/ClientManagementReport";
import { CreateRegion } from "pages/Region/CreateRegion";
import { EditViewRegion } from "pages/Region/EditViewRegion";
import { RegionList } from "pages/Region/RegionList";
import { CreateEmployeeCost } from "pages/EmployeeCosts/CreateEmployeeCost";
import { EmployeeCostsList } from "pages/EmployeeCosts/EmployeeCostsList";
import { EditViewEmployeeCost } from "pages/EmployeeCosts/EditViewEmployeeCost";
import { RNCReport } from "pages/Report/RNCReport";
import { CreateBillings } from "pages/Financial/CreateBillings";
import { CreateMonitoringUsers } from "pages/Users/CreateMonitoringUsers";
import { EditViewMonitoringUsers } from "pages/Users/EditViewMonitoringUsers";
import { PurchaseOrdersRefused } from "pages/PurchaseOrder/PurchaseOrdersRefused";
import { LoadRatioServices } from "pages/Services/LoadRatio/LoadRatioServices";
import { CreateShippingCost } from "pages/Shipping/CreateShippingCost";
import { ShippingCosts } from "pages/Shipping/ShippingCosts";
import { EditViewShippingCosts } from "pages/Shipping/EditViewShippingCosts";
import { Collects } from "pages/Collect/Collects";
import { Pmcs } from "pages/Pmc/Pmcs";
import { CreatePmc } from "pages/Pmc/CreatePmc";
import { Billings } from "pages/Financial/Billings";
import { ServicesValidationsRealTimes } from "pages/Services/ValidateRealTime/ServicesValidationsRealTimes";
import { Asos } from "pages/Asos/Asos";
import { Boards } from "pages/Board/Boards";
import { Vaccines } from "pages/Vaccine/Vaccines";
import { AsosReport } from "pages/Asos/AsosReport";
import { Trainings } from "pages/Training/Trainings";
import { TrainingsReport } from "pages/Training/TrainingsReport";
import { RequestChangesAddress } from "pages/Address/RequestChangesAddress";
import { AddressesWithRequestedChanges } from "pages/Address/AddressesWithRequestedChanges";
import { VaccinesReport } from "pages/Vaccine/VaccinesReport";
import { PurchaseOrders } from "pages/PurchaseOrder/PurchaseOrders";
import { Deliveries } from "pages/Delivery/Deliveries";
import { BusinessCosts } from "pages/BusinessCosts/BusinessCosts";
import { CreateBusinessCost } from "pages/BusinessCosts/CreateBusinessCost";
import { EditBusinessCost } from "pages/BusinessCosts/EditBusinessCost";
import { TrainingsLlm } from "pages/TrainingLlm/TrainingsLlm";
import { TrainingsLlmReport } from "pages/TrainingLlm/TrainingsLlmReport";
import { InternComunications } from "pages/InternComunications/InternComunications";
import { CreateStandardization } from "pages/Standardization/CreateStandardization";
import { Standardizations } from "pages/Standardization/Standardizations";
import { CorrectStandardization } from "pages/Standardization/CorrectStandardization";
import { StandardizationsReport } from "pages/Standardization/StandardizationsReport";
import { NewMatrizDashboard } from "pages/Dashboard/MatrizDashboard";
import { CollectServicesWithDelayOccurrence } from "pages/Dashboard/Llm/CollectServicesWithDelayOccurrence";
import { DeliveryServicesWithDelayOccurrence } from "pages/Dashboard/Llm/DeliveryServicesWithDelayOccurrence";
import { DelayedOrNextToDelayCollectServices } from "pages/Dashboard/Llm/DelayedOrNextToDelayCollectServices";
import { DelayedOrNextToDelayDeliveryServices } from "pages/Dashboard/Llm/DelayedOrNextToDelayDeliveryServices";
import { DelayedBoards } from "pages/Dashboard/All/DelayedBoards";
import { UnsuccessCollectsPendingValidation } from "pages/Dashboard/Llm/UnsuccessCollectsPendingValidation";
import { ServicesWithNewCollectAddresses } from "pages/Dashboard/Llm/ServicesWithNewCollectAddreses";
import { UnfinishedWithDelayOccurrenceServices } from "pages/Dashboard/Llm/UnfinishedWithDelayOccurrenceServices";
import { ToBoardValidateServices } from "pages/Dashboard/Llm/ToBoardValidateServices";
import { ToAllocateServices } from "pages/Dashboard/Llm/ToAllocateServices";
import { ServicesRequestedToCancel } from "pages/Dashboard/Llm/ServicesRequestedToCancel";
import { ServicesWithCollectAddressesRequestedToCancel } from "pages/Dashboard/Llm/ServicesWithCollectAddressesRequestedToCancel";
import { StocksUnderMinimum } from "pages/Dashboard/All/StocksUnderMinimum";
import { ProductsWithOrUnderMinimumStock } from "pages/Dashboard/Llm/ProductsWithOrUnderMinimumStock";
import { NewCollectorDashboard } from "pages/Dashboard/CollectorDashboard";
import { DelayedLandings } from "pages/Dashboard/Collector/DelayedLandings";
import { NewDriverDashboard } from "pages/Dashboard/DriverDashboard";
import { NewCustomerDashboard } from "pages/Dashboard/CustomerDashboard";

import { Collects as DashboardCustomerCollects } from "pages/Dashboard/Customer/Collects"
import { Boards as DashboardCustomerBoards } from "pages/Dashboard/Customer/Boards"
import { Landings as DashboardCustomerLandings } from "pages/Dashboard/Customer/Landings"
import { Deliveries as DashboardCustomerDeliveries } from "pages/Dashboard/Customer/Deliveries"
import { DispatchStocks } from "pages/DispatchStock/DispatchStocks";
import { CreateDispatchStock } from "pages/DispatchStock/CreateDispatchStock";
import { EditDispatchStock } from "pages/DispatchStock/EditDispatchStock";
import { DispatchStocksReport } from "pages/DispatchStock/DispatchStocksReport";
import { GollogTracking } from "pages/Tracking/GollogTracking";
import { Test } from "pages/test";
import { AppLayout } from "_layouts/AppLayout";
import { Notifications } from "pages/Notifications/Notifications";
import { DispatchStockProposals } from "pages/Stock/Sent/DispatchStockProposals";
import { DelayedBoardValidations } from "pages/Dashboard/Llm/DelayedBoardValidations";
import { CustomerEmailList } from "pages/Customer/CustomerEmailList";
import { ServiceListInProgress } from "pages/Services/ServiceListInProgress";
import { ValidatePendingLandingServices } from "pages/Services/ValidatePendingLandingServices/ValidatePendingLandingServices";
import { DeliveriesWithoutQRCodePendingValidation } from "pages/Services/Delivery/DeliveriesWithoutQRCodePendingValidation";
import { CreateCollectorDispatchStockProposalManually } from "pages/Stock/Sent/CreateRequestMaterialsSendManually";
import { Onboardings } from "pages/Onboarding/Onboardings";
import { OnboardingsReport } from "pages/Onboarding/OnboardingsReport";
import { PatrimonyTypes } from "pages/Patrimony/PatrimonyTypes";
import { Patrimonies } from "pages/Patrimony/Patrimonies";
import { PatrimonyRequests } from "pages/Patrimony/PatrimonyRequests";
import { ServiceUnsuccessPendingValidateList } from "pages/Services/ServiceUnsuccessList";
import { ServiceValidateWithoutQrList } from "pages/Services/ServiceValidateWithoutQrList";
import { CltIntercurrences } from "pages/CltIntercurrences/CltIntercurrences";
import { CreateCltIntercurrence } from "pages/CltIntercurrences/CreateCltIntercurrence";
import { BusinessServiceSchedule } from "pages/Services/Business/BusinessServiceSchedule";
import { Advertences } from "pages/Advertence/Advertences";
import { AdvertencesReport } from "pages/Advertence/AdvertencesReport";
import { ValidateServicesWithStepChange } from "pages/Services/ValidateServicesWithStepChange/ValidateServicesWithStepChange";
import { Stocks } from "pages/Stock/Stocks";
import { InternClts } from "pages/InternClt/InternClts";
import { CreateInternClt } from "pages/InternClt/CreateInternClt";
import { EditInternClt } from "pages/InternClt/EditInternClt";
import { Documents } from "pages/Document/Documents";
import { DocumentsReport } from "pages/Document/DocumentsReport";
import { Customers } from "pages/Customer/Customers";
import { ExperiencePeriods } from "pages/ExperiencePeriods/ExperiencePeriods";
import { Vacations } from "pages/Vacations/Vacations";
import { Carriers } from "pages/Carriers/Carriers";
import { CarriersReport } from "pages/Carriers/CarriersReport";
import { DetailServiceV2 } from "pages/Services/DetailService";
import { Regionals } from "pages/Regionals/Regionals";
import { Audits } from "pages/Audits/Audits";
import { InternCltsOffices } from "pages/internCltsOffices/InternCltsOffices";
import { AuditsReport } from "pages/Audits/AuditsReport";
import { Refunds } from "pages/Refund/Refunds";
import { Sporadics } from "pages/Sporadic/Sporadics";
import { SporadicsTable } from "pages/Sporadic/SporadicsTable";
import { CltDrivers } from "pages/CltDrivers/CltDrivers";
import { Supports } from "pages/Support/Supports";
import { ShippingsCostsReport } from "pages/Report/ShippingsCostsReport";
import { SupportsSubjects } from "pages/Support/SupportsSubjects";
import { PassiveFeedbacksTable } from "pages/PassiveFeedbacks/PassiveFeedbacksTable";
import { PassiveFeedbacks } from "pages/PassiveFeedbacks/PassiveFeedbacks";
import { ActivesFeedbacks } from "pages/ActiveFeedbacks/ActivesFeedbacks";
import { ActiveFeedbacksTable } from "pages/ActiveFeedbacks/ActiveFeedbacksTable";
import { Contingencies } from "pages/Contingencies/Contigencies";
import { Products } from "pages/Product/Products";
import { ServicesInDelayModal } from "components/Modal/Service/ServiceInDelay/ServicesInDelayModal";
import { ServicesInDelayTriggerButton } from "components/Modal/Service/ServiceInDelay/components/ServicesInDelayTriggerButton";
import { useQuery, useQueryClient } from "react-query";
import { getDelayedDeliveries, GetDelayedDeliveriesResponse } from "api/service/getDelayedDeliveries";
import { getDelayedCollects, GetDelayedCollectsResponse } from "api/service/getDelayedCollects";
import { toast } from "react-toastify";
import { AggregatesExtrasDiscounts } from "pages/Aggregate/AggregatesExtrasDiscounts";
import { TrainingsKanban } from "pages/Training/TrainingsKanban";
import { AuditsKanban } from "pages/Audits/AuditsKanban";
import { AdvertencesKanban } from "pages/Advertence/AdvertencesKanban";
import { CollectorsExtrasDiscounts } from "pages/Collector/CollectorsExtrasDiscounts";
import { AsosKanban } from "pages/Asos/AsosKanban";
import { CltIntercurrencesKanban } from "pages/CltIntercurrences/CltIntercurrencesKanban";
import { StandardizationsKanban } from "pages/Standardization/StandardizationsKanban";
import { VaccinesKanban } from "pages/Vaccine/VaccinesKanban";
import { DocumentsKanban } from "pages/Document/DocumentsKanban";
import { TrainingsLlmKanban } from "pages/TrainingLlm/TrainingsLlmKanban";
import { VacationsKanban } from "pages/Vacations/VacationsKanban";
import { OnboardingsKanban } from "pages/Onboarding/OnboardingsKanban";
import { ExperiencePeriodsKanban } from "pages/ExperiencePeriods/ExperiencePeriodsKanban";

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean;
  customPathname?: string;
}

const CustomRoute: FC<CustomRouteProps> = ({
  isPrivate,
  customPathname,
  ...rest
}) => {
  const { pathname } = useLocation();
  const { authenticated, loading } = useContext(AuthContext);

  if (!loading) {
    if (isPrivate && !authenticated) {
      return <Redirect to="/login" />;
    }

    if (rest.path === "/login" && authenticated) {
      return <Redirect to="/" />;
    }

    if (customPathname === "hub") {
      return <Route {...rest} />;
    }

    if (customPathname === "branch") {
      return (
        <BranchProvider pathname={pathname}>
          <Route {...rest} />
        </BranchProvider>
      );
    }

    if (customPathname === "shipping") {
      return (
        <ShippingProvider pathname={pathname}>
          <Route {...rest} />
        </ShippingProvider>
      );
    }

    if (customPathname === "requestedValidateDashboard") {
      return (
        <RequestedServiceProvider pathname={pathname}>
          <Route {...rest} />
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "customer") {
      return (
        <CustomerProvider pathname={pathname}>
          <Route {...rest} />
        </CustomerProvider>
      );
    }

    if (customPathname === "city") {
      return <Route {...rest} />;
    }
    if (customPathname === "city_list") {
      return (
        <LogProvider>
          <Route {...rest} />
        </LogProvider>
      );
    }

    if (customPathname === "permission") {
      return (
        <PermissionProvider pathname={pathname}>
          <Route {...rest} />
        </PermissionProvider>
      );
    }

    if (customPathname === "provider") {
      return (
        <ProviderContextProvider pathname={pathname}>
          <Route {...rest} />
        </ProviderContextProvider>
      );
    }

    if (customPathname === "driver") {
      return <Route {...rest} />;
    }

    if (customPathname === "input") {
      return (
        <InputProvider pathname={pathname}>
          <Route {...rest} />
        </InputProvider>
      );
    }

    if (customPathname === "in-progress") {
      return (
        <RequestedServiceProvider pathname={pathname}>
          <Route {...rest} />
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "consult") {
      return <Route {...rest} />;
    }

    if (customPathname === "report") {
      return (
        <ReportProvider pathname={pathname}>
          <Route {...rest} />
        </ReportProvider>
      );
    }

    if (customPathname === "budget") {
      return (
        <BudgetProvider pathname={pathname}>
          <Route {...rest} />
        </BudgetProvider>
      );
    }

    if (customPathname === "stock") {
      return (
        <StockProvider pathname={pathname}>
          <Route {...rest} />
        </StockProvider>
      );
    }

    if (customPathname === "user") {
      return (
        <UserProvider pathname={pathname}>
          <Route {...rest} />
        </UserProvider>
      );
    }

    if (customPathname === "allocate") {
      return (
        <AllocatedServiceProvider pathname={pathname}>
          <LogProvider>
            <Route {...rest} />
          </LogProvider>
        </AllocatedServiceProvider>
      );
    }

    if (customPathname === "collected") {
      return (
        <RequestedServiceProvider pathname={pathname}>
          <CollectedServiceProvider pathname={pathname}>
            <LogProvider>
              <Route {...rest} />
            </LogProvider>
          </CollectedServiceProvider>
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "collected_addresses") {
      return (
        <CollectedServiceProvider pathname={pathname}>
          <RequestedServiceProvider pathname={pathname}>
            <LogProvider>
              <Route {...rest} />
            </LogProvider>
          </RequestedServiceProvider>
        </CollectedServiceProvider>
      );
    }

    if (customPathname === "boarded") {
      return (
        <LogProvider>
          <Route {...rest} />
        </LogProvider>
      );
    }

    if (customPathname === "board_validate_form") {
      return (
        <LogProvider>
          <Route {...rest} />
        </LogProvider>
      );
    }

    if (customPathname === "general_services_list") {
      return (
        <BranchProvider pathname={pathname}>
          <RequestedServiceProvider pathname={pathname}>
            <Route {...rest} />
          </RequestedServiceProvider>
        </BranchProvider>
      );
    }

    if (customPathname === "service_list") {
      return <Route {...rest} />;
    }

    if (customPathname === "delivery_services_list") {
      return (
        <RequestedServiceProvider pathname={pathname}>
          <Route {...rest} />
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "validate") {
      return (
        <BranchProvider pathname={pathname}>
          <ShippingProvider pathname={pathname}>
            <RequestedServiceProvider pathname={pathname}>
              <Route {...rest} />
            </RequestedServiceProvider>
          </ShippingProvider>
        </BranchProvider>
      );
    }

    if (customPathname === "available") {
      return (
        <LogProvider>
          <Route {...rest} />
        </LogProvider>
      );
    }

    if (customPathname === "landing") {
      return (
        <LogProvider>
          <Route {...rest} />
        </LogProvider>
      );
    }

    if (customPathname === "delivery") {
      return (
        <RequestedServiceProvider pathname={pathname}>
          <DeliveryServiceProvider pathname={pathname}>
            <LogProvider>
              <Route {...rest} />
            </LogProvider>
          </DeliveryServiceProvider>
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "delivery_address") {
      return (
        <RequestedServiceProvider pathname={pathname}>
          <Route {...rest} />
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "requested") {
      return (
        <BranchProvider pathname={pathname}>
          <BudgetProvider pathname={pathname}>
            <ShippingProvider pathname={pathname}>
              <CustomerProvider pathname={pathname}>
                <ProviderContextProvider pathname={pathname}>
                  <RequestedServiceProvider pathname={pathname}>
                    <Route {...rest} />
                  </RequestedServiceProvider>
                </ProviderContextProvider>
              </CustomerProvider>
            </ShippingProvider>
          </BudgetProvider>
        </BranchProvider>
      );
    }

    if (customPathname === "requested_edit_detail_validate") {
      return (
        <RequestedServiceProvider pathname={pathname}>
          <LogProvider>
            <Route {...rest} />
          </LogProvider>
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "request_service_form") {
      return (
        <RequestedServiceProvider pathname="isOnlyFunctions">
          <LogProvider>
            <Route {...rest} />
          </LogProvider>
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "detail") {
      return (
        <LogProvider>
          <RequestedServiceProvider pathname={pathname}>
            <Route {...rest} />
          </RequestedServiceProvider>
        </LogProvider>
      );
    }

    if (customPathname === "settings") {
      return (
        <UserProvider pathname={pathname}>
          <Route {...rest} />
        </UserProvider>
      );
    }
    if (customPathname === "recurrent") {
      return (
        <RequestedServiceProvider pathname={pathname}>
          <Route {...rest} />
        </RequestedServiceProvider>
      );
    }

    if (customPathname === "occurrence") {
      return (
        <LogProvider>
          <OccurrenceProvider pathname={pathname}>
            <Route {...rest} />
          </OccurrenceProvider>
        </LogProvider>
      );
    }

    if (customPathname === "knit") {
      return (
        <KnitProvider pathname={pathname}>
          <Route {...rest} />
        </KnitProvider>
      );
    }

    return <Route {...rest} />
  }
  return <GeneralContentLoading />;
};


export function Routes() {
  const { userLogged } = useAuth()

  const userCanViewServicesInDelay = ['ADMIN', 'SUCESSO DO CLIENTE - TORRE DE CONTROLE', 'SUCESSO DO CLIENTE - DIRETORIA'].includes(userLogged?.user_type)

  const prevDelayedCollectsRef = useRef<Pick<GetDelayedCollectsResponse, 'services' | null>>(null)
  const prevDelayedDeliveriesRef = useRef<Pick<GetDelayedDeliveriesResponse, 'services' | null>>(null)

  const {
    isOpen: isServicesInDelayModalOpen,
    onOpen: onOpenServicesInDelayModal,
    onClose: onCloseServicesInDelayModal,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const {
    data: delayedCollectsResult,
    isRefetching: isRefetchingCollects
  } = useQuery({
    queryKey: ['delayed-collects'],
    queryFn: getDelayedCollects,
    refetchInterval: 1000 * 60 * 15,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    enabled: userCanViewServicesInDelay,
    onSuccess(data) {
      if (prevDelayedCollectsRef.current) {
        const newDelayedServices = data.services.reduce((acc, curr) => {
          const serviceAlreadyListed = prevDelayedCollectsRef?.current?.services?.find(
            (service) => service.protocol === curr.protocol
          )

          if (!serviceAlreadyListed) {
            acc.push(curr)
          }

          return acc
        }, [])

        if (newDelayedServices.length && userCanViewServicesInDelay) {
          const delayedProtocols = newDelayedServices.map((service) => service.protocol)

          toast.warn(`Os serviços ${delayedProtocols.join(', ')} entraram em atraso de coleta!`)
        }
      }
    }
  })

  const {
    data: delayedDeliveriesResult,
    isRefetching: isRefetchingDeliveries
  } = useQuery({
    queryKey: ['delayed-deliveries'],
    queryFn: getDelayedDeliveries,
    refetchInterval: 1000 * 60 * 15,
    refetchIntervalInBackground: true,
    enabled: userCanViewServicesInDelay,
    onSuccess(data) {
      if (prevDelayedDeliveriesRef?.current) {
        const newDelayedServices = data.services.reduce((acc, curr) => {
          const serviceAlreadyListed = prevDelayedDeliveriesRef?.current?.services?.find(
            (service) => service?.protocol === curr?.protocol
          )

          if (!serviceAlreadyListed) {
            acc.push(curr)
          }

          return acc
        }, [])

        if (newDelayedServices.length && userCanViewServicesInDelay) {
          const delayedProtocols = newDelayedServices?.map((service) => service?.protocol)

          toast.warn(`Os serviços ${delayedProtocols.join(', ')} entraram em atraso de entrega!`)
        }
      }
    }
  })

  useEffect(() => {
    if (isRefetchingCollects) {
      prevDelayedCollectsRef.current = queryClient.getQueryData(['delayed-collects'])
    }
  }, [isRefetchingCollects, queryClient])

  useEffect(() => {
    if (isRefetchingDeliveries) {
      prevDelayedDeliveriesRef.current = queryClient.getQueryData(['delayed-deliveries'])
    }
  }, [isRefetchingDeliveries, queryClient])

  const delayedServicesCount = delayedDeliveriesResult?.services?.length + delayedCollectsResult?.services?.length



  return (
    <>
      <Switch>
        <CustomRoute exact path="/login" component={Login} />
      </Switch>

      <AppLayout>
        <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
          {userCanViewServicesInDelay && (

            <ServicesInDelayTriggerButton
              aria-label="delay-services"
              label="Serviços em atraso"
              onClick={onOpenServicesInDelayModal}
              delayedServicesCount={delayedServicesCount || 0}
            />
          )}

          <ServicesInDelayModal
            isOpen={isServicesInDelayModalOpen}
            onClose={onCloseServicesInDelayModal}
            delayedCollects={delayedCollectsResult}
            delayedDeliveries={delayedDeliveriesResult}
          />

          <Switch>
            <CustomRoute isPrivate exact path="/" component={Home} />

            {/* SETTINGS */}
            <CustomRoute
              customPathname="settings"
              isPrivate
              exact
              path="/configuracoes"
              component={Settings}
            />

            {/* CITY */}
            <CustomRoute
              customPathname="city_list"
              isPrivate
              path="/cidades"
              component={CityList}
            />
            <CustomRoute
              customPathname="city"
              isPrivate
              path="/cidade/:slug/:id?"
              component={CityAddDetailEdit}
            />

            {/* CUSTOMER */}
            <CustomRoute
              isPrivate
              path="/clientes"
              component={Customers}
            />

            <CustomRoute
              customPathname="customer"
              isPrivate
              path="/cliente/:slug/:id?"
              component={CustomerAddDetailEdit}
            />

            <CustomRoute
              exact
              isPrivate
              path='/faturamentos'
              component={Billings}
            />

            <CustomRoute
              exact
              isPrivate
              path='/faturamento/adicionar'
              component={CreateBillings}
            />

            <CustomRoute
              exact
              isPrivate
              path='/extras-e-descontos/adicionar'
              component={CreateExtrasAndDiscounts}
            />

            <CustomRoute
              exact
              isPrivate
              path='/extras-e-descontos'
              component={ExtrasAndDiscountsList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/extras-e-descontos/visualizar/:id"
              component={ViewExtrasAndDiscounts}
            />


            <CustomRoute
              exact
              isPrivate
              path="/extras-e-descontos/:extraAndDiscountId/editar"
              component={UpdateExtrasAndDiscounts}
            />

            <CustomRoute
              exact
              isPrivate
              path='/extras-e-descontos/finalizados'
              component={ClosedExtrasAndDiscountsList}
            />

            {/* COLLECTOR */}
            <CustomRoute
              exact
              isPrivate
              path="/coletadores"
              component={CollectorList}
            />
            <CustomRoute
              exact
              isPrivate
              path="/coletador/:slug/:id?"
              component={CollectorAddDetailEdit}
            />

            {/* DRIVER */}
            <CustomRoute
              exact
              customPathname="driver"
              isPrivate
              path="/motoristas"
              component={DriverList}
            />
            <CustomRoute
              exact
              customPathname="driver"
              isPrivate
              path="/motorista/:slug/:id?"
              component={DriverAddDetailEdit}
            />

            <CustomRoute
              isPrivate
              path="/motorista-clt/registrar"
              component={RegisterCltDriver}
            />

            <CustomRoute
              isPrivate
              path="/motorista-clt/editar/:id"
              component={EditCltDriver}
            />

            <CustomRoute
              isPrivate
              path="/motoristas-clt"
              component={CltDrivers}
            />

            {/* ADDRESS */}
            <CustomRoute isPrivate exact path="/enderecos" component={AddressesTable} />

            <CustomRoute isPrivate exact path="/enderecos/alteracoes-solicitadas" component={AddressesWithRequestedChanges} />
            <CustomRoute
              isPrivate
              exact
              path="/endereco/:slug/:id"
              component={EditViewAddress}
            />

            <CustomRoute
              isPrivate
              exact
              path="/endereco/adicionar"
              component={CreateAddress}
            />

            <CustomRoute
              isPrivate
              exact
              path="/endereco/:slug/alteracoes/:id"
              component={RequestChangesAddress}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios-novos/:slug"
              component={ReportPage}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/servicos/manutencao"
              component={MaintenanceReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/servicos/ocorrencia"
              component={OccurrenceReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/comunicacoes-internas"
              component={InternComunicationReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/produtividade"
              component={ProductivityReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/agregados"
              component={AggregatesReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/esg"
              component={ESGReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/agendamentos"
              component={ScheduleReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/custo"
              component={CostReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/utilizacao"
              component={UsageReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/crm"
              component={CrmReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/coletador"
              component={CollectorReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/bug"
              component={BugReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/extras-e-descontos"
              component={ExtrasAndDiscountsReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/gerencial-cliente"
              component={ClientManagementReport}
            />

            <CustomRoute
              isPrivate
              exact
              path="/relatorios/rnc"
              component={RNCReport}
            />

            {/* PROVIDER */}
            <CustomRoute
              customPathname="provider"
              isPrivate
              path="/fornecedores"
              component={ProviderList}
            />
            <CustomRoute
              customPathname="provider"
              isPrivate
              path="/fornecedor/:slug/:id?"
              component={ProviderAddDetailEdit}
            />

            {/* SHIPPING */}
            <CustomRoute
              customPathname="shipping"
              isPrivate
              exact
              path="/transportadoras"
              component={ShippingList}
            />
            <CustomRoute
              customPathname="shipping"
              isPrivate
              path="/transportadora/:slug/:id?"
              component={ShippingAddDetailEdit}
            />

            {/* BRANCH */}
            <CustomRoute
              customPathname="branch"
              isPrivate
              path="/transportadoras/bases"
              component={BranchList}
            />
            <CustomRoute
              customPathname="branch"
              isPrivate
              path="/transportadoras/base/:slug/:id?"
              component={BranchAddDetailEdit}
            />

            <CustomRoute
              customPathname="branch"
              isPrivate
              path="/transportadoras/custos/adicionar"
              component={CreateShippingCost}
            />
            <CustomRoute
              exact
              isPrivate
              path="/transportadoras/custos/:shippingCostId/:slug"
              component={EditViewShippingCosts}
            />

            <CustomRoute
              customPathname="branch"
              isPrivate
              path="/transportadoras/custos"
              component={ShippingCosts}
            />

            {/* PERMISSION */}
            <CustomRoute
              customPathname="permission"
              isPrivate
              path="/permissoes"
              component={PermissionList}
            />
            <CustomRoute
              customPathname="permission"
              isPrivate
              path="/permissao/:slug/:id?"
              component={PermissionAddDetailEdit}
            />

            {/* USER */}
            <CustomRoute
              customPathname="user"
              isPrivate
              exact
              path="/usuarios"
              component={UserList}
            />
            <CustomRoute
              customPathname="user"
              isPrivate
              exact
              path="/usuarios/alterar-permissoes"
              component={UserUpdatePermissions}
            />
            <CustomRoute
              customPathname="user"
              isPrivate
              path="/usuario/:slug/:id?"
              component={UserAddDetailEdit}
            />

            {/* BUDGET */}
            <CustomRoute
              customPathname="budget"
              isPrivate
              path="/orcamentos"
              component={BudgetList}
            />
            <CustomRoute
              customPathname="budget"
              isPrivate
              path="/orcamento/:slug/:id?"
              component={BudgetAddDetailEdit}
            />

            {/* SERVICE */}
            <CustomRoute
              customPathname="general_services_list"
              isPrivate
              exact
              path="/servicos"
              component={ServiceList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos-em-andamento"
              component={InProgressServicesList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos/retencoes-fiscais"
              component={FiscalRetentionList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servico/retencao-fiscal/validar/:service_id"
              component={ValidateFiscalRetention}
            />

            {/* REQUESTED-SERVICE */}
            <CustomRoute
              customPathname="service_list"
              isPrivate
              exact
              path="/servicos/solicitados"
              component={RequestedServiceList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servico/unico/solicitar"
              component={RequestService}
            />

            <CustomRoute
              customPathname="requested_edit_detail_validate"
              isPrivate
              exact
              path="/servicos/solicitado/:slug/:id?"
              component={ServiceEditDetailValidate}
            />
            <CustomRoute
              customPathname="requested"
              isPrivate
              exact
              path="/servicos/solicitado/:flag/:slug/:id?"
              component={ServiceEditDetailValidate}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servico/:serviceId/editar-solicitacao"
              component={EditValidatedRequestedService}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos/servicos-cancelados"
              component={ServiceRequestedToCancel}
            />

            {/* RECURRENT-SERVICE */}
            <CustomRoute
              isPrivate
              exact
              path="/servico/recorrente/solicitar"
              component={RecurrentServiceAddPage}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico/recorrente/:slug/:id?"
              component={RecurrentServiceEditDetailPage}
            />
            <CustomRoute
              customPathname="recurrent"
              isPrivate
              exact
              path="/servicos/recorrentes"
              component={RecurrentServiceList}
            />
            <CustomRoute
              customPathname="recurrent"
              isPrivate
              exact
              path="/servicos/recorrentes/executar"
              component={ExecuteRecurrentService}
            />

            {/* COLLECT-SERVICE */}
            <CustomRoute
              customPathname="service_list"
              isPrivate
              exact
              path="/servicos/coletas"
              component={InCollectServicesList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos/coletas-pendente-validacao-sem-sucesso"
              component={UnsuccessCollectsPendingValidationTable}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servicos/novos-enderecos"
              component={ServicesWithNewAddressesList}
            />

            <CustomRoute
              customPathname="collected_addresses"
              isPrivate
              exact
              path="/servicos/coletas/:service_id"
              component={CollectServiceAddress}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos/solicitacoes-cancelamentos"
              component={CollectAddressesAndServicesRequestedToCancel}
            />

            {/* ADDRESS COLLECT SERVICE ROUTES */}
            <CustomRoute
              isPrivate
              exact
              path="/servico/:service_id/coleta/endereco/:address_id"
              component={StartAddressCollectService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico/:service_id/finalizar/coleta/endereco/:address_id"
              component={FinishAddressCollectService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico/:service_id/editar/coleta/endereco/:address_id"
              component={EditAddressCollectService}
            />

            {/* PROVIDER COLLECT SERVICE ROUTES */}
            <CustomRoute
              isPrivate
              exact
              path="/servico/:service_id/coleta/fornecedor/:provider_id"
              component={StartProviderCollectService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico/:service_id/finalizar/coleta/fornecedor/:provider_id"
              component={FinishProviderCollectService}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servico/:service_id/editar/coleta/fornecedor/:provider_id"
              component={EditProviderCollectService}
            />

            <CustomRoute
              customPathname="collected"
              isPrivate
              exact
              path="/servicos/coletas-sem-sucesso/:service_id"
              component={ValidateUnsuccessCollectedService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico/validar-enderecos-para-coleta/:service_id"
              component={ValidateAddressToCollect}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico/:serviceId/validar-novos-enderecos"
              component={ValidateNewServiceAddressesPage}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servicos/validar/tempo-real-coleta-entrega"
              component={ServicesValidationsRealTimes}
            />
            {/* CONSULT-SERVICE */}
            <CustomRoute
              exact
              path="/servicos-business/consultar"
              component={ConsultBusinessService}
              isPrivate
            />
            <CustomRoute
              exact
              path="/servicos-express/:slug"
              component={ConsultExpressService}
              isPrivate
            />

            {/* DETAIL-SERVICE */}
            <CustomRoute
              customPathname="detail"
              isPrivate
              exact
              path="/servico/detalhes/:service_id"
              component={DetailService}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servico/:serviceId"
              component={DetailServiceV2}
            />

            {/* IN-PROGRESS-EDIT */}
            <CustomRoute
              customPathname="in-progress"
              isPrivate
              exact
              path="/servico/editar/:service_id"
              component={EditServiceInProgress}
            />
            <CustomRoute
              customPathname="collected_addresses"
              isPrivate
              exact
              path="/servico/coletas/andamento/:service_id"
              component={CollectedAddresses}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico/entregas/andamento/:service_id"
              component={DeliveryAddresses}
            />

            {/* BOARDING_SERVICE */}
            <CustomRoute
              isPrivate
              exact
              path="/servicos/embarques"
              component={InBoardServicesList}
            />

            <CustomRoute
              customPathname="service_list"
              isPrivate
              exact
              path="/servicos/embarques/sem-sucesso"
              component={UnsuccessBoardServicesToValidate}
            />
            <CustomRoute
              customPathname="service_list"
              isPrivate
              exact
              path="/servicos/embarques/validar"
              component={ValidadteServiceList}
            />
            <CustomRoute
              customPathname="boarded"
              isPrivate
              exact
              path="/servicos/embarques/:service_id"
              component={BoardedServiceSelected}
            />
            <CustomRoute
              customPathname="board_validate_form"
              isPrivate
              exact
              path="/servicos/embarques/validar/:slug?/:service_id"
              component={ValidateService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico/embarques/:service_id"
              component={BoardedServicesToEdit}
            />

            {/* ALLOCATE-SERVICE */}
            <CustomRoute
              customPathname="service_list"
              isPrivate
              exact
              path="/servicos/alocar"
              component={AllocatedServiceList}
            />
            <CustomRoute
              customPathname="allocate"
              isPrivate
              exact
              path="/servicos/alocar/:slug?/:service_id"
              component={AllocatedService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servicos/alocados/:service_id"
              component={AllocatedServicesToEdit}
            />

            {/* DELIVERY-SERVICE */}
            <CustomRoute
              isPrivate
              exact
              path="/servicos/entregas"
              component={InDeliveryServicesList}
            />
            <CustomRoute
              customPathname="delivery_address"
              isPrivate
              exact
              path="/servicos/entregas/:service_id"
              component={DeliveryServiceAddresses}
            />
            <CustomRoute
              customPathname="delivery"
              isPrivate
              exact
              path="/servicos/entregas/:slug?/:service_id/enderecos/:address_id"
              component={DeliveryServiceAddressSelected}
            />

            {/* AVAILABLE-SERVICE */}
            <CustomRoute
              customPathname="service_list"
              isPrivate
              exact
              path="/servicos/disponibilizar/desembarque"
              component={AvailableServiceList}
            />
            <CustomRoute
              customPathname="available"
              isPrivate
              exact
              path="/servicos/disponibilizar/desembarque/:slug?/:service_id"
              component={AvailableService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servicos/disponibilizados/:service_id"
              component={AvailableServicesToEdit}
            />

            {/* LANDING-SERVICE */}
            <CustomRoute
              isPrivate
              exact
              path="/servicos/desembarques"
              component={InLandingServicesList}
            />
            <CustomRoute
              customPathname="landing"
              isPrivate
              exact
              path="/servicos/desembarques/:slug?/:service_id"
              component={LandingService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servicos/desembarcados/:service_id"
              component={LandingServicesToEdit}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos/relacao/cargas"
              component={LoadRatioServices}
            />

            {/* <CustomRoute
              isPrivate
              exact
              path="/servicos/relacao/coletas"
              component={Collects}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos/relacao/embarques"
              component={Boards}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos/relacao/entregas"
              component={Deliveries}
            /> */}

            {/* GELO SECO MAINTENANCE */}
            <CustomRoute
              customPathname="landing"
              isPrivate
              exact
              path="/servicos/gelo-seco/manutencoes"
              component={GeloSecoMaintenanceList}
            />

            <CustomRoute
              customPathname="landing"
              isPrivate
              exact
              path="/servico/gelo-seco/manutencao/:service_id"
              component={CreateGeloSecoMaintenancePage}
            />

            {/* REPORT */}
            <CustomRoute
              customPathname="report"
              isPrivate
              exact
              path="/relatorios/:slug"
              component={Report}
            />

            {/* INPUT */}
            <CustomRoute
              customPathname="input"
              isPrivate
              exact
              path="/insumos"
              component={InputList}
            />
            <CustomRoute
              customPathname="input"
              isPrivate
              exact
              path="/insumo/:slug/:id?"
              component={InputAddDetailEdit}
            />

            {/* STOCK */}
            <CustomRoute
              customPathname="stock"
              isPrivate
              exact
              path="/estoques/adicionar/envio"
              component={StockAddSent}
            />
            <CustomRoute
              isPrivate
              exact
              path="/estoque/enviar-arquivo-csv"
              component={StockSendCsvPage}
            />

            <CustomRoute
              customPathname="stock"
              isPrivate
              exact
              path="/estoque/:slug/:id?"
              component={StockAddDetailEdit}
            />

            <CustomRoute
              customPathname="stock"
              isPrivate
              exact
              path="/estoques"
              component={Stocks}
            />

            {/* HUBS */}
            <CustomRoute
              customPathname="hub"
              isPrivate
              exact
              path="/hubs"
              component={HubList}
            />
            <CustomRoute
              customPathname="hub"
              isPrivate
              exact
              path="/hub/:slug"
              component={HubAdd}
            />
            <CustomRoute
              customPathname="hub"
              isPrivate
              path="/hub/:slug/:id"
              component={HubDetailEdit}
            />

            {/* COLLECTOR-COST */}
            <CustomRoute
              isPrivate
              path="/custos/coletadores"
              component={CollectorCostList}
            />
            <CustomRoute
              exact
              isPrivate
              path="/custos/coletador/:slug/:id?"
              component={CollectorCostAddDetailEdit}
            />

            {/* LOGS */}
            <CustomRoute isPrivate exact path="/logs" component={LogList} />
            <CustomRoute
              isPrivate
              exact
              path="/logs/filtrar"
              component={LogFilter}
            />
            <CustomRoute
              customPathname="occurrence"
              isPrivate
              exact
              path="/ocorrencia/validar/procedencia/:occurrence_id?"
              component={OccurrenceProceedAddDetailEdit}
            />
            <CustomRoute
              customPathname="occurrence"
              isPrivate
              exact
              path="/ocorrencias"
              component={OccurrenceList}
            />
            <CustomRoute
              isPrivate
              exact
              path="/ocorrencias/pendentes/alinhamento"
              component={AwaitingAlignmentOccurrencesList}
            />
            <CustomRoute
              isPrivate
              exact
              path="/ocorrencias/consultar"
              component={ConsultOccurrences}
            />

            <CustomRoute
              isPrivate
              exact
              path="/ocorrencia/alinhar/:id"
              component={AlignOccurrence}
            />

            <CustomRoute
              isPrivate
              exact
              path="/ocorrencia/visualizar/:id"
              component={OccurrenceDetailsPage}
            />

            <CustomRoute
              isPrivate
              exact
              path="/ocorrencias/pendentes/anexar-evidencias"
              component={AwaitingAttachmentEvidencesOccurrencesList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/ocorrencia/anexar-evidencias/:id"
              component={AttachOccurrenceEvidences}
            />

            <CustomRoute
              isPrivate
              exact
              path="/ocorrencias/cliente/finalizadas"
              component={FinishedCustomerOccurrencesList}
            />

            {/* KNIT */}
            <CustomRoute
              customPathname="knit"
              isPrivate
              exact
              path="/malha/enviar"
              component={KnitSendFilePage}
            />
            <CustomRoute
              isPrivate
              exact
              path="/malhas"
              component={RouteNetworkList}
            />

            {/* RNC */}
            <CustomRoute
              isPrivate
              exact
              path="/rnc/terceiros/adicionar"
              component={CreateThirdPartyRnc}
            />

            <CustomRoute
              isPrivate
              exact
              path="/rnc/terceiros/aguardando-resposta"
              component={AwaitingAnswerThirdPartyRncsList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/rnc/terceiros/aguardando-validacao"
              component={AwaitingValidationThirdPartyRncsList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/rnc/terceiros/finalizados"
              component={FinalizedThirdPartyRncsList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/rnc/adicionar"
              component={CreateRncPage}
            />
            <CustomRoute
              isPrivate
              exact
              path="/rnc/pendentes-resposta"
              component={ToAnswerRncList}
            />
            <CustomRoute
              isPrivate
              exact
              path="/rnc/pendentes-validacao"
              component={ToValidateRncList}
            />
            <CustomRoute
              isPrivate
              exact
              path="/rnc/pendentes-alinhamento"
              component={ToAlignRncList}
            />
            <CustomRoute
              isPrivate
              exact
              path="/rnc/finalizados"
              component={FinishedRncList}
            />
            <CustomRoute
              isPrivate
              exact
              path="/rnc/resposta/:rncID"
              component={AnswerRncPage}
            />
            <CustomRoute
              isPrivate
              exact
              path="/rnc/validar/:rncID"
              component={ValidateRncPage}
            />
            <CustomRoute
              isPrivate
              exact
              path="/rnc/alinhar/:rncID"
              component={AlignRncPage}
            />

            {/* CTE */}
            <CustomRoute
              isPrivate
              exact
              path="/cte/loglife/enviar-arquivo"
              component={CTELoglifeSendFilePage}
            />

            <CustomRoute
              isPrivate
              exact
              path="/cte/complementar/enviar-arquivo"
              component={CTEComplementarySendFilePage}
            />

            {/* ACCOUNT */}
            <CustomRoute
              isPrivate
              exact
              path="/fatura/cliente/gerar"
              component={GenerateCustomerAccountPage}
            />

            {/* HELP */}
            <CustomRoute isPrivate exact path="/como-usar-o-sistema" component={Help} />

            {/* HOLIDAY */}
            <CustomRoute
              isPrivate
              exact
              path="/feriado/:slug/:id?"
              component={CreateDetailEditHoliday}
            />
            <CustomRoute
              isPrivate
              exact
              path="/feriados"
              component={HolidayList}
            />

            {/* PROFILE */}
            <CustomRoute
              isPrivate
              exact
              path="/perfil/:slug/:id?"
              component={CreateDetailEditProfilePage}
            />
            <CustomRoute isPrivate exact path="/perfis" component={ProfileList} />

            <CustomRoute
              isPrivate
              exact
              path="/usuario-solicitado/:slug/:id?"
              component={CreateDetailEditRequestedUser}
            />
            <CustomRoute
              customPathname="user"
              isPrivate
              exact
              path="/usuarios-solicitados"
              component={RequestedUserList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/preco/:slug/:id?"
              component={CreateDetailEditPrice}
            />
            <CustomRoute
              isPrivate
              exact
              path="/precos/consultar"
              component={ConsultPrice}
            />
            <CustomRoute
              isPrivate
              exact
              path="/precos/importar"
              component={PriceSendFilePage}
            />
            <CustomRoute isPrivate exact path="/precos" component={PriceList} />

            <CustomRoute
              isPrivate
              exact
              path="/orcamento-business/:slug/:id?"
              component={CreateDetailEditBusinessBudget}
            />
            <CustomRoute
              isPrivate
              exact
              path="/orcamentos-business"
              component={BusinessBudgetList}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servico-business/detalhes/:id?"
              component={DetailBusinessService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico-business/em-andamento/editar/:service_id"
              component={EditBusinessServiceInProgress}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servico-business/:slug/:id?"
              component={CreateDetailEditRequestedBusinessService}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servicos-solicitados-business/recorrentes"
              component={RecurrentRequestedBusinessServiceList}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servicos-solicitados-business"
              component={BusinessServices}
            />
            <CustomRoute
              isPrivate
              exact
              path="/servicos-business/coletas"
              component={CollectBusinessServiceList}
            />
            <CustomRoute
              customPathname="collected_addresses"
              isPrivate
              exact
              path="/servico-business/coleta/enderecos/:service_id"
              component={CollectBusinessServiceAddresses}
            />
            <CustomRoute
              customPathname="collected_addresses"
              isPrivate
              exact
              path="/servico-business/coleta/:slug?/:service_id/endereco/:address_id"
              component={CollectBusinessServiceAddressSelected}
            />
            <CustomRoute
              customPathname="collected_addresses"
              isPrivate
              exact
              path="/servico-business/coletas-finalizadas/editar/:service_id"
              component={CollectedBusinessAddresses}
            />

            <CustomRoute
              isPrivate
              exact
              path="/servicos-business/entregas"
              component={DeliveryBusinessServiceList}
            />
            <CustomRoute
              customPathname="delivery_address"
              isPrivate
              exact
              path="/servico-business/entrega/enderecos/:service_id"
              component={DeliveryBusinessServiceAddresses}
            />
            <CustomRoute
              customPathname="delivery"
              isPrivate
              exact
              path="/servico-business/entrega/:slug?/:service_id/endereco/:address_id"
              component={DeliveryBusinessServiceAddressSelected}
            />
            <CustomRoute
              customPathname="collected_addresses"
              isPrivate
              exact
              path="/servico-business/entregas-finalizadas/editar/:service_id"
              component={DeliveryBusinessAddresses}
            />
            <CustomRoute
              customPathname="user"
              exact
              path="/recuperar-senha/:token?"
              component={RecoverPasswordPage}
            />
            <CustomRoute exact path="/upload-pdf" component={UploadPdfPage} />
            <CustomRoute
              exact
              path="/associar-pdf"
              component={SendFileToAssignPdfAndServicePage}
            />
            <CustomRoute
              exact
              isPrivate
              path="/servicos/kanban"
              component={KanbanViewServicesPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servico/validar/:service_id"
              component={ValidateRequestedService}
            />

            {/* <CustomRoute
            exact
            isPrivate
            path="/comunicacoes-internas"
            component={InternComunicationsList}
          /> */}

            <CustomRoute
              exact
              isPrivate
              customPathname="occurrence"
              path="/ocorrencia/criar"
              component={CreateOccurrence}
            />

            <CustomRoute
              exact
              isPrivate
              path="/material/criar"
              component={CreateMaterialPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/material/editar/:id"
              component={EditMaterialPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/materiais"
              component={MaterialList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servicos/manutencoes"
              component={MaintenanceServiceList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servico/manutencao/:service_id"
              component={StartMaintenanceServicePage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servico/manutencao/finalizar/:maintenance_id"
              component={FinishMaintenanceService}
            />

            <CustomRoute
              exact
              isPrivate
              path="/notificacao/tipo/criar"
              component={CreateNotificationType}
            />

            <CustomRoute
              exact
              isPrivate
              path="/notificacao/tipo/:id/editar"
              component={UpdateNotificationType}
            />

            <CustomRoute
              exact
              isPrivate
              path="/notificacao/tipos"
              component={NotificationTypesList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/agregado/criar"
              component={CreateAggregatePage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/agregado/:slug/:id"
              component={EditViewAggregatePage}
            />
            <CustomRoute
              exact
              isPrivate
              path="/agregados"
              component={AggregateList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/agregado-ativar/:aggregateId"
              component={ActivateAggregatesPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/agregado-desligamento/:id"
              component={DismissalAggregatePage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/agregado-inativar/:id"
              component={InactivateAggregatePage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/classificacao-financeira/criar"
              component={CreateFinancialClassificationPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/classificacao-financeira/editar/:id"
              component={EditFinancialClassificationPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/classificacao-financeira"
              component={FinancialClassificationList}
            />
            <CustomRoute
              exact
              isPrivate
              path="/fornecedor-geral/criar"
              component={CreateGeneralProviderPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/fornecedor-geral/:slug/:id"
              component={EditGeneralProviderPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/fornecedor-geral"
              component={GeneralProviderList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/produtos"
              component={Products}
            />

            <CustomRoute
              exact
              isPrivate
              path="/almoxarifado/criar"
              component={CreateWarehousePage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/almoxarifado/editar/:id"
              component={EditWarehousePage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/almoxarifados"
              component={WarehouseList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/crm/criar"
              component={CreateCrm}
            />

            <CustomRoute
              exact
              isPrivate
              path="/crm/visualizar/:crmId"
              component={ViewCrm}
            />

            <CustomRoute
              exact
              isPrivate
              path="/crms"
              component={CrmsList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/pmc/criar"
              component={CreatePmc}
            />

            <CustomRoute
              exact
              isPrivate
              path="/pmc/pedido-compra/criar"
              component={CreatePurchaseOrderPmcPage}
            />

            <CustomRoute
              exact
              isPrivate
              path="/pedido-compra/criar"
              component={CreatePurchaseOrder}
            />

            <CustomRoute
              exact
              isPrivate
              path="/pedido-compra/:purchaseOrderId/visualizar"
              component={ViewPurchaseOrder}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compras/pedidos/aguardando-cotacao"
              component={PurchaseOrdersAwaitingQuote}
            />

            <CustomRoute
              exact
              isPrivate
              path="/pedido-compra/:purchaseOrderId/cotar"
              component={QuotePurchaseOrder}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compras/cotacoes/aguardando-aprovacao"
              component={PurchaseOrdersAwaitingApprove}
            />

            <CustomRoute
              exact
              isPrivate
              path="/pedido-compra/:purchaseOrderId/aprovar-cotacoes"
              component={ApprovePurchaseOrder}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compras/pedidos/aguardando-compra"
              component={PurchaseOrdersAwaitingBuy}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compras/pedidos/aguardando-nota-fiscal"
              component={PurchaseOrdersAwaitingInvoice}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compras/aguardando-chegada"
              component={PurchaseOrdersAwaitingArrival}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compra/:purchaseOrderId/validar"
              component={ValidatePurchaseOrder}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compras/finalizadas"
              component={PurchaseOrdersArrived}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compras/recusadas"
              component={PurchaseOrdersRefused}
            />

            <CustomRoute
              exact
              isPrivate
              path="/compras"
              component={PurchaseOrders}
            />

            <CustomRoute
              exact
              isPrivate
              path="/extra-desconto/coletador/criar"
              component={CreateCollectorExtraDiscount}
            />

            <CustomRoute
              exact
              isPrivate
              path="/extras-descontos/coletadores"
              component={CollectorsExtrasDiscounts}
            />

            <CustomRoute
              exact
              isPrivate
              path="/extra-desconto/motorista-clt/criar"
              component={CreateCltDriverExtraDiscount}
            />


            <CustomRoute
              exact
              isPrivate
              path="/extras-descontos/motoristas-clt"
              component={() => {
                return (
                  <CltDriversExtrasDiscountsStatusProvider>
                    <CltDriversExtrasDiscountsList />
                  </CltDriversExtrasDiscountsStatusProvider>
                )
              }
              }
            />


            <CustomRoute
              exact
              isPrivate
              path="/extra-desconto/agregado/criar"
              component={CreateAggregateExtraDiscount}
            />


            <CustomRoute
              exact
              isPrivate
              path="/extras-descontos/agregados"
              component={AggregatesExtrasDiscounts}
            />


            <CustomRoute
              exact
              isPrivate
              path="/rncs/pendente-anexo"
              component={AwaitingAttachmentRncsList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/rnc/:rncId/alinhar"
              component={AlignInternRnc}
            />

            <CustomRoute
              exact
              isPrivate
              path="/rnc/:rncId/anexar"
              component={SendRncAlignmentAttachments}
            />

            <CustomRoute
              exact
              isPrivate
              path="/rsm/solicitar"
              component={CreateRSM}
            />

            <CustomRoute
              exact
              isPrivate
              path="/coletador/despacho/criar/proposta"
              component={CreateCollectorDispatchStockProposal}
            />

            <CustomRoute
              exact
              isPrivate
              path="/estoques/despachos/propostas"
              component={DispatchStockProposals}
            />

            <CustomRoute
              exact
              isPrivate
              path="/rsms"
              component={() => {
                return (
                  <MaterialRequestsStatusTableButtonProvider>
                    <MaterialRequestsList />
                  </MaterialRequestsStatusTableButtonProvider>
                )
              }}
            />

            <CustomRoute
              exact
              isPrivate
              path="/extra-desconto/:extraDiscountId/coletador"
              component={UpdateCollectorExtraDiscount}
            />

            <CustomRoute
              exact
              isPrivate
              path="/extra-desconto/:extraDiscountId/motorista-clt"
              component={UpdateCltDriverExtraDiscount}
            />

            <CustomRoute
              exact
              isPrivate
              path="/extra-desconto/:extraDiscountId/agregado"
              component={UpdateAggregateExtraDiscount}
            />

            <CustomRoute
              exact
              isPrivate
              path="/extra-desconto/:extraDiscountId/agregado"
              component={UpdateAggregateExtraDiscount}
            />

            <CustomRoute
              exact
              isPrivate
              path="/bugs"
              component={BugsList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/regioes"
              component={RegionList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/regioes/adicionar"
              component={CreateRegion}
            />

            <CustomRoute
              exact
              isPrivate
              path="/regioes/:slug/:id"
              component={EditViewRegion}
            />

            <CustomRoute
              exact
              isPrivate
              path="/empregado/custo/criar"
              component={CreateEmployeeCost}
            />

            <CustomRoute
              exact
              isPrivate
              path="/empregados/custos"
              component={EmployeeCostsList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/empregado/custo/:employeeCostId/:slug"
              component={EditViewEmployeeCost}
            />

            <CustomRoute
              exact
              isPrivate
              path="/usuarios/monitoramento"
              component={CreateMonitoringUsers}
            />

            <CustomRoute
              exact
              isPrivate
              path="/usuarios/monitoramento/:slug"
              component={EditViewMonitoringUsers}
            />

            <CustomRoute
              isPrivate
              exact
              path='/pmcs'
              component={Pmcs}
            />

            <CustomRoute
              isPrivate
              exact
              path='/asos'
              component={Asos}
            />

            <CustomRoute
              isPrivate
              exact
              path='/asos/relatorio'
              component={AsosReport}
            />

            <CustomRoute
              isPrivate
              exact
              path='/vacinas'
              component={Vaccines}
            />

            <CustomRoute
              isPrivate
              exact
              path='/vacinas/relatorio'
              component={VaccinesReport}
            />

            <CustomRoute
              isPrivate
              exact
              path='/controle/treinamentos'
              component={Trainings}
            />

            <CustomRoute
              isPrivate
              exact
              path='/controle/treinamentos/relatorio'
              component={TrainingsReport}
            />

            <CustomRoute
              isPrivate
              exact
              path='/custos/business'
              component={BusinessCosts}
            />

            <CustomRoute
              isPrivate
              exact
              path='/custo/business/criar'
              component={CreateBusinessCost}
            />

            <CustomRoute
              isPrivate
              exact
              path='/custo/business/:businessCostId/editar'
              component={EditBusinessCost}
            />
            <CustomRoute
              isPrivate
              exact
              path='/controle/treinamentos/llm'
              component={TrainingsLlm}
            />

            <CustomRoute
              isPrivate
              exact
              path='/controle/treinamentos/llm/relatorio'
              component={TrainingsLlmReport}
            />

            <CustomRoute
              isPrivate
              exact
              path='/comunicacoes-internas'
              component={InternComunications}
            />

            <CustomRoute
              isPrivate
              exact
              path='/padronizacao/criar'
              component={CreateStandardization}
            />

            <CustomRoute
              isPrivate
              exact
              path='/padronizacao/:standardizationId/corrigir'
              component={CorrectStandardization}
            />

            <CustomRoute
              isPrivate
              exact
              path='/padronizacoes'
              component={Standardizations}
            />

            <CustomRoute
              isPrivate
              exact
              path='/padronizacoes/relatorio'
              component={StandardizationsReport}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz'
              component={NewMatrizDashboard}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/coletador'
              component={NewCollectorDashboard}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/motorista'
              component={NewDriverDashboard}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/cliente'
              component={NewCustomerDashboard}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/coletas-com-ocorrencias-de-atraso'
              component={CollectServicesWithDelayOccurrence}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/entregas-com-ocorrencias-de-atraso'
              component={DeliveryServicesWithDelayOccurrence}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/coletas-atrasadas-ou-proximas-a-atrasar'
              component={DelayedOrNextToDelayCollectServices}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/entregas-atrasadas-ou-proximas-a-atrasar'
              component={DelayedOrNextToDelayDeliveryServices}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/embarques-atrasados'
              component={DelayedBoards}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/validacoes-de-embarque-em-atraso'
              component={DelayedBoardValidations}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/coletador/desembarques-atrasados'
              component={DelayedLandings}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/coletas-sem-sucesso-pendente-validacao'
              component={UnsuccessCollectsPendingValidation}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/coletas-com-novos-enderecos'
              component={ServicesWithNewCollectAddresses}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/servicos-nao-finalizados-com-ocorrencia-de-atraso'
              component={UnfinishedWithDelayOccurrenceServices}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/embarques-a-validar'
              component={ToBoardValidateServices}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/embarques-a-alocar'
              component={ToAllocateServices}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/servicos-solicitados-para-cancelamento'
              component={ServicesRequestedToCancel}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/servicos-com-solicitacoes-de-cancelamento-de-endereco-de-coleta'
              component={ServicesWithCollectAddressesRequestedToCancel}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/estoques-abaixo-do-minimo'
              component={StocksUnderMinimum}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/matriz/produtos-em-estoque-minimo-ou-abaixo'
              component={ProductsWithOrUnderMinimumStock}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/cliente/servicos-em-coleta'
              component={DashboardCustomerCollects}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/cliente/servicos-em-embarque'
              component={DashboardCustomerBoards}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/cliente/servicos-em-desembarque'
              component={DashboardCustomerLandings}
            />

            <CustomRoute
              isPrivate
              exact
              path='/dashboard/cliente/servicos-em-entregas'
              component={DashboardCustomerDeliveries}
            />
            <CustomRoute
              isPrivate
              exact
              path='/despachos'
              component={DispatchStocks}
            />

            <CustomRoute
              isPrivate
              exact
              path='/despachos/relatorio'
              component={DispatchStocksReport}
            />

            <CustomRoute
              isPrivate
              exact
              path='/despachos/criar'
              component={CreateDispatchStock}
            />

            <CustomRoute
              isPrivate
              exact
              path='/despachos/editar/:id'
              component={EditDispatchStock}
            />

            <CustomRoute
              isPrivate
              exact
              path='/rastreios/gollog/:tracker'
              component={GollogTracking}
            />

            <CustomRoute
              exact
              path='/teste'
              component={Test}
            />

            <CustomRoute
              exact
              isPrivate
              path="/notificacoes"
              component={Notifications}
            />
            <CustomRoute
              exact
              isPrivate
              path="/emails/clientes"
              component={CustomerEmailList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/relacao/servicos"
              component={ServiceListInProgress}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servicos/desembarques-pendentes-validacao"
              component={ValidatePendingLandingServices}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servicos/entregas-pendentes-validacao"
              component={DeliveriesWithoutQRCodePendingValidation}
            />
            <CustomRoute
              exact
              isPrivate
              path="/coletador/despacho/criar/proposta/manual"
              component={CreateCollectorDispatchStockProposalManually}
            />
            <CustomRoute
              exact
              isPrivate
              path="/onboardings"
              component={Onboardings}
            />
            <CustomRoute
              exact
              isPrivate
              path="/onboardings/relatorio"
              component={OnboardingsReport}
            />

            <CustomRoute
              exact
              isPrivate
              path="/tipos/patrimonio"
              component={PatrimonyTypes}
            />

            <CustomRoute
              exact
              isPrivate
              path="/patrimonios"
              component={Patrimonies}
            />
            <CustomRoute
              exact
              isPrivate
              path="/patrimonios/solicitados"
              component={PatrimonyRequests}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servicos/sem-sucesso"
              component={ServiceUnsuccessPendingValidateList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servicos/validar/sem-qr"
              component={ServiceValidateWithoutQrList}
            />

            <CustomRoute
              exact
              isPrivate
              path="/intercorrencias-clt"
              component={CltIntercurrences}
            />

            <CustomRoute
              exact
              isPrivate
              path="/intercorrencia-clt/criar"
              component={CreateCltIntercurrence}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servicos/business/agenda"
              component={BusinessServiceSchedule}
            />

            <CustomRoute
              exact
              isPrivate
              path="/advertencias"
              component={Advertences}
            />

            <CustomRoute
              exact
              isPrivate
              path="/relatorio/advertencias"
              component={AdvertencesReport}
            />

            <CustomRoute
              exact
              isPrivate
              path="/servicos/validar/step"
              component={ValidateServicesWithStepChange}
            />

            <CustomRoute
              exact
              isPrivate
              path="/internos-clt"
              component={InternClts}
            />

            <CustomRoute
              exact
              isPrivate
              path="/interno-clt/criar"
              component={CreateInternClt}
            />

            <CustomRoute
              exact
              isPrivate
              path="/interno-clt/:internCltId/editar"
              component={EditInternClt}
            />

            <CustomRoute
              exact
              isPrivate
              path="/documentos"
              component={Documents}
            />

            <CustomRoute
              exact
              isPrivate
              path="/relatorio/documentos"
              component={DocumentsReport}
            />

            <CustomRoute
              exact
              isPrivate
              path="/periodos-experiencia"
              component={ExperiencePeriods}
            />

            <CustomRoute
              exact
              isPrivate
              path="/ferias"
              component={Vacations}
            />

            <CustomRoute
              exact
              isPrivate
              path="/portadores"
              component={Carriers}
            />

            <CustomRoute
              exact
              isPrivate
              path="/portadores/relatorio"
              component={CarriersReport}
            />

            <CustomRoute
              exact
              isPrivate
              path="/regionais"
              component={Regionals}
            />

            <CustomRoute
              exact
              isPrivate
              path="/auditorias"
              component={Audits}
            />

            <CustomRoute
              exact
              isPrivate
              path="/internos-clt/cargos"
              component={InternCltsOffices}
            />

            <CustomRoute
              exact
              isPrivate
              path="/auditorias/relatorio"
              component={AuditsReport}
            />

            <CustomRoute
              exact
              isPrivate
              path="/reembolsos"
              component={Refunds}
            />

            <CustomRoute
              exact
              isPrivate
              path="/esporadicos/kanban"
              component={Sporadics}
            />

            <CustomRoute
              exact
              isPrivate
              path="/esporadicos/tabela"
              component={SporadicsTable}
            />
            <CustomRoute
              exact
              isPrivate
              path="/custo-transportadora/relatorio"
              component={ShippingsCostsReport}
            />

            <CustomRoute
              exact
              isPrivate
              path="/atendimentos"
              component={Supports}
            />

            <CustomRoute
              exact
              isPrivate
              path="/atendimentos/assuntos"
              component={SupportsSubjects}
            />
            <CustomRoute
              exact
              isPrivate
              path="/feedbacks-passivos/tabela"
              component={PassiveFeedbacksTable}
            />

            <CustomRoute
              exact
              isPrivate
              path="/feedbacks-passivos/kanban"
              component={PassiveFeedbacks}
            />
            <CustomRoute
              exact
              isPrivate
              path="/feedbacks-ativos/tabela"
              component={ActiveFeedbacksTable}
            />
            <CustomRoute
              exact
              isPrivate
              path="/feedbacks-ativos/kanban"
              component={ActivesFeedbacks}
            />
            <CustomRoute
              exact
              isPrivate
              path="/contingencias"
              component={Contingencies}
            />
            <CustomRoute
              exact
              isPrivate
              path="/advertencias/kanban"
              component={AdvertencesKanban}
            />

            <CustomRoute
              exact
              isPrivate
              path="/asos/kanban"
              component={AsosKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/intercorrencias-clt/kanban"
              component={CltIntercurrencesKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/padronizacoes/kanban"
              component={StandardizationsKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/vacinas/kanban"
              component={VaccinesKanban}
            />

            <CustomRoute
              exact
              isPrivate
              path="/auditorias/kanban"
              component={AuditsKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/controle/treinamentos/kanban"
              component={TrainingsKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/documentos/kanban"
              component={DocumentsKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/controle/treinamentos/llm/kanban"
              component={TrainingsLlmKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/ferias/kanban"
              component={VacationsKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/onboardings/kanban"
              component={OnboardingsKanban}
            />
            <CustomRoute
              exact
              isPrivate
              path="/periodos-experiencia/kanban"
              component={ExperiencePeriodsKanban}
            />

          </Switch>
        </ErrorBoundary>
      </AppLayout>
    </>
  );
}

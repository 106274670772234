import { useMutation, useQueryClient } from 'react-query'
import {
  CreateRequestedUserInputProps,
  createRequestedUserReqFunction,
  deleteRequestedUserReqFunction,
  editRequestedUserReqFunction,
  EditRequestedUserReqProps,
  validateRequestedUserToCreateReqFunction,
} from '../../utils/RequestFunctions/RequestedUser/requestRequestedUserFunctions'
import { useSwal } from '../swal/useSwal'

export const useRequestedUserFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createRequestedUser = useMutation(
    'createRequestedUser',
    (input: CreateRequestedUserInputProps) =>
      createRequestedUserReqFunction(input),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['requestedUsers'])
        await queryClient.invalidateQueries(['requestedUser'])
        await successMessage('Usuário solicitado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const editRequestedUser = useMutation(
    'editRequestedUser',
    ({ input, id }: EditRequestedUserReqProps) =>
      editRequestedUserReqFunction({ input, id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['requestedUsers'])
        await queryClient.invalidateQueries(['requestedUser'])
        await successMessage('Solicitação de usuário atualizada com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const validateRequestedUser = useMutation(
    'validateRequestedUser',
    (id: string) => validateRequestedUserToCreateReqFunction(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['requestedUsers'])
        await queryClient.invalidateQueries(['requestedUser'])
        await successMessage('Usuário validado e criado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const deleteRequestedUser = useMutation(
    'deleteRequestedUser',
    (id: string) => deleteRequestedUserReqFunction(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['requestedUsers'])
        await queryClient.invalidateQueries(['requestedUser'])
        await successMessage('Solicitação de usuário excluída com sucesso!')
      },

      onError: async (error: any) => {
        await errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    createRequestedUser,
    editRequestedUser,
    deleteRequestedUser,
    validateRequestedUser,
  }
}

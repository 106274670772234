import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Standardization } from "api/standardizations/standardization";
import { format } from "date-fns";
import { FaSearch } from "react-icons/fa";
import { StandardizationDetail } from "./StandardizationDetail";
import { StandardizationStatus } from "./StandardizationStatus";


interface StandardizationTableRowProps {
  standardization: Standardization
}
export function StandardizationTableRow({ standardization }: StandardizationTableRowProps) {

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()


  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Visualizar Padronização"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
          size='6xl'
        >
          <ModalOverlay />

          <StandardizationDetail standardizationId={standardization.id} />

        </Modal>
      </Td>
      <Td>{standardization?.name}</Td>
      <Td>{standardization?.hub?.name}</Td>
      <Td>{format(new Date(standardization.sended_at), 'dd/MM/yyyy')}</Td>
      <Td>
        <StandardizationStatus status={standardization.status} />
      </Td>
    </Tr>
  )
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useToastify } from "hooks/toastify/useToastify"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"
import { set } from "date-fns";
import { completeIsoQualityAudit } from "api/audits/completeIsoQualityAudit"


interface AuditQualityIsoProps {
  auditId: string
}

interface AuditQualityIsoSchema {
  realizedDate: string
  hasTrainingSchedule: string
  hasAuditsSchedule: string
  hasCommercialProcessMapping: string
  hasRhDpProcessMapping: string
  hasOperationProcessMapping: string
  hasPurchaseProcessMapping: string
  hasFinancialProcessMapping: string
  hasQualityProcessMapping: string
  hasOrganizationalCultureProcessMapping: string
  hasUpdatedOrganizationalChart: string
  hasCargoAndFunctionsDetail: string
  hasAuditProcessDocument: string
  hasTrainingProcessDocument: string
  hasMeetingModelForCriticalAnalysis: string
  hasAuditorsSavedCertificates: string
  hasChangesPlanning: string
  hasQualityPolicy: string
  hasQualityObjectives: string
  hasDocumentToStandardizesDocuments: string
  hasProcessInteraction: string
  hasQualityManagmentSystemManual: string
  hasCarriersIqrQualification: string
  hasProvidersIqrQualification: string
  hasCollectorsIqrQualification: string
  hasNonComplianceDocument: string
  hasEthicsCode: string
  hasInternExternalComunication: string
  hasStrategicPlanning: string
  nonCompliance: string
  isTimeCardRecordedCorrectly: string
}


const auditQualityIsoSchema = yup.object({
  realizedDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  hasTrainingSchedule: yup.string().required(),
  hasAuditsSchedule: yup.string().required(),
  hasCommercialProcessMapping: yup.string().required(),
  hasRhDpProcessMapping: yup.string().required(),
  hasOperationProcessMapping: yup.string().required(),
  hasPurchaseProcessMapping: yup.string().required(),
  hasFinancialProcessMapping: yup.string().required(),
  hasQualityProcessMapping: yup.string().required(),
  hasOrganizationalCultureProcessMapping: yup.string().required(),
  hasUpdatedOrganizationalChart: yup.string().required(),
  hasCargoAndFunctionsDetail: yup.string().required(),
  hasAuditProcessDocument: yup.string().required(),
  hasTrainingProcessDocument: yup.string().required(),
  hasMeetingModelForCriticalAnalysis: yup.string().required(),
  hasAuditorsSavedCertificates: yup.string().required(),
  hasChangesPlanning: yup.string().required(),
  hasQualityPolicy: yup.string().required(),
  hasQualityObjectives: yup.string().required(),
  hasDocumentToStandardizesDocuments: yup.string().required(),
  hasProcessInteraction: yup.string().required(),
  hasQualityManagmentSystemManual: yup.string().required(),
  hasCarriersIqrQualification: yup.string().required(),
  hasProvidersIqrQualification: yup.string().required(),
  hasCollectorsIqrQualification: yup.string().required(),
  hasNonComplianceDocument: yup.string().required(),
  hasEthicsCode: yup.string().required(),
  hasInternExternalComunication: yup.string().required(),
  hasStrategicPlanning: yup.string().required(),
  isTimeCardRecordedCorrectly: yup.string().required(),
  nonCompliance: yup.string(),
})

export function AuditQualityIso({
  auditId
}: AuditQualityIsoProps) {
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<AuditQualityIsoSchema>({
    resolver: yupResolver(auditQualityIsoSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: completeIsoQualityAuditFn } = useMutation({
    mutationFn: completeIsoQualityAudit,
    onSuccess() {
      queryClient.invalidateQueries(['audits'])
      queryClient.invalidateQueries({ queryKey: 'pending-audits' })
      queryClient.invalidateQueries({ queryKey: 'approved-audits' })
      queryClient.invalidateQueries({ queryKey: 'reproved-audits' })
      queryClient.invalidateQueries({ queryKey: ['audit', auditId] })
    }
  })

  async function handleAuditQualityIso(values: AuditQualityIsoSchema) {
    const convertValuesAuditQualityIso = {
      hasTrainingSchedule: values.hasTrainingSchedule === 'yes',
      hasAuditsSchedule: values.hasAuditsSchedule === 'yes',
      hasCommercialProcessMapping: values.hasCommercialProcessMapping === 'yes',
      hasRhDpProcessMapping: values.hasRhDpProcessMapping === 'yes',
      hasOperationProcessMapping: values.hasOperationProcessMapping === 'yes',
      hasPurchaseProcessMapping: values.hasPurchaseProcessMapping === 'yes',
      hasFinancialProcessMapping: values.hasFinancialProcessMapping === 'yes',
      hasQualityProcessMapping: values.hasQualityProcessMapping === 'yes',
      hasOrganizationalCultureProcessMapping: values.hasOrganizationalCultureProcessMapping === 'yes',
      hasUpdatedOrganizationalChart: values.hasUpdatedOrganizationalChart === 'yes',
      hasCargoAndFunctionsDetail: values.hasCargoAndFunctionsDetail === 'yes',
      hasAuditProcessDocument: values.hasAuditProcessDocument === 'yes',
      hasTrainingProcessDocument: values.hasTrainingProcessDocument === 'yes',
      hasMeetingModelForCriticalAnalysis: values.hasMeetingModelForCriticalAnalysis === 'yes',
      hasAuditorsSavedCertificates: values.hasAuditorsSavedCertificates === 'yes',
      hasChangesPlanning: values.hasChangesPlanning === 'yes',
      hasQualityPolicy: values.hasQualityPolicy === 'yes',
      hasQualityObjectives: values.hasQualityObjectives === 'yes',
      hasDocumentToStandardizesDocuments: values.hasDocumentToStandardizesDocuments === 'yes',
      hasProcessInteraction: values.hasProcessInteraction === 'yes',
      hasQualityManagmentSystemManual: values.hasQualityManagmentSystemManual === 'yes',
      hasCarriersIqrQualification: values.hasCarriersIqrQualification === 'yes',
      hasProvidersIqrQualification: values.hasProvidersIqrQualification === 'yes',
      hasCollectorsIqrQualification: values.hasCollectorsIqrQualification === 'yes',
      hasNonComplianceDocument: values.hasNonComplianceDocument === 'yes',
      hasEthicsCode: values.hasEthicsCode === 'yes',
      hasInternExternalComunication: values.hasInternExternalComunication === 'yes',
      hasStrategicPlanning: values.hasStrategicPlanning === 'yes',
      isTimeCardRecordedCorrectly: values.isTimeCardRecordedCorrectly === 'yes',
    }

    await promiseMessage(completeIsoQualityAuditFn({
      body: {
        realizedDate: values.realizedDate,
        nonCompliance: values.nonCompliance,
        ...convertValuesAuditQualityIso,
      },
      auditId,
    }), 'Auditoria realizada!')
  }
  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleAuditQualityIso)}
    >
      <Heading letterSpacing="tight" size='sm'> Auditoria Qualidade ISO</Heading>

      <FormControl isInvalid={!!errors?.realizedDate} mt={3}>
        <FormLabel fontSize="sm">
          Data realizada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('realizedDate')}
          name="realizedDate"
          type="date"
          size="sm"
          rounded="md"
          w={["full", "full", "sm"]}
        />
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasTrainingSchedule}>
            <FormLabel>
              Existe cronograma de treinamentos?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasTrainingSchedule"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasAuditsSchedule}>
            <FormLabel>
              Existe cronograma de auditorias?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasAuditsSchedule"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasCommercialProcessMapping}>
            <FormLabel>
              Existe Mapeamento de Processos do Comercial?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasCommercialProcessMapping"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasRhDpProcessMapping}>
            <FormLabel>
              Existe Mapeamento de Processos do RH/DP?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasRhDpProcessMapping"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasOperationProcessMapping}>
            <FormLabel>
              Existe Mapeamento de Processos de Operação?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasOperationProcessMapping"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasPurchaseProcessMapping}>
            <FormLabel>
              Existe Mapeamento de Processos de Compras?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasPurchaseProcessMapping"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasFinancialProcessMapping}>
            <FormLabel>
              Existe Mapeamento de Processos do Financeiro?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasFinancialProcessMapping"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasQualityProcessMapping}>
            <FormLabel>
              Existe Mapeamento de Processos da Qualidade?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasQualityProcessMapping"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasOrganizationalCultureProcessMapping}>
            <FormLabel>
              Existe Mapeamento de Processos da Cultura Organizacional?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasOrganizationalCultureProcessMapping"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasUpdatedOrganizationalChart}>
            <FormLabel>
              Existe organograma atualizado?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasUpdatedOrganizationalChart"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasCargoAndFunctionsDetail}>
            <FormLabel>
              Existe descrição de cargos e funções?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasCargoAndFunctionsDetail"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasAuditProcessDocument}>
            <FormLabel>
              Existe documento que defina o processo de auditoria?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasAuditProcessDocument"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasTrainingProcessDocument}>
            <FormLabel>
              Existe documento que defina o processo de treinamento?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasTrainingProcessDocument"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasMeetingModelForCriticalAnalysis}>
            <FormLabel>
              Existe modelo de ata de reunião de análise crítica?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasMeetingModelForCriticalAnalysis"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasAuditorsSavedCertificates}>
            <FormLabel>
              Existem certificados dos auditores salvos?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasAuditorsSavedCertificates"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasChangesPlanning}>
            <FormLabel>
              Existe planejamento de mudanças?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasChangesPlanning"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasQualityPolicy}>
            <FormLabel>
              Existe política da qualidade?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasQualityPolicy"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasQualityObjectives}>
            <FormLabel>
              Existe objetivos da qualidade?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasQualityObjectives"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasDocumentToStandardizesDocuments}>
            <FormLabel>
              Existe documento que padroniza os documentos?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasDocumentToStandardizesDocuments"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasProcessInteraction}>
            <FormLabel>
              Existe interação dos processos?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasProcessInteraction"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasQualityManagmentSystemManual}>
            <FormLabel>
              Existe manual do Sistema de Gestão da Qualidade?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasQualityManagmentSystemManual"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasCarriersIqrQualification}>
            <FormLabel>
              Existe qualificação de transportadoras e IQR?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasCarriersIqrQualification"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasProvidersIqrQualification}>
            <FormLabel>
              Existe qualificação de fornecedores e IQR?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasProvidersIqrQualification"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasCollectorsIqrQualification}>
            <FormLabel>
              Existe qualificação de coletadores e IQR?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasCollectorsIqrQualification"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasNonComplianceDocument}>
            <FormLabel>
              Existe documento de não conformidade?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasNonComplianceDocument"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasEthicsCode}>
            <FormLabel>
              Existe código de ética?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasEthicsCode"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasInternExternalComunication}>
            <FormLabel>
              Existe comunicação interna e externa?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasInternExternalComunication"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.hasStrategicPlanning}>
            <FormLabel>
              Existe planejamento estratégico?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              name="hasStrategicPlanning"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>

      <FormControl isInvalid={!!errors.isTimeCardRecordedCorrectly} mt={3}>
        <FormLabel>
          Cartão de ponto batido corretamente?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isTimeCardRecordedCorrectly"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="column">
                <Radio value="yes">Sim</Radio>
                <Radio value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.nonCompliance} mt={3}>
        <FormLabel>
          Não conformidade
        </FormLabel>
        <Textarea
          {...register('nonCompliance')}
          placeholder="Descreva a não conformidade..."
          size='sm'
          rounded='md'
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Auditar
        </Button>
      </Flex>
    </Box>
  )
}

import { Button, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { approveCltIntercurrence } from "api/cltIntercurrences/approveCltIntercurrence"
import { GetCltIntercurrencesResponse } from "api/cltIntercurrences/getCltIntercurrences"
import { useToastify } from "hooks/toastify/useToastify"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"

interface ApprovePatrimonyWithoutReturnCltIntercurrenceSchema {
  wasEntryMadeOnTerminationReceipt: string
}

const approvePatrimonyWithoutReturnCltIntercurrenceSchema = yup.object({
  wasEntryMadeOnTerminationReceipt: yup.string().required(),
})

interface ApprovePatrimonyWithoutReturnCltIntercurrenceProps {
  cltIntercurrenceId: string
}

export function ApprovePatrimonyWithoutReturnCltIntercurrence({
  cltIntercurrenceId
}: ApprovePatrimonyWithoutReturnCltIntercurrenceProps) {
  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting,
    }
  } = useForm<ApprovePatrimonyWithoutReturnCltIntercurrenceSchema>({
    resolver: yupResolver(approvePatrimonyWithoutReturnCltIntercurrenceSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: approveCltIntercurrenceFn } = useMutation({
    mutationFn: approveCltIntercurrence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetCltIntercurrencesResponse>({
        queryKey: ['clt-intercurrences']
      })

      queryClient.invalidateQueries({ queryKey: 'approved-clt-intercurrences' })
      queryClient.invalidateQueries({ queryKey: 'rejected-clt-intercurrences' })
      queryClient.invalidateQueries({ queryKey: 'requested-cltintercurrences' })
      queryClient.invalidateQueries({ queryKey: ['clt-intercurrence', routeParams.cltIntercurrenceId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          cltIntercurrences: cachedValue.cltIntercurrences.map((cltIntercurrence) => {
            if (cltIntercurrence.id === routeParams.cltIntercurrenceId) {
              return { ...cltIntercurrence, status: 'approved' }
            }

            return cltIntercurrence
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleApproveCltIntercurrence(values: ApprovePatrimonyWithoutReturnCltIntercurrenceSchema) {
    const body = {}

    body['wasEntryMadeOnTerminationReceipt'] = values.wasEntryMadeOnTerminationReceipt === 'yes'

    await promiseMessage(approveCltIntercurrenceFn({
      body,
      routeParams: {
        cltIntercurrenceId
      }
    }), 'Intercorrência aprovada com sucesso!')
  }

  return (
    <Flex
      direction="column"
      gap="3"
      as="form"
      onSubmit={handleSubmit(handleApproveCltIntercurrence)}
    >

      <FormControl isInvalid={!!errors.wasEntryMadeOnTerminationReceipt}>
        <FormLabel>
          Valor adicional liberado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="wasEntryMadeOnTerminationReceipt"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="column">
                <Radio value="yes">Sim</Radio>
                <Radio value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <Button
        type="submit"
        colorScheme="blue"
        w="min"
        alignSelf="end"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  )
}

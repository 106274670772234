import { Box, Button, Divider, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { changeStatusActiveFeedback } from "api/feedbacks/changeStatusActiveFeedback"
import { getActiveFeedback } from "api/feedbacks/getActiveFeedback"
import { useAuth } from "hooks/auth/useAuth"
import { useToastify } from "hooks/toastify/useToastify"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { ActiveFeedbackDetailTable } from "./ActiveFeedbackDetailTable"
import { DirectLeaderApprovalForm } from "./DirectLeaderApprovalForm"
import { ExecutiveLeaderApprovalForm } from "./ExecutiveLeaderApprovalForm"

interface ActiveFeedbackDetailsProps {
  activeFeedbackId: string
}

export function ActiveFeedbackDetails({
  activeFeedbackId
}: ActiveFeedbackDetailsProps) {
  const { userLogged } = useAuth()
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const doesUserCanDirectLeaderApproval = userLogged?.permissions.includes('approval-direct-leader-active-feedback')

  const doesUserCanExecutiveLeaderApproval = userLogged?.permissions.includes('approval-executive-leader-active-feedback')

  const doesUserCanChangeStatusActiveFeedback = userLogged?.permissions.includes('change-status-active-feedback')


  const {
    data: activeFeedbackResult,
    isLoading: isLoadingActiveFeedbackResult
  } = useQuery({
    queryKey: ['active-feedback', activeFeedbackId],
    queryFn: () => getActiveFeedback({ activeFeedbackId })
  })


  const { mutateAsync: changeStatusActiveFeedbackFn } = useMutation({
    mutationFn: changeStatusActiveFeedback,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'scheduled-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'direct-leader-approving-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'awaiting-leader-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'done-actives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: ['active-feedback', activeFeedbackId] })
    },
  })

  async function handleChangeStatusActiveFeedback(status: string) {
    await promiseMessage(changeStatusActiveFeedbackFn({
      activeFeedbackId,
      status
    }), 'Feedback em avaliação')
  }

  return (
    <ModalContent >
      <ModalHeader>
        Detalhes Feedback
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {isLoadingActiveFeedbackResult ? (
          <Table size="sm">
            <Tbody>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={index}>
                    <Td>
                      <Skeleton w="full" h="16px" />
                    </Td>
                    <Td isNumeric>
                      <Skeleton w="full" h="16px" />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>

        ) : (
          <Grid
            templateColumns={['direct-leader-approving', 'executive-leader-approving'].includes(activeFeedbackResult?.activeFeedback.status)
              ? "1fr auto 1fr"
              : "1fr"
            }
            gap={6}
          >
            <GridItem>
              <ActiveFeedbackDetailTable
                activeFeedback={activeFeedbackResult}
              />
            </GridItem>
            <Box>
              <Divider orientation="vertical" />
            </Box>
            {doesUserCanDirectLeaderApproval && activeFeedbackResult?.activeFeedback.status === 'direct-leader-approving' && (
              <GridItem>
                <DirectLeaderApprovalForm
                  activeFeedbackId={activeFeedbackId}
                />
              </GridItem>
            )}
            {doesUserCanExecutiveLeaderApproval && activeFeedbackResult?.activeFeedback.status === 'executive-leader-approving' && (
              <GridItem>
                <ExecutiveLeaderApprovalForm
                  activeFeedbackId={activeFeedbackId}
                />
              </GridItem>
            )}

          </Grid>
        )}
      </ModalBody>
      <ModalFooter>
        {doesUserCanChangeStatusActiveFeedback && activeFeedbackResult?.activeFeedback?.status === 'scheduled' && (
          <Button
            size="sm"
            colorScheme='blue'
            onClick={() => handleChangeStatusActiveFeedback('direct-leader-approving')}
          >
            Avaliar
          </Button>
        )}
        {doesUserCanChangeStatusActiveFeedback && activeFeedbackResult?.activeFeedback?.status === 'awaiting-leader-feedback' && (
          <Button
            size="sm"
            colorScheme='blue'
            onClick={() => handleChangeStatusActiveFeedback('done')}
          >
            Finalizar
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  )
}

import { SupportStatus } from 'api/supports/_types/Support'

export const supportStatusMap: Record<SupportStatus, string> = {
  analyzing: 'Aguardando análise',
  supporting: 'Em atendimento',
  "waiting-return": 'Aguardando retorno',
  verifying: 'Verificação',
  done: 'Concluído',
  canceled: 'Cancelado'
}





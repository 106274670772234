import { useHistory, useParams } from 'react-router'
import { useState, useContext, useEffect } from 'react'
import swal from 'sweetalert'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { BranchForm } from './BranchForm'
import { branchFormatDataToBack } from '../../utils/BranchFunctions/branchFormatDataToBack'
import { BranchContext } from '../../contexts/BranchContext'
import { AuthContext } from '../../contexts/AuthContext'

interface QueryParams {
  id: string
  slug: string
}

interface FormInputs {
  nickname: string
  shipping_id: string
  email: string
  hub_id: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  open_on_holidays: string
  holiday_open: string | null
  holiday_close: string | null
  observation: string
}

export function BranchAddDetailEdit() {
  const [isLoading, setIsLoading] = useState(false)
  const { createBranch, editBranch } = useContext(BranchContext)
  const { userLogged } = useContext(AuthContext)

  const { id, slug }: QueryParams = useParams()

  const history = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'adicionar') {
            if (!permissions?.includes('add-branch')) {
              history.push('/')
            }
          }
          if (slug === 'editar') {
            if (!permissions?.includes('edit-branch')) {
              history.push('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-branch')) {
              history.push('/')
            }
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  async function handleCreateBranch(values: FormInputs) {
    await swal({
      title: 'Deseja adicionar uma base de transportadora?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willCreate) => {
      if (willCreate) {
        const formatedData = branchFormatDataToBack(values)
        const response = await createBranch(formatedData)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/transportadoras/bases')
          }, 1500)
        }
      } else {
        return swal('Criação cancelada com êxito!')
      }
    })
  }

  async function handleEditBranch(values: FormInputs) {
    await swal({
      title: 'Deseja editar uma uma base de transportadora?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willEdit) => {
      if (willEdit) {
        const formatedData = branchFormatDataToBack(values)
        const response = await editBranch(id, formatedData)
        if (response) {
          setIsLoading(true)
          setTimeout(() => {
            history.push('/transportadoras/bases')
          }, 1500)
        }
      } else {
        swal('Edição cancelada com êxito!')
      }
    })
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'adicionar') {
    return (
      <BranchForm
        slug={slug}
        id={id}
        title="Adicionar Base de Transportadora"
        action="Salvar"
        submit={handleCreateBranch}
        href=""
      />
    )
  } else if (slug === 'editar') {
    return (
      <BranchForm
        slug={slug}
        id={id}
        title="Editar Base de Transportadora"
        action="Salvar"
        submit={handleEditBranch}
        href=""
      />
    )
  } else {
    return (
      <BranchForm
        slug={slug}
        id={id}
        title="Visualizar Base de Transportadora"
        action="Editar"
        submit={async () => {}}
        isDisabled={true}
        href={`/transportadoras/base/editar/${id}`}
      />
    )
  }
}

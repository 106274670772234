import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, Divider, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, Icon } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { Input } from "components/Inputs/Input"
import { Select } from "components/Inputs/SelectInput"
import { Pagination } from "components/Pagination/Pagination"

import { useAuth } from "hooks/auth/useAuth"
import { useDriver } from "hooks/driver/useDriver"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { Occurrence } from "hooks/occurrences/dtos/Occurrence"
import { useInProgressServices } from "hooks/services/useInProgressServices"
import { Fragment, useEffect, useMemo, useReducer } from "react"
import { useForm, useWatch } from "react-hook-form"
import { FaExclamation, FaEye } from "react-icons/fa"
import { Link } from "react-router-dom"
import { inProgressServiceSelectOptions } from "utils/CustomLists/inProgressServiceSelectOptions"
import { formatDate } from "utils/DateFunctions/formatDate"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"

interface InProgressServicesFilterInputs {
  protocol: number
  collect_date: string
  delivery_date: string
  customer: string
  source_city: string
  destination_city: string
  shipping: string
  source_collector: string
  destination_collector: string
  driver_id: string
  step: string
  responsible: string
}

interface ActionPayload {
  inProgressServiceData?: InProgressServicesFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-in-progress-services-data' | 'set-in-progress-services-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-in-progress-services-current-page') {
    return {
      inProgressServiceData: { ...state.inProgressServiceData },
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

const inProgressServicesListFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'collect_date', value: 'Data da coleta', checked: false },
  { key: 'delivery_date', value: 'Data da entrega', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'source_city', value: 'Cidade(s) de origem', checked: false },
  { key: 'destination_city', value: 'Cidade(s) de destino', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'source_collector', value: 'Coletador de origem', checked: false },
  { key: 'destination_collector', value: 'Coletador de destino', checked: false },
  { key: 'driver_id', value: 'Motorista', checked: false },
  { key: 'responsible', value: 'Responsável pelo atendimento', checked: false },
  { key: 'step', value: 'Etapa', checked: false },
]

export function InProgressServicesList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const [inProgressServicesDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const { userLogged } = useAuth()

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()

  const filterOptionsByUserLogged = useMemo(() => {
    return inProgressServicesListFilterOptions
      .filter(option => {
        return userLogged?.user_type === 'CLIENTE'
          ? !['shipping', 'source_collector', 'destination_collector'].includes(option.key)
          : option
      })
  }, [userLogged])


  useEffect(() => {
    onLoadSetFilterOptions(filterOptionsByUserLogged)
  }, [onLoadSetFilterOptions, filterOptionsByUserLogged])

  const { register, control } = useForm<InProgressServicesFilterInputs>()

  const [
    protocol,
    collectDate,
    deliveryDate,
    customer,
    sourceCity,
    destinationCity,
    shipping,
    sourceCollector,
    destinationCollector,
    driver,
    step,
    responsible
  ] = useWatch({
    control,
    name: [
      'protocol',
      'collect_date',
      'delivery_date',
      'customer',
      'source_city',
      'destination_city',
      'shipping',
      'source_collector',
      'destination_collector',
      'driver_id',
      'step',
      'responsible'
    ]
  })

  const {
    drivers: {
      data: drivers,
      isFetching: isFetchingDrivers,
    }
  } = useDriver(null, true)

  const driversSelectOptions = drivers?.map(driver => {
    return {
      key: driver.id,
      value: driver.id,
      showOption: `${driver.firstname} ${driver.lastname}`
    }
  })

  const {
    data: inProgressServicesData,
    isFetching: isFetchingInProgressServicesData
  } = useInProgressServices({
    queryParams: {
      ...inProgressServicesDataState.inProgressServiceData,
      collector_id: userLogged?.collector_id,
      customer_id: userLogged?.customer_id,
      owner_id: userLogged?.user_type === 'REMETENTE' ? userLogged?.id : undefined,
      current_page: inProgressServicesDataState.currentPage,
      page_size: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!inProgressServicesDataState.currentPage
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: inProgressServicesData?.total_pages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-in-progress-services-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch({
        type: 'set-in-progress-services-data',
        payload: {
          inProgressServiceData: {
            protocol: protocol,
            collect_date: collectDate,
            delivery_date: deliveryDate,
            customer: customer,
            source_city: sourceCity,
            destination_city: destinationCity,
            shipping: shipping,
            source_collector: sourceCollector,
            destination_collector: destinationCollector,
            driver_id: driver,
            step: step,
            responsible: responsible
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounce)
  }, [
    protocol,
    collectDate,
    deliveryDate,
    customer,
    sourceCity,
    destinationCity,
    shipping,
    sourceCollector,
    destinationCollector,
    step,
    driver,
    responsible,
    setCurrentPage
  ])

  const handleChangePage = (page: number) => setCurrentPage(page)


  return (

      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        <Flex
          gap={4}
          direction='column'
        >
          <Heading size='md'>Serviços em andamento</Heading>

          <Divider />

          <Flex w={['full', 'full', 'min']}>
            <TableFilterButton />
          </Flex>

          {filterOptions
            .filter(option => option.checked)
            .map(option => {
              return (
                <Fragment key={option.key}>
                  {option.key === 'collect_date' ? (
                    <Input
                      {...register('collect_date')}
                      name='collect_date'
                      label='Data da coleta'
                      type='date'
                      size='sm'
                    />
                  ) : (
                    option.key === 'delivery_date' ? (
                      <Input
                        {...register('delivery_date')}
                        name='delivery_date'
                        label='Data da entrega'
                        type='date'
                        size='sm'
                      />
                    ) : (
                      option.key === 'step' ? (
                        <Select
                          {...register('step')}
                          name='step'
                          label='Etapa'
                          options={inProgressServiceSelectOptions}
                          placeholder='Selecione uma opção...'
                          size='sm'
                        />
                      ) : (
                        option.key === 'driver_id' ? (
                          <Select
                            {...register('driver_id')}
                            name='driver_id'
                            label='Motorista'
                            options={driversSelectOptions}
                            isDisabled={isFetchingDrivers}
                            placeholder='Selecione uma opção...'
                            size='sm'
                          />
                        ) : (
                          <Input
                            {...register(option.key as keyof InProgressServicesFilterInputs)}
                            name={option.key}
                            label={`Buscar ${option.value}`}
                            size='sm'
                            placeholder='Buscar...'
                          />
                        )
                      )))}
                </Fragment>
              )
            })}

          {isFetchingInProgressServicesData ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th></Th>
                    {filterOptionsByUserLogged.map(option => {
                      return (
                        <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                      )
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {inProgressServicesData?.services?.map(service => {
                    const collectDelayOccurrences = [
                      'ATRASO NA COLETA',
                      'NO SHOW'
                    ]

                    const serviceStepsToShowCollectDelayOccurrence = [
                      'toCollectService',
                      'collectingService',
                      'toValidateCollectAddresses',
                      'toBoardService',
                      'boardingService'
                    ]

                    const serviceCollectDelayOccurrence =
                      service.serviceIDRequested.occurrenceIDService
                        .filter(occurrence => collectDelayOccurrences.includes(occurrence.intercurrence))
                        .reduce((acc, curr) => {
                          return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
                        }, {} as Occurrence)

                    const hasCollectDelayOccurrence = !!serviceCollectDelayOccurrence?.id &&
                      serviceStepsToShowCollectDelayOccurrence.includes(service.serviceIDRequested.step)

                    const collectDate = serviceCollectDelayOccurrence?.occurrence_date
                      ? formatDate.handle(serviceCollectDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
                      : formatDate.handle(service.collect_date, "DateWithoutHourToShow")

                    const deliveryDelayOccurrences = [
                      'ATRASO NA ENTREGA',
                      'CANCELAMENTO DE VOO',
                      'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
                      'ATRASO NA TRANSFERÊNCIA',
                      'ATRASO NA LIBERAÇÃO'
                    ]

                    const serviceDeliveryDelayOccurrence =
                      service.serviceIDRequested.occurrenceIDService
                        .filter(occurrence => deliveryDelayOccurrences.includes(occurrence.intercurrence))
                        .reduce((acc, curr) => {
                          return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
                        }, {} as Occurrence)

                    const hasDeliveryDelay = !!serviceDeliveryDelayOccurrence?.id

                    const deliveryDate = serviceDeliveryDelayOccurrence?.occurrence_date
                      ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
                      : formatDate.handle(service.delivery_date, "DateWithoutHourToShow")

                    const driversAssigned = []

                    if (service.driver_address_assign) {
                      service.driver_address_assign.forEach(driverAssigned => {
                        const driverAssignedParsed = JSON.parse(driverAssigned)

                        driversAssigned.push(driverAssignedParsed.driver_id)
                      })
                    }
                    if (service.driver_provider_assign) {
                      service.driver_provider_assign.forEach(driverAssigned => {
                        const driverAssignedParsed = JSON.parse(driverAssigned)

                        driversAssigned.push(driverAssignedParsed.driver_id)
                      })
                    }

                    const driversAssignedInfo = Array
                      .from(new Set(drivers
                        ?.filter(driver => {
                          return driversAssigned.includes(driver.id)
                        })
                        .map(driver => {
                          return `${driver.firstname} ${driver.lastname}`
                        })
                      ))
                      .join(', ')

                    return (
                      <Tr
                        key={service.id}
                        color={hasDeliveryDelay || hasCollectDelayOccurrence ? 'yellow.300' : ''}
                      >
                        <Td>
                          {service?.budgetIDService.priority_budget && (
                            <Icon as={FaExclamation} color={'red.500'} />
                          )}
                        </Td>
                        <Td>
                          <Button
                            as={Link}
                            to={`/servico/detalhes/${service.serviceIDRequested.id}`}
                            variant='unstyled'
                          >
                            <Icon as={FaEye} />
                          </Button>
                        </Td>

                        <Td>{service.serviceIDRequested.protocol}</Td>
                        <Td>{collectDate}</Td>
                        <Td>{deliveryDate}</Td>
                        <Td>{service.serviceIDRequested.customerIDService.trading_firstname}</Td>
                        <Td>{Array.from(new Set(service.source_cities.map(city => city.name))).join(', ')}</Td>
                        <Td>{Array.from(new Set(service.destination_cities.map(city => city.name))).join(', ')}</Td>
                        {userLogged?.user_type !== 'CLIENTE' && (
                          <>
                            <Td>{service.shippings.length ? service.shippings.map(shipp => shipp.company_name) : '-'}</Td>
                            <Td>{service.sourceCollectorIDService.trading_name}</Td>
                            <Td>{service.destinationCollectorIDService.trading_name}</Td>
                          </>
                        )}
                        <Td>{driversAssignedInfo || '-'}</Td>
                        <Td>{service?.serviceIDRequested?.customerIDService?.attendanceResponsibleIDCustomer?.firstname} {service?.serviceIDRequested?.customerIDService?.attendanceResponsibleIDCustomer?.lastname}</Td>
                        <Td>{serviceHandleStep(service.serviceIDRequested.step)}</Td>
                      </Tr>
                    )
                  })}

                </Tbody>
              </Table>
            </TableContainer>
          )}

          <Pagination
            currentPage={currentPage}
            pages={pages}
            pagesQuantity={pagesCount}
            handlePageChange={handleChangePage}
          />

        </Flex>
      </Box>


  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface VerifySporadicParams {
  sporadicId: string
}

export async function verifySporadic({
  sporadicId
}: VerifySporadicParams) {
  try {
    await api.patch(`/sporadics/${sporadicId}/verify`,)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface CreateInteractionProps {
  body: {
    description: string
    type: string
  }
  routeParams: {
    serviceId: string
  }
}

export async function createInteraction({
  body,
  routeParams
}: CreateInteractionProps) {
  try {
    const { data } = await api.post(`services/${routeParams.serviceId}/interactions`, body)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}

import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ExternalComunicationsMessageContentProps extends FlexProps {
  children: ReactNode
}

export function ExternalComunicationsMessageContentContainer({
  children,
  ...rest
}: ExternalComunicationsMessageContentProps) {
  return (
    <Flex
      direction="column"
      w="full"
      {...rest}
    >
      {children}
    </Flex>
  )
}

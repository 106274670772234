import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface InactivateDocumentProps {
  routeParams: {
    documentId: string
  }
}

export async function inactivateDocument({
  routeParams
}: InactivateDocumentProps) {
  try {
    await api.patch(`/documents/${routeParams.documentId}/inactivate`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

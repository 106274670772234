import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

export interface GetActiveServicesByBudgetResponse {
  services: {
    id: string
  }[]
}

interface GetActiveServicesByBudgetParams {
  budgetId: string
}

export async function getActiveServicesByBudget({
  budgetId
}: GetActiveServicesByBudgetParams) {
  try {
    const response = await api.get<GetActiveServicesByBudgetResponse>(`/budgets/${budgetId}/active-services`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

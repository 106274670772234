import { format, toDate } from 'date-fns'
import { transformStringToNumber } from '../GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

interface DataProps {
  company_name: string
  trading_name: string
  hub_id: string
  cnpj: string
  email: string
  material: string
  unit_cost: number
  payment_conditional: string
  day_expiration_1: string
  day_expiration_2: string
  payment_type: string
  cellphone: string
  telephone: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  business_open: string
  business_close: string
  saturday_open: string | null
  saturday_close: string | null
  sunday_open: string | null
  sunday_close: string | null
  situation: string
  material_price: string
  observation: string
  deadline: string
  is_primary_provider: string
}

const providerFormatDataToBack = (data: DataProps) => {
  console.log(data, 'Data')

  const hourWeekOpen = data.business_open.split(':')
  const hourWeekClose = data.business_close.split(':')
  const hourSaturdayOpen =
    data.saturday_open !== null ? data.saturday_open.split(':') : ''
  const hourSaturdayClose =
    data.saturday_close !== null ? data.saturday_close.split(':') : ''
  const hourSundayOpen =
    data.sunday_open !== null ? data.sunday_open.split(':') : ''
  const hourSundayClose =
    data.sunday_close !== null ? data.sunday_close.split(':') : ''

  const dateNowWeekOpen = toDate(Date.now()).setHours(
    Number(hourWeekOpen[0]),
    Number(hourWeekOpen[1]),
    0,
  )
  const dateNowWeekClose = toDate(Date.now()).setHours(
    Number(hourWeekClose[0]),
    Number(hourWeekClose[1]),
    0,
  )
  const dateNowSaturdayOpen =
    hourSaturdayOpen.length <= 1
      ? null
      : toDate(Date.now()).setHours(
          Number(hourSaturdayOpen[0]),
          Number(hourSaturdayOpen[1]),
          0,
        )
  const dateNowSaturdayClose =
    hourSaturdayClose.length <= 1
      ? null
      : toDate(Date.now()).setHours(
          Number(hourSaturdayClose[0]),
          Number(hourSaturdayClose[1]),
          0,
        )
  const dateNowSundayOpen =
    hourSundayOpen.length <= 1
      ? null
      : toDate(Date.now()).setHours(
          Number(hourSundayOpen[0]),
          Number(hourSundayOpen[1]),
          0,
        )
  const dateNowSundayClose =
    hourSundayClose.length <= 1
      ? null
      : toDate(Date.now()).setHours(
          Number(hourSundayClose[0]),
          Number(hourSundayClose[1]),
          0,
        )

  const formatedDataWeekOpen = format(
    toDate(dateNowWeekOpen),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )
  const formatedDataWeekClose = format(
    toDate(dateNowWeekClose),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  )
  const formatedDataSaturdayOpen =
    dateNowSaturdayOpen === null
      ? null
      : format(toDate(dateNowSaturdayOpen), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  const formatedDataSaturdayClose =
    dateNowSaturdayClose === null
      ? null
      : format(toDate(dateNowSaturdayClose), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  const formatedDataSundayOpen =
    dateNowSundayOpen === null
      ? null
      : format(toDate(dateNowSundayOpen), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
  const formatedDataSundayClose =
    dateNowSundayClose === null
      ? null
      : format(toDate(dateNowSundayClose), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

  const addressFormatedData = {
    ...data,
    is_primary_provider: data.is_primary_provider === 'yes',
    unit_cost: transformStringToNumber(String(data.unit_cost)),
    saturday_close: formatedDataSaturdayClose,
    saturday_open: formatedDataSaturdayOpen,
    business_close: formatedDataWeekClose,
    sunday_close: formatedDataSundayClose,
    business_open: formatedDataWeekOpen,
    sunday_open: formatedDataSundayOpen,
    material_price: transformStringToNumber(data.material_price),
    deadline: Number(data.deadline)
  }

  return addressFormatedData
}

export { providerFormatDataToBack }

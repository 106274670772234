import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Stock } from './types/Stock';

interface GetStocksParams {
  pageSize: string
  currentPage: string
  collector?: string
  collectorId?: string
  hubs?: string
  input?: string

}

export interface GetStocksResponse {
  stocks: Stock[]
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getStocks({
  pageSize,
  currentPage,
  hubs,
  collector,
  collectorId,
  input
}: GetStocksParams) {
  try {
    const { data } = await api.get<GetStocksResponse>('/stocks', {
      params: {
        pageSize,
        currentPage,
        hubs,
        collector,
        collectorId,
        input
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import axios from "axios";
import { PurchaseOrder } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreatePurchaseOrderPmcBody {
  purchaseDescription: string
  type: string
}

interface CreatePurchaseOrderPmcParams {
  pmcId: string
  body: CreatePurchaseOrderPmcBody
}

interface CreatePurchaseOrderPmcResponse {
  notificationProfiles: string[]
  purchaseOrders: PurchaseOrder[]
}

export async function createPurchaseOrderPmc({
  pmcId,
  body: {
    purchaseDescription,
    type
   }
}: CreatePurchaseOrderPmcParams) {
  try {
    const response = await api.post<CreatePurchaseOrderPmcResponse>(`/pmcs/${pmcId}/purchase-orders`, {
      purchaseDescription,
      type
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

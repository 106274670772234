import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  CheckboxGroup,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckBoxProps,
  Text,
  Stack,
  Box,
  Spinner,
  Flex,
} from '@chakra-ui/react'
import { Address } from 'hooks/address/dtos/Address'
import { forwardRef, ForwardRefRenderFunction, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { searchBoxFilter } from '../../utils/searchBoxFilter'
import { SearchBox } from '../SearchBox/SearchBox'

interface InputProps extends ChakraCheckBoxProps {
  slug?: string
  id?: string
  isLoading?: boolean
  addresses?: Address[]
  readOnlyAddresses?: string[]
  defaultCheckedOptions?: string[]
  onCheckboxChange: () => void;
  name: string
  label?: string
  required?: boolean
  error?: FieldError
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    isLoading,
    slug,
    id,
    addresses,
    defaultCheckedOptions,
    readOnlyAddresses,
    required = false,
    error = null,
    onCheckboxChange,
    ...rest
  },
  ref,
) => {

  const [addressFiltered, setAddressFiltered] = useState('')

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            <Flex h="full" w="full" gap="1" align="center">
              <Text fontSize="sm" color="gray.700" w="full">
                {label}
              </Text>
              {required && (
                <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                  {' '}
                  *
                </Text>
              )}
            </Flex>
          </FormLabel>
        </Flex>
      )}

      {addresses && (
        <>
          <SearchBox
            isCheckbox={true}
            size="sm"
            placeholder="Buscar Endereço..."
            handleSearch={(e) =>
              setAddressFiltered(e.target.value)
            }
          />
          <Box
            overflowY="auto"
            height="300px"
            borderWidth="1px"
            borderRadius="lg"
            p="2"
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <Stack spacing="10px" justify="flex-start">
                <CheckboxGroup
                  colorScheme="blue"
                  onChange={onCheckboxChange}
                  defaultValue={defaultCheckedOptions}

                >
                  <Stack direction="column">
                    {addresses
                      ?.filter((address) => {
                        const addressFilter = searchBoxFilter(
                          `${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
                  ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`,
                          addressFiltered,
                        )

                        const situationFilter = address.situation = 'ATIVO'

                        if (addressFiltered === '') return situationFilter

                        return addressFilter && situationFilter
                      })
                      .map(address => {
                        return (
                          <ChakraCheckbox
                            key={address.id}
                            name={name}
                            label={label}
                            value={address.id}
                            error={error}
                            ref={ref}
                            isReadOnly={
                              !!(
                                slug === 'andamento' &&
                                readOnlyAddresses?.includes(address.id)
                              )
                            }
                            {...rest}
                          >
                            {`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
                  ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}
                          </ChakraCheckbox>
                        )
                      })}
                  </Stack>
                </CheckboxGroup>
              </Stack>
            )}
          </Box>
        </>
      )}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const CheckboxService = forwardRef(CheckboxBase)

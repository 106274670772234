import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { useAuth } from "hooks/auth/useAuth";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from "hooks/toastify/useToastify";
import { CrmListResponse } from "./dtos/Crm";

interface CrmsQueryParams {
  pageSize?: number
  currentPage?: number
  customer?: string
  createdAt?: string
  type?: string
  owner?: string
}

type QueryOptions = UseQueryOptions<CrmListResponse, unknown, CrmListResponse, QueryKey>

interface UseCrmsOptions {
  queryKey?: QueryKey
  queryParams?: CrmsQueryParams
  queryOptions?: QueryOptions
}

export function useCrms(options?: UseCrmsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getCrms(queryParams?: CrmsQueryParams) {
    try {
      const { data } = await api.get(`/crms`, {
        params: {
          ...queryParams
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery<CrmListResponse, unknown, CrmListResponse, QueryKey>([
    options?.queryKey ?? 'crms',
    { ...options?.queryParams }
  ], () => getCrms(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

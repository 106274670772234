import { AttachOccurrenceEvidencesForm } from "./components/AttachOccurrenceEvidencesForm";
import { Link, useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { useOccurrence } from "hooks/occurrences/useOccurrence";
import { Box, Button, Divider, Flex, Heading, Spinner } from "@chakra-ui/react";


interface Params {
  id: string
}
interface Attachments {
  attachment: FileList
}
export interface AttachOccurrenceEvidencesFormInputs {
  // preventive_responsible: string
  // attachment: FileList
  alignments: {
    preventive_responsible: string
    attachments: Attachments[]
  }[]
}

export function AttachOccurrenceEvidences() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const { id } = useParams<Params>()

  useEffect(() => {
    if (!userLogged?.permissions.includes('attach-customer-occurrence-evidences')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const {
    occurrence: {
      data: occurrence,
      isFetching: isFetchingOccurrence
    },
    handleSetOccurrenceId
  } = useOccurrence()


  useEffect(() => {
    handleSetOccurrenceId(id)
  }, [handleSetOccurrenceId, id])

  return (

      <Box
        bg='white'
        p={4}
        as={Flex}
        direction='column'
        gap={2}
      >
        <Heading size='md'>Anexar evidências da ocorrência</Heading>

        <Divider />

        {isFetchingOccurrence ? (
          <Spinner />
        ) : (
          occurrence?.alignments.map((alignment, index) => {
            const alignmentRegistedAttachments = alignment?.attachments
              ? typeof alignment?.attachments !== 'string'
                ? alignment?.attachments
                : JSON.parse(alignment?.attachments)
              : []
            return (
              <AttachOccurrenceEvidencesForm
                indexOccurrence={index}
                key={alignment.id}
                occurrenceAlignmentId={alignment.id}
                occurrence_id={alignment.occurrence_id}
                preventiveResponsibleId={alignment.preventive_responsible}
                preventiveResponsibleAttachment={alignmentRegistedAttachments}
                preventiveResponsibleName={`${alignment.preventiveResponsible.firstname} ${alignment.preventiveResponsible.lastname}`}
              />

            )
          })
        )}

        <Button
          w={['full', 'full', 'min']}
          as={Link}
          to='/ocorrencias/pendentes/anexar-evidencias'
          colorScheme='gray'
        >
          Ocorrências pendentes anexo
        </Button>
      </Box>

  )
}

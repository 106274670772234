import { useState } from "react"
import { useMutation } from "react-query"
import { api } from "services/api"
import { Attachment } from "./dtos/Attachment"

export interface CreateAttachmentResponse {
  attachment: Attachment
}

export const useAttachmentMutation = () => {
  const [uploadProggress, setUploadProggress] = useState(0)

  async function createAttachment(data: FormData) {
    try {
      setUploadProggress(0)
      const response = await api.post<CreateAttachmentResponse>('/attachments', data, {
        onUploadProgress: (proggressEvent: ProgressEvent) => {

          setUploadProggress(prevState => Math.round(proggressEvent.loaded * 100) / proggressEvent.total)
        }
      }
      )

      return response.data
    } catch (error) { }
  }

  const mutation = useMutation({
    mutationFn: createAttachment
  })

  return { mutation, uploadProggress }

}

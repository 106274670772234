import { usePagination } from "@ajna/pagination"
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { Pagination } from "components/Pagination/Pagination"
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FaEye } from "react-icons/fa";
import { PurchaseOrder } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { ViewPurchaseOrderModal } from "./components/ViewPurchaseOrderModal";
import { useRefusedPurchaseOrders } from "hooks/purchaseOrder/useRefusedPurchaseOrders";

export function PurchaseOrdersRefused() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect, location } = useHistory()

  const [purchaseOrder, setPurchaseOrder] = useState({} as PurchaseOrder)
  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)


  const userHasPermissionToViewRefusedPurchaseOrders =
    userLogged.permissions.includes('view-purchases-refused')

  useEffect(() => {
    if (!userHasPermissionToViewRefusedPurchaseOrders) {
      redirect('/')
    }
  }, [userHasPermissionToViewRefusedPurchaseOrders, redirect])

  const {
    data: purchaseOrdersRefusedResponseData,
    isFetching: isFetchingPurchaseOrdersRefusedData
  } = useRefusedPurchaseOrders({
    queryParams: {
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      pageSize: Number(rowsPerPage),
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: purchaseOrdersRefusedResponseData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      },
    });

  useEffect(() => {
    if (userHasPermissionToViewRefusedPurchaseOrders) {
      redirect({
        pathname: 'recusadas',
        search: `?page=${currentPage}`
      })
    }
  }, [currentPage, redirect, userHasPermissionToViewRefusedPurchaseOrders])

  const {
    isOpen: isViewPurchaseOrderModalOpen,
    onOpen: onOpenViewPurchaseOrderModal,
    onClose: onCloseViewPurchaseOrderModal,
  } = useDisclosure()

  const handleOpenViewPurchaseOrderModal = (purchaseOrder: PurchaseOrder) => {
    setPurchaseOrder(purchaseOrder)
    onOpenViewPurchaseOrderModal()
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (
    <>
      <ViewPurchaseOrderModal
        onClose={onCloseViewPurchaseOrderModal}
        isOpen={isViewPurchaseOrderModalOpen}
        purchaseOrder={purchaseOrder}
      />
      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        {isFetchingPurchaseOrdersRefusedData ? <Spinner /> :
          <Flex
            w="full"
            direction="column"
            gap={6}
          >
            <Heading fontSize="xl">Compras Recusadas</Heading>
            <Divider />
            <TableContainer>
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>PROTOCOLO</Th>
                    <Th>SOLICITANTE</Th>
                    <Th>DATA SOLICITAÇÃO</Th>
                    <Th>TIPO</Th>
                    <Th>DATA LIMITE</Th>
                    <Th>DATA DE RECUSA</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {purchaseOrdersRefusedResponseData?.purchaseOrders.map(purchaseOrder => {
                    return (
                      <Tr key={purchaseOrder.id}>
                        <Td>{purchaseOrder.protocol}</Td>
                        <Td>{`${purchaseOrder.owner.firstname} ${purchaseOrder.owner.lastname}`}</Td>
                        <Td>{formatDate.handle(purchaseOrder.created_at, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.type}</Td>
                        <Td>{formatDate.handle(purchaseOrder.arrival_limit_date, 'DateWithoutHourToShow')}</Td>
                        <Td>{formatDate.handle(purchaseOrder.refused_at, 'DateWithoutHourToShow')}</Td>
                        <Td isNumeric>
                          <Button
                            onClick={() => handleOpenViewPurchaseOrderModal(purchaseOrder)}
                            variant="ghost"
                          >
                            <Icon
                              cursor="pointer"
                              as={FaEye}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              pagesQuantity={pagesCount}
              handlePageChange={handleChangePage}
            />
          </Flex>
        }
      </Box>
    </>
  )
}

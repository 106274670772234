import { Button, Divider, Flex, Icon, IconButton, Stack } from "@chakra-ui/react"
import { Input } from "components/Inputs/Input"
import { UserProps } from "contexts/UserContext"
import { Control, DeepMap, FieldError, useFieldArray, UseFormRegister } from "react-hook-form"
import { FaPlus, FaTrash } from "react-icons/fa"
import { MonitoringUsersFormInputs } from "./MonitoringUsersForm"
import { MonitoringUsersPeriodResponsibleField } from "./MonitoringUsersPeriodResponsibleField"

interface MonitoringUsersPeriodFieldProps {
  index: number
  control: Control<MonitoringUsersFormInputs>
  register: UseFormRegister<MonitoringUsersFormInputs>
  users: UserProps[]
  errors: DeepMap<MonitoringUsersFormInputs, FieldError>
  onSetDeletedMonitoringUsersIds?: (monitoringId: string | string[]) => void
  isViewPage: boolean
}

export function MonitoringUsersPeriodField({
  control,
  index,
  register,
  users,
  errors,
  onSetDeletedMonitoringUsersIds,
  isViewPage
}: MonitoringUsersPeriodFieldProps) {

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    name: `monitoringDays.${index}.periods`,
    control
  })

  const handleRemovePeriodField = (index: number, monitoringIds: string[]) => {
    onSetDeletedMonitoringUsersIds(monitoringIds)
    remove(index)
  }

  const handleAppendPeriodField = () => append({
    startHour: undefined,
    endHour: undefined,
    responsibles: undefined
  })

  return (
    <Flex
      direction="column"
      gap="6"
      mt="4"
    >
      {fields.map((field, fieldIndex) => {
        return (
          <Stack key={field.id} spacing="6">
            <Stack direction={["column", "column", "row"]} spacing="6">
              <Input
                {...register(`monitoringDays.${index}.periods.${fieldIndex}.startHour`)}
                name={`monitoringDays.${index}.periods.${fieldIndex}.startHour`}
                type="time"
                error={
                  errors?.monitoringDays
                    ? errors?.monitoringDays[index]?.periods
                      ? errors?.monitoringDays[index]?.periods[fieldIndex]?.startHour
                      : undefined
                    : undefined
                }
                label="Horário inicial"
                isDisabled={isViewPage}
                required
              />
              <Input
                {...register(`monitoringDays.${index}.periods.${fieldIndex}.endHour`)}
                name={`monitoringDays.${index}.periods.${fieldIndex}.endHour`}
                type="time"
                error={
                  errors?.monitoringDays
                    ? errors?.monitoringDays[index]?.periods
                      ? errors?.monitoringDays[index]?.periods[fieldIndex]?.endHour
                      : undefined
                    : undefined
                }
                label="Horário final"
                isDisabled={isViewPage}
                required
              />
              <Button
                mt={["2", "2", "8"]}
                colorScheme="red"
                as={IconButton}
                onClick={() => handleRemovePeriodField(fieldIndex, field.responsibles.map((responsible) => responsible?.monitoringId))}
                icon={<Icon as={FaTrash} />}
                hidden={isViewPage}
              />
            </Stack>


            <MonitoringUsersPeriodResponsibleField
              control={control}
              register={register}
              index={index}
              periodIndex={fieldIndex}
              users={users}
              errors={errors}
              onSetDeletedMonitoringUsersIds={onSetDeletedMonitoringUsersIds}
              isViewPage={isViewPage}
            />

            <Divider />

          </Stack>
        )
      })}

      {!isViewPage && (
        <Button
          leftIcon={<Icon as={FaPlus} />}
          onClick={handleAppendPeriodField}
          alignSelf="flex-end"
          w={["full", "full", "min"]}
          colorScheme="blue"
        >
          Novo período
        </Button>
      )}
    </Flex>
  )

}

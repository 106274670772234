import { usePagination } from "@ajna/pagination"
import { Box, Button, Flex, Heading, IconButton, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { getAggregatesExtrasDiscounts } from "api/aggregates/getAggregatesExtrasDiscounts"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"
import { Pagination } from "components/Pagination/Pagination"
import { format } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { ExtraDiscount, ExtraDiscountStatus } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts"
import { useSearchParams } from "hooks/useSearchParams"
import { useEffect, useState } from "react"
import { FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { AggregateExtraDiscountTableRow } from "./components/AggregateExtraDiscountTableRow"
import { AggregatesExtrasDiscountsTableFilter } from "./components/AggregatesExtrasDiscountsTableFilter"


const headers = [
  { label: 'AGREGADO', key: 'aggregate' },
  { label: 'TIPO', key: 'type' },
  { label: 'DATA DO SERVIÇO', key: 'service_date' },
  { label: 'VALOR', key: 'value' },
  { label: 'ROTA', key: 'route' },
  { label: 'PROTOCOLO', key: 'protocol' },
  { label: 'DESCRIÇÃO', key: 'description' },
  { label: 'CRIADO POR', key: 'created_by' },
]

function formatValuesToReport(values: ExtraDiscount[]) {
  return values?.map((extraDiscount) => ({
    ...extraDiscount,
    aggregate: `${extraDiscount.aggregate.driver.firstname} ${extraDiscount.aggregate.driver.lastname} `,
    service_date: format(new Date(`${extraDiscount.service_date} 12:00`), 'dd/MM/yyy'),
    value: extraDiscount.value / 100,
    route: extraDiscount.service_route ?? '-',
    protocol: extraDiscount.services ? extraDiscount?.services?.map((service) => service.service.protocol).join(', ') : '-',
    created_by: extraDiscount?.createdBy ? `${extraDiscount?.createdBy.firstname} ${extraDiscount?.createdBy.lastname}` : '-',
  }))
}

export function AggregatesExtrasDiscounts() {
  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  const searchParams = useSearchParams()
  const { replace, push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userCanViewAggregateExtraDiscounts =
    userLogged?.permissions.includes('view-aggregate-extra-discount')
  const userCanCreateAggregateExtraDiscount = userLogged?.permissions?.includes('create-aggregate-extra-discount')

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }


  useEffect(() => {
    if (!userCanViewAggregateExtraDiscounts) {
      redirect('/')
    }
  }, [userCanViewAggregateExtraDiscounts, redirect])


  const status = searchParams.get('status')
  const page = searchParams.get('page') ?? '1'
  const aggregate = searchParams.get('aggregate')
  const endDate = searchParams.get('endDate')
  const startDate = searchParams.get('startDate')
  const route = searchParams.get('route')

  const {
    data: aggregatesExtrasDiscountsResult,
  } = useQuery({
    queryKey: [
      'aggregates-extras-discounts',
      page,
      status,
      route,
      aggregate,
      startDate,
      endDate,
    ],
    queryFn: () => getAggregatesExtrasDiscounts({
      currentPage: Number(page),
      pageSize: 10,
      status: ['requested', 'approved', 'effectivated', 'rejected'].includes(status) ? status as ExtraDiscountStatus : null,
      aggregate,
      endDate,
      startDate,
      route
    }),
    keepPreviousData: false,
  })

  const {
    data: aggregatesExtrasDiscountsReportResult,
    isLoading: isLoadingAggregatesExtrasDiscountsReportResult
  } = useQuery({
    queryKey: [
      'aggregates-extras-discounts-report',
      status,
      route,
      aggregate,
      startDate,
      endDate,
      aggregatesExtrasDiscountsResult?.count,
    ],
    queryFn: () => getAggregatesExtrasDiscounts({
      currentPage: 1,
      pageSize: aggregatesExtrasDiscountsResult?.count,
      status: ['requested', 'approved', 'effectivated', 'rejected'].includes(status) ? status as ExtraDiscountStatus : null,
      aggregate,
      endDate,
      startDate,
      route
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: aggregatesExtrasDiscountsResult?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 10
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const csvReportProps = {
    data: aggregatesExtrasDiscountsReportResult ? formatValuesToReport(aggregatesExtrasDiscountsReportResult?.extrasDiscounts) : [],
    headers,
    filename: `extras-e-descontos-agregados.csv`,
  }

  return (
    <Box
      rounded="md"
      p="6"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Extras/Descontos de Agregados</Heading>
        {userCanCreateAggregateExtraDiscount && (
          <IconButton
            aria-label="Criar extra/desconto"
            icon={<FaPlus />}
            colorScheme="blue"
            size="sm"
            as={Link}
            to="/extra-desconto/agregado/criar"
          />
        )}
      </Flex>
      <AggregatesExtrasDiscountsTableFilter />
      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Motorista</Th>
              <Th color="white">Tipo</Th>
              <Th color="white">Data do serviço</Th>
              <Th color="white">Valor</Th>
              <Th color="white">Rota</Th>
              <Th color="white">Protocolo</Th>
              <Th color="white">Criado por</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {aggregatesExtrasDiscountsResult?.extrasDiscounts?.map(extraDiscount => {
              return (
                <AggregateExtraDiscountTableRow
                  key={extraDiscount?.id}
                  extraDiscount={extraDiscount}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {!aggregatesExtrasDiscountsReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingAggregatesExtrasDiscountsReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}

      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getSporadics } from "api/sporadics/getSporadics";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreateSporadic } from "./components/CreateSporadic";
import { KanbanSporadicColumn } from "./components/KanbanSporadicColumn";
import { SporadicsTableFilter } from "./components/SporadicsTableFilter";


export function Sporadics() {

  const { userLogged } = useAuth()
  const history = useHistory()

  const userCanViewSporadics = userLogged?.permissions?.includes(
    'view-sporadics'
  )

  const userCanCreateSporadic = userLogged?.permissions?.includes(
    'create-sporadic'
  )

  useEffect(() => {
    if (!userCanViewSporadics) history.push('/')
  }, [history, userCanViewSporadics])

  const {
    isOpen: isCreateSporadicOpen,
    onOpen: onOpenCreateSporadic,
    onClose: onCloseCreateSporadic
  } = useDisclosure()

  const searchParams = useSearchParams()
  const protocol = searchParams.get('protocol')

  const {
    data: inBillingSporadicsResult,
    isLoading: isInBillingSporadicsResultLoading,
    hasNextPage: hasNextPageInBillingSporadics,
    fetchNextPage: fetchNextPageInBillingSporadics,
    isFetchingNextPage: isFetchingNextPageInBillingSporadics,
  } = useInfiniteQuery({
    queryKey: ['in-billing', protocol],
    queryFn: ({ pageParam = 1 }) => getSporadics({
      currentPage: pageParam,
      pageSize: 10,
      status: 'in-billing',
      protocol
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: billedSporadicsResult,
    isLoading: isBilledSporadicsResultLoading,
    hasNextPage: hasNextPageBilledSporadics,
    fetchNextPage: fetchNextPageBilledSporadics,
    isFetchingNextPage: isFetchingNextPageBilledSporadics,
  } = useInfiniteQuery({
    queryKey: ['billed', protocol],
    queryFn: ({ pageParam = 1 }) => getSporadics({
      currentPage: pageParam,
      pageSize: 10,
      status: 'billed',
      protocol
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: canceledSporadicsResult,
    isLoading: isCanceledSporadicsResultLoading,
    hasNextPage: hasNextPageCanceledSporadics,
    fetchNextPage: fetchNextPageCanceledSporadics,
    isFetchingNextPage: isFetchingNextPageCanceledSporadics,
  } = useInfiniteQuery({
    queryKey: ['canceled', protocol],
    queryFn: ({ pageParam = 1 }) => getSporadics({
      currentPage: pageParam,
      pageSize: 10,
      status: 'canceled',
      protocol
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const columnsSporadics = [
    {
      title: 'Em Faturamento',
      sporadics: inBillingSporadicsResult?.pages.flatMap((page) => page.sporadics) || [],
      sporadicsCount: inBillingSporadicsResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageInBillingSporadics,
      fetchNextPage: fetchNextPageInBillingSporadics,
      isFetchingNextPage: isFetchingNextPageInBillingSporadics,
      isLoading: isInBillingSporadicsResultLoading
    },
    {
      title: 'Faturado',
      sporadics: billedSporadicsResult?.pages.flatMap((page) => page.sporadics) || [],
      sporadicsCount: billedSporadicsResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageBilledSporadics,
      fetchNextPage: fetchNextPageBilledSporadics,
      isFetchingNextPage: isFetchingNextPageBilledSporadics,
      isLoading: isBilledSporadicsResultLoading
    },
    {
      title: 'Cancelado',
      sporadics: canceledSporadicsResult?.pages.flatMap((page) => page.sporadics) || [],
      sporadicsCount: canceledSporadicsResult?.pages[0]?.meta?.count,
      color: 'red.300',
      hasNextPage: hasNextPageCanceledSporadics,
      fetchNextPage: fetchNextPageCanceledSporadics,
      isFetchingNextPage: isFetchingNextPageCanceledSporadics,
      isLoading: isCanceledSporadicsResultLoading
    },
  ]

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Esporádicos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/esporadicos/tabela')}
          />
          {userCanCreateSporadic && (
            <IconButton
              aria-label="Criar esporadico"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateSporadic}
            />
          )}
        </Flex>

        <Modal
          isOpen={isCreateSporadicOpen}
          onClose={onCloseCreateSporadic}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreateSporadic onClose={onCloseCreateSporadic} />
        </Modal>
      </Flex>
      <SporadicsTableFilter
        hasStatusFilter={false}
      />
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsSporadics.map((sporadic, index) => (
          <KanbanSporadicColumn
            key={sporadic?.title}
            sporadic={sporadic}
          />
        )
        )}
      </Flex>
    </Box>
  )
}

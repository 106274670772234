import { usePagination } from "@ajna/pagination";
import { Box, Button, Divider, Flex, Heading, Icon, Menu, MenuButton, MenuDivider, MenuGroup, MenuList, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Pagination } from "components/Pagination/Pagination";

import { ConsultOccurrencesProvider } from "contexts/ConsultOccurrencesContext";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect, useMemo, useState } from "react";
import { FaCalendar, FaChevronDown, FaFilter } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { ConsultOccurrencesCheckboxFilterButton } from "./components/ConsultOccurrencesCheckboxFilterButton/index"
import { ConsultOcurrencesTable } from "./components/ConsultOccurrencesTable";
import { QueryOccurrences } from "./components/QueryOccurrences";

export interface FilterOptions {
  showValue: string
  value: string
  selected: boolean
}

const generalFilterOptions = [
  { showValue: 'Intercorrência', value: 'intercurrence', selected: false },
  { showValue: 'Tipo', value: 'type', selected: false },
  { showValue: 'Etapa', value: 'step', selected: false },
  { showValue: 'Data de Abertura', value: 'date', selected: false },
]

const intercurrenceFilterOptions = [
  { showValue: 'DECLARAÇÃO NÃO PREENCHIDA CORRETAMENTE', value: 'DECLARAÇÃO NÃO PREENCHIDA CORRETAMENTE', selected: false },
  { showValue: 'CTE FALTANDO VOLUME', value: 'CTE FALTANDO VOLUME', selected: false },
  { showValue: 'RETORNO DE COLETA/ENTREGA', value: 'RETORNO DE COLETA/ENTREGA', selected: false },
  { showValue: 'ATRASO NA COLETA DO MEU MATERIAL', value: 'ATRASO NA COLETA DO MEU MATERIAL', selected: false },
  { showValue: 'ATRASO NA ENTREGA DO MEU MATERIAL', value: 'ATRASO NA ENTREGA DO MEU MATERIAL', selected: false },
  { showValue: 'PROBLEMA DE POSTURA/ATENDIMENTO DA LOGLIFE', value: 'PROBLEMA DE POSTURA/ATENDIMENTO DA LOGLIFE', selected: false },
  { showValue: 'CAIXA RECEBIDA FORA DO PADRÃO', value: 'CAIXA RECEBIDA FORA DO PADRÃO', selected: false },
  { showValue: 'OUTRAS COBRANÇAS', value: 'OUTRAS COBRANÇAS', selected: false },
  { showValue: 'DECLARAÇÃO DE CONTEÚDO NÃO CONFORME', value: 'DECLARAÇÃO DE CONTEÚDO NÃO CONFORME', selected: false },
  { showValue: 'ENTREGA EM LOCAL ERRADO', value: 'ENTREGA EM LOCAL ERRADO', selected: false },
  { showValue: 'FALTA DE FOTO CAIXA', value: 'FALTA DE FOTO CAIXA', selected: false },
  { showValue: 'FALTA DE FOTO DECLARAÇÃO DE CONTEÚDO', value: 'FALTA DE FOTO DECLARAÇÃO DE CONTEÚDO', selected: false },
  { showValue: 'INSUMOS ERRADOS', value: 'INSUMOS ERRADOS', selected: false },
  { showValue: 'ALTERAÇÃO DE INSUMOS UTILIZADOS', value: 'ALTERAÇÃO DE INSUMOS UTILIZADOS', selected: false },
  { showValue: 'NECESSIDADE DE MANUTENÇÃO DE REFRIGERAÇÃO', value: 'NECESSIDADE DE MANUTENÇÃO DE REFRIGERAÇÃO', selected: false },
  { showValue: 'ATRASO NA COLETA', value: 'ATRASO NA COLETA', selected: false },
  { showValue: 'ATRASO NA ENTREGA', value: 'ATRASO NA ENTREGA', selected: false },
  { showValue: 'RECLAMAÇÃO DE CLIENTE', value: 'RECLAMAÇÃO DE CLIENTE', selected: false },
  { showValue: 'NO SHOW', value: 'NO SHOW', selected: false },
  { showValue: 'ENTREGA NO LOCAL ERRADO', value: 'ENTREGA NO LOCAL ERRADO', selected: false },
  { showValue: 'CAIXAS NÃO IDENTIFICADAS', value: 'CAIXAS NÃO IDENTIFICADAS', selected: false },
  { showValue: 'FALTA DE LANÇAMENTO NO SISTEMA', value: 'FALTA DE LANÇAMENTO NO SISTEMA', selected: false },
  { showValue: 'FALTA DE FOTO CAIXA/CTE', value: 'FALTA DE FOTO CAIXA/CTE', selected: false },
  { showValue: 'FALTA DE FOTO DECLARAÇÃO', value: 'FALTA DE FOTO DECLARAÇÃO', selected: false },
  { showValue: 'REPOSIÇÃO DE INSUMOS', value: 'REPOSIÇÃO DE INSUMOS', selected: false },
  { showValue: 'SERVIÇO EXTRA', value: 'SERVIÇO EXTRA', selected: false },
  { showValue: 'EMBARQUE ERRADO', value: 'EMBARQUE ERRADO', selected: false },
  { showValue: 'ATRASO AUDITORIA', value: 'ATRASO AUDITORIA', selected: false },
  { showValue: 'USO DE DECLARAÇÃO GENÉRICA', value: 'USO DE DECLARAÇÃO GENÉRICA', selected: false },
  { showValue: 'CARGA EXTRAVIADA', value: 'CARGA EXTRAVIADA', selected: false },
  { showValue: 'ATRASO DEVIDO AO SISTEMA', value: 'ATRASO DEVIDO AO SISTEMA', selected: false },
  { showValue: 'ALTERAÇÃO DE EMBARQUE', value: 'ALTERAÇÃO DE EMBARQUE', selected: false },
  { showValue: 'TENTATIVA DE ENTREGA SEM SUCESSO', value: 'TENTATIVA DE ENTREGA SEM SUCESSO', selected: false },
  { showValue: 'TEMPO DE ESPERA PELA MATRIZ', value: 'TEMPO DE ESPERA PELA MATRIZ', selected: false },
  { showValue: 'VALOR DO CTE DIVERGENTE DA TABELA', value: 'VALOR DO CTE DIVERGENTE DA TABELA', selected: false },
  { showValue: 'TRANSPORTADORA SEM SISTEMA', value: 'TRANSPORTADORA SEM SISTEMA', selected: false },
  { showValue: 'CARGA AVARIADA', value: 'CARGA AVARIADA', selected: false },
  { showValue: 'DESVIO DE BUDGET DE TRANSFERÊNCIA', value: 'DESVIO DE BUDGET DE TRANSFERÊNCIA', selected: false },
  { showValue: 'RETENÇÃO FISCAL', value: 'RETENÇÃO FISCAL', selected: false },
  { showValue: 'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)', value: 'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)', selected: false },
  { showValue: 'CANCELAMENTO DE VOO', value: 'CANCELAMENTO DE VOO', selected: false },
  { showValue: 'ATRASO NA TRANSFERÊNCIA', value: 'ATRASO NA TRANSFERÊNCIA', selected: false },
];

const occurrenceTypeFilterOptions = [
  { showValue: 'COLETADOR', value: 'COLETADOR', selected: false },
  { showValue: 'MOTORISTA', value: 'MOTORISTA', selected: false },
  { showValue: 'CLIENTE', value: 'CLIENTE', selected: false },
  { showValue: 'TRANSPORTADORA', value: 'TRANSPORTADORA', selected: false },
];

const occurrenceStepFilterOptions = [
  { showValue: 'AGUARDANDO VALIDAÇÃO', value: 'AWAITING-PROCEDURE', selected: false },
  { showValue: 'AGUARDANDO ALINHAMENTO', value: 'AWAITING-ALIGNMENT', selected: false },
  { showValue: 'AGUARDANDO ANEXO DE EVIDÊNCIAS', value: 'AWAITING-ATTACHMENT', selected: false },
  { showValue: 'FINALIZADA', value: 'OCCURRENCE-FINALIZED', selected: false },
]

export function ConsultOccurrences() {
  const [totalPages, setTotalPages] = useState(1)
  const [startFilter, setStartFilter] = useState<string>('')
  const [startFilterDebounce, setStartFilterDebounce] = useState<string | null>(null)
  const [endFilter, setEndFilter] = useState<string>('')
  const [endFilterDebounce, setEndFilterDebounce] = useState<string | null>(null)
  const [generalFilters, setGeneralFilters] = useState<FilterOptions[]>(generalFilterOptions)
  const [occurrenceStepFilters, setOccurrenceStepFilters] = useState<FilterOptions[]>(occurrenceStepFilterOptions)
  const [occurrenceTypeFilters, setOccurrenceTypeFilters] = useState<FilterOptions[]>(occurrenceTypeFilterOptions)
  const [intercurrenceFilters, setIntercurrenceFilters] = useState<FilterOptions[]>(intercurrenceFilterOptions)

  const handleSetTotalPages = (quantity: number) => setTotalPages(quantity)

  useEffect(() => {
    const debounce = setTimeout(() => {
      setStartFilterDebounce(startFilter)
    }, 500)

    return () => clearTimeout(debounce)

  }, [startFilter])

  useEffect(() => {
    const debounce = setTimeout(() => {
      setEndFilterDebounce(endFilter)
    }, 500)

    return () => clearTimeout(debounce)

  }, [endFilter])

  const startFilterFormatted = new Date(startFilter) instanceof Date && !isNaN(new Date(startFilter).getMilliseconds())
    ? format(parseISO(startFilter), "dd 'de' LLL", {
      locale: ptBR
    })
    : null

  const endFilterFormatted = new Date(endFilter) instanceof Date && !isNaN(new Date(endFilter).getMilliseconds())
    ? format(parseISO(endFilter), "dd 'de' LLL", {
      locale: ptBR
    })
    : null

  const { userLogged } = useAuth()
  const { push: redirect, location } = useHistory()

  const userLoggedHasConsultOccurrencesPermission = userLogged?.permissions.includes('consult-occurrence')

  useEffect(() => {
    if (!userLoggedHasConsultOccurrencesPermission) {
      redirect('/')
    }
  }, [redirect, userLoggedHasConsultOccurrencesPermission])

  const intercurrencesFiltersSelected = intercurrenceFilters.filter(
    (intercurrenceFilter) => intercurrenceFilter.selected
  )

  const intercurrencesFiltersSelectedValues = intercurrencesFiltersSelected.map(
    (intercurrenceFilter) => intercurrenceFilter.value
  )

  const intercurrencesFiltersSelectedCount = intercurrencesFiltersSelected.length

  const occurrenceTypeFiltersSelected = occurrenceTypeFilters.filter(
    (occurrenceTypeFilter) => occurrenceTypeFilter.selected
  )

  const occurrenceTypeFiltersSelectedValues = occurrenceTypeFiltersSelected.map(
    (occurrenceTypeFilter) => occurrenceTypeFilter.value
  )

  const occurrenceTypeFiltersSelectedCount = occurrenceTypeFiltersSelected.length

  const occurrenceStepFiltersSelected = occurrenceStepFilters.filter(
    (occurrenceStepFilter) => occurrenceStepFilter.selected
  )

  const occurrenceStepFiltersSelectedValues = occurrenceStepFiltersSelected.map(
    (occurrenceStepFilter) => occurrenceStepFilter.value
  )

  const occurrenceStepFiltersSelectedCount = occurrenceStepFilters.filter(
    (occurrenceStepFilter) => occurrenceStepFilter.selected
  ).length

  function handleSetIntercurrenceFilter(intercurrence: string) {
    const newIntercurrenceFilters = [...intercurrenceFilters]

    const intercurrenceFilterSelected = newIntercurrenceFilters.find(
      (intercurrenceFilter) => intercurrenceFilter.value === intercurrence
    )

    intercurrenceFilterSelected.selected = !intercurrenceFilterSelected.selected

    setIntercurrenceFilters(() => newIntercurrenceFilters)
  }

  function handleSetOccurrenceTypeFilter(occurrenceType: string) {
    const newOccurrenceTypesFilters = [...occurrenceTypeFilters]

    const occurrenceTypeFilterSelected = newOccurrenceTypesFilters.find(
      (occurrenceTypeFilter) => occurrenceTypeFilter.value === occurrenceType
    )

    occurrenceTypeFilterSelected.selected = !occurrenceTypeFilterSelected.selected

    setOccurrenceTypeFilters(() => newOccurrenceTypesFilters)
  }

  function handleSetOccurrenceStepFilter(step: string) {
    const newOccurrenceStepFilters = [...occurrenceStepFilters]

    const occurrenceStepSelected = newOccurrenceStepFilters.find(
      (occurrenceTypeFilter) => occurrenceTypeFilter.value === step
    )

    occurrenceStepSelected.selected = !occurrenceStepSelected.selected

    setOccurrenceStepFilters(() => newOccurrenceStepFilters)
  }

  function handleSetGeneralFilter(filterType: string) {
    const newGeneralFilters = [...generalFilters]

    const generalFilterSelected = newGeneralFilters.find(
      (generalFilter) => generalFilter.value === filterType
    )

    generalFilterSelected.selected = !generalFilterSelected.selected


    if (filterType === 'intercurrence' && generalFilterSelected.selected === false) {
      setIntercurrenceFilters(prevIntercurrenceFilterOptions => prevIntercurrenceFilterOptions.map((option) => {
        return {
          ...option,
          selected: false
        }
      }))
    }

    if (filterType === 'type' && generalFilterSelected.selected === false) {
      setOccurrenceTypeFilters(prevOccurrenceTypeFilterOptions => prevOccurrenceTypeFilterOptions.map((option) => {
        return {
          ...option,
          selected: false
        }
      }))
    }

    if (filterType === 'step' && generalFilterSelected.selected === false) {
      setOccurrenceStepFilters(prevOccurrenceStepFilters => prevOccurrenceStepFilters.map((option) => {
        return {
          ...option,
          selected: false
        }
      }))
    }

    if (filterType === 'date' && generalFilterSelected.selected === false) {
      setStartFilter('')
      setEndFilter('')
    }

    setGeneralFilters(() => newGeneralFilters)
  }

  const generalFiltersSelected = useMemo(() => {
    return generalFilters
      .filter((generalFilter) => generalFilter.selected)
      .map((generalFilter) => generalFilter.value)
  }, [generalFilters])

  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)


  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: totalPages,
      initialState: {
        pageSize: Number(10),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    if (userLoggedHasConsultOccurrencesPermission) {
      redirect({
        pathname: '/ocorrencias/consultar',
        search: `page=${currentPage}`
      })
    }
  }, [redirect, currentPage, userLoggedHasConsultOccurrencesPermission])

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
        boxShadow="md"
      >
        <Heading size="lg">Consultar Ocorrências</Heading>
        <ConsultOccurrencesProvider>
          <Flex
            w="full"
            align="center"
            justify="flex-end"

          >
            <Flex
              gap="4"
              w={["full", "full", "min"]}
              direction={["column", "column", "row"]}
            >
              <QueryOccurrences />

              <ConsultOccurrencesCheckboxFilterButton.Root
                title="Filtros"
                icon={FaFilter}
              >
                <ConsultOccurrencesCheckboxFilterButton.Content
                  filterOptions={generalFilterOptions}
                  onSetFilterOption={handleSetGeneralFilter}
                />

              </ConsultOccurrencesCheckboxFilterButton.Root>

            </Flex>

          </Flex>

          <Divider mt="4" />

          <Flex
            mt="4"
            gap="4"
            p="2"
            overflowX="auto"
          >
            {generalFiltersSelected.includes('type') && (
              <ConsultOccurrencesCheckboxFilterButton.Root
                title="Tipo"
                icon={FaChevronDown}
                checkedOptionsCount={occurrenceTypeFiltersSelectedCount}
              >
                <ConsultOccurrencesCheckboxFilterButton.Content
                  filterOptions={occurrenceTypeFilters}
                  onSetFilterOption={handleSetOccurrenceTypeFilter}
                />
              </ConsultOccurrencesCheckboxFilterButton.Root>
            )}

            {generalFiltersSelected.includes('intercurrence') && (
              <ConsultOccurrencesCheckboxFilterButton.Root
                title="Intercorrência"
                icon={FaChevronDown}
                checkedOptionsCount={intercurrencesFiltersSelectedCount}
              >
                <ConsultOccurrencesCheckboxFilterButton.Content
                  filterOptions={intercurrenceFilters}
                  onSetFilterOption={handleSetIntercurrenceFilter}
                />
              </ConsultOccurrencesCheckboxFilterButton.Root>
            )}

            {generalFiltersSelected.includes('step') && (
              <ConsultOccurrencesCheckboxFilterButton.Root
                title="Etapa"
                icon={FaChevronDown}
                checkedOptionsCount={occurrenceStepFiltersSelectedCount}
              >
                <ConsultOccurrencesCheckboxFilterButton.Content
                  filterOptions={occurrenceStepFilters}
                  onSetFilterOption={handleSetOccurrenceStepFilter}
                />
              </ConsultOccurrencesCheckboxFilterButton.Root>
            )}

            {generalFiltersSelected.includes('date') && (
              <Menu>
                <MenuButton
                  as={Button}
                  variant="outline"
                  rounded="lg"
                  fontWeight="normal"
                  boxShadow="base"
                  display="flex"
                  alignItems="center"
                  rightIcon={
                    <Icon as={FaCalendar} />
                  }
                  _focus={{
                    outlineOffset: 0,
                    outlineColor: "#38c3fa",
                    outlineWidth: 2,
                  }}
                >
                  {startFilterFormatted && endFilterFormatted ? (
                    <Text>{startFilterFormatted} - {endFilterFormatted}</Text>
                  ) : (
                    <Text>Data</Text>
                  )}
                </MenuButton>
                <MenuList
                  boxShadow="md"
                >
                  <MenuGroup
                    title="Datas"
                  >
                    <MenuDivider />
                    <Flex
                      gap="4"
                      direction="row"
                      px="4"
                      pb="4"
                    >
                      <Flex
                        direction="column"
                      >
                        <Text fontSize="sm">Data inicial</Text>
                        <Input
                          name="startFilter"
                          type="date"
                          value={startFilter}
                          onChange={(event) => setStartFilter(event.target.value)}
                          size="sm"
                          rounded="md"
                          boxShadow="base"
                        />
                      </Flex>

                      <Flex
                        direction="column"
                      >
                        <Text fontSize="sm">Data final</Text>
                        <Input
                          name="endFilter"
                          type="date"
                          value={endFilter}
                          onChange={(event) => setEndFilter(event.target.value)}
                          size="sm"
                          rounded="md"
                          boxShadow="base"
                        />
                      </Flex>
                    </Flex>
                  </MenuGroup>
                </MenuList>
              </Menu>
            )}

          </Flex>

          <ConsultOcurrencesTable
            startFilterDebounce={startFilterDebounce}
            endFilterDebounce={endFilterDebounce}
            currentPageFilter={currentPageFilter}
            occurrenceTypeFiltersSelectedValues={occurrenceTypeFiltersSelectedValues}
            occurrenceStepFiltersSelectedValues={occurrenceStepFiltersSelectedValues}
            intercurrencesFiltersSelectedValues={intercurrencesFiltersSelectedValues}
            onSetTotalPages={handleSetTotalPages}
          />
        </ConsultOccurrencesProvider>

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Box>

  )
}

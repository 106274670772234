import { FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

interface WorkAccidentIntercurrenceFormSchema {
  isTrajectoryOrOccupationalAccident: string
  accidentAddress: string
  bodyPart: string
  medicalCareTime: string
  medicalCertificateCid: string
  medicalName: string
  medicalCrm: string
}

export function WorkAccidentCltIntercurrenceForm() {

  const {
    control,
    register,
    formState: {
      errors,
    }
  } = useFormContext<WorkAccidentIntercurrenceFormSchema>()

  return (
    <>
      <FormControl isInvalid={!!errors.isTrajectoryOrOccupationalAccident}>
        <FormLabel>
          Foi acidente de trajeto, durante a jornada ou doença ocupacional?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isTrajectoryOrOccupationalAccident"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="column">
                <Radio value="yes">Sim</Radio>
                <Radio value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.accidentAddress}>
        <FormLabel>
          Endereço do acidente
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('accidentAddress')}
        />
      </FormControl>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="3"
      >

        <FormControl isInvalid={!!errors.bodyPart}>
          <FormLabel>
            Parte do corpo atingida
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('bodyPart')}
          />
        </FormControl>


        <FormControl isInvalid={!!errors.medicalCareTime}>
          <FormLabel>
            Horário do atendimento médico
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('medicalCareTime')}
            type="time"
          />
        </FormControl>

      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="3"
      >

        <FormControl isInvalid={!!errors.medicalCertificateCid}>
          <FormLabel>
            CID do atestado médico
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('medicalCertificateCid')}
          />
        </FormControl>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing="3"
          w="full"
        >
          <FormControl isInvalid={!!errors.medicalName}>
            <FormLabel>
              Nome do médico
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('medicalName')}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.medicalCrm}>
            <FormLabel>
              CRM do médico
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('medicalCrm')}
            />
          </FormControl>
        </Stack>

      </Stack>
    </>
  )
}

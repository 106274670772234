import { usePagination } from "@ajna/pagination";
import { Box, Heading, IconButton, Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { TrainingStatus as TrainingStatusTypes } from "api/trainings/training";
import { getTrainingsReport } from "api/trainings/getTrainingsReport";
import { Pagination } from "components/Pagination/Pagination";

import { format } from "date-fns";
import { useSearchParams } from "hooks/useSearchParams";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { trainingStatusMap } from "utils/trainingMappers";
import { TrainingStatus } from "./components/TrainingStatus";
import { TrainingsReportFilters } from "./components/TrainingsReportFilters";

const headers = [
  { label: 'Regional', key: 'regional.code' },
  { label: 'Colaborador', key: 'collaborator_name' },
  { label: 'Data de vencimento', key: 'due_date' },
  { label: 'Status', key: 'status' },
  { label: 'Anexo (Treinamento)', key: 'trainingAttachment.link' },
  { label: 'Anexo (Certificado)', key: 'certificateAttachment.link' },
]

export function TrainingsReport() {
  const searchParams = useSearchParams()

  const status = searchParams.get('status') ?? 'all'
  const collaborator = searchParams.get('collaborator')

  const {
    data: trainingsReportData
  } = useQuery({
    queryKey: ['trainings-report', status, collaborator],
    queryFn: () => getTrainingsReport({
      collaborator,
      status: status as TrainingStatusTypes | 'all',
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!status
  })

  const csvProps = {
    data: trainingsReportData ? trainingsReportData?.trainings.map(training => {
      return {
        ...training,
        due_date: training.due_date ? format(new Date(training.due_date), 'dd/MM/yyyy') : '-',
        status: trainingStatusMap[training.status],
        realization_date: format(new Date(training.realization_date), 'dd/MM/yyyy'),
      }
    })
      : [],
    headers,
    filename: `relatório-treinamentos-${new Date().toISOString()}.csv`,
  }

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
      >
        <Heading letterSpacing="tight" >Relatório de Treinamentos</Heading>

        <TrainingsReportFilters csvProps={csvProps} />

        {trainingsReportData && (
          <TableContainer mt="6">
            <Table
              size="sm"
            >
              <Thead>
                <Tr>
                  <Th>Regional</Th>
                  <Th>Colaborador</Th>
                  <Th>Data de vencimento</Th>
                  <Th>Status</Th>
                  <Th>Data de Realização</Th>
                  <Th>Anexo Certificado</Th>
                  <Th>Anexo Treinamento</Th>
                </Tr>
              </Thead>

              <Tbody>
                {trainingsReportData?.trainings.slice(offset, offset + 10).map((training) => {

                  return (
                    <Tr key={training.id}>
                      <Td>{training.regional ? training?.regional.code.toUpperCase() : '-'}</Td>
                      <Td>{training.collaborator_name}</Td>
                      <Td>{training.due_date ? format(new Date(training.due_date), 'dd/MM/yyyy') : '-'}</Td>
                      <Td>
                        <TrainingStatus status={training.status} />
                      </Td>
                      <Td>{training.realization_date ? format(new Date(training.realization_date), 'dd/MM/yyyy') : '-'}</Td>
                      <Td>
                        {training?.certificateAttachment ? (
                          <IconButton
                            aria-label="Abrir anexo de certificado"
                            as={Link}
                            href={training.certificateAttachment.link}
                            isExternal
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                          />
                        ) : '-'}
                      </Td>
                      <Td>
                        {training?.trainingAttachment ? (
                          <IconButton
                            aria-label="Abrir anexo de treinamento"
                            as={Link}
                            href={training.trainingAttachment.link}
                            isExternal
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                          />
                        ) : '-'}
                      </Td>

                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          handlePageChange={handleChangePage}
          pagesQuantity={pagesCount}
          pages={pages}
          currentPage={currentPage}
        />


      </Box>


  )
}

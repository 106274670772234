import { Box, Card, CardBody, CardHeader, Flex, Grid, GridItem, Icon, IconButton, Popover, PopoverTrigger, Portal, Table, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react"
import { getNextToDelayCollects } from "api/service/getNextToDelayCollects"
import { InfoCardsLoading } from "pages/Dashboard/components/InfoCardsLoading"
import { Interactions } from "pages/Dashboard/components/Interactions"
import { useEffect } from "react"
import { FaInfoCircle, FaPeopleArrows } from "react-icons/fa"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { captalize } from "utils/captalize"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"
import { interactionStatusColors } from "./DelayedCollects"

interface NextToDelayCollectsProps {
  onSetIsError: () => void
}

export function NextToDelayCollects({ onSetIsError }: NextToDelayCollectsProps) {

  const {
    data: result,
    isError,
    isLoading: isLoadingNextToDelayCollectsResult
  } = useQuery({
    queryKey: ['next-to-delay-collects'],
    queryFn: getNextToDelayCollects,
    refetchInterval: 1000 * 60 * 2, // 2 minutes,
    refetchIntervalInBackground: true,
  })

  useEffect(() => {
    if (isError) onSetIsError()
  }, [isError, onSetIsError])

  return (
    <Grid
      gap="6"
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
    >
      {isLoadingNextToDelayCollectsResult && (
        <InfoCardsLoading />
      )}
      {result?.services?.map((service) => {
        return (
          <GridItem
            key={service.id}
          >
            <Card
              rounded="xl"
              transition="all 0.2s"
              _hover={{
                transform: 'translateY(-0.5rem)'
              }}
              h="full"
            >
              <CardHeader
                as={Link}
                display="flex"
                alignItems="center"
                to={`/servico/detalhes/${service.id}`}
                h="40px"
                bg="#38c3fa"
                borderTopRadius="xl"
              >
                <Text fontWeight="bold" color="white">{service.protocol}</Text>
              </CardHeader>

              <CardBody
                as={Flex}
                gap="6"
                direction="column"
              >
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td fontSize='lg'>Status</Td>
                      <Td isNumeric fontSize='lg'>{captalize(serviceHandleStep(service.step))}</Td>
                    </Tr>
                    <Tr>
                      <Td fontSize='lg'>Cliente</Td>
                      <Td isNumeric fontSize='lg'>{captalize(service.customer)}</Td>
                    </Tr>
                    <Tr>
                      <Td fontSize='lg'>
                        <Flex>
                          Hub de Origem
                          {Number(service.source_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                            <Tooltip label={service.source_tz}>
                              <Box as="span" ml="2">
                                <Icon as={FaInfoCircle} />
                              </Box>
                            </Tooltip>
                          )}
                        </Flex>
                      </Td>
                      <Td isNumeric fontSize='lg'>{captalize(service.source_hub)}</Td>
                    </Tr>
                    <Tr>
                      <Td fontSize='lg'>
                        <Flex>
                          Hub de Destino
                          {Number(service.destination_tz.split('-')[1]) !== new Date().getTimezoneOffset() / 60 && (
                            <Tooltip label={service.destination_tz}>
                              <Box as="span" ml="2">
                                <Icon as={FaInfoCircle} />
                              </Box>
                            </Tooltip>
                          )}
                        </Flex>
                      </Td>
                      <Td isNumeric fontSize='lg'>{captalize(service.destination_hub)}</Td>
                    </Tr>
                  </Tbody>
                </Table>

                <Flex h="full" justify="flex-end" align="flex-end">
                  <Popover isLazy>
                    {({ isOpen }) => {
                      return (
                        <>
                          <PopoverTrigger>
                            <IconButton
                              aria-label="Abrir Interações"
                              icon={<FaPeopleArrows />}
                              colorScheme={interactionStatusColors[service.last_interaction_status] || interactionStatusColors.default}
                              size="sm"
                            />
                          </PopoverTrigger>
                          <Portal>
                            <Interactions isOpen={isOpen} serviceId={service.id} />
                          </Portal>
                        </>
                      )
                    }}

                  </Popover>
                </Flex>
              </CardBody>
            </Card>
          </GridItem>
        )
      })}
    </Grid>
  )
}

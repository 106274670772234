import { Box, Flex } from "@chakra-ui/react";
import { DocumentStatus as DocumentStatusTypes } from "api/documents/types/Document";
import { documentStatusMap } from "utils/documentMappers";

interface DocumentStatusProps {
  status: DocumentStatusTypes;
}

export function DocumentStatus({ status }: DocumentStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {(status === 'non-evaluated' || status === 'expired') && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'active' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'inactive' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}
      {documentStatusMap[status]}
    </Flex>
  )
}

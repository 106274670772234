import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface GetCustomersUsersDashboardMetricsParams {
  customerId: string
}

export interface GetCustomersUsersDashboardMetricsResponse {
  inBoardServicesCount: number
  inCollectServicesCount: number
  inDeliveryServicesCount: number
  inLandingServicesCount: number
}

export async function getCustomersUsersDashboardMetrics({
  customerId
}: GetCustomersUsersDashboardMetricsParams) {
  try {
    const response = await api.get<GetCustomersUsersDashboardMetricsResponse>(`/metrics/dashboard/customers`, {
      params: { customerId }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

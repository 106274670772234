import { format, toDate } from 'date-fns'
import { UserAuthProps } from '../../contexts/AuthContext'

interface DataProps {
  find_by: string
  shipping_id: string
  driver_id: string
  customer_id: string
  collector_id: string
  startFilter: string
  endFilter: string
}

const reportFormatDataToBack = (
  data: DataProps,
  userLogged: UserAuthProps | null,
) => {
  const startFilterSplited =
    data.startFilter !== ' ' ? data.startFilter.split('-') : null
  const startFilterSplitedToDate =
    startFilterSplited !== null
      ? toDate(
          new Date(
            Number(startFilterSplited[0]),
            Number(startFilterSplited[1]) - 1,
            Number(startFilterSplited[2]),
            0,
            0,
          ),
        )
      : null
  const formatedStartFilterSplited =
    startFilterSplitedToDate !== null
      ? format(startFilterSplitedToDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      : ''

  const endFilterSplited =
    data.endFilter !== ' ' ? data.endFilter.split('-') : null
  const endFilterSplitedToDate =
    endFilterSplited !== null
      ? toDate(
          new Date(
            Number(endFilterSplited[0]),
            Number(endFilterSplited[1]) - 1,
            Number(endFilterSplited[2]),
            23,
            59,
          ),
        )
      : null
  const formatedEndFilterSplited =
    endFilterSplitedToDate !== null
      ? format(endFilterSplitedToDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
      : ''

  const newReportFormatDate = {
    shipping_id: data.shipping_id === '' ? null : data.shipping_id,
    is_driver: data.find_by === 'MOTORISTA',
    customer_id: data.customer_id === '' ? null : data.customer_id,
    collector_id: data.collector_id === '' ? null : data.collector_id,
    startFilter: formatedStartFilterSplited,
    endFilter: formatedEndFilterSplited,
    is_customer: userLogged?.user_type === 'CLIENTE',
    is_collector: userLogged?.user_type === 'COLETADOR',
  }

  return newReportFormatDate
}

export { reportFormatDataToBack }

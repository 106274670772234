import { useMutation, useQueryClient } from 'react-query'
import {
  createRequestedBusinessServiceReqFunction,
  deleteRequestedBusinessServiceReqFunction,
  editRequestedBusinessServiceReqFunction,
  EditRequestedBusinessServiceReqProps,
  RequestedBusinessServiceInputProps,
} from '../../../utils/RequestFunctions/Service/RequestedBusiness/requestRequestedBusinessServiceFunctions'
import { useAuth } from '../../auth/useAuth'
import { useSwal } from '../../swal/useSwal'

export const useRequestedBusinessServiceFunctions = () => {
  const queryClient = useQueryClient()
  const { handleLogout } = useAuth()
  const { successMessage, errorMessage } = useSwal()

  const createRequestedBusinessService = useMutation(
    'createRequestedBusinessService',
    (input: RequestedBusinessServiceInputProps) =>
      createRequestedBusinessServiceReqFunction(input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['requestedBusinessServices'])
        queryClient.invalidateQueries(['requestedBusinessService'])
        successMessage('Serviço business solicitado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.message.includes('Token')) handleLogout()
      },
    },
  )

  const editRequestedBusinessService = useMutation(
    'editRequestedBusinessService',
    ({ id, input }: EditRequestedBusinessServiceReqProps) =>
      editRequestedBusinessServiceReqFunction({ id, input }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['requestedBusinessServices'])
        queryClient.invalidateQueries(['requestedBusinessService'])
        successMessage('Serviço business editado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.message.includes('Token')) handleLogout()
      },
    },
  )

  const deleteRequestedBusinessService = useMutation(
    'deleteRequestedBusinessService',
    (id: string) => deleteRequestedBusinessServiceReqFunction(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['requestedBusinessServices'])
        successMessage('Serviço business excluído com sucesso!')
      },

      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    createRequestedBusinessService,
    editRequestedBusinessService,
    deleteRequestedBusinessService,
  }
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ValidateRealTimeBody {
  isValidated: boolean
  updateId: string
  type: "collect" | "delivery"
}

interface ValidateRealTimeParams {
  updateId: string
  body: ValidateRealTimeBody
}

export async function validateRealTime({
  updateId,
  body
}: ValidateRealTimeParams) {
  try {
    await api.patch(`/services/validations/real-times/${updateId}/validate`, body)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

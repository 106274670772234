import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface RealizePassiveFeedbackBody {
  whatDidYouLikedMore?: string
  whatDidYouLikedLess?: string
  whatYouThinkAboutYourDevelopment?: number
  howCouldYouBetter?: string
  haveYouBeenTrainedCorrectly?: boolean
  howManyWeeksAreYourLearnCurve?: number
  doYouHaveClarityOnKpiAndMatrix?: boolean
  howDoYouFeel?: string
  whatYouThinkAboutTheTeam?: string
  whoDidYouFeelMostSupportedBy?: string
  leaderRate?: number
  whatWouldYouImproveAboutLeadership?: string
  didYouKnowWhereConsultProcesses?: boolean
  didYouHasQuestionsAboutRh?: boolean
  doesYouKnowTheFullPointFlow?: string
  didYouRequestedRhAnswerYourQuestions?: boolean
  doesYourQuestionsAnswered?: string
  haveQuestionsAboutYourTimeBank?: boolean
  didYouKnowWhereOpenSupport?: boolean
  haveYouQuestionsAboutBuildingMaintenance?: boolean
  doesYouFollowsYammerPublications?: boolean
  doesYouKnowAboutGeneralMeet?: boolean
  observations?: string
  doesYouKnowHowOpenSystemBug?: boolean
  doesYouThereInSystemImprovementsPlanner?: boolean

  selfPerformanceLast6Months?: number
  goalAchievementFeedback?: string
  improvementFeedback?: string
  challengesFeedback?: string
  goalsFeedback?: string
  teamEnvironmentRate?: number
  improvementSugestion?: string
  marketingFeedback?: string
  leadershipRate?: number
  supportFeedback?: string
  companyImprovementFeedback?: string
  loglifeReferralScore?: number
  workSatisfactionRate?: number
  routeChallengesEncountered?: string
  roleResponsibilityFeedback?: string
  impactOfPartnersAbsencesOnRoutine?: string
  awareOfStandardizationReason?: string


}

interface RealizePassiveFeedbackProps {
  body: RealizePassiveFeedbackBody
  passiveFeedbackId: string

}
export async function realizePassiveFeedback({
  body,
  passiveFeedbackId
}: RealizePassiveFeedbackProps) {
  try {
    const response = await api.put(`/feedbacks/${passiveFeedbackId}/passives/realize`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text, VStack } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm, useWatch } from "react-hook-form";
import { dismissalDocsInternClt } from "api/internClts/dismissalDocsInternClt";
import { set } from "date-fns";


interface DismissalDocsInternCltProps {
  internCltId: string
}

export interface DismissalDocsInternCltSchema {
  isDocsVerified: string
  isLegalRiskVerified: string
  signedDocs: string[]
  isOperationLeaderAware: string
  isWithNotice: string
  lastWorkDate: string
  isDismissalSignedByCollaborator: string
}

const dismissalDocsInternCltSchema = yup.object({
  isDocsVerified: yup.string().required(),
  isLegalRiskVerified: yup.string().required(),
  signedDocs: yup.array().min(1).of(yup.string()).required(),
  isOperationLeaderAware: yup.string().required(),
  isWithNotice: yup.string().required(),
  lastWorkDate: yup.mixed().when('isWithNotice', {
    is: 'yes',
    then: yup.string().required().transform((value, originalValue, ctx) => {
      if (!value) return null

      const [year, month, day] = originalValue?.split('-').map(Number)
      value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

      return value
    })
  }),
  isDismissalSignedByCollaborator: yup.string().required(),
})

const signedDocsChecklist = [
  { name: 'Entregas de patrimônio' },
  { name: 'Regulamento interno' },
  { name: 'Contrato de experiência' },
]

export function DismissalDocsInternClt({ internCltId }: DismissalDocsInternCltProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    register,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DismissalDocsInternCltSchema>({
    resolver: yupResolver(dismissalDocsInternCltSchema)
  })

  const isWithNotice = useWatch({
    control,
    name: 'isWithNotice'
  })

  const { mutateAsync: dismissalDocsInternCltFn } = useMutation({
    mutationFn: dismissalDocsInternClt,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })
    }
  })

  async function handleDismissalDocsInternClt(values: DismissalDocsInternCltSchema) {
    await promiseMessage(dismissalDocsInternCltFn({
      body: {
        ...values,
        isDocsVerified: values.isDocsVerified === 'yes',
        isLegalRiskVerified: values.isLegalRiskVerified === 'yes',
        isOperationLeaderAware: values.isOperationLeaderAware === 'yes',
        isWithNotice: values.isWithNotice === 'yes',
        lastWorkDate: values.isWithNotice ? values.lastWorkDate : null
      },
      internCltId
    }), 'Documentação enviada!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleDismissalDocsInternClt)}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão documentação</Heading>
      <FormControl isInvalid={!!errors.isDocsVerified} mt={3}>
        <FormLabel>
          Documentação verificada?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isDocsVerified"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isLegalRiskVerified} mt={3}>
        <FormLabel>
          Risco jurídico verificado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isLegalRiskVerified"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl mt={3} isInvalid={!!errors.signedDocs}>
        <FormLabel fontSize='sm'>
          Documentação assinada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          control={control}
          name="signedDocs"
          render={({ field }) => {
            return (
              <CheckboxGroup
                value={field.value}
                onChange={field.onChange}
              >
                <VStack
                  spacing={3}
                  p={2}
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="md"
                  align="left"
                >
                  {signedDocsChecklist.map(check => {
                    return (
                      <Checkbox key={check.name} value={check.name}>
                        <Text fontSize="sm">
                          {check.name}
                        </Text>
                      </Checkbox>
                    )
                  })}
                </VStack>

              </CheckboxGroup>
            )
          }}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isOperationLeaderAware} mt={3}>
        <FormLabel>
          Líder da operação ciente para realizar a demissão?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isOperationLeaderAware"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.isWithNotice} mt={3}>
        <FormLabel>
          Funcionário irá cumprir aviso prévio?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isWithNotice"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      {isWithNotice === 'yes' && (
        <FormControl isInvalid={!!errors?.lastWorkDate}>
          <FormLabel fontSize="sm">
            Data último dia de trabalho
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('lastWorkDate')}
            name="lastWorkDate"
            type="date"
            rounded="md"
            size='sm'
          />
        </FormControl>
      )}
      <FormControl isInvalid={!!errors.isDismissalSignedByCollaborator} mt={3}>
        <FormLabel>
          Assinatura do aviso de demissão pelo colaborador?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isDismissalSignedByCollaborator"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

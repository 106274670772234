import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Select, Spinner, Stack } from "@chakra-ui/react";
import { BusinessCost } from "api/businessCosts/businessCost";
import { format } from "date-fns";
import { useBusinessBudgetRoutes } from "hooks/businessBudget/useBusinessBudgetsRoutes";
import { Collector } from "hooks/collector/dtos/Collector";
import { useCollectors } from "hooks/collector/useCollectors";
import { useCustomers } from "hooks/customer/useCustomers";
import { useEffect } from "react";
import { useCurrency } from "react-hook-currency";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { FaPlus, FaTrash } from "react-icons/fa";
import { BusinessBudgetProps } from "utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions";

export interface BusinessCostFormSchema {
  collectors: {
    collectorId: string
  }[]
  startDate: string
  endDate: string
  customerId: string
  cost: string
  situation: 'active' | 'inactive'
  observations?: string | null
  businessBudgetId: string
}

interface BusinessCostFormProps {
  businessCost?: BusinessCost & {
    businessBudget: BusinessBudgetProps,
    collectors: {
      id: string
      collector: Collector
    }[]
  }
}

export function BusinessCostForm({
  businessCost
}: BusinessCostFormProps) {

  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext<BusinessCostFormSchema>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'collectors'
  })

  const customerId = watch('customerId')

  const [situation, businessBudgetId] = useWatch({
    control,
    name: ['situation', 'businessBudgetId']
  })

  const isActiveSituation = situation === 'active'

  const { onChange: onCurrencyChange, format: currencyFormat } = useCurrency({
    style: 'decimal'
  })

  const {
    data: customersResult,
    isLoading: isLoadingCustomersResult
  } = useCustomers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const {
    data: collectorsResult,
    isLoading: isLoadingCollectorsResult
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const {
    data: businessBudgetRoutesResult,
    isLoading: isLoadingBusinessBudgetRoutesResult
  } = useBusinessBudgetRoutes({
    customer_id: customerId,
    queryOptions: {
      enabled: !!customerId
    }
  })

  useEffect(() => {
    if (businessCost) {

      const collectors = businessCost.collectors.map(
        (collector) => {
          return {
            collectorId: collector.collector.id
          }
        }
      )

      if (collectorsResult) {
        setValue('collectors', collectors)
      }

      setValue('situation', businessCost.situation)

      setValue(
        'startDate',
        format(new Date(businessCost.start_date), 'yyyy-MM-dd')
      )

      if (businessCost.situation === 'inactive') {
        setValue(
          'endDate',
          format(new Date(businessCost.end_date), 'yyyy-MM-dd')
        )
      }

      setValue('cost', (businessCost.cost / 100).toString())

    }
  }, [
    setValue,
    businessCost,
    collectorsResult,
  ])

  useEffect(() => {
    if (customersResult && businessCost) {
      setValue('customerId', businessCost.businessBudget.customer_id)
    }
  }, [customersResult, setValue, businessCost])

  useEffect(() => {
    if (businessBudgetRoutesResult && businessCost) {
      setValue('businessBudgetId', businessCost.business_budget_id)
    }
  }, [businessBudgetRoutesResult, setValue, businessCost])

  const selectedBusinessBudgetRoute = businessBudgetRoutesResult?.routes.find(
    (businessBudgetRoute) => businessBudgetRoute.id === businessBudgetId
  )

  return (
    <Flex
      direction="column"
      gap="6"
      mt="6"
    >
      <Flex
        border="1px solid"
        borderColor="gray.200"
        p="4"
        rounded="lg"
        direction="column"
      >
        <Heading
          fontSize="md"
          letterSpacing="tight"
        >
          Coletadores
        </Heading>

        <Box>
          {isLoadingCollectorsResult ? (
            <Spinner />
          ) : (
            fields.map((field, index) => {
              return (
                <FormControl mt="3" key={field.id} isInvalid={errors?.collectors ? !!errors?.collectors[index]?.collectorId : undefined}>
                  <FormLabel fontSize="sm">Coletador</FormLabel>
                  <Flex gap="3">
                    <Select
                      key={field.id}
                      {...register(`collectors.${index}.collectorId`)}
                      rounded="lg"
                      placeholder="Selecione um coletador..."
                    >
                      {collectorsResult?.map(collector => {
                        return (
                          <option key={collector.id} value={collector.id}>{collector.trading_name}</option>
                        )
                      })}

                    </Select>

                    <IconButton
                      aria-label="Remover coletador"
                      icon={<FaTrash />}
                      variant="ghost"
                      onClick={() => remove(index)}
                    />
                  </Flex>
                </FormControl>
              )
            })
          )}

        </Box>

        <Button
          size="sm"
          leftIcon={<FaPlus />}
          colorScheme="blue"
          onClick={() => append({ collectorId: '' })}
          mt="3"
          alignSelf="end"
        >
          Adicionar
        </Button>

      </Flex>

      <Stack
        spacing="6"
        direction={{ base: 'column', md: 'row' }}
      >
        <FormControl isInvalid={!!errors?.situation}>
          <FormLabel fontSize="sm">Situação</FormLabel>
          <Select
            {...register('situation')}
            rounded="lg"
          >
            <option value="active">Ativo</option>
            <option value="inactive">Inativo</option>
          </Select>
        </FormControl>

        <Stack w="full" spacing="6" direction={{ base: 'column', md: 'row' }}>
          <FormControl isInvalid={!!errors?.startDate}>
            <FormLabel fontSize="sm">Data inicial</FormLabel>
            <Input
              {...register('startDate')}
              type="date"
              rounded="lg"
            />
          </FormControl>

          {!isActiveSituation && (
            <FormControl isInvalid={!!errors?.endDate}>
              <FormLabel fontSize="sm">Data final</FormLabel>
              <Input
                {...register('endDate')}
                type="date"
                rounded="lg"
              />
            </FormControl>
          )}
        </Stack>
      </Stack>

      <FormControl isInvalid={!!errors?.cost}>
        <FormLabel fontSize="sm">Custo</FormLabel>
        <Input
          {...register('cost')}
          rounded="lg"
          onChange={onCurrencyChange}
          defaultValue={currencyFormat('000')}
        />

      </FormControl>

      <FormControl isInvalid={!!errors?.customerId}>
        <FormLabel fontSize="sm">Cliente</FormLabel>
        <Select
          {...register('customerId')}
          rounded="lg"
          placeholder="Selecione um cliente..."
          isDisabled={isLoadingCustomersResult}
        >
          {customersResult?.map(customer => {
            return (
              <option key={customer.id} value={customer.id}>{customer.trading_firstname}</option>
            )
          })}
        </Select>
      </FormControl>

      {customerId && (
        <Stack
          spacing="6"
          direction={{ base: 'column', md: 'row' }}
        >
          <FormControl isInvalid={!!errors?.businessBudgetId}>
            <FormLabel fontSize="sm">Rota</FormLabel>
            <Select
              {...register('businessBudgetId')}
              rounded="lg"
              placeholder="Selecione um cliente..."
              isDisabled={isLoadingBusinessBudgetRoutesResult}
            >
              {businessBudgetRoutesResult?.routes.map((businessBudgetRoute) => {
                return (
                  <option key={businessBudgetRoute.id} value={businessBudgetRoute.id}>{businessBudgetRoute.route_nickname}</option>
                )
              })}
            </Select>
          </FormControl>

          <Stack w="full" spacing="6" direction={{ base: 'column', md: 'row' }}>
            <FormControl>
              <FormLabel fontSize="sm">Hub origem</FormLabel>
              <Input
                rounded="lg"
                defaultValue={selectedBusinessBudgetRoute?.sourceHubIDBusinessBudget.name}
                isDisabled
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm">Hub destino</FormLabel>
              <Input
                rounded="lg"
                defaultValue={selectedBusinessBudgetRoute?.destinationHubIDBusinessBudget.name}
                isDisabled
              />
            </FormControl>
          </Stack>

        </Stack>
      )}

    </Flex >
  )
}

import { ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { getBusinessCost } from "api/businessCosts/getBusinessCost";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { BusinessCostSituation } from "./BusinessCostSituation";

interface BusinessCostDetailProps {
  businessCostId: string
}

export function BusinessCostDetail({
  businessCostId
}: BusinessCostDetailProps) {

  const {
    data: businessCostResult,
    isLoading: isLoadingBusinessCostResult
  } = useQuery({
    queryKey: ['business-cost', businessCostId],
    queryFn: () => getBusinessCost(businessCostId)
  })

  return (

    <ModalContent>
      {isLoadingBusinessCostResult ? (
        <ModalBody>
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader>
            Detalhes do custo business
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Cliente</Td>
                  <Td textAlign="right">{businessCostResult?.businessCost.businessBudget.customerIDBusinessBudget.trading_firstname}</Td>
                </Tr>
                <Tr>
                  <Td>Rota</Td>
                  <Td textAlign="right">{businessCostResult?.businessCost.businessBudget.route_nickname}</Td>
                </Tr>
                <Tr>
                  <Td minW="150px">Data inicial</Td>
                  <Td textAlign="right">{format(new Date(businessCostResult?.businessCost.start_date), 'dd/MM/yyyy')}</Td>
                </Tr>
                {businessCostResult?.businessCost.end_date && (
                  <Tr>
                    <Td minW="150px">Data final</Td>
                    <Td textAlign="right">{format(new Date(businessCostResult?.businessCost.end_date), 'dd/MM/yyyy')}</Td>
                  </Tr>
                )}
                <Tr>
                  <Td minW="150px">Situação</Td>
                  <Td display="flex" justifyContent="flex-end">
                    <BusinessCostSituation situation={businessCostResult?.businessCost.situation} />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Custo</Td>
                  <Td textAlign="right">
                    {(businessCostResult?.businessCost.cost / 100).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </Td>
                </Tr>
                {businessCostResult?.businessCost.collectors.map((collector) => {
                  return (
                    <Tr key={collector.id}>
                      <Td>Coletador</Td>
                      <Td textAlign="right">{collector.collector.trading_name}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </ModalBody>
        </>
      )
      }

    </ModalContent >
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getAuditsReport } from "api/audits/getAuditsReport";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { captalize } from "utils/captalize";
import { AuditStatus } from "./components/AuditStatus";
import { AuditsReportFilters } from "./components/AuditsReportFilters";
import { auditStatusMap } from "utils/auditMappers";
import { useEffect } from "react";

const defaultHeaders = [
  { label: 'Colaborador', key: 'collaborator' },
  { label: 'Data realizada', key: 'realized_date' },
  { label: 'Status', key: 'status' },
  { label: 'Não conformidade', key: 'non_compliance' },

]

const headers = (type: string) => {
  switch (type) {
    case 'OPERACIONAL':
      return [
        ...defaultHeaders,
        ...Array.from({ length: 3 }).map((_, index) => {
          return [
            { label: `Protocolo do serviço ${index + 1}`, key: `serviceProtocol${index}` },
            { label: `Insumos sem divergência ${index + 1}`, key: `isWithNonDivergentInputs${index}` },
            { label: `Horário de coleta dentro do bloco de horário ${index + 1}`, key: `collectHourInsideHourBlock${index}` },
            { label: `Foto da caixa na coleta? ${index + 1}`, key: `hasBoxPhotoInCollect${index}` },
            { label: `Foto da declaração de conteúdo na coleta? ${index + 1}`, key: `hasContentDeclarationInCollect${index}` },
            { label: `Declaração de conteúdo assinada na coleta? ${index + 1}`, key: `isContentDeclarationSignedInCollect${index}` },
            { label: `Declaração de conteúdo preenchida na coleta? ${index + 1}`, key: `isContentDeclarationFilledInCollect${index}` },
            { label: `Declaração de conteúdo com carimbo na coleta? ${index + 1}`, key: `isContentDeclarationStampedInCollect${index}` },
            { label: `Lançamento feito pelo coletador de origem? ${index + 1}`, key: `isReleaseMadeBySourceCollector${index}` },
            { label: `Horário de entrega dentro do bloco de horário? ${index + 1}`, key: `deliveryHourInsideHourBlock${index}` },
            { label: `Foto da caixa na entrega? ${index + 1}`, key: `hasBoxPhotoInDelivery${index}` },
            { label: `Foto da declaração de conteúdo na entrega? ${index + 1}`, key: `hasContentDeclarationInDelivery${index}` },
            { label: `Declaração de conteúdo assinada na entrega? ${index + 1}`, key: `isContentDeclarationSignedInDelivery${index}` },
            { label: `Lançamento feito pelo coletador de destino? ${index + 1}`, key: `isReleaseMadeByDestinationCollector${index}` },
          ]
        }).flat()
      ]
    case 'COMERCIAL':
      return [
        ...defaultHeaders,
        { label: 'Contrato válido assinado?', key: 'isSignedValidContract' },
        { label: 'POP atualizado e assinado?', key: 'isUpdatedAndSignedPop' }
      ]
    case 'RH':
      return [
        ...defaultHeaders,
        { label: 'Registro de treinamento POP do cliente?', key: 'isCustomerPopTrainingRegistered' },
        { label: 'Registro de treinamento limpeza e desinfecção?', key: 'isCleaningDesinfectionTrainingRegistered' },
        { label: 'Registro de treinamento de biossegurança?', key: 'isBiosecurityTrainingRegistered' },
        { label: 'Registro de limpeza do mês anterior?', key: 'isLastMonthCleaningRegistered' },
        { label: 'Cartão de vacina?', key: 'isWithVaccineCard' },
        { label: 'CNH com EAR?', key: 'isWithEarCnh' },
        { label: 'Curso de motofrete/ANTT?', key: 'isWithMotorcycleFreightAnttCourse' },
        { label: 'CRLV categoria aluguel?', key: 'isWithCrlvLocationCategory' },
        { label: 'Aso?', key: 'isWithAso' },
        { label: 'KIT EPI entregue?', key: 'isEpiKitDelivered' },
        { label: 'Cartão de ponto batido corretamente?', key: 'isTimeCardRecordedCorrectly' },
        { label: 'Conta salário aberta?', key: 'isOpenedSalaryAccount' },
        { label: 'Crachá?', key: 'isWithBadge' },
        { label: 'Cordão crachá?', key: 'isWithBadgeCord' },
        { label: 'Adesivos moto entregue?', key: 'isDeliveredMotorcycleLabels' },
        { label: 'Caixa térmica entregue?', key: 'isDeliveredThermalBox' },
      ]
    case 'CAMPO':
      return [
        ...defaultHeaders,
        { label: 'Última data da troca de óleo', key: 'lastOilChangeDate' },
        { label: 'Sistema elétrico', key: 'eletricSystem' },
        { label: 'Número de patrimônio do baú', key: 'chestPatrimonyNumber' },
        { label: 'Número de patrimônio das caixas térmicas', key: 'thermicBoxesPatrimonyNumber' },
        { label: 'Foto do colaborador', key: 'collaboratorPhotoAttachment.link' },
        { label: 'Foto da traseira da moto com placa', key: 'vehicleRearPhotoAttachment.link' },
        { label: 'Foto da embalagem terciária', key: 'tertiaryPackingPhotoAttachment.link' },
        { label: 'Foto interna do baú', key: 'internChestPhotoAttachment.link' },
        { label: 'Foto da CNH', key: 'cnhPhotoAttachment.link' },
        { label: 'Foto da CRLV', key: 'crlvPhotoAttachment.link' },
      ]
    case 'RH LLM':
      return [
        ...defaultHeaders,
        { label: 'Cartão de ponto batido corretamente?', key: 'isTimeCardRecordedCorrectly' },
        { label: 'Onboarding finalizado?', key: 'isFinishedOnboarding' },
        { label: 'ASO vigente?', key: 'isWithAso' },
        { label: 'Conta salário aberta?', key: 'isOpenedSalaryAccount' },
      ]
    case 'FINANCEIRA':
      return [
        ...defaultHeaders,
        { label: 'Relatório', key: 'reportAttachment.link' },
        { label: 'Desvio encontrado', key: 'deviationFoundValue' },
      ]
    case 'QUALIDADE VISA':
      return [
        ...defaultHeaders,
        { label: 'Existe cronograma de treinamentos?', key: 'hasTrainingSchedule' },
        { label: 'Existe cronograma de auditorias?', key: 'hasAuditsSchedule' },
        { label: 'Existe documento atualizado que padronize os documentos críticos?', key: 'hasUpdatedDocumentThatStandardizesCriticalDocuments' },
        { label: 'Existe organograma atualizado?', key: 'hasUpdatedOrganizationalChart' },
        { label: 'Existe descrição de cargos e funções?', key: 'hasCargoAndFunctionsDetail' },
        { label: 'Existe documento que defina o processo de auditoria?', key: 'hasAuditProcessDocument' },
        { label: 'Existe documento que defina o processo de treinamento?', key: 'hasTrainingProcessDocument' },
        { label: 'Existe documento que descreve a limpeza e desinfecção?', key: 'hasCleaningDesinfectionDocument' },
        { label: 'Existe documento que defina as regras de biossegurança?', key: 'hasBiossecurityRulesDocument' },
        { label: 'Existe documento padrão de POP do Cliente?', key: 'hasCustomerPopPatternDocument' },
      ]
    case 'QUALIDADE ISO':
      return [
        ...defaultHeaders,
        { label: 'Existe cronograma de treinamentos?', key: 'hasTrainingSchedule' },
        { label: 'Existe cronograma de auditorias?', key: 'hasAuditsSchedule' },
        { label: 'Existe Mapeamento de Processos do Comercial?', key: 'hasCommercialProcessMapping' },
        { label: 'Existe Mapeamento de Processos do RH/DP?', key: 'hasRhDpProcessMapping' },
        { label: 'Existe Mapeamento de Processos de Operação?', key: 'hasOperationProcessMapping' },
        { label: 'Existe Mapeamento de Processos de Compras?', key: 'hasPurchaseProcessMapping' },
        { label: 'Existe Mapeamento de Processos do Financeiro?', key: 'hasFinancialProcessMapping' },
        { label: 'Existe Mapeamento de Processos da Qualidade?', key: 'hasQualityProcessMapping' },
        { label: 'Existe Mapeamento de Processos da Cultura Organizacional?', key: 'hasOrganizationalCultureProcessMapping' },
        { label: 'Existe organograma atualizado?', key: 'hasUpdatedOrganizationalChart' },
        { label: 'Existe descrição de cargos e funções?', key: 'hasCargoAndFunctionsDetail' },
        { label: 'Existe documento que defina o processo de auditoria?', key: 'hasAuditProcessDocument' },
        { label: 'Existe documento que defina o processo de treinamento?', key: 'hasTrainingProcessDocument' },
        { label: 'Existe modelo de ata de reunião de análise crítica?', key: 'hasMeetingModelForCriticalAnalysis' },
        { label: 'Existem certificados dos auditores salvos?', key: 'hasAuditorsSavedCertificates' },
        { label: 'Existe planejamento de mudanças?', key: 'hasChangesPlanning' },
        { label: 'Existe política da qualidade?', key: 'hasQualityPolicy' },
        { label: 'Existe objetivos da qualidade?', key: 'hasQualityObjectives' },
        { label: 'Existe documento que padroniza os documentos?', key: 'hasDocumentToStandardizesDocuments' },
        { label: 'Existe interação dos processos?', key: 'hasProcessInteraction' },
        { label: 'Existe manual do Sistema de Gestão da Qualidade?', key: 'hasQualityManagmentSystemManual' },
        { label: 'Existe qualificação de transportadoras e IQR?', key: 'hasCarriersIqrQualification' },
        { label: 'Existe qualificação de fornecedores e IQR?', key: 'hasProvidersIqrQualification' },
        { label: 'Existe qualificação de coletadores e IQR?', key: 'hasCollectorsIqrQualification' },
        { label: 'Existe documento de não conformidade?', key: 'hasNonComplianceDocument' },
        { label: 'Existe código de ética?', key: 'hasEthicsCode' },
        { label: 'Existe comunicação interna e externa?', key: 'hasInternExternalComunication' },
        { label: 'Cartão de ponto batido corretamente?', key: 'isTimeCardRecordedCorrectly' },
        { label: 'Existe planejamento estratégico?', key: 'hasStrategicPlanning' },
      ]
    case 'MANUTENÇÃO':
      return [
        ...defaultHeaders,
        { label: 'Relatório', key: 'reportAttachment.link' }

      ]
    case 'MULTAS':
      return [
        ...defaultHeaders,
        { label: 'Relatório', key: 'reportAttachment.link' }

      ]
    default:
      return []
  }
}

const switchValues = (value: unknown) => {
  switch (value) {
    case true:
      return 'Sim'
    case false:
      return 'Não'
    case 'yes':
      return 'Sim'
    case 'no':
      return 'Não'
    case 'n.a':
      return 'N/A'
    default:
      return value
  }
}

export function AuditsReport() {

  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const status = searchParams.get('status') ?? 'all'
  const type = searchParams.get('type')

  const userCanViewAuditsReport = userLogged?.permissions.includes('view-audits-report')

  useEffect(() => {
    if (!userCanViewAuditsReport) history.push('/')
  }, [history, userCanViewAuditsReport])

  const {
    data: auditsReportData
  } = useQuery({
    queryKey: ['audits-report', startDate, endDate, status, type],
    queryFn: () => getAuditsReport({
      queryParams: {
        endDate,
        startDate,
        status,
        type
      }
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!type
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const csvPropsData = auditsReportData
    ? auditsReportData?.audits.map(audit => {


      if (audit.type === 'OPERACIONAL') {
        const operationalFields = audit.operationalAudits.reduce((operationalAudits, audit, index) => {
          Object.entries(audit).forEach(([key, value]) => {
            operationalAudits[`${key}${index}`] = switchValues(value)
          })

          return operationalAudits
        }, {})


        return {
          ...audit,
          ...operationalFields,
          status: auditStatusMap[audit.status],
        }
      }

      if (audit.type === 'COMERCIAL') {
        return {
          ...audit,
          isSignedValidContract: switchValues(audit.commercialAudit.isSignedValidContract),
          isUpdatedAndSignedPop: switchValues(audit.commercialAudit.isUpdatedAndSignedPop),
          status: auditStatusMap[audit.status],

        }
      }

      if (audit.type === 'RH') {

        const humanResourceAuditFields = Object.entries(audit.humanResourcesAudit).reduce((humanResourceAudit, [key, value]) => {
          humanResourceAudit[key] = switchValues(value)

          return humanResourceAudit
        }, {})

        return {
          ...audit,
          ...humanResourceAuditFields,
          status: auditStatusMap[audit.status],
        }
      }

      if (audit.type === 'CAMPO') {

        const fieldAuditFields = Object.entries(audit.fieldAudit).reduce((fieldAudit, [key, value]) => {
          if (key === 'collaboratorPhotoAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else if (key === 'vehicleRearPhotoAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else if (key === 'tertiaryPackingPhotoAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else if (key === 'internChestPhotoAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else if (key === 'cnhPhotoAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else if (key === 'crlvPhotoAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else {
            fieldAudit[key] = switchValues(value)
          }

          return fieldAudit
        }, {})

        return {
          ...audit,
          ...fieldAuditFields,
          status: auditStatusMap[audit.status],
        }
      }

      if (audit.type === 'RH LLM') {

        const llmHumanResourcesAuditFields = Object.entries(audit.humanResourcesAudit).reduce((fieldAudit, [key, value]) => {
          fieldAudit[key] = switchValues(value)

          return fieldAudit
        }, {})

        return {
          ...audit,
          ...llmHumanResourcesAuditFields,
          status: auditStatusMap[audit.status],
        }
      }

      if (audit.type === 'FINANCEIRA') {

        const financialAuditFields = Object.entries(audit.financialAudit).reduce((fieldAudit, [key, value]) => {
          if (key === 'reportAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else if (key === 'deviationFoundValueInCents') {
            fieldAudit[key] = switchValues((value / 100).toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency '
            }))
          } else {
            fieldAudit[key] = switchValues(value)
          }

          return fieldAudit
        }, {})

        return {
          ...audit,
          ...financialAuditFields,
          status: auditStatusMap[audit.status],
        }
      }

      if (audit.type === 'QUALIDADE VISA') {

        const visaQualityAuditFields = Object.entries(audit.visaQualityAudit).reduce((fieldAudit, [key, value]) => {
          fieldAudit[key] = switchValues(value)

          return fieldAudit
        }, {})

        return {
          ...audit,
          ...visaQualityAuditFields,
          status: auditStatusMap[audit.status],
        }
      }

      if (audit.type === 'QUALIDADE ISO') {

        const isoQualityAuditFields = Object.entries(audit.isoQualityAudit).reduce((fieldAudit, [key, value]) => {
          fieldAudit[key] = switchValues(value)

          return fieldAudit
        }, {})

        return {
          ...audit,
          ...isoQualityAuditFields,
          status: auditStatusMap[audit.status],
        }
      }

      if (audit.type === 'MANUTENÇÃO') {

        const maintenanceAuditFields = Object.entries(audit.maintenanceAudit).reduce((fieldAudit, [key, value]) => {
          if (key === 'reportAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else {
            fieldAudit[key] = switchValues(value)
          }


          return fieldAudit
        }, {})

        return {
          ...audit,
          ...maintenanceAuditFields,
          status: auditStatusMap[audit.status],
        }
      }

      if (audit.type === 'MULTAS') {
        const maintenanceAuditFields = Object.entries(audit.maintenanceAudit).reduce((fieldAudit, [key, value]) => {
          if (key === 'reportAttachment') {
            fieldAudit[key] = { link: switchValues(value.link) }
          } else {
            fieldAudit[key] = switchValues(value)
          }


          return fieldAudit
        }, {})

        return {
          ...audit,
          ...maintenanceAuditFields,
          status: auditStatusMap[audit.status],
        }
      }


      return {
        ...audit,
        realized_date: String(format(new Date(audit.realized_date), 'dd/MM/yyyy')),
      }
    })
    : []

  const csvProps = {
    data: csvPropsData,
    headers: headers(type),
    filename: `relatório-auditorias-${new Date().toISOString()}.csv`,
  }

  const handleChangePage = (page: number) => setCurrentPage(page)


  return (
    <Box
      p="6"
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight">Relatório de Auditorias</Heading>
      <AuditsReportFilters csvProps={csvProps} />
      {auditsReportData && (
        <TableContainer mt="6">
          <Table
            size="sm"
          >
            <Thead>
              <Tr>
                <Th>Colaborador</Th>
                <Th>Data Realizada</Th>
                <Th>Tipo de auditoria</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {auditsReportData?.audits.slice(offset, offset + 10).map((audit) => {
                return (
                  <Tr key={audit.id}>
                    <Td>{audit.collaborator ? captalize(audit.collaborator) : 'N/A'}</Td>
                    <Td>{format(new Date(audit?.realized_date), 'dd/MM/yyyy')}</Td>
                    <Td>{captalize(audit.type)}</Td>
                    <Td>
                      <AuditStatus status={audit.status} />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}

import { Flex, IconButton, Input, Select } from "@chakra-ui/react";
import { VacationStatus } from "api/vacations/_types/Vacation";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { vacationStatusMap } from "utils/vacationMappers";

interface VacationTableFiltersSchema {
  status: VacationStatus | 'all'
  collaboratorName: string
}

export function VacationsTableFilters() {
  const searchParams = useSearchParams()
  const history = useHistory()

  const collaboratorName = searchParams.get('collaboratorName')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<VacationTableFiltersSchema>({
    defaultValues: {
      collaboratorName: collaboratorName,
      status: status as VacationStatus | 'all',
    }
  })

  async function handleFilterVacations(data: VacationTableFiltersSchema) {
    if (data.collaboratorName) {
      searchParams.set('collaboratorName', data.collaboratorName)
    } else {
      searchParams.delete('collaboratorName')
    }

    if (data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('collaboratorName')
    searchParams.delete('status')

    searchParams.set('page', '1')

    reset({
      collaboratorName: '',
      status: 'all'
    })

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      align="center"
      gap="2"
      direction={{ base: 'column', lg: 'row' }}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterVacations)}
    >
      <Input
        {...register("collaboratorName")}
        placeholder="Colaborador"
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '300px' }}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={{ base: 'full', lg: '300px' }}
      >
        {Object.entries(vacationStatusMap).map(([statusKey, statusValue]) => {
          return <option key={statusKey} value={statusKey}>{statusValue}</option>
        })}
        <option value="all">Todos status</option>
      </Select>

      <IconButton
        aria-label=""
        icon={<FaSearch />}
        variant="ghost"
        size="sm"
        type="submit"
      />

      <IconButton
        aria-label=""
        icon={<FaTimes />}
        variant="ghost"
        type="button"
        size="sm"
        onClick={handleClearFilters}
      />

    </Flex>
  )
}

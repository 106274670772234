import { useToastify } from "hooks/toastify/useToastify"
import { Controller, useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Heading, IconButton, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { CreateRequestPatrimony } from "pages/Patrimony/components/CreateRequestPatrimony";
import { hireInternClt } from "api/internClts/hireInternClt";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { FaPlus } from "react-icons/fa";


interface HireInternCltProps {
  internCltId: string
}

export interface HireInternCltSchema {
  documentationChecklist: string[]
}

const hireInternCltSchema = yup.object({
  documentationChecklist: yup.array().min(1).of(yup.string()).nullable().required(),
})

const documentationChecklist = [
  { name: 'Regulamento Interno' },
  { name: 'Contrato Experiência' },
  { name: 'Ficha de Registro' },
  { name: 'Ficha de VT' },
]


export function HireInternClt({ internCltId }: HireInternCltProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const searchParams = useSearchParams()
  const { replace } = useHistory()


  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<HireInternCltSchema>({
    resolver: yupResolver(hireInternCltSchema)
  })

  const patrimonyRequested = searchParams.get('patrimonyRequested')
  const hasPatrimonyRequested = patrimonyRequested === 'true'

  const {
    isOpen: isCreateRequestPatrimonyModalOpen,
    onOpen: onOpenCreateRequestPatrimonyModal,
    onClose: onCloseCreateRequestPatrimonyModal
  } = useDisclosure()

  const { mutateAsync: hireInternCltFn } = useMutation({
    mutationFn: hireInternClt,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })

      searchParams.delete('patrimonyRequested')
      replace({ search: searchParams.toString() })

    }
  })



  async function handleHireInternClt(values: HireInternCltSchema) {
    await promiseMessage(hireInternCltFn({
      body: values,
      internCltId
    }), 'Contratação realizada')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleHireInternClt)}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação</Heading>

      <Flex mt={3} alignItems="baseline" justifyContent="space-between">
        <FormLabel fontSize='sm'>
          Solicitação de patrimônio
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <IconButton
          aria-label="Criar Interno CLT"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenCreateRequestPatrimonyModal}
        />

      </Flex>


      <Modal
        isOpen={isCreateRequestPatrimonyModalOpen}
        onClose={onCloseCreateRequestPatrimonyModal}
        isCentered
      >
        <ModalOverlay />
        <CreateRequestPatrimony
          onCloseModal={onCloseCreateRequestPatrimonyModal}
          required={true}
        />
      </Modal>

      <FormControl mt={3} isInvalid={!!errors.documentationChecklist}>
        <FormLabel fontSize='sm'>
          Documentação
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          control={control}
          name="documentationChecklist"
          render={({ field }) => {
            return (
              <CheckboxGroup
                value={field.value}
                onChange={field.onChange}
              >
                <VStack
                  spacing={3}
                  p={2}
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="md"
                  align="left"
                >
                  {documentationChecklist.map(check => {
                    return (
                      <Checkbox key={check.name} value={check.name}>
                        <Text fontSize="sm">
                          {check.name}
                        </Text>
                      </Checkbox>
                    )
                  })}
                </VStack>

              </CheckboxGroup>
            )
          }}
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || !hasPatrimonyRequested}
        >
          Salvar
        </Button>
      </Flex>

    </Box>
  )

}

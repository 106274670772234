import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Stack,
  Text,
  Icon,
  Button,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { Input } from '../../../components/Inputs/Input'
import {
  ServiceProps,
} from '../../../contexts/ServiceContext'
import { CollectedServiceProps } from '../../../contexts/CollectedServiceContext'
import { useService } from '../../../hooks/services/service'
import { ListButton } from '../../../components/Buttons/ListButton'
import { CancelButton } from '../../../components/Buttons/CancelButton'
import { useAuth } from '../../../hooks/auth/useAuth'


import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { FormActionButton } from '../../../components/Buttons/FormActionButton'
import { FaFilePdf } from 'react-icons/fa'
import { GenerateLabelModal } from '../../../components/Modal/Service/Label/GenerateLabelModal'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { Address } from 'hooks/address/dtos/Address'
import { useProviders } from 'hooks/provider/useProviders'
import { CancelServiceModal } from 'components/Modal/Service/CancelServiceModal'

interface IQueryParams {
  service_id: string
  address_id: string
}

interface ICollectServiceAddress {
  slug: string
  isDisabled?: boolean
}

interface ProviderProps {
  id: string
  company_name: string
  trading_name: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
}

export function CollectBusinessServiceAddresses({
  slug,
  isDisabled = false,
}: ICollectServiceAddress) {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const [stepsOfCollectedService, setStepsOfCollectedService] = useState<
    string[]
  >([])

  const [sourceModalAddresses, setSourceModalAddresses] = useState<
    Address[]
  >([])
  const [destinationModalAddresses, setDestinationModalAddresses] = useState<
    Address[]
  >([])

  const [sourceAddresses, setSourceAddresses] = useState<Address[]>([])
  const [providerSourceAddress, setProviderSourceAddress] = useState<
    ProviderProps[]
  >([])

  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])
  const [collectedServiceFilteredById, setCollectedServiceFilteredById] =
    useState<CollectedServiceProps[]>([])



  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()
  const { service_id }: IQueryParams = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()


  const {
    service: { data: serviceById, isLoading: isServiceLoading },
  } = useService(service_id, false, false)
  const { data: providers, isLoading: isProviderLoading } = useProviders()
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  const {
    isOpen: isCancelServiceModalOpen,
    onOpen: onOpenCancelServiceModal,
    onClose: onCloseCancelServiceModal,
  } = useDisclosure()

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    if (serviceById && addresses) {
      const sourceAddressesToModal = addresses.filter((address) =>
        serviceById.serviceIDRequestedBusiness.source_address_id.includes(
          address.id,
        ),
      )
      const destinationAddressesToModal = addresses.filter((address) =>
        serviceById.serviceIDRequestedBusiness.destination_address_id.includes(
          address.id,
        ),
      )

      setSourceModalAddresses(sourceAddressesToModal)
      setDestinationModalAddresses(destinationAddressesToModal)
    }
  }, [serviceById, addresses])

  useEffect(() => {
    function run() {
      if (serviceById?.serviceIDCollect !== undefined) {
        setCollectedServiceFilteredById(serviceById?.serviceIDCollect)
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (!isProviderLoading && !isAddressesLoading && !isServiceLoading) {
        setIsPageLoading(false)
      }
    }
    run()
  }, [isProviderLoading, isAddressesLoading, isServiceLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-collect-business-service')) {
            redirectTo('/')
          }
        }
      } catch {
        redirectTo('/')
      }
    }
    run()
  }, [slug, redirectTo, userLogged])

  useEffect(() => {
    function run() {
      if (collectedServiceFilteredById.length === 0) {
        setStepsOfCollectedService([])
        return
      }
      const stepsOfCollectedService = collectedServiceFilteredById.map(
        (colService) => colService.step,
      )

      setStepsOfCollectedService(stepsOfCollectedService)
    }
    run()
  }, [collectedServiceFilteredById])

  useEffect(() => {
    if (providers) {
      const hasProviderOnService = serviceFilteredById.findIndex(
        (service) => service.serviceIDRequestedBusiness.provider_id,
      )

      const providerAlreadyInUse = collectedServiceFilteredById.filter(
        (colService) => colService.provider_id,
      )

      const stepOfProviderSearch = providerAlreadyInUse.findIndex(
        (colService) =>
          colService.step === 'DONE' ||
          colService.step === 'UNSUCCESS' ||
          colService.step === 'VALIDATEUNSUCCESS',
      )

      if (hasProviderOnService >= 0 && stepOfProviderSearch < 0) {
        const providerIdOfService = serviceFilteredById.map(
          (service) => service.serviceIDRequestedBusiness.provider_id,
        )

        const providerSourceAddressFiltered = providers.filter(
          (provider) => provider.id === String(providerIdOfService),
        )

        setProviderSourceAddress(providerSourceAddressFiltered)
        return
      }
      setProviderSourceAddress([])
    }
  }, [serviceFilteredById, collectedServiceFilteredById, providers])

  useEffect(() => {
    function run() {
      const sourceAddressOfService = serviceFilteredById.flatMap(
        (service) => service.serviceIDRequestedBusiness.source_address_id,
      )

      if (addresses !== undefined) {
        if (stepsOfCollectedService.length === 0) {
          const sourceAddress = addresses.filter((address) =>
            sourceAddressOfService.includes(address.id),
          )
          setSourceAddresses(sourceAddress)
        } else {
          const colServiceAddresses = collectedServiceFilteredById.map(
            (colService) => colService.address_id,
          )

          const servicesWithoutAddress = addresses
            .filter((address) => sourceAddressOfService.includes(address.id))
            .filter((address) => !colServiceAddresses.includes(address.id))

          const addressesFilteredByStep = collectedServiceFilteredById
            .filter(
              (colService) =>
                colService.step !== 'DONE' &&
                colService.step !== 'UNSUCCESS' &&
                colService.step !== 'VALIDATEUNSUCCESS',
            )
            .map((colService) => colService.address_id)

          const sourceAddress = addresses.filter((address) =>
            addressesFilteredByStep.includes(address.id),
          )

          setSourceAddresses([...servicesWithoutAddress, ...sourceAddress])
        }
      }
    }

    run()
  }, [
    addresses,
    serviceFilteredById,
    collectedServiceFilteredById,
    stepsOfCollectedService,
  ])

  useEffect(() => {
    if (serviceById) {
      const serviceStep = serviceById.step
      const providerCount = serviceById.serviceIDRequestedBusiness.provider_id
        ? 1
        : 0
      const sourceAddressesOfRequestedService =
        serviceById.serviceIDRequestedBusiness.source_address_id
      const providerAndSourceAddressCount =
        providerCount + sourceAddressesOfRequestedService.length
      const collectServiceSteps = serviceById.serviceIDCollect.map(
        (collectService) => collectService.step,
      )

      if (serviceStep) {
        if (
          serviceStep !== 'toCollectBusinessService' &&
          serviceStep !== 'collectingBusinessService'
        ) {
          setIsLoading(true)
          redirectTo('/servicos-business/coletas')
          return
        }
      }
      if (collectServiceSteps && providerAndSourceAddressCount) {
        if (
          !collectServiceSteps.includes('GOING') &&
          collectServiceSteps.length === providerAndSourceAddressCount
        ) {
          setIsLoading(true)
          redirectTo('/servicos-business/coletas')
        }
      }
    }
  }, [serviceById, redirectTo])


  if (isLoading || isPageLoading) {
    return <GeneralContentLoading />
  }

  return (
    <>
      <GenerateLabelModal
        isModalOpen={isOpen}
        onClose={onClose}
        sourceAddresses={sourceModalAddresses}
        destinationAddresses={destinationModalAddresses}
        serviceProtocol={serviceById?.protocol!}
      />
      <CancelServiceModal
        serviceId={serviceById.id}
        serviceProtocol={serviceById.protocol}
        isOpen={isCancelServiceModalOpen}
        onClose={onCloseCancelServiceModal} />

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          noValidate
        >
          <Flex
            align="center"
            justify="space-between"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Endereços para Coleta
            </Heading>
          </Flex>

          <Divider my="6" borderColor="gray.700" />

          {sourceAddresses.length === 0 &&
            providerSourceAddress.length === 0 ? (
            <>
              <Stack
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'column']}
              >
                <Text fontSize="2xl">NÃO HÁ ENDEREÇOS PARA COLETAR</Text>
              </Stack>
            </>
          ) : (
            <>
              {sourceAddresses.map((address) => (
                <Stack
                  key={address.id}
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'column']}
                >
                  <Input
                    defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name}`.toUpperCase()}
                    isDisabled={isDisabled}
                    name="firstname"
                    label="Endereço Remetente"
                  />

                  <FormActionButton
                    href={`/servico-business/coleta/${service_id}/endereco/${address.id}`}
                    action="Coletar"
                    width="100%"
                    w="full"
                  />

                  <Divider my="6" borderColor="gray.700" />
                </Stack>
              ))}

              {providerSourceAddress.map((provider) => (
                <Stack
                  key={provider.id}
                  spacing="24px"
                  mt="4"
                  direction={['column', 'column', 'column']}
                >
                  <Input
                    defaultValue={`| ${provider.trading_name} | ${provider.street} | ${provider.number} | ${provider.neighborhood} | ${provider.city} | ${provider.cep}`.toUpperCase()}
                    isDisabled={isDisabled}
                    name="firstname"
                    label="Endereço do Fornecedor de Gelo Seco"
                  />

                  <FormActionButton
                    href={`/servico-business/coleta/${service_id}/endereco/${provider.id}`}
                    action="Coletar"
                    width="100%"
                    w="full"
                  />
                  <Divider my="6" borderColor="gray.700" />
                </Stack>
              ))}
            </>
          )}

          <Flex mt="8" justify="flex-end">
            <Flex w="full" justifySelf="flex-start">
              <Button onClick={onOpen}>
                {isWideVersion ? 'Download Etiqueta' : <Icon as={FaFilePdf} />}
              </Button>
            </Flex>
            <HStack spacing="12px">
              {userLogged !== null &&
                userLogged?.permissions.includes('cancel-service') && (
                  <CancelButton
                    type="button"
                    action="Cancelar Serviço"
                    onClick={() => onOpenCancelServiceModal()}
                  />
                )}
              <ListButton
                href="/servicos-business/coletas"
                name="Servioços Business à Coletar"
              />
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

import { Flex, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getDispatchStockProposal, GetDispatchStockProposalResponse } from "api/dispachStocks/getDispatchStockProposal";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useCollectorsFunctions } from "hooks/collector/useCollectorsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { useQuery, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { DispatchStockProposalStatus } from "./DispatchStockProposalStatus";

interface DispatchStockProposalDetailProps {
  proposalId: string
}

export function DispatchStockProposalDetail({
  proposalId
}: DispatchStockProposalDetailProps) {
  const { userLogged } = useAuth()

  const userCanUpdateInputSendedQuantity = userLogged?.permissions.includes('update-dispatch-stock-proposal-sended-quantity')

  const {
    data: result,
  } = useQuery({
    queryKey: ['dispatch-stock-proposal', proposalId],
    queryFn: () => getDispatchStockProposal({ proposalId })
  })

  const { updateCollectorDispatchStockProposal } = useCollectorsFunctions()

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  async function handleUpdateDispatchStockProposal(values: {
    sendQuantity: number
    inputId: string
    dispatchStockProposalId: string
  }) {
    await promiseMessage(updateCollectorDispatchStockProposal.mutateAsync({
      dispatchStockProposalId: values.dispatchStockProposalId,
      inputId: values.inputId,
      input: { sendQuantity: values.sendQuantity }
    }, {
      onSuccess: (_data, { input: body, inputId, dispatchStockProposalId }) => {
        const cached = queryClient.getQueriesData<GetDispatchStockProposalResponse>({
          queryKey: ['dispatch-stock-proposal']
        })

        cached.forEach(([cachedKey, cachedValue]) => {
          if (!cachedValue) return

          if (cachedValue.proposal.id === dispatchStockProposalId) {
            cachedValue.proposal.inputs.map((input => {
              if (input.input.id === inputId) {
                return { ...input, send_quantity: body.sendQuantity }
              }

              return input
            }))
          }

          queryClient.setQueryData(cachedKey, cachedValue)
        })
      }
    }), 'Proposta atualizada com sucesso.')

  }

  const invoiceTotalValue = result?.proposal?.inputs.reduce((acc, curr) => {
    return acc + (curr.send_quantity * curr.input.unit_cost);
  }, 0);

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Informações da Proposta</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>Protocolo</Td>
              <Td isNumeric>{result?.proposal?.protocol}</Td>
            </Tr>
            <Tr>
              <Td>Coletador</Td>
              <Td isNumeric>{result?.proposal?.collector ? captalize(result?.proposal?.collector.trading_name) : '-'}</Td>
            </Tr>
            <Tr>
              <Td>Valor total da proposta</Td>
              <Td isNumeric>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(invoiceTotalValue)}</Td>
            </Tr>
            <Tr>
              <Td>Status</Td>
              <Td as={Flex} w="full" justify="flex-end">
                <DispatchStockProposalStatus status={result?.proposal.status} />
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <TableContainer mt="4" rounded="md">
          <Table size="sm">
            <Thead
              h="40px"
              bg="#38C3FA"
            >
              <Tr>
                <Th color="white">Material</Th>
                <Th color="white">Quantidade a Enviar</Th>
                <Th color="white">Data da Solicitação</Th>
                <Th color="white">Estoque Atual</Th>
                <Th color="white">Estoque Padrão</Th>
                <Th color="white">Estoque Mínimo</Th>
                <Th color="white">Consumo nos Últimos 30 Dias</Th>
              </Tr>
            </Thead>
            <Tbody>
              {result?.proposal?.inputs?.map((input) => {
                return (
                  <Tr key={input.id}>
                    <Td>{input.input.name}</Td>
                    <Td isNumeric>
                      <Input
                        size="sm"
                        rounded="md"
                        type="number"
                        defaultValue={input.send_quantity}
                        isDisabled={!userCanUpdateInputSendedQuantity}
                        onBlur={event => handleUpdateDispatchStockProposal({
                          dispatchStockProposalId: proposalId,
                          inputId: input.input.id,
                          sendQuantity: Number(event.target.value)
                        })}
                      />
                    </Td>
                    <Td>{format(new Date(result?.proposal.created_at), 'dd/MM/yyyy')}</Td>
                    <Td isNumeric>{input.current_stock ?? '-'}</Td>
                    <Td isNumeric>{input.standard_stock ?? '-'}</Td>
                    <Td isNumeric>{input.min_stock ?? '-'}</Td>
                    <Td isNumeric>{input.period_consume ?? '-'}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </ModalBody>
    </ModalContent>
  )
}

import { Button, Divider, Flex, InputProps, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Stack, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { OccurrenceProps } from "contexts/OccurrenceContext";
import { useBranches } from "hooks/branch/useBranches";
import { useOccurrence } from "hooks/occurrences/useOccurrence";
import { Fragment, useEffect, useState } from "react";
import { formatDate } from "utils/DateFunctions/formatDate";
import { handleChangeUrl } from "utils/handleChangeUrl";


interface IInputsToShowProps {
  input_id: string
  inputName: string
  quantity: number
}

interface OldInputsProps {
  input_id: string
  name: string
  quantity: number
  prev_quantity?: number
}

interface DetailsOccurrenceModalProps {
  isOpen: boolean
  onClose: () => void
  occurrence: OccurrenceProps
  inputs: InputProps[]
}

export function DetailsOccurrenceModal({
  isOpen,
  onClose,
  occurrence,
  inputs
}: DetailsOccurrenceModalProps) {

  const {
    occurrence: {
      data: occurrenceById,
      isFetching: isFetchingOccurrence
    },
    handleSetOccurrenceId
  } = useOccurrence(false)

  const { data: branches, isFetching: isFetchingBranches } = useBranches()

  const serviceNewSourceBranch = branches?.find((branch) => branch.id === occurrenceById?.service_new_source_branch_id)

  const serviceNewDestinationBranch = branches?.find((branch) => branch.id === occurrenceById?.service_new_destination_branch_id)

  useEffect(() => {
    if (occurrence?.id) {
      handleSetOccurrenceId(occurrence?.id)
    }
  }, [occurrence, handleSetOccurrenceId])

  const [inputsToShow, setInputsToShow] = useState<IInputsToShowProps[] | null>(
    null,
  )

  const oldRegisteredInputs: OldInputsProps[] = occurrenceById?.prev_materials ? JSON.parse(occurrenceById?.prev_materials) : []


  useEffect(() => {
    if (
      occurrenceById?.intercurrence === 'ALTERAÇÃO DE INSUMOS UTILIZADOS' ||
      occurrenceById?.intercurrence === 'REPOSIÇÃO DE INSUMOS'
    ) {
      const inputsToShow = occurrenceById?.materials?.reduce((acc, curr) => {
        if (curr.input_id === 'GELO SECO') {
          acc.push({ input_id: curr.input_id, inputName: curr.input_id, quantity: curr.quantity })
        }

        inputs.forEach((input) => {
          if (input.id === curr.input_id) {
            acc.push({ input_id: curr.input_id, inputName: input.name, quantity: curr.quantity })
          }
        })
        return acc
      }, [] as IInputsToShowProps[])

      setInputsToShow(inputsToShow)
    }
  }, [occurrenceById, inputs])

  const combineRegisteredInputsAndOccurrenceMaterials = (
    registeredInputs: OldInputsProps[],
    materials: IInputsToShowProps[]
  ): OldInputsProps[] => {
    const newMaterialsArray: OldInputsProps[] = [];

    registeredInputs.forEach(input => {
      const matchingMaterial = materials?.find(
        material => input.input_id === material.input_id
      );

      if (matchingMaterial) {
        newMaterialsArray.push({
          input_id: input.input_id,
          name: input.name || matchingMaterial.inputName,
          prev_quantity: input.quantity,
          quantity: matchingMaterial.quantity,
        });
      }
    });

    return newMaterialsArray;
  };

  const updatedRegisteredInputs: OldInputsProps[] = combineRegisteredInputsAndOccurrenceMaterials(
    oldRegisteredInputs,
    inputsToShow
  );

  if (isFetchingOccurrence || isFetchingBranches) {
    return <Spinner />
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        overflow='auto'
        maxW={'2xl'}
      >
        <ModalHeader>Informações da ocorrência</ModalHeader>
        <ModalCloseButton />

        <ModalBody maxH='500px' as={Flex} flexDirection='column' gap={4} p={4}>
          <Text as='b' >Informações de abertura</Text>
          <Input
            name='protocol'
            defaultValue={occurrenceById?.occurrence_protocol}
            size='sm'
            label='PROTOCOLO (OCORRÊNCIA)'
            isDisabled
          />
          <Input
            name='protocol'
            defaultValue={occurrenceById?.occurrenceIDService?.protocol}
            size='sm'
            label='PROTOCOLO (SERVIÇO)'
            isDisabled
          />
          <Input
            name='trading_firstname'
            defaultValue={occurrenceById?.occurrenceIDCustomer?.trading_firstname}
            size='sm'
            label='Cliente'
            isDisabled
          />
          <Input
            label="Ocorrência"
            size='sm'
            name="occurrence_type"
            value={occurrenceById?.type}
            isDisabled={true}
          />
          <Input
            name='intercurrence'
            defaultValue={occurrenceById?.intercurrence}
            size='sm'
            label='Intercorrência'
            isDisabled
          />

          {occurrenceById?.intercurrence ===
            'SERVIÇO EXTRA' && (
              <Input
                label="Valor Lançado"
                name="occurrence_detail"
                size='sm'
                value={
                  occurrenceById?.occurrence_detail !== null
                    ? occurrenceById?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            )}

          {occurrenceById?.intercurrence === 'TENTATIVA DE ENTREGA SEM SUCESSO' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                name='unsuccess_delivery_address_id'
                size='sm'
                value={`${occurrence?.unsuccessDeliveryAddress.trading_name} | ${occurrence?.unsuccessDeliveryAddress.branch} | ${occurrence?.unsuccessDeliveryAddress.street} | ${occurrence?.unsuccessDeliveryAddress.number} | ${occurrence?.unsuccessDeliveryAddress.neighborhood} | ${occurrence?.unsuccessDeliveryAddress.cityIDAddress.name} | ${occurrence?.unsuccessDeliveryAddress.cep}
            `.toUpperCase()}
                label="Endereço"
                isDisabled={true}
              />
            </Stack>
          )}

          {occurrenceById?.intercurrence ===
            'DESVIO DE BUDGET DE TRANSFERÊNCIA' && (
              <Stack
                w="full"
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  label="Valor"
                  name="occurrence_detail"
                  size='sm'
                  value={
                    occurrence?.occurrence_detail !== null
                      ? occurrence?.occurrence_detail
                      : ''
                  }
                  isDisabled={true}
                />
              </Stack>
            )}

          {occurrenceById?.intercurrence === 'ESPERA ACIMA DE 10 MIN' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                label="Tempo"
                name="occurrence_detail"
                size='sm'
                value={
                  occurrence?.occurrence_detail !== null
                    ? occurrence?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            </Stack>
          )}

          {occurrenceById?.intercurrence === 'INSUMOS ERRADOS' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <TextArea
                label="Descrição"
                name="occurrence_detail"
                size='sm'
                value={
                  occurrence?.occurrence_detail !== null
                    ? occurrence?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            </Stack>
          )}

          {occurrenceById?.intercurrence === 'OUTRAS COBRANÇAS' && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                label="Valor"
                name="occurrence_detail"
                size='sm'
                value={
                  occurrenceById?.occurrence_detail !== null
                    ? occurrenceById?.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            </Stack>
          )}

          {(occurrenceById?.intercurrence === 'CANCELAMENTO DE VOO' ||
            occurrenceById?.intercurrence === 'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)') && (
              <>
                <Input
                  label="Motivo do atraso"
                  name="occurrence_detail"
                  size='sm'
                  value={occurrenceById!.occurrence_detail ?? ''}
                  isDisabled
                />
                <Input
                  label="Nova data prevista"
                  name="occurrence_date"
                  size='sm'
                  value={formatDate.handle(occurrenceById?.occurrence_date, "DateWithoutHourToShow")}
                  isDisabled={true}
                />
                <Input
                  label="Novo horário previsto"
                  name="occurrence_hour"
                  size='sm'
                  value={formatDate.handle(occurrenceById?.occurrence_hour, "DateOnlyWithHourMinute")}
                  isDisabled={true}
                />
              </>

            )}

          {(occurrenceById?.intercurrence === 'ATRASO NA COLETA' ||
            occurrenceById?.intercurrence === 'ATRASO NA ENTREGA') && (
              <>
                <Stack
                  w="full"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  {occurrenceById?.occurrence_date && (
                    <Input
                      label="Previsão de Coleta/Entrega (Data)"
                      name="occurrence_date"
                      size='sm'
                      value={
                        occurrenceById.occurrence_date !== null
                          ? formatDate.handle(
                            occurrence.occurrence_hour,
                            'DateWithoutHourToInput',
                          )
                          : ''
                      }
                      type="date"
                      isDisabled
                    />
                  )}
                  <Input
                    label="Previsão de Coleta/Entrega (Horário)"
                    name="occurrence_hour"
                    size='sm'
                    value={
                      occurrenceById?.occurrence_hour !== null
                        ? formatDate.handle(occurrenceById?.occurrence_hour, "DateOnlyWithHourMinute")
                        : ''
                    }
                    type="time"
                    isDisabled
                  />
                </Stack>

                <Input
                  label="Motivo do Atraso"
                  name="occurrence_detail"
                  size='sm'
                  value={
                    occurrenceById?.occurrence_detail !== null
                      ? occurrenceById.occurrence_detail
                      : ''
                  }
                  isDisabled
                />
              </>
            )}

          {occurrenceById?.intercurrence ===
            'RECLAMAÇÃO DE CLIENTE' && (
              <TextArea
                label="Reclamação"
                name="occurrence_detail"
                size='sm'
                value={
                  occurrenceById.occurrence_detail !== null
                    ? occurrenceById.occurrence_detail
                    : ''
                }
                isDisabled={true}
              />
            )}

          {occurrenceById?.occurrence_detail && (
            <TextArea
              name='occurrence_detail'
              defaultValue={occurrenceById.occurrence_detail}
              size='sm'
              label='Descrição'
              isDisabled
            />
          )}
          {occurrenceById?.service_new_source_branch_id && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                name='service_new_source_branch_id'
                size='sm'
                defaultValue={serviceNewSourceBranch?.nickname}
                label='Nova Base de Origem'
                isDisabled
              />
            </Stack>
          )}
          {occurrenceById?.service_new_destination_branch_id && (
            <Stack
              w="full"
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                name='service_new_destination_branch_id'
                defaultValue={serviceNewDestinationBranch?.nickname}
                label='Nova Base de Origem'
                size='sm'
                isDisabled
              />
            </Stack>
          )}
          {occurrenceById?.observation && (
            <TextArea
              name='observation'
              defaultValue={occurrenceById.observation}
              size='sm'
              label='Observações'
              isDisabled
            />
          )}

          {occurrenceById?.attachment_photo && (
            <Stack
              mt="4"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Button
                w='full'
                size='lg'
                as={Link}
                href={handleChangeUrl(occurrenceById?.attachment_photo)}
                isExternal
              >
                Visualizar anexo da ocorrência
              </Button>
            </Stack>
          )}

          {occurrenceById?.shipping_ticket_number !== null && (
            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                label="Número do chamado/ticket"
                name="shipping_ticket_number"
                size='sm'
                value={occurrence?.shipping_ticket_number}
                isDisabled={true}
              />
            </Stack>
          )}
          <Divider my="6" borderColor="gray.700" />

          {(inputsToShow && occurrenceById?.prev_materials) &&
            updatedRegisteredInputs.map((input) => (
              <Stack
                mt="4"
                key={input.input_id}
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Input
                  name={`input`}
                  label="Insumo"
                  size='sm'
                  defaultValue={input.name}
                  isDisabled={true}
                />
                <Input
                  name={`quantity`}
                  label="Quantidade"
                  size='sm'
                  defaultValue={input.quantity}
                  isDisabled={true}
                />
                <Input
                  name={`prev_quantity`}
                  label="Quantidade anterior"
                  size='sm'
                  defaultValue={input.prev_quantity}
                  isDisabled={true}
                />
              </Stack>
            ))}

          {occurrence.proceed !== null && (
            <>
              <Text as='b'>Informações de validação</Text>
              {occurrenceById?.proceed && (
                <Input
                  name='proceed'
                  size='sm'
                  defaultValue={occurrenceById?.proceed}
                  label='Procede?'
                  isDisabled
                />
              )
              }
              {occurrenceById?.reason && (
                <Input
                  name='reason'
                  defaultValue={occurrenceById?.reason}
                  size='sm'
                  label='Motivo'
                  isDisabled
                />
              )}
            </>
          )}



          {occurrenceById?.cause && (
            <Input
              name='cause'
              defaultValue={occurrenceById.cause}
              size='sm'
              label='Causa'
              isDisabled
            />
          )}
          {occurrenceById?.corrective && (
            <Input
              name='corrective'
              defaultValue={occurrenceById.corrective}
              size='sm'
              label='Corretiva'
              isDisabled
            />
          )}
          {occurrenceById?.preventive && (
            <Input
              name='preventive'
              defaultValue={occurrenceById.preventive}
              size='sm'
              label='Preventiva'
              isDisabled
            />
          )}


          <Divider />

          {occurrenceById?.alignments.length > 0 && (
            <>
              <Text as='b'>Informações de alinhamento</Text>
              <Input
                name='alignment_indicated_user'
                defaultValue={`${occurrenceById?.occurrenceIDAlignmentIndicatedUser?.firstname} ${occurrenceById?.occurrenceIDAlignmentIndicatedUser?.lastname}`}
                label='Usuário indicado no alinhamento'
                size='sm'
                isDisabled
              />
            </>

          )}

          {occurrenceById?.alignments?.map(alignment => {
            const alignmentRegistedAttachments = alignment?.attachments
              ? typeof alignment?.attachments !== 'string'
                ? alignment?.attachments
                : JSON.parse(alignment?.attachments)
              : []
            return (
              <Fragment key={alignment?.id}>
                <Stack
                  w="full"
                  spacing="24px"
                  direction={['column', 'column', 'row']}
                >
                  <Input
                    name='preventive_responsible'
                    defaultValue={`${alignment.preventiveResponsible.firstname} ${alignment.preventiveResponsible.lastname}`}

                    label='Responsável pela preventiva'
                    size='sm'
                    isDisabled
                  />
                  <Input
                    name='preventive_limit_date'
                    defaultValue={formatDate.handle(alignment.preventive_limit_date, "DateWithoutHourToInput")}
                    size='sm'
                    type='date'
                    label='Data limite da ação preventiva'
                    isDisabled
                  />
                </Stack>

                <TextArea
                  name='alignment_actions'
                  defaultValue={alignment.alignment_actions}
                  size='sm'
                  label='Ações de alinhamento'
                  mb={2}
                  isDisabled
                />
                {alignmentRegistedAttachments?.map((attachment) => {
                  return (
                    <Stack
                      mt="4"
                      spacing="24px"
                      direction={['column', 'column', 'row']}
                    >
                      <Button
                        w='full'
                        size='lg'
                        as={Link}
                        href={handleChangeUrl(attachment.key)}
                        isExternal
                      >
                        Visualizar anexo
                      </Button>
                    </Stack>

                  )
                })}
              </Fragment>
            )
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

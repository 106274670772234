import { Box, FormControl, FormLabel, Text, Textarea, VStack } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { AttachmentTableRowField } from "pages/Support/AttachmentTableRowField";
import { FormEvent, useEffect, useReducer } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { CreateSupportSchema } from "../CreateSupport";
import { AttachmentState, SetAttachmentAction } from "../InternalSupportForm";


interface CreateJuridicalSupportProps {
  reducer: (state: AttachmentState[], action: SetAttachmentAction) => AttachmentState[];
}

export function CreateJuridicalSupport({ reducer }: CreateJuridicalSupportProps) {

  const { register, control, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()
  const [attachments, setAttachments] = useReducer(reducer, [] as AttachmentState[])

  useEffect(() => {
    if (attachments.length !== 0) {
      setValue(`formData.attachments.label`, 'Anexos');
      setValue(`formData.attachments.value`, JSON.stringify(attachments));
      setValue(`formData.attachments.type`, 'attachments');
    }
  }, [attachments, setValue])

  const {
    fields: attachmentsFields,
    append: appendAttachmentsField,
    remove: removeAttachmentsField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const [supportSubjectId, attachmentsSelected] = useWatch({
    control,
    name: ['subject', 'attachments']
  })


  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      description: 'Descreva o que precisa que seja verificado/criado'
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  const hasDescriptioInSubject = [
    'Validação de documento(s)',
    'Criação de documento(s)'
  ].map(item => item.toLowerCase()).includes(supportSubjectResult?.supportSubject?.name.toLowerCase())

  return (
    <Box w='full'>
      {hasDescriptioInSubject && (
        <FormControl isInvalid={!!errors.formData?.description} mt={3}>
          <FormLabel fontSize="sm">
            Descreva o problema ou a sugestão
          </FormLabel>
          <Textarea
            {...register('formData.description.value')}
            name='formData.description.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('description')}
          />
        </FormControl>
      )}

      <FormLabel fontSize='sm' mt={3}>
        Anexo(s)
        <Text as="sup" color="red.500">*</Text>
      </FormLabel>
      <VStack spacing={3}>
        {attachmentsFields.map((field, index) => {

          const isFilledField = Boolean(attachmentsSelected[index]?.file?.length);
          return (
            <AttachmentTableRowField
              key={field.id}
              index={index}
              onSetAttachmentId={setAttachments}
              isFilledField={isFilledField}
              onAppendField={() => appendAttachmentsField({})}
              onRemoveField={() => removeAttachmentsField(index)} />
          );
        })}
      </VStack>

    </Box>
  )
}

import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Progress, Radio, RadioGroup, Stack, Table, Tbody, Td, Text, Textarea, Tr } from "@chakra-ui/react"
import { useToastify } from "hooks/toastify/useToastify"
import { useMutation, useQueryClient } from "react-query"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { validateHasFile } from "utils/fileValidation"
import { useForm, useWatch } from "react-hook-form"
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation"
import { ChangeEvent } from "react"
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa"
import { disableRequestedPatrimony } from "api/patrimonies/disableRequestedPatrimony"

interface DisablePatrimonyRequestProps {
  onCloseModal: () => void
  patrimonyRequestId: string
  patrimonyId: string
}

interface DisablePatrimonyRequestSchema {
  defectiveOrBrokenDescription: string
  defectOrBrokenAttachmentId: FileList
  isDefectiveOrBroken: string
}

const disablePatrimonyRequestSchema = yup.object({
  isDefectiveOrBroken: yup.mixed().required().transform((value) => {
    return value.toLowerCase() === 'yes'
  }),
  defectiveOrBrokenDescription: yup.mixed().when('isDefectiveOrBroken', {
    is: 'yes',
    then: yup.string().required()
  }),
  defectOrBrokenAttachmentId:yup.mixed().when('isDefectiveOrBroken', {
    is: 'yes',
    then: yup.mixed().test(value => validateHasFile(value)).required()
  }),
})

export function DisablePatrimonyRequest({ onCloseModal, patrimonyRequestId, patrimonyId }: DisablePatrimonyRequestProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const { mutation: uploadDefectOrBrokenAttachmentFn, uploadProggress: uploadProggressDefectOrBrokenAttachment } = useAttachmentMutation()

  const { mutateAsync: disableRequestedPatrimonyFn } = useMutation({
    mutationFn: disableRequestedPatrimony,
    onSuccess() {
      queryClient.invalidateQueries(['requestsPatrimony'])
      onCloseModal()
    }
  })

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DisablePatrimonyRequestSchema>({
    resolver: yupResolver(disablePatrimonyRequestSchema)
  })

  const [defectOrBrokenAttachmentId, isDefectiveOrBroken] = useWatch({
    control,
    name: ['defectOrBrokenAttachmentId', 'isDefectiveOrBroken'],
  })


  async function handleUploadReceiptAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadDefectOrBrokenAttachmentFn.mutateAsync(formData)

  }

  async function handleDisablePatrimonyRequest({
    defectiveOrBrokenDescription,
    isDefectiveOrBroken
  }: DisablePatrimonyRequestSchema) {
    await promiseMessage(disableRequestedPatrimonyFn({
      body: {
        defectiveOrBrokenDescription,
        isDefectiveOrBroken,
        defectOrBrokenAttachmentId: uploadDefectOrBrokenAttachmentFn?.data?.attachment.id
      },
      patrimonyId: patrimonyId,
      patrimonyRequestId: patrimonyRequestId
    }), 'Patrimônio inutilizado! 🎉')
  }

  return (
    <ModalContent>
      <ModalHeader>Inutilizar Patrimônio</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleDisablePatrimonyRequest)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Apresentou defeito ou quebra ?
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.isDefectiveOrBroken}>
              <RadioGroup >
                <Radio
                  {...register('isDefectiveOrBroken')}
                  name="isDefectiveOrBroken"
                  size='sm'
                  rounded='md'
                  mr='7'
                  value='yes'>
                  Sim
                </Radio>
                <Radio
                  {...register('isDefectiveOrBroken')}
                  name="isDefectiveOrBroken"
                  size='sm'
                  rounded='md'
                  mr='7'
                  value='no'>
                  Não
                </Radio>

              </RadioGroup>

            </FormControl>
          </Stack>
        </Stack>
        {isDefectiveOrBroken === 'yes' && (
          <>
            <Stack
              spacing="6"
              direction={["column", "column", "column"]}
              mt="3"
            >
              <Stack
                direction="column"
                spacing="0.25"
                w="full"
              >
                <FormControl w='full' isInvalid={!!errors?.defectiveOrBrokenDescription}>
                  <FormLabel fontSize="sm">Descrição</FormLabel>
                  <Textarea
                    {...register('defectiveOrBrokenDescription')}
                    name="defectiveOrBrokenDescription"
                    size="sm"
                    rounded='md'
                  />

                </FormControl>
              </Stack>
            </Stack>
            <Stack
              spacing="6"
              direction={["column", "column", "column"]}
              mt="3"
            >
              <Stack
                direction="column"
                spacing="0.25"
                w="full"
              >

                <Button
                  as={FormLabel}
                  htmlFor="defectOrBrokenAttachmentId"
                  lineHeight="1"
                  leftIcon={<FaFileImport />}
                  size="sm"
                  w="min"
                  cursor="pointer"
                  border={!!errors?.defectOrBrokenAttachmentId && '2px solid'}
                  borderColor={(!!errors?.defectOrBrokenAttachmentId) && 'red.500'}
                >
                  Anexar foto
                </Button>
                <FormControl isInvalid={!!errors?.defectOrBrokenAttachmentId}>
                  <Input
                    {...register('defectOrBrokenAttachmentId')}
                    name="defectOrBrokenAttachmentId"
                    id="defectOrBrokenAttachmentId"
                    type="file"
                    accept="image/*"
                    hidden
                    onChangeCapture={handleUploadReceiptAttachment}
                  />
                </FormControl>
              </Stack>

              {defectOrBrokenAttachmentId && (
                <Table size="sm">
                  <Tbody>
                    {Object.entries(defectOrBrokenAttachmentId).map(([key, file]) => {
                      return (
                        <Tr key={key}>
                          <Td fontSize="xs" maxW="100px">{file.name}</Td>
                          <Td fontSize="xs" w="200px">
                            <Progress size="sm" rounded="md" value={uploadProggressDefectOrBrokenAttachment} />
                          </Td>
                          <Td fontSize="xs" isNumeric>
                            {uploadDefectOrBrokenAttachmentFn.data && (
                              <IconButton
                                aria-label="Visualizar anexo"
                                as={Link}
                                size="sm"
                                icon={<FaExternalLinkAlt />}
                                href={uploadDefectOrBrokenAttachmentFn.data.attachment.link}
                                isExternal
                              />
                            )}
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              )}
            </Stack>
          </>
        )}

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Inutilizar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

import { Flex, Box, Heading } from '@chakra-ui/react'

import { UserTable } from '../../components/Tables/User/UserTable'
import { useEffect, useState } from 'react'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useHistory } from 'react-router-dom'
import { useUsers } from 'hooks/user/useUsers'

export function UserList() {
  const [isLoading, setIsLoading] = useState(true)


  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const isUserLoggedCustomer = userLogged?.user_type === 'CLIENTE'

  const { data: users, isLoading: isUserLoading, refetch } = useUsers({ queryParms: {
    customer_id: isUserLoggedCustomer ? userLogged?.customer_id : null,
    collector_id: userLogged?.collector_id
  } })

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-user')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  useEffect(() => {
    function run() {
      if (!isUserLoading) {
        setIsLoading(false)
      }
    }
    run()
  }, [isUserLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Lista de Usuários
            </Heading>
          </Flex>
          {!!users && <UserTable users={users} refetch={refetch} />}
        </Box>
      </Flex>

  )
}

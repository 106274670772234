import { usePagination } from "@ajna/pagination";
import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getOnboardingReport } from "api/onboardings/getOnboardingReport";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { onboardingStatusMap, OnboardingStatusThemeTypeMap } from "utils/onboardingMappers";
import { OnboardingsReportFilters } from "./components/OnboardingsReportFilters";

const headers = [
  { label: 'Nome', key: 'name' },
  { label: 'Tipo de treinamento', key: 'training_type' },
  { label: 'CPF', key: 'cpf' },
  { label: 'E-mail', key: 'email' },
  { label: 'Status', key: 'status' },
  { label: 'Certificado', key: 'certificate' },
  { label: 'Teste da cumbuca', key: 'llm_training_themes' },
]


export function OnboardingsReport() {

  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const status = searchParams.get('status') ?? 'all'

  const userCanViewOnboardingReport = userLogged?.permissions.includes('view-onboardings-report')

  useEffect(() => {
    if (!userCanViewOnboardingReport) history.push('/')
  }, [history, userCanViewOnboardingReport])

  const {
    data: onboardingsReportData
  } = useQuery({
    queryKey: ['onboardings-report', startDate, endDate, status],
    queryFn: () => getOnboardingReport({
      queryParams: {
        endDate,
        startDate,
        status,
        timezoneOffset: new Date().getTimezoneOffset() / 60
      }
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const csvPropsData = onboardingsReportData
    ? onboardingsReportData?.onboardings.map(onboarding => {
      return {
        ...onboarding,
        status: onboardingStatusMap[onboarding.status],
        llm_training_themes: onboarding.llm_training_themes ? onboarding?.llm_training_themes.map((llmTrainingTheme) =>{
          return `
            Tema: ${llmTrainingTheme.theme},
            Status: ${OnboardingStatusThemeTypeMap[llmTrainingTheme.status]}
          `
        }) : '-',
        certificate: onboarding?.certificate ? onboarding.certificate.link : '-'
      }
    })
    : []

  const csvProps = {
    data: csvPropsData,
    headers,
    filename: `relatório-onboardings-${new Date().toISOString()}.csv`,
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (
    <Box
      p="6"
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight">Relatório de Onboardings</Heading>
      <OnboardingsReportFilters csvProps={csvProps} />
      {onboardingsReportData && (
        <TableContainer mt="6">
          <Table
            size="sm"
          >
            <Thead>
              <Tr>
                <Th>Colaborador</Th>
                <Th>Tipo de Treinamento</Th>
                <Th>CPF</Th>
                <Th>E-mail</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {onboardingsReportData?.onboardings.slice(offset, offset + 10).map((onboarding) => {
                return (
                  <Tr key={onboarding.id}>
                    <Td>{onboarding.name}</Td>
                    <Td>{onboarding.training_type}</Td>
                    <Td>{onboarding.cpf}</Td>
                    <Td>{onboarding.email}</Td>
                    <Td>{onboardingStatusMap[onboarding.status]}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}

import axios from "axios";
import { Notification } from "hooks/notification/dtos/Notification";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface GetUserNotificationsParams {
  pageSize?: string
  currentPage?: string
}

export interface GetUserNotificationsResponse {
  notifications: {
    id: string
    is_read: boolean
    created_at: string
    notification: Notification
  }[]
  meta: {
    currentPage: number | null
    pageSize: number | null
    totalPages: number | null
    count: number
    unreadCount: number
  }
}

export async function getUserNotifications({
  pageSize,
  currentPage,
}: GetUserNotificationsParams) {
  try {
    const { data } = await api.get<GetUserNotificationsResponse>('/users/notifications', {
      params: {
        pageSize,
        currentPage,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


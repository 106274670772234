import { api } from '../../../services/api'

export type CreateDriverInputProps = {
  situation: string
  firstname: string
  lastname: string
  collector_id: string
  cpf: string
  email: string
  observation: string
  intervals: {
    timeStartInMinutes: number
    timeEndInMinutes: number
    weekDay: number
  }[] | null
}

export type EditDriverInputProps = CreateDriverInputProps

export type EditDriverReqProps = {
  driverId: string
  editDriverInput: EditDriverInputProps
}

const createDriverReqFunction = (driverInput: CreateDriverInputProps) =>
  api
    .post('/driver', driverInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const editDriverReqFunction = ({
  driverId,
  editDriverInput,
}: EditDriverReqProps) =>
  api
    .put(`/driver/${driverId}`, editDriverInput)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

const deleteDriverReqFunction = (driverId: string) =>
  api
    .delete(`/driver/${driverId}`)
    .then((res) => res.data)
    .catch((e) => {
      throw new Error(e.response.data.message)
    })

export {
  createDriverReqFunction,
  editDriverReqFunction,
  deleteDriverReqFunction,
}

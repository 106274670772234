import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Flex, ModalFooter, Button, Link, FormLabel, Icon, Text } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { TextArea } from "components/Inputs/TextInput"
import { Input } from "components/Inputs/Input";
import { useInternComunicationsFunctions } from "hooks/internComunication/useInternComunicationsFunctions"
import { useInternComunicationSocket } from "hooks/socket/useInternComunicationSocket"
import { useSwal } from "hooks/swal/useSwal"
import { useForm, useWatch } from "react-hook-form"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import * as yup from "yup"
import { FiFile } from "react-icons/fi";
import { GetInternComunicationsByResponsibleIdResponse } from "api/internComunications/getInternComunicationsByResponsibleId";
import { useAuth } from "hooks/auth/useAuth";

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/zip",
  "application/gzip",
  "application/x-zip-compressed",
  "application/x-rar-compressed",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
]

interface InternComunicationValidateAnswerModalInputs {
  comunication_answer: string
  answer_attachment: FileList
}

interface InternComunicationAnswerModalInfo {
  id: string
  comunication_owner: string
  comunication_question: string
}

interface InternComunicationAnswerModalProps {
  isInternComunicationAnswerModalOpen: boolean
  onCloseInternComunicationAnswerModal: () => void
  internComunicationAnswerModalInfo: InternComunicationAnswerModalInfo
}

const internComunicationAnswerModalSchema = yup.object().shape({
  comunication_answer: yup.string().required("Campo obrigatório")
})

function cleanText(text: string) {
  return text?.replace(/<\/?[^>]+(>|$)/g, '').replace(/strong/g, '').trim();
}

export function InternComunicationAnswerModal({
  internComunicationAnswerModalInfo,
  isInternComunicationAnswerModalOpen,
  onCloseInternComunicationAnswerModal,
}: InternComunicationAnswerModalProps) {

  const history = useHistory()

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting }
  } = useForm<InternComunicationValidateAnswerModalInputs>({
    resolver: yupResolver(internComunicationAnswerModalSchema)
  })

  const answerAttachment = useWatch({
    control,
    name: 'answer_attachment'
  })

  const {
    answerInternComunication: { mutateAsync: answerInternComunication }
  } = useInternComunicationsFunctions()

  const { standardMessage, confirmMessage } = useSwal()
  const { internComunicationSocketConnection } = useInternComunicationSocket()

  const { userLogged } = useAuth()
  const queryClient = useQueryClient()

  async function handleAnswerInternComunication(
    values: InternComunicationValidateAnswerModalInputs
  ) {
    const hasAnswerInternComunication = await confirmMessage({ title: "Deseja responder uma comunicação interna?" })

    if (hasAnswerInternComunication) {

      internComunicationSocketConnection.emit("internComunicationAnswered", {
        comunication_answer: values.comunication_answer,
        comunication_owner: internComunicationAnswerModalInfo.comunication_owner
      })

      const formData = new FormData()
      formData.append('comunication_answer', values.comunication_answer)
      formData.append('answer_attachment', values.answer_attachment[0])

      await answerInternComunication({
        id: internComunicationAnswerModalInfo.id,
        formData
      }, {
        onSuccess: async (_data, { id }) => {
          reset()
          queryClient.invalidateQueries({
            queryKey: ['responsible-intern-comunications', userLogged?.id]
          })
          history.replace({ state: undefined })
          onCloseInternComunicationAnswerModal()
        }
      })

      await queryClient.invalidateQueries('userTasks')
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  function handleCloseModal() {
    history.replace({ state: undefined })
    onCloseInternComunicationAnswerModal()
  }

  const attachmentPhotoPreview = answerAttachment
    ? Object.entries(answerAttachment).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  return (
    <Modal
      isOpen={isInternComunicationAnswerModalOpen}
      onClose={handleCloseModal}
    >
      <ModalOverlay />
      <ModalContent as="form" noValidate onSubmit={handleSubmit(handleAnswerInternComunication)}>
        <ModalHeader>Responder comunicação interna</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={6} p={4}>
            <TextArea
              name="comunication_question"
              label="Descrição da solicitação"
              isReadOnly={true}
              opacity="0.5"
              value={cleanText(internComunicationAnswerModalInfo.comunication_question)}
              style={{
                minHeight: '100px',
                resize: 'vertical',
                overflow: 'hidden',
              }}

            />
            <TextArea
              {...register("comunication_answer")}
              name="comunication_answer"
              label="Resposta"
              error={errors.comunication_answer}
              required
            />
            <Input
              {...register("answer_attachment")}
              name="answer_attachment"
              type="file"
              accept={supportedFileFormats.join(", ")}
              hidden
              label="Arquivo (Opcional)"
              error={errors.answer_attachment}
            >
              <Button
                leftIcon={<Icon as={FiFile} />}
                as={FormLabel}
                htmlFor="answer_attachment"
                _hover={{
                  cursor: "pointer"
                }}
              >Escolha um arquivo</Button>
            </Input>
            {attachmentPhotoPreview && (
              <Link
                href={URL.createObjectURL(answerAttachment[0])}
                isExternal
              >
                <Text as='b'>{answerAttachment[0].name} (Visualizar)</Text>
              </Link>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button variant='ghost' onClick={handleCloseModal}>
              Fechar
            </Button>
            <Button isLoading={isSubmitting} type="submit" colorScheme='blue'>
              Responder
            </Button>
          </Flex>

        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { Accordion } from '@chakra-ui/react'
import { Address } from 'hooks/address/dtos/Address'
import { memo } from 'react'
import { BranchProps } from '../../../../contexts/BranchContext'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import BranchAccordion from '../BranchAccordion/BranchAccordion'
import { RequestedAccordion } from '../RequestedAccordion/RequestedAccordion'

interface BoardAccordionProps {
  serviceByID: ServiceProps
  addresses: Address[]
  branches: BranchProps[]
  branchId: string
}

function BoardAccordion({
  serviceByID,
  branchId,
  addresses,
  branches,
}: BoardAccordionProps) {
  return (
    <Accordion allowMultiple>
      <RequestedAccordion
        serviceByID={serviceByID}
        addresses={addresses}
        branches={branches}
      />
      <BranchAccordion serviceByID={serviceByID} branchId={branchId} />
    </Accordion>
  )
}

export default memo(BoardAccordion)

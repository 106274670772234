import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface CreatePassiveFeedbackBody {
  internCltId: string
  cltDriverId: string
  deadline: string
}

interface CreatePassiveFeedbackParams {
  body: CreatePassiveFeedbackBody
}

export async function createPassiveFeedback({
  body
}: CreatePassiveFeedbackParams) {
  try {
    await api.post('/feedbacks/passives', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


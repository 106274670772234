import { Flex, Box, Heading, Button, Icon, useDisclosure } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { HubsTable } from '../../components/Tables/Hub/HubsTable'
import { useEffect } from 'react'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useHub } from '../../hooks/hub/useHub'
import { useContentLoading } from '../../hooks/loading/useContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { UpdateHubResponsibleModal } from './components/UpdateHubResponsibleModal'

export function HubList() {
  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()
  const { userLogged } = useAuth()

  const {
    hubs: { data: hubs, isLoading: isHubLoading },
  } = useHub(null, true)

  useEffect(() => {
    function run() {
      if (!isHubLoading) {
        handleSetIsContentLoadingFalse()
      }
    }
    run()
  }, [isHubLoading, handleSetIsContentLoadingFalse])

  const {
    isOpen: isUpdateHubResponsibleModalOpen,
    onClose: onCloseUpdateHubResponsibleModal,
    onOpen: onOpenUpdateHubResponsibleModal
  } = useDisclosure()

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  const userHasCreateHubPermission = userLogged?.permissions.includes("add-hub")
  const userHasUpdateHubResponsiblePermission = userLogged?.permissions.includes("update-hub-responsible")

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>

        <UpdateHubResponsibleModal
          isUpdateHubResponsibleModalOpen={isUpdateHubResponsibleModalOpen}
          onCloseUpdateHubResponsibleModal={onCloseUpdateHubResponsibleModal}
        />

        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            gap={4}
            justify={["center", "center", "space-between"]}
            direction={['column', 'column', 'row']}
            align="center"
          >
            <Heading size="lg" fontFamily="poppins">
              Lista de HUBs
            </Heading>
            <Flex gap={4} direction={['column', 'column', 'row']}>
              {userHasUpdateHubResponsiblePermission && (
                <Button
                  onClick={onOpenUpdateHubResponsibleModal}
                  colorScheme="gray"
                  rightIcon={<Icon as={FiPlus} />}
                  size="sm"
                >
                  Atualizar responsáveis
                </Button>
              )}
              {userHasCreateHubPermission && (
                <Button
                  as={Link}
                  to="/hub/adicionar"
                  colorScheme="gray"
                  rightIcon={<Icon as={FiPlus} />}
                  size="sm"
                >
                  Adicionar HUB
                </Button>
              )}
            </Flex>
          </Flex>
          {!!hubs && <HubsTable hubs={hubs} />}
        </Box>
      </Flex>

  )
}

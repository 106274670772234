import axios from "axios";
import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Bug } from "./dtos/Bug";

interface BugsResponse {
  bugs: Bug[]
  totalPages: number | number
  count: number
}

type UseBugsQueryOptions = UseInfiniteQueryOptions<BugsResponse, unknown, BugsResponse, QueryKey>

interface QueryParams {
  pageSize?: number
  currentPage?: number
  status?: string
}

interface UseBugsProps {
  queryKey?: string
  queryOptions?: UseBugsQueryOptions
  queryParams?: QueryParams
}

export function useBugs(options?: UseBugsProps) {

  async function getBugs({ pageParam = 1 }) {
    try {
      const { data } = await api.get('/bugs', {
        params: {
          ...options?.queryParams,
          currentPage: pageParam,
        }
      })

      return data

    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  return useInfiniteQuery(options?.queryKey ?? 'bugs', getBugs, options?.queryOptions)
}

import { Box, Button, Flex, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt, FaTag, FaTimes } from "react-icons/fa";
import { AdvertenceDetail } from "./AdvertenceDetail";
import { useAuth } from "hooks/auth/useAuth";
import { Advertence } from "api/advertences/types/Advertence";

import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { GetAdvertencesResponse } from "api/advertences/getAdvertences";
import { advertenceTypeMap } from "utils/advertenceMapppers";
import { format } from "date-fns";
import { completeAdvertence } from "api/advertences/completeAdvertence";
import { cancelAdvertence } from "api/advertences/cancelAdvertence";



interface AdvertencesKanbanCardProps {
  advertence: Advertence
}

export function AdvertencesKanbanCard({ advertence }: AdvertencesKanbanCardProps) {
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()
  const userCanCompleteAdvertence = userLogged?.permissions?.includes('complete-advertence')
  const userCanCancelAdvertence = userLogged?.permissions?.includes('cancel-advertence')


  const {
    isOpen: isAdvertenceDetailModalOpen,
    onToggle: onToggleAdvertenceDetailModal,
  } = useDisclosure()

  const { mutateAsync: cancelAdvertenceFn } = useMutation({
    mutationFn: cancelAdvertence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetAdvertencesResponse>({
        queryKey: ['advertences']
      })
      queryClient.invalidateQueries({ queryKey: 'awaiting-application-advertences' })
      queryClient.invalidateQueries({ queryKey: 'parent-verification-advertences' })
      queryClient.invalidateQueries({ queryKey: 'cancelled-advertences' })
      queryClient.invalidateQueries({ queryKey: 'done-advertences' })
      queryClient.invalidateQueries({ queryKey: ['advertence', routeParams?.advertenceId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          advertences: cachedValue.advertences.map((advertence) => {
            if (advertence.id === routeParams.advertenceId) {
              return { ...advertence, status: 'canceled' }
            }

            return advertence
          })
        })
      })
    }
  })

  const { mutateAsync: completeAdvertenceFn } = useMutation({
    mutationFn: completeAdvertence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetAdvertencesResponse>({
        queryKey: ['advertences']
      })
      queryClient.invalidateQueries({ queryKey: 'awaiting-application-advertences' })
      queryClient.invalidateQueries({ queryKey: 'parent-verification-advertences' })
      queryClient.invalidateQueries({ queryKey: 'cancelled-advertences' })
      queryClient.invalidateQueries({ queryKey: 'done-advertences' })
      queryClient.invalidateQueries({ queryKey: ['advertence', routeParams?.advertenceId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          advertences: cachedValue.advertences.map((advertence) => {
            if (advertence.id === routeParams.advertenceId) {
              return { ...advertence, status: 'done' }
            }

            return advertence
          })
        })
      })
    }
  })

  async function handleCancelAdvertence() {
    await promiseMessage(
      cancelAdvertenceFn({ routeParams: { advertenceId: advertence.id } }),
      'Advertência cancelada com sucesso!'
    )
  }

  async function handleCompleteAdvertence() {
    await promiseMessage(
      completeAdvertenceFn({ routeParams: { advertenceId: advertence.id } }),
      'Advertência concluída com sucesso!'
    )
  }


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleAdvertenceDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {advertence?.user.firstname} {advertence?.user.lastname}
        </Text>
      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaTag} />
            <Text fontSize="xs" fontWeight="bold">ADVERTÊNCIA</Text>
          </HStack>
          <Text ml={5}>{advertenceTypeMap[advertence.advertence_type]}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA ADVERTÊNCIA</Text>
          </HStack>
          <Text ml={5}>{advertence.date ? format(new Date(advertence.date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>

      </VStack>
      <Flex align="center" gap="2" alignItems="flex-end" justifyContent="flex-end">
        {userCanCancelAdvertence && advertence?.status === 'awaiting-application' && (
          <Button
            size="xs"
            lineHeight="1"
            leftIcon={<FaTimes />}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              handleCancelAdvertence()
            }}
          >
            Cancelar
          </Button>
        )}

        {userCanCompleteAdvertence && advertence?.status === 'parent-verification' && (
          <Button
            size="xs"
            lineHeight="1"
            leftIcon={<FaTimes />}
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation()
              handleCompleteAdvertence()
            }}
          >
            Concluir
          </Button>
        )}
      </Flex>
      <Modal
        isOpen={isAdvertenceDetailModalOpen}
        onClose={onToggleAdvertenceDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <AdvertenceDetail
          advertenceId={advertence.id}
        />
      </Modal>
    </Box>
  )
}

import { useMutation } from "react-query"
import { api } from "services/api"

interface CreateExternalComunicationInput {
  subject: string
  content: string
}

interface CreateExternalComunicationReqFunctionProps {
  serviceId: string
  input: CreateExternalComunicationInput
}

async function createExternalComunicationReqFunction({
  input,
  serviceId
}: CreateExternalComunicationReqFunctionProps) {
  try {
    const { data } = await api.post(`/service/${serviceId}/comunications/external`, input)

    return data
  } catch (error) {
    return error
  }
}

interface AnswerExternalComunicationInput {
  content: string
}

interface AnswerExternalComunicationReqFunctionProps {
  externalComunicationId: string
  questionId: string
  input: AnswerExternalComunicationInput
}

async function answerExternalComunicationReqFunction({
  externalComunicationId,
  questionId,
  input
}: AnswerExternalComunicationReqFunctionProps) {
  try {
    const { data } = await api.patch(`/comunications/external/${externalComunicationId}/answer/questions/${questionId}`, input)

    return data
  } catch (error) {
    return error
  }
}

interface CreateExternalComunicationQuestionInput {
  content: string
}

interface CreateExternalComunicationAnswerReqFunctionProps {
  externalComunicationId: string
  input: CreateExternalComunicationQuestionInput
}

async function createExternalComunicationQuestionReqFunction({
  externalComunicationId,
  input
}: CreateExternalComunicationAnswerReqFunctionProps) {
  try {
    const { data } = await api.patch(`/comunications/external/${externalComunicationId}/questions`, input)

    return data
  } catch (error) {
    return error
  }
}


interface CloseExternalComunicationReqFunctionProps {
  externalComunicationId: string
}

async function closeExternalComunicationReqFunction({
  externalComunicationId,
}: CloseExternalComunicationReqFunctionProps) {
  try {
    const { data } = await api.patch(`/comunications/external/${externalComunicationId}/close`)

    return data
  } catch (error) {
    return error
  }
}

export function useExternalComunicationsFunctions() {

  const createExternalComunication = useMutation(
    createExternalComunicationReqFunction
  )

  const answerExternalComunication = useMutation(
    answerExternalComunicationReqFunction
  )

  const createExternalComunicationQuestion = useMutation(
    createExternalComunicationQuestionReqFunction
  )

  const closeExternalComunication = useMutation(
    closeExternalComunicationReqFunction
  )

  return {
    createExternalComunication,
    answerExternalComunication,
    createExternalComunicationQuestion,
    closeExternalComunication,
  }
}

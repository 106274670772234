import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Socket } from "socket.io-client";
import { socketApi } from "../services/socket/socket";

interface PurchaseOrdersSocketContextProps {
  purchaseOrderSocketConnection: Socket | null
}

interface PurchaseOrdersSocketProviderProps {
  children: ReactNode
}

interface CreatedPurchaseOrderNotificationData {
  message: string
  profiles: string[]
}

interface QuotedPurchaseOrderNotificationData {
  message: string
  responsibleForApproval: string
}

interface ApprovedPurchaseOrderNotificationData {
  message: string
  quotedBy: string
}

export const PurchaseOrdersSocketContext = createContext({} as PurchaseOrdersSocketContextProps)

export function PurchaseOrdersSocketProvider({ children }: PurchaseOrdersSocketProviderProps) {
  const [purchaseOrderSocketConnection, setPurchaseOrdersSocketConnection] = useState<Socket | null>(null)

  const { infoMessage } = useToastify()
  const { userLogged } = useAuth()

  const queryClient = useQueryClient()

  useEffect(() => {
    setPurchaseOrdersSocketConnection(socketApi.connect("purchase-orders"))
  }, [])

  useEffect(() => {
    purchaseOrderSocketConnection?.on("purchaseOrderCreatedNotification", async ({
      message,
      profiles
    }: CreatedPurchaseOrderNotificationData) => {
      const isUserLoggedNotification = profiles.includes(userLogged?.user_type) || userLogged?.user_type === 'ADMIN'

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    purchaseOrderSocketConnection?.on("purchaseOrderQuotedNotification", async ({
      message,
      responsibleForApproval,
    }: QuotedPurchaseOrderNotificationData) => {
      console.log(responsibleForApproval, "Responsible")

      const isUserLoggedNotification = userLogged?.id === responsibleForApproval

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    purchaseOrderSocketConnection?.on("purchaseOrderApprovedNotification", async ({
      message,
      quotedBy,
    }: ApprovedPurchaseOrderNotificationData) => {
      const isUserLoggedNotification = userLogged?.id === quotedBy

      if (isUserLoggedNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications']})

        infoMessage(message)
      }
    })

    return () => {
      purchaseOrderSocketConnection?.off("purchaseOrderCreatedNotification")
      purchaseOrderSocketConnection?.off("purchaseOrderQuotedNotification")
      purchaseOrderSocketConnection?.off("purchaseOrderApprovedNotification")
    }
  }, [purchaseOrderSocketConnection, queryClient, infoMessage, userLogged])

  return (
    <PurchaseOrdersSocketContext.Provider value={{
      purchaseOrderSocketConnection,
    }}>
      {children}
    </PurchaseOrdersSocketContext.Provider>
  )
}

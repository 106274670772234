import { Flex, Stack } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { InputMaskCustom } from "components/Inputs/InputMask";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { AddressIcmsTypes, AddressPersonTypes, AddressSituationType } from "hooks/address/useAddressesFunctions";
import { useAuth } from "hooks/auth/useAuth";
import { useCustomer } from "hooks/customer/useCustomer";
import { useIbgeState } from "hooks/ibge/useIbgeState";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { contribution_types, negative_positive, situation } from "utils/customLists";
import { handleSwitchMask } from "utils/handleChangeMask";

interface AddressFormProps {
  slug: string
  cities: CitiesProps[]
}

export interface AddressFormInputs {
  customer_id: string
  type: AddressPersonTypes
  cnpj_cpf: string
  trading_name: string
  branch: string
  responsible_name: string
  responsible_email: string
  responsible_telephone: string
  cep: string
  state: string
  city_id: string
  street: string
  number: string
  neighborhood: string
  complement: string
  reference_point: string
  icms: AddressIcmsTypes
  business_open: string
  business_close: string
  saturday_open?: string
  saturday_close?: string
  sunday_open?: string
  sunday_close?: string
  observation: string
  situation: AddressSituationType
  has_business_operation: 'SIM' | 'NÃO'
  has_saturday_operation: 'SIM' | 'NÃO'
  has_sunday_operation: 'SIM' | 'NÃO'
  has_own_board_landing?: 'SIM' | 'NÃO'
  deadline: number
}

const addressPersonTypes = [
  { id: 0, name: 'PESSOA FÍSICA' },
  { id: 1, name: 'PESSOA JURÍDICA' },
]

const deadlineOptions = [
  { key: '0', value: 0, showOption: 'D + 0' },
  { key: '1', value: 1, showOption: 'D + 1' },
  { key: '2', value: 2, showOption: 'D + 2' },
  { key: '3', value: 3, showOption: 'D + 3' },
  { key: '4', value: 4, showOption: 'D + 4' },
  { key: '5', value: 5, showOption: 'D + 5' },
]

export function AddressForm({
  slug,
  cities
}: AddressFormProps) {

  const {
    ibgeStates: { data: ibgeStates, isLoading: isIbgeStatesLoading }
  } = useIbgeState()

  const {
    customers: { data: customers, isLoading: isCustomersLoading }
  } = useCustomer(null, true)

  const { userLogged } = useAuth()

  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useFormContext<AddressFormInputs>()

  const isCreatePage = slug === "adicionar"
  const isEditPage = slug === "editar"
  const isViewPage = slug === "visualizar"
  const isRequestChange = slug === 'solicitar'

  const isCustomerOrRecipientUserType = userLogged?.user_type === "CLIENTE" || userLogged?.user_type === "REMETENTE"

  const isCollectorOrDriverUserType = userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA'


  useEffect(() => {
    if (isCreatePage && userLogged && !isCustomersLoading) setValue("customer_id", userLogged.customer_id)
  }, [isCustomerOrRecipientUserType, setValue, isCreatePage, isCustomersLoading, userLogged])

  useEffect(() => {
    if (isCreatePage && isCustomerOrRecipientUserType) {
      setValue('has_own_board_landing', 'NÃO')
    }
  }, [isCreatePage, isCustomerOrRecipientUserType, setValue])

  const personTypeSelected = watch("type")

  const isCustomerSelected = !!watch("customer_id")
  const isPersonTypeSelected = !!personTypeSelected

  const isJuridicalPerson = personTypeSelected === "PESSOA JURÍDICA"

  const stateSelected = watch("state")

  const citiesFilteredByState = cities?.filter(city => city.state === stateSelected)

  const hasBusinessOperation = watch("has_business_operation") === "SIM"
  const hasSaturdayOperation = watch("has_saturday_operation") === "SIM"
  const hasSundayOperation = watch("has_sunday_operation") === "SIM"

  return (
    <Flex direction="column" gap={6} align="center">
      <Select
        {...register("situation")}
        name="situation"
        placeholder="Selecione uma opção..."
        label="Situação"
        situations={situation}
        isDisabled={!isEditPage}
        error={errors.situation}
        required
      />

      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Select
          {...register("customer_id")}
          name="customer_id"
          label="Pertence ao cliente"
          placeholder="Selecione uma opção..."
          isDisabled={isCustomerOrRecipientUserType || isCustomersLoading || isViewPage}
          customers={customers}
          error={errors.customer_id}
          required
        />

        <Select
          {...register("type")}
          name="type"
          label="Tipo de cliente"
          placeholder="Selecione uma opção"
          error={errors.type}
          types={addressPersonTypes}
          isDisabled={isViewPage}
          required
        />
      </Stack>
      {isCustomerSelected && isPersonTypeSelected && (
        <>
          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Controller
              control={control}
              name="cnpj_cpf"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  isDisabled={isViewPage}
                  name="cnpj_cpf"
                  label={isJuridicalPerson ? 'CNPJ' : 'CPF'}
                  mask={isJuridicalPerson
                    ? handleSwitchMask('cnpj')
                    : handleSwitchMask('cpf')
                  }
                  defaultValue={value}
                  onChange={onChange}
                  error={errors.cnpj_cpf}
                  required
                />
              )}
            />

            {isJuridicalPerson && (
              <Input
                {...register("trading_name")}
                name="trading_name"
                label="Nome fantasia"
                error={errors.trading_name}
                isDisabled={isViewPage}
                required
              />
            )}

          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Input
              {...register("branch")}
              name="branch"
              isDisabled={isViewPage}
              label="Setor/Unidade"
              error={errors.branch}
              required
            />
            <Input
              {...register("responsible_name")}
              name="responsible_name"
              isDisabled={isViewPage}
              label="Nome do responsável"
              error={errors.responsible_name}
              required
            />
          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Input
              {...register('responsible_email')}
              name="responsible_email"
              label="E-mail do responsável"
              placeholder="email@exemplo.com"
              error={errors.responsible_email}
              isDisabled={isViewPage}
            />
            {!isCollectorOrDriverUserType && (
              <Controller
                control={control}
                name="responsible_telephone"
                render={({ field: { onChange, value } }) => (
                  <InputMaskCustom
                    mask={
                      value?.length > 13
                        ? handleSwitchMask('cellphone')
                        : handleSwitchMask('mixedPhone')
                    }
                    maskChar=""
                    alwaysShowMask={false}
                    defaultValue={value}
                    onChange={onChange}
                    name="responsible_telephone"
                    label="Telefone do responsável"
                    error={errors.responsible_telephone}
                    isDisabled={isViewPage}
                  />
                )}
              />
            )}
          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Controller
              control={control}
              name="cep"
              render={({ field: { onChange, value } }) => (
                <InputMaskCustom
                  mask={handleSwitchMask('cep')}
                  defaultValue={value}
                  onChange={onChange}
                  name="cep"
                  label="CEP"
                  error={errors.cep}
                  isDisabled={isViewPage || isIbgeStatesLoading}
                  required
                />
              )}
            />
            <Stack
              direction={["column", "column", "row"]}
              spacing={6}
              w="full"
            >
              <Input
                {...register('street')}
                name="street"
                label="Rua"
                error={errors.street}
                required
                isDisabled={isViewPage}
              />
              <Input
                {...register('number')}
                name="number"
                label="Número"
                error={errors.number}
                required
                isDisabled={isViewPage}
              />
            </Stack>
          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Input
              {...register("complement")}
              name="complement"
              label="Complemento"
              error={errors.complement}
              isDisabled={isViewPage}
            />
            <Input
              {...register("neighborhood")}
              name="neighborhood"
              label="Bairro"
              error={errors.neighborhood}
              isDisabled={isViewPage}
              required
            />

            <Select
              {...register("state")}
              name="state"
              label="Estado"
              error={errors.state}
              placeholder="Selecione uma opção..."
              states={ibgeStates}
              required
              isDisabled={isViewPage || isIbgeStatesLoading}
            />
            <Select
              {...register("city_id")}
              name="city_id"
              label="Cidade"
              citiesBack={citiesFilteredByState}
              placeholder="Selecione uma opção..."
              error={errors.city_id}
              required
              isDisabled
            />
          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Input
              {...register("reference_point")}
              name="reference_point"
              label="Ponto de referência"
              error={errors.reference_point}
              isDisabled={isViewPage}
            />
            <Stack
              direction={["column", "column", "row"]}
              spacing={6}
              w="full"
            >
              <Select
                {...register("icms")}
                name="icms"
                label="ICMS"
                error={errors.icms}
                placeholder="Selecione uma opção..."
                types={contribution_types}
                required
                isDisabled={isViewPage}
              />
              <Select
                {...register('deadline')}
                isDisabled={isViewPage}
                name="deadline"
                placeholder="Selecione uma opção..."
                options={deadlineOptions}
                label="Prazo máximo"
                error={errors.deadline}
                required
              />
            </Stack>
          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Select
              {...register("has_business_operation")}
              name="has_business_operation"
              label="Funcionamento de segunda a sexta?"
              placeholder="Selecione uma opção..."
              negative_positive={negative_positive}
              isDisabled={true}
              required
            />
            <Stack
              direction={["column", "column", "row"]}
              spacing={6}
              w="full"
            >
              <Input
                {...register('business_open')}
                name="business_open"
                label="Horário de abertura"
                type="time"
                error={errors.business_open}
                isDisabled={isViewPage}
                required={hasBusinessOperation}
              />
              <Input
                {...register('business_close')}
                name="business_close"
                label="Horário de fechamento"
                type="time"
                error={errors.number}
                isDisabled={isViewPage}
                required={hasBusinessOperation}
              />
            </Stack>
          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Select
              {...register("has_saturday_operation")}
              name="has_saturday_operation"
              label="Funcionamento aos sábados?"
              placeholder="Selecione uma opção..."
              error={errors.has_saturday_operation}
              negative_positive={negative_positive}
              isDisabled={isViewPage}
              required
            />
            <Stack
              direction={["column", "column", "row"]}
              spacing={6}
              w="full"
            >
              <Input
                {...register('saturday_open')}
                name="saturday_open"
                label="Horário de abertura"
                type="time"
                error={errors.saturday_open}
                isDisabled={isViewPage || !hasSaturdayOperation}
                required={hasSaturdayOperation}
              />
              <Input
                {...register('saturday_close')}
                name="saturday_close"
                label="Horário de fechamento"
                type="time"
                error={errors.saturday_close}
                isDisabled={isViewPage || !hasSaturdayOperation}
                required={hasSaturdayOperation}
              />
            </Stack>
          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing={6}
            w="full"
          >
            <Select
              {...register("has_sunday_operation")}
              name="has_sunday_operation"
              label="Funcionamento aos domingos?"
              placeholder="Selecione uma opção..."
              negative_positive={negative_positive}
              error={errors.has_sunday_operation}
              isDisabled={isViewPage}
              required
            />
            <Stack
              direction={["column", "column", "row"]}
              spacing={6}
              w="full"
            >
              <Input
                {...register('sunday_open')}
                name="sunday_open"
                label="Horário de abertura"
                type="time"
                error={errors.sunday_open}
                isDisabled={isViewPage || !hasSundayOperation}
                required={hasSundayOperation}
              />
              <Input
                {...register('sunday_close')}
                name="sunday_close"
                label="Horário de fechamento"
                type="time"
                error={errors.sunday_close}
                isDisabled={isViewPage || !hasSundayOperation}
                required={hasSundayOperation}
              />
            </Stack>
          </Stack>

          {!isCustomerOrRecipientUserType && (
            <Select
              {...register("has_own_board_landing")}
              name="has_own_board_landing"
              label="Possui embarque/desembarque próprio?"
              placeholder="Selecione uma opção..."
              negative_positive={negative_positive}
              error={errors.has_own_board_landing}
              isDisabled={isViewPage}
              required
            />
          )}

          <TextArea
            {...register("observation")}
            name="observation"
            label="Observações"
            isDisabled={isViewPage}
          />
        </>
      )}

    </Flex >
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getCarriersReport } from "api/carriers/getCarriersReport";
import { CarrierStatus } from "api/carriers/_types/Carrier";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { carrierStatusMap } from "utils/carrierMappers";
import { CarriersReportTableFilters } from "./components/CarriersReportTableFilters";
import { CarriersReportTableRow } from "./components/CarriersReportTableRow";
import { CreateCarrier } from "./components/CreateCarrier";

const headers = [
  { label: "Nome", key: "name" },
  { label: "CPF", key: "cpf" },
  { label: "E-mail", key: "email" },
  { label: "Parceiro Loglife", key: "collector.trading_name" },
  { label: "Cartão de Vacina - dose DT (difteria e tétano)", key: "dtVaccineDoseAttachment.link" },
  { label: "Cartão de Vacina - dose Hepatite B", key: "hepatiteBVaccineDoseAttachment.link" },
  { label: "Cartão de Vacina - dose Tríplice viral", key: "tripliceViralVaccineDoseAttachment.link" },
  { label: "CNH atual", key: "cnhAttachment.link" },
  { label: "CRLV atual", key: "crlvAttachment.link" },
  { label: "Curso de motofrete/ANTT", key: "anttMotorFreightAttachment.link" },
  { label: "Foto da lateral da moto/carro", key: "vehicleSidePhotoAttachment.link" },
  { label: "Foto da traseira da moto/carro", key: "vehicleRearPhotoAttachment.link" },
  { label: "Foto interna do baú/carroceria", key: "vehicleInternPhotoAttachment.link" },
  { label: "Pendências", key: "pendencies" },
  { label: "Cadastro Universidade Loglife", key: "has_loglife_university_register" },
  { label: "Cadastro de treinamento", key: "has_training_register" },
  { label: "Cadastro de vacinas", key: "has_vaccines_register" },
  { label: "Cadastro de ASO", key: "has_aso_register" },
  { label: "Observações", key: "observations" },
  { label: "Status", key: "status" },
]

export function CarriersReport() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewCarriersReport = userLogged?.permissions?.includes(
    'view-carriers-report'
  )

  useEffect(() => {
    if (!userCanViewCarriersReport) history.push('/')
  }, [history, userCanViewCarriersReport])

  const status = searchParams.get('status') ?? 'all'

  const {
    data: carriersResult
  } = useQuery({
    queryKey: ['carriers-report', status],
    queryFn: () => getCarriersReport({
      status: status as CarrierStatus | 'all',
    })
  })

  const csvProps = {
    data: carriersResult ? carriersResult?.carriers.map(carrier => {
      return {
        ...carrier,
        status: carrierStatusMap[carrier.status],
        has_loglife_university_register: carrier.has_loglife_university_register !== null ? carrier.has_loglife_university_register ? 'Sim' : 'Não' : '',
        has_training_register: carrier.has_training_register !== null ? carrier.has_training_register ? 'Sim' : 'Não' : '',
        has_vaccines_register: carrier.has_vaccines_register !== null ? carrier.has_vaccines_register ? 'Sim' : 'Não' : '',
        has_aso_register: carrier.has_aso_register !== null ? carrier.has_aso_register ? 'Sim' : 'Não' : '',
      }
    })
      : [],
    headers,
    filename: `relatório-portadores-${new Date().toISOString()}.csv`,
  }

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: carriersResult?.meta?.totalPages,
    initialState: {
      pageSize: 10,
      currentPage: 1,
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateCarrierOpen,
    onOpen: onOpenCreateCarrier,
    onClose: onCloseCreateCarrier
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Relatório de Portadores</Heading>
        {/* {userCanCreateCarrierRequest && ( */}
        <IconButton
          aria-label="Criar portador"
          icon={<FaPlus />}
          colorScheme="blue"
          size="sm"
          onClick={onOpenCreateCarrier}
        />
        {/* )} */}

        <Modal
          isOpen={isCreateCarrierOpen}
          onClose={onCloseCreateCarrier}
          isCentered
          size="2xl"
        >
          <ModalOverlay />
          <CreateCarrier onClose={onCloseCreateCarrier} />
        </Modal>
      </Flex>

      <CarriersReportTableFilters csvProps={csvProps} />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th color="white">Nome</Th>
              <Th color="white">Parceiro Loglife</Th>
              <Th color="white">Cpf</Th>
              <Th color="white">Email</Th>
              <Th color="white">Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {carriersResult?.carriers?.map((carrier) => {
              return (
                <CarriersReportTableRow
                  key={carrier.id}
                  carrier={carrier}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

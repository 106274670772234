import { ButtonGroup, EditableInput, EditablePreview, Flex, IconButton, Input, InputProps, useEditableControls } from "@chakra-ui/react";
import { forwardRef, useEffect } from "react";
import { FaCheck, FaPen, FaTimes } from "react-icons/fa";


interface RefundDateEditableInputProps extends InputProps {
  onEditChange?: (isEditing: boolean) => void
}

export const RefundDateEditableInput = forwardRef<HTMLInputElement, RefundDateEditableInputProps>(({ onEditChange, ...rest }, ref) => {

  const {
    getCancelButtonProps,
    getEditButtonProps,
    getSubmitButtonProps,
    isEditing
  } = useEditableControls()

  useEffect(() => {
    if (onEditChange) {
      onEditChange(isEditing)
    }
  }, [isEditing, onEditChange])

  return (
    <Flex
      gap={2}
      align='center'
      justify='end'
    >
      {isEditing ? (
        <ButtonGroup>
          <IconButton
            aria-label=""
            icon={<FaTimes />}
            size='sm'
            {...getCancelButtonProps()}

          />
          <IconButton
            aria-label=""
            icon={<FaCheck />}
            size='sm'
            {...getSubmitButtonProps()}
            colorScheme='blue'
          />
        </ButtonGroup>
      ) : (
        <IconButton
          aria-label=""
          icon={<FaPen />}
          size='sm'
          {...getEditButtonProps()}
        />
      )}
      <EditablePreview />
      <Input
        ref={ref}
        as={EditableInput}
        {...rest}
      />
    </Flex>
  )
})

RefundDateEditableInput.displayName = 'RefundDateEditableInput'

import { Button, Flex, Input } from "@chakra-ui/react";
import { addDays, format } from "date-fns";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm, useWatch } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface ShippingsCostsReportFiltersSchema {
  startDate: string
  finalDate: string
}

interface ShippingsCostsReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: {
      shipping: string
      tracker: string
      realBoardDate: string
      desviation: number
      route: string
      totalTaxedWeight: number
      totalCteTransferCostInCents: number
      expectedCostInCents: number

    }[]
    filename: string
  }
  isLoading: boolean
}

export function ShippingsCostsReportFilters({
  csvProps,
  isLoading
}: ShippingsCostsReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()


  const startDate = searchParams.get('startDate')
  const finalDate = searchParams.get('finalDate')

  const {
    control,
    register,
    handleSubmit,
  } = useForm<ShippingsCostsReportFiltersSchema>({
    defaultValues: {
      startDate,
      finalDate,

    }
  })

  function handleSearchShippingsCostsReport(values: ShippingsCostsReportFiltersSchema) {
    if (values.startDate) {
      searchParams.set('startDate', values.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (values.finalDate) {
      searchParams.set('finalDate', values.finalDate)
    } else {
      searchParams.delete('finalDate')
    }


    replace({ search: searchParams.toString() })

  }

  const startDateSelected = useWatch({ control, name: 'startDate' })

  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchShippingsCostsReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Input
          {...register("startDate")}
          type="date"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Input
          {...register("finalDate")}
          type="date"
          size="sm"
          rounded="md"
          max={format(addDays(new Date(startDateSelected), 31), 'yyyy-MM-dd')}
          w={{ base: 'full', md: 'fit-content' }}
        />


      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
          isLoading={isLoading}
        >
          Gerar relatório
        </Button>
      </Flex>
    </Flex>
  )
}

import { Box, FormControl, FormLabel, Select, Text } from "@chakra-ui/react";
import { useFormContext, useWatch } from "react-hook-form";
import { CreateAccountPayable } from "./AccountsPayable/CreateAccountPayable";
import { CreateAccountReceivable } from "./AccountsReceivable/CreateAccountReceivable";
import { CreateSupportSchema } from "./CreateSupport";
import { CreateHumanResourcesSupport } from "./HumanResources/CreateHumanResourcesSupport";
import { CreateJuridicalSupport } from "./Juridical/CreateJuridicalSupport";
import { CreatePurchaseAndWarehouse } from "./PurchasesAndWarehouse/CreatePurchaseAndWarehouse";
import { CreateQualitySupport } from "./Quality/CreateQualitySupport";
import { CreateSupportTI } from "./TI/CreateSupportTI";

const categoryOptions = [
  'Compras e Almoxarifado',
  'Contas a Pagar',
  'Contas a Receber',
  'TI',
  'Gente',
  'Jurídico',
  'Qualidade'
]

export interface AttachmentState {
  attachmentId: string
  link: string
  title: string
}

interface SetAttachmentActionPayload {
  attachmentId: string
  link: string
  title: string
}

export interface SetAttachmentAction {
  type: 'ADD' | 'DELETE'
  payload: SetAttachmentActionPayload
}

function reducer(state: AttachmentState[], action: SetAttachmentAction) {
  switch (action.type) {
    case 'ADD':
      return [...state, {
        attachmentId: action.payload.attachmentId,
        link: action.payload.link,
        title: action.payload.title
      }]

    case 'DELETE':
      return state.filter(attachment => attachment.attachmentId !== action.payload.attachmentId)

    default:
      return state
  }
}


export function InternalSupportForm() {

  const { register, control, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const [category] = useWatch({
    control,
    name: ['category']
  })

  const categoryIsPurcharsesAndWarehouse = category === 'Compras e Almoxarifado'
  const categoryIsAccountsPayable = category === 'Contas a Pagar'
  const categoryIsAccountsReceivable = category === 'Contas a Receber'
  const categoryIsTI = category === 'TI'
  const categoryIsHumanResources = category === 'Gente'
  const categoryIsJuridical = category === 'Jurídico'
  const categoryIsQuality = category === 'Qualidade'

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.category} mt={3}>
        <FormLabel fontSize="sm">
          Categoria
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Select
          {...register('category')}
          name="category"
          placeholder="Selecione..."
          size="sm"
          rounded="md"
        >
          {Object.entries(categoryOptions).map(([key, value]) => (
            <option key={key} value={value}>{value}</option>
          ))}
        </Select>

      </FormControl>

      {categoryIsPurcharsesAndWarehouse && (
        <CreatePurchaseAndWarehouse
          reducer={reducer}
        />
      )}

      {categoryIsAccountsPayable && (
        <CreateAccountPayable
          reducer={reducer}
        />
      )}

      {categoryIsAccountsReceivable && (
        <CreateAccountReceivable />
      )}
      {categoryIsTI && (
        <CreateSupportTI
          reducer={reducer}
        />
      )}

      {categoryIsHumanResources && (
        <CreateHumanResourcesSupport
          reducer={reducer}
        />
      )}

      {categoryIsJuridical && (
        <CreateJuridicalSupport
          reducer={reducer}
        />
      )}

      {categoryIsQuality && (
        <CreateQualitySupport />
      )}
    </Box>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getCustomers } from "api/customers/getCustomers";
import { getCustomersReport } from "api/customers/getCustomersReport";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { FaFileExcel, FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { CustomersTableFilters } from "./components/CustomersTableFilters";
import { CustomerTableRow } from "./components/CustomerTableRow";

const customerReportHeaders = [
  { label: 'TIPO', key: 'type' },
  { label: 'SITUAÇÃO', key: 'situation' },
  { label: 'NOME FANTASIA', key: 'trading_firstname' },
  { label: 'RAZÃO SOCIAL', key: 'company_lastname' },
  { label: 'CPF/CNPJ', key: 'cnpj_cpf' },
  { label: 'CENTRO DE CUSTO', key: 'cost_center' },
  { label: 'HORÁRIO LIMITE DE ENTREGA', key: 'deadline_delivery' },
  { label: 'EMAIL OPERACIONAL', key: 'operational_email' },
  { label: 'EMAIL FINANCEIRO', key: 'financial_email' },
  { label: 'CELULAR', key: 'cellphone' },
  { label: 'TELEFONE', key: 'telephone' },
  { label: 'CEP', key: 'cep' },
  { label: 'ESTADO', key: 'state' },
  { label: 'CIDADE', key: 'city' },
  { label: 'RUA', key: 'street' },
  { label: 'NÚMERO', key: 'number' },
  { label: 'BAIRRO', key: 'neighborhood' },
  { label: 'COMPLEMENTO', key: 'complement' },
  { label: 'REGISTRO MUNICIPAL', key: 'minicipal_register' },
  { label: 'REGISTRO ESTADUAL', key: 'state_register' },
  { label: 'ICMS', key: 'icms' },
  { label: 'ISS', key: 'iss' },
  { label: 'CONDIÇÃO DE PAGAMENTO', key: 'payment_condition' },
  { label: '1º DIA DE VENCIMENTO', key: 'day_expiration_1' },
  { label: '2º DIA DE VENCIMENTO', key: 'day_expiration_2' },
  { label: 'TIPO DE PAGAMENTO', key: 'payment_type' },
  { label: 'TIPO DE EMISSÃO', key: 'emission_type' },
  { label: 'POSSUI TEMPO DE ESPERA', key: 'has_waiting_time' },
  { label: 'RESPONSÁVEL PELO ATENDIMENTO', key: 'attendance_responsible' },
  { label: 'ANEXOS', key: 'attachments' },
  { label: 'OBSERVAÇÕES', key: 'observation' },
]

export function Customers() {
  const [isCustomersReportQueryEnabled, setIsCustomersReportQueryEnabled] = useState(false)
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanCreateCustomer = userLogged?.permissions?.includes('add-customer')

  const page = searchParams.get('page') ?? '1'
  const search = searchParams.get('search')

  const {
    data: customersResult,
    isLoading: isCustomersResultLoading
  } = useQuery({
    queryKey: ['customers', page, search],
    queryFn: () => getCustomers({
      currentPage: Number(page),
      pageSize: 10,
      search
    })
  })

  const {
    data: customersReportResult,
    isLoading: isCustomersReportResultLoading
  } = useQuery({
    queryKey: ['customers-report'],
    queryFn: getCustomersReport,
    enabled: isCustomersReportQueryEnabled
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: customersResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: customersResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const csvLinkRef = useRef<any | null>(null);

  function handleExportCustomers() {
    setIsCustomersReportQueryEnabled(true)
  }

  useEffect(() => {
    if (isCustomersReportQueryEnabled && customersReportResult && csvLinkRef?.current) {
      csvLinkRef?.current?.link?.click()
      setIsCustomersReportQueryEnabled(false)
    }
  }, [customersReportResult, csvLinkRef, isCustomersReportQueryEnabled])

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Clientes</Heading>

        <Flex
          align="center"
          gap={2}
        >
          {userCanCreateCustomer && (
            <IconButton
              aria-label="Criar cliente"
              as={Link}
              to="/cliente/adicionar"
              icon={<FaPlus />}
              colorScheme="blue"
            />
          )}

          <Button
            colorScheme="green"
            rightIcon={<FaFileExcel />}
            onClick={handleExportCustomers}
            isLoading={isCustomersReportResultLoading}
          >
            Exportar
          </Button>

          <CSVLink
            data={
              customersReportResult
                ? customersReportResult?.customers.map((customer) => {
                  return {
                    ...customer,
                    deadline_delivery: format(new Date(customer.deadline_delivery), 'HH:mm'),
                    has_waiting_time: customer.has_waiting_time ? 'Sim' : 'Não',
                    attendance_responsible: customer?.attendanceResponsibleIDCustomer
                      ? `${customer?.attendanceResponsibleIDCustomer?.firstname} ${customer?.attendanceResponsibleIDCustomer?.lastname}`
                      : '',
                    attachments: customer?.attachments?.length === 0 ? '-' : customer?.attachments?.map(attachcment => {
                      return `${handleChangeUrl(attachcment)} \n`
                    }).join('\n')
                  }
                })
                : []
            }
            headers={customerReportHeaders}
            ref={csvLinkRef as any}
            filename="relatorio-clientes.csv"
            separator={';'}
            style={{ display: 'none' }}
          />
        </Flex>
      </Flex>

      <CustomersTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Cliente</Th>
              <Th color="white">Centro de custo</Th>
              <Th color="white">Responsável pelo atendimento</Th>
              <Th color="white">Tipo de pessoa</Th>
              <Th color="white">Situação</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isCustomersResultLoading ? (
              Array.from({ length: 10 }).map((_, index) => {
                return (
                  <Tr key={index.toString()}>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                    <Td>
                      <Skeleton w="full" h="20px" />
                    </Td>
                  </Tr>
                )
              })
            ) : (
              customersResult?.customers.map((customer) => {
                return (
                  <CustomerTableRow
                    key={customer.id}
                    customer={customer}
                  />
                )
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>
  )
}

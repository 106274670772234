import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { dismissalFinancialInternClt } from "api/internClts/dismissalFinancialInternClt";
import { set } from "date-fns";


interface DismissalFinancialInternCltProps {
  internCltId: string
}

export interface DismissalFinancialInternCltSchema {
  hasCompletedContractTerminationTerm: string
  hasRemovedFromOccupationalMedicine: string
  hasRemovedFromHealthcare: string
  hasRemovedFromDentalPlan: string
  hasRemovedFromPharmacyAgreement: string
  dismissalRealDate: string
}

const dismissalFinancialInternCltSchema = yup.object({
  hasCompletedContractTerminationTerm: yup.string().required(),
  hasRemovedFromOccupationalMedicine: yup.string().required(),
  hasRemovedFromHealthcare: yup.string().required(),
  hasRemovedFromDentalPlan: yup.string().required(),
  hasRemovedFromPharmacyAgreement: yup.string().required(),
  dismissalRealDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  })
})


export function DismissalFinancialInternClt({ internCltId }: DismissalFinancialInternCltProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    register,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<DismissalFinancialInternCltSchema>({
    resolver: yupResolver(dismissalFinancialInternCltSchema)
  })

  const { mutateAsync: dismissalFinancialInternCltFn } = useMutation({
    mutationFn: dismissalFinancialInternClt,
    onSuccess(_data, { body, internCltId }) {
      queryClient.invalidateQueries({ queryKey: 'intern-clts' })
      queryClient.invalidateQueries({ queryKey: ['intern-clt', internCltId] })
    }
  })

  async function handleDismissalFinancialInternClt(values: DismissalFinancialInternCltSchema) {
    await promiseMessage(dismissalFinancialInternCltFn({
      body: {
        ...values,
        hasCompletedContractTerminationTerm: values.hasCompletedContractTerminationTerm === 'yes'
      },
      internCltId
    }), 'Financeiro enviado!')
  }


  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleDismissalFinancialInternClt)}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão financeiro</Heading>
      <FormControl isInvalid={!!errors.hasCompletedContractTerminationTerm} mt={3}>
        <FormLabel>
          Termo de rescisão de contrato preenchido ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasCompletedContractTerminationTerm"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRemovedFromOccupationalMedicine} mt={3}>
        <FormLabel>
          Excluiu o funcionário da plataforma da Medicina Ocupacional?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRemovedFromOccupationalMedicine"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRemovedFromHealthcare} mt={3}>
        <FormLabel>
          Exclusão Plano de saúde ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRemovedFromHealthcare"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRemovedFromDentalPlan} mt={3}>
        <FormLabel>
          Exclusão plano odonto ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRemovedFromDentalPlan"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.hasRemovedFromPharmacyAgreement} mt={3}>
        <FormLabel>
          Exclusão convenio farmácia ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasRemovedFromPharmacyAgreement"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="Sim">Sim</Radio>
                <Radio size='sm' rounded='md' value="Não">Não</Radio>
                <Radio size='sm' rounded='md' value="N/A">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors?.dismissalRealDate}>
        <FormLabel fontSize="sm">
          Data real do desligamento
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('dismissalRealDate')}
          name="dismissalRealDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

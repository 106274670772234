import { Box, Button, Flex, IconButton, Stack, Text, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { FaTimes } from 'react-icons/fa'
import * as yup from 'yup'
import { negative_positive } from '../../../utils/customLists'
import { RncAnswerAccordion } from '../../Accordions/Rnc/RncAnswerAccordion'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { StandardBackgroundForm } from '../../Form/StandardBackgroundForm'
import { Select } from '../../Inputs/SelectInput'
import { TextArea } from '../../Inputs/TextInput'

interface IFormInputProps {
  proceed: string
  cause: string | null
  corrective: string | null
  preventive: string | null
  why_not_proceed: string | null
  reasons_why: {
    content: string
  }[]
}

interface IRncFormProps {
  title: string
  rncID: string
  submit: (values: IFormInputProps) => Promise<void>
}

const schema = yup.object().shape({
  proceed: yup.string().required('Campo Obrigatório'),
  reasons_why: yup.mixed().when('proceed', {
    is: 'SIM',
    then: yup.array().min(1).of(yup.object({
      content: yup.string().required()
    }))
  }),
  cause: yup.string().when('proceed', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),
  corrective: yup.string().when('proceed', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),
  preventive: yup.string().when('proceed', {
    is: 'SIM',
    then: yup.string().required('Campo Obrigatório'),
  }),
  why_not_proceed: yup.string().when('proceed', {
    is: 'NÃO',
    then: yup.string().required('Campo Obrigatório'),
  }),
})

export function AnswerRncForm({ title, submit, rncID }: IRncFormProps) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
    defaultValues:
    {
      reasons_why: [{ content: '' }]
    }

  })

  const proceedValue = watch('proceed')

  const {
    fields: reasonsWhyFields,
    append: appendReasonWhyField,
    remove: removeReasonWhyField,
  } = useFieldArray({
    control,
    name: 'reasons_why'
  })

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(submit)} title={title} >
      {rncID && <RncAnswerAccordion rncID={rncID} />}

      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']} >
        <Select
          {...register('proceed')}
          negative_positive={negative_positive}
          placeholder="Selecione uma opção..."
          name="proceed"
          label="Procede?"
          error={errors.proceed}
          required
        />
      </Stack >

      {proceedValue === 'SIM' && (
        <>
          <VStack
            spacing={3}
            w="full"
            align="start"
            mt={3}
          >
            <Text as="b">Porquês</Text>

            {reasonsWhyFields.map((field, index) => {
              return (
                <Box
                  position="relative"
                  w="full"
                  key={field.id}
                >
                  <TextArea
                    {...register(`reasons_why.${index}.content`)}
                    name={`reasons_why.${index}.content`}
                    error={errors?.why_not_proceed ? errors?.why_not_proceed[index].content : undefined}
                    required
                  />

                  <IconButton
                    aria-label=""
                    icon={<FaTimes />}
                    size="sm"
                    rounded="full"
                    position="absolute"
                    top={-2}
                    right={-2}
                    bg="white"
                    onClick={() => removeReasonWhyField(index)}
                  />
                </Box>
              )
            })}

            {reasonsWhyFields.length < 5 && (
              <Button
                alignSelf="end"
                colorScheme="blue"
                size="sm"
                onClick={() => appendReasonWhyField({ content: '' })}
              >
                Novo porquê
              </Button>
            )}
          </VStack>

          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <TextArea
              {...register('cause')}
              name="cause"
              label="Causa Raiz"
              error={errors.cause}
              required
            />
          </Stack>
          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <TextArea
              {...register('corrective')}
              name="corrective"
              label="Ação Corretiva"
              error={errors.corrective}
              required
            />
          </Stack>
          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <TextArea
              {...register('preventive')}
              name="preventive"
              label="Ação Preventiva"
              error={errors.cause}
              required
            />
          </Stack>
        </>
      )}

      {
        proceedValue === 'NÃO' && (
          <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
            <TextArea
              {...register('why_not_proceed')}
              name="why_not_proceed"
              label="Por que não procede?"
              error={errors.why_not_proceed}
              required
            />
          </Stack>
        )
      }

      <Flex align="center" justify="flex-end" mt="4">
        <Stack direction="row">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          <ListButton href="/rnc/pendentes-resposta" name="Lista de Rnc Pendentes" />
        </Stack>
      </Flex>
    </StandardBackgroundForm >
  )
}

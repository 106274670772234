import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Stack,
  Text,
  Icon,
  Button,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useContext, useState, useMemo } from "react";
import { useHistory, useParams, useLocation } from "react-router";

import { Input } from "../../../components/Inputs/Input";
import { RequestedServiceContext } from "../../../contexts/ServiceContext";
import { useService } from "../../../hooks/services/service";

import { ListButton } from "../../../components/Buttons/ListButton";
import { CancelButton } from "../../../components/Buttons/CancelButton";
import { useAuth } from "../../../hooks/auth/useAuth";


import { GeneralContentLoading } from "../../../components/Loading/GeneralContentLoading";
import { FormActionButton } from "../../../components/Buttons/FormActionButton";
import { useSwal } from "../../../hooks/swal/useSwal";
import { FaFilePdf, FaTruckLoading } from "react-icons/fa";
import { GenerateLabelModal } from "../../../components/Modal/Service/Label/GenerateLabelModal";
import { useCollector } from "../../../hooks/collector/useCollector";
import { useDriver } from "../../../hooks/driver/useDriver";
import { FormProvider, useForm } from "react-hook-form";
import { AssignDriverToAddressForm } from "./components/AssignDriverToAddressForm";
import { useQueryClient } from "react-query";
import { ProviderProps } from "../../../contexts/ProviderContext";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCollectServiceFunctions } from "../../../hooks/collectService/useCollectServiceFunctions";
import { useAddresses } from "../../../hooks/address/useAddresses";
import { Address } from "hooks/address/dtos/Address";
import { useRequestServiceFunctions } from "hooks/services/request/useRequestServiceFunctions";
import { useProviders } from "hooks/provider/useProviders";
import { useServiceSocket } from "hooks/socket/useServiceSocket";
import { CancelServiceModal } from "components/Modal/Service/CancelServiceModal";
import { ExternalComunications } from "components/ExternalComunications";
//import { useExternalComunications } from "hooks/externalComunications/useExternalComunications";

interface IQueryParams {
  service_id: string;
  address_id: string;
}

interface ICollectServiceAddress {
  slug: string;
  isDisabled?: boolean;
}

interface IAssignDriverFormProps {
  driver_address_assign: Array<{
    driver_id: string;
    address_id: string;
  }>;
  driver_provider_assign: Array<{
    driver_id: string;
    provider_id: string;
  }>;
}

const schema = yup.object().shape({
  driver_address_assign: yup.array().of(
    yup.object({
      driver_id: yup.string().required("Campo Obrigatório"),
      address_id: yup.string().required("Campo Obrigatório"),
    })
  ),
  driver_provider_assign: yup.array().of(
    yup.object({
      driver_id: yup.string().required("Campo Obrigatório"),
      provider_id: yup.string().required("Campo Obrigatório"),
    })
  ),
});

export function CollectServiceAddress({
  slug,
  isDisabled = false,
}: ICollectServiceAddress) {
  const [isWideVersion] = useMediaQuery("(min-width: 1280px)");
  const [isLoading, setIsLoading] = useState(false);
  const [hasEditAssignedDrivers, setHasEditAssignedDrivers] = useState(false);

  const {
    assignDriverToAddress,
    assignDriverToProvider,
  } = useContext(RequestedServiceContext);

  const {
    cancelCollectServiceAddress: {
      mutateAsync: cancelCollectServiceAddress,
      isLoading: isCancelCollectAddressLoading,
    },
  } = useCollectServiceFunctions();

  const { serviceSocketConnection } = useServiceSocket()

  const {
    cancelAddressToCollectService: {
      mutateAsync: cancelAddressToCollectService,
    },
  } = useRequestServiceFunctions();

  const { userLogged } = useAuth();
  const { push: redirectTo } = useHistory();
  const location = useLocation()
  const state = location.state as { path?: string }

  const { service_id }: IQueryParams = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { confirmMessage, standardMessage } = useSwal();

  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(service_id, false, false);
  const { data: providers, isLoading: isProviderLoading } = useProviders();
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses();
  const { data: collector, isLoading: isCollectorLoading } = useCollector(
    service?.serviceIDRequested?.source_collector_id!
  );
  const {
    drivers: { data: drivers, isLoading: isDriversLoading },
  } = useDriver(null, true, false);

  useEffect(() => {
    if (!userLogged?.permissions?.includes("add-collect-service")) {
      redirectTo("/");
    }
  }, [redirectTo, userLogged]);

  const queryClient = useQueryClient();
  const formMethods = useForm<IAssignDriverFormProps>({
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitSuccessful },
  } = formMethods;

  const {
    isOpen: isCancelServiceModalOpen,
    onOpen: onOpenCancelServiceModal,
    onClose: onCloseCancelServiceModal,
  } = useDisclosure()

  useEffect(() => {
    if (isSubmitSuccessful) {
      queryClient.invalidateQueries(["service"]);
    }
  }, [queryClient, isSubmitSuccessful]);

  const requestedSourceAddresses = useMemo(() => {
    return service?.serviceIDRequested.source_address_id?.filter(address => {
      return !service.serviceIDRequested.addresses_requested_to_cancel.includes(address)
    });
  }, [service])

  const modalSourceAddresses =
    addresses?.filter((address) =>
      service?.serviceIDRequested.source_address_id.includes(address.id)
    ) ?? [];

  const modalDestinationAddresses =
    addresses?.filter((address) =>
      service?.serviceIDRequested.destination_address_id.includes(address.id)
    ) ?? [];

  const stepsOfCollectedServices =
    service?.serviceIDCollect.map((colService) => colService.step) ?? null;

  const providerId = service?.serviceIDRequested.provider_id;
  const hasProviderOnService = service?.serviceIDRequested.provider_id !== null;

  const providerCollectStep = hasProviderOnService
    ? service?.serviceIDCollect.find(
      (collect) => collect.provider_id === providerId
    )?.step
    : null;

  const isProviderCollectFinished =
    providerCollectStep === "DONE" ||
    providerCollectStep === "UNSUCCESS" ||
    providerCollectStep === "VALIDATEUNSUCCESS";

  const [sourceProviderFiltered, setSourceProviderFiltered] = useState<
    ProviderProps[]
  >([]);

  useEffect(() => {
    if (providers && hasProviderOnService && !isProviderCollectFinished) {
      const providerFiltered = providers?.filter(
        (provider) => provider.id === providerId
      );

      setSourceProviderFiltered(providerFiltered);
    }
  }, [hasProviderOnService, isProviderCollectFinished, providers, providerId]);

  const allCollectAddresses = service?.serviceIDCollect.map(
    (collect) => collect.address_id
  );

  const collectAddressesInProggress =
    service?.serviceIDCollect
      .filter((collect) => collect.step === "GOING")
      .filter((collect) => collect.address_id !== null)
      .map((collect) => collect.address_id) ?? [];

  const addressesFilteredByInProggress =
    addresses?.filter((address) =>
      collectAddressesInProggress.includes(address.id)
    ) ?? [];

  const noStartedAddresses =
    addresses
      ?.filter((address) => requestedSourceAddresses?.includes(address.id))
      .filter((address) => !allCollectAddresses?.includes(address.id)) ?? [];

  const sourceAddressesFiltered = !stepsOfCollectedServices
    ? addresses?.filter((address) =>
      requestedSourceAddresses?.includes(address.id)
    ) ?? []
    : [...noStartedAddresses, ...addressesFilteredByInProggress] ?? [];

  const serviceStep = service?.step;
  const providerAddressCount = service?.serviceIDRequested.provider_id ? 1 : 0;
  const requestedSourceAddressesCount = requestedSourceAddresses?.length ?? 0;
  const providerAndSourceAddressCount =
    providerAddressCount + requestedSourceAddressesCount;

  useEffect(() => {
    if (serviceStep) {
      if (
        serviceStep !== "toCollectService" &&
        serviceStep !== "collectingService"
      ) {
        setIsLoading(true);
        redirectTo("/servicos/coletas");
        return;
      }
    }
    if (stepsOfCollectedServices && providerAndSourceAddressCount) {
      if (
        !stepsOfCollectedServices.includes("GOING") &&
        stepsOfCollectedServices.length === providerAndSourceAddressCount
      ) {
        setIsLoading(true);
        redirectTo("/servicos/coletas");
      }
    }
  }, [
    serviceStep,
    providerAddressCount,
    stepsOfCollectedServices,
    providerAndSourceAddressCount,
    service,
    redirectTo,
  ]);

  function handleEditAssignedDrivers() {
    setHasEditAssignedDrivers(true);
  }

  const collectorHasDriverAssignment = collector?.has_driver_assign;
  const driversFilteredBySourceCollector = drivers?.filter(
    (driver) =>
      driver.collector_id === service?.serviceIDRequested.source_collector_id
  );

  async function handleAssignDriverToAddress(data: IAssignDriverFormProps) {
    const hasAssignDriverToAddress = await confirmMessage({
      title: "Deseja confirmar as atribuições de motoristas?",
    });

    if (hasAssignDriverToAddress) {
      await assignDriverToAddress(service_id, data.driver_address_assign);

      if (data.driver_provider_assign && data.driver_provider_assign.length) {
        await assignDriverToProvider(service_id, data.driver_provider_assign);
      }

      await queryClient.invalidateQueries("service");

      if (hasEditAssignedDrivers) {
        setHasEditAssignedDrivers(false);
      }
    } else {
      standardMessage("Ação cancelada com êxito!");
    }
  }

  const requestedServiceHasDriverAndAddressAssigned =
    service?.serviceIDRequested.driver_address_assign !== null;

  const requestedServiceHasDriverAndProviderAssigned = hasProviderOnService
    ? service?.serviceIDRequested.driver_provider_assign !== null
    : true;

  const [requestedSourceProvider, setRequestedSourceProvider] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (sourceProviderFiltered?.length) {
      const provider = sourceProviderFiltered.map((provider) => provider.id);

      setRequestedSourceProvider(provider);
    }
  }, [sourceProviderFiltered]);


  const isUserTypeDriver = userLogged?.user_type === "MOTORISTA";

  const {
    isOpen: isExternalComunicationModalOpen,
    onOpen: onOpenExternalComunicationModal,
    onClose: onCloseExternalComunicationModal,
  } = useDisclosure()

  if (
    isLoading ||
    isProviderLoading ||
    isAddressesLoading ||
    isServiceLoading ||
    isCollectorLoading ||
    isDriversLoading ||
    isCancelCollectAddressLoading
  ) {
    return <GeneralContentLoading />;
  }

  if (
    collectorHasDriverAssignment === true &&
    (!requestedServiceHasDriverAndAddressAssigned ||
      !requestedServiceHasDriverAndProviderAssigned)
  ) {
    return (
      <>
        {isUserTypeDriver ? (

          <Box w="full" flex="1" borderRadius="8px" bg="white" p={["6", "8"]}>
            <Flex justify="center" align="center">
              <Flex
                maxW="400px"
                justify="center"
                direction="column"
                align="center"
              >
                <Icon as={FaTruckLoading} w={20} h={20} opacity={0.8} />
                <Text align="center" fontWeight="bold">
                  Oops... Nada por aqui!
                </Text>
                <Text align="center">
                  O seu coletador ainda está atribuindo endereços aos
                  motoristas!
                </Text>
                <Link to="/servicos/coletas" style={{ width: "100%" }}>
                  <Button colorScheme="facebook" w="full">
                    Retornar para coletas
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Box>

        ) : (
          <FormProvider {...formMethods}>
            <AssignDriverToAddressForm
              requestedSourceAddresses={requestedSourceAddresses}
              requestedSourceProvider={requestedSourceProvider}
              serviceId={service_id}
              serviceProtocol={service.protocol}
              providers={providers}
              addresses={addresses}
              hasEditAssignedDrivers={hasEditAssignedDrivers}
              driversFilteredBySourceCollector={
                driversFilteredBySourceCollector
              }
              onAssignDriverToAddress={handleAssignDriverToAddress}
            />
          </FormProvider>
        )}
      </>
    );
  }

  const requestedSourceAddressesAssignedToDriver = service?.serviceIDRequested
    .driver_address_assign
    ? service?.serviceIDRequested.driver_address_assign
      .map((value) => JSON.parse(value as string))
      .filter((value) => value.driver_id === userLogged?.driver_id)
    : null;

  const requestedSourceProviderAssignedToDriver = service?.serviceIDRequested
    .driver_provider_assign
    ? service?.serviceIDRequested.driver_provider_assign
      .map((value) => JSON.parse(value as string))
      .filter((value) => value.driver_id === userLogged?.driver_id)
    : null;

  const sourceAddressFilteredByUserType = isUserTypeDriver
    ? collectorHasDriverAssignment === true
      ? requestedSourceAddressesAssignedToDriver !== null
        ? sourceAddressesFiltered.reduce((acc, curr) => {
          requestedSourceAddressesAssignedToDriver.map((value) => {
            if (value.address_id === curr.id) {
              return acc.push(curr);
            }

            return [];
          });

          return acc;
        }, [] as Address[])
        : []
      : sourceAddressesFiltered
    : sourceAddressesFiltered;

  const sourceProviderFilteredByUserType = isUserTypeDriver
    ? collectorHasDriverAssignment === true
      ? sourceProviderFiltered.length
        ? requestedSourceProviderAssignedToDriver
          ? sourceProviderFiltered.reduce((acc, curr) => {
            requestedSourceProviderAssignedToDriver.map((value) => {
              if (value.provider_id === curr.id) {
                return acc.push(curr);
              }

              return [];
            });

            return acc;
          }, [] as ProviderProps[])
          : []
        : []
      : sourceProviderFiltered
    : sourceProviderFiltered;

  const driverAssignedToAddresses =
    service?.serviceIDRequested?.driver_address_assign;
  const driverAssignedToProviders =
    service?.serviceIDRequested?.driver_provider_assign;
  const sourceAddressesPendingStart = noStartedAddresses.length
    ? noStartedAddresses.map((address) => address.id)
    : null;

  const isProviderCollectStarted =
    providerCollectStep === "GOING" ||
    providerCollectStep === "DONE" ||
    providerCollectStep === "UNSUCCESS" ||
    providerCollectStep === "VALIDATEUNSUCCESS";

  const sourceProviderPendingStart = !isProviderCollectStarted
    ? sourceProviderFilteredByUserType.map((provider) => provider.id)
    : [];

  if (hasEditAssignedDrivers) {
    return (
      <FormProvider {...formMethods}>
        <AssignDriverToAddressForm
          providers={providers}
          addresses={addresses}
          requestedSourceAddresses={requestedSourceAddresses}
          hasEditAssignedDrivers={hasEditAssignedDrivers}
          driversFilteredBySourceCollector={driversFilteredBySourceCollector}
          onAssignDriverToAddress={handleAssignDriverToAddress}
          driversAssignedToAddresses={driverAssignedToAddresses as string[]}
          driversAssignedToProvider={driverAssignedToProviders as string[]}
          sourceAddressesPendingStart={noStartedAddresses}
          sourceProviderPendingStart={sourceProviderPendingStart}
        />
      </FormProvider>
    );
  }



  async function handleCancelAddressToCollect(address_id: string) {
    const hasCancelCollectAddress = await confirmMessage({
      title: "Deseja cancelar a coleta do endereço?",
    });

    if (hasCancelCollectAddress) {
      await cancelCollectServiceAddress({
        address_id,
        service_id
      });
    } else {
      standardMessage("Ação cancelada com êxito!");
    }
  }

  async function handleRequestCancelAddressToCollect(address_id: string) {
    const hasCancelCollectAddress = await confirmMessage({
      title: "Deseja solicitar cancelamento da coleta do endereço?",
    });

    if (hasCancelCollectAddress) {
      await cancelAddressToCollectService({ service_id, address_id }, {
        onSuccess: () => {
          serviceSocketConnection.emit('requestedCancelCollectAddress', {
            service_protocol: service.protocol
          })
        }
      });
    } else {
      standardMessage("Ação cancelada com êxito!");
    }
  }

  const userHasPermissionToCancelAddress = userLogged?.permissions.includes(
    "cancel-collect-address"
  );
  const userHasPermissionRequestToCancelAddress =
    userLogged?.permissions.includes("request-cancel-collect-address");

  const serviceHasPendingSourceAddressesToValidate = service
    ? service.serviceIDRequested.additional_collect_addresses !== null
    : false;



  return (
    <>
      <ExternalComunications.TriggerButton
        aria-label="help-me"
        onClick={onOpenExternalComunicationModal}
      />

      <ExternalComunications.Root
        isOpen={isExternalComunicationModalOpen}
        onClose={onCloseExternalComunicationModal}
      >
        <ExternalComunications.Content
          serviceId={service.id}
          onClose={onCloseExternalComunicationModal}
        />
      </ExternalComunications.Root>

      <GenerateLabelModal
        isModalOpen={isOpen}
        onClose={onClose}
        sourceAddresses={modalSourceAddresses}
        destinationAddresses={modalDestinationAddresses}
        serviceProtocol={service?.protocol!}
      />
      <CancelServiceModal
        serviceId={service.id}
        serviceProtocol={service.protocol}
        isOpen={isCancelServiceModalOpen}
        onClose={onCloseCancelServiceModal}
      />

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={["6", "8"]}
          noValidate
        >
          <Flex
            align="center"
            justify="space-between"
            direction={["column", "column", "row"]}
          >
            <Heading size="lg" fontFamily="poppins">
              Endereços para Coleta
            </Heading>
            {!isUserTypeDriver &&
              collectorHasDriverAssignment === true &&
              (!!sourceAddressesPendingStart?.length ||
                !!sourceProviderPendingStart.length) && (
                <Button onClick={handleEditAssignedDrivers} size="sm">
                  Editar motoristas atribuidos
                </Button>
              )}
          </Flex>

          <Divider my="6" borderColor="gray.700" />
          {sourceAddressFilteredByUserType.length === 0 &&
            sourceProviderFilteredByUserType.length === 0 ? (
            <>
              <Flex justify="center" align="center">
                <Flex
                  maxW="400px"
                  direction="column"
                  justify="center"
                  align="center"
                >
                  <Icon as={FaTruckLoading} w={20} h={20} />
                  <Text fontWeight="bold">Oops... Nada por aqui!</Text>
                  <Text>Não há endereços para coleta!</Text>
                  <ListButton
                    customStyle={{ width: "100%" }}
                    href="/servicos/coletas"
                    name="Retornar para coletas"
                  />
                </Flex>
              </Flex>
            </>
          ) : (
            <>
              {sourceAddressFilteredByUserType.filter(address => !service.serviceIDRequested.addresses_requested_to_cancel.includes(address.id)).map((address) => (
                <Stack
                  key={address.id}
                  spacing="24px"
                  mt="4"
                  direction={["column", "column", "column"]}
                >
                  <Input
                    defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep} | ${address.responsible_name} | ${address.complement}`.toUpperCase()}
                    isDisabled={isDisabled}
                    name="firstname"
                    label="Endereço Remetente"
                  />

                  {service?.serviceIDCollect.find(
                    (collect) => collect.address_id === address.id
                  ) ? (
                    <FormActionButton
                      href={{
                        pathname: `/servico/${service_id}/finalizar/coleta/endereco/${address.id}`,
                        state: {
                          path: state?.path !== undefined ? state?.path : null
                        }
                      }}
                      action="Finalizar coleta"
                      width="100%"
                      w="full"
                    />
                  ) : (
                    <Stack>
                      <FormActionButton
                        href={{
                          pathname: `/servico/${service_id}/coleta/endereco/${address.id}`,
                          state: {
                            path: state?.path !== undefined ? state?.path : null
                          }
                        }}
                        action="Coletar"
                        w="full"
                      />
                      {userHasPermissionToCancelAddress && (
                        <Button
                          colorScheme="red"
                          variant="outline"
                          onClick={() =>
                            handleCancelAddressToCollect(address.id)
                          }
                          w="full"
                        >
                          Cancelar coleta
                        </Button>
                      )}
                      {userHasPermissionRequestToCancelAddress && (
                        <Button
                          colorScheme="red"
                          variant="outline"
                          onClick={() =>
                            handleRequestCancelAddressToCollect(address.id)
                          }
                          w="full"
                        >
                          Solicitar cancelamento de coleta
                        </Button>
                      )}
                    </Stack>
                  )}

                  <Divider my="6" borderColor="gray.700" />
                </Stack>
              ))}

              {sourceProviderFilteredByUserType.map((provider) => (
                <Stack
                  key={provider.id}
                  spacing="24px"
                  mt="4"
                  direction={["column", "column", "column"]}
                >
                  <Input
                    defaultValue={`| ${provider.trading_name} | ${provider.street} | ${provider.number} | ${provider.neighborhood} | ${provider.city} | ${provider.cep} | ${provider.complement}`.toUpperCase()}
                    isDisabled={isDisabled}
                    name="firstname"
                    label="Endereço do Fornecedor de Gelo Seco"
                  />

                  {service?.serviceIDCollect.find(
                    (collect) => collect.provider_id === provider.id
                  ) ? (
                    <FormActionButton
                      href={{
                        pathname: `/servico/${service_id}/finalizar/coleta/fornecedor/${provider.id}`,
                        state: {
                          path: state?.path !== undefined ? state?.path : null
                        }
                      }}
                      action="Finalizar coleta"
                      w="full"
                    />
                  ) : (
                    <FormActionButton
                      href={{
                        pathname: `/servico/${service_id}/coleta/fornecedor/${provider.id}`,
                        state: {
                          path: state?.path !== undefined ? state?.path : null
                        }
                      }}
                      action="Coletar"
                      w="full"
                    />
                  )}
                  <Divider my="6" borderColor="gray.700" />
                </Stack>
              ))}
              <Flex mt="8" justify="flex-end">
                <Flex w="full" justifySelf="flex-start">
                  <Button onClick={onOpen}>
                    {isWideVersion ? (
                      "Download Etiqueta"
                    ) : (
                      <Icon as={FaFilePdf} />
                    )}
                  </Button>
                </Flex>
                <HStack spacing="12px">
                  {userLogged !== null &&
                    userLogged?.permissions.includes("cancel-service") &&
                    !serviceHasPendingSourceAddressesToValidate && (
                      <CancelButton
                        type="button"
                        action="Cancelar Serviço"
                        onClick={() => onOpenCancelServiceModal()}
                      />
                    )}
                  <ListButton
                    href="/servicos/coletas"
                    name="Serviços à Coletar"
                  />
                </HStack>
              </Flex>
            </>
          )}
        </Box>
      </Flex>
    </>
  );
}

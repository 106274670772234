import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  CheckboxGroup,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckBoxProps,
  Text,
  Stack,
  Box,
  Flex,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

export interface CheckboxOption {
  key: string
  value?: string | number
  showOption: ReactNode
}

interface InputProps extends ChakraCheckBoxProps {
  name: string
  label?: string
  required?: boolean
  error?: FieldError
  checkboxOptions: CheckboxOption[]
  defaultCheckedOptions?: string[] | number[]
  onCheckboxChange: () => void
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    required = false,
    error = null,
    checkboxOptions,
    defaultCheckedOptions,
    onCheckboxChange,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            <Flex h="full" w="full" gap="1" align="center">
              <Text fontSize="sm" color="gray.700" w="full">
                {label}
              </Text>
              {required && (
                <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                  {' '}
                  *
                </Text>
              )}
            </Flex>
          </FormLabel>
        </Flex>
      )}

      <Box
        overflowY="auto"
        height="200px"
        borderWidth="1px"
        borderRadius="lg"
        p="2"
      >
        {!!checkboxOptions && (
          <Stack spacing="10px" justify="flex-start">
            <CheckboxGroup
              onChange={onCheckboxChange}
              colorScheme="blue"
              defaultValue={defaultCheckedOptions}
            >
              <Stack direction="column">
                {checkboxOptions?.map(option => (
                  <ChakraCheckbox
                    key={option.key}
                    name={name}
                    label={label}
                    value={option.value}
                    error={error}
                    ref={ref}
                    {...rest}
                  >
                    {option.showOption}
                  </ChakraCheckbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </Stack>
        )}
      </Box>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const GeneralCheckbox = forwardRef(CheckboxBase)

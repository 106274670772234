import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface RejectCltIntercurrenceProps {
  routeParams: {
    cltIntercurrenceId: string
  }
}

export async function rejectCltIntercurrence({
  routeParams
}: RejectCltIntercurrenceProps) {
  try {
    await api.patch(`/clt-intercurrences/${routeParams.cltIntercurrenceId}/reject`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Divider } from "@chakra-ui/react";
import { MaterialRequest } from "hooks/materialRequests/dtos/MaterialRequest";
import { MaterialRequestInfo } from "./MaterialRequestInfo";
import { MaterialRequestModal } from "./MaterialRequestModal";

interface ViewMaterialRequestProps {
  isOpen: boolean
  onClose: () => void
  materialRequest: MaterialRequest
}


export function ViewMaterialRequestModal({
  isOpen,
  onClose,
  materialRequest
}: ViewMaterialRequestProps) {

  return (
    <MaterialRequestModal.Root
      title="Visualizar RSM"
      isOpen={isOpen}
      onClose={onClose}
    >
      <MaterialRequestModal.Content>
        <MaterialRequestInfo materialRequest={materialRequest} />

        <Divider />

      </MaterialRequestModal.Content>

    </MaterialRequestModal.Root>
  )
}

import { usePagination } from "@ajna/pagination"
import { Box, Button, Divider, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";

import { Pagination } from "components/Pagination/Pagination"
import { useHistory } from "react-router-dom";

import { useEffect, useReducer, useState } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FaEye } from "react-icons/fa";
import { useArrivedPurchaseOrders } from "hooks/purchaseOrder/useArrivedPurchaseOrders";
import { PurchaseOrder } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { ViewPurchaseOrderModal } from "./components/ViewPurchaseOrderModal";

interface ActionPayload {
  currentPage?: number
}

interface Action {
  type: 'set-arrived-purchase-orders-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-arrived-purchase-orders-current-page') {
    return {
      currentPage: action.payload.currentPage
    }
  }
  return {
    ...state,
    ...action.payload
  }
}

export function PurchaseOrdersArrived() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const [purchaseOrder, setPurchaseOrder] = useState({} as PurchaseOrder)

  const [listPurchaseOrdersArrivedDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const userHasPermissionToViewArrivedPurchaseOrders =
    userLogged.permissions.includes('view-purchases-arrived')

  useEffect(() => {
    if (!userHasPermissionToViewArrivedPurchaseOrders) {
      redirect('/')
    }
  }, [userHasPermissionToViewArrivedPurchaseOrders, redirect])



  const {
    data: purchaseOrdersArrivedResponseData,
    isFetching: isFetchingPurchaseOrdersArrivedData
  } = useArrivedPurchaseOrders({
    queryParams: {
      currentPage: listPurchaseOrdersArrivedDataState.currentPage,
      pageSize: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!listPurchaseOrdersArrivedDataState.currentPage
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: purchaseOrdersArrivedResponseData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-arrived-purchase-orders-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  const {
    isOpen: isViewPurchaseOrderModalOpen,
    onOpen: onOpenViewPurchaseOrderModal,
    onClose: onCloseViewPurchaseOrderModal,
  } = useDisclosure()

  const handleOpenViewPurchaseOrderModal = (purchaseOrder: PurchaseOrder) => {
    setPurchaseOrder(purchaseOrder)
    onOpenViewPurchaseOrderModal()
  }


  const handleChangePage = (page: number) => setCurrentPage(page)


  return (
    <>
      <ViewPurchaseOrderModal
        onClose={onCloseViewPurchaseOrderModal}
        isOpen={isViewPurchaseOrderModalOpen}
        purchaseOrder={purchaseOrder}
      />
      <Box
        borderRadius='8px'
        p={4}
        bg='white'
      >
        {isFetchingPurchaseOrdersArrivedData ? <Spinner /> :
          <Flex
            w="full"
            direction="column"
            gap={6}
          >
            <Heading fontSize="xl">Compras Finalizadas</Heading>
            <Divider />
            <TableContainer>
              <Table variant="striped" size="sm">
                <Thead>
                  <Tr>
                    <Th>PROTOCOLO</Th>
                    <Th>SOLICITANTE</Th>
                    <Th>DATA SOLICITAÇÃO</Th>
                    <Th>TIPO</Th>
                    <Th>TIPO DE COTAÇÃO</Th>
                    <Th>DATA LIMITE</Th>
                    <Th>FORNECEDOR APROVADO</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {purchaseOrdersArrivedResponseData?.purchaseOrders.map(purchaseOrder => {
                    return (
                      <Tr key={purchaseOrder.id}>
                        <Td>{purchaseOrder.protocol}</Td>
                        <Td>{`${purchaseOrder.owner.firstname} ${purchaseOrder.owner.lastname}`}</Td>
                        <Td>{formatDate.handle(purchaseOrder.created_at, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.type}</Td>
                        <Td>{purchaseOrder.quote_type}</Td>
                        <Td>{formatDate.handle(purchaseOrder.arrival_limit_date, 'DateWithoutHourToShow')}</Td>
                        <Td>{purchaseOrder.approved_provider}</Td>
                        <Td isNumeric>
                          <Button
                            onClick={() => handleOpenViewPurchaseOrderModal(purchaseOrder)}
                            variant="ghost"
                          >
                            <Icon
                              cursor="pointer"
                              as={FaEye}
                              transition="all 0.2s"
                              _hover={{ opacity: 0.8 }}
                            />
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              pagesQuantity={pagesCount}
              handlePageChange={handleChangePage}
            />
          </Flex>
        }
      </Box>
    </>
  )
}

import { Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Menu, MenuButton, IconButton, MenuList, MenuGroup, MenuItem, Flex, Icon } from "@chakra-ui/react"
import { useConsultOccurrences } from "hooks/occurrences/useConsultOccurrences"
import { useConsultOccurrencesContext } from "hooks/occurrences/useConsultOccurrencesContext"
import { useEffect, useState } from "react"
import { FaEllipsisH, FaEye } from "react-icons/fa"
import { Link } from "react-router-dom"

interface ConsultOccurrencesTableProps {
  currentPageFilter?: RegExpMatchArray
  intercurrencesFiltersSelectedValues: string[]
  occurrenceTypeFiltersSelectedValues: string[]
  occurrenceStepFiltersSelectedValues: string[]
  startFilterDebounce?: string
  endFilterDebounce?: string
  onSetTotalPages: (quantity: number) => void
}

enum OccurrenceStepEnum {
  'AWAITING-PROCEDURE' = 'AGUARDANDO VALIDAÇÃO',
  'AWAITING-ALIGNMENT' = 'AGUARDANDO ALINHAMENTO',
  'AWAITING-ATTACHMENT' = 'AGUARDANDO ANEXO DE EVIDÊNCIAS',
  'OCCURRENCE-FINALIZED' = 'FINALIZADA'
}

export function ConsultOcurrencesTable({
  currentPageFilter,
  intercurrencesFiltersSelectedValues,
  occurrenceTypeFiltersSelectedValues,
  occurrenceStepFiltersSelectedValues,
  startFilterDebounce,
  endFilterDebounce,
  onSetTotalPages,
}: ConsultOccurrencesTableProps) {
  const [debouncedQuery, setDebouncedQuery] = useState('')

  const { query } = useConsultOccurrencesContext()

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedQuery(query)
    }, 500)

    return () => clearTimeout(debounce)
  }, [query])


  const {
    data: consultOccurrencesData,
    isFetching: isFetchingConsultOccurrencesData
  } = useConsultOccurrences({
    queryParams: {
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1,
      pageSize: 10,
      intercurrences: intercurrencesFiltersSelectedValues.join(','),
      types: occurrenceTypeFiltersSelectedValues.join(','),
      steps: occurrenceStepFiltersSelectedValues.join(','),
      query: debouncedQuery ? debouncedQuery : undefined,
      startFilter: startFilterDebounce ? startFilterDebounce : undefined,
      endFilter: endFilterDebounce ? endFilterDebounce : undefined
    }
  })

  useEffect(() => {
    onSetTotalPages(consultOccurrencesData?.totalPages ?? 1)
  }, [consultOccurrencesData,onSetTotalPages])

  return (
    <>
      {isFetchingConsultOccurrencesData ? (
        <Spinner />
      ) : (
        <TableContainer
          fontSize="xs"
          rounded="md"
          border="1px solid"
          borderColor="gray.100"
          ml="2"
        >
          <Table
            size="md"
            variant="simple"
          >
            <Thead
              bg="blue.50"
            >
              <Tr>
                <Th>Tipo</Th>
                <Th>Intercorrência</Th>
                <Th>Protocolo da Ocorrência</Th>
                <Th>Protocolo do Serviço</Th>
                <Th>Etapa</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {consultOccurrencesData?.occurrences.map((occurrence) => {
                return (
                  <Tr key={occurrence.id}>
                    <Td>{occurrence.type}</Td>
                    <Td>{occurrence.intercurrence}</Td>
                    <Td>{occurrence.occurrence_protocol}</Td>
                    <Td>{occurrence.occurrenceIDService.protocol}</Td>
                    <Td>{OccurrenceStepEnum[occurrence.step]}</Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          variant='ghost'
                          aria-label='Options'
                          icon={<FaEllipsisH />}
                        />
                        <MenuList
                          boxShadow="md"
                        >
                          <MenuGroup
                            title="Ações"
                          >
                            <MenuItem
                              as={Link}
                              to={`/ocorrencia/visualizar/${occurrence.id}`}
                            >
                              <Flex
                                gap={2}
                                align="center"
                              >
                                <Icon
                                  as={FaEye}
                                  fontSize="md"
                                />
                                Visualizar
                              </Flex>
                            </MenuItem>
                          </MenuGroup>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

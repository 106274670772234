import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  FormLabel,
  Icon,
  Link as ChakraLink,
  TableContainer,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useEffect, useMemo } from 'react'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch, useFieldArray } from 'react-hook-form'
import { Input } from '../../components/Inputs/Input'
import { Select } from '../../components/Inputs/SelectInput'
import { TextArea } from '../../components/Inputs/TextInput'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useCity } from '../../hooks/city/useCity'
import { useCurrency } from 'react-hook-currency'
import { useCollectors } from 'hooks/collector/useCollectors'
import { useCollectorCost } from 'hooks/cost/useCollectorCost'
import { FiFile } from 'react-icons/fi'
import { FaEye, FaTimes, FaTrash } from 'react-icons/fa'
import { handleChangeUrl } from 'utils/handleChangeUrl'
import { useCollectorCostFunctions } from 'hooks/cost/useCollectorCostFunctions'
import { useToastify } from 'hooks/toastify/useToastify'
import { useQueryClient } from 'react-query'
import { positiveNegativeOptions } from '../../utils/CustomLists/positiveNegativeOptions'
import { CostPeriodConditionalsField } from './components/CostPeriodConditionalsField'
import { CostDayConditionalsField } from './components/CostDayConditionalsField'
import { CostAdmRegionConditionalsField } from './components/CostAdmRegionConditionalsField'
import { AdministrativeRegionConditional, PeriodConditional, DayConditional } from 'hooks/cost/dtos/CollectorCost'

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/eml",
]

const conditionalOptions = [
  { key: '0', value: 'hour', showOption: 'Horário' },
  { key: '1', value: 'day', showOption: 'Dia' },
  { key: '2', value: 'admRegion', showOption: 'Região Administrativa' },
]

interface Attachments {
  attachment: FileList
}



export interface FormInputProps {
  collector_id: string
  status: 'active' | 'inactive'
  city_id: string
  cost_motorcycle: number
  additional_cost_motorcycle: number
  cost_car: number
  additional_cost_car: number
  cost_truck: number
  additional_cost_truck: number
  observation: string
  attachments: Attachments[]
  has_conditional: 'yes' | 'no'
  conditional: string
  admRegionConditionals: AdministrativeRegionConditional[]
  periodConditionals: PeriodConditional[]
  dayConditionals: DayConditional[]
  is_primary_collector: 'yes' | 'no'
}

const statusOptions = [
  { showOption: 'Ativo', value: 'active', key: 'active' },
  { showOption: 'Inativo', value: 'inactive', key: 'inactive' }
]

interface CollectorFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
  submit: (values: FormInputProps) => Promise<void>
}

const collectorCostSchema = yup.object().shape({
  collector_id: yup.string().required('Coletador inválido'),
  status: yup.string().required('Campo obrigatório'),
  city_id: yup.string().required('Cidade inválida'),
  cost_motorcycle: yup.string().required('Campo Obrigatório'),
  additional_cost_motorcycle: yup.string().required('Campo Obrigatório'),
  cost_car: yup.string().required('Campo Obrigatório'),
  additional_cost_car: yup.string().required('Campo Obrigatório'),
  cost_truck: yup.string().required('Campo Obrigatório'),
  additional_cost_truck: yup.string().required('Campo Obrigatório'),
  observation: yup.string(),
  has_conditional: yup.string().required('Campo obrigatório'),
  conditional: yup.mixed().when('has_conditional', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),
  periodConditionals: yup.mixed().when('conditional', {
    is: 'hour',
    then: yup.array().min(1, 'Campo obrigatório').of(yup.object({
      start_hour: yup.string().required('Campo obrigatório'),
      end_hour: yup.string().required('Campo obrigatório'),
      cost_motorcycle: yup.string().required('Campo obrigatório'),
      additional_cost_motorcycle: yup.string().required('Campo obrigatório'),
      cost_car: yup.string().required('Campo obrigatório'),
      additional_cost_car: yup.string().required('Campo obrigatório'),
    }))
  }),
  dayConditionals: yup.mixed().when('conditional', {
    is: 'day',
    then: yup.array().min(1, 'Campo obrigatório').of(yup.object({
      day: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
      cost_motorcycle: yup.string().required('Campo obrigatório'),
      additional_cost_motorcycle: yup.string().required('Campo obrigatório'),
      cost_car: yup.string().required('Campo obrigatório'),
      additional_cost_car: yup.string().required('Campo obrigatório'),
    }))
  }),
  is_primary_collector: yup.string().required('Campo obrigatório'),
})

export function CollectorCostForm({
  slug,
  id,
  isDisabled = false,
  href,
  title,
  action,
  submit,
}: CollectorFormProps) {

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true, false)
  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    data: collectorCost,
    isFetching: isCollectorCostFetching
  } = useCollectorCost({ collectorCostId: id, queryOptions: { enabled: slug !== 'adicionar' } })

  const {
    deleteCollectorCostAttachment
  } = useCollectorCostFunctions()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  async function handleDeleteCollectorCostAttachment(fileKey: string) {
    await promiseMessage(deleteCollectorCostAttachment.mutateAsync({
      collectorCostId: id,
      fileKey,
    }), 'Anexo excluído com sucesso!')

    await queryClient.invalidateQueries('collectorCost')
  }

  const collectorCostRegistedAttachments = collectorCost?.attachments
    ? typeof collectorCost?.attachments !== 'string'
      ? collectorCost?.attachments
      : JSON.parse(collectorCost?.attachments)
    : []

  const {
    register,
    handleSubmit,
    setValue,
    control,
    unregister,
    formState: { errors, isSubmitting },
  } = useForm<FormInputProps>({
    resolver: yupResolver(collectorCostSchema),
    defaultValues: {
      status: slug === 'adicionar' ? 'active' : undefined
    }
  })

  console.log(errors)

  const { onChange, format } = useCurrency({
    style: 'decimal',
  })

  const [
    conditional,
    hasConditional,
    collectorSelected,
    collectorCostAttachment,
  ] = useWatch({
    control,
    name: [
      'conditional',
      'has_conditional',
      'collector_id',
      'attachments',
    ]
  })


  useEffect(() => {
    if (hasConditional === 'no') {
      unregister('conditional')
      unregister('dayConditionals')
      unregister('periodConditionals')
      unregister('admRegionConditionals')
    }
  }, [hasConditional, unregister])

  const isHourConditional = conditional === 'hour'
  const isDayConditional = conditional === 'day'
  const isAdmRegionConditional = conditional === 'admRegion'

  const isCollectorSelected = !!collectorSelected

  const {
    fields: collectorCostAttachmentFields,
    append: appendCollectorCostAttachmentField,
    remove: removeCollectorCostAttachmentField
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  function handleRemoveCollectorCostAttachmentField(index: number) {
    removeCollectorCostAttachmentField(index)
  }

  function handleAppendCollectorCostAttachmentField() {
    appendCollectorCostAttachmentField({ attachment: undefined })
  }

  const citiesFiltered = useMemo(() => {
    if (!!collectors && !!cities) {
      const hubList = collectors
        .filter((collector) => collector.id === collectorSelected)
        .flatMap((collector) => collector.hub_list)
      return cities.filter((city) => hubList.includes(city.hub_id))
    }
  }, [cities, collectors, collectorSelected])

  useEffect(() => {
    async function run() {
      if ((slug === 'editar' || slug === 'visualizar') && collectorCost) {
        setValue('collector_id', collectorCost.collector_id)
        setValue('status', collectorCost.status)
        setValue('city_id', collectorCost.city_id)
        setValue('cost_motorcycle', collectorCost.cost_motorcycle)
        setValue('additional_cost_motorcycle', collectorCost.additional_cost_motorcycle)
        setValue('cost_car', collectorCost.cost_car)
        setValue('additional_cost_car', collectorCost.additional_cost_car)
        setValue('cost_truck', collectorCost.cost_truck)
        setValue('additional_cost_truck', collectorCost.additional_cost_truck)
        setValue('observation', collectorCost.observation)
        setValue('is_primary_collector', collectorCost?.is_primary_collector ? 'yes' : 'no')

        if (collectorCost?.conditionals) {
          setValue('has_conditional', 'yes')

          if (collectorCost.conditionals.adm_region_conditionals) {
            setValue('conditional', 'admRegion')
          }

          if (collectorCost.conditionals.day_conditionals) {
            setValue('conditional', 'day')
          }

          if (collectorCost.conditionals.period_conditionals) {
            setValue('conditional', 'hour')
          }

        }
      } else {
        setValue('has_conditional', 'no')
      }
    }

    run()
  }, [collectorCost, setValue, slug, id])

  if (isCollectorCostFetching) {
    return <GeneralContentLoading />
  }

  if (!isCollectorSelected) {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />
          <Select
            {...register('collector_id')}
            name="collector_id"
            label="Pertence ao Coletador"
            error={errors.collector_id}
            collectors={collectors}
            isDisabled={isDisabled || isCollectorsLoading}
            placeholder="Selecione uma opção..."
            required
          />

          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/custos/coletadores">
                <Button type="button" colorScheme="gray">
                  Lista de Custos de Coletadores
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  } else {
    return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack spacing={6} direction={['column', 'column', 'row']}>
            <Stack w="full" spacing={6} direction={['column', 'column', 'row']}>
              <Select
                {...register('collector_id')}
                isDisabled={isDisabled || isCollectorsLoading}
                name="collector_id"
                label="Pertence ao Coletador"
                collectors={collectors}
                error={errors.collector_id}
                placeholder="Selecione uma opção..."
                required
              />

              <Select
                {...register('city_id')}
                name="city_id"
                label="Pertence a Cidade"
                error={errors.city_id}
                isDisabled={isDisabled || isCitiesLoading}
                citiesBack={citiesFiltered}
                placeholder="Selecione uma opção..."
                required
              />
            </Stack>

            <Select
              {...register('status')}
              name="status"
              label="Status"
              error={errors.status}
              isDisabled={isDisabled || slug === 'adicionar'}
              options={statusOptions}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('cost_motorcycle')}
              name="cost_motorcycle"
              label="Custo para Motos"
              onChange={onChange}
              defaultValue={format('000')}
              error={errors.cost_motorcycle}
              isDisabled={isDisabled}
              addChildren="R$"
              required
            />

            <Input
              {...register('additional_cost_motorcycle')}
              name="additional_cost_motorcycle"
              label="Custo Adicional para Motos"
              onChange={onChange}
              defaultValue={format('000')}
              error={errors.additional_cost_motorcycle}
              isDisabled={isDisabled}
              addChildren="R$"
              required
            />
          </Stack>

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('cost_car')}
              name="cost_car"
              label="Custo para Carros"
              onChange={onChange}
              defaultValue={format('000')}
              error={errors.cost_car}
              isDisabled={isDisabled}
              addChildren="R$"
              required
            />

            <Input
              {...register('additional_cost_car')}
              name="additional_cost_car"
              label="Custo Adicional para Carros"
              onChange={onChange}
              defaultValue={format('000')}
              error={errors.additional_cost_car}
              isDisabled={isDisabled}
              addChildren="R$"
              required
            />
          </Stack>
          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('cost_truck')}
              name="cost_truck"
              onChange={onChange}
              defaultValue={format('000')}
              label="Custo para Caminhões"
              error={errors.cost_truck}
              isDisabled={isDisabled}
              addChildren="R$"
              required
            />

            <Input
              {...register('additional_cost_truck')}
              name="additional_cost_truck"
              label="Custo Adicional para Caminhões"
              error={errors.additional_cost_truck}
              isDisabled={isDisabled}
              onChange={onChange}
              defaultValue={format('000')}
              addChildren="R$"
              required
            />
          </Stack>
          <Stack
            spacing="6"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register("is_primary_collector")}
              name="is_primary_collector"
              placeholder="Selecione uma opção..."
              options={positiveNegativeOptions}
              label="É o coletador principal?"
              error={errors.is_primary_collector}
              required
            />
          </Stack>
          <Stack
            spacing="6"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register("has_conditional")}
              name="has_conditional"
              placeholder="Selecione uma opção..."
              options={positiveNegativeOptions}
              label="Possui condicional?"
              error={errors.has_conditional}
              required
            />

            {hasConditional === 'yes' && (
              <Select
                {...register("conditional")}
                name="conditional"
                error={errors.conditional}
                placeholder="Selecione uma opção..."
                label="Condicional"
                options={conditionalOptions}
                required
              />
            )}

          </Stack>

          {isHourConditional && (
            <CostPeriodConditionalsField
              control={control}
              errors={errors}
              register={register}
              periodConditionals={collectorCost?.conditionals?.period_conditionals}
            />
          )}

          {isDayConditional && (
            <CostDayConditionalsField
              control={control}
              errors={errors}
              register={register}
              dayConditionals={collectorCost?.conditionals?.day_conditionals}
            />
          )}

          {isAdmRegionConditional && (
            <CostAdmRegionConditionalsField
              control={control}
              errors={errors}
              register={register}
              admRegionConditionals={collectorCost?.conditionals?.adm_region_conditionals}
            />
          )}

          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            {collectorCostRegistedAttachments?.length > 0 && (
              <TableContainer w="full">
                <Table size='sm' mt={4}>
                  <Thead>
                    <Tr>
                      <Th>Anexos Cadastrados</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {collectorCostRegistedAttachments?.map((attachment) => {
                      return (
                        <Tr key={attachment?.key}>
                          <Th>{attachment?.filename}</Th>
                          <Th isNumeric>
                            <Button
                              variant='ghost'
                              onClick={() => handleDeleteCollectorCostAttachment(attachment.key)}
                            >
                              <Icon as={FaTrash} />
                            </Button>
                            <Button
                              as={ChakraLink}
                              href={handleChangeUrl(attachment?.key)}
                              variant='ghost'
                              isExternal
                            >
                              <Icon as={FaEye} />
                            </Button>

                          </Th>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Stack>
          {collectorCostAttachmentFields.map((field, index) => {
            return (
              <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                <Input
                  {...register(`attachments.${index}.attachment`)}
                  name={`attachments.${index}.attachment`}
                  label="Anexo"
                  type="file"
                  accept={supportedFileFormats.join(", ")}
                  error={errors.attachments ? errors?.attachments[index]?.attachment : undefined}
                  hidden
                >
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    justifyContent='space-between'
                    direction={['column', 'column', 'row']}
                  >
                    <Button
                      as={FormLabel}
                      htmlFor={`attachments.${index}.attachment`}
                      _hover={{
                        cursor: 'pointer'
                      }}
                      mr="2"
                      leftIcon={<Icon as={FiFile} />}
                    >
                      Upload
                    </Button>
                    {collectorCostAttachment && collectorCostAttachment[index]?.attachment?.length > 0 && (
                      <ChakraLink
                        href={URL.createObjectURL(collectorCostAttachment[index].attachment[0])}
                      >
                        {collectorCostAttachment[index].attachment[0].name} (Visualizar)
                      </ChakraLink>
                    )}
                    <Button
                      leftIcon={<Icon as={FaTimes} />}
                      variant="ghost"
                      onClick={() => handleRemoveCollectorCostAttachmentField(index)}
                      h="48px"
                    >
                      Remover
                    </Button>
                  </Stack>
                </Input>
              </Stack>
            )
          })}

          <Stack
            w="full"
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Button
              _hover={{
                cursor: 'pointer'
              }}
              mr="2"
              leftIcon={<Icon as={FiFile} />}
              onClick={handleAppendCollectorCostAttachmentField}
              isDisabled={isDisabled}
            >
              Adicionar anexo
            </Button>
          </Stack>
          <Stack
            w="full"
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <TextArea
              {...register('observation')}
              name="observation"
              label="Observações"
              isDisabled={isDisabled}
            />
          </Stack>
          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/custos/coletadores">
                <Button type="button" colorScheme="gray">
                  Lista de Custo de Coletadores
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

    )
  }
}

import { Box, Button, Flex, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt, FaTag, FaTimes } from "react-icons/fa";
import { CltIntercurrenceDetail } from "./CltIntercurrenceDetail";
import { useAuth } from "hooks/auth/useAuth";
import { CltIntercurrence } from "api/cltIntercurrences/types/CltIntercurrence";
import { cltIntercurrenceTypeMap } from "utils/cltIntercurrenceMappers";
import { format } from "date-fns";
import { useToastify } from "hooks/toastify/useToastify";
import { GetCltIntercurrencesResponse } from "api/cltIntercurrences/getCltIntercurrences";
import { useMutation, useQueryClient } from "react-query";
import { rejectCltIntercurrence } from "api/cltIntercurrences/rejectCltIntercurrence";


interface CltIntercurrencesKanbanCardProps {
  cltIntercurrence: CltIntercurrence
}

export function CltIntercurrencesKanbanCard({ cltIntercurrence }: CltIntercurrencesKanbanCardProps) {
  const { userLogged } = useAuth()
  const queryClient = useQueryClient()


  const userCanRejectCltIntercurrence = userLogged?.permissions?.includes('reject-clt-intercurrence')

  const { mutateAsync: rejectCltIntercurrenceFn } = useMutation({
    mutationFn: rejectCltIntercurrence,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetCltIntercurrencesResponse>({
        queryKey: ['clt-intercurrences']
      })

      queryClient.invalidateQueries({ queryKey: 'approved-clt-intercurrences' })
      queryClient.invalidateQueries({ queryKey: 'rejected-clt-intercurrences' })
      queryClient.invalidateQueries({ queryKey: 'requested-cltintercurrences' })
      queryClient.invalidateQueries({ queryKey: ['clt-intercurrence', routeParams.cltIntercurrenceId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          cltIntercurrences: cachedValue.cltIntercurrences.map((cltIntercurrence) => {
            if (cltIntercurrence.id === routeParams.cltIntercurrenceId) {
              return { ...cltIntercurrence, status: 'rejected' }
            }

            return cltIntercurrence
          })
        })
      })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleRejectCltIntercurrence() {
    await promiseMessage(
      rejectCltIntercurrenceFn({ routeParams: { cltIntercurrenceId: cltIntercurrence.id } }),
      'Intercorrência rejeitada com sucesso!'
    )
  }


  const {
    isOpen: isCltIntercurrenceDetailModalOpen,
    onToggle: onToggleCltIntercurrenceDetailModal,
  } = useDisclosure()



  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleCltIntercurrenceDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {cltIntercurrence?.collaborator_name}
        </Text>

      </HStack>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaTag} />
            <Text fontSize="xs" fontWeight="bold">TIPO</Text>
          </HStack>
          <Text ml={5}>{cltIntercurrenceTypeMap[cltIntercurrence.type]}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA INTERCORRêNCIA</Text>
          </HStack>
          <Text ml={5}>{format(new Date(cltIntercurrence?.date), 'dd/MM/yyyy')}</Text>
        </Box>

      </VStack>
      <Flex align="center" gap="2" alignItems="flex-end">
        {userCanRejectCltIntercurrence && cltIntercurrence?.status !== 'rejected' && (
          <Button
            size="xs"
            lineHeight="1"
            leftIcon={<FaTimes />}
            variant="outline"
            onClick={(e) => {
              e.stopPropagation()
              handleRejectCltIntercurrence()
            }}

            ml="auto"
          >
            Rejeitar
          </Button>
        )}
      </Flex>

      <Modal
        isOpen={isCltIntercurrenceDetailModalOpen}
        onClose={onToggleCltIntercurrenceDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <CltIntercurrenceDetail
          cltIntercurrenceId={cltIntercurrence.id}

        />
      </Modal>
    </Box>
  )
}

import { Box, Flex } from "@chakra-ui/react";
import { ExperiencePeriodStatus as ExperiencePeriodStatusTypes } from "api/experiencePeriods/types/ExperiencePeriod";
import { experiencePeriodStatusMap } from "utils/experiencePeriodMappers";

interface ExperiencePeriodStatusProps {
  status: ExperiencePeriodStatusTypes;
}

export function ExperiencePeriodStatus({ status }: ExperiencePeriodStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {(status === 'validating-45-days' || status === 'validating-90-days') && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'active' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'dismissing' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}


      {experiencePeriodStatusMap[status]}
    </Flex>
  )
}

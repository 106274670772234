import { Box, Divider, Flex, Grid, GridItem, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Skeleton, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { getAudit } from "api/audits/getAudit";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { AuditCommercialTableRow } from "./AuditCommercialTableRow";
import { AuditFieldTableRow } from "./AuditFieldTableRow";
import { AuditFinancialTableRow } from "./AuditFinancialTableRow";
import { AuditFineTableRow } from "./AuditFineTableRow";
import { AuditMaintenanceTableRow } from "./AuditMaintenanceTableRow";
import { AuditOperationalTableRow } from "./AuditOperationalTableRow";
import { AuditQualityIsoTableRow } from "./AuditQualityIsoTableRow";
import { AuditQualityVisaTableRow } from "./AuditQualityVisaTableRow";
import { AuditRhLlmTableRow } from "./AuditRhLlmTableRow";
import { AuditRhTableRow } from "./AuditRhTableRow";
import { AuditStatus } from "./AuditStatus";
import { RealizeAudit } from "./RealizeAudit";

interface AuditDetailProps {
  auditId: string
}

export function AuditDetail({
  auditId
}: AuditDetailProps) {

  const { userLogged } = useAuth()

  const userCanCompleteAudit = userLogged?.permissions?.includes('complete-audit')

  const {
    data: auditResult,
    isLoading: isLoadingAuditResult
  } = useQuery({
    queryKey: ['audit', auditId],
    queryFn: () => getAudit({ routeParams: { auditId } })
  })

  return (
    <ModalContent>
      <ModalHeader>
        Detalhes da Auditoria
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Box
          maxH="550px"
          overflowY="scroll"
          p={3}
        >
          <Grid
            templateColumns={
              userCanCompleteAudit && auditResult?.audit.status === 'pending'
                ? "1fr auto 1fr"
                : "1fr"
            }
            gap={6}
          >
            <GridItem>

              <Table size="sm">
                <Tbody>
                  {isLoadingAuditResult ? (
                    Array.from({ length: 10 }).map((_, index) => {
                      return (
                        <Tr>
                          <Td>
                            <Skeleton w="full" h="16px" />
                          </Td>
                          <Td isNumeric>
                            <Skeleton w="full" h="16px" />
                          </Td>
                        </Tr>
                      )
                    })
                  ) : (
                    <>
                      <Tr>
                        <Td>Data programada</Td>
                        <Td isNumeric>{format(new Date(auditResult?.audit?.scheduled_date), 'dd/MM/yyyy')}</Td>
                      </Tr>
                      <Tr>
                        <Td>Tipo de Auditoria</Td>
                        <Td isNumeric>{auditResult?.audit.type}</Td>
                      </Tr>
                      <Tr>
                        <Td>Cliente</Td>
                        <Td isNumeric>{captalize(auditResult?.audit.customer.trading_firstname)}</Td>
                      </Tr>
                      <Tr>
                        <Td>Colaborador</Td>
                        <Td isNumeric>{auditResult?.audit.collaborator ? captalize(auditResult?.audit.collaborator) : '-'}</Td>
                      </Tr>
                      <Tr>
                        <Td>Status</Td>
                        <Td isNumeric>
                          <Flex justify="end">
                            <AuditStatus status={auditResult?.audit.status} />
                          </Flex>
                        </Td>
                      </Tr>
                      {auditResult?.audit.realized_date && (
                        <Tr>
                          <Td>Data realizada</Td>
                          <Td isNumeric>{format(new Date(auditResult?.audit?.realized_date), 'dd/MM/yyyy')}</Td>
                        </Tr>
                      )}
                      {auditResult?.audit.non_compliance && (
                        <Tr>
                          <Td>Não conformidade</Td>
                          <Td isNumeric>{auditResult?.audit?.non_compliance}</Td>
                        </Tr>
                      )}
                      {auditResult?.audit?.type === 'COMERCIAL' && auditResult?.audit.status !== 'pending' && (
                        <AuditCommercialTableRow
                          audit={auditResult}
                        />
                      )}

                      {auditResult?.audit?.type === 'RH' && auditResult?.audit.status !== 'pending' && (
                        <AuditRhTableRow
                          audit={auditResult}
                        />
                      )}

                      {auditResult?.audit?.type === 'RH LLM' && auditResult?.audit.status !== 'pending' && (
                        <AuditRhLlmTableRow
                          audit={auditResult}
                        />
                      )}

                      {auditResult?.audit?.type === 'QUALIDADE VISA' && auditResult?.audit.status !== 'pending' && (
                        <AuditQualityVisaTableRow
                          audit={auditResult}
                        />
                      )}

                      {auditResult?.audit?.type === 'QUALIDADE ISO' && auditResult?.audit.status !== 'pending' && (
                        <AuditQualityIsoTableRow
                          audit={auditResult}
                        />
                      )}
                      {auditResult?.audit?.type === 'MANUTENÇÃO' && auditResult?.audit.status !== 'pending' && (
                        <AuditMaintenanceTableRow
                          audit={auditResult}
                        />
                      )}

                      {auditResult?.audit?.type === 'MULTAS' && auditResult?.audit.status !== 'pending' && (
                        <AuditFineTableRow
                          audit={auditResult}
                        />
                      )}

                      {auditResult?.audit?.type === 'FINANCEIRA' && auditResult?.audit.status !== 'pending' && (
                        <AuditFinancialTableRow
                          audit={auditResult}
                        />
                      )}

                      {auditResult?.audit?.type === 'CAMPO' && auditResult?.audit.status !== 'pending' && (
                        <AuditFieldTableRow
                          audit={auditResult}
                        />
                      )}

                      {auditResult?.audit?.type === 'OPERACIONAL' && auditResult?.audit.status !== 'pending' && (
                        <AuditOperationalTableRow
                          audit={auditResult}
                        />
                      )}
                    </>
                  )}
                </Tbody>
              </Table>
            </GridItem>
            {userCanCompleteAudit && auditResult?.audit.status === 'pending' && (
              <>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <GridItem>
                  <RealizeAudit
                    audit={auditResult?.audit}
                  />
                </GridItem>
              </>
            )}
          </Grid>

        </Box>
      </ModalBody>
    </ModalContent>
  )
}

import { createContext, ReactNode, useEffect } from "react";
import { useQueryClient } from "react-query";
import { Socket } from "socket.io-client";
import { socketApi } from "../services/socket/socket";

interface ISocketContextProps {
  packetSocketConnection: Socket
}

interface ISocketProviderProps {
  children: ReactNode
}

export const SocketContext = createContext({} as ISocketContextProps)

export function SocketProvider({ children }: ISocketProviderProps) {

  const packetSocketConnection = socketApi.connect("packet")

  const queryClient = useQueryClient()

  useEffect(() => {
    packetSocketConnection.on("connect", () => {
      console.log("Socket connected")
    })

    packetSocketConnection.on("getNotification", async () => {
      queryClient.invalidateQueries({ queryKey: ['user-notifications']})
    })
  }, [packetSocketConnection, queryClient])

  return (
    <SocketContext.Provider value={{ packetSocketConnection }}>
      {children}
    </SocketContext.Provider>
  )
}

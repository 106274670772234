import { useEffect } from "react";
import { usePagination } from "@ajna/pagination";
import { useForm, useWatch } from "react-hook-form";
import { useOccurrences } from "hooks/occurrences/useOccurrences";
import { searchBoxFilter } from "utils/searchBoxFilter";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { SearchBox } from "components/SearchBox/SearchBox";

import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { Box, Button, Flex, Heading, Icon, Spinner, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Pagination } from "components/Pagination/Pagination";
import { FaPen } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { useUsers } from "hooks/user/useUsers";

interface AwaitingAlignmentOccurrencesFilterInputs {
  occurrenceProtocol: string
  serviceProtocol: string
  customer: string
  intercurrence: string
}

const awaitingAligmentOccurrencesFilterOptions = [
  { key: 'occurrenceProtocol', value: 'Protocolo (Ocorrência)', checked: false },
  { key: 'serviceProtocol', value: 'Protocolo (Serviço)', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'intercurrence', value: 'Intercorrência', checked: false },
  { key: "owner", value: "Aberta por", checked: false },
]

export function AwaitingAlignmentOccurrencesList() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()

  const { push: redirect } = useHistory()
  const { data: users, isFetching: isFetchingUsers } = useUsers()

  useEffect(() => {
    if (!userLogged?.permissions.includes('align-customer-occurrence')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const { filterOptions, onLoadSetFilterOptions } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(awaitingAligmentOccurrencesFilterOptions)
  }, [onLoadSetFilterOptions])

  const {
    control,
    register
  } = useForm<AwaitingAlignmentOccurrencesFilterInputs>()

  const {
    data: occurrences,
    isFetching: isFetchingOccurrences
  } = useOccurrences({
    queryParams: { step: 'AWAITING-ALIGNMENT' }
  })

  const [
    occurrenceProtocolFiltered,
    serviceProtocolFiltered,
    customerFiltered,
    intercurrenceFiltered
  ] = useWatch({
    control,
    name: ['occurrenceProtocol', 'serviceProtocol', 'customer', 'intercurrence']
  })

  const occurrencesFiltered = occurrences?.filter(occurrence => {
    const occurrenceProtocolFilter = occurrenceProtocolFiltered
      ? searchBoxFilter(String(occurrence.occurrence_protocol), occurrenceProtocolFiltered)
      : occurrence.occurrence_protocol

    const serviceProtocolFilter = serviceProtocolFiltered
      ? searchBoxFilter(String(occurrence.occurrenceIDService.protocol), serviceProtocolFiltered)
      : occurrence.occurrenceIDService.protocol

    const customerFilter = customerFiltered
      ? searchBoxFilter(occurrence.occurrenceIDCustomer.trading_firstname, customerFiltered)
      : occurrence.occurrenceIDCustomer.trading_firstname

    const intercurrenceFilter = intercurrenceFiltered
      ? searchBoxFilter(occurrence.intercurrence, intercurrenceFiltered)
      : occurrence.intercurrence

    return occurrenceProtocolFilter && serviceProtocolFilter && customerFilter && intercurrenceFilter
  })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: occurrencesFiltered?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setCurrentPage(1)
    }, 500)

    return () => clearTimeout(debounceTimeout)
  }, [
    setCurrentPage,
    occurrenceProtocolFiltered,
    serviceProtocolFiltered,
    customerFiltered,
    intercurrenceFiltered
  ])

  const handlePageChange = (page: number) => setCurrentPage(page)

  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >

        <Flex
          w="full"
          direction="column"
          gap={6}
        >

          <Heading fontSize="xl">Ocorrências para alinhamento</Heading>

          <Flex
            w='full'
            direction={['column', 'column', 'row']}
            gap={2}
            justify='space-between'
          >
            <Flex>
              <TableFilterButton />
            </Flex>
            <Stack direction='column'>
              {
                filterOptions
                  .filter(option => option.checked)
                  .map(option => {
                    return (
                      <SearchBox
                        {...register(option.key as keyof AwaitingAlignmentOccurrencesFilterInputs)}
                        key={option.key}
                        size="sm"
                        placeholder={'Buscar ' + option.value}
                        handleSearch={() => { }}
                      />
                    )
                  })
              }
            </Stack>
          </Flex>
          {isFetchingOccurrences && isFetchingUsers ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table variant='striped' size='sm'>
                <Thead>
                  <Tr>
                    {awaitingAligmentOccurrencesFilterOptions?.map(option => {
                      return (
                        <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                      )
                    })}
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {occurrencesFiltered?.slice(offset, offset + Number(itemLimit))?.map(occurrence => {
                    const occurrenceOwner = users?.filter(user => user.id === occurrence.owner).map(user => `${user.firstname} ${user.lastname}`)
                    return (
                      <Tr key={occurrence.id}>
                        <Td>{occurrence.occurrence_protocol}</Td>
                        <Td>{occurrence.occurrenceIDService.protocol}</Td>
                        <Td>{occurrence.occurrenceIDCustomer.trading_firstname}</Td>
                        <Td>{occurrence.intercurrence}</Td>
                        <Td>{occurrenceOwner}</Td>
                        <Td>
                          <Button
                            as={Link}
                            variant='ghost'
                            to={`/ocorrencia/alinhar/${occurrence.id}`}
                          >
                            <Icon as={FaPen} />
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}

          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </Flex>
      </Box>

  )
}

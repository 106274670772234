import { Flex, Select, Button, Icon, Text, Input } from "@chakra-ui/react";
import { ExperiencePeriodStatus } from "api/experiencePeriods/types/ExperiencePeriod";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface ExperiencePeriodTableFilterSchema {
  status: ExperiencePeriodStatus | 'all'
  name?: string
}

export function ExperiencePeriodTableFilters() {
  const { replace } = useHistory()
  const searchParams = useSearchParams()

  const status = searchParams.get('status') ?? 'all'
  const name = searchParams.get('name')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<ExperiencePeriodTableFilterSchema>({
    defaultValues: {
      name: name ?? '',
      status: status as ExperiencePeriodStatus | 'all'
    }
  })

  function handleSearchPmcs({ status, name }: ExperiencePeriodTableFilterSchema) {
    if (status) {
      searchParams.set('status', status)
    } else {
      searchParams.delete('status')
    }
    if (name) {
      searchParams.set('name', name)
    } else {
      searchParams.delete('name')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('status')
    searchParams.delete('name')

    reset({ status: 'all' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      as="form"
      align="center"
      direction={["column", "column", "row"]}
      gap="2"
      mt="4"
      onSubmit={handleSubmit(handleSearchPmcs)}
    >
      <Text alignSelf={["flex-start", "flex-start", "center"]} fontSize="sm">
        Filtros:
      </Text>

      <Input
        {...register("name")}
        placeholder="Nome"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register('status')}
        defaultValue="all"
        size="sm"
        borderRadius="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="active">Ativo</option>
        <option value="validating-45-days">À validar 45 dias</option>
        <option value="validating-90-days">À validar 90 dias</option>
        <option value="dismissing">Demissão solicitada</option>
      </Select>

      <Button
        type="submit"
        size="sm"
        leftIcon={<Icon as={FaSearch} />}
        w={['full', 'full', 'min']}
      >
        Filtrar resultados
      </Button>

      <Button
        type="submit"
        size="sm"
        variant="outline"
        leftIcon={<Icon as={FaTimes} />}
        w={['full', 'full', 'min']}
        onClick={handleRemoveFilters}
      >
        Remover filtros
      </Button>


    </Flex>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { ActiveFeedback, ActiveFeedbackStatus } from "./_types/ActiveFeedback";

export interface GetActivesFeedbacksParams {
  pageSize: number
  currentPage: number
  status?: ActiveFeedbackStatus | 'all'

}

export interface GetActivesFeedbacksResponse {
  activesFeedbacks: ActiveFeedback[]
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
    count: number
  }
}


export async function getActivesFeedbacks({
  currentPage,
  pageSize,
  status,

}: GetActivesFeedbacksParams) {
  try {
    const response = await api.get<GetActivesFeedbacksResponse>('/feedbacks/actives', {
      params: {
        pageSize,
        currentPage,
        status,
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

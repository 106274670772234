import { Flex } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { useFormContext } from "react-hook-form";
import { situation } from "utils/customLists";

interface WarehouseFormProps {
  slug?: string
}

export interface WarehouseFormInputs {
  situation: string
  name: string
}

export function WarehouseForm({ slug }: WarehouseFormProps) {
  const {
    register,
    formState: {
      errors
    }
  } = useFormContext<WarehouseFormInputs>()

  return (
    <Flex align="center" gap={6}>
      {slug === 'editar' && (
        <Select
          {...register('situation')}
          name="situation"
          label="Situação"
          error={errors.situation}
          situations={situation}
          placeholder="Selecione uma opção..."
          required
        />
      )}
      <Input
        {...register("name")}
        name="name"
        placeholder="Digite o nome do almoxarifado..."
        label="Nome"
        error={errors.name}
        required
      />
    </Flex>
  )
}

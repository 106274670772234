import { api } from '../../../../services/api'


interface IAssignDriverInputProps {
  driver_id: string
  address_id: string
}

interface IAssignDriverProviderInputProps {
  driver_id: string
  provider_id: string
}

export type CreateRecurrentServiceInputProps = {
  customer_id: string
  situation: string
  budget_id: string
  owner: string
  source_address_id: string[]
  destination_address_id: string[]
  source_collector_id: string | null
  destination_collector_id: string | null
  source_branch_id: string | null
  destination_branch_id: string | null
  provider_id: string | null
  availability_forecast_deadline: number | null
  availability_forecast_day: string | null
  availability_forecast_time: string | null
  board_hour: string | null
  board_date: number | null
  planned_flight: string | null
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collect_date: string | null
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string | null
  delivery_hour: string
  days_of_week: number[]
  material_type: string
  driver_address_assign: IAssignDriverInputProps[]
  driver_provider_assign: IAssignDriverProviderInputProps[]
  destination_addresses_input:
    | {
        address_id: string
        quantity: number
      }[]
    | null
  observation: string
}

export type RecurrentServiceInputProps = {
  situation: string
  customer_id: string
  budget_id: string
  caixa_termica: number
  collect_date: string | null
  delivery_date: string | null
  collect_hour_end: string
  collect_hour_start: string
  days_of_week: number[]
  deadline: number
  delivery_hour: string
  board_hour: string | null
  board_date: number | null
  destination_address_id: string[]
  embalagem_secundaria: number
  franchising: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  modal: string
  observation: string
  service_type: string
  source_address_id: string[]
  terciaria3l: number
  terciaria8l: number
  material_type: string
  driver_address_assign: IAssignDriverInputProps[]
  driver_provider_assign: IAssignDriverProviderInputProps[]
  destination_addresses_input:
    | {
        address_id: string
        quantity: number
      }[]
    | null
  vehicle: string
}

export type EditRecurrentServiceProps = {
  recurrentServiceID: string
  recurrentServiceInput: RecurrentServiceInputProps
}

const createRecurrentServiceReqFunction = (
  createRecurrentServiceInput: CreateRecurrentServiceInputProps,
) =>
  api
    .post('/recurrent-service', createRecurrentServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const editRecurrentServiceReqFunction = ({
  recurrentServiceID,
  recurrentServiceInput,
}: EditRecurrentServiceProps) =>
  api
    .put(`/recurrent-service/${recurrentServiceID}`, recurrentServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

export { editRecurrentServiceReqFunction, createRecurrentServiceReqFunction }

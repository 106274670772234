import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AlignRncForm } from '../../components/Form/Rnc/AlignRncForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useAuth } from '../../hooks/auth/useAuth'
import { useRncFunctions } from '../../hooks/rnc/useRncFunctions'
import { useSwal } from '../../hooks/swal/useSwal'

interface IFormInputProps {
  alignment_observation: string
  alignment_date: string
}

interface IQueryParams {
  rncID: string
}

export function AlignRncPage() {
  const [isLoading, setIsLoading] = useState(false)

  const {
    alignRnc: { mutateAsync: alignRnc },
  } = useRncFunctions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()
  const { rncID }: IQueryParams = useParams()

  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('add-rnc')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const handleAlignRnc = async (values: IFormInputProps) => {
    const newValues = {
      rncId: rncID,
      hasUpdateAlignment: true,
      alignRncInput: {
        alignment_observation: values.alignment_observation,
        alignment_date: values.alignment_date,
      },
    }

    const hasAlignRnc = await confirmMessage({
      title: 'Deseja finalizar um RNC?',
    })

    if (hasAlignRnc) {
      setIsLoading(true)
      try {
        const alignRncResponse = await alignRnc(newValues)
        if (alignRncResponse) redirectTo('/rnc/pendentes-alinhamento')
      } catch {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (
    <AlignRncForm title="Alinhar Rnc" rncID={rncID} submit={handleAlignRnc} />
  )
}

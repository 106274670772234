import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverDismissLegalPreventionDetailsBody {
  dismissDate: string
  doesEmployeeHaveNoStabilityOfJob: string
  doesOperationLeaderKnownAboutDismiss: boolean
  hasConferredEmployeeHasPendingDocumentsWithCompany: boolean
  hasConferredPointRegisters: boolean
  legalPreventionDocumentsChecklist: string[]
}

interface SendCltDriverDismissLegalPreventionDetailsProps {
  body: SendCltDriverDismissLegalPreventionDetailsBody
  cltDriverId: string
}

export async function sendCltDriverDismissLegalPreventionDetails({
  body,
  cltDriverId
}: SendCltDriverDismissLegalPreventionDetailsProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/dismiss/legal-prevention-details`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

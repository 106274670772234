import { Button, Flex, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { deleteDocument } from "api/documents/deleteDocument";
import { GetDocumentsResponse } from "api/documents/getDocuments";
import { inactivateDocument } from "api/documents/inactivateDocument";
import { Document } from "api/documents/types/Document";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { RiSearchLine } from "react-icons/ri";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { DocumentDetail } from "./DocumentDetail";
import { DocumentStatus } from "./DocumentStatus";


interface DocumentsTableRowProps {
  document: Document
}

export function DocumentsTableRow({ document }: DocumentsTableRowProps) {

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanViewDocuments = userLogged?.permissions?.includes('view-documents')

  const userCanInactiveDocument = userLogged?.permissions?.includes('inactive-document')
  const userCanDeleteDocument = userLogged?.permissions?.includes('delete-document')

  useEffect(() => {
    if (!userCanViewDocuments) history.push('/')
  }, [history, userCanViewDocuments])

  const {
    isOpen: isDocumentDetailModalOpen,
    onOpen: onOpenDocumentDetailModal,
    onClose: onCloseDocumentDetailModal,
  } = useDisclosure()


  const { mutateAsync: inactivateDocumentFn } = useMutation({
    mutationFn: inactivateDocument,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetDocumentsResponse>({
        queryKey: ['documents']
      })

      queryClient.invalidateQueries({ queryKey: 'non-evaluated-documents' })
      queryClient.invalidateQueries({ queryKey: 'expired-documents' })
      queryClient.invalidateQueries({ queryKey: 'active-documents' })
      queryClient.invalidateQueries({ queryKey: 'inactive-documents' })
      queryClient.invalidateQueries({ queryKey: ['document', routeParams?.documentId] })

      cached.forEach(([cachedKey, cachedValue]) => {
        if (!cachedValue) return

        queryClient.setQueryData(cachedKey, {
          ...cachedValue,
          documents: cachedValue.documents.map((document) => {
            if (document.id === routeParams.documentId) {
              return { ...document, status: 'inactive' }
            }

            return document
          })
        })
      })
    }
  })

  async function handleInactivateDocument() {
    await promiseMessage(inactivateDocumentFn({
      routeParams: {
        documentId: document.id
      }
    }), 'Documento inativado!')
  }
  const { mutateAsync: deleteDocumentFn } = useMutation({
    mutationFn: deleteDocument,
    onSuccess(_data, { documentId }) {
      const cachedDocuments = queryClient.getQueriesData<GetDocumentsResponse>({
        queryKey: ['documents']
      })

      queryClient.invalidateQueries({ queryKey: 'non-evaluated-documents' })
      queryClient.invalidateQueries({ queryKey: 'expired-documents' })
      queryClient.invalidateQueries({ queryKey: 'active-documents' })
      queryClient.invalidateQueries({ queryKey: 'inactive-documents' })
      queryClient.invalidateQueries({ queryKey: ['document', documentId] })

      cachedDocuments.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          documents: cachedData.documents.filter((cachedDocument) => {
            return cachedDocument.id !== documentId
          })
        })

      })
    },
  })

  async function handleDeleteDocument() {
    await promiseMessage(deleteDocumentFn({
      documentId: document?.id
    }), 'Documento excluido!')
  }


  return (
    <Tr>
      <Td>
        <Modal
          isOpen={isDocumentDetailModalOpen}
          onClose={onCloseDocumentDetailModal}
          size='6xl'
        >
          <ModalOverlay />

          <DocumentDetail documentId={document.id} />

        </Modal>
        <IconButton
          aria-label="Detalhes do documento"
          icon={<RiSearchLine />}
          onClick={onOpenDocumentDetailModal}
          size="sm"
        />
      </Td>
      <Td>{document.name}</Td>
      <Td>{document.document_type}</Td>
      <Td>{document.classification ?? '-'}</Td>
      <Td>
        <DocumentStatus status={document.status} />
      </Td>
      <Td>
        <Flex align="center" gap="2">

          {(userCanInactiveDocument && document.status !== 'inactive') && (
            <Button
              leftIcon={<FaTimes />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={handleInactivateDocument}
            >
              Inativar
            </Button>
          )}

          {userCanDeleteDocument && (
            <Button
              leftIcon={<FaTimes />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={handleDeleteDocument}
            >
              Excluir
            </Button>
          )}


        </Flex>
      </Td>
    </Tr>
  )
}

import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { useServiceFunctions } from "hooks/services/useServiceFunctions";
import { useServiceSocket } from "hooks/socket/useServiceSocket";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { useForm } from "react-hook-form";
import * as yup from "yup"

interface RequestCancelServiceFormInputs {
  cancel_reason: 'CLIENTE SOLICITOU CANCELAMENTO' | 'PACIENTE NÃO COMPARECEU' |
  'PACIENTE NÃO COMPARECEU' | 'SERVIÇO ABERTO INCORRETAMENTE'
  cancel_observation: string
}

interface RequestCancelServiceModalProps {
  isOpen: boolean
  onClose: () => void
  serviceId: string
  serviceProtocol: number
}

const cancelReasonOptions = [
  { key: '0', value: 'CLIENTE SOLICITOU CANCELAMENTO', showOption: 'CLIENTE SOLICITOU O CANCELAMENTO' },
  { key: '1', value: 'PACIENTE NÃO COMPARECEU', showOption: 'PACIENTE NÃO COMPARECEU' },
  { key: '2', value: 'PACIENTE NÃO COMPARECEU', showOption: 'SERVIÇO DUPLICADO' },
  { key: '3', value: 'SERVIÇO ABERTO INCORRETAMENTE', showOption: 'SERVIÇO ABERTO INCORRETAMENTE' },
]

const schema = yup.object().shape({
  cancel_reason: yup.string().required('Campo obrigatório'),
  cancel_observation: yup.string(),
})

export function RequestCancelServiceModal({
  isOpen,
  onClose,
  serviceId,
  serviceProtocol,
}: RequestCancelServiceModalProps) {

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<RequestCancelServiceFormInputs>({
    resolver: yupResolver(schema)
  })

  const { serviceSocketConnection } = useServiceSocket()

  const { promiseMessage } = useToastify()
  const { confirmMessage, standardMessage } = useSwal()

  const {
    requestCancelService: { mutateAsync: requestCancelService }
  } = useServiceFunctions()

  async function handleRequestCancelService({
    cancel_reason,
    cancel_observation
  }: RequestCancelServiceFormInputs) {
    const hasRequestCancelService = await confirmMessage({
      title: 'Deseja solicitar o cancelamento do serviço?'
    })

    const input = {
      cancel_reason,
      cancel_observation
    }

    if (hasRequestCancelService) {
      await promiseMessage(requestCancelService({
        serviceId,
        input
      }, {
        onSuccess: () => {
          serviceSocketConnection?.emit('requestedCancelService', {
            service_protocol: serviceProtocol
          })

          onClose()
        }
      }), "Solicitação enviada com sucesso!")

    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        as='form'
        onSubmit={handleSubmit(handleRequestCancelService)}
        noValidate
      >
        <ModalHeader>Solicitar cancelamento de serviço</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex gap={4} direction='column'>
            <Select
              {...register('cancel_reason')}
              name='cancel_reason'
              label='Motivo do cancelamento'
              placeholder='Selecione uma opção...'
              options={cancelReasonOptions}
              error={errors.cancel_reason}
              required
            />

            <TextArea
              {...register('cancel_observation')}
              name='cancel_observation'
              label='Observações'
            />
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex gap={2}>
            <Button
              onClick={onClose}
              colorScheme='gray'
            >
              Fechar
            </Button>
            <Button
              type='submit'
              colorScheme='blue'
            >
              Salvar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>

    </Modal >
  )
}

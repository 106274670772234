import { Flex, Select, Button, Icon, Text } from "@chakra-ui/react";
import { AdvertenceStatus } from "api/advertences/types/Advertence";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface AdvertenceTableFilterSchema {
  status: AdvertenceStatus | 'all'
}

export function AdvertenceTableFilters() {
  const { replace } = useHistory()
  const searchParams = useSearchParams()

  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<AdvertenceTableFilterSchema>({
    defaultValues: {
      status: status as AdvertenceStatus | 'all'
    }
  })

  function handleSearchPmcs({ status }: AdvertenceTableFilterSchema) {
    if (status) {
      searchParams.set('status', status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('status')

    reset({ status: 'all' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  return (
    <Flex
      as="form"
      align="center"
      direction={["column", "column", "row"]}
      gap="2"
      mt="4"
      onSubmit={handleSubmit(handleSearchPmcs)}
    >
      <Text alignSelf={["flex-start", "flex-start", "center"]} fontSize="sm">
        Filtros:
      </Text>

      <Select
        {...register('status')}
        defaultValue="all"
        size="sm"
        borderRadius="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="awaiting-application">Aguardando aplicação</option>
        <option value="parent-verification">Aguardando verificação da matriz</option>
        <option value="cancelled">Cancelado</option>
        <option value="done">Concluído</option>
      </Select>

      <Button
        type="submit"
        size="sm"
        leftIcon={<Icon as={FaSearch} />}
        w={['full', 'full', 'min']}
      >
        Filtrar resultados
      </Button>

      <Button
        type="submit"
        size="sm"
        variant="outline"
        leftIcon={<Icon as={FaTimes} />}
        w={['full', 'full', 'min']}
        onClick={handleRemoveFilters}
      >
        Remover filtros
      </Button>


    </Flex>
  )
}

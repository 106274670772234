import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { useAuth } from "hooks/auth/useAuth";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from "hooks/toastify/useToastify";
import { ShippingCost } from "./dtos/ShippingCost";

interface ShippingCostResponse {
  shippingCosts: ShippingCost[]
  totalPages: number
}

interface ShippingCostQueryParams {
  pageSize: number
  currentPage?: number
  shipping?: string
  sourceBranch?: string
  destinationBranch?: string
}

type QueryOptions = UseQueryOptions<ShippingCostResponse, unknown, ShippingCostResponse, QueryKey>

interface UseShippingCostOptions {
  queryKey?: QueryKey
  queryParams?: ShippingCostQueryParams
  queryOptions?: QueryOptions
}

export function useShippingCosts(options?: UseShippingCostOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getShippingCosts(queryParams?: ShippingCostQueryParams) {
    try {
      const { data } = await api.get(`/shippings/costs`, {
        params: {
          ...queryParams
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response
        throw new RequestError(message, status)
      } else {
        throw new RequestError('Erro inesperado!')
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey ?? 'shipping-costs',
    { ...options?.queryParams }
  ], () => getShippingCosts(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

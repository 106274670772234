import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverMotorcycleDetailsBody {
  motorcycleBrand: string
  motorcycleModel: string
  motorcycleYear: string
  motorcycleColor: string
  motorcycleLicensePlate: string
  vehicleDocumentRegisterYear: string
}

interface SendCltDriverMotorcycleDetailsProps {
  body: SendCltDriverMotorcycleDetailsBody
  cltDriverId: string
}

export async function sendCltDriverMotorcycleDetails({
  body,
  cltDriverId
}: SendCltDriverMotorcycleDetailsProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/hire/motorcyle-details`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

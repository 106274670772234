import { Flex, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useRnc } from '../../../hooks/rnc/useRnc'
import { RncAlignAccordion } from '../../Accordions/Rnc/RncAlignAccordion'
import { ListButton } from '../../Buttons/ListButton'
import { SubmitButton } from '../../Buttons/SubmitButton'
import { StandardBackgroundForm } from '../../Form/StandardBackgroundForm'
import { Input } from '../../Inputs/Input'
import { TextArea } from '../../Inputs/TextInput'
import { GeneralContentLoading } from '../../Loading/GeneralContentLoading'

interface IFormInputProps {
  alignment_observation: string
  alignment_date: string
}

interface IRncFormProps {
  title: string
  rncID: string
  submit: (values: IFormInputProps) => Promise<void>
}

const schema = yup.object().shape({
  alignment_observation: yup.string().required('Campo Obrigatório'),
  alignment_date: yup.date().typeError('Campo Obrigatório'),
})

export function AlignRncForm({ title, submit, rncID }: IRncFormProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  const {
    rnc: { data: rnc, isLoading: isRncLoading },
  } = useRnc(rncID, false)

  useEffect(() => {
    setValue('alignment_observation', rnc?.alignment_observation!)
  }, [setValue, rnc])

  if (isRncLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(submit)} title={title}>
      {rnc && <RncAlignAccordion rnc={rnc} />}

      <Stack w="full" mt="4" direction="column" spacing="24px">
        <Input
          {...register('alignment_date')}
          name="alignment_date"
          label="Data de Alinhamento"
          type="date"
          error={errors.alignment_date}
          required
        />

        <TextArea
          {...register('alignment_observation')}
          name="alignment_observation"
          label="Observações/Alinhamento"
          error={errors.alignment_observation}
          required
        />
      </Stack>

      <Flex align="center" justify="flex-end" mt="4">
        <Stack direction="row">
          <SubmitButton action="Salvar" isSubmitting={isSubmitting} />
          <ListButton
            href="/rnc/pendentes-resposta"
            name="Lista de Rnc Pendentes"
          />
        </Stack>
      </Flex>
    </StandardBackgroundForm>
  )
}

import { usePagination } from "@ajna/pagination";
import { Spinner, Flex, Heading, Box, Divider, TableContainer, Table, Thead, Tr, Th, Tbody, Button, Popover, PopoverTrigger, IconButton, PopoverContent, PopoverArrow, PopoverBody, Input, FormControl, FormLabel } from "@chakra-ui/react";
import { getInCollectServices } from "api/service/getInCollectServices";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";

import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaFilter, FaMinus, FaPlus, FaRedo, FaSearch, FaTimes } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";

import { useHistory } from "react-router-dom";
import { CollectsTableRow } from "./components/CollectsTableRow";

interface CollectsFiltersSchema {
  protocol: { value: string }[]
  customer: { value: string }[]
  vehicle: { value: string }[]
  sourceAddress: { value: string }[]
  collectDate: { value: string }[]
  collectHour: { value: string }[]
  destinationAddress: { value: string }[]
  sourceCollector: { value: string }[]
  destinationCollector: { value: string }[]
}

export function Collects() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { push: redirect, replace } = useHistory()
  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const queryClient = useQueryClient()

  const page = searchParams.get('page') ?? '1'

  const userLoggedHasViewCollectsPermission = userLogged?.permissions.includes("view-collects")

  useEffect(() => {
    if (!userLoggedHasViewCollectsPermission) {
      redirect('/')
    }
  }, [userLoggedHasViewCollectsPermission, redirect])


  const protocol = searchParams.get('protocol')
  const customer = searchParams.get('customer')
  const vehicle = searchParams.get('vehicle')
  const sourceAddress = searchParams.get('sourceAddress')
  const collectDate = searchParams.get('collectDate')
  const collectHour = searchParams.get('collectHour')
  const destinationAddress = searchParams.get('destinationAddress')
  const sourceCollector = searchParams.get('sourceCollector')
  const destinationCollector = searchParams.get('destinationCollector')

  const {
    register,
    handleSubmit,
    reset,
    control
  } = useForm<CollectsFiltersSchema>({
    defaultValues: {
      protocol: [{ value: '' }],
      customer: [{ value: '' }],
      vehicle: [{ value: '' }],
      sourceAddress: [{ value: '' }],
      collectDate: [{ value: '' }],
      collectHour: [{ value: '' }],
      destinationAddress: [{ value: '' }],
      sourceCollector: [{ value: '' }],
      destinationCollector: [{ value: '' }],
    }
  })

  const {
    fields: protocolFields,
    append: appendProtocol,
    remove: removeProtocol
  } = useFieldArray({
    control,
    name: 'protocol'
  });

  const {
    fields: customerFields,
    append: appendCustomer,
    remove: removeCustomer
  } = useFieldArray({
    control,
    name: 'customer'
  });

  const {
    fields: vehicleFields,
    append: appendVehicle,
    remove: removeVehicle
  } = useFieldArray({
    control,
    name: 'vehicle'
  });

  const {
    fields: sourceAddressFields,
    append: appendSourceAddress,
    remove: removeSourceAddress
  } = useFieldArray({
    control,
    name: 'sourceAddress'
  });

  const {
    fields: collectDateFields,
    append: appendCollectDate,
    remove: removeCollectDate
  } = useFieldArray({
    control,
    name: 'collectDate'
  });

  const {
    fields: collectHourFields,
    append: appendCollectHour,
    remove: removeCollectHour
  } = useFieldArray({
    control,
    name: 'collectHour'
  });

  const {
    fields: destinationAddressFields,
    append: appendDestinationAddress,
    remove: removeDestinationAddress
  } = useFieldArray({
    control,
    name: 'sourceAddress'
  });

  const {
    fields: sourceCollectorFields,
    append: appendSourceCollector,
    remove: removeSourceCollector
  } = useFieldArray({
    control,
    name: 'sourceCollector'
  });

  const {
    fields: destinationCollectorFields,
    append: appendDestinationCollector,
    remove: removeDestinationCollector
  } = useFieldArray({
    control,
    name: 'destinationCollector'
  });

  const {
    data: inCollectServicesData,
    isLoading: isInCollectServicesDataLoading
  } = useQuery([
    'in-collect-services',
    page,
    customer,
    protocol,
    vehicle,
    sourceAddress,
    collectDate,
    collectHour,
    destinationAddress,
    sourceCollector,
    destinationCollector,
    userLogged?.collector_id
  ], () => getInCollectServices({
    currentPage: page,
    customer,
    pageSize: rowsPerPage,
    protocol,
    vehicle,
    sourceAddress,
    collectDate,
    collectHour,
    destinationAddress,
    sourceCollector,
    destinationCollector,
    timezoneOffset: String(new Date().getTimezoneOffset() / 60),
    collectorId: userLogged?.collector_id
  }), {
    refetchOnWindowFocus: false
  })

  function handleFilterCollects(data: CollectsFiltersSchema) {
    console.log(data.protocol)

    if (data.protocol) {
      searchParams.set('protocol', data.protocol.map(protocol => protocol.value).join(','))
    } else {
      searchParams.delete('protocol')
    }

    if (data.collectDate) {
      searchParams.set('collectDate', data.collectDate.map(collectDate => collectDate.value).join(','))
    } else {
      searchParams.delete('collectDate')
    }

    if (data.collectHour) {
      searchParams.set('collectHour', data.collectHour.map(collectHour => collectHour.value).join(','))
    } else {
      searchParams.delete('collectHour')
    }

    if (data.customer) {
      searchParams.set('customer', data.customer.map(customer => customer.value).join(','))
    } else {
      searchParams.delete('customer')
    }

    if (data.destinationAddress) {
      searchParams.set('destinationAddress', data.destinationAddress.map(address => address.value).join(','))
    } else {
      searchParams.delete('destinationAddress')
    }

    if (data.sourceAddress) {
      searchParams.set('sourceAddress', data.sourceAddress.map(address => address.value).join(','))
    } else {
      searchParams.delete('sourceAddress')
    }

    if (data.vehicle) {
      searchParams.set('vehicle', data.vehicle.map(vehicle => vehicle.value).join(','))
    } else {
      searchParams.delete('vehicle')
    }

    if (data.sourceCollector) {
      searchParams.set('sourceCollector', data.sourceCollector.map(collector => collector.value).join(','))
    } else {
      searchParams.delete('sourceCollector')
    }

    if (data.destinationCollector) {
      searchParams.set('destinationCollector', data.destinationCollector.map(collector => collector.value).join(','))
    } else {
      searchParams.delete('destinationCollector')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('customer')
    searchParams.delete('protocol')
    searchParams.delete('vehicle')
    searchParams.delete('sourceAddress')
    searchParams.delete('collectDate')
    searchParams.delete('collectHour')
    searchParams.delete('destinationAddress')
    searchParams.delete('sourceCollector')
    searchParams.delete('destinationCollector')

    searchParams.set('page', '1')

    reset({
      customer: [{ value: '' }],
      protocol: [{ value: '' }],
      vehicle: [{ value: '' }],
      sourceAddress: [{ value: '' }],
      collectDate: [{ value: '' }],
      collectHour: [{ value: '' }],
      destinationAddress: [{ value: '' }],
      sourceCollector: [{ value: '' }],
      destinationCollector: [{ value: '' }],
    })

    replace({ search: searchParams.toString() })

  }

  function handleUpdateTable() {
    queryClient.invalidateQueries(['in-collect-services'])
  }


  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: inCollectServicesData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: page ? Number(page) : 1,
      },
    });

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >

      <Flex
        w="full"
        direction="column"
        gap={6}
      >
        <Heading letterSpacing="tight">Coletas</Heading>
        <Divider />

        {isInCollectServicesDataLoading ? (
          <Spinner />
        ) : (
          <Box
            as="form"
            onSubmit={handleSubmit(handleFilterCollects)}
          >
            <Flex
              align="center"
              gap="2"
              mb="6"
            >
              <Button
                type="submit"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaSearch />}
              >
                Filtrar resultados
              </Button>

              <Button
                type="button"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaTimes />}
                variant="ghost"
                onClick={handleRemoveFilters}
              >
                Remover filtros
              </Button>

              <Button
                type="button"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaRedo />}
                variant="ghost"
                onClick={handleUpdateTable}
              >
                Atualizar página
              </Button>
            </Flex>

            <TableContainer
              fontSize="xs"
              rounded="md"
              border="1px solid"
              borderColor="gray.100"
            >
              <Table
                w="full"
                size="sm"
                variant="simple"
              >
                <Thead h="40px" bg="#38c3fa">
                  <Tr h="40px">
                    <Th></Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Protocolo
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de protocolo"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {protocolFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Protocolo</FormLabel>
                                  <Input
                                    {...register(`protocol.${index}.value` as const)}
                                    placeholder="Protocolo"
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeProtocol(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label="Adicionar protocolo"
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendProtocol({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Cliente
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de cliente"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {customerFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Cliente</FormLabel>
                                  <Input
                                    {...register(`customer.${index}.value` as const)}
                                    placeholder="Cliente"
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeCustomer(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label=""
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendCustomer({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Veículo
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de veículo"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {vehicleFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Veículo</FormLabel>
                                  <Input
                                    {...register(`vehicle.${index}.value` as const)}
                                    placeholder="Veículo"
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeVehicle(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label=""
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendVehicle({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Remetentes
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de remetentes"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {sourceAddressFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Endereço</FormLabel>
                                  <Input
                                    {...register(`sourceAddress.${index}.value` as const)}
                                    placeholder="Rua, Bairro, Número, etc."
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeSourceAddress(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label="Adicionar protocolo"
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendSourceAddress({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Data da coleta
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de data da coleta"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {collectDateFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Data</FormLabel>
                                  <Input
                                    {...register(`collectDate.${index}.value` as const)}
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                    type="date"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeCollectDate(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label=""
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendCollectDate({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Horário da coleta
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de horário da coleta"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {collectHourFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Horário</FormLabel>
                                  <Input
                                    {...register(`collectHour.${index}.value` as const)}
                                    size="sm"
                                    type="time"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeCollectHour(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label=""
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendCollectHour({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">EMBARQUE/ENTREGA</Th>
                    <Th textColor="white">INSUMOS</Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Destinatários
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de remetentes"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {destinationAddressFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Endereço</FormLabel>
                                  <Input
                                    {...register(`destinationAddress.${index}.value` as const)}
                                    placeholder="Rua, Bairro, Número, etc."
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeDestinationAddress(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label=""
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendDestinationAddress({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">OBSERVAÇÕES</Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Coletador de origem
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de coletador de origem"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {sourceCollectorFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Coletador</FormLabel>
                                  <Input
                                    {...register(`sourceCollector.${index}.value` as const)}
                                    placeholder="Nome fantasia"
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeSourceCollector(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label=""
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendSourceCollector({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>

                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Coletador de destino
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de coletador de destino"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {destinationCollectorFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Coletador</FormLabel>
                                  <Input
                                    {...register(`destinationCollector.${index}.value` as const)}
                                    placeholder="Nome fantasia"
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeDestinationCollector(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label=""
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendDestinationCollector({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {inCollectServicesData?.services.map(service => {
                    return (
                      <CollectsTableRow key={service.protocol.toString()} service={service} />
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>

          </Box>
        )}
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Flex>

    </Box>

  )
}

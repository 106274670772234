import { Box, FormControl, FormLabel, Input, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function AuthorizedOvertimeRequestForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      reasonForHE: 'Motivo da necessidade de HE',
      totalTime: 'Tempo total',
      heDate: 'Data da HE',
      employee: 'Funcionário',
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.reasonForHE} mt={3}>
        <FormLabel fontSize="sm">
          Motivo da necessidade de HE
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register('formData.reasonForHE.value')}
          name='formData.reasonForHE.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('reasonForHE')}
        />
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.totalTime}>
            <FormLabel fontSize='sm'>
              Tempo total

              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.totalTime.value')}
              name='formData.totalTime.value'
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('totalTime')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.heDate} >
            <FormLabel fontSize='sm'>
              Data da HE
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.heDate.value')}
              name='formData.heDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('heDate')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.employee} mt={3}>
        <FormLabel fontSize='sm'>
          Funcionário
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.employee.value')}
          name='formData.employee.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('employee')}
        />
      </FormControl>
    </Box>
  )

}

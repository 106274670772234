import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CreateRefundProviderBody {
  date: string
  description: string
  emailRequestAttachmentId: string
  occurrenceDate: string
  protocol: string
  providerId: string
  providerType: string
  requestAttachmentId: string
  type: string
  valueInCents: number
  invoiceNumber: number
}

interface CreateRefundProviderProps {
  body: CreateRefundProviderBody
}

export async function createRefundProvider({
  body
}: CreateRefundProviderProps) {
  try {
    const response = await api.post(`/refunds/provider`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EditAdvertenceFieldProps {
  routeParams: {
    advertenceId: string
  }
  body: {
    field: string
    value: string | boolean
  }
}

export async function editAdvertenceField({
  body,
  routeParams
}: EditAdvertenceFieldProps) {
  try {
    await api.patch(`/advertences/${routeParams.advertenceId}/edit`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

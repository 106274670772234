import { Box, FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";


export function PaymentInquiryForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value
    const labelMap: { [key in keyof FormDataItem]: string } = {
      questionOrSuggestion: 'Qual a dúvida ou sugestão?',
    };

    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.questionOrSuggestion} mt={3}>
        <FormLabel fontSize="sm">
          Qual a dúvida ou sugestão?
        </FormLabel>
        <Textarea
          {...register('formData.questionOrSuggestion.value')}
          name='formData.questionOrSuggestion.value'
          size='sm'
          rounded='md'
          onChangeCapture={handleInputChange('questionOrSuggestion')}
        />
      </FormControl>
    </Box>
  )

}

import axios from "axios"
import { useAuth } from "hooks/auth/useAuth"
import { useMutation, useQueryClient } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"
import { PurchaseOrder } from "./dtos/PurchaseOrder"

interface CreatePurchaseOrderInputProps {
  input: FormData
}

interface CreatePurchaseOrderResponse {
  purchaseOrder: PurchaseOrder
  notificationProfiles: string[]
}

async function createPurchaseOrderReqFunction({ input }: CreatePurchaseOrderInputProps) {
  try {
    const { data } = await api.post<CreatePurchaseOrderResponse>('/purchase-orders', input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


interface QuotePurchaseOrderReqFunctionProps {
  purchaseOrderId: string
  input: FormData
}

async function quotePurchaseOrderReqFunction({
  purchaseOrderId,
  input
}: QuotePurchaseOrderReqFunctionProps) {
  try {

    const { data } = await api.patch(`/purchase-orders/${purchaseOrderId}/quote`, input)

    return data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface ApprovePurchaseOrderInput {
  approvedProvider: string | null
}

interface ApprovePurchaseOrderReqFunctionProps {
  purchaseOrderId: string
  input: ApprovePurchaseOrderInput
}


async function approvePurchaseOrderReqFunction({
  purchaseOrderId,
  input
}: ApprovePurchaseOrderReqFunctionProps) {
  try {

    const { data } = await api.patch(`/purchase-orders/${purchaseOrderId}/approve`, input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface BuyPurchaseOrderReqFunctionProps {
  providerId: string
  purchaseOrderId: string
  input: FormData
}

async function buyPurchaseOrderReqFunction({
  providerId,
  purchaseOrderId,
  input
}: BuyPurchaseOrderReqFunctionProps) {
  try {

    await api.patch(`/providers/${providerId}/purchase-orders/${purchaseOrderId}/buy`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface ValidatePurchaseOrderInput {
  isFinishedCorrectly: 'yes' | 'no'
  hasRequestedFinancialPayment: 'yes' | 'no'
  hasConferredEquity: 'yes' | 'no'
}

interface ValidatePurchaseOrderReqFunctionProps {
  purchaseOrderId: string
  input: ValidatePurchaseOrderInput
}

async function validatePurchaseOrderReqFunction({
  purchaseOrderId,
  input
}: ValidatePurchaseOrderReqFunctionProps) {
  try {

    await api.patch(`/purchase-orders/${purchaseOrderId}/validate`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

interface UpdateInvoicePurchaseOrderReqFunctionProps {
  purchaseOrderId: string
  input: FormData
}

async function updateInvoicePurchaseOrderReqFunction({
  purchaseOrderId,
  input
}: UpdateInvoicePurchaseOrderReqFunctionProps) {
  try {

    await api.patch(`/purchase-orders/${purchaseOrderId}/invoice`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function deletePurchaseOrderReqFunction(purchaseOrderId: string) {
  try {
    await api.delete(`/purchase-orders/${purchaseOrderId}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

interface RefusePurchaseOrderInput {
  refuseDescription: string

}
interface RefusePurchaseOrderReqFunctionProps {
  purchaseOrderId: string
  input: RefusePurchaseOrderInput
}

async function refusePurchaseOrderReqFunction({
  purchaseOrderId,
  input
}: RefusePurchaseOrderReqFunctionProps) {
  try {

    await api.put(`/purchase-orders/${purchaseOrderId}/refuse`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

export function usePurchaseOrderFunctions() {
  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("purchaseOrders")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createPurchaseOrder = useMutation('createPurchaseOrder', createPurchaseOrderReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const quotePurchaseOrder = useMutation('quotePurchaseOrder', quotePurchaseOrderReqFunction, {
    onSuccess: onRequestSuccess,
  })

  const approvePurchaseOrder = useMutation('approvePurchaseOrder', approvePurchaseOrderReqFunction, {
    onSuccess: onRequestSuccess,
  })

  const buyPurchaseOrder = useMutation('buyPurchaseOrder', buyPurchaseOrderReqFunction, {
    onSuccess: onRequestSuccess,
  })

  const validatePurchaseOrder = useMutation('validatePurchaseOrder', validatePurchaseOrderReqFunction, {
    onSuccess: onRequestSuccess,
  })

  const updateInvoicePurchaseOrder = useMutation('updateInvoicePurchaseOrder', updateInvoicePurchaseOrderReqFunction, {
    onSuccess: onRequestSuccess,
  })

  const refusePurchaseOrder = useMutation('refusePurchaseOrder', refusePurchaseOrderReqFunction, {
    onSuccess: onRequestSuccess,
  })

  const deletePurchaseOrder = useMutation('deletePurchaseOrder',
    deletePurchaseOrderReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return {
    createPurchaseOrder,
    quotePurchaseOrder,
    approvePurchaseOrder,
    buyPurchaseOrder,
    validatePurchaseOrder,
    updateInvoicePurchaseOrder,
    deletePurchaseOrder,
    refusePurchaseOrder
  }
}








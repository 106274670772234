const searchBoxFilter = (property: string | null, field: string) => {
  if (property !== null) {
    return property
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(field.toLowerCase())
  }
  return false
}

export { searchBoxFilter }

import * as yup from "yup"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { useCrmFunctions } from "hooks/crms/useCrmFunctions"
import { useToastify } from "hooks/toastify/useToastify"
import { FormProvider, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { CrmForm, CrmFormInputs } from "./components/CrmForm"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Flex } from "@chakra-ui/react"
import { validateFileSize, validateHasFile } from "utils/imageValidation"
import { useAuth } from "hooks/auth/useAuth"
import { useEffect } from "react"
import { validateFileType } from "utils/fileValidation"

export const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/zip",
  "application/gzip",
  "application/x-zip-compressed",
  "application/x-rar-compressed",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
]


const createCrmSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório'),
  customerId: yup.string().required('Campo obrigatório'),
  meetingDate: yup.mixed().when('type', {
    is: 'REUNIÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
  meetingParticipants: yup.mixed().when('type', {
    is: 'REUNIÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
  meetingSummary: yup.mixed().when('type', {
    is: 'REUNIÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
  meetingAttachment: yup.mixed().when('type', {
    is: 'REUNIÃO',
    then: yup.mixed()
      .test('hasFile', 'Campo obrigatório', value => validateHasFile(value))
      .test('fileSize', 'É suportado o upload de imagem somente até 10Mb', value => validateFileSize(value)),
  }),
  invoiceNumber: yup.mixed().when('type', {
    is: 'INADIMPLÊNCIA',
    then: yup.string().required('Campo obrigatório'),
  }),
  originalInvoiceExpirationDate: yup.mixed().when('type', {
    is: 'INADIMPLÊNCIA',
    then: yup.string().required('Campo obrigatório'),
  }),
  paymentForecastDate: yup.mixed().when('type', {
    is: 'INADIMPLÊNCIA',
    then: yup.string().required('Campo obrigatório'),
  }),
  rncDate: yup.mixed().when('type', {
    is: 'RNC DE CLIENTE',
    then: yup.string().required('Campo obrigatório'),
  }),
  rncDescription: yup.mixed().when('type', {
    is: 'RNC DE CLIENTE',
    then: yup.string().required('Campo obrigatório'),
  }),
  rncAttachments: yup.mixed().when('type', {
    is: 'RNC DE CLIENTE',
    then: yup.mixed()
      .test('typeFile', 'Tipo de anexo não permitido', value => validateFileType(value,supportedFileFormats))
  }),
  noteDate: yup.mixed().when('type', {
    is: 'ANOTAÇÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
  noteDescription: yup.mixed().when('type', {
    is: 'ANOTAÇÃO',
    then: yup.string().required('Campo obrigatório'),
  }),
})

export function CreateCrm() {
  const formMethods = useForm<CrmFormInputs>({
    resolver: yupResolver(createCrmSchema)
  })

  const { userLogged } = useAuth()

  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('create-crm')) {
      redirect('/')
    }
  }, [redirect, userLogged])

  const { handleSubmit } = formMethods

  const {
    createCrm: { mutateAsync: createCrm }
  } = useCrmFunctions()

  const { promiseMessage } = useToastify()

  async function handleCreateCrm(values: CrmFormInputs) {
    let input: CrmFormInputs | FormData = { ...values }

    if (values.type === 'REUNIÃO') {
      const formData = new FormData()
      formData.append('type', values.type)
      formData.append('meetingDate', values.meetingDate)
      formData.append('meetingParticipants', values.meetingParticipants)
      formData.append('meetingSummary', values.meetingSummary)
      formData.append('meetingAttachment', values.meetingAttachment[0])

      input = formData
    }

    if (values.type === 'RNC DE CLIENTE') {
      const formData = new FormData()
      formData.append('type', values.type)
      formData.append('rncDate', values.rncDate)
      formData.append('rncDescription', values.rncDescription)

      if (values.rncAttachments) {
        Object.entries(values.rncAttachments).forEach(([key, file]) => {
          formData.append('rncAttachments', file)
        })
      }

      input = formData
    }

    await promiseMessage(createCrm({
      customerId: values.customerId,
      input
    }, {
      onSuccess: () => {
        redirect('/crms')
      }
    }), 'Crm criada com sucesso!')

  }

  return (
    <StandardBackgroundForm
      title='Criar Crm'
      onSubmit={handleSubmit(handleCreateCrm)}
    >
      <FormProvider {...formMethods}>
        <CrmForm slug='create' />
      </FormProvider>

      <Flex justify='flex-end' mt={4}>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Salvar
        </Button>
      </Flex>

    </StandardBackgroundForm>
  )
}

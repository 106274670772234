import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ApproveExecutiveLeaderActiveFeedbackBody {
  doesExecutiveLeaderKeepCollaborator: boolean
  doesExecutiveLeaderApproveCollaboratorPromotion: boolean
}

interface ApproveExecutiveLeaderActiveFeedbackProps {
  body: ApproveExecutiveLeaderActiveFeedbackBody
  activeFeedbackId: string

}
export async function approveExecutiveLeaderActiveFeedback({
  body,
  activeFeedbackId
}: ApproveExecutiveLeaderActiveFeedbackProps) {
  try {
    const response = await api.put(`/feedbacks/${activeFeedbackId}/actives/executive-leader-approve`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

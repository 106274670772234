import { Button, Icon, IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { Billing } from "api/billings/billing";
import { closeBilling } from "api/billings/closeBilling";
import { deleteBilling } from "api/billings/deleteBilling";
import { GetBillingsResponse } from "api/billings/getBillings";
import { receiveBilling } from "api/billings/receiveBilling";
import { format, getDate, parseISO } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FaArrowRight, FaPen, FaSearch, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { BillBilling } from "./BillBilling";
import { BillingDetail } from "./BillingDetail";
import { BillingStatus } from "./BillingStatus";
import { EditBilling } from "./EditBilling";

interface BillingsTableRowProps {
  billing: Billing & {
    customer: {
      trading_firstname: string
      day_expiration_1: string
      day_expiration_2: string
      payment_conditional: string
      billing_type: string
      payment_type: string
      cost_center: string
    }
  }
}

export function BillingsTableRow({
  billing
}: BillingsTableRowProps) {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const userHasViewBillingsPermission = userLogged?.permissions.includes('view-billings')

  useEffect(() => {
    if (!userHasViewBillingsPermission) {
      redirect('/')
    }
  }, [userHasViewBillingsPermission, redirect])

  const userHasBillBillingPermission = userLogged?.permissions.includes('bill-billing')
  const userHasReceiveBillingPermission = userLogged?.permissions.includes('receive-billing')
  const userHasCloseBillingPermission = userLogged?.permissions.includes('close-billing')
  const userHasEditBillingPermission = userLogged?.permissions.includes('edit-billing')

  const {
    isOpen: isBillingDetailModalOpen,
    onOpen: onOpenBillingDetailModal,
    onClose: onCloseBillingDetailModal,
  } = useDisclosure()

  const {
    isOpen: isBillBillingModalOpen,
    onOpen: onOpenBillBillingModal,
    onClose: onCloseBillBillingModal,
  } = useDisclosure()

  const {
    isOpen: isEditBillingModalOpen,
    onOpen: onOpenEditBillingModal,
    onClose: onCloseEditBillingModal,
  } = useDisclosure()

  const queryClient = useQueryClient()

  const { mutateAsync: closeBillingFn } = useMutation({
    mutationFn: closeBilling,
    onSuccess(data) {
      const cachedBillings = queryClient.getQueriesData<GetBillingsResponse>({
        queryKey: ['billings']
      })

      cachedBillings.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          billings: cachedData.billings.map((cachedBilling) => {
            if (cachedBilling.id === billing.id) {
              return {
                ...cachedBilling,
                status: data.billing.status
              }
            }

            return cachedBilling
          })
        })
      })
    },
  })

  const { mutateAsync: receiveBillingFn } = useMutation({
    mutationFn: receiveBilling,
    onSuccess(data) {
      const cachedBillings = queryClient.getQueriesData<GetBillingsResponse>({
        queryKey: ['billings']
      })

      cachedBillings.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          billings: cachedData.billings.map((cachedBilling) => {
            if (cachedBilling.id === billing.id) {
              return {
                ...cachedBilling,
                status: 'done'
              }
            }

            return cachedBilling
          })
        })
      })
    },
  })

  const { mutateAsync: deleteBillingFn } = useMutation({
    mutationFn: deleteBilling,
    onSuccess(_data, { billingId }) {
      const cachedBillings = queryClient.getQueriesData<GetBillingsResponse>({
        queryKey: ['billings']
      })

      cachedBillings.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) {
          return null
        }

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          billings: cachedData.billings.filter((cachedBilling) => {
            return cachedBilling.id !== billingId
          })
        })

      })
    },
  })

  const { promiseMessage } = useToastify()

  async function handleCloseBilling() {
    await promiseMessage(closeBillingFn({
      billingId: billing.id
    }), 'Faturamento finalizado! 🎉')
  }

  async function handleReceiveBilling() {
    await promiseMessage(receiveBillingFn({
      billingId: billing.id
    }), 'Faturamento recebido! 🎉')
  }

  async function handleDeleteBilling() {
    await promiseMessage(deleteBillingFn({
      billingId: billing.id
    }), 'Faturamento excluido!')
  }

  let dayExpirationInfo = '1'

  if (billing.customer.payment_conditional === 'FATURADO MENSAL') {
    dayExpirationInfo = `${billing.customer.day_expiration_1}`
  }

  if (billing.customer.payment_conditional === 'FATURADO QUINZENAL') {
    dayExpirationInfo = `${billing.customer.day_expiration_1}, ${billing.customer.day_expiration_2}`
  }

  if (billing.customer.payment_conditional === 'À VISTA') {
    dayExpirationInfo = getDate(new Date(billing.created_at)).toString()
  }

  const currentMonth = format(new Date(), 'MM')

  let billingDay = '-'

  if (billing.customer.billing_type === 'MENSAL') {
    billingDay = `01/${currentMonth}`
  }

  if (billing.customer.billing_type === 'QUINZENAL') {
    const billingCreatedDayOfMonth = getDate(
      new Date(billing.created_at)
    )

    const isBillingOnFirstPartOfTheMonth = billingCreatedDayOfMonth < 16

    if (isBillingOnFirstPartOfTheMonth) {
      billingDay = `01/${currentMonth}`
    } else {
      billingDay = `16/${currentMonth}`
    }
  }

  return (
    <Tr>
      <Td>
        <IconButton
          aria-label="Detalhes do faturamento"
          icon={<Icon as={FaSearch} />}
          variant="ghost"
          size="sm"
          onClick={onOpenBillingDetailModal}
        />

        <Modal
          isOpen={isBillingDetailModalOpen}
          onClose={onCloseBillingDetailModal}
          isCentered
        >
          <ModalOverlay />

          <BillingDetail billingId={billing.id} />
        </Modal>
      </Td>
      <Td>
        {userHasEditBillingPermission && (
          <>
            <IconButton
              aria-label="Editar faturamento"
              icon={<Icon as={FaPen} />}
              size="sm"
              variant="ghost"
              onClick={onOpenEditBillingModal}
              isDisabled={billing.status === 'awaiting-billing'}
            />

            <Modal
              isOpen={isEditBillingModalOpen}
              onClose={onCloseEditBillingModal}
              isCentered
            >
              <ModalOverlay />

              <EditBilling billingId={billing.id} onCloseModal={onCloseEditBillingModal} />
            </Modal>
          </>
        )}
      </Td>
      <Td>{billing.customer.trading_firstname}</Td>
      <Td>
        <BillingStatus status={billing.status} />
      </Td>
      <Td>{dayExpirationInfo}</Td>
      <Td>{
        billing?.due_date
          ? format(parseISO(billing?.due_date), 'dd/MM/yyyy')
          : '-'
      }</Td>
      <Td>{billingDay}</Td>
      <Td>{billing.customer.payment_type}</Td>
      <Td>{billing.customer?.cost_center}</Td>
      <Td>

        {(billing.status === 'awaiting-billing' && userHasBillBillingPermission) && (
          <Button
            size="xs"
            leftIcon={<Icon w={2.5} h={2.5} as={FaArrowRight} />}
            variant="outline"
            lineHeight="1"
            onClick={onOpenBillBillingModal}
          >
            Faturar
          </Button>
        )}

        {(billing.status === 'awaiting-receive' && userHasReceiveBillingPermission) && (
          <Button
            size="xs"
            leftIcon={<Icon w={2.5} h={2.5} as={FaArrowRight} />}
            variant="outline"
            lineHeight="1"
            onClick={handleReceiveBilling}
          >
            Receber
          </Button>
        )}

        {(billing.status === 'billed' && userHasCloseBillingPermission) && (
          <Button
            size="xs"
            leftIcon={<Icon w={2.5} h={2.5} as={FaArrowRight} />}
            variant="outline"
            lineHeight="1"
            onClick={handleCloseBilling}
          >
            Finalizar
          </Button>
        )}

        <Modal
          isOpen={isBillBillingModalOpen}
          onClose={onCloseBillBillingModal}
        >
          <ModalOverlay />

          <BillBilling onCloseModal={onCloseBillBillingModal} billingId={billing.id} />

        </Modal>
      </Td>
      <Td>
        <Button
          size="xs"
          leftIcon={<Icon as={FaTimes} />}
          variant="ghost"
          lineHeight="1"
          onClick={handleDeleteBilling}
        >
          Excluir
        </Button>
      </Td>
    </Tr>
  )
}

import { api } from '../../../../services/api'

export type CreateLandingServiceInputProps = {
  service_id: string
  collector_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  branch_id: string
  driver_id: string
}

export type FinishLandingServiceInputProps = {
  service_id: string
  collector_id: string
  landing_volume: number | null
  box_break: boolean | null
  cargo_photo: {} | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  observation: string
}

export type UpdateLandingServiceInputProps = {
  service_id: string
  collector_id: string
  landing_volume: number | null
  box_break: boolean | null
  cargo_photo: {} | null
  box_break_photos: File | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  observation: string
}

export type FinishLandingServiceReqProps = {
  serviceID: string
  finishLandingServiceInput: FinishLandingServiceInputProps
}

export type UpdateLandingServiceReqProps = {
  serviceID: string
  updateLandingServiceInput: UpdateLandingServiceInputProps
}

const createLandingServiceReqFunction = (
  createLandingServiceInput: CreateLandingServiceInputProps,
) =>
  api
    .post('/landing-service', createLandingServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const finishLandingServiceReqFunction = ({
  serviceID,
  finishLandingServiceInput,
}: FinishLandingServiceReqProps) =>
  api
    .put(`/landing-service/${serviceID}`, finishLandingServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

const updateLandingServiceReqFunction = ({
  serviceID,
  updateLandingServiceInput,
}: UpdateLandingServiceReqProps) =>
  api
    .put(`in-progress/landing-service/${serviceID}`, updateLandingServiceInput)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message)
    })

export {
  createLandingServiceReqFunction,
  finishLandingServiceReqFunction,
  updateLandingServiceReqFunction,
}

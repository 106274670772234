import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { BusinessBudgetProps } from "utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions";
import { BusinessCost } from "./businessCost";

interface GetBusinessCostsParams {
  pageSize: string
  currentPage: string
  customer?: string | null
  situation?: string | null
}

export interface GetBusinessCostsResponse {
  businessCosts: Array<BusinessCost & {
    businessBudget: BusinessBudgetProps
  }>
  meta: {
    currentPage: number
    pageSize: number
    totalPages: number
  }
}

export async function getBusinessCosts({
  pageSize,
  currentPage,
  customer,
  situation
}: GetBusinessCostsParams) {
  try {
    const { data } = await api.get<GetBusinessCostsResponse>('/business-costs', {
      params: {
        pageSize,
        currentPage,
        customer,
        situation,
      }
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


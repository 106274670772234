import { Box, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, IconButton, Input, InputGroup, InputLeftAddon, Radio, RadioGroup, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { InternCltBenefitName, InternCltStatus } from "api/internClts/_types/internClt";
import { InputMaskCustom } from "components/Inputs/InputMask";
import { useEffect } from "react";
import { useCurrency } from "react-hook-currency";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { BiDollar } from "react-icons/bi";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { getWeekDays } from "utils/getWeekDays";
import { handleSwitchMask } from "utils/handleChangeMask";
import { apiCep } from "../../../services/cepApiService/cepApi"
import { apiIbge } from "../../../services/ibgeApiservice/ibgeApi"
import AsyncSelect from 'react-select/async';
import { getInternCltsOffices } from "api/internClts/getInternCltsOffices";
import { internCltBenefitMap, internCltStatusMap } from "utils/internCltMappers";

export interface InternCltFormSchema {
  name: string
  status: InternCltStatus
  cpf: string
  rg: string
  bornDate: string
  addressStreet: string
  addressNumber: number
  addressNeighborhood: string
  addressComplement: string
  addressCity: string
  addressCep: string
  addressState: string
  email: string
  regional: 'sp' | 'rj' | 'mg' | 'df' | 'other'
  admissionDate: string
  salary: string
  office: {
    label: string
    value: string
  }
  hasLeadershipGratification: 'yes' | 'no'
  leadershipGratificationValue: string
  hasCommission: 'yes' | 'no'
  commissionDescription: string
  journeyType: 'days' | '12x36'
  workStartHour: string
  workEndHour: string
  workIntervalStartHour: string
  workIntervalEndHour: string
  saturdayWorkStartHour: string
  saturdayWorkEndHour: string
  saturdayWorkIntervalStartHour: string
  saturdayWorkIntervalEndHour: string
  sundayWorkStartHour: string
  sundayWorkEndHour: string
  sundayWorkIntervalStartHour: string
  sundayWorkIntervalEndHour: string
  workWeekDays: string[]
  benefits: InternCltBenefitName[]
  hasHealthInsuranceDependents: 'yes' | 'no'
  vrDailyValue: string
  vaDailyValue: string
  fuelMonthlyValue: string
  vtTypes: string[]
  phoneAssistanceValue: string
  vtDailyValue: string
}

interface InternCltFormProps {
  onOpenCreateInternCltOfficeModal: () => void
  isCepQueryEnabled?: boolean
  onSetCepQueryEnabled?: () => void
  slug: 'create' | 'edit'
}

const vtTypesOptions = [
  'RIOCARD',
  'TRANSFACIL',
  'OTIMO'
]

export function InternCltForm({
  onOpenCreateInternCltOfficeModal,
  isCepQueryEnabled = true,
  onSetCepQueryEnabled = () => { },
  slug
}: InternCltFormProps) {

  const {
    control,
    setValue,
    register,
    unregister,
    formState: { errors }
  } = useFormContext<InternCltFormSchema>()

  const [
    addressCep,
    addressState,
    hasLeadershipGratificationValue,
    hasCommissionValue,
    journeyType,
    benefits,
    workWeekDays
  ] = useWatch({
    name: [
      'addressCep',
      'addressState',
      'hasLeadershipGratification',
      'hasCommission',
      'journeyType',
      'benefits',
      'workWeekDays'
    ],
    control
  })

  const hasVrBenefit = benefits?.includes('vr')
  const hasVaBenefit = benefits?.includes('va')
  const hasHealthInsuranceBenefit = benefits?.includes('health-insurance')
  const hasFuelAidBenefit = benefits?.includes('fuel-aid')
  const hasVtBenefit = benefits?.includes('vt')
  const hasPhoneAssistanceBenefit = benefits?.includes('phone-assistance')

  const isWithSaturdayDayWork = workWeekDays?.includes('6')
  const isWithSundayDayWork = workWeekDays?.includes('0')

  useEffect(() => {
    if (slug === 'create') {
      setValue('status', 'hiring')
    }
  }, [setValue, slug])

  useEffect(() => {
    if (!hasVrBenefit) unregister('vrDailyValue')
    if (!hasVaBenefit) unregister('vaDailyValue')
    if (!hasFuelAidBenefit) unregister('fuelMonthlyValue')
    if (!hasHealthInsuranceBenefit) unregister('hasHealthInsuranceDependents')
    if (!hasPhoneAssistanceBenefit) unregister('phoneAssistanceValue')
  }, [hasVrBenefit, hasHealthInsuranceBenefit, unregister, hasVaBenefit, hasFuelAidBenefit, hasPhoneAssistanceBenefit])

  const hasLeadershipGratification = hasLeadershipGratificationValue === 'yes'
  const hasCommission = hasCommissionValue === 'yes'

  const weekDays = getWeekDays()

  const isDaysJourneyType = journeyType === 'days'

  const {
    data: cepData
  } = useQuery({
    queryKey: ['cep', addressCep],
    queryFn: () => apiCep.get(addressCep + '/json'),
    enabled: !!addressCep && addressCep.length === 9 && !addressCep.includes('_'),
    refetchOnWindowFocus: false
  })

  const {
    data: statesData
  } = useQuery({
    queryKey: ['ibgeStates'],
    queryFn: () => apiIbge.getStates('estados'),
    refetchOnWindowFocus: false
  })

  const stateUf = Object.entries(statesWithUfList).find(([key, value]) => {
    return key === addressState
  })?.[1]?.uf

  const {
    data: citiesData
  } = useQuery({
    queryKey: ['ibgeCities', stateUf],
    queryFn: () => apiIbge.getCities(`/estados/${stateUf}/municipios`),
    enabled: !!stateUf,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (cepData) {
      if (cepData?.erro) {
        toast('O cep inserido não foi encontrado!', {
          type: 'warning'
        })

      } else {

        const state = Object.entries(statesWithUfList).find(([key, value]) => {
          return value.uf === cepData.uf
        })[0]

        if (isCepQueryEnabled) {
          setValue('addressStreet', cepData.logradouro)
          setValue('addressNeighborhood', cepData.bairro)
          setValue('addressComplement', cepData.complemento)
        }

        if (citiesData) setValue('addressCity', cepData.localidade)
        if (statesData) setValue('addressState', state)
      }

    }
  }, [setValue, cepData, statesData, citiesData, isCepQueryEnabled])

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })

  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getInternCltsOffices({ currentPage: 1, pageSize: 5, name: inputValue })

    return response.internCltOffices.map(office => {
      return { value: office.id, label: office.name }
    })
  }

  return (
    <Flex
      direction="column"
      w="full"
      gap={3}
      mt={3}
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
      >
        <FormControl isInvalid={!!errors.name}>
          <FormLabel>
            Nome completo
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('name')}
            name="name"
          />
        </FormControl>

        <FormControl isInvalid={!!errors.status}>
          <FormLabel>
            Status
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Select
            {...register('status')}
            name="status"
            isDisabled
          >
            {Object.entries(internCltStatusMap).map((([key, value]) => {
              return (
                <option key={key} value={key}>{value}</option>
              )
            }))}
          </Select>
        </FormControl>
      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={3}
          w="full"
        >
          <Controller
            control={control}
            name="cpf"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cpf')}
                name="cpf"
                label='CPF'
                mask={handleSwitchMask('cpf')}
                defaultValue={value}
                onChange={onChange}
                error={errors.cpf}
                bg="white"
                border="1px solid"
                size="md"
                borderColor="gray.200"
                required
              />
            )}
          />

          <Controller
            control={control}
            name="rg"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('rg')}
                name="rg"
                label='RG'
                mask={handleSwitchMask('rg')}
                defaultValue={value}
                onChange={onChange}
                error={errors.rg}
                bg="white"
                border="1px solid"
                size="md"
                borderColor="gray.200"
                required
              />
            )}
          />
        </Stack>

        <FormControl isInvalid={!!errors.bornDate}>
          <FormLabel>
            Data de nascimento
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('bornDate')}
            name="bornDate"
            type="date"
          />
        </FormControl>

      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={3}
          w="full"
        >

          <Controller
            control={control}
            name="addressCep"
            render={({ field: { onChange, value, name } }) => (
              <InputMaskCustom
                {...register('addressCep')}
                name="cep"
                label="CEP"
                error={errors.addressCep}
                defaultValue={value}
                onChange={onChange}
                onChangeCapture={onSetCepQueryEnabled}
                mask={handleSwitchMask('cep')}
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                size="md"
                required
              />
            )}
          />

          <FormControl isInvalid={!!errors?.addressNumber}>
            <FormLabel>
              Número
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('addressNumber')}
              name="addressNumber"
            />
          </FormControl>
        </Stack>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={3}
          w="full"
        >
          <FormControl isInvalid={!!errors?.addressState}>
            <FormLabel>
              Estado
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('addressState')}
            >
              {statesData?.map((state) => {
                return (
                  <option key={state.id}>{state.nome}</option>
                )
              })}
            </Select>
          </FormControl>

          <FormControl isInvalid={!!errors?.addressCity}>
            <FormLabel>
              Cidade
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('addressCity')}
            >
              {citiesData?.map((city) => {
                return (
                  <option key={city.id}>{city.nome}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
        w="full"
      >
        <FormControl isInvalid={!!errors?.addressStreet}>
          <FormLabel>
            Rua
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('addressStreet')}
            name="addressStreet"
          />
        </FormControl>

        <FormControl isInvalid={!!errors?.addressNeighborhood}>
          <FormLabel>
            Bairro
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('addressNeighborhood')}
            name="addressNeighborhood"
          />
        </FormControl>
      </Stack>

      <FormControl>
        <FormLabel>
          Complemento
        </FormLabel>
        <Input
          {...register('addressComplement')}
          name="addressComplement"
        />
      </FormControl>


      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
        w="full"
      >
        <FormControl isInvalid={!!errors?.email}>
          <FormLabel>
            Email
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('email')}
            name="email"
          />
        </FormControl>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={3}
          w="full"
        >
          <FormControl isInvalid={!!errors?.regional}>
            <FormLabel>
              Regional
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              {...register('regional')}
              name="regional"
            >
              <option value="sp">SP</option>
              <option value="df">DF</option>
              <option value="mg">MG</option>
              <option value="rj">RJ</option>
              <option value="other">Outra</option>
            </Select>
          </FormControl>

          <FormControl isInvalid={!!errors?.admissionDate}>
            <FormLabel>
              Data de admissão
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('admissionDate')}
              name="admissionDate"
              type="date"
            />
          </FormControl>
        </Stack>
      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
        w="full"
      >
        <Stack
          spacing={3}
          w="full"
          direction="row"
        >
          <FormControl isInvalid={!!errors?.regional}>
            <FormLabel w="full">
              <Flex
                align="center"
                justify="space-between"
                w="full"
              >
                <Box>
                  Cargo
                  <Text as="sup" color="red.500">*</Text>
                </Box>

                <IconButton
                  aria-label="Criar cargo"
                  icon={<FaPlus />}
                  colorScheme="blue"
                  size="xs"
                  rounded="full"
                  onClick={onOpenCreateInternCltOfficeModal}
                />
              </Flex>
            </FormLabel>
            <Controller
              control={control}
              name="office"
              render={({ field }) => (
                <AsyncSelect
                  {...register('office')}
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  cacheOptions
                  defaultOptions
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: '1px',
                      borderRadius: '6px',
                      border: state.isFocused ? 'none' : '',
                      boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                    })
                  }}
                  noOptionsMessage={() => 'Não há cargos cadastrados ou encontrados!'}
                  placeholder="Selecione um cargo..."
                  loadOptions={promiseOptions}
                  isClearable={true}
                />
              )}
            />
          </FormControl>
        </Stack>

        <FormControl isInvalid={!!errors?.admissionDate}>
          <FormLabel>
            Salário
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <BiDollar />
            </InputLeftAddon>
            <Input
              {...register('salary')}
              name="salary"
              defaultValue={currencyFormat('000')}
              onChange={onCurrencyChange}
            />
          </InputGroup>
        </FormControl>
      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
        w="full"
      >
        <FormControl isInvalid={!!errors?.hasLeadershipGratification}>
          <FormLabel>
            Possui gratificação de liderança?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            control={control}
            name="hasLeadershipGratification"
            render={({ field }) => {
              return (
                <RadioGroup {...field}>
                  <Stack direction='row' spacing={3}>
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )
            }}
          />
        </FormControl>

        {hasLeadershipGratification && (
          <FormControl isInvalid={!!errors?.leadershipGratificationValue}>
            <FormLabel>
              Valor da gratificação
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <BiDollar />
              </InputLeftAddon>
              <Input
                {...register('leadershipGratificationValue')}
                name="leadershipGratificationValue"
                defaultValue={currencyFormat('000')}
                onChange={onCurrencyChange}
              />
            </InputGroup>
          </FormControl>
        )}

      </Stack>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
        w="full"
      >
        <FormControl isInvalid={!!errors?.hasCommission}>
          <FormLabel>
            Possui comissão?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            control={control}
            name="hasCommission"
            render={({ field }) => {
              return (
                <RadioGroup {...field}>
                  <Stack direction='row' spacing={3}>
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )
            }}
          />
        </FormControl>

        {hasCommission && (
          <FormControl isInvalid={!!errors?.commissionDescription}>
            <FormLabel>
              Descrição da apuração
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Textarea
              {...register('commissionDescription')}
              name="commissionDescription"
            />
          </FormControl>
        )}

      </Stack>

      <FormControl isInvalid={!!errors?.journeyType}>
        <FormLabel>
          Tipo de jornada
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('journeyType')}
          name="journeyType"
          placeholder="Selecione o tipo de jornada..."
        >
          <option value="days">Dias</option>
          <option value="12x36">12x36</option>
        </Select>
      </FormControl>

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={3}
        w="full"
      >
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={3}
          w="full"
        >

          <FormControl isInvalid={!!errors?.workStartHour}>
            <FormLabel>
              Horário inicial de trabalho
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('workStartHour')}
              name="workStartHour"
              type="time"
            />
          </FormControl>

          <FormControl isInvalid={!!errors?.workEndHour}>
            <FormLabel>
              Horário final de trabalho
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('workEndHour')}
              name="workEndHour"
              type="time"
            />
          </FormControl>
        </Stack>

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={3}
          w="full"
        >

          <FormControl isInvalid={!!errors?.workIntervalStartHour}>
            <FormLabel>
              Horário inicial de intervalo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('workIntervalStartHour')}
              name="workIntervalStartHour"
              type="time"
            />
          </FormControl>

          <FormControl isInvalid={!!errors?.workIntervalEndHour}>
            <FormLabel>
              Horário final de intervalo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('workIntervalEndHour')}
              name="workIntervalEndHour"
              type="time"
            />
          </FormControl>
        </Stack>
      </Stack>

      {isWithSaturdayDayWork && (
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={3}
          w="full"
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={3}
            w="full"
          >

            <FormControl isInvalid={!!errors?.saturdayWorkStartHour}>
              <FormLabel>
                Horário inicial de trabalho (sábados)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('saturdayWorkStartHour')}
                name="saturdayWorkStartHour"
                type="time"
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.saturdayWorkEndHour}>
              <FormLabel>
                Horário final de trabalho (sábados)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('saturdayWorkEndHour')}
                name="saturdayWorkEndHour"
                type="time"
              />
            </FormControl>
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={3}
            w="full"
          >

            <FormControl isInvalid={!!errors?.saturdayWorkIntervalStartHour}>
              <FormLabel>
                Horário inicial de intervalo (sábados)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('saturdayWorkIntervalStartHour')}
                name="saturdayWorkIntervalStartHour"
                type="time"
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.saturdayWorkIntervalEndHour}>
              <FormLabel>
                Horário final de intervalo (sábados)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('saturdayWorkIntervalEndHour')}
                name="saturdayWorkIntervalEndHour"
                type="time"
              />
            </FormControl>
          </Stack>
        </Stack>
      )}

      {isWithSundayDayWork && (
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={3}
          w="full"
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={3}
            w="full"
          >

            <FormControl isInvalid={!!errors?.sundayWorkStartHour}>
              <FormLabel>
                Horário inicial de trabalho (domingos)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('sundayWorkStartHour')}
                name="sundayWorkStartHour"
                type="time"
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.sundayWorkEndHour}>
              <FormLabel>
                Horário final de trabalho (domingos)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('sundayWorkEndHour')}
                name="sundayWorkEndHour"
                type="time"
              />
            </FormControl>
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={3}
            w="full"
          >

            <FormControl isInvalid={!!errors?.sundayWorkIntervalStartHour}>
              <FormLabel>
                Horário inicial de intervalo (domingos)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('sundayWorkIntervalStartHour')}
                name="sundayWorkIntervalStartHour"
                type="time"
              />
            </FormControl>

            <FormControl isInvalid={!!errors?.sundayWorkIntervalEndHour}>
              <FormLabel>
                Horário final de intervalo (domingos)
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('sundayWorkIntervalEndHour')}
                name="sundayWorkIntervalEndHour"
                type="time"
              />
            </FormControl>
          </Stack>
        </Stack>
      )}


      {isDaysJourneyType && (
        <FormControl>
          <FormLabel>
            Dias da semana
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            control={control}
            name="workWeekDays"
            render={({ field }) => {
              return (
                <CheckboxGroup onChange={field.onChange} value={field.value}>
                  <Stack
                    spacing={3}
                    border="1px solid"
                    borderColor="gray.200"
                    p={2}
                    rounded="md"
                  >
                    {weekDays.map((weekDay, index) => {
                      return (
                        <Checkbox key={weekDay} value={index.toString()}>
                          {weekDay}
                        </Checkbox>
                      )
                    })}
                  </Stack>
                </CheckboxGroup>
              )
            }}
          />
        </FormControl>
      )}

      <FormControl >
        <FormLabel>
          Benefícios
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Controller
          name="benefits"
          control={control}
          render={({ field }) => {
            return (
              <CheckboxGroup
                onChange={field.onChange}
                value={field.value}
              >
                <Stack
                  spacing={3}
                  direction="column"
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="md"
                  p={2}
                >
                  {Object?.entries(internCltBenefitMap)?.map(([benefitKey, benefitValue]) => {
                    return (
                      <Checkbox value={benefitKey}>{benefitValue}</Checkbox>
                    )
                  })}
                </Stack>

              </CheckboxGroup>
            )
          }}
        />
      </FormControl>

      {hasFuelAidBenefit && (
        <FormControl isInvalid={!!errors?.fuelMonthlyValue}>
          <FormLabel>
            Valor mensal (auxílio combustível)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <BiDollar />
            </InputLeftAddon>
            <Input
              {...register('fuelMonthlyValue')}
              name="fuelMonthlyValue"
              defaultValue={currencyFormat('000')}
              onChange={onCurrencyChange}
            />
          </InputGroup>
        </FormControl>
      )}

      {hasVrBenefit && (
        <FormControl isInvalid={!!errors?.vrDailyValue}>
          <FormLabel>
            Valor diário (VR)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <BiDollar />
            </InputLeftAddon>
            <Input
              {...register('vrDailyValue')}
              name="vrDailyValue"
              defaultValue={currencyFormat('000')}
              onChange={onCurrencyChange}
            />
          </InputGroup>
        </FormControl>
      )}

      {hasVaBenefit && (
        <FormControl isInvalid={!!errors?.vaDailyValue}>
          <FormLabel>
            Valor diário (VA)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <BiDollar />
            </InputLeftAddon>
            <Input
              {...register('vaDailyValue')}
              name="vaDailyValue"
              defaultValue={currencyFormat('000')}
              onChange={onCurrencyChange}
            />
          </InputGroup>
        </FormControl>
      )}

      {hasPhoneAssistanceBenefit && (
        <FormControl isInvalid={!!errors?.vaDailyValue}>
          <FormLabel>
            Valor (Auxílio telefonia)
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <BiDollar />
            </InputLeftAddon>
            <Input
              {...register('phoneAssistanceValue')}
              name="phoneAssistanceValue"
              defaultValue={currencyFormat('000')}
              onChange={onCurrencyChange}
            />
          </InputGroup>
        </FormControl>
      )}

      {hasHealthInsuranceBenefit && (
        <FormControl isInvalid={!!errors?.hasHealthInsuranceDependents}>
          <FormLabel>
            Possui dependentes?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Controller
            control={control}
            name="hasHealthInsuranceDependents"
            render={({ field }) => {
              return (
                <RadioGroup {...field}>
                  <Stack direction='row' spacing={3}>
                    <Radio value="yes">Sim</Radio>
                    <Radio value="no">Não</Radio>
                  </Stack>
                </RadioGroup>
              )
            }}
          />
        </FormControl>
      )}
      {hasVtBenefit && (
        <>
          <FormControl>
            <FormLabel>
              Vales Transporte
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Controller
              control={control}
              name="vtTypes"
              render={({ field }) => {
                return (
                  <CheckboxGroup onChange={field.onChange} value={field.value}>
                    <Stack
                      spacing={3}
                      border="1px solid"
                      borderColor="gray.200"
                      p={2}
                      rounded="md"
                    >
                      {Object.entries(vtTypesOptions).map(([key, value]) => (
                        <Checkbox key={key} value={value}>
                          {value}
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                );
              }} />
          </FormControl>
          <FormControl isInvalid={!!errors?.vtDailyValue}>
            <FormLabel>
              Valor diário (VT)
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <BiDollar />
              </InputLeftAddon>
              <Input
                {...register('vtDailyValue')}
                name="vtDailyValue"
                defaultValue={currencyFormat('000')}
                onChange={onCurrencyChange} />
            </InputGroup>
          </FormControl>
        </>
      )}
    </Flex>
  )
}

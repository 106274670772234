import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { Sidebar } from "components/NavigationMenu/Sidebar";
import { ReactNode } from "react";

interface AppLayoutProps {
  children: ReactNode
}

export function AppLayout({ children }: AppLayoutProps) {
  return (
    <Box overflow="hidden" w="100svw" maxH='100svh' bg={useColorModeValue('gray.100', 'gray.900')} scrollBehavior="smooth">
      <Flex overflow="hidden"  w="full">
        <Sidebar />
        <Box justifySelf="center" py="8" pl={{ base: '6', lg: '8'}} pr={{ base: '2', lg: '8' }} w="100svw" h="100svh" overflowY="scroll">
          {children}
        </Box>
      </Flex>
    </Box>
  )

}

interface FileObject {
  name: string;
  lastModified: number;
  lastModifiedDate: Date;
  webkitRelativePath: string;
  size: number;
  type: string;
}

export function validateHaveDuplicateFiles(...arrays: { attachment: { [key: number]: FileObject, length: number } }[]): boolean {
  const files: { [key: string]: boolean } = {};
  for (const array of arrays) {
    for (let i = 0; i < array.attachment.length; i++) {
      const file = array.attachment[i];
      const fileKey = `${file.name}_${file.size}_${file.type}`;

      if (files[fileKey]) {
        return true;
      } else {
        files[fileKey] = true;
      }
    }
  }
  return false;
}

import * as yup from 'yup'
import { isCnpjValid } from '../utils/cpfCnpjValidateFunctions'

const shippingSchema = yup.object().shape({
  company_name: yup.string().required('Cidade inválida'),
  trading_name: yup.string().required('Selecione uma razão social'),
  cnpj: yup.string().transform(isCnpjValid).required('CNPJ inválido'),
  email: yup
    .string()
    .email('Formato de e-mail inválido')
    .required('E-mail obrigatório'),
  modal: yup.string().required('Modal inválido'),
  cellphone: yup.string().required('Celular inválido'),
  telephone: yup.string().required('Telefone inválido'),
  state_register: yup.string().required('Registro estadual inválido'),
  observation: yup.string(),
  has_board_autofill: yup.string().required('Campo obrigatório'),
  cte_transfer_cost: yup.mixed().when('has_board_autofill', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),
  board_weight: yup.mixed().when('has_board_autofill', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),
  real_weight: yup.mixed().when('has_board_autofill', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),
  taxed_weight: yup.mixed().when('has_board_autofill', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),
  is_loglife_payer: yup.mixed().when('has_board_autofill', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),
  is_cte_destination_branch_equal_to_planned: yup.mixed().when('has_board_autofill', {
    is: 'yes',
    then: yup.string().required('Campo obrigatório')
  }),

})

export { shippingSchema }

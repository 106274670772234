import axios from "axios";
import { Collector } from "hooks/collector/dtos/Collector";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { BusinessBudgetProps } from "utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions";
import { BusinessCost } from "./businessCost";

export interface GetBusinessCostResponse {
  businessCost: BusinessCost & {
    businessBudget: BusinessBudgetProps,
    collectors: {
      id: string
      collector: Collector
    }[]
  }
}

export async function getBusinessCost(businessCostId: string) {
  try {
    const { data } = await api.get<GetBusinessCostResponse>(`/business-costs/${businessCostId}`)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { AddressesWithRequestedChangesStatus, AddressRequestedChange } from "./addressRequestedChange";



interface GetAddressesWithRequestedChangesParams {
  pageSize: string
  currentPage: string
  customer?: string
  status?: AddressesWithRequestedChangesStatus | 'all'
}


interface GetAddressesWithRequestedChangesResponse {
  addressRequestedChanges: Array<Pick<AddressRequestedChange, 'id' | 'status' | 'address'>>
  meta: {
    totalPages: number
    currentPage: number
    pageSize: number
  }
}

export async function getAddressesWithRequestedChanges({
  currentPage,
  pageSize,
  customer,
  status
}: GetAddressesWithRequestedChangesParams) {

  try {

    const { data } = await api.get<GetAddressesWithRequestedChangesResponse>(
      `/address/changes`,
      {
        params: {
          currentPage,
          pageSize,
          customer,
          status
        }
      }
    )

    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

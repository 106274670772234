import { Button, Flex, FormControl, FormLabel, IconButton, Input, Link, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { updatePricesByCsv } from "api/prices/updatePricesByCsv";
import { getShippings } from "api/shippings/getShippings";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaFileImport } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"
import AsyncSelect from 'react-select/async';
import { captalize } from "utils/captalize";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToastify } from "hooks/toastify/useToastify";

interface UpdatePricesByCsvSchema {
  csv: FileList
  shipping: {
    label: string
    value: string
  }
}

const updatePricesByCsvSchema = yup.object({
  csv: yup.mixed().test((file: FileList) => file.length > 0),
  shipping: yup.object().required()
})

export function UpdatePricesByCsvModal() {

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<UpdatePricesByCsvSchema>({
    resolver: yupResolver(updatePricesByCsvSchema)
  })

  const [csvAttachment] = useWatch({
    control,
    name: ['csv']
  })

  const attachmentCsvPreview = csvAttachment
    ? Object.entries(csvAttachment).map(([key, value]) => URL.createObjectURL(value))[0]
    : null

  const queryClient = useQueryClient()

  const { mutateAsync: updatePricesByCsvFn } = useMutation({
    mutationFn: updatePricesByCsv,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['prices'] })
    }
  })

  const { promiseMessage } = useToastify()

  async function handleUpdatePricesByCsv(values: UpdatePricesByCsvSchema) {
    const formData = new FormData()

    formData.append('csv', values.csv[0])
    formData.append('shippingId', values.shipping.value)

    await promiseMessage(updatePricesByCsvFn({ body: formData }), 'Preços atualizados com sucesso!')
  }

  async function promiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getShippings({ currentPage: 1, pageSize: 5, tradingName: inputValue })

    return response.shippings.map(s => {
      return { value: s.id, label: captalize(s.trading_name) }
    })
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Atualizar preços por CSV
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody
        as='form'
        onSubmit={handleSubmit(handleUpdatePricesByCsv)}
      >

        <Stack
          direction="column"
          w="full"
          mt="3"
        >
          <FormControl>
            <FormLabel>
              Transportadora
            </FormLabel>
            <Controller
              control={control}
              name="shipping"
              render={({ field }) => (
                <AsyncSelect
                  {...register("shipping")}
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value}
                  cacheOptions
                  defaultOptions
                  placeholder="Transportadora..."
                  loadOptions={promiseOptions}
                  isClearable={true}
                />
              )}
            />
          </FormControl>

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >

            <FormControl isInvalid={!!errors?.csv}>
              <FormLabel fontSize="sm">
                Arquivo csv
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>
              <Input
                {...register('csv')}
                name="csv"
                id="csv"
                type="file"
                hidden
                accept=".csv"

              />
            </FormControl>
            <Button
              as={FormLabel}
              htmlFor="csv"
              lineHeight="1"
              leftIcon={<FaFileImport />}
              size="sm"
              w="min"
              cursor="pointer"
              border={!!errors?.csv && '2px solid'}
              borderColor={!!errors?.csv && 'red.500'}
            >
              Anexar csv
            </Button>
            {attachmentCsvPreview && (
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td fontSize="xs" maxW="100px">{csvAttachment[0].name}</Td>

                    <Td fontSize="xs" isNumeric>

                      <IconButton
                        aria-label="Visualizar anexo"
                        as={Link}
                        size="sm"
                        icon={<FaExternalLinkAlt />}
                        href={URL.createObjectURL(csvAttachment[0])}
                        isExternal
                      />

                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            )}
          </Stack>
        </Stack>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Atualizar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { getDispatchStocksReport } from "api/dispachStocks/getDispatchStocksReport";
import { Pagination } from "components/Pagination/Pagination";

import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { dispatchStockStepMap } from "utils/dispatchStockMappers";
import { DispatchStocksReportFilters } from "./components/DispatchStocksReportFilters";
import { DispatchStockStep } from "./components/DispatchStockStep";

const headers = [
  { label: 'Protocolo', key: 'protocol' },
  { label: 'Etapa', key: 'newStep' },
  { label: 'Responsável', key: 'sender' },
  { label: 'Coletador', key: 'collector.trading_name' },
  { label: 'Transportadora', key: 'shipping.trading_name' },
  { label: 'Base', key: 'branch.nickname' },
  { label: 'Data de Embarque', key: 'board_date' },
  { label: 'Previsão de Chegada', key: 'arrival_forecast' },
  { label: 'Custo do Despacho', key: 'confirmation.dispatch_cost' },
  { label: 'CTE do Despacho', key: 'confirmation.dispatch_cte' },
  { label: 'Rastreador do Despacho', key: 'confirmation.dispatch_tracker' },
  { label: 'NF do Despacho', key: 'confirmation.dispatch_invoice' },
  { label: 'Data da Chegada', key: 'confirmation.arrival_date' },
  { label: 'Materiais enviados', key: 'materialsInfo' },
  { label: 'Responsável pela Retirada', key: 'withdrawal_responsible' },
]

export function DispatchStocksReport() {
  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const userCanViewDispatchStocksReport = userLogged?.permissions.includes('view-dispatch-stock-report')

  useEffect(() => {
    if (!userCanViewDispatchStocksReport) history.push('/')
  }, [history, userCanViewDispatchStocksReport])

  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const step = searchParams.get('step') ?? 'all'

  const {
    data: dispatchStocksReportData
  } = useQuery({
    queryKey: ['dispatch-stocks-report', startDate, endDate, step],
    queryFn: () => getDispatchStocksReport({
      queryParams: {
        endDate,
        startDate,
        step,
        timezoneOffset: new Date().getTimezoneOffset() / 60
      }
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!startDate && !!endDate
  })

  const csvPropsData = dispatchStocksReportData
  ? dispatchStocksReportData?.dispatchStocks.map(dispatchStock => {
    return {
      ...dispatchStock,
      arrival_forecast: format(new Date(dispatchStock.arrival_forecast), 'dd/MM/yyyy'),
      board_date: format(new Date(dispatchStock.board_date), 'dd/MM/yyyy'),
      newStep: dispatchStockStepMap[dispatchStock.step],
      materialsInfo: dispatchStock.materials
        .map((material) => {
          return `
          Nome: ${material.input.name},
          Quantidade: ${material.quantity}
        `
        })
        .join('\n'),
      confirmation: {
        ...dispatchStock?.confirmation,
        arrival_date: dispatchStock?.confirmation?.arrival_date ? format(new Date(dispatchStock.confirmation.arrival_date), 'dd/MM/yyyy') : '-'
      }
    }
  })
  : []

  const csvProps = {
    data: csvPropsData,
    headers,
    filename: `relatório-despachos-de-estoque-${new Date().toISOString()}.csv`,
  }

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
      >
        <Heading letterSpacing="tight">Relatório de Despachos de Estoque</Heading>

        <DispatchStocksReportFilters csvProps={csvProps} />

        {dispatchStocksReportData && (
          <TableContainer mt="6">
            <Table
              size="sm"
            >
              <Thead>
                <Tr>
                  <Th>Protocolo</Th>
                  <Th>Coletador de origem</Th>
                  <Th>Coletador de destino</Th>
                  <Th>Transportadora</Th>
                  <Th>Base</Th>
                  <Th>Etapa</Th>
                </Tr>
              </Thead>

              <Tbody>
                {dispatchStocksReportData?.dispatchStocks.slice(offset, offset + 10).map((dispatchStock) => {
                  return (
                    <Tr key={dispatchStock.id}>
                      <Td>{dispatchStock.protocol}</Td>
                      <Td>{dispatchStock.sourceCollector.trading_name}</Td>
                      <Td>{dispatchStock.destinationCollector.trading_name ?? '-'}</Td>
                      <Td>{dispatchStock.shipping.trading_name ?? '-'}</Td>
                      <Td>{dispatchStock.branch.nickname ?? '-'}</Td>
                      <Td>
                        <DispatchStockStep step={dispatchStock.step} />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          handlePageChange={handleChangePage}
          pagesQuantity={pagesCount}
          pages={pages}
          currentPage={currentPage}
        />


      </Box>


  )
}

import { Occurrence } from 'hooks/occurrences/dtos/Occurrence';
import request from "axios"
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import { useAuth } from "hooks/auth/useAuth";
import { RequestError } from "utils/errors/RequestErrors";
import { useToastify } from "hooks/toastify/useToastify";

interface ConsultOccurrencesQueryParams {
  steps?: string
  types?: string
  intercurrences?: string
  query?: string
  startFilter?: string
  endFilter?: string
  pageSize?: number
  currentPage?: number
}

interface ConsultOccurrencesResponse {
  occurrences: Pick<Occurrence, "id" | "type" | "intercurrence" | "occurrence_protocol" | "occurrenceIDService" | "step">[]
  totalPages: number
}

interface GetConsultOccurrencesProps {
  queryParams?: ConsultOccurrencesQueryParams
}

async function getConsultOccurrences({
  queryParams
}: GetConsultOccurrencesProps) {
  try {
    const { data } = await api.get(`/occurrences/consult`, {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

type QueryOptions = UseQueryOptions<ConsultOccurrencesResponse, unknown, ConsultOccurrencesResponse, QueryKey>

interface UseConsultOccurrencesOptions {
  queryKey?: QueryKey
  queryParams?: ConsultOccurrencesQueryParams
  queryOptions?: QueryOptions
}

export function useConsultOccurrences(
  options?: UseConsultOccurrencesOptions
) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey ?? 'consultOccurrences',
    { ...options?.queryParams }
  ], () => getConsultOccurrences({
    queryParams: options?.queryParams
  }), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

import * as yup from 'yup'

const hubSchema = yup.object().shape({
  situation: yup.string().required('Campo Obrigatório'),
  name: yup.string().required('Campo Obrigatório'),
  state: yup.string().required('Campo Obrigatório'),
  regional: yup.number().typeError('Campo Obrigatório'),
  timezone: yup.string().required('Campo Obrigatório'),
  hub_responsible: yup.string().required('Campo Obrigatório'),
  observation: yup.string(),
})

export { hubSchema }

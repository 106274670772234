import { useEffect } from "react"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Flex } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import { useAuth } from "hooks/auth/useAuth"
import { Link, useHistory } from "react-router-dom"
import { isCnpjValid } from "utils/cpfCnpjValidateFunctions";
import { useAggregateFunctions } from "hooks/aggregate/useAggregateFunctions";
import { useCep } from "hooks/useCep";
import { useToastify } from "hooks/toastify/useToastify";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { useIbgeCitiesByUf } from "hooks/ibge/useIbgeCitiesByUf";
import { useIbgeState } from "hooks/ibge/useIbgeState";
import { AggregateForm, AggregateFormInputs } from './components/AggregateForm'
import { useDriver } from "hooks/driver/useDriver";
import { Aggregate } from "hooks/aggregate/dtos/Aggregate";
import { useCity } from "hooks/city/useCity";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";

const createAggregateForm = yup.object().shape({
  company_name: yup.string().required("Campo obrigatório"),
  trading_name: yup.string().required("Campo obrigatório"),
  cnpj: yup.string().transform(isCnpjValid).required('CNPJ inválido'),
  rg: yup.string().required("Campo obrigatório"),
  cellphone: yup.string().required("Campo obrigatório"),
  driver_id: yup.string().required("Campo obrigatório"),
  birth_date: yup.string().required("Campo obrigatório"),
  cep: yup.string().required("Campo obrigatório"),
  state: yup.string().required("Campo obrigatório"),
  city: yup.string().required("Campo obrigatório"),
  street: yup.string().required("Campo obrigatório"),
  number: yup.string().required("Campo obrigatório"),
  neighborhood: yup.string().required("Campo obrigatório"),
  complement: yup.string(),
  contract_start_date: yup.string().required("Campo obrigatório"),
  payment_type: yup.string().required("Campo obrigatório"),
  due_date: yup.number().typeError('Campo obrigatório'),
  days_of_work: yup.array().of(yup.object({
    day: yup.number().typeError('Campo obrigatório'),
    price: yup.string().required('Campo obrigatório')
  })).min(1, 'Campo obrigatório'),
  rider_backup: yup.string().required("Campo obrigatório"),
  bank: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  cpf: yup.mixed().when('type', {
    is: 'cpf',
    then: yup.string().required('Campo obrigatório')
  }),
  account_type: yup.string().required('Campo obrigatório'),
  agency: yup.string().required('Campo obrigatório'),
  account: yup.string().required('Campo obrigatório'),
  cities: yup.array().of(yup.object({
    cityId: yup.string().required('Campo obrigatório'),
    carValueInCents: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
    motorcycleValueInCents: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100)),
  })),
  observation: yup.string(),
})

export function CreateAggregatePage() {
  const formMethods = useForm<AggregateFormInputs>({
    resolver: yupResolver(createAggregateForm),
    defaultValues: {
      days_of_work: [],
      rider_backup: 'no',
      cities: [{ uf: '', cityId: '', carValueInCents: '0,00', motorcycleValueInCents: '0,00' }]
    }
  })

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const { warnMessage } = useToastify()
  const userHasPermissionCreateAggregates =
    userLogged?.permissions.includes("create-aggregate");

  const { watch, setValue, handleSubmit, formState: { isSubmitting } } = formMethods

  const {
    createAggregate: { mutateAsync: createAggregate },
    updateAggregateAttachments: { mutateAsync: updateAggregateAttachments },
  } = useAggregateFunctions()

  const cepValue = watch("cep")
  const isValidCep = cepValue ? !cepValue.includes("_") : false
  const cepFormatted = isValidCep ? cepValue.split("-").join("") : null

  const {
    data: cepData, isFetching: isFetchingCepData, refetch: refetchCep
  } = useCep({ cep: cepFormatted, useQueryOptions: { enabled: isValidCep } })

  useEffect(() => {
    if (!userHasPermissionCreateAggregates) {
      redirect('/')
    }
  }, [redirect, userHasPermissionCreateAggregates])

  useEffect(() => {
    if (isValidCep) refetchCep()
  }, [refetchCep, isValidCep, cepValue])

  const isErrorOnConsultCep = cepData?.erro

  useEffect(() => {
    if (isErrorOnConsultCep && !isFetchingCepData) {
      warnMessage("Ocorreu um erro ao consultar o cep informado!")
    }
  }, [isErrorOnConsultCep, warnMessage, isFetchingCepData])

  const {
    ibgeStates: { data: ibgeStates, isFetching: isFetchingIbgeStates }
  } = useIbgeState()

  const { data: ibgeCities, isFetching: isFetchingIbgeCitiesByUf } =
    useIbgeCitiesByUf({
      uf: cepData?.uf,
      queryOptions: { enabled: !isErrorOnConsultCep && !isFetchingCepData }
    })

  const stateFromCepUf = Object.entries(statesWithUfList).find(([key, value]) => value.uf === cepData?.uf)?.[0]

  const cityFilteredByCep = ibgeCities?.find(city => {
    return city.nome === cepData?.localidade
  })

  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true)

  useEffect(() => {
    if (
      cepData &&
      !isErrorOnConsultCep &&
      !isFetchingCepData &&
      !isFetchingIbgeCitiesByUf &&
      !isFetchingIbgeStates
    ) {
      setValue('street', cepData.logradouro)
      setValue('complement', cepData.complemento)
      setValue('neighborhood', cepData.bairro)

      if (stateFromCepUf) setValue('state', stateFromCepUf?.toLocaleUpperCase())
      if (cityFilteredByCep) setValue('city', cityFilteredByCep?.nome.toLocaleUpperCase())

    }
  }, [
    cepData,
    stateFromCepUf,
    setValue,
    cityFilteredByCep,
    isErrorOnConsultCep,
    isFetchingCepData,
    isFetchingIbgeCitiesByUf,
    isFetchingIbgeStates,
    isCitiesLoading
  ])

  async function handleCreateAggregate({ ...values }: AggregateFormInputs) {
    await createAggregate(values, {
      onSuccess: async (data: Aggregate) => {
        if (values.attachments) {
          const formData = new FormData()

          Object.entries(values.attachments).forEach(([key, file]) => {
            formData.append('attachments', file)
          })

          await updateAggregateAttachments({
            aggregateId: data.id,
            input: formData
          })
        }
        redirect('/agregados')
      }
    })
  }

  const {
    drivers: { data: drivers, isLoading: isDriversLoading },
  } = useDriver(null, true)


  return (
    <StandardBackgroundForm title="Criar Agregado" onSubmit={handleSubmit(handleCreateAggregate)}>
      <FormProvider {...formMethods}>
        <AggregateForm
          slug="criar"
          ibgeStates={ibgeStates}
          ibgeCities={ibgeCities}
          cities={cities}
          driversState={{
            drivers,
            isLoading: isDriversLoading
          }}
        />
      </FormProvider>
      <Flex
        mt={6}
        gap={4}
        direction={["column", "column", "row"]}
        align="center"
        justify={["center", "center", "flex-end"]}
      >
        <Button
          as={Link}
          colorScheme='gray'
          w={['full', 'full', 'min']}
          isLoading={isSubmitting}
          to='/agregados'
        >
          Lista de agregados
        </Button>
        <Button
          w={["full", "full", "min"]}
          colorScheme="blue"
          type="submit"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )

}

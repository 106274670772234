import { yupResolver } from '@hookform/resolvers/yup'

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'


import { TextArea } from '../../components/Inputs/TextInput'
import { Select } from '../../components/Inputs/SelectInput'
import { Input } from '../../components/Inputs/Input'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { regional_list, situation } from '../../utils/customLists'
import { useHub } from '../../hooks/hub/useHub'
import { apiIbge } from '../../services/ibgeApiservice/ibgeApi'
import { IIbgeApiStatesProps } from '../../services/ibgeApiservice/IIbgeApi'
import { hubSchema } from '../../validations/hubSchema'
import { useUsers } from 'hooks/user/useUsers'

interface IFormInputProps {
  name: string
  state: string
  situation: string
  observation: string
  regional: number
  timezone: string
  hub_responsible: string
  value: string
}

interface HubFormProps {
  title: string
  action: string
  slug?: string
  id?: string
  isDisabled?: boolean
  href: string
  submit: (values: IFormInputProps) => Promise<void>
}

export const timezoneList = {
  "FNT": {
    name: "Horário Padrão de Fernando de Noronha (UTC-2)",
    value: "UTC-2"
  },
  "BRT": {
    name: "Horário Padrão de Brasília (UTC-3)",
    value: "UTC-3"
  },
  "AMT": {
    name: "Horário Padrão do Amazonas (UTC-4)",
    value: "UTC-4"
  },
  "ACT": {
    name: "Horário Padrão do Acre (UTC-5)",
    value: "UTC-5"
  },
}

export function HubForm({
  title,
  slug,
  isDisabled = false,
  id,
  action,
  href,
  submit,
}: HubFormProps) {
  const [statesList, setStatesList] = useState<IIbgeApiStatesProps[]>([])

  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const {
    hub: { data: hub, isLoading: isHubLoading },
  } = useHub(id || null, false, false)

  const { data: users, isFetching: isUsersFetching } = useUsers()

  const activeUsersOptions = users
    ?.filter(user => user.situation === "ATIVO")
    .map(user => {
      return {
        key: user.id,
        value: user.id,
        showOption: `${user.firstname} ${user.lastname}`
      }
    })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(hubSchema),
  })

  useEffect(() => {
    const setLoadingState = () => {
      if (!isHubLoading && !isUsersFetching) {
        setIsLoadingPage(false)
      }
    }
    setLoadingState()
  }, [isHubLoading, isUsersFetching])

  useEffect(() => {
    const fetchIbgeAPi = async () => {
      const statesList = await apiIbge.getStates('estados')
      setStatesList(statesList)
    }

    fetchIbgeAPi()
  }, [])

  useEffect(() => {
    const setValuesOnFieds = () => {
      if (slug === 'adicionar') setValue('situation', 'ATIVO')

      if (slug !== 'adicionar') {
        if (hub && statesList) {
          Object.entries(hub).forEach(([name, value]) =>
            setValue(name as keyof IFormInputProps, value as string),
          )
        }
      }
    }
    setValuesOnFieds()
  }, [setValue, slug, hub, statesList])

  if (isLoadingPage) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box
          as="form"
          flex="1"
          borderRadius="8px"
          bg="white"
          p={['6', '8']}
          onSubmit={handleSubmit(submit)}
          noValidate
        >
          <Heading size="lg" fontFamily="poppins">
            {title}
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          <Stack>
            <Select
              {...register('situation')}
              name="situation"
              label="Situação"
              error={errors.situation}
              isDisabled={slug === 'adicionar' ? true : isDisabled}
              situations={situation}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>
          <HStack spacing="24px" mt="4">
            <Input
              {...register('name')}
              isDisabled={isDisabled}
              name="name"
              label="Nome"
              error={errors.name}
              required
            />

            <Select
              {...register('state')}
              name="state"
              label="Estado"
              error={errors.state}
              isDisabled={isDisabled}
              states={statesList}
              placeholder="Selecione o estado"
              required
            />
          </HStack>

          <Stack mt="4" direction={["column", "column", "row"]} spacing={6}>
            <Select
              {...register('regional')}
              name="regional"
              label="Regional"
              error={errors.regional}
              isDisabled={isDisabled}
              regional_list={regional_list}
              placeholder="Selecione uma opção..."
              required
            />

            <Select
              {...register("hub_responsible")}
              name="hub_responsible"
              label="Responsável do hub"
              placeholder="Selecione uma opção..."
              options={activeUsersOptions}
              error={errors.hub_responsible}
              required
            />
          </Stack>

          <Stack mt="4">
            <Select
              {...register("timezone")}
              name="timezone"
              placeholder="Selecione uma opção..."
              label="Timezone"
              error={errors.timezone}
              isDisabled={isDisabled}
              required
            >
              {Object.entries(timezoneList).map(([key, value]) => {
                return (
                  <option key={key} value={value.value}>{value.name}</option>
                )
              })}
            </Select>
          </Stack>

          <Stack mt="4">
            <TextArea
              {...register('observation')}
              name="observation"
              label="Observações"
              isDisabled={isDisabled}
            />
          </Stack>
          <Flex mt="8" justify="flex-end">
            <HStack>
              {slug === 'visualizar' ? (
                <Link to={href}>
                  <Button
                    type="button"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {action}
                  </Button>
                </Link>
              ) : (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  loadingText="Carregando"
                >
                  {action}
                </Button>
              )}

              <Link to="/hubs">
                <Button type="button" colorScheme="gray">
                  Lista de HUBs
                </Button>
              </Link>
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}

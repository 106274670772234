import { InternCltBenefitName, InternCltJourneyType, InternCltStatus } from "api/internClts/_types/internClt";

export const internCltBenefitMap: Record<InternCltBenefitName, string>  = {
  "fuel-aid": 'Auxilio combustível',
  vr: 'Vale refeição',
  va: 'Vale alimentação',
  "health-insurance": 'Plano de saúde',
  "dental-plan": 'Plano odontológico',
  "pharmacy-agreement": 'Convênio farmácia',
  "medication-assistance": 'Auxílio medicamento',
  gympass: 'Gympass',
  'salary-advance': 'Adiantamento salarial',
  'vt': 'Vale Transporte',
  'phone-assistance': 'Auxilio telefonia'
}

export const internCltJourneyTypeMap: Record<InternCltJourneyType, string> = {
  "12x36": '12x36',
  days: 'Dias',
}

export const internCltStatusMap: Record<InternCltStatus, string> = {
  hiring: 'Contratando',
  "pontomais-registering": 'Registrando no ponto mais',
  "sending-bank-details": 'Enviando dados bancários',
  active: 'Ativo',
  "dismissal-docs" : 'Demissão Documentação',
  "in-notice": 'Aguardando fim de aviso previo',
  "dismissal-comunication":  'Demissão Comunicação',
  "dismissal-payment": 'Demissão Pagamento',
  "dismissal-financial": 'Demissão Financeiro',
  dismissed: 'Demitido',
  inactive: 'Inativo',
}

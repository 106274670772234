import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Spinner,
  Stack,
} from '@chakra-ui/react'
import { useRnc } from '../../../hooks/rnc/useRnc'
import { formatDateToFrontInput } from '../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontInput'
import { Input } from '../../Inputs/Input'
import { TextArea } from '../../Inputs/TextInput'

interface IRncAnswerAccordionProps {
  rncID: string
}

export function RncAnswerAccordion({ rncID }: IRncAnswerAccordionProps) {
  const {
    rnc: { data: rnc, isLoading: isRncLoading },
  } = useRnc(rncID, false)

  if (isRncLoading) {
    return <Spinner />
  }

  return (
    <Accordion allowMultiple mt="4">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Informações de Abertura do RNC
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <Stack>
            <Input
              label="Protocolo"
              name="protocol"
              value={rnc?.protocol}
              isDisabled
            />
          </Stack>
          <Stack
            mt="4"
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              label="Diretoria"
              name="direction"
              value={rnc?.direction}
              isDisabled={true}
            />

            <Input
              label="Data"
              name="date"
              type="date"
              value={rnc ? formatDateToFrontInput(rnc?.date) : ''}
              isDisabled={true}
            />
          </Stack>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <Input
              label="Prejuízo"
              name="loss_value"
              value={rnc ? rnc.loss_value?.toFixed(2) : ''}
              isDisabled={true}
            />
          </Stack>
          <Stack
            w="full"
            spacing="24px"
            direction={['column', 'column', 'row']}
          >
            <TextArea
              label="Descrição da Intercorrência"
              name="intercurrence_description"
              value={rnc?.intercurrence_description}
              isDisabled={true}
            />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface DismissalFinancialInternCltBody {
  hasCompletedContractTerminationTerm: boolean
  hasRemovedFromOccupationalMedicine: string
  hasRemovedFromHealthcare: string
  hasRemovedFromDentalPlan: string
  hasRemovedFromPharmacyAgreement: string
  dismissalRealDate: string
}

interface DismissalFinancialInternCltProps {
  body: DismissalFinancialInternCltBody
  internCltId: string
}

export async function dismissalFinancialInternClt({
  body,
  internCltId
}: DismissalFinancialInternCltProps) {
  try {
    const response = await api.put(`/intern-clts/${internCltId}/dismissal-financial`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

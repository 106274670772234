import { usePagination } from "@ajna/pagination";
import { Box, Flex, Heading, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getServicesWithStepChanges } from "api/service/requestStepChange/getServicesWithStepChanges";
import { Pagination } from "components/Pagination/Pagination";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ValidateServiceWithStepChangeTableRow } from "./components/ValidateServiceWithStepChangeTableRow";

export function ValidateServicesWithStepChange() {
  const { userLogged } = useAuth()
  const history = useHistory()

  const userCanViewServicesWithStepChange = userLogged?.permissions.includes('view-services-with-step-change')

  useEffect(() => {
    if (!userCanViewServicesWithStepChange) history.push('/')
  }, [history, userCanViewServicesWithStepChange])


  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'

  const { data: servicesWithStepChangesData, isLoading: isServicesWithStepChangesLoading } =
    useQuery(['services-validations-request-change-step', page], () => getServicesWithStepChanges({
      currentPage: page,
      pageSize: '10'
    }))

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: servicesWithStepChangesData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: 1
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      bg="white"
      borderRadius="md"
      p="4"
    >
      <Flex
        w="full"
        direction="column"
        gap={6}
      >
        <Heading letterSpacing="tight">Validar serviços com alteração de fase</Heading>

        {isServicesWithStepChangesLoading ? (
          <Spinner />
        ) : (
          <TableContainer
            rounded="md"
          >
            <Table
              size="sm"
            >
              <Thead
                h="40px"
                bg="#38c3fa"
              >
                <Tr>
                  <Th textColor="white">Protocolo</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {servicesWithStepChangesData?.services.map((service) => {
                  return (
                    <ValidateServiceWithStepChangeTableRow
                      key={service.id}
                      service={service}
                    />
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Flex>
    </Box>
  )
}

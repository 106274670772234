import { Flex, Spinner } from "@chakra-ui/react"
import { Select } from "components/Inputs/SelectInput"
import { Crm, CrmType } from "hooks/crms/dtos/Crm"
import { useCustomers } from "hooks/customer/useCustomers"
import { useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { crmTypeOptions } from "utils/CustomLists/crmTypeOptions"
import { CrmMeetingForm } from "./CrmMeetingForm"
import { CrmNonPayerForm } from "./CrmNonPayerForm"
import { CrmNoteForm } from "./CrmNoteForm"
import { CrmRncForm } from "./CrmRncForm"

interface CrmFormProps {
  slug: 'create' | 'view'
  crm?: Crm
}

export interface CrmFormInputs {
  type: CrmType
  customerId: string
  meetingDate: string
  meetingParticipants: string
  meetingSummary: string
  invoiceNumber: number
  originalInvoiceExpirationDate: string
  paymentForecastDate: string
  rncDate: string
  rncDescription: string
  noteDate: string
  noteDescription: string
  meetingAttachment: FileList
  rncAttachments: FileList
}

export function CrmForm({
  slug,
  crm,
}: CrmFormProps) {

  const {
    control,
    setValue,
    register,
    formState: { errors }
  } = useFormContext<CrmFormInputs>()

  const [
    type
  ] = useWatch({
    control,
    name: ['type']
  })

  const isViewPage = slug === 'view'
  const isCreatePage = slug === 'create'

  const isMeetingCrmType = type === 'REUNIÃO'
  const isNonPayerCrmType = type === 'INADIMPLÊNCIA'
  const isRncCrmType = type === 'RNC DE CLIENTE'
  const isNoteCrmType = type === 'ANOTAÇÃO'

  const {
    data: customers,
    isFetching: isFetchingCustomers
  } = useCustomers({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const customerOptions = customers?.map((customer) => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: customer.trading_firstname
    }
  })

  useEffect(() => {
    if (!isCreatePage) {
      setValue('type', crm.type)

      if (crm.type === 'REUNIÃO') {
        setValue('meetingDate', crm.meeting_date)
        setValue('meetingParticipants', crm.meeting_participants)
        setValue('meetingSummary', crm.meeting_summary)
      }

      if (crm.type === 'INADIMPLÊNCIA') {
        setValue('invoiceNumber', crm.invoice_number)
        setValue('originalInvoiceExpirationDate', crm.original_invoice_expiration_date)
        setValue('paymentForecastDate', crm.payment_forecast_date)
      }

      if (crm.type === 'RNC DE CLIENTE') {
        setValue('rncDate', crm.rnc_date)
        setValue('rncDescription', crm.rnc_description)
      }

      if (crm.type === 'ANOTAÇÃO') {
        setValue('noteDate', crm.note_date)
        setValue('noteDescription', crm.note_description)
      }

      if (!isFetchingCustomers) {
        setValue('customerId', crm.customer_id)
      }
    }
  }, [isCreatePage, setValue, isFetchingCustomers, crm])

  return (
    <Flex
      gap={4}
      direction='column'
    >
      <Select
        {...register('type')}
        label='Tipo'
        name='type'
        placeholder='Selecione uma opção...'
        options={crmTypeOptions}
        error={errors.type}
        isDisabled={isViewPage}
        required
      />

      {isFetchingCustomers ? (
        <Spinner />
      ) : (
        <Select
          {...register('customerId')}
          label='Cliente'
          name='customerId'
          placeholder='Selecione uma opção...'
          options={customerOptions}
          error={errors.customerId}
          isDisabled={isViewPage}
          required
        />
      )}


      {isMeetingCrmType && (
        <CrmMeetingForm
          isViewPage={isViewPage}
          meetingAttachmentPreview={crm?.meeting_attachment}
        />
      )}

      {isNonPayerCrmType && (
        <CrmNonPayerForm isViewPage={isViewPage} />
      )}

      {isRncCrmType && (
        <CrmRncForm
          isViewPage={isViewPage}
          rncAttachmentPreview={crm?.rnc_attachments}
        />
      )}

      {isNoteCrmType && (
        <CrmNoteForm isViewPage={isViewPage} />
      )}

    </Flex>
  )
}

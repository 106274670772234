import { getAllHubs, getOnehub } from '../../services/getFunctions/hub/getHub'
import { useToastify } from '../toastify/useToastify'
import { useQuery } from 'react-query'
import { useAuth } from '../auth/useAuth'

export function useHub(
  hubId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useToastify()

  const hubs = useQuery('hubs', getAllHubs, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
      handleLogout()
    },
  })

  const hub = useQuery(
    ['hubId', hubId], () => getOnehub(hubId),
    {
      enabled: !!hubId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
        if (e.message.includes('Token')) {
          handleLogout()
        }
      },
    },
  )

  return {
    hubs,
    hub,
  }
}

import { Button, Flex, Spinner, Stack } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm"
import { Input } from "components/Inputs/Input"
import { Select } from "components/Inputs/SelectInput"
import { add } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useCollectors } from "hooks/collector/useCollectors"
import { useNonInactiveCustomers } from "hooks/customer/useNonInactiveCustomers"
import { OccurrenceReportData } from "hooks/report/dtos/OccurrenceReport"
import { useReport } from "hooks/report/useReport"
import { useShipping } from "hooks/shipping/useShipping"
import { useToastify } from "hooks/toastify/useToastify"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"
import { handleChangeUrl } from "utils/handleChangeUrl"
import { occurrenceStatusDict } from "utils/OccurrenceFunctions/occurrenceDict"
import { handleChangeHeaders } from "utils/ReportFunctions/handleChangeHeaders"
import { serviceHandleStep } from "utils/ServiceFunctions/serviceDict"
import * as yup from "yup"
import { OccurrenceReportTable } from "./components/OccurrenceReportTable"

interface OccurrenceReportInputs {
  find_by: 'GERAL' | 'CLIENTE' | 'MOTORISTA' | 'COLETADOR' | 'TRANSPORTADORA'
  occurrence_type: 'CLIENTE' | 'TODAS'
  start_filter: string
  end_filter: string
  shipping_id: string
  customer_id: string
  collector_id: string
}

const occurrenceReportFilterOptions = [
  { key: 'general', value: 'GERAL', showOption: 'GERAL' },
  { key: 'customer', value: 'CLIENTE', showOption: 'CLIENTE' },
  { key: 'driver', value: 'MOTORISTA', showOption: 'MOTORISTA' },
  { key: 'collector', value: 'COLETADOR', showOption: 'COLETADOR' },
  { key: 'shipping', value: 'TRANSPORTADORA', showOption: 'MOTORISTA' },
]

const occurrenceTypesOptions = [
  { key: 'all', value: 'TODAS', showOption: 'TODAS' },
  { key: 'customer', value: 'CLIENTE', showOption: 'CLIENTE' },
]

const occurrenceReportSchema = yup.object().shape({
  find_by: yup.string().required('Campo obrigatório'),
  start_filter: yup.string().required('Campo obrigatório'),
  end_filter: yup.string().required('Campo obrigatório'),
  occurrence_type: yup.string().when('find_by', {
    is: 'GERAL',
    then: yup.string().required('Campo obrigatório')
  }),
  customer_id: yup.string().when('find_by', {
    is: 'CLIENTE',
    then: yup.string().required('Campo obrigatório')
  }),
  collector_id: yup.string().when('find_by', {
    is: 'COLETADOR',
    then: yup.string().required('Campo obrigatório')
  }),
  shipping_id: yup.string().when('find_by', {
    is: 'TRANSPORTADORA',
    then: yup.string().required('Campo obrigatório')
  }),
})

function formatOccurrenceReportData(occurrenceReportData?: OccurrenceReportData[]) {
  return occurrenceReportData?.map(occurrenceData => {
    return {
      ...occurrenceData,
      occurrenceDetail: occurrenceData.occurrenceDetail.split("\"").join("'"),
      occurrenceDate:
        occurrenceData.occurrenceDate !== '-'
          ? formatDate.handle(occurrenceData.occurrenceDate, 'DateWithoutHourToShow')
          : occurrenceData.occurrenceDate,
      occurrenceHour:
        occurrenceData.occurrenceHour !== '-'
          ? formatDate.handle(occurrenceData.occurrenceHour, 'DateOnlyWithHourMinute')
          : occurrenceData.occurrenceHour,
      cause: occurrenceData.cause.split("\"").join("'"),
      corrective: occurrenceData.corrective.split("\"").join("'"),
      preventive: occurrenceData.preventive.split("\"").join("'"),
      createdAt: formatDate.handle(occurrenceData.createdAt, 'DateWithoutHourToShow'),
      updatedAt: occurrenceData.updatedAt,
      serviceStep: serviceHandleStep(occurrenceData.serviceStep),
      step: occurrenceStatusDict(occurrenceData.step),
      photo: occurrenceData.photo ? handleChangeUrl(occurrenceData.photo) : '-',
      occurrenceAttachments: occurrenceData.occurrenceAttachments
        ? typeof occurrenceData.occurrenceAttachments === 'string'
          ? JSON.parse(occurrenceData.occurrenceAttachments).map((attachment) => {
            return `${handleChangeUrl(attachment?.key)} \n`
          }).join('\n')
          : occurrenceData.occurrenceAttachments.map((attachment) => {
            return `${handleChangeUrl(attachment.key)} \n`
          }).join('\n')
        : '-',
      occurrencePreventiveResponsibles: occurrenceData.occurrencePreventiveResponsibles.map((preventiveResponsible, index) => {
        return `
          ${index + 1}: ${preventiveResponsible}
        `
      }).join('\n'),
      occurrenceAlignmentActions: occurrenceData.occurrenceAlignmentActions.map((alignmentAction, index) => {
        return `
          ${index + 1}: ${alignmentAction}
        `
      }).join('\n'),
    }
  })
}

export function OccurrenceReport() {

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-occurrence-report')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const {
    generateOccurrenceReport: {
      data: occurrenceReportData,
      mutateAsync: generateOccurrenceReport,
      isLoading: isGeneratingOccurrenceReportData
    }
  } = useReport()

  const { promiseMessage } = useToastify()

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<OccurrenceReportInputs>({
    defaultValues: {
      occurrence_type: 'TODAS'
    },
    resolver: yupResolver(occurrenceReportSchema),
    shouldUnregister: true
  })

  useEffect(() => {
    if (userLogged?.user_type === 'CLIENTE') {
      setValue('find_by', 'CLIENTE')
      setValue('customer_id', userLogged?.customer_id)
    }
    if (userLogged?.user_type === 'COLETADOR') {
      setValue('find_by', 'COLETADOR')
      setValue('collector_id', userLogged?.collector_id)
    }
    if (userLogged?.user_type === 'MOTORISTA') {
      setValue('find_by', 'MOTORISTA')
    }
  }, [userLogged, setValue])

  const isDisabledFindByOptions =
    ['CLIENTE', 'COLETADOR', 'MOTORISTA'].includes(userLogged?.user_type)

  const findByOptionSelected = watch('find_by')

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setValue('shipping_id', undefined)
    }, 500)

    return () => clearTimeout(debounceTimeout)
  }, [findByOptionSelected, setValue])

  const {
    data: customersData,
    isFetching: isCustomersDataFetching
  } = useNonInactiveCustomers({
    queryOptions: {
      enabled: findByOptionSelected === 'CLIENTE'
    }
  })

  const customersOptions = customersData?.customers.map(customer => {
    return {
      key: customer.id,
      value: customer.id,
      showOption: `${customer.trading_firstname} (${customer.company_lastname})`
    }
  })

  const {
    data: collectors, isFetching: isFetchingCollectors
  } = useCollectors({
    queryOptions: { enabled: findByOptionSelected === 'COLETADOR' }
  })

  const collectorsOptions = collectors?.map(collector => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: `${collector.trading_name} (${collector.company_name})`
    }
  })

  const {
    shipping: { data: shipping, isFetching: isFetchingShipping }
  } = useShipping(null, findByOptionSelected === 'TRANSPORTADORA')

  const shippingOptions = shipping?.map(ship => {
    return {
      key: ship.id,
      value: ship.id,
      showOption: `${ship.trading_name} (${ship.company_name})`
    }
  })

  const endFilter = watch('end_filter')
  const startFilter = watch('start_filter')

  const isValidStartDate = new Date(startFilter) instanceof Date &&
    !isNaN(new Date(startFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(startFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const reportFilename = `relatório-ocorrencia-${startFilter}-${endFilter}`

  const csvReportProps = {
    data: formatOccurrenceReportData(occurrenceReportData),
    headers: handleChangeHeaders('ocorrencias', findByOptionSelected),
    filename: reportFilename,
  }

  async function handleGenerateOccurrenceReport(
    formData: OccurrenceReportInputs
  ) {

    const ocurrenceReportInputData = {
      ...formData,
      is_driver: userLogged?.user_type === 'MOTORISTA',
      is_customer: userLogged?.user_type === 'CLIENTE',
      is_collector: userLogged?.user_type === 'COLETADOR',
    }

    await promiseMessage(
      generateOccurrenceReport(ocurrenceReportInputData),
      'Relatório de ocorrências gerado com sucesso'
    )
  }

  return (
    <StandardBackgroundForm
      title='Relatório de ocorrências'
      onSubmit={handleSubmit(handleGenerateOccurrenceReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Select
          {...register('find_by')}
          name='find_by'
          label='Procurar por'
          placeholder='Selecione uma opção...'
          options={occurrenceReportFilterOptions}
          error={errors.find_by}
          isDisabled={isDisabledFindByOptions}
          required
        />

        {findByOptionSelected === 'GERAL' && (
          <Select
            {...register('occurrence_type')}
            name='occurrence_type'
            label='Tipo de ocorrência'
            placeholder='Selecione uma opção...'
            options={occurrenceTypesOptions}
            error={errors.occurrence_type}
            required
          />
        )}

        {findByOptionSelected === 'CLIENTE' && (
          <Select
            {...register('customer_id')}
            name='customer_id'
            label='Cliente'
            placeholder='Selecione uma opção...'
            options={customersOptions}
            error={errors.customer_id}
            isDisabled={isCustomersDataFetching || userLogged?.user_type === 'CLIENTE'}
            required
          />
        )}

        {findByOptionSelected === 'COLETADOR' && (
          <Select
            {...register('collector_id')}
            name='collector_id'
            label='Coletador'
            placeholder='Selecione uma opção...'
            options={collectorsOptions}
            error={errors.collector_id}
            isDisabled={isFetchingCollectors || userLogged?.user_type === 'COLETADOR'}
            required
          />
        )}

        {findByOptionSelected === 'TRANSPORTADORA' && (
          <Select
            {...register('shipping_id')}
            name='shipping_id'
            label='Transportadora'
            placeholder='Selecione uma opção...'
            options={shippingOptions}
            error={errors.shipping_id}
            isDisabled={isFetchingShipping}
            required
          />
        )}

        <Stack
          direction={['column', 'column', 'row']}
          spacing={4}
        >
          <Input
            {...register('start_filter')}
            name='start_filter'
            label='Data inicial do filtro'
            type='date'
            error={errors.start_filter}
            required
          />
          <Input
            {...register('end_filter')}
            name='end_filter'
            label='Data final do filtro'
            type='date'
            error={errors.end_filter}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex
          direction={['column', 'column', 'row']}
          gap={2}
          justify={['center', 'center', 'flex-end']}
        >
          <Button
            w={['full', 'full', 'min']}
            type='submit'
            colorScheme='blue'
          >
            Gerar relatório
          </Button>

          {occurrenceReportData && (
            <GenerateExcelReportButton
              mt={-4}
              csvReportProps={csvReportProps}
            />
          )}
        </Flex>

        {isGeneratingOccurrenceReportData && (
          <Spinner />
        )}
        {occurrenceReportData && (
          <OccurrenceReportTable
            occurrenceReportData={occurrenceReportData}
          />
        )}
      </Flex>

    </StandardBackgroundForm>
  )
}

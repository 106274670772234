import { AspectRatio, Box, Flex, Grid, GridItem, Heading, Icon, Link, Text } from '@chakra-ui/react'
import { useState } from 'react';
import { FaBookBookmark } from 'react-icons/fa6';

import { IoVideocamOutline } from 'react-icons/io5'

const trainings = [
  {
    title: 'Treinamento Geral Sistema',
    src: 'https://www.youtube.com/embed/sVlItOyIrvw'
  },
  {
    title: 'Consulta de Orçamentos Ativos',
    src: 'https://www.youtube.com/embed/15L-X4zWGS4'
  },
  { title: 'Solicitação de Coleta', src: 'https://www.youtube.com/embed/T2jCZc4uJ8c' },
  {
    title: 'Solicitação de Novo Usuário (Interno  ou Remetente)',
    src: 'https://www.youtube.com/embed/uHdWScLgqew'
  },
  {
    title: 'Consulta de Cidades e Horários',
    src: 'https://www.youtube.com/embed/nnhxdIxclts'
  },
  {
    title: 'Consultar Andamento do Transporte',
    src: 'https://www.youtube.com/embed/_D1Z8JJwPUA'
  },
  {
    title: 'Cancelar e Editar Solicitação de Coleta',
    src: 'https://www.youtube.com/embed/U4tVkcMKufo'
  },
  {
    title: 'Abertura de Ocorrência',
    src: 'https://www.youtube.com/embed/tQ85EBhMH58'
  },
]

export function Help() {
  const [selectedVideo, setSelectedVideo] = useState<{ title: string, src: string } | null>(null)

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >

      <Heading  letterSpacing="tight">Como Usar o Sistema</Heading>

      <Grid overflow="hidden" gap="1" mt="6" gridTemplateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}} h="full" maxH={{base: 'full', md: '400px'}}>
        <GridItem>
          {selectedVideo ? (
            <Box
              position="relative"
              display="flex"
              border="1px solid"
              h="full"
              rounded="lg"
              borderColor="blackAlpha.100"
              overflow="hidden"
            >
              <AspectRatio w="full" h="full" ratio={16 / 9}>
                <iframe
                  title={selectedVideo.title}
                  src={selectedVideo.src}
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
          ) : (
            <Flex
              w="full"
              minH="250px"
              h="full"
              justify="center"
              align="center"
              border="1px solid"
              borderColor="blackAlpha.100"
              rounded="lg"
            >
              Selecione um vídeo
            </Flex>
          )}
        </GridItem>
        <GridItem h="full">
          {trainings?.map((training) => {
            return (
              <Flex
                bg={selectedVideo?.title === training.title ? '#38C3FA' : 'blackAlpha.50'}
                key={training.title}
                p="2"
                onClick={() => setSelectedVideo(training)}
                _first={{
                  borderTopRadius: 'lg'
                }}
                _last={{
                  borderBottomRadius: 'lg'
                }}
                _notFirst={{
                  mt: '0.5'
                }}
                _hover={{
                  bg: 'blackAlpha.100'
                }}
                transition="all 0.2s"
                color={selectedVideo?.title === training.title ? 'white' : 'inherit'}
                cursor='pointer'
                display="flex"
                align="center"
                gap="3"
              >
                <Icon fontSize="lg" as={IoVideocamOutline} />
                <Text>{training.title}</Text>
              </Flex>
            )
          })}
        </GridItem>
      </Grid>

      <Flex gap="2" align="center" mt="6">
        <Icon as={FaBookBookmark} />
        <Text>Você também pode consultar o manual do sistema clicando {' '}
          <Link isExternal fontWeight="bold" color='#38C3FA' href="https://loglifeacademy-my.sharepoint.com/:w:/g/personal/gustavocouto_loglifelogistica_com_br/ESYaLwpMKsJFhiy63awV3vABEymcDx7oeINdRVXc0gjtbQ?rtime=qzir_av920g">Aqui</Link>
        </Text>
      </Flex>
    </Box>

  )
}

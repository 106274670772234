import { useEffect } from "react";
import { Box, Flex, Heading, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import {  useHistory } from "react-router-dom";
import { useSearchParams } from "hooks/useSearchParams";
import { useQuery } from "react-query";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";
import { InternCltsOfficesTableFilters } from "./components/InternCltsOfficesTableFilters";
import { getInternCltsOffices } from "api/internClts/getInternCltsOffices";
import { InternCltOfficeTableRow } from "./components/InternCltOfficeTableRow";

export function InternCltsOffices() {
  const { userLogged } = useAuth()
  const history = useHistory()
  const searchParams = useSearchParams()

  const userCanViewInternClts = userLogged?.permissions.includes('view-intern-clts-offices')


  useEffect(() => {
    if (!userCanViewInternClts) history.push('/')
  }, [userCanViewInternClts, history])

  const page = searchParams.get('page') ?? 1
  const name = searchParams.get('name')

  const { data: internCltsOfficesData } = useQuery({
    queryKey: ['intern-clts-offices',
      page,
      name
    ],
    queryFn: () => getInternCltsOffices({
      currentPage: Number(page),
      pageSize: 10,
      name,

    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: internCltsOfficesData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: internCltsOfficesData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Cargos internos CLT</Heading>

      </Flex>

      <InternCltsOfficesTableFilters />

      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>

            </Tr>
          </Thead>
          <Tbody>
            {internCltsOfficesData?.internCltOffices?.map((internCltOffice) => {
              return (
                <InternCltOfficeTableRow
                  key={internCltOffice.id}
                  internCltOffice={internCltOffice}
                />
              )
            })}

          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>

  )
}

import { ModalContent, ModalHeader, ModalCloseButton, ModalBody, Stack, FormLabel, FormControl, Select, Flex, Button, Text } from "@chakra-ui/react"
import { TrainingTypes } from "api/trainings/training"
import { Controller, useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { captalize } from "utils/captalize"
import { trainingTypesMap, trainingCollaboratorTypeMap } from "utils/trainingMappers";
import { createTraining } from "api/trainings/createTraining";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { getAggregates } from "hooks/aggregate/useAggregates";
import { getCltDrivers } from "hooks/cltDriver/useCltDrivers";
import { getInternClts } from "api/internClts/getInternClts";
import { getRegionals } from "api/regionals/getRegionals";
import AsyncSelect from "react-select/async"
import { getCarriers } from "api/carriers/getCarriers";

interface CreateTrainingProps {
  onCloseModal: () => void
}

interface CreateTrainingSchema {
  regional: { value: string, label: string }
  collaboratorType: string
  collaborator: { name: string, label: string, email: string }
  type: TrainingTypes
}

const createTrainingSchema = yup.object({
  regional: yup.object().required('Campo Obrigatório'),
  collaboratorType: yup.string().required('Campo Obrigatório'),
  collaborator: yup.object().required('Campo Obrigatório'),
  type: yup.string().required('Campo Obrigatório'),
})


export function CreateTraining({ onCloseModal }: CreateTrainingProps) {
  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  const { mutateAsync: createTrainingFn } = useMutation({
    mutationFn: createTraining,
    onSuccess() {
      queryClient.invalidateQueries(['trainings'])
      onCloseModal()
    }
  })

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreateTrainingSchema>({
    resolver: yupResolver(createTrainingSchema)
  })

  const collaboratorType = useWatch({
    control,
    name: 'collaboratorType'
  })

  async function handleCreateTraining({
    collaborator,
    collaboratorType,
    regional,
    type
  }: CreateTrainingSchema) {
    await promiseMessage(createTrainingFn({
      body: {
        collaboratorName: collaborator.name,
        collaboratorEmail: collaborator.email,
        collaboratorType: collaboratorType,
        regionalId: regional.value,
        type,
      }
    }), 'Treinamento solicitado! 🎉')
  }


  async function regionalPromiseOptions(inputValue: string): Promise<Array<{ value: string, label: string }>> {
    const response = await getRegionals({ currentPage: 1, pageSize: 10 })

    return response.regionals.filter(regional => regional.code.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(regional => {
      return { value: regional.id, label: regional.code.toUpperCase() }
    })
  }

  async function llmCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ name: string, label: string, email: string }>> {
    const response = await getInternClts({ currentPage: 1, pageSize: 5, name: inputValue })

    return response.internClts.map(internClt => {
      return {
        name: internClt.name,
        label: internClt.name,
        email: internClt.email
      }
    })
  }

  async function cltDriversCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ name: string, label: string, email: string }>> {
    const response = await getCltDrivers({ currentPage: 1, pageSize: 5, search: inputValue })

    return response.cltDrivers.map(cltDriver => {
      return {
        name: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        label: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        email: cltDriver.driver.email.toLocaleLowerCase()
      }
    })
  }

  async function aggregatesCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ name: string, label: string, email: string }>> {
    const response = await getAggregates({ status: 'active' })

    return response.filter(aggregate => `${aggregate.driver.firstname} ${aggregate.driver.lastname}`.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())).map(aggregate => {
      return {
        name: captalize(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`),
        label: captalize(`${aggregate.driver.firstname} ${aggregate.driver.lastname}`),
        email: aggregate.driver.email.toLocaleLowerCase()
      }
    })
  }

  async function carriersCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ name: string, label: string, email: string }>> {
    const response = await getCarriers({ currentPage: 1, pageSize: 5, name: inputValue, status: 'all' })

    return response.carriers.map((carrier) => {
      return {
        name: captalize(carrier.name),
        label: captalize(carrier.name),
        email: carrier.email.toLocaleLowerCase()
      }
    })
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Solicitar Treinamento</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreateTraining)}
        maxH='600px'
        overflowY='scroll'
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.collaboratorType}>
              <Select
                {...register('collaboratorType')}
                name="collaboratorType"
                placeholder="Selecione..."
                rounded="md"
              >
                {Object.entries(trainingCollaboratorTypeMap).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.collaborator}>
              {collaboratorType === 'llm' && (
                <Controller
                  control={control}
                  name="collaborator"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('collaborator')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        })
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborator..."
                      loadOptions={llmCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}

              {collaboratorType === 'aggregate-driver' && (
                <Controller
                  control={control}
                  name="collaborator"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('collaborator')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        })
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborator..."
                      loadOptions={aggregatesCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}

              {collaboratorType === 'clt-driver' && (
                <Controller
                  control={control}
                  name="collaborator"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('collaborator')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        })
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborator..."
                      loadOptions={cltDriversCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}

              {collaboratorType === 'carrier' && (
                <Controller
                  control={control}
                  name="collaborator"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('collaborator')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        })
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborator..."
                      loadOptions={carriersCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}
            </FormControl>
          </Stack>

        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Regional
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.regional}>
              <Controller
                control={control}
                name="regional"
                render={({ field }) => (
                  <AsyncSelect
                    {...register('regional')}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                    isMulti={false}
                    cacheOptions
                    defaultOptions
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '1px',
                        borderRadius: '6px',
                        border: state.isFocused ? 'none' : '',
                        boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                      })
                    }}
                    noOptionsMessage={() => 'Não há regionais cadastradas ou encontradas!'}
                    placeholder="Selecione uma regional..."
                    loadOptions={regionalPromiseOptions}
                    isClearable={true}
                  />
                )}
              />
            </FormControl>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Treinamento
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.type}>
              <Select
                {...register('type')}
                name="type"
                placeholder="Selecione..."
                rounded="md"
              >
                {Object.entries(trainingTypesMap).map(([key, value]) => {
                  return (
                    <option key={key} value={key}>{value}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Criar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

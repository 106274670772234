import { Stack } from "@chakra-ui/react";
import { Select } from "components/Inputs/SelectInput";
import { useFormContext } from "react-hook-form";

interface SelectOption {
  key: string
  value: string
  showOption: string
}

interface CollectorsInputsGroupInputs {
  source_collector_id: string
  crossdocking_collector_id: string
  destination_collector_id: string
}

interface CollectorsInputsGroupProps {
  sourceCollectorsOptions?: SelectOption[]
  crossdockingCollectorsOptions?: SelectOption[]
  destinationCollectorsOptions?: SelectOption[]
  isCrossdockingService: boolean
  isFractionedService: boolean
  isLoading: boolean
  isDisabled?: boolean
}

export function CollectorsInputsGroup({
  sourceCollectorsOptions,
  crossdockingCollectorsOptions,
  destinationCollectorsOptions,
  isCrossdockingService,
  isFractionedService,
  isDisabled = false,
  isLoading
}: CollectorsInputsGroupProps) {

  const {
    register,
    formState: {
      errors
    }
  } = useFormContext<CollectorsInputsGroupInputs>()

  return (
    <Stack direction={["column", "column", "row"]} spacing={6} w="full">
      <Stack direction={["column", "column", "row"]} spacing={6} w="full">
        <Select
          {...register("source_collector_id")}
          name="source_collector_id"
          options={sourceCollectorsOptions}
          placeholder="Selecione uma opção..."
          isDisabled={isLoading || isDisabled}
          error={errors.source_collector_id}
          label="Coletador origem"
          required
        />
        {isCrossdockingService && (
          <Select
            {...register("crossdocking_collector_id")}
            name="crossdocking_collector_id"
            label="Coletador crossdocking"
            options={crossdockingCollectorsOptions}
            placeholder="Selecione uma opção..."
            error={errors.crossdocking_collector_id}
            isDisabled={isLoading || isDisabled}
            required
          />
        )}
      </Stack>
      <Select
        {...register("destination_collector_id")}
        name="destination_collector_id"
        label="Coletador destino"
        options={destinationCollectorsOptions}
        placeholder="Selecione uma opção..."
        error={errors.destination_collector_id}
        isDisabled={isLoading || isDisabled}
        required
      />
    </Stack>
  )
}

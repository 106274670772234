import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Flex,
  Button,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye } from 'react-icons/fi'
import { useState, useEffect, useMemo } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../../hooks/filterOptions/useFilterOptions'
import { FaUndoAlt } from 'react-icons/fa'
import { EmptyContentTableAlert } from '../../../Alerts/EmptyContentTableAlert'
import { differenceInMinutes, format, set } from 'date-fns'
import { formatDate } from '../../../../utils/DateFunctions/formatDate'
import { useAuth } from '../../../../hooks/auth/useAuth'
import { RequestedBusinessServiceProps } from '../../../../utils/RequestFunctions/Service/RequestedBusiness/requestRequestedBusinessServiceFunctions'
import { Address } from 'hooks/address/dtos/Address'
import { Collector } from 'hooks/collector/dtos/Collector'

interface IDeliveryBusinessServiceTableProps {
  requestedBusinessServices: RequestedBusinessServiceProps[]
  addresses?: Address[]
  collectors: Collector[]
  refetch: () => void
}
const collectBusinessServiceOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'routeStartDate', value: 'Data Inicial da Rota', checked: false },
  { key: 'sourceCity', value: 'Cidade de Origem', checked: false },
  { key: 'destinationCity', value: 'Cidade de Destino', checked: false },
  { key: 'geloSeco', value: 'Gelo Seco', checked: false },
  { key: 'routeStartHour', value: 'Horário Inicial da Rota', checked: false },
  { key: 'routeEndHour', value: 'Horário Final da Rota', checked: false },
  { key: 'collector', value: 'Coletador', checked: false },
  { key: 'driver', value: 'Motorista', checked: false },
]

const collectBusinessServiceOrderOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'routeStartDate', value: 'Data Inicial da Rota', checked: false },
  { key: 'routeStartHour', value: 'Horário Inicial da Rota', checked: false },
  { key: 'routeEndHour', value: 'Horário Final da Rota', checked: false },
].sort((a, b) => a.value.localeCompare(b.value))

export function DeliveryBusinessServiceTable({
  requestedBusinessServices,
  collectors,
  addresses,
  refetch,
}: IDeliveryBusinessServiceTableProps) {

  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [protocolFiltered, setProtocolFiltered] = useState(null)
  const [customerFiltered, setCustomerFiltered] = useState(null)
  const [routeStartDateFiltered, setRouteStartDateFiltered] = useState(null)
  const [routeStartHourFiltered, setRouteStartHourFiltered] = useState(null)
  const [routeEndHourFiltered, setRouteEndHourFiltered] = useState(null)
  const [sourceCityFiltered, setSourceCityFiltered] = useState(null)
  const [destinationCityFiltered, setDestinationCityFiltered] = useState(null)
  const [geloSecoFiltered, setGeloSecoFiltered] = useState(null)
  const [collectorFiltered, setCollectorFiltered] = useState(null)
  const [driverFiltered, setDriverFiltered] = useState(null)

  const {
    filterOptions,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { userLogged } = useAuth()

  const requestedBusinessServicesFilteredByUserLogged = useMemo(() => {
    if (userLogged?.user_type === 'MOTORISTA') {
      return requestedBusinessServices?.filter(
        (requestedBusinessService) => requestedBusinessService.driver_id === userLogged.driver_id
      )
    }

    return requestedBusinessServices
  }, [requestedBusinessServices, userLogged])

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: requestedBusinessServicesFilteredByUserLogged?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(collectBusinessServiceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(collectBusinessServiceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  const servicesWithFilter = requestedBusinessServicesFilteredByUserLogged?.filter((requestedBusinessService) => {

    const destinationCityFilter = destinationCityFiltered
      ? searchBoxFilter(
        addresses
          ?.filter(
            (address) => requestedBusinessService.destination_address_id.includes(address.id))
          .map(
            (address) => address.cityIDAddress.name
          )
          .join(', '),
        destinationCityFiltered
      )
      : true

    const sourceCityFilter = sourceCityFiltered
      ? searchBoxFilter(
        addresses
          ?.filter(
            (address) => requestedBusinessService.source_address_id.includes(address.id))
          .map(
            (address) => address.cityIDAddress.name
          )
          .join(', '),
        sourceCityFiltered
      )
      : true

    const routeStartHourFilter = routeStartHourFiltered
      ? searchBoxFilter(
        formatDate.handle(
          requestedBusinessService?.route_start_hour,
          'DateOnlyWithHourMinute',
        ),
        routeStartHourFiltered,
      )
      : true

    const routeEndHourFilter = routeEndHourFiltered
      ? searchBoxFilter(
        formatDate.handle(
          requestedBusinessService?.route_end_hour,
          'DateOnlyWithHourMinute',
        ),
        routeEndHourFiltered,
      )
      : true

    const routeStartDateFilter = routeStartDateFiltered
      ? searchBoxFilter(
        formatDate.handle(
          requestedBusinessService.route_start_date,
          'DateWithoutHourToShow',
        ),
        routeStartDateFiltered,
      )
      : true

    const geloSecoFilter = geloSecoFiltered
      ? searchBoxFilter(
        requestedBusinessService.gelo_seco.toString(),
        geloSecoFiltered,
      )
      : true


    const customerFilter = customerFiltered
      ? searchBoxFilter(
        requestedBusinessService.customerIDBusinessService.trading_firstname,
        customerFiltered,
      )
      : true

    const protocolFilter = protocolFiltered
      ? searchBoxFilter(
        requestedBusinessService.serviceIDRequestedBusiness.protocol.toString(),
        protocolFiltered,
      )
      : true

    const requestedBusinessServiceCollector = collectors?.find(
      (collector) => collector.id === requestedBusinessService.source_collector_id
    )?.trading_name

    const collectorFilter = collectorFiltered
      ? searchBoxFilter(
        requestedBusinessServiceCollector,
        collectorFiltered
      )
      : true

    const driver = `${requestedBusinessService.driverIDBusinessService.firstname}
      ${requestedBusinessService.driverIDBusinessService.lastname}`

    const driverFilter = driverFiltered
      ? searchBoxFilter(
        driver,
        driverFiltered
      )
      : true

    return destinationCityFilter && sourceCityFilter && routeStartHourFilter && routeEndHourFilter &&
      routeStartDateFilter && geloSecoFilter && customerFilter && protocolFilter && collectorFilter &&
      driverFilter
  })

  const compareDateNowWithCollectDateAndFinalCollectHour = (
    collectDate: string,
    finalCollectHour: string,
  ) => {
    const [, finalCollectHourSplited] = format(
      new Date(finalCollectHour),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
    ).split('T')
    const [hour, minutes] = finalCollectHourSplited.split(':')

    const differenceInMinutesDateNowWithCollectDateAndFinalCollectHour =
      differenceInMinutes(
        new Date(),
        set(new Date(collectDate), {
          hours: Number(hour),
          minutes: Number(minutes),
          seconds: 0,
        }),
      )

    return differenceInMinutesDateNowWithCollectDateAndFinalCollectHour > 0
  }

  const handlePageChange = (page: number) => {
    return setCurrentPage(page)
  }

  const handleRefetchData = () => {
    return refetch()
  }

  function handleChangeSetter(key: string, value: string) {
    switch (key) {
      case 'protocol':
        return setProtocolFiltered(value)
      case 'customer':
        return setCustomerFiltered(value)
      case 'routeStartDate':
        return setRouteStartDateFiltered(value)
      case 'routeStartHour':
        return setRouteStartHourFiltered(value)
      case 'routeEndHour':
        return setRouteEndHourFiltered(value)
      case 'sourceCity':
        return setSourceCityFiltered(value)
      case 'destinationCity':
        return setDestinationCityFiltered(value)
      case 'geloSeco':
        return setGeloSecoFiltered(value)
      case 'collector':
        return setCollectorFiltered(value)
      case 'driver':
        return setDriverFiltered(value)
      default:
    }
  }

  return (
    <>
      {requestedBusinessServices?.length <= 0 ? (
        <EmptyContentTableAlert
          title="Oops!"
          description="Não há dados para mostrar aqui!"
        />
      ) : (
        <>
          <Stack direction="row">
            <TableFilterButton />
            <Button colorScheme="blue" onClick={handleRefetchData}>
              <Icon as={FaUndoAlt} />
            </Button>
          </Stack>
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>

                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(event) => handleChangeSetter(option.key, event.target.value)}
                  />
                </Flex>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  {collectBusinessServiceOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {servicesWithFilter
                  .slice(offset, offset + Number(itemLimit))
                  .map((requestedBusinessService, index) => {

                    const driver = `${requestedBusinessService.driverIDBusinessService.firstname}
                    ${requestedBusinessService.driverIDBusinessService.lastname}`

                    const sourceCities = Array.from(
                      new Set(
                        addresses
                          ?.filter((address) =>
                            requestedBusinessService.source_address_id.includes(
                              address.id,
                            ),
                          )
                          .map(
                            (address) => address.cityIDAddress.name,
                          ),
                      ),
                    )
                      .join(', ')
                      .toUpperCase()

                    const destinationCities = Array.from(
                      new Set(
                        addresses
                          ?.filter((address) =>
                            requestedBusinessService.source_address_id.includes(
                              address.id,
                            ),
                          )
                          .map(
                            (address) => address.cityIDAddress.name,
                          ),
                      ),
                    )
                      .join(', ')
                      .toUpperCase()

                    const sourceCollectorFiltered = collectors.find(
                      (collector) =>
                        collector.id ===
                        requestedBusinessService
                          .source_collector_id,
                    )?.trading_name

                    return (
                      <Tr
                        key={index}
                        color={
                          compareDateNowWithCollectDateAndFinalCollectHour(
                            requestedBusinessService.route_start_date,
                            requestedBusinessService.route_end_hour,
                          )
                            ? 'red.400'
                            : requestedBusinessService.serviceIDRequestedBusiness.serviceIDCollect.filter(
                              (service) =>
                                service.step === 'VALIDATEUNSUCCESS',
                            ).length > 0
                              ? 'blue.400'
                              : ''
                        }
                        fontWeight={
                          compareDateNowWithCollectDateAndFinalCollectHour(
                            requestedBusinessService
                              .route_start_date,
                            requestedBusinessService
                              .route_start_hour,
                          ) ||
                            requestedBusinessService.serviceIDRequestedBusiness.serviceIDCollect.filter(
                              (service) => service.step === 'VALIDATEUNSUCCESS',
                            ).length > 0
                            ? 'bold'
                            : ''
                        }
                      >
                        <Td isNumeric>
                          {!!userLogged && (
                            <>
                              {userLogged.permissions.includes(
                                'add-collect-business-service',
                              ) && (
                                  <Link
                                    to={`/servico-business/entrega/enderecos/${requestedBusinessService.service_id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEdit3}
                                      fontSize="20"
                                      mr="2"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              <Link
                                to={`/servico-business/detalhes/${requestedBusinessService.service_id}`}
                              >
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  mr="2"
                                  fontSize="20"
                                />
                              </Link>
                            </>
                          )}
                        </Td>
                        <Td fontSize="12">{requestedBusinessService.serviceIDRequestedBusiness.protocol}</Td>
                        <Td fontSize="12">
                          {requestedBusinessService.customerIDBusinessService.trading_firstname.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedBusinessService.route_start_date,
                            'DateWithoutHourToShow',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {sourceCities}
                        </Td>
                        <Td fontSize="12">
                          {destinationCities}
                        </Td>
                        <Td fontSize="12">
                          {requestedBusinessService.gelo_seco}
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedBusinessService.route_start_hour,
                            'DateOnlyWithHourMinute',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedBusinessService.route_end_hour,
                            'DateOnlyWithHourMinute',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {sourceCollectorFiltered?.toUpperCase()}
                        </Td>
                        <Td fontSize="12">
                          {driver.toUpperCase()}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

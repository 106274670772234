import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  createHubReqFunction,
  deleteHubReqFunction,
  editHubReqFunction,
  updateHubResponsibleReqFunction,
} from '../../utils/RequestFunctions/Hubs/requestHubFunctions'
import { useSwal } from '../swal/useSwal'

export const useHubFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()
  const { push: redirectTo } = useHistory()

  const postHub = useMutation('hubPost', createHubReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubs'])
      successMessage('Hub criado com sucesso!')
      redirectTo('/hubs')
    },

    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
    },
  },
  )
  const editHub = useMutation("editHub", editHubReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubs'])
      successMessage('Hub editado com sucesso!')
      redirectTo('/hubs')
    },

    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
    },
  },
  )
  const deleteHub = useMutation("deleteHub", deleteHubReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubs'])
      successMessage('Hub excluído com sucesso!')
    },

    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
    },
  },
  )
  const updateHubResponsible = useMutation("updateHubResponsible", updateHubResponsibleReqFunction, {
    onSuccess: () => {
      queryClient.invalidateQueries(['hubs'])
      successMessage('Responsável pela regional atualizado com sucesso!')
    },
    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
    },
  })

  return {
    postHub,
    editHub,
    deleteHub,
    updateHubResponsible
  }
}

import { Box, Flex, Heading, IconButton, Modal, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getPassivesFeedbacks } from "api/feedbacks/getPassivesFeedbacks";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaExchangeAlt, FaPlus } from "react-icons/fa";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { CreatePassiveFeedback } from "./components/CreatePassiveFeedback";
import { KanbanPassiveFeedbackColumn } from "./components/KanbanPassiveFeedbackColumn";


export function PassiveFeedbacks() {
  const { userLogged } = useAuth()
  const history = useHistory()

  const userCanViewPassivesFeedbacks = userLogged?.permissions?.includes(
    'view-passives-feedbacks'
  )

  const userCanCreatePassiveFeedback = userLogged?.permissions?.includes(
    'create-passive-feedback'
  )

  useEffect(() => {
    if (!userCanViewPassivesFeedbacks) history.push('/')
  }, [history, userCanViewPassivesFeedbacks])

  const {
    data: scheduledPassivesFeedbacksResult,
    isLoading: isScheduledPassivesFeedbacksResultLoading,
    hasNextPage: hasNextPageScheduledPassivesFeedbacks,
    fetchNextPage: fetchNextPageScheduledPassivesFeedbacks,
    isFetchingNextPage: isFetchingNextPageScheduledPassivesFeedbacks,
  } = useInfiniteQuery({
    queryKey: ['scheduled-passives-feedbacks'],
    queryFn: ({ pageParam = 1 }) => getPassivesFeedbacks({
      currentPage: pageParam,
      pageSize: 10,
      status: 'scheduled'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  const {
    data: donePassivesFeedbacksResult,
    isLoading: isDonePassivesFeedbacksResultLoading,
    hasNextPage: hasNextPageDonePassivesFeedbacks,
    fetchNextPage: fetchNextPageDonePassivesFeedbacks,
    isFetchingNextPage: isFetchingNextPageDonePassivesFeedbacks,
  } = useInfiniteQuery({
    queryKey: ['done-passives-feedbacks'],
    queryFn: ({ pageParam = 1 }) => getPassivesFeedbacks({
      currentPage: pageParam,
      pageSize: 10,
      status: 'done'
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })



  const columnsPassivesFeedbacks = [
    {
      title: 'Agendado',
      passivesFeedbacks: scheduledPassivesFeedbacksResult?.pages.flatMap((page) => page.passivesFeedbacks) || [],
      passivesFeedbacksCount: scheduledPassivesFeedbacksResult?.pages[0]?.meta?.count,
      color: 'orange.300',
      hasNextPage: hasNextPageScheduledPassivesFeedbacks,
      fetchNextPage: fetchNextPageScheduledPassivesFeedbacks,
      isFetchingNextPage: isFetchingNextPageScheduledPassivesFeedbacks,
      isLoading: isScheduledPassivesFeedbacksResultLoading,
    },
    {
      title: 'Realizado',
      passivesFeedbacks: donePassivesFeedbacksResult?.pages.flatMap((page) => page.passivesFeedbacks) || [],
      passivesFeedbacksCount: donePassivesFeedbacksResult?.pages[0]?.meta?.count,
      color: 'green.300',
      hasNextPage: hasNextPageDonePassivesFeedbacks,
      fetchNextPage: fetchNextPageDonePassivesFeedbacks,
      isFetchingNextPage: isFetchingNextPageDonePassivesFeedbacks,
      isLoading: isDonePassivesFeedbacksResultLoading,
    },

  ];

  const {
    isOpen: isCreatePassiveFeedbackOpen,
    onOpen: onOpenCreatePassiveFeedback,
    onClose: onCloseCreatePassiveFeedback
  } = useDisclosure()

  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Feedbacks Passivos</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar tabela"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/feedbacks-passivos/tabela')}
          />
          {userCanCreatePassiveFeedback && (
            <IconButton
              aria-label="Criar feedback"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreatePassiveFeedback}
            />
          )}
        </Flex>
        <Modal
          isOpen={isCreatePassiveFeedbackOpen}
          onClose={onCloseCreatePassiveFeedback}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <CreatePassiveFeedback onCloseModal={onCloseCreatePassiveFeedback} />
        </Modal>
      </Flex>
      <Flex gap={4} overflowX="auto" mt={4}>
        {columnsPassivesFeedbacks.map((passiveFeedback, index) => (
          <KanbanPassiveFeedbackColumn
            key={index}
            passiveFeedback={passiveFeedback}
          />
        ))}
      </Flex>
    </Box>
  )
}

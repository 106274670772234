import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteAdvertenceProps {
  routeParams: {
    advertenceId: string
  }
}

export async function completeAdvertence({
  routeParams
}: CompleteAdvertenceProps) {
  try {
    await api.patch(`/advertences/${routeParams.advertenceId}/complete`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

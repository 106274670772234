import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, Divider, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, Icon, useDisclosure, Modal, ModalOverlay } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { Input } from "components/Inputs/Input"
import { Pagination } from "components/Pagination/Pagination"

import { differenceInMinutes, format, isBefore, isSameDay, set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { Occurrence } from "hooks/occurrences/dtos/Occurrence"
import { Services } from "hooks/services/dtos/InBoardService"
import { useInBoardServices } from "hooks/services/useInBoardServices"
import { Fragment, useEffect, useMemo, useReducer, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { FaClock, FaExclamation, FaEye, FaPen, FaUndoAlt } from "react-icons/fa"
import { useQueryClient } from "react-query"
import { Link, useHistory } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"
import { ConfirmDestinationBranch } from "./components/ConfirmDestinationBranch"

interface InBoardServicesFilterInputs {
  protocol?: string
  collect_date?: string
  board_date?: string
  board_hour?: string
  shipping?: string
  source_branch?: string
  destination_branch?: string
  customer?: string
  source_city?: string
  destination_city?: string
  delivery_date?: string
  collector?: string
}

interface ActionPayload {
  inBoardServiceData?: InBoardServicesFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-in-board-services-data' | 'set-in-board-services-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-in-board-services-current-page') {
    return {
      inBoardServiceData: { ...state.inBoardServiceData },
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

const inBoardServicesListFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'collect_date', value: 'Data da coleta', checked: false },
  { key: 'board_date', value: 'Data limite de embarque', checked: false },
  { key: 'board_hour', value: 'Horário limite de embarque', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'source_branch', value: 'Base de origem', checked: false },
  { key: 'destination_branch', value: 'Base de destino', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'source_city', value: 'Cidade(s) de origem', checked: false },
  { key: 'destination_city', value: 'Cidade(s) de destino', checked: false },
  { key: 'delivery_date', value: 'Data da entrega', checked: false },
  { key: 'collector', value: 'Coletador de origem', checked: false },
]

function compareBoardDateWithDateNow(
  boardDate: string,
  boardHour: string
) {
  const [collectEndHours, collectEndMinutes] = formatDate
    .handle(boardHour, 'DateOnlyWithHourMinute').split(':')


  const differenceToNow = differenceInMinutes(
    new Date(),
    set(new Date(boardDate), {
      hours: Number(collectEndHours),
      minutes: Number(collectEndMinutes)
    })
  )

  return differenceToNow > 0
}

export function InBoardServicesList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const [inBoardServicesDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )
  const [serviceId, setServiceId] = useState('')
  const [destinationBranchService, setDestinationBranchService] = useState('')

  const { userLogged } = useAuth()
  const { push: redirectTo } = useHistory()

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()


  const filterOptionsByUserLogged = useMemo(() => {
    return inBoardServicesListFilterOptions.filter(option => {
      const userLoggedCollectorFilter = (userLogged?.user_type === 'COLETADOR' ||
        userLogged?.user_type === 'MOTORISTA')
        ? option.key !== 'collector'
        : true

      return userLoggedCollectorFilter
    })
  }, [userLogged])

  useEffect(() => {
    onLoadSetFilterOptions(filterOptionsByUserLogged)
  }, [onLoadSetFilterOptions, filterOptionsByUserLogged])



  const { register, control } = useForm<InBoardServicesFilterInputs>()

  const [
    protocol,
    collectDate,
    boardDate,
    boardHour,
    shipping,
    sourceBranch,
    destinationBranch,
    customer,
    sourceCity,
    destinationCity,
    deliveryDate,
    collector
  ] = useWatch({
    control,
    name: [
      'protocol',
      'collect_date',
      'board_date',
      'board_hour',
      'shipping',
      'source_branch',
      'destination_branch',
      'customer',
      'source_city',
      'destination_city',
      'delivery_date',
      'collector'
    ]
  })

  const {
    data: inBoardServicesData,
    isFetching: isFetchingInBoardServicesData
  } = useInBoardServices({
    queryParams: {
      ...inBoardServicesDataState.inBoardServiceData,
      collector_id: userLogged?.collector_id,
      current_page: inBoardServicesDataState.currentPage,
      page_size: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!inBoardServicesDataState.currentPage
    }
  })


  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: inBoardServicesData?.total_pages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-in-board-services-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch({
        type: 'set-in-board-services-data',
        payload: {
          inBoardServiceData: {
            protocol: protocol,
            collect_date: collectDate,
            board_date: boardDate,
            board_hour: boardHour,
            shipping: shipping,
            source_branch: sourceBranch,
            destination_branch: destinationBranch,
            customer: customer,
            source_city: sourceCity,
            destination_city: destinationCity,
            delivery_date: deliveryDate,
            collector: collector
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounce)
  }, [
    protocol,
    collectDate,
    boardDate,
    boardHour,
    shipping,
    sourceBranch,
    destinationBranch,
    customer,
    sourceCity,
    destinationCity,
    deliveryDate,
    collector,
    setCurrentPage
  ])

  const handleChangePage = (page: number) => setCurrentPage(page)

  const queryClient = useQueryClient()

  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['inBoardServices'])
  }
  const userHasStartBoardServicePermission =
    userLogged?.permissions.includes('add-boarding-service')

  const {
    isOpen: isConfirmDestinationBranchModalOpen,
    onOpen: onOpenConfirmDestinationBranchModal,
    onClose: onCloseConfirmDestinationBranchModal
  } = useDisclosure()

  async function handleConfirmDestinationBranch(service: Services, destinationBranch: string) {
    setServiceId(service.id)
    setDestinationBranchService(destinationBranch)
    const serviceHasCrossdocking = service.serviceIDRequested.crossdocking_collector_id !== null

    const destinationBranchBoardConfirmed = service.is_destination_board_branch_confirmed
      ? redirectTo(`/servicos/embarques/${service.id}`)
      : onOpenConfirmDestinationBranchModal()

    const crossdockingDestinationBoardBranchConfirmed = service.is_crossdocking_destination_board_branch_confirmed
      ? redirectTo(`/servicos/embarques/${service.id}`)
      : onOpenConfirmDestinationBranchModal()

    if (serviceHasCrossdocking) {
      const crossdockingBoardAlreadyFinished = service.serviceIDBoard.find(board => {
        return board.step === 'DONE' && board.collector_id === service.serviceIDRequested.crossdocking_collector_id
      })

      if (crossdockingBoardAlreadyFinished) {
        return destinationBranchBoardConfirmed
      } else {
        return crossdockingDestinationBoardBranchConfirmed
      }

    } else {
      return destinationBranchBoardConfirmed
    }

  }


  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Heading size='md'>Serviços em Embarque</Heading>

        <Divider />

        <Flex w={['full', 'full', 'min']} gap={2}>
          <TableFilterButton />
          <Button colorScheme="blue" onClick={handleRefetchTableData}>
            <Icon as={FaUndoAlt} />{' '}
          </Button>
        </Flex>
        <Modal
          isOpen={isConfirmDestinationBranchModalOpen}
          onClose={onCloseConfirmDestinationBranchModal}
          isCentered
        >
          <ModalOverlay />
          <ConfirmDestinationBranch
            onCloseModal={onCloseConfirmDestinationBranchModal}
            serviceId={serviceId}
            destinationBranch={destinationBranchService}
          />

        </Modal>

        {filterOptions
          .filter(option => option.checked)
          .map(option => {
            return (
              <Fragment key={option.key}>
                {option.key === 'collect_date' ? (
                  <Input
                    {...register('collect_date')}
                    name='collect_date'
                    label='Data da coleta'
                    type='date'
                    size='sm'
                  />
                ) : (
                  option.key === 'board_date' ? (
                    <Input
                      {...register('board_date')}
                      name='board_date'
                      label='Data do embarque'
                      type='date'
                      size='sm'
                    />
                  ) : (
                    option.key === 'board_hour' ? (
                      <Input
                        {...register('board_hour')}
                        name='board_hour'
                        label='Horário do embarque'
                        type='time'
                        size='sm'
                      />
                    ) : (
                      option.key === 'delivery_date' ? (
                        <Input
                          {...register('delivery_date')}
                          name='delivery_date'
                          label='Data da entrega'
                          type='date'
                          size='sm'
                        />
                      ) : (
                        <Input
                          {...register(option.key as keyof InBoardServicesFilterInputs)}
                          name={option.key}
                          label={`Buscar ${option.value}`}
                          size='sm'
                          placeholder='Buscar...'
                        />
                      )
                    )))}
              </Fragment>
            )
          })}

        {isFetchingInBoardServicesData ? (
          <Spinner />
        ) : (
          <TableContainer>

            <Table size='sm' variant='striped'>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                  {inBoardServicesListFilterOptions.map(option => {
                    return (
                      <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {inBoardServicesData?.services?.map(service => {

                  const collectDelayIntercurrences = [
                    'ATRASO NA COLETA',
                    'NO SHOW'
                  ]

                  const serviceCollectDelayOccurrence =
                    service.occurrenceIDService
                      ?.filter(occurrence => collectDelayIntercurrences.includes(occurrence.intercurrence))
                      .reduce((acc, curr) => {
                        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
                      }, {} as Occurrence)

                  const collectDate = serviceCollectDelayOccurrence?.occurrence_date
                    ? formatDate.handle(serviceCollectDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
                    : formatDate.handle(service.serviceIDRequested.collect_date, "DateWithoutHourToShow")

                  const deliveryDelayOccurrences = [
                    'ATRASO NA ENTREGA',
                    'CANCELAMENTO DE VOO',
                    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
                    'ATRASO NA TRANSFERÊNCIA',
                    'ATRASO NA LIBERAÇÃO'
                  ]

                  const serviceDeliveryDelayOccurrence =
                    service.occurrenceIDService
                      .filter(occurrence => deliveryDelayOccurrences.includes(occurrence.intercurrence))
                      .reduce((acc, curr) => {
                        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
                      }, {} as Occurrence)

                  const deliveryDate = serviceDeliveryDelayOccurrence?.occurrence_date
                    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
                    : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

                  let boardDate = ''
                  let boardHour = ''
                  let shipping = ''
                  let sourceBranch = ''
                  let destinationBranch = ''
                  let sourceCollector = ''

                  const serviceHasCrossdocking = service.serviceIDRequested.crossdocking_collector_id !== null

                  let isServiceDelayed = false

                  if (serviceHasCrossdocking) {
                    const crossdockingBoardAlreadyFinished = service.serviceIDBoard.find(board => {
                      return board.step === 'DONE' && board.collector_id === service.serviceIDRequested.crossdocking_collector_id
                    })

                    const isBoardDateBeforeToToday = isBefore(
                      new Date(service.serviceIDRequested.board_date),
                      new Date()
                    )

                    const isBoardDateSameToToday = isSameDay(
                      new Date(service.serviceIDRequested.board_date),
                      new Date()
                    )

                    if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
                      isServiceDelayed = true
                    } else if (isBoardDateSameToToday) {
                      const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

                      console.log(differenceInMinutes(
                        set(new Date(), {
                          hours: boardHours,
                          minutes: boardMinutes
                        }), new Date()
                      ))

                      isServiceDelayed = differenceInMinutes(
                        set(new Date(), {
                          hours: boardHours,
                          minutes: boardMinutes
                        }), new Date()
                      ) < 240
                    }

                    if (crossdockingBoardAlreadyFinished) {
                      boardDate = service.serviceIDRequested.board_date
                      boardHour = service.serviceIDRequested.board_hour

                      shipping = service.shippings.find(ship => {
                        return ship.id === service.serviceIDRequested.sourceCrossdockingBranchIDService.shipping_id
                      })?.company_name
                      sourceBranch = service.serviceIDRequested.sourceCrossdockingBranchIDService.nickname
                      destinationBranch = service.serviceIDRequested.destinationBranchIDService.nickname
                      sourceCollector = service.serviceIDRequested.crossdockingCollectorIDService.trading_name
                    } else {
                      boardDate = service.serviceIDRequested.crossdocking_board_date
                      boardHour = service.serviceIDRequested.crossdocking_board_hour
                      shipping = service.shippings.find(ship => {
                        return ship.id === service.serviceIDRequested.sourceBranchIDService.shipping_id
                      })?.company_name
                      sourceBranch = service.serviceIDRequested.sourceBranchIDService.nickname
                      destinationBranch = service.serviceIDRequested.destinationCrossdockingBranchIDService.nickname
                      sourceCollector = service.serviceIDRequested.sourceCollectorIDService.trading_name
                    }
                  } else {
                    boardDate = service.serviceIDRequested.board_date
                    boardHour = service.serviceIDRequested.board_hour
                    shipping = service.shippings.find(ship => {
                      return ship.id === service.serviceIDRequested.sourceBranchIDService.shipping_id
                    })?.company_name
                    sourceBranch = service.serviceIDRequested.sourceBranchIDService.nickname
                    destinationBranch = service.serviceIDRequested.destinationBranchIDService.nickname
                    sourceCollector = service.serviceIDRequested.sourceCollectorIDService.trading_name
                  }

                  let tableLineColor = ''

                  const isCurrentDateBiggerThanBoardDate =
                    compareBoardDateWithDateNow(boardDate, boardHour)

                  if (
                    serviceCollectDelayOccurrence.id ||
                    serviceDeliveryDelayOccurrence.id
                  ) {
                    tableLineColor = 'yellow.300'
                  } else if (isCurrentDateBiggerThanBoardDate) {
                    tableLineColor = 'red.300'
                  }

                  return (
                    <Tr
                      key={service.id}
                      color={tableLineColor}
                    >
                      <Td>
                        {service?.serviceIDRequested?.budgetIDService?.priority_budget && (
                          <Icon as={FaExclamation} color={'red.500'} />
                        )}
                        {isServiceDelayed && (
                          <Icon as={FaClock} color={'red.500'} />
                        )}
                      </Td>
                      <Td>
                        <Flex>
                          {userHasStartBoardServicePermission && (
                            <Button
                              onClick={() => handleConfirmDestinationBranch(service, destinationBranch)}
                              variant='unstyled'
                            >
                              <Flex w='full' h='full' align='center' justify='center'>
                                <Icon as={FaPen} />
                              </Flex>
                            </Button>
                          )}
                          <Button
                            as={Link}
                            to={`/servico/detalhes/${service.id}`}
                            variant='unstyled'
                          >
                            <Flex w='full' h='full' align='center' justify='center'>
                              <Icon as={FaEye} />
                            </Flex>
                          </Button>
                        </Flex>
                      </Td>
                      <Td>{service.protocol}</Td>
                      <Td>{collectDate}</Td>
                      <Td>{formatDate.handle(boardDate, 'DateWithoutHourToShow')}</Td>
                      <Td>{formatDate.handle(boardHour, 'DateOnlyWithHourMinute')}</Td>
                      <Td>{shipping}</Td>
                      <Td>{sourceBranch}</Td>
                      <Td>{destinationBranch}</Td>
                      <Td>{service.customerIDService.trading_firstname}</Td>
                      <Td>{Array.from(new Set(service.source_cities.map(city => city.name))).join(', ')}</Td>
                      <Td>{Array.from(new Set(service.destination_cities.map(city => city.name))).join(', ')}</Td>
                      <Td>{deliveryDate}</Td>
                      <Td>{sourceCollector}</Td>
                    </Tr>
                  )
                })}

              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </Flex>
    </Box>


  )
}

import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface DeleteDispatchStockMaterialParams {
  dispatchStockId: string
  materialId: string
}

export async function deleteDispatchStockMaterial({
  dispatchStockId,
  materialId,
}: DeleteDispatchStockMaterialParams) {
  try {
    await api.delete(`/dispatch-stocks/${dispatchStockId}/materials/${materialId}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


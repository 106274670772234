import { Collector } from 'hooks/collector/dtos/Collector'
import { CustomerProps } from '../../../../contexts/CustomerContext'
import { ServiceProps } from '../../../../contexts/ServiceContext'
import { api } from '../../../../services/api'
import { IDriverProps } from '../../../../services/getFunctions/driver/getDrivers'
import { BusinessBudgetProps } from '../../BusinessBudget/requestBusinessBudgetFunctions'

export interface IAddressesScheduleProps {
  address_id: string;
  planned_hour: string;
}

export type RequestedBusinessServiceProps = {
  id: string
  customer_id: string
  business_budget_id: string
  situation: string
  source_collector_id: string
  destination_collector_id: string
  provider_id: string | null
  driver_id: string
  service_id: string
  source_address_id: string[]
  destination_address_id: string[]
  vehicle: string
  service_type: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  route_start_date: string
  route_start_hour: string
  route_end_hour: string
  deadline: number
  observation: string
  is_recurrent: boolean
  days_of_week: number[] | null
  source_addresses_schedule: string[] | null
  destination_addresses_schedule: string[] | null
  recurrence: 'EVERY OTHER DAY' | 'ALTERNATE WEEKLY'
  driverIDBusinessService: IDriverProps
  customerIDBusinessService: CustomerProps
  serviceIDRequestedBusiness: ServiceProps
  sourceCollectorIDBusinessService: Collector
  businessBudgetIDBusinessService: BusinessBudgetProps
  destinationCollectorIDBusinessService: Collector
  automation_start_date: string
}

export type RequestedBusinessServiceInputProps = {
  customer_id: string
  business_budget_id: string
  situation: string
  source_collector_id: string
  destination_collector_id: string
  provider_id: string | null
  driver_id: string
  source_address_id: string[]
  destination_address_id: string[]
  vehicle: string
  service_type: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  route_start_date: Date
  route_start_hour: Date
  route_end_hour: Date
  deadline: number
  is_recurrent: boolean
  days_of_week: number[] | null
  observation: string
  recurrence?: 'EVERY OTHER DAY' | 'ALTERNATE WEEKLY'
  source_addresses_schedule: Array<IAddressesScheduleProps>
  destination_addresses_schedule: Array<IAddressesScheduleProps>
  automation_start_date: string
}

export type EditRequestedBusinessServiceReqProps = {
  id: string
  input: RequestedBusinessServiceInputProps
}

interface GetAllRequestedBusinessServiceReqFunctionProps {
  queryParams: {
    step: 'collect' | 'delivery'
  }
}

async function getAllRequestedBusinessServiceReqFunction({
  queryParams
}: GetAllRequestedBusinessServiceReqFunctionProps): Promise<
  RequestedBusinessServiceProps[]
> {
  try {
    const { data } = await api.get<RequestedBusinessServiceProps[]>('/requested-business-service', {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function getOneRequestedBusinessServiceReqFunction(
  id: string,
): Promise<RequestedBusinessServiceProps> {
  try {
    const { data } = await api.get<RequestedBusinessServiceProps>(
      `/requested-business-service/${id}`,
    )

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function createRequestedBusinessServiceReqFunction(
  input: RequestedBusinessServiceInputProps,
) {
  try {
    const { data } = await api.post('/requested-business-service', {
      ...input,
      is_business: true,
    })

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function editRequestedBusinessServiceReqFunction({
  id,
  input,
}: EditRequestedBusinessServiceReqProps) {
  try {
    const { data } = await api.put(`/requested-business-service/${id}`, input)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function deleteRequestedBusinessServiceReqFunction(id: string) {
  try {
    const { data } = await api.delete(`/requested-business-service/${id}`)

    return data
  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export {
  getAllRequestedBusinessServiceReqFunction,
  getOneRequestedBusinessServiceReqFunction,
  createRequestedBusinessServiceReqFunction,
  editRequestedBusinessServiceReqFunction,
  deleteRequestedBusinessServiceReqFunction,
}

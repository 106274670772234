import { Flex, Box, Heading, Button, Link as ChakraLink } from '@chakra-ui/react'
import { useState, useEffect } from 'react'

import { AllocatedServiceTable } from '../../../components/Tables/Service/Allocated/AllocatedServiceTable'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'

import { useService } from '../../../hooks/services/service'
import { useBranch } from '../../../hooks/branch/useBranch'
import { useAddresses } from '../../../hooks/address/useAddresses'

export function AllocatedServiceList() {
  const [isLoading, setIsLoading] = useState(true)

  const {
    servicesBySteps: { data: services, isLoading: isServiceLoading, refetch },
  } = useService(null, true, false, false, ["toAllocateService"])
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)

  useEffect(() => {
    function setLoadingFalse() {
      if (!isAddressesLoading && !isServiceLoading && !isBranchLoading) {
        setIsLoading(false)
      }
    }
    setLoadingFalse()
  }, [isAddressesLoading, isServiceLoading, isBranchLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

    <Flex w="100%" direction={['column', 'column', 'row']}>
      <Box
        overflowX="auto"
        flex="1"
        borderRadius="8"
        bg="white"
        p={['6', '8']}
      >
        <Flex
          mb="8"
          justify="space-between"
          align="center"
          direction={['column', 'column', 'row']}
        >
          <Heading size="lg" fontFamily="poppins">
            Serviços a Alocar
          </Heading>
          <Flex
            w='full'
            justifyContent='flex-end'
            direction={["column", "column", "row"]}
          >
            <Button
              colorScheme='blue'
              as={ChakraLink}
              href="https://loglifeacademy-my.sharepoint.com/:w:/g/personal/cainagoncalves_loglifelogistica_com_br/EeFJYwiqh5RBhDN-Rf8lxusBkb_rJvJ1gj5zrT_9oxodLw?e=Kfb6w3"
              isExternal
            >
              POP - Alocação
            </Button>
          </Flex>
        </Flex>
        <AllocatedServiceTable
          services={services}
          addresses={addresses}
          branches={branches}
          refetch={refetch}
        />
      </Box>
    </Flex>

  )
}

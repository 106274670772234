import { Flex, Box, Heading, Link as ChakraLink, Button } from '@chakra-ui/react'
import { useState, useEffect } from 'react'

import { ValidateServiceTable } from '../../../components/Tables/Service/Validate/ValidadteServiceTable'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'

import { useService } from '../../../hooks/services/service'
import { useBranch } from '../../../hooks/branch/useBranch'
import { useAddresses } from '../../../hooks/address/useAddresses'

export function ValidadteServiceList() {
  const [isLoading, setIsLoading] = useState(true)

  const {
    servicesBySteps: { data: services, isLoading: isServiceLoading, refetch },
  } = useService(null, true, false, false, ["toBoardValidate"])
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)

  useEffect(() => {
    function setLoadingFalse() {
      if (!isAddressesLoading && !isServiceLoading && !isBranchLoading) {
        setIsLoading(false)
      }
    }
    setLoadingFalse()
  }, [isAddressesLoading, isServiceLoading, isBranchLoading])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

    <Flex w="100%" direction={['column', 'column', 'row']}>
      <Box
        overflowX="auto"
        flex="1"
        borderRadius="8"
        bg="white"
        p={['6', '8']}
      >
        <Flex
          mb="8"
          justify="space-between"
          align="center"
          direction={['column', 'column', 'row']}
        >
          <Heading size="lg" fontFamily="poppins">
            Serviços a Validar Embarque
          </Heading>
          <Flex
            w='full'
            justifyContent='flex-end'
            direction={["column", "column", "row"]}
          >
            <Button
              colorScheme='blue'
              as={ChakraLink}
              href="https://loglifeacademy-my.sharepoint.com/:b:/g/personal/cainagoncalves_loglifelogistica_com_br/EagaIIaRR0VEhRSSt8B9T2oBQq_Te0sN2LkkD_Wo2Sv0Dw?e=SEl81R"
              isExternal
            >
              POP - Validação de Embarque
            </Button>
          </Flex>
        </Flex>
        {!!branches && (
          <ValidateServiceTable
            services={services}
            addresses={addresses}
            branches={branches}
            refetch={refetch}
          />
        )}
      </Box>
    </Flex>

  )
}

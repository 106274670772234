import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverBankDetailsBody {
  account: string
  agency: string
  bank: string
}

interface SendCltDriverBankDetailsProps {
  body: SendCltDriverBankDetailsBody
  cltDriverId: string
}

export async function sendCltDriverBankDetails({
  body,
  cltDriverId
}: SendCltDriverBankDetailsProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/hire/bank-details`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Button, Flex } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { set } from "date-fns";
import { AddressInput, useAddressesFunctions } from "hooks/address/useAddressesFunctions";
import { useCity } from "hooks/city/useCity";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { useCep } from "hooks/useCep";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { statesWithUfList } from "utils/CustomLists/statesWithUfList";
import { AddressForm, AddressFormInputs } from "./components/AddressForm";
import * as yup from "yup"
import { isCnpjValid, isCpfValid } from "utils/cpfCnpjValidateFunctions";
import { yupResolver } from "@hookform/resolvers/yup";

const transformTimeInputInDateOrNullValue = (time: string | undefined) => {
  if (!time) return null

  const [hours, minutes] = time.split(":").map(value => Number(value))

  const timestamp = set(new Date(), {
    hours,
    minutes
  })

  return timestamp
}

const addressFormSchema = yup.object().shape({
  situation: yup.string().required("Campo obrigatório"),
  customer_id: yup.string().required("Campo obrigatório"),
  type: yup.string().required("Campo obrigatório"),
  cnpj_cpf: yup
    .string()
    .when('type', {
      is: 'PESSOA FÍSICA',
      then: yup.string().transform(isCpfValid).required('CPF inválido'),
    })
    .when('type', {
      is: 'PESSOA JURÍDICA',
      then: yup.string().transform(isCnpjValid).required('CNPJ inválido'),
    }),
  trading_name: yup.string().when('type', {
    is: 'PESSOA JURÍDICA',
    then: yup.string().required("Campo obrigatório")
  }),
  branch: yup.string().required("Campo obrigatório"),
  responsible_name: yup.string().required("Campo obrigatório"),
  responsible_email: yup.string(),
  responsible_telephone: yup.string().required("Campo obrigatório"),
  cep: yup.string().required("Campo obrigatório"),
  street: yup.string().required("Campo obrigatório"),
  number: yup.string().required("Campo obrigatório"),
  complement: yup.string(),
  neighborhood: yup.string().required("Campo obrigatório"),
  state: yup.string().required("Campo obrigatório"),
  city_id: yup.string().required("Campo obrigatório"),
  reference_point: yup.string(),
  icms: yup.string().required("Campo obrigatório"),
  has_business_operation: yup.string().required("Campo obrigatório"),
  business_open: yup.string().when("has_business_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  business_close: yup.string().when("has_business_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  has_saturday_operation: yup.string().required("Campo obrigatório"),
  saturday_open: yup.string().when("has_saturday_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  saturday_close: yup.string().when("has_saturday_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  has_sunday_operation: yup.string().required("Campo obrigatório"),
  sunday_open: yup.string().when("has_sunday_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  sunday_close: yup.string().when("has_sunday_operation", {
    is: "SIM",
    then: yup.string().required("Campo obrigatório")
  }),
  deadline: yup.number().typeError('Campo Obrigatório').required('Campo Obrigatório'),
  observation: yup.string()
})


export function CreateAddress() {

  const {
    createAddress: {
      mutateAsync: createAddress,
      isLoading: isCreateAddressLoading
    }
  } = useAddressesFunctions()

  const formMethods = useForm<AddressFormInputs>({
    defaultValues: {
      situation: "ATIVO",
      has_business_operation: "SIM"
    },
    resolver: yupResolver(addressFormSchema)
  })

  const { watch, setValue, handleSubmit, formState: { isSubmitting } } = formMethods

  const { push: redirectTo } = useHistory()
  const { confirmMessage, standardMessage } = useSwal()
  const { warnMessage } = useToastify()

  const cepValue = watch("cep")
  const isValidCep = cepValue ? !cepValue.includes("_") : false
  const cepFormatted = isValidCep ? cepValue.split("-").join("") : null

  const {
    data: cepData, isFetching: isFetchingCepData, refetch: refetchCep
  } = useCep({ cep: cepFormatted, useQueryOptions: { enabled: isValidCep } })

  useEffect(() => {
    if (isValidCep) refetchCep()
  }, [refetchCep, isValidCep, cepValue])

  const isErrorOnConsultCep = cepData?.erro

  useEffect(() => {
    if (isErrorOnConsultCep && !isFetchingCepData) {
      warnMessage("Ocorreu um erro ao consultar o cep informado!")
    }
  }, [isErrorOnConsultCep, warnMessage, isFetchingCepData])

  const stateFromCepUf = Object.entries(statesWithUfList).find(([key, value]) => value.uf === cepData?.uf)?.[0]

  const stateSelected = watch("state")
  const isStateSelected = !!stateSelected

  const {
    cities: { data: cities, isFetching: isFetchingCities }
  } = useCity(null, isStateSelected, false)

  const cityFilteredByCep = cities?.find(city => city.name === cepData?.localidade?.toLocaleUpperCase())?.id

  useEffect(() => {
    if (cepData && !isErrorOnConsultCep && !isFetchingCepData && !isFetchingCities) {
      setValue('street', cepData.logradouro)
      setValue('complement', cepData.complemento)
      setValue('neighborhood', cepData.bairro)

      if (stateFromCepUf) setValue('state', stateFromCepUf?.toLocaleUpperCase())
      if (cityFilteredByCep) setValue('city_id', cityFilteredByCep)
    }
  }, [cepData, stateFromCepUf, setValue, cityFilteredByCep, isErrorOnConsultCep, isFetchingCepData, isFetchingCities])

  useEffect(() => {
    if (!isErrorOnConsultCep && !isFetchingCities && !isFetchingCepData && isStateSelected) {
      const timeout = setTimeout(() => {
        if (!cityFilteredByCep) {
          warnMessage("O cep informado é de uma cidade não cadastrada no sistema. Para criação do endereço, é necessário cadastrá-la anteriormente!")
          setValue('city_id', '')
        }
      }, 2000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [setValue, cityFilteredByCep, warnMessage, isErrorOnConsultCep, isFetchingCities, isFetchingCepData, isStateSelected])

  async function handleCreateAddress({
    has_business_operation,
    has_sunday_operation,
    has_saturday_operation,
    ...values
  }: AddressFormInputs) {

    const hasCreateAddress = await confirmMessage({ title: "Deseja criar um endereço?" })

    const saturdayClose = transformTimeInputInDateOrNullValue(values.saturday_close)
    const businessClose = transformTimeInputInDateOrNullValue(values.business_close)
    const businessOpen = transformTimeInputInDateOrNullValue(values.business_open)
    const saturdayOpen = transformTimeInputInDateOrNullValue(values.saturday_open)
    const sundayClose = transformTimeInputInDateOrNullValue(values.sunday_close)
    const sundayOpen = transformTimeInputInDateOrNullValue(values.sunday_open)

    const createAddressInputData: AddressInput = {
      ...values,
      business_open: businessOpen!,
      business_close: businessClose!,
      saturday_open: saturdayOpen,
      saturday_close: saturdayClose,
      sunday_open: sundayOpen,
      sunday_close: sundayClose,
      has_own_board_landing: values.has_own_board_landing === 'SIM'
    }

    if (hasCreateAddress) {
      await createAddress(createAddressInputData, {
        onSuccess: () => redirectTo("/enderecos")
      })
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  if (isCreateAddressLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      title="Adicionar endereço"
      onSubmit={handleSubmit(handleCreateAddress)}
    >
      <FormProvider {...formMethods}>
        <AddressForm
          slug="adicionar"
          cities={cities}
        />
      </FormProvider>
      <Flex
        direction={["column", "column", "row"]}
        justify={["center", "center", "flex-end"]}
        mt={6}
        gap={4}
      >

        <Button
          type="submit"
          colorScheme="blue"
          w={["full", "full", "min"]}
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
        <Button
          as={Link}
          to="/enderecos"
          colorScheme="gray"
          w={["full", "full", "min"]}
          isLoading={isSubmitting}
        >
          Endereços
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"
import { PurchaseOrderStatus, PurchaseOrder } from './purchaseOrder'


interface GetPurchaseOrdersQueryParams {
  pageSize: string
  currentPage: string
  status: PurchaseOrderStatus | 'all'
  protocol?: string
}

export interface GetPurchaseOrdersResponse {
  purchaseOrders: PurchaseOrder[]
  meta: {
    totalPages: number
    pageSize: number
    currentPage: number
  }
}

export async function getPurchaseOrders({
  currentPage,
  pageSize,
  status,
  protocol
}: GetPurchaseOrdersQueryParams) {
  try {
    const response = await api.get<GetPurchaseOrdersResponse>('/purchase-orders', {
      params: {
        currentPage,
        pageSize,
        status,
        protocol
      }
    })

    return response.data

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

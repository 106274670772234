import { User } from 'hooks/user/dtos/User'
import { createContext, ReactNode } from 'react'
import swal from 'sweetalert'
import { api } from '../services/api'
import { IHubsProps } from '../utils/RequestFunctions/Hubs/requestHubFunctions'
import { ShippingProps } from './ShippingContext'

interface BudgetProviderProps {
  children: ReactNode
  pathname?: string
}

export interface BudgetProps {
  id: string
  customer_id: string
  situation: string
  source_hub_id: string
  crossdocking_hub_id: string | null
  destination_hub_id: string
  source_cities: string[]
  destination_cities: string[]
  source_address_qty: number
  destination_address_qty: number
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  price: number
  price_kg_extra: number | null
  transfer_budget: number | null
  price_add_collect: number
  price_add_delivery: number
  price_unsuccessful_collect: number
  days_service: number[]
  observation: string
  deadline_delivery: Date
  customerIDBudget: {
    trading_firstname: string
  }
  sourceHubIDBudget: IHubsProps
  destinationHubIDBudget: IHubsProps
  crossdockingHubIDBudget: IHubsProps
  createdBy: User
  priority_budget: boolean | string
  last_mile_budget: number
  shippings: {
    shipping_id: string
    shipping: ShippingProps
  }[]
  shipping_type: 'Próximo voo' | 'Econômico' | null
}

type BudgetInput = Omit<
  BudgetProps,
  'id' | 'customerIDBudget' | 'sourceHubIDBudget' | 'destinationHubIDBudget' | 'crossdockingHubIDBudget' | 'createdBy' | 'shippings'
>

interface BudgetContextProps {
  createBudget: (budgetInput: BudgetInput) => Promise<boolean>
  deleteBudget: (budgetId: string) => Promise<boolean | undefined>
  editBudget: (
    budgetId: string,
    budgetInput: BudgetInput,
  ) => Promise<boolean | undefined>
}

export const BudgetContext = createContext<BudgetContextProps>(
  {} as BudgetContextProps,
)

export function BudgetProvider({ children, pathname }: BudgetProviderProps) {
  async function createBudget(budgetInput: BudgetInput) {
    try {
      await api.post('/budget', budgetInput)

      setTimeout(() => {
        swal('Poof! O orçamento foi criado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deleteBudget(budgetId: string) {
    try {
      await api.delete(`/budget/${budgetId}`)
      swal('Poof! O orçamento foi excluído com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editBudget(budgetId: string, budgetInput: BudgetInput) {
    try {
      await api.put(`/budget/${budgetId}`, budgetInput)
      setTimeout(() => {
        swal('Poof! O orçamento foi atualizado com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <BudgetContext.Provider value={{ createBudget, editBudget, deleteBudget }}>
      {children}
    </BudgetContext.Provider>
  )
}

import axios from "axios";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors";

interface CreateRegionInput {
  name: string
  acronym: string
}

async function createRegionReqFunction(input: CreateRegionInput) {
  try {
    const { data } = await api.post("/regions", input)

    return data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}

interface UpdateRegion {
  regionId: string,
  input: CreateRegionInput
}

async function updateRegionReqFunction({ regionId, input }: UpdateRegion) {
  try {
    await api.put(`/regions/${regionId}`, input)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    }
    throw new RequestError('Erro inesperado!')
  }
}


async function deleteRegionReqFunction(regionId: string) {
  try {
    await api.delete(`/regions/${regionId}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!", 500)
    }
  }
}

export function useRegionFunctions() {
  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("regions")
    await queryClient.invalidateQueries("region")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createRegion = useMutation('createRegion', createRegionReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const updateRegion = useMutation('updateRegion', updateRegionReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const deleteRegion = useMutation('deleteRegion', deleteRegionReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return {
    createRegion,
    updateRegion,
    deleteRegion
  }
}

import { usePagination } from "@ajna/pagination";
import { Box, Icon, Link as ChakraLink, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Address } from "hooks/address/dtos/Address";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Pagination } from "../../../../../../components/Pagination/Pagination";
import { ServiceProps } from "../../../../../../contexts/ServiceContext";
import { formatDate } from "../../../../../../utils/DateFunctions/formatDate";
import { serviceHandleStep } from "../../../../../../utils/ServiceFunctions/serviceDict";

interface IConsultedServicesTable {
  services: ServiceProps[]
  addresses: Address[]
}

export function ConsultedBusinessServicesTable({ services, addresses }: IConsultedServicesTable) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: services?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  function handleChangePage(page: number) {
    setCurrentPage(page)
  }

  return (
    <>
      <Box overflowX="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>PROTOCOLO</Th>
              <Th>CLIENTE</Th>
              <Th>CIDADE(S) ORIGEM</Th>
              <Th>CIDADE(S) DESTINO</Th>
              <Th>DATA DA ROTA</Th>
              <Th>HORÁRIO INICIAL DA ROTA</Th>
              <Th>HORÁRIO FINAL DA ROTA</Th>
              <Th>ETAPA</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {services
              .slice(offset, offset + Number(itemLimit))
              .map(service => {

                const sourceCities = Array.from(
                  new Set(addresses
                    .filter(address => service.serviceIDRequestedBusiness.source_address_id.includes(address.id))
                    .map(address => address.cityIDAddress.name)
                  ))


                const destinationCities = Array.from(
                  new Set(addresses
                    .filter(address => service.serviceIDRequestedBusiness.destination_address_id.includes(address.id))
                    .map(address => address.cityIDAddress.name)
                  ))

                return (
                  <Tr key={service.id}>
                    <Td>{service.protocol}</Td>
                    <Td>{service.customerIDService.trading_firstname}</Td>
                    <Td>{sourceCities.join(", ")}</Td>
                    <Td>{destinationCities.join(", ")}</Td>
                    <Td>{formatDate.handle(service.serviceIDRequestedBusiness.route_start_date, "DateWithoutHourToShow")}</Td>
                    <Td>{formatDate.handle(service.serviceIDRequestedBusiness.route_start_hour, "DateOnlyWithHourMinute")}</Td>
                    <Td>{formatDate.handle(service.serviceIDRequestedBusiness.route_end_hour, "DateOnlyWithHourMinute")}</Td>
                    <Td>{serviceHandleStep(service.step)}</Td>
                    <Td><ChakraLink fontSize="xl" as={Link} to={`/servico-business/detalhes/${service.id}`}><Icon as={FaEye} /></ChakraLink></Td>
                  </Tr>
                )
              })
            }
          </Tbody>
        </Table>
      </Box>
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </>
  )
}

import { StandardBackgroundForm } from "../../../../components/Form/StandardBackgroundForm";
import * as yup from "yup"
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { ICreateCollectServiceInput, useCollectServiceFunctions } from "../../../../hooks/collectService/useCollectServiceFunctions";
import { useForm, FormProvider } from "react-hook-form";
import { useService } from "../../../../hooks/services/service";
import { GeneralContentLoading } from "../../../../components/Loading/GeneralContentLoading";
import { useDriver } from "../../../../hooks/driver/useDriver";
import { yupResolver } from "@hookform/resolvers/yup";
import { StartCollectServiceForm } from "../components/StartCollectServiceForm";
import { RequestedServiceInfoAccordion } from "../../../../components/Accordions/Service/RequestedServiceInfoAccordion";
import { Accordion, Flex, useDisclosure } from "@chakra-ui/react";
import { useGeolocation } from "../../../../hooks/useGeolocation";
import { toast } from "react-toastify";
import { useAddresses } from "../../../../hooks/address/useAddresses";
import { ExternalComunications } from "components/ExternalComunications";
import { useAuth } from "hooks/auth/useAuth";
import { Empty } from "components/Empty";

interface IFormProps {
  address_id: string;
  driver_id: string;
}

interface IParams {
  service_id: string;
  address_id: string;
}

const schema = yup.object().shape({
  address_id: yup.string(),
  driver_id: yup.string().required("Campo Obrigatório")
})

async function alertGeolocationError(error: GeolocationPositionError) {
  if (error.code === 1) {
    toast.error("Para iniciar a coleta, é necessário que a localização esteja habilitada!")
  }
  if (error.code === 2) {
    toast.error("Localização não está disponível! Tente recarregar a página ou verifique as configurações do dispositivo.")
  }
}

export function StartAddressCollectService() {

  const { address_id, service_id }: IParams = useParams()
  const { push: redirectTo, goBack } = useHistory()
  const location = useLocation()
  const state = location.state as { path?: string }
  const hasPathRedirect = state?.path !== undefined && state?.path !== null
  const { userLogged } = useAuth()

  const {
    service: { data: service, isLoading: isServiceLoading }
  } = useService(service_id)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    drivers: { data: drivers, isLoading: isDriversLoading }
  } = useDriver(null, true)

  const {
    createCollectService: {
      mutateAsync: createCollectService, isLoading: isCreateCollectServiceLoading, isSuccess: isCreateCollectServiceSuccess
    }
  } = useCollectServiceFunctions()

  const { position, error: geolocationError } = useGeolocation()

  const driversFilteredBySourceCollector = drivers?.filter(driver => driver.collector_id === service?.serviceIDRequested.source_collector_id)

  const formMethods = useForm<IFormProps>({
    resolver: yupResolver(schema)
  })

  const { setValue, handleSubmit } = formMethods

  const collectorHasDriverAssign = service?.serviceIDRequested?.sourceCollectorIDService?.has_driver_assign

  const serviceDriverAddressAssignmentExists = service?.serviceIDRequested.driver_address_assign


  useEffect(() => {
    setValue('address_id', address_id)
  }, [setValue, address_id])

  const autoCreateCollectServiceCallback = useCallback(async (submitData: ICreateCollectServiceInput) => {
    await createCollectService(submitData)
  }, [createCollectService])

  useEffect(() => {
    async function autoStartCollect() {
      if (serviceDriverAddressAssignmentExists) {
        const driverAddressAssignment = serviceDriverAddressAssignmentExists
          .map(assignment => JSON.parse(assignment as string))
          .find(assignment => assignment.address_id === address_id)

        if (driverAddressAssignment) {
          setValue("address_id", driverAddressAssignment.address_id)
          setValue("driver_id", driverAddressAssignment.driver_id)

          if (geolocationError) {
            return alertGeolocationError(geolocationError)
          }

          if (position) {
            const submitData = {
              address_id,
              service_id,
              driver_id: driverAddressAssignment.driver_id,
              arrival_latitude: String(position?.coords.latitude),
              arrival_longitude: String(position?.coords.longitude),
              arrival_timestamp: new Date(),
              isBusiness: false
            }

            await autoCreateCollectServiceCallback(submitData)
          }
        }
      }
    }

    autoStartCollect()
  }, [
    address_id,
    autoCreateCollectServiceCallback,
    serviceDriverAddressAssignmentExists,
    service_id,
    setValue,
    position,
    geolocationError
  ])

  async function handleStartCollectService(values: IFormProps) {
    if (geolocationError) {
      return alertGeolocationError(geolocationError)
    }

    if (position) {
      const submitData = {
        ...values,
        address_id,
        service_id,
        arrival_latitude: String(position?.coords.latitude),
        arrival_longitude: String(position?.coords.longitude),
        arrival_timestamp: new Date(),
        isBusiness: false
      }

      await createCollectService(submitData)
    }
  }

  useEffect(() => {
    if (isCreateCollectServiceSuccess) {
      redirectTo({
        pathname: `/servico/${service_id}/finalizar/coleta/endereco/${address_id}`,
        state: {
          path: hasPathRedirect ? state?.path : null
        }
      })
    }
  }, [redirectTo, service_id, address_id, isCreateCollectServiceSuccess, state?.path, hasPathRedirect])

  const {
    isOpen: isExternalComunicationModalOpen,
    onOpen: onOpenExternalComunicationModal,
    onClose: onCloseExternalComunicationModal,
  } = useDisclosure()

  if (isServiceLoading || isAddressesLoading || isDriversLoading || isCreateCollectServiceLoading) {
    return (
      <GeneralContentLoading />
    )
  }

  return (
    <>
      {(userLogged?.user_type === 'COLETADOR' || userLogged?.user_type === 'MOTORISTA') && (
        <ExternalComunications.TriggerButton
          aria-label="help-me"
          onClick={onOpenExternalComunicationModal}
        />
      )}

      <ExternalComunications.Root
        isOpen={isExternalComunicationModalOpen}
        onClose={onCloseExternalComunicationModal}
      >
        <ExternalComunications.Content
          serviceId={service.id}
          onClose={onCloseExternalComunicationModal}
        />
      </ExternalComunications.Root>

      {collectorHasDriverAssign && !serviceDriverAddressAssignmentExists ? (
        <Empty.Root
          message="Aguarde até que a atribuição de motoristas dos endereços do serviço sejam finalizadas."
        >
          <Empty.ActionButton onClick={goBack}>Voltar</Empty.ActionButton>
        </Empty.Root>
      ) : (
        <StandardBackgroundForm onSubmit={handleSubmit(handleStartCollectService)} title="Iniciar coleta">
          <Flex direction="column" gap="4">
            <Accordion allowMultiple>
              <RequestedServiceInfoAccordion
                requestedServiceId={service?.serviceIDRequested.id!}
                addresses={addresses!}
              />
            </Accordion>
            <FormProvider {...formMethods}>
              <StartCollectServiceForm
                addresses={addresses}
                drivers={driversFilteredBySourceCollector ?? []}
              />
            </FormProvider>
          </Flex>
        </StandardBackgroundForm>
      )}

    </>
  )
}

import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { EmptyContentTableAlert } from "components/Alerts/EmptyContentTableAlert";
import { CustomerProps } from "contexts/CustomerContext";
import { format } from "date-fns";
import { Collector } from "hooks/collector/dtos/Collector";
import { Fragment } from "react";
import { CitiesProps } from "services/getFunctions/city/getCity";
import { IDriverProps } from "services/getFunctions/driver/getDrivers";
import { formatDate } from "utils/DateFunctions/formatDate";
import { BusinessBudgetLogs } from "utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions";
import { IHubsProps } from "utils/RequestFunctions/Hubs/requestHubFunctions";
import { BusinessBudgetLogLine } from "./BusinessBudgetLogLine";

interface AttendanceDay {
  driver: string
  day: number;
  start_hour: string
  end_hour: string
}

interface BusinessBudgetLogsModalProps {
  isOpen: boolean
  onClose: () => void
  businessBudgetLogs: BusinessBudgetLogs[]
  cities: CitiesProps[]
  hubs: IHubsProps[]
  drivers: IDriverProps[]
  customers: CustomerProps[]
  collectors: Collector[]
}

const daysOfWeek = {
  0: 'Segunda-feira',
  1: 'Terça-feira',
  2: 'Quarta-feira',
  3: 'Quinta-feira',
  4: 'Sexta-feira',
  5: 'Sábado',
  6: 'Domingo',
}

export function BusinessBudgetLogsModal({
  isOpen,
  onClose,
  businessBudgetLogs,
  cities,
  hubs,
  drivers,
  customers,
  collectors
}: BusinessBudgetLogsModalProps) {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="850px">
        <ModalHeader>Logs</ModalHeader>
        <ModalCloseButton />

        <ModalBody maxH='500px' h='full' overflow='auto' >
          <Flex direction='column' align='center'>
            <TableContainer borderRadius='md' maxW='700px'>
              <Table m='2' maxW='500px' variant='simple' size='sm'>
                <Thead>
                  <Tr>
                    <Th>CAMPO</Th>
                    <Th>VALOR ANTERIOR</Th>
                    <Th>VALOR ATUALIZADO</Th>
                    <Th>ATUALIZADO POR</Th>
                    <Th>DATA DA ATUALIZAÇÃO</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {businessBudgetLogs ? businessBudgetLogs.map((log) => {
                    return (
                      <Fragment key={log.id}>
                        {log.log_detail?.situation && (
                          <BusinessBudgetLogLine
                            name="Situação"
                            prevValue={String(log.log_detail?.situation.prev_value)}
                            newValue={String(log.log_detail?.situation.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.customer_id && (
                          <BusinessBudgetLogLine
                            name="Cliente"
                            prevValue={customers?.find((customer) => customer.id === log.log_detail.customer_id.prev_value)?.trading_firstname}
                            newValue={customers?.find((customer) => customer.id === log.log_detail.customer_id.new_value)?.trading_firstname}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.source_collector_id && (
                          <BusinessBudgetLogLine
                            name="Cliente"
                            prevValue={collectors?.find((collector) => collector.id === log.log_detail.source_collector_id.prev_value)?.trading_name}
                            newValue={collectors?.find((collector) => collector.id === log.log_detail.source_collector_id.new_value)?.trading_name}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.source_hub_id && (
                          <BusinessBudgetLogLine
                            name="Hub Origem"
                            prevValue={hubs?.find((hub) => hub.id === log.log_detail?.source_hub_id.prev_value)?.name}
                            newValue={hubs?.find((hub) => hub.id === log.log_detail?.source_hub_id.new_value)?.name}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.destination_hub_id && (
                          <BusinessBudgetLogLine
                            name="Hub Destino"
                            prevValue={hubs?.find((hub) => hub.id === log.log_detail?.destination_hub_id.prev_value)?.name}
                            newValue={hubs?.find((hub) => hub.id === log.log_detail?.destination_hub_id.new_value)?.name}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.route_nickname && (
                          <BusinessBudgetLogLine
                            name="Apelido da rota"
                            prevValue={String(log.log_detail?.route_nickname.prev_value)}
                            newValue={String(log.log_detail?.route_nickname.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.vehicle && (
                          <BusinessBudgetLogLine
                            name="Veículo"
                            prevValue={String(log.log_detail?.vehicle.prev_value)}
                            newValue={String(log.log_detail?.vehicle.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.start_date && (
                          <BusinessBudgetLogLine
                            name="Data da Início"
                            prevValue={String(formatDate.handle(String(log.log_detail?.start_date.prev_value), "DateWithoutHourToShow"))}
                            newValue={String(formatDate.handle(String(log.log_detail?.start_date.new_value), "DateWithoutHourToShow"))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.caixa_termica && (
                          <BusinessBudgetLogLine
                            name="Caixa Térmica"
                            prevValue={String(log.log_detail?.caixa_termica.prev_value)}
                            newValue={String(log.log_detail?.caixa_termica.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.gelo_seco && (
                          <BusinessBudgetLogLine
                            name="Gelo Seco"
                            prevValue={String(log.log_detail?.gelo_seco.prev_value)}
                            newValue={String(log.log_detail?.gelo_seco.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.gelox && (
                          <BusinessBudgetLogLine
                            name="Gelox"
                            prevValue={String(log.log_detail?.gelox.prev_value)}
                            newValue={String(log.log_detail?.gelox.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.isopor3l && (
                          <BusinessBudgetLogLine
                            name="Isopor 3L"
                            prevValue={String(log.log_detail?.isopor3l.prev_value)}
                            newValue={String(log.log_detail?.isopor3l.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.isopor7l && (
                          <BusinessBudgetLogLine
                            name="Isopor 7L"
                            prevValue={String(log.log_detail?.isopor7l.prev_value)}
                            newValue={String(log.log_detail?.isopor7l.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.terciaria3l && (
                          <BusinessBudgetLogLine
                            name="Terciária 3L"
                            prevValue={String(log.log_detail?.terciaria3l.prev_value)}
                            newValue={String(log.log_detail?.terciaria3l.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.terciaria8l && (
                          <BusinessBudgetLogLine
                            name="Terciária 8L"
                            prevValue={String(log.log_detail?.terciaria8l.prev_value)}
                            newValue={String(log.log_detail?.terciaria8l.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }
                        {log.log_detail?.embalagem_secundaria && (
                          <BusinessBudgetLogLine
                            name="Embalagem secundária"
                            prevValue={String(log.log_detail?.embalagem_secundaria.prev_value)}
                            newValue={String(log.log_detail?.embalagem_secundaria.new_value)}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )
                        }

                        {log.log_detail?.monthly_price && (
                          <BusinessBudgetLogLine
                            name="Preço mensal"
                            prevValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.monthly_price.prev_value)))}
                            newValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.monthly_price.new_value)))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.budget && (
                          <BusinessBudgetLogLine
                            name="Budget"
                            prevValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.budget.prev_value)))}
                            newValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.budget.new_value)))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.monthly_km_franchising && (
                          <BusinessBudgetLogLine
                            name="Franquia mensal de KM"
                            prevValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.monthly_km_franchising.prev_value)))}
                            newValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.monthly_km_franchising.new_value)))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.week_time_franchising && (
                          <BusinessBudgetLogLine
                            name="Franquia de tempo semanal"
                            prevValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.week_time_franchising.prev_value)))}
                            newValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.week_time_franchising.new_value)))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.km_extra_price && (
                          <BusinessBudgetLogLine
                            name="Preço do KM adicional"
                            prevValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.km_extra_price.prev_value)))}
                            newValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.km_extra_price.new_value)))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.weekday_daily_budget_backup && (
                          <BusinessBudgetLogLine
                            name="Budget diária backup (Dias úteis)"
                            prevValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.weekday_daily_budget_backup.prev_value / 100)))}
                            newValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.weekday_daily_budget_backup.new_value / 100)))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.weekend_daily_budget_backup && (
                          <BusinessBudgetLogLine
                            name="Budget diária backup (Finais de semana) "
                            prevValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.weekend_daily_budget_backup.prev_value / 100)))}
                            newValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.weekend_daily_budget_backup.new_value / 100)))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.extra_hour_price && (
                          <BusinessBudgetLogLine
                            name="Preço da HE"
                            prevValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.extra_hour_price.prev_value)))}
                            newValue={String(new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(Number(log.log_detail?.extra_hour_price.new_value)))}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.drivers && (
                          <BusinessBudgetLogLine
                            name="Dias da semana"
                            prevValue={Object.entries(log?.log_detail.drivers.prev_value).map(([prevKey, prevValue]) => {

                              return (
                                <>
                                  Motorista: {prevKey},
                                  <br />
                                  Dias: {prevValue.attendance_days.map((attendanceDay) => daysOfWeek[attendanceDay]).join(', ')}
                                  <br />
                                  Horário Inicial: {format(new Date(prevValue.start_hour), 'HH:mm')},
                                  <br />
                                  Horário Final: {format(new Date(prevValue.end_hour), 'HH:mm')},
                                  <br />
                                  <br />
                                </>
                              )
                            })}
                            newValue={Object.entries(log.log_detail.drivers.new_value).map(([newKey, newValue]) => {

                              return (
                                <>
                                Motorista: {newKey},
                                <br />
                                Dias: {newValue.attendance_days.map((attendanceDay) => daysOfWeek[attendanceDay]).join(', ')}
                                <br />
                                Horário Inicial: {format(new Date(newValue.start_hour), 'HH:mm')},
                                <br />
                                Horário Final: {format(new Date(newValue.end_hour), 'HH:mm')},
                                <br />
                                <br />
                              </>
                              )
                            })}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.source_cities && (
                          <BusinessBudgetLogLine
                            name="Cidades de Origem"
                            prevValue={log.log_detail.source_cities.prev_value.map((prevValue) => {
                              const cityName = cities?.find((city) => city.id === prevValue)?.name

                              return (
                                <>
                                  {cityName},
                                  <br />
                                </>
                              )
                            })}
                            newValue={log.log_detail.source_cities.new_value.map((newValue) => {
                              const cityName = cities?.find((city) => city.id === newValue)?.name

                              return (
                                <>
                                  {cityName},
                                  <br />
                                </>
                              )
                            })}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                        {log.log_detail?.destination_cities && (
                          <BusinessBudgetLogLine
                            name="Cidades de Origem"
                            prevValue={log.log_detail.destination_cities.prev_value.map((prevValue) => {
                              const cityName = cities?.find((city) => city.id === prevValue)?.name

                              return (
                                <>
                                  {cityName},
                                  <br />
                                </>
                              )
                            })}
                            newValue={log.log_detail.destination_cities.new_value.map((newValue) => {
                              const cityName = cities?.find((city) => city.id === newValue)?.name

                              return (
                                <>
                                  {cityName},
                                  <br />
                                </>
                              )
                            })}
                            updatedBy={`${log?.updatedBy.firstname} ${log?.updatedBy.lastname}`} updatedAt={formatDate.handle(log?.updatedAt, "DateWithDateAndHourMinute")}
                          />
                        )}
                      </Fragment>

                    )
                  }) : (<EmptyContentTableAlert title="Ops..." description="Não há logs para este orçamento!" />)}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button
              onClick={onClose}
              colorScheme='gray'
              variant='ghost'
            >
              Fechar
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { useCallback } from 'react'
import { toast } from 'react-toastify'

interface PromiseErrorData {
  data: {
    message: string
  }
}

const useToastify = () => {
  const errorMessage = useCallback(
    (message: string) => toast.error(message),
    [],
  )

  const warnMessage = useCallback((message: string) => toast.warn(message), [])

  const promiseMessage = (promise: Promise<unknown>, successMessage: string) =>
    toast.promise(promise, {
      pending: 'Carregando',
      success: {
        type: 'success',
        render() {
          return `${successMessage}`
        }
      },
      error: {
        type: 'error',
        render({ data }: PromiseErrorData) {

          return `${data.message}`
        }
      },
    },
    )

  const infoMessage = useCallback((message: string) => {
    return toast.info(message, {
      theme: 'colored',
    })
  }, [])

  const successMessage = useCallback((message: string) => {
    return toast.success(message, {
      theme: 'colored'
    })
  }, [])

  return { errorMessage, promiseMessage, warnMessage, successMessage, infoMessage }
}

export { useToastify }

import { Box, Button, Flex, FormControl, FormLabel, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import { ExternalSupportForm } from "./ExternalSupportForm";
import { PartnerSupportForm } from "./PartnerSupportForm";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { createSupport, FormDataItem } from 'api/supports/createSupport'
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { InternalSupportForm } from "./InternalSupportForm";
import { useEffect, useRef, useState } from "react";
import { createSupportSchema } from "../validation/supportSchema";
import { SupportType } from "utils/supportTypes";
import { useAuth } from "hooks/auth/useAuth";
import { useCollectors } from "hooks/collector/useCollectors";
import { useSearchParams } from "hooks/useSearchParams";
import { useHistory } from "react-router-dom";
import { getSupportSubjectsByCategory } from "api/supports/getSupportSubjectsByCategory";

interface CreateSupportProps {
  onClose: () => void
  required?: boolean
}

export const supportTypeOptions = [
  'Externo', 'Parceiro', 'Interno'
]

export interface CreateSupportSchema {
  type: 'Externo' | 'Parceiro' | 'Interno'
  subject: string
  requestAttachmentId?: FileList
  bank?: string
  agency?: string
  accountType?: string
  accountOwner?: string
  cpf?: string
  accountNumber?: string
  description?: string
  collectorId?: string
  category?: string
  formData?: FormDataItem
  attachments: {
    file: FileList
  }[]
}


function removeEmptyFields(obj: FormDataItem): FormDataItem {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value.value)
  );
}

export function CreateSupport({
  onClose,
  required = false
}: CreateSupportProps) {
  const { userLogged } = useAuth()

  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const isUserLoggedDriverOrCollector = ['COLETADOR', 'MOTORISTA'].includes(userLogged?.user_type)

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const [subjectSelected, setSubjectSelected] = useState('')

  const formMethods = useForm<CreateSupportSchema>({
    resolver: yupResolver(createSupportSchema),
    defaultValues: {
      attachments: [{ file: undefined }],
    },
    context: {
      subject: subjectSelected
    }
  })

  const { handleSubmit, register, control, unregister, setValue, formState: { isSubmitting, errors } } = formMethods

  const { mutation: uploadRequestAttachmentFn, uploadProggress: uploadProggressRequestAttachment } = useAttachmentMutation()

  const [type, subject, category] = useWatch({
    control,
    name: ['type', 'subject', 'category']
  })

  const {
    data: collectorsResult,
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO',
    }
  })

  const {
    data: supportsSubjectsResult
  } = useQuery({
    queryKey: ['subjects', category],
    queryFn: () => getSupportSubjectsByCategory({
      type: type as SupportType | 'all',
      category: category ?? null
    }),
    enabled: !!type
  })

  const subjectName = supportsSubjectsResult?.supportsSubjects?.find(supportSubject => supportSubject.id === subject)


  useEffect(() => {
    if (isUserLoggedDriverOrCollector && type === 'Parceiro' && Boolean(collectorsResult)) {
      setValue('collectorId', userLogged?.collector_id)
    }
  }, [type, setValue, userLogged?.collector_id, collectorsResult, isUserLoggedDriverOrCollector])

  const prevSubjectRef = useRef(subject);

  useEffect(() => {
    setSubjectSelected(subjectName?.name)
  }, [subject, subjectName?.name])

  useEffect(() => {
    if (prevSubjectRef.current && (prevSubjectRef.current !== subject) && subject) {
      unregister('formData');
    }
    prevSubjectRef.current = subject;
  }, [subject, unregister]);


  const isExternalSupport = type === 'Externo'
  const isPartnerSupport = type === 'Parceiro'
  const isInternalSupport = type === 'Interno'

  const { mutateAsync: createSupportFn } = useMutation({
    mutationFn: createSupport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: 'analyzing-supports' })
      if (required) {
        searchParams.set('supportRequested', 'true')
        replace({ search: searchParams.toString() })
      }
      onClose()
    }
  })


  async function handleCreateSupport(values: CreateSupportSchema) {
    await promiseMessage(createSupportFn({
      body: {
        subjectId: values.subject,
        type: values.type,
        description: values.description,
        externalSupport: {
          accountNumber: values.accountNumber ? Number(values.accountNumber.replace('-', '')) : null,
          accountOwner: values.accountOwner,
          accountType: values.accountType,
          agency: values.agency ? Number(values.agency.replace('-', '')) : null,
          bank: values.bank,
          cpf: values.cpf,
        },
        partnerSupport: {
          accountNumber: values.accountNumber ? Number(values.accountNumber.replace('-', '')) : null,
          agency: values.agency ? Number(values.agency.replace('-', '')) : null,
          bank: values.bank,
          collectorId: values.collectorId,
        },
        internSupport: {
          category: values.category,
          formData: removeEmptyFields(values.formData)
        },
        requestAttachmentId: values.requestAttachmentId ? uploadRequestAttachmentFn?.data?.attachment?.id : null,
      }
    }), 'Atendimento solicitado!')
  }


  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Criar Atendimento
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <FormProvider {...formMethods}>
          <Box
            as="form"
            onSubmit={handleSubmit(handleCreateSupport)}
            maxH='600px'
            overflowY='scroll'
            p={3}
          >
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
              mt="3"
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors.type}>
                  <FormLabel fontSize="sm">
                    Tipo do atendimento
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>

                  <Select
                    {...register('type')}
                    name="type"
                    placeholder="Selecione..."
                    rounded="md"
                    size='sm'
                  >
                    {supportTypeOptions.map((type) => {
                      if (isUserLoggedDriverOrCollector && type !== 'Interno') {
                        return <option key={type} value={type}>{type}</option>
                      }

                      return <option key={type} value={type}>{type}</option>
                    })}


                  </Select>
                </FormControl>
              </Stack>
            </Stack>

            {isInternalSupport && (
              <InternalSupportForm />
            )}

            <FormControl isInvalid={!!errors?.subject} mt={3}>
              <FormLabel fontSize="sm">
                Motivo do atendimento
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Select
                {...register('subject')}
                name="subject"
                placeholder="Selecione..."
                rounded="md"
                size='sm'
              >
                {supportsSubjectsResult?.supportsSubjects?.map((subject) => {
                  return <option key={subject.id} value={subject.id}>{subject.name}</option>
                })}


              </Select>
            </FormControl>


            {isExternalSupport && (
              <ExternalSupportForm
                uploadProggressRequestAttachment={uploadProggressRequestAttachment}
                uploadRequestAttachmentFn={uploadRequestAttachmentFn}
              />
            )}
            {isPartnerSupport && (
              <PartnerSupportForm
                uploadProggressRequestAttachment={uploadProggressRequestAttachment}
                uploadRequestAttachmentFn={uploadRequestAttachmentFn}
                collectors={collectorsResult}
              />
            )}

            <Flex
              mt="6"
              w="full"
              justify="flex-end"
            >
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Criar
              </Button>
            </Flex>
          </Box>
        </FormProvider>

      </ModalBody>
    </ModalContent>
  )
}

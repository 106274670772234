import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber"

type DataProps = {
  company_name: string
  trading_name: string
  cnpj: string
  email: string
  modal: string
  cellphone: string
  telephone: string
  state_register: string | null
  observation: string
  has_board_autofill: 'yes' | 'no'
  cte_transfer_cost: string
  board_weight: string
  real_weight: string
  taxed_weight: string
  is_loglife_payer: string
  is_cte_destination_branch_equal_to_planned: string
}

type ShippingFormatedDataProps = Omit<DataProps,
  'has_board_autofill' |
  'cte_transfer_cost' |
  'board_weight' |
  'real_weight' |
  'taxed_weight' |
  'is_loglife_payer' |
  'is_cte_destination_branch_equal_to_planned'> & {
    has_board_autofill: boolean
    cte_transfer_cost_in_cents?: number
    board_weight?: number
    real_weight?: number
    taxed_weight?: number
    is_loglife_payer?: boolean
    is_cte_destination_branch_equal_to_planned?: boolean
}

const shippingFormatDataToBack = (data: DataProps): ShippingFormatedDataProps => {
  const {
    cte_transfer_cost,
    board_weight,
    real_weight,
    taxed_weight,
    is_loglife_payer,
    is_cte_destination_branch_equal_to_planned,
    ...rest
  } = data;

  const shippingFormatedData: ShippingFormatedDataProps = {
    ...rest,
    has_board_autofill: data.has_board_autofill === 'yes',
  };

  if (shippingFormatedData.has_board_autofill) {
    shippingFormatedData.cte_transfer_cost_in_cents = transformStringToNumber(cte_transfer_cost) * 100;
    shippingFormatedData.board_weight = transformStringToNumber(board_weight);
    shippingFormatedData.real_weight = transformStringToNumber(real_weight);
    shippingFormatedData.taxed_weight = transformStringToNumber(taxed_weight);
    shippingFormatedData.is_loglife_payer = is_loglife_payer === 'yes';
    shippingFormatedData.is_cte_destination_branch_equal_to_planned = is_cte_destination_branch_equal_to_planned === 'yes';
  }

  return shippingFormatedData;
}

export { shippingFormatDataToBack }

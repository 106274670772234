import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api'
import { RequestError } from 'utils/errors/RequestErrors'
import {
  CreateUserInputProps,
  createUserReqFunction,
  deleteUserReqFunction,
  editUserReqFunction,
  EditUserReqProps,
} from '../../utils/RequestFunctions/User/requestUserFunctions'
import { useSwal } from '../swal/useSwal'

export interface ReadUserNotificationReqProps {
  userId: string
  notificationId: string
}

export interface DeleteUserNotificationReqProps {
  userId: string
  notificationId: string
}

export interface UpdateUserCanReceiveNotificationTypeReqProps {
  userId: string
  canReceive: boolean
  notificationTypeId: string
}

async function updateUserCanReceiveNotificationTypeReqFunction({
  userId,
  canReceive,
  notificationTypeId
}: UpdateUserCanReceiveNotificationTypeReqProps) {
  try {
    await api.patch(`/user/notification-types/${userId}`, {
      can_receive: canReceive,
      notification_type_id: notificationTypeId
    })
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function readUserNotificationReqFunction({
  userId,
  notificationId
}: ReadUserNotificationReqProps) {
  try {
    await api.patch(`/user/notifications/read/${userId}`, {
      notification_id: notificationId
    })
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function deleteUserNotificationReqFunction({
  userId,
  notificationId
}: DeleteUserNotificationReqProps) {
  try {
    await api.delete(`/user/notifications/${userId}`, {
      params: {
        notification_id: notificationId
      }
    })
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function deleteAllUserNotificationsReqFunction(userId: string) {
  try {
    await api.delete(`/user/notifications/all/${userId}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

async function requestInactivateUserReqFunction(userId: string) {
  try {
    await api.patch(`/users/${userId}/inactivate`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

interface CreateMonitoringUsersData {
  monitoringDays: {
    day: number
    periods: {
      startHour: string
      endHour: string
      responsibles: {
        userId: string
      }[]
    }[]
  }[]
}

interface CreateMonitoringUsersReqFunctionProps {
  data: CreateMonitoringUsersData
}

async function createMonitoringUsersReqFunction(
  props: CreateMonitoringUsersReqFunctionProps
) {
  try {
    await api.post(`/user/monitoring`, props.data)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

interface EditMonitoringUsersReqFunctionProps {
  data: CreateMonitoringUsersData & {
    deletedMonitoringIds: string[]
  }
}

async function editMonitoringUsersReqFunction(
  props: EditMonitoringUsersReqFunctionProps
) {
  try {
    await api.put(`/user/monitoring`, props.data)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado')
    }
  }
}

export const useUserFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createUser = useMutation(
    'createUser',
    (input: CreateUserInputProps) => createUserReqFunction(input),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['users'])
        await queryClient.invalidateQueries(['user'])
        await successMessage('Usuário criado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const editUser = useMutation(
    'editUser',
    ({ id, input }: EditUserReqProps) => editUserReqFunction({ id, input }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['users'])
        await queryClient.invalidateQueries(['user'])
        await successMessage('Usuário atualizado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const deleteUser = useMutation(
    'deleteUser',
    (id: string) => deleteUserReqFunction(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['users'])
        await queryClient.invalidateQueries(['user'])
        await successMessage('Usuário excluído com sucesso!')
      },

      onError: async (error: any) => {
        await errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const requestInactivateUser = useMutation(
    'requestInactivateUser',
    (id: string) => requestInactivateUserReqFunction(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['users'])
        await queryClient.invalidateQueries(['user'])
        await successMessage('Solicitação de inativação de usuário enviada com sucesso!')
      },

      onError: async (error: any) => {
        await errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const readUserNotification = useMutation(
    "readUserNotification", readUserNotificationReqFunction, {
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['user-notifications']})
    }
  })

  const deleteUserNotification = useMutation(
    "deleteUserNotification", deleteUserNotificationReqFunction, {
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['user-notifications']})
    }
  })

  const deleteAllUserNotifications = useMutation(
    "deleteAllUserNotifications", deleteAllUserNotificationsReqFunction, {
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['user-notifications']})
    }
  })

  const updateUserCanReceiveNotificationType = useMutation(
    "updateUserCanReceiveNotificationType", updateUserCanReceiveNotificationTypeReqFunction, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['userNotificationTypes'])
    }
  })

  const createMonitoringUsers = useMutation(createMonitoringUsersReqFunction)

  const editMonitoringUsers = useMutation(editMonitoringUsersReqFunction)

  return {
    createUser,
    editUser,
    deleteUser,
    readUserNotification,
    deleteUserNotification,
    deleteAllUserNotifications,
    updateUserCanReceiveNotificationType,
    requestInactivateUser,
    createMonitoringUsers,
    editMonitoringUsers,
  }
}

import { add, format } from 'date-fns'
import { DateFormats, dateFormatsMoreUsed } from './dateFormats'

export class AddDate {
  async handleFormatAndAddHour(
    date: string | Date,
    hours: number,
    dateFormat: DateFormats,
  ) {
    return format(
      add(new Date(date), { hours }),
      dateFormatsMoreUsed[dateFormat].format,
    )
  }

  async handleFormatAndAddMinutes(
    date: string | Date,
    minutes: number,
    dateFormat: DateFormats,
  ) {
    return format(
      add(new Date(date), { minutes }),
      dateFormatsMoreUsed[dateFormat].format,
    )
  }

  async handleFormatAndAddHourAndMinutes(
    date: string | Date,
    hour: number,
    minutes: number,
    dateFormat: DateFormats,
  ) {
    return format(
      add(new Date(date), { hours: hour, minutes }),
      dateFormatsMoreUsed[dateFormat].format,
    )
  }

  async handleFormatAndAddDays(
    date: string | Date,
    days: number,
    dateFormat: DateFormats,
  ) {
    return format(
      add(new Date(date), { days }),
      dateFormatsMoreUsed[dateFormat].format,
    )
  }
}

const addDate = new AddDate()

export { addDate }

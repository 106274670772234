import { Flex } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";

import { Radio } from "components/Inputs/RadioInput";

import { useFormContext } from "react-hook-form";
import { positiveNegativeOptions } from "utils/CustomLists/positiveNegativeOptions";

export interface DismissalAggregateFormInputs {
  hasLogisticsDirectorApproved: 'yes' | 'no'
  endContractDate: string
}

export function DismissalAggregateForm() {
  const {
    register,
    formState: { errors }
  } = useFormContext<DismissalAggregateFormInputs>()

  return (
    <Flex direction="column" gap={6} align="center">
      <Radio
        {...register('hasLogisticsDirectorApproved')}
        name='hasLogisticsDirectorApproved'
        radioOptions={positiveNegativeOptions}
        label='Aprovado pelo Diretor de Logística?'
        error={errors.hasLogisticsDirectorApproved}
        required
      />
      <Input
        {...register('endContractDate')}
        name='endContractDate'
        type='date'
        label='Data de término de contrato'
        error={errors.endContractDate}
        required
      />
    </Flex>
  )

}

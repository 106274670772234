import { Box, Flex, IconButton, Link, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react"
import { GetInternCltResponse } from "api/internClts/getInternClt"
import { format, setDay } from "date-fns"
import { ptBR } from "date-fns/locale"
import { FaExternalLinkAlt } from "react-icons/fa"
import { captalize } from "utils/captalize"
import { internCltBenefitMap, internCltJourneyTypeMap } from "utils/internCltMappers"

interface InternCltDetailTableProps {
  internCltData: GetInternCltResponse
}

export function InternCltDetailTable({ internCltData }: InternCltDetailTableProps) {
  return (
    <Box maxH="600px" overflowY="scroll">
      <Table size="sm" w="full" >
        <Tbody>
          <Tr>
            <Td>Nome</Td>
            <Td isNumeric>{internCltData?.internClt.name}</Td>
          </Tr>
          <Tr>
            <Td>CPF</Td>
            <Td isNumeric>{internCltData?.internClt.cpf}</Td>
          </Tr>
          <Tr>
            <Td>RG</Td>
            <Td isNumeric>{internCltData?.internClt.rg}</Td>
          </Tr>
          {internCltData?.internClt.born_date && (
            <Tr>
              <Td>Data de nascimento</Td>
              <Td isNumeric>{format(new Date(internCltData?.internClt.born_date), 'dd/MM/yyyy')}</Td>
            </Tr>
          )}
          <Tr>
            <Td>Endereço</Td>
            <Td isNumeric>{`
      ${internCltData?.internClt.address_street}, ${' '}
      ${internCltData?.internClt.address_number}, ${internCltData?.internClt.address_neighborhood} - ${internCltData?.internClt.address_cep}`
            }
            </Td>
          </Tr>
          {internCltData?.internClt.address_complement && (
            <Tr>
              <Td>Complemento</Td>
              <Td isNumeric>{internCltData?.internClt.address_complement}</Td>
            </Tr>
          )}
          <Tr>
            <Td>Cidade</Td>
            <Td isNumeric>{internCltData?.internClt.address_city}</Td>
          </Tr>
          <Tr>
            <Td>Estado</Td>
            <Td isNumeric>{internCltData?.internClt.address_state}</Td>
          </Tr>
          <Tr>
            <Td>Regional</Td>
            <Td isNumeric>{internCltData?.internClt.regional === 'other' ? 'Outra' : internCltData?.internClt.regional.toUpperCase()}</Td>
          </Tr>
          {internCltData?.internClt.admission_date && (
            <Tr>
              <Td>Data da admissão</Td>
              <Td isNumeric>{format(new Date(internCltData?.internClt.admission_date), 'dd/MM/yyyy')}</Td>
            </Tr>
          )}
          <Tr>
            <Td>Salário</Td>
            <Td isNumeric>{(internCltData?.internClt.salary_in_cents / 100).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
            </Td>
          </Tr>
          <Tr>
            <Td>Possui gratificação de liderança?</Td>
            <Td isNumeric>{internCltData?.internClt.has_leadership_gratification ? 'Sim' : 'Não'}</Td>
          </Tr>
          {internCltData?.internClt.leadership_gratification_value_in_cents && (
            <Tr>
              <Td>Valor da gratificação</Td>
              <Td isNumeric>{(internCltData?.internClt.leadership_gratification_value_in_cents / 100).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
              </Td>
            </Tr>
          )}
          <Tr>
            <Td>Possui comissão?</Td>
            <Td isNumeric>{internCltData?.internClt.has_commission ? 'Sim' : 'Não'}</Td>
          </Tr>
          {internCltData?.internClt.commission_description && (
            <Tr>
              <Td>Descrição da apuração</Td>
              <Td isNumeric>{internCltData?.internClt.commission_description}</Td>
            </Tr>
          )}
          <Tr>
            <Td>Tipo de jornada</Td>
            <Td isNumeric>{internCltJourneyTypeMap[internCltData?.internClt.journey_type]}</Td>
          </Tr>
          {internCltData?.internClt.work_week_days && (
            <Tr>
              <Td>Tipo de jornada</Td>
              <Td isNumeric>
                {internCltData?.internClt.work_week_days.map((weekDay) => {
                  return (
                    <Text>{captalize(format(setDay(new Date(), Number(weekDay)), 'eeee', {
                      locale: ptBR
                    }))}</Text>
                  )
                })}
              </Td>
            </Tr>
          )}
          {internCltData && (
            <Tr>
              <Td>Jornada</Td>
              <Td isNumeric>{`${format(new Date(internCltData?.internClt.work_start_hour), 'HH:mm')} até ${format(new Date(internCltData?.internClt.work_end_hour), 'HH:mm')}`}</Td>
            </Tr>
          )}
          {internCltData && (
            <Tr>
              <Td>Intervalo de jornada</Td>
              <Td isNumeric>{`${format(new Date(internCltData?.internClt.work_interval_start_hour), 'HH:mm')} até ${format(new Date(internCltData?.internClt.work_interval_end_hour), 'HH:mm')}`}</Td>
            </Tr>
          )}
          {internCltData?.internClt.benefits && (
            <Tr>
              <Td>Benefícios</Td>
              <Td isNumeric>
                {internCltData?.internClt.benefits.map((benefit) => {
                  return (
                    <Flex mt={1} key={benefit} w="full" align="end" direction="column">
                      <Text>Benefício: {internCltBenefitMap[benefit]}</Text>
                    </Flex>
                  )
                })}
              </Td>
            </Tr>
          )}
          {internCltData?.internClt.vt_types && (
            <Tr>
              <Td>Vale Transporte</Td>
              <Td isNumeric>{internCltData?.internClt?.vt_types.map((vt) => vt).join(', ')}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.documentation_checklist && (
            <Tr>
              <Td>Documentação - contratação</Td>
              <Td isNumeric>{internCltData?.internClt?.documentation_checklist.map((document) => document).join(', ')}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_with_pontomais_register && (
            <Tr>
              <Td>Cadastro efetuado no Pontomais ?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_with_pontomais_register}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.bank && (
            <Tr>
              <Td>Banco</Td>
              <Td isNumeric>{internCltData?.internClt?.bank}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.agency && (
            <Tr>
              <Td>Agência</Td>
              <Td isNumeric>{internCltData?.internClt?.agency}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.account && (
            <Tr>
              <Td>Conta</Td>
              <Td isNumeric>{internCltData?.internClt?.account}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_docs_verified && (
            <Tr>
              <Td>Documentação verificada?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_docs_verified ? 'Sim' : 'Não'}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_legal_risk_verified && (
            <Tr>
              <Td>Risco jurídico verificado?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_legal_risk_verified ? 'Sim' : 'Não'}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.signed_docs && (
            <Tr>
              <Td>Documentação assinada</Td>
              <Td isNumeric>{internCltData?.internClt?.signed_docs.map((document) => document).join(', ')}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_operation_leader_aware && (
            <Tr>
              <Td>Líder da operação ciente para realizar a demissão?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_operation_leader_aware ? 'Sim' : 'Não'}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_with_notice && (
            <Tr>
              <Td>Funcionário irá cumprir aviso prévio?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_with_notice ? 'Sim' : 'Não'}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.last_work_date && (
            <Tr>
              <Td>Data último dia de trabalho</Td>
              <Td isNumeric>{format(new Date(internCltData?.internClt.last_work_date), 'dd/MM/yyyy')}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_dismissal_signed_by_collaborator && (
            <Tr>
              <Td>Assinatura do aviso de demissão pelo colaborador?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_dismissal_signed_by_collaborator}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_ticket_card_blocked && (
            <Tr>
              <Td>Cartão ticket bloqueado ?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_ticket_card_blocked}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_removed_on_transfacil_and_otimo && (
            <Tr>
              <Td>Funcionário desativado do site Transfácil e Otimo ?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_removed_on_transfacil_and_otimo}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_conferred_point_registers && (
            <Tr>
              <Td>Realizou conferência de registros de ponto ?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_conferred_point_registers}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.inactivations && (
            <Tr>
              <Td>Funcionário inativado:</Td>
              <Td isNumeric>{internCltData?.internClt?.inactivations.map((inactive) => inactive).join(', ')}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_with_co_participation_discount && (
            <Tr>
              <Td>Funcionário possui descontos de coparticipação em aberto para descontar ?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_with_co_participation_discount ? 'Sim' : 'Não'}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.co_participation_discount_value_in_cents && (
            <Tr>
              <Td>Valor a ser descontado</Td>
              <Td isNumeric>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(internCltData?.internClt?.co_participation_discount_value_in_cents / 100)}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.is_conferred_grrf && (
            <Tr>
              <Td>Realizadou conferência GRRF ?</Td>
              <Td isNumeric>{internCltData?.internClt?.is_conferred_grrf}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.terminationDocumentsAttachments.map((attachment) => {
            return (
              <Tr key={attachment.attachment?.id}>
                <Td maxW="210px" overflow="hidden" textOverflow="ellipsis">
                  Documento recisório - {attachment?.attachment?.title}
                </Td>
                <Td isNumeric>
                  <IconButton
                    aria-label="Abrir anexo"
                    as={Link}
                    href={attachment?.attachment?.link}
                    icon={<FaExternalLinkAlt />}
                    size="sm"
                    isExternal
                  />
                </Td>
              </Tr>
            )
          })}
          {internCltData?.internClt?.payment_date && (
            <Tr>
              <Td>Data de pagamento</Td>
              <Td isNumeric>{format(new Date(internCltData?.internClt.payment_date), 'dd/MM/yyyy')}</Td>
            </Tr>
          )}

          {internCltData?.internClt?.has_completed_contract_termination_term && (
            <Tr>
              <Td>Termo de rescisão de contrato preenchido ?</Td>
              <Td isNumeric>{internCltData?.internClt.has_completed_contract_termination_term ? 'Sim' : 'Não'}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.has_removed_from_occupational_medicine && (
            <Tr>
              <Td>Excluiu o funcionário da plataforma da Medicina Ocupacional?</Td>
              <Td isNumeric>{internCltData?.internClt.has_removed_from_occupational_medicine}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.has_removed_from_healthcare && (
            <Tr>
              <Td>Exclusão Plano de saúde ?</Td>
              <Td isNumeric>{internCltData?.internClt.has_removed_from_healthcare}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.has_removed_from_dental_plan && (
            <Tr>
              <Td>Exclusão plano odonto ?</Td>
              <Td isNumeric>{internCltData?.internClt.has_removed_from_dental_plan}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.has_removed_from_pharmacy_agreement && (
            <Tr>
              <Td>Exclusão convenio farmácia ?</Td>
              <Td isNumeric>{internCltData?.internClt.has_removed_from_pharmacy_agreement}</Td>
            </Tr>
          )}
          {internCltData?.internClt?.dismissal_real_date && (
            <Tr>
              <Td>Data real do desligamento</Td>
              <Td isNumeric>{format(new Date(internCltData?.internClt.dismissal_real_date), 'dd/MM/yyyy')}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}

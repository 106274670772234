import axios from "axios"
import { ServiceProps } from "contexts/ServiceContext"
import { useAuth } from "hooks/auth/useAuth"
import { useQuery, UseQueryOptions } from "react-query"
import { api } from "services/api"
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum"
import { RequestError } from "utils/errors/RequestErrors"

interface ServicesQueryParams {
  collect_range_date?: string
  steps?: string[]
  source_collector_id?: string
}

type QueryOptions = UseQueryOptions<ServiceProps[], unknown, ServiceProps[], ["services"]>

interface UseServicesOptions {
  queryParams?: ServicesQueryParams,
  queryOptions?: QueryOptions
}

async function getServicesReqFunction(queryParams?: ServicesQueryParams) {
  try {
    const { data } = await api.get('/service', {
      params: queryParams && {
        ...queryParams,
        steps: queryParams?.steps ? queryParams?.steps.join(',') : []
      }
    })

    return data
  } catch (error) {
    console.log(error)
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado!')
    }
  }
}

export function useServices(options?: UseServicesOptions) {

  const { handleLogout } = useAuth()

  const onRequestError = async ({ message, httpCode }: RequestError) => {
    if (httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  return useQuery([
    'services',
    options?.queryParams?.collect_range_date,
    options?.queryParams?.steps,
    options?.queryParams?.source_collector_id
  ],
    () => getServicesReqFunction(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: onRequestError
  }
  )

}

import { usePagination } from "@ajna/pagination";
import { Box, Heading, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link } from "@chakra-ui/react";
import { getAdvertencesReport } from "api/advertences/getAdvertencesReport";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { advertenceTypeMap, regionalTypeMap } from "utils/advertenceMapppers";
import { AdvertencesReportFilters } from "./components/AdvertencesReportFilters";
import { AdvertenceStatus } from "./components/AdvertenceStatus";

const headers = [
  { label: 'Nome', key: 'user' },
  { label: 'Regional', key: 'regional' },
  { label: 'Data', key: 'date' },
  { label: 'Motivo da advertência', key: 'advertence_type' },
  { label: 'Anexo da advertência', key: 'advertence_attachment_id' },
  { label: 'Comprovação da advertência', key: 'proof_of_sending_advertence_attachment_id' },
]

export function AdvertencesReport() {

  const { userLogged } = useAuth()
  const searchParams = useSearchParams()
  const history = useHistory()

  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')
  const status = searchParams.get('status') ?? 'all'

  const userCanViewAdvertencesReport = userLogged?.permissions.includes('view-advertences-report')

  useEffect(() => {
    if (!userCanViewAdvertencesReport) history.push('/')
  }, [history, userCanViewAdvertencesReport])

  const {
    data: advertencesReportData
  } = useQuery({
    queryKey: ['advertences-report', startDate, endDate, status],
    queryFn: () => getAdvertencesReport({
      queryParams: {
        endDate,
        startDate,
        status,
      }
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const csvPropsData = advertencesReportData
    ? advertencesReportData?.advertences.map(advertence => {
      return {
        ...advertence,
        user: `${advertence?.user?.firstname} ${advertence?.user?.firstname}`,
        advertence_attachment_id: advertence?.advertence_attachment_id ? advertence?.advertenceAttachment?.link : '-',
        proof_of_sending_advertence_attachment_id: advertence?.proof_of_sending_advertence_attachment_id ? advertence?.proofOfSendingAdvertenceAttachment?.link : '-',
        regional: regionalTypeMap[advertence?.regional],
        date: String(format(new Date(advertence.date), 'dd/MM/yyyy')),
        advertence_type: advertenceTypeMap[advertence?.advertence_type],
      }
    })
    : []

  const csvProps = {
    data: csvPropsData,
    headers,
    filename: `relatório-advertencias-${new Date().toISOString()}.csv`,
  }

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (
    <Box
      p="6"
      bg="white"
      rounded="md"
    >
      <Heading letterSpacing="tight">Relatório de Advertências</Heading>
      <AdvertencesReportFilters csvProps={csvProps} />
      {advertencesReportData && (
        <TableContainer mt="6">
          <Table
            size="sm"
          >
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>Regional</Th>
                <Th>Status</Th>
                <Th>Motivo da advertência</Th>
                <Th>Anexo da advertência</Th>
                <Th>Comprovante da advertência</Th>
              </Tr>
            </Thead>
            <Tbody>
              {advertencesReportData?.advertences.slice(offset, offset + 10).map((advertence) => {
                return (
                  <Tr key={advertence.id}>
                    <Td>{advertence?.user?.firstname} {advertence?.user?.lastname}</Td>
                    <Td>{regionalTypeMap[advertence?.regional]}</Td>
                    <Td>
                      <AdvertenceStatus status={advertence?.status}/>
                    </Td>
                    <Td>{advertenceTypeMap[advertence?.advertence_type]}</Td>
                    <Td>
                      {
                        advertence.advertence_attachment_id ? (
                          <IconButton
                            aria-label="Abrir anexo"
                            as={Link}
                            href={advertence?.advertenceAttachment?.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        ) : '-'
                      }
                    </Td>
                    <Td>
                      {
                        advertence.proof_of_sending_advertence_attachment_id ? (
                          <IconButton
                            aria-label="Abrir anexo"
                            as={Link}
                            href={advertence?.proofOfSendingAdvertenceAttachment?.link}
                            icon={<FaExternalLinkAlt />}
                            size="sm"
                            isExternal
                          />
                        ) : '-'
                      }
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        handlePageChange={handleChangePage}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />
    </Box>
  )
}

import { Box, Flex } from "@chakra-ui/react";
import { SporadicStatus as SporadicStatusTypes } from "api/sporadics/_types/Sporadic";
import { sporadicStatusMap } from "utils/sporadicMappers";

interface SporadicStatusProps {
  status: SporadicStatusTypes;
}

export function SporadicStatus({ status }: SporadicStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {status === 'in-billing' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'billed' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'canceled' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {sporadicStatusMap[status]}
    </Flex>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getAdvertences } from "api/advertences/getAdvertences";
import { Advertence, AdvertenceStatus } from "api/advertences/types/Advertence";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { advertenceStatusMap, advertenceTypeMap, regionalTypeMap, userTypeMap } from "utils/advertenceMapppers";
import { AdvertenceTableFilters } from "./components/AdvertenceTableFilters";
import { AdvertenceTableRow } from "./components/AdvertenceTableRow";
import { CreateAdvertence } from "./components/CreateAdvertence";

const headers = [
  { label: 'COLABORADOR', key: 'collaborator_name' },
  { label: 'TIPO DE COLABORADOR', key: 'user_type' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'STATUS', key: 'status' },
  { label: 'DATA DA ADVERTÊNCIA', key: 'date' },
  { label: 'MOTIVO DA ADVERTÊNCIA', key: 'type' },

]

function formatValuesToReport(values: Advertence[]) {
  return values?.map((advertence) => ({
    ...advertence,
    collaborator_name: `${advertence?.user.firstname} ${advertence?.user.lastname}`,
    user_type: userTypeMap[advertence?.user_type],
    regional: regionalTypeMap[advertence.regional],
    date: advertence.date
      ? format(new Date(advertence.date), 'dd/MM/yyyy')
      : '-',
    type: advertenceTypeMap[advertence.advertence_type],
    status: advertenceStatusMap[advertence?.status]
  }))
}


export function Advertences() {
  const searchParams = useSearchParams()

  const history = useHistory()
  const { userLogged } = useAuth()

  const userCanCreateAdvertence = userLogged?.permissions?.includes('create-advertence')

  const page = searchParams.get('page') ?? '1'
  const status = searchParams.get('status')

  const {
    data: advertencesResult
  } = useQuery({
    queryKey: ['advertences', page, status],
    queryFn: () => getAdvertences({
      currentPage: page,
      pageSize: '10',
      status: status as AdvertenceStatus | 'all'
    })
  })


  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: advertencesDataReportResult,
    isLoading: isLoadingAdvertencesDataReportResult
  } = useQuery({
    queryKey: ['advertences-report', page, status],
    queryFn: () => getAdvertences({
      currentPage: '1',
      pageSize: String(advertencesResult?.meta?.count),
      status: ["awaiting-application", "parent-verification", "cancelled", "done"].includes(status) ? status as AdvertenceStatus : 'all'
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })


  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: advertencesResult?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: advertencesResult?.meta?.pageSize
    }
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    history.replace({ search: searchParams.toString() })
  }

  const {
    isOpen: isCreateAdvertenceModalOpen,
    onOpen: onOpenCreateAdvertenceModal,
    onClose: onCloseCreateAdvertenceModal
  } = useDisclosure()

  const csvReportProps = {
    data: advertencesDataReportResult ? formatValuesToReport(advertencesDataReportResult?.advertences) : [],
    headers,
    filename: `advertencias.csv`,
  }


  return (
    <Box
      p="4"
      rounded="md"
      bg="white"
    >
      <Flex justify="space-between">
        <Heading letterSpacing="tight">Advertências</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => history.push('/advertencias/kanban')}
          />
          {userCanCreateAdvertence && (
            <IconButton
              aria-label="Criar Advertência"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateAdvertenceModal}
            />
          )}
        </Flex>

        <Modal
          isOpen={isCreateAdvertenceModalOpen}
          onClose={onCloseCreateAdvertenceModal}
          isCentered
        >
          <ModalOverlay />
          <CreateAdvertence
            onCloseModal={onCloseCreateAdvertenceModal}
          />
        </Modal>
      </Flex>

      <AdvertenceTableFilters />

      <TableContainer
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th color="white">Nome</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Data da advertência</Th>
              <Th color="white">Motivo da advertência</Th>
              <Th color="white">Status</Th>
              <Th></Th>

            </Tr>
          </Thead>
          <Tbody>
            {advertencesResult?.advertences.map((advertence) => {
              return (
                <AdvertenceTableRow
                  key={advertence.id}
                  advertence={advertence}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!advertencesDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingAdvertencesDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />

    </Box>
  )
}

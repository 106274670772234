import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useContext, useState, useEffect } from 'react'
import { Pagination } from '../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import swal from 'sweetalert'
import { SearchBox } from '../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../utils/searchBoxFilter'
import { AuthContext } from '../../../contexts/AuthContext'
import { LogContext } from '../../../contexts/LogContext'
import { useCity } from '../../../hooks/city/useCity'
import { TableFilterButton } from '../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../hooks/filterOptions/useFilterOptions'
import { useCityFunctions } from '../../../hooks/city/useCityFunctions'
import { CitiesProps } from '../../../services/getFunctions/city/getCity'
import { formatHourToFrontInput } from '../../../utils/GeneralFunctions/HourFunctions/formatHourToFrontInput'
import { formatDate } from '../../../utils/DateFunctions/formatDate'
import { days_of_week } from '../../../utils/customLists'
import { GenerateExcelReportButton } from '../../Buttons/GenerateExcelReportButton'

interface CityTableProps {
  cities: CitiesProps[]
}

const cityOptions = [
  { key: 'city', value: 'Cidade', checked: false },
  { key: 'state', value: 'Estado', checked: false },
  { key: 'hub', value: 'Hub', checked: false },
  {
    key: 'start_attendance_block',
    value: 'Bloco Inicial de Atendimento',
    checked: false,
  },
  {
    key: 'final_attendance_block',
    value: 'Bloco Final de Atendimento',
    checked: false,
  },
  {
    key: 'schedule_deadline',
    value: 'Horário Limite de Agendamento',
    checked: false,
  },
]

const cityOrderOptions = [
  { key: 'city', value: 'Cidade' },
  { key: 'state', value: 'Estado' },
]

const headers = [
  { label: 'NOME', key: 'name' },
  { label: 'ESTADO', key: 'state' },
  { label: 'HUB', key: 'hub_id' },
  { label: 'HORÁRIO LIMITE DE AGENDAMENTO', key: 'schedule_deadline' },
  { label: 'PRAZO PARA AGENDAMENTO', key: 'opening_deadline' },
  { label: 'BLOCO INICIAL DE ATENDIMENTO', key: 'start_attendance_block' },
  { label: 'BLOCO FINAL DE ATENDIMENTO', key: 'final_attendance_block' },
  { label: 'DIAS DE ATENDIMENTO', key: 'days_service' },
  { label: 'SITUAÇÃO', key: 'situation' },
  { label: 'OBSERVAÇÃO', key: 'observation' },
]

function formatValuesToReport(values: CitiesProps[]) {
  return values.map((value) => ({
    ...value,
    opening_deadline: `D - ${value.opening_deadline}`,
    hub_id: value.hubIDCity.name,
    schedule_deadline: formatDate.handle(
      value.schedule_deadline,
      'DateOnlyWithHourMinute',
    ),
    start_attendance_block: formatDate.handle(
      value.start_attendance_block,
      'DateOnlyWithHourMinute',
    ),
    final_attendance_block: formatDate.handle(
      value.final_attendance_block,
      'DateOnlyWithHourMinute',
    ),
    days_service: days_of_week
      .reduce((acc, curr) => {
        value.days_service.map((day) => {
          if (day === curr.value) {
            acc.push(curr.name)
          }
          return []
        })

        return acc
      }, [] as string[])
      .join('\n'),
  }))
}

export function CityTable({ cities }: CityTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [cityFiltered, setCityFiltered] = useState('')
  const [stateFiltered, setStateFiltered] = useState('')
  const [hubFiltered, setHubFiltered] = useState('')
  const [startAttendanceBlockFiltered, setStartAttendanceBlockFiltered] =
    useState('')
  const [finalAttendanceBlockFiltered, setFinalAttendanceBlockFiltered] =
    useState('')
  const [hourLimitFiltered, setHourLimitFiltered] = useState('')

  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const {
    cities: { refetch },
  } = useCity(null, false, false)
  const {
    deleteCity: { mutateAsync: deleteCity },
  } = useCityFunctions()
  const { userLogged } = useContext(AuthContext)
  const { createLog } = useContext(LogContext)

  const isSearchedCitiesOrdered = orderOptionSelected.length > 0

  const citiesFiltered = cities.filter((city) => {
    const cityFilter = searchBoxFilter(city.name, cityFiltered)
    const stateFilter = searchBoxFilter(city.state, stateFiltered)
    const hubfilter = searchBoxFilter(city.hubIDCity.name, hubFiltered)
    const startAttendanceBlockFilter = searchBoxFilter(
      formatHourToFrontInput(city.start_attendance_block),
      startAttendanceBlockFiltered,
    )
    const finalAttendanceBlockFilter = searchBoxFilter(
      formatHourToFrontInput(city.final_attendance_block),
      finalAttendanceBlockFiltered,
    )
    const hourLimitFilter = searchBoxFilter(
      formatHourToFrontInput(city.schedule_deadline),
      hourLimitFiltered,
    )

    if (
      cityFiltered === '' &&
      stateFiltered === '' &&
      hubFiltered === '' &&
      startAttendanceBlockFiltered === '' &&
      finalAttendanceBlockFiltered === '' &&
      hourLimitFiltered === ''
    )
      return city

    return (
      cityFilter &&
      stateFilter &&
      hubfilter &&
      startAttendanceBlockFilter &&
      finalAttendanceBlockFilter &&
      hourLimitFilter
    )
  })

  const searchedCities = isSearchedCitiesOrdered
    ? citiesFiltered.sort(
      (a, b) =>
        (orderOptionSelected.includes('city') &&
          a.hubIDCity.name.localeCompare(b.hubIDCity.name)) ||
        (orderOptionSelected.includes('state') &&
          a.state.localeCompare(b.state)) ||
        0,
    )
    : citiesFiltered

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedCities.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  const csvReportProps = {
    data: formatValuesToReport(cities),
    headers,
    filename: `relatório-cadastro-cidades`,
  }

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(cityOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(cityOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  console.log(orderOptionSelected)

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'city':
        return setCityFiltered(value)
      case 'state':
        return setStateFiltered(value)
      case 'hub':
        return setHubFiltered(value)
      case 'start_attendance_block':
        return setStartAttendanceBlockFiltered(value)
      case 'final_attendance_block':
        return setFinalAttendanceBlockFiltered(value)
      case 'schedule_deadline':
        return setHourLimitFiltered(value)
    }
  }

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteCity(cityId: string) {
    await swal({
      title: 'Deseja deletar uma cidade?',
      text: 'Essa ação não poderá ser desfeita!',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (willDelete) => {
      if (willDelete) {
        const cityName = cities
          .filter((city) => city.id === cityId)
          .map((city) => city.name)
          .toString()
        const response = await deleteCity(cityId)
        if (response && !!userLogged) {
          createLog({
            user_id: userLogged.id,
            action: `EXCLUIR CIDADE - ${cityName}`,
          })
          refetch()
        }
      } else {
        swal('Remoção cancelada com êxito!')
      }
    })
  }

  return (
    <>
      {cities.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {cityOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th fontSize="12" color="blue.900">PRAZO DE SOLICITAÇÃO</Th>
                  <Th fontSize="12" color="blue.900">CRIADA POR</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedCities
                  .slice(offset, offset + Number(itemLimit))
                  .map((city) => (
                    <Tr key={city.id}>
                      <Td fontSize="12">{city.name.toUpperCase()}</Td>
                      <Td fontSize="12">{city.state.toUpperCase()}</Td>
                      <Td fontSize="12">{city.hubIDCity.name.toUpperCase()}</Td>
                      <Td fontSize="12">
                        {formatHourToFrontInput(city.start_attendance_block)}
                      </Td>
                      <Td fontSize="12">
                        {formatHourToFrontInput(city.final_attendance_block)}
                      </Td>
                      <Td fontSize="12">
                        {formatHourToFrontInput(city.schedule_deadline)}
                      </Td>
                      <Td>D-{city.opening_deadline}</Td>
                      <Td>{city.createdBy !== null ? city?.createdBy?.firstname + " " + city?.createdBy?.lastname : "-"}</Td>
                      <Td isNumeric>
                        {userLogged !== null && (
                          <>
                            {(userLogged.permissions.includes('view-city') ||
                              userLogged.user_type === 'CLIENTE') && (
                                <Link to={`/cidade/visualizar/${city.id}`}>
                                  <Icon
                                    cursor="pointer"
                                    as={FiEye}
                                    fontSize="20"
                                  />
                                </Link>
                              )}
                            {userLogged.permissions.includes('edit-city') && (
                              <Link to={`/cidade/editar/${city.id}`}>
                                <Icon
                                  cursor="pointer"
                                  as={FiEdit3}
                                  fontSize="20"
                                  ml="4"
                                  mt={['2', '2', '0']}
                                />
                              </Link>
                            )}
                            {userLogged.permissions.includes('remove-city') && (
                              <Icon
                                cursor="pointer"
                                as={FiTrash2}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                                onClick={() => handleDeleteCity(city.id)}
                              />
                            )}
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <GenerateExcelReportButton csvReportProps={csvReportProps} />
          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

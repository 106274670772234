import { Button, Flex, Spinner } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useToastify } from "hooks/toastify/useToastify";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { ShippingCostForm, ShippingCostFormInputs } from "./components/ShippingCostForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useShipping } from "hooks/shipping/useShipping";
import { transformStringToNumber } from '../../utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { useShippingCostFunctions } from "hooks/shippingCost/useShippingCostFunctions";

const createShippingCostFormSchema = yup.object().shape({
  shippingId: yup.string().required('Campo obrigatório'),
  sourceBranchId: yup.string().required('Campo obrigatório'),
  destinationBranchId: yup.string().required('Campo obrigatório'),
  weights: yup.array().of(yup.object({
    kg: yup.number().required('Campo obrigatório'),
    priceInCents: yup.number()
      .typeError('Campo obrigatório')
      .required('Campo obrigatório')
      .transform((price, originalPrice) => Math.ceil(transformStringToNumber(originalPrice) * 100)),
  })).min(1, 'Campo obrigatório'),
  extraKgPriceInCents: yup
    .number()
    .typeError('Campo obrigatório')
    .required('Campo obrigatório')
    .transform((price, originalPrice) => Math.ceil(transformStringToNumber(originalPrice) * 100)),
})

export function CreateShippingCost() {

  const { push: redirect } = useHistory()

  const { userLogged } = useAuth()
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const userHasPermissionToCreateShippingCost =
    userLogged?.permissions.includes('create-shipping-cost')

  useEffect(() => {
    if (!userHasPermissionToCreateShippingCost) {
      redirect('/')
    }
  }, [userHasPermissionToCreateShippingCost, redirect])

  const formMethods = useForm<ShippingCostFormInputs>({
    resolver: yupResolver(createShippingCostFormSchema),
    defaultValues: {
      weights: Array.from({ length: 30 }).map((_, index) => {
        return {
          kg: index + 1,
          priceInCents: "0,00"
        }
      })
    }
  })

  const {
    shipping: { data: shipping, isFetching: isFetchingShipping },
  } = useShipping(null, true, false)

  const { createShippingCost: { mutateAsync: createShippingCost } } = useShippingCostFunctions()

  async function handleCreateShippingCost(values: ShippingCostFormInputs) {
    await promiseMessage(createShippingCost({
      shippingId: values.shippingId,
      input: values
    }, {
      onSuccess: async () => {
        redirect('/transportadoras/custos')
        await queryClient.invalidateQueries(['shippingCosts'])
      }
    }), 'Custo de transportadora criado com sucesso!')
  }

  return (
    <StandardBackgroundForm
      onSubmit={formMethods.handleSubmit(handleCreateShippingCost)}
      title="Criar Custo de Transportadora"
    >
      {isFetchingShipping ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <ShippingCostForm shippings={shipping} />
        </FormProvider>
      )}

      <Flex
        w="full"
        align="center"
        justify="flex-end"
        mt="4"
        gap="2"
      >
        <Button
          type="submit"
          colorScheme="blue"
        >
          Salvar
        </Button>

        <Button
          as={Link}
          type="submit"
          to="/transportadoras/custos"
        >
          Custos de Trasnportadoras
        </Button>

      </Flex>
    </StandardBackgroundForm>
  )

}

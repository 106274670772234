import { Popover, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, Placement, Table, Tbody, Tr, Td, Tfoot, Th } from "@chakra-ui/react";
import { GetServiceTotalCostResponse } from "api/service/getServiceTotalCost";

interface TotalPriceServiceModalProps {
  isOpen: boolean
  onClose: () => void
  initialFocusRef: React.RefObject<HTMLElement> | null;
  onOpen: () => void;
  placement: Placement;
  closeOnBlur: boolean;
  serviceTotalCostData: GetServiceTotalCostResponse
}

export function TotalPriceServiceModal({
  isOpen,
  onClose,
  onOpen,
  placement,
  closeOnBlur,
  initialFocusRef,
  serviceTotalCostData
}: TotalPriceServiceModalProps) {

  return (

    <Popover
      isOpen={isOpen}
      initialFocusRef={initialFocusRef}
      onOpen={onOpen} onClose={onClose}
      placement={placement}
      closeOnBlur={closeOnBlur}
    >
      <PopoverContent
        mt='80%'
        ml={"325%"}
        zIndex="9999"
      >
        <PopoverArrow />
        <PopoverHeader
          fontSize="md"
          fontWeight="bold"
          border="0"
          letterSpacing="tight"
        >
          Preço Total do Serviço
        </PopoverHeader>
        <PopoverCloseButton onClick={onClose} />
        <PopoverBody maxH='300px' h='full' overflow='auto'>
          <Table size="sm" w="full">
            <Tbody>
              <Tr>
                <Td>Coletas sem sucesso</Td>
                <Td>{
                  serviceTotalCostData?.unsuccessfulCollectPrice.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }</Td>
              </Tr>
              <Tr>
                <Td>Materiais extras</Td>
                <Td>{
                  serviceTotalCostData?.extraRequestedMaterialsTotalCost.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }</Td>
              </Tr>
              <Tr>
                <Td>Coletas adicionais</Td>
                <Td>{
                  serviceTotalCostData?.sourceAddressDiffValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }</Td>
              </Tr>
              <Tr>
                <Td>Entregas adicionais</Td>
                <Td>{
                  serviceTotalCostData?.destinationAddressDiffValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }</Td>
              </Tr>
              <Tr>
                <Td>Kg extra</Td>
                <Td>{
                  serviceTotalCostData?.franchisingDiffValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }</Td>
              </Tr>
              <Tr>
                <Td>Orçamento</Td>
                <Td>{
                  serviceTotalCostData?.budgetPrice.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }</Td>
              </Tr>
              <Tr>
                <Td>Outras cobranças</Td>
                <Td>{
                  serviceTotalCostData?.otherCollectionsValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }</Td>
              </Tr>
              <Tr>
                <Td>Extras/Descontos</Td>
                <Td>{
                  serviceTotalCostData?.extrasDiscountsTotal.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                }</Td>
              </Tr>
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Total</Th>
                <Th>{serviceTotalCostData?.total.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })}
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

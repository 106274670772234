import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useRnc } from '../../../hooks/rnc/useRnc'
import { RncAlignAccordion } from '../../Accordions/Rnc/RncAlignAccordion'

interface IFinishedRncModalProps {
  rncID: string
  isOpen: boolean
  onClose: () => void
}

export function FinishedRncModal({
  rncID,
  isOpen,
  onClose,
}: IFinishedRncModalProps) {
  const {
    rnc: { data: rnc, isLoading: isRncLoading },
  } = useRnc(rncID, false)

  return (
    <Modal size="4xl" onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Informações do RNC</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {rnc && (
            <RncAlignAccordion
              rnc={rnc}
              isRncLoading={isRncLoading}
              isModalOpen={true}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

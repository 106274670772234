import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Flex, Stack } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { useAuth } from "hooks/auth/useAuth";
import { Input } from "components/Inputs/Input";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { formatDate } from "utils/DateFunctions/formatDate";
import { add } from "date-fns";
import { useBugsFunctions } from "hooks/bugs/useBugsFunctionts";
import { useToastify } from "hooks/toastify/useToastify";

interface BugReportInputs {
  initialDate: string
  finalDate: string
}

const BugReportHeaders = [
  { key: 'subject', label: 'ASSUNTO' },
  { key: 'description', label: 'DESCRIÇÃO' },
  { key: 'created_by', label: 'CRIADO POR' },
  { key: 'created_at', label: 'CRIADO EM' },
  { key: 'updated_at', label: 'ÚLTIMA ATUALIZAÇÃO' },
  { key: 'validated_at', label: 'VALIDADO EM' },
]

const schema = yup.object().shape({
  initialDate: yup.string().required('Campo obrigatório!'),
  finalDate: yup.string().required('Campo obrigatório!')
})

export function BugReport() {

  const { promiseMessage } = useToastify()

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<BugReportInputs>({
    resolver: yupResolver(schema),
  })

  const [
    initialDateFilter,
    finalDateFilter
  ] = useWatch({
    control,
    name: ['initialDate', 'finalDate']
  })

  const isValidStartDate = new Date(initialDateFilter) instanceof Date &&
    !isNaN(new Date(initialDateFilter).getMilliseconds())

  const maxEndFilter = isValidStartDate
    ? formatDate.handle(add(new Date(initialDateFilter), {
      days: 31
    }), 'DateWithoutHourToInput')
    : ''

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-bugs-report')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const { generateBugReport: {
    mutateAsync: generateBugReport,
    isLoading: isGenerateBugReportDataLoading,
    data: bugsReportData
  } } = useBugsFunctions()

  async function handleGenerateBugReport({ initialDate, finalDate }: BugReportInputs) {
    await promiseMessage(
      generateBugReport({
        initialDate,
        finalDate
      }), "Relatório gerado com sucesso!"
    )
  }

  const bugReportDataFormatted = bugsReportData !== undefined ?
    bugsReportData?.bugs
      ?.map(bug => {
        return {
          ...bug,
          created_at: formatDate.handle(bug.created_at, 'DateWithoutHourToShow'),
          created_by: `${bug?.createdBy.firstname} ${bug?.createdBy.lastname}`,
          updated_at: bug.updated_at ? formatDate.handle(bug.updated_at, 'DateWithoutHourToShow') : "-",
          validated_at: bug.validated_at ? formatDate.handle(bug.validated_at, 'DateWithoutHourToShow'): "-",
        }
      })
    : []

  const csvReportFilename = `relatorio-bugs-${initialDateFilter}-${finalDateFilter}`

  const csvReportProps = {
    data: bugReportDataFormatted,
    headers: BugReportHeaders,
    filename: csvReportFilename,
  }

  return (
    <StandardBackgroundForm
      title='Relatório de Bugs'
      onSubmit={handleSubmit(handleGenerateBugReport)}
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Stack
          w='full'
          spacing={4}
          direction={['column', 'column', 'row']}
        >
          <Input
            {...register('initialDate')}
            name='initialDate'
            label='Data inicial do filtro'
            type='date'
            error={errors.initialDate}
            required
          />

          <Input
            {...register('finalDate')}
            name='finalDate'
            label='Data final do filtro'
            type='date'
            error={errors.finalDate}
            max={maxEndFilter}
            required
          />
        </Stack>

        <Flex gap={2} justify={['center', 'center', 'flex-end']}>
          <Button
            isLoading={isGenerateBugReportDataLoading}
            type='submit'
            w={['full', 'full', 'min']}
            colorScheme='blue'
          >
            Gerar relatório
          </Button>
          {bugsReportData && (
            <GenerateExcelReportButton mt={-4} csvReportProps={csvReportProps} isLoading={isGenerateBugReportDataLoading} />
          )}
        </Flex>
      </Flex>
    </StandardBackgroundForm>
  )
}

import { Button, Flex, Icon, IconButton, Stack } from "@chakra-ui/react"
import { Input } from "components/Inputs/Input"
import { PeriodConditional } from "hooks/cost/dtos/CollectorCost"
import { useEffect } from "react"
import { useCurrency } from "react-hook-currency"
import { Control, FieldErrors, useFieldArray, UseFormRegister } from "react-hook-form"
import { FaPlus, FaTrash } from "react-icons/fa"
import { FormInputProps } from "../CollectorCostForm"

interface CostPeriodConditionalsFieldProps<T> {
  control: Control<T>
  register: UseFormRegister<T>
  errors: FieldErrors<T>
  periodConditionals: PeriodConditional[]
}

export function CostPeriodConditionalsField({
  control,
  register,
  errors,
  periodConditionals,
}: CostPeriodConditionalsFieldProps<FormInputProps>) {

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'periodConditionals'
  })

  useEffect(() => {
    if (periodConditionals) {
      periodConditionals.forEach((conditional, index) => {
        update(index, {
          start_hour: conditional.start_hour,
          end_hour: conditional.end_hour,
          cost_motorcycle: String(conditional.cost_motorcycle as number / 100),
          additional_cost_motorcycle: String(conditional.additional_cost_motorcycle as number / 100),
          cost_car: String(conditional.cost_car as number / 100),
          additional_cost_car: String(conditional.additional_cost_car as number / 100),

        })
      })
    }
  }, [update, periodConditionals])

  const { format: currencyFormat, onChange: onCurrencyChange } = useCurrency({
    style: 'decimal'
  })

  const handleAppendField = () => append({
    start_hour: '',
    end_hour: '',
    cost_motorcycle: '',
    additional_cost_motorcycle: '',
    cost_car: '',
    additional_cost_car: '',
  })

  const handleRemoveField = (index: number) => remove(index)

  return (
    <Flex
      direction="column"
      gap="6"
      mt="6"
    >
      {fields.map((field, index) => {
        return (
          <Flex
            key={field.id}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="base"
            p="4"
            direction="column"
            gap="6"
          >
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
            >
              <Input
                {...register(`periodConditionals.${index}.start_hour`)}
                name={`periodConditionals.${index}.start_hour`}
                error={
                  errors?.periodConditionals
                    ? errors?.periodConditionals[index]?.start_hour
                    : undefined
                }
                type="time"
                label="Horário inicial"
                required
              />

              <Input
                {...register(`periodConditionals.${index}.end_hour`)}
                name={`periodConditionals.${index}.end_hour`}
                error={
                  errors?.periodConditionals
                    ? errors?.periodConditionals[index]?.end_hour
                    : undefined
                }
                type="time"
                label="Horário final"
                required
              />
            </Stack>

            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
            >
              <Input
                {...register(`periodConditionals.${index}.cost_motorcycle`)}
                name={`periodConditionals.${index}.cost_motorcycle`}
                error={
                  errors?.periodConditionals
                    ? errors?.periodConditionals[index]?.cost_motorcycle
                    : undefined
                }
                addChildren="R$"
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
                label="Custo para motos"
                required
              />

              <Input
                {...register(`periodConditionals.${index}.additional_cost_motorcycle`)}
                name={`periodConditionals.${index}.additional_cost_motorcycle`}
                error={
                  errors?.periodConditionals
                    ? errors?.periodConditionals[index]?.additional_cost_motorcycle
                    : undefined
                }
                addChildren="R$"
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
                label="Custo adicional para motos"
                required
              />
            </Stack>

            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
            >
              <Input
                {...register(`periodConditionals.${index}.cost_car`)}
                name={`periodConditionals.${index}.cost_car`}
                error={
                  errors?.periodConditionals
                    ? errors?.periodConditionals[index]?.cost_car
                    : undefined
                }
                addChildren="R$"
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
                label="Custo para carros"
                required
              />

              <Input
                {...register(`periodConditionals.${index}.additional_cost_car`)}
                name={`periodConditionals.${index}.additional_cost_car`}
                error={
                  errors?.periodConditionals
                    ? errors?.periodConditionals[index]?.additional_cost_car
                    : undefined
                }
                addChildren="R$"
                onChange={onCurrencyChange}
                defaultValue={currencyFormat('000')}
                label="Custo adicional para carros"
                required
              />
            </Stack>

            <Flex
              w="full"
              justify="flex-end"
            >
              <Button
                as={IconButton}
                icon={<Icon as={FaTrash} />}
                colorScheme="red"
                mt="8"
                onClick={() => handleRemoveField(index)}
              />
            </Flex>
          </Flex>
        )
      })}

      <Button
        leftIcon={<Icon as={FaPlus} />}
        colorScheme="blue"
        onClick={handleAppendField}
        alignSelf="flex-end"
        outline={
          errors?.periodConditionals?.message
            ? '2px solid'
            : ''
        }
        outlineColor={
          errors?.periodConditionals?.message
            ? 'red.500'
            : 'none'
        }
      >
        Novo período
      </Button>
    </Flex>

  )
}

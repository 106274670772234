import { createContext, ReactNode } from 'react'
import { api } from '../services/api'

import 'react-toastify/dist/ReactToastify.css'
import swal from 'sweetalert'

interface PermissionProviderProps {
  children: ReactNode
  pathname?: string
}

export interface PermissionProps {
  id: string
  group: string
  order: number
  key: string
  value: string
}

type PermissionInput = Omit<PermissionProps, 'id'>

interface PermissionContextProps {
  createPermission: (PermissionInput: PermissionInput) => Promise<boolean>
  deletePermission: (permissionId: string) => Promise<boolean | undefined>
  editPermission: (
    permissionId: string,
    PermissionInput: PermissionInput,
  ) => Promise<boolean | undefined>
}

export const PermissionContext = createContext<PermissionContextProps>(
  {} as PermissionContextProps,
)

export function PermissionProvider({
  children,
  pathname,
}: PermissionProviderProps) {
  async function createPermission(PermissionInput: PermissionInput) {
    try {
      await api.post('/permission', PermissionInput)
      setTimeout(() => {
        swal('Poof! A permissão foi criada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function deletePermission(permissionId: string) {
    try {
      await api.delete(`/permission/${permissionId}`)
      swal('Poof! A permissão foi excluída com sucesso!', {
        icon: 'success',
      })
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  async function editPermission(
    permissionId: string,
    PermissionInput: PermissionInput,
  ) {
    try {
      await api.put(`/permission/${permissionId}`, PermissionInput)
      setTimeout(() => {
        swal('Poof! A permissão foi atualizada com sucesso!', {
          icon: 'success',
        })
      }, 1500)
      return true
    } catch (error: any) {
      swal('Erro', `${error.response.data.message}`, 'error')
      return false
    }
  }

  return (
    <PermissionContext.Provider
      value={{ createPermission, deletePermission, editPermission }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

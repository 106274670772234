import {
  Flex,
  Box,
  Heading,
  Divider,
  Stack,
  Button,
  HStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ListButton } from '../../../../components/Buttons/ListButton'
import { Input } from '../../../../components/Inputs/Input'
import { GeneralContentLoading } from '../../../../components/Loading/GeneralContentLoading'

import { useContentLoading } from '../../../../hooks/loading/useContentLoading'
import { useService } from '../../../../hooks/services/service'
import { formatDateToFrontInput } from '../../../../utils/GeneralFunctions/DateFunctions/formatDateToFrontInput'
import { formatHourToFrontInput } from '../../../../utils/GeneralFunctions/HourFunctions/formatHourToFrontInput'

interface IQueryParams {
  service_id: string
}

export function AvailableServicesToEdit() {
  const { service_id }: IQueryParams = useParams()
  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()

  const {
    service: { data: service, isLoading: isServiceLoading },
  } = useService(service_id, false, false)

  useEffect(() => {
    if (!isServiceLoading) {
      handleSetIsContentLoadingFalse()
    }
  }, [isServiceLoading, handleSetIsContentLoadingFalse])

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex>
        <Box flex="1" borderRadius="8px" bg="white" p={['6', '8']}>
          <Heading size="lg" fontFamily="poppins">
            Editar Serviços Disponibilizados
          </Heading>

          <Divider my="6" borderColor="gray.700" />

          {service?.serviceIDAvailable.map((availableService) => (
            <Stack
              key={availableService.id}
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              <Stack w="full" direction={['column', 'column', 'row']}>
                <Input
                  defaultValue={`${formatDateToFrontInput(
                    availableService.landing_availability_date,
                  )}`}
                  isDisabled={true}
                  type="date"
                  name="landing_availability_date"
                  label="Data Prevista Para Disponibilidade"
                />
                <Input
                  defaultValue={`${formatHourToFrontInput(
                    availableService.landing_availability_hour,
                  )}`}
                  isDisabled={true}
                  type="time"
                  name="landing_availability_hour"
                  label="Horário Previsto Para Disponibilidade"
                />
              </Stack>

              <Link
                to={{
                  pathname: `/servicos/disponibilizar/desembarque/andamento/${availableService.service_id}`,
                  state: {
                    collector_id: availableService.collector_id,
                  },
                }}
              >
                <Button type="button" colorScheme="blue" w="100%">
                  Editar Serviço Disponibilizado
                </Button>
              </Link>
              <Divider my="6" borderColor="gray.700" />
            </Stack>
          ))}

          <Flex mt="8" justify="flex-end">
            <HStack>
              <ListButton
                href="/servicos/disponibilizar/desembarque"
                name="Serviços à Disponibilizar"
              />
            </HStack>
          </Flex>
        </Box>
      </Flex>

  )
}

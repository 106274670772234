import { Box, FormControl, FormLabel, Input, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { useDriver } from "hooks/driver/useDriver";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";

const contractTypeOptions = [
  'Diária',
  'Mensal',
]

const rentalCompanyOptions = [
  'Localiza',
  'Movida',
  'Outra',
]

const vehicleToRentOptions = [
  'Furgão',
  'Sedan',
  'Carro Pequeno',
]


export function VehicleRentalForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()

  const {
    drivers: { data: drivers },
  } = useDriver(null, true)

  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.currentTarget
    const value = target.value

    const labelMap: { [key in keyof FormDataItem]: string } = {
      withdrawalDate: 'Data da Retirada',
      expectedReturnDate: 'Data Prevista Devolução',
      driverOfVehicle: 'Motorista que vai dirigir o veículo',
      contractType: 'Tipo de Contrato',
      rentalCompany: 'Empresa Locadora',
      vehicleToRent: 'Veículo a Locar',
      reasonForRental: 'Motivo da Locação',
      rentalCostCenter: 'Centro de Custo da Locação'
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };

  return (
    <Box w="full">
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.withdrawalDate} mt={3}>
            <FormLabel fontSize='sm'>
              Data da Retirada
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.withdrawalDate.value')}
              name='formData.withdrawalDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('withdrawalDate')}
            />
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.expectedReturnDate} mt={3}>
            <FormLabel fontSize='sm'>
              Data Prevista Devolução
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Input
              {...register('formData.expectedReturnDate.value')}
              name='formData.expectedReturnDate.value'
              type="date"
              size='sm'
              rounded="md"
              onChangeCapture={handleInputChange('expectedReturnDate')}
            />
          </FormControl>
        </Stack>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.driverOfVehicle} mt={3}>
        <FormLabel fontSize='sm'>
          Motorista que vai dirigir o veículo
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          placeholder="Selecione..."
          {...register('formData.driverOfVehicle.value')}
          name='formData.driverOfVehicle.value'
          onChangeCapture={handleInputChange('driverOfVehicle')}
          size="sm"
          rounded="md"
        >
          {drivers?.map((driver) => {
            return (
              <option
                key={driver.id}
                value={`${driver.firstname} ${driver.lastname}`}>
                {`${driver.firstname} ${driver.lastname}`}
              </option>
            )
          })}

        </Select>
      </FormControl>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.contractType}>
            <FormLabel fontSize='sm'>
              Tipo de Contrato
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              placeholder="Selecione..."
              {...register('formData.contractType.value')}
              onChangeCapture={handleInputChange('contractType')}
              size="sm"
              rounded="md"
            >
              {Object.entries(contractTypeOptions).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormControl isInvalid={!!errors.formData?.rentalCompany}>
            <FormLabel fontSize='sm'>
              Empresa Locadora
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <Select
              placeholder="Selecione..."
              {...register('formData.rentalCompany.value')}
              onChangeCapture={handleInputChange('rentalCompany')}
              size="sm"
              rounded="md"
            >
              {Object.entries(rentalCompanyOptions).map(([key, value]) => (
                <option key={key} value={value}>{value}</option>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <FormControl isInvalid={!!errors.formData?.vehicleToRent} >
          <FormLabel fontSize="sm">
            Veículo a Locar
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Select
            placeholder="Selecione..."
            {...register('formData.vehicleToRent.value')}
            onChangeCapture={handleInputChange('vehicleToRent')}
            size="sm"
            rounded="md"
          >
            {Object.entries(vehicleToRentOptions).map(([key, value]) => (
              <option key={key} value={value}>{value}</option>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <FormControl isInvalid={!!errors.formData?.reasonForRental} >
          <FormLabel fontSize="sm">
            Motivo da Locação
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Textarea
            {...register('formData.reasonForRental.value')}
            name='formData.reasonForRental.value'
            size='sm'
            rounded='md'
            onChangeCapture={handleInputChange('reasonForRental')}
          />
        </FormControl>
      </Stack>
      <FormControl isInvalid={!!errors.formData?.rentalCostCenter} mt={3}>
        <FormLabel fontSize='sm'>
          Centro de Custo da Locação
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.rentalCostCenter.value')}
          name='formData.rentalCostCenter.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('rentalCostCenter')}
        />
      </FormControl>
    </Box>
  )
}

import { Box, Button, FormControl, FormLabel, Heading, HStack, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { finishVacationPeriod } from "api/vacations/finishVacationPeriod";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface FinishVacationPeriodSchema {
  withPontomaisAllowance: 'yes' | 'no'
  isFuelBenefitBlocked: 'yes' | 'no'
}

interface FinishVacationPeriodProps {
  vacationId: string

}

const finishVacationPeriodSchema = yup.object({
  withPontomaisAllowance: yup.string().required(),
  isFuelBenefitBlocked: yup.string().required(),
})

export function FinishVacationPeriod({ vacationId }: FinishVacationPeriodProps) {
  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<FinishVacationPeriodSchema>({
    resolver: yupResolver(finishVacationPeriodSchema)
  })

  const queryClient = useQueryClient()

  const { mutateAsync: finishVacationPeriodFn } = useMutation({
    mutationFn: finishVacationPeriod,
    onSuccess(){

      queryClient.invalidateQueries({ queryKey: 'vacations' })
      queryClient.invalidateQueries({ queryKey: 'active-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planning-vacations' })
      queryClient.invalidateQueries({ queryKey: 'planned-vacations' })
      queryClient.invalidateQueries({ queryKey: 'paying-vacations' })
      queryClient.invalidateQueries({ queryKey: 'waiting-vacations' })
      queryClient.invalidateQueries({ queryKey: 'on-vacations' })
      queryClient.invalidateQueries({ queryKey: 'done-vacations' })
      queryClient.invalidateQueries({ queryKey: 'dismissed-vacations' })
      queryClient.invalidateQueries({ queryKey: ['vacation', vacationId]})
    }

  })

  const { promiseMessage } = useToastify()

  async function handleFinishVacationPeriod(values: FinishVacationPeriodSchema) {
    await promiseMessage(finishVacationPeriodFn({
      body: {
        isFuelBenefitBlocked: values.isFuelBenefitBlocked === 'yes',
        withPontomaisAllowance: values.withPontomaisAllowance === 'yes'
      },
      vacationId,
    }), 'Período de férias finalizado com sucesso!')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleFinishVacationPeriod)}
    >
      <Heading letterSpacing="tight" size='sm'>Finalizar período</Heading>

      <FormControl isInvalid={!!errors.withPontomaisAllowance}>
        <FormLabel fontSize="sm">
          Abonou ponto mais?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Controller
          control={control}
          name="withPontomaisAllowance"
          render={({ field }) => {
            return (
              <RadioGroup
                name={field.name}
                onChange={field.onChange}
                value={field.value}
              >
                <HStack spacing={3} fontSize="sm">
                  <Radio value="yes">
                    <Text fontSize="sm">Sim</Text>
                  </Radio>
                  <Radio value="no">
                    <Text fontSize="sm">Não</Text>
                  </Radio>
                </HStack>

              </RadioGroup>
            )
          }}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.isFuelBenefitBlocked} mt={3}>
        <FormLabel fontSize="sm">
          Beneficio de combustível bloqueado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>

        <Controller
          control={control}
          name="isFuelBenefitBlocked"
          render={({ field }) => {
            return (
              <RadioGroup
                name={field.name}
                onChange={field.onChange}
                value={field.value}
              >
                <HStack spacing={3} fontSize="sm">
                  <Radio value="yes">
                    <Text fontSize="sm">Sim</Text>
                  </Radio>
                  <Radio value="no">
                    <Text fontSize="sm">Não</Text>
                  </Radio>
                </HStack>

              </RadioGroup>
            )
          }}
        />
      </FormControl>

      <HStack
        w="full"
        justify="end"
        mt={3}
      >
        <Button
          type="submit"
          colorScheme="blue"
          ml="auto"
          size="sm"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Salvar
        </Button>
      </HStack>
    </Box>

  )
}

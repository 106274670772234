import { OccurrenceAccordion } from "components/Accordions/Occurrence/OccurrenceAccordion";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useInput } from "hooks/input/useInput";
import { useOccurrence } from "hooks/occurrences/useOccurrence";
import { useOccurrenceFunctions } from "hooks/occurrences/useOccurrenceFunctions";
import { useSwal } from "hooks/swal/useSwal";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { AlignOccurrenceForm } from "./components/AlignOccurrenceForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "hooks/auth/useAuth";
import { useQueryClient } from "react-query";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";

interface Params {
  id: string
}

interface IActionPreventiveResponsibles {
  alignment_actions: string
  preventive_responsible: string
  preventive_limit_date: string
}

export interface AlignOccurrenceInputs {
  alignment_indicated_user_type: string
  customer_id: string
  collector_id: string
  alignment_indicated_user: string
  has_collector_fine: string
  collector_fine_value: string
  alignments: IActionPreventiveResponsibles[]
}

const alignOccurrenceSchema = yup.object().shape({
  //alignment_actions: yup.string().required('Campo obrigatório'),
  alignments: yup.array().of(yup.object().shape({
    alignment_actions: yup.string().required('Campo obrigatório'),
    preventive_responsible: yup.string().required('Campo obrigatório'),
    preventive_limit_date: yup.string().required('Campo obrigatório')
  })).test('array-size', 'É necessário ter no minimo 1 responsável no alinhamento', (value) => value.length === 1 || (value.length > 1)),
  alignment_indicated_user_type: yup.string().required('Campo obrigatório'),
  has_collector_fine: yup.mixed().when('alignmen_indicated_user_type', {
    is: 'COLETADOR',
    then: yup.string().required('Campo obrigatório'),
  }),
  collector_fine_value: yup.mixed().when('alignmen_indicated_user_type', {
    is: 'COLETADOR',
    then: yup.string().required('Campo obrigatório'),
  }),
  alignment_indicated_user: yup.string().required('Campo obrigatório'),
  collector_id: yup.string().when('alignment_indicated_user_type', {
    is: (value: string) => ['COLETADOR', 'MOTORISTA'].includes(value),
    then: yup.string().required('Campo obrigatório'),
  }),
  customer_id: yup.string().when('alignment_indicated_user_type', {
    is: 'CLIENTE',
    then: yup.string().required('Campo obrigatório'),
  }),
})

export function AlignOccurrence() {

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const { id } = useParams<Params>()

  useEffect(() => {
    if (!userLogged?.permissions.includes('align-customer-occurrence')) {
      redirect('/')
    }
  }, [userLogged, redirect])

  const {
    alignOccurrence: { mutateAsync: alignOccurrence }
  } = useOccurrenceFunctions()

  const {
    occurrence: {
      data: occurrence,
      isFetching: isFetchingOccurrence
    },
    handleSetOccurrenceId
  } = useOccurrence(false)
  const {
    inputs: { data: inputs, isFetching: isFetchingInputs }
  } = useInput(null, true)

  useEffect(() => {
    if (id) {
      handleSetOccurrenceId(id)
    }
  }, [id, handleSetOccurrenceId])

  const formMethods = useForm({
    resolver: yupResolver(alignOccurrenceSchema)
  })

  const { handleSubmit } = formMethods

  const { confirmMessage, standardMessage } = useSwal()

  const queryClient = useQueryClient()

  async function handleAlignOccurrence({
    alignments,
    alignment_indicated_user,
    collector_fine_value,
    has_collector_fine
  }: AlignOccurrenceInputs) {
    const hasAlignOccurrence = await confirmMessage({
      title: 'Deseja confirmar o alinhamento da ocorrência?'
    })

    if (hasAlignOccurrence) {
      await alignOccurrence({
        id,
        input: {
          alignments,
          alignment_indicated_user,
          collector_fine_value: has_collector_fine === 'yes'
            ? transformStringToNumber(collector_fine_value)
            : 0
        }
      }, {
        onSuccess: () => {
          redirect('/ocorrencias/pendentes/alinhamento')
        }
      })
      await queryClient.invalidateQueries('userTasks')
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <StandardBackgroundForm
      title='Alinhar ocorrência'
      onSubmit={handleSubmit(handleAlignOccurrence)}
    >
      <OccurrenceAccordion
        occurrenceById={occurrence}
        inputs={inputs}
        isInputsLoading={isFetchingInputs}
        isOccurrenceLoading={isFetchingOccurrence}
      />

      <FormProvider {...formMethods}>
        <AlignOccurrenceForm />
      </FormProvider>

    </StandardBackgroundForm>
  )
}

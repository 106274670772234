import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, IconButton, Modal, ModalOverlay, Table, TableContainer, Tbody, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { Regional } from "api/regionals/_types/Regional";
import { getVaccines } from "api/vaccines/getVaccines";
import { Vaccine, VaccineStatus } from "api/vaccines/vaccine";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { Pagination } from "components/Pagination/Pagination";
import { format } from "date-fns";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { FaExchangeAlt, FaFileDownload, FaFileExport, FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { vaccineCollaboratorTypeMap, vaccineStatusMap, vaccineTypesMap } from "utils/vaccineMappers";
import { CreateVaccine } from "./components/CreateVaccine";
import { VaccinesTableFilters } from "./components/VaccinesTableFilters";
import { VaccinesTableRow } from "./components/VaccinesTableRow";

const headers = [
  { label: 'COLABORADOR', key: 'collaborator_name' },
  { label: 'TIPO DE COLABORADOR', key: 'collaborator_type' },
  { label: 'REGIONAL', key: 'regional' },
  { label: 'STATUS', key: 'status' },
  { label: 'VACINA', key: 'type' },
  { label: 'DATA PRÓXIMA DOSE', key: 'next_dose_date' },

]

function formatValuesToReport(values: Array<Vaccine & { regional: Regional }>) {
  return values?.map((vaccine) => ({
    ...vaccine,
    collaborator_type: vaccineCollaboratorTypeMap[vaccine.collaborator_type],
    regional: vaccine.regional ? vaccine.regional.code.toUpperCase() : '-',
    next_dose_date: vaccine.next_dose_date
      ? format(new Date(vaccine.next_dose_date), 'dd/MM/yyyy')
      : '-',
    type: vaccineTypesMap[vaccine.type],
    status: vaccineStatusMap[vaccine?.status]
  }))
}



export function Vaccines() {

  const { replace, push: redirect } = useHistory()
  const searchParams = useSearchParams()

  const { userLogged } = useAuth()

  const userHasViewVaccinesPermission = userLogged?.permissions.includes('view-vaccines')

  const userHasCreateVaccinePermission = userLogged?.permissions.includes('create-vaccine')

  useEffect(() => {
    if (!userHasViewVaccinesPermission) {
      redirect('/')
    }
  }, [userHasViewVaccinesPermission, redirect])

  const page = searchParams.get('page') ?? '1'
  const collaborator = searchParams.get('collaborator')
  const status = searchParams.get('status') ?? 'all'

  const { data: vaccinesData } = useQuery({
    queryKey: ['vaccines', page,
      collaborator,
      status],
    queryFn: () => getVaccines({
      currentPage: page,
      pageSize: '10',
      timezoneOffset: String(new Date().getTimezoneOffset() / 3),
      collaborator,
      status: status as VaccineStatus | 'all'
    }),
    keepPreviousData: true
  })

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    pagesCount: vaccinesData?.meta?.totalPages,
    initialState: {
      currentPage: Number(page),
      pageSize: vaccinesData?.meta?.pageSize
    },
  })

  function handleChangePage(page: number) {
    setCurrentPage(page)

    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  const [isGenerateCsvEnabled, setIsGenerateCsvEnabled] = useState(false)

  function handleGenerateCsv() {
    setIsGenerateCsvEnabled(true)
  }

  const {
    data: vaccinesDataReportResult,
    isLoading: isLoadingVaccinesDataReportResult
  } = useQuery({
    queryKey: [
      'vaccines-report',
      status,
      collaborator,
      vaccinesData?.meta?.count,
    ],
    queryFn: () => getVaccines({
      currentPage: '1',
      pageSize: String(vaccinesData?.meta?.count),
      status: ["pending", "inactive", "on-time"].includes(status) ? status as VaccineStatus : 'all',
      collaborator,
      timezoneOffset: String(new Date().getTimezoneOffset() / 3),
    }),
    onSuccess() {
      setIsGenerateCsvEnabled(false)
    },
    enabled: isGenerateCsvEnabled
  })


  const {
    isOpen: isCreateVaccineModalOpen,
    onOpen: onOpenCreateVaccineModal,
    onClose: onCloseCreateVaccineModal
  } = useDisclosure()

  const csvReportProps = {
    data: vaccinesDataReportResult ? formatValuesToReport(vaccinesDataReportResult?.vaccines) : [],
    headers,
    filename: `vacinas.csv`,
  }

  return (
    <Box
      bg="white"
      p="6"
      rounded="md"
    >
      <Flex
        align="center"
        justify="space-between"
      >
        <Heading letterSpacing="tight">Vacinas</Heading>
        <Flex width='full' justifyContent='flex-end' gap={3}>
          <IconButton
            aria-label="Visualizar kanban"
            icon={<FaExchangeAlt />}
            variant='ghost'
            borderWidth={'thin'}
            size="sm"
            onClick={() => redirect('/vacinas/kanban')}
          />
          {userHasCreateVaccinePermission && (
            <IconButton
              aria-label="Criar Vacina"
              icon={<FaPlus />}
              colorScheme="blue"
              size="sm"
              onClick={onOpenCreateVaccineModal}
            />
          )}
        </Flex>

        <Modal
          isOpen={isCreateVaccineModalOpen}
          onClose={onCloseCreateVaccineModal}
          size="2xl"
          isCentered
        >
          <ModalOverlay />
          <CreateVaccine onCloseModal={onCloseCreateVaccineModal} />
        </Modal>
      </Flex>
      <VaccinesTableFilters />
      <TableContainer
        border="1px solid"
        borderColor="gray.200"
        rounded="md"
        mt="6"
      >
        <Table size="sm">
          <Thead>
            <Tr
              h="40px"
              bg="#38c3fa"
            >
              <Th></Th>
              <Th></Th>
              <Th color="white">Colaborador</Th>
              <Th color="white">Tipo de colaborador</Th>
              <Th color="white">Regional</Th>
              <Th color="white">Vacina</Th>
              <Th color="white">Status</Th>
              <Th color="white">Data próxima dose</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {vaccinesData?.vaccines?.map((vaccine) => {
              return (
                <VaccinesTableRow
                  key={vaccine.id}
                  vaccine={vaccine}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {!vaccinesDataReportResult ? (
        <Flex justify="end">
          <Button
            isLoading={isLoadingVaccinesDataReportResult}
            onClick={handleGenerateCsv}
            colorScheme="green"
            type="button"
            mt={4}
            size="sm"
            leftIcon={<FaFileExport />}
          >
            Gerar csv
          </Button>
        </Flex>
      ) : (
        <GenerateExcelReportButton
          csvReportProps={csvReportProps}
          leftIcon={<FaFileDownload />}
          size="sm"
          w={{ base: 'full', md: 'min' }}
          buttonTitle='Download csv'
        />
      )}
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />
    </Box>

  )
}

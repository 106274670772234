import {
  getAllInputs,
  getOneInput,
} from '../../services/getFunctions/input/getInput'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

export function useInput(
  inputId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const inputs = useQuery('inputs', getAllInputs, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })
  const input = useQuery(
    ['input', inputId],
    () => getOneInput(inputId !== null ? inputId : ''),
    {
      enabled: !!inputId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    inputs,
    input,
  }
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Link, Progress, Select, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
import { validateHasFile } from "utils/fileValidation";
import { set } from "date-fns";
import { ChangeEvent } from "react";
import { useAttachmentMutation } from "hooks/attachment/useAttachmentMutation";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation, useQueryClient } from "react-query";
import { reviewDocument } from "api/documents/reviewDocument";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";

interface ReviewDocumentRequestProps {
  documentId: string
}

interface ReviewDocumentRequestSchema {
  isDocumentChanged: 'yes' | 'no'
  documentAttachmentId: FileList
  nextReviewDate: string
  version: string
}

const reviewDocumentRequestSchema = yup.object({
  isDocumentChanged: yup.boolean().required().transform(value => value === 'yes'),
  documentAttachmentId: yup.mixed().when('isDocumentChanged', {
    is: true,
    then: yup.mixed().test(value => validateHasFile(value)).required(),
  }),
  nextReviewDate: yup.mixed().when('isDocumentChanged', {
    is: true,
    then: yup.mixed().required().transform((value, originalValue, ctx) => {
      if (!value) return null

      const [year, month, day] = originalValue?.split('-').map(Number)
      value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

      return value
    })
  }),
  version: yup.mixed().when('isDocumentChanged', {
    is: true,
    then: yup.mixed().required(),
  })
})

export function ReviewDocumentRequest({ documentId }: ReviewDocumentRequestProps) {
  const { mutation: uploadDocumentAttachmentFn, uploadProggress: uploadProggressDocumentAttachment } = useAttachmentMutation()

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const { mutateAsync: reviewDocumentFn } = useMutation({
    mutationFn: reviewDocument,
    onSuccess: () => {
      queryClient.invalidateQueries(['documents'])
      queryClient.invalidateQueries({ queryKey: 'non-evaluated-documents' })
      queryClient.invalidateQueries({ queryKey: 'expired-documents' })
      queryClient.invalidateQueries({ queryKey: 'active-documents' })
      queryClient.invalidateQueries({ queryKey: 'inactive-documents' })
      queryClient.invalidateQueries({ queryKey: ['document', documentId] })

    }
  })

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<ReviewDocumentRequestSchema>({
    resolver: yupResolver(reviewDocumentRequestSchema)
  })

  const [documentAttachmentId, isDocumentChanged] = useWatch({
    control,
    name: ['documentAttachmentId', 'isDocumentChanged'],
  })

  async function handleUploadDocumentAttachment(event: ChangeEvent<HTMLInputElement>) {
    const formData = new FormData()

    formData.append('attachment', event.target.files[0])

    await uploadDocumentAttachmentFn.mutateAsync(formData)

  }

  async function handleReviewDocumentRequest(values: ReviewDocumentRequestSchema) {

    await promiseMessage(reviewDocumentFn({
      body: {
        ...values,
        isDocumentChanged: values.isDocumentChanged === 'yes',
        documentAttachmentId: uploadDocumentAttachmentFn?.data?.attachment?.id
      },
      routeParams: {
        documentId
      }
    }), 'Solicitação avaliada')

  }
  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleReviewDocumentRequest)}
      maxH='500px'
      overflowY='scroll'
    >
      <Heading letterSpacing="tight" size='sm'>Avaliar solicitação do documento</Heading>

      <Stack
        spacing="6"
        direction={["column", "column", "row"]}
        mt="3"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormLabel fontSize="sm">
            Documento sofreu alteração?
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.isDocumentChanged}>
            <Select
              {...register('isDocumentChanged')}
              name="isDocumentChanged"
              placeholder="Selecione..."
              size="sm"
              rounded="md"
            >
              <option key='yes' value='yes'>Sim</option>
              <option key='no' value='no'>Não</option>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {isDocumentChanged === 'yes' && (
        <>
          <Stack
            direction="column"
            w="full"
            mt="3"
          >
            <Button
              as={FormLabel}
              htmlFor="documentAttachmentId"
              lineHeight="1"
              leftIcon={<FaFileImport />}
              size="sm"
              w="min"
              cursor="pointer"
              border={!!errors?.documentAttachmentId && '2px solid'}
              borderColor={(!!errors?.documentAttachmentId) && 'red.500'}
            >
              Anexar Documento
            </Button>
            <FormControl isInvalid={!!errors?.documentAttachmentId}>
              <Input
                {...register('documentAttachmentId')}
                name="documentAttachmentId"
                id="documentAttachmentId"
                type="file"
                hidden
                onChangeCapture={handleUploadDocumentAttachment}
              />
            </FormControl>
          </Stack>

          {documentAttachmentId && (
            <Table size="sm">
              <Tbody>
                {Object.entries(documentAttachmentId).map(([key, file]) => {
                  return (
                    <Tr key={key}>
                      <Td fontSize="xs" maxW="100px">{file.name}</Td>
                      <Td fontSize="xs" w="200px">
                        <Progress size="sm" rounded="md" value={uploadProggressDocumentAttachment} />
                      </Td>
                      <Td fontSize="xs" isNumeric>
                        {uploadDocumentAttachmentFn.data && (
                          <IconButton
                            aria-label="Visualizar anexo"
                            as={Link}
                            size="sm"
                            icon={<FaExternalLinkAlt />}
                            href={uploadDocumentAttachmentFn.data.attachment.link}
                            isExternal
                          />
                        )}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors?.nextReviewDate}>
                <FormLabel fontSize="sm">
                  Próxima revisão
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('nextReviewDate')}
                  name="nextReviewDate"
                  type="date"
                  size="sm"
                  rounded="md" />
              </FormControl>
            </Stack>

          </Stack>
          <Stack
            spacing="6"
            direction={["column", "column", "row"]}
            mt="3"
          >
            <Stack
              direction="column"
              w="full"
              spacing="0.25"
            >
              <FormControl isInvalid={!!errors.version} mt="3">
                <FormLabel fontSize="sm">
                  Versão
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <Input
                  {...register('version')}
                  name="version"
                  size="sm"
                  rounded="md"
                />
              </FormControl>
            </Stack>
          </Stack>
        </>
      )}

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>

    </Box>
  )
}

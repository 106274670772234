import { Box, Button, Flex, Icon } from "@chakra-ui/react";
import { Select } from "components/Inputs/SelectInput";
import { UserProps } from "contexts/UserContext";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FaTrash } from "react-icons/fa";
import { MonitoringUsersReducedData } from "../EditViewMonitoringUsers";
import { MonitoringUsersPeriodField } from "./MonitoringUsersPeriodField";

interface MonitoringUsersFormProps {
  users: UserProps[]
  monitoringUsersReducedData?: MonitoringUsersReducedData
  onSetDeletedMonitoringUsersIds?: (monitoringId: string) => void
  isViewPage?: boolean
}

export interface MonitoringUsersFormInputs {
  monitoringDays: {
    day: number
    periods: {
      startHour: string
      endHour: string
      responsibles: {
        userId: string
        monitoringId?: string
      }[]
    }[]
  }[]
}

const dayOptions = [
  { key: '0', value: 0, showOption: 'DOMINGO' },
  { key: '1', value: 1, showOption: 'SEGUNDA-FEIRA' },
  { key: '2', value: 2, showOption: 'TERÇA-FEIRA' },
  { key: '3', value: 3, showOption: 'QUARTA-FEIRA' },
  { key: '4', value: 4, showOption: 'QUINTA-FEIRA' },
  { key: '5', value: 5, showOption: 'SEXTA-FEIRA' },
  { key: '6', value: 6, showOption: 'SÁBADO' },
]

export function MonitoringUsersForm({
  users,
  monitoringUsersReducedData,
  onSetDeletedMonitoringUsersIds,
  isViewPage
}: MonitoringUsersFormProps) {

  const {
    register,
    control,
    formState: {
      errors
    }
  } = useFormContext<MonitoringUsersFormInputs>()

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({
    name: 'monitoringDays',
    control,
  })

  useEffect(() => {
    if (monitoringUsersReducedData) {
      Object.entries(monitoringUsersReducedData).forEach(([day, periods], index) => {
        const formattedPeriods = Object.entries(periods).map(([period, responsibles]: [string, any[]], periodIndex) => {
          const startHour = period.split(' - ')[0]
          const endHour = period.split(' - ')[1]

          return {
            startHour,
            endHour,
            responsibles: responsibles.map((responsible) => {
              return {
                userId: responsible.id,
                monitoringId: responsible.monitoringId
              }
            }),
          }
        })

        update(index, {
          day: Number(day),
          periods: formattedPeriods
        })
      })
    }
  }, [update, monitoringUsersReducedData])

  const handleRemoveMonitoringDayField = (index: number) => remove(index)

  const handleAppendMonitoringDayField = () => append({
    day: undefined,
    periods: []
  })

  return (
    <Flex direction="column" gap="6">
      {fields.map((field, index) => {

        return (
          <Box key={field.id} border="1px solid" p="4" borderRadius="base" borderColor="gray.100">
            <Select
              {...register(`monitoringDays.${index}.day`)}
              name={`monitoringDays.${index}.day`}
              options={dayOptions}
              error={
                errors?.monitoringDays
                  ? errors?.monitoringDays[index]?.day
                  : undefined
              }
              label="Dia"
              placeholder="Selecione uma opção..."
              isDisabled={isViewPage}
              required
            />

            <MonitoringUsersPeriodField
              control={control}
              index={index}
              register={register}
              users={users}
              errors={errors}
              onSetDeletedMonitoringUsersIds={onSetDeletedMonitoringUsersIds}
              isViewPage={isViewPage}
            />

            <Button
              colorScheme="red"
              leftIcon={<Icon as={FaTrash} />}
              onClick={() => handleRemoveMonitoringDayField(index)}
              isDisabled={isViewPage}
              mt="4"
            >
              Excluir dia
            </Button>
          </Box>
        )
      })}

      {!isViewPage && (
        <Button
          onClick={handleAppendMonitoringDayField}
        >
          Adicionar dia
        </Button>
      )}
    </Flex>
  )
}

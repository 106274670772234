import { Flex, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { getPmc } from "api/pmcs/getPmc";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale"
import { useQuery } from "react-query";
import { PmcPurchaseOrderStatus } from "./PmcPurchaseOrderStatus";
import { PmcStatus } from "./PmcStatus";

interface PmcDetailProps {
  pmcId: string
}

export function PmcDetail({ pmcId }: PmcDetailProps) {

  const {
    data: pmcData,
    isLoading: isPmcLoading
  } = useQuery(['pmc', pmcId], () => getPmc({ pmcId }))

  const pmcStatus = pmcData?.pmc.approved_at === null
    ? 'approving'
    : pmcData?.pmc?.received_at === null
      ? 'receiving'
      : 'received'

  return (
    <ModalContent>
      {isPmcLoading ? (
        <ModalBody display="flex" alignItems="center" justifyContent="center" p="20">
          <Spinner />
        </ModalBody>
      ) : (
        <>
          <ModalHeader letterSpacing="tight">
            <Flex
              direction="column"
            >
              Pmc: {pmcData?.pmc?.protocol}
              <Text as="span" fontSize="xs" fontWeight="medium">Detalhes da pmc</Text>
            </Flex>
          </ModalHeader >
          <ModalCloseButton />

          <ModalBody
            maxHeight="600px"
            overflowY="scroll"
          >
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Status</Td>
                  <Td display="flex" justifyContent="flex-end">
                    <PmcStatus status={pmcStatus} />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Almoxarifado</Td>
                  <Td textAlign="right">{pmcData?.pmc?.warehouse.name}</Td>
                </Tr>
                <Tr>
                  <Td>Solicitado há</Td>
                  <Td textAlign="right">{formatDistanceToNow(new Date(pmcData?.pmc?.created_at), {
                    locale: ptBR,
                    addSuffix: true
                  })}</Td>
                </Tr>
                <Tr>
                  <Td>Aprovado em</Td>
                  <Td textAlign="right">
                    {
                      pmcData?.pmc?.approved_at
                        ? formatDistanceToNow(new Date(pmcData?.pmc?.approved_at), {
                          locale: ptBR,
                          addSuffix: true
                        })
                        : '-'
                    }
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <TableContainer>
              <Table size="sm" mt="6">
                <Thead>
                  <Tr>
                    <Th>Produto</Th>
                    <Th>Fornecedor</Th>
                    <Th>Medida</Th>
                    <Th>Atual</Th>
                    <Th>Padrão</Th>
                    <Th>Mínimo</Th>
                    <Th>Custo unitário</Th>
                    <Th>Qtde comprada</Th>
                    <Th isNumeric>Custo estimado</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pmcData?.pmc?.pmcProducts?.map((pmcProduct) => {
                    return (
                      <Tr key={pmcProduct.product_id}>
                        <Td>{pmcProduct.product.material.material_name}</Td>
                        <Td>{pmcProduct.product.generalProvider.trading_name}</Td>
                        <Td>{pmcProduct.product.measure}</Td>
                        <Td>{pmcProduct.product.current_stock}</Td>
                        <Td>{pmcProduct.product.standard_stock}</Td>
                        <Td>{pmcProduct.product.min_stock}</Td>
                        <Td>{(pmcProduct.product.unit_cost / 100).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })}</Td>
                        <Td>{pmcProduct.approved_quantity ?? '-'}</Td>
                        <Td>{((pmcProduct.product.unit_cost / 100) * pmcProduct.approved_quantity).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })}</Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>

            {pmcData.pmc.purchaseOrders.length > 0 && (
              <TableContainer mt="6" border="1px solid" borderColor="gray.200" borderRadius="8px">
                <Table size="sm">
                  <TableCaption>Pedidos de compra</TableCaption>
                  <Thead
                    h="40px"
                  >
                    <Tr>
                      <Th>Status</Th>
                      <Th w="full">Fornecedor</Th>
                      <Th>Protocolo</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {pmcData.pmc.purchaseOrders.map((purchaseOrder) => {

                      console.log(purchaseOrder)

                      const purchaseOrderStatus = purchaseOrder.refused_at
                        ? 'refused'
                        : !purchaseOrder.quoted_at
                          ? 'quoting'
                          : purchaseOrder.quoted_at && !purchaseOrder.approved_at
                            ? 'approving'
                            : purchaseOrder.approved_at && !purchaseOrder.purchased_at
                              ? 'purchasing'
                              : purchaseOrder.purchased_at && purchaseOrder.purchase_has_invoice && !purchaseOrder.purchase_invoice_sended_at
                                ? 'sending-invoice'
                                : 'purchased'

                      return (
                        <Tr key={purchaseOrder.id}>
                          <Td display="flex" justifyContent="flex-start">
                            <PmcPurchaseOrderStatus status={purchaseOrderStatus} />
                          </Td>
                          <Td>{purchaseOrder.provider.trading_name}</Td>
                          <Td isNumeric>{purchaseOrder.protocol}</Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
        </>
      )
      }

    </ModalContent >
  )
}

import { Box, Grid, GridItem, Heading, HStack, Stack, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, Tab, TabIndicator, Table, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { getService } from "api/service/getService"
import { format } from "date-fns"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { captalize } from "utils/captalize"
import { serviceStepMap } from "utils/serviceMappers"
import { CollectStatus } from "./components/CollectStatus"

interface URLParams {
  serviceId: string
}

export function DetailServiceV2() {
  const { serviceId } = useParams<URLParams>()

  const {
    data: serviceResult,
    isLoading: isLoadingServiceResult
  } = useQuery({
    queryKey: ['service', serviceId],
    queryFn: () => getService({ serviceId })
  })

  const serviceRequestedCollectsCount = serviceResult?.service?.serviceIDRequested?.source_address_id.length

  const finishedCollectsCount = serviceResult?.service?.serviceIDCollect?.filter(
    (collect) => collect.step === 'DONE'
  ).length

  const finishedCollectsPercent = (finishedCollectsCount * 100) / serviceRequestedCollectsCount

  const unsuccessCollectsCount = serviceResult?.service?.serviceIDCollect?.filter(
    (collect) => collect.step === 'UNSUCCESS'
  ).length

  const unsuccessCollectsPercent = (unsuccessCollectsCount * 100) / serviceRequestedCollectsCount

  return (
    <Box
      p={6}
      rounded="md"
      bg="white"
    >
      <Heading letterSpacing="tight">{serviceResult?.service?.protocol}</Heading>
      {serviceResult && (
        <Grid templateColumns="repeat(3, 1fr)" mt={3} gap={6}>
          <GridItem>
            <Box
              rounded="lg"
              border="1px solid"
              borderColor="gray.200"
              p={2}
            >
              <Text as="b" ml={4}>Informações</Text>

              <Table
                w="full"
                size="sm"
              >
                <Tbody>
                  <Tr>
                    <Td>Tipo de serviço</Td>
                    <Td>{captalize(serviceResult?.service?.serviceIDRequested?.service_type ?? '')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Veículo</Td>
                    <Td>{captalize(serviceResult?.service?.serviceIDRequested?.vehicle ?? '')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data da coleta</Td>
                    <Td>{format(new Date(serviceResult?.service?.serviceIDRequested?.collect_date), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Data da entrega</Td>
                    <Td>{format(new Date(serviceResult?.service?.serviceIDRequested?.delivery_date), 'dd/MM/yyyy')}</Td>
                  </Tr>
                  <Tr>
                    <Td>Fuso Hub de origem</Td>
                    <Td>{serviceResult?.service?.serviceIDRequested?.budgetIDService?.sourceHubIDBudget?.timezone}</Td>
                  </Tr>
                  <Tr>
                    <Td>Fuso Hub de destino</Td>
                    <Td>{serviceResult?.service?.serviceIDRequested?.budgetIDService?.destinationHubIDBudget?.timezone}</Td>
                  </Tr>
                  <Tr>
                    <Td>Crossdocking</Td>
                    <Td>{!!serviceResult?.service?.serviceIDRequested?.crossdocking_collector_id ? 'Sim' : 'Não'}</Td>
                  </Tr>
                  {serviceResult?.service?.serviceIDRequested?.material_type && (
                    <Tr>
                      <Td>Tipo de material</Td>
                      <Td>{serviceResult?.service?.serviceIDRequested?.material_type}</Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>Etapa</Td>
                    <Td>{serviceStepMap[serviceResult?.service?.step]}</Td>
                  </Tr>
                </Tbody>

              </Table>

            </Box>
          </GridItem>
          <GridItem colSpan={2}>
            <Text fontSize="xl" as="b">Visão geral</Text>
            <Box
              border="1px solid"
              borderColor="gray.200"
              rounded="lg"
              p={3}
              mt={3}
            >
              <Text as="b">Coletas</Text>
              <Grid
                templateColumns="repeat(3, 1fr)"
                w="full"
                mt={3}
              >
                <GridItem>
                  <StatGroup>
                    <Stat>
                      <StatLabel>Solicitadas</StatLabel>
                      <StatNumber>{serviceRequestedCollectsCount}</StatNumber>
                    </Stat>
                  </StatGroup>
                </GridItem>

                <GridItem>
                  <StatGroup>
                    <Stat>
                      <StatLabel>Coletas finalizadas</StatLabel>
                      <HStack>
                        <StatNumber position="relative">
                          {finishedCollectsPercent}%
                          <StatHelpText position="absolute" right={-7} top={3}>
                            {`( ${finishedCollectsCount} )`}
                          </StatHelpText>
                        </StatNumber>
                      </HStack>
                    </Stat>
                  </StatGroup>
                </GridItem>

                <GridItem>
                  <StatGroup>
                    <Stat>
                      <StatLabel>Coletas sem sucesso</StatLabel>
                      <HStack>
                        <StatNumber position="relative">
                          {unsuccessCollectsPercent}%
                          <StatHelpText position="absolute" right={-7} top={3}>
                            {`( ${unsuccessCollectsCount} )`}
                          </StatHelpText>
                        </StatNumber>
                      </HStack>
                    </Stat>
                  </StatGroup>
                </GridItem>
              </Grid>

            </Box>

            <Box
              border="1px solid"
              borderColor="gray.200"
              rounded="md"
              p={3}
              mt={3}
            >
              <Text as="b">Coletas</Text>
              <Grid
                templateColumns="repeat(3, 1fr)"
                w="full"
                mt={3}
              >
                <GridItem>
                  <StatGroup>
                    <Stat>
                      <StatLabel>Solicitadas</StatLabel>
                      <StatNumber>{serviceRequestedCollectsCount}</StatNumber>
                    </Stat>
                  </StatGroup>
                </GridItem>

                <GridItem>
                  <StatGroup>
                    <Stat>
                      <StatLabel>Coletas finalizadas</StatLabel>
                      <HStack>
                        <StatNumber position="relative">
                          {finishedCollectsPercent}%
                          <StatHelpText position="absolute" right={-7} top={3}>
                            {`( ${finishedCollectsCount} )`}
                          </StatHelpText>
                        </StatNumber>
                      </HStack>
                    </Stat>
                  </StatGroup>
                </GridItem>

                <GridItem>
                  <StatGroup>
                    <Stat>
                      <StatLabel>Coletas sem sucesso</StatLabel>
                      <HStack>
                        <StatNumber position="relative">
                          {unsuccessCollectsPercent}%
                          <StatHelpText position="absolute" right={-7} top={3}>
                            {`( ${unsuccessCollectsCount} )`}
                          </StatHelpText>
                        </StatNumber>
                      </HStack>
                    </Stat>
                  </StatGroup>
                </GridItem>
              </Grid>

            </Box>

            <Box
              mt={3}
            >
              <Text fontSize="xl" as="b">Histórico</Text>

              <Tabs position="relative" variant="unstyled">
                <TabList>
                  <Tab fontWeight="semibold" letterSpacing="tight" fontSize="sm">Coletas</Tab>
                </TabList>
                <TabIndicator mt='-1.5px' height='2px' bg='blue.500' borderRadius='1px' />

                <TabPanels>
                  <TabPanel>
                    <Table size="sm" variant="unstyled">
                      <Thead>
                        <Tr>
                          <Th fontSize="xs" borderBottom="1px" borderColor="gray.200">Endereço</Th>
                          <Th fontSize="xs" borderBottom="1px" borderColor="gray.200">Motorista</Th>
                          <Th fontSize="xs" borderBottom="1px" borderColor="gray.200">Status</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {serviceResult.service?.serviceIDRequested.sourceAddresses.map(address => {

                          const collectInfo = serviceResult.service?.serviceIDCollect.find(
                            (collect) => collect.address_id === address.id
                          )

                          return (
                            <Tr key={address.id}>
                              <Td>{captalize(`${address.street}, ${address.neighborhood}, ${address.number}, ${address.cityIDAddress.name} - ${address.cep}`)}</Td>
                              <Td>{collectInfo ? captalize(`${collectInfo.driverIDCollect.firstname} ${collectInfo.driverIDCollect.lastname}`) : '-'}</Td>
                              <Td>{collectInfo ? <CollectStatus status={collectInfo.step as any} /> : '-'}</Td>
                            </Tr>
                          )
                        })}
                      </Tbody>
                    </Table>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </GridItem>
        </Grid>
      )}


    </Box>
  )
}

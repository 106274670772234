import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompletRefundBody {
  evidenceAttachmentId?: string
  invoiceNumber?: number
  disapprovalReason?: string
  status: string
}

interface CompletRefundParams {
  body: CompletRefundBody
  refundId: string
}

export async function completRefund({
  body,
  refundId
}: CompletRefundParams) {
  try {
    await api.put(`/refunds/${refundId}`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  RadioProps as ChakraRadioProps,
  Text,
  Stack,
  Radio as ChakraRadio,
  RadioGroup,
  Flex,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

export interface RadioOption {
  key: string
  value?: string
  showOption: ReactNode
}

interface InputProps extends ChakraRadioProps {
  name: string
  label?: string
  required?: boolean
  error?: FieldError
  radioOptions: RadioOption[]
  defaultCheckedOption?: string
}

const RadioBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    required = false,
    error = null,
    radioOptions,
    defaultCheckedOption,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex flex="1" justify="space-between" >
          <FormLabel htmlFor={name} noOfLines={1}>
            <Flex h="full" w="full" gap="1" align="center">
              <Text fontSize="sm" color="gray.700" w="full">
                {label}
              </Text>
              {required && (
                <Text display="block" mt="2" color="red" fontWeight="bold" fontSize="sm" as="sup">
                  {' '}
                  *
                </Text>
              )}
            </Flex>
          </FormLabel>
        </Flex>
      )}


      <Stack spacing="10px" justify="flex-start">
        <RadioGroup
          defaultValue={defaultCheckedOption}
          colorScheme="blue"
        >
          <Stack direction="column">
            {radioOptions?.map(option => (
              <ChakraRadio
                key={option.key}
                name={name}
                label={label}
                value={option.value}
                error={error}
                ref={ref}
                {...rest}
              >
                {option.showOption}
              </ChakraRadio>
            ))}
          </Stack>
        </RadioGroup>
      </Stack>


      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Radio = forwardRef(RadioBase)

import axios from "axios";
import { useMutation } from "react-query";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface AnswerThirdPartyRncInput {
  proceed: 'yes' | 'no'
  why_not_proceed: string
  cause: string
  corrective: string
  preventive: string
}

interface AnswerThirdPartyRncRequest {
  id: string
  input: AnswerThirdPartyRncInput
}

interface ValidateThirdPartyRncInput {
  risk_rating: string
  validation_cause: string
}

interface ValidateThirdPartyRncRequest {
  id: string
  input: ValidateThirdPartyRncInput
}

async function createThirdPartyRncReqFunction(input: FormData) {
  try {
    await api.post('/third-party-rnc', input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado.')
    }
  }
}

async function answerThirdPartyRncReqFunction({
  id,
  input
}: AnswerThirdPartyRncRequest) {
  try {
    await api.patch(`/third-party-rnc/answer/${id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado.')
    }
  }
}

async function deleteThirdPartyRncReqFunction(id: string) {
  try {
    await api.delete(`/third-party-rnc/${id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado.')
    }
  }
}
async function validateThirdPartyRncReqFunction({
  id,
  input
}: ValidateThirdPartyRncRequest) {
  try {
    await api.patch(`/third-party-rnc/validate/${id}`, input)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    } else {
      throw new RequestError('Erro inesperado.')
    }
  }
}


export function useThirdPartyRncFunctions() {

  const createThirdPartyRnc = useMutation(
    'createThirdPartyRnc',
    createThirdPartyRncReqFunction
  )

  const answerThirdPartyRnc = useMutation(
    'answerThirdPartyRnc',
    answerThirdPartyRncReqFunction
  )

  const validateThirdPartyRnc = useMutation(
    'validateThirdPartyRnc',
    validateThirdPartyRncReqFunction
  )

  const deleteThirdPartyRnc = useMutation(
    'validateThirdPartyRnc',
    deleteThirdPartyRncReqFunction
  )

  return {
    createThirdPartyRnc,
    answerThirdPartyRnc,
    validateThirdPartyRnc,
    deleteThirdPartyRnc
  }
}

import { Box } from "@chakra-ui/react";
import { getSupportSubject } from "api/supports/getSupportSubject";

import { useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { CreateSupportSchema } from "../CreateSupport";
import { AccountsReceivableProvisionForm } from "./AccountsReceivableProvisionForm";
import { CTECancellationForm } from "./CTECancellationForm";
import { NewClientCreditAnalysisForm } from "./NewClientCreditAnalysisForm";
import { NFCancellationForm } from "./NFCancellationForm";
import { SingleInvoicingRequestForm } from "./SingleInvoicingRequestForm";


export function CreateAccountReceivable() {

  const { control } = useFormContext<CreateSupportSchema>()


  const [supportSubjectId] = useWatch({
    control,
    name: ['subject']
  })


  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })


  const subjectIsCTECancellation = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Cancelamento de CTE'.toLowerCase()
  const subjectIsNFCancellation = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Cancelamento de NFs'.toLowerCase()
  const subjectIsSingleInvoicingRequest = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Solicitação de faturamento avulso'.toLowerCase()
  const subjectIsNewClientCreditAnalysis = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Análise de crédito de novo cliente correntista'.toLowerCase()
  const subjectIsAccountsReceivableProvision = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Provisão de contas a receber (Ajuste, inclusão ou exclusão)'.toLowerCase()



  return (
    <Box w='full'>

      {subjectIsCTECancellation && (
        <CTECancellationForm />
      )}

      {subjectIsNFCancellation && (
        <NFCancellationForm />
      )}

      {subjectIsSingleInvoicingRequest && (
        <SingleInvoicingRequestForm />
      )}

      {subjectIsNewClientCreditAnalysis && (
        <NewClientCreditAnalysisForm />
      )}

      {subjectIsAccountsReceivableProvision && (
        <AccountsReceivableProvisionForm />
      )}

    </Box>
  )
}

import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
  },
  styles: {
    global: {
      body: {
        bg: 'blue.50',
        color: 'blackAlpha.900',
        fontFamily: 'Outfit, sans-serif',
        overflow: 'hidden',
        height: '100svh',
        WebkitOverflowScrolling: 'touch',
      },
      "::-webkit-scrollbar": {
        width: "2px",
        height: '8px'
      },
      "::-webkit-scrollbar-track": {
        width: "6px",
        height: '2px'
      },
      "::-webkit-scrollbar-thumb": {
        background: "#87cefa",
        borderRadius: "2px",
        height: '4px'
      },
      "::-webkit-transform": {
        translate: "(0, 0, 0)",
      },
      html: {
        // height: '100vh',
        overflow: 'hidden',
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'Outfit, sans-serif',
      },
    },
    Button: {
      baseStyle: {
        _focus: {
          border: 'none',
          boxShadow: '0 0 0 2px #38c3fa',
        },
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: '#38c3fa'
      }
    },
    Input: {
      defaultProps: {
        focusBorderColor: '#38c3fa'
      }
    },
    Select: {
      defaultProps: {
        focusBorderColor: '#38c3fa'
      }
    }
  },
})

import { getAllLogs } from '../../services/getFunctions/log/getLog'
import { useQuery } from 'react-query'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'

export function useLog(isEnable?: boolean, refetchOnFocus?: boolean) {
  const { handleLogout, userLogged } = useContext(AuthContext)

  const logs = useQuery('logs', getAllLogs, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      toast.error('Erro ao consultar informações! Realize o login novamente.')
      handleLogout()
    },
  })

  return {
    logs,
  }
}

import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Box,
  Flex,
  Button,
} from '@chakra-ui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import * as yup from 'yup'

import { Select } from 'components/Inputs/SelectInput'
import { useSwal } from 'hooks/swal/useSwal'
import { SubmitButton } from 'components/Buttons/SubmitButton'
import { useBoardServiceAuthorization } from 'hooks/services/board/useBoardServiceAuthorization'
import { IDriverProps } from 'services/getFunctions/driver/getDrivers'
import { ShippingProps } from 'contexts/ShippingContext'


interface IGenerateBoardServiceAuthorizationModalProps {
  isOpen: boolean
  onClose: () => void
  serviceId: string
  drivers: IDriverProps[]
  shipping: ShippingProps
}

interface IFormInputProps {
  driver_id: string
  shipping_id: string

}

const schema = yup.object().shape({
  driver_id: yup.string().required('Campo Obrigatório'),
  shipping_id: yup.string().required('Campo Obrigatório'),
})

export function BoardServiceAuthorizationModal({
  isOpen,
  onClose,
  serviceId,
  drivers,
  shipping
}: IGenerateBoardServiceAuthorizationModalProps) {
  const [labelPdfUrl, setLabelPdfUrl] = useState('')

  const { confirmMessage, standardMessage } = useSwal()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(schema),
  })

  const driverId = useWatch({
    control,
    name: "driver_id"
  })

  const shippingId = useWatch({
    control,
    name: "shipping_id"
  })

  const { data: boardServiceAuthorizationData } = useBoardServiceAuthorization({ service_id: serviceId, queryOptions: { enabled: !!shippingId && !!driverId && !!serviceId }, queryParams: { current_date: new Date(), shipping_id: shippingId, driver_id: driverId } })


  async function handleGenerateBoardServiceAuthorizationPdf(values: IFormInputProps) {

    const hasBoardServiceAuthorizationPdf = await confirmMessage({
      title: 'Deseja gerar a autorização de embarque?',
    })

    if (hasBoardServiceAuthorizationPdf) {
      const bytes = new Uint8Array(boardServiceAuthorizationData)

      const url = URL.createObjectURL(
        new Blob([bytes], { type: 'application/pdf' }),
      )
      setLabelPdfUrl(url)

    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  return (
    <Modal
      size="4xl"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Gerar Autorização de Embarque</ModalHeader>
        <ModalCloseButton />

        <ModalBody overflow="hidden">

            <Box
              w="full"
              onSubmit={handleSubmit(handleGenerateBoardServiceAuthorizationPdf)}
              as="form"
              p="2"
              noValidate
            >
              <Stack direction="column" spacing="24px">
                <Select
                  {...register(`driver_id`)}
                  name={`driver_id`}
                  label="Motorista"
                  drivers={drivers}
                  error={errors.driver_id}
                  placeholder="Selecione uma opção..."
                  required
                />
                <Select
                  {...register('shipping_id')}
                  name="shipping_id"
                  label="Transportadora"
                  error={errors.shipping_id}

                  shipping={[shipping]}
                  placeholder="Selecione uma opção..."

                  required
                />


              </Stack>

              <Flex w="full" mt="4" justify="flex-end" align="center">
                <Stack direction="row">
                  <SubmitButton
                    action="Gerar Autorização"
                    isSubmitting={isSubmitting}

                  />
                  {labelPdfUrl !== '' && (
                    <a
                      href={labelPdfUrl}
                      download={`autorizacao-embarque-${serviceId}`}
                    >
                      <Button
                        onClick={() =>
                          setTimeout(() => setLabelPdfUrl(''), 1500)
                        }
                        colorScheme="green"
                      >
                        Download Autorização de Embarque
                      </Button>
                    </a>
                  )}
                </Stack>
              </Flex>
            </Box>

        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, Divider, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, Icon } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { Input } from "components/Inputs/Input"
import { Pagination } from "components/Pagination/Pagination"

import { differenceInMinutes, set } from "date-fns"
import { Address } from "hooks/address/dtos/Address"
import { useAuth } from "hooks/auth/useAuth"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { Occurrence } from "hooks/occurrences/dtos/Occurrence"
import { useInDeliveryServices } from "hooks/services/useInDeliveryServices"
import { Fragment, useEffect, useMemo, useReducer } from "react"
import { useForm, useWatch } from "react-hook-form"
import { FaExclamation, FaEye, FaPen, FaUndoAlt } from "react-icons/fa"
import { useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"

interface InDeliveryServicesFilterInputs {
  protocol?: string
  delivery_date?: string
  delivery_hour?: string
  customer?: string
  gelo_seco?: string
  source_city?: string
  destination_city?: string
  volume?: string
  recipient?: string
  collector?: string
}

interface ActionPayload {
  inDeliveryServiceData?: InDeliveryServicesFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-in-delivery-services-data' | 'set-in-delivery-services-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-in-delivery-services-current-page') {
    return {
      inDeliveryServiceData: { ...state.inDeliveryServiceData },
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

interface RequestedDeliveryAddressesActionPayload {
  requestedServiceId: string
  addresses: Address[]
}

interface RequestedDeliveryAddressesAction {
  payload: RequestedDeliveryAddressesActionPayload
}

function requestedDeliveryAddressesReducer(
  state: RequestedDeliveryAddressesActionPayload,
  action: RequestedDeliveryAddressesAction
) {
  return {
    addresses: action.payload.addresses,
    requestedServiceId: action.payload.requestedServiceId,
  }
}

const inDeliveryServicesListFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'delivery_date', value: 'Data da entrega', checked: false },
  { key: 'delivery_hour', value: 'Horário da entrega', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'gelo_seco', value: 'Gelo seco', checked: false },
  { key: 'source_city', value: 'Cidade(s) de origem', checked: false },
  { key: 'destination_city', value: 'Cidade(s) de destino', checked: false },
  { key: 'volume', value: 'Volume(s)', checked: false },
  { key: 'recipient', value: 'Destinatários', checked: false },
  { key: 'collector', value: 'Coletador de destino', checked: false },
  { key: 'drivers', value: 'Motoristas atribuídos', checked: false },
]

function compareDeliveryDateWithDateNow(
  deliveryDate: string,
  deliveryHour: string
) {
  const [collectEndHours, collectEndMinutes] = formatDate
    .handle(deliveryHour, 'DateOnlyWithHourMinute').split(':')


  const differenceToNow = differenceInMinutes(
    new Date(),
    set(new Date(deliveryDate), {
      hours: Number(collectEndHours),
      minutes: Number(collectEndMinutes)
    })
  )

  return differenceToNow > 0
}

export function InDeliveryServicesList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const [inDeliveryServicesDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const [
    requestedDeliveryAddressesState,
    dispatchRequestDeliveryAddresses
  ] = useReducer(requestedDeliveryAddressesReducer, {
    requestedServiceId: null,
    addresses: null
  })

  const { userLogged } = useAuth()

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()


  const filterOptionsByUserLogged = useMemo(() => {
    return inDeliveryServicesListFilterOptions.filter(option => {
      const userLoggedCollectorFilter = userLogged?.user_type === 'COLETADOR'
        ? option.key !== 'collector' && option.key !== 'drivers'
        : option.key !== 'drivers'

      return userLoggedCollectorFilter
    })
  }, [userLogged])

  useEffect(() => {
    onLoadSetFilterOptions(filterOptionsByUserLogged)
  }, [onLoadSetFilterOptions, filterOptionsByUserLogged])



  const { register, control } = useForm<InDeliveryServicesFilterInputs>()

  const [
    protocol,
    deliveryDate,
    deliveryHour,
    customer,
    geloSeco,
    sourceCity,
    destinationCity,
    volume,
    recipient,
    collector
  ] = useWatch({
    control,
    name: [
      'protocol',
      'delivery_date',
      'delivery_hour',
      'customer',
      'gelo_seco',
      'source_city',
      'destination_city',
      'volume',
      'recipient',
      'collector'
    ]
  })

  const {
    data: inDeliveryServicesData,
    isFetching: isFetchingInDeliveryServicesData
  } = useInDeliveryServices({
    queryParams: {
      ...inDeliveryServicesDataState.inDeliveryServiceData,
      collector_id: userLogged?.collector_id,
      driver_id: userLogged?.driver_id,
      current_page: inDeliveryServicesDataState.currentPage,
      page_size: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!inDeliveryServicesDataState.currentPage
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: inDeliveryServicesData?.total_pages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-in-delivery-services-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch({
        type: 'set-in-delivery-services-data',
        payload: {
          inDeliveryServiceData: {
            protocol: protocol,
            delivery_date: deliveryDate,
            delivery_hour: deliveryHour,
            customer: customer,
            gelo_seco: geloSeco,
            source_city: sourceCity,
            destination_city: destinationCity,
            volume: volume,
            recipient: recipient,
            collector: collector,
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounce)
  }, [
    protocol,
    deliveryDate,
    deliveryHour,
    customer,
    geloSeco,
    sourceCity,
    destinationCity,
    volume,
    recipient,
    collector,
    setCurrentPage
  ])

  const handleChangePage = (page: number) => setCurrentPage(page)

  const queryClient = useQueryClient()

  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['inDeliveryServices'])
  }

  const userHasStartDeliveryServicePermission =
    userLogged?.permissions.includes('add-delivery-service')

  const userHasValidateNewDeliveryServiceAddressesPermission =
    userLogged?.permissions.includes('validate-new-delivery-service-addresses')

  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Heading size='md'>Serviços em Entrega</Heading>

        <Divider />

        <Flex w={['full', 'full', 'min']} gap={2}>
          <TableFilterButton />
          <Button colorScheme="blue" onClick={handleRefetchTableData}>
            <Icon as={FaUndoAlt} />{' '}
          </Button>
        </Flex>

        {filterOptions
          .filter(option => option.checked)
          .map(option => {
            return (
              <Fragment key={option.key}>
                {option.key === 'delivery_date' ? (
                  <Input
                    {...register('delivery_date')}
                    name='delivery_date'
                    label='Data da entrega'
                    type='date'
                    size='sm'
                  />
                ) : (
                  option.key === 'delivery_hour' ? (
                    <Input
                      {...register('delivery_hour')}
                      name='delivery_hour'
                      label='Horário da entrega'
                      type='time'
                      size='sm'
                    />
                  ) : (
                    <Input
                      {...register(option.key as keyof InDeliveryServicesFilterInputs)}
                      name={option.key}
                      label={`Buscar ${option.value}`}
                      size='sm'
                      placeholder='Buscar...'
                    />
                  )
                )}
              </Fragment>
            )
          })}

        {isFetchingInDeliveryServicesData ? (
          <Spinner />
        ) : (
          <TableContainer>
            <Table size='sm' variant='striped'>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                  {inDeliveryServicesListFilterOptions.map(option => {
                    return (
                      <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {inDeliveryServicesData?.services?.map(service => {

                  const deliveryDelayOccurrences = [
                    'ATRASO NA ENTREGA',
                    'CANCELAMENTO DE VOO',
                    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
                    'ATRASO NA TRANSFERÊNCIA',
                    'ATRASO NA LIBERAÇÃO'
                  ]

                  const serviceDeliveryDelayOccurrence =
                    service.occurrenceIDService
                      .filter(occurrence => deliveryDelayOccurrences.includes(occurrence.intercurrence))
                      .reduce((acc, curr) => {
                        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
                      }, {} as Occurrence)

                  const deliveryDate = serviceDeliveryDelayOccurrence?.occurrence_date
                    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
                    : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

                  const deliveryHour = serviceDeliveryDelayOccurrence?.occurrence_hour
                    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_hour, "DateOnlyWithHourMinute")
                    : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

                  let serviceHasPendingDeliveryAddressesToFinishOrStart = true

                  const hasSomeDeliveryServiceInProggress = service.serviceIDDelivery
                    .find(delivery => delivery.step === 'GOING')

                  if (
                    !hasSomeDeliveryServiceInProggress &&
                    service.serviceIDRequested.destination_address_id.length ===
                    service.serviceIDDelivery.length
                  ) {
                    serviceHasPendingDeliveryAddressesToFinishOrStart = false
                  }

                  const serviceHasPendingNewDeliveryAddressesToValidate =
                    service.serviceIDRequested.additional_delivery_addresses.length > 0

                  const deliveryAssignedDrivers = service.deliveryDriversAssigned
                    ? Array.from(new Set(service.deliveryDriversAssigned))
                      .map((assignment) => `${assignment.driver.firstname}
                      ${assignment.driver.lastname}`)
                      .join(', ')
                    : ''

                  return (
                    <Tr
                      key={service.id}
                      color={!!serviceDeliveryDelayOccurrence.id
                        ? 'yellow.300'
                        : compareDeliveryDateWithDateNow(
                          service.serviceIDRequested.delivery_date,
                          service.serviceIDRequested.delivery_hour
                        )
                          ? 'red.300'
                          : ''
                      }
                    >
                      <Td>
                        {service?.serviceIDRequested?.budgetIDService?.priority_budget && (
                          <Icon as={FaExclamation} color={'red.500'} />
                        )}
                      </Td>
                      <Td>
                        <Flex>
                          {
                            userHasStartDeliveryServicePermission &&
                            serviceHasPendingDeliveryAddressesToFinishOrStart &&
                            service.step !== 'toValidateAdditionalDeliveryAddresses' && (
                              <Button
                                as={Link}
                                to={`/servicos/entregas/${service.id}`}
                                variant='unstyled'
                              >
                                <Flex w='full' h='full' align='center' justify='center'>
                                  <Icon as={FaPen} />
                                </Flex>
                              </Button>
                            )}
                          <Button
                            as={Link}
                            to={`/servico/detalhes/${service.id}`}
                            variant='unstyled'
                          >
                            <Flex w='full' h='full' align='center' justify='center'>
                              <Icon as={FaEye} />
                            </Flex>
                          </Button>
                        </Flex>
                      </Td>
                      <Td>{service.protocol}</Td>
                      <Td>{deliveryDate}</Td>
                      <Td>{deliveryHour}</Td>
                      <Td>{service.customerIDService.trading_firstname}</Td>
                      <Td>{service.serviceIDRequested.gelo_seco}</Td>
                      <Td>{Array.from(new Set(service.source_cities.map(city => city.name))).join(', ')}</Td>
                      <Td>{Array.from(new Set(service.destination_cities.map(city => city.name))).join(', ')}</Td>
                      <Td>{service.serviceIDLanding.map(landing => landing.landing_volume).join(', ') || '-'}</Td>
                      <Td>{service.recipients.join(', ')}</Td>
                      <Td>{service.serviceIDRequested.destinationCollectorIDService.trading_name}</Td>
                      <Td>{deliveryAssignedDrivers || '-'}</Td>
                    </Tr>
                  )
                })}

              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </Flex>
    </Box>


  )
}

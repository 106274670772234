import { useMutation, useQueryClient } from 'react-query'
import { updateStockFromCsvReqFunction } from '../../utils/RequestFunctions/Stock/requestStockFunctions'
import { useSwal } from '../swal/useSwal'

export const useStockFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const updateStockFromCsv = useMutation(
    'updateStockFromCsv',
    (input: FormData) => updateStockFromCsvReqFunction(input),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries('stocks')
        successMessage('Estoque(s) atualizado(s) com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    updateStockFromCsv,
  }
}

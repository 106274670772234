import { Box, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { FormDataItem } from "api/supports/createSupport";
import { FormEvent } from "react";
import { useFormContext } from "react-hook-form";
import { CreateSupportSchema } from "../CreateSupport";



export function NewEntrantForm() {

  const { register, setValue, formState: { errors } } = useFormContext<CreateSupportSchema>()


  const handleInputChange = (field: keyof FormDataItem) => (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const target = event.currentTarget
    const value = target.value;
    const labelMap: { [key in keyof FormDataItem]: string } = {
      signatureName: 'Nome que deve aparecer na assinatura',
      signaturePosition: 'Cargo que deve aparecer',
      signaturePhone: 'Telefone da assinatura',
      signatureAddress: 'Endereço da assinatura',
      fullName: 'Nome Completo',
      emailToBeUsed: 'Qual será o e-mail utilizado?',
    };


    setValue(`formData.${field}.label`, labelMap[field]);
    setValue(`formData.${field}.value`, value);
    setValue(`formData.${field}.type`, target.type);
  };
  return (
    <Box w='full'>
      <FormControl isInvalid={!!errors.formData?.signatureName} mt={3}>
        <FormLabel fontSize='sm'>
          Nome que deve aparecer na assinatura
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.signatureName.value')}
          name='formData.signatureName.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('signatureName')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.signaturePosition} mt={3}>
        <FormLabel fontSize='sm'>
          Cargo que deve aparecer
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.signaturePosition.value')}
          name='formData.signaturePosition.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('signaturePosition')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.signaturePhone} mt={3}>
        <FormLabel fontSize='sm'>
          Telefone da assinatura
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.signaturePhone.value')}
          name='formData.signaturePhone.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('signaturePhone')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.signatureAddress} mt={3}>
        <FormLabel fontSize='sm'>
          Endereço da assinatura
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.signatureAddress.value')}
          name='formData.signatureAddress.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('signatureAddress')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.fullName} mt={3}>
        <FormLabel fontSize='sm'>
          Nome Completo
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.fullName.value')}
          name='formData.fullName.value'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('fullName')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.formData?.emailToBeUsed} mt={3}>
        <FormLabel fontSize='sm'>
          Qual será o e-mail utilizado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('formData.emailToBeUsed.value')}
          name='formData.emailToBeUsed.value'
          type='email'
          size='sm'
          rounded="md"
          onChangeCapture={handleInputChange('emailToBeUsed')}
        />
      </FormControl>

    </Box>
  )
}

import { Attachment } from 'hooks/attachment/dtos/Attachment';
import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Sporadic } from "./_types/Sporadic";
import { Region } from 'hooks/regions/dtos/Region';

export interface GetSporadicResponse {
  sporadic: Sporadic & {
    emailRequestAttachment: Attachment
    regional: Region
  }
}

interface GetSporadicParams {
  sporadicId: string
}

export async function getSporadic({
  sporadicId
}: GetSporadicParams) {
  try {
    const response = await api.get<GetSporadicResponse>(`/sporadics/${sporadicId}`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

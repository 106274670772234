import { Button, Divider, Flex, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { InputMaskCustom } from "components/Inputs/InputMask";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";

import { IIbgeApiCityProps, IIbgeApiStatesProps } from 'services/ibgeApiservice/IIbgeApi';

import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { handleSwitchMask } from "utils/handleChangeMask";
import { situation } from "utils/customLists";
import { FaPlus, FaTimes } from "react-icons/fa";
import { FinancialClassificationResponse } from "hooks/financialClassification/dtos/FinancialClassification";
import { Material } from "hooks/material/dtos/Material";


interface GeneralProviderFormProps {
  slug: string
  ibgeStates?: IIbgeApiStatesProps[]
  ibgeCities?: IIbgeApiCityProps[]
  materials?: Material[]
  financialClassifications: FinancialClassificationResponse
}

interface IFinancialClassifications {
  _id: string
}
export interface ICostCenters {
  name: string
}
interface IMaterials {
  _id: string
}

export interface GeneralProviderFormInputs {
  situation: string
  company_name: string
  trading_name: string
  state_registration: number
  cnpj: string
  email: string
  payment_conditional: string
  payment_type: string
  cellphone: string
  telephone: string
  cep: string
  state: string
  city: string
  street: string
  number: number
  neighborhood: string
  complement: string
  type: string
  materials?: IMaterials[]
  financial_classifications: IFinancialClassifications[]
  cost_centers: ICostCenters[]
  observation: string
  provider_person: string
  cpf: string
}

const paymentTypeOptions = [
  { key: '0', value: 'BOLETO', showOption: 'Boleto' },
  { key: '1', value: 'TRANSFERÊNCIA', showOption: 'Transferência' },
  { key: '2', value: 'CARTÃO DE CRÉDITO', showOption: 'Cartão de crédito' },
]

const paymentConditionsOptions = [
  { key: '0', value: 'À VISTA', showOption: 'À vista' },
  { key: '1', value: 'FATURADO', showOption: 'Faturado' },
]

export const providerTypeOptions = [
  { key: 'product', value: 'PRODUTO', showOption: 'Produto' },
  { key: 'service', value: 'SERVIÇO', showOption: 'Serviço' },
]

export const providerPersonOptions = [
  { key: '0', value: 'PESSOA FÍSICA', showOption: 'PESSOA FÍSICA' },
  { key: '1', value: 'PESSOA JURÍDICA', showOption: 'PESSOA JURÍDICA' },
]

export function GeneralProviderForm({ ibgeCities, ibgeStates, slug, materials, financialClassifications }: GeneralProviderFormProps) {
  const isViewPage = slug === "visualizar"

  const {
    register,
    control,
    formState: {
      errors
    }
  } = useFormContext<GeneralProviderFormInputs>()

  const {
    fields: materialFields,
    append: appendMaterialField,
    remove: removeMaterialField
  } = useFieldArray({
    control,
    name: 'materials'
  })

  const {
    fields: financialClassificationFields,
    append: appendFinancialClassificationField,
    remove: removeFinancialClassificationField
  } = useFieldArray({
    control,
    name: 'financial_classifications'
  })

  const {
    fields: costCentersFields,
    append: appendCostCentersField,
    remove: removeCostCentersField
  } = useFieldArray({
    control,
    name: 'cost_centers'
  })

  function handleRemoveMaterialField(index: number) {
    removeMaterialField(index)
  }
  function handleAppendMaterialField() {
    appendMaterialField({ _id: '' })
  }

  function handleRemoveFinancialClassificationField(index: number) {
    removeFinancialClassificationField(index)
  }

  function handleAppendFinancialClassificationField() {
    appendFinancialClassificationField({ _id: '' })
  }

  function handleRemoveCostCenterField(index: number) {
    removeCostCentersField(index)
  }
  function handleAppendCostCenterField() {
    appendCostCentersField({ name: '' })
  }

  const materialsOptions = materials?.map(materials => {
    return { key: materials.id, value: materials.id, showOption: materials.material_name }
  })

  const financialClassificationsOptions = financialClassifications?.financial_classifications?.map(financialClassification => {
    return { key: financialClassification.id, value: financialClassification.id, showOption: financialClassification.type }
  })

  const cepForm = useWatch({
    control,
    name: 'cep'
  })
  const stateSelected = useWatch({
    control,
    name: 'state'
  })
  const typeProviderSelected = useWatch({
    control,
    name: 'type'
  })

  const providerPersonSelected = useWatch({
    control,
    name: 'provider_person'
  })

  return (
    <Flex direction="column" gap={6} align="center">
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <Select
          {...register('situation')}
          name="situation"
          label="Situação"
          error={errors.situation}
          isDisabled={isViewPage}
          situations={situation}
          placeholder="Selecione uma opção..."
          required
        />
        <Select
          {...register("provider_person")}
          name="provider_person"
          label="Tipo de fornecedor"
          placeholder="Selecione uma opção"
          error={errors.provider_person}
          options={providerPersonOptions}
          isDisabled={isViewPage}
          required
        />

      </Stack>
      {providerPersonSelected === 'PESSOA JURÍDICA' && (
        <Stack
          spacing={6}
          direction={["column", "column", "row"]}
          w="full">
          <Input
            {...register('company_name')}
            name="company_name"
            label="Razão Social"
            error={errors.company_name}
            isDisabled={isViewPage}
            required
          />
        </Stack>
      )}
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Input
          {...register('trading_name')}
          name="trading_name"
          label="Nome Fantasia"
          error={errors.trading_name}
          isDisabled={isViewPage}
          required
        />
        <Input
          {...register('state_registration')}
          name="state_registration"
          label="Inscrição Estadual"
          error={errors.state_registration}
          isDisabled={isViewPage}
        />
      </Stack>

      <Stack
        direction={["column", "column", "row"]}
        spacing={6}
        w="full"
      >
        {providerPersonSelected === 'PESSOA JURÍDICA' && (

          <Controller
            control={control}
            name="cnpj"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cnpj')}
                name="cnpj"
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('cnpj')}
                label="CNPJ"
                error={errors.cnpj}
                isDisabled={isViewPage}
                required
              />
            )}
          />
        )}
        {providerPersonSelected === 'PESSOA FÍSICA' && (
          <Controller
            control={control}
            name="cpf"
            render={({ field: { onChange, value } }) => (
              <InputMaskCustom
                {...register('cpf')}
                name="cpf"
                defaultValue={value}
                onChange={onChange}
                mask={handleSwitchMask('cpf')}
                label="CPF"
                error={errors.cpf}
                isDisabled={isViewPage}
                required
              />
            )}
          />
        )}
        <Input
          {...register('email')}
          name="email"
          label="E-mail"
          error={errors.email}
          isDisabled={isViewPage}
          required
        />
      </Stack>

      <Stack
        direction={["column", "column", "row"]}
        spacing={6}
        w="full"
      >
        <Select
          {...register('payment_conditional')}
          name="payment_conditional"
          label="Condição de pagamento"
          error={errors.payment_conditional}
          options={paymentConditionsOptions}
          placeholder="Selecione uma opção..."
          isDisabled={isViewPage}
          required
        />
        <Select
          {...register('payment_type')}
          name="payment_type"
          options={paymentTypeOptions}
          label="Tipo de pagamento"
          placeholder="Selecione uma opção..."
          error={errors.payment_type}
          isDisabled={isViewPage}
          required
        />
      </Stack>
      <Stack
        direction={["column", "column", "row"]}
        spacing={6}
        w="full"
      >
        <Controller
          control={control}
          name="cellphone"
          render={({ field: { onChange, value } }) => (
            <InputMaskCustom
              {...register('cellphone')}
              mask={
                handleSwitchMask('cellphone')
              }
              maskChar=""
              alwaysShowMask={false}
              defaultValue={value}
              onChange={onChange}
              name="cellphone"
              label="Celular"
              error={errors.cellphone}
              isDisabled={isViewPage}
              required

            />
          )}
        />
        <Controller
          control={control}
          name="telephone"
          render={({ field: { onChange, value } }) => (
            <InputMaskCustom
              {...register('telephone')}
              name="telephone"
              defaultValue={value}
              onChange={onChange}
              mask={handleSwitchMask('phone')}
              label="Telefone"
              error={errors.telephone}
              isDisabled={isViewPage}
              required
            />
          )}
        />
      </Stack>
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Controller
          control={control}
          name="cep"
          render={({ field: { onChange, value } }) => (
            <InputMaskCustom
              {...register('cep')}
              name="cep"
              label="CEP"
              error={errors.cep}

              defaultValue={value}
              onChange={onChange}
              mask={handleSwitchMask('cep')}
              isDisabled={isViewPage}
              required
            />
          )}
        />
        <Stack
          w='full'
          spacing={6}
          direction={['column', 'column', 'row']}
        >
          <Select
            {...register("state")}
            name="state"
            label="Estado"
            error={errors.state}
            placeholder="Selecione uma opção..."
            states={ibgeStates}
            isDisabled={isViewPage}
            required

          />
          {cepForm && stateSelected && (
            <Select
              {...register("city")}
              name="city"
              label="Cidade"
              citiesIBGE={ibgeCities}
              placeholder="Selecione uma opção..."
              error={errors.city}
              isDisabled={isViewPage}
              required

            />
          )}
        </Stack>

      </Stack>
      <Stack w="full" spacing={6} direction={['column', 'column', 'row']}>
        <Input
          {...register('street')}
          name="street"
          label="Rua"
          error={errors.street}
          isDisabled={isViewPage}
          required
        />

        <Input
          {...register('number')}
          name="number"
          label="Número"
          error={errors.number}
          isDisabled={isViewPage}
          required
        />
      </Stack>
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <Input
          {...register("neighborhood")}
          name="neighborhood"
          label="Bairro"
          error={errors.neighborhood}
          isDisabled={isViewPage}
          required
        />
        <Input
          {...register("complement")}
          name="complement"
          label="Complemento"
          error={errors.complement}
          isDisabled={isViewPage}
        />

      </Stack>
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">

        <HStack w="100%">
          <Select
            {...register('type')}
            name="type"
            options={providerTypeOptions}
            label="Tipo"
            placeholder="Selecione uma opção..."
            error={errors.type}
            isDisabled={isViewPage}
            required
          />

        </HStack>
      </Stack>

      {typeProviderSelected === 'PRODUTO' && (
        <>
          <Flex direction="column" w='full'>
            <Stack w='full' spacing={4} direction={['column', 'column', 'row']}>
              <Text>Materiais</Text>
            </Stack>
            <Divider />
          </Flex>
          <Flex direction="column" w='full'>
            {materialFields.map((field, index) => {
              return (
                <Flex key={index} w='full' alignItems={'end'}>
                  <Stack
                    w="full"
                    spacing="24px"
                    mt="4"
                    direction={['column', 'column', 'row']}
                  >
                    <Select
                      {...register(`materials.${index}._id`)}
                      name={`materials.${index}._id`}
                      label='Material'
                      placeholder='Selecione uma opção...'
                      error={errors.materials
                        ? errors?.materials[index]?._id
                        : undefined}
                      options={materialsOptions}
                      isDisabled={isViewPage}
                      required />
                  </Stack>

                  <Button
                    leftIcon={<Icon as={FaTimes} />}
                    variant="ghost"
                    onClick={() => handleRemoveMaterialField(index)}
                    h="48px"
                    ml="12px"
                    disabled={isViewPage}
                  >
                    Remover
                  </Button>

                </Flex>
              );
            })}
            <Stack w='full' spacing={4} direction={['column', 'column', 'row']}>
              <Button
                leftIcon={<Icon as={FaPlus} />}
                alignSelf="flex-start"
                onClick={handleAppendMaterialField}
                mt={2}
                w='full'
                disabled={isViewPage}
              >
                Adicionar
              </Button>
            </Stack>


          </Flex></>

      )}
      <Flex direction="column" w='full'>
        <Stack w='full' spacing={4} direction={['column', 'column', 'row']}>
          <Text>Classificações Financeiras</Text>

        </Stack>
        <Divider />
      </Flex>

      <Flex direction="column" w='full'>
        {financialClassificationFields?.map((field, index) => {

          return (
            <Flex key={field.id} w='full' alignItems={'end'}>
              <Stack
                w="full"
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Select
                  {...register(`financial_classifications.${index}._id`)}
                  name={`financial_classifications.${index}._id`}
                  label='Classificação Financeira'
                  placeholder='Selecione uma opção...'
                  error={
                    errors.financial_classifications
                      ? errors?.financial_classifications[index]?._id
                      : undefined
                  }
                  options={financialClassificationsOptions}
                  required
                  isDisabled={isViewPage}
                />
              </Stack>

              <Button
                leftIcon={<Icon as={FaTimes} />}
                variant="ghost"
                onClick={() => handleRemoveFinancialClassificationField(index)}
                h="48px"
                ml='12px'
                disabled={isViewPage}
              >
                Remover
              </Button>

            </Flex>
          )
        })}
        <Stack w='full' spacing={4} direction={['column', 'column', 'row']}>

          <Button
            leftIcon={<Icon as={FaPlus} />}
            alignSelf="flex-start"
            onClick={handleAppendFinancialClassificationField}
            mt={2}
            w="full"
            disabled={isViewPage}
          >
            Adicionar
          </Button>
        </Stack>
      </Flex>
      <Stack w='full' spacing={4} direction={['column', 'column', 'row']}>
        <Text>Centros de Custo</Text>
      </Stack>


      <Flex direction="column" w='full'>
        {costCentersFields?.map((field, index) => {
          return (
            <Flex
              key={index} w='full' alignItems='end'
            >
              <Stack
                w="full"
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Input
                  {...register(`cost_centers.${index}.name`)}
                  name={`cost_centers.${index}.name`}
                  label="Centro de Custo"
                  error={errors.cost_centers ? errors?.cost_centers[index]?.name : undefined}
                  isDisabled={isViewPage}
                  required
                />


              </Stack>
              <Stack pt={8}>
                <Button
                  leftIcon={<Icon as={FaTimes} />}
                  variant="ghost"
                  onClick={() => handleRemoveCostCenterField(index)}
                  h="48px"
                  ml='12px'
                  disabled={isViewPage}
                >
                  Remover
                </Button>
              </Stack>
            </Flex>
          )
        })}
        <Button
          leftIcon={<Icon as={FaPlus} />}
          alignSelf="flex-start"
          onClick={handleAppendCostCenterField}
          mt={2}
          w='full'
          disabled={isViewPage}
        >
          Adicionar
        </Button>
      </Flex>
      <Stack
        spacing={6}
        direction={["column", "column", "row"]}
        w="full"
      >
        <TextArea
          {...register("observation")}
          name="observation"
          label="Observações"
          isDisabled={isViewPage}
        />
      </Stack>
    </Flex>
  )
}

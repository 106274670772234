import { Button, Flex, Grid, GridItem, Skeleton } from "@chakra-ui/react"
import { getInternComunicationsByTeam } from "api/internComunications/getInternComunicationsByTeam"
import { useAuth } from "hooks/auth/useAuth"
import { useSearchParams } from "hooks/useSearchParams"
import { useInfiniteQuery } from "react-query"
import { InternComunicationCard } from "./InternComunicationCard"

export function TeamInternComunications() {
  const searchParams = useSearchParams()
  const { userLogged } = useAuth()

  const isManagmentOrDirectionOperationUserLoggedProfile =
    ['SUCESSO DO CLIENTE - DIRETORIA', 'SUCESSO DO CLIENTE - GERÊNCIA'].includes(userLogged.user_type)

  const isManagmentOrDirectionComercialUserLoggedProfile =
    userLogged.user_type === 'COMERCIAL - DIRETORIA'

  const isManagmentOrDirectionQualityUserLoggedProfile =
    userLogged.user_type === 'REGULAÇÃO E QUALIDADE - DIRETORIA'

  const isManagmentOrDirectionFinanceUserLoggedProfile =
    userLogged.user_type === 'ADMINISTRATIVO FINANCEIRO - DIRETORIA'

  const comunicationTypes = isManagmentOrDirectionOperationUserLoggedProfile
    ? ['DIRETORIA DE LOGÍSTICA - LAST MILE', 'DIRETORIA DE LOGÍSTICA - PLANEJAMENTO']
    : isManagmentOrDirectionComercialUserLoggedProfile
      ? ['DIRETORIA COMERCIAL']
      : isManagmentOrDirectionQualityUserLoggedProfile
        ? ['DIRETORIA DE REGULAÇÃO E QUALIDADE']
        : isManagmentOrDirectionFinanceUserLoggedProfile
          ? ['DIRETORIA FINANCEIRA']
          : userLogged?.user_type.includes('COMERCIAL')
            ? ['DIRETORIA COMERCIAL']
            : userLogged?.user_type.includes('FINANCEIRO')
              ? ['DIRETORIA FINANCEIRA']
              : userLogged?.user_type.includes('TORRE DE CONTROLE')
                ? ['DIRETORIA DE LOGÍSTICA - PLANEJAMENTO']
                : userLogged?.user_type.includes('LAST MILE')
                  ? ['DIRETORIA DE LOGÍSTICA - LAST MILE']
                  : []


  const customer = searchParams.get('customer');
  const responsible = searchParams.get('responsible');
  const serviceProtocol = searchParams.get('serviceProtocol');
  const sourceHub = searchParams.get('sourceHub');
  const destinationHub = searchParams.get('destinationHub');
  const date = searchParams.get('date');
  const hour = searchParams.get('hour');
  const comunicationType = searchParams.get('comunicationType')
  const comunicationProtocol = searchParams.get('comunicationProtocol')

  const {
    data: result,
    isLoading: isInternComunicationsByTeamLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'team-intern-comunications',
      userLogged?.id,
      sourceHub,
      customer,
      responsible,
      serviceProtocol,
      sourceHub,
      destinationHub,
      date,
      hour,
      comunicationType
    ],
    queryFn: ({ pageParam = 1 }) => getInternComunicationsByTeam({
      queryParams: {
        currentPage: pageParam,
        pageSize: '9',
        isManagmentOrDirection:
          isManagmentOrDirectionOperationUserLoggedProfile ||
          isManagmentOrDirectionComercialUserLoggedProfile ||
          isManagmentOrDirectionQualityUserLoggedProfile ||
          isManagmentOrDirectionFinanceUserLoggedProfile,
        comunicationTypes,
        customer,
        responsible,
        serviceProtocol,
        sourceHub,
        destinationHub,
        date,
        hour,
        comunicationProtocol,
        timezoneOffset: new Date().getTimezoneOffset() / 60,
        comunicationType
      }
    }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.meta.currentPage < lastPage.meta.totalPages
        ? lastPage.meta.currentPage + 1
        : undefined

      return nextPage
    },
    keepPreviousData: true,
  })

  return (
    <Flex
      direction="column"
    >
      <Grid
        gap="4"
        templateColumns={{ base: 'repeat(1fr)', lg: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }}
      >
        {isInternComunicationsByTeamLoading && (
          Array.from({ length: 6 }).map((_, index) => {
            return (
              <GridItem key={index.toString()}>
                <Skeleton h={335} w="full" rounded="lg" />
              </GridItem>
            )
          })
        )}
        {result?.pages?.map((page) => {
          return page?.internComunications?.map((internComunication) => {
            return (
              <GridItem key={internComunication.id}>
                <InternComunicationCard internComunication={internComunication} />
              </GridItem>
            )
          })
        })}
      </Grid>
      {hasNextPage && (
        <Button
          colorScheme="blue"
          alignSelf="center"
          mt="6"
          w={{ base: 'full', md: "400px" }}
          onClick={() => fetchNextPage()}
          isLoading={isFetchingNextPage}
          disabled={isFetchingNextPage}
        >
          Carregar mais
        </Button>
      )}
    </Flex>
  )
}

import { useEffect } from 'react'
import { useAuth } from "hooks/auth/useAuth"
import { useHistory } from "react-router-dom"

import { Box, Button, Flex, Heading, IconButton } from '@chakra-ui/react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { addDays, format, set, subDays } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FaArrowLeft } from 'react-icons/fa'
import { createDispatchStock } from 'api/dispachStocks/createDispachStock'
import { useMutation, useQuery } from 'react-query'
import { useToastify } from 'hooks/toastify/useToastify'
import { DispatchStockForm, DispatchStockFormSchema } from './components/DispatchStockForm'
import { getDispatchStockProposal } from 'api/dispachStocks/getDispatchStockProposal'
import { transformStringToNumber } from 'utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber'

const today = new Date()

function formatBoardDateToValidate(value: any, originalValue: string) {
  if (!value) return null

  value = set(addDays(new Date(originalValue), 1), { hours: 12 })

  return value
}

const createDispatchStockSchema = yup.object().shape({
  collectorId: yup.string().required('Campo Obrigatório'),
  destinationCollectorId: yup.string().required('Campo Obrigatório'),
  shippingId: yup.string().required('Campo Obrigatório'),
  proposalId: yup.string().required('Campo Obrigatório'),
  sender: yup.string().required('Campo Obrigatório'),
  branchId: yup.string().required('Campo Obrigatório'),
  boardDate: yup
    .date()
    .transform(formatBoardDateToValidate)
    .min(
      subDays(today, 2),
      `A data de embarque deve ser dia ${format(
        new Date(today),
        'dd/MM/yyyy',
      )} ou após esta data`,
    ),
  arrivalForecast: yup
    .date()
    .transform(formatBoardDateToValidate)
    .when(
      'board_date',
      (board_date, schema) =>
        board_date &&
        schema.min(
          subDays(new Date(board_date), 1),
          'A data prevista deve ser maior ou igual à data de embarque',
        ),
    ),
  withdrawalResponsible: yup.string().required('Campo obrigatório'),
  vehicle: yup.string().when('withdrawal_responsible', {
    is: 'COLETADOR',
    then: yup.string().required('Campo obrigatório')
  }),
  invoiceTotalValue: yup.number().required().transform((value, originalValue) => Math.ceil(transformStringToNumber(originalValue) * 100))
})

export function CreateDispatchStock() {
  const history = useHistory()
  const { userLogged } = useAuth()
  const { promiseMessage } = useToastify()

  const userCanCreateDispatchStock = userLogged?.permissions.includes('create-dispatch-stock')

  const { mutateAsync: createDispatchStockFn } = useMutation({
    mutationFn: createDispatchStock,
    onSuccess: () => {
      history.push('/despachos')
    }
  })


  useEffect(() => {
    if (!userCanCreateDispatchStock) history.push('/')
  }, [history, userCanCreateDispatchStock])

  const formMethods = useForm<DispatchStockFormSchema>({
    resolver: yupResolver(createDispatchStockSchema),
  })
  const { handleSubmit, control, setValue, formState: { isSubmitting } } = formMethods

  const proposalId = useWatch({
    control,
    name: 'proposalId'
  })

  const {
    data: dispatchStockProposalData,
  } = useQuery({
    queryKey: ['dispatch-stock-proposal', proposalId],
    queryFn: () => getDispatchStockProposal({ proposalId }),
    enabled: !!proposalId
  })

  const invoiceTotalValue = dispatchStockProposalData?.proposal?.inputs.reduce((acc, curr) => {
    return acc + (curr.send_quantity * curr.input.unit_cost);
  }, 0);

  useEffect(()=>{
    if(dispatchStockProposalData){
      setValue('invoiceTotalValue', String(invoiceTotalValue))
    }
  },[dispatchStockProposalData, invoiceTotalValue, setValue])


  async function handleCreateDispatchStock({
    arrivalForecast,
    boardDate,
    branchId,
    collectorId,
    destinationCollectorId,
    proposalId,
    sender,
    shippingId,
    vehicle,
    withdrawalResponsible,
    invoiceTotalValue
  }: DispatchStockFormSchema) {
    await promiseMessage(createDispatchStockFn({
      body: {
        arrivalForecast,
        boardDate,
        branchId,
        collectorId,
        destinationCollectorId,
        proposalId,
        sender,
        shippingId,
        vehicle,
        withdrawalResponsible,
        invoiceTotalPriceInCents: Number(invoiceTotalValue)
      }
    }), 'Despacho de estoque criado! ')
  }

  return (

    <Box
      p="6"
      bg="white"
      rounded="md"
      as="form"
      onSubmit={handleSubmit(handleCreateDispatchStock)}
    >
      <Flex align="center" gap="2">
        <IconButton
          icon={<FaArrowLeft />}
          aria-label="Voltar"
          size="sm"
          variant="ghost"
          onClick={() => history.goBack()}
        />
        <Heading letterSpacing="tight">Criar Despacho</Heading>
      </Flex>

      <FormProvider {...formMethods}>
        <DispatchStockForm />
      </FormProvider>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Criar
        </Button>
      </Flex>
    </Box>

  )
}

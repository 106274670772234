import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { useThirdPartyRncFunctions } from "hooks/rnc/useThirdPartyRncFunctions";
import { CreateThirdPartyRncForm, CreateThirdPartyRncInputs } from "./components/CreateThirdPartyRncForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToastify } from "hooks/toastify/useToastify";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const createThirdPartyRncSchema = yup.object().shape({
  type: yup.string().required('Campo obrigatório.'),
  collector_id: yup.string().when('type', {
    is: 'COLETADOR',
    then: yup.string().required('Campo obrigatório.')
  }),
  provider_id: yup.string().when('type', {
    is: 'FORNECEDOR',
    then: yup.string().required('Campo obrigatório.')
  }),
  occurred_date: yup.string().required('Campo obrigatório.'),
  loss_value: yup.string().required('Campo obrigatório.'),
  description: yup.string().required('Campo obrigatório.'),
  attachment: yup.mixed(),
})

export function CreateThirdPartyRnc() {
  const { push: redirect } = useHistory()
  const { userLogged } = useAuth()

  const userHasCreateThirdPartyRncPermission =
    userLogged?.permissions.includes('create-third-party-rnc')

  useEffect(() => {
    if (!userHasCreateThirdPartyRncPermission) redirect('/')
  }, [userHasCreateThirdPartyRncPermission, redirect])

  const formMethods = useForm<CreateThirdPartyRncInputs>({
    resolver: yupResolver(createThirdPartyRncSchema)
  })

  const { promiseMessage } = useToastify()

  const { handleSubmit } = formMethods
  const {
    createThirdPartyRnc: { mutateAsync: createThirdPartyRnc }
  } = useThirdPartyRncFunctions()

  async function handleCreateThirdPartyRnc(values: CreateThirdPartyRncInputs) {
    const formData = new FormData()

    Object.entries(values).forEach(([key, value]: [keyof CreateThirdPartyRncInputs, unknown]) => {
      if (key === 'attachment' && !!value) {
        formData.append(key, value[0])
      } else {
        formData.append(key, value as string)
      }

    })

    await promiseMessage(createThirdPartyRnc(formData, {
      onSuccess: () => redirect('/rnc/terceiros/aguardando-resposta')
    }), 'Rnc criado com sucesso!')
  }

  return (
    <StandardBackgroundForm
      title='Abrir rnc para terceiros'
      onSubmit={handleSubmit(handleCreateThirdPartyRnc)}
    >

      <FormProvider {...formMethods}>
        <CreateThirdPartyRncForm />
      </FormProvider>

    </StandardBackgroundForm>
  )
}

import axios from "axios";
import { useAuth } from "hooks/auth/useAuth";
import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";
import { HttpErrorStatusCodeEnum } from "utils/errors/HttpStatusCodeEnum";
import { RequestError } from "utils/errors/RequestErrors";

interface UpdateMaterialRequestProps {
  material_id: string
  material_name: string
}

async function createMaterialReqFunction(material_name: string) {
  try {
    await api.post('/material', { material_name })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function updateMaterialReqFunction({
  material_id,
  material_name
}: UpdateMaterialRequestProps) {
  try {
    await api.put(`/material/${material_id}`, { material_name })

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

async function deleteMaterialReqFunction(material_id: string) {
  try {
    await api.delete(`/material/${material_id}`)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

export function useMaterialFunctions() {

  const queryClient = useQueryClient()
  const { handleLogout, } = useAuth()

  const onRequestSuccess = async () => {
    await queryClient.invalidateQueries("materials")
  }

  const onRequestError = async (error: RequestError) => {
    if (error.httpCode === HttpErrorStatusCodeEnum['HttpUnauthorized']) {
      await handleLogout()
    }
  }

  const createMaterial = useMutation('createMaterial', createMaterialReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const updateMaterial = useMutation('updateMaterial', updateMaterialReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  const deleteMaterial = useMutation('deleteMaterial', deleteMaterialReqFunction, {
    onSuccess: onRequestSuccess,
    onError: onRequestError
  })

  return { createMaterial, updateMaterial, deleteMaterial }
}

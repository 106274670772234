import { Box, Button, Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react"
import { Controller, useForm, useWatch } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverScalabriniDetails } from "api/cltDrivers/sendCltDriverScalabriniDetails";
import { sendCltDriverAsoAndNoticeDetails } from "api/cltDrivers/sendCltDriverAsoAndNoticeDetails";

interface SendCltDriverAsoAndNoticeDetailsProps {
  cltDriverId: string
}

export interface SendCltDriverAsoAndNoticeDetailsSchema {
  hasSignedDemissionNotice: string
  isTicketLogBlocked: string
}

const sendCltDriverAsoAndNoticeDetailsSchema = yup.object({
  hasSignedDemissionNotice: yup.string().required(),
  isTicketLogBlocked: yup.string().required(),
})

export function SendCltDriverAsoAndNoticeDetails({ cltDriverId }: SendCltDriverAsoAndNoticeDetailsProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverAsoAndNoticeDetailsSchema>({
    resolver: yupResolver(sendCltDriverAsoAndNoticeDetailsSchema)
  })

  console.log(errors)

  const { mutateAsync: sendCltDriverAsoAndNoticeDetailsFn } = useMutation({
    mutationFn: sendCltDriverAsoAndNoticeDetails,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverAsoAndNoticeDetails(values: SendCltDriverAsoAndNoticeDetailsSchema) {
    await promiseMessage(sendCltDriverAsoAndNoticeDetailsFn({
      body: {
        hasSignedDemissionNotice: values.hasSignedDemissionNotice === 'yes',
        isTicketLogBlocked: values.isTicketLogBlocked === 'yes',
      }, cltDriverId
    }), 'Informações de Aso e Aviso prévio enviadas!')
  }

  const doesNotSignedDemissionNotice = useWatch({
    control,
    name: 'hasSignedDemissionNotice'
  }) === 'no'

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverAsoAndNoticeDetails)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Contratação - Aso & Aviso Prévio</Heading>
      <FormControl isInvalid={!!errors.hasSignedDemissionNotice} mt={3}>
        <FormLabel>
          Aviso de demissão assinado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="hasSignedDemissionNotice"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
        {doesNotSignedDemissionNotice && (
          <Text as="sub" color="yellow.500">Caso o colaborador não assine, deve ser assinado pelo supervisor e coordenador local.</Text>
        )}
      </FormControl>

      <FormControl isInvalid={!!errors.isTicketLogBlocked} mt={3}>
        <FormLabel>
          Ticket log bloqueado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isTicketLogBlocked"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="n/a">N/A</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link, useHistory } from 'react-router-dom'

import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useBusinessBudget } from '../../hooks/businessBudget/useBusinessBudget'
import { BusinessBudgetTable } from '../../components/Tables/BusinessBudget/BusinessBudgetTable'
import { useAuth } from '../../hooks/auth/useAuth'
import { useEffect } from 'react'
import { useCity } from 'hooks/city/useCity'

export function BusinessBudgetList() {
  const {
    businessBudgets: {
      data: businessBudgets,
      isLoading: isBusinessBudgetsLoading,
    },
  } = useBusinessBudget()
  const {
    cities: { data: cities, isLoading: isCitiesLoading },
  } = useCity(null, true, false)

  const { push: redirectTo } = useHistory()
  const { userLogged } = useAuth()

  useEffect(() => {
    if (!userLogged?.permissions.includes('view-business-budget')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  if (isBusinessBudgetsLoading && isCitiesLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontFamily="poppins">
              Orçamentos Business
            </Heading>
            <Link to="/orcamento-business/adicionar">
              {userLogged?.permissions.includes('add-business-budget') && (
                <Button
                  colorScheme="gray"
                  rightIcon={<Icon as={FiPlus} />}
                  size="sm"
                >
                  Adicionar Orçamento Business
                </Button>
              )}
            </Link>
          </Flex>
          {!!businessBudgets && (
            <BusinessBudgetTable businessBudgets={businessBudgets} cities={cities} />
          )}
        </Box>
      </Flex>

  )
}

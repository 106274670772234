import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"


export interface GetLlmUsersDashboardMetricsResponse {
  delayedCollectServicesCount: number
  delayedDeliveryServicesCount: number
  nextToDelayCollectServicesCount: number
  nextToDelayDeliveryServicesCount: number
  collectServicesWithDelayOccurrenceCount: number
  delayedOrNextToDelayDeliveryServicesCount: number
  deliveryServicesWithDelayOccurrenceCount: number
  delayedBoardServicesCount: number
  unsuccessCollectsPendingValidationCount: number
  collectsWithNewAddressesPendingValidationCount: number
  unfinishedServicesWithDelayOccurrenceCount: number
  toBoardValidateServicesCount: number
  toAllocateServicesCount: number
  servicesRequestedToCancelCount: number
  servicesWithCancelAddressRequestAddressCount: number
  minStocksCount: number
  internComunicationsCount: number
  minProductsStocksCount: number
  delayedBoardValidationsCount: number
}

export async function getLlmUsersDashboardMetrics() {
  try {
    const response = await api.get<GetLlmUsersDashboardMetricsResponse>(`/metrics/dashboard/llm`)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

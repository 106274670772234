import { Button, Checkbox, CheckboxGroup, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { editInternCltOffice } from "api/internClts/editInternCltOffice";
import { getInternCltOffice } from "api/internClts/getInternCltOffice";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import * as yup from "yup"

interface EditInternCltOfficeSchema {
  name: string
  trainings: string[]
}

interface EditInternCltOfficeProps {
  onClose: () => void
  officeId: string
}

const editInternCltOfficeSchema = yup.object({
  name: yup.string().required(),
  trainings: yup.array().min(1).required()
})

const trainings = [
  { name: 'LLM - COMPRAS' },
  { name: 'LLM - RH' },
  { name: 'LLM - OPERAÇÃO MATRIZ' },
  { name: 'LLM - COMERCIAL' },
  { name: 'LLM - QUALIDADE' },
  { name: 'LLM - TI' },
  { name: 'LLM - FINANCEIRO' },
  { name: 'LLM - CULTURA ORGANIZACIONAL' },
  { name: 'LLM - OPERAÇÃO REGIONAIS' },
]


export function EditInternCltOffice({
  onClose,
  officeId
}: EditInternCltOfficeProps) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<EditInternCltOfficeSchema>({
    resolver: yupResolver(editInternCltOfficeSchema)
  })

  const {
    data: internCltOfficeData
  } = useQuery({
    queryKey: ['intern-clt-offices', officeId],
    queryFn: () => getInternCltOffice({ routeParams: { officeId } })
  })

  useEffect(() => {
    if (internCltOfficeData) {
      setValue('name', internCltOfficeData?.office.name)
      if (internCltOfficeData?.office.trainings) {
        setValue('trainings', internCltOfficeData?.office.trainings)
      }
    }
  }, [internCltOfficeData, setValue])

  const { mutateAsync: editInternCltOfficeFn } = useMutation({
    mutationFn: editInternCltOffice,
    onSuccess: onClose
  })

  async function handleEditInternCltOffice(values: EditInternCltOfficeSchema) {
    await editInternCltOfficeFn({
      body: {
        name: values.name,
        trainings: values.trainings
      },
      routeParams: {
        officeId
      }
    })
  }

  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">
        Editar cargo CLT interno
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody
        as="form"
        display="flex"
        flexDirection="column"
        onSubmit={handleSubmit(handleEditInternCltOffice)}
      >

        <FormControl isInvalid={!!errors?.name}>
          <FormLabel>
            Nome
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>

          <Input
            {...register('name')}
            name="name"
          />
        </FormControl>
        <FormControl isInvalid={!!errors?.trainings}>
          <FormLabel>
            Treinamentos
          </FormLabel>
          <Controller
            control={control}
            name="trainings"
            render={({ field }) => {
              return (
                <CheckboxGroup
                  value={field.value}
                  onChange={field.onChange}
                >
                  <VStack
                    spacing={3}
                    p={2}
                    border="1px solid"
                    borderColor="gray.200"
                    rounded="md"
                    align="left"
                  >
                    {trainings.map(training => {
                      return (
                        <Checkbox key={training.name} value={training.name}>
                          <Text fontSize="sm">
                            {training.name}
                          </Text>
                        </Checkbox>
                      )
                    })}
                  </VStack>

                </CheckboxGroup>
              )
            }}
          />
        </FormControl>

        <Button
          type="submit"
          colorScheme="blue"
          mt={3}
          isLoading={isSubmitting}
          alignSelf="end"
        >
          Editar
        </Button>

      </ModalBody>
    </ModalContent>
  )

}

import { Flex, Box, Heading } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { useService } from '../../../hooks/services/service'
import { RequestedServiceTable } from '../../../components/Tables/Service/Requested/RequestedServiceTable'


import { useAddresses } from '../../../hooks/address/useAddresses'
import { useBudget } from 'hooks/budget/useBudget'

export function RequestedServiceList() {
  const [isLoading, setIsLoading] = useState(true)

  const {
    servicesBySteps: { data: services, isLoading: isServiceLoading, refetch },
  } = useService(null, true, false, false, ["toValidateRequestedService"])
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    budgets: {
      data: budgets, isFetching: isFetchingBudgets
    }
  } = useBudget(null, true)

  useEffect(() => {
    function run() {
      if (!isServiceLoading && !isAddressesLoading && !isFetchingBudgets) {
        setIsLoading(false)
      }
    }
    run()
  }, [isServiceLoading, isAddressesLoading, isFetchingBudgets])

  if (isLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins">
              Serviços Solicitados
            </Heading>
          </Flex>
          <RequestedServiceTable
            budgets={budgets}
            services={services}
            addresses={addresses}
            refetch={refetch}
          />
        </Box>
      </Flex>

  )
}

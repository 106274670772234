export const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png"
]

export function validateHasFile(file: FileList) {
  console.log(file)
  if (!file?.length) return false

  return true
}

export function validateFileType(file: FileList) {
  if (file?.length) {
    return supportedFileFormats.includes(file[0].type)
  }

  return true
}

export function validateFileSize(file: FileList) {
  if (file?.length) {
    return file[0].size / 1024 / 1024 <= 15
  }

  return true
}

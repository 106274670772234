
import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface BulkEditShippingCostsProps {
  body: FormData
}

export async function bulkEditShippingCosts({
  body
}: BulkEditShippingCostsProps) {
  try {
    await api.put('/shippings/costs/csv/edit', body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


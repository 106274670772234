import { Button, Td, Tr } from "@chakra-ui/react";

import { ServiceWithStepChanges } from "api/service/requestStepChange/getServicesWithStepChanges";
import { validateStepChangeRequest } from "api/service/requestStepChange/validateStepChangeRequest";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import { useMutation, useQueryClient } from "react-query";

interface ValidateServiceWithStepChangeTableRowProps {
  service: ServiceWithStepChanges
}

export function ValidateServiceWithStepChangeTableRow({
  service
}: ValidateServiceWithStepChangeTableRowProps) {
  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()
  const { userLogged } = useAuth()

  const userCanValidateServiceWithStepChange = userLogged?.permissions.includes('validate-service-with-step-change')

  const { mutateAsync: validateStepChangeRequestFn } = useMutation({
    mutationFn: validateStepChangeRequest,
    onSuccess() {
      queryClient.invalidateQueries(['services-validations-request-change-step'])

    }
  })

  async function handleValidateServiceWithStepChange(action: "validar" | "invalidar") {

    await promiseMessage(validateStepChangeRequestFn({
      body: {
        hasValidate: action === 'validar'
      },
      routeParams: {
        serviceId: service?.id
      }
    }), 'Serviço validado com sucesso!')
  }


  return (
    <Tr>
      <Td>{service.protocol}</Td>
      <Td>
        {userCanValidateServiceWithStepChange && (
          <>
            <Button
              leftIcon={<FaArrowRight />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={() => handleValidateServiceWithStepChange('validar')}
            >
              Validar
            </Button>
            <Button
              leftIcon={<FaTimes />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={() => handleValidateServiceWithStepChange('invalidar')}
            >
              Invalidar
            </Button>
          </>
        )}
      </Td>
    </Tr>
  )
}

import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { PurchaseOrder } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { Link } from "react-router-dom";

interface ViewPurchaseOrderModalProps {
  isOpen: boolean
  onClose: () => void
  purchaseOrder: PurchaseOrder
}

export function ViewPurchaseOrderModal({
  isOpen,
  onClose,
  purchaseOrder,
}: ViewPurchaseOrderModalProps) {

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        maxH="600px"
        overflow="scroll"
      >
        <ModalHeader>
          <Text>Pedido de compra</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody
          as={Flex}
          direction="column"
          gap={4}
        >
          <Stack
            direction={["column", "column", "row"]}
            spacing="4"
          >
            <Input
              name="type"
              defaultValue={purchaseOrder?.type}
              size="sm"
              label="Tipo de Pedido"
              isDisabled
            />
            {purchaseOrder.arrival_limit_date && (
              <Input
                name="arrival_limit_date"
                defaultValue={purchaseOrder?.arrival_limit_date}
                type="date"
                size="sm"
                label="Data limite "
                isDisabled
              />
            )}
          </Stack>
          <Stack
            direction={["column", "column", "row"]}
            spacing="4"
          >
            <TextArea
              name="purchase_reason"
              defaultValue={purchaseOrder.purchase_reason ? purchaseOrder.purchase_reason : '-'}
              size="sm"
              label="Motivo da Compra"
              isDisabled
            />
          </Stack>
          <Input
            name="purchase_description"
            defaultValue={purchaseOrder?.purchase_description}
            size="sm"
            label="Descrição da Compra"
            isDisabled
          />

          {purchaseOrder?.pmc?.pmcProducts.length > 0 && (
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Fornecedor</Th>
                    <Th>Produto</Th>
                    <Th>Quantidade aprovada</Th>
                    <Th>Valor total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {purchaseOrder?.pmc?.pmcProducts?.filter(pmcProduct => pmcProduct.product.general_provider_id === purchaseOrder.provider_id).map((pmcProduct) => {
                    return (
                      <Tr key={pmcProduct.product_id}>
                        <Td>{pmcProduct.product.generalProvider.trading_name}</Td>
                        <Td>{pmcProduct.product.material.material_name}</Td>
                        <Td>{pmcProduct.approved_quantity}</Td>
                        <Td>{(pmcProduct.product.unit_cost / 100).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })}</Td>
                      </Tr>
                    )
                  })}

                </Tbody>
              </Table>
            </TableContainer>
          )}



          <Button
            as={Link}
            to={`/pedido-compra/${purchaseOrder.id}/visualizar`}
            w='min'
            alignSelf='flex-end'
            variant='solid'
          >
            Visualizar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import {
  Flex,
  Box,
  Heading,
  Button,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { ServiceTable } from '../../components/Tables/Service/ServiceTable'
import { useEffect } from 'react'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useService } from '../../hooks/services/service'
import { useBranch } from '../../hooks/branch/useBranch'
import { useAuth } from '../../hooks/auth/useAuth'
import { useContentLoading } from '../../hooks/loading/useContentLoading'
import { useBudget } from '../../hooks/budget/useBudget'
import { useDriver } from '../../hooks/driver/useDriver'
import { useAddresses } from '../../hooks/address/useAddresses'

export function ServiceList() {
  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()
  const { userLogged } = useAuth()

  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const {
    services: { data: services, isLoading: isServiceLoading },
  } = useService(null, true, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true, false)
  const {
    budgets: { data: budgets, isLoading: isBudgetLoading },
  } = useBudget(null, true, false)
  const {
    drivers: { data: drivers, isLoading: isDriversLoading },
  } = useDriver(null, true, false)

  const permissions = userLogged?.permissions

  useEffect(() => {
    if (
      !isServiceLoading &&
      !isAddressesLoading &&
      !isBranchLoading &&
      !isBudgetLoading &&
      !isDriversLoading
    ) {
      handleSetIsContentLoadingFalse()
    }
  }, [
    isServiceLoading,
    isAddressesLoading,
    isBranchLoading,
    handleSetIsContentLoadingFalse,
    isBudgetLoading,
    isDriversLoading
  ])

  if (isContentLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="full" direction={['column', 'column', 'row']}>
        <Box
          overflowX="auto"
          flex="1"
          borderRadius="8"
          bg="white"
          p={['6', '8']}
        >
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg">Serviços em Andamento</Heading>
            {permissions !== undefined && (
              <Flex
                justify={['center', 'flex-end']}
                align="center"
                direction={['column', 'column', 'row']}
                mt={['4', '4', '0']}
              >
                {userLogged?.permissions.includes('add-single-service') && (
                  <Link
                    style={{ width: '100%' }}
                    to="/servicos/solicitado/unico"
                  >
                    <Button
                      w="full"
                      colorScheme="gray"
                      rightIcon={<Icon as={FiPlus} />}
                      size="sm"
                    >
                      {isWideVersion ? 'Adicionar Serviço Único' : 'Único'}
                    </Button>
                  </Link>
                )}
                {userLogged?.permissions.includes('add-recurrent-service') && (
                  <Link
                    style={{ width: '100%' }}
                    to="/servicos/solicitado/recorrente"
                  >
                    <Button
                      w="full"
                      colorScheme="gray"
                      rightIcon={<Icon as={FiPlus} />}
                      size="sm"
                      ml={[' ', ' ', '4']}
                      mt={['4', '4', '0']}
                    >
                      {isWideVersion
                        ? 'Adicionar Serviço Recorrente'
                        : 'Recorrente'}
                    </Button>
                  </Link>
                )}
              </Flex>
            )}
          </Flex>
          {branches && budgets && drivers && (
            <ServiceTable
              services={services}
              addresses={addresses}
              branches={branches}
              budgets={budgets}
              drivers={drivers}
            />
          )}
        </Box>
      </Flex>

  )
}

import { AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, Spinner, Stack, Flex } from "@chakra-ui/react"
import { Address } from "hooks/address/dtos/Address"
import { useRequestedService } from "../../../hooks/services/requestedService"
import { formatDate } from "../../../utils/DateFunctions/formatDate"
import { Input } from "../../Inputs/Input"
import { Select } from "../../Inputs/SelectInput"
import { TextArea } from "../../Inputs/TextInput"

interface IRequestedServiceInfoAccordion {
  requestedServiceId: string
  addresses: Address[]
}

export function RequestedServiceInfoAccordion({ requestedServiceId, addresses }: IRequestedServiceInfoAccordion) {
  const {
    requestedService: { data: requestedService, isLoading: isRequestedServiceLoading }
  } = useRequestedService(requestedServiceId)

  const isCollectServiceStep = ["toCollectService", "collectingService"].some(value => value === requestedService?.serviceIDRequested.step)

  const requestedServiceSourceAddresses = requestedService?.source_address_id
  const sourceAddresses = addresses
    .filter(address => requestedServiceSourceAddresses?.includes(address.id))
  const sourceCities = Array.from(new Set(sourceAddresses
    .map(address => address.cityIDAddress.name)))

  const requestedServiceDestinationAddresses = requestedService?.destination_address_id
  const destinationAddresses = addresses
    .filter(address => requestedServiceDestinationAddresses?.includes(address.id))
  const destinationCities = Array.from(new Set(destinationAddresses
    .map(address => address.cityIDAddress.name)))

  const isFractionedService = requestedService?.service_type === "FRACIONADO"

  return (
    <AccordionItem>
      <h2>
        <AccordionButton p="4">
          <Box flex="1" textAlign="left">
            Informações do serviço
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>
        {isRequestedServiceLoading ? (
          <Spinner />
        ) : (
          <Flex direction="column" gap="4">
            <Input
              name="protocol"
              label="Protocolo"
              value={requestedService?.serviceIDRequested.protocol}
              isDisabled
            />
            {isCollectServiceStep && (
              <Stack direction={['column', 'column', 'row']} spacing="24px">
                <Input
                  name="collect_date"
                  label="Data da coleta"
                  value={formatDate.handle(requestedService!.collect_date, "DateWithoutHourToShow")}
                  isDisabled
                />
                <Stack w="full" direction={['column', 'column', 'row']} spacing="24px">
                  <Input
                    name="collect_hour_start"
                    label="Horário inicial da coleta"
                    value={formatDate.handle(requestedService!.collect_hour_start, "DateOnlyWithHourMinute")}
                    isDisabled
                  />
                  <Input
                    name="collect_hour_start"
                    label="Horário final da coleta"
                    value={formatDate.handle(requestedService!.collect_hour_end, "DateOnlyWithHourMinute")}
                    isDisabled
                  />
                </Stack>
              </Stack>
            )}
            <Stack spacing="24px">
              <Input
                name="source_cities"
                label="Cidade(s) de origem"
                value={sourceCities.join(", ")}
                isDisabled
              />
              <Input
                name="source_cities"
                label="Cidade(s) de destino"
                value={destinationCities.join(", ")}
                isDisabled
              />
            </Stack>
            {isFractionedService && (
              <Stack spacing="24px">
                <Input
                  name="source_branch"
                  label="Base de origem"
                  value={requestedService?.sourceBranchIDService.nickname}
                  isDisabled
                />
                <Input
                  name="destination_branch"
                  label="Base de destino"
                  value={requestedService?.destinationBranchIDService.nickname}
                  isDisabled
                />
              </Stack>
            )}
            <AccordionItem>
              <AccordionButton p="4" border="none">
                <Box flex="1" textAlign="left">
                  Endereços para coleta
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Flex direction="column" gap="4">
                  {sourceAddresses.map(address => {
                    return (
                      <Select
                        name={`source_address_${address.id}`}
                        key={address.id}
                        label="Endereço"
                        value={address.id}
                        addresses={addresses}
                        isDisabled
                      />
                    )
                  })}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton p="4" border="none">
                <Box flex="1" textAlign="left">
                  Endereços para entrega
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Flex direction="column" gap="4">
                  {destinationAddresses.map(address => {
                    return (
                      <Select
                        name={`destination_address_${address.id}`}
                        key={address.id}
                        label="Endereço"
                        value={address.id}
                        addresses={addresses}
                        isDisabled
                      />
                    )
                  })}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
            <Stack direction={['column', 'column', 'row']} spacing="24px">
              <Input
                name="service_type"
                value={requestedService?.service_type}
                label="Tipo de Serviço"
                isDisabled
              />
              <Input
                name="vehicle"
                value={requestedService?.vehicle}
                label="Veículo"
                isDisabled
              />
            </Stack>
            <Stack
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                name="caixa_termica"
                value={requestedService?.caixa_termica}
                label="Caixa Térmica"
                isDisabled
              />
              <Input
                name="embalagem_secundaria"
                value={requestedService?.embalagem_secundaria}
                label="Embalagem Secundária"
                isDisabled
              />
              <Input
                name="gelo_seco"
                value={requestedService?.gelo_seco}
                label="Gelo Seco"
                isDisabled
              />
              <Input
                name="gelox"
                value={requestedService?.gelox}
                label="Gelox"
                isDisabled
              />
            </Stack>
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Input
                name="isopor3l"
                value={requestedService?.isopor3l}
                label="Isopor 3l"
                isDisabled
              />
              <Input
                name="isopor7l"
                value={requestedService?.isopor7l}
                label="Isopor 7l"
                isDisabled
              />
              <Input
                name="terciaria3l"
                value={requestedService?.terciaria3l}
                label="Terciária 3l"
                isDisabled
              />
              <Input
                name="terciaria8l"
                value={requestedService?.terciaria8l}
                label="Terciária 8l"
                isDisabled
              />
            </Stack>

            <TextArea
              name="observation"
              label="Observações do serviço"
              value={requestedService?.observation}
              isDisabled
            />
          </Flex>
        )}
      </AccordionPanel>
    </AccordionItem>
  )
}

import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit3, FiEye, FiTrash2 } from 'react-icons/fi'
import { useState, useEffect } from 'react'
import { Pagination } from '../../../Pagination/Pagination'
import { usePagination } from '@ajna/pagination'
import { SearchBox } from '../../../SearchBox/SearchBox'
import { searchBoxFilter } from '../../../../utils/searchBoxFilter'
import { TableFilterButton } from '../../../Filters/TableFilterButton'
import { useFilterOptions } from '../../../../hooks/filterOptions/useFilterOptions'
import { useAuth } from '../../../../hooks/auth/useAuth'
import { RequestedBusinessServiceProps } from '../../../../utils/RequestFunctions/Service/RequestedBusiness/requestRequestedBusinessServiceFunctions'
import { formatDate } from '../../../../utils/DateFunctions/formatDate'
import { useRequestedBusinessServiceFunctions } from '../../../../hooks/services/requestedBusiness/useRequestedBusinessServiceFunctions'
import { useSwal } from '../../../../hooks/swal/useSwal'
import { days_of_week } from '../../../../utils/customLists'
import { Address } from 'hooks/address/dtos/Address'
import { RecurrenceEnum } from 'utils/enums/RecurrenceEnum'

interface IRequestedBusinessServiceProps {
  requestedBusinessServices: RequestedBusinessServiceProps[]
  addresses: Address[]
}

const requestedBusinessServiceOptions = [
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'routeStartDate', value: 'Data Início da Rota', checked: false },
  { key: 'routeStartHour', value: 'Horário Início da Rota', checked: false },
  { key: 'routeStartEnd', value: 'Horário Final da Rota', checked: false },
  { key: 'sourceCities', value: 'Cidade(s) Origem', checked: false },
  { key: 'destinationCities', value: 'Cidade(s) Destino', checked: false },
  { key: 'geloSeco', value: 'Gelo Seco', checked: false },
  { key: 'daysOfWeek', value: 'Dias da Semana', checked: false },
  { key: 'recurrence', value: 'Recorrência', checked: false },
  { key: 'driver', value: 'Motorista', checked: false },
  { key: 'routeNickname', value: 'Apelido', checked: false },
]

const requestedBusinessServiceOrderOptions = [
  { key: 'customer', value: 'Cliente' },
]

export function RecurrentRequestedBusinessServiceTable({
  requestedBusinessServices,
  addresses,
}: IRequestedBusinessServiceProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const [
    searchedRequestedBusinessServices,
    setSearchedRequestedBusinessServices,
  ] = useState<RequestedBusinessServiceProps[]>([])
  const [slicedRequestedBusinessServices, setSlicedRequestedBusinessService] =
    useState<RequestedBusinessServiceProps[]>([])

  const [customerFiltered, setCustomerFiltered] = useState('')
  const [routeStartDateFiltered, setRouteStartDateFiltered] = useState('')
  const [routeStartHourFiltered, setRouteStartHourFiltered] = useState('')
  const [routeEndHourFiltered, setRouteEndHourFiltered] = useState('')
  const [sourceCitiesFiltered, setSourceCitiesFiltered] = useState('')
  const [destinationCitiesFiltered, setDestinationCitiesFiltered] = useState('')
  const [daysOfWeekFiltered, setDaysOfWeekFiltered] = useState('')
  const [recurrenceFiltered, setRecurrenceFiltered] = useState('')
  const [driverFiltered, setDriverFiltered] = useState('')
  const [routeNicknameFiltered, setRouteNicknameFiltered] = useState('')

  const {
    deleteRequestedBusinessService: {
      mutateAsync: deleteRequestedBusinessService,
    },
  } = useRequestedBusinessServiceFunctions()
  const {
    filterOptions,
    orderOptionSelected,
    onLoadSetFilterOptions,
    onLoadSetOrderOptions,
  } = useFilterOptions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: searchedRequestedBusinessServices.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetOrderOptions(requestedBusinessServiceOrderOptions)
    }
    setFilterOptions()
  }, [onLoadSetOrderOptions])

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(requestedBusinessServiceOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  useEffect(() => {
    function run() {
      if (userLogged !== null) {
        if (userLogged.user_type === 'CLIENTE') {
          const requestedBusinessServicesPerPage = requestedBusinessServices
            .filter((requestedBusinessService) => requestedBusinessService?.customer_id === userLogged.customer_id)

            setSlicedRequestedBusinessService(requestedBusinessServicesPerPage)
        } else {
          setSlicedRequestedBusinessService(requestedBusinessServices)
        }
      }
    }
    run()
  }, [itemLimit, currentPage, offset, requestedBusinessServices, userLogged])

  useEffect(() => {
    const newSlicedRequestedBusinessService =
      slicedRequestedBusinessServices?.filter((requestedService) => {
        const customerFilter = searchBoxFilter(
          requestedService.customerIDBusinessService.trading_firstname,
          customerFiltered,
        )
        const routeStartDateFilter = searchBoxFilter(
          formatDate.handle(
            requestedService.route_start_date,
            'DateWithoutHourToShow',
          ),
          routeStartDateFiltered,
        )
        const routeStartHourFilter = searchBoxFilter(
          formatDate.handle(
            requestedService.route_start_hour,
            'DateOnlyWithHourMinute',
          ),
          routeStartHourFiltered,
        )
        const routeEndHourFilter = searchBoxFilter(
          formatDate.handle(
            requestedService.route_end_hour,
            'DateOnlyWithHourMinute',
          ),
          routeEndHourFiltered,
        )
        const sourceCitiesFilter = searchBoxFilter(
          Array.from(
            new Set(
              addresses.filter((address) =>
                requestedService.source_address_id.includes(address.id),
              ),
            ),
          )
            .map((address) => address.cityIDAddress.name)
            .join(', '),
          sourceCitiesFiltered,
        )
        const destinationCitiesFilter = searchBoxFilter(
          Array.from(
            new Set(
              addresses.filter((address) =>
                requestedService.destination_address_id.includes(address.id),
              ),
            ),
          )
            .map((address) => address.cityIDAddress.name)
            .join(', '),
          destinationCitiesFiltered,
        )

        const daysOfWeekFilter = searchBoxFilter(
          days_of_week.reduce((acc, curr) => {
            requestedService.days_of_week!.map(day => {
              if (day === curr.value) {
                return acc.push(curr.name)
              }

              return []
            })
            return acc
          }, [] as string[]).join(","),
          daysOfWeekFiltered
        )

        const recurrenceFilter = requestedService.recurrence
        ? searchBoxFilter(
          RecurrenceEnum[requestedService.recurrence],
          recurrenceFiltered
        )
        : true

        const driverFilter = searchBoxFilter(
          `${requestedService.driverIDBusinessService.firstname} ${requestedService.driverIDBusinessService.lastname}`,
          driverFiltered
        )

        const routeNicknameFilter = searchBoxFilter(
          requestedService.businessBudgetIDBusinessService.route_nickname,
          routeNicknameFiltered
        )

        if (
          [
            customerFiltered,
            routeStartDateFiltered,
            routeStartHourFiltered,
            routeEndHourFilter,
            sourceCitiesFiltered,
            destinationCitiesFiltered,
            daysOfWeekFiltered,
            driverFiltered,
            routeNicknameFiltered,
            recurrenceFilter
          ].every((value) => value === '')
        )
          return requestedService

        return (
          customerFilter &&
          routeStartDateFilter &&
          routeStartHourFilter &&
          routeEndHourFilter &&
          sourceCitiesFilter &&
          daysOfWeekFilter &&
          driverFilter &&
          destinationCitiesFilter &&
          routeNicknameFilter &&
          recurrenceFilter
        )
      })

    if (orderOptionSelected.length > 0) {
      const slicedBusinessBudgetsByOrder =
        newSlicedRequestedBusinessService.sort(
          (a, b) =>
            (orderOptionSelected.includes('customer') &&
              a.customerIDBusinessService.trading_firstname.localeCompare(
                b.customerIDBusinessService.trading_firstname,
              )) ||
            0,
        )

      return setSearchedRequestedBusinessServices(slicedBusinessBudgetsByOrder)
    }

    setSearchedRequestedBusinessServices(newSlicedRequestedBusinessService)
  }, [
    customerFiltered,
    routeStartDateFiltered,
    routeStartHourFiltered,
    routeEndHourFiltered,
    sourceCitiesFiltered,
    destinationCitiesFiltered,
    daysOfWeekFiltered,
    recurrenceFiltered,
    driverFiltered,
    routeNicknameFiltered,
    addresses,
    slicedRequestedBusinessServices,
    orderOptionSelected,
  ])

  const handleChangeSetter = (key: string, value: string) => {
    switch (key) {
      case 'customer':
        return setCustomerFiltered(value)
      case 'routeStartDate':
        return setRouteStartDateFiltered(value)
      case 'routeStartHour':
        return setRouteStartHourFiltered(value)
      case 'routeStartEnd':
        return setRouteEndHourFiltered(value)
      case 'sourceCities':
        return setSourceCitiesFiltered(value)
      case 'destinationCities':
        return setDestinationCitiesFiltered(value)
      case 'daysOfWeek':
        return setDaysOfWeekFiltered(value)
      case 'recurrence':
        return setRecurrenceFiltered(value)
      case 'driver':
        return setDriverFiltered(value)
      case 'routeNickname':
        return setRouteNicknameFiltered(value)
    }
  }

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  async function handleDeleteRequestedBusinessService(id: string) {
    const hasDeleteBusinessBudget = await confirmMessage({
      title: 'Deseja excluir um serviço solicitado business?',
    })

    if (hasDeleteBusinessBudget) {
      await deleteRequestedBusinessService(id)
      return
    }

    standardMessage('Ação cancelada com êxito!')
  }

  return (
    <>
      {slicedRequestedBusinessServices.length <= 0 ? (
        <Alert
          status="info"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Oops!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Não há dados para mostrar aqui!
          </AlertDescription>
        </Alert>
      ) : (
        <>
          <TableFilterButton />
          <Stack mt="4" direction="column">
            {filterOptions
              .filter((option) => option.checked === true)
              .map((option) => (
                <Flex key={option.key}>
                  <SearchBox
                    placeholder={`Buscar ${option.value}...`}
                    handleSearch={(e) =>
                      handleChangeSetter(option.key, e.target.value)
                    }
                  />
                </Flex>
              ))}
          </Stack>
          <Box overflowX="auto" w="100%">
            <Table colorScheme="gray" variant="striped" size="sm">
              <Thead>
                <Tr>
                  {requestedBusinessServiceOptions.map((option) => (
                    <Th key={option.key} fontSize="12" color="blue.900">
                      {option.value.toUpperCase()}
                    </Th>
                  ))}
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {searchedRequestedBusinessServices
                  .slice(offset, offset + Number(itemLimit))
                  .map((requestedService, index) => {

                    const daysOfWeek = requestedService.days_of_week && requestedService.days_of_week.length
                      ? days_of_week.reduce((acc, curr) => {
                        requestedService.days_of_week!.map(day => {
                          if (day === curr.value) {
                            return acc.push(curr.name)
                          }

                          return []
                        })
                        return acc
                      }, [] as string[]).join(", ")
                      : '-'

                      const recurrence = RecurrenceEnum[requestedService.recurrence] ?? '-'

                    const driver = `${requestedService.driverIDBusinessService.firstname} ${requestedService.driverIDBusinessService.lastname}`

                    const routeNicknameFilter = requestedService.businessBudgetIDBusinessService.route_nickname

                    return (
                      <Tr key={requestedService.id}>
                        <Td fontSize="12">
                          {
                            requestedService.customerIDBusinessService
                              .trading_firstname
                          }
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedService.route_start_date,
                            'DateWithoutHourToShow',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedService.route_start_hour,
                            'DateOnlyWithHourMinute',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {formatDate.handle(
                            requestedService.route_end_hour,
                            'DateOnlyWithHourMinute',
                          )}
                        </Td>
                        <Td fontSize="12">
                          {Array.from(
                            new Set(
                              addresses.filter((address) =>
                                requestedService.source_address_id.includes(
                                  address.id,
                                ),
                              )
                                .map((address) => address.cityIDAddress.name)
                            )
                          )
                            .join(', ')}
                        </Td>
                        <Td fontSize="12">
                          {Array.from(
                            new Set(
                              addresses.filter((address) =>
                                requestedService.destination_address_id.includes(
                                  address.id,
                                ),
                              )
                                .map((address) => address.cityIDAddress.name)
                            )
                          )
                            .join(', ')}
                        </Td>
                        <Td fontSize="12">{requestedService.gelo_seco}</Td>
                        <Td fontSize="12">{daysOfWeek}</Td>
                        <Td fontSize="12">{recurrence}</Td>
                        <Td fontSize="12">{driver}</Td>
                        <Td fontSize="12">{routeNicknameFilter}</Td>
                        <Td isNumeric>
                          {userLogged !== null && (
                            <>
                              {userLogged.permissions.includes(
                                'view-requested-business-service',
                              ) && (
                                  <Link
                                    to={`/servico-business/visualizar/${requestedService.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEye}
                                      fontSize="20"
                                    />
                                  </Link>
                                )}
                              {userLogged.permissions.includes(
                                'edit-requested-business-service',
                              ) && (
                                  <Link
                                    to={`/servico-business/editar/${requestedService.id}`}
                                  >
                                    <Icon
                                      cursor="pointer"
                                      as={FiEdit3}
                                      fontSize="20"
                                      ml="4"
                                      mt={['2', '2', '0']}
                                    />
                                  </Link>
                                )}
                              {userLogged.permissions.includes(
                                'remove-requested-business-service',
                              ) && (
                                  <Icon
                                    cursor="pointer"
                                    as={FiTrash2}
                                    fontSize="20"
                                    ml="4"
                                    mt={['2', '2', '0']}
                                    onClick={() =>
                                      handleDeleteRequestedBusinessService(
                                        requestedService.id,
                                      )
                                    }
                                  />
                                )}
                            </>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </Box>

          <Pagination
            handlePageChange={handlePageChange}
            pagesQuantity={pagesCount}
            pages={pages}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  )
}

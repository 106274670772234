import { Stack } from "@chakra-ui/react"
import { CheckboxService } from "components/Inputs/CheckboxService"
import { Address } from "hooks/address/dtos/Address"
import { Controller, useFormContext } from "react-hook-form"

interface AddressesCheckboxGroupProps {
  sourceAddresses?: Address[]
  destinationAddresses?: Address[]
  defaultSelectedSourceAddresses?: string[]
  defaultSelectedDestinationAddresses?: string[]
  isAddressesFetching: boolean
  isEditPage?: boolean
}

export function AddressesCheckboxInputsGroup({
  sourceAddresses,
  destinationAddresses,
  defaultSelectedSourceAddresses,
  defaultSelectedDestinationAddresses,
  isAddressesFetching,
  isEditPage
}: AddressesCheckboxGroupProps) {

  const { control, formState: { errors } } = useFormContext()

  return (
    <Stack spacing={6} direction={["column", "column", "row"]} w="full">
      <Controller
        name="source_address_id"
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <CheckboxService
              onCheckboxChange={onChange}
              isLoading={isAddressesFetching}
              name="source_address_id"
              addresses={sourceAddresses}
              isDisabled={true}
              defaultCheckedOptions={defaultSelectedSourceAddresses}
              label="Endereços de origem"
              error={errors.source_address_id}
              required
            />
          )
        }}
      />
      <Controller
        name="destination_address_id"
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <CheckboxService
              onCheckboxChange={onChange}
              isLoading={isAddressesFetching}
              name="destination_address_id"
              addresses={destinationAddresses}
              isDisabled={!isEditPage}
              defaultCheckedOptions={defaultSelectedDestinationAddresses}
              label="Endereços de destino"
              error={errors.destination_address_id}
              required
            />
          )
        }}
      />
    </Stack>
  )
}

import { Button, Flex, Icon, Input, Select } from "@chakra-ui/react";
import { AdvertenceStatus } from "api/advertences/types/Advertence";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaFileExport, FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface AdvertencesReportFiltersSchema {
  startDate: string
  endDate: string
  status: AdvertenceStatus | 'all'
}

interface AdvertencesReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: {
      user: string
      regional: string
      date: string
      advertence_type: string
      advertence_attachment_id: string
      proof_of_sending_advertence_attachment_id: string
    }[]
    filename: string
  }
}

export function AdvertencesReportFilters({
  csvProps
}: AdvertencesReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const status = searchParams.get('status')
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<AdvertencesReportFiltersSchema>({
    defaultValues: {
      startDate,
      endDate,
      status: status as AdvertenceStatus | 'all' ?? 'all'
    }
  })

  function handleSearchAdvertencesReport(values: AdvertencesReportFiltersSchema) {
    if (values.startDate) {
      searchParams.set('startDate', values.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (values.endDate) {
      searchParams.set('endDate', values.endDate)
    } else {
      searchParams.delete('endDate')
    }

    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    replace({ search: searchParams.toString() })

  }

  function handleRemoveFilters() {
    searchParams.delete('status')
    searchParams.delete('startDate')
    searchParams.delete('endDate')

    reset({ status: 'all' })

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchAdvertencesReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Input
          {...register("startDate")}
          type="date"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Input
          {...register("endDate")}
          type="date"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Select
          {...register("status")}
          defaultValue="all"
          size="sm"
          rounded="md"
          placeholder="Selecione uma opção..."
          w={{ base: "full", md: '250px' }}
        >
          <option value="all">Todos status</option>
          <option value="awaiting-application">Aguardando aplicação</option>
          <option value="parent-verification">Aguardando verificação da matriz</option>
          <option value="cancelled">Cancelado</option>
          <option value="done">Concluído</option>
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
        <Button
        type="submit"
        size="sm"
        variant="outline"
        leftIcon={<Icon as={FaTimes} />}
        w={['full', 'full', 'min']}
        onClick={handleRemoveFilters}
      >
        Remover filtros
      </Button>
      </Flex>
    </Flex>
  )
}

import { Box, Flex } from "@chakra-ui/react";
import { CarrierStatus as CarrierStatusTypes } from "api/carriers/_types/Carrier";
import { carrierStatusMap } from "utils/carrierMappers";

interface CarrierStatusProps {
  status: CarrierStatusTypes;
}

export function CarrierStatus({ status }: CarrierStatusProps) {
  return (
    <Flex
      gap="2"
      align="center"
    >
      {['pending', 'evaluating'].includes(status) && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="orange.300"
        />
      )}

      {status === 'qualified' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="green.300"
        />
      )}

      {status === 'inactive' && (
        <Box
          w="2"
          h="2"
          rounded="full"
          bgColor="red.300"
        />
      )}

      {carrierStatusMap[status]}
    </Flex>
  )
}

import { Box, Button, Divider, Heading, Input, Select, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { useCollectorMaterialRequirements } from "hooks/collector/useCollectorMaterialRequirements";
import { useCollectors } from "hooks/collector/useCollectors";
import { useCollectorsFunctions } from "hooks/collector/useCollectorsFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";

interface ProposalInput {
  inputId: string
  materialName: string
  sendQuantity: number
  standardStock: number
  currentStock: number
  periodConsume: number
  minStock: number
}

interface InputT {
  collectorId: string
  proposalInputs: ProposalInput[]
}

export function CreateCollectorDispatchStockProposal() {
  const [inputs, setInputs] = useState<InputT>({
    collectorId: '',
    proposalInputs: [],
  })

  function handleSetProposalInputs(event: ChangeEvent<HTMLInputElement>, index: number) {
    setInputs((prevInputs) => {
      const prevProposalInput = prevInputs.proposalInputs[index]

      prevProposalInput[event.target.name] = event.target.value

      return {
        ...prevInputs,
        proposalInputs: [...prevInputs.proposalInputs]
      }
    })
  }

  function handleSetCollectorInput(event: ChangeEvent<HTMLSelectElement>) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value
      }
    })
  }

  const { push: redirect } = useHistory()

  const {
    data: collectors,
    isFetching: isFetchingCollectors
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO'
    }
  })

  const collectorsOptions = collectors?.map((collector) => {
    return {
      key: collector.id,
      value: collector.id,
      showOption: collector.trading_name
    }
  })

  const {
    data: collectorMaterialRequirements,
    isFetching: isFetchingCollectorMaterialRequirements
  } = useCollectorMaterialRequirements({
    collectorId: inputs.collectorId,
    queryOptions: {
      enabled: !!inputs.collectorId,
    }
  })

  useEffect(() => {
    if (collectorMaterialRequirements) {
      setInputs((prevInputs) => {
        return {
          ...{ ...prevInputs, proposalInputs: [] }
        }
      })

      collectorMaterialRequirements.materialRequirements.forEach(
        (materialRequirement, index) => {
          setInputs((prevInputs) => {
            return {
              ...prevInputs,
              proposalInputs: [...prevInputs.proposalInputs, {
                inputId: materialRequirement.id,
                materialName: materialRequirement.material_name,
                sendQuantity: materialRequirement.send_quantity,
                minStock: materialRequirement.min_stock,
                standardStock: materialRequirement.standard_stock,
                currentStock: materialRequirement.current_stock,
                periodConsume: materialRequirement.last_period_consumed
              }]
            }
          })
        })
    }
  }, [collectorMaterialRequirements])

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    createCollectorDispatchStockProposal
  } = useCollectorsFunctions()

  async function handleCreateCollectorDispatchStockProposal(
    event: FormEvent
  ) {

    event.preventDefault()

    const proposalInputs = inputs.proposalInputs.reduce((acc, curr) => {
      const materialInfo = collectorMaterialRequirements?.materialRequirements.find(
        (materialRequirement) => materialRequirement.material_name === curr.materialName
      )

      if (materialInfo) {
        acc.push({
          ...curr,
          inputId: materialInfo.id
        })
      }

      return acc
    }, [])

    await promiseMessage(createCollectorDispatchStockProposal.mutateAsync({
      collectorId: inputs.collectorId,
      input: {
        proposalInputs
      }
    }), 'Proposta de envio de materiais criada com sucesso!')

    await queryClient.invalidateQueries('dispatchStockProposals')

    redirect('/estoques/despachos/propostas')
  }

  return (

    <Box
      display="flex"
      flexDirection="column"
      gap="4"
      rounded="md"
      bg="white"
      boxShadow="md"
      p="6"
    >
      <Heading letterSpacing="tight">Criar Proposta de Despacho de Estoque</Heading>
      <Divider />
      {(isFetchingCollectors || isFetchingCollectorMaterialRequirements) ? (
        <Spinner />
      ) : (
        <Box
          as="form"
          display="flex"
          flexDirection="column"
          gap="4"
          onSubmit={handleCreateCollectorDispatchStockProposal}
        >
          <Select
            value={inputs.collectorId}
            onChange={(event) => handleSetCollectorInput(event)}
            name="collectorId"
            placeholder="Selecione um coletador..."
            size="md"
          >
            {collectorsOptions?.map((option) => {
              return (
                <option key={option.key} value={option.value}>{option.showOption}</option>
              )
            })}
          </Select>

          <TableContainer
            rounded="md"
            borderColor="gray.100"
          >
            <Table
              size="sm"
            >
              <Thead
                h="40px"
                bg="#38C3FA"
              >
                <Tr>
                  <Th color="white">Material</Th>
                  <Th color="white">Estoque atual</Th>
                  <Th color="white">Estoque padrão</Th>
                  <Th color="white">Estoque mínimo</Th>
                  <Th color="white">Consumo nos últimos 30 dias</Th>
                  <Th color="white">Quantidade a enviar</Th>
                </Tr>
              </Thead>
              <Tbody>
                {collectorMaterialRequirements?.materialRequirements.map((materialRequirement, index) => {
                  return (
                    <Tr key={materialRequirement.id}>
                      <Td>
                        <Input
                          name="materialName"
                          value={inputs.proposalInputs[index]?.materialName ? captalize(inputs.proposalInputs[index]?.materialName) : ''}
                          onChange={(event) => handleSetProposalInputs(event, index)}
                          size="sm"
                          bgColor="white"
                          border="none"
                          borderRadius="md"
                          isReadOnly
                        />
                      </Td>
                      <Td>{materialRequirement.current_stock}</Td>
                      <Td>{materialRequirement.standard_stock}</Td>
                      <Td>{materialRequirement.min_stock}</Td>
                      <Td>{materialRequirement.last_period_consumed}</Td>
                      <Td>
                        <Input
                          name="sendQuantity"
                          value={inputs.proposalInputs[index]?.sendQuantity ?? 0}
                          onChange={(event) => handleSetProposalInputs(event, index)}
                          size="sm"
                          rounded="md"
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>

          <Button
            colorScheme="blue"
            boxShadow="md"
            type="submit"
            w="min"
            alignSelf="flex-end"
          >
            Solicitar
          </Button>
        </Box>
      )}
    </Box>

  )
}

import { MenuItem, MenuList, MenuListProps } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";

export interface BugActionsMenuListProps extends MenuListProps {
  onDeleteBug: () => Promise<void>
}

export function BugActionsMenuList({
  onDeleteBug,
  ...rest
}: BugActionsMenuListProps) {
  const { userLogged } = useAuth()

  return (
    <MenuList
      fontSize="xs"
      {...rest}
    >
      <MenuItem
        onClick={onDeleteBug}
        isDisabled={!userLogged?.permissions.includes('delete-bug')}
      >
        Excluir
      </MenuItem>
    </MenuList>
  )
}

import { Box, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Table, Tbody, Td, Tr } from "@chakra-ui/react"
import { getInternCltOffice } from "api/internClts/getInternCltOffice"
import { useQuery } from "react-query"

interface InternCltOfficeDetailProps {
  officeId: string
}

export function InternCltOfficeDetail({
  officeId
}: InternCltOfficeDetailProps) {

  const {
    data: internCltOfficeData
  } = useQuery({
    queryKey: ['intern-clt-offices', officeId],
    queryFn: () => getInternCltOffice({ routeParams: { officeId } })
  })

  return (
    <ModalContent>
      <ModalHeader>
        Detalhes do cargo
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Box w="full" maxH="800px" overflowY="scroll" p={2}>
          <Table size="sm">
            <Tbody>
              <Tr>
                <Td>Nome</Td>
                <Td isNumeric>{internCltOfficeData?.office?.name}</Td>
              </Tr>
              {internCltOfficeData?.office?.trainings && (
                <Tr>
                  <Td>Treinamentos</Td>
                  <Td isNumeric>
                    {internCltOfficeData?.office?.trainings.join(', ')}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </ModalBody>
    </ModalContent>
  )
}

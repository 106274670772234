import { Text, TextProps } from "@chakra-ui/react";
import { captalize } from "utils/captalize";

interface NotificationTitleProps extends TextProps {
  title: string
}

export function NotificationTitle({ title, ...rest }: NotificationTitleProps) {
  return (
    <Text
      fontSize="sm"
      color="blackAlpha.900"
      fontWeight="semibold"
      {...rest}
    >
      {captalize(title)}
    </Text>
  )
}

import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { OnboardingStatus } from "api/onboardings/onboarding";
import { addDays, format } from "date-fns";
import { useSearchParams } from "hooks/useSearchParams";
import { CSVLink } from "react-csv";
import { useForm, useWatch } from "react-hook-form";
import { FaFileExport, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface OnboardingsReportFiltersSchema {
  startDate: string
  endDate: string
  status: OnboardingStatus | 'all'
}

interface OnboardingsReportFiltersProps {
  csvProps: {
    headers: {
      label: string
      key: string
    }[]
    data: {
      name: string
      training_type: string,
      cpf: string,
      email: string,
      status: string,
      llm_training_themes: string | string[],
      certificate: string
    }[]
    filename: string
  }
}

export function OnboardingsReportFilters({
  csvProps
}: OnboardingsReportFiltersProps) {
  const searchParams = useSearchParams()

  const { replace } = useHistory()

  const status = searchParams.get('status')
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')

  const {
    control,
    register,
    handleSubmit,
  } = useForm<OnboardingsReportFiltersSchema>({
    defaultValues: {
      startDate,
      endDate,
      status: status as OnboardingStatus | 'all' ?? 'all'
    }
  })

  function handleSearchOnboardingsReport(values: OnboardingsReportFiltersSchema) {
    if (values.startDate) {
      searchParams.set('startDate', values.startDate)
    } else {
      searchParams.delete('startDate')
    }

    if (values.endDate) {
      searchParams.set('endDate', values.endDate)
    } else {
      searchParams.delete('endDate')
    }

    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    replace({ search: searchParams.toString() })

  }

  const startDateSelected = useWatch({ control, name: 'startDate' })

  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      mt="6"
      as="form"
      gap="2"
      w='full'
      onSubmit={handleSubmit(handleSearchOnboardingsReport)}
    >
      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Input
          {...register("startDate")}
          type="date"
          size="sm"
          rounded="md"
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Input
          {...register("endDate")}
          type="date"
          size="sm"
          rounded="md"
          max={format(addDays(new Date(startDateSelected), 31), 'yyyy-MM-dd')}
          w={{ base: 'full', md: 'fit-content' }}
        />

        <Select
          {...register("status")}
          defaultValue="all"
          size="sm"
          rounded="md"
          placeholder="Selecione uma opção..."
          w={{ base: "full", md: '250px' }}
        >
          <option value="all">Todos status</option>
          <option value="inviting">Aguardando convite</option>
          <option value="training">Em treinamento</option>
          <option value="testing">Teste da cumbuca</option>
          <option value="inactive">Inativo</option>
          <option value="finished">Finalizados</option>
        </Select>
      </Flex>

      <Flex w={{ base: 'full', md: 'min' }} gap="2" direction={{ base: 'column', md: 'row' }}>
        <Button
          leftIcon={<FaFileExport />}
          size="sm"
          type="button"
          colorScheme="green"
          as={CSVLink}
          separator={';'}
          {...csvProps}
          w={{ base: 'full', md: 'min' }}
        >
          Exportar
        </Button>
        <Button
          leftIcon={<FaSearch />}
          size="sm"
          type="submit"
          w={{ base: 'full', md: 'min' }}
        >
          Gerar relatório
        </Button>
      </Flex>
    </Flex>
  )
}





import { api } from "services/api";
import axios from 'axios';
import { RequestError } from 'utils/errors/RequestErrors';

interface ValidateStandardizationBody {
  cnhStatus: 'approved' | 'reproved'
  crlvStatus: 'approved' | 'reproved'
  motorFreightCertificateStatus: 'approved' | 'reproved'
  cleaningRegisterStatus: 'approved' | 'reproved'
  vaccinesStatus: 'approved' | 'reproved'
  trainingsStatus: 'approved' | 'reproved'
  salaryAccountStatus: 'approved' | 'reproved'
  uniformAndBadgeStatus: 'approved' | 'reproved'
  stickersStatus: 'approved' | 'reproved'
  reflectiveStripStatus: 'approved' | 'reproved'
  redPlateStatus: 'approved' | 'reproved'
  vehicleVisualCleaningStatus: 'approved' | 'reproved'
  vehicleEpiKitStatus: 'approved' | 'reproved'
  tiresConditionStatus: 'approved' | 'reproved'
  approvationObservations: string
  status: 'approved' | 'reproved'
}

interface ValidateStantardizationProps {
  params: {
    standardizationId: string
  }
  body: ValidateStandardizationBody
}

export async function validateStandardization({
  params,
  body
}: ValidateStantardizationProps) {
  try {
    await api.put(`/standardizations/${params.standardizationId}/validate`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}


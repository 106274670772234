import { Flex, HStack, Stack, Link as ChakraLink, Button, FormLabel, Icon } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { Select } from "components/Inputs/SelectInput";
import { TextArea } from "components/Inputs/TextInput";
import { PurchaseOrderType } from "hooks/purchaseOrder/dtos/PurchaseOrder";
import { User } from "hooks/user/dtos/User";
import { useFormContext, useWatch } from "react-hook-form";
import { FiFile } from "react-icons/fi";

interface PurchaseOrderProps {
  slug: string
  users: User[]
}

export interface PurchaseOrderFormInputs {
  type: PurchaseOrderType
  purchaseDescription: string
  quantity: number
  purchaseReason: string
  sugestedProvider: string
  arrivalLimitDate: string
  productResponsibleId: string
  typeUser: string
  attachment: FileList
}

export const purchaseOrderTypeOptions = [
  { key: 'product', value: 'PRODUTO', showOption: 'PRODUTO' },
  { key: 'service', value: 'SERVIÇO', showOption: 'SERVIÇO' },
]

export const typeUserOptions = [
  { key: 'llm', value: 'LLM', showOption: 'LLM' },
  { key: 'driver', value: 'MOTORISTA', showOption: 'MOTORISTA' },
]

export function PurchaseOrderForm({ slug, users }: PurchaseOrderProps) {
  const isViewPage = slug === "visualizar"

  const {
    register,
    control,
    formState: {
      errors
    }
  } = useFormContext<PurchaseOrderFormInputs>()

  const [purchaseOrderTypeSelected, typeUserSelected, attachment] = useWatch({
    control,
    name: ['type', 'typeUser','attachment']
  })
  const purchaseOrderTypeProduct = purchaseOrderTypeSelected === 'PRODUTO'

  const usersLlmFiltered = users?.filter(user => user.loglife_employee === true)
  const usersDriversFiltered = users.filter(user => user.driver_id !== null)

  const typeUsersByPurchaseOrderTypeSelected = purchaseOrderTypeProduct && typeUserSelected === 'LLM' ? usersLlmFiltered : usersDriversFiltered

  return (
    <Flex direction="column" gap={6} align="center">
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <HStack w="100%">
          <Select
            {...register('type')}
            name="type"
            options={purchaseOrderTypeOptions}
            label="Tipo"
            placeholder="Selecione uma opção..."
            error={errors.type}
            isDisabled={isViewPage}
            required
          />
        </HStack>
      </Stack>
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <Input
          {...register('purchaseDescription')}
          name={'purchaseDescription'}
          label="O que precisa ser comprado/realizado"
          error={errors.purchaseDescription}
          required
        />
        {purchaseOrderTypeProduct && (
          <Input
            {...register('quantity')}
            name={'quantity'}
            label="Quantidade"
            error={errors.quantity}
            required
          />
        )}
      </Stack>
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <TextArea
          {...register('purchaseReason')}
          name='purchaseReason'
          label='Motivo da compra'
          error={errors.purchaseReason}
          isDisabled={isViewPage}
          required
        />
      </Stack>
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <Input
          {...register('sugestedProvider')}
          name={'sugestedProvider'}
          label="Fornecedor sugerido"
          error={errors.sugestedProvider}
        />
        <Input
          {...register("arrivalLimitDate")}
          name="arrivalLimitDate"
          label="Data limite para a chegada do produto/contratação do serviço"
          error={errors.arrivalLimitDate}
          type="date"
          isDisabled={isViewPage}
          required
        />
      </Stack>
      {purchaseOrderTypeProduct && (
        <Stack spacing={6}
          direction={["column", "column", "row"]}
          w="full">
          <Select
            {...register('typeUser')}
            name="typeUser"
            options={typeUserOptions}
            label="Tipo do colaborador"
            placeholder="Selecione uma opção..."
            error={errors.typeUser}
            isDisabled={isViewPage}
            required
          />
          <Select
            {...register('productResponsibleId')}
            users={typeUsersByPurchaseOrderTypeSelected}
            placeholder="Selecione uma opção..."
            name="productResponsibleId"
            label="Colaborador responsável pelo produto"
            error={errors.productResponsibleId}
            required
          />
        </Stack>
      )}
      <Stack spacing={6}
        direction={["column", "column", "row"]}
        w="full">
        <Input
          {...register('attachment')}
          name='attachment'
          type='file'
          hidden
          label='Adicionar anexo'
          error={errors.attachment}
        >
          <Flex
            align='center'
          >
            <Button
              leftIcon={<Icon as={FiFile} />}
              as={FormLabel}
              htmlFor='attachment'
              h='48px'
              _hover={{
                cursor: 'pointer'
              }}
              size='sm'
            >
              Escolha um arquivo
            </Button>
            {attachment && attachment.length > 0 && (
              <ChakraLink
                href={URL.createObjectURL(attachment[0])}
                isExternal
              >
                {attachment[0].name} (Visualizar)
              </ChakraLink>
            )}
          </Flex>
        </Input>
      </Stack>
    </Flex>
  )
}

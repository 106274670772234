import { BranchProps } from "contexts/BranchContext";
import { useQuery, UseQueryOptions } from "react-query";
import { api } from "services/api";
import request from "axios"
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";

interface BranchesQueryParams {
  hub_id?: string
  hubs?: string[]
  shipping_id?: string
  modal?: string
}

type QueryOptions = UseQueryOptions<BranchProps[], unknown, BranchProps[], ["branches"]>

interface UseBranchesOptions {
  queryOptions?: QueryOptions
  queryParams?: BranchesQueryParams
}

export const useBranches = (options?: UseBranchesOptions) => {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getBranches(queryParams?: BranchesQueryParams) {
    try {
      const { data } = await api.get(`/branch/`, {
        params: queryParams && {
          ...queryParams,
          hubs: queryParams?.hubs?.join(",")
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new Error("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    "branches",
    options?.queryParams?.hubs,
    options?.queryParams?.shipping_id,
    options?.queryParams?.hub_id
  ], () => getBranches(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

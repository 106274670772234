import {
  Flex,
  Box,
  Heading,
  Divider,
  HStack,
  Button,
  Stack,
  Icon,
  Alert,
  AlertIcon,
  useMediaQuery,
} from '@chakra-ui/react'
import { useEffect, useContext, useState, useMemo } from 'react'

import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { format } from 'date-fns'

import {
  CollectedServiceContext,
  CollectedServiceProps,
} from '../../../contexts/CollectedServiceContext'
import { ServiceProps } from '../../../contexts/ServiceContext'

import { collectFormatDataToBack } from '../../../utils/ServiceFunctions/Collect/collectFormatDataToBack'

import { GeneralContentLoading } from '../../../components/Loading/GeneralContentLoading'
import { TextArea } from '../../../components/Inputs/TextInput'
import { Input } from '../../../components/Inputs/Input'



import { useService } from '../../../hooks/services/service'
import { useDriver } from '../../../hooks/driver/useDriver'

import { FaList, FaSave, FaTimes } from 'react-icons/fa'
import { useSwal } from '../../../hooks/swal/useSwal'
import { useAuth } from '../../../hooks/auth/useAuth'
import { useCollectServiceFunctions } from '../../../hooks/collectService/useCollectServiceFunctions'
import { useAddresses } from '../../../hooks/address/useAddresses'
import { Address } from 'hooks/address/dtos/Address'
import { useProviders } from 'hooks/provider/useProviders'

interface IFormInputProps {
  address_id: string
  service_id: string
  driver_id: string
  provider_id: string
  arrival_latitude: string
  arrival_longitude: string
  arrival_timestamp: string
  responsible_name: string | null
  collect_volume: number | null
  collect_sample: number | null
  box_photo: {} | null
  open_box_photo: {} | null
  content_declaration: {} | null
  receipt_photo: {} | null
  departure_latitude: string | null
  departure_longitude: string | null
  departure_timestamp: string | null
  unsuccess_latitude: string | null
  unsuccess_longitude: string | null
  unsuccess_timestamp: string | null
  box_photo_change: string | null
  open_box_photo_change: string | null
  content_photo_change: string | null
  receipt_photo_change: string | null
  real_arrival_date: string
  real_arrival_hour: string
  observation: string | null
  has_temp_check: 'yes' | 'no'
}

interface QueryParams {
  service_id: string
  address_id: string
  slug: string
}

interface DriverFormProps {
  slug: string
  id?: string
  isDisabled?: boolean
  href: string
  title: string
  action: string
}

interface ProviderProps {
  id: string
  company_name: string
  trading_name: string
  cep: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
}
const createSchema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
})

const schema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
  box_photo_change: yup.string().required('Campo Obrigatório'),
  open_box_photo_change: yup.string().required('Campo Obrigatório'),
  responsible_name: yup.string().required('Campo obrigatório'),
  content_photo_change: yup.string().required('Campo Obrigatório'),
  receipt_photo_change: yup.string().required('Campo Obrigatório'),
  observation: yup.string(),
})

const unsuccessSchema = yup.object().shape({
  driver_id: yup.string().required('Motorista inválido'),
  responsible_name: yup.string().required('Campo obrigatório'),
})

export function CollectBusinessServiceAddressSelected({
  id,
  isDisabled = false,
  href,
  title,
  action,
}: DriverFormProps) {
  const [isWideVersion] = useMediaQuery('(min-width: 1280px)')

  const [providerSourceAddress, setProviderSourceAddress] = useState<
    ProviderProps[]
  >([])
  const [sourceAddresses, setSourceAddresses] = useState<Address[]>([])
  const [addressCollected, setAddressCollected] = useState<string[]>([])
  const [hasUnsuccess, setHasUnsuccess] = useState<boolean>(false)

  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  // ------------------------------------------------------------------------//

  const [isCurrentService, setIsCurrentService] = useState(false)

  const [collectedServiceFilteredById, setCollectedServiceFilteredById] =
    useState<CollectedServiceProps[]>([])
  // const [requestedServiceFilteredById, setRequestedServiceFilteredById] = useState<RequestedBusinessServiceProps[]>([])
  const [serviceFilteredById, setServiceFilteredById] = useState<
    ServiceProps[]
  >([])

  const { confirmMessage, standardMessage } = useSwal()

  const { userLogged } = useAuth()
  const {
    createCollectedService,
    editCurrentCollectedService,
    deleteCollectedService,
  } = useContext(CollectedServiceContext)

  const {
    finishCollectBusinessService: {
      mutateAsync: finishCollectBusinessService, isSuccess: isFinishedCollectBusinessSuccess
    }
  } = useCollectServiceFunctions()


  const { service_id, address_id, slug }: QueryParams = useParams()
  const history = useHistory()

  useEffect(() => {
    if (isFinishedCollectBusinessSuccess) {
      history.push(`/servico-business/coleta/enderecos/${service_id}`)
    }
  }, [isFinishedCollectBusinessSuccess, history, service_id])

  const {
    service: {
      data: serviceById,
      isLoading: isServiceLoading,
      refetch: refetchService,
    },
  } = useService(service_id, false, false)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()
  const { data: providers, isLoading: isProviderLoading } = useProviders()
  const {
    drivers: { data: drivers, isLoading: isDriverLoading },
  } = useDriver(null, true, false)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<IFormInputProps>({
    resolver:
      hasUnsuccess === true
        ? yupResolver(unsuccessSchema)
        : addressCollected.includes(address_id)
          ? yupResolver(schema)
          : yupResolver(createSchema),
    defaultValues: {
      collect_sample: 0,
      collect_volume: 0
    }
  })

  const isDriverSelected = watch('driver_id')

  const colServiceByServiceInProgress = useMemo(() => {
    return collectedServiceFilteredById.filter(
      (colService) =>
        colService.address_id || colService.provider_id === address_id,
    )
  }, [collectedServiceFilteredById, address_id])

  useEffect(() => {
    function run() {
      if (serviceById !== undefined) {
        const serviceFiltered = []
        serviceFiltered.push(serviceById)
        setServiceFilteredById(serviceFiltered)

        if (serviceById.serviceIDRequestedBusiness !== null) {
          const requestedServiceFiltered = []
          requestedServiceFiltered.push(serviceById.serviceIDRequestedBusiness)
          // setRequestedServiceFilteredById(requestedServiceFiltered)
        }
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (serviceById?.serviceIDCollect !== undefined) {
        setCollectedServiceFilteredById(serviceById?.serviceIDCollect)
      }
    }
    run()
  }, [serviceById])

  useEffect(() => {
    function run() {
      if (
        !isProviderLoading &&
        !isAddressesLoading &&
        !isDriverLoading &&
        !isServiceLoading
      ) {
        setIsLoadingPage(false)
      }
    }
    run()
  }, [isProviderLoading, isServiceLoading, isAddressesLoading, isDriverLoading])

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (!permissions?.includes('add-collect-business-service')) {
            history.push('/')
          }
        }
      } catch {
        history.push('/')
      }
    }
    run()
  }, [slug, history, userLogged])

  useEffect(() => {
    if (serviceById) {
      setValue('driver_id', serviceById?.serviceIDRequestedBusiness.driver_id)
    }
  }, [setValue, serviceById])

  useEffect(() => {
    function run() {
      const serviceIdByCollected = collectedServiceFilteredById.filter(
        (colService) => colService.address_id === address_id,
      )
      const serviceIdByCollectedProvider = collectedServiceFilteredById.filter(
        (colService) => colService.provider_id === address_id,
      )

      const addressId = serviceIdByCollected.map(
        (service) => service.address_id,
      )
      if (addressId.length > 0) {
        setValue('receipt_photo', {})
        setAddressCollected(addressId)
      }
      const providerAddressId = serviceIdByCollectedProvider.map(
        (service) => service.provider_id,
      )
      if (providerAddressId.length > 0) {
        setValue('content_declaration', {})
        setAddressCollected(providerAddressId)
      }

      if (
        addressId.includes(address_id) ||
        providerAddressId.includes(address_id)
      ) {
        const filterCollectedServiceByAddress =
          collectedServiceFilteredById.filter(
            (service) => service.address_id === address_id,
          )
        const filterCollectedServiceByProvider =
          collectedServiceFilteredById.filter(
            (service) => service.provider_id === address_id,
          )

        if (filterCollectedServiceByAddress.length > 0) {
          const arrivalLatitude = filterCollectedServiceByAddress
            .map((service) => service.arrival_latitude)
            .toString()
          const arrivalLongitude = filterCollectedServiceByAddress
            .map((service) => service.arrival_longitude)
            .toString()
          const arrivalTimestamp = filterCollectedServiceByAddress
            .map((service) => service.arrival_timestamp)
            .toString()

          setValue('address_id', address_id)
          setValue('arrival_latitude', arrivalLatitude)
          setValue('arrival_longitude', arrivalLongitude)
          setValue('arrival_timestamp', arrivalTimestamp)
        }

        if (filterCollectedServiceByProvider.length > 0 && !!drivers) {
          const arrivalLatitude = filterCollectedServiceByProvider
            .map((service) => service.arrival_latitude)
            .toString()
          const arrivalLongitude = filterCollectedServiceByProvider
            .map((service) => service.arrival_longitude)
            .toString()
          const arrivalTimestamp = filterCollectedServiceByProvider
            .map((service) => service.arrival_timestamp)
            .toString()

          setValue('provider_id', address_id)
          setValue('arrival_latitude', arrivalLatitude)
          setValue('arrival_longitude', arrivalLongitude)
          setValue('arrival_timestamp', arrivalTimestamp)
        }

        setValue('service_id', service_id)
        navigator.geolocation.getCurrentPosition((position) => {
          setValue('departure_latitude', position.coords.latitude.toString())
          setValue('departure_longitude', position.coords.longitude.toString())
        })
        setValue(
          'departure_timestamp',
          format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        )
        navigator.geolocation.getCurrentPosition((position) => {
          setValue('departure_latitude', position.coords.latitude.toString())
          setValue('departure_latitude', position.coords.latitude.toString())
          setValue('unsuccess_latitude', position.coords.longitude.toString())
          setValue('unsuccess_longitude', position.coords.longitude.toString())
        })
        setValue(
          'unsuccess_timestamp',
          format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        )
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          setValue('arrival_latitude', position.coords.latitude.toString())
          setValue('arrival_longitude', position.coords.longitude.toString())
        })
        setValue(
          'arrival_timestamp',
          format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        )
        setValue('service_id', service_id)
      }
    }

    run()
  }, [
    setValue,
    drivers,
    collectedServiceFilteredById,
    service_id,
    address_id,
  ])

  useEffect(() => {
    function run() {
      if (!!addresses && !!providers && !!drivers) {
        const sourceAddress = addresses.filter(
          (address) => address.id === address_id,
        )
        if (sourceAddress.length > 0) {
          setValue('address_id', address_id)
        }
        const providerSourceAddress = providers.filter(
          (provider) => provider.id === address_id,
        )
        if (providerSourceAddress.length > 0) {
          setValue('provider_id', address_id)
        }

        setSourceAddresses(sourceAddress)
        setProviderSourceAddress(providerSourceAddress)
      }
    }

    run()
  }, [
    setValue,
    address_id,
    service_id,
    userLogged,
    drivers,
    serviceFilteredById,
    providers,
    addresses,
  ])

  useEffect(() => {
    function run() {
      if (slug) {
        colServiceByServiceInProgress.map((colService) => {
          return (
            setValue('collect_sample', colService.collect_sample),
            setValue('collect_volume', colService.collect_volume),
            setValue('responsible_name', colService.responsible_name),
            setValue('observation', colService.observation),
            setValue('box_photo', colService.box_photo),
            setValue('open_box_photo', colService.open_box_photo),
            setValue('content_declaration', colService.content_declaration),
            setValue('receipt_photo', colService.receipt_photo)
          )
        })
      } else {
        setValue('box_photo_change', ' ')
        setValue('open_box_photo_change', ' ')
        setValue('content_photo_change', ' ')
        setValue('receipt_photo_change', ' ')
      }
    }

    run()
  }, [setValue, slug, colServiceByServiceInProgress])

  async function handleCreateCollectedService(values: IFormInputProps) {
    const hasCreateCollectedService = await confirmMessage({
      title: 'Deseja iniciar uma coleta?',
    })

    if (hasCreateCollectedService) {
      setIsLoading(true)
      try {
        const createResponse = await createCollectedService(values, true)
        if (createResponse && userLogged) {
          refetchService()
          history.push(
            `/servico-business/coleta/${service_id}/endereco/${address_id}`,
          )
          setIsLoading(false)
        }
      } catch (err) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  async function handleEditCollectedService(values: IFormInputProps) {
    const formData = collectFormatDataToBack(values, hasUnsuccess)

    const serviceIdByProvider = collectedServiceFilteredById
      .filter((colService) => colService.provider_id === address_id)
      .map((colService) => colService.service_id)
    const serviceIdByAddress = collectedServiceFilteredById
      .filter((colService) => colService.address_id === address_id)
      .map((colService) => colService.service_id)

    if (hasUnsuccess) {
      const hasSetUnsuccessCollectedService = await confirmMessage({
        title: 'Finalizar coleta como sem sucesso?',
      })

      if (hasSetUnsuccessCollectedService) {
        setIsLoading(true)

        if (serviceIdByProvider.length > 0) {
          try {
            if (serviceIdByProvider.length > 1) {
              const collectServicesRepeatedToDelete =
                collectedServiceFilteredById
                  .filter((colService) => colService.provider_id === address_id)
                  .map((colService) => colService.id)
              const isCollectServicesRepeatedRemoved = await Promise.all(
                collectServicesRepeatedToDelete.map(async (id, index) => {
                  if (index > 0) {
                    deleteCollectedService(id)
                  }
                }),
              )
              if (isCollectServicesRepeatedRemoved) {
                const setUnsuccessCollectServiceResponse =
                  await await finishCollectBusinessService({
                    input: formData,
                    service_id: serviceIdByAddress[0],
                  })
                if (setUnsuccessCollectServiceResponse && userLogged) {
                  history.push(
                    `/servico-business/coleta/enderecos/${service_id}`,
                  )
                }
              }
              return
            }
            const setUnsuccessCollectServiceResponse =
              await await finishCollectBusinessService({
                input: formData,
                service_id: serviceIdByAddress[0],
              })
            if (setUnsuccessCollectServiceResponse && userLogged) {
              history.push(`/servico-business/coleta/enderecos/${service_id}`)
            }
          } catch (err) {
            setIsLoading(false)
          }
          return
        }

        if (serviceIdByAddress.length > 0) {
          try {
            if (serviceIdByAddress.length > 1) {
              const collectServicesRepeatedToDelete =
                collectedServiceFilteredById
                  .filter((colService) => colService.address_id === address_id)
                  .map((colService) => colService.id)
              const isCollectServicesRepeatedRemoved = await Promise.all(
                collectServicesRepeatedToDelete.map(async (id, index) => {
                  if (index > 0) {
                    deleteCollectedService(id)
                  }
                }),
              )
              if (isCollectServicesRepeatedRemoved) {
                const setUnsuccessCollectedServiceResponse =
                  await await finishCollectBusinessService({
                    input: formData,
                    service_id: serviceIdByAddress[0],
                  })
                if (setUnsuccessCollectedServiceResponse && userLogged) {
                  history.push(
                    `/servico-business/coleta/enderecos/${service_id}`,
                  )
                }
              }
              return
            }
            const setUnsuccessCollectedServiceResponse =
              await await finishCollectBusinessService({
                input: formData,
                service_id: serviceIdByAddress[0],
              })
            if (setUnsuccessCollectedServiceResponse && userLogged) {
              history.push(`/servico-business/coleta/enderecos/${service_id}`)
            }
          } catch {
            setIsLoading(false)
          }
          return
        }
      } else {
        standardMessage('Ação cancelada com êxito!')
      }
      return
    }

    if (isCurrentService) {
      const hasEditCurrentService = await confirmMessage({
        title: 'Deseja atualizar uma coleta?',
      })

      if (hasEditCurrentService) {
        setIsLoading(true)

        if (serviceIdByAddress.length > 0) {
          try {
            if (serviceIdByAddress.length > 1) {
              const collectServicesRepeatedToDelete =
                collectedServiceFilteredById
                  .filter((colService) => colService.address_id === address_id)
                  .map((colService) => colService.id)
              const isCollectedServiceRepetedRemoved = await Promise.all(
                collectServicesRepeatedToDelete.map(async (id, index) => {
                  if (index > 0) {
                    deleteCollectedService(id)
                  }
                }),
              )
              if (isCollectedServiceRepetedRemoved) {
                const editCurrentCollectedServiceResponse =
                  await editCurrentCollectedService(
                    serviceIdByAddress[0],
                    formData as any,
                  )
                if (editCurrentCollectedServiceResponse && userLogged) {
                  history.push(
                    `/servico-business/coletas-finalizadas/editar/${service_id}`,
                  )
                }
              }
              return
            }
            const editCurrentCollectedServiceResponse =
              await editCurrentCollectedService(
                serviceIdByAddress[0],
                formData as any,
              )
            if (editCurrentCollectedServiceResponse && userLogged) {
              history.push(
                `/servico-business/coletas-finalizadas/editar/${service_id}`,
              )
            }
          } catch (err) {
            setIsLoading(false)
          }
          return
        }

        if (serviceIdByProvider.length > 0) {
          try {
            if (serviceIdByProvider.length > 1) {
              const collectServicesRepeatedToDelete =
                collectedServiceFilteredById
                  .filter((colService) => colService.provider_id === address_id)
                  .map((colService) => colService.id)
              const isCollectedServiceRepetedRemoved = await Promise.all(
                collectServicesRepeatedToDelete.map(async (id, index) => {
                  if (index > 0) {
                    deleteCollectedService(id)
                  }
                }),
              )
              if (isCollectedServiceRepetedRemoved) {
                const editCurrentCollectedServiceResponse =
                  await editCurrentCollectedService(
                    serviceIdByProvider[0],
                    formData as any,
                  )
                if (editCurrentCollectedServiceResponse && userLogged) {
                  history.push(
                    `/servico-business/coletas-finalizadas/editar/${service_id}`,
                  )
                }
              }
              return
            }
            const editCurrentCollectedServiceResponse =
              await editCurrentCollectedService(
                serviceIdByProvider[0],
                formData as any,
              )
            if (editCurrentCollectedServiceResponse && userLogged) {
              history.push(
                `/servico-business/coletas-finalizadas/editar/${service_id}`,
              )
            }
          } catch (err) {
            setIsLoading(false)
          }
          return
        }
      } else {
        standardMessage('Ação cancelada com êxito!')
      }
      return
    }

    const hasFinishCollect = await confirmMessage({
      title: 'Deseja finalizar uma coleta?',
    })

    if (hasFinishCollect) {
      setIsLoading(true)

      if (serviceIdByAddress.length > 0) {
        try {
          if (serviceIdByAddress.length > 1) {
            const collectServicesRepeatedToDelete = collectedServiceFilteredById
              .filter((colService) => colService.address_id === address_id)
              .map((colService) => colService.id)
            const isCollectServicesRepeatedRemoved = await Promise.all(
              collectServicesRepeatedToDelete.map(async (id, index) => {
                if (index > 0) {
                  deleteCollectedService(id)
                }
              }),
            )
            if (isCollectServicesRepeatedRemoved) {
              const editCollectedServiceResponse = await finishCollectBusinessService({
                input: formData,
                service_id: serviceIdByAddress[0],
              })
              if (editCollectedServiceResponse && userLogged) {
                history.push(
                  `/servico-business/coleta/enderecos/${service_id}`,
                )
              }
            }
            return
          }
          const editCollectedServiceResponse = await finishCollectBusinessService({
            input: formData,
            service_id: serviceIdByAddress[0],
          })
          if (editCollectedServiceResponse && userLogged) {
            history.push(`/servico-business/coleta/enderecos/${service_id}`)
          }
        } catch (err) {
          setIsLoading(false)
        }
        return
      }

      if (serviceIdByProvider.length > 0) {
        try {
          if (serviceIdByProvider.length > 1) {
            const collectServicesRepeatedToDelete = collectedServiceFilteredById
              .filter((colService) => colService.provider_id === address_id)
              .map((colService) => colService.id)
            const isCollectServicesRepeatedRemoved = await Promise.all(
              collectServicesRepeatedToDelete.map(async (id, index) => {
                if (index > 0) {
                  deleteCollectedService(id)
                }
              }),
            )
            if (isCollectServicesRepeatedRemoved) {
              const editCollectedServiceResponse = await finishCollectBusinessService({
                input: formData,
                service_id: serviceIdByAddress[0],
              })
              if (editCollectedServiceResponse && userLogged) {
                history.push(`/servico-business/coletas/${service_id}`)
              }
            }
            return
          }
          const editCollectedServiceResponse = await finishCollectBusinessService({
            input: formData,
            service_id: serviceIdByAddress[0],
          })
          if (editCollectedServiceResponse && userLogged) {
            history.push(`/servico-business/coleta/enderecos/${service_id}`)
          }
        } catch (err) {
          setIsLoading(false)
        }
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  const handleChangeValueContentReceipt = () => {
    if (slug === 'andamento') {
      setIsCurrentService(true)

      if (sourceAddresses.length > 0) {
        setValue('receipt_photo_change', ' ')
      } else {
        setValue('content_photo_change', ' ')
      }
    }
  }

  if (isLoading || isLoadingPage) {
    return <GeneralContentLoading />
  }

  if (!addressCollected.includes(address_id)) {
    return (

        <Flex>
          <Box
            as="form"
            flex="1"
            borderRadius="8px"
            bg="white"
            p={['6', '8']}
            onSubmit={handleSubmit(handleCreateCollectedService)}
            noValidate
          >
            <Heading size="lg" fontFamily="poppins">
              {title}
            </Heading>

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'column']}
            >
              {!!sourceAddresses && (
                <>
                  {sourceAddresses.map((address) => (
                    <Input
                      key={address.id}
                      defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}
                      isDisabled={true}
                      name="firstname"
                      label="Endereço Remetente"
                    />
                  ))}
                </>
              )}

              {!!providerSourceAddress && (
                <>
                  {providerSourceAddress.map((provider) => (
                    <Input
                      key={provider.id}
                      defaultValue={`${provider.trading_name} | ${provider.street} | ${provider.number} | ${provider.neighborhood} | ${provider.city} | ${provider.cep}`.toUpperCase()}
                      isDisabled={true}
                      name="firstname"
                      label="Endereço do Fornecedor de Gelo Seco"
                    />
                  ))}
                </>
              )}

              {isDriverSelected && (
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                >
                  Iniciar Coleta
                </Button>
              )}
              <Divider my="6" borderColor="gray.700" />
            </Stack>
            <Flex mt="8" justify="flex-end">
              <HStack>
                <Link to="/servicos-business/coletas">
                  <Button type="button" colorScheme="gray">
                    Serviços Business à Coletar
                  </Button>
                </Link>
              </HStack>
            </Flex>
          </Box>
        </Flex>

    )
  } else {
    return (

        <Flex>
          <Box
            as="form"
            flex="1"
            borderRadius="8px"
            bg="white"
            p={['6', '8']}
            onSubmit={handleSubmit(handleEditCollectedService)}
            noValidate
          >
            <Heading size="lg" fontFamily="poppins">
              {title}
            </Heading>

            <Alert status="info">
              <AlertIcon />
              Coleta já iniciada!
            </Alert>

            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              {!!sourceAddresses && (
                <>
                  {sourceAddresses.map((address) => (
                    <Input
                      key={address.id}
                      defaultValue={`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} | ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}
                      isDisabled={true}
                      name="firstname"
                      label="Endereço Remetente"
                    />
                  ))}
                </>
              )}

              {!!providerSourceAddress && (
                <>
                  {providerSourceAddress.map((provider) => (
                    <Input
                      key={provider.id}
                      defaultValue={`${provider.trading_name} | ${provider.street} | ${provider.number} | ${provider.neighborhood} | ${provider.city} | ${provider.cep}`.toUpperCase()}
                      isDisabled={true}
                      name="firstname"
                      label="Endereço do Fornecedor de Gelo Seco"
                    />
                  ))}
                </>
              )}

            </Stack>

            <Input
              {...register("responsible_name")}
              name="responsible_name"
              label="Nome do responsável"
              error={errors.responsible_name}
              required
            />

            <TextArea
              {...register('observation')}
              name="observation"
              label="Observações"
              isDisabled={isDisabled}
            />

            <Flex mt="8" justify="flex-end">
              <Stack spacing="12px" direction="row">
                {slug ? (
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                    onClick={handleChangeValueContentReceipt}
                  >
                    {isWideVersion ? 'Atualizar Coleta' : <Icon as={FaSave} />}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isLoading={isSubmitting}
                    loadingText="Carregando"
                  >
                    {isWideVersion ? 'Finalizar Coleta' : <Icon as={FaSave} />}
                  </Button>
                )}

                {!slug && (
                  <Button
                    type="submit"
                    colorScheme="red"
                    onClick={() => {
                      setHasUnsuccess(true)
                    }}
                  >
                    {isWideVersion ? 'Sem Sucesso' : <Icon as={FaTimes} />}
                  </Button>
                )}

                <Link to="/servicos-business/coletas">
                  <Button
                    type="button"
                    colorScheme="gray"
                    w={['full', 'full', ' ']}
                  >
                    {isWideVersion ? (
                      'Serviços Business à Coletar'
                    ) : (
                      <Icon as={FaList} />
                    )}
                  </Button>
                </Link>
              </Stack>
            </Flex>
          </Box>
        </Flex>

    )
  }
}

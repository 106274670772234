import { useMutation, useQueryClient } from "react-query";
import { api } from "services/api";

import { useAuth } from "hooks/auth/useAuth";

interface ICreateLogInput {
  user_id: string
  action: string
}

async function createLogReqFunction(input: ICreateLogInput) {

  try {
    const { data } = await api.post('/logs', input)

    return data
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}

export function useLogFunctions(){
  const queryClient = useQueryClient()
  const { handleLogout } = useAuth()

  const createLog = useMutation("createLog", createLogReqFunction,{
    onSuccess: async () => {
      await queryClient.invalidateQueries("logs")
    },
    onError: async (error: any) => {

      if (error.status === 401) {
        await handleLogout()
      }
    }
  })

  return {createLog}
}

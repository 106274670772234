import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { TableFilterButton } from "components/Filters/TableFilterButton";
import { Input } from "components/Inputs/Input";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useExtrasAndDiscounts } from "hooks/extrasAndDiscounts/useExtrasAndDiscounts";
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions";
import { Fragment, useEffect, useReducer, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { formatDate } from "utils/DateFunctions/formatDate";
import { FiEye, FiTrash2, FiCheckCircle } from "react-icons/fi";
import { useExtrasAndDiscountsFunctions } from "hooks/extrasAndDiscounts/useExtrasAndDiscountsFunctions";
import { useSwal } from "hooks/swal/useSwal";
import { useToastify } from "hooks/toastify/useToastify";
import { FaPen, FaPlus } from "react-icons/fa";
import { ExtrasAndDiscountsListResponse } from "hooks/extrasAndDiscounts/dtos/ExtrasAndDiscounts";
import { GenerateExcelReportButton } from "components/Buttons/GenerateExcelReportButton";
import { handleChangeUrl } from "utils/handleChangeUrl";
import { format, parseISO } from "date-fns";

interface ExtrasAndDiscountsListSearchOptions {
  customer: string
  date: string
  identifier: string
}

const extrasAndDiscountsListSearchOptions = [
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'date', value: 'Data', checked: false },
  { key: 'identifier', value: 'Identificador', checked: false }
]

interface ActionPayload {
  extrasAndDiscountsQueryData?: ExtrasAndDiscountsListSearchOptions,
  currentPage?: number
}

interface Action {
  payload: ActionPayload
  type: 'set-current-page' | 'set-extras-and-discounts-query-data'
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-current-page') {
    return {
      extrasAndDiscountsQueryData: state.extrasAndDiscountsQueryData,
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

const headers = [
  { label: 'CLIENTE', key: 'customer' },
  { label: 'DATA DE CRIAÇÃO', key: 'created_at' },
  { label: 'DATA DA OCORRÊNCIA', key: 'occurrence_date' },
  { label: 'TIPO', key: 'type' },
  { label: 'TIPO DE SERVIÇO', key: 'service_type' },
  { label: 'ROTA', key: 'route' },
  { label: 'IDENTIFICADOR', key: 'identifier' },
  { label: 'DESCRIÇÃO', key: 'description' },
  { label: 'VALOR', key: 'value' },
  { label: 'FINALIZADO EM', key: 'closed_at' },
  { label: 'ANEXO', key: 'attachment' },
]

function formatValuesToReport(values: ExtrasAndDiscountsListResponse) {
  return values?.extrasAndDiscounts.map((extraAndDiscount) => ({
    ...extraAndDiscount,
    customer: extraAndDiscount?.customer.trading_firstname,
    created_at: formatDate.handle(extraAndDiscount.created_at, 'DateWithoutHourToShow'),
    occurrence_date: extraAndDiscount?.occurrence_date ? format(parseISO(extraAndDiscount?.occurrence_date), 'dd/MM/yyyy') : '-',
    value: extraAndDiscount.value / 100,
    identifier: extraAndDiscount.service_type === 'EXPRESS'
      ? extraAndDiscount.protocol
      : extraAndDiscount.route,
    closed_at: extraAndDiscount.closed_at ? formatDate.handle(extraAndDiscount.closed_at, 'DateWithoutHourToShow') : '-',
    attachment: extraAndDiscount.attachment ? handleChangeUrl(extraAndDiscount.attachment) : '-'
  }))
}

export function ExtrasAndDiscountsList() {
  const ROWS_PER_PAGE = process.env.REACT_APP_ITEMS_PER_PAGE

  const [extrasAndDiscountsQueryDataState, dispatch] = useReducer(reducer, {} as ActionPayload)

  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()
  const { confirmMessage, standardMessage } = useSwal()
  const { promiseMessage } = useToastify()

  const {
    deleteCustomerExtraAndDiscount: {
      mutateAsync: deleteCustomerExtraAndDiscount
    },
    finishCustomerExtraAndDiscount: {
      mutateAsync: finishCustomerExtraAndDiscount
    }
  } = useExtrasAndDiscountsFunctions()

  const userLoggedHasPermissionToViewExtrasAndDiscounts =
    userLogged?.permissions.includes('view-extras-and-discounts')

  useEffect(() => {
    if (!userLoggedHasPermissionToViewExtrasAndDiscounts) {
      redirect('/')
    }
  }, [userLoggedHasPermissionToViewExtrasAndDiscounts, redirect])

  const {
    control,
    register,
  } = useForm<ExtrasAndDiscountsListSearchOptions>()

  const [
    customerSearched,
    dateSearched,
    identifierSearched
  ] = useWatch({
    control,
    name: ['customer', 'date', 'identifier']
  })

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()

  useEffect(() => {
    onLoadSetFilterOptions(extrasAndDiscountsListSearchOptions)
  }, [onLoadSetFilterOptions])

  const {
    data: extrasAndDiscountsData,
    isFetching: isFetchingExtrasAndDiscountsData
  } = useExtrasAndDiscounts({
    queryParams: {
      ...extrasAndDiscountsQueryDataState.extrasAndDiscountsQueryData,
      current_page: extrasAndDiscountsQueryDataState.currentPage,
      page_size: Number(ROWS_PER_PAGE)
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: extrasAndDiscountsData?.total_pages,
      initialState: {
        pageSize: Number(ROWS_PER_PAGE),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleChangePage = (page: number) => setCurrentPage(page)


  useEffect(() => {
    dispatch({
      type: 'set-current-page',
      payload: {
        currentPage: currentPage
      }
    })
  }, [currentPage])


  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch({
        type: 'set-extras-and-discounts-query-data',
        payload: {
          extrasAndDiscountsQueryData: {
            customer: customerSearched,
            date: dateSearched,
            identifier: identifierSearched
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounceTimeout)
  }, [
    customerSearched,
    dateSearched,
    dispatch,
    setCurrentPage,
    identifierSearched
  ])

  async function handleDeleteExtraAndDiscount(extraAndDiscountId: string) {
    const hasDeleteGeneralProvider = await confirmMessage({ title: "Deseja excluir o extra/desconto?" })

    if (hasDeleteGeneralProvider) {
      await promiseMessage(deleteCustomerExtraAndDiscount(extraAndDiscountId), "Extra/Desconto excluído com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  async function handleFinishExtraAndDiscount(extraAndDiscountId: string) {
    const hasDeleteGeneralProvider = await confirmMessage({ title: "Deseja finalizar o extra/desconto?" })

    if (hasDeleteGeneralProvider) {
      await promiseMessage(finishCustomerExtraAndDiscount(extraAndDiscountId), "Extra/Desconto finalizado com sucesso!")
    } else {
      standardMessage("Ação cancelada com êxito!")
    }
  }

  const [isGenerateReportEnabled, setIsGenerateReportEnabled] = useState(false)

  function handleGenerateReportEnabled() {
    setIsGenerateReportEnabled(isGenerateReportEnabled => !isGenerateReportEnabled)
  }

  const {
    data: extrasAndDiscountsReportData,
    isFetching: isFetchingExtrasAndDiscountsReportData
  } = useExtrasAndDiscounts({
    queryOptions: {
      enabled: isGenerateReportEnabled,
    }
  })

  const filterOptionsChecked = filterOptions.filter((option) => option.checked)

  const csvReportProps = {
    data: extrasAndDiscountsReportData ? formatValuesToReport(extrasAndDiscountsReportData) : [],
    headers,
    filename: `extras-e-descontos.csv`,
  }

  return (

    <Box
      p={4}
      bg="white"
      borderRadius="8px"
    >
      <Flex
        w="full"
        direction="column"
        gap={6}
      >
        <Flex
          align="center"
          gap={6}
          justify={["center", "center", "space-between"]}
          direction={["column", "column", "row"]}
          flex="1"
          w="full"
        >
          <Heading fontSize="xl">Extras e Descontos</Heading>
          <Button
            as={Link}
            size="sm"
            w={["full", "full", "min"]}
            colorScheme="gray"
            to="/extras-e-descontos/adicionar"
            leftIcon={<Icon as={FaPlus} />}
          >
            Novo
          </Button>
        </Flex>


        <Flex>
          <TableFilterButton />
        </Flex>

        {filterOptionsChecked.map((option) => {
          return (
            <Fragment key={option.key}>
              {option.key === 'date' ? (
                <Input
                  {...register('date')}
                  name='date'
                  label='Data da criação'
                  type='date'
                />
              ) : (
                <Input
                  {...register(option.key as keyof ExtrasAndDiscountsListSearchOptions)}
                  name={option.key}
                  label={`Buscar por ${option.value}`}
                />
              )}

            </Fragment>
          )
        })}

        {isFetchingExtrasAndDiscountsData ? (
          <Spinner />
        ) : (
          <TableContainer>
            <Table size='sm' variant='striped'>
              <Thead>
                <Tr>
                  <Th>CLIENTE</Th>
                  <Th>DATA CRIAÇÃO</Th>
                  <Th>TIPO</Th>
                  <Th>TIPO DE SERVIÇO</Th>
                  <Th>IDENTIFICADOR</Th>
                  <Th>VALOR DO EXTRA OU DESCONTO</Th>
                  <Th>CRIADO POR</Th>
                  <Th>FINALIZADO EM</Th>
                </Tr>
              </Thead>
              <Tbody>
                {extrasAndDiscountsData?.extrasAndDiscounts?.map((extraAndDiscount) => {

                  const extraAndDiscountInfo = extraAndDiscount.service_type === 'EXPRESS'
                    ? extraAndDiscount.protocol
                    : extraAndDiscount.route

                  return (
                    <Tr key={extraAndDiscount.id}>
                      <Td>{extraAndDiscount.customer.trading_firstname}</Td>
                      <Td>{formatDate.handle(extraAndDiscount.created_at, 'DateWithoutHourToShow')}</Td>
                      <Td>{extraAndDiscount.type}</Td>
                      <Td>{extraAndDiscount.service_type}</Td>
                      <Td>{extraAndDiscountInfo}</Td>
                      <Td>
                        {
                          new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(extraAndDiscount.value / 100)
                        }
                      </Td>
                      <Td>{extraAndDiscount.createdBy ? `${extraAndDiscount.createdBy.firstname} ${extraAndDiscount.createdBy.lastname}` : '-'}</Td>
                      <Td>{extraAndDiscount.closed_at ? formatDate.handle(extraAndDiscount.closed_at, 'DateWithoutHourToShow') : '-'}</Td>
                      <Td>
                        {
                          userLogged.permissions.includes('view-extras-and-discounts') && (
                            <Link
                              to={`/extras-e-descontos/visualizar/${extraAndDiscount.id}`}
                            >
                              <Icon
                                cursor="pointer"
                                as={FiEye}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                              />
                            </Link>
                          )
                        }
                        {
                          userLogged.permissions.includes('update-extra-and-discount') && (
                            <Link
                              to={`/extras-e-descontos/${extraAndDiscount.id}/editar`}
                            >
                              <Icon
                                cursor="pointer"
                                as={FaPen}
                                fontSize="20"
                                ml="4"
                                mt={['2', '2', '0']}
                              />
                            </Link>
                          )
                        }
                        {
                          userLogged.permissions.includes('delete-extra-and-discount') && (
                            <Icon
                              cursor="pointer"
                              as={FiTrash2}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                              onClick={() =>
                                handleDeleteExtraAndDiscount(extraAndDiscount.id)
                              }
                            />
                          )
                        }
                        {
                          userLogged.permissions.includes('close-extra-and-discount') && (
                            <Icon
                              cursor="pointer"
                              as={FiCheckCircle}
                              fontSize="20"
                              ml="4"
                              mt={['2', '2', '0']}
                              onClick={() =>
                                handleFinishExtraAndDiscount(extraAndDiscount.id)
                              }
                            />
                          )
                        }
                      </Td>

                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Flex>

      <Flex justifyContent={'flex-end'} >
        {!isGenerateReportEnabled ? (
          <Button
            colorScheme="green"
            onClick={handleGenerateReportEnabled}
            justifyContent="flex-end" mt="4"
          >
            Gerar CSV
          </Button>
        ) : (
          <GenerateExcelReportButton
            csvReportProps={csvReportProps}
            isLoading={isFetchingExtrasAndDiscountsReportData}
            onClick={handleGenerateReportEnabled}
          />
        )}
      </Flex>
      <Pagination
        currentPage={currentPage}
        pages={pages}
        pagesQuantity={pagesCount}
        handlePageChange={handleChangePage}
      />

    </Box>

  )
}

import { api } from "../../../services/api"

export type CreateGeloSecoMaintenanceProps = {
  service_id: string;
  input: FormData;
}

async function createGeloSecoMaintenanceReqFunction({
  service_id,
  input
}: CreateGeloSecoMaintenanceProps) {
  try {
    const {
      data
    } = await api.post(`/service/maintenance/gelo_seco/${service_id}`, input)

    return data;
  } catch (error: any) {
    throw new Error(error.message)
  }
}

export { createGeloSecoMaintenanceReqFunction }

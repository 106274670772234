import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { DeliveryDetail } from "./DeliveryDetail";

interface ValidatePendingLandingServicesRowProps {
  service: {
    id: string
    protocol: number
    serviceIDDelivery: {
      id: string
      protocol: number
      customerIDService: {
        trading_firstname: string
      }
      serviceIDRequested: {
        destinationCollectorIDService: {
          trading_name: string
        }
      }
    }
  }
}

export function DeliveriesWithoutQRCodePendingValidationTableRow({
  service
}: ValidatePendingLandingServicesRowProps) {

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  return (
    <Tr>

      <Td>
        <IconButton
          aria-label="Visualizar Desembarque"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />

          <DeliveryDetail deliveryId={service.id} onCloseModal={onCloseDetailModal} />
        </Modal>
      </Td>
      <Td>{service.serviceIDDelivery.protocol}</Td>
      <Td>{service.serviceIDDelivery.customerIDService.trading_firstname}</Td>
      <Td>{service.serviceIDDelivery.serviceIDRequested.destinationCollectorIDService.trading_name}</Td>
    </Tr>
  )
}

import {
  Divider,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { GetDelayedCollectsResponse } from "api/service/getDelayedCollects";
import { GetDelayedDeliveriesResponse } from "api/service/getDelayedDeliveries";
import { DelayedCollectsTable } from "./components/DelayedCollectsTable";
import { DelayedDeliveriesTable } from "./components/DelayedDeliveriesTable";

interface ServicesInDelayModalProps {
  isOpen: boolean
  onClose: () => void
  delayedCollects: Pick<GetDelayedCollectsResponse, 'services'>
  delayedDeliveries: Pick<GetDelayedDeliveriesResponse, 'services'>
}

export function ServicesInDelayModal({
  isOpen,
  onClose,
  delayedCollects,
  delayedDeliveries
}: ServicesInDelayModalProps) {

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold" fontSize="2xl">Atrasos</ModalHeader>
        <ModalCloseButton />

        <ModalBody
          maxH="570px"
          overflowY="auto"
          overflowX="auto"
        >
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>

            <GridItem>
              <Text fontWeight="bold" mb={4}>Atrasos de Coleta</Text>
              <DelayedCollectsTable
                delayedCollectsResult={delayedCollects}
              />
            </GridItem>

            <GridItem>
              <Divider orientation="vertical"/>
            </GridItem>

            <GridItem pr={6}>
              <Text fontWeight="bold" mb={4}>Atrasos de Entrega</Text>
              <DelayedDeliveriesTable
                delayedDeliveriesResult={delayedDeliveries}
              />
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

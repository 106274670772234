import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { FaCalendarAlt } from "react-icons/fa";
import { ExperiencePeriodDetail } from "./ExperiencePeriodDetail";
import { ExperiencePeriod } from "api/experiencePeriods/types/ExperiencePeriod";
import { format } from "date-fns";

interface ExperiencePeriodsKanbanCardProps {
  experiencePeriod: ExperiencePeriod
}

export function ExperiencePeriodsKanbanCard({ experiencePeriod }: ExperiencePeriodsKanbanCardProps) {
  const {
    isOpen: isExperiencePeriodDetailModalOpen,
    onToggle: onToggleExperiencePeriodDetailModal,
  } = useDisclosure()

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleExperiencePeriodDetailModal}
      cursor='pointer'
    >
      <HStack justify="space-between" w="full">
        <Text fontWeight="bold" mb={2}>
          {experiencePeriod?.name}
        </Text>

      </HStack>


      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA ADMISSÃO</Text>
          </HStack>
          <Text ml={5}>{experiencePeriod?.admission_date ? format(new Date(experiencePeriod?.admission_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">45 DIAS</Text>
          </HStack>
          <Text ml={5}>{experiencePeriod?.admission_date ? format(new Date(experiencePeriod?.period_45_days_end_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">90 DIAS</Text>
          </HStack>
          <Text ml={5}>{experiencePeriod?.admission_date ? format(new Date(experiencePeriod?.period_90_days_end_date), 'dd/MM/yyyy') : '-'}</Text>
        </Box>

      </VStack>

      <Modal
        isOpen={isExperiencePeriodDetailModalOpen}
        onClose={onToggleExperiencePeriodDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <ExperiencePeriodDetail
          experiencePeriodId={experiencePeriod.id}
        />
      </Modal>
    </Box>
  )
}

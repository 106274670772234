import { Box, Button, Flex, FormControl, FormLabel, Heading, Radio, RadioGroup, Stack, Text, Textarea } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { realizePassiveFeedback } from "api/feedbacks/realizePassiveFeedback";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup"

interface RealizeCltDriverPassiveFeedbackProps {
  passiveFeedbackId: string
}

interface RealizeCltDriverPassiveFeedbackSchema {
  hasDpDoubts: string
  doesYouKnowHowToClockIn: string
  hasPaymentsAndDatesDoubts: string
  hasProceduresDoubts: string
  hasStandardizationsAndMotorcycleLocationDoubts: string
  doesYouKnowHowAccessPayStub: string
  haveYouBeenTrainedCorrectly: string
  observations: string
}

const realizeCltDriverPassiveFeedbackSchema = yup.object({
  hasDpDoubts: yup.string().required(),
  doesYouKnowHowToClockIn: yup.string().required(),
  hasPaymentsAndDatesDoubts: yup.string().required(),
  hasProceduresDoubts: yup.string().required(),
  hasStandardizationsAndMotorcycleLocationDoubts: yup.string().required(),
  doesYouKnowHowAccessPayStub: yup.string().required(),
  haveYouBeenTrainedCorrectly: yup.string().required(),
  observations: yup.string().required(),
})

export function RealizeCltDriverPassiveFeedback({ passiveFeedbackId }: RealizeCltDriverPassiveFeedbackProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    handleSubmit,
    control,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<RealizeCltDriverPassiveFeedbackSchema>({
    resolver: yupResolver(realizeCltDriverPassiveFeedbackSchema)
  })

  const { mutateAsync: realizeCltDriverPassiveFeedbackFn } = useMutation({
    mutationFn: realizePassiveFeedback,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'scheduled-passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'done-passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: ['passive-feedback', passiveFeedbackId] })
    }
  })

  async function handleRealizeCltDriverPassiveFeedback(values: RealizeCltDriverPassiveFeedbackSchema) {
    await promiseMessage(realizeCltDriverPassiveFeedbackFn({
      body: {
        ...values,
        haveYouBeenTrainedCorrectly: values.haveYouBeenTrainedCorrectly === 'yes',

      },
      passiveFeedbackId
    }), 'Feedback realizado')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleRealizeCltDriverPassiveFeedback)}
      maxH='500px'
      overflowY='scroll'
      px={2}
    >
      <Heading letterSpacing="tight" size='sm'>Formulário Inicial</Heading>

      <FormControl isInvalid={!!errors.haveYouBeenTrainedCorrectly} mt={3}>
        <FormLabel fontSize='sm'>
          Foi devidamente treinado?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="haveYouBeenTrainedCorrectly"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.hasDpDoubts} mt={3}>
        <FormLabel fontSize="sm">
          Você tem dúvidas sobre DP? Quais?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("hasDpDoubts")}
          name="hasDpDoubts"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <FormControl isInvalid={!!errors.doesYouKnowHowToClockIn} mt={3}>
        <FormLabel fontSize='sm'>
          Sabe como bater ponto?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesYouKnowHowToClockIn"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.hasPaymentsAndDatesDoubts} mt={3}>
        <FormLabel fontSize="sm">
          Tem dúvidas ainda sobre pagamentos e datas?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("hasPaymentsAndDatesDoubts")}
          name="hasPaymentsAndDatesDoubts"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <FormControl isInvalid={!!errors.hasProceduresDoubts} mt={3}>
        <FormLabel fontSize="sm">
          Sabe onde abrir atendimento para esclarecer suas dúvidas, anexar atestados dentre outros procedimento?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("hasProceduresDoubts")}
          name="hasProceduresDoubts"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <FormControl isInvalid={!!errors.hasStandardizationsAndMotorcycleLocationDoubts} mt={3}>
        <FormLabel fontSize="sm">
          Ficou claro como funciona o controle de padronização e pagamento de aluguel de moto?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("hasStandardizationsAndMotorcycleLocationDoubts")}
          name="hasStandardizationsAndMotorcycleLocationDoubts"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <FormControl isInvalid={!!errors.doesYouKnowHowAccessPayStub} mt={3}>
        <FormLabel fontSize='sm'>
          Sabe como acessar o seu holerite?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="doesYouKnowHowAccessPayStub"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors.observations} mt={3}>
        <FormLabel fontSize="sm">
          Observações
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Textarea
          {...register("observations")}
          name="observations"
          size="sm"
          rounded="md"
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>

    </Box>

  )
}

import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { ExtraDiscount } from "hooks/extrasDiscounts/dtos/ExtrasDiscounts";

interface ViewExtraDiscountModalProps {
  isOpen: boolean
  onClose: () => void
  extraDiscount: ExtraDiscount
  type: 'collector' | 'aggregate' | 'cltDriver'
}

export function ViewExtraDiscountModal({
  isOpen,
  onClose,
  extraDiscount,
  type,
}: ViewExtraDiscountModalProps) {

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />


      <ModalContent>
        <ModalHeader>
          {type === 'collector' && (
            <Text>{extraDiscount?.collector?.trading_name}</Text>
          )}

          {type === 'aggregate' && (
            <Text>{extraDiscount?.aggregate?.driver.firstname} {extraDiscount?.aggregate?.driver.lastname}</Text>
          )}

          {type === 'cltDriver' && (
            <Text>{extraDiscount?.cltDriver?.driver.firstname} {extraDiscount?.cltDriver?.driver.lastname}</Text>
          )}

          <ModalCloseButton />
        </ModalHeader>
        <ModalBody
          as={Flex}
          direction="column"
          gap={4}
        >

          <Stack
            direction={["column", "column", "row"]}
            spacing="4"
          >
            <Input
              name="type"
              defaultValue={extraDiscount?.type}
              size="sm"
              label="Tipo"
              isDisabled
            />
            <Input
              name="serviceDate"
              defaultValue={extraDiscount?.service_date}
              type="date"
              size="sm"
              label="Data do serviço"
              isDisabled
            />
          </Stack>

          <Stack
            direction={["column", "column", "row"]}
            spacing="4"
          >
            <Input
              name="value"
              defaultValue={new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(extraDiscount?.value / 100)}
              size="sm"
              label="Valor"
              isDisabled
            />

            {extraDiscount?.service_route && (
              <Input
                name="route"
                defaultValue={extraDiscount?.service_route}
                size="sm"
                label="Rota"
                isDisabled
              />
            )}

          </Stack>
          <Stack
            direction={["column", "column", "row"]}
            spacing="4"
          >
            {extraDiscount?.services && extraDiscount?.services?.map((service) => {
              return (
                <Input
                  key={service.id}
                  name="protocol"
                  defaultValue={service?.service?.protocol}
                  size="sm"
                  label="Protocolo"
                  isDisabled
                />
              )
            })}
          </Stack>

          <TextArea
            name="description"
            defaultValue={extraDiscount?.description}
            size="sm"
            label="Descrição"
            isDisabled
          />

        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

const formatDateToFrontWithDayAndMonth = (date: string) => {
  return format(new Date(date), "dd 'de' MMMM", {
    locale: ptBR,
  })
}

export { formatDateToFrontWithDayAndMonth }

import { Box, Divider, Heading, Button, Text, Grid, GridItem, Image } from '@chakra-ui/react'

import { useAuth } from '../../hooks/auth/useAuth'
import { Link } from 'react-router-dom'
import { captalize } from 'utils/captalize'
import trackingCalendarImage from '../../assets/images/tracking_calendar.png'

export function Home() {
  const { userLogged } = useAuth()

  const name = captalize(`${userLogged?.firstname} ${userLogged?.lastname}`)

  return (
    <Box w="full" h="full" bg="white" px={{ base: 12, lg: 20 }} rounded="md">
      <Grid h="full" templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}>
        <GridItem
          alignContent="center"
        >
          <Heading fontSize="5xl" letterSpacing="tight">
            Olá, <br />
            {name}.
          </Heading>
          <Text mt={3} fontSize="2xl" fontWeight="semibold">
            Vamos {' '}
            <Text as="span" color="#38c3fa">juntos</Text> fazer a {' '}
            <Text as="span" color="#38c3fa">diferença!</Text>
          </Text>

          <Divider my="6" borderColor="gray.700" />

          {userLogged !== null && (
            <>
              {userLogged.user_type !== 'CLIENTE' &&
                userLogged.user_type !== 'REMETENTE' &&
                userLogged.user_type !== 'COLETADOR' &&
                userLogged.user_type !== 'MOTORISTA' && (
                  <Link style={{ width: '100%' }} to="/dashboard/matriz">
                    <Button rounded="full" w="min" colorScheme="blue">
                      Meu Dashboard
                    </Button>
                  </Link>
                )}

              {(userLogged.user_type === 'CLIENTE' || userLogged.user_type === 'REMETENTE') && (
                <Link style={{ width: '100%' }} to="/dashboard/cliente">
                  <Button rounded="full" w="min" colorScheme="blue">
                    Meu Dashboard
                  </Button>
                </Link>
              )}

              {userLogged.user_type === 'COLETADOR' && (
                <Link style={{ width: '100%' }} to="/dashboard/coletador">
                  <Button rounded="full" w="min" colorScheme="blue">
                    Meu Dashboard
                  </Button>
                </Link>
              )}

              {userLogged.user_type === 'MOTORISTA' && (
                <Link style={{ width: '100%' }} to="/dashboard/motorista">
                  <Button rounded="full" w="min" colorScheme="blue">
                    Meu Dashboard
                  </Button>
                </Link>
              )}
            </>
          )}
        </GridItem>
        <GridItem
          alignContent="center"
        >
          <Image
            src={trackingCalendarImage}
            w={{ base: '400px',  lg:"600px"}}
            h={{ base: '400px',  lg:"600px"}}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";

import { Aso } from "api/asos/aso";
import { AsoDetail } from "./AsoDetail";
import { asoTypeMap } from "utils/asoMappers";

interface AsosKanbanCardProps {
  aso: Aso
}

export function AsosKanbanCard({ aso }: AsosKanbanCardProps) {

  const {
    isOpen: isAsoDetailModalOpen,
    onToggle: onToggleAsoDetailModal,
  } = useDisclosure()


  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onToggleAsoDetailModal}
      cursor='pointer'
    >

      <Text fontWeight="bold" mb={2}>
        {aso?.collaborator_name}
      </Text>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">

        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">DATA SOLICITAÇÃO</Text>
          </HStack>
          <Text ml={5}>{format(new Date(aso?.created_at), "dd/MM/yyyy")}</Text>
        </Box>
        <Box>
          <HStack spacing={2}>
            <Icon as={FaCalendarAlt} />
            <Text fontSize="xs" fontWeight="bold">TIPO</Text>
          </HStack>
          <Text ml={5}>{asoTypeMap[aso.type]}</Text>
        </Box>

      </VStack>
      <Modal
        isOpen={isAsoDetailModalOpen}
        onClose={onToggleAsoDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <AsoDetail
          asoId={aso.id}
          onClose={onToggleAsoDetailModal}
        />
      </Modal>
    </Box>
  )
}

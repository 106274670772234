import { Flex, Box, Heading, Button, Icon } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { ShippingTable } from '../../components/Tables/Shipping/ShippingTable'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useShipping } from '../../hooks/shipping/useShipping'
import { useAuth } from '../../hooks/auth/useAuth'

export function ShippingList() {
  const {
    shipping: { data: shipping, isLoading: isShippingLoading, refetch },
  } = useShipping(null, true, false)

  const { userLogged } = useAuth()
  const permissions = userLogged?.permissions

  if (isShippingLoading) {
    return <GeneralContentLoading />
  }

  return (

      <Flex w="100%" direction={['column', 'column', 'row']}>
        <Box flex="1" borderRadius="8" bg="white" p={['6', '8']}>
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading size="lg" fontFamily="poppins" alignItems="center">
              Lista de Transportadoras
            </Heading>
            {permissions !== undefined && (
              <Link to="/transportadora/adicionar">
                {userLogged?.permissions.includes('add-shipping') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                    mt={['2', '2', '0']}
                  >
                    Adicionar Transportadora
                  </Button>
                )}
              </Link>
            )}
          </Flex>
          {!!shipping && (
            <ShippingTable shipping={shipping} refetch={refetch} />
          )}
        </Box>
      </Flex>

  )
}

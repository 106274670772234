import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface SendCltDriverDismissTerminationDetailsBody {
  doesPayedTermination: boolean
  hasDeletedEmployeeFromOccupationalMedicalPlatform: 'yes' | 'no' | 'n/a'
  dismissionRealDate: Date
}

interface SendCltDriverDismissTerminationDetailsProps {
  body: SendCltDriverDismissTerminationDetailsBody
  cltDriverId: string
}

export async function sendCltDriverDismissTerminationDetails({
  body,
  cltDriverId
}: SendCltDriverDismissTerminationDetailsProps) {
  try {
    const response = await api.put(`/clt-drivers/${cltDriverId}/dismiss/termination`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

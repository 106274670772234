import { ValidateNewServiceAddressAction } from "./ValidateNewServiceAddressAction";
import { ValidateNewServiceAddressContent } from "./ValidateNewServiceAddressContent";
import { ValidateNewServiceAddressesCard } from "./ValidateNewServiceAddressesCard";
import { ValidateNewServiceAddressesRoot } from "./ValidateNewServiceAddressesRoot";

export const ValidateNewServiceAddresses = {
  Root: ValidateNewServiceAddressesRoot,
  Card: ValidateNewServiceAddressesCard,
  Content: ValidateNewServiceAddressContent,
  Action: ValidateNewServiceAddressAction
}

import { Flex } from "@chakra-ui/react";
import { Input } from "components/Inputs/Input";
import { TextArea } from "components/Inputs/TextInput";
import { useFormContext } from "react-hook-form";

interface CrmNoteFormInputs {
  noteDate: number
  noteDescription: string
}

interface CrmNoteProps {
  isViewPage: boolean
}


export function CrmNoteForm({ isViewPage }: CrmNoteProps) {

  const {
    register,
    formState: { errors }
  } = useFormContext<CrmNoteFormInputs>()

  return (
    <Flex
      gap={4}
      direction='column'
    >
      <Input
        {...register('noteDate')}
        name='noteDate'
        error={errors.noteDate}
        type='date'
        label='Data'
        isDisabled={isViewPage}
        required
      />

      <TextArea
        {...register('noteDescription')}
        name='noteDescription'
        label='Descrição'
        error={errors.noteDescription}
        isDisabled={isViewPage}
        required
      />
    </Flex>
  )
}

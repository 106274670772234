import { FormProvider, useForm } from "react-hook-form";
import { NotificationTypeForm, NotificationTypeFormInputs } from "./components/NotificationTypeForm";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { Button, Flex, Spinner } from "@chakra-ui/react";
import { useNotificationTypeFunctions } from "hooks/notification/useNotificationTypeFunctions";
import { useToastify } from "hooks/toastify/useToastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useNotificationType } from "hooks/notification/useNotificationType";
import { useAuth } from "hooks/auth/useAuth";

interface Params {
  id: string
}

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  profiles: yup.array().min(1, 'É necessário escolher ao menos uma opção').required('Campo obrigatório')
})

export function UpdateNotificationType() {

  const { userLogged } = useAuth()

  const { push: redirectTo } = useHistory()
  const { id } = useParams<Params>()

  useEffect(() => {
    if (!userLogged?.permissions.includes('edit-notification-type')) {
      redirectTo('/')
    }
  }, [userLogged, redirectTo])

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      profiles: []
    }
  })

  const { setValue, handleSubmit } = formMethods

  const {
    data: notificationType,
    isFetching: isFetchingNotificationType
  } = useNotificationType({ notificationTypeId: id })

  useEffect(() => {
    if (notificationType) {
      setValue('name', notificationType.name)
      setValue('profiles', notificationType.profiles)
    }
  }, [notificationType, setValue])

  const {
    updateNotificationType: { mutateAsync: updateNotificationType }
  } = useNotificationTypeFunctions()

  const { promiseMessage } = useToastify()

  async function handleUpdateNotificationType({
    name,
    profiles
  }: NotificationTypeFormInputs) {
    await promiseMessage(updateNotificationType({
      name,
      profiles,
      notification_type_id: id
    }, {
      onSuccess: () => redirectTo('/notificacao/tipos')
    }), 'Tipo de notificação atualizado com sucesso!')
  }

  return (
    <StandardBackgroundForm
      title='Editar tipo de notificação'
      onSubmit={handleSubmit(handleUpdateNotificationType)}
    >
      {isFetchingNotificationType ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <NotificationTypeForm
            mode="edit"
            defaultSelectedProfiles={notificationType?.profiles}
          />
        </FormProvider>
      )}


      <Flex
        direction={['column', 'column', 'row']}
        gap={2}
        justify={['center', 'center', 'flex-end']}
        mt={4}
      >
        <Button type='submit' colorScheme='blue'>Editar</Button>
        <Button
          as={Link}
          to='/notificacao/tipos'
          variant='ghost'
        >
          Tipos Cadastrados
        </Button>
      </Flex>
    </StandardBackgroundForm>
  )
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";


interface EditInternCltOfficeBody {
  name: string
  trainings: string[]
}

interface EditInternCltOfficeProps {
  body: EditInternCltOfficeBody
  routeParams: { officeId: string }
}

export async function editInternCltOffice({
  body,
  routeParams
}: EditInternCltOfficeProps) {
  try {
    const response = await api.put(`/offices/${routeParams.officeId}/intern-clts`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

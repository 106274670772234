import { usePagination } from "@ajna/pagination"
import { Box, Flex, Heading, Divider, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, Icon, Link as ChakraLink } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { Input } from "components/Inputs/Input"
import { Pagination } from "components/Pagination/Pagination"

import { differenceInMinutes, format, isBefore, isSameDay, set } from "date-fns"
import { useAuth } from "hooks/auth/useAuth"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { Occurrence } from "hooks/occurrences/dtos/Occurrence"
import { useInLandingServices } from "hooks/services/useInLandingServices"
import { Fragment, useEffect, useMemo, useReducer } from "react"
import { useForm, useWatch } from "react-hook-form"
import { FaClock, FaExclamation, FaEye, FaPen, FaUndoAlt } from "react-icons/fa"
import { useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import { formatDate } from "utils/DateFunctions/formatDate"

interface InLandingServicesFilterInputs {
  protocol?: string
  delivery_date?: string
  delivery_hour?: string
  shipping?: string
  board_tracker?: string
  board_volume?: string
  source_branch?: string
  destination_branch?: string
  customer?: string
  source_city?: string
  destination_city?: string
  collector?: string
}

interface ActionPayload {
  inLandingServiceData?: InLandingServicesFilterInputs
  currentPage?: number
}

interface Action {
  type: 'set-in-landing-services-data' | 'set-in-landing-services-current-page'
  payload: ActionPayload
}

function reducer(state: ActionPayload, action: Action) {
  if (action.type === 'set-in-landing-services-current-page') {
    return {
      inLandingServiceData: { ...state.inLandingServiceData },
      currentPage: action.payload.currentPage
    }
  }

  return {
    ...state,
    ...action.payload
  }
}

const inLandingServicesListFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'delivery_date', value: 'Data da entrega', checked: false },
  { key: 'delivery_hour', value: 'Horário da entrega', checked: false },
  { key: 'shipping', value: 'Transportadora', checked: false },
  { key: 'board_tracker', value: 'Rastreador validado', checked: false },
  { key: 'board_volume', value: 'Volume validado no embarque', checked: false },
  { key: 'source_branch', value: 'Base de origem', checked: false },
  { key: 'destination_branch', value: 'Base de destino', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'source_city', value: 'Cidade(s) de origem', checked: false },
  { key: 'destination_city', value: 'Cidade(s) de destino', checked: false },
  { key: 'collector', value: 'Coletador de destino', checked: false },
]

function compareDeliveryForecastDateWithDateNow(
  availableForecastDate: string,
  availableForecastHour: string
) {
  const [availableForecastEndHours, availableForecastEndMinutes] = formatDate
    .handle(availableForecastHour, 'DateOnlyWithHourMinute').split(':')


  const differenceToNow = differenceInMinutes(
    new Date(),
    set(new Date(availableForecastDate), {
      hours: Number(availableForecastEndHours),
      minutes: Number(availableForecastEndMinutes)
    })
  )

  return differenceToNow > 0
}

export function InLandingServicesList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const [inLandingServicesDataState, dispatch] = useReducer(
    reducer,
    {} as ActionPayload
  )

  const { userLogged } = useAuth()

  const {
    filterOptions,
    onLoadSetFilterOptions
  } = useFilterOptions()


  const filterOptionsByUserLogged = useMemo(() => {
    return inLandingServicesListFilterOptions.filter(option => {
      const userLoggedCollectorFilter = (userLogged?.user_type === 'COLETADOR' ||
        userLogged?.user_type === 'MOTORISTA')
        ? option.key !== 'collector'
        : true

      return !['board_tracker', 'board_volume'].includes(option.key) && userLoggedCollectorFilter
    })
  }, [userLogged])

  useEffect(() => {
    onLoadSetFilterOptions(filterOptionsByUserLogged)
  }, [onLoadSetFilterOptions, filterOptionsByUserLogged])



  const { register, control } = useForm<InLandingServicesFilterInputs>()

  const [
    protocol,
    deliveryDate,
    deliveryHour,
    shipping,
    boardTracker,
    boardVolume,
    sourceBranch,
    destinationBranch,
    customer,
    sourceCity,
    destinationCity,
    collector
  ] = useWatch({
    control,
    name: [
      'protocol',
      'delivery_date',
      'delivery_hour',
      'shipping',
      'board_tracker',
      'board_volume',
      'source_branch',
      'destination_branch',
      'customer',
      'source_city',
      'destination_city',
      'collector'
    ]
  })

  const {
    data: inBoardServicesData,
    isFetching: isFetchingInLandingServicesData
  } = useInLandingServices({
    queryParams: {
      ...inLandingServicesDataState.inLandingServiceData,
      collector_id: userLogged?.collector_id,
      current_page: inLandingServicesDataState.currentPage,
      page_size: Number(rowsPerPage)
    },
    queryOptions: {
      enabled: !!inLandingServicesDataState.currentPage
    }
  })

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: inBoardServicesData?.total_pages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  useEffect(() => {
    dispatch({
      type: 'set-in-landing-services-current-page',
      payload: {
        currentPage
      }
    })
  }, [currentPage])

  useEffect(() => {
    const debounce = setTimeout(() => {
      dispatch({
        type: 'set-in-landing-services-data',
        payload: {
          inLandingServiceData: {
            protocol: protocol,
            delivery_date: deliveryDate,
            delivery_hour: deliveryHour,
            shipping: shipping,
            board_tracker: boardTracker,
            board_volume: boardVolume,
            source_branch: sourceBranch,
            destination_branch: destinationBranch,
            customer: customer,
            source_city: sourceCity,
            destination_city: destinationCity,
            collector: collector
          }
        }
      })

      setCurrentPage(1)
    }, 1000)

    return () => clearTimeout(debounce)
  }, [
    protocol,
    deliveryDate,
    deliveryHour,
    shipping,
    boardTracker,
    boardVolume,
    sourceBranch,
    destinationBranch,
    customer,
    sourceCity,
    destinationCity,
    collector,
    setCurrentPage
  ])

  const handleChangePage = (page: number) => setCurrentPage(page)

  const queryClient = useQueryClient()

  const handleRefetchTableData = async () => {
    await queryClient.invalidateQueries(['inLandingServices'])
  }
  const userHasStartLandingServicePermission =
    userLogged?.permissions.includes('add-landing-service')

  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >
      <Flex
        gap={4}
        direction='column'
      >
        <Heading size='md'>Serviços em Desembarque</Heading>

        <Divider />

        <Flex w={['full', 'full', 'min']} gap={2}>
          <TableFilterButton />
          <Button colorScheme="blue" onClick={handleRefetchTableData}>
            <Icon as={FaUndoAlt} />{' '}
          </Button>
        </Flex>

        {filterOptions
          .filter(option => option.checked)
          .map(option => {
            return (
              <Fragment key={option.key}>
                {option.key === 'delivery_date' ? (
                  <Input
                    {...register('delivery_date')}
                    name='delivery_date'
                    label='Data da entrega'
                    type='date'
                    size='sm'
                  />
                ) : (
                  option.key === 'delivery_hour' ? (
                    <Input
                      {...register('delivery_hour')}
                      name='delivery_hour'
                      label='Horário da entrega'
                      type='time'
                      size='sm'
                    />
                  ) : (
                    <Input
                      {...register(option.key as keyof InLandingServicesFilterInputs)}
                      name={option.key}
                      label={`Buscar ${option.value}`}
                      size='sm'
                      placeholder='Buscar...'
                    />
                  )
                )}
              </Fragment>
            )
          })}

        {isFetchingInLandingServicesData ? (
          <Spinner />
        ) : (
          <TableContainer>
            <Table size='sm' variant='striped'>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                  {inLandingServicesListFilterOptions.map(option => {
                    return (
                      <Th key={option.key}>{option.value.toLocaleUpperCase()}</Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {inBoardServicesData?.services?.map(service => {

                  const deliveryDelayOccurrences = [
                    'ATRASO NA ENTREGA',
                    'CANCELAMENTO DE VOO',
                    'CORTE DE VOO (NÃO ALOCADO VOO PLANEJADO)',
                    'ATRASO NA TRANSFERÊNCIA',
                    'ATRASO NA LIBERAÇÃO'
                  ]

                  const serviceDeliveryDelayOccurrence =
                    service.occurrenceIDService
                      .filter(occurrence => deliveryDelayOccurrences.includes(occurrence.intercurrence))
                      .reduce((acc, curr) => {
                        return new Date(acc.createdAt) > new Date(curr.createdAt) ? acc : curr
                      }, {} as Occurrence)

                  const deliveryDate = serviceDeliveryDelayOccurrence?.occurrence_date
                    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_date, "DateWithoutHourToShow")
                    : formatDate.handle(service.serviceIDRequested.delivery_date, "DateWithoutHourToShow")

                  const deliveryHour = serviceDeliveryDelayOccurrence?.occurrence_hour
                    ? formatDate.handle(serviceDeliveryDelayOccurrence.occurrence_hour, "DateOnlyWithHourMinute")
                    : formatDate.handle(service.serviceIDRequested.delivery_hour, "DateOnlyWithHourMinute")

                  let tracker = '-'
                  let validatedBoardVolume = '-'
                  let shipping = '-'
                  let sourceBranch = '-'
                  let destinationBranch = '-'
                  let destinationCollector = '-'

                  let trackerLink = null

                  const serviceHasCrossdocking = service.serviceIDRequested.crossdocking_collector_id !== null

                  let isServiceDelayed = false

                  if (serviceHasCrossdocking) {
                    const crossdockingBoardAlreadyFinished = service.serviceIDLanding.find(landing => {
                      return ['DONE', 'VALIDATING'].includes(landing.step) && landing.collector_id === service.serviceIDRequested.crossdocking_collector_id
                    })

                    const isBoardDateBeforeToToday = isBefore(
                      new Date(service.serviceIDRequested.board_date),
                      new Date()
                    )

                    const isBoardDateSameToToday = isSameDay(
                      new Date(service.serviceIDRequested.board_date),
                      new Date()
                    )

                    if (!isBoardDateSameToToday && isBoardDateBeforeToToday) {
                      isServiceDelayed = true
                    } else if (isBoardDateSameToToday) {
                      const [boardHours, boardMinutes] = format(new Date(service.serviceIDRequested.board_hour), "HH:mm").split(':').map(Number)

                      isServiceDelayed = differenceInMinutes(
                        set(new Date(), {
                          hours: boardHours,
                          minutes: boardMinutes
                        }), new Date()
                      ) < 240
                    }

                    if (crossdockingBoardAlreadyFinished) {
                      const currentBoardInfo = service.serviceIDBoard.find(board => {
                        return board.collector_id === service.serviceIDRequested.source_collector_id
                      })

                      tracker = String(currentBoardInfo?.operational_number)
                      validatedBoardVolume = String(currentBoardInfo?.board_volume)
                      shipping = service.shippings.find(ship => {
                        return ship.id === service.serviceIDRequested.sourceCrossdockingBranchIDService.shipping_id
                      })?.company_name
                      trackerLink = currentBoardInfo?.tracker_link
                      sourceBranch = service.serviceIDRequested.sourceCrossdockingBranchIDService.nickname
                      destinationBranch = service.serviceIDRequested.destinationBranchIDService.nickname
                      destinationCollector = service.serviceIDRequested.destinationCollectorIDService.trading_name
                    } else {
                      const currentBoardInfo = service.serviceIDBoard.find(board => {
                        return board.collector_id === service.serviceIDRequested.crossdocking_collector_id
                      })

                      tracker = String(currentBoardInfo?.operational_number)
                      validatedBoardVolume = String(currentBoardInfo?.board_volume)
                      shipping = service.shippings.find(ship => {
                        return ship.id === service.serviceIDRequested.sourceBranchIDService.shipping_id
                      })?.company_name
                      trackerLink = currentBoardInfo?.tracker_link
                      sourceBranch = service.serviceIDRequested.sourceBranchIDService.nickname
                      destinationBranch = service.serviceIDRequested.destinationCrossdockingBranchIDService.nickname
                      destinationCollector = service.serviceIDRequested.crossdockingCollectorIDService.trading_name
                    }
                  } else {
                    const currentBoardInfo = service.serviceIDBoard.find(board => {
                      return board.collector_id === service.serviceIDRequested.source_collector_id
                    })
                    tracker = String(currentBoardInfo?.operational_number)
                    validatedBoardVolume = String(currentBoardInfo?.board_volume)
                    shipping = service.shippings.find(ship => {
                      return ship.id === service.serviceIDRequested.sourceBranchIDService.shipping_id
                    })?.company_name
                    trackerLink = currentBoardInfo?.tracker_link
                    sourceBranch = service.serviceIDRequested.sourceBranchIDService.nickname
                    destinationBranch = service.serviceIDRequested.destinationBranchIDService.nickname
                    destinationCollector = service.serviceIDRequested.destinationCollectorIDService.trading_name
                  }

                  let tableLineColor = ''

                  const isCurrentDateBiggerThanDeliveryForecastDate =
                    compareDeliveryForecastDateWithDateNow(
                      service.serviceIDRequested.delivery_date,
                      service.serviceIDRequested.delivery_hour
                    )

                  if (
                    serviceDeliveryDelayOccurrence.id
                  ) {
                    tableLineColor = 'yellow.300'
                  } else if (isCurrentDateBiggerThanDeliveryForecastDate) {
                    tableLineColor = 'red.300'
                  }

                  return (
                    <Tr
                      key={service.id}
                      color={tableLineColor}
                    >
                      <Td>
                        {service?.serviceIDRequested?.budgetIDService?.priority_budget && (
                          <Icon as={FaExclamation} color={'red.500'} />
                        )}
                        {isServiceDelayed && (
                          <Icon as={FaClock} color={'red.500'} />
                        )}
                      </Td>
                      <Td>
                        <Flex>
                          {(userHasStartLandingServicePermission) && (
                            <Button
                              as={Link}
                              to={`/servicos/desembarques/${service.id}`}
                              variant='unstyled'
                            >
                              <Flex w='full' h='full' align='center' justify='center'>
                                <Icon as={FaPen} />
                              </Flex>
                            </Button>
                          )}
                          <Button
                            as={Link}
                            to={`/servico/detalhes/${service.id}`}
                            variant='unstyled'
                          >
                            <Flex w='full' h='full' align='center' justify='center'>
                              <Icon as={FaEye} />
                            </Flex>
                          </Button>
                        </Flex>
                      </Td>
                      <Td>{service.protocol}</Td>
                      <Td>{deliveryDate}</Td>
                      <Td>{deliveryHour}</Td>
                      <Td>{shipping}</Td>
                      <Td>{
                        trackerLink ?
                          <ChakraLink href={trackerLink} isExternal>
                            {tracker}
                          </ChakraLink>
                          : tracker}</Td>
                      <Td>{validatedBoardVolume}</Td>
                      <Td>{sourceBranch}</Td>
                      <Td>{destinationBranch}</Td>
                      <Td>{service.customerIDService.trading_firstname}</Td>
                      <Td>{Array.from(new Set(service.source_cities.map(city => city.name))).join(', ')}</Td>
                      <Td>{Array.from(new Set(service.destination_cities.map(city => city.name))).join(', ')}</Td>
                      <Td>{destinationCollector}</Td>
                    </Tr>
                  )
                })}

              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />

      </Flex>
    </Box>


  )
}

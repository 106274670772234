import axios from "axios"
import { api } from "services/api"
import { RequestError } from "utils/errors/RequestErrors"

interface DeleteBillingParams {
  billingId: string
}


export async function deleteBilling({
  billingId,
}: DeleteBillingParams) {
  try {
    await api.delete(`/billings/${billingId}`)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

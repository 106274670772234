import { Box, FormLabel, Text, VStack } from "@chakra-ui/react";
import { getSupportSubject } from "api/supports/getSupportSubject";
import { AttachmentTableRowField } from "pages/Support/AttachmentTableRowField";
import { useEffect, useReducer } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { CreateSupportSchema } from "../CreateSupport";
import { AttachmentState, SetAttachmentAction } from "../InternalSupportForm";
import { CreateEmailForm } from "./CreateEmailForm";
import { CreatePipefyAccessForm } from "./CreatePipefyAccessForm";
import { DefaultSupportTIForm } from "./DefaultSupportTIForm";
import { NewEntrantForm } from "./NewEntrantForm";
import { TerminationForm } from "./TerminationForm";

interface CreateSupportTIProps {
  reducer: (state: AttachmentState[], action: SetAttachmentAction) => AttachmentState[];
}

export function CreateSupportTI({ reducer }: CreateSupportTIProps) {

  const { control, setValue } = useFormContext<CreateSupportSchema>()
  const [attachments, setAttachments] = useReducer(reducer, [] as AttachmentState[])

  useEffect(() => {
    if (attachments.length !== 0) {
      setValue(`formData.attachments.label`, 'Anexos');
      setValue(`formData.attachments.value`, JSON.stringify(attachments));
      setValue(`formData.attachments.type`, 'attachments');
    }
  }, [attachments, setValue])

  const {
    fields: attachmentsFields,
    append: appendAttachmentsField,
    remove: removeAttachmentsField,
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const [supportSubjectId, attachmentsSelected] = useWatch({
    control,
    name: ['subject', 'attachments']
  })

  const {
    data: supportSubjectResult
  } = useQuery({
    queryFn: () => getSupportSubject({ supportSubjectId }),
    queryKey: ['support-subject', supportSubjectId],
    enabled: !!supportSubjectId
  })



  const subjectIsDefaultSupportTI = ['Internet', 'Servidor Rede', 'E-mail (Correção ou Melhoria)', 'Pipefy (Correção ou Melhoria)', 'Vuupt', 'Rota Exata', 'Telefonia', 'Microsoft Team', 'Outro', 'Assinatura de e-mail'].map(item => item.toLowerCase()).includes(supportSubjectResult?.supportSubject?.name.toLowerCase())
  const subjectIsCreateEmail = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Criar e-mail'.toLowerCase()
  const subjectIsCreatePipefyAccess = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Criar acesso pipefy'.toLowerCase()
  const subjectIsNewEntrant = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Novo Entrante'.toLowerCase()
  const subjectIsTermination = supportSubjectResult?.supportSubject?.name.toLowerCase() === 'Desligamento'.toLowerCase()


  return (
    <Box w='full'>

      {subjectIsDefaultSupportTI && (
        <DefaultSupportTIForm />
      )}

      {subjectIsCreateEmail && (
        <CreateEmailForm />
      )}

      {subjectIsCreatePipefyAccess && (
        <CreatePipefyAccessForm />
      )}

      {subjectIsNewEntrant && (
        <NewEntrantForm />
      )}

      {subjectIsTermination && (
        <TerminationForm />
      )}


      {(subjectIsDefaultSupportTI || subjectIsCreateEmail || subjectIsCreatePipefyAccess || subjectIsNewEntrant) && (
        <>
          <FormLabel fontSize='sm' mt={3}>
            {subjectIsNewEntrant ? 'Foto de rosto para a assinatura' : 'Anexo(s)'}


            <Text as="sup" color="red.500">*</Text>
          </FormLabel><VStack spacing={3}>
            {attachmentsFields.map((field, index) => {

              const isFilledField = Boolean(attachmentsSelected[index]?.file?.length);
              return (
                <AttachmentTableRowField
                  key={field.id}
                  index={index}
                  onSetAttachmentId={setAttachments}
                  isFilledField={isFilledField}
                  onAppendField={() => appendAttachmentsField({})}
                  onRemoveField={() => removeAttachmentsField(index)} />
              );
            })}
          </VStack>
        </>
      )}

    </Box>
  )
}

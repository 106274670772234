import { DocumentStatus, DocumentRequestTypes } from "api/documents/types/Document";

export const documentStatusMap: Record<DocumentStatus, string> = {
  "non-evaluated": 'Não avaliado',
  active: 'Ativo',
  expired: 'Vencido',
  inactive: 'Inativo'
}

export const documentRequestType : Record<DocumentRequestTypes, string> = {
  creation: 'Criação',
  alteration: 'Alteração'
}

import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { GetOnboardingsResponse } from "api/onboardings/getOnboardings";
import { validateTestOnboarding } from "api/onboardings/validateTestOnboarding";
import { useToastify } from "hooks/toastify/useToastify";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";


interface TestingOnboardingOnboardingProps {
  onboardingId: string
}

interface TestingOnboardingSchema {
  llmTrainingThemes: {
    theme: string
    status: string
  }[]
}

export function TestingOnboarding({ onboardingId }: TestingOnboardingOnboardingProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    register,
    control,
    handleSubmit,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<TestingOnboardingSchema>({
    defaultValues: {
      llmTrainingThemes: Array.from({ length: 2 }).map((_, index) => {
        return {
          theme: '',
          status: ''
        }
      })
    }
  })

  const {
    fields: themesFields,
  } = useFieldArray({
    control,
    name: 'llmTrainingThemes'
  })

  const { mutateAsync: validateTestOnboardingFn } = useMutation({
    mutationFn: validateTestOnboarding,
    onSuccess(_data, { body, onboardingId }) {
      const cachedOnboardings = queryClient.getQueriesData<GetOnboardingsResponse>({
        queryKey: ['onboardings']
      })
      queryClient.invalidateQueries(['onboardings'])
      queryClient.invalidateQueries({ queryKey: 'inviting-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'training-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'testing-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'finished-onboardings' })
      queryClient.invalidateQueries({ queryKey: 'inactive-onboardings' })
      queryClient.invalidateQueries({ queryKey: ['onboarding', onboardingId]})

      cachedOnboardings.forEach(([cachedKey, cachedData]) => {
        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          onboardings: cachedData.onboardings.map((onboarding) => {
            if (onboarding.id === onboardingId) {
              return {
                ...onboarding,
              }
            }

            return onboarding
          })
        })
      })


    }
  })

  async function handleTestingOnboarding({ llmTrainingThemes }: TestingOnboardingSchema) {
    await promiseMessage(validateTestOnboardingFn({
      body: {
        llmTrainingThemes: llmTrainingThemes
      },
      onboardingId: onboardingId
    }), 'Onboarding validado! 🎉')
  }

  return (
    <Box
      w="full"
      as="form"
      maxH='500px'
      overflowY='scroll'
      onSubmit={handleSubmit(handleTestingOnboarding)}
    >
      <Heading letterSpacing="tight" size='sm'>Teste da cumbuca</Heading>
      <Flex
        direction="column"
        gap="1"
        w="full"
        mt="6"
      >
        {themesFields.map((field, index) => {
          return (
            <Stack
              w='full'
              gap={6}
              direction={['row', 'row', 'row']}
              key={field.id}
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl
                  isInvalid={!!errors?.llmTrainingThemes &&
                    errors?.llmTrainingThemes[index].theme ? true : false}
                >
                  <FormLabel fontSize="sm">
                    Tema {index + 1}
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>
                  <Input
                    {...register(`llmTrainingThemes.${index}.theme`)}
                    name={`llmTrainingThemes.${index}.theme`}
                    size="sm"
                    rounded="md"

                  />
                </FormControl>
              </Stack>
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormLabel>
                  Status
                  <Text ml="1" textColor="red.500" as="sup">*</Text>
                </FormLabel>

                <FormControl
                  isInvalid={!!errors?.llmTrainingThemes &&
                    errors?.llmTrainingThemes[index].status ? true : false}
                >
                  <Controller
                    {...register(`llmTrainingThemes.${index}.status`)}
                    name={`llmTrainingThemes.${index}.status`}
                    control={control}
                    render={({ field }) => {
                      return (
                        <RadioGroup
                          size="sm"
                          onChange={field.onChange}
                          value={field.value}
                          name={field.name}
                        >
                          <Stack direction="row">
                            <Radio
                              autoFocus={!!errors?.llmTrainingThemes &&
                                errors?.llmTrainingThemes[index].status ? true : false}
                              value="approved"
                            >Aprovado</Radio>
                            <Radio
                              autoFocus={!!errors?.llmTrainingThemes &&
                                errors?.llmTrainingThemes[index].status ? true : false}
                              value="reproved"
                            >
                              Reprovado
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )
                    }}
                  />
                </FormControl>
              </Stack>

            </Stack>
          )
        })}
      </Flex>
      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

const handleSwitchMask = (mask: string) => {
  switch (mask) {
    case 'cpf':
      return '***.***.***-**'
    case 'cnpj':
      return '**.***.***/****-**'
    case 'phone':
      return '(**)****-****'
    case 'mixedPhone':
      return '(**)****-*****'
    case 'cellphone':
      return '(**)*****-****'
    case 'cep':
      return '*****-***'
    case 'rg':
      return '**.***.***.*'
    default:
      return ''
  }
}

export { handleSwitchMask }

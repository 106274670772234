import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { Collector } from "hooks/collector/dtos/Collector";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Carrier, CarrierStatus } from "./_types/Carrier";

export interface GetCarriersReportParams {
  status?: CarrierStatus | 'all'
}

export interface GetCarriersReportResponse {
  carriers: Array<Carrier & {
    collector: Collector
    dtVaccineDoseAttachment: Attachment
    hepatiteBVaccineDoseAttachment: Attachment
    tripliceViralVaccineDoseAttachment: Attachment
    cnhAttachment: Attachment
    crlvAttachment: Attachment
    anttMotorFreightAttachment: Attachment
    vehicleSidePhotoAttachment: Attachment
    vehicleRearPhotoAttachment: Attachment
    vehicleInternPhotoAttachment: Attachment
  }>
  meta: {
    totalPages: number
  }
}


export async function getCarriersReport({
  status
}: GetCarriersReportParams) {
  try {
    const response = await api.get<GetCarriersReportResponse>('/carriers/report', {
      params: {
        status
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

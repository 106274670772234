import { Button, Flex, IconButton, Input, Select, useMediaQuery } from "@chakra-ui/react";
import { AddressesWithRequestedChangesStatus } from "api/addresses/addressRequestedChange";
import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface AddressesWithRequestedChangesTableFilterSchema {
  status: 'pending' | 'approving' | 'finished' | 'all'
  customer: string
}

export function AddressesWithRequestedChangesTableFilters() {
  const { userLogged } = useAuth()
  const [isLargerThen768, isSmallerThen1200] = useMediaQuery(['(min-width: 768px)', '(max-width: 1200px)'])

  const searchParams = useSearchParams()
  const history = useHistory()

  const customer = searchParams.get('customer')
  const status = searchParams.get('status')

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<AddressesWithRequestedChangesTableFilterSchema>({
    defaultValues: {
      status: status as AddressesWithRequestedChangesStatus | 'all' ?? 'all',
      customer
    }
  })

  function handleFilterAddressesWithRequestedChanges(
    values: AddressesWithRequestedChangesTableFilterSchema
  ) {
    if (values.customer) {
      searchParams.set('customer', values.customer)
    } else {
      searchParams.delete('customer')
    }

    if (values.status) {
      searchParams.set('status', values.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })
  }

  function handleClearFilters() {
    searchParams.delete('customer')
    searchParams.delete('status')

    searchParams.set('page', '1')

    history.replace({ search: searchParams.toString() })

    reset({
      customer: '',
      status: 'all'
    })
  }

  return (
    <Flex
      mt="6"
      as="form"
      gap="2"
      direction={{ base: "column", md: "row" }}
      onSubmit={handleSubmit(handleFilterAddressesWithRequestedChanges)}
    >
      <Input
        {...register('customer')}
        placeholder="Cliente"
        size="sm"
        rounded="md"
        isDisabled={userLogged?.user_type === 'CLIENTE'}
        w={{ base: "full", md: '250px' }}
      />

      <Select
        {...register('status')}
        size="sm"
        defaultValue="all"
        rounded="md"
        w={{ base: "full", md: '250px' }}
      >
        <option value="pending">Pendente</option>
        <option value="approving">Aprovando</option>
        <option value="finished">Finalizado</option>
        <option value="all">Todos status</option>
      </Select>
      {isLargerThen768 && isSmallerThen1200 ? (
        <IconButton
          aria-label="Filtrar resultados"
          type="submit"
          size="sm"
          icon={<FaSearch />}
        />
      ) : (
        <Button
          type="submit"
          size="sm"
          leftIcon={<FaSearch />}
        >
          Filtrar resultados
        </Button>
      )}

      {isLargerThen768 && isSmallerThen1200 ? (
        <IconButton
          aria-label="Remover filtros"
          type="button"
          size="sm"
          variant="ghost"
          onClick={handleClearFilters}
          icon={<FaTimes />}
        />
      ) : (
        <Button
          size="sm"
          onClick={handleClearFilters}
          leftIcon={<FaTimes />}
          variant="ghost"
        >
          Remover Filtros
        </Button>
      )}


    </Flex>
  )

}

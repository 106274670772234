import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Select, Stack, Text } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useToastify } from "hooks/toastify/useToastify";
import { sendCltDriverApproveDismiss } from "api/cltDrivers/sendCltDriverApproveDismiss";
import { set } from "date-fns";

interface SendCltDriverApproveDismissProps {
  cltDriverId: string
}

export interface SendCltDriverApproveDismissSchema {
  dismissDate: string
  dismissionType: string
  isApprovedDismission: string
  isLegalRiskControlled: string
  noticeType: string
}

const sendCltDriverApproveDismissSchema = yup.object({
  dismissDate: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),
  dismissionType: yup.string().required(),
  isApprovedDismission: yup.string().required(),
  isLegalRiskControlled: yup.string().required(),
  noticeType: yup.string().required(),
})

const dismissionOptions = [
  { name: 'Término contrato de experiência' },
  { name: 'Sem justa causa' },
  { name: 'Com justa causa' },
  { name: 'Pedido de demissão pelo colaborador' },
  { name: 'Acordo entre partes' },
]

const noticeTypeOptions = [
  { name: 'Trabalhado' },
  { name: 'Indenizado' },
  { name: 'Descontado' }
]


export function SendCltDriverApproveDismiss({ cltDriverId }: SendCltDriverApproveDismissProps) {

  const queryClient = useQueryClient()
  const { promiseMessage } = useToastify()

  const {
    handleSubmit,
    control,
    register,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<SendCltDriverApproveDismissSchema>({
    resolver: yupResolver(sendCltDriverApproveDismissSchema)
  })

  const { mutateAsync: sendCltDriverApproveDismissFn } = useMutation({
    mutationFn: sendCltDriverApproveDismiss,
    onSuccess(_data, { body, cltDriverId }) {
      queryClient.invalidateQueries({ queryKey: 'clts-drivers' })
      queryClient.invalidateQueries({ queryKey: ['clt-driver', cltDriverId] })
    }
  })


  async function handleSendCltDriverApproveDismiss(values: SendCltDriverApproveDismissSchema) {
    await promiseMessage(sendCltDriverApproveDismissFn({
      body: {
        ...values,
        isApprovedDismission: values.isApprovedDismission === 'yes',
        isLegalRiskControlled: values.isLegalRiskControlled === 'yes',
      }, cltDriverId
    }), 'Aprovação de demissão')
  }

  return (
    <Box
      w="full"
      as="form"
      onSubmit={handleSubmit(handleSendCltDriverApproveDismiss)}
      ml={3}
    >
      <Heading letterSpacing="tight" size='sm'>Demissão - Aprovação</Heading>
      <FormControl isInvalid={!!errors.isLegalRiskControlled} mt={3}>
        <FormLabel fontSize='sm'>
          Risco jurídico controlado ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel >
        <Controller
          name="isLegalRiskControlled"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.dismissionType} mt={3}>
        <FormLabel fontSize="sm">
          Tipo de demissão
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('dismissionType')}
          name="dismissionType"
          placeholder="Selecione..."
          rounded="md"
          size='sm'
        >
          {dismissionOptions.map((dismiss) => {
            return (
              <option key={dismiss.name} value={dismiss.name}>{dismiss.name}</option>
            )
          })}

        </Select>

      </FormControl>
      <FormControl isInvalid={!!errors.noticeType} mt={3}>
        <FormLabel fontSize="sm">
          Tipo de aviso prévio
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Select
          {...register('noticeType')}
          name="noticeType"
          placeholder="Selecione..."
          rounded="md"
          size='sm'
        >
          {noticeTypeOptions.map((notice) => {
            return (
              <option key={notice.name} value={notice.name}>{notice.name}</option>
            )
          })}

        </Select>

      </FormControl>

      <FormControl isInvalid={!!errors.isApprovedDismission} mt={3}>
        <FormLabel fontSize='sm'>
          Demissão aprovada ?
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Controller
          name="isApprovedDismission"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="row">
                <Radio size='sm' rounded='md' value="yes">Sim</Radio>
                <Radio size='sm' rounded='md' value="no">Não</Radio>
              </Stack>
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl isInvalid={!!errors?.dismissDate} mt={3}>
        <FormLabel fontSize="sm">
          Data da demissão:
          <Text as="sup" color="red.500">*</Text>
        </FormLabel>
        <Input
          {...register('dismissDate')}
          name="dismissDate"
          type="date"
          rounded="md"
          size='sm'
        />
      </FormControl>

      <Flex
        mt="6"
        w="full"
        justify="flex-end"
      >
        <Button
          type="submit"
          size="sm"
          colorScheme="blue"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  )
}

export const timezonesUsed = {
  "UTC-2": {
    timezone: "America/Noronha"
  },
  "UTC-3": {
    timezone: "America/Sao_paulo"
  },
  "UTC-4": {
    timezone: "America/Porto_velho"
  },
  "UTC-5": {
    timezone: "America/Rio_branco"
  },
}

export type TimezonesFormat = keyof typeof timezonesUsed

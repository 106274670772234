import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface CompleteLlmHumanResourcesAuditBody {
  realizedDate: string
  isTimeCardRecordedCorrectly: boolean
  isFinishedOnboarding: boolean
  isWithAso: boolean
  isOpenedSalaryAccount: boolean
  nonCompliance?: string
}

interface CompleteLlmHumanResourcesAuditParams {
  body: CompleteLlmHumanResourcesAuditBody
  auditId: string
}

export async function completeLlmHumanResourcesAudit({
  body,
  auditId
}: CompleteLlmHumanResourcesAuditParams) {
  try {
    await api.put(`/audits/${auditId}/llm-human-resources`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

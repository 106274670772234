
import axios from "axios";
import { Attachment } from "hooks/attachment/dtos/Attachment";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { Document } from "./types/Document";

interface GetDocumentByPagePathnameResponse {
  documentAttachment: {
    id: string
    document: Document
    version: number
    created_at: string
    updated_at: string
    createdAt: string
    updatedAt: string
    attachment_id: string
    document_id: string
    attachment: Attachment
  }
}

interface GetDocumentByPagePathnameParams {
  relatedPagePathname: string
}

export async function getDocumentByPagePathname({
  relatedPagePathname
}: GetDocumentByPagePathnameParams) {
  try {
    const response = await api.get<GetDocumentByPagePathnameResponse>(`/pages/documents`, {
      params: {
        relatedPagePathname
      }
    })

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

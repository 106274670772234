import * as yup from "yup"
import { Button, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Select } from "components/Inputs/SelectInput";
import { useForm } from "react-hook-form";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";

interface LoginCollectorSelectorModalProps {
  isOpen: boolean
  onClose: () => void
  userCollectors: { id: string, trading_name: string }[]
}

interface SelectLoggedUserCollectorInputs {
  collectorId: string
}

const selectLoggedUserCollectorSchema = yup.object().shape({
  collectorId: yup.string().required('Campo obrigatório')
})

export function LoginCollectorSelectorModal({
  isOpen,
  onClose,
  userCollectors,
}: LoginCollectorSelectorModalProps) {
  const { handleSetUserLoggedCollectorId } = useAuth()

  const {
    register,
    handleSubmit,
    formState: {
      errors,
    }
  } = useForm<SelectLoggedUserCollectorInputs>({
    resolver: yupResolver(selectLoggedUserCollectorSchema)
  })

  const userCollectorsOptions = userCollectors?.map((userCollector) => {
    return {
      key: userCollector.id,
      value: userCollector.id,
      showOption: userCollector.trading_name
    }
  })


  console.log(userCollectorsOptions)

  const { successMessage } = useToastify()

  function handleSubmitForm(values: SelectLoggedUserCollectorInputs) {
    handleSetUserLoggedCollectorId(values.collectorId)

    successMessage('Cliente selecionado com sucesso!')
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Selecione um cliente
        </ModalHeader>
        <ModalBody
          p="6"
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          noValidate
        >
          <Text mb="4" textAlign="left">Olá!
          <br />
          <br />
          Antes de iniciar sua sessão, é necessário que selecione de qual coletador deseja que as informações sejam visualizadas!</Text>
          <Select
            {...register('collectorId')}
            name="collectorId"
            label="Cliente"
            placeholder="Selecione um coletador..."
            options={userCollectorsOptions}
            error={errors.collectorId}
            required
          />

          <Flex
            mt="4"
            align="center"
            justify="flex-end"
          >
            <Button type="submit" colorScheme="blue">Salvar</Button>
          </Flex>
        </ModalBody>
      </ModalContent>


    </Modal>
  )
}

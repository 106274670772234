import { usePagination } from "@ajna/pagination";
import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { TrainingLlmStatus as TrainingLlmStatusTypes } from "api/trainingLlm/trainingLlm";
import { Pagination } from "components/Pagination/Pagination";
import { AppLayout } from "_layouts/AppLayout";
import { format } from "date-fns";
import { useSearchParams } from "hooks/useSearchParams";
import { useQuery } from "react-query";
import { trainingLlmMStatusMap, TrainingLlmStatus } from "./components/TrainingLlmStatus";
import { TrainingsLlmReportFilters } from "./components/TrainingsLlmReportFilters";
import { getTrainingsLlmReport } from "api/trainingLlm/getTrainingsLlmReport";


const headers = [
  { label: 'Colaborador', key: 'collaborator.name' },
  { label: 'Treinamento', key: 'training' },
  { label: 'Status', key: 'status' },
  { label: 'Data limite para realização', key: 'due_date' },
  { label: 'Data da aprovação', key: 'approved_at' },
  { label: 'Data da reprovação', key: 'reproved_at' },
]

export function TrainingsLlmReport() {
  const searchParams = useSearchParams()

  const status = searchParams.get('status') ?? 'all'
  const collaboratorId = searchParams.get('collaboratorId')
  const limitDate = searchParams.get('limitDate')

  const {
    data: trainingsReportData
  } = useQuery({
    queryKey: ['trainings-llm-report', status, collaboratorId, limitDate],
    queryFn: () => getTrainingsLlmReport({
      collaboratorId,
      status: status as TrainingLlmStatusTypes | 'all',
      limitDate
    }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: !!collaboratorId || !!status || !!limitDate
  })

  const csvProps = {
    data: trainingsReportData ? trainingsReportData?.llmTrainings.map(training => {
      return {
        ...training,
        training: training.training,
        status: trainingLlmMStatusMap[training.status],
        due_date: format(new Date(training.due_date), 'dd/MM/yyyy'),
        approved_at: training.approved_at ? format(new Date(training.approved_at), 'dd/MM/yyyy') : '-',
        reproved_at: training.reproved_at ? format(new Date(training.reproved_at), 'dd/MM/yyyy') : '-',
      }
    })
      : [],
    headers,
    filename: `relatório-treinamentos-llm-${new Date().toISOString()}.csv`,
  }

  const {
    pages,
    currentPage,
    pagesCount,
    setCurrentPage,
    offset,
  } = usePagination({
    limits: {
      inner: 1,
      outer: 1
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handleChangePage = (page: number) => setCurrentPage(page)

  return (
    <>
      <Box
        p="6"
        bg="white"
        rounded="md"
      >
        <Heading letterSpacing="tight" >Relatório de Treinamentos</Heading>

        <TrainingsLlmReportFilters csvProps={csvProps} />

        {trainingsReportData && (
          <TableContainer mt="6">
            <Table
              size="sm"
            >
              <Thead>
                <Tr>
                  <Th>Colaborador</Th>
                  <Th>Treinamento</Th>
                  <Th>Status</Th>
                  <Th>Data limite pra realização</Th>
                  <Th>Data da aprovação</Th>
                  <Th>Data da reprovação</Th>
                </Tr>
              </Thead>

              <Tbody>
                {trainingsReportData?.llmTrainings.slice(offset, offset + 10).map((training) => {
                  return (
                    <Tr key={training.id}>
                      <Td>{training.collaborator.name}</Td>
                      <Td>{training.training}</Td>
                      <Td>
                        <TrainingLlmStatus status={training.status} />
                      </Td>
                      <Td>
                        {training.due_date ? format(new Date(training.due_date), 'dd/MM/yyyy') : '-'}
                      </Td>
                      <Td>
                        {training.approved_at ? format(new Date(training.approved_at), 'dd/MM/yyyy') : '-'}
                      </Td>
                      <Td>
                        {training.reproved_at ? format(new Date(training.reproved_at), 'dd/MM/yyyy') : '-'}
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Pagination
          handlePageChange={handleChangePage}
          pagesQuantity={pagesCount}
          pages={pages}
          currentPage={currentPage}
        />


      </Box>
    </>
  )
}

import { Box, Button, Flex, Heading, IconButton } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormProvider, useForm } from "react-hook-form";
import { BusinessCostForm, BusinessCostFormSchema } from "./components/BusinessCostForm";
import * as yup from "yup";
import { useMutation } from "react-query";
import { createBusinessCost } from "api/businessCosts/createBusinessCost";
import { transformStringToNumber } from "utils/GeneralFunctions/FormatValuesFuntions/transformStringToNumber";
import { set } from "date-fns";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";

const createBusinessCostSchema = yup.object({
  collectors: yup.array().min(1).of(yup.object({
    collectorId: yup.string().required()
  })),
  cost: yup.number().required().transform((value, originalValue, ctx) => {
    if (ctx.isType(value)) return null

    value = Math.ceil(transformStringToNumber(originalValue) * 100)

    return value
  }),
  customerId: yup.string().required(),
  startDate: yup.date().required().transform((value, originalValue, ctx) => {
    if (!ctx.isType(value)) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 })

    return value
  }),
  situation: yup.string().required(),
  endDate: yup.mixed().nullable().when('situation', {
    is: 'inactive',
    then: yup.date().required().transform((value, originalValue, ctx) => {
      if (!ctx.isType(value)) return null

      const [year, month, day] = originalValue?.split('-').map(Number)
      value = set(new Date(), { date: day, month: month - 1, year })

      return value
    }),
    otherwise: yup.string().nullable().transform((value) => {
      value = null

      return value
    })
  }),
  businessBudgetId: yup.string().required(),
  observations: yup.string().nullable(),
})

interface TransformedBusinessCostSchema {
  startDate: Date
  endDate?: Date | null
  cost: number
  observations?: string | null
  businessBudgetId: string
  collectors: {
    collectorId: string
  }[]
}

export function CreateBusinessCost() {
  const { userLogged } = useAuth()
  const history = useHistory()

  const userCanCreateBusinessCost = userLogged?.permissions.includes('create-business-cost')

  useEffect(() => {
    if (!userCanCreateBusinessCost) history.push('/')
  },[history, userCanCreateBusinessCost])

  const formMethods = useForm<BusinessCostFormSchema>({
    resolver: yupResolver(createBusinessCostSchema),
    defaultValues: {
      collectors: [{ collectorId: '' }],
      situation: 'active'
    }
  })

  const { mutateAsync: createBusinessCostFn } = useMutation({
    mutationFn: createBusinessCost,
    onSuccess: () => {
      toast.success('Custo business criado com sucesso!')
      history.push('/custos/business')
    }
  })

  async function handleCreateBusinessCost(values: TransformedBusinessCostSchema) {
    await createBusinessCostFn({
      body: {
        ...values,
        collectorsIds: values.collectors.map((collector) => collector.collectorId)
      },
      params: {
        businessBudgetId: values.businessBudgetId
      }
    })
  }

  return (

      <Box
        p="6"
        bg="white"
        rounded="md"
      >

        <Flex align="center" gap="2">
          <IconButton
            icon={<FaArrowLeft />}
            aria-label="Voltar"
            size="sm"
            variant="ghost"
            onClick={() => history.goBack()}
          />
          <Heading letterSpacing="tight" fontSize="2xl">Criar custo business</Heading>
        </Flex>

        <FormProvider {...formMethods}>
          <Box
            as="form"
            onSubmit={formMethods.handleSubmit(handleCreateBusinessCost)}
          >
            <BusinessCostForm />

            <Flex
              w="full"
              justify="end"
              mt="6"
            >
              <Button
                colorScheme="blue"
                type="submit"
              >
                Criar
              </Button>

            </Flex>
          </Box>
        </FormProvider>

      </Box>

  )
}

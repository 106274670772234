import { Divider, Flex, HStack, Stack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, NestedValue, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { BudgetProps } from '../../../../contexts/BudgetContext'
import { useBranch } from '../../../../hooks/branch/useBranch'
import { useBudget } from '../../../../hooks/budget/useBudget'
import { useCustomer } from '../../../../hooks/customer/useCustomer'
import { useContentLoading } from '../../../../hooks/loading/useContentLoading'
import { useAddresses } from '../../../../hooks/address/useAddresses'
import {
  days_of_week,
  deadline_delivery,
  modal_types,
  service_types,
  situation,
  vehicle_types,
} from '../../../../utils/customLists'
import { switchRequestedServiceMateryalTypes } from '../../../../utils/CustomLists/switchRequestedServiceMateryalTypes'
import { createRecurrentServiceSchema } from '../../../../validations/createRecurrentServiceSchema'
import { EmptyAddressesFormServiceAlert } from '../../../Alerts/EmptyAddressesFormServiceAlert'
import { ListButton } from '../../../Buttons/ListButton'
import { SubmitButton } from '../../../Buttons/SubmitButton'
import { CheckboxDaysWeek } from '../../../Inputs/CheckboxDaysWeek'
import { CheckboxService } from '../../../Inputs/CheckboxService'
import { Input } from '../../../Inputs/Input'
import { Select } from '../../../Inputs/SelectInput'
import { TextArea } from '../../../Inputs/TextInput'
import { GeneralContentLoading } from '../../../Loading/GeneralContentLoading'
import { StandardBackgroundForm } from '../../StandardBackgroundForm'
import { formatDate } from 'utils/DateFunctions/formatDate'
import { useCollectors } from 'hooks/collector/useCollectors'
import { Collector } from 'hooks/collector/dtos/Collector'
import { useProviders } from 'hooks/provider/useProviders'
import { useDriver } from 'hooks/driver/useDriver'
import { useCollector } from 'hooks/collector/useCollector'

interface IDestinationAddressesInputProps {
  address_id: string
  quantity: number
}

interface IAssignDriverInputProps {
  driver_id: string
  address_id: string
}

interface IAssignDriverProviderInputProps {
  driver_id: string
  provider_id: string
}

interface IFormInputProps {
  customer_id: string
  situation: string
  budget_id: string
  owner: string
  source_address_id: NestedValue<string[]>
  destination_address_id: NestedValue<string[]>
  source_collector_id: string
  destination_collector_id: string
  source_branch_id: string
  destination_branch_id: string
  provider_id: string
  availability_forecast_deadline: number | null
  availability_forecast_day: string
  availability_forecast_time: string
  board_hour: string
  board_date: number | null
  planned_flight: string
  deadline: number
  service_type: string
  franchising: number
  modal: string
  vehicle: string
  caixa_termica: number
  embalagem_secundaria: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  collect_date: string
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string
  delivery_hour: string
  days_of_week: NestedValue<number[]>
  material_type: string
  destination_addresses_input: IDestinationAddressesInputProps[]
  driver_address_assign: IAssignDriverInputProps[]
  driver_provider_assign: IAssignDriverProviderInputProps[]
  observation: string
}

interface IRecurrentServiceAddFormProps {
  isRequestLoading: boolean
  submit: (values: IFormInputProps) => Promise<void>
}

export function RecurrentServiceAddForm({
  submit,
  isRequestLoading,
}: IRecurrentServiceAddFormProps) {
  const [destinationAddressesInput, setDestinationAddressesInput] = useState<
    IDestinationAddressesInputProps[]
  >([])

  const [budgetsByCustomerId, setBudgetsByCustomerId] = useState<BudgetProps[]>(
    [],
  )
  const [sourceCollectors, setSourceCollectors] = useState<Collector[]>([])
  const [destinationCollectors, setDestinationCollectors] = useState<
    Collector[]
  >([])

  const { isContentLoading, handleSetIsContentLoadingFalse } =
    useContentLoading()

  const {
    data: collectors, isLoading: isCollectorsLoading,
  } = useCollectors()
  const {
    branches: { data: branches, isLoading: isBranchLoading },
  } = useBranch(null, true)
  const { data: providers, isLoading: isProvidersLoading } = useProviders()
  const {
    customers: { data: customers, isLoading: isCustomersLoading },
  } = useCustomer(null, true)
  const {
    budgets: { data: budgets, isLoading: isBudgetsLoading },
  } = useBudget(null, true)
  const { data: addresses, isLoading: isAddressesLoading } = useAddresses()

  const {
    drivers: { data: drivers, isLoading: isDriversLoading },
  } = useDriver(null, true, false);


  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IFormInputProps>({
    resolver: yupResolver(createRecurrentServiceSchema),
    defaultValues: {
      availability_forecast_deadline: null,
      board_date: null
    }
  })

  const { fields } = useFieldArray({
    control,
    name: 'destination_addresses_input',
  })

  const destinationAddressesSelected = useWatch({
    control,
    name: 'destination_address_id',
  })

  const customerId = watch('customer_id')
  const budgetId = watch('budget_id')
  const sourceAddressesId = watch('source_address_id')
  const destinationAddressesId = watch('destination_address_id')
  const modalType = watch('modal')
  const serviceType = watch('service_type')
  const sourceCollectorId = watch('source_collector_id')
  const geloSecoQuantity = watch('gelo_seco')
  const sourceBranchId = watch('source_branch_id')
  const materialType = watch('material_type')

  const {
    data: collector, isLoading: isCollectorLoading
  } = useCollector(sourceCollectorId)

  const collectorHasDriverAssignment = collector?.has_driver_assign;

  const driversFilteredBySourceCollector = drivers?.filter(
    (driver) =>
      driver.collector_id === sourceCollectorId
  );

  const [sourceAddressesSelected, driverAddressAssignSelected] = useWatch({
    control,
    name: ['source_address_id', 'driver_address_assign'],
  })

  const [providerAddressesSelected, driverAddressProviderAssignSelected] = useWatch({
    control,
    name: ['provider_id', 'driver_provider_assign'],
  })

  const {
    fields: sourceAddressesFields,
    update: updateSourceAddressesField,
    remove: removeSourceAddressField,
  } = useFieldArray({
    control,
    name: "driver_address_assign"
  })

  const {
    fields: providerSourceAddresses,
    update: updateProviderAddresses,
    remove: removeProviderAddressField,
  } = useFieldArray({
    control,
    name: "driver_provider_assign"
  })

  const prevDriverAddressAssignSelected = useRef<IAssignDriverInputProps[] | null>(null)
  const prevDriverProviderAddressAssignSelected = useRef<IAssignDriverProviderInputProps[] | null>(null)

  useEffect(() => {
    prevDriverAddressAssignSelected.current = driverAddressAssignSelected
  }, [driverAddressAssignSelected])

  useEffect(() => {
    prevDriverProviderAddressAssignSelected.current = driverAddressProviderAssignSelected
  }, [driverAddressProviderAssignSelected])

  useEffect(() => {
    if (sourceAddressesSelected !== undefined && sourceAddressesSelected.length !== 0 && collectorHasDriverAssignment && serviceType === 'DEDICADO' && modalType === 'RODOVIÁRIO') {
      if (prevDriverAddressAssignSelected?.current && prevDriverAddressAssignSelected?.current?.length > sourceAddressesSelected.length) {
        const removedDriverAssignFieldIndex = prevDriverAddressAssignSelected?.current.findIndex(field => !sourceAddressesSelected.includes(field.address_id))
        removeSourceAddressField(removedDriverAssignFieldIndex)
      } else {
        sourceAddressesSelected?.forEach((address, index) => {
          updateSourceAddressesField(index, { address_id: address, driver_id: '' })
        })
      }

    }
  }, [collectorHasDriverAssignment, modalType, removeSourceAddressField, serviceType, sourceAddressesSelected, updateSourceAddressesField])

  useEffect(() => {
    if (providerAddressesSelected !== undefined && providerAddressesSelected.length !== 0 && collectorHasDriverAssignment && serviceType === 'DEDICADO' && modalType === 'RODOVIÁRIO') {
      if (prevDriverProviderAddressAssignSelected?.current && prevDriverProviderAddressAssignSelected?.current?.length > providerAddressesSelected.length) {
        const removedDriverAssignFieldIndex = prevDriverProviderAddressAssignSelected.current.findIndex(field => !providerAddressesSelected.includes(field.provider_id))
        removeProviderAddressField(removedDriverAssignFieldIndex)
      } else {
        updateProviderAddresses(0, { provider_id: providerAddressesSelected, driver_id: '' })

      }

    }
  }, [collectorHasDriverAssignment, modalType, providerAddressesSelected, removeProviderAddressField, removeSourceAddressField, serviceType, updateProviderAddresses])



  const sourceAddresses = useMemo(() => {
    if (addresses && budgetId && customerId && budgetsByCustomerId) {
      const sourceCitiesOfBudget = budgetsByCustomerId
        .filter((budget) => budget.id === budgetId)
        .flatMap((budget) => budget.source_cities)

      const sourceAddressesList = addresses
        .filter((address) => address.situation === 'ATIVO')
        .filter((address) => address.customer_id === customerId)
        .filter((address, i) =>
          sourceCitiesOfBudget.includes(address.cityIDAddress.id),
        )

      return sourceAddressesList ?? []
    }
  }, [addresses, budgetsByCustomerId, customerId, budgetId])

  const destinationAddresses = useMemo(() => {
    if (addresses && budgetId && customerId && budgetsByCustomerId) {
      const destinationCitiesOfBudget = budgetsByCustomerId
        .filter((budget) => budget.id === budgetId)
        .flatMap((budget) => budget.destination_cities)

      const destinationAddressesList = addresses
        .filter((address) => address.situation === 'ATIVO')
        .filter((address) => address.customer_id === customerId)
        .filter((address, i) =>
          destinationCitiesOfBudget.includes(address.cityIDAddress.id),
        )

      return destinationAddressesList ?? []
    }
  }, [addresses, budgetsByCustomerId, customerId, budgetId])

  const providersWithGeloSeco = useMemo(() => {
    if (
      providers &&
      Number(geloSecoQuantity) > 0 &&
      budgetsByCustomerId &&
      budgetId
    ) {
      const sourceHubOfBudgetSelected = String(
        budgetsByCustomerId
          .filter((budget) => budget.id === budgetId)
          .map((budget) => budget.source_hub_id),
      )
      return providers
        .filter((provider) => provider.material === 'GELO SECO')
        .filter((provider) => provider.hub_id === sourceHubOfBudgetSelected)
    }
  }, [providers, budgetsByCustomerId, budgetId, geloSecoQuantity])

  const sourceBranches = useMemo(() => {
    if (budgetsByCustomerId && budgetId && branches) {
      const sourceHubOfBudget = String(
        budgetsByCustomerId
          .filter((budget) => budget.id === budgetId)
          .map((budget) => budget.source_hub_id),
      )
      return branches
        .filter((branch) => sourceHubOfBudget === branch.hub_id)
        .filter((branch) => branch.shippingIDBranch.modal === modalType)
    }
  }, [budgetsByCustomerId, budgetId, branches, modalType])

  const destinationBranches = useMemo(() => {
    if (budgetsByCustomerId && budgetId && branches) {
      const destinationHubOfBudget = String(
        budgetsByCustomerId
          .filter((budget) => budget.id === budgetId)
          .map((budget) => budget.destination_hub_id),
      )
      const sourceShippingIdBySourceBranch = branches
        .filter((branch) => branch.id === sourceBranchId)
        .map((branch) => branch.shippingIDBranch.id)

      const destinationBranch = branches
        .filter((branch) => branch.id !== sourceBranchId)
        .filter((branch) =>
          sourceShippingIdBySourceBranch?.includes(branch.shipping_id),
        )
        .filter((branch) => branch.shippingIDBranch.modal === modalType)
        .filter((branch) => branch.hub_id === destinationHubOfBudget)

      return destinationBranch ?? []
    }
  }, [budgetsByCustomerId, budgetId, sourceBranchId, modalType, branches])

  useEffect(() => {
    if (
      !isCustomersLoading &&
      !isBudgetsLoading &&
      !isAddressesLoading &&
      !isCollectorsLoading &&
      !isProvidersLoading &&
      !isBranchLoading &&
      !isDriversLoading &&
      !isCollectorLoading
    ) {
      handleSetIsContentLoadingFalse()
    }
  }, [
    isCustomersLoading,
    isBudgetsLoading,
    isAddressesLoading,
    isCollectorsLoading,
    isProvidersLoading,
    isBranchLoading,
    isDriversLoading,
    isCollectorLoading,
    handleSetIsContentLoadingFalse,
  ])

  useEffect(() => {
    if (
      typeof destinationAddressesSelected === 'object' ||
      typeof destinationAddressesSelected === 'string'
    ) {
      if (materialType === 'CORRELATOS' || materialType === 'CARGA GERAL') {
        if (typeof destinationAddressesSelected === 'string') {
          const destinationAddressesSelectedArray: string[] = []
          destinationAddressesSelectedArray.push(destinationAddressesSelected)

          const newDestinationAddressesSelected =
            destinationAddressesSelectedArray.map((address) => {
              return {
                address_id: address,
                quantity: 0,
              }
            })

          setDestinationAddressesInput(newDestinationAddressesSelected)
          return
        }
        const destinationAddressesSelectedCopy = [
          ...destinationAddressesSelected,
        ]

        const newDestinationAddressesSelected =
          destinationAddressesSelectedCopy.map((address) => {
            return {
              address_id: address,
              quantity: 0,
            }
          })

        setDestinationAddressesInput(newDestinationAddressesSelected)
      }
    }
  }, [materialType, destinationAddressesSelected])

  useEffect(() => {
    if (destinationAddressesInput.length > 0) {
      setValue('destination_addresses_input', destinationAddressesInput)

      for (let i = 0; i < destinationAddressesInput.length; i++) {
        setValue(
          `destination_addresses_input.${i}.address_id`,
          destinationAddressesInput[i].address_id,
        )
        setValue(
          `destination_addresses_input.${i}.quantity`,
          destinationAddressesInput[i].quantity,
        )
      }
    }
  }, [setValue, destinationAddressesInput])

  useEffect(() => {
    if (budgets && customerId) {
      const budgetsFilteredByCustomerId = budgets.filter(
        (budget) => budget.customer_id === customerId,
      ).filter(budget => budget.situation === 'ATIVO')
      setBudgetsByCustomerId(budgetsFilteredByCustomerId)
    }
  }, [budgets, customerId])

  useEffect(() => {
    if (collectors && budgetsByCustomerId && budgetId) {
      const sourceHubOfBudget = String(
        budgetsByCustomerId
          .filter((budget) => budget.id === budgetId)
          .map((budget) => budget.source_hub_id),
      )
      const destinationHubOfBudget = String(
        budgetsByCustomerId
          .filter((budget) => budget.id === budgetId)
          .map((budget) => budget.destination_hub_id),
      )
      const collectorsBySourceHubId = collectors.filter((collector) =>
        collector.hub_list.includes(sourceHubOfBudget),
      )
      const collectorsByDestinationHubId = collectors.filter((collector) =>
        collector.hub_list.includes(destinationHubOfBudget),
      )
      setSourceCollectors(collectorsBySourceHubId)
      setDestinationCollectors(collectorsByDestinationHubId)
    }
  }, [collectors, budgetId, budgetsByCustomerId])

  useEffect(() => {
    if (serviceType === 'DEDICADO') {
      setValue('modal', 'RODOVIÁRIO')
      if (sourceCollectorId) {
        setValue('destination_collector_id', sourceCollectorId)
      }
    }
  }, [serviceType, sourceCollectorId, setValue])

  useEffect(() => {
    if (modalType === 'AÉREO') {
      setValue('availability_forecast_time', '')
      setValue('availability_forecast_day', '')
      setValue('board_hour', '')
      setValue('board_date', null)
    }
  }, [modalType, setValue])

  useEffect(() => {
    setValue('situation', 'ATIVO')

    if (budgetsByCustomerId) {
      budgetsByCustomerId
        .filter((budget) => budget.id === budgetId)
        .map((budget) => {
          return (
            setValue('deadline', budget.deadline),
            setValue('service_type', budget.service_type),
            setValue('modal', budget.modal),
            setValue('vehicle', budget.vehicle),
            setValue('franchising', budget.franchising),
            setValue('caixa_termica', budget.caixa_termica),
            setValue('embalagem_secundaria', budget.embalagem_secundaria),
            setValue('gelo_seco', budget.gelo_seco),
            setValue('gelox', budget.gelox),
            setValue('isopor3l', budget.isopor3l),
            setValue('isopor7l', budget.isopor7l),
            setValue('terciaria3l', budget.terciaria3l),
            setValue('terciaria8l', budget.terciaria8l)
          )
        })
    }
  }, [setValue, budgetsByCustomerId, budgetId])

  useEffect(() => {
    if (budgetId && budgets) {
      const customerDeadlineDelivery = budgets.find(budget => {
        return budget.id === budgetId
      })?.deadline_delivery

      if (customerDeadlineDelivery) {
        setValue('delivery_hour', formatDate.handle(customerDeadlineDelivery, "DateOnlyWithHourMinute"))
      }
    }
  }, [setValue, budgetId, budgets])

  if (isContentLoading || isRequestLoading) {
    return <GeneralContentLoading />
  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(submit)}
      title="Solicitar Serviço Recorrente"
    >
      <Stack mt="4" spacing="24px" direction={['column', 'column', 'row']}>
        <Select
          {...register('customer_id')}
          name="customer_id"
          label="Pertence ao cliente"
          error={errors.customer_id}
          customers_first_name={customers}
          placeholder="Selecione uma opção..."
          required
        />
        <Select
          {...register('situation')}
          name="situation"
          label="Situação"
          error={errors.situation}
          situations={situation}
          placeholder="Selecione uma opção..."
          required
        />
      </Stack>

      {customerId && (
        <Stack mt="4">
          <Select
            {...register('budget_id')}
            name="budget_id"
            label="Pertence ao orçamento"
            error={errors.budget_id}
            budgets={budgetsByCustomerId}
            placeholder="Selecione uma opção..."
            required
          />
        </Stack>
      )}

      {customerId && budgetId && !!sourceAddresses && !!destinationAddresses && (
        <Stack
          spacing="24px"
          mt="4"
          direction={
            sourceAddresses.length > 0 && destinationAddresses.length > 0
              ? ['column', 'column', 'row']
              : 'column'
          }
        >
          {sourceAddresses.length > 0 ? (
            <Controller
              name="source_address_id"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <CheckboxService
                    onCheckboxChange={onChange}
                    name="source_address_id"
                    error={errors.source_address_id}
                    addresses={sourceAddresses}
                    label="Endereço(s) de origem"
                    required
                  />
                )
              }}
            />
          ) : (
            <>
              <Flex w="full" justify="center">
                <EmptyAddressesFormServiceAlert
                  title="Oops!"
                  description="Não há endereços de origem!"
                />
              </Flex>
            </>
          )}
          {destinationAddresses.length > 0 ? (
            <Controller
              control={control}
              name="destination_address_id"
              render={({ field: { onChange } }) => {
                return (
                  <CheckboxService
                    onCheckboxChange={onChange}
                    name="destination_address_id"
                    error={errors.destination_address_id}
                    addresses={destinationAddresses}
                    label="Endereço(s) de destino"
                    required
                  />
                )
              }}
            />

          ) : (
            <>
              <Flex w="full" justify="center">
                <EmptyAddressesFormServiceAlert
                  title="Oops!"
                  description="Não há endereços de destino!"
                />
              </Flex>
            </>
          )}
        </Stack>
      )}

      {sourceAddressesId && destinationAddressesId && (
        <>
          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Select
              {...register('deadline')}
              name="deadline"
              label="Prazo de entrega"
              error={errors.deadline}
              deadline_delivery={deadline_delivery}
              placeholder="Selecione uma opção..."
              required
            />

            <Select
              {...register('service_type')}
              name="service_type"
              label="Tipo de serviço"
              error={errors.service_type}
              service_types={service_types}
              placeholder="Selecione uma opção..."
              required
            />
          </Stack>


          <Stack
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Select
              {...register('source_collector_id')}
              name="source_collector_id"
              label='Coletador de Origem'
              error={errors.source_collector_id}
              sourceCollector={sourceCollectors}
              placeholder="Selecione uma opção..."
              required
            />

            <Select
              {...register('destination_collector_id')}
              name="destination_collector_id"
              label="Coletador de Destino"
              error={errors.destination_collector_id}
              destinationCollector={destinationCollectors}
              placeholder="Selecione uma opção..."
              required
            />

          </Stack>

          {serviceType === 'FRACIONADO' && (
            <Stack
              spacing="24px"
              mt="4"
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('source_branch_id')}
                name="source_branch_id"
                label="Base de Origem"
                error={errors.source_branch_id}
                sourceBranch={sourceBranches}
                placeholder="Selecione uma opção..."
                required
              />
              <Select
                {...register('destination_branch_id')}
                name="destination_branch_id"
                label="Base de Destino"
                error={errors.destination_branch_id}
                sourceBranch={destinationBranches}
                placeholder="Selecione uma opção..."
                required
              />
            </Stack>
          )}

          <Stack
            w="full"
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Select
                {...register('modal')}
                name="modal"
                label="Modal"
                error={errors.modal}
                modal_types={modal_types}
                isDisabled={serviceType === 'DEDICADO'}
                placeholder="Selecione uma opção..."
                required
              />
              <Select
                {...register('vehicle')}
                name="vehicle"
                label="Veículo"
                error={errors.vehicle}
                vehicle_types={vehicle_types}
                placeholder="Selecione uma opção..."
                isDisabled={
                  modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
                }
                required
              />
            </Stack>

            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              {serviceType === 'FRACIONADO' && modalType === 'RODOVIÁRIO' && (
                <Input
                  {...register('planned_flight')}
                  name="planned_flight"
                  label="Viagem Planejada"
                  error={errors.planned_flight}
                  required
                />
              )}

              <Input
                {...register('franchising')}
                name="franchising"
                label="Franquia"
                addChildren="KG"
                type="number"
                error={errors.franchising}
                isDisabled={
                  modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
                }
                required
              />
            </Stack>
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('caixa_termica')}
              name="caixa_termica"
              label="Caixa Térmica"
              type="number"
              error={errors.caixa_termica}
              isDisabled={
                modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
              }
              required
            />
            <Stack
              w="full"
              spacing="24px"
              direction={['column', 'column', 'row']}
            >
              <Input
                {...register('gelo_seco')}
                name="gelo_seco"
                label="Gelo Seco"
                type="number"
                error={errors.gelo_seco}
                isDisabled={
                  modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
                }
                required
              />

              <Input
                {...register('gelox')}
                name="gelox"
                label="Gelox"
                type="number"
                error={errors.gelox}
                isDisabled={
                  modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
                }
                required
              />
            </Stack>
          </Stack>
          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('isopor3l')}
              name="isopor3l"
              label="Isopor 3L"
              type="number"
              error={errors.isopor3l}
              isDisabled={
                modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
              }
              required
            />
            <Input
              {...register('isopor7l')}
              name="isopor7l"
              label="Isopor 7L"
              type="number"
              error={errors.isopor7l}
              isDisabled={
                modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
              }
              required
            />
            <Input
              {...register('terciaria3l')}
              name="terciaria3l"
              label="Terciária 3L"
              type="number"
              error={errors.terciaria3l}
              isDisabled={
                modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
              }
              required
            />

            <Input
              {...register('terciaria8l')}
              name="terciaria8l"
              label="Terciária 8L"
              type="number"
              error={errors.gelox}
              isDisabled={
                modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
              }
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('embalagem_secundaria')}
              name="embalagem_secundaria"
              label="Embalagem Secundária Ziplock"
              type="number"
              error={errors.embalagem_secundaria}
              isDisabled={
                modalType !== 'RODOVIÁRIO' && serviceType !== 'DEDICADO'
              }
              required
            />
          </Stack>

          {(serviceType === 'DEDICADO' || modalType === 'RODOVIÁRIO') &&
            Number(geloSecoQuantity) > 0 && (
              <Stack
                mt="4"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Select
                  {...register('provider_id')}
                  name="provider_id"
                  label="Fornecedor de gelo seco"
                  error={errors.provider_id}
                  providerList={providersWithGeloSeco}
                  placeholder="Selecione uma opção..."
                  required
                />
              </Stack>
            )}

          <Stack
            w="full"
            spacing="24px"
            mt="4"
            direction={['column', 'column', 'row']}
          >
            <Input
              {...register('collect_hour_start')}
              name="collect_hour_start"
              type="time"
              label="Horário inicial da coleta"
              error={errors.collect_hour_start}
              required
            />

            <Input
              {...register('collect_hour_end')}
              name="collect_hour_end"
              type="time"
              label="Horário final da coleta"
              error={errors.collect_hour_end}
              required
            />
          </Stack>

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Input
              {...register('delivery_hour')}
              name="delivery_hour"
              type="time"
              label="Horário limite para entregas"
              error={errors.delivery_hour}
              required
            />
          </Stack>

          {serviceType === 'FRACIONADO' && modalType === 'RODOVIÁRIO' && (
            <Stack w="full" mt="4" spacing="24px" direction="column">
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Select
                  {...register('board_date')}
                  name="board_date"
                  deadline_delivery={deadline_delivery}
                  placeholder="Selecione uma opção..."
                  label="Data limite para embarque"
                  error={errors.board_date}
                  required
                />
                <Input
                  {...register('board_hour')}
                  name="board_hour"
                  type="time"
                  label="Horário limite para embarque"
                  error={errors.board_hour}
                  required
                />
              </Stack>
              <Stack
                w="full"
                spacing="24px"
                direction={['column', 'column', 'row']}
              >
                <Select
                  {...register('availability_forecast_deadline')}
                  name="availability_forecast_deadline"
                  label="Data previsão de disponibilidade"
                  error={errors.availability_forecast_deadline}
                  deadline_delivery={deadline_delivery}
                  placeholder="Selecione uma opção..."
                  required
                />
                <Input
                  {...register('availability_forecast_time')}
                  name="availability_forecast_time"
                  type="time"
                  label="Hora previsão de disponibilidade"
                  error={errors.availability_forecast_time}
                  required
                />
              </Stack>
            </Stack>
          )}

          <Stack mt="4">
            <Select
              {...register('material_type')}
              name="material_type"
              label="Tipo de Material"
              error={errors.material_type}
              placeholder="Selecione uma opção..."
              requestedServiceMaterialTypes={switchRequestedServiceMateryalTypes.handle()}
              required
            />
          </Stack>

          {(materialType === 'CORRELATOS' ||
            materialType === 'CARGA GERAL') && (
              <>
                {fields.map((item, index) => (
                  <Fragment key={index}>
                    <Flex
                      key={index}
                      w="full"
                      align="center"
                      direction={['column', 'column', 'row']}
                    >
                      <Stack
                        w="full"
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Select
                          {...register(
                            `destination_addresses_input.${index}.address_id`,
                          )}
                          name={`destination_addresses_input.${index}.address_id`}
                          label="Endereço"
                          addresses={addresses}
                          error={
                            errors.destination_addresses_input
                              ? errors?.destination_addresses_input[index]
                                ?.address_id
                              : undefined
                          }
                          isDisabled={true}
                          required
                        />
                        <Input
                          {...register(
                            `destination_addresses_input.${index}.quantity`,
                          )}
                          name={`destination_addresses_input.${index}.quantity`}
                          label="Quantidade"
                          error={
                            errors.destination_addresses_input
                              ? errors?.destination_addresses_input[index]
                                ?.quantity
                              : undefined
                          }
                          required
                        />
                      </Stack>
                    </Flex>
                    <Divider my="6" borderColor="gray.700" />
                  </Fragment>
                ))}
              </>
            )}

          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <Controller
              control={control}
              name='days_of_week'
              render={({ field: { onChange } }) => (
                <CheckboxDaysWeek
                  name="days_of_week"
                  days_of_week={days_of_week}
                  error={errors.days_of_week}
                  label="Período de Recorrência"
                  onCheckboxChange={onChange}
                  required
                />
              )}

            />
          </Stack>
          {collectorHasDriverAssignment && serviceType === 'DEDICADO' && modalType === 'RODOVIÁRIO' && sourceAddressesFields !== null &&
            sourceAddressesFields?.map((field, index) => {
              return (
                <Stack key={field.id} spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                  <Select
                    {...register(`driver_address_assign.${index}.driver_id`)}
                    name={`driver_address_assign.${index}.driver_id`}
                    label="Motorista"
                    drivers={driversFilteredBySourceCollector}
                    error={errors.driver_address_assign ? errors?.driver_address_assign[index]?.driver_id : undefined}
                    placeholder="Selecione uma opção..."
                  />
                  <Stack w="full" direction="row">
                    <Select
                      {...register(`driver_address_assign.${index}.address_id`)}
                      name={`driver_address_assign.${index}.address_id`}
                      label="Endereço"
                      addresses={addresses}
                      error={errors.driver_address_assign ? errors?.driver_address_assign[index]?.address_id : undefined}
                      isDisabled={true}
                      placeholder="Selecione uma opção..."
                    />


                  </Stack>
                </Stack>
              )
            })}

          {collectorHasDriverAssignment && serviceType === 'DEDICADO' && modalType === 'RODOVIÁRIO' && Number(geloSecoQuantity) > 0 && providerSourceAddresses !== null && providerSourceAddresses?.map((field, index) => {
            return (
              <Stack key={field.id} spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                <Select
                  {...register(`driver_provider_assign.${index}.driver_id`)}
                  name={`driver_provider_assign.${index}.driver_id`}
                  label="Motorista"
                  drivers={driversFilteredBySourceCollector}
                  error={errors.driver_provider_assign ? errors?.driver_provider_assign[index]?.driver_id : undefined}
                  placeholder="Selecione uma opção..."
                  required
                />
                <Select
                  {...register(`driver_provider_assign.${index}.provider_id`)}
                  name={`driver_provider_assign.${index}.provider_id`}
                  label="Endereço Fornecedor"
                  providerAddress={providers}
                  error={errors.driver_provider_assign ? errors?.driver_provider_assign[index]?.provider_id : undefined}
                  isDisabled={true}
                  placeholder="Selecione uma opção..."
                  required
                />
              </Stack>
            )
          })}
          <Stack spacing="24px" mt="4" direction={['column', 'column', 'row']}>
            <TextArea
              {...register('observation')}
              name="observation"
              label="Observações"
            />
          </Stack>
        </>
      )}

      <Flex mt="8" justify="flex-end">
        <HStack spacing="24px" mt="4">
          <ListButton
            href="/servicos/recorrentes"
            name="Serviços Recorrentes"
          />
          <SubmitButton isSubmitting={isSubmitting} action="Salvar" />
        </HStack>
      </Flex>
    </StandardBackgroundForm>
  )
}

import { Td, Tr } from "@chakra-ui/react";
import { RouteNetwork } from "hooks/useRouteNetwork/dtos/RouteNetwork";
import { formatDate } from "utils/DateFunctions/formatDate";

interface RouteNetworkTableRow {
  routeNetwork: RouteNetwork
}

export function RouteNetworksTableRow({
  routeNetwork
}: RouteNetworkTableRow) {
  return (
    <Tr >
      <Td>{routeNetwork.shipping.trading_name}</Td>
      <Td>{routeNetwork.source_hub.name}</Td>
      <Td>{routeNetwork.destination_hub.name}</Td>
      <Td>{routeNetwork.source_branch.nickname}</Td>
      <Td>{routeNetwork.destination_branch.nickname}</Td>
      <Td>{formatDate.handle(routeNetwork.departure_date, 'DateWithoutHourToShow')}</Td>
      <Td>{formatDate.handle(routeNetwork.departure_hour, 'DateOnlyWithHourMinute')}</Td>
      <Td>{formatDate.handle(routeNetwork.arrival_date, 'DateWithoutHourToShow')}</Td>
      <Td>{formatDate.handle(routeNetwork.arrival_hour, 'DateOnlyWithHourMinute')}</Td>
      <Td>{routeNetwork.truck_flight}</Td>
      <Td>{routeNetwork.modal}</Td>
    </Tr>
  )
}

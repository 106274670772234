import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface EditDocumentFieldProps {
  routeParams: {
    documentId: string
  }
  body: {
    field: string
    value: string | boolean
  }
}

export async function editDocumentField({
  body,
  routeParams
}: EditDocumentFieldProps) {
  try {
    await api.patch(`/documents/${routeParams.documentId}/edit-field`, body)

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { Td, Tr } from "@chakra-ui/react";
import { GetAuditResponse } from "api/audits/getAudit";

interface AuditQualityIsoTableRowRowProps {
  audit: GetAuditResponse
}

export function AuditQualityIsoTableRow({
  audit
}: AuditQualityIsoTableRowRowProps) {
  return (
    <>
      <Tr>
        <Td>Existe cronograma de treinamentos?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasTrainingSchedule ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe cronograma de auditorias?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasAuditsSchedule ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe Mapeamento de Processos do Comercial?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasCommercialProcessMapping ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe Mapeamento de Processos do RH/DP?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasRhDpProcessMapping ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe Mapeamento de Processos de Operação?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasOperationProcessMapping ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe Mapeamento de Processos de Compras?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasPurchaseProcessMapping ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe Mapeamento de Processos do Financeiro?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasFinancialProcessMapping ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe Mapeamento de Processos da Qualidade?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasQualityProcessMapping ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe Mapeamento de Processos da Cultura Organizacional?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasOrganizationalCultureProcessMapping ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe organograma atualizado?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasUpdatedOrganizationalChart ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe descrição de cargos e funções?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasCargoAndFunctionsDetail ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento que defina o processo de auditoria?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasAuditProcessDocument ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento que defina o processo de treinamento?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasTrainingProcessDocument ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe modelo de ata de reunião de análise crítica?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasMeetingModelForCriticalAnalysis ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existem certificados dos auditores salvos?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasAuditorsSavedCertificates ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe planejamento de mudanças?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasChangesPlanning ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe política da qualidade?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasQualityPolicy ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe objetivos da qualidade?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasQualityObjectives ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento que padroniza os documentos?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasDocumentToStandardizesDocuments ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe interação dos processos?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasProcessInteraction ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe manual do Sistema de Gestão da Qualidade?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasQualityManagmentSystemManual ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe qualificação de transportadoras e IQR?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasCarriersIqrQualification ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe qualificação de fornecedores e IQR?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasProvidersIqrQualification ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe qualificação de coletadores e IQR?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasCollectorsIqrQualification ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe documento de não conformidade?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasNonComplianceDocument ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe código de ética?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasEthicsCode ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe comunicação interna e externa?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasInternExternalComunication ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td>Existe planejamento estratégico?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.hasStrategicPlanning ? 'SIM' : 'NÃO'}</Td>
      </Tr>
      <Tr>
        <Td> Cartão de ponto batido corretamente?</Td>
        <Td isNumeric>{audit?.audit?.isoQualityAudit?.isTimeCardRecordedCorrectly ? 'SIM' : 'NÃO'}</Td>
      </Tr>
    </>
  )
}

import { useEffect } from "react"
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAuth } from "hooks/auth/useAuth";
import { Flex, Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { useToastify } from "hooks/toastify/useToastify";
import { useEmployeeCostsFunctions } from "hooks/employeeCosts/useEmployeeCostsFunctions";
import { EmployeeCostForm, EmployeeCostFormInputs } from "./components/EmployeeCostForm";
import { useEmployeeCost } from "hooks/employeeCosts/useEmployeeCost";
import { useRegions } from "hooks/regions/useRegions";
import { GenerateEmployeeCostStatementModal } from "./components/GenerateEmployeeCostStatementModal";

interface Params {
  slug: string
  employeeCostId: string
}

const editEmployeeCostFormSchema = yup.object().shape({
  remuneration: yup.string().required('Campo obrigatório'),
  fapAliquot: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  thirdyPartyEmployerInss: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  satEmployerInss: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  fgts: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  vacation: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  oneThirdOfVacation: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  employerInssVacation: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  vacationFgts: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  thirteenthSalary: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  fgtsThirteenthSalary: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  employerInsuranceThirteenthSalary: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  costForecastWithIndemnifiedPriorNotice: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  fgtsPriorNotice: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  fgtsFineUponDismissalWithoutJustCause: yup.number().typeError('Campo obrigatório').required('Campo obrigatório'),
  transportationVoucher: yup.string().required('Campo obrigatório'),
  foodVoucher: yup.string().required('Campo obrigatório'),
  mealTicket: yup.string().required('Campo obrigatório'),
  uniform: yup.string().required('Campo obrigatório'),
  healthInsurance: yup.string().required('Campo obrigatório'),
  dentalPlan: yup.string().required('Campo obrigatório'),
  lifeInsurance: yup.string().required('Campo obrigatório'),
  monetaryAllowance: yup.string().required('Campo obrigatório'),
  motorbykeCarRental: yup.string().required('Campo obrigatório'),
  workMaterials: yup.string().required('Campo obrigatório'),
  fuel: yup.string().required('Campo obrigatório'),
  cellphone: yup.string().required('Campo obrigatório'),
  tracker: yup.string().required('Campo obrigatório'),
  homeWork: yup.string().required('Campo obrigatório'),
  backup: yup.string().required('Campo obrigatório'),
  fairTrader: yup.string().required('Campo obrigatório'),
  regionId: yup.string().required('Campo obrigatório'),
  vehicle: yup.string().required('Campo obrigatório'),
  monthlyKmLimit: yup.string().required('Campo obrigatório'),
  kmExtraPrice: yup.string().required('Campo obrigatório'),
  profitMargin: yup.string().required('Campo obrigatório'),
  taxCharges: yup.string().required('Campo obrigatório'),
});

export function EditViewEmployeeCost() {
  const { userLogged } = useAuth()
  const { push: redirect } = useHistory()

  const { promiseMessage } = useToastify();
  const { slug, employeeCostId } = useParams<Params>()

  const userHasPermissionToEditEmployeeCost =
    userLogged?.permissions.includes("edit-employee-cost");

  useEffect(() => {
    if (!userHasPermissionToEditEmployeeCost && slug !== 'visualizar') {
      redirect('/')
    }
  }, [redirect, slug, userHasPermissionToEditEmployeeCost])

  const { editEmployeeCost } = useEmployeeCostsFunctions()


  const formMethods = useForm<EmployeeCostFormInputs>({
    resolver: yupResolver(editEmployeeCostFormSchema)
  })

  const {
    handleSubmit,
    setValue,
    formState: {
      isSubmitting
    }
  } = formMethods

  const isViewPage = slug === "visualizar"
  const formTitle = isViewPage
    ? "Visualizar Custo de Empregado"
    : "Editar Custo de Empregado"

  const {
    data: employeeCostData,
    isFetching: isFetchingEmployeeCostData
  } = useEmployeeCost({
    employeeCostId,
    queryOptions: { enabled: !!employeeCostId }
  })

  useEffect(() => {
    if (employeeCostData) {
      setValue('remuneration', String(employeeCostData.employeeCost.remuneration / 100))
      setValue('fapAliquot', employeeCostData.employeeCost.fap_aliquot)
      setValue('thirdyPartyEmployerInss', employeeCostData.employeeCost.thirdy_party_employer_inss)
      setValue('satEmployerInss', employeeCostData.employeeCost.sat_employer_inss)
      setValue('fgts', employeeCostData.employeeCost.fgts)
      setValue('vacation', employeeCostData.employeeCost.vacation)
      setValue('oneThirdOfVacation', employeeCostData.employeeCost.one_third_of_vacation)
      setValue('employerInssVacation', employeeCostData.employeeCost.employer_inss_vacation)
      setValue('vacationFgts', employeeCostData.employeeCost.vacation_fgts)
      setValue('thirteenthSalary', employeeCostData.employeeCost.thirteenth_salary)
      setValue('fgtsThirteenthSalary', employeeCostData.employeeCost.fgts_thirteenth_salary)
      setValue('employerInsuranceThirteenthSalary', employeeCostData.employeeCost.employer_insurance_thirteenth_salary)
      setValue('costForecastWithIndemnifiedPriorNotice', employeeCostData.employeeCost.cost_forecast_with_indemnified_prior_notice)
      setValue('fgtsPriorNotice', employeeCostData.employeeCost.fgts_prior_notice)
      setValue('fgtsFineUponDismissalWithoutJustCause', employeeCostData.employeeCost.fgts_fine_upon_dismissal_without_just_cause)
      setValue('transportationVoucher', String(employeeCostData.employeeCost.transportation_voucher / 100))
      setValue('foodVoucher', String(employeeCostData.employeeCost.food_voucher / 100))
      setValue('mealTicket', String(employeeCostData.employeeCost.meal_ticket / 100))
      setValue('uniform', String(employeeCostData.employeeCost.uniform / 100))
      setValue('healthInsurance', String(employeeCostData.employeeCost.health_insurance / 100))
      setValue('dentalPlan', String(employeeCostData.employeeCost.dental_plan / 100))
      setValue('lifeInsurance', String(employeeCostData.employeeCost.life_insurance / 100))
      setValue('monetaryAllowance', String(employeeCostData.employeeCost.monetary_allowance / 100))
      setValue('motorbykeCarRental', String(employeeCostData.employeeCost.motorbyke_car_rental / 100))
      setValue('workMaterials', String(employeeCostData.employeeCost.work_materials / 100))
      setValue('fuel', String(employeeCostData.employeeCost.fuel / 100))
      setValue('cellphone', String(employeeCostData.employeeCost.cellphone / 100))
      setValue('tracker', String(employeeCostData.employeeCost.tracker / 100))
      setValue('homeWork', String(employeeCostData.employeeCost.home_work / 100))
      setValue('backup', String(employeeCostData.employeeCost.backup / 100))
      setValue('fairTrader', String(employeeCostData.employeeCost.fair_trader / 100))
      setValue('regionId', String(employeeCostData.employeeCost.region_id))
      setValue('vehicle', employeeCostData.employeeCost.vehicle)
      setValue('monthlyKmLimit', String(employeeCostData.employeeCost.monthly_km_limit))
      setValue('kmExtraPrice', String(employeeCostData.employeeCost.km_extra_price_in_cents / 100))
      setValue('profitMargin', String(employeeCostData.employeeCost.profit_margin))
      setValue('taxCharges', String(employeeCostData.employeeCost.tax_charges))
    }
  }, [setValue, employeeCostData])

  const {
    data: regionsData,
    isFetching: isFetchingRegionsData,
  } = useRegions()

  const regionsOptions = regionsData?.regions.map((region) => {
    return {
      key: region.id,
      value: region.id,
      showOption: `${region.name} (${region.acronym})`
    }
  })

  async function handleEditEmployeeCost(values: EmployeeCostFormInputs) {
    await promiseMessage(editEmployeeCost.mutateAsync({ ...values, employeeCostId }, {
      onSuccess: () => {
        redirect("/empregados/custos")
      }
    }),
      'Custo de empregado atualizado com suecsso!')
  }

  const {
    isOpen,
    onClose,
    onOpen,
  } = useDisclosure()

  const handleOpenGenerateEmployeeCostStatementModal = () => onOpen()

  return (
    <>
      <GenerateEmployeeCostStatementModal
        isOpen={isOpen}
        onClose={onClose}
        employeeCostId={employeeCostId}
      />

      <StandardBackgroundForm title={formTitle} onSubmit={handleSubmit(handleEditEmployeeCost)}>
        <Flex
          justify="flex-end"
        >
          <Button
            onClick={handleOpenGenerateEmployeeCostStatementModal}
            variant="ghost"
            boxShadow="base"
          >
            Gerar Demonstrativo
          </Button>
        </Flex>

        {isFetchingRegionsData ? (
          <Spinner />
        ) : (
          <FormProvider {...formMethods}>
            <EmployeeCostForm regionsOptions={regionsOptions} slug={slug} />
          </FormProvider>
        )}

        <Flex
          mt={6}
          gap={4}
          direction={["column", "column", "row"]}
          align="center"
          justify={["center", "center", "flex-end"]}
        >
          <Button
            as={Link}
            colorScheme='gray'
            w={['full', 'full', 'min']}
            isLoading={isSubmitting}
            to='/regioes'
          >
            Lista de custos de empregados
          </Button>

          {isViewPage ? (
            <Button
              as={Link}
              to={`/empregado/custo/${employeeCostId}/editar`}
              type="button"
              colorScheme="blue"
              w={["full", "full", "min"]}
              isLoading={isFetchingEmployeeCostData}
            >
              Editar
            </Button>
          ) : (
            <Button
              type="submit"
              colorScheme="blue"
              w={["full", "full", "min"]}
              isLoading={isFetchingEmployeeCostData || isSubmitting}
            >
              Salvar
            </Button>
          )}
        </Flex>
      </StandardBackgroundForm>
    </>
  )
}

import { Button, Divider, Flex, Icon, Spinner, Stack, Link as ChakraLink, FormLabel, Text, Alert, AlertTitle, AlertDescription } from "@chakra-ui/react"
import { addDays, set } from "date-fns"
import { Fragment, useEffect } from "react"
import { Controller, NestedValue, useFieldArray, useFormContext, useWatch } from "react-hook-form"
import { EmptyAddressesFormServiceAlert } from "../../../../../components/Alerts/EmptyAddressesFormServiceAlert"
import { CheckboxService } from "../../../../../components/Inputs/CheckboxService"
import { Input } from "../../../../../components/Inputs/Input"
import { Select } from "../../../../../components/Inputs/SelectInput"
import { TextArea } from "../../../../../components/Inputs/TextInput"
import { useAuth } from "../../../../../hooks/auth/useAuth"
import { useCustomer } from "../../../../../hooks/customer/useCustomer"
import { useAddresses } from "../../../../../hooks/address/useAddresses"
import { deadline_delivery, modal_types, service_types } from "../../../../../utils/customLists"
import { switchRequestedServiceMateryalTypes } from "../../../../../utils/CustomLists/switchRequestedServiceMateryalTypes"
import { formatDate } from "../../../../../utils/DateFunctions/formatDate"
import { RequestServiceDeadlineOptions, RequestServiceServiceTypeOptions, RequestServiceModalOptions, RequestServiceVehicleOptions } from "../../../../../utils/RequestFunctions/Service/Request/requestRequestServiceFunctions"
import { FaFilePdf, FaTimes } from "react-icons/fa"
import { BudgetProps } from "contexts/BudgetContext"
import { Address } from "hooks/address/dtos/Address"
import { useHistory } from "react-router-dom"

interface DestinationAddressesInputAssociationProps {
  address_id: string;
  quantity: number;
}

interface GeloSecoAddressesProps {
  address_id: string;
}

interface RequestServiceFormProps {
  budgets?: BudgetProps[]
  isBudgetsLoading?: boolean
}

interface IAttachments {
  attachment: FileList
}

export interface RequestServiceFormInputs {
  is_unique_collect_address_service: boolean
  customer_id: string;
  budget_id: string;
  source_address_id: NestedValue<string[]>
  destination_address_id: NestedValue<string[]>
  gelo_seco_addresses: GeloSecoAddressesProps[]
  deadline: RequestServiceDeadlineOptions
  service_type: RequestServiceServiceTypeOptions
  modal: RequestServiceModalOptions
  vehicle: RequestServiceVehicleOptions
  franchising: number
  caixa_termica: number
  gelo_seco: number
  gelox: number
  isopor3l: number
  isopor7l: number
  terciaria3l: number
  terciaria8l: number
  embalagem_secundaria: number
  collect_date: string
  collect_hour_start: string
  collect_hour_end: string
  delivery_date: string
  delivery_hour: string
  material_type: string
  destination_addresses_input: DestinationAddressesInputAssociationProps[]
  attachments: IAttachments[]
  requested_collect_addresses_observations: {
    address_id: string
    observation: string
  }[]
  observation: string
}

const supportedFileFormats = [
  "image/jpeg",
  "image/pjpeg",
  "image/jpg",
  "image/png",
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/zip",
  "application/gzip",
  "application/x-zip-compressed",
  "application/x-rar-compressed",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xml",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
]

interface WeekendResponse {
  isDayOfWeek: boolean
  isSaturday: boolean
  isSunday: boolean
}

function isWeekend(date: string | undefined): WeekendResponse {
  if (typeof date === 'string') {
    const [year, month, day] = date.split("-").map(value => Number(value))

    const deliveryDate = set(new Date(), {
      date: day,
      month: month - 1,
      year: year
    })

    const dayOfWeek = deliveryDate.getDay();

    return {
      isDayOfWeek: dayOfWeek === 0 || dayOfWeek === 6,
      isSaturday: dayOfWeek === 6,
      isSunday: dayOfWeek === 0
    }

  }
  return {
    isDayOfWeek: false,
    isSaturday: false,
    isSunday: false
  }

}

export function RequestServiceForm({ budgets, isBudgetsLoading }: RequestServiceFormProps) {

  const { userLogged } = useAuth()
  const history = useHistory()

  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useFormContext<RequestServiceFormInputs>()

  const {
    fields: destinationAddressesInputFields,
    remove: removeDestinationAddressInput,
    update: updateDestinationAddressesInputFields
  } = useFieldArray({
    control,
    name: "destination_addresses_input"
  })

  const {
    fields: geloSecoAddressesFields,
    append: appendGeloSecoAddressField,
    remove: removeGeloSecoAddress,
  } = useFieldArray({
    control,
    name: "gelo_seco_addresses"
  })

  function handleUpdateGeloSecoAddressesFields(e: { target: HTMLInputElement }) {
    const isCheckedField = e.target.checked

    if (!isCheckedField) {
      const fieldIndex = geloSecoAddressesFields.findIndex(field => field.address_id === e.target.value)

      removeGeloSecoAddress(fieldIndex)
    }
  }

  function handleAppendGeloSecoAddress() {
    appendGeloSecoAddressField({ address_id: '' })
  }

  function handleRemoveGeloSecoAddress(index: number) {
    removeGeloSecoAddress(index)
  }

  const destinationAddressesSelected = useWatch({
    control,
    name: "destination_address_id"
  })

  const geloSecoAddressesSelected = useWatch({
    control,
    name: "gelo_seco_addresses"
  })

  const {
    fields: attachmentsFields,
    append: appendAttachmentField,
    remove: removeAttachmentField
  } = useFieldArray({
    control,
    name: 'attachments'
  })

  const attachments = useWatch({
    control,
    name: 'attachments'
  })

  const materialType = watch("material_type")
  const materialTypeHasAddressAssociation = ["CORRELATOS", "CARGA GERAL"]
    .includes(materialType)

  useEffect(() => {
    if (materialTypeHasAddressAssociation) {
      destinationAddressesSelected?.forEach((address, index) => {
        updateDestinationAddressesInputFields(index, { address_id: address })
      })
    }
  }, [materialTypeHasAddressAssociation, destinationAddressesSelected, updateDestinationAddressesInputFields])

  function handleChangeDestinationAddressesInput(event: { target: HTMLInputElement }) {
    if (materialTypeHasAddressAssociation) {
      const addressExists = destinationAddressesInputFields.findIndex(
        field => field.address_id === event.target.value
      )

      if (addressExists > -1) return removeDestinationAddressInput(addressExists)
    }
  }

  const isUserLoggedCustomerOrRecipient = userLogged?.user_type === "CLIENTE" || userLogged?.user_type === "REMETENTE"

  const {
    customers: { data: customers, isLoading: isCustomersLoading }
  } = useCustomer(null, true)

  useEffect(() => {
    if (isUserLoggedCustomerOrRecipient && !isCustomersLoading) {
      const userLoggedCustomer = customers?.find(customer => customer.id === userLogged?.customer_id)

      if (userLoggedCustomer?.situation !== 'ATIVO') {
        history.push('/')
      }

      setValue("customer_id", userLogged?.customer_id)
    }
  }, [isUserLoggedCustomerOrRecipient, userLogged, setValue, isCustomersLoading, customers, history])

  const customerSelected = watch("customer_id")
  const isCustomerSelected = !!customerSelected

  const activeBudgets = budgets?.filter(budget => budget.situation === "ATIVO")


  const budgetSelected = watch("budget_id")
  const isBudgetSelected = !!budgetSelected

  const budgetSelectedData = budgets?.find(
    budget => budget.id === budgetSelected
  )

  useEffect(() => {
    if (budgetSelectedData) {
      const {
        deadline,
        service_type,
        modal,
        vehicle,
        franchising,
        caixa_termica,
        gelox,
        gelo_seco,
        isopor3l,
        isopor7l,
        terciaria3l,
        terciaria8l,
        embalagem_secundaria,
      } = budgetSelectedData

      setValue("deadline", deadline as RequestServiceDeadlineOptions)
      setValue("service_type", service_type as RequestServiceServiceTypeOptions)
      setValue("modal", modal as RequestServiceModalOptions)
      setValue("vehicle", vehicle as RequestServiceVehicleOptions)
      setValue("franchising", franchising)
      setValue("caixa_termica", caixa_termica)
      setValue("gelox", gelox)
      setValue("gelo_seco", gelo_seco)
      setValue("isopor3l", isopor3l)
      setValue("isopor7l", isopor7l)
      setValue("terciaria3l", terciaria3l)
      setValue("terciaria8l", terciaria8l)
      setValue("embalagem_secundaria", embalagem_secundaria)
    }
  }, [budgetSelectedData, setValue])

  const budgetSourceCities = budgetSelectedData?.source_cities ?? []
  const budgetDestinationCities = budgetSelectedData?.destination_cities ?? []

  const budgetSourceAndDestinationCities = budgetSourceCities?.concat(budgetDestinationCities)
  const {
    data: addresses,
    isLoading: isAddressesLoading,
    refetch: refetchAddresses,
    isRefetching: isAddressesRefetching
  } = useAddresses({
    queryParams: { cities: budgetSourceAndDestinationCities },
    queryOptions: { enabled: isBudgetSelected }
  })

  useEffect(() => {
    if (!!budgetSelected) refetchAddresses()
  }, [refetchAddresses, budgetSelected])

  const sourceAddressesSelected = useWatch({
    control,
    name: "source_address_id"
  })

  useEffect(() => {
    if (budgetSelectedData && sourceAddressesSelected) {
      const { deadline_delivery, price_add_collect } = budgetSelectedData

      setValue("delivery_hour", formatDate.handle(deadline_delivery, "DateOnlyWithHourMinute"))

      if (sourceAddressesSelected?.length > budgetSelectedData.source_address_qty && price_add_collect === 0) {
        setValue("is_unique_collect_address_service", true)
      } else {
        setValue("is_unique_collect_address_service", false)
      }
    }
  }, [budgetSelectedData, setValue, sourceAddressesSelected])

  const deliveryDate = useWatch({
    control,
    name: "delivery_date"
  })

  const weekendInfo = isWeekend(deliveryDate);

  const addressesWithoutOpeningHoursSaturdayOrSunday = weekendInfo.isDayOfWeek ? addresses?.reduce((acc: { sunday: Address[], saturday: Address[] }, address) => {


    if (destinationAddressesSelected.includes(address.id)) {

      if (weekendInfo.isSaturday && address.saturday_open === null) {
        acc.saturday.push(address);
      }

      if (weekendInfo.isSunday && address.sunday_open === null) {
        acc.sunday.push(address);
      }
    }
    return acc;
  }, { sunday: [], saturday: [] }) : { sunday: [], saturday: [] };



  const sourceAddresses = addresses?.filter(address => {
    return budgetSourceCities.includes(address.city_id)
      && address.situation === "ATIVO"
      && address.customer_id === customerSelected
      && !destinationAddressesSelected?.includes(address.id)
      && (address.deadline >= budgetSelectedData.deadline)
  })


  const requestedCollectAddressesObservationsOptions = sourceAddresses?.filter((address) => sourceAddressesSelected?.includes(address.id))?.map((address) => {
    return {
      key: address.id,
      value: address.id,
      showOption: `${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
      ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()
    }
  })

  const {
    fields: requestCollectAddressObservationFields,
    append: appendRequestCollectAddressObservationField,
    remove: removeRequestCollectAddressObservationField
  } = useFieldArray({
    control,
    name: 'requested_collect_addresses_observations'
  })

  function handleRemoveRequestCollectAddressObservationField(index: number) {
    removeRequestCollectAddressObservationField(index)
  }
  function handleAppendRequestCollectAddressObservationField() {
    appendRequestCollectAddressObservationField({ address_id: '', observation: '' })
  }


  const serviceHasGeloSeco = watch("gelo_seco") > 0

  const geloSecoAddressesSelectedAddresses =
    geloSecoAddressesSelected?.map(geloSecoAddress => geloSecoAddress.address_id)

  const sourceGeloSecoAddressesOptions = sourceAddresses
    ?.filter(sourceAddress => sourceAddressesSelected?.includes(sourceAddress.id))
    .map(sourceAddress => {
      return {
        key: sourceAddress.id,
        value: sourceAddress.id,
        showOption: `${sourceAddress.street}, ${sourceAddress.number}, ${sourceAddress.neighborhood
          } - ${sourceAddress.cep}`,
        disabled: geloSecoAddressesSelectedAddresses?.includes(sourceAddress.id)
      }
    })

  const destinationAddresses = addresses?.filter(address => {
    return budgetDestinationCities.includes(address.city_id)
      && address.situation === "ATIVO"
      && address.customer_id === customerSelected
      && !sourceAddressesSelected?.includes(address.id)
      && (address.deadline >= budgetSelectedData.deadline)
  })


  const hasSourceAddressesToCollect = sourceAddresses && sourceAddresses.length
  const hasDestinationAddressesToDelivery = destinationAddresses && destinationAddresses.length

  const collectDate = watch("collect_date")
  const deadline = watch("deadline")
  const isValidCollectDate = !isNaN(new Date(collectDate).getMilliseconds())

  useEffect(() => {
    if (isValidCollectDate) {
      const [year, month, day] = collectDate.split("-").map(value => Number(value))

      const deliveryDate = formatDate.handle(addDays(set(new Date(), {
        date: day,
        month: month - 1,
        year: year
      }), deadline), "DateWithoutHourToInput")

      setValue("delivery_date", deliveryDate)

    }
  }, [isValidCollectDate, collectDate, deadline, setValue])

  const isAllSourceAddressesAssignedWithGeloSeco =
    geloSecoAddressesFields?.length === sourceAddressesSelected?.length

  function handleRemoveAttachmentField(index: number) {
    removeAttachmentField(index)
  }

  function handleAppendAttachmentField() {
    appendAttachmentField({ attachment: undefined })
  }

  return (
    <Flex
      direction="column"
      gap="4"
    >
      <Select
        {...register("customer_id")}
        customers={customers}
        name="customer_id"
        label="Pertence ao cliente"
        placeholder="Selecione uma opção..."
        isDisabled={isCustomersLoading || isUserLoggedCustomerOrRecipient}
        error={errors.customer_id}
        required
      />
      {isCustomerSelected && (
        <Select
          {...register("budget_id")}
          name="budget_id"
          budgets={activeBudgets}
          isDisabled={isBudgetsLoading}
          hasFilter
          label="Pertence ao orçamento"
          placeholder="Selecione uma opção..."
          required
        />
      )}
      {isBudgetSelected && (
        <>
          {(isAddressesLoading || isAddressesRefetching) ? (
            <Spinner />
          ) : (
            <>
              <Stack spacing="24px" direction={["column", "column", "row"]}>
                {hasSourceAddressesToCollect ? (
                  <Controller
                    name="source_address_id"
                    control={control}
                    render={({ field: { onChange } }) => {
                      return (
                        <CheckboxService
                          onCheckboxChange={onChange}
                          onChange={handleUpdateGeloSecoAddressesFields}
                          name="source_address_id"
                          addresses={sourceAddresses}
                          label="Endereços de origem"
                          error={errors.source_address_id}
                          required
                        />
                      )
                    }}
                  />
                ) : (
                  <Flex w="full" justify="center" align="center">
                    <EmptyAddressesFormServiceAlert
                      title="Oops!"
                      description="Não há endereços de origem!"
                    />
                  </Flex>
                )}
                {hasDestinationAddressesToDelivery ? (
                  <Controller
                    name="destination_address_id"
                    control={control}
                    render={({ field: { onChange } }) => {
                      return (
                        <CheckboxService
                          onCheckboxChange={onChange}
                          name="destination_address_id"
                          onChange={handleChangeDestinationAddressesInput}
                          addresses={destinationAddresses}
                          label="Endereços de destino"
                          error={errors.destination_address_id}
                          required
                        />
                      )
                    }}
                  />
                ) : (
                  <Flex w="full" justify="center" align="center">
                    <EmptyAddressesFormServiceAlert
                      title="Oops!"
                      description="Não há endereços de destino!"
                    />
                  </Flex>
                )}
              </Stack>
              <Stack w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                <Text>Adicionar observações em endereços</Text>
              </Stack>
              <Flex direction="column">
                {requestCollectAddressObservationFields.map((field, index) => {
                  return (
                    <Flex direction='column'>
                      <Stack
                        w="full"
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <Select
                          {...register(`requested_collect_addresses_observations.${index}.address_id`)}
                          name={`requested_collect_addresses_observations.${index}.address_id`}
                          label='Endereço'
                          placeholder='Selecione uma opção...'
                          error={
                            errors.requested_collect_addresses_observations
                              ? errors?.requested_collect_addresses_observations[index]?.address_id
                              : undefined
                          }
                          options={requestedCollectAddressesObservationsOptions}
                          required
                        />
                      </Stack>

                      <Stack
                        w="full"
                        spacing="24px"
                        mt="4"
                        direction={['column', 'column', 'row']}
                      >
                        <TextArea
                          {...register(`requested_collect_addresses_observations.${index}.observation`)}
                          name={`requested_collect_addresses_observations.${index}.observation`}
                          label='Observações'
                          error={
                            errors.requested_collect_addresses_observations
                              ? errors?.requested_collect_addresses_observations[index]?.observation
                              : undefined
                          }
                          required
                        />

                      </Stack>
                      <Stack pt={8}>
                        <Button
                          leftIcon={<Icon as={FaTimes} />}
                          variant="ghost"
                          onClick={() => handleRemoveRequestCollectAddressObservationField(index)}
                          h="48px"
                        >
                          Remover
                        </Button>
                      </Stack>
                    </Flex>
                  )
                })}
                <Button
                  alignSelf="flex-start"
                  onClick={handleAppendRequestCollectAddressObservationField}
                  mt={2}
                >
                  Adicionar
                </Button>
              </Flex>
              <Stack direction={["column", "column", "row"]} spacing={6}>
                <Select
                  {...register("deadline")}
                  name="deadline"
                  label="Prazo de entrega"
                  required
                  deadline_delivery={deadline_delivery}
                  isDisabled
                />
                <Select
                  {...register("service_type")}
                  name="service_type"
                  label="Tipo de serviço"
                  required
                  service_types={service_types}
                  isDisabled
                />
              </Stack>
              <Stack direction={["column", "column", "row"]} spacing={6}>
                <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
                  <Select
                    {...register("modal")}
                    name="modal"
                    label="Modal"
                    required
                    modal_types={modal_types}
                    isDisabled
                  />
                  <Input
                    {...register("vehicle")}
                    name="vehicle"
                    label="Veículo"
                    required
                    isDisabled
                  />
                </Stack>
                <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
                  <Input
                    {...register("franchising")}
                    name="franchising"
                    label="Franquia"
                    addChildren="KG"
                    required
                    isDisabled
                  />
                  {userLogged?.loglife_employee && (
                    <Input
                      name="price_budget"
                      label="Valor do orçamento"
                      defaultValue={budgetSelectedData?.price.toFixed(2).replace('.', ',')}
                      addChildren="R$"
                      isDisabled
                    />
                  )}
                </Stack>
              </Stack>
              <Stack direction={["column", "column", "row"]} spacing={6}>
                <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
                  <Input
                    {...register("caixa_termica")}
                    name="caixa_termica"
                    label="Caixa térmica"
                    type="number"
                    error={errors.caixa_termica}
                    required
                  />
                  <Input
                    {...register("embalagem_secundaria")}
                    name="embalagem_secundaria"
                    label="Embalagem secundária ziplock"
                    type="number"
                    error={errors.embalagem_secundaria}
                    required
                  />
                </Stack>
                <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
                  <Input
                    {...register("gelo_seco")}
                    name="gelo_seco"
                    label="Gelo seco"
                    type="number"
                    error={errors.gelo_seco}
                    required
                  />
                  <Input
                    {...register("gelox")}
                    name="gelox"
                    label="Gelox"
                    type="number"
                    error={errors.gelox}
                    required
                  />
                </Stack>
              </Stack>
              <Stack direction={["column", "column", "row"]} spacing={6}>
                <Input
                  {...register("isopor3l")}
                  name="isopor3l"
                  label="Isopor 3l"
                  type="number"
                  error={errors.isopor3l}
                  required
                />
                <Input
                  {...register("isopor7l")}
                  name="isopor7l"
                  label="Isopor 7l"
                  type="number"
                  error={errors.isopor7l}
                  required
                />
                <Input
                  {...register("terciaria3l")}
                  name="terciaria3l"
                  label="Terciária 3l"
                  type="number"
                  error={errors.terciaria3l}
                  required
                />
                <Input
                  {...register("terciaria8l")}
                  name="terciaria8l"
                  label="Terciária 8l"
                  type="number"
                  error={errors.terciaria8l}
                  required
                />
              </Stack>

              <Stack direction={["column", "column", "row"]} spacing={6}>
                <Input
                  {...register("collect_date")}
                  name="collect_date"
                  label="Data da coleta"
                  error={errors.collect_date}
                  type="date"
                  required
                />

                <Stack w="full" direction={["column", "column", "row"]} spacing={6}>
                  <Input
                    {...register("collect_hour_start")}
                    name="collect_hour_start"
                    label="Horário inicial da coleta"
                    error={errors.collect_hour_start}
                    type="time"
                    required
                  />
                  <Input
                    {...register("collect_hour_end")}
                    name="collect_hour_end"
                    label="Horário final da coleta"
                    error={errors.collect_hour_end}
                    type="time"
                    required
                  />
                </Stack>
              </Stack>
              <Stack direction={["column", "column", "row"]} spacing={6}>
                {isValidCollectDate && (
                  <Input
                    {...register("delivery_date")}
                    name="delivery_date"
                    label="Data de entrega"
                    error={errors.delivery_date}
                    type="date"
                    isDisabled
                    required
                  />
                )}
                <Input
                  {...register("delivery_hour")}
                  name="delivery_hour"
                  label="Horário limite para entregas"
                  type="time"
                  isDisabled
                  required
                />

              </Stack>
              {addressesWithoutOpeningHoursSaturdayOrSunday.saturday.length !== 0 && (
                <Alert status='warning' mt='4' flexDirection='column' rounded='sm'>

                  <AlertTitle >Endereço(s) sem funcionamento aos sábados!</AlertTitle>
                  {addressesWithoutOpeningHoursSaturdayOrSunday.saturday.map((address) => {
                    return (
                      <AlertDescription key={address.id} alignSelf='flex-start'>{`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
                    ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}</AlertDescription>
                    )
                  })}
                </Alert>
              )}
              {addressesWithoutOpeningHoursSaturdayOrSunday.sunday.length !== 0 && (
                <Alert status='warning' mt='4' flexDirection='column' rounded='sm'>
                  <AlertTitle>Endereço(s) sem funcionamento aos domingos!</AlertTitle>
                  {addressesWithoutOpeningHoursSaturdayOrSunday.sunday.map((address) => {
                    return (
                      <AlertDescription alignSelf='flex-start'>{`${address.trading_name} | ${address.branch} | ${address.street} | ${address.number} | ${address.neighborhood} |
                    ${address.complement ?? '-'} | ${address.reference_point ?? '-'} ${address.cityIDAddress.name} | ${address.cep}`.toUpperCase()}</AlertDescription>
                    )
                  })}
                </Alert>
              )}
              <Select
                {...register("material_type")}
                name="material_type"
                label="Tipo de material"
                requestedServiceMaterialTypes={switchRequestedServiceMateryalTypes.handle()}
                placeholder="Selecione uma opção..."
                error={errors.material_type}
                required
              />
              {materialTypeHasAddressAssociation && (
                <>
                  {destinationAddressesInputFields.map((field, index) => {
                    return (
                      <Fragment key={field.id}>
                        <Stack
                          direction={["column", "column", "row"]}
                          spacing={6}
                        >
                          <Select
                            {...register(`destination_addresses_input.${index}.address_id`)}
                            name={`destination_addresses_input.${index}.address_id`}
                            addresses={addresses}
                            error={
                              errors.destination_addresses_input
                                ? errors?.destination_addresses_input[index]?.address_id
                                : undefined
                            }
                            label="Endereço"
                            isDisabled
                            required
                          />
                          <Input
                            {...register(`destination_addresses_input.${index}.quantity`)}
                            name={`destination_addresses_input.${index}.quantity`}
                            error={
                              errors.destination_addresses_input
                                ? errors?.destination_addresses_input[index]?.quantity
                                : undefined
                            }
                            label="Quantidade"
                            type="number"
                            required
                          />

                        </Stack>
                        <Divider />
                      </Fragment>
                    )
                  })}
                </>
              )}

              {serviceHasGeloSeco && (
                <>
                  {geloSecoAddressesFields.map((field, index) => {
                    return (
                      <Stack key={field.id}>

                        <Stack direction={["column", "column", "row"]} spacing={6}>
                          <Select
                            {...register(`gelo_seco_addresses.${index}.address_id`)}
                            name={`gelo_seco_addresses.${index}.address_id`}
                            label="Endereço"
                            placeholder="Selecione uma opção..."
                            options={sourceGeloSecoAddressesOptions}
                            error={
                              errors.gelo_seco_addresses
                                ? errors?.gelo_seco_addresses[index]?.address_id
                                : undefined
                            }
                            required
                          />
                          <Stack pt={[0, 0, 8]}>
                            <Button
                              leftIcon={<Icon as={FaTimes} />}
                              variant="ghost"
                              onClick={() => handleRemoveGeloSecoAddress(index)}
                              h="48px"
                            >
                              Remover
                            </Button>
                          </Stack>

                        </Stack>

                        <Divider />
                      </Stack>
                    )
                  })}
                  {!isAllSourceAddressesAssignedWithGeloSeco && (
                    <Flex>
                      <Button
                        colorScheme="blue"
                        onClick={handleAppendGeloSecoAddress}
                        w="full"
                      >
                        Adicionar gelo seco no endereço
                      </Button>
                    </Flex>
                  )}
                </>
              )}

              {attachmentsFields.map((field, index) => {
                return (
                  <Stack key={field.id} w='full' spacing="24px" mt="4" direction={['column', 'column', 'row']}>
                    <Input
                      {...register(`attachments.${index}.attachment`)}
                      name={`attachments.${index}.attachment`}
                      label="Anexo"
                      type="file"
                      accept={supportedFileFormats.join(", ")}
                      error={errors.attachments ? errors?.attachments[index]?.attachment : undefined}
                      hidden
                    >
                      <Stack
                        w="full"
                        spacing="24px"
                        mt="4"
                        justifyContent='space-between'
                        direction={['column', 'column', 'row']}
                      >
                        <Button
                          as={FormLabel}
                          htmlFor={`attachments.${index}.attachment`}
                          _hover={{
                            cursor: 'pointer'
                          }}
                          mr="2"
                          leftIcon={<Icon as={FaFilePdf} />}
                        >
                          Upload
                        </Button>
                        {attachments && attachments[index]?.attachment?.length > 0 && (
                          <ChakraLink
                            href={URL.createObjectURL(attachments[index].attachment[0])}
                          >
                            {attachments[index].attachment[0].name} (Visualizar)
                          </ChakraLink>
                        )}
                        <Button
                          leftIcon={<Icon as={FaTimes} />}
                          variant="ghost"
                          onClick={() => handleRemoveAttachmentField(index)}
                          h="48px"
                        >
                          Remover
                        </Button>
                      </Stack>
                    </Input>
                  </Stack>
                )
              })}

              <Stack
                w="full"
                spacing="24px"
                mt="4"
                direction={['column', 'column', 'row']}
              >
                <Button
                  _hover={{
                    cursor: 'pointer'
                  }}
                  mr="2"
                  leftIcon={<Icon as={FaFilePdf} />}
                  onClick={handleAppendAttachmentField}
                >
                  Adicionar anexo
                </Button>
              </Stack>

              <TextArea
                {...register("observation")}
                name="observation"
                label="Observações"
              />
            </>
          )}
        </>
      )}
    </Flex>
  )
}

import { Td, Tr } from "@chakra-ui/react"
import { ReactNode } from "react"
import { formatDate } from "utils/DateFunctions/formatDate"

interface AddressLogTableRowProps {
  name: string
  prevValue: string | ReactNode
  newValue: string | ReactNode
  updatedBy: {
    firstname: string
    lastname: string
  }
  updatedAt: string
}

export function AddressLogTableRow({ name, prevValue, newValue, updatedBy, updatedAt }: AddressLogTableRowProps) {
  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{prevValue}</Td>
      <Td>{newValue}</Td>
      <Td>{`${updatedBy.firstname} ${updatedBy.lastname}`}</Td>
      <Td>{formatDate.handle(updatedAt, "DateWithDateAndHourMinute")}</Td>
    </Tr>
  )
}

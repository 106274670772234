import { Box, Flex, Icon, Text, ToastId, useToast } from "@chakra-ui/react";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { createContext, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Socket } from "socket.io-client";
import { socketApi } from "../services/socket/socket";

interface InternComunicationSocketContextProps {
  internComunicationSocketConnection: Socket | null
}

interface InternComunicationSocketProviderProps {
  children: ReactNode
}

interface InternComunicationCreatedNotificationProps {
  comunication_responsible: string
}
interface InternComunicationAnsweredNotificationProps {
  comunication_answer: string
  comunication_owner: string
}
interface InternComunicationValidatedNotificationProps {
  comunication_question: string
  comunication_responsible: string
  is_satisfactory_answer: 'SIM' | 'NÃO'

}

export const InternComunicationSocketContext = createContext({} as InternComunicationSocketContextProps)

export function InternComunicationSocketProvider({ children }: InternComunicationSocketProviderProps) {

  const [internComunicationSocketConnection, setInternComunicationSocketConnection] = useState<Socket | null>(null)

  const { infoMessage } = useToastify()
  const { userLogged } = useAuth()

  const queryClient = useQueryClient()

  const toast = useToast()
  const toastIdRef = useRef<null | ToastId>(null)

  const history = useHistory()

  const closeToast = useCallback(() => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }, [toastIdRef, toast])

  const addToast = useCallback((message: any) => {
    toastIdRef.current = toast({
      position: 'top-right',
      status: 'info',
      isClosable: true,

      render: () => (
        <Box rounded="md" shadow="base" position="relative" color='white' p={3} bg='blue.500'>
          <Flex direction="column" w="90%">
            <Text textAlign="left">
              Foi aberta uma nova ocorrência de atraso
            </Text>
            <Text
              onClick={() => history.push(`/comunicacoes-internas?type=team&comunicationProtocol=${message.internComunicationProtocol}`)}
              display="flex"
              alignItems="center"
              justifyContent="start"
              gap="2"
              cursor="pointer"
              textAlign="left"
            >
              Responder comunicação
              <FaExternalLinkAlt />
            </Text>
          </Flex>
          <Icon
            as={FaX}
            position="absolute"
            right="2"
            top="2"
            onClick={closeToast}
            cursor="pointer"
          />

        </Box>
      )
    })
  }, [history, toast, closeToast])

  useEffect(() => {
    setInternComunicationSocketConnection(socketApi.connect("intern-comunication"))

    return () => setInternComunicationSocketConnection(null)
  }, [])

  useEffect(() => {
    internComunicationSocketConnection?.on("delayOccurrenceInternComunicationCreated", async (message: {
      internComunicationProtocol: number,
      profilesCanReceive: string[]
    }) => {

      const userCanReceiveNotification = message.profilesCanReceive.includes(userLogged?.user_type)

      if (userCanReceiveNotification) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

        addToast(message)
      }
    })

    internComunicationSocketConnection?.on("getInternComunicationCreatedNotification", async ({
      comunication_responsible
    }: InternComunicationCreatedNotificationProps) => {
      const isUserLoggedInternComunicationResponsible = userLogged?.id === comunication_responsible

      if (isUserLoggedInternComunicationResponsible) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

        const internComunicationCreatedMessage = `Você tem uma nova comunicação interna para responder!`

        infoMessage(internComunicationCreatedMessage)
      }
    })

    internComunicationSocketConnection?.on("getInternComunicationAnsweredNotification", async ({
      comunication_owner,
      comunication_answer
    }: InternComunicationAnsweredNotificationProps) => {
      const isUserLoggedInternComunicationOwner = userLogged?.id === comunication_owner

      if (isUserLoggedInternComunicationOwner) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

        const internComunicationAnsweredMessage = `Uma comunicação interna que você abriu foi respondida!`

        infoMessage(internComunicationAnsweredMessage)
      }
    })

    internComunicationSocketConnection?.on("getInternComunicationValidatedNotification", async ({
      comunication_question,
      comunication_responsible,
      is_satisfactory_answer
    }: InternComunicationValidatedNotificationProps) => {
      const isUserLoggedInternComunicationResponsible = userLogged?.id === comunication_responsible

      if (isUserLoggedInternComunicationResponsible) {
        queryClient.invalidateQueries({ queryKey: ['user-notifications'] })

        let internComunicationValidatedMessage = ''

        if (is_satisfactory_answer === 'SIM') {
          internComunicationValidatedMessage = `Uma comunicação interna foi validada e concluída!`
        } else {
          internComunicationValidatedMessage = `Foi solicitada uma nova resposta para uma comunicação interna!`
        }

        infoMessage(internComunicationValidatedMessage)
      }
    })

    return () => {
      internComunicationSocketConnection?.off("delayOccurrenceInternComunicationCreated")
      internComunicationSocketConnection?.off("getInternComunicationCreatedNotification")
      internComunicationSocketConnection?.off("getInternComunicationAnsweredNotification")
      internComunicationSocketConnection?.off("getInternComunicationValidatedNotification")
    }
  }, [internComunicationSocketConnection, queryClient, userLogged, addToast, infoMessage])

  return (
    <InternComunicationSocketContext.Provider value={{
      internComunicationSocketConnection
    }}>
      {children}
    </InternComunicationSocketContext.Provider>
  )
}

import { useState } from 'react'
import { useQuery } from 'react-query'
import {
  getAllOccurrences,
  getOneOccurrence,
} from '../../services/getFunctions/occurrence/getOccurrences'
import { useAuth } from '../auth/useAuth'
import { useToastify } from '../toastify/useToastify'

const useOccurrence = (isEnable?: boolean, refetchOnFocus?: boolean, step?: string) => {
  const [occurrenceId, setOccurrenceId] = useState('')
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useToastify()

  const handleSetOccurrenceId = (occurrenceIdValue: string) =>
    setOccurrenceId(occurrenceIdValue)

  const occurrences = useQuery('occurrences', () => getAllOccurrences(step), {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (error: any) => {
      errorMessage(error.message)
      handleLogout()
    },
  })
  const occurrence = useQuery(
    ['occurrence', occurrenceId],
    () => getOneOccurrence(occurrenceId),
    {
      enabled: !!occurrenceId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message)
        handleLogout()
      },
    },
  )

  return { occurrences, occurrence, handleSetOccurrenceId }
}

export { useOccurrence }

import { cnpj, cpf } from 'cpf-cnpj-validator'

const isCpfValid = (value: string, originalValue: string) => {
  if (value === ' ') {
    return ' '
  }
  return cpf.isValid(originalValue) ? originalValue : ''
}

const isCnpjValid = (value: string, originalValue: string) => {
  return cnpj.isValid(originalValue) ? originalValue : ''
}

export { isCpfValid, isCnpjValid }

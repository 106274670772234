import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import {
  getAllRecurrentServices,
  getOneRecurrentService,
} from '../../services/getFunctions/recurrentService/getRecurrentService'
import { useAuth } from '../auth/useAuth'

export function useRecurrentService(
  recurrentServiceId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
  flag?: string,
) {
  const { handleLogout, userLogged } = useAuth()

  const recurrentServices = useQuery(
    'recurrentServices',
    getAllRecurrentServices,
    {
      enabled: !!isEnable && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )
  const recurrentService = useQuery(
    ['recurrentService', recurrentServiceId],
    () =>
      getOneRecurrentService(
        recurrentServiceId !== null ? recurrentServiceId : '',
      ),
    {
      enabled: !!recurrentServiceId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        toast.error('Erro ao consultar informações! Realize o login novamente.')
        handleLogout()
      },
    },
  )

  return {
    recurrentServices,
    recurrentService,
  }
}

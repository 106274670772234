import { Popover, PopoverTrigger, Button, Icon, Portal, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, PopoverFooter, ButtonGroup } from "@chakra-ui/react"
import { FaTrash } from "react-icons/fa"

interface DeleteThirdPartyRncPoppoverProps {
  id: string
  onDeleteThirdPartyRnc: (id: string) => Promise<void>
}

export function DeleteThirdPartyRncPoppover({
  id,
  onDeleteThirdPartyRnc
}: DeleteThirdPartyRncPoppoverProps) {

  async function handleDeleteThirdPartyRnc() {
    await onDeleteThirdPartyRnc(id)
  }

  return (
    <Popover
      returnFocusOnClose={false}
      placement='auto-start'
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              variant='ghost'
              colorScheme='red'
            >
              <Icon as={FaTrash} />
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>Confirmação</PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                Deseja continuar e excluir a rnc?
              </PopoverBody>
              <PopoverFooter display='flex' justifyContent='flex-end'>
                <ButtonGroup size='sm'>
                  <Button
                    onClick={onClose}
                    variant='outline'
                  >
                    Cancelar
                  </Button>
                  <Button
                    colorScheme='red'
                    onClick={handleDeleteThirdPartyRnc}
                  >
                    Confirmar
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </>
      )
      }
    </Popover>
  )
}

import { useParams, useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { HubForm } from './HubForm'
import { GeneralContentLoading } from '../../components/Loading/GeneralContentLoading'
import { useHubFunctions } from '../../hooks/hub/useHubFunctions'
import { useSwal } from '../../hooks/swal/useSwal'
import { useAuth } from '../../hooks/auth/useAuth'

interface QueryUrlProps {
  id: string
  slug: string
}

interface IFormInputProps {
  name: string
  state: string
  situation: string
  regional: number
  timezone: string
  hub_responsible: string
  observation: string
}

export function HubDetailEdit() {
  const [isLoading, setIsLoading] = useState(false)

  const {
    editHub: {
      mutate: editHub,
      isLoading: isMutationEditLoading,
      isError: isMutationEditError,
    },
  } = useHubFunctions()
  const { confirmMessage, standardMessage } = useSwal()
  const { userLogged } = useAuth()

  const { id, slug }: QueryUrlProps = useParams()
  const { push: redirectTo } = useHistory()

  useEffect(() => {
    function run() {
      const permissions = userLogged?.permissions
      try {
        if (permissions !== undefined) {
          if (slug === 'editar') {
            if (!permissions?.includes('edit-hub')) {
              redirectTo('/')
            }
          }
          if (slug === 'visualizar') {
            if (!permissions?.includes('view-hub')) {
              redirectTo('/')
            }
          }
        }
      } catch {
        redirectTo('/')
      }
    }
    run()
  }, [slug, userLogged, redirectTo])

  async function handleEditHub(values: IFormInputProps) {
    const response = await confirmMessage({ title: 'Deseja Editar um Hub?' })

    const reqEditParams = {
      hubId: id,
      hubInput: values,
    }

    if (response) {
      editHub({ ...reqEditParams })

      if (isMutationEditLoading) {
        setIsLoading(true)
      }

      if (isMutationEditError) {
        setIsLoading(false)
      }
    } else {
      standardMessage('Ação cancelada com êxito!')
    }
  }

  if (isLoading) {
    return <GeneralContentLoading />
  }

  if (slug === 'visualizar') {
    return (
      <HubForm
        action="Editar"
        id={id}
        slug={slug}
        title="Visualizar HUB"
        submit={async () => {}}
        isDisabled={true}
        href={`/hub/editar/${id}`}
      />
    )
  }
  return (
    <HubForm
      title="Editar HUB"
      slug={slug}
      id={id}
      action="Salvar"
      submit={handleEditHub}
      href=""
    />
  )
}

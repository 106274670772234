import { api } from "services/api"
import request from "axios"
import { useQuery, UseQueryOptions } from "react-query"
import { RequestError } from "utils/errors/RequestErrors"
import { useToastify } from "hooks/toastify/useToastify"
import { useAuth } from "hooks/auth/useAuth"
import { Holiday } from "./dtos/Holiday"

interface HolidayQueryParams {
  cities?: string[]
  city_id?: string
  start_date?: Date
  end_date?: Date
}

type QueryOptions = UseQueryOptions<Holiday[], unknown, Holiday[], ["holidays"]>

interface UseHolidaysOptions {
  queryOptions?: QueryOptions
  queryParams?: HolidayQueryParams
}

export function useHolidays(options?: UseHolidaysOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  async function getHolidays(queryParams?: HolidayQueryParams) {
    try {
      const { data } = await api.get(`/holiday/`, {
        params: queryParams && {
          ...queryParams,
          cities: queryParams?.cities && queryParams.cities?.join(",")
        }
      })

      return data
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        const { status, data: { message } } = error.response

        throw new RequestError(message, status)
      } else {
        throw new RequestError("Erro inesperado!")
      }
    }
  }

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery(["holidays", {
    start_date: options.queryParams.start_date,
    end_date: options.queryParams.end_date,
  }], () => getHolidays(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

import { useQuery } from 'react-query'
import {
  getAllBusinessBudgetsReqFunction,
  getOneBusinessBudgetReqFunction,
} from '../../utils/RequestFunctions/BusinessBudget/requestBusinessBudgetFunctions'
import { useAuth } from '../auth/useAuth'
import { useSwal } from '../swal/useSwal'

export function useBusinessBudget(id?: string, refetchOnFocus?: boolean) {
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useSwal()

  const businessBudgets = useQuery(
    'businessBudgets',
    getAllBusinessBudgetsReqFunction,
    {
      enabled: !id && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.message.includes('Token')) {
          handleLogout()
        }
      },
    },
  )

  const businessBudget = useQuery(
    ['businessBudget', id],
    () => getOneBusinessBudgetReqFunction(id || ''),
    {
      enabled: !!id && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
        if (error.message.inclues('Token')) {
          handleLogout()
        }
      },
    },
  )

  return {
    businessBudgets,
    businessBudget,
  }
}

import { Button, Flex, Image, Stack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../../../components/Buttons/SubmitButton";
import { StandardBackgroundForm } from "../../../../components/Form/StandardBackgroundForm";
import { Input } from "../../../../components/Inputs/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICreateGeloSecoMaintenanceFormData } from "../CreateGeloSecoMaintenancePage";

interface ICreateGeloSecoMaintenanceFormProps {
  submit: (data: ICreateGeloSecoMaintenanceFormData) => Promise<void>;
}

const schema = yup.object().shape({
  replenished_gelo_seco: yup.number().typeError("Campo Obrigatório!").required("Campo Obrigatório!"),
  open_box_photo: yup.mixed().required("Campo Obrigatório!"),
})

export function CreateGeloSecoMaintenanceForm({ submit }: ICreateGeloSecoMaintenanceFormProps) {
  const [previewOpenBoxPhoto, setPreviewOpenBoxPhoto] = useState<string | undefined>(undefined)
  const [selectedOpenBoxPhoto, setSelectedOpenBoxPhoto] = useState<File | null>(null)

  const {
    handleSubmit, register, setValue, formState: { errors, isSubmitting }
  } = useForm<ICreateGeloSecoMaintenanceFormData>({
    resolver: yupResolver(schema)
  })

  const openBoxImageRef = useRef<HTMLInputElement | null>(null)
  const handleRefOpenBoxImage = () => openBoxImageRef.current?.click()

  useEffect(() => {
    if (!selectedOpenBoxPhoto) {
      setPreviewOpenBoxPhoto(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedOpenBoxPhoto)
    setPreviewOpenBoxPhoto(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedOpenBoxPhoto])

  function handleGetOpenBoxPhoto(e: { target: HTMLInputElement }) {

    if (!e.target.files || e.target.files.length === 0) {
      setValue("open_box_photo", null)
      setSelectedOpenBoxPhoto(null)
      return
    }

    setValue("open_box_photo", e.target.files[0])
    setSelectedOpenBoxPhoto(e.target.files[0])
  }

  return (
    <StandardBackgroundForm onSubmit={handleSubmit(submit)} title="Manutenção de Gelo Seco">
      <Stack direction="column">
        <Input
          {...register("replenished_gelo_seco")}
          name="replenished_gelo_seco"
          type="number"
          label="Gelo seco reposto (qtde)"
          error={errors.replenished_gelo_seco}
          required
        />

        <Stack direction={['column', 'column', 'row']}>
          <Input
            {...register("open_box_photo")}
            name="open_box_photo"
            label="Foto da caixa aberta"
            type="file"
            accept="image/*"
            onChange={handleGetOpenBoxPhoto}
            hidden
            error={errors.open_box_photo}
            ref={openBoxImageRef}
            required
          >
            <Button
              onClick={handleRefOpenBoxImage}
            >
              Upload
            </Button>
          </Input>
          {!!previewOpenBoxPhoto && (
            <Image
              boxSize="200px"
              border="none"
              outline="none"
              objectFit="cover"
              src={previewOpenBoxPhoto}
            />
          )}
        </Stack>
      </Stack>
      <Flex mt="4" justify="flex-end">
        <SubmitButton
          action="Salvar"
          isSubmitting={isSubmitting}
        />
      </Flex>
    </StandardBackgroundForm>
  )
}

import { usePagination } from "@ajna/pagination";
import { Box, Button, Flex, FormControl, FormLabel, Heading, IconButton, Input, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { getInBoardServices } from "api/service/getInBoardServices";
import { Pagination } from "components/Pagination/Pagination";

import { useAuth } from "hooks/auth/useAuth";
import { useSearchParams } from "hooks/useSearchParams";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaFilter, FaRedo, FaSearch, FaTimes } from "react-icons/fa";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { BoardsTableRow } from "./components/BoardsTableRow";

interface BoardsFiltersSchema {
  protocol: { value: string }[]
  customer: { value: string }[]
  boardDate: string
  boardHour: string
  boardInfo: string
  sourceAddress: string
  destinationAddress: string
  sourceCollector: string
  destinationCollector: string
}

export function Boards() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE
  const { push: redirect, replace } = useHistory()
  const { userLogged } = useAuth()
  const searchParams = useSearchParams()

  const page = searchParams.get('page') ?? '1'
  const userLoggedHasViewBoardsPermission = userLogged?.permissions.includes("view-boards")

  const queryClient = useQueryClient()

  useEffect(() => {
    if (!userLoggedHasViewBoardsPermission) {
      redirect('/')
    }
  }, [userLoggedHasViewBoardsPermission, redirect])

  const protocol = searchParams.get('protocol')
  const customer = searchParams.get('customer')
  const boardDate = searchParams.get('boardDate')
  const boardHour = searchParams.get('boardHour')
  const boardInfo = searchParams.get('boardInfo')
  const sourceAddress = searchParams.get('sourceAddress')
  const destinationAddress = searchParams.get('destinationAddress')
  const sourceCollector = searchParams.get('sourceCollector')
  const destinationCollector = searchParams.get('destinationCollector')

  const {
    register,
    handleSubmit,
    reset,
    control,
  } = useForm<BoardsFiltersSchema>({
    defaultValues: {
      protocol: [{ value: '' }],
      customer: [{ value: '' }],
      boardDate,
      boardHour,
      boardInfo,
      sourceAddress,
      destinationAddress,
      sourceCollector,
      destinationCollector,
    }
  })

  const {
    fields: protocolFields,
    append: appendProtocol,
    remove: removeProtocol
  } = useFieldArray({
    control,
    name: 'protocol'
  });

  const {
    fields: customerFields,
    append: appendCustomer,
    remove: removeCustomer
  } = useFieldArray({
    control,
    name: 'customer'
  });

  const {
    data: inBoardServicesData,
    isLoading: isInBoardServicesDataLoading
  } = useQuery([
    'in-board-services',
    page,
    protocol,
    customer,
    boardDate,
    boardHour,
    boardInfo,
    sourceAddress,
    destinationAddress,
    sourceCollector,
    destinationCollector,
    userLogged?.collector_id,
  ], () => getInBoardServices({
    currentPage: page,
    pageSize: rowsPerPage,
    protocol,
    customer,
    sourceAddress,
    destinationAddress,
    boardDate,
    boardHour,
    boardInfo,
    timezoneOffset: String(new Date().getTimezoneOffset() / 60),
    sourceCollector,
    destinationCollector,
    collectorId: userLogged?.collector_id,
  }))

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: inBoardServicesData?.meta?.totalPages,
      initialState: {
        currentPage: Number(page),
        pageSize: inBoardServicesData?.meta?.pageSize
      },
    });

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    searchParams.set('page', page.toString())

    replace({ search: searchParams.toString() })
  }

  function handleFilterBoards(data: BoardsFiltersSchema) {
    if (data.boardDate) {
      searchParams.set('boardDate', data.boardDate)
    } else {
      searchParams.delete('boardDate')
    }

    if (data.boardHour) {
      searchParams.set('boardHour', data.boardHour)
    } else {
      searchParams.delete('boardHour')
    }

    if (data.boardInfo) {
      searchParams.set('boardInfo', data.boardInfo)
    } else {
      searchParams.delete('boardInfo')
    }

    if (data.customer) {
      searchParams.set('customer', data.customer.map(customer => customer.value).join(','))
    } else {
      searchParams.delete('customer')
    }

    if (data.destinationAddress) {
      searchParams.set('destinationAddress', data.destinationAddress)
    } else {
      searchParams.delete('destinationAddress')
    }

    if (data.protocol) {
      searchParams.set('protocol', data.protocol.map(protocol => protocol.value).join(','))
    } else {
      searchParams.delete('protocol')
    }

    if (data.sourceAddress) {
      searchParams.set('sourceAddress', data.sourceAddress)
    } else {
      searchParams.delete('sourceAddress')
    }

    if (data.sourceCollector) {
      searchParams.set('sourceCollector', data.sourceCollector)
    } else {
      searchParams.delete('sourceCollector')
    }

    if (data.destinationCollector) {
      searchParams.set('destinationCollector', data.destinationCollector)
    } else {
      searchParams.delete('destinationCollector')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    searchParams.delete('boardDate')
    searchParams.delete('boardHour')
    searchParams.delete('boardInfo')
    searchParams.delete('customer')
    searchParams.delete('destinationAddress')
    searchParams.delete('protocol')
    searchParams.delete('sourceAddress')
    searchParams.delete('sourceCollector')
    searchParams.delete('destinationCollector')

    searchParams.set('page', '1')

    reset({
      protocol: [{ value: '' }],
      customer: [{ value: '' }],
      boardDate: '',
      boardHour: '',
      boardInfo: '',
      sourceAddress: '',
      destinationAddress: '',
      sourceCollector: '',
      destinationCollector: ''
    })

    replace({ search: searchParams.toString() })

  }

  function handleUpdateTable() {
    queryClient.invalidateQueries(['in-board-services'])
  }

  return (

    <Box
      borderRadius='8px'
      p={4}
      bg='white'
    >
      <Flex
        w="full"
        direction="column"
        gap={6}
      >
        <Heading letterSpacing="tight">Embarques</Heading>

        {isInBoardServicesDataLoading ? (
          <Spinner />
        ) : (
          <Box
            as="form"
            onSubmit={handleSubmit(handleFilterBoards)}
          >
            <Flex
              align="center"
              gap="2"
              mb="6"
            >
              <Button
                type="submit"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaSearch />}
              >
                Filtrar resultados
              </Button>

              <Button
                type="button"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaTimes />}
                variant="ghost"
                onClick={handleRemoveFilters}
              >
                Remover filtros
              </Button>
              <Button
                type="button"
                size="sm"
                letterSpacing="1"
                leftIcon={<FaRedo />}
                variant="ghost"
                onClick={handleUpdateTable}
              >
                Atualizar página
              </Button>
            </Flex>

            <TableContainer
              rounded="md"
              border="1px solid"
              borderColor="gray.100"
            >
              <Table
                size="sm"
              >
                <Thead
                  h="40px"
                  bg="#38c3fa"
                >
                  <Tr>
                    <Th></Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Protocolo
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de protocolo"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {protocolFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Protocolo</FormLabel>
                                  <Input
                                    {...register(`protocol.${index}.value` as const)}
                                    placeholder="Protocolo"
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeProtocol(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label="Adicionar protocolo"
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendProtocol({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Cliente
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de cliente"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              {customerFields.map((field, index) => (
                                <FormControl
                                  key={field.id}
                                  position="relative"
                                >
                                  <FormLabel>Cliente</FormLabel>
                                  <Input
                                    {...register(`customer.${index}.value` as const)}
                                    placeholder="Cliente"
                                    size="sm"
                                    rounded="md"
                                    color="gray.900"
                                  />

                                  <IconButton
                                    aria-label=""
                                    position="absolute"
                                    top={3}
                                    right={-2}
                                    size="xs"
                                    border="3px solid"
                                    borderColor="white"
                                    rounded="full"
                                    icon={<FaMinus />}
                                    onClick={() => removeCustomer(index)}
                                  />
                                </FormControl>
                              ))}
                              <Flex w='full' justifyContent='flex-end'>
                                <IconButton
                                  aria-label=""
                                  icon={<FaPlus />}
                                  size="xs"
                                  colorScheme="blue"
                                  letterSpacing="1"
                                  mt={3}
                                  type="button"
                                  onClick={() => appendCustomer({ value: '' })}
                                />
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="400px" textColor="white">
                      <Flex align="center" gap="2">
                        Remetentes
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de remetentes"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('sourceAddress')}
                                name="sourceAddress"
                                placeholder="Rua, Responsável, Bairro, etc."
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Data limite de embarque
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de data limite de embarque"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('boardDate')}
                                name="boardDate"
                                placeholder="Data limite de embaque"
                                size="sm"
                                type="date"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Horário limite de embarque
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de horário limite de embarque"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('boardHour')}
                                name="boardHour"
                                size="sm"
                                type="time"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="200px" textColor="white">
                      <Flex align="center" gap="2">
                        Embarque
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de embarque"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('boardInfo')}
                                name="boardInfo"
                                placeholder="Base, Transportadora, etc."
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="400px" textColor="white">
                      <Flex align="center" gap="2">
                        Destinatários
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de destinatários"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="400px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('destinationAddress')}
                                name="destinationAddress"
                                placeholder="Rua, Responsável, Bairro, etc."
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th w="400px" textColor="white">Observações</Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Coletador de origem
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de coletador de origem"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('sourceCollector')}
                                name="sourceCollector"
                                placeholder="Nome fantasia"
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                    <Th textColor="white">
                      <Flex align="center" gap="2">
                        Coletador de destino
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              size="xs"
                              variant="ghost"
                              color="white"
                              _hover={{
                                bg: 'none',
                                opacity: 0.9
                              }}
                              aria-label="Abrir filtro de coletador de destino"
                              icon={<FaFilter />}
                            />
                          </PopoverTrigger>

                          <PopoverContent
                            w="200px"
                          >
                            <PopoverArrow />
                            <PopoverBody>
                              <Input
                                {...register('destinationCollector')}
                                name="destinationCollector"
                                placeholder="Nome fantasia"
                                size="sm"
                                rounded="md"
                                color="gray.900"
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {inBoardServicesData?.services.map(service => {
                    return (
                      <BoardsTableRow key={service.protocol.toString()} service={service} />
                    )
                  })}
                </Tbody>
              </Table>

            </TableContainer>
          </Box>
        )}

        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Flex>
    </Box>

  )
}

import { differenceInMinutes, set } from "date-fns";
import { Holiday } from "hooks/holiday/dtos/Holiday";

type ValidationTypes = 'DELIVERY' | 'COLLECT'

enum ValidationTypesEnum {
  DELIVERY = 'entrega',
  COLLECT = 'coleta'
}

export function validateIsHolidayOnServiceCollectDeliveryDate(
  type: ValidationTypes,
  holidays: Holiday[],
  cities: string[],
  date: Date,
): string | null {

  const dateWithDefaultHours = set(new Date(date), {
    hours: 12,
    minutes: 0,
    seconds: 0
  })

  let message: string | null = null

  holidays.forEach(holiday => {
    const holidayDateWithDefaultHours = set(new Date(holiday.date), {
      hours: 12,
      minutes: 0,
      seconds: 0
    })

    const isHolidayOnDate = differenceInMinutes(
      holidayDateWithDefaultHours,
      dateWithDefaultHours
    ) === 0

    if (isHolidayOnDate) {
      if (holiday.national) {
        message = `A data da ${ValidationTypesEnum[type]} é feriado nacional de ${holiday.name
          }. Deseja continuar?`
      } else {
        const isCityHoliday = cities.find(city => city === holiday.city_id)

        if (isCityHoliday) {
          message = `A data da ${ValidationTypesEnum[type]} é feriado de ${holiday.name
            } na cidade ${holiday.cityIDHoliday.name}. Deseja continuar?`
        }
      }
    }
  })

  return message
}

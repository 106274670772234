import { usePagination } from "@ajna/pagination"
import { Flex, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import { TableFilterButton } from "components/Filters/TableFilterButton"
import { Pagination } from "components/Pagination/Pagination"
import { SearchBox } from "components/SearchBox/SearchBox"
import { useFilterOptions } from "hooks/filterOptions/useFilterOptions"
import { OccurrenceReportData } from "hooks/report/dtos/OccurrenceReport"
import { useEffect } from "react"
import { useForm, useWatch } from "react-hook-form"
import { occurrenceStatusDict } from "utils/OccurrenceFunctions/occurrenceDict"
import { searchBoxFilter } from "utils/searchBoxFilter"

interface OccurrenceReportFilterOptionsInputs {
  protocol: string
  customer: string
  occurrence_type: string
  status: string
}

const occurrenceReportTableFilterOptions = [
  { key: 'protocol', value: 'Protocolo', checked: false },
  { key: 'customer', value: 'Cliente', checked: false },
  { key: 'occurrence_type', value: 'Tipo de ocorrência', checked: false },
  { key: 'status', value: 'Status', checked: false },
]

enum OccurrenceStepEnum {
  'AWAITING-PROCEDURE' = 'AGUARDANDO VALIDAÇÃO',
  'AWAITING-ALIGNMENT' = 'AGUARDANDO ALINHAMENTO',
  'AWAITING-ATTACHMENT' = 'AGUARDANDO ANEXO DE EVIDÊNCIAS',
  'OCCURRENCE-FINALIZED' = 'FINALIZADA'
}

interface OccurrenceReportTableProps {
  occurrenceReportData: OccurrenceReportData[]
}

export function OccurrenceReportTable({
  occurrenceReportData
}: OccurrenceReportTableProps) {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { filterOptions, onLoadSetFilterOptions } = useFilterOptions()

  useEffect(() => {
    function setFilterOptions() {
      onLoadSetFilterOptions(occurrenceReportTableFilterOptions)
    }
    setFilterOptions()
  }, [onLoadSetFilterOptions])

  const {
    control,
    register
  } = useForm()

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: occurrenceReportData.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: 1,
      },
    })

  function handlePageChange(page: number) {
    setCurrentPage(page)
  }

  const [
    protocolFiltered,
    customerFiltered,
    occurrenceTypeFiltered,
    statusFiltered
  ] = useWatch({
    control,
    name: ['protocol', 'customer', 'occurrence_type', 'status']
  })

  const occurrenceReportDataFiltered = occurrenceReportData
    ?.filter(occurrence => {
      const protocolFilter = protocolFiltered
        ? searchBoxFilter(String(occurrence.occurrenceProtocol), protocolFiltered)
        : occurrence.occurrenceProtocol

      const customerFilter = customerFiltered
        ? searchBoxFilter(occurrence.customer, customerFiltered)
        : occurrence.customer

      const occurrenceTypeFilter = occurrenceTypeFiltered
        ? searchBoxFilter(occurrence.type, occurrenceTypeFiltered)
        : occurrence.type

      const statusFilter = statusFiltered
        ? searchBoxFilter(
          occurrence.occurrenceProtocol,
          occurrenceStatusDict(statusFiltered)
        )
        : occurrence.occurrenceProtocol


      return protocolFilter && customerFilter && occurrenceTypeFilter && statusFilter
    })

  return (

    <Flex
      direction='column'
      gap={4}
    >
      <Flex justify='space-between' gap={4}>
        <TableFilterButton />
        <Stack direction='column'>
          {
            filterOptions
              .filter(option => option.checked)
              .map(option => {
                return (
                  <SearchBox
                    {...register(option.key as keyof OccurrenceReportFilterOptionsInputs)}
                    key={option.key}
                    size="sm"
                    placeholder={'Buscar ' + option.value}
                    handleSearch={() => { }}
                  />
                )
              })
          }
        </Stack>
      </Flex>
      <TableContainer>
        <Table variant='striped' size='sm'>
          <Thead>
            <Tr>
              {occurrenceReportTableFilterOptions.map(option => {
                return (
                  <Th>{option.value.toLocaleUpperCase()}</Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>
            {
              occurrenceReportDataFiltered
                .slice(offset, offset + Number(itemLimit))
                .map((occurrence, index) => {
                  return (
                    <Tr key={String(index)}>
                      <Td>{occurrence.occurrenceProtocol}</Td>
                      <Td>{occurrence.customer}</Td>
                      <Td>{occurrence.type}</Td>
                      <Td>{OccurrenceStepEnum[occurrence.step]}</Td>
                    </Tr>
                  )
                })
            }
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        handlePageChange={handlePageChange}
        pagesQuantity={pagesCount}
        pages={pages}
        currentPage={currentPage}
      />

    </Flex>
  )
}

import { Box, Flex, Grid, GridItem, Heading, Skeleton } from "@chakra-ui/react";
import { getLlmUsersDashboardMetrics, GetLlmUsersDashboardMetricsResponse } from "api/metrics/getLlmUsersDashboardMetrics";
import { useAuth } from "hooks/auth/useAuth";
import { FaBoxOpen, FaClipboardCheck, FaClock, FaComments, FaFileAlt, FaProjectDiagram } from "react-icons/fa";
import { useQuery } from "react-query";
import { captalize } from "utils/captalize";
import { DashboardCard } from "./components/DashboardCard";

function switchMetricTitle(key: keyof GetLlmUsersDashboardMetricsResponse) {
  switch (key) {
    case 'collectServicesWithDelayOccurrenceCount':
      return { title: 'Serviços Atrasados com Ocorrência de Atraso - Coleta', order: 0, isAlertCard: true, icon: FaFileAlt, path: '/dashboard/matriz/coletas-com-ocorrencias-de-atraso' }
    case 'deliveryServicesWithDelayOccurrenceCount':
      return { title: 'Serviços Atrasados com Ocorrência de Atraso - Entrega', order: 1, isAlertCard: true, icon: FaFileAlt, path: '/dashboard/matriz/entregas-com-ocorrencias-de-atraso' }
    case 'delayedCollectServicesCount':
      return { title: 'Coletas em Atraso ou Próximas a Atrasar', order: 2, isAlertCard: true, icon: FaClock, path: '/dashboard/matriz/coletas-atrasadas-ou-proximas-a-atrasar' }
    case 'delayedDeliveryServicesCount':
      return { title: 'Entregas em Atraso ou Próximas a Atrasar', order: 3, isAlertCard: true, icon: FaClock, path: '/dashboard/matriz/entregas-atrasadas-ou-proximas-a-atrasar' }
    case 'delayedBoardServicesCount':
      return { title: 'Serviços Atrasados - Embarque', order: 4, isAlertCard: true, icon: FaClock, path: '/dashboard/embarques-atrasados' }
    case 'delayedBoardValidationsCount':
      return { title: 'Validações de Embarque em Atraso', order: 5, isAlertCard: true, icon: FaClock, path: '/dashboard/validacoes-de-embarque-em-atraso' }
    case 'unsuccessCollectsPendingValidationCount':
      return { title: 'Coletas Sem Sucesso Pendente Validação', order: 6, icon: FaClipboardCheck, path: '/dashboard/matriz/coletas-sem-sucesso-pendente-validacao' }
    case 'collectsWithNewAddressesPendingValidationCount':
      return { title: 'Coletas com Novos Endereços para Validação', order: 7, icon: FaClipboardCheck, path: '/dashboard/matriz/coletas-com-novos-enderecos' }
    case 'unfinishedServicesWithDelayOccurrenceCount':
      return { title: 'Serviços não finalizados com ocorrência de atraso', order: 8, icon: FaFileAlt, path: '/dashboard/matriz/servicos-nao-finalizados-com-ocorrencia-de-atraso' }
    case 'toBoardValidateServicesCount':
      return { title: 'Embarques a Validar', order: 9, icon: FaClipboardCheck, path: '/dashboard/matriz/embarques-a-validar' }
    case 'toAllocateServicesCount':
      return { title: 'Serviços a Alocar', order: 10, icon: FaProjectDiagram, path: '/dashboard/matriz/embarques-a-alocar' }
    case 'internComunicationsCount':
      return { title: 'Comunicações Internas', order: 11, icon: FaComments, path: '/comunicacoes-internas' }
    case 'servicesRequestedToCancelCount':
      return { title: 'Solicitações de Cancelamento de Serviço Pendente Validação', order: 12, icon: FaClipboardCheck, path: '/dashboard/matriz/servicos-solicitados-para-cancelamento' }
    case 'servicesWithCancelAddressRequestAddressCount':
      return { title: 'Solicitações de Cancelamento de Endereço Pendente Validação', order: 13, icon: FaClipboardCheck, path: '/dashboard/matriz/servicos-com-solicitacoes-de-cancelamento-de-endereco-de-coleta' }
    case 'minStocksCount':
      return { title: 'Estoque Mínimo de Insumos', order: 14, isAlertCard: true, icon: FaBoxOpen, path: '/dashboard/estoques-abaixo-do-minimo' }
    case 'minProductsStocksCount':
      return { title: 'Estoque Mínimo de Produtos', order: 15, isAlertCard: true, icon: FaBoxOpen, path: '/dashboard/matriz/produtos-em-estoque-minimo-ou-abaixo' }
    default:
      return { title: '-', order: Infinity, icon: FaClock, path: '/' }

  }
}

export function NewMatrizDashboard() {
  const { userLogged } = useAuth()

  const userLoggedName = captalize(`${userLogged.firstname} ${userLogged.lastname}`)

  const {
    data: llmUsersDashboardMetricsResult,
    isLoading: isLoadingLlmUsersDashboardMetricsResult
  } = useQuery({
    queryKey: ['dashboard-metrics-llm'],
    queryFn: getLlmUsersDashboardMetrics
  })

  const metrics = llmUsersDashboardMetricsResult
    ? Object.entries(llmUsersDashboardMetricsResult)
      .map(([key, value]: [keyof GetLlmUsersDashboardMetricsResponse, number]) => {
        const metricInfo = switchMetricTitle(key)

        if (key === 'delayedCollectServicesCount') {
          return {
            title: metricInfo.title,
            order: metricInfo.order,
            isAlertCard: metricInfo?.isAlertCard,
            icon: metricInfo.icon,
            path: metricInfo.path,
            count: value,
            isComposed: true,
            composedCount: llmUsersDashboardMetricsResult?.nextToDelayCollectServicesCount
          }
        }

        if (key === 'delayedDeliveryServicesCount') {
          return {
            title: metricInfo.title,
            order: metricInfo.order,
            isAlertCard: metricInfo?.isAlertCard,
            icon: metricInfo.icon,
            path: metricInfo.path,
            count: value,
            isComposed: true,
            composedCount: llmUsersDashboardMetricsResult?.nextToDelayDeliveryServicesCount
          }
        }

        return {
          title: metricInfo.title,
          order: metricInfo.order,
          isAlertCard: metricInfo?.isAlertCard,
          icon: metricInfo.icon,
          path: metricInfo.path,
          count: value,
        }
      })
      .sort((a, b) => a.order - b.order)
    : []

  return (
    <Box
      p="6"
      rounded="md"
    >
      <Flex
        direction="column"
        gap="6"
      >
        <Heading letterSpacing="tight">Olá, {userLoggedName}</Heading>

        <Grid
          gap="6"
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
        >

          {isLoadingLlmUsersDashboardMetricsResult ? (
            Array.from({ length: 15 }).map((_, index) => {
              return (
                <GridItem key={index}>
                  <Skeleton rounded="xl" startColor="gray.300" endColor="gray.500" height="220px" w="full" />
                </GridItem>
              )
            })
          ) : (
            metrics.map((metric, index) => {
              return (
                <GridItem key={metric.title}>
                  <DashboardCard
                    title={metric.title}
                    count={metric.count}
                    isAlertCard={metric.isAlertCard}
                    icon={metric.icon}
                    path={metric.path}
                    isComposed={metric.isComposed}
                    composedCount={metric.composedCount}
                  />
                </GridItem>
              )
            })
          )}

        </Grid>

      </Flex>
    </Box>
  )
}

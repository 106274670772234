import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import request from "axios"
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { ExtrasAndDiscountsListResponse } from "./dtos/ExtrasAndDiscounts";

interface ExtrasAndDiscountsQueryParams {
  customer?: string
  date?: string
  identifier?: string
  current_page?: number
  page_size?: number
}

type QueryOptions = UseQueryOptions<ExtrasAndDiscountsListResponse, unknown, ExtrasAndDiscountsListResponse, QueryKey>

interface UseExtrasAndDiscountsOptions {
  queryKey?: string
  queryOptions?: QueryOptions
  queryParams?: ExtrasAndDiscountsQueryParams
}

async function getExtrasAndDiscounts(queryParams?: ExtrasAndDiscountsQueryParams) {
  try {
    const { data } = await api.get(`/extras-and-discounts`, {
      params: {
        ...queryParams
      }
    })

    return data
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response
      throw new RequestError(message, status)
    } else {
      throw new RequestError("Erro inesperado!")
    }
  }
}

export function useExtrasAndDiscounts(options?: UseExtrasAndDiscountsOptions) {

  const { errorMessage } = useToastify()
  const { handleLogout } = useAuth()

  const onRequestError = async (error: unknown) => {
    if (error instanceof RequestError) {
      errorMessage(error.message)

      if (error.httpCode === 401) await handleLogout()
    }
  }

  return useQuery([
    options?.queryKey || 'extrasAndDiscounts',
    { ...options?.queryParams }
  ], () => getExtrasAndDiscounts(options?.queryParams), {
    ...options?.queryOptions,
    refetchOnWindowFocus: false,
    onError: async (error) => onRequestError(error)
  })
}

import { useToastify } from '../toastify/useToastify'
import { useQuery } from 'react-query'
import { useAuth } from '../auth/useAuth'
import {
  getAllHolidays,
  getOneHoliday,
} from '../../services/getFunctions/holiday/getHoliday'

export function useHoliday(
  holidayId: string | null,
  isEnable?: boolean,
  refetchOnFocus?: boolean,
) {
  const { handleLogout, userLogged } = useAuth()
  const { errorMessage } = useToastify()

  const holidays = useQuery('holidays', getAllHolidays, {
    enabled: !!isEnable && !!userLogged,
    refetchOnWindowFocus: !!refetchOnFocus,
    onError: (e: any) => {
      errorMessage(e.message || 'Erro Inesperado!')
      handleLogout()
    },
  })

  const holiday = useQuery(
    ['holiday', holidayId],
    () => getOneHoliday(holidayId !== null ? holidayId : ''),
    {
      enabled: !!holidayId && !!userLogged,
      refetchOnWindowFocus: !!refetchOnFocus,
      onError: (e: any) => {
        errorMessage(e.message || 'Erro Inesperado!')
        handleLogout()
      },
    },
  )

  return {
    holidays,
    holiday,
  }
}

import { VStack } from "@chakra-ui/react";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";

import { SocketContext } from "contexts/SocketContext";
import { useRequestServiceFunctions } from "hooks/services/request/useRequestServiceFunctions";
import { useService } from "hooks/services/service";
import { useServiceNewRequestedAddresses } from "hooks/services/useServiceNewRequestedAddresses";
import { useToastify } from "hooks/toastify/useToastify";
import { useContext, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ValidateNewServiceAddresses } from "./components";

interface IQueryParams {
  serviceId: string
}

export function ValidateNewServiceAddressesPage() {
  const { serviceId }: IQueryParams = useParams()
  const { goBack } = useHistory()

  const { service } = useService(serviceId)

  const { packetSocketConnection } = useContext(SocketContext)

  const {
    data: newRequestedAddresses,
    isFetching: isNewRequestedAddressesFetching
  } = useServiceNewRequestedAddresses({
    serviceId: serviceId,
    queryOptions: {
      enabled: !!serviceId
    }
  })

  const hasRequestedAddressesToValidate = newRequestedAddresses
    ? newRequestedAddresses?.newRequestedCollectAddresses.length > 0 ||
    newRequestedAddresses?.newRequestedDeliveryAddresses.length > 0
    : true

  useEffect(() => {
    if (!hasRequestedAddressesToValidate) {
      goBack()
    }
  }, [hasRequestedAddressesToValidate, goBack, newRequestedAddresses])

  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()
  const {
    validateAddressToCollectService,
    validateRequestedDeliveryAddress
  } = useRequestServiceFunctions()

  async function handleValidateRequestedDeliveryAddress(
    addressId: string,
    status: 'validated' | 'invalidated'
  ) {
    const successMessage = status === 'validated'
      ? 'Endereço para entrega validado com sucesso!'
      : 'Endereço para entrega invalidado com sucesso!'

    await promiseMessage(validateRequestedDeliveryAddress.mutateAsync({
      id: service.data.serviceIDRequested.id,
      address_id: addressId,
      status
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('newServiceRequestedAddresses', {
          exact: true,
          refetchActive: true,
          refetchInactive: true,
        })
        await queryClient.invalidateQueries('servicesWithNewRequestedAddresses', {
          exact: true,
          refetchActive: true,
          refetchInactive: true,
        })
      }
    }), successMessage)
  }

  async function handleValidateRequestedCollectAddress(
    addressId: string,
    hasValidate: boolean
  ) {
    const successMessage = hasValidate
      ? 'Endereço para coleta validado com sucesso!'
      : 'Endereço para coleta invalidado com sucesso!'

    await promiseMessage(validateAddressToCollectService.mutateAsync({
      service_id: serviceId,
      validateAddressToCollectInput: {
        address_id: addressId,
        has_validate: hasValidate
      }
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('newServiceRequestedAddresses', {
          exact: true,
          refetchActive: true,
          refetchInactive: true,
        })
        await queryClient.invalidateQueries('servicesWithNewRequestedAddresses', {
          exact: true,
          refetchActive: true,
          refetchInactive: true,
        })
        packetSocketConnection.emit('sendNotification', {
          service_id: serviceId,
          address_id: addressId
        })
      }
    }), successMessage)
  }

  if (isNewRequestedAddressesFetching) {
    return <GeneralContentLoading />
  }

  return (

      <ValidateNewServiceAddresses.Root title="Validar Novos Endereços Solicitados">
        {newRequestedAddresses?.newRequestedCollectAddresses.length > 0 && (
          <ValidateNewServiceAddresses.Content
            title="Novos Endereços para Coleta"
          >
            {newRequestedAddresses?.newRequestedCollectAddresses?.map((address) => {

              const addressObservationsData: { address_id: string, observation: string }[] =
              service?.data?.serviceIDRequested.requested_collect_addresses_observations && service?.data?.serviceIDRequested.requested_collect_addresses_observations.length
                ? JSON.parse(service?.data.serviceIDRequested.requested_collect_addresses_observations as string)
                : null

              const addressObservations = addressObservationsData
                ? addressObservationsData.find((addressObservation) => addressObservation.address_id === address.id)?.observation
                : null

              return (
                <ValidateNewServiceAddresses.Card
                  key={address.id}
                  address={address}
                  observations={addressObservations}
                >
                  <VStack>
                    <ValidateNewServiceAddresses.Action
                      actionType="validate"
                      onClick={() => handleValidateRequestedCollectAddress(address.id, true)}
                    />
                    <ValidateNewServiceAddresses.Action
                      actionType="invalidate"
                      onClick={() => handleValidateRequestedCollectAddress(address.id, false)}
                    />
                  </VStack>
                </ValidateNewServiceAddresses.Card>
              )
            })}
          </ValidateNewServiceAddresses.Content>
        )}
        {newRequestedAddresses?.newRequestedDeliveryAddresses.length > 0 && (
          <ValidateNewServiceAddresses.Content
            title="Novos Endereços para Entrega"
          >
            {newRequestedAddresses?.newRequestedDeliveryAddresses?.map((address) => {
              return (
                <ValidateNewServiceAddresses.Card
                  key={address.id}
                  address={address}
                >
                  <VStack>
                    <ValidateNewServiceAddresses.Action
                      actionType="validate"
                      onClick={() => handleValidateRequestedDeliveryAddress(address.id, 'validated')}
                    />
                    <ValidateNewServiceAddresses.Action
                      actionType="invalidate"
                      onClick={() => handleValidateRequestedDeliveryAddress(address.id, 'invalidated')}
                    />
                  </VStack>
                </ValidateNewServiceAddresses.Card>
              )
            })}
          </ValidateNewServiceAddresses.Content>
        )}
      </ValidateNewServiceAddresses.Root>

  )
}

import { Flex, FlexProps } from "@chakra-ui/react"
import { ReactNode } from "react"

interface NotificationActionsProps extends FlexProps {
  children: ReactNode
}

export function NotificationActions({
  children,
  ...rest
}: NotificationActionsProps) {
  return (
    <Flex align="center" gap="2" {...rest}>
      {children}
    </Flex>
  )
}

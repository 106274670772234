import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface ValidateLandingParams {
  landingId: string
  body: {
    landingVolume: number
    isInvalid: boolean
  }
}

export async function validateLanding({
  landingId,
  body
}: ValidateLandingParams) {
  try {
    await api.put(`/services/landings/${landingId}/validate`, body)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

import { useEffect } from "react";
import { GeneralContentLoading } from "components/Loading/GeneralContentLoading";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory } from "react-router-dom";
import { useAggregates } from "hooks/aggregate/useAggregates";
import { ActivateAggregatesTable } from "./components/ActivateAggregatesTable";
import { InactiveAggregatesTable } from "./components/InactiveAggregatesTable";
import { QualifiedAggregatesTable } from "./components/QualifiedAggregatesTable";
import { ResignedAggregatesTable } from "./components/ResignedAggregatesTable";
import { AggregatesList } from "./AggregatesList";
import { useAggregateStatusTableButton } from "hooks/aggregate/useAggregateStatusTableButton";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";
import { AggregatesTableButtonStatus } from "contexts/AggregatesStatusTableButtonContext";

export enum AggregatesTablesTitlesByStatusEnum {
  active = 'Agregados Ativos',
  'in-qualification' = 'Agregados em Qualificação',
  'in-resigning' = 'Agregados em Desligamento',
  inactive = 'Agregados Inativos'
}

export function AggregateList() {
  const itemLimit = process.env.REACT_APP_ITEMS_PER_PAGE

  const { status, handleChangeStatus } = useAggregateStatusTableButton()

  const { userLogged } = useAuth()
  const { push: redirect, location } = useHistory()

  const userHasPermissionViewAggregates =
    userLogged?.permissions.includes("view-aggregate");

  useEffect(() => {
    if (!userHasPermissionViewAggregates) {
      redirect("/");
    }
  }, [userHasPermissionViewAggregates, redirect]);

  const statusFilter = location.search.match(`[?&]status=([^&]+)`)
  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const { data: aggregates, isFetching: isFetchingAggregates } = useAggregates({
    queryParams: {
      status: status
    }
  })

  const { pagesCount, pages, offset, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      total: aggregates?.length,
      initialState: {
        pageSize: Number(itemLimit),
        isDisabled: false,
        currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1
      },
    });

  useEffect(() => {
    if (!status && statusFilter) {
      handleChangeStatus(statusFilter[1] as AggregatesTableButtonStatus);
    }
  }, [status, statusFilter, handleChangeStatus])

  useEffect(() => {
    if (userHasPermissionViewAggregates) {
      redirect({
        pathname: '/agregados',
        search: `?page=${currentPage}&status=${status}`
      })
    }
  }, [currentPage, status, redirect, userHasPermissionViewAggregates])

  const slicedAggregates = aggregates?.slice(offset, offset + Number(itemLimit))

  if (isFetchingAggregates) {
    return <GeneralContentLoading />
  }

  return (
    <AggregatesList.Root title={AggregatesTablesTitlesByStatusEnum[status]}>
      {status === 'active' && (
        <ActivateAggregatesTable aggregatesActivated={slicedAggregates} />
      )}
      {status === 'inactive' && (
        <InactiveAggregatesTable aggregatesInactivated={slicedAggregates} />
      )}
      {status === 'in-qualification' && (
        <QualifiedAggregatesTable aggregatesQualified={slicedAggregates} />
      )}
      {status === 'in-resigning' && (
        <ResignedAggregatesTable aggregatesResigned={slicedAggregates} />
      )}

      <Pagination
        currentPage={currentPage}
        pagesQuantity={pagesCount}
        pages={pages}
        handlePageChange={setCurrentPage}
      />
    </AggregatesList.Root>
  )

}

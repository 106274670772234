import { Button, Flex, FormControl, FormLabel, Input, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Select, Stack, Text } from "@chakra-ui/react"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { addMonths, format, set } from "date-fns"
import { getInternClts } from "api/internClts/getInternClts"
import { getCltDrivers } from "hooks/cltDriver/useCltDrivers"
import { captalize } from "utils/captalize"
import AsyncSelect from "react-select/async"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useToastify } from "hooks/toastify/useToastify"
import { useMutation, useQueryClient } from "react-query"
import { createPassiveFeedback } from "api/feedbacks/createPassiveFeedback"
import { useEffect } from "react"

interface CreatePassiveFeedbackProps {
  onCloseModal: () => void
}

interface CreatePassiveFeedbackSchema {
  collaboratorType: 'Interno' | 'Motorista'
  internClt: { id: string, name: string, label: string, admissionDate: string }
  cltDriver: { id: string, name: string, label: string, admissionDate: string }
  deadline: string
}

const createPassiveFeedbackSchema = yup.object({
  collaboratorType: yup.string().required(),
  internClt: yup.mixed().when('collaboratorType', {
    is: 'Interno',
    then: yup.object().required(),
  }),
  cltDriver: yup.mixed().when('collaboratorType', {
    is: 'Motorista',
    then: yup.object().required(),
  }),
  deadline: yup.string().required().transform((value, originalValue, ctx) => {
    if (!value) return null

    const [year, month, day] = originalValue?.split('-').map(Number)
    value = set(new Date(), { date: day, month: month - 1, year, hours: 12 }).toISOString()

    return value
  }),

})


const collaboratorTypeOptions = [
  'Interno',
  'Motorista',
]

export function CreatePassiveFeedback({ onCloseModal }: CreatePassiveFeedbackProps) {
  const { promiseMessage } = useToastify()
  const queryClient = useQueryClient()

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: {
      errors,
      isSubmitting
    }
  } = useForm<CreatePassiveFeedbackSchema>({
    resolver: yupResolver(createPassiveFeedbackSchema)
  })

  const [collaboratorType, internClt, cltDriver] = useWatch({
    control,
    name: ['collaboratorType', 'internClt', 'cltDriver']
  })

  useEffect(() => {
    if (internClt) {
      setValue('deadline', String(format(addMonths(new Date(internClt?.admissionDate), 3), 'yyyy-MM-dd')))
    }

    if (cltDriver) {
      setValue('deadline', String(format(addMonths(new Date(cltDriver?.admissionDate), 3), 'yyyy-MM-dd')))
    }
  }, [internClt, setValue, cltDriver])

  async function llmCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ id: string, name: string, label: string, admissionDate: string }>> {
    const response = await getInternClts({ currentPage: 1, pageSize: 5, name: inputValue })

    return response.internClts.map(internClt => {
      return {
        id: internClt.id,
        name: internClt.name,
        label: internClt.name,
        admissionDate: internClt.admission_date
      }
    })
  }

  async function cltDriversCollaboratorsPromiseOptions(inputValue: string): Promise<Array<{ id: string, name: string, label: string, admissionDate: string }>> {
    const response = await getCltDrivers({ currentPage: 1, pageSize: 5, search: inputValue })

    return response.cltDrivers.map(cltDriver => {
      return {
        id: cltDriver.id,
        name: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        label: captalize(`${cltDriver.driver.firstname} ${cltDriver.driver.lastname}`),
        admissionDate: cltDriver?.admission_date
      }
    })
  }

  const { mutateAsync: createPassiveFeedbackFn } = useMutation({
    mutationFn: createPassiveFeedback,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: 'passives-feedbacks' })
      queryClient.invalidateQueries({ queryKey: 'scheduled-passives-feedbacks' })
      onCloseModal()
    }
  })

  async function handleCreatePassiveFeedback(values: CreatePassiveFeedbackSchema) {
    await promiseMessage(createPassiveFeedbackFn({
      body: {
        cltDriverId: values?.cltDriver?.id,
        internCltId: values?.internClt?.id,
        deadline: values.deadline
      }
    }), 'Feedback solicitado! ')
  }
  return (
    <ModalContent>
      <ModalHeader letterSpacing="tight">Solicitar Feedback</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        as="form"
        onSubmit={handleSubmit(handleCreatePassiveFeedback)}
      >
        <Stack
          spacing="6"
          direction={["column", "column", "row"]}
          mt="3"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Tipo de colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.collaboratorType}>
              <Select
                {...register('collaboratorType')}
                name="collaboratorType"
                placeholder="Selecione..."
                rounded="md"
              >
                {Object.entries(collaboratorTypeOptions).map(([key, value]) => (
                  <option key={key} value={value}>{value}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Colaborador
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.internClt}>
              {collaboratorType === 'Interno' && (
                <Controller
                  control={control}
                  name="internClt"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('internClt')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        })
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborator..."
                      loadOptions={llmCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}

              {collaboratorType === 'Motorista' && (
                <Controller
                  control={control}
                  name="cltDriver"
                  render={({ field }) => (
                    <AsyncSelect
                      {...register('cltDriver')}
                      name={field.name}
                      onChange={field.onChange}
                      value={field.value}
                      cacheOptions
                      defaultOptions
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: '1px',
                          borderRadius: '6px',
                          border: state.isFocused ? 'none' : '',
                          boxShadow: state.isFocused ? '0 0 0 2px #38c3fa' : ''
                        })
                      }}
                      noOptionsMessage={() => 'Não há colaboradores cadastrados ou encontrados!'}
                      placeholder="Selecione um colaborator..."
                      loadOptions={cltDriversCollaboratorsPromiseOptions}
                      isClearable={true}
                    />
                  )}
                />
              )}


            </FormControl>
          </Stack>

        </Stack>
        <FormControl isInvalid={!!errors?.deadline} mt={3}>
          <FormLabel fontSize="sm">
            Prazo para resposta
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <Input
            {...register('deadline')}
            name="deadline"
            type="date"
            size="sm"
            rounded="md"
          />
        </FormControl>
        <Flex
          mt="6"
          w="full"
          justify="flex-end"
        >
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  )
}

import { Button, Flex, IconButton, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { GetAddressRequestedChangeResponse, getAddressRequestedChanges } from "api/addresses/getAddressRequestedChanges";
import { validateAddressRequestedChange } from "api/addresses/validateAddressRequestedChange";
import { validateAllAddressRequestedChanges } from "api/addresses/validateAllAddressRequestedChanges";
import { getCity } from "api/cities/getCity";
import { format } from "date-fns";
import { Address } from "hooks/address/dtos/Address";
import { useAuth } from "hooks/auth/useAuth";
import { useToastify } from "hooks/toastify/useToastify";
import { FaCheck, FaCheckDouble, FaTimes } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { captalize } from "utils/captalize";
import { RequestError } from "utils/errors/RequestErrors";
import { AddressesRequestedChangesChangeStatus } from "./AddressRequestedChangesChangeStatus";

interface AddressRequestedChangesDetailProps {
  addressRequestChangeId: string
}

interface ValidateAddressRequestedChangeProps {
  key: keyof Address
  status: 'approved' | 'reproved'
}

export function AddressRequestedChangesDetail({
  addressRequestChangeId
}: AddressRequestedChangesDetailProps) {
  const { userLogged } = useAuth()

  const userCanValidateAddressRequestedChanges = userLogged?.permissions.includes('validate-address-requested-changes')

  const {
    data: result,
    isLoading: isAddressRequestedChangesLoading
  } = useQuery({
    queryKey: ['address-requested-changes', addressRequestChangeId],
    queryFn: () => getAddressRequestedChanges(addressRequestChangeId)
  })

  function switchKeyName(key: keyof Address) {
    switch (key) {
      case 'customer_id':
        return 'Cliente'
      case 'branch':
        return 'Setor'
      case 'business_open':
        return 'Horário inicial (dias úteis)'
      case 'business_close':
        return 'Horário final (dias úteis)'
      case 'cep':
        return 'Cep'
      case 'city_id':
        return 'Cidade'
      case 'cnpj_cpf':
        return 'Cpf/Cnpj'
      case 'complement':
        return 'Complemento'
      case 'deadline':
        return 'Prazo'
      case 'has_own_board_landing':
        return 'Embarque próprio'
      case 'icms':
        return 'Icms'
      case 'neighborhood':
        return 'Bairro'
      case 'number':
        return 'Número'
      case 'reference_point':
        return 'Ponto de referência'
      case 'responsible_email':
        return 'Email do responsável'
      case 'responsible_name':
        return 'Nome do responsável'
      case 'responsible_telephone':
        return 'Telefone do responsável'
      case 'saturday_open':
        return 'Horário inicial (sábados)'
      case 'saturday_close':
        return 'Horário final (sábados)'
      case 'sunday_open':
        return 'Horário inicial (domingos)'
      case 'sunday_close':
        return 'Horário final (domingos)'
      case 'state':
        return 'Estado'
      case 'street':
        return 'Rua'
      case 'situation':
        return 'Situação'
      case 'trading_name':
        return 'Nome fantasia'
      case 'type':
        return 'Tipo'
      default:
        return key
    }
  }

  const changesHasCityId = !!result?.addressRequestedChange.changes.city_id

  const {
    data: cityResult,
  } = useQuery({
    queryKey: ['city', result?.addressRequestedChange.changes?.city_id?.new],
    queryFn: () => getCity(result?.addressRequestedChange.changes?.city_id?.new),
    enabled: changesHasCityId
  })

  const queryClient = useQueryClient()
  const { errorMessage } = useToastify()

  const { mutateAsync: validateAddressRequestedChangeFn } = useMutation({
    mutationFn: validateAddressRequestedChange,
    onSuccess: (_data, { body, params }) => {
      const cached = queryClient.getQueriesData<GetAddressRequestedChangeResponse>({
        queryKey: ['address-requested-changes']
      })

      cached.forEach(([queryKey, cachedData]) => {
        if (cachedData.addressRequestedChange.id === params.addressRequestChangeId) {
          cachedData.addressRequestedChange.changes[body.key].status = body.status
          cachedData.addressRequestedChange.changes[body.key].validatedByName = `${userLogged.firstname} ${userLogged.lastname}`
        }

        queryClient.setQueryData(queryKey, {
          ...cachedData,
        })
      })

    },
    onError: (error: RequestError) => {
      errorMessage(error.message)
    }
  })

  const { mutateAsync: validateAllAddressRequestedChangesFn } = useMutation({
    mutationFn: validateAllAddressRequestedChanges,
    onSuccess: (_data, { body, params }) => {
      const cached = queryClient.getQueriesData<GetAddressRequestedChangeResponse>({
        queryKey: ['address-requested-changes']
      })

      cached.forEach(([queryKey, cachedData]) => {

        if (cachedData.addressRequestedChange.id === params.addressRequestChangeId) {
          Object.entries(cachedData.addressRequestedChange.changes).forEach(([key, value]) => {
            cachedData.addressRequestedChange.changes[key] = {
              ...value,
              status: body.status,
              validatedByName: `${userLogged.firstname} ${userLogged.lastname}`
            }
          })
        }

        queryClient.setQueryData(queryKey, {
          ...cachedData,
        })
      })

    },
    onError: (error: RequestError) => {
      errorMessage(error.message)
    }
  })

  async function handleValidateAddressRequestedChange({
    key,
    status
  }: ValidateAddressRequestedChangeProps) {
    await validateAddressRequestedChangeFn({
      body: {
        key,
        status
      },
      params: {
        addressId: result?.addressRequestedChange.address.id,
        addressRequestChangeId,
      }
    })
  }

  async function handleValidateAllAddressRequestedChanges(
    status: 'approved' | 'reproved'
  ) {
    await validateAllAddressRequestedChangesFn({
      body: {
        status
      },
      params: {
        addressId: result?.addressRequestedChange.address.id,
        addressRequestChangeId,
      }
    })
  }

  return (
    <ModalContent>
      {isAddressRequestedChangesLoading ? (
        <Spinner />
      ) : (
        <>
          <ModalHeader letterSpacing="tight">
            {captalize(`${result.addressRequestedChange.address.customerIDAddress.trading_firstname} - ${result.addressRequestedChange.address.street}`)}
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody
            display="flex"
            flexDirection="column"
          >

            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>Campo</Th>
                    <Th>Atual</Th>
                    <Th>Solicitado</Th>
                    <Th>Status</Th>
                    <Th>Aprovado por</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(result.addressRequestedChange.changes).map(([key, value]) => {

                    return (
                      <Tr key={key}>
                        <Td>
                          {(value.status === 'pending' && userCanValidateAddressRequestedChanges) && (
                            <Flex
                              display="flex"
                              align="center"
                              gap="2"
                            >
                              <IconButton
                                aria-label="Reprovar"
                                icon={<FaTimes />}
                                size="xs"
                                variant="outline"
                                onClick={() => handleValidateAddressRequestedChange({
                                  key: key as keyof Address,
                                  status: 'reproved'
                                })}
                              />
                              <IconButton
                                aria-label="Aprovar"
                                icon={<FaCheck />}
                                size="xs"
                                colorScheme="blue"
                                onClick={() => handleValidateAddressRequestedChange({
                                  key: key as keyof Address,
                                  status: 'approved'
                                })}
                              />
                            </Flex>
                          )}

                        </Td>
                        <Td>{switchKeyName(key as keyof Address)}</Td>
                        {typeof value.prev === 'boolean' ? (
                          <Td>{value.prev ? 'Sim' : 'Não'}</Td>
                        ) : (
                          ((key.includes('open') || key.includes('close')) && typeof value.prev === 'string') ? (
                            <Td>{format(new Date(value.prev), 'HH:mm')}</Td>
                          ) : (
                            key === 'city_id' ? (
                              <Td>{captalize(result?.addressRequestedChange?.address?.cityIDAddress?.name)}</Td>
                            ) : (
                              <Td> {value.prev ?? '-'}</Td>
                            )
                          )
                        )}
                        {typeof value.new === 'boolean' ? (
                          <Td>{value.new ? 'Sim' : 'Não'}</Td>
                        ) : (
                          ((key.includes('open') || key.includes('close')) && typeof value.new === 'string') ? (
                            <Td>{format(new Date(value.new), 'HH:mm')}</Td>
                          ) : (
                            (key === 'city_id' && cityResult) ? (
                              <Td>{captalize(cityResult.name)}</Td>
                            ) : (
                              <Td>{value.new ?? '-'}</Td>
                            )
                          )
                        )}
                        <Td>{
                          <AddressesRequestedChangesChangeStatus status={value.status} />
                        }</Td>
                        <Td>{value.validatedByName ? captalize(value.validatedByName) : '-'}</Td>
                      </Tr>
                    )
                  })}
                </Tbody>

              </Table>
            </TableContainer>

            {(result.addressRequestedChange.status !== 'finished' && userCanValidateAddressRequestedChanges) && (
              <Flex alignSelf="end" gap="4" mt="6" mb="4">
                <Button
                  leftIcon={<FaTimes />}
                  alignSelf="end"
                  size="sm"
                  variant="outline"
                  rounded="lg"
                  onClick={() => handleValidateAllAddressRequestedChanges('reproved')}
                >
                  Reprovar todas
                </Button>
                <Button
                  leftIcon={<FaCheckDouble />}
                  alignSelf="end"
                  size="sm"
                  colorScheme="blue"
                  rounded="lg"
                  onClick={() => handleValidateAllAddressRequestedChanges('approved')}
                >
                  Aprovar todas
                </Button>
              </Flex>
            )}
          </ModalBody>
        </>
      )}
    </ModalContent >
  )
}

import { Flex, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Select, Spinner, Stack, Text } from "@chakra-ui/react";
import { DispatchStock } from "api/dispachStocks/dispatchStock";
import { format } from "date-fns";
import { useBranches } from "hooks/branch/useBranches";
import { useCollectorDispatchStockProposals } from "hooks/collector/useCollectorDispatchStockProposals";
import { useCollectors } from "hooks/collector/useCollectors";
import { useShipping } from "hooks/shipping/useShipping";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { dispatch_sender } from "utils/customLists";
import { dispatchStockVehicleTypesOptions } from "utils/CustomLists/dispatchStockVehicleTypesOptions";
import { dispatchStockWithdrawResponsiblesOptions } from "utils/CustomLists/dispatchStockWithdrawResponsiblesOptions";

export interface DispatchStockFormSchema {
  collectorId: string
  destinationCollectorId: string
  shippingId: string
  proposalId: string
  sender: string
  branchId: string
  boardDate: string
  arrivalForecast: string
  withdrawalResponsible: string
  vehicle: string
  invoiceTotalValue: string
}

interface DispatchStockFormProps {
  dispatchStock?: DispatchStock
}

const dateMin = format(new Date(), 'yyyy-MM-dd')

export function DispatchStockForm({ dispatchStock }: DispatchStockFormProps) {

  const {
    register,
    control,
    setValue,
    formState: {
      errors,
    }
  } = useFormContext<DispatchStockFormSchema>()

  const history = useHistory()

  const state = history.location.state as { collectorId?: string, proposalId?: string }


  const [
    destinationCollectorSelected,
    withdrawalResponsibleSelected,
    shippingSelected,
    branchSelected
  ] = useWatch({
    control,
    name: [
      'destinationCollectorId',
      'withdrawalResponsible',
      'shippingId',
      'branchId'
    ]
  })

  const {
    data: collectors
  } = useCollectors({
    queryParams: {
      situation: 'ATIVO',
    },
  })

  const {
    data: collectorDispatchStockProposalsData,
    isFetching: isFetchingCollectorDispatchStockProposalsData,
  } = useCollectorDispatchStockProposals({
    collectorId: destinationCollectorSelected,
    queryOptions: {
      enabled: !!destinationCollectorSelected
    }
  })

  const {
    shipping: { data: shipping },
  } = useShipping(null, true, false)


  const isCollectorWithdrawalResponsible =
    withdrawalResponsibleSelected === 'COLETADOR'

  const destinationCollectorHubList = collectors?.find(
    (collector) => collector.id === destinationCollectorSelected
  )?.hub_list

  const { data: branches } = useBranches({
    queryParams: {
      hubs: destinationCollectorHubList,
      shipping_id: shippingSelected
    }
  })

  useEffect(() => {
    if (collectors) {

      if (dispatchStock) {
        setValue('collectorId', dispatchStock.collector_id)
        setValue('destinationCollectorId', dispatchStock.destination_collector_id)
      } else if (state?.collectorId) {
        setValue('destinationCollectorId', state?.collectorId)
      }
    }
  }, [dispatchStock, collectors, setValue, state])

  useEffect(() => {
    if (collectorDispatchStockProposalsData) {
      if (dispatchStock) {
        setValue('proposalId', dispatchStock.proposal_id)
      } else if (state?.proposalId) {
        setValue('proposalId', state?.proposalId)
      }
    }
  }, [dispatchStock, collectorDispatchStockProposalsData, setValue, state])

  useEffect(() => {
    if (dispatchStock && branches) {
      setValue('branchId', dispatchStock.branch_id)
    }
  }, [dispatchStock, branches, setValue])

  useEffect(() => {
    if (dispatchStock && shipping) {
      setValue('shippingId', dispatchStock.shipping_id)
    }
  }, [dispatchStock, shipping, setValue])

  return (
    <Flex
      direction="column"
      gap="6"
      mt="6"
    >
      <Stack
        direction={["column", "column", "row"]}
        spacing={6}
        w="full"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormLabel fontSize="sm">
            Coletador de Origem
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.collectorId}>
            <Select
              {...register("collectorId")}
              name="collectorId"
              placeholder="Selecione..."
              size="md"
              rounded="md"
            >
              {collectors?.sort((a, b) => a.trading_name.localeCompare(b.trading_name))?.map((collector) => {
                return (
                  <option key={collector.id} value={collector.id}>{collector.trading_name.toLocaleUpperCase()}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>

        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormLabel fontSize="sm">
            Coletador de Destino
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.destinationCollectorId}>
            <Select
              {...register("destinationCollectorId")}
              name="destinationCollectorId"
              placeholder="Selecione..."
              size="md"
              rounded="md"
            >
              {collectors?.sort((a, b) => a.trading_name.localeCompare(b.trading_name))?.map((collector) => {
                return (
                  <option key={collector.id} value={collector.id}>{collector.trading_name.toLocaleUpperCase()}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>

      </Stack>
      {!!destinationCollectorSelected && (
        <>
          {isFetchingCollectorDispatchStockProposalsData ? (
            <Spinner />
          ) : (
            <Stack
              spacing="6"
              direction={["column", "column", "row"]}
              mt="3"
            >
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormLabel fontSize="sm">
                  Proposta
                  <Text as="sup" color="red.500">*</Text>
                </FormLabel>
                <FormControl isInvalid={!!errors.proposalId}>
                  <Select
                    {...register("proposalId")}
                    name="proposalId"
                    placeholder="Selecione..."
                    size="md"
                    rounded="md"
                  >
                    {collectorDispatchStockProposalsData?.dispatchStockProposals?.map((dispatchStockProposal) => {
                      return (
                        <option
                          key={dispatchStockProposal.id}
                          value={dispatchStockProposal.id}
                        >
                          {dispatchStockProposal.protocol}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                direction="column"
                w="full"
                spacing="0.25"
              >
                <FormControl isInvalid={!!errors?.invoiceTotalValue}>
                  <FormLabel fontSize="sm">
                    Valor da Total Proposta
                    <Text as="sup" color="red.500">*</Text>
                  </FormLabel>
                  <InputGroup size='md'>
                    <InputLeftAddon
                      children='R$'
                      rounded="md"
                    />
                    <Input
                      {...register('invoiceTotalValue')}
                      name="invoiceTotalValue"
                      size="md"
                      rounded="md"
                      isDisabled
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </Stack>
          )}
        </>
      )}
      {!!destinationCollectorSelected && (
        <Stack
          direction={["column", "column", "row"]}
          spacing={6}
          w="full"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Transportadora para Envio
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.shippingId}>
              <Select
                {...register("shippingId")}
                name="shippingId"
                placeholder="Selecione..."
                size="md"
                rounded="md"
              >
                {shipping?.sort((a, b) => a.company_name.localeCompare(b.company_name))?.map((shipping) => {
                  return (
                    <option key={shipping.id} value={shipping.id}>{shipping.company_name.toLocaleUpperCase()}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      )}
      {!!shippingSelected && (
        <Stack
          direction={["column", "column", "row"]}
          spacing={6}
          w="full"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Responsável Despacho
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.sender}>
              <Select
                {...register("sender")}
                name="sender"
                placeholder="Selecione..."
                size="md"
                rounded="md"
              >
                {dispatch_sender?.map((sender) => {
                  return (
                    <option key={sender.id} value={sender.name}>{sender.name}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Base de Destino
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.branchId}>
              <Select
                {...register("branchId")}
                name="branchId"
                placeholder="Selecione..."
                size="md"
                rounded="md"
              >
                {branches?.map((branch) => {
                  return (
                    <option key={branch.id} value={branch.id}>{
                      `${branch.nickname} - ${branch?.shippingIDBranch.company_name}`
                    }</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      )}
      <Stack
        direction={["column", "column", "row"]}
        spacing={6}
        w="full"
      >
        <Stack
          direction="column"
          w="full"
          spacing="0.25"
        >
          <FormLabel fontSize="sm">
            Responsável pela retirada
            <Text as="sup" color="red.500">*</Text>
          </FormLabel>
          <FormControl isInvalid={!!errors.withdrawalResponsible}>
            <Select
              {...register("withdrawalResponsible")}
              name="withdrawalResponsible"
              placeholder="Selecione..."
              size="md"
              rounded="md"
            >
              {dispatchStockWithdrawResponsiblesOptions?.map((responsible) => {
                return (
                  <option key={responsible.key} value={responsible.value}>{responsible.showOption}</option>
                )
              })}
            </Select>
          </FormControl>
        </Stack>
        {isCollectorWithdrawalResponsible && (
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormLabel fontSize="sm">
              Veículo
              <Text as="sup" color="red.500">*</Text>
            </FormLabel>
            <FormControl isInvalid={!!errors.vehicle}>
              <Select
                {...register("vehicle")}
                name="vehicle"
                placeholder="Selecione..."
                size="md"
                rounded="md"
              >
                {dispatchStockVehicleTypesOptions?.map((vehicle) => {
                  return (
                    <option key={vehicle.key} value={vehicle.value}>{vehicle.showOption}</option>
                  )
                })}
              </Select>
            </FormControl>
          </Stack>
        )}
      </Stack>
      {!!branchSelected && (
        <Stack
          direction={["column", "column", "row"]}
          spacing={6}
          w="full"
        >
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.boardDate}>
              <FormLabel fontSize="sm">
                Data do Embarque
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Input
                {...register('boardDate')}
                name="boardDate"
                type="date"
                rounded="lg"
                min={dateMin}
              />
            </FormControl>
          </Stack>
          <Stack
            direction="column"
            w="full"
            spacing="0.25"
          >
            <FormControl isInvalid={!!errors?.arrivalForecast}>
              <FormLabel fontSize="sm">
                Previsão de Chegada
                <Text as="sup" color="red.500">*</Text>
              </FormLabel>

              <Input
                {...register('arrivalForecast')}
                name="arrivalForecast"
                type="date"
                rounded="lg"
                min={dateMin}
              />
            </FormControl>
          </Stack>
        </Stack>
      )}
    </Flex>
  )
}

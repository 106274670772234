import { Button, Flex, Spinner } from "@chakra-ui/react";
import { StandardBackgroundForm } from "components/Form/StandardBackgroundForm";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useHistory} from "react-router-dom";
import { BillingsForm, BillingsFormInputs } from "./components/BillingsForm";
import * as yup from "yup"
import { validateHasFile } from "utils/imageValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToastify } from "hooks/toastify/useToastify";
import { useMutation } from "react-query";

import { useCustomers } from "hooks/customer/useCustomers";
import { createBilling } from "api/billings/createBilling";



const billingsFormSchema = yup.object().shape({
  startDate: yup.string().required('Campo obrigatório.'),
  endDate: yup.string().required('Campo obrigatório.'),
  dueDate: yup.string().required('Campo obrigatório.'),
  value: yup.string().required('Campo obrigatório.'),
  autoSendEmail: yup.mixed().required('Campo obrigatório.'),
  invoices: yup.mixed().when('$isUniqueBilling', {
    is: false,
    then: yup.mixed().test('minLength', 'Campo obrigatório', value => {
      return validateHasFile(value)
    })
  }),
  attachments: yup.mixed().when('$isUniqueBilling', {
    is: false,
    then: yup.mixed().test('minLength', 'Campo obrigatório', value => {
      return validateHasFile(value)
    })
  })
})

export function CreateBillings() {
  const { push: redirect } = useHistory()

  const {
    data: customers,
    isFetching: isFetchingCustomers
  } = useCustomers({
    queryParams: {
      situation: 'ATIVO'
    }
  })


  const formMethods = useForm<BillingsFormInputs>({
    resolver: yupResolver(billingsFormSchema),
     context: {
    isUniqueBilling: false
  }
  })

  const {  handleSubmit } = formMethods

  const { mutateAsync: createBillingFn } = useMutation({
    mutationFn: createBilling
  })

  const { promiseMessage } = useToastify()

  async function handleBillBilling(formValues: BillingsFormInputs) {
    const formData = new FormData()

    formData.append('customerId', formValues.customerId)
    formData.append('startDate', formValues.startDate)
    formData.append('endDate', formValues.endDate)
    formData.append('dueDate', formValues.dueDate)
    formData.append('value', formValues.value)
    formData.append('autoSendEmail', formValues.autoSendEmail)
    if (formValues.paymentSlips) {
      Object.entries(formValues.paymentSlips).forEach(
        ([key, paymentSlip]) => formData.append('paymentSlips', paymentSlip)
      )
    }
    Object.entries(formValues.invoices).forEach(
      ([key, invoice]) => formData.append('invoices', invoice)
    )
    Object.entries(formValues.attachments).forEach(
      ([key, attachment]) => formData.append('attachments', attachment)
    )

    await promiseMessage(createBillingFn({
      body: formData
    }, {
      onSuccess: async () => {
        redirect('/faturamentos')
      }
    }), 'Faturamento criado! 🎉')
  }

  return (
    <StandardBackgroundForm
      onSubmit={handleSubmit(handleBillBilling)}
      title='Adicionar Faturamento de Cliente'
    >
      { isFetchingCustomers ? (
        <Spinner />
      ) : (
        <FormProvider {...formMethods}>
          <BillingsForm  customers={customers}/>
        </FormProvider>
      )}

      <Flex w='full' mt={4} gap={2} justify='flex-end'>
        <Button
          type='submit'
          colorScheme='blue'
        >
          Enviar
        </Button>
        <Button
          as={Link}
          to='/faturamentos'
        >
          Cancelar
        </Button>

      </Flex>

    </StandardBackgroundForm>
  )
}

import { Button, Flex, Input, Select } from "@chakra-ui/react";
import { OnboardingStatus, OnboardingType } from "api/onboardings/onboarding";
import { useSearchParams } from "hooks/useSearchParams";
import { useForm } from "react-hook-form";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

interface OnboardingsTableFiltersSchema {
  status: OnboardingStatus | 'all'
  name: string
  trainingType: OnboardingType | 'all'
}

export function OnboardingsTableFilters() {
  const searchParams = useSearchParams()
  const { replace } = useHistory()

  const trainingType = searchParams.get('trainingType') ?? 'all'
  const name = searchParams.get('name')
  const status = searchParams.get('status') ?? 'all'

  const {
    register,
    handleSubmit,
    reset,
  } = useForm<OnboardingsTableFiltersSchema>({
    defaultValues: {
      trainingType: trainingType as OnboardingType | 'all',
      name: name ?? '',
      status: status as OnboardingStatus | 'all'
    }
  })

  async function handleFilterOnboardings(data: OnboardingsTableFiltersSchema) {
    if (data.trainingType) {
      searchParams.set('trainingType', data.trainingType)
    } else {
      searchParams.delete('trainingType')
    }

    if (data.name) {
      searchParams.set('name', data.name)
    } else {
      searchParams.delete('name')
    }
    if (
      data.status) {
      searchParams.set('status', data.status)
    } else {
      searchParams.delete('status')
    }

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }

  function handleRemoveFilters() {
    reset()
    searchParams.delete('name')
    searchParams.delete('trainingType')
    searchParams.delete('status')

    searchParams.set('page', '1')

    replace({ search: searchParams.toString() })
  }


  return (
    <Flex
      align="center"
      gap="2"
      direction={["column", "column", "row"]}
      mt="6"
      as="form"
      onSubmit={handleSubmit(handleFilterOnboardings)}
    >

      <Input
        {...register("name")}
        placeholder="Nome"
        size="sm"
        rounded="md"
        w={["full", "full", "min"]}
      />

      <Select
        {...register("status")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos status</option>
        <option value="inviting">Aguardando convite</option>
        <option value="training">Em treinamento</option>
        <option value="testing">Teste da cumbuca</option>
        <option value="inactive">Inativo</option>
        <option value="finished">Finalizados</option>
      </Select>

      <Select
        {...register("trainingType")}
        defaultValue="all"
        size="sm"
        rounded="md"
        w={["full", "full", "200px"]}
      >
        <option value="all">Todos os treinamentos</option>
        <option value="LLM - COMPRAS">LLM - COMPRAS</option>
        <option value="LLM - RH">LLM - RH</option>
        <option value="LLM - OPERAÇÃO MATRIZ">LLM - OPERAÇÃO MATRIZ</option>
        <option value="LLM - COMERCIAL">LLM - COMERCIAL</option>
        <option value="LLM - QUALIDADE">LLM - QUALIDADE</option>
        <option value="LLM - TI">LLM - TI</option>
        <option value="LLM - FINANCEIRO">LLM - FINANCEIRO</option>
        <option value="LLM - CULTURA ORGANIZACIONAL">LLM - CULTURA ORGANIZACIONAL</option>
        <option value="LLM - OPERAÇÃO REGIONAIS">LLM - OPERAÇÃO REGIONAIS</option>
        <option value="MOTOCICLISTA - MOTORISTA">MOTOCICLISTA - MOTORISTA</option>
        <option value="AGREGADOS">AGREGADOS</option>
        <option value="COLETADOR">COLETADOR</option>
        <option value="PORTADOR">PORTADOR</option>
      </Select>

      <Button
        size="sm"
        leftIcon={<FaSearch />}
        lineHeight="1"
        type="submit"
        w={["full", "full", "min"]}
      >
        Filtrar resultados
      </Button>

      <Button
        size="sm"
        leftIcon={<FaTimes />}
        lineHeight="1"
        variant="ghost"
        type="button"
        onClick={handleRemoveFilters}
        w={["full", "full", "min"]}
      >
        Remover filtros
      </Button>

    </Flex>
  )
}

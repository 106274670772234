import { Button, Flex, IconButton, Modal, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { approveDispatchStockProposal } from "api/dispachStocks/approveDispatchStockProposal";
import { deleteDispatchStockProposal } from "api/dispachStocks/deleteDispatchStockProposal";
import { DispatchStockProposal } from "api/dispachStocks/dispatchStockProposal";
import { GetDispatchStockProposalsResponse } from "api/dispachStocks/getDispatchStockProposals";
import { reproveDispatchStockProposal } from "api/dispachStocks/reproveDispatchStockProposal";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { Collector } from "hooks/collector/dtos/Collector";
import { useToastify } from "hooks/toastify/useToastify";
import { useEffect } from "react";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useMutation, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { captalize } from "utils/captalize";
import { DispatchStockProposalDetail } from "./DispatchStockProposalDetail";
import { DispatchStockProposalStatus } from "./DispatchStockProposalStatus";

interface DispatchStockProposalsTableRowProps {
  proposal: DispatchStockProposal & {
    collector: Collector
  }
}

export function DispatchStockProposalsTableRow({
  proposal
}: DispatchStockProposalsTableRowProps) {
  const { userLogged } = useAuth()

  const userCanApproveProposal = userLogged?.permissions.includes('approve-dispatch-stock-proposal')
  const userCanViewProposals = userLogged?.permissions.includes('view-dispatch-stock-proposal')
  const userCanPrepareProposal = userLogged?.permissions.includes('prepare-dispatch-stock-proposal')
  const userCanReproveProposal = userLogged?.permissions.includes('disapprove-dispatch-stock-proposal')
  const userCanDeleteProposal = userLogged?.permissions.includes('delete-dispatch-stock-proposal')

  const history = useHistory()

  useEffect(() => {
    if (!userCanViewProposals) history.push('/')
  }, [userCanViewProposals, history])

  const {
    isOpen: isDispatchStockProposalDetailModalOpen,
    onOpen: onOpenDispatchStockProposalDetailModal,
    onClose: onCloseDispatchStockProposalDetailModal,
  } = useDisclosure()

  const { promiseMessage } = useToastify()

  const queryClient = useQueryClient()

  const { mutateAsync: approveDispatchStockProposalFn } = useMutation({
    mutationFn: approveDispatchStockProposal,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetDispatchStockProposalsResponse>({
        queryKey: ['dispatch-stock-proposals']
      })

      cached.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) return

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          proposals: cachedData.proposals.map((proposal) => {
            if (proposal.id === routeParams.proposalId) {
              return { ...proposal, status: 'sending', approved_at: new Date() }
            }

            return proposal
          })
        })
      })
    }
  })

  async function handleApproveDispatchStockProposal(proposalId: string) {
    await promiseMessage(approveDispatchStockProposalFn({
      routeParams: { proposalId }
    }), 'Proposta aprovada! ✔️')
  }

  const { mutateAsync: reproveDispatchStockProposalFn } = useMutation({
    mutationFn: reproveDispatchStockProposal,
    onSuccess: (_data, { routeParams }) => {
      const cached = queryClient.getQueriesData<GetDispatchStockProposalsResponse>({
        queryKey: ['dispatch-stock-proposals']
      })

      cached.forEach(([cachedKey, cachedData]) => {
        if (!cachedData) return

        queryClient.setQueryData(cachedKey, {
          ...cachedData,
          proposals: cachedData.proposals.map((proposal) => {
            if (proposal.id === routeParams.proposalId) {
              return { ...proposal, status: 'reproved', reproved_at: new Date() }
            }

            return proposal
          })
        })
      })
    }
  })

  async function handleReproveDispatchStockProposal(proposalId: string) {
    await promiseMessage(reproveDispatchStockProposalFn({
      routeParams: { proposalId }
    }), 'Proposta reprovada!')
  }

  const { mutateAsync: deleteDispatchStockProposalFn } = useMutation({
    mutationFn: deleteDispatchStockProposal,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dispatch-stock-proposals'] })
    }
  })

  async function handleDeleteDispatchStockProposal(proposalId: string) {
    await promiseMessage(deleteDispatchStockProposalFn({
      proposalId
    }), 'Proposta de despacho excluída!')
  }


  return (
    <Tr>
      <Td>
        <IconButton
          aria-label=""
          icon={<FiSearch />}
          size="sm"
          onClick={onOpenDispatchStockProposalDetailModal}
          variant="ghost"
        />

        <Modal
          isOpen={isDispatchStockProposalDetailModalOpen}
          onClose={onCloseDispatchStockProposalDetailModal}
          size="4xl"
          isCentered
        >
          <DispatchStockProposalDetail proposalId={proposal.id} />
        </Modal>
      </Td>
      <Td>{proposal.protocol}</Td>
      <Td>{captalize(proposal.collector.trading_name)}</Td>
      <Td>{format(new Date(proposal.created_at), 'dd/MM/yyyy')}</Td>
      <Td>{proposal.approved_at ? format(new Date(proposal.approved_at), 'dd/MM/yyyy') : '-'}</Td>
      <Td>
        <DispatchStockProposalStatus status={proposal.status} />
      </Td>
      <Td>
        <Flex
          gap="3"
          align="center"
          h="full"
        >
          {proposal.status === 'approving' && userCanApproveProposal && (
            <Button
              leftIcon={<FaArrowRight />}
              size="xs"
              variant="ghost"
              lineHeight="1"
              onClick={() => handleApproveDispatchStockProposal(proposal.id)}
            >
              Aprovar
            </Button>
          )}

          {(proposal.status === 'sending' || proposal.status === 'sended') && userCanPrepareProposal && (
            <Button
              as={Link}
              to={{
                pathname: '/despachos/criar',
                state: {
                  proposalId: proposal.id,
                  collectorId: proposal.collector.id,
                }
              }}
              leftIcon={<FaArrowRight />}
              size="xs"
              variant="ghost"
              lineHeight="1"
            >
              Preparar
            </Button>
          )}

          {proposal.status === 'approving' && userCanReproveProposal && (
            <Button
              leftIcon={<FaTimes />}
              size="xs"
              variant="outline"
              colorScheme="red"
              lineHeight="1"
              onClick={() => handleReproveDispatchStockProposal(proposal.id)}
            >
              Reprovar
            </Button>
          )}

          {userCanDeleteProposal && proposal.status !== 'sended' && (
            <Button
              leftIcon={<FaTimes />}
              lineHeight="1"
              size="xs"
              variant="ghost"
              onClick={() => handleDeleteDispatchStockProposal(proposal.id)}
            >
              Excluir
            </Button>
          )}

        </Flex>
      </Td>
    </Tr>
  )
}

import { useMutation, useQueryClient } from 'react-query'
import {
  CreateProfileInputProps,
  createProfileReqFunction,
  deleteProfileReqFunction,
  editProfileReqFunction,
  EditProfileReqProps,
} from '../../utils/RequestFunctions/Profile/requestProfileFunctions'
import { useSwal } from '../swal/useSwal'

export const useProfileFunctions = () => {
  const queryClient = useQueryClient()
  const { successMessage, errorMessage } = useSwal()

  const createProfile = useMutation(
    'createProfile',
    (input: CreateProfileInputProps) => createProfileReqFunction(input),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['profiles'])
        await queryClient.invalidateQueries(['profile'])
        await successMessage('Perfil criado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const editProfile = useMutation(
    'editProfile',
    ({ input, id }: EditProfileReqProps) =>
      editProfileReqFunction({ input, id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['profiles'])
        await queryClient.invalidateQueries(['profile'])
        await successMessage('Perfil editado com sucesso!')
      },

      onError: (error: any) => {
        errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  const deleteProfile = useMutation(
    'deleteProfile',
    (id: string) => deleteProfileReqFunction(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['profiles'])
        await queryClient.invalidateQueries(['profile'])
        await successMessage('Perfil excluído com sucesso!')
      },

      onError: async (error: any) => {
        await errorMessage(error.message || 'Erro Inesperado!')
      },
    },
  )

  return {
    createProfile,
    editProfile,
    deleteProfile,
  }
}

import axios from "axios";
import { api } from "services/api";
import { RequestError } from "utils/errors/RequestErrors";

interface AdvertenceBody {
  userType: string
  userId: string
  advertenceType: string
  regional: string
  description: string
  date: string
}

interface CreateAdvertenceProps {
  body: AdvertenceBody
}

export async function createAdvertence({
  body
}: CreateAdvertenceProps) {
  try {
    const response = await api.post(`/advertences`, body)

    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data: { message } } = error.response

      throw new RequestError(message, status)
    }

    throw new RequestError('Erro inesperado!')
  }
}

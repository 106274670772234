import { Box, Button, IconButtonProps, Text, Tooltip } from "@chakra-ui/react";
import { useExternalComunicationsMetrics } from "hooks/externalComunications/useExternalComunicationsMetrics";
import { FaComment } from "react-icons/fa";

interface ExternalComunicationsTriggerButtonProps extends IconButtonProps {
  label?: string
}

export function ExternalComunicationsTriggerButton({
  label = 'Envie uma dúvida!',
  ...rest
}: ExternalComunicationsTriggerButtonProps) {

  const {
    data: externalComunicationsMetricsData,
    isFetching: isFetchingExternalComunicationsMetricsData,
  } = useExternalComunicationsMetrics({
    queryOptions: {
      refetchOnMount: true
    }
  })

  return (
    <Tooltip
      hasArrow
      label={label}
      placement="left"
      fontSize="sm"
      rounded="lg"
      boxShadow="md"
    >


      <Button
        aria-label="Comunicações externas"
        position="fixed"
        bottom="8"
        right="2"
        size="md"
        colorScheme="blue"
        boxShadow="md"
        rounded="lg"
        isLoading={isFetchingExternalComunicationsMetricsData}
        {...rest}
      >
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FaComment size="24" />
        </Box>
          {externalComunicationsMetricsData?.openExternalComunicationsCount > 0 && (
            <Text
              position="absolute"
              bgColor="red"
              top="-1"
              right="-1"
              rounded="full"
              w="5"
              h="5"
              fontSize="xs"
              display="flex"
              alignItems="center"
              justifyContent="center"
              // zIndex="tooltip"
            >
              {externalComunicationsMetricsData.openExternalComunicationsCount}
            </Text>
          )}
      </Button>
    </Tooltip >
  )
}

import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { LandingsPendingValidationsResponse } from "api/service/landing/getLandingsPendingValidations";
import { FaSearch } from "react-icons/fa";

import { LandingDetail } from "./LandingDetail";

interface ValidatePendingLandingServicesRowProps {
  service: LandingsPendingValidationsResponse
}

export function ValidatePendingLandingServicesRow({
  service
}: ValidatePendingLandingServicesRowProps) {

  const {
    isOpen: isDetailModalOpen,
    onOpen: onOpenDetailModal,
    onClose: onCloseDetailModal,
  } = useDisclosure()

  return (
    <Tr>

      <Td>
        <IconButton
          aria-label="Visualizar Desembarque"
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          onClick={onOpenDetailModal}
        />

        <Modal
          isOpen={isDetailModalOpen}
          onClose={onCloseDetailModal}
          isCentered
        >
          <ModalOverlay />
          <LandingDetail
            landingId={service.id}
            onCloseModal={onCloseDetailModal}
          />
        </Modal>
      </Td>
      <Td>{service.protocol}</Td>
      <Td>{service.customer}</Td>
      <Td>{service?.destination_collector}</Td>
      <Td>{service?.destination_branch}</Td>
    </Tr>
  )
}

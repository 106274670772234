import { Box, HStack, Icon, Modal, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { format, formatDistanceToNowStrict, isFuture } from "date-fns";
import { FaCalendarAlt, FaExclamationCircle } from "react-icons/fa";
import { ptBR } from "date-fns/locale";
import { PassiveFeedback } from "api/feedbacks/_types/PassiveFeedback";
import { PassiveFeedbackDetails } from "./PassiveFeedbackDetails";

interface KanbanPassiveFeedbackCardProps {
  passiveFeedback: PassiveFeedback
}

export function KanbanPassiveFeedbackCard({ passiveFeedback }: KanbanPassiveFeedbackCardProps) {

  const {
    isOpen: isPassiveFeedbackDetailModalOpen,
    onToggle: onTogglePassiveFeedbackDetailModal,
  } = useDisclosure()


  const passiveFeedbackFinalDate = new Date(passiveFeedback?.deadline);
  const formattedPassiveFeedbackDate = format(passiveFeedbackFinalDate, "dd MMM", { locale: ptBR });

  const relativeDate = formatDistanceToNowStrict(passiveFeedbackFinalDate, { locale: ptBR });
  const isDateInFuture = isFuture(passiveFeedbackFinalDate);
  const relativeDateText = isDateInFuture ? `em ${relativeDate}` : `${relativeDate} atrás`;

  return (
    <Box
      w="full"
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="sm"
      border="1px solid #E2E8F0"
      onClick={onTogglePassiveFeedbackDetailModal}
      cursor='pointer'
    >

      <Text fontWeight="bold" mb={2}>
        {passiveFeedback?.cltDriverId ? passiveFeedback?.cltDriver?.driver?.firstname : passiveFeedback?.internClt ? passiveFeedback?.internClt?.name : '-'}
      </Text>

      <VStack align="start" spacing={2} fontSize="sm" color="gray.600">


        {passiveFeedback?.status === 'scheduled' && (
          <HStack spacing={2}>
            {isDateInFuture ? (
              <>
                <Icon as={FaCalendarAlt} />
                <Text fontWeight="bold">{`Venc ${formattedPassiveFeedbackDate}`}</Text>
              </>
            ) : (
              <>
                <Icon as={FaExclamationCircle} color="red.500" />
                <Text color="red.500" fontWeight="bold">{`Venc ${formattedPassiveFeedbackDate}`}</Text>
              </>
            )}
            <Text>{relativeDateText}</Text>
          </HStack>

        )}

      </VStack>
      <Modal
        isOpen={isPassiveFeedbackDetailModalOpen}
        onClose={onTogglePassiveFeedbackDetailModal}
        size='6xl'
      >
        <ModalOverlay />
        <PassiveFeedbackDetails
          passiveFeedbackId={passiveFeedback.id}
          onClose={onTogglePassiveFeedbackDetailModal}
        />
      </Modal>
    </Box>
  )
}

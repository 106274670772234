import { IconButton, Modal, ModalOverlay, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { ExperiencePeriod } from "api/experiencePeriods/types/ExperiencePeriod";
import { format } from "date-fns";
import { useAuth } from "hooks/auth/useAuth";
import { useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import { ExperiencePeriodDetail } from "./ExperiencePeriodDetail";
import { ExperiencePeriodStatus } from "./ExperiencePeriodStatus";

interface ExperiencePeriodTableRowProps {
  experiencePeriod: ExperiencePeriod
}

export function ExperiencePeriodTableRow({ experiencePeriod }: ExperiencePeriodTableRowProps) {
  const history = useHistory()
  const { userLogged } = useAuth()
  const userCanViewExperiencePeriods = userLogged?.permissions?.includes('view-experience-periods')
  useEffect(() => {
    if (!userCanViewExperiencePeriods) history.push('/')
  }, [history, userCanViewExperiencePeriods])


  const {
    isOpen: isExperiencePeriodDetailModalOpen,
    onOpen: onOpenExperiencePeriodDetailModal,
    onClose: onCloseExperiencePeriodDetailModal,
  } = useDisclosure()

  return (

    <Tr>
      <Td>
        <Modal
          isOpen={isExperiencePeriodDetailModalOpen}
          onClose={onCloseExperiencePeriodDetailModal}
        >
          <ModalOverlay />
          <ExperiencePeriodDetail experiencePeriodId={experiencePeriod?.id} />
        </Modal>
        <IconButton
          aria-label="Detalhes do periodo de experiência"
          icon={<FaSearch />}
          onClick={onOpenExperiencePeriodDetailModal}
          size="sm"
        />
      </Td>
      <Td>{experiencePeriod?.name}</Td>
      <Td>{format(new Date(experiencePeriod?.admission_date), 'dd/MM/yyyy')}</Td>
      <Td>{format(new Date(experiencePeriod?.period_45_days_end_date), 'dd/MM/yyyy') ?? '-'}</Td>
      <Td>{format(new Date(experiencePeriod?.period_90_days_end_date), 'dd/MM/yyyy') ?? '-'}</Td>
      <Td>{experiencePeriod?.regional}</Td>
      <Td>
        <ExperiencePeriodStatus status={experiencePeriod?.status} />
      </Td>
    </Tr>
  )
}

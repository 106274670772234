import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";

enum ExternalComunicationsSubjectButtonStatusColor {
  'open' = 'red.500',
  'closed' = 'blue.500',
}

interface ExternalComunicationsSubjectButtonProps extends BoxProps {
  serviceProtocol: number
  subject: string
  type: 'closed' | 'open'
}

export function ExternalComunicationsSubjectButton({
  serviceProtocol,
  subject,
  type,
  ...rest
}: ExternalComunicationsSubjectButtonProps) {
  return (
    <Box
      p="2"
      w="full"
      bgColor="white"
      display="flex"
      alignItems="center"
      gap="1"
      justifyContent="space-between"
      fontSize="xs"
      transition="all 0.3s"
      rounded="md"
      _hover={{
        cursor: "pointer",
        bgColor: "gray.50",
        boxShadow: "base"
      }}
      {...rest}
    >
      <Box
        rounded="full"
        bgColor={ExternalComunicationsSubjectButtonStatusColor[type]}
        w="2"
        h="2"
      />
      <Flex
        direction="column"
        justify="space-between"
        maxW="80%"
        w="full"
      >
        <Text
          fontSize="x-small"
        >
          {serviceProtocol}
        </Text>
        <Text
          w="full"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {subject}
        </Text>
      </Flex>
    </Box>
  )
}

import { api } from "../../../services/api"

async function sendPdfFilesReqFunction(input: FormData) {
  try {
    await api.post("/pdf", input)

  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

async function sendFileToAssociatePdfToServiceReqFunction(input: FormData) {
  try {
    await api.post("/pdf/associate", input)

  } catch (error: any) {
    throw new Error(error.response.data.message)
  }
}

export { sendPdfFilesReqFunction, sendFileToAssociatePdfToServiceReqFunction }

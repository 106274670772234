import { useEffect } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { useHistory, Link } from "react-router-dom";

import { Box, Button, Flex, Heading, Icon, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { FiEdit3, FiEye, FiPlus, FiTrash2 } from "react-icons/fi";
import { useToastify } from "hooks/toastify/useToastify";
import { usePagination } from "@ajna/pagination";
import { Pagination } from "components/Pagination/Pagination";
import { useEmployeeCosts } from "hooks/employeeCosts/useEmployeeCosts";
import { useEmployeeCostsFunctions } from "hooks/employeeCosts/useEmployeeCostsFunctions";
import { useQueryClient } from "react-query";

export function EmployeeCostsList() {
  const rowsPerPage = process.env.REACT_APP_ITEMS_PER_PAGE

  const { userLogged } = useAuth()
  const { push: redirect, location } = useHistory()

  const { promiseMessage } = useToastify();

  const userHasPermissionToViewEmployeeCosts =
    userLogged?.permissions.includes("view-employee-costs");

  useEffect(() => {
    if (!userHasPermissionToViewEmployeeCosts) {
      redirect("/");
    }
  }, [userHasPermissionToViewEmployeeCosts, redirect]);


  const currentPageFilter = location.search.match(`[?&]page=([^&]+)`)

  const {
    data: employeeCostsData,
    isFetching: isFetchingEmployeeCostsData,
  } = useEmployeeCosts({
    queryParams: {
      pageSize: Number(rowsPerPage),
      currentPage: currentPageFilter ? Number(currentPageFilter[1]) : 1
    }
  })
  const { deleteEmployeeCost } = useEmployeeCostsFunctions()

  const queryClient = useQueryClient()

  async function handleDeleteEmployeeCost(employeeCostId: string) {
    await promiseMessage(
      deleteEmployeeCost.mutateAsync(employeeCostId, {
        onSuccess: async () => {
          await queryClient.invalidateQueries('employeeCosts')
        },
      }),
      "Custo de empregado excluído com sucesso!"
    );
  }

  const { pagesCount, pages, currentPage, setCurrentPage } =
    usePagination({
      limits: {
        outer: 1,
        inner: 1,
      },
      pagesCount: employeeCostsData?.totalPages,
      initialState: {
        pageSize: Number(rowsPerPage),
        isDisabled: false,
        currentPage: 1,
      },
    });

  const handleChangePage = (page: number) => setCurrentPage(page)

  useEffect(() => {
    if (userHasPermissionToViewEmployeeCosts) {
    redirect({
      pathname: '/empregados/custos',
      search: `?page=${currentPage}`
    })
    }
  }, [currentPage, redirect, userHasPermissionToViewEmployeeCosts])


  return (

      <Box
        p={4}
        bg="white"
        borderRadius="8px"
      >
        <Flex
          w="full"
          direction="column"
          gap={6}
        >
          <Flex
            mb="8"
            justify="space-between"
            align="center"
            direction={['column', 'column', 'row']}
          >
            <Heading fontSize="xl">Custos de Empregados</Heading>

            {userLogged !== null && (
              <Link to="/empregado/custo/criar">
                {userLogged?.permissions.includes('create-employee-cost') && (
                  <Button
                    colorScheme="gray"
                    rightIcon={<Icon as={FiPlus} />}
                    size="sm"
                    mt={['2', '2', '0']}
                  >
                    Adicionar Custo de Empregado
                  </Button>
                )}
              </Link>
            )}
          </Flex>

          {isFetchingEmployeeCostsData ? (
            <Spinner />
          ) : (
            <TableContainer>
              <Table size='sm' variant='striped'>
                <Thead>
                  <Th>REMUNERAÇÃO</Th>
                  <Th>REGIÃO</Th>
                  <Th></Th>
                </Thead>
                <Tbody>
                  {employeeCostsData?.employeeCosts.map((employeeCost) => {
                    return (
                      <Tr key={employeeCost.id}>
                        <Td>{new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(employeeCost.remuneration / 100)}</Td>
                        <Td>{employeeCost.region.acronym}</Td>
                        <Td isNumeric>
                          {userLogged !== null && (
                            <>
                              <Link to={`/empregado/custo/${employeeCost.id}/visualizar`} >
                                <Icon
                                  cursor="pointer"
                                  as={FiEye}
                                  transition="all 0.2s"
                                  _hover={{ opacity: 0.8 }}
                                  fontSize="20"
                                />
                              </Link>
                              {userLogged.permissions.includes('edit-employee-cost') && (
                                <Link to={`/empregado/custo/${employeeCost.id}/editar`} >
                                  <Icon
                                    cursor="pointer"
                                    as={FiEdit3}
                                    transition="all 0.2s"
                                    _hover={{ opacity: 0.8 }}
                                    fontSize="20"
                                    ml="4"
                                  />
                                </Link>
                              )}
                              {userLogged.permissions.includes('delete-employee-cost') && (
                                <Icon
                                  onClick={() => handleDeleteEmployeeCost(employeeCost.id)}
                                  cursor="pointer"
                                  as={FiTrash2}
                                  transition="all 0.2s"
                                  _hover={{ opacity: 0.8 }}
                                  fontSize="20"
                                  ml="4"
                                />
                              )}
                            </>
                          )}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Flex>
        <Pagination
          currentPage={currentPage}
          pages={pages}
          pagesQuantity={pagesCount}
          handlePageChange={handleChangePage}
        />
      </Box>

  )

}

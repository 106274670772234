class SwitchOccurrenceTypes {
  handle(userType?: string) {
    const occurrenceTypes = [
      { key: "collector", value: 'COLETADOR', showOption: 'COLETADOR' },
      { key: "shipping", value: 'TRANSPORTADORA', showOption: 'TRANSPORTADORA' },
      { key: "customer", value: 'CLIENTE', showOption: 'CLIENTE' },
    ]

    if (userType === 'MOTORISTA' || userType === 'COLETADOR') {
      const newOccurrenceTypes = [...occurrenceTypes]

      newOccurrenceTypes.push({ key: "driver", value: 'MOTORISTA', showOption: 'MOTORISTA' })

      return newOccurrenceTypes
    }

    if (userType?.includes('COMERCIAL')) {
      const newOccurrenceTypes = [...occurrenceTypes]

      return newOccurrenceTypes.filter(occurrenceType => {
        return occurrenceType.key === 'customer'
      })
    }

    if (userType?.includes('OPERACIONAL')) {
      const newOccurrenceTypes = [...occurrenceTypes]

      return newOccurrenceTypes.filter(occurrenceType => {
        return occurrenceType.key !== 'customer'
      })
    }

    return occurrenceTypes
  }
}

const switchOccurrenceTypes = new SwitchOccurrenceTypes()

export { switchOccurrenceTypes }
